import { useState } from "react"
import Modal from 'react-bootstrap/Modal';

const BillingInfo = ({show,handleClose,onPay}) => {
    const [billingAddress,setBillingAddress] = useState({})
    const onChange = (e) => {
        const {name,value} = e.target
        setBillingAddress({
            ...billingAddress,
            [name] : value
        })
    }
    const handlePay = (e) => {
        e.preventDefault()
        onPay(billingAddress)
    }
    return(
        <Modal
          show={show}
          onHide={handleClose}
          backdrop="static"
          keyboard={false}
          size="md"
          aria-labelledby="contained-modal-title-vcenter"
          centered   
        >
          <Modal.Header closeButton>
            <Modal.Title>Billing Info</Modal.Title>
          </Modal.Header>
          <Modal.Body className="verify-email-modal-body" style={{minHeight:"220px"}}>
          <div className='subscriptionModalMainContainer'>
                <div className='subscriptionModalPlanContainer'>
                    {/* <div>
                        <span className='subscriptionModalValueText SpotLetSubHead'>Billing Info</span>
                    </div>
                    <hr/> */}
                    <form className='d-flex flex-column SpotLetContent' onSubmit={handlePay}>
                        <div className='d-flex flex-column w-100 mb-2'>
                            <label className='form-label'>Billing Name{' '}<span className='required'>*</span></label>
                            <input
                                type='text'
                                name='billingName'
                                className='textFiled'
                                value={billingAddress?.billingName}
                                required
                                onChange={onChange}
                             />
                        </div>
                        <div className='d-flex flex-column w-100 mb-2'>
                            <label className='form-label'>GST Number{' '}(Optional)</label>
                            <input
                                type='text'
                                name='gstNumber'
                                className='textFiled'
                                value={billingAddress?.gstNumber}
                                // required
                                onChange={onChange}
                             />
                        </div>
                        <div className='d-flex flex-column w-100 mb-2'>
                            <label className='form-label'>Address{' '}<span className='required'>*</span></label>
                            <input
                                type='text'
                                name='address'
                                className='textFiled'
                                value={billingAddress?.address}
                                required
                                onChange={onChange}
                             />
                        </div>
                        <div className='d-flex flex-column w-100 mb-2'>
                            <label className='form-label'>City{' '}<span className='required'>*</span></label>
                            <input
                                type='text'
                                name='city'
                                className='textFiled'
                                value={billingAddress?.city}
                                required
                                onChange={onChange}
                             />
                        </div>
                        <div className='d-flex flex-column w-100 mb-2'>
                            <label className='form-label'>State{' '}<span className='required'>*</span></label>
                            <input
                                type='text'
                                name='state'
                                className='textFiled'
                                value={billingAddress?.state}
                                required
                                onChange={onChange}
                             />
                        </div>
                        <div className='d-flex flex-column w-100 mb-2'>
                            <label className='form-label'>Pincode{' '}<span className='required'>*</span></label>
                            <input
                                type='number'
                                name='pincode'
                                className='textFiled'
                                value={billingAddress?.pincode}
                                required
                                onChange={onChange}
                             />
                        </div>
                        <div className='d-flex flex-row justify-content-between'>
                            <button type='button' onClick={handleClose} className='btn btn-secondary'>Back</button>
                            <button type='submit' className='btn btn-primary'>Proceed to Pay</button>
                        </div>
                    </form>
                </div>
            </div>
          </Modal.Body>
        </Modal>
    )
}
export default BillingInfo;