import { Table, Thead, Tbody, Tr, Th, Td } from 'react-super-responsive-table';
import 'react-super-responsive-table/dist/SuperResponsiveTableStyle.css';
import { getEnquiryData } from '../apis/api';
import { useState } from 'react';
import { CenteredModal } from './Modal/CenteredModal';
import moment from 'moment';

const UserEnquiriesResponsiveTable = ({rows,columns}) => {
    const [data, setData] = useState({});
    const [showCenteredModal, setShowCenteredModal] = useState(false);

    const getData = async(id) => {
        try{
            const res = await getEnquiryData({ id, read : false});
            setData(res?.data?.enquiryDetails);
            setShowCenteredModal(true);
        } catch (error) {
            console.log(error);
        }
    } 

    const getDetails = (id) => {
        return(
            <button  onClick={() => getData(id) }
                className="btn btn-outline-danger"
                type='button'
                >
                Details
              </button>
        )
    }

    const actions = (id) => {
        return getDetails(id);
    }

    const contactHostDetails = (each) => {
        return(
            <div className='mb-2 mt-2'>
                <div className=''>
						<span  className='fw-bold spotletContent'>Property Id :</span> <span>{each?.data?.locationId}</span>
                </div>
                <div className='mt-3'>
						<span  className='fw-bold spotletContent'>Enquiry Id :</span> <span>{each?.enquiryId}</span>
                </div>
                <div className='mt-3'>
						<span  className='fw-bold spotletContent'>Enquiry Date & Time :</span> <span>{moment(each.createdAt).format('YYYY-MM-DD hh:mm a')}</span>
                </div>
                {/* <div className='mt-3'>
						<span  className='fw-bold spotletContent'>Name :</span> <span>{each?.data?.name}</span>
                </div>
                <div className='mt-3'>
						<span  className='fw-bold spotletContent'>Contact No :</span> <span>{each?.data?.mobileNumber}</span>
                </div>
                <div className='mt-3'>
						<span  className='fw-bold spotletContent'>Email :</span> <span>{each?.data?.email}</span>
                </div> */}
            </div>
        )
    } 

    const reportIncorrectInfoDetails = (each) => {
        return(
            <div className='mb-2 mt-2'>
                <div className=''>
						<span  className='fw-bold spotletContent'>Property Id :</span> <span>{each?.data?.locationId}</span>
                </div>
                <div className='mt-3'>
						<span  className='fw-bold spotletContent'>Enquiry Id :</span> <span>{each?.enquiryId || "NA"}</span>
                </div>
                <div className='mt-3'>
						<span  className='fw-bold spotletContent'>Enquiry Date & Time :</span> <span>{moment(each.createdAt).format('YYYY-MM-DD hh:mm a')}</span>
                </div>
                {/* <div className='mt-3'>
						<span  className='fw-bold spotletContent'>Name :</span> <span>{each?.data?.name}</span>
                </div>
                <div className='mt-3'>
						<span  className='fw-bold spotletContent'>Contact No :</span> <span>{each?.data?.mobileNumber}</span>
                </div>
                <div className='mt-3'>
						<span  className='fw-bold spotletContent'>Email :</span> <span>{each?.data?.email}</span>
                </div> */}
                <div className='mt-3'>
                        <span  className='fw-bold spotletContent'>Report Details :</span>
						<ul>
                            {
                                each?.data?.reportDetails?.map((eachReport) => {
                                    return <li> {eachReport}  </li>
                                } )  
                            }
                        </ul>
                </div>
                <div className='mt-3 d-flex flex-row'>
						<span  className='fw-bold spotletContent text-nowrap'>Additional Info :</span> 
                        <span>{each?.data?.additionalInfo || "NA"}</span>
                </div>
            </div>
        )
    } 
    
    const getInTouchDetails = (each) => {
        return(
            <div className='mb-2 mt-2'>
                <div className=''>
						<span  className='fw-bold spotletContent'>Property Id :</span> <span>{each?.data?.locationId}</span>
                </div>
                <div className='mt-3'>
						<span  className='fw-bold spotletContent'>Enquiry Id :</span> <span>{each?.enquiryId || "NA"}</span>
                </div>
                <div className='mt-3'>
						<span  className='fw-bold spotletContent'>Enquiry Date & Time :</span> <span>{moment(each.createdAt).format('YYYY-MM-DD hh:mm a')}</span>
                </div>
                <div className='mt-3'>
						<span  className='fw-bold spotletContent'>Organisation Name :</span> <span>{each?.data?.organization}</span>
                </div>
                <div className='mt-3'>
						<span  className='fw-bold spotletContent'>Name :</span> <span>{each?.data?.name}</span>
                </div>
                <div className='mt-3'>
						<span  className='fw-bold spotletContent'>User Contact No :</span> <span>{each?.data?.mobileNumber}</span>
                </div>
                <div className='mt-3'>
						<span  className='fw-bold spotletContent'>User Email :</span> <span>{each?.data?.email}</span>
                </div>
                <div className='mt-3 d-flex flex-row'>
						<span  className='fw-bold spotletContent me-2 text-nowrap'>Message :</span>  
                        <span className='spotletContent'>{each?.data?.message}</span>
                </div>
            </div>
        )
    } 

    const getScheduleVisitDetails = (each) => {
        return(
            <div className='mb-2 mt-2'>
                <div className=''>
						<span  className='fw-bold spotletContent'>Property Id :</span> <span>{each?.data?.locationId}</span>
                </div>
                <div className='mt-3'>
						<span  className='fw-bold spotletContent'>Enquiry Id :</span> <span>{each?.enquiryId || "NA"}</span>
                </div>
                <div className='mt-3'>
						<span  className='fw-bold spotletContent'>Enquiry Date & Time :</span> <span>{moment(each.createdAt).format('YYYY-MM-DD hh:mm a')}</span>
                </div>
                <div className='mt-3'>
						<span  className='fw-bold spotletContent'>Visit Schedule Date :</span> <span>{each?.data?.date}</span>
                </div>
                {/* <div className='mt-3'>
						<span  className='fw-bold spotletContent'>Name :</span> <span>{each?.data?.name}</span>
                </div>
                <div className='mt-3'>
						<span  className='fw-bold spotletContent'>Contact No :</span> <span>{each?.data?.mobileNumber}</span>
                </div>
                <div className='mt-3'>
						<span  className='fw-bold spotletContent'>Email :</span> <span>{each?.data?.email}</span>
                </div> */}
                <div className='mt-3 d-flex flex-row'>
						<span  className='fw-bold spotletContent me-2 text-nowrap'>Message :</span>  
                        <span className='spotletContent'>{each?.data?.additionalInfo}</span>
                </div>
            </div>
        )
    } 

    const bookingDetails = (each) => {
        let reqServices 
        if (each?.data?.services){
            const filteredServices = each?.data?.services?.filter((each ) => each !== "")
            reqServices = filteredServices.length > 0 ? each?.data?.services.join(", ") : "N/A";
        }
        else {
            reqServices = "N/A"
        }
        return(
            <div className="d-flex flex-column">
                <div className='card mb-xl-10' >
                    <div className='card-header cursor-pointer'>
                        <div className='mb-3'>
                        {/* begin::Text */}
                            <div className='mt-3'>
                                <span  className='fw-bold spotletContent'>Location Id :</span> <span>{each?.data?.locationId}</span>
                            </div>
                            <div className='mt-3'>
                                <span  className='fw-bold spotletContent'>Enquiry Id :</span> <span>{each?.enquiryId}</span>
                            </div>
                            <div className='mt-3'>
                                <span  className='fw-bold spotletContent'>Booking Id :</span> <span>{each?._id}</span>
                            </div>
                            <div className='mt-3'>
                                <span  className='fw-bold spotletContent'>Type of Event :</span> <span>{each?.data?.event}</span>
                            </div>
                            {/* { 
                            each?.data?.event !== "stay"  && (
                            <div className=' mt-3'>
                                <span  className='fw-bold'>Sub Event :</span> <span>{each?.data?.subEvent}</span>
                            </div>
                            )} */}
                            { 
                            each?.data?.event === "stay"  && 
                            <div className='mt-3'>
                                <span  className='fw-bold spotletContent'>Adults Number:</span> <span>{each?.data?.adults}</span>
                            </div>
                            }
                            { 
                            each?.data?.event === "stay"  && 
                            <div className='mt-3'>
                                <span  className='fw-bold spotletContent'>Children Number :</span> <span>{each?.data?.children}</span>
                            </div>
                            }
                            { 
                            each?.data?.event === "stay"  && 
                            <div className='mt-3'>
                                <span  className='fw-bold spotletContent'>Infants Number :</span> <span>{each?.data?.infants}</span>
                            </div>
                            }
                            { 
                            each?.data?.event === "stay"  && 
                            <div className='mt-3'>
                                <span  className='fw-bold spotletContent'>Check In :</span> <span>{moment(each?.data?.checkIn).format('DD/MM/YYYY')} </span>
                            </div>
                            }
                            { 
                            each?.data?.event === "stay"  && 
                            <div className='mt-3'>
                                <span  className='fw-bold spotletContent'>Check Out :</span> <span>{moment(each?.data?.checkOut).format('DD/MM/YYYY')} </span>
                            </div>
                            }
                            { 
                            each?.data?.event !== "stay"  && 
                            <div className='mt-3'>
                                <span  className='fw-bold spotletContent'>No. of Guests  :</span> <span>{each?.data?.guests}</span>
                            </div>
                            }
                            <div className=' mt-3'>
                                <span  className='fw-bold spotletContent'>Requested on :</span> <span>{moment(each.createdAt).format('DD/MM/YYYY hh:mm a')}</span>
                            </div>
                            { each?.data?.event !== "stay"  && 
                                <div className=' mt-3'>
                                    <span  className='fw-bold spotletContent'>Booking Requested for : </span> 
                                    <ol  className='fw-bold'>
                                        {/* {
                                            each?.data?.requestDates?.map((eachDate) => {
                                                return ( */}
                                                <li className="mt-2 spotletContent"> 
                                                    Date :  {moment(each?.data?.startDate).format('DD/MM/YYYY')} <br/>
                                                    Start Time :  {each?.data?.startTime} <br/>
                                                    No. of Hours :  {each?.data?.noOfHours} <br/>
                                                </li>
                                                {/* )
                                            } )
                                        } */}
                                    </ol>
                            </div>
                        }
                        {/* end::Text */}
                        </div>
                    </div>
                </div>
            </div>

        )
    }

    const getModelBody = (key) => {
        switch (key) {
            case "Contact Host":
                return contactHostDetails(data);
            case "Report Incorrect Info":
                return reportIncorrectInfoDetails(data);
            case "Booking Request":
                return bookingDetails(data);
            case "Schedule Property Visit":
                return getScheduleVisitDetails(data);
            case "Get In Touch":
                return getInTouchDetails(data);
            default:
                return "NA";
        }
    }

    return (
        <>
        <Table>
          <Thead>
            <Tr>
                {columns?.map((column,i)=>{
                    return(
                        <Th key={i}>{column}</Th>
                    )
                })}
            </Tr>
          </Thead>
          <Tbody>
            {rows?.map((row,i1)=>{
                return(
                    <Tr key={i1}>
                        {Object.keys(row)?.map((key)=>{
                            if(key !== 'action'){
                                return(
                                    (key !== "id" &&  <Td key={key} className = "text-center">{row?.[key]}</Td>)
                                )
                            } else {
                                return(
                                    <Td className = "d-flex flex-row justify-content-center align-items-center" style = {{height : "61px", borderBottom : "none"}} >{actions(row?.id)}</Td>
                                )
                            }
                        })}
                    </Tr>
                )
            })}
          </Tbody>
        </Table>
        {
                showCenteredModal && (
                <CenteredModal
                    show={showCenteredModal}
                    handleClose={() => setShowCenteredModal(false)}
                    handleOk={() => setShowCenteredModal(false)}
                    title= "Details"
                    data = {getModelBody(data?.type)}
                />
                )
            }
        </>
      )
}
export default UserEnquiriesResponsiveTable;