
import { Modal } from "react-bootstrap";
import { useState } from "react";

const { Header, Title, Body } = Modal;

export const ScheduleVisitModal = ({
    handleClose,
    handleOk,
    locationId,
    show,
    userId,
}) => {

    const [formData, setFormData] = useState({
        locationId ,
        userId,
        date : "",
        additionalInfo : ""
    })

    const onChange = (e) => {
        const { name, value } = e.target
        setFormData({ ...formData, [name]: value })
    }


    const submitForm = async(e) => {
        e.preventDefault()
        handleOk(formData)
    }

    return (
        <>
            <Modal
                show={show}
                onHide={handleClose}
                backdrop="static"
                keyboard={false}
                size="md"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Header closeButton>
                    <Title className="SpotLetMainHead">Schedule a visit (Film)</Title>
                </Header>
                <Body className="verify-email-modal-body">
                <form onSubmit={submitForm} style={{ overflowY: "auto" }} >
                        <div
                            style={{
                                margin: "5px 5px 5px 0px",
                            }}
                        >
                            <h1 className="SpotLetSubHead"> Details : </h1>
                            <div className="">
                                <div className="mb-4 d-flex flex-column">
                                    <label htmlFor="visiting_date" className="form-label SpotLetContent">
                                        Visiting Date<span className="required">*</span>
                                    </label>
                                    <input
                                        type="date"
                                        required
                                        placeholder="Date"
                                        id="visiting_date"
                                        name="date"
                                        className="textFiled SpotLetContent"
                                        value={formData?.date}
                                        onChange={onChange}
                                        min = {new Date().toISOString().split('T')[0]}
                                    />
                                </div>
                                <div className="d-flex flex-column mb-4">
                                    <label
                                        htmlFor="claim_additional_info"
                                        className="form-label "
                                    >
                                        Additional Info<span className="required">*</span>
                                    </label>
                                    <textarea
                                        required
                                        className="basicInfoDescription text-area-preserved-whitespace"
                                        placeholder="Additional Info"
                                        id="claim_additional_info"
                                        rows='5'
                                        name='additionalInfo'
                                        onChange={onChange}
                                        value={formData?.additionalInfo}
                                    ></textarea>
                                </div>
                            </div>
                            
                            <div>
                                <button
                                    type="submit"
                                    className="SpotLetContent"
                                    style={{
                                        background: "#EA4335",
                                        border: "none",
                                        borderRadius: "5px",
                                        color: "#ffffff",
                                        width: "80px",
                                        height: "40px",
                                        margin: "5px",
                                    }}
                                >
                                    Submit
                                </button>
                            </div>
                        </div>
                    </form>
                </Body>
            </Modal>
        </>
    );

}