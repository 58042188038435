import { useEffect, useMemo, useState } from "react";
import Table from "./Table";
import ResponsiveTable from "./responsiveTable";
import { createPaymentOrder, getBookings, addPaymentStatusAndDetails, createBDPaymentOrder, userBookingCancellation } from "../apis/api";
import { useAuthStore } from "../store/auth";
import dayjs from "dayjs";
import { Button } from "react-bootstrap";
import { useNavigate } from 'react-router-dom';
import moment from "moment";
import { PageLoader } from "./PageLoader";
import { BookingRejectPopup } from "./Modal/BookingRejectPopup";
import BillingInfo from "./Modal/BillingInfoModel";

// Images
import logo from '../assets/NewImages/BookingsTable/spotlet_logo.svg';
// import logo from "../assets/NewImages/NewLogo/Spotlet_V1 .webp"


export const BookingsTable = () => {
  const { userProfile } = useAuthStore();
  const navigate = useNavigate();
  const COLUMNS = ['Booking ID','Location ID','Status','Date','Event','Action']
  const [rows,setRows] = useState([])
  const [bookings, setBookings] = useState([]);
  const [loader, setLoader] = useState(false)
  const [init, setInit] = useState(false)
  const [showBookingCancelPopup, setsShowBookingCancelPopup] = useState(false)
  const [booking, setBooking] = useState({})
  const [showBilling, setShowBilling] = useState(false)

  function getCancellationPeriod(cancellationPolicy) {
    const nonRefundable = cancellationPolicy === 'Non Refundable';
    if (nonRefundable) {
      return null;
    }
  
    const daysMatch = cancellationPolicy.match(/(\d+)\s*days?/i);
    if (daysMatch) {
      return parseInt(daysMatch[1]) * 24; // Convert days to hours
    }
  
    const hoursMatch = cancellationPolicy.match(/(\d+)\s*hours?/i);
    if (hoursMatch) {
      return parseInt(hoursMatch[1]);
    }
  
    return null;
  }

  function isCancellationAllowed(cancellationPolicy, checkInDate) {
    const cancellationPeriod = getCancellationPeriod(cancellationPolicy);
    if (cancellationPeriod === null) {
      return false; // Non-refundable
    }
  
    const currentTime = new Date();
    const checkInTime = new Date(checkInDate);
  
    // Calculate the time difference in hours
    const timeDifference = (checkInTime - currentTime) / (1000 * 60 * 60);
    return timeDifference >= cancellationPeriod;
  }



  const handleTableData = (data) => {
    Promise.all(data?.map((item)=>{
      let actions = ['details','Pay Now']
      if(item?.status === 'Confirmed'){
        if(isCancellationAllowed(item?.cancellation, item?.checkIn)){
          actions.push('User Cancel')
        }
      }
      return {
        bookingId : item?.bookingId,
        locationId : item?.property_id,
        status : item?.status,
        date : dayjs(item?.createdAt).format("DD/MM/YYYY"),
        Event : item?.event,
        action : actions,
      }
    })).then((values)=> setRows(values))
  }

  useEffect(() => {
    (async () => {
      if (userProfile) {
        setLoader(true)
        try {
          const resp = await getBookings(userProfile._id);
          handleTableData(resp?.data)
          setBookings(resp.data);
        } catch (error) {
          console.log(error);
        }
        setLoader(false)
        setInit(true)
      }
    })();
  }, [userProfile]);

  function loadScript(src) {
    return new Promise((resolve) => {
      const script = document.createElement('script');
      script.src = src;
      script.onload = () => {
        resolve(true);
      };
      script.onerror = () => {
        resolve(false);
      };
      document.body.appendChild(script);
    });
  }

  function loadScriptModule(src, module) {
    return new Promise((resolve) => {
      const script = document.createElement('script');
      script.src = src;
      if (module) {
        script.type='module';
      } else {
        script.noModule='';
      }
      script.onload = () => {
        resolve(true);
      };
      script.onerror = () => {
        resolve(false);
      };
      document.body.appendChild(script);
    });
  }

  const updateBooking = async (data) => {
    const bookingData = {
      bookingId: data?._id,
      paymentId : data?.razorpayPaymentId,
      status: 'Confirmed',
      paymentStatus: "Completed",
      billingAddress : data?.billingAddress,
    };


    try {
      await addPaymentStatusAndDetails(bookingData);
      const resp = await getBookings(userProfile?._id);
      setBookings(resp?.data);
      handleTableData(resp?.data)
    } catch (e) {
      console.log(e);
    }

  }

  const handleCancel = async (reason) => {
    setLoader(true)
    const bookingData = {
      bookingId: booking?._id,
      reason,
    };
    try {
      await userBookingCancellation(bookingData);
      const resp = await getBookings(userProfile?._id);
      setBookings(resp?.data);
      handleTableData(resp?.data)
      setsShowBookingCancelPopup(false)
    } catch (e) {
      console.log(e);
    }
    setLoader(false)
  }

  const handlePayNow = (id) => {
    let data;
    bookings?.map((item)=> {
      if(item?.bookingId === id){
        data = item
      }
    })
    setBooking(data)
    setShowBilling(true)
  }

  const payNow = (billingAddress) => {
    setShowBilling(false)
    displayRazorpay(billingAddress);
    // displayBillDesk(booking);
  }

  async function displayBillDesk(booking){

    const response = await loadScriptModule("https://uat1.billdesk.com/merchant-uat/sdk/dist/billdesksdk/billdesksdk.esm.js", 'module');
    const response1 = await loadScriptModule("https://uat1.billdesk.com/merchant-uat/sdk/dist/billdesksdk.js");
    //const response2 = await loadLinkTag("https://uat1.billdesk.com/merchant-uat/sdk/dist/billdesksdk/billdesksdk.css");

    if (!response) {
      alert('BillDesk SDK failed to load. Are you online?');
      return;
    }

    const result = await createBDPaymentOrder({
      amount: booking?.totalPayment + '.00',
      bookingId: booking?.bookingId
    });

    const { mercid, token, ru, bdorderid } = result.data;
    console.log("token = ", token);


    var flow_config = {
        merchantId: mercid,
        bdOrderId: bdorderid,
        authToken: token,
        childWindow: true,
        retryCount: 2
    };
    var responseHandler = function(txn) {
      console.log("responseHandler = txn ", txn);

        if (txn.status === 200) {
          
            console.log("callback received status:: ", txn.status);
            console.log("callback received response:: ", txn.txnResponse)//response handler to be implemented by the merchant

            updateBooking(booking, "Confirmed", 'Card');
        }
    };
    var config = {
        flowConfig: flow_config,
        flowType: "payments",
        responseHandler: responseHandler,
    };
    window.loadBillDeskSdk(config);

  }

  async function displayRazorpay(billingAddress) {
    try {
      setLoader(true)
      const res = await loadScript(
        'https://checkout.razorpay.com/v1/checkout.js'
      );

      if (!res) {
        setLoader(false)
        alert('Razorpay SDK failed to load. Are you online?');
        return;
      }
      const result = await createPaymentOrder({
        amount: booking?.totalPayment + '.00',
        bookingId: booking?.bookingId
      });

      if (!result) {
        setLoader(false)
        alert('Server error. Are you online?');
        return;
      }
      setLoader(false)
      const { amount, id: order_id, currency } = result.data;

      const options = {
        key: process.env.REACT_APP_RAZORPAY_KEY,//'<YOUR RAZORPAY KEY>', // Enter the Key ID generated from the Dashboard
        amount: amount,
        currency: currency,
        name: 'Spotlet solutions pvt ltd.',
        description: 'Location Booking amount',
        image: { logo },
        order_id: order_id,
        handler: async function (response) {
          const data = {
            orderId: order_id,
            razorpayPaymentId: response.razorpay_payment_id,
            razorpayOrderId: response.razorpay_order_id,
            razorpaySignature: response.razorpay_signature,
          };
          updateBooking({...booking,...data,billingAddress});

        },
        prefill: {
          name: userProfile?.personalInfo?.firstName,
          email: userProfile?.personalInfo?.email,
          contact: userProfile?.personalInfo?.mobile,
        },
        notes: {
          address: '',
        },
        theme: {
          color: '#61dafb',
        },
      };


      const paymentObject = new window.Razorpay(options);
      paymentObject.open();
    } catch (e) {
      console.log(e);
      setLoader(false)
    }
  }

  const columns = useMemo(
    () => [
      {
        Header: "Booking ID",
        accessor: "bookingId",
      },
      {
        Header: "Location ID",
        accessor: "property_id",
      },
      {
        Header: "Status",
        accessor: "status",
      },
      {
        Header: "Date",
        accessor: "createdAt",
        Cell: ({ row: { original } }) => {
          return dayjs(original?.createdAt).format(
            "DD-MM-YYYY"
          );
        },
      },
      {
        Header: "Event",
        accessor: "event",
        Cell: ({ row: { original } }) => {
          return original?.event
        },
      },
      // {
      //   Header: "Total Amount",
      //   accessor: "totalPayment",
      // },
      {
        Header: "Action",
        accessor: "",
        Cell: ({ row: { original } }) => {

          // const eventData = original?.bookedTimeDates[0];

          // // Parse the date and start time
          // const eventDate = new Date(eventData.date);
          // const startTime = moment(eventData.start, 'hh:mm a');

          // // Set the start time to the date
          // eventDate.setHours(startTime.hours(), startTime.minutes(), 0, 0);

          // // Add the number of hours
          // // eventDate.setHours(eventDate.getHours() + parseInt(eventData.no_of_hours));

          return (
            <div className="d-flex justify-content-center align-items-center">
              <Button variant="danger" onClick={() => navigate(`/booking-details/${original?.bookingId}`)}
                className="btn btn-theme btn-detail">
                Details
              </Button>
              {/* {original?.status === "Approved" && (new Date() < eventDate) && (
                <Button variant="danger" className="btn btn-theme btn-detail" onClick={() => payNow(original)}>
                  Pay Now
                </Button>
              )} */}
            </div>
          );
        },
      },
    ],
    []
  );

  return (
  <>
    {/* <div class="filter-btns">
      <input placeholder="Search" type="text" class="form-control" id="cname" />
      <button type="button" class="filter-btn btn" data-bs-toggle="modal" data-bs-target="#booking-filter">
        <img src="assets/images/filter.svg" class="me-2" alt="" />
        Filter</button>
    </div> */}
    {init && (
      <>
        {bookings?.length === 0 ? (
          <div className="d-flex flex-column justify-content-center align-items-center">
            <span className="SpotLetSubHead">No Bookings Found</span>
          </div>
        ) : (
          <div class="table-responsive">
            {/* <Table columns={columns} data={bookings} /> */}
            <ResponsiveTable rows={rows}  columns={COLUMNS} 
              payNow={(bookingId)=>{
                handlePayNow(bookingId)
              }}
              cancelByUser={(bookingId)=>{
                let data;
                bookings?.map((item)=> {
                  if(item?.bookingId === bookingId){
                    data = item
                  }
                })
                setsShowBookingCancelPopup(true)
                setBooking(data)
              }}
            />
          </div>
        )}
      </>
    )}
    {showBookingCancelPopup && (
      <BookingRejectPopup
        type='Continue'
        show={showBookingCancelPopup}
        handleClose={() => setsShowBookingCancelPopup(false)}
        handleOk={(rejectReason) => handleCancel(rejectReason)}
        bookings={booking}
      />
    )}
    {showBilling && (
      <BillingInfo
        show={showBilling}
        handleClose={() => setShowBilling(false)}
        onPay={(billingAddress) => payNow(billingAddress)}
      />
    )}
    {loader && <PageLoader />}
  </>
  )
};
