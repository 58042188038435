import React, { useState, useEffect, useRef } from "react";
import { useAuthStore } from "../../../../store/auth";
import { updateFavourites } from "../../../../apis/api"
import AuthenitcateModal from "../../../../utils/NewAuthentication/AuthenitcateModal";

import { Box, Typography } from "@mui/material";
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { FacebookShareButton } from 'react-share'

//mui popup
import PropTypes from 'prop-types';
import { Unstable_Popup as BasePopup } from '@mui/base/Unstable_Popup';
import { styled } from '@mui/system';

// Images
import redHeartP from "../../../../assets/NewImages/PropertyPage/redHeartP.svg";
import redHeart from "../../../../assets/NewImages/PropertyPage/redHartLogo.webp";
// import downArrowF from "../../../../assets/NewImages/PropertyPage/DownArrowfill.svg";
// import fileListSearch from "../../../../assets/NewImages/PropertyPage/file-list-search.svg";
import copyIcon from "../../../../assets/NewImages/PropertyPage/copyIcon.svg";
import MailIcon from "../../../../assets/NewImages/PropertyPage/MailIcon.svg";
import WhatsAppIcon from "../../../../assets/NewImages/PropertyPage/whatsappIcon.svg";
import FacebookIcon from "../../../../assets/NewImages/PropertyPage/facebookIcon.svg";

// import "../../propertyPage.css";
import "./style.css";

export default function TopTabs({
  favoriteDisplay,
  homePage,
  location_id,
}) {
  const ref = useRef(null);
  const { userProfile, setUserProfile } = useAuthStore();
  const [printBox, setPrintBox] = useState(false);
  const [favorites, setFavorites] = useState([]);
  const [shareBox, setShareBox] = useState(false);
  const copyRef = useRef(null);
  let reqUrl = new URL(window.location.href); 
  reqUrl = reqUrl?.href

  const handleClickOutside = (event) => {
    if (
      ref.current &&
      !ref.current.contains(event.target) &&
      event.target.id !== "Print" &&
      event.target.id !== "PrintImg" &&
      event.target.id !== "PrintText"
    ) {
      setPrintBox(false);
    }
    if (event.target.id === "printBox") {
      setPrintBox(true);
    }
  };

  const handleClickOutsideShareBox = (event) => {
    if (
      ref.current &&
      !ref.current.contains(event.target) &&
      event.target.id !== "share" &&
      event.target.id !== "shareText"
    ) {
      setShareBox(false);
    }
  };

  useEffect(() => {
    document.addEventListener("click", handleClickOutside);
    document.addEventListener("click", handleClickOutsideShareBox);
    return () => {
      // Remove the click event listener when the component unmounts
      document.removeEventListener("click", handleClickOutside);
      document.removeEventListener("click", handleClickOutsideShareBox);
    };
  }, []);

  useEffect(()=>{
    if(userProfile){
      setFavorites(userProfile?.favourites)
    }
  },[userProfile]);

   //Lead start
   const [showSignUp,setShowSignUp] = useState(false)
   const handleLead = async () => {
     const user = await setUserProfile()
     if(user){
      try {
        const favourites = user?.favourites
        if(!user?.favourites.includes(location_id)){
          favourites.push(location_id)
          const data = {favourites : favourites,user_id : user?._id}
          await updateFavourites(data);
        }
      } catch (error) {
        console.log(error);
      }
     }
   }
   //Lead end

  const updateFav = async (favorites) => {
    const data = {
      favourites: favorites,
      user_id: userProfile?._id,
    };
    if (userProfile?._id) {
      try {
        await updateFavourites(data);
        setUserProfile();
      } catch (error) {
        console.log(error);
      }
    }
  };

  const handleFavorites = () => {
    if(!userProfile){
      setShowSignUp(true)
      return null
    }
    if (favorites?.includes(location_id)) {
      const data = favorites.filter((element) => element !== location_id);
      setFavorites((prev) => prev.filter((element) => element !== location_id));
      updateFav(data);
    } else {
      setFavorites((prev) => [...prev, location_id]);
      updateFav([...favorites, location_id]);
    }
  };

  // mui popup starts
  const  PopupWithTrigger = (props) => {

    const { id, buttonLabel, ...other } = props;

    return (
      <>
        <Box className={homePage ? "topTabs homePageTopTab" : "topTabs"}>
          <Box
            className="top_TabBox"
          >
            <Box
              onClick={handleFavorites}
              sx={{
                width: "90px",
                background: "#fff",
                display: favoriteDisplay ? "flex" : "none",
              }}
              className="topWhiteTab pointer"
            >
              <img
                style={{ width: "16px", height: "16px" }}
                className="topTabLogo"
                src={favorites.includes(location_id) ? redHeartP : redHeart}
              />
              <Typography>Favorite</Typography>
            </Box>

            <Box
              onClick={() => {
                setShareBox(!shareBox)
                // if (copy) setCopy(false)
              }}
              className="topWhite_Tab pointer share_Box"
              sx={{
                background: shareBox ? "#EA4335" : "#fff",
                color: shareBox ? "#fff" : "black",
              }}
              id="share"
            >
              {shareBox ? (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="11"
                  height="12"
                  viewBox="0 0 11 12"
                  fill="none"
                >
                  <path
                    d="M8.9375 7.78125C8.25823 7.78125 7.65964 8.12299 7.28367 8.6427L4.0331 6.94041C4.08707 6.75227 4.125 6.5573 4.125 6.35156C4.125 6.07252 4.06906 5.80723 3.97242 5.56322L7.37429 3.46959C7.75287 3.924 8.30933 4.21875 8.9375 4.21875C10.0748 4.21875 11 3.27255 11 2.10938C11 0.946195 10.0748 0 8.9375 0C7.80017 0 6.875 0.946195 6.875 2.10938C6.875 2.37743 6.92897 2.6317 7.0185 2.86777L3.60651 4.96753C3.22825 4.52663 2.67981 4.24219 2.0625 4.24219C0.925169 4.24219 0 5.18838 0 6.35156C0 7.51474 0.925169 8.46094 2.0625 8.46094C2.75298 8.46094 3.36167 8.10916 3.73622 7.57486L6.97609 9.27162C6.91641 9.46863 6.875 9.67383 6.875 9.89062C6.875 11.0538 7.80017 12 8.9375 12C10.0748 12 11 11.0538 11 9.89062C11 8.72745 10.0748 7.78125 8.9375 7.78125Z"
                    fill="white"
                  />
                </svg>
              ) : (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="11"
                  height="12"
                  viewBox="0 0 11 12"
                >
                  <path
                    d="M8.9375 7.78125C8.25823 7.78125 7.65964 8.12299 7.28367 8.6427L4.0331 6.94041C4.08707 6.75227 4.125 6.5573 4.125 6.35156C4.125 6.07252 4.06906 5.80723 3.97242 5.56322L7.37429 3.46959C7.75287 3.924 8.30933 4.21875 8.9375 4.21875C10.0748 4.21875 11 3.27255 11 2.10938C11 0.946195 10.0748 0 8.9375 0C7.80017 0 6.875 0.946195 6.875 2.10938C6.875 2.37743 6.92897 2.6317 7.0185 2.86777L3.60651 4.96753C3.22825 4.52663 2.67981 4.24219 2.0625 4.24219C0.925169 4.24219 0 5.18838 0 6.35156C0 7.51474 0.925169 8.46094 2.0625 8.46094C2.75298 8.46094 3.36167 8.10916 3.73622 7.57486L6.97609 9.27162C6.91641 9.46863 6.875 9.67383 6.875 9.89062C6.875 11.0538 7.80017 12 8.9375 12C10.0748 12 11 11.0538 11 9.89062C11 8.72745 10.0748 7.78125 8.9375 7.78125Z"
                    fill="black"
                  />
                </svg>
              )}
              <Typography id="shareText" sx={{ marginLeft: "5px" }}>
                Share
              </Typography>
            </Box>
          </Box>
        </Box>
        <Popup id={id} open={shareBox}  {...other}>
          <PopupBody>
                <Box
                    ref={ref}
                    sx={{ display: shareBox ? "block" : "none" }}
                  >
                    {/* <Box ml={1} className="shareBoxHeader">
                      <Typography className="shareBoxHeaderText">
                        Share this location
                      </Typography>
                    </Box> */}
                    <Box className="shareItemBox">
                      <CopyToClipboard
                        // text={window.location.href}
                        text={reqUrl}
                        onCopy={() => copyRef.current.innerText = "Copied"}
                      >
                        <Box className="shareItem">
                          <img src={copyIcon} />
                          <Typography className="shareItemText" ref = {copyRef}> Copy Link </Typography>
                        </Box>
                      </CopyToClipboard>
                      <a
                        target="_blank"
                        href={`mailto:?subject=I wanted you to see this property&body=Check out this ${reqUrl}`}
                        title="Share viaEmail"
                        style={{ textDecoration: "none" }}
                      >
                        <Box className="shareItem">
                          <img src={MailIcon} />
                          <Typography className="shareItemText">Email</Typography>
                        </Box>
                      </a>
                      <a
                        target="_blank"
                        href={`https://api.whatsapp.com/send?text=I wanted you to see this property Check out this ${reqUrl}&app_absent=0`}
                        title="Share viaWhatsapp"
                        style={{ textDecoration: "none" }}
                      >
                        <Box className="shareItem">
                          <img src={WhatsAppIcon} />
                          <Typography className="shareItemText">WhatsApp</Typography>
                        </Box>
                      </a>
                      <FacebookShareButton url={reqUrl} quotes={"I wanted you to see this property"}>
                        <Box className="shareItem">
                          <img src={FacebookIcon} />
                          <Typography className="shareItemText">Facebook</Typography>
                        </Box>
                      </FacebookShareButton>
                    </Box>
                </Box>
          </PopupBody>
        </Popup>
      </>
    );
  }
  
  PopupWithTrigger.propTypes = {
    buttonLabel: PropTypes.string.isRequired,
    id: PropTypes.string,
  };
  
  const Popup = styled(BasePopup)`
    z-index: 11;
  `;
  
  const grey = {
    50: '#F3F6F9',
    100: '#E5EAF2',
    200: '#DAE2ED',
    300: '#C7D0DD',
    400: '#B0B8C4',
    500: '#9DA8B7',
    600: '#6B7A90',
    700: '#434D5B',
    800: '#303740',
    900: '#1C2025',
  };
  
  const PopupBody = styled('div')(
    ({ theme }) => `
    width: 390px;
    height: auto;
    display: flex;
    align-items: center;
    padding: 16px;
    margin: 8px;
    border-radius: 8px;
    border: 1px solid ${theme.palette.mode === 'dark' ? grey[700] : grey[200]};
    background-color: ${theme.palette.mode === 'dark' ? grey[900] : '#fff'};
    box-shadow: ${
      theme.palette.mode === 'dark'
        ? `0px 4px 8px rgb(0 0 0 / 0.7)`
        : `0px 4px 8px rgb(0 0 0 / 0.1)`
    };
    font-family: 'IBM Plex Sans', sans-serif;
    font-size: 0.875rem;
  `,
  );
  // mui popup ends

  return (
    <>
        <Box
          sx={{
          display: 'flex',
          gap: '10px',
          overflowY: 'auto',
          position: 'relative',
          padding: '40px',
          }}
      >
          {/* <PopupWithTrigger id="popup-with-portal" buttonLabel="With a portal" /> */}
          <PopupWithTrigger
              id="popup-without-portal"
              buttonLabel="No portal, default strategy"
              disablePortal
              className = "propertyPageSharePopup"
          />
          {/* <PopupWithTrigger
              id="popup-without-portal-fixed"
              buttonLabel="No portal, 'fixed' strategy"
              disablePortal
              strategy="fixed"
          /> */}
        </Box>
          {showSignUp && 
            <AuthenitcateModal
                show={showSignUp}
                handleSuccess={()=> {
                    setShowSignUp(false)
                    handleLead()
                }}
                handleClose={()=>setShowSignUp(false)}
                activeTab=''
            />
          }
    </>
  );
}
