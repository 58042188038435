import { useLocation, useNavigate , useParams} from 'react-router-dom';
import { useState,useEffect } from 'react';
import { getSubscription, getBookingDetails,getInvoiveDetails } from '../../apis/api';
import { PageLoader } from '../../components/PageLoader';
import generatePDF, { Resolution, Margin } from 'react-to-pdf';
import { IoPrintOutline } from "react-icons/io5";
import { GoDownload } from "react-icons/go";
import "./Invoice.css"

//images
import spotlet_logo from "../../assets/NewImages/NewLogo/Spotlet_V1 .webp"

const options = {
    // default is `save`
    method: 'save',
    // default is Resolution.MEDIUM = 3, which should be enough, higher values
    // increases the image quality but also the size of the PDF, so be careful
    // using values higher than 10 when having multiple pages generated, it
    // might cause the page to crash or hang.
    resolution: Resolution.MEDIUM,
    page: {
       // margin is in MM, default is Margin.NONE = 0
       margin: Margin.SMALL,
       // default is 'A4'
       format: 'letter',
       // default is 'portrait'
       orientation: 'portrait',
    },
    canvas: {
       // default is 'image/jpeg' for better size performance
       mimeType: 'image/png',
       qualityRatio: 1
    },
    // Customize any value passed to the jsPDF instance and html2canvas
    // function. You probably will not need this and things can break, 
    // so use with caution.
    overrides: {
       // see https://artskydj.github.io/jsPDF/docs/jsPDF.html for more options
       pdf: {
          compress: true
       },
       // see https://html2canvas.hertzen.com/configuration for more options
       canvas: {
          useCORS: true
       }
    },
 };

const Invoice = () => {
    const { id } = useParams()
    const [data,setData] = useState({})
    const [loader,setLoader] = useState(false)
    const getData = async()=>{
        try{
            const resp = await getInvoiveDetails(id)
            setData(resp?.data)
        }catch(error){
            console.log(error)
        }
    }
    useEffect(()=>{
        if(id) getData()
    },[])
    const getTargetId = () => {
        const id = document.getElementById('spotletInvoiceContainer')
        return id
    }

    const downloadPdf = async() => {
        setLoader(true)
        try{
            await generatePDF(getTargetId, options)
        } catch(e) {
            console.log(e);
        }
        setLoader(false)
    }

    return(
        <div className='InvoiceMainContainer'>
            <div className="invoiceButtonContainer">
                <button className='btn btn-primary me-2' onClick={() => window.print()}><IoPrintOutline />{' '}Print</button>
                <button className='btn btn-primary' onClick={() => downloadPdf()}><GoDownload />{' '}Download</button>
            </div>
            <div id="spotletInvoiceContainer" className="invoicePageContainer mt4">
                {/* <div className='invoiceHeader'>
                    <img src={spotlet_logo} alt='spotlet logo'/>
                    <div className='d-flex flex-column'>
                        <span>Spotlet Pvt Ltd.</span>
                        <span>Spotlet Pvt Ltd.</span>
                        <span>Spotlet Pvt Ltd.</span>
                        <span>Spotlet Pvt Ltd.</span>
                    </div>
                </div>
                <hr/> */}
                {/* <div className='invoiceBody'>
                    {data?.listingId && (
                        <div className='w-100 mb-2 d-flex flex-row justify-content-between align-items-center'>
                            <span className='invoiceKeyText SpotLetContent'>Listing Id : </span>
                            <span className='invoiceValueText SpotLetContent'>{data?.listingId}</span>
                        </div>
                    )}
                    <div className='w-100 mb-2 d-flex flex-row justify-content-between align-items-center'>
                        <span className='invoiceKeyText SpotLetContent'>Subscription : </span>
                        <span className='invoiceValueText SpotLetContent'>{data?.planName}</span>
                    </div>
                    <div className='w-100 mb-2 d-flex flex-row justify-content-between align-items-center'>
                        <span className='invoiceKeyText SpotLetContent'>   Plan Id : </span>
                        <span className='invoiceValueText SpotLetContent'>{data?.planId}</span>
                    </div>
                    <hr className='w-100'/>
                    <div className='mb-2'>
                        <span className='SpotLetSubHead'>Payment Details : </span>
                    </div>
                    <div className='w-100 mb-2 d-flex flex-row justify-content-between align-items-center'>
                        <span className='invoiceKeyText SpotLetContent'>   Order Id : </span>
                        <span className='invoiceValueText SpotLetContent'>{payment?.orderId}</span>
                    </div>
                    <div className='w-100 mb-2 d-flex flex-row justify-content-between align-items-center'>
                        <span className='invoiceKeyText SpotLetContent'>   Payment Id : </span>
                        <span className='invoiceValueText SpotLetContent'>{payment?.razorpayPaymentId}</span>
                    </div>
                    <div className='w-100 mb-2 d-flex flex-row justify-content-between align-items-center'>
                        <span className='invoiceKeyText SpotLetContent'>Plan Price : </span>
                        <span className='invoiceValueText SpotLetContent'>{payment?.totalAmount}</span>
                    </div>
                    <div className='w-100 mb-2 d-flex flex-row justify-content-between align-items-center'>
                        <span className='invoiceKeyText SpotLetContent'>Base Price : </span>
                        <span className='invoiceValueText SpotLetContent'>{payment?.basicPrice}</span>
                    </div>
                    {payment?.couponDiscount && (
                        <div className='w-100 mb-2 d-flex flex-row justify-content-between align-items-center'>
                            <span className='invoiceKeyText SpotLetContent'>Coupon Discount : </span>
                            <span className='invoiceValueText SpotLetContent'>{payment?.couponDiscount}</span>
                        </div>
                    )}
                    <div className='w-100 mb-2 d-flex flex-row justify-content-between align-items-center'>
                        <span className='invoiceKeyText SpotLetContent'>CGST : </span>
                        <span className='invoiceValueText SpotLetContent'>{payment?.cgst}</span>
                    </div>
                    <div className='w-100 mb-2 d-flex flex-row justify-content-between align-items-center'>
                        <span className='invoiceKeyText SpotLetContent'>SGST : </span>
                        <span className='invoiceValueText SpotLetContent'>{payment?.sgst}</span>
                    </div>
                    <div className='w-100 mb-2 d-flex flex-row justify-content-between align-items-center'>
                        <span className='invoiceKeyText SpotLetContent'>Tota Amount : </span>
                        <span className='invoiceValueText SpotLetContent'>{payment?.totalAmount}</span>
                    </div>
                    <hr className='w-100'/>
                    <div className='mb-2'>
                        <span className='SpotLetSubHead'>Billing Address : </span>
                    </div>
                    <div className='w-100 mb-2 d-flex flex-row justify-content-between align-items-center'>
                        <span className='invoiceKeyText SpotLetContent'>Billing name : </span>
                        <span className='invoiceValueText SpotLetContent'>{payment?.billingAddress?.billingName}</span>
                    </div>
                    {payment?.billingAddress?.gstNumber && (
                        <div className='w-100 mb-2 d-flex flex-row justify-content-between align-items-center'>
                            <span className='invoiceKeyText SpotLetContent'>Gst Number : </span>
                            <span className='invoiceValueText SpotLetContent'>{payment?.billingAddress?.gstNumber}</span>
                        </div>
                    )}
                    <div className='w-100 mb-2 d-flex flex-row justify-content-between align-items-center'>
                        <span className='invoiceKeyText SpotLetContent'>Address : </span>
                        <span className='invoiceValueText SpotLetContent'>{payment?.billingAddress?.address}</span>
                    </div>
                    <div className='w-100 mb-2 d-flex flex-row justify-content-between align-items-center'>
                        <span className='invoiceKeyText SpotLetContent'>City : </span>
                        <span className='invoiceValueText SpotLetContent'>{payment?.billingAddress?.city}</span>
                    </div>
                    <div className='w-100 mb-2 d-flex flex-row justify-content-between align-items-center'>
                        <span className='invoiceKeyText SpotLetContent'>State : </span>
                        <span className='invoiceValueText SpotLetContent'>{payment?.billingAddress?.state}</span>
                    </div>
                    <div className='w-100 mb-2 d-flex flex-row justify-content-between align-items-center'>
                        <span className='invoiceKeyText SpotLetContent'>Pincode : </span>
                        <span className='invoiceValueText SpotLetContent'>{payment?.billingAddress?.pincode}</span>
                    </div>
                </div> */}

                {/* new invoice start */}
                <div className="invoice">
                    <div className="invoiceheader">
                        <div className="invoicecompany-details">
                        <h1 className='SpotLetMainHead'>{data?.company?.name}</h1>
                        <p className='SpotLetContent'  >{data?.company?.address}</p>
                        <p className='SpotLetContent'  ><strong>GSTIN: {data?.company?.gstin}</strong></p>
                        </div>
                        <div className="invoice-details">
                        <h2 className='SpotLetMainHead'>Tax Invoice</h2>
                        <p className='SpotLetContent'  ><strong>Date:</strong> {data?.invoice?.date}</p>
                        <p className='SpotLetContent'  ><strong>Invoice Number:</strong> {data?.invoice?.number}</p>
                        </div>
                    </div>

                    <div className="invoiceaddresses">
                        <div className="invoicebilled-to">
                        <h3 className='invoiceheading-color'>Billed To:</h3>
                        <p className='SpotLetContent'  ><strong>{data?.billingAddress?.billingName}</strong></p>
                        <p className='SpotLetContent'  >{data?.billingAddress?.address}</p>
                        <p className='SpotLetContent'  >City: {data?.billingAddress?.city}</p>
                        <p className='SpotLetContent'  >State: {data?.billingAddress?.state}</p>
                        <p className='SpotLetContent'  >Pincode: {data?.billingAddress?.pincode}</p>
                        <p className='SpotLetContent'  ><strong>GSTIN: {data?.billingAddress?.gstNumber}</strong></p>
                        </div>
                    </div>

                    <table className="invoiceinvoice-table">
                        <thead>
                        <tr>
                            <th>Description</th>
                            <th>HSN Code</th>
                            <th>Amount</th>
                        </tr>
                        </thead>
                        <tbody>
                        {data?.items?.map((item, index) => (
                            <tr key={index}>
                            <td>{item?.description}</td>
                            <td className='text-center'>997221</td>
                            <td className='d-flex justify-content-end align-items-end'>{"₹"+item?.amount}</td>
                            </tr>
                        ))}
                        </tbody>
                    </table>
                        <div className="invoicesummary">
                        <p className='SpotLetContent'  ><strong>Total:</strong> ₹{data?.summary?.total}</p>
                        </div>
                    <div className="invoicefooter">
                        <p className='SpotLetContent'  >Make all cheques payable to: <strong>{data?.company?.name}</strong></p>
                        <hr />
                        <p className='SpotLetContent'  ><strong>Thank you for your business! </strong></p>
                        <p className='SpotLetContent'  >If you have any questions concerning this invoice, please contact us.</p>
                    </div>
                    <p className='invoicepara'>{data?.company?.address}</p>
                    <p className='invoicepara'> Phone: {data?.company?.contact.phone} | {data?.company?.contact?.email} | {data?.company?.contact?.website}</p>
                    </div>
                {/* new invoice end */}
            </div>
            {loader && <PageLoader/>}
        </div>
    )
}
export default Invoice;