export const getBaseUrl = () => {
  return `${process.env.REACT_APP_API_URL}`;
};

export const getSearchBaseUrl = () => {
  return `${process.env.REACT_APP_SEARCH_API_URL}`;
};

export const getGoogleClientId = () => {
  return `${process.env.REACT_APP_GOOGLE_CLIENT_ID}`;
};

export const getNotificationUrl = () => {
  return `${process.env.REACT_APP_NOTIFICATION_API_URL}`;
}

export const getAuthServiceUrl = () => {
  return `${process.env.REACT_APP_AUTH_SERVICE_URL}`;
}

export const getGky = () => {
  return `${process.env.REACT_APP_G_KEY}`;
}

export const RAZOR_KEY = () => {
  return `${process.env.REACT_APP_RAZORPAY_KEY}`;
}

export const getRegion = () => {
  return localStorage.getItem('region');
}
