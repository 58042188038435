import { Button, Modal } from "react-bootstrap";

const { Header, Footer, Title, Body } = Modal;
export function ActiveLisitngModel({
  handleClose,
  handleOk,
  show,
}) {

  return (
    <Modal show={show} onHide={handleClose}>
      <Header closeButton>
        <Title style={{fontSize : '16px'}}>Deactivate Account</Title>
      </Header>
      <Body className="verify-email-modal-body" style={{fontSize : '13px'}}>
        <p><b>Attention:</b> You have active listings on SpotLet!</p>
        <p>Would you like to disable your listings along with deactivating your account?</p>
      </Body>
      <Footer>
        <Button variant="secondary" onClick={handleClose} style={{fontSize : '13px'}}>
          Cancel
        </Button>
        <Button variant="primary" onClick={handleOk} style={{fontSize : '13px'}}>
          Ok
        </Button>
      </Footer>
    </Modal>
  );
}
