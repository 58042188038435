import { Header } from "../../components/NewHeader/Header"
import { Footer } from "../../components/Footer"
import { useNavigate } from "react-router-dom"
import banner1 from "../../assets/NewImages/LandingPage/lp_banner_1.png"
import banner2 from "../../assets/NewImages/LandingPage/lp_banner_2.png"
import banner3 from "../../assets/NewImages/LandingPage/lp_banner_3.png"
import banner4 from "../../assets/NewImages/LandingPage/lp_banner_4.png"
import person from "../../assets/NewImages/LandingPage/personIcon.png"
import section1 from "../../assets/NewImages/LandingPage/section1.png"
import section2 from "../../assets/NewImages/LandingPage/section2.png"
import section3 from "../../assets/NewImages/LandingPage/section3.png"
import section4 from "../../assets/NewImages/LandingPage/section4.png"
import section2Banner1 from "../../assets/NewImages/LandingPage/section2.1.png"
import section2Banner2 from "../../assets/NewImages/LandingPage/section2.2.png"
import icon01 from "../../assets/NewImages/LandingPage/icon-desired.webp"
import icon02 from "../../assets/NewImages/LandingPage/icon-find.webp";
import icon03 from "../../assets/NewImages/LandingPage/icon-book.webp";
import whyChooseUs from "../../assets/NewImages/LandingPage/landingpage-img01.webp"
import ExcitingIcon from "../../assets/NewImages/LandingPage/Exciting Spaces icon.svg";
import TransparentIcon from "../../assets/NewImages/LandingPage/Transparent prices icon.svg";
import SimplifiedIcon from "../../assets/NewImages/LandingPage/Simpified Booking icons.svg";
import safespace from "../../assets/NewImages/LandingPage/icon-safespace.svg";
import chancetosave from "../../assets/NewImages/LandingPage/icon-chancetosave.svg";
import widerange from "../../assets/NewImages/LandingPage/icon-widerange.svg";
import memorable from "../../assets/NewImages/LandingPage/icon-memorable.svg";
import './LandingPage.css'
import { useState } from "react"
import Constants from "../../utils/Helmet";
import { FindMySpotModal } from "../../components/Modal/FindMySpotModal"
import { EventPlanningSupportModal } from "../../components/Modal/EventPlanningSupportModal"
import { ConfirmationModel } from "../../components/Modal/ConfirmationModel"
import { saveFindMySpotData,saveEventPlanningRequest } from "../../apis/api"
const LandingPage = () => {
    const [showFindMySpotModal, setShowFindMySpotModal] = useState(false);
    const [showConfirmationModel, setShowConfirmationModel] = useState(false);
    const [confirmationTitle, setConfirmationTitle] = useState("");
    const [confirmationBody, setConfirmationBody] = useState("");
    const [eventPlanningModal, setEventPlanningModal] = useState(false);
    const [formData,setFormData] = useState({})
    const navigate = useNavigate()
    const onChange = (e) => {
        const {name,value} = e.target
        setFormData({
            ...formData,
            [name] : value,
        })
    }
    const handleNav = (link) => {
        navigate(link)
    }
    const onSubmit = async (e) => {
        e.preventDefault()
        try{
            console.log(formData)
            const res = await saveFindMySpotData({...formData,eventType :'Landing page lead'})
            alert('Request submitted')
            setFormData({})
        }catch(error){
            console.log(error)
            alert('Error while submitting request')
        }
    }
    return(
        <div>
            <Header/>
            <Constants/>
            <div className="lp_main">
                <div className="lp_sub_section">
                    <h1 className="SpotLetSubHead text-center" style={{fontWeight : 'normal'}}>Access <span style={{color : '#ea4335',fontWeight : '700'}}>1000+ Properties in Hyderabad</span> and enjoy finger tip feasible searches for all kinds of Events and Stays.</h1>
                    <p className="SpotLetContent text-center m-0" style={{color : '#ea4335',fontWeight : '700'}}>Begin your magical journey with SpotLet</p>
                    <h2 className="SpotLetContent text-center">1000+ Unique Venues & Locations Only On SpotLet</h2>
                    <div className="lp_banner">
                        <div className="d-flex flex-column justify-content-start align-items-start w-100">
                            <div className="lp_banner_image_section w-100">
                                <div className="lp_banner_image_section_image" style={{backgroundImage : `url(${banner1}`}}>
                                    <strong className="SpotLetSubHead">Staycation</strong>
                                    <span className="SpotLetContent">Explore Our Staycations and Start Your Relaxation Journey Today!</span>
                                    <button type="button" className="btn btn-primary" onClick={()=>handleNav('/locations/staycation//hyderabad')}>View Properties in Hyd</button>
                                </div>
                                <div className="lp_banner_image_section_image" style={{backgroundImage : `url(${banner2}`}}>
                                    <strong className="SpotLetSubHead">Personal Events</strong>
                                    <span className="SpotLetContent">Explore Our Ideal Personal Event Venues Today!</span>
                                    <button type="button" className="btn btn-primary" onClick={()=>handleNav('/locations/event//hyderabad')}>View Venues in Hyd</button>
                                </div>
                            </div>
                            <div className="lp_banner_image_section w-100">
                                <div className="lp_banner_image_section_image" style={{backgroundImage : `url(${banner3}`}}>
                                    <strong className="SpotLetSubHead">Corporate Events</strong>
                                    <span className="SpotLetContent">Explore Our Corporate Event Venues and Start Planning for Success!</span>
                                    <button type="button" className="btn btn-primary" onClick={()=>handleNav('/locations/event/corporate-event/hyderabad')}>View Venues in Hyd</button>
                                </div>
                                <div className="lp_banner_image_section_image" style={{backgroundImage : `url(${banner4}`}}>
                                    <strong className="SpotLetSubHead">Film Shooting</strong>
                                    <span className="SpotLetContent">Explore Our Locations and Book Your Perfect Film Shoot Venue!</span>
                                    <button type="button" className="btn btn-primary" onClick={()=>handleNav('/locations/film-shoot//hyderabad')}>View Properties  in Hyd</button>
                                </div>
                            </div>
                        </div>
                        <form className="lp_form_section w-100" onSubmit={onSubmit}>
                                <strong className="SpotLetMainHead">Explore Our Premium Venues and Locations Around You</strong>
                                <span className="SpotLetContent">(Signup and Get Notified Unique Venues for Your Next Event)</span>
                                <div className="w-100 mb-4">
                                    <label className="propertyPageFormLabel SpotLetContent ">Full Name{' '}<span className="required">*</span></label>
                                    <div className='propertyFormInputContainer'>
                                        <input
                                            className="propertyPageFormInput SpotLetContent"
                                            placeholder="Full Name"
                                            type='text'
                                            name='fullName'
                                            value={formData?.fullName}
                                            onChange={onChange}
                                            required
                                        />
                                        <img src={person} alt='person' className='propertyPageFormSvg'/>
                                    </div>
                                </div>
                                <div className="w-100 mb-4">
                                    <label className="propertyPageFormLabel SpotLetContent ">Mobile Number{' '}<span className="required">*</span></label>
                                    <div className='propertyFormInputContainer'>
                                        <input
                                            className="propertyPageFormInput SpotLetContent"
                                            placeholder="Mobile NUmber"
                                            type='number'
                                            name='number'
                                            value={formData?.number}
                                            onChange={onChange}
                                            required
                                        />
                                        <img src={person} alt='person' className='propertyPageFormSvg'/>
                                    </div>
                                </div>
                                <div className="w-100 mb-4">
                                    <label className="propertyPageFormLabel SpotLetContent ">Email{' '}<span className="required">*</span></label>
                                    <div className='propertyFormInputContainer'>
                                        <input
                                            className="propertyPageFormInput SpotLetContent"
                                            placeholder="Email"
                                            type='email'
                                            name='email'
                                            value={formData?.email}
                                            onChange={onChange}
                                            required
                                        />
                                        <img src={person} alt='person' className='propertyPageFormSvg'/>
                                    </div>
                                </div>
                                {/* <div className="w-100 mb-4">
                                    <label className="propertyPageFormLabel SpotLetContent ">Message</label>
                                    <textarea
                                        className="propertyPageFormTextArea text-area-preserved-whitespace"
                                        placeholder="Message"
                                        name='message'
                                        onChange={onChange}
                                        required
                                    />
                                </div> */}
                                <button type="submit" className="btn btn-primary">Submit</button>

                        </form>
                    </div>
                </div>
                <div className="lp_sub_section row">
                    <strong className="SpotLetMainHead mb-4 lh-base">SpotLet Highlights for Staycations</strong>
                    <div className="col-lg-6 m-0 p-0">
                        <img src={section1} loading="lazy" alt="image1" className="lp_section_image"/>
                    </div>
                    <div className="lp_sub_section_content_section col-lg-6 mx-0">
                        <strong className="SpotLetSubHead mb-4 lh-base">Escape Without Leaving Home: Book Your Perfect Staycation Today!</strong>
                        <span className="SpotLetContent mb-4 lh-base">Treat yourself to a refreshing break right where you are. Our exclusive staycation packages offer the ultimate in relaxation and fun, from luxurious accommodations to top-notch amenities. Don’t miss out—reserve your staycation now and transform your everyday routine into an extraordinary retreat!</span>
                        {/* <ul className="SpotLetContent mb-4 lh-base">
                            <li>All the Lorem Ipsum generators on the Internet tend to repeat</li>
                            <li>There are many variations of passages</li>
                            <li>Repeat predefined chunks as necessary</li>
                        </ul> */}
                        <span className="SpotLetContent mb-4 lh-base">Click here to book your staycation and start your relaxation journey!
                        </span>
                        <div className="aling-self-center d-flex flex-row justify-content-center align-items-center w-100">
                            <button type="button" className="btn btn-primary" onClick={()=>handleNav('/locations/staycation//hyderabad')}>Properties for Staycation</button>
                        </div>
                    </div>
                </div>
                <div className="lp_sub_section row">
                    <strong className="SpotLetMainHead mb-4 lh-base">SpotLet Highlights for Personal Events</strong>
                    <div className="col-lg-6 m-0 p-0">
                        <img src={section2} loading="lazy" alt="image1" className="lp_section_image"/>
                    </div>
                    <div className="lp_sub_section_content_section col-lg-6 mx-0">
                        <strong className="SpotLetSubHead mb-4 lh-base">Create Unforgettable Moments: Book Your Ideal Personal Event Venue Today!</strong>
                        <span className="SpotLetContent mb-4 lh-base">There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable. If you are going to use a passage of Lorem Ipsum, you need to be sure there isn't anything embarrassing hidden in the middle of text.</span>
                        {/* <ul className="SpotLetContent mb-4 lh-base">
                            <li>All the Lorem Ipsum generators on the Internet tend to repeat</li>
                            <li>There are many variations of passages</li>
                            <li>Repeat predefined chunks as necessary</li>
                        </ul> */}
                        <span className="SpotLetContent mb-4 lh-base">Click here to explore our venues and start planning your perfect event!
                        </span>
                        <div className="aling-self-center d-flex flex-row justify-content-center align-items-center w-100">
                            <button type="button" className="btn btn-primary" onClick={()=>handleNav('/locations/event//hyderabad')}>Venues for Events</button>
                        </div>
                    </div>
                </div>
                <div className="lp_sub_section row">
                    <strong className="SpotLetMainHead mb-4 lh-base">SpotLet Highlights for Corporate Events</strong>
                    <div className="col-lg-6 m-0 p-0">
                        <img src={section3} loading="lazy" alt="image1" className="lp_section_image"/>
                    </div>
                    <div className="lp_sub_section_content_section col-lg-6 mx-0">
                        <strong className="SpotLetSubHead mb-4 lh-base">Elevate Your Next Corporate Event: Reserve Your Premier Venue Today!</strong>
                        <span className="SpotLetContent mb-4 lh-base">From conferences and workshops to company celebrations and team-building events, our state-of-the-art venues are designed to impress and inspire. With flexible spaces and exceptional services, we ensure your corporate event is a success. Don’t delay—book your venue now and set the stage for a standout event!</span>
                        {/* <ul className="SpotLetContent mb-4 lh-base">
                            <li>All the Lorem Ipsum generators on the Internet tend to repeat</li>
                            <li>There are many variations of passages</li>
                            <li>Repeat predefined chunks as necessary</li>
                        </ul> */}
                        <span className="SpotLetContent mb-4 lh-base">Click here to secure your corporate event venue and start planning for success!
                        </span>
                        <div className="aling-self-center d-flex flex-row justify-content-center align-items-center w-100">
                            <button type="button" className="btn btn-primary" onClick={()=>handleNav('/locations/event/corporate-event/hyderabad')}>Venues for Corporate Events</button>
                        </div>
                    </div>
                </div>
                <div className="lp_sub_section row">
                    <strong className="SpotLetMainHead mb-4 lh-base">SpotLet Highlights for Film Shootings</strong>
                    <div className="col-lg-6 m-0 p-0">
                        <img src={section4} loading="lazy" alt="image1" className="lp_section_image"/>
                    </div>
                    <div className="lp_sub_section_content_section col-lg-6 mx-0">
                        <strong className="SpotLetSubHead mb-4 lh-base">Bring Your Vision to Life: Find the Perfect Film Shooting Location Today!</strong>
                        <span className="SpotLetContent mb-4 lh-base">Transform your creative ideas into cinematic reality with our stunning film shooting locations and properties. Whether you’re working on a blockbuster or an indie project, our diverse range of locations offers the ideal backdrop for your story. Don’t miss out—explore our properties now and set the scene for your next production!</span>
                        {/* <ul className="SpotLetContent mb-4 lh-base">
                            <li>All the Lorem Ipsum generators on the Internet tend to repeat</li>
                            <li>There are many variations of passages</li>
                            <li>Repeat predefined chunks as necessary</li>
                        </ul> */}
                        <span className="SpotLetContent mb-4 lh-base">Click here to browse our locations and book your perfect film shoot venue!

                        </span>
                        <div className="aling-self-center d-flex flex-row justify-content-center align-items-center w-100">
                            <button type="button" className="btn btn-primary" onClick={()=>handleNav('/locations/film-shoot//hyderabad')}>Properties for Film Shootings</button>
                        </div>
                    </div>
                </div>
                <div className="lp_sub_section d-flex flex-column justify-content-center align-items-center">
                    <strong className="SpotLetMainHead text-center">A SIMPLE 3-STEP BOOKING PROCESS</strong>
                    <div className="d-flex flex-row justify-content-around align-items-center flex-wrap w-100">
                    <div className="lp_simple_steps_box">
                        <figure>
                            <img loading="lazy" src={icon01} alt='Search your Desired Property'/>
                        </figure>
                        <strong className="SpotLetSubHead">Search your Desired Property</strong>
                        <span className="SpotLetContent text-center mb-3">Explore listings, filter by preferences, and find your perfect property match on SpotLet's platform.</span>
                    </div>
                    <div className="lp_simple_steps_box">
                        <figure>
                            <img loading="lazy" src={icon02} alt='Contact Property Owners'/>
                        </figure>
                        <strong className="SpotLetSubHead">Contact Property Owners</strong>
                        <span className="SpotLetContent text-center mb-3">Contact the host to confirm availability and understand property, amenities, and policies that match your requirements.</span>
                    </div>
                    <div className="lp_simple_steps_box">
                        <figure>
                            <img loading="lazy" src={icon03} alt='Finalize Booking'/>
                        </figure>
                        <strong className="SpotLetSubHead">Finalize Booking</strong>
                        <span className="SpotLetContent text-center mb-3">Confirm details with host to match requirements and finalize booking for a seamless experience.</span>
                    </div>
                </div>
                </div>
                <div className="lp_sub_section d-flex flex-column justify-content-center align-items-center">
                    <strong className="SpotLetMainHead">SPOTLET PROFESSIONAL SERVICES</strong>
                    <div className="d-flex flex-row justify-content-around flex-wrap w-100 mt-4">
                        <div className="lp_services_banner mb-2" style={{backgroundImage : `url(${section2Banner1})`}}>
                            <div className="lp_services_banner_section">
                                <strong className="SptoLetSubHead mb-2">SpotLet Professional Services</strong>
                                <span className="SpotLetContent mb-2">Let our expert team assist you in discovering your perfect location</span>
                                <div>
                                    <button className="btn btn-primary mb-2" type="button" onClick={() => setShowFindMySpotModal(true)}>Find My Spot</button>
                                </div>
                            </div>
                        </div>
                        <div className="lp_services_banner" style={{backgroundImage : `url(${section2Banner2})`}}>
                            <div className="lp_services_banner_section">
                                <strong className="SptoLetSubHead mb-2">Need Assistance with Your Event Planning?</strong>
                                <span className="SpotLetContent mb-2">Our experienced team is here to provide you with comprehensive event planning support.</span>
                                <div>
                                    <button className="btn btn-primary mb-2" type="button" onClick={() => setEventPlanningModal(true)}>Get Started</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="lp_sub_section d-flex flex-column justify-content-center align-items-center">
                    <strong className="SpotLetMainHead">Why Choose Us</strong>
                    <span className="content text_color mt-3 d-block d-lg-none">SpotLet offers transparency and trust</span>
                    <div className="mt-4">
                        <div className="container-fluid">
                            <div className="row">
                                <div className="lp_wcu_banner col-lg-6 m-0 p-0 d-none d-lg-block">
                                    <figure>
                                        <img loading="lazy" src={whyChooseUs} alt="Colorful Hot Air Balloons Soaring in the Sky at Skyfest" />
                                        <span className="lp_wcu_banner_text sub_text text_color_2">
                                        SpotLet offers transparency and trust
                                        </span>
                                    </figure>
                                </div>
                                <div className="col-lg-6 m-0 p-0">
                                    <div className="lp_wcu_content_section">
                                        <div className="d-flex flex-row">
                                            <div className="me-3">
                                                <img loading="lazy" className="lp_wcu_image" src={ExcitingIcon} alt='Exciting Spaces'/>
                                            </div>
                                            <div className="d-flex flex-column lp_wcu_content_section_Text">
                                                <strong className="SpotLetSubHead">Exciting Spaces</strong>
                                                <span className="SpotLetContent">Whether you’re hosting a family get-together, planning a corporate event or starting an OTT series, we have the perfect spaces for you to uncover!</span>
                                            </div>
                                        </div>
                                        <div className="d-flex flex-row">
                                            <div className="me-3">
                                                <img loading="lazy" className="lp_wcu_image" src={TransparentIcon} alt='Transparent Prices'/>
                                            </div>
                                            <div className="d-flex flex-column lp_wcu_content_section_Text">
                                                <strong className="SpotLetSubHead">Transparent Prices</strong>
                                                <span className="SpotLetContent">We assure you of no hidden fees in all our properties. Pay for what you need and find the most cost-effective spaces for your requirements.</span>
                                            </div>
                                        </div>
                                        <div className="d-flex flex-row">
                                            <div className="me-3">
                                                <img loading="lazy" className="lp_wcu_image" src={SimplifiedIcon} alt='Simplified Bookings'/>
                                            </div>
                                            <div className="d-flex flex-column lp_wcu_content_section_Text">
                                                <strong className="SpotLetSubHead">Simplified Bookings</strong>
                                                <span className="SpotLetContent">Bid goodbye to long messy contracts and legal hassles. Our smooth and simplified booking can be made on the go, within a few minutes!</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="lp_sub_section d-flex flex-column justify-content-center align-items-center">
                    <strong className="SpotLetMainHead">SPOTLET PROVIDES</strong>
                    <div className="d-flex flex-row justify-content-around align-items-center flex-wrap w-100 mt-4">
                        <div className="lp_provides_section mb-2">
                            <figure>
                                <img src={safespace} alt='A Safe Space'/>
                            </figure>
                            <strong className="SpotLetContent">A Safe Space</strong>
                            <span className="SpotLetContent">We do our background research and ensure that all the properties on our portal are safe for usage amongst crew members, teams, families and friends.</span>
                        </div>
                        <div className="lp_provides_section mb-2">
                            <figure>
                                <img src={chancetosave} alt='A Wide Range of Options'/>
                            </figure>
                            <strong className="SpotLetContent">A Wide Range of Options</strong>
                            <span className="SpotLetContent">We invite you to explore our diverse locations for all kinds of shooting, events and celebrations. You’ll always be spoilt for choice!</span>
                        </div>
                        <div className="lp_provides_section mb-2">
                            <figure>
                                <img src={widerange} alt='Transparency and Ease'/>
                            </figure>
                            <strong className="SpotLetContent">Transparency and Ease</strong>
                            <span className="SpotLetContent">SpotLet facilitates direct communication between property owners and customers, making the booking process straightforward and eliminating the need for intermediaries.</span>
                        </div>
                        <div className="lp_provides_section mb-2">
                            <figure>
                                <img src={memorable} alt='A Safe Space'/>
                            </figure>
                            <strong className="SpotLetContent">A Memorable Experience</strong>
                            <span className="SpotLetContent">We promise our customers a smooth, satisfactory and memorable experience during the booking, and our hosts ensure you love your location every time!</span>
                        </div>
                    </div>
                </div>
            </div>
            <Footer/>
            {showFindMySpotModal &&
                <FindMySpotModal
                    show={showFindMySpotModal}
                    handleClose={() => setShowFindMySpotModal(false)}
                    handleOk={async (data) => {
                    try {
                        const res = await saveFindMySpotData(data)
                        setConfirmationTitle("Info");
                        setConfirmationBody(res?.data?.message);
                        setShowConfirmationModel(true);
                        setShowFindMySpotModal(false);
                    } catch (error) {
                        console.log(error);
                        setConfirmationTitle("Error");
                        setConfirmationBody(error?.response?.data?.message);
                        setShowConfirmationModel(true);
                    }
                    }}
                />
                }
        
                {eventPlanningModal &&
                <EventPlanningSupportModal
                    show={eventPlanningModal}
                    handleClose={() => setEventPlanningModal(false)}
                    handleOk={async (data) => {
                    try {
                        const res = await saveEventPlanningRequest(data)
                        setConfirmationTitle("Info");
                        setConfirmationBody(res?.data?.message);
                        setShowConfirmationModel(true);
                        setEventPlanningModal(false);
                    } catch (error) {
                        console.log(error);
                        setConfirmationTitle("Error");
                        setConfirmationBody(error?.response?.data?.message);
                        setShowConfirmationModel(true);
                    }
                    }}
                />
                }
        
                {showConfirmationModel &&
                <ConfirmationModel
                    show={showConfirmationModel}
                    handleClose={() => setShowConfirmationModel(false)}
                    handleOk={() => setShowConfirmationModel(false)}
                    title={confirmationTitle}
                    body={confirmationBody}
                />
                }
        </div>
    )
}
export default LandingPage;