import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';

export function DescriptionModal({show,onHide,title,body}) {
  return (
    <Modal
    show={show}
    onHide={onHide}
    keyboard={false}
    size="md"
    aria-labelledby="contained-modal-title-vcenter"
    centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter" className='SpotLetMainHead'>
          {title}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className='SpotLetContent text-area-preserved-whitespace'  style={{padding:"20px", fontWeight : "normal"}}>
        {body}
      </Modal.Body>
    </Modal>
  );
}