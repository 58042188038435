import React, { useState, useRef, useEffect } from 'react';
import {  Modal  } from "react-bootstrap";
import { useAuthStore } from '../../store/auth';
//apis
import { applyJob, uploadResume } from "../../apis/api"
import {ConfirmationModel} from "./ConfirmationModel"

const { Header, Title, Body } = Modal;
import {
    Box,
    Typography,
  } from "@mui/material";

import { Formik } from "formik";
import * as yup from "yup";
import { Form } from "react-bootstrap"; 


// const schema = yup.object().shape({
//     position: yup.string().required('Position is required'),
//     name: yup.string().required('Name is required'),
//     qualification: yup.string().required('Qualification is required'),
//     phoneNumber: yup.string().required('Mobile number is required')
//       .min(10, 'Mobile number must be 10 digits')
//       .max(10, 'Mobile number must be 10 digits'),
//     altNumber: yup.string()
//       .min(10, 'Mobile number must be 10 digits')
//       .max(10, 'Mobile number must be 10 digits'),
//     email: yup.string().email('Invalid email').required('Email Address is required'),
//     experience: yup.string().required('Experience is required'),
//     relExperience: yup.string().required('Relevant Experience is required'),
//     currentCtc: yup.string().required('Currect Ctc is required'),
//     expCtc: yup.string().required('Expected Ctc is required'),
//     skils: yup.string().required('Skils is required'),
//   });

  const formInitialValues = {
    position: '',
    name: "",
    qualification: "",
    phoneNumber: "",
    altNumber: "",
    email: "",
    experience: "",
    relExperience: "",
    currentCtc: "",
    expCtc: "",
    skils: "",
  }

const JobApplyModal = ({ handleClose, show, title, job, pos }) =>  {

  const [initialValues, setInitialValues] = useState(formInitialValues);
  const { userProfile } = useAuthStore();
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false)
  const [loader, setLoader] = useState(false)
  const [selectedJob, setSelectedJob] = useState('')
  const [formData, setFormData] = useState({});
  const fileInputRef = useRef(null);

  const [showConfirmationModel, setShowConfirmationModel] = useState(false);
  const [confirmationTitle, setConfirmationTitle] = useState("");
  const [confirmationBody, setConfirmationBody] = useState("");


  const schema = yup.object().shape({ // ? yup.string().required('Position is required') :
    position: pos !== undefined ? yup.string() : yup.string().required('Position is required') ,
    name: yup.string().required('Name is required'),
    qualification: yup.string().required('Qualification is required'),
    phoneNumber: yup.string().required('Mobile number is required')
      .min(10, 'Mobile number must be 10 digits')
      .max(10, 'Mobile number must be 10 digits'),
    altNumber: yup.string()
      .min(10, 'Mobile number must be 10 digits')
      .max(10, 'Mobile number must be 10 digits'),
    email: yup.string().email('Invalid email').required('Email Address is required'),
    experience: yup.string().required('Experience is required'),
    relExperience: yup.string().required('Relevant Experience is required'),
    currentCtc: yup.string().required('Currect Ctc is required'),
    expCtc: yup.string().required('Expected Ctc is required'),
    skils: yup.string().required('Skils is required'),
  });

  const handleUploadResume = async (e) => {
    try {
      if (e.target.files[0].size > 2097152) { //accepts upto 2MB.
        setShowConfirmationModel(true);
        setConfirmationBody("File is too big!");
        // alert("File is too big!")
      };
      let resume = new FormData();
      resume = { "resume": e.target.files[0] }
      const response = await uploadResume(resume);
      setFormData({ ...formData, resume: { url: response.data.url, } });
    } catch (error) {
      console.log(error);
    }
  };

  const onChange = (e) => {
    const { name, value } = e.target
    setFormData({ ...formData, [name]: value })
  }

const submitForm = async (formValues) => {
    // if (!userProfile) {
    //   return navigate('/login')
    // }

    setLoading(true)
    try {
        setLoader(true)
        const data = { ...formValues,  ...formData }
        const res = await applyJob(data, selectedJob, userProfile?._id)
        setOpen(false)
        setLoading(false)
        setLoader(false)
        setFormData({})
        setShowConfirmationModel(true);
        setConfirmationBody(res?.data);
        // alert(res.data)
        location.reload()
    } catch (err) {
        console.log(err)
        setOpen(false)
        setLoading(false)
        setLoader(false)
        setFormData({})
        setShowConfirmationModel(true);
        setConfirmationBody(err?.response?.data?.message);
        // alert(err)
    }
    }

    useEffect(() => {
        if(pos) setFormData({
            ...formData,
            "position" : pos,
        })
        if (job)  setSelectedJob(job);
  
    },[pos, job] )

  return (
    <>
    <Modal show={show} onHide={handleClose} centered size = "lg">
      <Header closeButton>
        <Title  className="h5" >{title}</Title>
      </Header>
      <Body className="verify-email-modal-body" style={{fontSize : '13px'}}>
      <Box className="form-auto-scroll" sx={{ height: 550, overflowY: 'scroll' }}>
                  <Formik
                    validationSchema={schema}
                    onSubmit={submitForm}
                    initialValues={initialValues}
                  >
                    {({
                      handleSubmit,
                      handleChange,
                      handleBlur,
                      touched,
                      isValid,
                      errors,
                    }) => (
                      <Form noValidate onSubmit={handleSubmit} autoComplete="off" className='modal-form' >
                        <Box className="form-box">
                          <div className="two-column">
                            <Typography className="applyInput" variant="body1">Position</Typography>
                            <Form.Control
                              type="text"
                              value={formData?.position}
                              name='position'
                              disabled={selectedJob}
                              onChange={(e) => handleChange(e)}
                              isInvalid={ selectedJob ? false  : (!!touched.position && !!errors.position)}
                            />
                            <Form.Control.Feedback
                              type="invalid"
                              className="text-capitalize"
                            >
                              {errors.position}
                            </Form.Control.Feedback>
                          </div>
                        </Box>
                        <Box className="form-box">
                          <div className="two-column">
                            <Typography className="applyInput" variant="body1">Name<span className="required">*</span></Typography>
                            <Form.Control
                              type="text"
                              value={formData?.name}
                              name='name'
                              onChange={(e) => handleChange(e)}
                              isInvalid={!!touched.name && !!errors.name}
                            />
                            <Form.Control.Feedback
                              type="invalid"
                              className="text-capitalize"
                            >
                              {errors.name}
                            </Form.Control.Feedback>
                          </div>
                          <div className="two-column">
                            <Typography className="applyInput" variant="body1">Email Address<span className="required">*</span></Typography>
                            <Form.Control
                              type="text"
                              value={formData?.email}
                              name='email'
                              onChange={(e) => handleChange(e)}
                              isInvalid={!!touched.email && !!errors.email}
                            />
                            <Form.Control.Feedback
                              type="invalid"
                              className="text-capitalize"
                            >
                              {errors.email}
                            </Form.Control.Feedback>
                          </div>
                        </Box>
                        <Box className="form-box">
                          <div className="two-column">
                            <Typography className="applyInput" variant="body1" >Phone number<span className="required">*</span></Typography>
                            <Form.Control
                              type="number"
                              value={formData?.phoneNumber}
                              name='phoneNumber'
                              onChange={(e) => handleChange(e)}
                              isInvalid={!!touched.phoneNumber && !!errors.phoneNumber}
                            />
                            <Form.Control.Feedback
                              type="invalid"
                              className="text-capitalize"
                            >
                              {errors.phoneNumber}
                            </Form.Control.Feedback>
                          </div>
                          <div className="two-column">
                            <Typography className="applyInput" variant="body1">Alternate number</Typography>
                            <Form.Control
                              type="number"
                              value={formData?.altNumber}
                              name='altNumber'
                              onChange={(e) => handleChange(e)}
                              isInvalid={!!errors.altNumber}
                            />
                            <Form.Control.Feedback
                              type="invalid"
                              className="text-capitalize"
                            >
                              {errors.altNumber}
                            </Form.Control.Feedback>
                          </div>
                        </Box>
                        <Box className="form-box">
                          <div className="two-column">
                            <Typography className="applyInput" variant="body1">Qualification<span className="required">*</span></Typography>
                            <Form.Control
                              type="text"
                              value={formData?.posqualificationition}
                              name='qualification'
                              onChange={(e) => handleChange(e)}
                              isInvalid={!!touched.qualification && !!errors.qualification}
                            />
                            <Form.Control.Feedback
                              type="invalid"
                              className="text-capitalize"
                            >
                              {errors.qualification}
                            </Form.Control.Feedback>
                          </div>
                          <div className="two-column">
                            <Typography className="applyInput" variant="body1">IT experience<span className="required">*</span></Typography>
                            <Form.Control
                              type="number"
                              value={formData?.experience}
                              name='experience'
                              onChange={(e) => handleChange(e)}
                              isInvalid={!!touched.experience && !!errors.experience}
                            />
                            <Form.Control.Feedback
                              type="invalid"
                              className="text-capitalize"
                            >
                              {errors.experience}
                            </Form.Control.Feedback>
                          </div>
                        </Box>
                        <Box className="form-box">
                          <div className="two-column">
                            <Typography className="applyInput" variant="body1">
                              Relevant experience<span className="required">*</span>
                            </Typography>
                            <Form.Control
                              type="number"
                              value={formData?.relExperience}
                              name='relExperience'
                              onChange={(e) => handleChange(e)}
                              isInvalid={!!touched.relExperience && !!errors.relExperience}
                            />
                            <Form.Control.Feedback
                              type="invalid"
                              className="text-capitalize"
                            >
                              {errors.relExperience}
                            </Form.Control.Feedback>
                          </div>
                          <div className="two-column">
                            <Typography className="applyInput" variant="body1">Current CTC<span className="required">*</span></Typography>
                            <Form.Control
                              type="number"
                              value={formData?.currentCtc}
                              name='currentCtc'
                              onChange={(e) => handleChange(e)}
                              isInvalid={!!touched.currentCtc && !!errors.currentCtc}
                            />
                            <Form.Control.Feedback
                              type="invalid"
                              className="text-capitalize"
                            >
                              {errors.currentCtc}
                            </Form.Control.Feedback>
                          </div>
                        </Box>
                        <Box className="form-box">
                          <div className="two-column">
                            <Typography className="applyInput" variant="body1">Expected CTC<span className="required">*</span></Typography>
                            <Form.Control
                              type="number"
                              value={formData?.expCtc}
                              name='expCtc'
                              onChange={(e) => handleChange(e)}
                              isInvalid={!!touched.expCtc && !!errors.expCtc}
                            />
                            <Form.Control.Feedback
                              type="invalid"
                              className="text-capitalize"
                            >
                              {errors.expCtc}
                            </Form.Control.Feedback>
                          </div>
                          <div className="two-column">
                            <Typography className="applyInput" variant="body1">Key skills<span className="required">*</span></Typography>
                            <Form.Control
                              type="text"
                              value={formData?.skils}
                              name='skils'
                              onChange={(e) => handleChange(e)}
                              isInvalid={!!touched.skils && !!errors.skils}
                            />
                            <Form.Control.Feedback
                              type="invalid"
                              className="text-capitalize"
                            >
                              {errors.skils}
                            </Form.Control.Feedback>
                          </div>
                        </Box>
                        <Box className="form-box">
                          <div className="one-column">
                            <Typography className="applyInput" variant="body1">Profile Summary</Typography>
                            <Form.Control
                              type="textarea"
                              className="textarea"
                              value={formData?.summary}
                              name='summary'
                              onChange={(e) => handleChange(e)}
                            />
                          </div>
                        </Box>
                        <Box className="form-box">
                          <div className="one-column">
                            <Typography className="applyInput" variant="body1">{formData?.resume ? 'Uploaded' : 'Upload Resume'}</Typography>
                            <Box className="applyFileInputBox">
                              <input
                                onChange={handleUploadResume}
                                ref={fileInputRef}
                                type="file"
                                accept=".xlsx,.xls,image/*,.doc, .docx,.ppt, .pptx,.txt,.pdf"
                                className="hidden"
                                name='resume'
                              // disabled = {formData?.resume ? true : false}
                              />
                              <svg onClick={() => fileInputRef.current.click()} className='pointer' xmlns="http://www.w3.org/2000/svg" width="26" height="26" viewBox="0 0 26 26" fill="none">
                                <g clip-path="url(#clip0_2182_5478)">
                                  <path d="M20.9625 10.8766C20.2258 7.13909 16.9433 4.33325 13 4.33325C9.86917 4.33325 7.15 6.10992 5.79583 8.70992C2.535 9.05658 0 11.8191 0 15.1666C0 18.7524 2.91417 21.6666 6.5 21.6666H20.5833C23.5733 21.6666 26 19.2399 26 16.2499C26 13.3899 23.7792 11.0716 20.9625 10.8766ZM20.5833 19.4999H6.5C4.10583 19.4999 2.16667 17.5608 2.16667 15.1666C2.16667 12.9458 3.82417 11.0933 6.02333 10.8658L7.1825 10.7466L7.72417 9.71742C8.75333 7.73492 10.7683 6.49992 13 6.49992C15.8383 6.49992 18.2867 8.51492 18.8392 11.2991L19.1642 12.9241L20.8217 13.0433C22.5117 13.1516 23.8333 14.5708 23.8333 16.2499C23.8333 18.0374 22.3708 19.4999 20.5833 19.4999ZM8.66667 14.0833H11.4292V17.3333H14.5708V14.0833H17.3333L13 9.74992L8.66667 14.0833Z" fill="#212121" />
                                </g>
                                <defs>
                                  <clipPath id="clip0_2182_5478">
                                    <rect width="26" height="26" fill="white" />
                                  </clipPath>
                                </defs>
                              </svg>
                              <Typography onClick={() => fileInputRef.current.click()} className="dragText pointer">Drag & Drop</Typography>
                              <Typography onClick={() => fileInputRef.current.click()} my={1} className="bottomRedTex pointert">Supported Formats: doc, docx, rtf, PDF upto 2MB</Typography>
                            </Box>
                          </div>
                        </Box>
                        <Box className="form-box">
                          <div style={{ display: "flex", justifyContent: "end" }} className="one-column">
                            <button
                              className="btn-apply"
                              variant="contained"
                              size="large"
                              type='submit'
                              disabled={loading}
                            >
                              Apply Now
                            </button>
                          </div>
                        </Box>
                      </Form>
                    )}
                  </Formik>
                </Box>
      </Body>
    </Modal>
    {showConfirmationModel && (
              <ConfirmationModel
                show={showConfirmationModel}
                handleClose={() => setShowConfirmationModel(false)}
                handleOk={() => setShowConfirmationModel(false)}
                title={"Careers"}
                body={confirmationBody}
              />
            )}
    </>
  );
}


export default JobApplyModal