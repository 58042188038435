import { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
const { Header, Footer, Title, Body } = Modal;
export const AddNewTagModal = ({
    handleClose,
    handleOk,
    show,
    title,
    body,
    data,
    type,
    handleSubmit
}) => {

    const [newTag, setNewTag] = useState('')
    const [tagExists, setTagExists] = useState(false)

    const handleOnFormSubmit = (e) => {
        e.preventDefault()
        handleSubmit({name : newTag.trim(), type}, tagExists)
    }

    const checkTagExists = (value) => {
        const reqArray = data?.filter((each) => each?.name === value.trim() )
        if (reqArray?.length > 0) {
            setTagExists(true)
        }else {
            setTagExists(false)
        }
    }


    return (
        <Modal show={show} onHide={handleClose}>
            <Header closeButton>
                <Title>Add New Tag</Title>
            </Header>
            <Body >
                <form onSubmit={handleOnFormSubmit} className="mt-2">
                    <div className="fv-row mb-2">
                            <label className="form-label mb-2">Tag Type {" "} <span className="required">*</span></label><br/>
                            <select
                                className='form-select form-select-solid'
                                data-kt-select2='true'
                                data-placeholder='Select option'
                                data-allow-clear='true'
                                data-kt-user-table-filter='role'
                                data-hide-search='true'
                                // onChange={(e) => setNewTagType(e?.target?.value) }
                                value={type}
                                name = "type"
                                required
                                disabled
                            >
                                <option selected hidden value = "">Select Type</option>
                                <option value = "film"> FILM / TV SHOOTINGS</option>
                                <option value= "corporateEvent"> CORPORATE EVENTS </option>
                                <option value= "individualEvent"> INDIVIDUAL EVENTS </option>
                                <option value="stay"> HOMESTAYS </option>
                                <option value="photoshoot"> PHOTOSHOOT </option>
                            </select>
                    </div>
                    <div className="d-flex flex-column">
                        <label className="form-label">Tag Name {' '}<span className="required">*</span></label>
                        <Autocomplete
                            className="mb-2"
                            value={newTag}
                            // disableClearable={true}
                            onChange={(event, newValue) => {
                                if (newValue) {
                                    setNewTag(newValue)
                                    setTagExists(true)
                                }
                            }}
                            onInputChange={(event, newInputValue) => {
                                setNewTag(newInputValue)
                                // setTagExists(false)
                                checkTagExists(newInputValue)
                            }}
                            freeSolo
                            options={data.map((option) => option.name)}
                            renderInput={(params) => <TextField {...params} />}
                            />
                    </div>
                    <div className="d-flex flex-row justify-content-between align-items-center">
                        <button type="reset" className="btn btn-secondary" onClick={handleClose} title="Cancel adding tags">Cancel</button>
                        <button type="submit" className="btn btn-primary" title="Submit new tag">Submit</button>
                    </div>
                </form>
            </Body>
        </Modal>
    );
}