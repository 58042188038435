import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';

// Components
import SubTypeOfProperty from './SubTypeOfProperty';
import  SubPropertyAmenities from "./SubPropertyAmenities";
import  SubPropertyRoomsSpaces from "./SubPropertyRoomsSpaces";
import  SubPropertyTimings from "./SubPropertyTimings";
import  SubPropertyGallery from "./SubPropertyGallery";

export const SubPropertyViewModal = ({show, data, onHide, title}) => {

  return (
    <Modal
      size="xl"
      show = {show}
      onHide={onHide}
      aria-labelledby="contained-modal-title-vcenter"
      centered
      // style={{maxHeight:"500px"}}
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          SubProperty- {title}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className='subPropertyModalBody' >  
        {/*subproperty type of property start */}
        <SubTypeOfProperty image={data?.images?.[data?.basic_info?.property_name]?.[1]?.image} type={data?.location_type} data={data?.basic_info} />
        {/*subproperty type of property End */}
        {/*subproperty amenities start */}
        <SubPropertyAmenities image={data?.images?.[data?.basic_info?.property_name]?.[2]?.image} data={data?.amenities} defaultAmenities = {data?.defaultAmenities}/>
        {/*subproperty amenities end */}
        {/*subproperty roomsSpaces start */}
        <SubPropertyRoomsSpaces images = {data?.images} data={data?.features}/>
        {/*subproperty roomsSpaces end */}
        {/*subproperty Timings start */}
        <SubPropertyTimings timings = {data?.pricing} data={data?.pricing}/>
        {/*subproperty Timings end */}
        {/* subproperty Gallery start */}
        <SubPropertyGallery locationId={data?.basic_info?.property_name} data={data?.images}/>
          {/*subproperty gallery end  */}
      </Modal.Body>
    </Modal>
  );
}