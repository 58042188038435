import { useState,useEffect} from "react";
import Accordion from 'react-bootstrap/Accordion';
import info from "../../../assets/NewImages/BecomeAHost/i.svg";
import fileUploadIcon from "../../../assets/NewImages/BecomeAHost/file-upload.svg";
import { PageLoader } from "../../PageLoader";
import SortableList, { SortableItem } from 'react-easy-sort'
import arrayMove from 'array-move'
import { ImageView } from '../../Modal/ImageView';
import imageCompression from "browser-image-compression";
import { ConfirmationModel } from '../../Modal/ConfirmationModel';
import { uploadLocationPics, deleteFiles } from "../../../apis/api";
import "../ListYourSpace"

// for resize & watermark
import Resizer from "react-image-file-resizer";
import  watermark  from "watermarkjs";
import logo from "../../../assets/NewImages/watermark/Spotlet_V1_Watermark4.webp"

function urltoFile(url, filename, mimeType) {
    return fetch(url)
      .then(function (res) {
        return res.arrayBuffer();
      })
      .then(function (buf) {
        return new File([buf], Date.now() + filename, { type: mimeType });
      });
  }

const FILE_TYPES = ["image/png", "image/jpeg", "image/jpg"] 

const SubPropertyGallery = ({data, getSaved, propertyId, getBack}) => {
    const [locationId, setLocationId] = useState(''); // data?.basic_info?.property_name
    const [noOfFiles, setNoOfFiles] = useState(4);
    const [noOfSubFiles, setNoOfSubFiles] = useState(2);
    const [loaded, setLoaded] = useState(false);
    const [formData,setFormData] = useState({});
    const [originalImages,setOriginalImages] = useState({})
    const [imageData, setImageData] = useState();
    //model
    const [showConfirmationModel, setShowConfirmationModel] = useState(false);
    const [confirmationTitle, setConfirmationTitle] = useState("");
    const [confirmationBody, setConfirmationBody] = useState("");
    const [showImageModel, setShowImageModel] = useState(false);

    const [showAlertConfirmationModel, setShowAlertConfirmationModel] = useState(false);
    const [alertConfirmationTitle, setAlertConfirmationTitle] = useState('Gallery');
    const [alertConfirmationBody, setAlertConfirmationBody] = useState("");
    //delete
    const [deleteFileName, setDeleteFileName] = useState("");
    const [deleteFileIndex, setDeleteFileIndex] = useState("");
    const [subName,setSubName] = useState('')
    const [deleteImg, setDeleteImg] = useState(false)
    //adding images start
    const uplaodFileToS3Bucket = async (item,subName, type) => {
        // const imageObj = {
        //     item,
        //     imageSrc: URL.createObjectURL(item),
        //     name: item.name,
        // };
        const file = await urltoFile(
            item.imageSrc,
            item.name,
            "text/plain"
        );
        const mainImages = new FormData();
        mainImages.append("pic", file);
        mainImages.append("fileName", item.name);
        mainImages.append("locationId", propertyId);
        mainImages.append("type",type);
        try {
            const response = await uploadLocationPics(mainImages);
            const uploadedImage = {
                name : item.name,
                image : response.url,
                title : '',
            }
            if(type === 'Images'){
                setFormData((prevFiles) => {
                    return {
                        ...prevFiles,
                        [subName] : [
                            ...prevFiles[subName],
                            uploadedImage
                        ]
                    }
                });
            }
            if(type === 'Original_images'){
                setOriginalImages((prevFiles) => {
                        return {
                        ...prevFiles,
                        [subName] : [
                            ...prevFiles[subName],
                            uploadedImage
                        ]
                    }
                });
            }
            setLoaded(false)
          } catch (e) {
            console.log(e)
          }
    }
    //Watermark start
    const addWatermark = (file, subName) => {
        const img = new Image();
        img.src = URL.createObjectURL(file);
        img.onload = () => {
            const getx = () => {
                return img.width - 650
            }
            const gety = () => {
                return img.height - 300
            }
            watermark([file, logo])
                .image(watermark.image.atPos(getx, gety))
                .then(async (watermarkedImage) => {
                const imageObj = {
                file,
                imageSource : URL.createObjectURL(file),
                imageSrc : watermarkedImage.src,
                name: file.name,
                };

                uplaodFileToS3Bucket(imageObj,subName,'Images');
                });
            };
        }
    // Watermark end
    
    // Resizer start 
        const imageResizer = (compressedObj, subName) => {
            return(
                Resizer.imageFileResizer(
                    compressedObj, 
                    1920,
                    1080, 
                    "JPEG",
                    100, 
                    0, 
                    (resizedImage) => {
                        addWatermark(resizedImage, subName); 
                    }, 
                    "file", 
                    1920, 
                    1080 
                )
            )
        }
    // resizer end 

    const onUploadFile = (event,subName,FeatureImage) => {
        if(FeatureImage && event.target.files.length + formData[subName]?.length > noOfSubFiles){
            setAlertConfirmationBody(`You can Upload only ${noOfSubFiles} Images`)
            setShowAlertConfirmationModel(true)
            return null
        }
        if(event.target.files.length + formData[subName]?.length > noOfFiles){
            setAlertConfirmationBody(`You can Upload only ${noOfFiles} Images`)
            setShowAlertConfirmationModel(true)
            return null
        }

        //removing dublicate uploads start
        const selectedFiles = [...event.target.files]
        const filterFiles = [];
        const uploadedFiles = [];
        formData[subName].map((file,i) => uploadedFiles.push(file.name.split('.')[0]))
        let notSupported = false
        selectedFiles.map((file) => {
            if(!uploadedFiles.includes(file.name.split('.')[0]) && FILE_TYPES.includes(file.type)){
                filterFiles.push(file)
            }else{
                setAlertConfirmationBody(`Only JPEG and PNG files allowed for upload.`)
                setShowAlertConfirmationModel(true)
                notSupported = true
            }
        })
        if(notSupported) return null
        //removing dublicate uploads end
        if (!filterFiles) setLoaded(false); 
        if (filterFiles && filterFiles.length > 0) {
            setLoaded(true)
            const options = {
                maxSizeMB: 2,
                maxWidthOrHeight: 800,
                useWebWorker: true,
                fileType: 'image/webp',
              };
              //saving compressed & watermark images start
              filterFiles.forEach((file) => {
                if (options.maxSizeMB < file.size / 1000000) {
                    imageCompression(file, options).then(compressedObj => {
                        imageResizer(compressedObj,subName,'Images')
                    })
                }else{
                    imageResizer(file,subName,'Images')
                }
            })
            //saving compressed & watermark images end

            //saving original images start
            filterFiles.forEach((file) => {
                const imageObj = {
                        file,
                        imageSrc: URL.createObjectURL(file),
                        name: file.name,
                    };
                uplaodFileToS3Bucket(imageObj,subName,'Original_images');
            })
            //saving original images end
        }
    }
    //adding images end

    //delete image section start
    const deleteImage = async (fileName, index,subName) => {
        setConfirmationTitle("Confirmation");
        setConfirmationBody("Do you want to delete the image ?");
        setShowConfirmationModel(true);
        setDeleteImg(true)
        setDeleteFileName(fileName);
        setDeleteFileIndex(index);
        setSubName(subName)
    }
    const handleConfirmOk = async () => {
        setShowConfirmationModel(false)
        if (deleteImg) {
          confirmDeleteImage(deleteFileIndex);
        }
      }
    const confirmDeleteImage = async (index) => {
        const fileToDelete = formData[subName][index];
        try {
        if (fileToDelete) {
            // await deleteFiles({
            // image: fileToDelete.url
            // });
            let tempData = [...formData[subName]]
            tempData.splice(index, 1)
            setFormData((prevFiles) => {
                return{
                    ...prevFiles,
                    [subName]: tempData
                }
            })

            let tempOrgData = [...originalImages[subName]]
            tempOrgData.splice(index, 1)
            setOriginalImages((prevFiles) => {
                return{
                    ...prevFiles,
                    [subName]: tempOrgData
                }
            })
        }
        } catch (error) {
        alert(error.response.data);
        }
    };
    //delete image section end

    const onSubmit = () => {
        let error = false
        let reqFeature = ""
        for(const sub in formData){
            if(formData[sub]?.length < 1 && sub !== locationId){
                reqFeature = sub;
                error = true
                break
            }
        }
        if(formData[locationId]?.length < 2 && error){
            setAlertConfirmationBody(`Please upload minimum of 2 images in sub-property images and 2 in sub-property rooms/spaces images`)
            setShowAlertConfirmationModel(true)
            return null
        }
        if(formData[locationId]?.length < 2){
            setAlertConfirmationBody('Please upload minimum 2 images in sub-property images')
            setShowAlertConfirmationModel(true)
            return null
        }
        if (error) {
            setAlertConfirmationBody(`Please upload minimum 1 images in "${reqFeature}" images`);
            setShowAlertConfirmationModel(true);
            return null
        }
        getSaved({images: formData, original_images : originalImages})
    }

    const handleBack = () => {
        getBack();
    }

    useEffect(() => {
        if(data?.basic_info?.property_name){
            setLocationId(data?.basic_info?.property_name)
        }
        if(data?.images){
            setFormData({...data.images})
        }
        if(data?.original_images){
            setOriginalImages({...data.original_images})
        }
        const tempSubImages ={}
        const tempOrigianlSubImages ={}

        data?.features?.map((each) => {
            if(!data?.images?.[each.name]){
                tempSubImages[each.name] = []
            }
            if(!data.original_images?.[each.name]){
                tempOrigianlSubImages[each.name] = []
            }
        })
        if(!data?.images?.[data?.basic_info?.property_name]){
            tempSubImages[data?.basic_info?.property_name] = []
        }
        if(!data?.original_images?.[data?.basic_info?.property_name]){
            tempOrigianlSubImages[data?.basic_info?.property_name] = []
        }
        setFormData((prevFiles) => {
            return {
                ...prevFiles,
                ...tempSubImages,
            }
        })
        setOriginalImages((prevFiles) => {
            return {
                ...prevFiles,
                ...tempOrigianlSubImages,
            }
        })
    },[data])

    //drag and drop start
    const onSortEnd = (oldIndex, newIndex) => {
        setFormData((prevData) => {
            const sortedArrey = arrayMove(prevData[locationId], oldIndex, newIndex)
            return{
                ...formData,
                [locationId] : sortedArrey
            }
        })
        }

    const onSortEndSub = (oldIndex, newIndex,name) => {
        setFormData((prevData) => {
            const sortedArrey = arrayMove(prevData[name], oldIndex, newIndex)
            return{
                ...formData,
                [name] : sortedArrey
            }
        })
        }
    //drag and drop end

    const showImage = (image) => {
        setImageData(image);
        setShowImageModel(true);
    }

    const onClickSaveName = (i, subName, name) => {
        const imageData = formData[subName][i]
        imageData.title= name
        formData[subName].splice(i,1,imageData)
        setFormData({
            ...formData,
        })
    }

    return(
        <>
        <div className="locationtype-page container-fluid " >
            <h1 className="locationtype-head" id = "SubPropertyGallery" > Gallery {' '}
                <a
                    href="#"
                    data-bs-toggle="tooltip"
                    title="Showcase with high-quality photos; feature main areas, rooms."
                >
                    <img src={info} />
                </a>
            </h1>
        </div>
        <div className="tabCard">
            <div className="row">
                <div className="col-lg-9">
                    <Accordion defaultActiveKey="0" className="mb-3" >
                        <Accordion.Item eventKey="0">
                            <Accordion.Header> {data?.basic_info?.property_name} Images </Accordion.Header>
                            <Accordion.Body>
                                {formData?.[locationId]?.length < 1 && (
                                    <div className="upload__btn-box">
                                        <label className="upload__btn">
                                        <img src={fileUploadIcon} />
                                        <h3>Upload Your jpg or png files</h3>
                                        <p> Min. 2 images </p>
                                        <p>Max. {noOfFiles} images</p>
                                        <input
                                            type="file"
                                            multiple
                                            data-max_length="20"
                                            className="upload__inputfile"
                                            id="upload__inputfile"
                                            accept='image/png, image/jpeg, image/jpg'
                                            onChange={(e) =>onUploadFile(e,locationId,"")}
                                        />
                                        </label>
                                    </div>
                                )}
                                <SortableList onSortEnd={onSortEnd}  className="row">
                                        {formData?.[locationId]?.map((file,i) => {
                                            return(
                                                <SortableItem key={i} >
                                                    <div  className= {i === 0 ? "col-lg-3 col-sm-6 mb-2 gallery-profile-img-sec " : "col-lg-3 col-sm-6 mb-2 gallery-img-sec "} > 
                                                        <p> { i === 0 ? "Profile image" : "  " } </p>
                                                        <div onClick={() => showImage({ name: file.name, image: file.image })}>
                                                            <div className="img-bg  listSpaceImageLoading" style={{
                                                            backgroundImage: `url(${file.image})`,
                                                            backgroundRepeat: 'no-repeat'
                                                            }}>
                                                            <div className="upload__img-close"
                                                                onClick={(e) => {
                                                                deleteImage(file.name, i,locationId);
                                                                e.stopPropagation();
                                                                }}
                                                            />
                                                            </div>
                                                        </div>
                                                        <div className="gallery-img-name-sec"  >
                                                                <input
                                                                type="text"
                                                                placeholder="Add name"
                                                                className="gallery-input"
                                                                value={formData[locationId][i]?.title}
                                                                onChange = {(e) => onClickSaveName(i, locationId, e.target.value)} 
                                                                />
                                                        </div>
                                                    </div>
                                                </SortableItem>
                                            )
                                        })}
                                        {formData?.[locationId]?.length > 0 && formData[locationId]?.length < noOfFiles && (
                                        <div className="col-lg-3 col-sm-6 mb-2">
                                            <label className="upload__btn_mini" style={{marginTop:"23px"}} >
                                            <img src={fileUploadIcon} />
                                            <p>Add more</p>
                                            <input
                                                type="file"
                                                multiple
                                                data-max_length="20"
                                                className="upload__inputfile"
                                                id="upload__inputfile"
                                                accept='image/png, image/jpeg, image/jpg'
                                                onChange={(e) =>onUploadFile(e,locationId)}
                                            />
                                            </label>
                                        </div>
                                        )}
                                </SortableList>
                            </Accordion.Body>
                        </Accordion.Item>
                    </Accordion>
                    {
                        data?.features.length > 0 &&  
                        data?.features.map((each) => {
                            return(
                                <Accordion defaultActiveKey="0" className="mb-3">
                                    <Accordion.Item eventKey="0">
                                        <Accordion.Header > {each?.name} Images </Accordion.Header>
                                        <Accordion.Body>
                                            {formData?.[each.name]?.length < 1 && (
                                                <div className="upload__btn-box">
                                                    <label className="upload__btn">
                                                    <img src={fileUploadIcon} />
                                                    <h3>Upload Your jpg or png files</h3>
                                                    <p>Min. 1 images</p>
                                                    <p>Max. {noOfSubFiles} images</p>
                                                    <input
                                                        type="file"
                                                        multiple
                                                        data-max_length="20"
                                                        className="upload__inputfile"
                                                        id="upload__inputfile"
                                                        accept='image/png, image/jpeg, image/jpg'
                                                        onChange={(e) =>onUploadFile(e,each.name, "FeatureImage")}
                                                    />
                                                    </label>
                                                </div>
                                            )}
                                            <SortableList onSortEnd={(oldIndex, newIndex) => onSortEndSub(oldIndex, newIndex,each.name)} className="row">
                                                    {formData?.[each.name]?.map((file,i) => {
                                                        return(
                                                            <SortableItem key={i} >
                                                                <div  className= {i === 0 ? "col-lg-3 col-sm-6 mb-2 gallery-profile-img-sec " : "col-lg-3 col-sm-6 mb-2 gallery-img-sec "} >
                                                                    <p> { i === 0 ? "Profile image" : "  " } </p>
                                                                    <div onClick={() => showImage({ name: file.name, image: file.image })}>
                                                                        <div className="img-bg  listSpaceImageLoading" style={{
                                                                        backgroundImage: `url(${file.image})`,
                                                                        backgroundRepeat: 'no-repeat'
                                                                        }}>
                                                                        <div className="upload__img-close"
                                                                            onClick={(e) => {
                                                                            deleteImage(file.name, i,each.name,);
                                                                            e.stopPropagation();
                                                                            }}
                                                                        />
                                                                        </div>
                                                                    </div>
                                                                    <div className="gallery-img-name-sec"  >
                                                                        <input
                                                                        type="text"
                                                                        placeholder="Add name"
                                                                        className="gallery-input"
                                                                        value={formData[each?.name][i]?.title}
                                                                        onChange = {(e) => onClickSaveName(i, each?.name, e.target.value)} 
                                                                        />
                                                                    </div>
                                                                </div>
                                                            </SortableItem>
                                                        )
                                                    })}
                                                    {formData?.[each.name]?.length > 0 && formData[each.name]?.length < noOfSubFiles && (
                                                    <div className="col-lg-3 col-sm-6 mb-2">
                                                        <label className="upload__btn_mini" style={{marginTop:"23px"}} >
                                                        <img src={fileUploadIcon} />
                                                        <p>Add more</p>
                                                        <input
                                                            type="file"
                                                            multiple
                                                            data-max_length="20"
                                                            className="upload__inputfile"
                                                            id="upload__inputfile"
                                                            accept='image/png, image/jpeg, image/jpg'
                                                            onChange={(e) =>onUploadFile(e,each.name)}
                                                        />
                                                        </label>
                                                    </div>
                                                    )}
                                            </SortableList>
                                        </Accordion.Body>
                                    </Accordion.Item>
                            </Accordion>
                            )
                        } )
                    }
                </div>
            </div>
        </div>
         {/* Save Section Start */}
        <div className="row "    >
                <div className="d-flex flex-row justify-content-between" >
                    <button
                        type="button"
                        class="btn btn-outline-danger"
                        onClick={handleBack}
                    >
                        Previous
                    </button>
                    <button
                        type="button"
                        className="btn btn-primary me-3"
                        style={{width : "130px" }}
                        onClick={onSubmit}
                    >
                        Save
                    </button>
                </div>
        </div>
        {/* Save Section End */}
        {showConfirmationModel && (
        <ConfirmationModel
          show={showConfirmationModel}
          handleClose={() => { setShowConfirmationModel(false), setDeleteImg(false) }}
          handleOk={() => handleConfirmOk()}
          title={confirmationTitle}
          body={confirmationBody}
        />
        )}
        {showImageModel && (
        <ImageView
          show={showImageModel}
          handleClose={() => setShowImageModel(false)}
          handleOk={() => setShowImageModel(false)}
          imageData={imageData}
        />
        )}
        {showAlertConfirmationModel && (
        <ConfirmationModel
          show={showAlertConfirmationModel}
          handleClose={() => setShowAlertConfirmationModel(false)}
          handleOk={() => setShowAlertConfirmationModel(false)}
          title={alertConfirmationTitle}
          body={alertConfirmationBody}
        />
      )}
        {loaded && <PageLoader />}
    </>
    )
}

export default  SubPropertyGallery