import { useNavigate, useParams } from "react-router-dom";
import { useState } from "react";
import {reActivateAccount } from "../../apis/api";
import { ConfirmationModel } from '../../components/Modal/ConfirmationModel';

export const AccountActivation = () => {
    const { userId } = useParams()
    const navigate = useNavigate();
    const [showConfirmationModel, setShowConfirmationModel] = useState(false);
    const [confirmationTitle, setConfirmationTitle] = useState("");
    const [confirmationBody, setConfirmationBody] = useState("");


    const handleClick = async () => {
        try{
            if(userId){
                const res = await reActivateAccount(userId)
                setConfirmationTitle("SpotLet");
                setConfirmationBody(res?.data?.message);
                setShowConfirmationModel(true);
            }
        }catch(error){
            console.log(error)
        }
    }

    return(
        <div className="d-flex flex-column justify-content-center align-items-center" style={{marginTop : '20%'}}>
            <div className="d-flex flex-column justify-content-center align-items-center">
                <p>Activate your SpotLet account</p>
                <button type="button" className="btn btn-theme btn-active" onClick={handleClick}>click here</button>
            </div>
         {showConfirmationModel && (
            <ConfirmationModel
              show={showConfirmationModel}
              handleClose={() => setShowConfirmationModel(false) }
              handleOk={() => navigate('/')}
              title={confirmationTitle}
              body={confirmationBody}
            />
          )}
          </div>
    )
}