import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import Accordion from 'react-bootstrap/Accordion';
import { sendHostGetInTouch } from "../../apis/api";
//components
import { Header } from "../../components/NewHeader/Header";
import { Footer } from "../../components/Footer";
import HostClientTestimonials from "./ClientTestimonials/index";
import HostTestimonials from "./ClientTestimonials/HostTestimonials";
import { useAuthStore } from "../../store/auth";
import AuthenitcateModal from "../../utils/NewAuthentication/AuthenitcateModal";
import { PropertyPageInformationModal } from "../NewPropertyPage/Modals/PropertyPageInformationModal";
import PhoneInput, {isValidPhoneNumber} from 'react-phone-number-input'
import 'react-phone-number-input/style.css';
import Constants from "../../utils/Helmet";
//images
import banner from './images/hostBanner.png'
import image1 from './images/hostImage1.png'
import map from './images/hostMap.png'
import puzzle from './images/hostPuzzle.png'
import rupee from './images/hostRupee.png'
import image2 from './images/hostImage2.png'
import book from './images/hostBook.png'
import home from './images/hostHome.png'
import bill from './images/hostBill.png'
import joinbg from './images/joinbg.webp'
import image3 from './images/hostImage3_3.png'
import image4 from './images/hostImage4.webp'
import building from './images/building.svg'
import profileMan from './images/profileMan.svg'
import vectorPhone from './images/vectorPhone.svg'
import vectorMail from './images/vectorMail.svg'
import './index.css'
const FREQ = [
    {
        question : 'How do I list my property on SpotLet?',
        answer : "After registering with SpotLet, navigate to the \"List your Space\" option either in the header of the homepage or within the profile section. Choose your preferred subscription model (Free Listing, Standard, or Premium) based on the features listed in the subscription details. Fill in the details of your property, providing as much information as possible to increase bookings. Transparently set your pricing, and accept SpotLet's terms and conditions to submit your property for verification. The SpotLet team will verify the details and activate your listing within 24 to 72 working hours. Ensure to upload images of your property and specify available amenities during the listing process to enhance your property's visibility and attract potential guests.",
    },
    {
        question : 'How can I maximize my bookings on SpotLet?',
        answer : 'To optimize your bookings, ensure your property listing is detailed and attractive. Upload high-quality images showcasing your space and specify all available amenities. Transparently set your pricing and keep your availability calendar up to date. Promptly respond to booking inquiries and maintain good communication with potential guests to increase booking conversions.',
    },
    {
        question : 'What subscription plans does SpotLet offer for hosts?',
        answer : "SpotLet offers three subscription plans for hosts: Free Listing, Standard, and Premium. The Free Listing plan allows you to list your property at no cost, while the Standard and Premium plans offer additional features and benefits for a subscription fee. Choose the plan that best suits your needs and budget to maximize your property's exposure and potential earnings.",
    },
    {
        question : "How do I manage my bookings and reservations on SpotLet?",
        answer : "Once your property is listed on SpotLet, you can easily manage your bookings and reservations through your dashboard. Access your dashboard to view booking requests, confirm reservations, and communicate with guests. Keep track of your property's availability calendar and update it regularly to avoid double bookings. SpotLet provides tools and resources to streamline the booking management process and ensure a seamless experience for hosts.",
    },
    {
        question : "What support does SpotLet offer to hosts?",
        answer : "SpotLet provides comprehensive support to hosts throughout their journey on the platform. From assistance with listing optimization to resolving booking-related issues, our dedicated support team is available to help you every step of the way. Contact us via email or through our online support portal for prompt assistance and personalized guidance. We're committed to ensuring your success as a SpotLet host.",
    },
]
const readMoreQuestions = [
    {
        question : "What are the benefits of upgrading to a Standard or Premium subscription plan?",
        answer : "Upgrading to a Standard or Premium subscription plan offers additional features and benefits to enhance your property listing. These may include priority placement in search results, increased visibility to potential guests, access to advanced analytics and reporting tools, and dedicated customer support. Consider upgrading to maximize your property's exposure and increase your bookings.",
    },
    {
        question : "What should I do if I encounter any issues or challenges with my property listing or bookings?",
        answer : "If you encounter any issues or challenges with your property listing or bookings, don't hesitate to reach out to SpotLet's support team for assistance. Our team is available to address any questions, concerns, or technical issues you may encounter. We're here to help you resolve any issues promptly and ensure a smooth hosting experience.",
    },
    {
        question : "How can I attract more guests to my property on SpotLet?",
        answer : "To attract more guests to your property on SpotLet, consider optimizing your listing for search visibility by using relevant keywords, updating your property description with compelling content, and offering competitive pricing. Additionally, actively promote your listing on social media channels, engage with potential guests through messaging, and encourage positive reviews from satisfied guests to increase your property's visibility and attract more bookings.",
    },
]
const HostPage = () => {
    const navigate = useNavigate();
    const { userProfile } = useAuthStore();
    const [navTo, setNavTo] = useState('')
    const [authenticate, setAuthenticate] = useState(false)
    const [formData,setFormData] = useState({});
    const [numberError, setNumberError] = useState("");
    const [showModal,setShowModal] = useState(false);
    const [modalBody,setModalBody] = useState('');
    const [showReadMore, setShowReadMore] = useState(true)

    const handleNav = (value,type) => {
        if(userProfile){
            navigate(value)
        }else{
            setNavTo(value)
            setAuthenticate(true)
        }
    }
    const onClickSubmit = (e) => {
        e.preventDefault();
        setNumberError((prev) => '');
        
        let phoneNumberError = false;

        //check phone number validation
        if (formData?.mobileNumber === undefined) {
            phoneNumberError = true
             setNumberError("Enter Mobile Number")
        } 
        if(!phoneNumberError){
            if (formData?.mobileNumber.slice(0,4) === "+911" || !isValidPhoneNumber(formData?.mobileNumber)) {
                phoneNumberError = true
                setNumberError((prev) => "Enter a Valid Mobile Number")
            } 
        }
        //
         //if no error handle below function
         if(!phoneNumberError) {
                handleSubmit();
        }

    }
    const handleSubmit = async () => {
        try{
            const resp = await sendHostGetInTouch({...formData,userId : userProfile?._id})
            setModalBody(resp?.data?.message)
            setShowModal(true)
            const form = document.getElementById('hostPageGetInTouchForm')
            form.reset()
            setFormData({});
        }catch(error){
            console.log(error)
            setModalBody(error?.response?.data?.message)
            setShowModal(true)
        }
    }
    const onChange = (e) => {
        const {name,value} = e.target
        setFormData({
            ...formData,
            [name] : value
        })
    }
    const onClickReadMore = () => {
        setShowReadMore(!showReadMore)
    }

    return(
        <div>
            <Header/>
            <Constants/>
            <div className="hostPageMainContainer">
                <div className="hostPageBanner">
                    <img src={banner} alt='host banner image' className="hostPageBannerImage"/>
                    <div className="hostPageBannerTextContainer">
                        <div className="d-flex flex-column">
                            <span className="hostPageBannerText mb-3"><span className="hostPageBannerBoldText">List your property</span> on <span className="hostPageBannerBoldText"> SpotLet </span> for extra rental income </span>
                            <span className="hostPageBannerNormalText">Utilize your property to generate extra revenue by listing it on SpotLet's platform.</span>
                        </div>
                    </div>
                </div>
                <div className="hostContentContainer">
                    {/* Section 1 start */}
                    <div className="hostPageSectionContainer container">
                        <div className="row">
                            <div className="hostPageImageSection col-xl-6 col-lg-6">
                                <img className="hostPageBigImage" src={image1} alt="Welcome to SpotLet - Where Your Property Meets Potential!" />
                            </div>
                            <div className="hostPageContentSection  col-xl-6 col-lg-6">
                                <div className="d-flex flex-column">
                                    <span className="hostPageContentHeading mb-3 SpotLetMainHead">Welcome to SpotLet - Where Your Property Meets Potential!</span>
                                    <span className="hostPageContentNormal mb-3 SpotLetContent">Are you a property owner looking to maximize your revenue 
                                    potential? Look no further! SpotLet offers a seamless platform for property owners to showcase their spaces to a vast audience. 
                                    Join us and transform your property into a sought-after venue for film shoots, corporate events, and personal celebrations. 
                                    Whether it's a scenic mansion, a modern loft, or a cozy garden, your space can create unforgettable experiences. 
                                    Cater to diverse visitors, from travelers seeking homestays to filmmakers in need of unique locations. 
                                    SpotLet is the premier destination for renting properties, offering holistic solutions for all your rental needs. 
                                    List your property with us and start earning today!
                                    </span>
                                </div>
                                <div className="hostListYourSpaceButton">
                                    <Button
                                        onClick={() => {
                                            handleNav("/plans")
                                        }}
                                        className="readmore" variant="contained" size="large">
                                        LIST YOUR SPACE
                                    </Button>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* Section 1 end */}
                    {/* Section 2 start */}
                    <div className="hostPageSectionContainer d-flex flex-column justify-content-between align-items-center container">
                        <span className="hostPageContentHeading hostPageSectionHeading SpotLetMainHead">Become a Host in just 3 Simple Steps</span>
                        <div className="hostPageSection2BoxContainer w-100">
                            <div className="hostPagesection2Box col-xl-4">
                                <div className="hostPagesection2BoxImageContainer">
                                    <img src={map} alt="Add Location Details"/>
                                </div>
                                <span className="hostPageSection2BoxHeading SpotLetSubHead">Sign Up</span>
                                <span className="hostPageSection2BoxNormal mt-3 SpotLetContent">Create your SpotLet account in just a few clicks.</span>
                            </div>
                            <div className="hostPagesection2Box col-xl-4">
                                <div className="hostPagesection2BoxImageContainer">
                                    <img src={puzzle} alt="Complete your Features"/>
                                </div>
                                <span className="hostPageSection2BoxHeading SpotLetSubHead">List Your Property</span>
                                <span className="hostPageSection2BoxNormal mt-3 SpotLetContent"> Add details and showcase your space to potential guests.</span>
                            </div>
                            <div className="hostPagesection2Box col-xl-4">
                                <div className="hostPagesection2BoxImageContainer">
                                    <img src={rupee} alt="Set your Price"/>
                                </div>
                                <span className="hostPageSection2BoxHeading SpotLetSubHead">Start Earning</span>
                                <span className="hostPageSection2BoxNormal mt-3 SpotLetContent">Receive booking requests and earn revenue hassle-free.</span>
                            </div>
                        </div>
                    </div>
                    {/* Section 2 end */}
                    {/* Section 3 start */}
                    <div className="hostPageSectionContainer container">
                        <div className="row d-flex flex-row justify-content-between align-items-center">
                            <article className="col-xl-6 col-lg-6 p-0">
                                <span className="hostPageContentHeading SpotLetMainHead">WHY HOST WITH SPOTLET?</span>
                                <Box className="icon-content">
                                <Box className="icon">
                                    <img src={bill} />
                                </Box>
                                <Box className="content">
                                    <Typography variant="subtitle1" className="hostPageSection2BoxHeading SpotLetSubHead">Easy Listing</Typography>
                                    <Typography variant="body1 SpotLetContent">
                                    We don’t charge our customers to list their spaces on SpotLet. 
                                    The only premium charge is if you want to showcase your spaces 
                                    in the spotlight. Of course, you can always add your property for free on our portal!
                                    </Typography>
                                </Box>
                                </Box>
                                <Box className="icon-content">
                                <Box className="icon">
                                    <img src={book} />
                                </Box>
                                <Box className="content">
                                    <Typography variant="subtitle1"  className="hostPageSection2BoxHeading SpotLetSubHead">
                                    High Earning Potential
                                    </Typography>
                                    <Typography variant="body1 SpotLetContent">
                                    We’re reaching out to many new customers every day. 
                                    As a result, you can earn lots of extra income as you rent spaces for weddings, 
                                    film shoots or corporate events. Moreover, since you get more business during the downtime, 
                                    you can look forward to a prosperous future! 
                                    </Typography>
                                </Box>
                                </Box>
                                <Box className="icon-content">
                                <Box className="icon">
                                    <img src={home} />
                                </Box>
                                <Box className="content">
                                    <Typography variant="subtitle1"  className="hostPageSection2BoxHeading SpotLetSubHead">
                                    Complete Control
                                    </Typography>
                                    <Typography variant="body1 SpotLetContent">
                                    You can decide how you want to share your space with customers. 
                                    For example, you can choose to host corporate launches, film shoots over the 
                                    weekends or weddings during the season. You can select what’s best for you and 
                                    decide all the details with the renters beforehand.
                                    </Typography>
                                </Box>
                                </Box>
                            </article>
                            <figure className="col-xl-6 col-lg-6 p-0">
                                <img src={image2} />
                            </figure>
                        </div>
                    </div>
                    {/* Section 3 end */}
                    {/* Section 4 start */}
                    <div className="hostPageSectionContainer hostContentContainer">
                        {/* <span className="hostPageContentHeading">Host Testimonials</span> */}
                        {/* <Box className="testimonials-Container">
                            <HostClientTestimonials/>
                        </Box> */}
                        <HostTestimonials/>
                    </div>
                    {/* Section 4 end */}
                    {/* Section 5 start */}
                    <div className="hostPageSectionContainer">
                        <Box className="join-us">
                            <Box className="join-content">
                            <img src={joinbg} className="image-overlay" />
                            <article className="d-flex flex-column">
                                <span className="hostPageContentHeading mb-2 SpotLetMainHead" style={{color : '#ffffff'}}>
                                Start Hosting Memorable Events Today
                                </span>
                                <span className="hostPageContentNormal SpotLetContent"  style={{color : '#ffffff'}}>
                                Your property could be the canvas for someone's masterpiece or the backdrop for a successful 
                                corporate gathering. Join SpotLet and become a part of our growing network of exceptional 
                                hosts. Unlock the potential of your property now.
                                </span>
                            </article>
                            <aside>
                                <Button
                                onClick={() => {
                                    handleNav("/plans")
                                }}
                                className="readmore" variant="contained" size="large">
                                LIST YOUR SPACE
                                </Button>
                            </aside>
                            </Box>
                        </Box>
                    </div>
                    {/* Section 5 end */}
                    {/* Section 6 start */}
                    <div className="hostPageSectionContainer container">
                        <div className="row p-0">
                            <div className="hostPageImageSection col-xl-6 col-lg-6 justify-content-start p-0">
                                <img className="hostPageBigImage" src={image3} alt="Frequently Asked Questions" />
                            </div>
                            <div className="hostPageContentSection  justify-content-start col-xl-6 col-lg-6 p-0">
                            <span className="hostPageContentHeading SpotLetMainHead mb-3">Frequently Asked Questions</span>
                            <Accordion className="hostPageAccordion">
                                {FREQ?.map((item,i) => {
                                    return(
                                        <Accordion.Item eventKey={i} className="hostPageAccordionItem">
                                            <Accordion.Header>
                                                <div className="d-flex align-items-center">
                                                    <div className="hostPageAccordionItemHeadNumber">{i+1}</div>
                                                    <span className="hostPageAccordionItemHeadText SpotLetSubHead">{item?.question}</span>
                                                </div>
                                            </Accordion.Header>
                                            <Accordion.Body style={{color : '#737373'}}>{item?.answer}</Accordion.Body>
                                        </Accordion.Item>
                                    )
                                })}
                                {
                                    !showReadMore &&  readMoreQuestions?.map((item, i) => {
                                        return (
                                            <Accordion.Item eventKey={i+6} className="hostPageAccordionItem">
                                            <Accordion.Header>
                                                <div className="d-flex align-items-center">
                                                    <div className="hostPageAccordionItemHeadNumber">{i+6}</div>
                                                    <span className="hostPageAccordionItemHeadText SpotLetSubHead">{item?.question}</span>
                                                </div>
                                            </Accordion.Header>
                                            <Accordion.Body style={{color : '#737373'}}>{item?.answer}</Accordion.Body>
                                        </Accordion.Item>
                                        )
                                    } ) 
                                }
                                { showReadMore && <p className="p-0 text-end cursor required" onClick={onClickReadMore}  > read more </p> }
                                { !showReadMore && <p className="p-0 text-end cursor required" onClick={onClickReadMore}  > read less </p> }
                                </Accordion>
                            </div>
                        </div>
                    </div>
                    {/* Section 6 end */}
                    {/* section 7 start */}
                    <div className="hostPageSectionContainer container">
                        <div className="row">
                            <div className="hostPageGetInTouch col-xl-6">
                                <div className="d-flex flex-column">
                                    <span className="hostPageContentHeading mb-3 SpotLetMainHead">GET IN TOUCH</span>
                                    <span className="hostPageContentNormal mb-3 SpotLetSubHead">Love your property? Why not List it and Become a Successful SpotLet Host? </span>
                                </div>
                                    <form className="row" onSubmit={onClickSubmit} id='hostPageGetInTouchForm'>
                                        <div className="col-lg-6 mb-4">
                                            <label className="propertyPageFormLabel SpotLetContent ">Your Property Name</label>
                                            <div className='propertyFormInputContainer'>
                                                <input
                                                    className="propertyPageFormInput SpotLetContent "
                                                    placeholder="Your Property Name"
                                                    type='text'
                                                    name='property'
                                                    onChange={onChange}
                                                />
                                                <img src={building} alt='building' className='propertyPageFormSvg'/>
                                            </div>
                                        </div>
                                        <div className="col-lg-6 mb-4">
                                            <label className="propertyPageFormLabel SpotLetContent ">Your Name{' '}<span className='required'>*</span></label>
                                            <div className='propertyFormInputContainer'>
                                                <input
                                                    className="propertyPageFormInput SpotLetContent "
                                                    placeholder="Contact Person Name"
                                                    type='text'
                                                    name='name'
                                                    required
                                                    onChange={onChange}
                                                />
                                                <img src={profileMan} alt='building' className='propertyPageFormSvg'/>
                                            </div>
                                        </div>
                                        <div className="col-lg-6 mb-4">
                                            <label className="propertyPageFormLabel SpotLetContent ">Contact Number{' '}<span className='required'>*</span></label>
                                            <div className='propertyFormInputContainer'>
                                                {/* <input
                                                    className="propertyPageFormInput SpotLetContent "
                                                    placeholder="Mobile Number"
                                                    type="text"
                                                    pattern="[789][0-9]{9}"
                                                    title="Please enter a valid phone number"
                                                    name='mobileNumber'
                                                    required
                                                    onChange={onChange}
                                                /> */}
                                                <PhoneInput
                                                    required
                                                    international
                                                    name='mobileNumber'
                                                    countryCallingCodeEditable={false}
                                                    flags
                                                    placeholder="Mobile Number"
                                                    className="propertyPageFormInput SpotLetContent "
                                                    style={{height:"51px"}}
                                                    value={formData?.mobileNumber}
                                                    defaultCountry="IN"
                                                    onChange={(e) => {
                                                        setFormData({
                                                            ...formData,
                                                            mobileNumber : e,
                                                        })
                                                    }  
                                                }
                                                />
                                                <img src={vectorPhone} alt='building' className='propertyPageFormSvg'/>
                                            </div>
                                            {numberError && <p className='mb-0' style={{color:"#EA4335"}}> {numberError} </p>}
                                        </div>
                                        <div className="col-lg-6 mb-4">
                                            <label className="propertyPageFormLabel SpotLetContent ">Email{' '}<span className='required'>*</span></label>
                                            <div className='propertyFormInputContainer'>
                                                <input
                                                    pattern="^[^\s@]+@[^\s@]+\.[^\s@]{2,}$"
                                                    title="info@example.org"
                                                    className="propertyPageFormInput SpotLetContent "
                                                    placeholder="Email"
                                                    type='mail'
                                                    name='email'
                                                    required
                                                    onChange={onChange}
                                                />
                                                <img src={vectorMail} alt='building' className='propertyPageFormSvg'/>
                                            </div>
                                        </div>
                                        <div className="d-flex flex-column col-lg-12">
                                            <label className="propertyPageFormLabel SpotLetContent ">Your Query / Message</label>
                                            <textarea
                                                className="propertyPageFormTextArea"
                                                placeholder="Message"
                                                name='message'
                                                onChange={onChange}
                                            />
                                        </div>
                                        <div className='col-lg-12'>
                                            <div className="d-flex flex-row justify-content-center">
                                                <button
                                                className={`propertyFormSubmit SpotLetContent align-self-center`}
                                                type='submit'
                                                >
                                                    Submit
                                                </button>
                                            </div>
                                        </div>
                                    </form>
                            </div>
                            <img src={image4} alt ="Get In Touch" className="col-xl-6 hostPageGetInTouchImage"/>
                        </div>
                    </div>
                    {/* section 7 end */}
                </div>
            </div>
            < Footer/>
            {authenticate && 
                <AuthenitcateModal
                    show={authenticate}
                    handleSuccess={()=> {
                        setAuthenticate(false)
                        navigate(navTo)
                    }}
                    handleClose={()=>setAuthenticate(false)}
                    activeTab=''
                />
            }
            {showModal && (
                <PropertyPageInformationModal
                show = {showModal}
                title = 'SpotLet'
                content = {modalBody}
                handleClose = {()=>setShowModal(false)}
                />
            )}
        </div>
    )
}
export default HostPage;