import { useState,useEffect } from "react";
import { getTypeOfProperties } from "../../../apis/api";
import Accordion from 'react-bootstrap/Accordion';
// Images
import info from "../../../assets/NewImages/BecomeAHost/i.svg";

const TypeOfProperty = ({data,handleNext}) => {
    const [selectedLocation, setSelectedLocation] = useState("");
    const [activeCategory,setActiveCategory] = useState('Commercial Space')
    const [index,setIndex] = useState(0)
    const [locationTypes, setLocationTypes] = useState({});

    useEffect(()=>{
        const callBack = async()=> {
            try{
                const resp = await getTypeOfProperties()
                const temp = {}
                resp.data.map((type)=>{
                    temp[type.category] = type.types.sort();
                })
                setLocationTypes({...temp})
                //setData
                setSelectedLocation(data)
                if(data){
                    let tempPropertyType ;
                    let tempIndex = 0
                    for (const category in  temp) {
                        if( temp[category].includes(data)){
                            setIndex(tempIndex)
                            setActiveCategory(category)
                            tempPropertyType = data
                            break
                        }
                        tempIndex +=1
                    }
                    if(!tempPropertyType){
                        setLocationTypes({
                            ...temp,
                            Others : [data],
                        })
                        setActiveCategory('Others')
                    }
                }
            }catch(error){
                console.log(error)
            }
        }
        callBack()
    },[data])

    return(
        <div className="locationtype-page">
                <div className="locationtype-heading-container" >
                    <h1 className="locationtype-head" > Type of Property {' '}
                        <a
                            href="#"
                            data-bs-toggle="tooltip"
                            title="Select: Residential, Commercial, Event Venue, Vacation Rental"
                        >
                            <img src={info} />
                        </a>
                    </h1>
                </div>
            <div className="tabCard deskCard row">
                <div className="mainHeadContainer  col-lg-3 col-xl-2">
                    {Object.keys(locationTypes).map((category,i) => {
                        return(
                            <div 
                            key={i}
                            className={activeCategory=== category ? "category  is-active-categorry cursor" : 'category cursor'}
                            // onClick={()=>onClickCategory(category)}
                            >
                                <p>{category}</p>
                            </div>
                        )
                    })}
                </div>
                <div className="subHeadContainer  col-lg-7 col-xl-6">
                    {locationTypes[activeCategory]?.map((locationType,i)=>{
                        return(
                            <div 
                            key={i}
                            className={selectedLocation === locationType ? 'is-active-locationType' : 'locationType'}
                            >
                                <div className="d-flex flex-row  align-items-center" >
                                    <input className="amenities-checkbox cursor" type='checkbox' name={locationType} checked={selectedLocation === locationType} id={i}/>
                                    <label  className="amenities-label cursor" htmlFor={i}>{locationType}</label>
                                </div>
                            </div>
                        )
                    })}
                </div>
            </div>
            <div className="tabRowMobile">
                {Object.keys(locationTypes).map((category,i) => {
                    return(
                        <Accordion defaultActiveKey={index} className="m-3">
                            <Accordion.Item eventKey={i}>
                            <Accordion.Header>{category}</Accordion.Header>
                            <Accordion.Body>
                            <div className="subHeadContainer  col-lg-7 col-xl-6">
                                {locationTypes[category]?.map((locationType,i)=>{
                                    return(
                                        <div 
                                        key={i}
                                        className={selectedLocation === locationType ? 'is-active-locationType' : 'locationType'}
                                        >
                                            <div className="d-flex flex-row  align-items-center" >
                                                <input className="amenities-checkbox cursor" type='checkbox' name={locationType} checked={selectedLocation === locationType} id={i}/>
                                                <label  className="amenities-label cursor" htmlFor={i}>{locationType}</label>
                                            </div>
                                        </div>
                                    )
                                })}
                            </div>
                            </Accordion.Body>
                            </Accordion.Item>
                        </Accordion>
                    )
                })}
            </div>
                {/* <div className="col-lg-8 dashboardPopretySaveContainer justify-content-end mt-2">
                    <div className="d-flex flex-row justify-content-end align-center">
                        <button
                        type="button"
                        className="btn btn-primary"
                        onClick={()=>handleNext(1)}
                        >
                            Continue
                        </button>
                    </div>
                </div> */}
                {/* Footer Start */}
                <div className="row controlContainer">
                    <div className="col-lg-8">
                    {/* <div className=""> */}
                        <div className="d-flex flex-row justify-content-end align-items-center">
                            {/* <div>
                                <button
                                type="button"
                                class="btn btn-outline-danger"
                                onClick={handleBack}
                                >
                                    Back
                                </button>
                            </div> */}
                            <div>
                                <button
                                type="button"
                                className="btn btn-primary"
                                onClick={()=>handleNext(1)}
                                >
                                    Continue
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            {/* Footer End */}
        </div>
    )
}
export default TypeOfProperty;