import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import moment from "moment/moment";


const responsive = {
    desktop: {
      "breakpoint":  { max: 3000, min: 1200 },
      items: 4, // length > 3 ? 3 : length ,
      slidesToSlide: 1 // optional, default to 1.
    },
    tablet: {
      "breakpoint":  { max: 1200, min: 768 },
      items: 2, //length > 2 ? 2 : length,
      slidesToSlide: 1 // optional, default to 1.
    },
    mobile: {
      "breakpoint":  { max: 768, min: 0 },
      items: 1,
      slidesToSlide: 1 // optional, default to 1.
    }
  };

const RecentEvents = ({data}) => {

      const eventCard = (data, index) => {        
        return(
            <div className="recentEventCard" id = {index} >
                <div className="recentEventCardTopSection">
                    <p className="recentEventCardDate SpotLetSubHead" > 
                         {moment(data?.date).format("Do MMM YYYY")}
                    </p>
                    <img src = {data?.image} className="recentEventCardImg" alt = "eventImg"/>
                </div>
                <div className="recentEventCardBottomSection" >
                    <h6 className="recentEventType pb-0 SpotLetSubHead" > {data?.type} </h6>
                    <h6 className="recentEventName pb-0 SpotLetSubHead" > {data?.name} </h6>
                </div>
            </div>
        )
    }

    return(
        <div className="recentEventsMainContainer" >
                <h1  className="recentEventsHead SpotLetMainHead" id = "propertyPage10"> Recent Events </h1>
                <div className="recentEventsSlider" >
                <Carousel 
                    swipeable={true}
                    draggable={true}
                    showDots={false}
                    responsive={responsive}
                    ssr={true} // means to render carousel on server-side.
                    infinite={true}
                    autoPlay= {false} 
                    containerClass="carousel-container"
                    itemClass="carousel-item-padding-40-px"
                    >
                        {
                            data?.map((each,index) => eventCard(each, index))
                        }
                </Carousel>
            </div>
        </div>
    )
}

export default RecentEvents