import { useEffect, useState } from "react";
import { addPrioritySupport,getListingSupports, getPlans } from "../../../apis/api";
import { ConfirmationModel } from "../../../components/Modal/ConfirmationModel";
import { useAuthStore } from "../../../store/auth";
import moment from "moment";
import { PageLoader } from "../../../components/PageLoader";
import SubscriptionModal from "../../NewSubscription/Modals/SubscriptionModal";

const Support = ({data}) => {
    const [query,setQuery] = useState([])
    const [subscription,setSubscription] = useState('')
    const [formData,setFormData] = useState({})
    const [formActive,setFormActive] = useState(false)
    const [show,setShow] = useState(false)
    const [modalBody,setModalBody] = useState('')
    const { userProfile } = useAuthStore();
    const getData = async () => {
        try{
            const resp = await getListingSupports(data)
            setQuery(resp?.data?.data)
            setSubscription(resp?.data?.listing?.subscription_id)
        }catch(error){
            console.log(error)
        }
    }
    useEffect(()=>{
        getData()
    },[]);
    const onChange = (e) => {
        const {name,value} = e.target
        setFormData({
            ...formData,
            [name] : value
        })
    }
    const onSubmit = async (e) => {{
        e.preventDefault()
        try{
            const resp = await addPrioritySupport({...formData,locationId : data, user_id : userProfile?._id})
            setModalBody(resp?.data?.message)
            setShow(true)
            setFormData({})
            setFormActive(false)
            document.getElementById('prioritySupportForm').reset()
            getData()
        }catch(error){
            console.log(error)
        }
    }}
    const supportTable = () => {
        return(
            <div className="mt-2 w-100">
                <table>
                    <thead>
                        <tr>
                            <th>S No</th>
                            <th>Ticket Id</th>
                            <th>Location Id</th>
                            <th>Topic</th>
                            <th>Date</th>
                            <th>Status</th>
                            {/* <th>Actions</th> */}
                        </tr>
                    </thead>
                    <tbody>
                        {query?.map((item,i)=>{
                            return(
                                <tr style={{ textAlign: "center" }}>
                                    <td>{i+1}</td>
                                    <td>{item?.data?.id}</td>
                                    <td>{item?.data?.locationId}</td>
                                    <td>{item?.data?.topic}</td>
                                    <td>{moment(item?.createdAt).format('DD-MM-YYYY hh:mm:ss')}</td>
                                    <td>{item?.status}</td>
                                </tr>
                            )
                        })}
                    </tbody>
                </table>
                <div className="mt-2">
                    <button
                        className="btn btn-primary"
                        onClick={()=>setFormActive(true)}
                    >Add New</button>
                </div>
            </div>
        )
    }
    const supportForm = () => {
        return(
            <form onSubmit={onSubmit} id='prioritySupportForm' className="w-100">
                <div className="d-flex flex-column mb-2">
                    <label htmlFor="topic" className="form-label">Topic<label className="required">*</label></label>
                    <input
                        type="text"
                        id='topic'
                        name="topic"
                        value={formData?.topic}
                        onChange={onChange}
                        required
                        className="textFiled"
                        placeholder="Enter topic"
                     />
                </div>
                <div className="d-flex flex-column mb-2">
                    <label htmlFor="message" className="form-label">Message<label className="required">*</label></label>
                    <textarea
                        id='message'
                        name="message"
                        value={formData?.message || ''}
                        onChange={onChange}
                        required
                        className="basicInfoDescription"
                        placeholder="Enter message"
                        rows="4"
                        cols="50"
                     />
                </div>
                <div>
                    <button
                        className="btn btn-secondary mt-2 me-2"
                        type="button"
                        onClick={()=>setFormActive(false)}
                    >Cancel</button>
                    <button
                        className="btn btn-primary mt-2"
                        type="submit"
                    >Submit</button>
                </div>
            </form>
        )
    }
    //subscription update start
    const region = localStorage.getItem('region')
    const [loader, setLoader] = useState(false)
    const [showSubscriptionModel, setShowSubscriptionModel] = useState(false);
    const [standardPlan, setStandardPlan] = useState({})
    const [premiumPlan, setPremiumPlan] = useState({})
    const [activePlan,setActivePlan] = useState({})
    const onClickUpgrade = async () => {
        setLoader(true)
        try {
        const res = await getPlans();
        let reqCurrency 
        if (region === "India") {
            reqCurrency = "INR"
        } else {
            reqCurrency = "USD"
        }
        if(res?.data) {
            let standard,premium
            for (let each of  res?.data) {
                if (each?.plan === "Standard") {
                    for (let eachCountryPlan of each?.amount) {
                        if (eachCountryPlan?.currency === reqCurrency) {
                            standard = {
                                plan : each?.plan,
                                originalAmount : eachCountryPlan?.originalAmount,
                                amount : eachCountryPlan?.discountAmount,
                                planId : eachCountryPlan?.plan_id,
                                currency : eachCountryPlan?.currency,
                            }
                            setStandardPlan(standard)
                        }
                    }
                }
                if (each?.plan === "Premium") {
                    for (let eachCountryPlan of each?.amount) {
                        if (eachCountryPlan?.currency === reqCurrency) {
                            premium = {
                                plan : each?.plan,
                                originalAmount : eachCountryPlan?.originalAmount,
                                amount : eachCountryPlan?.discountAmount,
                                planId : eachCountryPlan?.plan_id,
                                currency : eachCountryPlan?.currency,
                            }
                            setPremiumPlan(premium)
                        }
                    }
                }
            }
            switch (subscription) {
                case 'Basic':
                    setActivePlan(standard)
                    break;
                case 'Standard':
                    setActivePlan(premium)
                    break;
                default:
                    break;
            }
            setShowSubscriptionModel(true)
        }
        } catch (error) {
        console.log(error)
        }
        setLoader(false)
    }
    const handleChangePlan = () => {
        if(activePlan?.plan === 'Standard'){
            setActivePlan(premiumPlan)
        }else{
            setActivePlan(standardPlan)
        }
    }
    const completeTask = () => {
        setShowSubscriptionModel(false)
        getData()
    }
    //subscription update end
    return(
        <div className="dashBoardRatingsSection">
            <span>Priority Support</span>
            {subscription === 'Basic' ? (
                <div className="d-flex flex-column">
                    <p>You are not subscribed to any plan. Please upgrade.</p>
                    <div>
                        <button type="button" className="btn btn-primary" onClick={onClickUpgrade}>Upgrade</button>
                    </div>
                </div>
            ) : (
                <>
                    {query?.length > 0 && !formActive ? supportTable() : supportForm()}
                </>
            )}
            {show && (
                <ConfirmationModel
                show={show}
                handleClose={()=>setShow(false)}
                handleOk={()=>setShow(false)}
                title='Priority Support'
                body={modalBody}
                 />
            )}
             {showSubscriptionModel && (
                <SubscriptionModal
                    show={showSubscriptionModel}
                    onHide={()=>setShowSubscriptionModel(false)}
                    plan ={activePlan}
                    handleChangePlan={handleChangePlan}
                    completeTask={completeTask}
                    title = {'Upgrade Plan'}
                    listingId = {data}
                />
            )}
            {loader && <PageLoader />}
        </div>
    )
}
export default Support;