import { Button, Modal } from "react-bootstrap";
import OTPInput from "otp-input-react";
import { useEffect, useState } from "react";
import PhoneInput, {isValidPhoneNumber} from 'react-phone-number-input'
import { PageLoader } from "../../components/PageLoader";
import { useAuthStore } from "../../store/auth";
import {signUp} from "../../apis/api";


//For Bcrypt
import bcrypt from "bcryptjs-react"

const { Header, Footer, Title, Body } = Modal;
export function VerifyWhatsApp({
  handleClose,
  handleSendOtpFunc,
  show,
  otpCallBack,
  number,
  reqOtp,
  confirmationTitle,
  confirmationBody,
  confirmationModel,
  handleOkay
}) {
  const { setAuthenticated, setUserProfile } = useAuthStore();
  const [timer, setTimer] = useState(60);
  const [otp, setOtp]  = useState("");
  const [mobileNumber,setMobileNumber] = useState('')
  const [loader, setLoader] = useState(false);

  const onClickResend = (data) => {
    handleSendOtpFunc(data);
  } 

  const onChangeOtp = (e) => {
    setOtp(e)
    otpCallBack(e)
  }


  useEffect(() => {
    const counter =
      timer > 0 &&
      setInterval(
        () =>
          setTimer((prevState) => {
            return prevState - 1;
          }),
        1000
      );
    return () => clearInterval(counter);
  }, [timer]);

  const handleWhatsappSignUp = (e) =>{
    e.preventDefault()
            onClickResend({mobile : reqNumber})
            if (timer < 60) {
              setTimer(60);
            }
  }

  const onVerify = async () => {
    const isOk = await bcrypt.compare(otp, reqOtp);
    if (!isOk) {
      confirmationTitle('Error')
      confirmationBody("Invalid OTP please try again!!!")
      confirmationModel(true)
      setLoader(false)
    }
    else {
      const response = await signUp({mobile : mobileNumber,whatsappLogin : true});
      localStorage.setItem("auth", response.data.token);
      setLoader(false)
      handleOkay()
      setAuthenticated();
      setUserProfile();
    }
};

  useEffect(() => {
    setMobileNumber(number);
  }, [number] )

  return (
    <Modal show={show} onHide={handleClose} backdrop="static">
      <Header closeButton>
        <Title>Verify your WhatsApp Number</Title>
      </Header>
      <Body className="verify-email-modal-body">
        <div>
                <p className="otp-header">Enter the 4 digit code sent to your WhatsApp</p>
                <OTPInput
                    value={otp}
                    onChange={ (e) =>  onChangeOtp(e)}
                    autoFocus
                    OTPLength={4}
                    otpType="number"
                    disabled={false}
                    secure
                    className="otp-input"
                />
                {timer > 0 && <p className="timer">{timer} sec.</p>}
                <div className="resend-email">
                    <p>Didn't get a OTP?</p>
                    <Button
                    variant="link"
                    className="resend-button"
                    disabled={timer > 0}
                    onClick={handleWhatsappSignUp}
                    >
                    Resend Code
                    </Button>
                   
                </div>
                {loader && <PageLoader/>}
        </div>
      </Body>
      <Footer>
        <Button variant="secondary" onClick={handleClose}>
          Cancel
        </Button>
        <Button variant="primary" onClick={() => {
          setLoader(true)
          onVerify()
        }}>
                    Verify OTP
        </Button>
      </Footer>
    </Modal>
  );
}
