import jwt_decode from "jwt-decode";
import "./App.css";
import Auth from "./pages/Auth";
import { Home } from "./pages/Home/Home";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import NotFound from "./components/NotFound";
import { TestRoute } from "./pages/TestRoute";
import { ProtectedRoute } from "./utils/ProtectedRoute";
import { SignUp } from "./pages/SignUp";
import { Login } from "./pages/Login";
import { useEffect } from "react";
import { useAuthStore } from "./store/auth";
import { ListingType } from "./pages/ListingType";
import { BecomeAHost } from "./components/becomeHost/BecomeAHost";
import {ListYourSpace} from "./components/ListYourSpace/ListYourSpace";
import { SubscriptionPlan } from './components/subscriptionPlan/SubscriptionPlan'
import Subscription from "./pages/NewSubscription/Subscription";
import { EditProfile } from "./pages/EditProfile";
import { Billing } from "./pages/Billing";
import { Listings } from "./pages/Listings";
import { IncompleteListings } from "./pages/IncompleteListings";
import { Calendar } from "./pages/Calendar";
import { Reviews } from "./pages/Review/Reviews"
import { SearchPlaces } from "./pages/SearchPlaces";
import { ResetPassword } from "./pages/ResetPassword";
import PropertyPage from './pages/PropertPage/PropertyPage'
import NewPropertyPage from "./pages/NewPropertyPage/NewPropertyPage";
import Booking from "./pages/Booking/Booking"
import PropertySearch from './pages/SearchPage/PropertySearch'
import { BookingsList } from "./pages/BookingsList";
import { UserEnquiriesList } from "./pages/UserEnquiriesList";
import { RequestedBookingList } from "./pages/RequestedBookingList";
import { CouponList } from "./pages/CouponsList";
import BookingDetails from "./pages/BookingDetails/BookingDetails"
import Favorites from "./pages/Favorites/index"
import CompareProperties from "./pages/NewCompareProperties";
import CreateCoupon from "./pages/Coupon/creatCoupn";
import UpdateCoupon from "./pages/Coupon/updateCoupn";
import Blog from "./pages/Blog";
import BlogDetails from "./pages/BlogDetails";
import AboutUs from "./pages/AboutUs";
import Careers from "./pages/Careers";
import Terms from "./pages/Terms&Conditions";
import Policy from "./pages/Privacy&Policy";
import HelpCenter from "./pages/HelpCenter";
import { RatingsList } from "./pages/Review/RatingsList/RatingsList";
import Cancellation from "./pages/cancellationRefund";
import CouponUsageList from "./pages/CouponUsageList";
import { Payment } from "./pages/Payment";
import { AccountActivation } from "./pages/AccountActivation/AccountActivation";
import Dashboard from "./pages/Dashboard";
import SearchPage from "./pages/NewSearchPage/SearchPage";
import Notifications from "./pages/Notifications/Notifications";
import HostPage from "./pages/HostPage/index"
import { Version } from "./pages/VersionPage";
import Invoice from "./pages/Invoice/Invoice";
import NewsLetter from "./pages/NewsLetterPage";
import Notification from "./pages/NewNotification/Notification";
import Activity from "./pages/Activity/Activity";
import LandingPage from "./pages/LandingPage/LandingPage";

const router = createBrowserRouter([
  {
    path: "/auth",
    element: <Auth />,
    errorElement: <NotFound />,
  },
  {
    path: "/",
    element: <Home />,
    errorElement: <NotFound />,
  },
  // {
  //   path: "/signup",
  //   element: <SignUp />,
  // },
  // {
  //   path: "/login",
  //   element: <Login />,
  // },
  {
    path: "/edit-profile",
    element: (
      <ProtectedRoute>
        <EditProfile />
      </ProtectedRoute>
    ),
  },
  // {
  //   path: "/billing",
  //   element: (
  //     <ProtectedRoute>
  //       <Billing />
  //     </ProtectedRoute>
  //   ),
  // },
  {
    path: "/listed-properties",
    element: (
      <ProtectedRoute>
        <Listings />
      </ProtectedRoute>
    ),
  },
  {
    path: "/incomplete-listings",
    element: (
      <ProtectedRoute>
        <IncompleteListings />
      </ProtectedRoute>
    ),
  },
  {
    path: "/my-bookings",
    element: (
      <ProtectedRoute>
        <BookingsList />
      </ProtectedRoute>
    ),
  },
  {
    path: "/my-enquiries",
    element: (
      <ProtectedRoute>
        <UserEnquiriesList />
      </ProtectedRoute>
    ),
  },
  {
    path: "/booking-requests",
    element: (
      <ProtectedRoute>
        <RequestedBookingList />
      </ProtectedRoute>
    ),
  },
  // {
  //   path: "/coupons",
  //   element: (
  //     <ProtectedRoute>
  //       <CouponList />
  //     </ProtectedRoute>
  //   ),
  // },
  {
    path: "/booking-details/:id",
    element: (
      <ProtectedRoute>
        <BookingDetails />
      </ProtectedRoute>
    ),
  },
  {
    path: "/calendar",
    element: (
      <ProtectedRoute>
        <Calendar />
      </ProtectedRoute>
    ),
  },
  // {
  //   path: "/reviews",
  //   element: (
  //     <ProtectedRoute>
  //       <Reviews />
  //     </ProtectedRoute>
  //   ),
  // },
  {
    path: "/test",
    element: (
      <ProtectedRoute>
        <TestRoute />
      </ProtectedRoute>
    ),
  },
  // {
  //   path: "/listing-type",
  //   element: (
  //     <ProtectedRoute>
  //       <ListingType />
  //     </ProtectedRoute>
  //   ),
  // },
  // {
  //   path: "/becomeahost",
  //   element: (
  //     <ProtectedRoute>
  //       <BecomeAHost />
  //     </ProtectedRoute>
  //   ),
  // },
  {
    path: "/list-your-space",
    element: (
      <ProtectedRoute>
        <ListYourSpace />
      </ProtectedRoute>
    ),
  },
  {
    path: "/plans",
    element: (
      <ProtectedRoute>
        <Subscription />
      </ProtectedRoute>
    ),
  },
  // {
  //   path: "/search-places",
  //   element: <SearchPlaces />,
  // },
  // {
  //   path: "/location",
  //   element: <PropertySearch />,
  // },
  {
    path: "/search/*",
    element: <SearchPage />,
  },
  {
    path: "/property/:id",
    element: <NewPropertyPage />,
    // element: (
    //   <ProtectedRoute>
    //     <NewPropertyPage />
    //   </ProtectedRoute>
    // ),
  },
  {
    path: "/booking/:id",
    // element: <Booking />,
    element: (
      <ProtectedRoute>
        <Booking />
      </ProtectedRoute>
    ),
  },
  {
    path: "/reset/:userId",
    element: <ResetPassword />,
  },
  // {
  //   path: "/blog",
  //   element: <Blog />,
  // },
  // {
  //   path: "/blog/details",
  //   element: <BlogDetails />,
  // },
  {
    path: "/about-us",
    element: <AboutUs />,
  },
  {
    path: "/careers",
    element: <Careers />,
  },
  {
    path: "/terms-and-conditions",
    element: <Terms />,
  },
  {
    path: "/cancellation-and-refund",
    element: <Cancellation />,
  },
  {
    path: "/privacy-policy",
    element: <Policy />,
  },
  {
    path: "/help-center",
    element: <HelpCenter />,
  },
  {
    path: "/favourites",
    element: (
      <ProtectedRoute>
        <Favorites />
      </ProtectedRoute>
    ),
  },
  {
    path: "/compare",
    element: (
      <ProtectedRoute>
        <CompareProperties />
      </ProtectedRoute>
    ),
  },
  // {
  //   path: "/create-coupon",
  //   element: (
  //     <ProtectedRoute>
  //       <CreateCoupon />
  //     </ProtectedRoute>
  //   ),
  // },
  // {
  //   path: "/update-coupon/:couponId",
  //   element: (
  //     <ProtectedRoute>
  //       <UpdateCoupon />
  //     </ProtectedRoute>
  //   ),
  // },
  // {
  //   path: "/ratings-list/:locationId",
  //   element: (
  //     <ProtectedRoute>
  //       <RatingsList />
  //     </ProtectedRoute>
  //   ),
  // },
  // {
  //   path: "/coupons/:couponId",
  //   element: (
  //     <ProtectedRoute>
  //       <CouponUsageList />
  //     </ProtectedRoute>
  //   ),
  // },
  {
    path: "/payment",
    element: (
      <Payment />
    ),
  },
  {
    path: "/activate/:userId",
    element: (
      <AccountActivation />
    ),
  },
  {
    path: "/dashboard",
    element: (
      <ProtectedRoute>
        <Dashboard />
      </ProtectedRoute>
    ),
  },
  {
    path: "/notifications",
    element: (
      <ProtectedRoute>
        <Notification />
      </ProtectedRoute>
    ),
  },
  {
    path: "/host",
    element: <HostPage />,
    errorElement: <NotFound />,
  },
  {
    path: "/version",
    element: <Version />,
  },
  {
    path: "/invoice/:id",
    element: (
      <ProtectedRoute>
        <Invoice />
      </ProtectedRoute>
    ),
  },
  {
    path: "/newsletter",
    element:  <NewsLetter/>
  },
  {
    path: "/locations",
    element:  <Activity/>
  },
  {
    path: "/locations/:event",
    element:  <Activity/>
  },
  {
    path: "/locations/:event/:city",
    element:  <Activity/>
  },
  {
    path: "/locations/:event//:activity",
    element:  <Activity/>
  },
  {
    path: "/locations/:event/:city/:activity",
    element:  <Activity/>
  },
  {
    path: "/landing-page",
    element:  <LandingPage/>
  },

]);

function App() {
  const { setAuthenticated, setUserProfile, setBookingServices } = useAuthStore();

  useEffect(() => {
    const token = localStorage.getItem("auth");
    const region = localStorage.getItem('region');
    if(!region){
      localStorage.setItem('region',"India") //set region
      localStorage.setItem('regionId',"IN72549630") //set region
    }
    if(token){
      let decoded = jwt_decode(token);
      if (Math.floor(Date.now() / 1000) < decoded?.exp) {
        setAuthenticated();
        setUserProfile();
      }
    }
    setBookingServices();
  }, []); 

  // for disabling mouse scroll event for all input type numbers start
  useEffect(() => {
    const handleWheel = (event) => {
      if (document.activeElement.type === 'number') {
        event.preventDefault();
      }
    };

    window.addEventListener('wheel', handleWheel, { passive: false });

    // Cleanup event listener on component unmount
    return () => {
      window.removeEventListener('wheel', handleWheel);
    };
  }, []);
  // for disabling mouse scroll event for all input type numbers end

  return <RouterProvider router={router} />;
}

export default App;
