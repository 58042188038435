import Slider from "react-slick";
import PropertyInfo from "../../../components/NewPropertyInfo";
import "./style.css";

// Images
import next from '../../../assets/NewImages/Home/next.webp';
import prev from '../../../assets/NewImages/Home/prev.webp';

function SampleNextArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{ ...style, display: "block", height: '50px', width: '100px' }}
    ><img loading="lazy" src={next} className="arrowImg" onClick={onClick} alt='Next'/></div>
  );
}

function SamplePrevArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{ ...style, display: "block", height: '50px', width: '100px', zIndex: '1', marginLeft: '-10px' }}
    ><img loading="lazy" src={prev} className="arrowImg" onClick={onClick} alt='Previous'/></div>
  );
}



export const TopRatedProperties = ({data}) => {

  var settings = {
    dots: false,
    infinite: true,
    swipeToSlide: false,
    autoplay: true,
    autoplaySpeed: 3000,
    fade: false,
    swipe: false,
    slidesToScroll: 1,
    initialSlide: 0,
    pauseOnHover: true,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    slidesToShow: data?.length >= 4 ? 4 : data?.length,
    responsive: [
      {
        breakpoint: 1025,
        settings: {
          slidesToShow: data?.length >= 2 ? 2 : data?.length,
          slidesToScroll: 1,
          infinite: true,
        }
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: data?.length >= 2 ? 2 : data?.length,
          slidesToScroll: 1,
          infinite: true,
          initialSlide: 0
        }
      },
      {
        breakpoint: 510,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
        }
      }
    ]
  };

  return (
    <Slider {...settings}>
      {data?.map((property) => (
        <PropertyInfo key={property._id} property={property} addVerify={true}/>
      ))}
    </Slider>
  );
}
