import { useState,useEffect, useRef} from "react";
import info from "../../assets/NewImages/BecomeAHost/i.svg";
import "./ListYourSpace"
import { ConfirmationModel } from '../Modal/ConfirmationModel';
import { VerifyModal } from "../Modal/VerifyModal";


const INITIAL_VALUES = {
    name : '',
    length : '',
    height : '',
    width : '',
    description : "",
    size : "",
    area_type : "Sq.Ft",
}

const Features = ({data,handleBack,handleNext}) => {
    const [allFeaturesData,setAllFeaturesData] = useState([]);
    const [formData, setFormData] = useState(INITIAL_VALUES);
    const [showForm, setShowForm] = useState(true);
    const [editIndex, setEditIndex] = useState(null);
    const [newGallary, setNewGallary] = useState({});
    const [newOriginalGallary, setNewOriginalGallary] = useState({});
    const [showConfirmationModel, setShowConfirmationModel] = useState(false);
    const [confirmationTitle, setConfirmationTitle] = useState("");
    const [confirmationBody, setConfirmationBody] = useState("");
    const [nameError, setNameError] = useState("");
    const violationRef = useRef(null);
    const [showVerifyModel, setShowVerifyModel] = useState(false)
    const [deleteIndex, setDeleteIndex] = useState();
    
    const onChangeInput = (e) => {
        const {name,value } = e.target
        if (name === "name") {
            if (value.trim() === "" ) {
                setNameError("The Name should not be Empty");
            } else {
                setNameError("")
            }
        }
        setFormData({...formData, [name]:value})
    }
    const onSubmitForm = (e) => {
        e.preventDefault();
        if (nameError) return null 
        if (editIndex || editIndex === 0) {

            //rename in gallary
            if(Object.keys(newGallary).length > 0){
                const name = formData.name
                const oldName = allFeaturesData[editIndex].name
                setNewGallary((prev) => { return {}})
                setNewOriginalGallary((prev) => { return {}})
                Object.keys(newGallary).map((folderName) => {
                    if(folderName === oldName) {
                        setNewGallary((prev) => {
                            return {
                                ...prev,
                                [name] : newGallary[folderName]
                            }
                        })
                    }else{
                        setNewGallary((prev) => {
                            return {
                                ...prev,
                                [folderName] : newGallary[folderName]
                            }
                        })
                    }
                })
                Object.keys(newOriginalGallary).map((folderName) => {
                    if(folderName === oldName) {
                        setNewOriginalGallary((prev) => {
                            return {
                                ...prev,
                                [name] : newOriginalGallary[folderName]
                            }
                        })
                    }else{
                        setNewOriginalGallary((prev) => {
                            return {
                                ...prev,
                                [folderName] : newOriginalGallary[folderName]
                            }
                        })
                    }
                })
            }
            allFeaturesData.splice(editIndex, 1, formData)
            setAllFeaturesData([...allFeaturesData])
        }else{
            for(let item of allFeaturesData){
                if(item.name === formData.name){
                    setConfirmationTitle('Rooms/Spaces')
                    setConfirmationBody(`${formData?.name} is already added, Please try to add with another name`)
                    setShowConfirmationModel(true)
                    return null
                }
            }
            setAllFeaturesData([...allFeaturesData, formData])
        }
        setEditIndex(null)
        setFormData(INITIAL_VALUES)
        setShowForm(false);
    }
    const onClickDiscard = () => {
        if (allFeaturesData.length > 0) {
            setEditIndex(null)
            setShowForm(false)
        }
        setNameError("");
    }
    const onDelete = (index) => {
        allFeaturesData.splice(index, 1)
        setAllFeaturesData([...allFeaturesData])
        if (allFeaturesData.length === 0) setShowForm(true)
    }
    const onClickEdit = (index) => {
        setFormData({size : "", area_type : "Sq.Ft", ...allFeaturesData[index]})
        setEditIndex(index)
        setShowForm(true)
        violationRef.current.scrollIntoView({ behavior: "smooth", block: "end" });
    }
    const handleSave = () => {
        handleNext({features : allFeaturesData, images : newGallary, original_images : newOriginalGallary})
    }
    useEffect(() => {
        if(data?.features?.length > 0){
            setAllFeaturesData([...data.features])
            setShowForm(false)
        }
        if(data?.images){
            setNewGallary({...data.images})
        }
        if(data?.original_images){
            setNewOriginalGallary({...data.original_images})
        }
        const element = document.getElementById(`listFeature`)
        if (element) element.scrollIntoView({behavior: "smooth",block: "start"});
    },[data])

    const onClickDelete = (index) => {
        setDeleteIndex(index)
        setShowVerifyModel(true)
        setConfirmationTitle("Rooms/Spaces")
        setConfirmationBody("Are you sure you want to delete?")
    }

    return(
        <>
        <div className="locationtype-page container-fluid " >
        <h1 className="locationtype-head" id='listFeature' ref={violationRef} > Rooms/Spaces  {' '}
                <a
                    href="#"
                    data-bs-toggle="tooltip"
                    title="Describe rooms uniquely, creating an inviting narrative in brief."
                >
                    <img src={info} />
                </a>
            </h1>
            <div className="tabCard row" >
                <div className="col-lg-8">
                    {showForm && (
                        <form className = "feature-form" onSubmit = {onSubmitForm} >
                            <div style={{maxWidth: "600px"}}>
                                <div className="d-flex flex-column mb-3" >
                                    <label > Name of the Room/Space <span className="required">*</span></label>
                                    <input 
                                    className="textFiled feature-input" 
                                    type = "input" 
                                    placeholder = "Eg: Bedroom, LivingArea, Dining Hall"
                                    required 
                                    onChange={onChangeInput}
                                    name = "name"
                                    value = {formData.name}
                                    />
                                    {nameError && <span className="required" > {nameError} </span>}
                                </div>
                                <div className="row">
                                    <span className="mb-2"> Dimensions of the Room/Space</span>
                                        <div className="d-flex flex-column  mt-2 col-lg-4"> 
                                            <label > Length </label>
                                            <input 
                                            // required 
                                            type = "number" 
                                            min = {1} 
                                            placeholder = "Length in Ft"  
                                            className="textFiled feature-small-input"
                                            onChange={onChangeInput}
                                            name = "length"
                                            value = {formData.length}
                                            />
                                        </div>
                                        <div className="d-flex flex-column mt-2 col-lg-4"> 
                                            <label > Width </label>
                                            <input 
                                            // required 
                                            type = "number" 
                                            min = {1} 
                                            name = "width"
                                            placeholder = "Width in Ft"  
                                            className="textFiled feature-small-input "
                                            onChange={ onChangeInput}
                                            value = {formData.width}
                                            />
                                        </div>
                                        <div className="d-flex flex-column mt-2 col-lg-4"> 
                                            <label > Height   </label>
                                            <input 
                                            type = "number" 
                                            min = {1} 
                                            placeholder = "Height in Ft"  
                                            className="textFiled feature-small-input" 
                                            onChange={onChangeInput}
                                            name = "height"
                                            value = {formData.height}
                                            />
                                        </div>
                                </div>
                                <div className="mb-2 mt-2 d-flex flex-column">
                                    <label htmlFor="room_size" className="m-0">
                                        Size
                                    </label>
                                    <div class="buttonIn">
                                        <input
                                            type="number"
                                            min='1'
                                            placeholder="00"
                                            id="room_size"
                                            name="size"
                                            className="textFiled feature-input"
                                            style={{width : '100%'}}
                                            value={formData?.size}
                                            onChange={onChangeInput}
                                        />
                                        <div className='propertySizeButtonContainer'>
                                        <button type="button" onClick={()=> setFormData({...formData,area_type : 'Sq.Ft'})} className={formData.area_type === 'Sq.Ft' ?"propertySizeButtonActive" :  "propertySizeButton"}>Sq.Ft</button>
                                        <button type="button" onClick={()=> setFormData({...formData,area_type : 'Sq.Mts'})} className={formData.area_type === 'Sq.Mts' ?"propertySizeButtonActive" :  "propertySizeButton"}>Sq.Mts</button>
                                        <button type="button" onClick={()=> setFormData({...formData,area_type : 'Sq.Yds'})} className={formData.area_type === 'Sq.Yds' ?"propertySizeButtonActive" :  "propertySizeButton"}>Sq.Yds</button>
                                        </div>
                                    </div>
                                </div>
                                <div className="d-flex flex-column mt-3" >
                                    <label > Description</label>
                                    <textarea 
                                    className="basicInfoDescription feature-text-area" 
                                    // type = "input" 
                                    placeholder = "Room/Space description"
                                    onChange={onChangeInput}
                                    name = "description"
                                    value = {formData.description}
                                    rows = {4}
                                    // cols = {55}
                                    ></textarea>
                                </div>
                                <div className="d-flex flex-row align-center mt-3" >
                                <button className="btn btn-primary feature-buttons" type="submit" > Save   </button>
                                <button className="btn btn-outline-secondary feature-buttons" type="button" onClick={onClickDiscard} > Discard </button>
                                </div>
                            </div>
                    </form>
                    ) }
                </div>
                <div className="col-lg-8">
                    {
                    allFeaturesData.length > 0 && 
                    allFeaturesData.map((details,index) => {
                        return (
                            <div className="feature-card mt-2">
                                <div className="feature-card-1" >
                                    <div>
                                        <p className="feature-card-details"> {details.name} </p>
                                    </div>
                                    <div className="feature-btns-container">
                                        <button type="button" className="btn btn-primary  feature-buttons" onClick={() => onClickEdit(index)} > <i class="bi bi-pencil"></i> </button> 
                                        <button type="button" className="btn btn-outline-secondary  feature-buttons" onClick={() => onClickDelete(index)}  > <i class="bi bi-trash"></i> </button>
                                    </div>
                                </div>
                                <div>
                                    <p className="feature-card-details">
                                        {details?.length && `${details.length}`}{details?.length && <span className="featureSmallText">L</span>}
                                        {details?.length && details.width && " x "}
                                        {details?.width && `${details.width}`}{details?.width && <span className="featureSmallText">W</span>}
                                        {details?.width && details.height && " x "}
                                        {details?.height && ` ${details.height}`}{details?.height && <span className="featureSmallText">H</span>}
                                    </p>
                                </div>
                                {
                                    details?.size && 
                                    <div> 
                                            <p className="feature-card-details m-0"> Size - { `${details?.size}  ${details?.area_type}`  } </p>
                                    </div>
                                }
                                <div>
                                    <p className="feature-card-details mt-2">{details?.description}</p>
                                </div>
                            </div>
                        )
                    } )
                    }
                </div>
                <div className="col-lg-4" ></div>
                <div>
                    { !showForm && <button type="button" onClick = {() => {
                        setFormData(INITIAL_VALUES)
                        setShowForm(true)
                        violationRef.current.scrollIntoView({ behavior: "smooth", block: "end" });
                    } }  
                        className="btn btn-primary mt-2" style={{width:"150px"}}    > 
                    Add New 
                    </button>}
                </div>
            </div>
        </div>
        <div className="row controlContainer">
            <div className="col-lg-8">
                <div className="d-flex flex-row justify-content-between align-items-center">
                    <div>
                        <button
                        type="button"
                        class="btn btn-outline-danger"
                        onClick={handleBack}
                        >
                            Back
                        </button>
                    </div>
                    <div>
                        <button
                        type="button"
                        className="btn btn-primary"
                        onClick={handleSave}
                        disabled = {allFeaturesData.length > 0 ? false : true}
                        >
                            Save & Continue
                        </button>
                    </div>
                </div>
            </div>
        </div>
    {showConfirmationModel && (
        <ConfirmationModel
          show={showConfirmationModel}
          handleClose={() =>setShowConfirmationModel(false)}
          handleOk={() => setShowConfirmationModel(false)}
          title={confirmationTitle}
          body={confirmationBody}
        />
    )}
    {showVerifyModel && (
        <VerifyModal
          show={showVerifyModel}
          onHide={() => {
            setConfirmationTitle("")
            setConfirmationBody("")
            setDeleteIndex()
            setShowVerifyModel(false)
          } 
        }
          onSuccess={() => {
            onDelete(deleteIndex)
            setConfirmationTitle("")
            setConfirmationBody("")
            setShowVerifyModel(false)}
          } 
          title={confirmationTitle}
          body={confirmationBody}
        />
    )}
        </>
    )
}
export default Features