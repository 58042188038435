import { useState, useEffect } from "react";
import jwt_decode from "jwt-decode";
import { useNavigate, useLocation } from "react-router-dom";
import { signIn, sendActivationLink } from "../apis/api";
import { useAuthStore } from "../store/auth";
import { Button } from "react-bootstrap";

import { GoogleLogin } from "react-google-login";
import { gapi } from "gapi-script";
import { ForgotPasswordModal } from "../utils/Authentication/ForgotPasswordModal";
import { InformationModal } from "../components/Modal/InformationModal";
import { ConfirmationModel } from '../components/Modal/ConfirmationModel';
import { ActivationModel } from "../utils/Authentication/ActivationModel";

// Images
import logoName from "../assets/NewImages/Login/logoname.svg";
import whatsup from "../assets/NewImages/Login/whatsup.svg";

export const Login = () => {
  const navigate = useNavigate();
  const location = useLocation()
  const { setAuthenticated, setUserProfile } = useAuthStore();

  const [details, setDetails] = useState({
    email: "",
    password: "",
  });
  const [hidePassword, setHidePassword] = useState(false);
  const { email, password } = details;
  const [googleClientId, setGoogleClientId] = useState(
    "837678588128-701v5ikaevb6ahi5bv0ps700q7lqit9d.apps.googleusercontent.com"
  );
  const [showForgotPasswordModal, setShowForgotPasswordModal] = useState(false);

  // For Showing Errors on Signin
  const [showErrorModal, setShowErrorModal] = useState(false);
  const [errorMsg, setErrorMsg] = useState("Some Error Occured");
  const [showConfirmationModel, setShowConfirmationModel] = useState(false);
  const [confirmationTitle, setConfirmationTitle] = useState("");
  const [confirmationBody, setConfirmationBody] = useState("");

  const [showConfirmationModel1, setShowConfirmationModel1] = useState(false);
  const [confirmationTitle1, setConfirmationTitle1] = useState("");
  const [confirmationBody1, setConfirmationBody1] = useState("");

  useEffect(() => {
    const initClient = () => {
      gapi.client.init({
        clientId:
          "837678588128-701v5ikaevb6ahi5bv0ps700q7lqit9d.apps.googleusercontent.com",
        scope: "",
      });
    };
    setTimeout(() => {
      // @ts-ignore
      gapi.load("client:auth2", initClient);
    }, 1000);
  });

  const onSuccess = (res) => {
    const req = {
      email: res.profileObj.email,
      googleLogin: true,
    };
    setDetails({ ...details, email: res.profileObj.email })
    userLogin(req);
  };

  const onFailure = (err) => {
    console.log("failed:", err);
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    setDetails({ ...details, [name]: value });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (email.trim() === "" || password.trim() === "") {
      // return alert("Please enter email and password");
      setErrorMsg("Please enter email and password")
      setShowErrorModal(true);
    }
    else {
      userLogin(details);
    }

  };

  const userLogin = async (details) => {
    try {
      const response = await signIn(details);
      localStorage.setItem("auth", response.data.token);
      setAuthenticated();
      setUserProfile();
      if (location.state?.from) {
        navigate(location.state.from.pathname)
      } else {
        navigate("/")
      }
    } catch (error) {
      // alert(error?.response?.data?.error);
      console.log(error);
      if (error?.response?.status === 403) {
        setConfirmationTitle1("Welcome back to SpotLet!");
        setConfirmationBody1([`We're thrilled to have you back.`, `To reactivate your account, please click "OK".`, `An account activation link will be sent to your registered email address.`]);
        setShowConfirmationModel1(true);
      } else {
        setErrorMsg(error?.response?.data?.error)
        setShowErrorModal(true);
      }
    }
  };

  const handleOk = async () => {
    try {
      setShowConfirmationModel1(false)
      await sendActivationLink({ email: details.email })
      setConfirmationTitle("SpotLet");
      setConfirmationBody("Check your email for activation link");
      setShowConfirmationModel(true);
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    // Define the 'otpless' function
    window.otpless = (otplessUser) => {
      // Retrieve the user's details after successful login
      const waName = otplessUser.waName;
      const waNumber = otplessUser.waNumber;

      //alert("WhatsApp Login success : " + otplessUser.waName + " - " +otplessUser.waNumber);

      const req = {
        firstName: otplessUser.waName,
        mobile: otplessUser.waNumber,
        whatsappLogin: true,
      };
      userLogin(req);
    };
  }, []);


  useEffect(() => {
    const token = localStorage.getItem("auth");
    if(token){
      let decoded = jwt_decode(token);
      if (Math.floor(Date.now() / 1000) < decoded?.exp) {
        navigate('/')
      }
    }
  }, []);

  return (
    <div className="container-fluid p-0">
      <div className="row g-0">
        <div className="col-md-8 col-lg-6">
          <div className="login d-flex align-items-center py-5">
            <div className="container">
              <div className="row">
                <div className="col-md-10 col-lg-9 col-11 mx-auto">
                  <h3 className="login-heading">
                    Log In to <img src={logoName} />
                  </h3>
                  <p className="login-text mb-4">
                    Log In with Google or enter your email and password to
                    access your account.
                  </p>
                  <form onSubmit={handleSubmit}>
                    <div className="row">
                      <div className="col-lg-12">
                        <div className="mb-4">
                          <label htmlFor="floatingInput" className="form-label">
                            Email<span className="required">*</span>
                          </label>
                          <input
                            type="email"
                            className="form-control"
                            id="uemail"
                            name="email"
                            onChange={handleChange}
                            value={email}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="row">

                      <div className="col-lg-12">
                        <div className="mb-4">
                          <label htmlFor="floatingInput">
                            Password<span className="required">*</span>
                          </label>
                          <div className="form-floating mb-3">
                            <input
                              type={!hidePassword ? "password" : "text"}
                              className="form-control"
                              id="upwd"
                              name="password"
                              onChange={handleChange}
                              password={password}
                            />
                            <div className="input-group-addon">
                              <i
                                onClick={() =>
                                  setHidePassword((hidePassword) => !hidePassword)
                                }
                                className={
                                  hidePassword
                                    ? "fa fa-eye pointer"
                                    : "fa fa-eye-slash pointer"
                                }
                                aria-hidden="true"
                              ></i>
                            </div>
                          </div>
                        </div>
                        <div className="fgt-pwd text-end">
                          <Button
                            variant="link"
                            onClick={() => setShowForgotPasswordModal(true)}
                          >
                            Forgot Password?
                          </Button>
                        </div>
                      </div>
                    </div>

                    <div className="d-grid">
                      <button
                        className="btn btn-lg btn-primary btn-login text-uppercase fw-bold mb-2"
                        type="submit"
                      >
                        Log In
                      </button>
                    </div>
                    <div className="other-login">
                      <GoogleLogin
                        clientId={googleClientId}
                        buttonText="Log In with Google"
                        onSuccess={onSuccess}
                        onFailure={onFailure}
                        cookiePolicy={"single_host_origin"}
                        isSignedIn={false}
                        className="btn btn-border fw-bolder text-dark"
                      />
                      {/* <div id="otpless"  className="btn btn-border"></div> */}
                      <button className="btn btn-border text-uppercase fw-bold disabled">
                        <img src={whatsup} /> Log In with WhatsApp
                      </button>
                    </div>

                    <div className="alreadyac">
                      <p className="mt-5">
                        Don’t have an account?{" "}
                        <a
                          className="pointer"
                          onClick={() => navigate("/signup")}
                        >
                          Register
                        </a>
                      </p>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="d-none d-md-flex col-md-4 col-lg-6 bg-image loginbg"></div>
      </div>
      {showConfirmationModel && (
        <ConfirmationModel
          show={showConfirmationModel}
          handleClose={() => setShowConfirmationModel(false)}
          handleOk={() => setShowConfirmationModel(false)}
          title={confirmationTitle}
          body={confirmationBody}
        />
      )}
      {showConfirmationModel1 && (
        <ActivationModel
          show={showConfirmationModel1}
          handleClose={() => setShowConfirmationModel1(false)}
          handleOk={handleOk}
          title={confirmationTitle1}
          body={confirmationBody1}
        />
      )}
      {showForgotPasswordModal && (
        <ForgotPasswordModal
          show={showForgotPasswordModal}
          handleClose={() => setShowForgotPasswordModal(false)}
        />
      )}
      {showErrorModal && <InformationModal
        show={showErrorModal}
        handleClose={() => setShowErrorModal(false)}
        title='Log In'
        message={errorMsg}
      />}
    </div>
  );
};
