import { Link } from "react-router-dom";
import { useLocation, useNavigate } from 'react-router-dom'
import { useAuthStore } from "../store/auth";

// Images
import editProfile from "../assets/NewImages/Sidebar/edit-profile.svg";
import billing from "../assets/NewImages/Sidebar/bolling.svg";
import myListing from "../assets/NewImages/Sidebar/my-listing.svg";
import calendar from "../assets/NewImages/Sidebar/calendar.svg";
import review from "../assets/NewImages/Sidebar/review.svg";
import logout from "../assets/NewImages/Sidebar/logout.svg";

import { MdKeyboardArrowRight } from "react-icons/md";
import { useState,useEffect } from "react";
import { ISO_8601 } from "moment";

// mui icons
import StickyNote2Icon from '@mui/icons-material/StickyNote2';
import ListAltOutlinedIcon from '@mui/icons-material/ListAltOutlined';
import CalendarMonthOutlinedIcon from '@mui/icons-material/CalendarMonthOutlined';
import ManageAccountsOutlinedIcon from '@mui/icons-material/ManageAccountsOutlined';
import ChatOutlinedIcon from '@mui/icons-material/ChatOutlined';
import LogoutOutlinedIcon from '@mui/icons-material/LogoutOutlined';
import NotificationsActiveOutlinedIcon from '@mui/icons-material/NotificationsActiveOutlined';

export const Sidebar = () => {
  const { setAuthenticated,setUserProfileNull,userProfile } = useAuthStore();
  const location = useLocation()?.pathname;
  const navigate = useNavigate();
  const [isOpen,setIsOpen] = useState(false)
  useEffect(()=> {
    if(location === '/listed-properties' || location === '/incomplete-listings' || location === '/booking-requests'){
      setIsOpen(true)
    }else{
      setIsOpen(false)
    }
  },[]);
  const onLogout = (event) => {
    event.preventDefault();
    event.stopPropagation();
    localStorage.removeItem("auth");
    setAuthenticated();
    setUserProfileNull()
    navigate("/");
  };

  return (
    <div className="side-bar">
      <ul>
        <li>
          <Link className={location == '/edit-profile' ? 'active' : ''} to="/edit-profile">
            {/* <img src={editProfile} /> */}
              <ManageAccountsOutlinedIcon style={{marginRight : "14px"}} />
            Edit Profile
          </Link>
        </li>
        {/* <li>
          <Link className={location == '/billing' ? 'active' : ''} to="/billing">
            <img src={billing} />
            Billing
          </Link>
        </li> */}
        {(userProfile?.listings > 0 || userProfile?.incompleteListing > 0) && (
          <>
            <li>
                <Link to={isOpen ? '' : '/listed-properties'} onClick={()=>setIsOpen(!isOpen)}>
                  {/* <img src={myListing} /> */}
                  <ListAltOutlinedIcon  style={{marginRight : "14px"}} />
                  My Listings
                </Link>
            </li>
            {isOpen && (
              <>
                <li className="mx-4 mb-1">
                  <Link className={location == '/listed-properties' ? 'active SpotLetContent text-warning' : 'SpotLetContent text-warning'} to="/listed-properties">
                  <MdKeyboardArrowRight /> {' '}Listed Properties
                  </Link>
                </li>
                <li className="mx-4 mb-1">
                  <Link className={location == '/incomplete-listings' ? 'active SpotLetContent text-warning' : 'SpotLetContent text-warning'} to="/incomplete-listings">
                  <MdKeyboardArrowRight /> {' '}Incomplete Listings
                  </Link>
                </li>
                <li className="mx-4 mb-1">
                  <Link className={location == '/booking-requests' ? 'active SpotLetContent text-warning' : 'SpotLetContent text-warning'} to="/booking-requests">
                    <MdKeyboardArrowRight /> {' '}Booking Requests
                  </Link>
                </li>
              </>
            )}
          </>
        )}
        {userProfile?.bookings > 0 && (
          <li>
            <Link className={location == '/my-bookings' ? 'active' : ''} to="/my-bookings">
              {/* <img src={myListing} /> */}
              <StickyNote2Icon  style={{marginRight : "14px"}} />
              My Bookings
            </Link>
          </li>
        )}
        <li>
          <Link className={location == '/my-enquiries' ? 'active' : ''} to="/my-enquiries">
            <ChatOutlinedIcon style={{marginRight : "14px"}} />
            My Enquiries
          </Link>
        </li>
        <li>
          <Link className={location == '/notifications' ? 'active' : ''} to="/notifications">
            {/* <img src={review} /> */}
            <NotificationsActiveOutlinedIcon  style={{marginRight : "14px"}} />
            Notifications
          </Link>
        </li>
        {/* <li>
          <Link className={location == '/coupons' ? 'active' : ''} to="/coupons">
            <img src={myListing} />
            My Coupons
          </Link>
        </li> */}
        <li>
          <Link className={location == '/calendar' ? 'active' : ''} to="/calendar">
            {/* <img src={calendar} /> */}
            <CalendarMonthOutlinedIcon  style={{marginRight : "14px"}} />
            Calendar
          </Link>
        </li>
        {/* <li>
          <Link className={location == '/reviews' ? 'active' : ''} to="/reviews">
            <img src={review} />
            Reviews
          </Link>
        </li> */}
        <li>
          <a href="javascript:void(0)" onClick={onLogout}>
            {/* <img src={logout} /> */}
            <LogoutOutlinedIcon  style={{marginRight : "14px"}} />
            Logout
          </a>
        </li>
      </ul>
    </div>
  );
};
