import { useState,useEffect } from 'react';
import building from '../assets/images/building.svg'
import profileMan from '../assets/images/profileMan.svg'
import vectorPhone from '../assets/images/vectorPhone.svg'
import vectorMail from '../assets/images/vectorMail.svg'
import staticImage from "../assets/images/getInTouch.jpeg"
import { sendGetInTouch } from '../../../apis/api';
import { useAuthStore } from "../../../store/auth";
import PhoneInput, {isValidPhoneNumber} from 'react-phone-number-input'
import 'react-phone-number-input/style.css';
import {PropertyPageInformationModal} from "../Modals/PropertyPageInformationModal"
import AuthenitcateModal from '../../../utils/NewAuthentication/AuthenitcateModal';

const GetInTouch = ({locationId,image, approveEnquiries}) => {
    const { userProfile,setUserProfile } = useAuthStore();
    const [formData,setFormData] = useState({});
    const [numberError, setNumberError] = useState("");
    const [showModal,setShowModal] = useState(false);
    const [modalBody,setModalBody] = useState('');

    const onClickSubmit = (e) => {
        e.preventDefault();
        setNumberError((prev) => '');
        
        let phoneNumberError = false;

        //check phone number validation
        if (formData?.mobileNumber === undefined) {
            phoneNumberError = true
             setNumberError("Enter Mobile Number")
        } 
        if(!phoneNumberError){
            if (formData?.mobileNumber.slice(0,4) === "+911" || !isValidPhoneNumber(formData?.mobileNumber)) {
                phoneNumberError = true
                setNumberError((prev) => "Enter a Valid Mobile Number")
            } 
        }
        //
         //if no error handle below function
         if(!phoneNumberError) {
            if(!userProfile){
                setShowSignUp(true)
                return null
            }
            handleSubmit();
        }

    }

    const handleSubmit = async () => {
        try{
            const resp = await sendGetInTouch({...formData,locationId,userId : userProfile?._id})
            setModalBody(resp.data)
            setShowModal(true)
            const form = document.getElementById('getInTouchForm')
            form.reset()
            setFormData({});
        }catch(error){
            console.log(error)
            setModalBody(error?.response?.data?.message)
            setShowModal(true)
        }
    }

    const onChange = (e) => {
        const {name,value} = e.target
        setFormData({
            ...formData,
            [name] : value
        })

    }

    //Lead start
    const [showSignUp,setShowSignUp] = useState(false)
    const handleLead = async () => {
        const user = await setUserProfile()
            if(user){
                try{
                    const resp = await sendGetInTouch({...formData,locationId,userId : user?._id})
                    setModalBody(resp.data)
                    setShowModal(true)
                    const form = document.getElementById('getInTouchForm')
                    form.reset()
                    setFormData({});
                }catch(error){
                    console.log(error)
                    setModalBody(error)
                    setShowModal(true)
                }
            }
    }
    //Lead end

    return(
        <div className="formMainContainer">
            <div className='row formFullContainer'>
                <div className="formContainer col-lg-6">
                    <span className="formMainHeading SpotLetMainHead" id='propertyPage11'>Get In Touch</span>
                    <span className="formMainText SpotLetContent">Contact us now to unlock exclusive property insights and explore your dream space with ease!</span>
                    <form className="row" onSubmit={onClickSubmit} id='getInTouchForm'>
                        <div className="col-lg-6 mb-4">
                            <label className="propertyPageFormLabel SpotLetContent ">Your Organization Name</label>
                            <div className='propertyFormInputContainer'>
                                <input
                                    className="propertyPageFormInput SpotLetContent "
                                    placeholder="Your Organisation Name"
                                    type='text'
                                    name='organization'
                                    onChange={onChange}
                                />
                                <img src={building} alt='building' className='propertyPageFormSvg'/>
                            </div>
                        </div>
                        <div className="col-lg-6 mb-4">
                            <label className="propertyPageFormLabel SpotLetContent ">Contact Person Name{' '}<span className='required'>*</span></label>
                            <div className='propertyFormInputContainer'>
                                <input
                                    className="propertyPageFormInput SpotLetContent "
                                    placeholder="Contact Person Name"
                                    type='text'
                                    name='name'
                                    required
                                    onChange={onChange}
                                />
                                <img src={profileMan} alt='building' className='propertyPageFormSvg'/>
                            </div>
                        </div>
                        <div className="col-lg-6 mb-4">
                            <label className="propertyPageFormLabel SpotLetContent ">Mobile Number{' '}<span className='required'>*</span></label>
                            <div className='propertyFormInputContainer'>
                                {/* <input
                                    className="propertyPageFormInput SpotLetContent "
                                    placeholder="Mobile Number"
                                    type="text"
                                    pattern="[789][0-9]{9}"
                                    title="Please enter a valid phone number"
                                    name='mobileNumber'
                                    required
                                    onChange={onChange}
                                /> */}
                                <PhoneInput
                                    required
                                    international
                                    name='mobileNumber'
                                    countryCallingCodeEditable={false}
                                    flags
                                    placeholder="Mobile Number"
                                    className="propertyPageFormInput SpotLetContent "
                                    style={{height:"51px"}}
                                    value={formData?.mobileNumber}
                                    defaultCountry="IN"
                                    onChange={(e) => {
                                        setFormData({
                                            ...formData,
                                            mobileNumber : e,
                                        })
                                    }  
                                }
                                />
                                <img src={vectorPhone} alt='building' className='propertyPageFormSvg'/>
                            </div>
                            {numberError && <p className='mb-0' style={{color:"#EA4335"}}> {numberError} </p>}
                        </div>
                        <div className="col-lg-6 mb-4">
                            <label className="propertyPageFormLabel SpotLetContent ">Email{' '}<span className='required'>*</span></label>
                            <div className='propertyFormInputContainer'>
                                <input
                                    pattern="^[^\s@]+@[^\s@]+\.[^\s@]{2,}$"
                                    title="info@example.org"
                                    className="propertyPageFormInput SpotLetContent "
                                    placeholder="Email"
                                    type='mail'
                                    name='email'
                                    required
                                    onChange={onChange}
                                />
                                <img src={vectorMail} alt='building' className='propertyPageFormSvg'/>
                            </div>
                        </div>
                        <div className="d-flex flex-column col-lg-12">
                            <label className="propertyPageFormLabel SpotLetContent ">Message</label>
                            <textarea
                                className="propertyPageFormTextArea text-area-preserved-whitespace"
                                placeholder="Message"
                                name='message'
                                onChange={onChange}
                            />
                        </div>
                        <div className='col-lg-6'>
                            <button
                            disabled = {!approveEnquiries}
                            className={`propertyFormSubmit SpotLetContent ${!approveEnquiries && "buttonDisabled"}`}
                            type='submit'
                            >Submit</button>
                        </div>
                    </form>
                </div>
                <div className="formVectorImage col-lg-6">
                    <img src={staticImage} alt='form' />
                </div>
            </div>
            {showModal && (
                <PropertyPageInformationModal
                show = {showModal}
                title = 'SpotLet'
                content = {modalBody}
                handleClose = {()=>setShowModal(false)}
                />
            )}
            {showSignUp && 
                <AuthenitcateModal
                    show={showSignUp}
                    handleSuccess={()=> {
                        setShowSignUp(false)
                        handleLead()
                    }}
                    handleClose={()=>setShowSignUp(false)}
                    activeTab=''
                />
            }
        </div>
    )
}
export default GetInTouch;