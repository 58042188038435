import React, { useState, useEffect } from 'react';
import { Box, Typography, Divider } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import moment from 'moment';
// import Rating from '@mui/material/Rating';
import { getBookingDetails, createUserReview, createHostReview, updateUserReview, updateHostReview } from "../../apis/api"
import { useAuthStore } from "../../store/auth";
import { Header } from "../../components/NewHeader/Header"
import { Footer } from "../../components/Footer"
import TopTabs from "../PropertPage/Component/TopTabs"
import GoogleMap from './GoogleMap/Map'
import { PageLoader } from "../../components/PageLoader";

import "./BookingDetails.css"

// Images
import locationIcon from "../../assets/NewImages/BookingDetails/Group 54.svg";
import staticProfileImage from "../../assets/NewImages/BookingDetails/user_profile_image.webp"

const BookingDetails = () => {
  const params = useParams()
  const navigate = useNavigate();
  const { userProfile } = useAuthStore();
  const bookingId = params.id
  const [loader, setLoader] = useState(false)
  const [bookingData, setBookingData] = useState({
    userReview: {},
    hostReview: {},
  })
  const [userEdit, setUserEdit] = useState(false);
  const [hostEdit, setHostEdit] = useState(false);


  const [formData, setFormData] = useState({
    userId: userProfile?._id,
    bookingId: bookingId,
    locationId: "",
    rating: 0,
    reviewHeader: '',
    reviewContent: '',
  })

  const onChange = (e) => {
    const { name, value } = e.target
    setFormData({
      ...formData,
      [name]: value,
    })
  }

  const submitUserReview = async (event) => {
    event.preventDefault()
    setLoader(true)
    try {
      if (userEdit) {
        await updateUserReview(formData, bookingData?.locationId)
      } else {
        await createUserReview(formData, bookingData?.locationId)
      }
      setUserEdit(false)
      getDetails();
    } catch (error) {
      console.log(error)
      setLoader(false)
    }
  }

  const submitHostReview = async (event) => {
    event.preventDefault()
    setLoader(true)
    try {
      if (hostEdit) {
        await updateHostReview(formData, bookingData?.userId)
      } else {
        await createHostReview(formData, bookingData?.userId)
      }
      setHostEdit(false)
      getDetails();
    } catch (error) {
      console.log(error)
      setLoader(false)
    }
  }

  const getDetails = async () => {
    try {
      const response = await getBookingDetails(bookingId)
      setBookingData(response?.data)
      setFormData({ ...formData, locationId: response?.data?.locationId })
      setLoader(false)
    } catch (e) {
      console.log(e);
      setLoader(false)
    }
  }

  const editUserReview = () => {
    setUserEdit(true)
    setFormData({
      ...formData,
      rating: bookingData?.userReview?.rating,
      reviewHeader: bookingData?.userReview?.reviewHeader,
      reviewContent: bookingData?.userReview?.reviewContent,
    })
  }

  const editHostReview = () => {
    setHostEdit(true)
    setFormData({
      ...formData,
      rating: bookingData?.hostReview?.rating,
      reviewHeader: bookingData?.hostReview?.reviewHeader,
      reviewContent: bookingData?.hostReview?.reviewContent,
    })
  }

  const handleInvoice = () => {
    navigate(`/invoice/${bookingId}`)
  }

  useEffect(() => {
    window.scrollTo(0, 0);
    setLoader(true)
    getDetails();
  }, [bookingId])


  return (
    <>
      <Header />
      <Box className="bookingDetailsContainer">
        <Box className="coverBox">
          <TopTabs absolute={true} />
          <Box className="backButtonBox">
            <Box onClick={() => navigate(-1)} className="backButton">
              <svg xmlns="http://www.w3.org/2000/svg" width="8" height="15" viewBox="0 0 8 15" fill="none">
                <path d="M6.5375 14.4479C6.37917 14.4479 6.22084 14.3896 6.09584 14.2646L0.6625 8.83125C-0.220833 7.94792 -0.220833 6.49792 0.6625 5.61458L6.09584 0.18125C6.3375 -0.0604167 6.7375 -0.0604167 6.97917 0.18125C7.22084 0.422917 7.22084 0.822917 6.97917 1.06458L1.54583 6.49792C1.14583 6.89792 1.14583 7.54792 1.54583 7.94792L6.97917 13.3812C7.22084 13.6229 7.22084 14.0229 6.97917 14.2646C6.85417 14.3812 6.69584 14.4479 6.5375 14.4479Z" fill="#1A1E25" />
              </svg>
              <Typography ml={1}>Go back</Typography>
            </Box>
          </Box>

          <Box className="coverPhotoBox">
            <Box className="coverPhoto">
              <img src={bookingData?.image} />
            </Box>
          </Box>

          <Box className="venueLocation venueLocationH">
            <Box mt={2} className="locationHeader">
              <Typography className='poppinsBold' variant='h5'>{bookingData?.locationId}</Typography>
              {/* <Typography sx={{ marginLeft: "10px", }} className='poppinsBold' variant='h5'>Serendipity Labs</Typography> */}
              {/* <Box onClick={() => setPropertyNameVisibility(true)} className="ClickHereTab">
                <Typography id='clickHereText'>Click Here</Typography>
              </Box> */}
            </Box>
            {/* <Box className="locationHeader">
                {bookingData?.subPropertyName !== 'NA' && (
                  <Typography className='poppinsBold' variant='h5'>Sub property : ({bookingData?.subPropertyName})</Typography>
                )}
            </Box> */}
            <Typography className='locationId'>
              {bookingData?.locationType}
            </Typography>

            <Box className="locationSubText">
              <Box className="locationLogo"></Box>
              <Box className="CityLocation">
                <Box className="cityLocationTextBox">
                  <img src={locationIcon} style={{ marginRight: "15px" }} />
                  <Typography className='poppins'>{`${bookingData?.locationAddress?.area},${bookingData?.locationAddress?.city},${bookingData?.locationAddress?.state}`}</Typography>
                </Box>
                <Divider className='divider' sx={{ margin: "0px 10px" }} orientation="vertical" flexItem />
                <Box className="cityLocationTextBox cityLocationTextBox2">
                {/* <Rating
                    name="half-rating-read"
                    value={bookingData?.avarageRating || 0}
                    readOnly
                    precision={0.5}
                  /> */}
                  <Typography className='poppins' sx={{ color: "#888888" }}> {bookingData?.propertyViews} Views</Typography>
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>


        <Box className="BookingDetailContinent">

          {/* <Box className="visitSummary BookingDetailBoxCard">
            <Box className="DetailBoxCardHeader">
              <Typography>Visit Summary</Typography>
            </Box>
            <Box className="DetailContent VisitSummaryContent">

              <Box className="mobileView">
                <Box className="DetailsCardRowBox">
                  <Box className="labelBox">
                    <Typography>Visitor Name</Typography>
                    <Typography>Visitor Contact number</Typography>
                  </Box>
                  <Box className="dataBox">
                    <Typography className='fontWeight'>Jhon Doe</Typography>
                    <Typography className='fontWeight'>123-456-7890</Typography>
                  </Box>
                </Box>
                <Box className="DetailsCardRowBox">
                  <Box className="labelBox">
                    <Typography>Visit Date</Typography>
                    <Typography>Visit Time</Typography>
                  </Box>
                  <Box className="dataBox">
                    <Typography className='fontWeight'>24/03/2023</Typography>
                    <Typography className='fontWeight'>09:42 pm</Typography>
                  </Box>
                </Box>
                <Box className="DetailsCardRowBox">
                  <Box className="labelBox">
                    <Typography pr={1.5}>Alternate contact name</Typography>
                    <Typography>Alternate contact number</Typography>
                  </Box>
                  <Box className="dataBox">
                    <Typography className='fontWeight'>Stiven Doe</Typography>
                    <Typography className='fontWeight'>123-456-7890</Typography>
                  </Box>
                </Box>
                <Box className="DetailsCardRowBox">
                  <Box className="labelBox">
                    <Typography>Number of persons visiting</Typography>
                    <Typography>Visit Status</Typography>
                  </Box>
                  <Box className="dataBox">
                    <Typography className='fontWeight'>20</Typography>
                    <Typography className='fontWeight GreenText'>Complete</Typography>
                  </Box>
                </Box>
              </Box>


              <Box className="desktopView">
                <Box className="DetailsCardRowBox">
                  <Box className="labelBox">
                    <Typography>Visitor Name</Typography>
                    <Typography>Visitor Contact number</Typography>
                    <Typography>Visit Date</Typography>
                    <Typography>Visit Time</Typography>
                  </Box>
                  <Box className="dataBox">
                    <Typography className='fontWeight'>Jhon Doe</Typography>
                    <Typography className='fontWeight'>123-456-7890</Typography>
                    <Typography className='fontWeight'>24/03/2023</Typography>
                    <Typography className='fontWeight'>09:42 pm</Typography>
                  </Box>
                </Box>
                <Box className="DetailsCardRowBox">
                  <Box className="labelBox">
                    <Typography>Alternate contact name</Typography>
                    <Typography>Alternate contact number</Typography>
                    <Typography>Number of persons visiting</Typography>
                    <Typography>Visit Status</Typography>
                  </Box>
                  <Box className="dataBox">
                    <Typography className='fontWeight'>Stiven Doe</Typography>
                    <Typography className='fontWeight'>123-456-7890</Typography>
                    <Typography className='fontWeight'>20</Typography>
                    <Typography className='fontWeight GreenText'>Complete</Typography>
                  </Box>
                </Box>
              </Box>
              <Box className="DetailsCardRowBox specialTopMargin">
                <Typography className="SpecialRequestsText">Special requests:</Typography>
                <Box className="SpecialRequestsBox">
                  <Box className="SpecialRequestsItem">
                    <img src={RightArrow} />
                    <Typography>If for any reason you wish to cancel a service before your flight </Typography>
                  </Box>
                  <Box className="SpecialRequestsItem">
                    <img src={RightArrow} />
                    <Typography>wish to cancel a service before your flight </Typography>
                  </Box>
                  <Box className="SpecialRequestsItem">
                    <img src={RightArrow} />
                    <Typography>If for any reason you wish to cancel a service before your flight.</Typography>
                  </Box>
                </Box>
              </Box>
            </Box>

          </Box> */}

          {/* <Box className="visitSummary BookingDetailBoxCard">
            <Box className="DetailBoxCardHeader">
              <Typography>Booking Summary</Typography>
            </Box>
            <Box className="DetailContent VisitSummaryContent">
              <Box className="mobileView">

                <Box className="DetailsCardRowBox">
                  <Box className="labelBox">
                    <Typography className='e6px'>Booking ID</Typography>
                    <Typography>Location</Typography>
                  </Box>
                  <Box className="dataBox" style={{ justifyContent: "flex-start" }}>
                    <Typography className='fontWeight e6px'>{bookingId}</Typography>
                    <div >
                      <Typography className='fontWeight'>{`${bookingData?.locationAddress?.area},`}</Typography>
                      <Typography className='fontWeight'>{`${bookingData?.locationAddress?.city},${bookingData?.locationAddress?.state}`}</Typography>
                    </div>
                  </Box>
                </Box>
                <Box className="DetailsCardRowBox">
                  <Box className="labelBox">
                    <Typography pr={1.5} className='e6px'>Booking Requested Date</Typography>
                    <Typography>Booking Amount</Typography>
                  </Box>
                  <Box className="dataBox">
                    <Typography className='fontWeight e6px'>{moment(bookingData?.bookingRequestedDate).format('DD-MM-YYYY HH:mm:ss')}</Typography>
                    <Typography className='fontWeight RedText'>INR {bookingData?.totalPayment}</Typography>
                  </Box>
                </Box>
                <Box className="DetailsCardRowBox">
                  <Box className="labelBox">
                    <Typography pr={1.5}>Booking Status</Typography>

                  </Box>
                  <Box className="dataBox">
                    <Box className="eventDateBox fontS11">
                      <Typography className='fontWeight GreenText'>{bookingData?.status}</Typography>
                      <Typography className='fontWeight'>03/04/2023   09.00am - 05.30pm</Typography>
                      <Typography className='fontWeight'>03/04/2023   09.00am - 05.30pm</Typography>
                    </Box>
                    <Box className="fontS11 bATextBox">
                      <Typography className='fontWeight'>{(locationData?.status === 'Approved' ||  locationData?.status === 'Confirmed') && moment(locationData?.hostResponseDate).format('DD-MM-YYYY HH:mm:ss')}</Typography>
                    </Box>
                  </Box>
                </Box>
                <Box className="DetailsCardRowBox">
                  <Box className="labelBox">
                    <Typography>{bookingData?.status === 'Rejected' && "Booking Rejected Date"}</Typography>
                    <Typography></Typography>
                  </Box>
                  <Box className="dataBox">
                    <Typography className='fontWeight e6px'></Typography>
                    <Typography className='fontWeight e6px'>{locationData?.status === 'Rejected' && moment(locationData?.hostResponseDate).format('DD-MM-YYYY HH:mm:ss')}</Typography>
                    <Typography className='fontWeight GreenText'>{locationData?.status}</Typography>
                  </Box>
                </Box>

              </Box>

              <Box className="desktopView">
                <Box className="DetailsCardRowBox">
                  <Box className="labelBox">
                    <Typography className='e6px'>Booking ID</Typography>
                    <Typography>Location</Typography>
                    <Typography>Booking Requested Date</Typography>
                    <Typography>Booking Amount</Typography>
                  </Box>
                  <Box className="dataBox">
                    <Typography className='fontWeight e6px'>{bookingId}</Typography>
                    <Typography className='fontWeight'>{`${bookingData?.locationAddress?.area},${bookingData?.locationAddress?.city},${bookingData?.locationAddress?.state}`}</Typography>
                    <Typography className='fontWeight'>{moment(bookingData?.bookingRequestedDate).format('DD-MM-YYYY HH:mm:ss')}</Typography>
                    <Typography className='fontWeight RedText'>INR {bookingData?.totalPayment}</Typography>
                  </Box>
                </Box>
                <Box className="DetailsCardRowBox">
                  <Box className="labelBox fontS11 textCenter">
                    <Typography className='e6px' >Booking Status  </Typography>

                    <Typography>{bookingData?.status === 'Rejected' && "Booking Rejected Date"}</Typography>
                    <Typography></Typography>
                  </Box>
                  <Box className="dataBox dataBoxAlign">
                    <Box className="eventDateBox">
                      <Typography className='fontWeight GreenText'>{bookingData?.status}</Typography>
                    </Box>
                    <Typography className='fontWeight'>{(locationData?.status === 'Approved' ||  locationData?.status === 'Confirmed') && moment(locationData?.hostResponseDate).format('DD-MM-YYYY HH:mm:ss')}</Typography>
                    <Typography className='fontWeight'>{locationData?.status === 'Rejected' && moment(locationData?.hostResponseDate).format('DD-MM-YYYY HH:mm:ss')}</Typography>
                    <Typography className='fontWeight GreenText'></Typography>
                  </Box>
                </Box>
              </Box>

            </Box>

          </Box> */}
          {/* Booking Summary Section Start */}
          <div className='booking-summary-card' >
            <Box className="DetailBoxCardHeader d-flex flex-row justify-content-between align-items-center">
              <Typography>Booking Summary</Typography>
            {bookingData?.paymentStatus === 'Completed' && <button type='button' className='btn btn-primary me-2' onClick={()=>handleInvoice()}>Get Invoice</button>}
            </Box>
            <div className='booking-summary-details' >

              <div className='booking-detail' >
                <Box className="labelBox">
                  <Typography>Booking Id</Typography>
                </Box>
                <Box className="dataBox">
                  <Typography className='fontWeight e6px'>{bookingId}</Typography>
                </Box>
              </div>

              <div className='booking-detail' >
                <Box className="labelBox">
                  <Typography>Location</Typography>
                </Box>
                <div className="d-flex flex-row justify-content-start align-items-star" style={{flexWrap : 'wrap'}}>
                  <span className='fontWeight location-detail'>{`${bookingData?.locationAddress?.area},`}</span>
                  <span className='fontWeight location-detail'>{`${bookingData?.locationAddress?.city},`}</span>
                  <span className='fontWeight location-detail'>{`${bookingData?.locationAddress?.state}`}</span>
                </div>
              </div>

              <div className='booking-detail' >
                <Box className="labelBox">
                  <Typography>Booking Requested Date</Typography>
                </Box>
                <Box className="dataBox">
                  <Typography className='fontWeight e6px'>
                    {moment(bookingData?.bookingRequestedDate).format('DD-MM-YYYY HH:mm:ss')}

                  </Typography>
                </Box>
              </div>

              <div className='booking-detail' >
                <Box className="labelBox">
                  <Typography>Booking Status</Typography>
                </Box>
                <Box className="dataBox">
                  <Typography className='fontWeight GreenText'>{bookingData?.status}</Typography>
                </Box>
              </div>

              {/* {
                bookingData?.reason && ( */}
                <div className='booking-detail'>
                  <Box className="labelBox">
                    <Typography>Reason</Typography>
                  </Box>
                  <Box className="dataBox">
                    <Typography className='fontWeight GreenText'>{bookingData?.reason || "NA"}</Typography>
                  </Box>
                </div>
                {/* )
              } */}

              {/* <div className='booking-detail' >
                <Box className="labelBox">
                  <Typography>Booking Amount</Typography>
                </Box>
                <Box className="dataBox">
                  <Typography className='fontWeight RedText'>INR {bookingData?.totalPayment}</Typography>
                </Box>
              </div> */}

            </div>

          </div>
          {/* Booking Summary Section End */}


          {/* Refund Section Start */}
          {
            bookingData?.refundStatus === "processed" && (
            <div className='booking-summary-card' >
                  <Box className="DetailBoxCardHeader">
                    <Typography>Refund Summary</Typography>
                  </Box>
                  <div className='booking-summary-details' >
                    <div className='booking-detail' >
                      <Box className="labelBox">
                        <Typography>Refund Id</Typography>
                      </Box>
                      <Box className="dataBox">
                        <Typography className='fontWeight e6px'>{bookingData?.refundDetails?.id}</Typography>
                      </Box>
                    </div>
                    <div className='booking-detail' >
                      <Box className="labelBox">
                        <Typography>Refund Status</Typography>
                      </Box>
                      <Box className="dataBox">
                        <Typography className='fontWeight e6px'>{bookingData?.refundDetails?.status}</Typography>
                      </Box>
                    </div>
                    <div className='booking-detail' >
                      <Box className="labelBox">
                        <Typography>Refund Amount</Typography>
                      </Box>
                      <Box className="dataBox">
                        <Typography className='fontWeight RedText'>{bookingData?.refundDetails?.currency} {" "} {bookingData?.refundDetails?.amount}</Typography>
                      </Box>
                    </div>
                    <div className='booking-detail' >
                      <Box className="labelBox">
                        <Typography>Refund Requested Date</Typography>
                      </Box>
                      <Box className="dataBox">
                        <Typography className='fontWeight e6px'>
                          {moment.unix(bookingData?.refundDetails?.created_at).format('YYYY-MM-DD HH:mm:ss')}
                        </Typography>
                      </Box>
                    </div>

                    <div className='booking-detail' >
                      <Box className="labelBox">
                        <Typography>Booking Status</Typography>
                      </Box>
                      <Box className="dataBox">
                        <Typography className='fontWeight GreenText'>{bookingData?.status}</Typography>
                      </Box>
                    </div>

                    {/* {
                      bookingData?.reason && ( */}
                      <div className='booking-detail'>
                        <Box className="labelBox">
                          <Typography>Reason</Typography>
                        </Box>
                        <Box className="dataBox">
                          <Typography className='fontWeight GreenText'>{bookingData?.refundDetails?.notes?.notes_key_1 || "NA"}</Typography>
                        </Box>
                      </div>
                      {/* )
                    } */}

                    {/* <div className='booking-detail' >
                      <Box className="labelBox">
                        <Typography>Booking Amount</Typography>
                      </Box>
                      <Box className="dataBox">
                        <Typography className='fontWeight RedText'>INR {bookingData?.totalPayment}</Typography>
                      </Box>
                    </div> */}

                  </div>
            </div>
            )
          }
          {/* Refund Section End */}

          <Box className="lastContentBox eventWidth">
            <Box className="EventSummary lastBookingDetailBoxCard">
              <Box className="DetailBoxCardHeader">
                <Typography>Event Summary</Typography>
              </Box>
              <Box className="DetailContent CancellationSummaryContent event-summary-scroll">
                <Box className="DetailsCardRowBox">
                  <Box className="labelBox">
                    <Typography pr={1.5}>Event and Activity Type</Typography>
                    <Typography>Guests</Typography>
                  </Box>
                  <Box className="dataBox">
                    <Typography className='fontWeight'>{bookingData?.event}</Typography>
                    {bookingData?.event !== 'stay' ? (
                      <Typography className='fontWeight RedText'>{bookingData?.guests}</Typography>
                    ) : (
                      <Typography className='fontWeight RedText'>Adults : {bookingData?.adults}, Children : {bookingData?.children}, Infants : {bookingData?.infants}</Typography>
                    )}
                  </Box>
                </Box>
                <Box className="DetailsCardRowBox">
                  <Box className="labelBox">
                    {bookingData?.event !== 'stay' ? (
                      <Typography>Date & Time</Typography>
                    ) : (
                      <Typography>Check in & Check out</Typography>
                    )}
                  </Box>
                  {bookingData?.event !== 'stay' ? (
                    <Box className="dataBox" sx={{ display: 'flex', flexDirection: 'column' }}>
                      {bookingData?.bookedTimeDates?.map((day, i) => {
                        return (
                          <Typography className='fontWeight'>{moment(day?.date).format('DD-MM-YYYY')}  {day?.start} to {day?.end}</Typography>
                        )
                      })}
                    </Box>
                  ) : (
                    <Typography className='fontWeight'>{moment(bookingData?.checkIn).format('DD-MM-YYYY')} to {moment(bookingData?.checkOut).format('DD-MM-YYYY')}</Typography>
                  )}
                </Box>
                <Box className="AboutProjectTextBox">
                  {/* <Box className="AboutProjectHeading">
                    <Typography>About the project</Typography>
                  </Box> */}
                  <Box className="AboutProjectMainTextBox">
                    {/* <Typography>{bookingData?.activity}</Typography> */}
                    {/* <Typography mb={2}>There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable. If you are going to use a passage of Lorem Ipsum, you need to be sure there isn't anything embarrassing hidden in the middle of text.
                      The majority have suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable. If you are going to use a passage of Lorem Ipsum,  hidden in the middle of text. </Typography>
                    <Typography>The majority have suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable. If you are going to use a passage of Lorem Ipsum, hidden in the middle of text.</Typography> */}
                  </Box>
                </Box>
              </Box>

            </Box>

            {/* {bookingData?.status === 'Confirmed' && ( */}
              <Box className="UserSummary lastBookingDetailBoxCard">
                <Box className="DetailBoxCardHeader">
                  <Typography>User Details</Typography>
                </Box>
                <Box className="DetailContent RefundSummaryContent">

                  <Box className="DetailsCardRowBox">
                    <Box className="labelBox">
                      {/* <Typography>Name of the Project</Typography> */}
                      {/* <Typography>Booking Location as a</Typography> */}
                    </Box>
                    <Box className="dataBox">
                      {/* <Typography className='fontWeight desktopView'>{bookingData?.projectName}</Typography> */}
                      {/* <Typography pr={1.5} className='fontWeight mobileView'>{bookingData?.projectName}</Typography> */}
                      {/* <Typography className='fontWeight'>{bookingData?.bookingAs}</Typography> */}
                    </Box>
                  </Box>
                  <Box className="DetailsCardRowBox">
                    <Box className="labelBox">
                      <Typography pr={1.5}>Contact Person Name</Typography>
                      <Typography>Contact Number</Typography>
                    </Box>
                    <Box className="dataBox">
                      <Typography className='fontWeight'>{bookingData?.contactName}</Typography>
                      <Typography className='fontWeight GreenText'>{bookingData?.mobileNo}</Typography>
                    </Box>
                  </Box>
                  <Box className="DetailsCardRowBox">
                    <Box className="labelBox">
                      <Typography>Email Address</Typography>
                    </Box>
                    <Box className="dataBox">
                      <Typography className='fontWeight RedText'>{bookingData?.email}</Typography>
                    </Box>
                  </Box>

                </Box>
              </Box>
            {/* )} */}
          </Box>

          {/* <Box className="visitSummary BookingDetailBoxCard">
            <Box className="DetailBoxCardHeader">
              <Typography>Rejected Summary</Typography>
            </Box>
            <Box className="DetailContent RejectedSummaryContent">
              <Box className="mobileView">
                <Box className="DetailsCardRowBox">
                  <Box className="labelBox">
                    <Typography className='e6px'>Rejected On</Typography>
                    <Typography>Rejected Reason</Typography>

                  </Box>
                  <Box className="dataBox">
                    <Typography pr={1.5} className='fontWeight e6px'>24/03/2023       08:45pm</Typography>
                    <Typography className='fontWeight'>There are many variations of passages of </Typography>
                  </Box>
                </Box>
                <Box className="DetailsCardRowBox">
                  <Box className="labelBox">
                    <Typography>Notes</Typography>
                  </Box>
                  <Box className="dataBox">
                    <Typography className='fontWeight fullWidth'>There are many variations of passages of Lorem Ipsum available, </Typography>
                  </Box>
                </Box>

              </Box>


              <Box className="desktopView">
                <Box className="DetailsCardRowBox">
                  <Box className="labelBox">
                    <Typography className='e6px'>Rejected On</Typography>
                    <Typography>Rejected Reason</Typography>
                    <Typography>Notes</Typography>
                  </Box>
                  <Box className="dataBox">
                    <Typography className='fontWeight e6px'>24/03/2023       08:45pm</Typography>
                    <Typography className='fontWeight'>There are many variations of passages of </Typography>
                    <Typography className='fontWeight'>There are many variations of passages of Lorem Ipsum available, </Typography>
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box> */}


          {bookingData?.paymentStatus === "Completed" && (
            <Box className="lastContentBox PaymentWidth">
              <Box className="EventSummary lastBookingDetailBoxCard">
                <Box className="DetailBoxCardHeader">
                  <Typography>Payment Summary</Typography>
                </Box>
                <Box className="DetailContent CancellationSummaryContent">
                  <Box className="DetailsCardRowBox">
                    <Box className="labelBox">
                      <Typography>Paid Date & Time</Typography>
                      <Typography>Paid Amount</Typography>
                    </Box>
                    <Box className="dataBox">
                      <Typography className='fontWeight'>{moment(bookingData?.paymentDate).format('DD/MM/YYYY HH:mm:ss')}</Typography>
                      <Typography className='fontWeight RedText'>INR {bookingData?.totalPayment}</Typography>
                    </Box>
                  </Box>
                  <Box className="DetailsCardRowBox">
                    <Box className="labelBox">
                      <Typography>Payment Method</Typography>
                      {bookingData?.couponName && (<Typography>Coupon Applied</Typography>)}
                    </Box>
                    <Box className="dataBox">
                      <Box sx={{ display: "flex", flexDirection: "row" }}>
                        <Typography className='fontWeight'>{bookingData?.paymentDetails?.method}</Typography>
                        {/* <img style={{ width: "37px", height: "27px" }} src={visaIcon} /> */}
                      </Box>
                      <Typography className='fontWeight YellowText'>{bookingData?.couponName}</Typography>
                    </Box>
                  </Box>
                  <Box className="DetailsCardRowBox">
                    <Box className="labelBox">
                      <Typography>Payment Status</Typography>
                    </Box>
                    <Box className="dataBox">
                      <Typography className='fontWeight GreenText'>{bookingData?.paymentStatus}</Typography>
                    </Box>
                  </Box>
                </Box>

              </Box>

              <Box className="HostSummary lastBookingDetailBoxCard">
                <Box className="DetailBoxCardHeader">
                  <Typography>Host Details</Typography>
                </Box>
                <Box className="DetailContent RefundSummaryContent">

                  <Box className="DetailsCardRowBox">
                    <Box className="labelBox">
                      <Typography>Host Name</Typography>
                      <Typography>Host Contact Number</Typography>
                    </Box>
                    <Box className="dataBox">
                      <Typography className='fontWeight'>{bookingData?.hostName}</Typography>
                      <Typography className='fontWeight GreenText'>{bookingData?.hostContactNumber}</Typography>
                    </Box>
                  </Box>
                  <Box className="DetailsCardRowBox">
                    <Box className="labelBox">
                      <Typography>Email Address</Typography>
                    </Box>
                    <Box className="dataBox">
                      <Typography className='fontWeight RedText'>{bookingData?.hostEmail}</Typography>
                    </Box>
                  </Box>

                </Box>
              </Box>
            </Box>
          )}
        </Box>
        {/* {bookingData?.paymentStatus === "Completed" && (
          <Box className="MapBox">
            <Box className="MapHeader">
              <Typography className='MapHeaderText'>
                Property Map
              </Typography>
              <Typography className='MapSubHeaderText'>
                {location?.locationAddress?.address}
              </Typography>
            </Box>
            <Box className="DetailContent MapBoxContent">
              <GoogleMap lat={bookingData?.locationAddress?.latitude} lng={bookingData?.locationAddress?.longitude} place_id={bookingData?.locationAddress?.googlePlaceId} />
            </Box>

          </Box>
        )} */}
        {/* <Box className="lastContentBox lastWidth">
          <Box className="CancellationSummary lastBookingDetailBoxCard">
            <Box className="DetailBoxCardHeader">
              <Typography>Cancellation Summary</Typography>
            </Box>
            <Box className="DetailContent CancellationSummaryContent">
              <Box className="labelBox">
                <Typography className='boxWidth'>Cancellation Reason</Typography>
              </Box>
              <Box mb={1} className="dataBox">
                <Typography className='fontWeight boxWidth'>If for any reason you wish to cancel a service before your flight </Typography>
              </Box>
              <Box className="labelBox">
                <Typography className='boxWidth'>Cancellation Notes</Typography>
              </Box>
              <Box mb={1} className="dataBox">
                <Typography className='fontWeight boxWidth'>If for any reason you wish to cancel a service before your flight </Typography>
              </Box>
              <Box className="labelBox">
                <Typography className='boxWidth'>Cancelled on</Typography>
              </Box>
              <Box className="dataBox">
                <Typography className='fontWeight boxWidth'>24/03/2020    09:42pm</Typography>
              </Box>

            </Box>

          </Box>

          <Box className="RefundSummary lastBookingDetailBoxCard">
            <Box className="DetailBoxCardHeader">
              <Typography>Refund Summary</Typography>
            </Box>
            <Box className="DetailContent RefundSummaryContent">

              <Box className="DetailsCardRowBox">
                <Box className="labelBox">
                  <Typography pr={1.5}>Refund Processed Date</Typography>
                  <Typography>Refund amount</Typography>
                </Box>
                <Box className="dataBox">
                  <Typography pr={1.5} className='fontWeight'>Refund process will be done 1 week</Typography>
                  <Typography className='fontWeight RedText'>INR 40000</Typography>
                </Box>
              </Box>
              <Box className="DetailsCardRowBox">
                <Box className="labelBox">
                  <Typography>Refund method</Typography>
                  <Typography>Refund Status</Typography>
                </Box>
                <Box className="dataBox">
                  <Typography pr={1.5} className='fontWeight'>Refunded to the same account</Typography>
                  <Typography className='fontWeight RedText'>processing</Typography>
                </Box>
              </Box>

            </Box>
          </Box>
        </Box> */}
        {/* {(((bookingData?.bookedTimeDates && (new Date(bookingData?.bookedTimeDates[0].date).setHours(new Date(new Date(bookingData?.bookedTimeDates[0].date).setHours(bookingData?.bookedTimeDates[0].start.slice(0, 2))).getHours() + parseInt(bookingData?.bookedTimeDates[0].no_of_hours)) < new Date())) && bookingData?.status === 'Confirmed' && Object.keys(bookingData?.userReview).length === 0 && bookingData?.userId === userProfile?._id) || userEdit) && (
          <Box className="visitSummary BookingDetailBoxCard">
            <Box className="DetailBoxCardHeader">
              <Typography>User Review</Typography>
            </Box>
            <Box className="DetailContent VisitSummaryContent">
              <form className='reviewMainContainer' onSubmit={submitUserReview}>
                <div className='ratingStarsContainer'>
                  <h5>Rating</h5>
                  <Rating
                    name="rating"
                    value={formData.rating}
                    onChange={(event, newValue) => {
                      setFormData({ ...formData, rating: newValue });
                    }}
                  />
                </div>
                <div className='ratingHeaderContainer'>
                  <h5>Add a headline</h5>
                  <input
                    name='reviewHeader'
                    placeholder={`What's most important to know ?`}
                    value={formData.reviewHeader}
                    onChange={onChange}
                    required
                  />
                </div>
                <div className='ratingContentContainer'>
                  <h5>Add a written review</h5>
                  <textarea
                    rows="4"
                    cols="50"
                    name='reviewContent'
                    placeholder={`What did you like or dislike ?`}
                    value={formData.reviewContent}
                    onChange={onChange}
                    required
                  />
                </div>
                <div className='ratingSubmitContainer'>
                  <button
                    type='submit'
                  >Submit</button>
                </div>
              </form>

            </Box>

          </Box>
        )} */}
        {/* {(((bookingData?.bookedTimeDates && (new Date(bookingData?.bookedTimeDates[0].date).setHours(new Date(new Date(bookingData?.bookedTimeDates[0].date).setHours(bookingData?.bookedTimeDates[0].start.slice(0, 2))).getHours() + parseInt(bookingData?.bookedTimeDates[0].no_of_hours)) < new Date())) && bookingData?.status === 'Confirmed' && Object.keys(bookingData?.hostReview).length === 0 && bookingData?.hostId === userProfile?._id) || hostEdit) && (
          <Box className="visitSummary BookingDetailBoxCard">
            <Box className="DetailBoxCardHeader">
              <Typography>Host Review</Typography>
            </Box>
            <Box className="DetailContent VisitSummaryContent">
              <form className='reviewMainContainer' onSubmit={submitHostReview}>
                <div className='ratingStarsContainer'>
                  <h5>Rating</h5>
                  <Rating
                    name="rating"
                    value={formData.rating}
                    onChange={(event, newValue) => {
                      setFormData({ ...formData, rating: newValue });
                    }}
                  />
                </div>
                <div className='ratingHeaderContainer'>
                  <h5>Add a headline</h5>
                  <input
                    name='reviewHeader'
                    placeholder={`What's most important to know ?`}
                    value={formData.reviewHeader}
                    onChange={onChange}
                    required
                  />
                </div>
                <div className='ratingContentContainer'>
                  <h5>Add a written review</h5>
                  <textarea
                    rows="4"
                    cols="50"
                    name='reviewContent'
                    placeholder={`What did you like or dislike ?`}
                    value={formData.reviewContent}
                    onChange={onChange}
                    required
                  />
                </div>
                <div className='ratingSubmitContainer'>
                  <button
                    type='submit'
                  >Submit</button>
                </div>
              </form>

            </Box>

          </Box>
        )} */}


        {/* {(Object.keys(bookingData?.userReview).length > 0 && !userEdit) && (
          <Box className="visitSummary BookingDetailBoxCard">
            <Box className="DetailBoxCardHeader">
              <Typography>User Review</Typography>
            </Box>
            <Box className="DetailContent VisitSummaryContent">
              <div className='userProfileContainer'>
                <div>
                  <img src={bookingData?.userReview?.profilePic || staticProfileImage} alt='user profile picture' className='userProfilePicture' />
                </div>
                <Typography className='fontWeight e6px'>{bookingData?.userReview?.userName}</Typography>
              </div>
              <div>
                <span className='reviewDate'>Reviewed on {moment(bookingData?.userReview?.date).format('MMMM Do YYYY')}</span>
              </div>
              <div className='ratingContainer'>
                <Rating
                  name="readOnly"
                  value={bookingData?.userReview?.rating}
                  readOnly
                  style={{ marginRight: '6px' }}
                />
                <Typography className='fontWeight e6px'>{bookingData?.userReview?.reviewHeader}</Typography>
              </div>
              <p style={{ color: '#000000' }}>{bookingData?.userReview?.reviewContent}</p>
              {bookingData?.userId === userProfile?._id &&
                <div>
                  <button type='button' className='reviewEditButton' onClick={editUserReview}>Edit</button>
                </div>}
            </Box>
          </Box>
        )} */}

        {/* {(Object.keys(bookingData?.hostReview).length > 0 && !hostEdit) && (
          <Box className="visitSummary BookingDetailBoxCard">
            <Box className="DetailBoxCardHeader">
              <Typography>Host Review</Typography>
            </Box>
            <Box className="DetailContent VisitSummaryContent">
              <div className='userProfileContainer'>
                <div>
                  <img src={bookingData?.hostReview?.profilePic || staticProfileImage} alt='user profile picture' className='userProfilePicture' />
                </div>
                <Typography className='fontWeight e6px'>{bookingData?.hostReview?.userName}</Typography>
              </div>
              <div>
                <span className='reviewDate'>Reviewed on {moment(bookingData?.hostReview?.date).format('MMMM Do YYYY')}</span>
              </div>
              <div className='ratingContainer'>
                <Rating
                  name="readOnly"
                  value={bookingData?.hostReview?.rating}
                  readOnly
                  style={{ marginRight: '6px' }}
                />
                <Typography className='fontWeight e6px'>{bookingData?.hostReview?.reviewHeader}</Typography>
              </div>
              <p style={{ color: '#000000' }}>{bookingData?.hostReview?.reviewContent}</p>
              {bookingData?.hostId === userProfile?._id &&
                <div>
                  <button type='button' className='reviewEditButton' onClick={editHostReview}>Edit</button>
                </div>}
            </Box>
          </Box>
        )} */}

      </Box>
      {loader && <PageLoader />}
      <Footer />
    </>
  )
}
export default BookingDetails