import React, { Component } from 'react';
import axios from 'axios';
import { Map, Marker,InfoWindow, GoogleApiWrapper } from 'google-maps-react';
import { getLatLng, geocodeByPlaceId } from 'react-places-autocomplete';
import { getGky } from "../../utils/baseUrl";

/*global google*/
const reqKey = getGky();
export class MapContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      // for google map places autocomplete
      showingInfoWindow: false,
      activeMarker: {},
      selectedPlace: {},

      mapCenter: {
        lat: this.props?.lat,
        lng: this.props?.lng
      }
    };

    // geocodeByAddress(this.props?.address)
    //   .then(results => getLatLng(results[0]))
    //   .then(latLng => {
    //     console.log('Success latLng : ', latLng);
    //     this.setState({ mapCenter: latLng });
    //   })
    //   .catch(error => console.error('Error', error));


    geocodeByPlaceId(this.props?.place_id)
      .then(results => getLatLng(results[0]))
      .then(latLng => {
        this.setState({ mapCenter: latLng });
      })
      .catch(error => console.error(error));

  }


  handleGetPlaceID = async (latitude, longitude) => {
    try {
      const response = await axios.get(
        `https://maps.googleapis.com/maps/api/geocode/json?latlng=${latitude},${longitude}&key=${reqKey}`
      );

      if (response.data && response.data.results && response.data.results.length > 0) {
        this.props.updateSelectLocation(response.data.results[0].place_id, latitude, longitude);
      } else {
        console.error('No results found');
      }
    } catch (error) {
      console.error('Error fetching Place ID:', error);
    }
  };

  // Handler for when a marker or map is clicked
  onMapClick = (mapProps, map, clickEvent) => {
    // Do something with the clickEvent, e.g., show an InfoWindow with more details
    this.setState({
      mapCenter: {
        lat: clickEvent.latLng.lat(),
        lng: clickEvent.latLng.lng(),
      },
      showingInfoWindow: false
    });
    this.handleGetPlaceID(clickEvent.latLng.lat(), clickEvent.latLng.lng());
  };

  onMarkerDragEnd = ( coord ) => {
    const { latLng } = coord;
    const lat = latLng.lat();
    const lng = latLng.lng();
    console.log(lat,lng)
    this.setState({
      mapCenter: {
        lat: lat,
        lng: lng,
      },
      showingInfoWindow: false
    });
    this.handleGetPlaceID(lat, lng);
  }

  onMarkerClick = (props, marker, e) => {
    console.log(props.name)
    this.setState({
      selectedPlace: props,
      activeMarker: marker,
      showingInfoWindow: !this.state.showingInfoWindow
    });
  }

  render() {
    return (
      <>
        <div id="navi">{this.props?.address}</div>
        <div id="googleMaps">
          <div id="map_render">
            <Map
              zoom={18}
              zoomControl={true}
              mapTypeControl={true}
              // scaleControl={true}
              // streetViewControl={true}
              // rotateControl={true}
              fullscreenControl={false}
              disableDefaultUI={true}
              google={this.props.google}
              // style={{ width: "30%", height: "80%", marginTop: "20px" }}
              style={
                window.innerWidth < 1200
                  ? {
                    width: "100%",
                    height: "280px",
                    paddingTop: "0px",
                  }
                  : {
                    width: "100%",
                    height: "440px",
                    marginTop: "20px",
                  }
              }
              containerStyle={
                window.innerWidth < 1200
                  ? { width: "95%", height: "22%", position: "absolute" }
                  : { width: "32%", height: "22%", position: "absolute" }
              }
              initialCenter={{
                lat: this.state.mapCenter.lat,
                lng: this.state.mapCenter.lng,
              }}
              center={{
                lat: this.state.mapCenter.lat,
                lng: this.state.mapCenter.lng,
              }}
              onClick={this.onMapClick}
            >
              
              <Marker
                onClick={this.onMarkerClick}
                google={this.props.google}
                 icon={{
                  url: 'https://spotlet-files.s3.amazonaws.com/spotlet/images/mapMarker/SpotletMapLogo.png',
                  // anchor: new google.maps.Point(30,30),
                  scaledSize: new google.maps.Size(230,90)
                }}
                name='property location'
                position={{
                  lat: this.state.mapCenter.lat,
                  lng: this.state.mapCenter.lng,
                }}
                draggable={true}
                onDragend={(t, map, coord) => this.onMarkerDragEnd(coord)}
              />
                <InfoWindow
                marker={this.state.activeMarker}
                visible={this.state.showingInfoWindow}
                >
                    <div>
                    Your Location Here!
                    </div>
                </InfoWindow>
            </Map>
          </div>
        </div>
      </>
    );
  }
}

export default GoogleApiWrapper({
  apiKey: reqKey
})(MapContainer)