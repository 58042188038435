import { Button, Modal } from "react-bootstrap";
import { useState } from "react";

const { Header, Footer, Title, Body } = Modal;
export function DeactivateRequestModel({
  handleClose,
  handleOk,
  show,
}) {

    const [formData,setFormData] = useState({})
    const [showTextArea,setShowTextArea] = useState(false)
    const [error,setError] = useState(false)
    const [askAgain,setAskaAgain] = useState(false)

    const onChange = (e) => {
        const {name,value,checked} = e.target
        if(checked){
            setError(false)
            setFormData({...formData,[name] : value})
        }else{
            delete formData[name]
            setFormData(formData)
        }
    }
    const onChangeOther = (e) => {
        if(e.target.checked){
            setShowTextArea(true)
        }else{
            setShowTextArea(false)
            delete formData['other']
            setFormData(formData)
        }
    }

    const submitForm = (e) => {
        setError(false)
        const data = []
        Object.values(formData).map((value)=> data.push(value))
        handleOk(data)
    }

    const handleSubmit = (e) => {
      e.preventDefault()
        if(Object.keys(formData).length === 0){
            setError(true)
            return null
        }
        setAskaAgain(true)
    }


  return (
    <Modal show={show} onHide={handleClose}>
      <Header closeButton>
        <Title style={{fontSize : '16px'}}>Deactivate Account</Title>
      </Header>
      {askAgain ? (
        <Body className="verify-email-modal-body" style={{fontSize : '13px'}}>
          <p>Do you want proceed for deactivation ?</p>
        </Body>
      ) : (
        <Body className="verify-email-modal-body" style={{fontSize : '13px'}}>
          <h6>Why do you want to Deactivate your account ?</h6>
          {error && <p style={{color : 'red'}}>Please share your feedback</p>}
          <form>
              <div className="m-3">
                  <label htmlFor="feedback1">
                    <input className="form-check-input" value='I am no longer in need of SpotLet services.' type="checkbox" id='feedback1' name='feedback1' onChange={onChange}/>
                    <span>I am no longer in need of SpotLet services.</span>
                  </label>
              </div>
              <div className="m-3">
                  <label htmlFor="feedback2">
                    <input className="form-check-input" value='I found alternative platforms that better suit my requirements.' type="checkbox" id='feedback2' name='feedback2' onChange={onChange}/>
                    <span>I found alternative platforms that better suit my requirements.</span>
                  </label>
              </div>
              <div className="m-3">
                  <label htmlFor="feedback3">
                    <input className="form-check-input" value='Privacy concerns: I am uncomfortable with the information sharing practices.' type="checkbox" id='feedback3' name='feedback3' onChange={onChange}/>
                    <span>Privacy concerns: I am uncomfortable with the information sharing practices.</span>
                  </label>
              </div>
              <div className="m-3">
                  <input className="form-check-input" value='' type="checkbox" id='other' name='other'  checked={showTextArea} onChange={onChangeOther}/>
                  <label htmlFor="other">Others</label>
              </div>
              {showTextArea && (
                  <div>
                      <textarea value={formData?.other} rows="3" columns='55' style={{width : '100%'}} name="otherFeedback" onChange={(e)=>setFormData({...formData,other : e.target.value})}></textarea>
                  </div>
              )}
          </form>
          <p></p>
        </Body>
      )}
      <Footer>
        <Button variant="secondary" onClick={handleClose} style={{fontSize : '13px'}}>
          Cancel
        </Button>
        {askAgain ? (
          <Button variant="primary" onClick={submitForm} style={{fontSize : '13px'}}>
            Confirm
          </Button>
        ) : (
          <Button variant="primary" onClick={handleSubmit} style={{fontSize : '13px'}}>
            Submit
          </Button>
        )}
      </Footer>
    </Modal>
  );
}
