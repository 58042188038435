import React, {useEffect, useState} from "react";
import { updateNewsLetter } from "../apis/api";

const NewsLetter = () => {

    const [pageContent, setPageContent] = useState("")

    const changeNewsLetterSubscription = async() => {
        try{
            const queryString = window.location.search.substring(1).split("&");
            const id = queryString[0].split("=")[1]
            const status = queryString[1].split("=")[1]
            await updateNewsLetter(id, status);
            if (status === "subscribe") {
                setPageContent("Succesfully subscribed to Newsletter")
            } else {
                setPageContent("Succesfully unsubscribed to Newsletter")
            }
        } catch(error) {
            console.log("update newsletter error", error)
            setPageContent(error?.response?.data?.message)
        }
    }
    useEffect(() => {
        changeNewsLetterSubscription()
    },[])

    return (
        <div className="w-100 mt-4 text-center">
            <span className="SpotLetSubHead"> {pageContent}  </span>
        </div>
    )
}

export default NewsLetter