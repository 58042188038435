import { useState,useEffect } from "react";
import Rating from '@mui/material/Rating';
import { getGoogleRatings } from "../../../apis/api";
import Testimonials from "../../NewPropertyPage/components/Testimonials";

const ReviewRatings = ({data}) => {
    const [ratings,setRatings] = useState(0)
    const [reviews,setReviews] = useState([])
    const [error,setError] = useState('')

    useEffect(()=>{
        const getRatings = async () => {
            try{
                const resp = await getGoogleRatings(data)
                console.log(resp)
                setRatings(resp?.data?.ratings)
                setReviews(resp?.data?.reviews)

            }catch(error){
                console.log(error?.response?.data?.message)
                setError(error?.response?.data?.message)
            }
        }
        getRatings()
    },[]);

    return(
        <div className="dashBoardRatingsSection">
            <span>Ratings</span>
            {ratings !== 0 && (
                <div className="dashBoardRatingsSectionRating">
                    <Rating
                        name="readOnly"
                        value={ratings}
                        readOnly
                        style={{ marginRight: '6px' }}
                    />
                    <span>({ratings})</span>
                </div>
            )}
            {reviews?.length > 0 && (< Testimonials reviews={reviews}/>)}
            {error && <div>{error}</div>}
        </div>
    )
}
export default ReviewRatings;