import "flatpickr/dist/themes/material_orange.css";
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { sendBookingRequest,GetServiceDetails } from '../../../apis/api';
import { useAuthStore } from "../../../store/auth";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import minus from '../assets/images/minus.svg'
import plus from '../assets/images/plus.svg'
import AuthenitcateModal from "../../../utils/NewAuthentication/AuthenitcateModal";
import { WhatsAppVerificationModal } from "../../../components/Modal/WhatsAppVerificationModal";
import { ConfirmationModel } from "../../../components/Modal/ConfirmationModel";
import { PageLoader } from "../../../components/PageLoader";

// for tooltips 
// import Tooltip, {tooltipClasses} from "@mui/material/Tooltip";
// import { styled } from '@mui/material/styles';
// import Popover from '@mui/material/Popover';

const FULL_TIME_OPTIONS = [
    { value: '00:00', label: '00:00' },
    { value: '01:00', label: '01:00' },
    { value: '02:00', label: '02:00' },
    { value: '03:00', label: '03:00' },
    { value: '04:00', label: '04:00' },
    { value: '05:00', label: '05:00' },
    { value: '06:00', label: '06:00' },
    { value: '07:00', label: '07:00' },
    { value: '08:00', label: '08:00' },
    { value: '09:00', label: '09:00' },
    { value: '10:00', label: '10:00' },
    { value: '11:00', label: '11:00' },
    { value: '12:00', label: '12:00' },
    { value: '13:00', label: '13:00' },
    { value: '14:00', label: '14:00' },
    { value: '15:00', label: '15:00' },
    { value: '16:00', label: '16:00' },
    { value: '17:00', label: '17:00' },
    { value: '18:00', label: '18:00' },
    { value: '19:00', label: '19:00' },
    { value: '20:00', label: '20:00' },
    { value: '21:00', label: '21:00' },
    { value: '22:00', label: '22:00' },
    { value: '23:00', label: '23:00' },
    { value: '23:59', label: '23:59' }
];
const HOURS_OPTIONS = [
    { value: '1', label: '1 hr' },
    { value: '2', label: '2 hrs' },
    { value: '3', label: '3 hrs' },
    { value: '4', label: '4 hrs' },
    { value: '5', label: '5 hrs' },
    { value: '6', label: '6 hrs' },
    { value: '7', label: '7 hrs' },
    { value: '8', label: '8 hrs' },
    { value: '9', label: '9 hrs' },
    { value: '10', label: '10 hrs' },
    { value: '11', label: '11 hrs' },
    { value: '12', label: '12 hrs' },
    { value: '13', label: '13 hrs' },
    { value: '14', label: '14 hrs' },
    { value: '15', label: '15 hrs' },
    { value: '16', label: '16 hrs' },
    { value: '17', label: '17 hrs' },
    { value: '18', label: '18 hrs' },
    { value: '19', label: '19 hrs' },
    { value: '20', label: '20 hrs' },
    { value: '21', label: '21 hrs' },
    { value: '22', label: '22 hrs' },
    { value: '23', label: '23 hrs' },
    { value: '24', label: '24 hrs' }
];

const EventRequestBookingTile = ({subPropertyName, locationId,data,owner_id,setInformation, approveEnquiries, bookingServices}) => {
    const navigate = useNavigate();
    const { userProfile, setUserProfile } = useAuthStore();
    const [timings, setTimings] = useState([])
    const [hours, setHours] = useState([])
    const [showWhatsAppModal, setShowWhatsAppModal] = useState(false)
    const [formData,setFormData] = useState({})
    const [permissions,setPermissions] = useState()
    const [showConfirmationModal, setShowConfirmationModal] = useState(false)
    const [errorMsg, setErrorMsg] = useState(false)
    const [loader, setLoader] = useState(false)
    const [checkInBookedDates,setCheckInBookedDates] = useState([])
    const [checkOutBookedDates,setCheckOutBookedDates] = useState([])
    const [stayAverageBookingPrice, setStayAverageBookingPrice] = useState("");
    // const [stayTooltips, setStayTooltips] = useState({
    //     adults : false,
    //     children : false
    // });

    const updateData = () => {
        let initialEvent = '';
        let initialSubEvent = '';
        let initialSub2Event = '';
        let initialPrice = '';
        let minBooking = '';
        let minAttendees = '';
        let maxAttendees = '';
        let weekEndPrice = '';
        const saveStartData = () => {
            setFormData(
                {   adults : 1,
                    children : 0,
                    infants : 0,
                    checkIn : '',
                    checkOut : '',
                    event : initialEvent,
                    subEvent : initialSubEvent,
                    sub2Event : initialSub2Event,
                    minBooking,
                    minAttendees,
                    maxAttendees,
                    price : initialPrice,
                    weekEndPrice,
                }
            )
        }

        const getSubEvent = (key) => {
            const reqKey = key?.fetchedSubType;
            if (reqKey === "hour") {
                return "perHour";
            }
            else if (reqKey === "day") {
                return "24Hours";
            }
            else if (reqKey === "half day") {
                return "12Hours";
            }
            else if (reqKey === "plate" || reqKey === "veg" || reqKey === "nonVeg" ) {
                return "perPlate"
            }
            else if (reqKey.includes("hours")) {
                return "venue"
            }
        }
        const fetchedDetails = getTileEventPriceInfo(locationId);
        if (fetchedDetails) {
            if (fetchedDetails?.fetchedType === "film" || fetchedDetails?.fetchedType === "tv") {
                initialEvent = fetchedDetails?.fetchedType;
                initialSubEvent = getSubEvent(fetchedDetails);
                initialPrice =  data?.[initialEvent]?.[initialSubEvent]?.price;
                if (initialSubEvent === "perHour") {
                    minBooking =  data?.[initialEvent]?.[initialSubEvent]?.minimumBookingHours;
                }
                maxAttendees = data?.[initialEvent]?.attendees
                saveStartData()
                return null
            }
            if (fetchedDetails?.fetchedType === "event") {
                initialEvent = fetchedDetails?.fetchedType;
                initialSubEvent = getSubEvent(fetchedDetails);
                initialPrice =  data?.[initialEvent]?.[initialSubEvent]?.price
                minBooking =  data?.[initialEvent]?.[initialSubEvent]?.minimumBookingHours;
                if (initialSubEvent === "perPlate") {
                    minAttendees = data?.[initialEvent]?.[initialSubEvent]?.minAttendees
                }
                maxAttendees = data?.[initialEvent]?.[initialSubEvent]?.maxAttendees
                saveStartData()
                return null
            }
            if (fetchedDetails?.fetchedType === "staycation") {
                initialEvent = 'stay'
                initialPrice = data?.stay?.weekdayPrice
                weekEndPrice = data?.stay?.weekendPrice
                maxAttendees = data?.stay?.maxGuests 
                saveStartData()
                return null
            }
        } 
        
        if (data?.film?.available) {
            if(data?.film?.perHour?.available){
                initialEvent = 'film'
                initialSubEvent = 'perHour'
                initialPrice =  data?.film?.perHour?.price
                minBooking =  data?.film?.perHour?.minimumBookingHours
                maxAttendees = data?.film?.attendees
                saveStartData()
                return null
            }
            if(data?.film?.['24Hours']?.available){
                initialEvent = 'film'
                initialSubEvent = '24Hours'
                initialPrice =  data?.film?.['24Hours']?.price
                maxAttendees = data?.film?.attendees
                saveStartData()
                return null
            }
            if(data?.film?.['12Hours']?.available){
                initialEvent = 'film'
                initialSubEvent = '12Hours'
                initialPrice =  data?.film?.['12Hours']?.price
                maxAttendees = data?.film?.attendees
                saveStartData()
                return null
            }
        } 

        if (data?.tv?.available) {
            if(data?.tv?.perHour?.available){
                initialEvent = 'tv'
                initialSubEvent = 'perHour'
                initialPrice =  data?.tv?.perHour?.price
                minBooking =  data?.tv?.perHour?.minimumBookingHours
                maxAttendees = data?.tv?.attendees
                saveStartData()
                return null
            }
            if(data?.tv?.['24Hours']?.available){
                initialEvent = 'tv'
                initialSubEvent = '24Hours'
                initialPrice =  data?.tv?.['24Hours']?.price
                maxAttendees = data?.tv?.attendees
                saveStartData()
                return null
            }
            if(data?.tv?.['12Hours']?.available){
                initialEvent = 'tv'
                initialSubEvent = '12Hours'
                initialPrice =  data?.tv?.['12Hours']?.price
                maxAttendees = data?.tv?.attendees
                saveStartData()
                return null
            }
        }

        if (data?.event?.available) {
            if(data?.event?.perPlate?.available){
                initialEvent = 'event'
                initialSubEvent = 'perPlate'
                if(data?.event?.pureVeg){
                        initialSub2Event = 'pureVeg'
                        initialPrice =  data?.event?.perPlate?.vegPrice
                        minBooking =  data?.event?.perPlate?.minimumBookingHours
                        minAttendees = data?.event?.perPlate?.minAttendees
                        maxAttendees = data?.event?.perPlate?.maxAttendees
                        saveStartData()
                        return null
                }
                if(data?.event?.nonVeg){
                        initialEvent = 'event'
                        initialSub2Event = 'pureVeg'
                        initialPrice =  data?.event?.perPlate?.nonVegPrice
                        minBooking =  data?.event?.perPlate?.minimumBookingHours
                        minAttendees = data?.event?.perPlate?.minAttendees
                        maxAttendees = data?.event?.perPlate?.maxAttendees
                        saveStartData()
                        return null
                }
            }
            if(data?.event?.venue?.available){
                initialEvent = 'event'
                initialSubEvent = 'venue'
                initialPrice =  data?.event?.venue?.price
                minBooking =  data?.event?.venue?.minimumBookingHours
                maxAttendees = data?.event?.venue?.maxAttendees
                saveStartData()
                return null
            }
            if(data?.event?.perHour?.available){
                initialEvent = 'event'
                initialSubEvent = 'perHour'
                initialPrice =  data?.event?.perHour?.price
                minBooking =  data?.event?.perHour?.minimumBookingHours
                maxAttendees = data?.event?.perHour?.maxAttendees
                saveStartData()
                return null
            }
        }

        if(data?.stay?.available){
            initialEvent = 'stay'
            initialPrice = data?.stay?.weekdayPrice
            weekEndPrice = data?.stay?.weekendPrice
            maxAttendees = data?.stay?.maxGuests 
            saveStartData()
            return null
        }
    }
    const getPermissions = async() => {
        if(locationId){
            try{
                const resp = await GetServiceDetails('BookingServices',locationId)
                setPermissions(resp?.data?.serviceData?.settings)
                setCheckInBookedDates(resp?.data?.checkInDates)
                setCheckOutBookedDates(resp?.data?.checkOutDates)
            }catch(error){
                console.log(error)
            }
        }
    }
    const getTileEventPriceInfo = (locationId) => {
        const now = new Date();
        let reqObj = localStorage.getItem(locationId);
        if (!reqObj) return null
        reqObj = JSON.parse(reqObj);
        if (now.getTime() > reqObj?.expiry) {
            localStorage.removeItem(locationId);
            return null;
        }
        const fetchedType = reqObj?.price?.type;
        const fetchedSubType = reqObj?.price?.tag;
        const fetchedNonVegType = reqObj?.nonVegPrice?.tag;
        return {fetchedType, fetchedSubType, fetchedNonVegType}
    }

    useEffect(() => {
        updateData()
        getPermissions()
    },[data])
    //Lead start
    const [showSignUp,setShowSignUp] = useState(false)
    const handleLead = async () => {
        setLoader(true)
        const user = await setUserProfile()
        if(user){
            let payload 
            if (subPropertyName) {
                payload = {...formData,locationId,owner_id,userId : user?._id, subPropertyName}
            } else {
                payload = {...formData,locationId,owner_id,userId : user?._id}
            }
            try{
                if (!user) {
                    setShowSignUp(true)
                    setLoader(false)
                    return null
                  }
                if ( user?.personalInfo?.mobileVerified ) {
                    if( 
                        (formData?.event === 'film' && permissions?.film && bookingServices?.film) || 
                        (formData?.event === 'tv' && permissions?.tv && bookingServices?.tv) || 
                        (formData?.event === 'event' && permissions?.event && bookingServices?.event) ||  
                        (formData?.event === 'stay' && permissions?.stay && bookingServices?.stay) 
                    ){
                    // if(formData?.event === 'stay' && permissions?.stay){
                        setLoader(false)
                        return navigate(`/booking/${locationId}`, {state: formData})
                    }
                    const resp = await sendBookingRequest(payload)
                    setInformation(resp?.data)
                    setLoader(false)
                    updateData()
                    document.getElementById("bookingForm").reset();
                }else{
                    setLoader(false)
                    setShowWhatsAppModal(true)
                }
            }catch(error){
                console.log(error)
                setLoader(false)
                setErrorMsg(error?.response?.data?.message)
                setShowConfirmationModal(true)
                // alert(error)
            }
            // navigate("/booking",{ state: {locationId,data : payload,owner_id}});
        }
        setLoader(false)
    }
    //Lead end
    const onSubmit = async (e) => {
        e.preventDefault()
        setLoader(true);
        let payload 
        if (subPropertyName) {
            payload = {...formData,locationId,owner_id,userId : userProfile?._id, subPropertyName}
        } else {
            payload = {...formData,locationId,owner_id,userId : userProfile?._id}
        }
        try{
            if (!userProfile) {
                setShowSignUp(true)
                setLoader(false)
                return null
              }
            if ( userProfile?.personalInfo?.mobileVerified ) {
                if( 
                    (formData?.event === 'film' && permissions?.film && bookingServices?.film) || 
                    (formData?.event === 'tv' && permissions?.tv && bookingServices?.tv) || 
                    (formData?.event === 'event' && permissions?.event && bookingServices?.event) ||  
                    (formData?.event === 'stay' && permissions?.stay && bookingServices?.stay) 
                 ){
                // if(formData?.event === 'stay' && permissions?.stay && bookingServices?.stay){
                    setLoader(false)
                    const stayRequest = {
                        event : formData?.event,
                        checkIn : formData?.checkIn,
                        checkOut : formData?.checkOut,
                        adults : formData?.adults,
                        children : formData?.children,
                        infants : formData?.infants,
                        weekdayPrice : formData?.price,
                        weekEndPrice : formData?.weekEndPrice,
                    }
                    return navigate(`/booking/${locationId}`, {state: {stayRequest,checkInBookedDates,checkOutBookedDates}})
                }
                const resp = await sendBookingRequest(payload)
                setInformation(resp?.data)
                updateData()
                setLoader(false)
                document.getElementById("bookingForm").reset();
            }else{
                setShowWhatsAppModal(true)
                setLoader(false)
            }
        }catch(error){
            console.log(error)
            // alert(error)
            setErrorMsg(error?.response?.data?.message)
            setShowConfirmationModal(true)
        }
        setLoader(false)
    }
    const getEventName = (name) => {
        let reqName
        switch (name) {
            case "film":
                return reqName = "Film Shooting"
            case "tv":
                return reqName = "Tv Shooting"
            case "event":
                return reqName = "Events"
            case "stay":
                return reqName = "Staycation"
            default:
                return name
        }
    }
    const filterDates = (date) => {
        let dates = []
        let finalDates = [];
        Object.keys(data?.[formData?.event]?.timings).map((day,i)=>{
                dates.push(day.substring(0, 3))
        })
        for (let i = 0; i < 7; i++) {
            switch (dates[i]) {
              case "mon":
                finalDates.push(1);
                break;
              case "tue":
                finalDates.push(2);
                break;
              case "wed":
                finalDates.push(3);
                break;
              case "thu":
                finalDates.push(4);
                break;
              case "fri":
                finalDates.push(5);
                break;
              case "sat":
                finalDates.push(6);
                break;
              case "sun":
                finalDates.push(0);
                break;
              default:
                break;
            }
          }
          const day = date.getDay();
          return (
            finalDates.includes(day)
          );
    }
    const onDateChange = (date) => {
        setFormData({
            ...formData,
            startDate : date,
            startTime : '',
            noOfHours : '',
        })
        const timings = data?.[formData?.event]?.timings
        const day = moment(date).format("dddd").toLowerCase()
        Object.keys(timings).map((key) => {
            if(key === day){
                if(timings[key].fullDay){
                    setTimings(FULL_TIME_OPTIONS);
                }else{
                    let startIndex
                    let endIndex
                    FULL_TIME_OPTIONS.map((item,i)=>{
                        if(item.value === timings[key].timings.open){
                            startIndex = i
                        }
                        if(item.value === timings[key].timings.close){
                            endIndex = i
                        }
                    })
                    const slicedArray = FULL_TIME_OPTIONS.slice(startIndex, endIndex + 1);
                    setTimings(slicedArray);
                }
            }
        })
    }
    const handleTiming = (value) => {
        setFormData({
            ...formData,
            startTime : value,
            noOfHours : '',
        })
        if(formData?.minBooking){
            const inputValue = formData?.minBooking;
            const index = HOURS_OPTIONS.findIndex(obj => obj.value === inputValue);
            if (index !== -1) {
                const slicedArray = HOURS_OPTIONS.slice(index);
                setHours(slicedArray)
            } else {
                console.log("Input value not found in the array.");
            }
        }
    }
    //stay start
    const updateAdults = (type,value) => {
        if(type === '-'){
            // setStayTooltips({
            //     ...stayTooltips,
            //     adults : false,
            // })
            if(value <= 1) return null
            setFormData((prev)=>{
                return{
                    ...prev,
                    adults : prev.adults - 1,
                }
            })
        }
        if(type === '+'){
            // if(parseInt(data?.stay?.maxGuests) ===  value) {
            //     // setStayTooltips({
            //     //     ...stayTooltips,
            //     //     adults : true,
            //     // })
            //     return null
            // }
            if(value + formData?.children === parseInt(data?.stay?.maxGuests)) return null
            // setStayTooltips({
            //     ...stayTooltips,
            //     adults : false,
            // })
            setFormData((prev)=>{
                return{
                    ...prev,
                    adults : prev.adults + 1,
                }
            })
        }
    }
    const updateChildren= (type,value) => {
        if(type === '-'){
            // setStayTooltips({
            //     ...stayTooltips,
            //     children : false,
            // })
            if(value <= 0) return null
            setFormData((prev)=>{
                return{
                    ...prev,
                    children : prev.children - 1,
                }
            })
        }
        if(type === '+'){
            // if(parseInt(data?.stay?.maxChildren) ===  value) {
            //     // setStayTooltips({
            //     //     ...stayTooltips,
            //     //     children : true,
            //     // })
            //     return null
            // }
            if(formData?.adults + value >= parseInt(data?.stay?.maxGuests)) return null
            // setStayTooltips({
            //     ...stayTooltips,
            //     children : false,
            // })
            setFormData((prev)=>{
                return{
                    ...prev,
                    children : prev.children + 1,
                }
            })
        }
    }
    const updateInfants= (type,value) => {
        if(type === '-'){
            if(value <= 0) return null
            setFormData((prev)=>{
                return{
                    ...prev,
                    infants : prev.infants - 1,
                }
            })
        }
        if(type === '+'){
            // if(formData?.adults + value >= 6) return null
            setFormData((prev)=>{
                return{
                    ...prev,
                    infants : prev.infants + 1,
                }
            })
        }
    }
    const isStayWeekday = (date) => {
        let dates = []
        let finalDates = [];
        Object.keys(data?.stay?.timings).map((day,i)=>{
                if (data?.stay?.timings?.[day]) {
                    dates.push(day.substring(0, 3))
                } 
        })
        for (let i = 0; i < 7; i++) {
            switch (dates[i]) {
              case "mon":
                finalDates.push(1);
                break;
              case "tue":
                finalDates.push(2);
                break;
              case "wed":
                finalDates.push(3);
                break;
              case "thu":
                finalDates.push(4);
                break;
              case "fri":
                finalDates.push(5);
                break;
              case "sat":
                finalDates.push(6);
                break;
              case "sun":
                finalDates.push(0);
                break;
              default:
                break;
            }
          }
          const day = date.getDay();
          return (
            finalDates.includes(day)
          );
    };
    const getStayCheckInExcludedDates = () => {
        // var array = [new Date(formData?.checkIn) ,new Date(formData?.checkOut)]
        var array = []
        checkInBookedDates?.map((item) => array.push(new Date(item)))
        if(formData?.checkIn) array.push(new Date(formData?.checkIn))
        if(formData?.checkOut) array.push(new Date(formData?.checkOut))
        return array
    }
    const getStayCheckOutExcludedDates = () => {
        // var array = [new Date(formData?.checkIn) ,new Date(formData?.checkOut)]
        var array = []
        checkOutBookedDates?.map((item) => array.push(new Date(item)))
        if(formData?.checkIn) array.push(new Date(formData?.checkIn))
        if(formData?.checkOut) array.push(new Date(formData?.checkOut))
        return array
    }
    const handleCheckIn = (date) => {
        setFormData({
            ...formData,
            checkIn : date,
            checkOut : '',
        })
    }

   function extractDay(dateString) {
    const datePart = dateString.split(',')[1].trim();
    const day = datePart.split(' ')[1];
    return day;
  }

   // for calculating the checkoutdate date start
   const getMaxDate = (checkIn, timings) => {
    let maxDate 
    if (!checkIn) return maxDate;
  
    const daysOfWeek = ['sunday', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday'];
    const checkInDate = new Date(checkIn);
    let checkInDayIndex = checkInDate.getDay();
    let trigger = true
    let i = 1
    const extractedDays = checkInBookedDates.map(extractDay);
    while(trigger && i<=6){
        const checkInDate2 = new Date(checkIn)
        checkInDate2.setDate(checkInDate.getDate() + i);
        const newDayOfMonth = checkInDate2.getDate().toString();
        checkInDayIndex = checkInDayIndex + 1
        if(checkInDayIndex > 6) checkInDayIndex = checkInDayIndex - 7
        const dayName = daysOfWeek[checkInDayIndex];
        if (timings?.[dayName] === null || timings?.[dayName] === undefined || extractedDays.includes(newDayOfMonth)) {
            const maxDate = new Date(checkInDate);
            maxDate.setDate(checkInDate.getDate() + i);
            trigger = false
            return maxDate
        }
        i++
    }
    return maxDate; // No max date if no null days are found
  }; 
   // for calculating the checkout date end 

    //stay start

    //new start
    const getPermission = () => {
        switch (formData?.event) {
            case 'film':
                return !permissions?.film
                break;
            case 'tv':
                return !permissions?.tv
                break;
            case 'event':
                return !permissions?.event
                break;
            case 'stay':
                return !permissions?.stay
                break;
            default: 
                return true
                break;
        }
    }
    const onChangeEventSelect = (value) => {
        let initialEvent = '';
        let initialSubEvent = '';
        let initialSub2Event = '';
        let initialPrice = '';
        let minBooking = '';
        let minAttendees = '';
        let maxAttendees = '';
        let weekEndPrice = '';
        const saveStartData = () => {
            setFormData(
                {   ...formData,
                    event : initialEvent,
                    subEvent : initialSubEvent,
                    sub2Event : initialSub2Event,
                    minBooking,
                    minAttendees,
                    maxAttendees,
                    price : initialPrice,
                    weekEndPrice,
                    startTime : '',
                    startDate : '',
                    noOfHours : '',
                    guests : '',
                    checkIn : '',
                    checkOut :'',
                    adults :1,
                    children : 0,
                }
            )
        }
        switch (value) {
            case 'film':
                if(data?.film?.perHour?.available){
                    initialEvent = 'film'
                    initialSubEvent = 'perHour'
                    initialPrice =  data?.film?.perHour?.price
                    minBooking =  data?.film?.perHour?.minimumBookingHours
                    maxAttendees = data?.film?.attendees
                    saveStartData()
                    return null
                }
                if(data?.film?.['24Hours']?.available){
                    initialEvent = 'film'
                    initialSubEvent = '24Hours'
                    initialPrice =  data?.film?.['24Hours']?.price
                    maxAttendees = data?.film?.attendees
                    saveStartData()
                    return null
                }
                if(data?.film?.['12Hours']?.available){
                    initialEvent = 'film'
                    initialSubEvent = '12Hours'
                    initialPrice =  data?.film?.['12Hours']?.price
                    maxAttendees = data?.film?.attendees
                    saveStartData()
                    return null
                }
                break;
            case 'tv':
                if(data?.tv?.perHour?.available){
                    initialEvent = 'tv'
                    initialSubEvent = 'perHour'
                    initialPrice =  data?.tv?.perHour?.price
                    minBooking =  data?.tv?.perHour?.minimumBookingHours
                    maxAttendees = data?.tv?.attendees
                    saveStartData()
                    return null
                }
                if(data?.tv?.['24Hours']?.available){
                    initialEvent = 'tv'
                    initialSubEvent = '24Hours'
                    initialPrice =  data?.tv?.['24Hours']?.price
                    maxAttendees = data?.tv?.attendees
                    saveStartData()
                    return null
                }
                if(data?.tv?.['12Hours']?.available){
                    initialEvent = 'tv'
                    initialSubEvent = '12Hours'
                    initialPrice =  data?.tv?.['12Hours']?.price
                    maxAttendees = data?.tv?.attendees
                    saveStartData()
                    return null
                }
                break;
            case 'event':
                if(data?.event?.perPlate?.available){
                    initialEvent = 'event'
                    initialSubEvent = 'perPlate'
                    if(data?.event?.pureVeg){
                            initialSub2Event = 'pureVeg'
                            initialPrice =  data?.event?.perPlate?.vegPrice
                            minBooking =  data?.event?.perPlate?.minimumBookingHours
                            minAttendees = data?.event?.perPlate?.minAttendees
                            maxAttendees = data?.event?.perPlate?.maxAttendees
                            saveStartData()
                            return null
                    }
                    if(data?.event?.nonVeg){
                            initialEvent = 'event'
                            initialSub2Event = 'nonVeg'
                            initialPrice =  data?.event?.perPlate?.nonVegPrice
                            minBooking =  data?.event?.perPlate?.minimumBookingHours
                            minAttendees = data?.event?.perPlate?.minAttendees
                            maxAttendees = data?.event?.perPlate?.maxAttendees
                            saveStartData()
                            return null
                    }
                }
                if(data?.event?.venue?.available){
                    initialEvent = 'event'
                    initialSubEvent = 'venue'
                    initialPrice =  data?.event?.venue?.price
                    minBooking =  data?.event?.venue?.minimumBookingHours
                    maxAttendees = data?.event?.venue?.maxAttendees
                    saveStartData()
                    return null
                }
                if(data?.event?.perHour?.available){
                    initialEvent = 'event'
                    initialSubEvent = 'perHour'
                    initialPrice =  data?.event?.perHour?.price
                    minBooking =  data?.event?.perHour?.minimumBookingHours
                    maxAttendees = data?.event?.perHour?.maxAttendees
                    saveStartData()
                    return null
                }
                break;
            case 'stay':
                if(data?.stay?.available){
                    initialEvent = 'stay'
                    initialPrice = data?.stay?.weekdayPrice
                    weekEndPrice = data?.stay?.weekendPrice
                    maxAttendees = data?.stay?.maxGuests
                    saveStartData()
                    return null
                }
                break;
            default:
                break;
        }
    }
    const onChangeSubSelect = (value) => {
        let initialSub2Event = '';
        let initialPrice = '';
        let minBooking = '';
        let minAttendees = '';
        let maxAttendees = '';
        let weekEndPrice = '';
        const saveStartData = () => {
            setFormData(
                {   event : formData?.event,
                    subEvent : value,
                    sub2Event : initialSub2Event,
                    minBooking,
                    minAttendees,
                    maxAttendees,
                    price : initialPrice,
                    weekEndPrice,
                    startDate : '',
                    startTime : '',
                    guests : '',
                    noOfHours : '',
                }
            )
        }
        switch (formData?.event) {
            case 'film':
                if(value === 'perHour'){
                    initialPrice =  data?.film?.perHour?.price
                    minBooking =  data?.film?.perHour?.minimumBookingHours
                    maxAttendees = data?.film?.attendees
                    saveStartData()
                    return null
                }
                if(value === '24Hours'){
                    initialPrice =  data?.film?.['24Hours']?.price
                    maxAttendees = data?.film?.attendees
                    saveStartData()
                    return null
                }
                if(value === '12Hours'){
                    initialPrice =  data?.film?.['12Hours']?.price
                    maxAttendees = data?.film?.attendees
                    saveStartData()
                    return null
                }
                break;
            case 'tv':
                if(value === 'perHour'){
                    initialPrice =  data?.tv?.perHour?.price
                    minBooking =  data?.tv?.perHour?.minimumBookingHours
                    maxAttendees = data?.tv?.attendees
                    saveStartData()
                    return null
                }
                if(value === '24Hours'){
                    initialPrice =  data?.tv?.['24Hours']?.price
                    maxAttendees = data?.tv?.attendees
                    saveStartData()
                    return null
                }
                if(value === '12Hours'){
                    initialPrice =  data?.tv?.['12Hours']?.price
                    maxAttendees = data?.tv?.attendees
                    saveStartData()
                    return null
                }
                break;
            case 'event':
                if(value === 'perPlate'){
                    if(data?.event?.pureVeg){
                            initialSub2Event = 'pureVeg'
                            initialPrice =  data?.event?.perPlate?.vegPrice
                            minBooking =  data?.event?.perPlate?.minimumBookingHours
                            minAttendees = data?.event?.perPlate?.minAttendees
                            maxAttendees = data?.event?.perPlate?.maxAttendees
                            saveStartData()
                            return null
                    }
                    if(data?.event?.nonVeg){
                            initialSub2Event = 'nonVeg'
                            initialPrice =  data?.event?.perPlate?.nonVegPrice
                            minBooking =  data?.event?.perPlate?.minimumBookingHours
                            minAttendees = data?.event?.perPlate?.minAttendees
                            maxAttendees = data?.event?.perPlate?.maxAttendees
                            saveStartData()
                            return null
                    }
                }
                if(value === 'venue'){
                    initialPrice =  data?.event?.venue?.price
                    minBooking =  data?.event?.venue?.minimumBookingHours
                    maxAttendees = data?.event?.venue?.maxAttendees
                    saveStartData()
                    return null
                }
                if(value === 'perHour'){
                    initialPrice =  data?.event?.perHour?.price
                    minBooking =  data?.event?.perHour?.minimumBookingHours
                    maxAttendees = data?.event?.perHour?.maxAttendees
                    saveStartData()
                    return null
                }
                break;
            default:
                break;
        }
    }
    const getPriceText = () => {
        if(formData?.event === 'stay') return "night"
        if(formData?.event === 'film' || formData?.event === 'tv'){
            if(formData?.subEvent === 'perHour') return "hour"
            if(formData?.subEvent === '24Hours') return "full day"
            if(formData?.subEvent === '12Hours') return "half day"
        }
        if(formData?.event === 'event'){
            if(formData?.subEvent === 'perHour') return "hour"
            if(formData?.subEvent === 'venue') return "-"
            if(formData?.subEvent === 'perPlate') {
                if(formData?.sub2Event === 'pureVeg') return "veg/per plate"
                return 'nonVeg/per plate'
            }
        }
    }
    const filmShootSection = () => {
        return(
            <div className="w-100">
                <div className='propertyPageEventSelectionContainer row'>
                    {data?.film?.['24Hours']?.available && (
                        <div className='propertyPageEachSelection col-lg-3  col-md-6  mt-2'>
                            <input type='checkbox' className={`CHECKBOX ${formData?.subEvent === '24Hours' && 'EVENTACTIVE'}`} id='subFilmFullDay' checked={formData?.subEvent === '24Hours'} name='subEvent' onChange={()=>onChangeSubSelect('24Hours')}/>
                            <label className={`LABEL ${formData?.subEvent === '24Hours'  && 'LABELACTIVE'}`} htmlFor='subFilmFullDay'>Full day</label>
                        </div>
                    )}
                    {data?.film?.['12Hours']?.available && (
                        <div className='propertyPageEachSelection col-lg-3  col-md-6  mt-2'>
                            <input type='checkbox' className={`CHECKBOX ${formData?.subEvent === '12Hours' && 'EVENTACTIVE'}`} id='subFilmHalfDay' checked={formData?.subEvent === '12Hours'} name='subEvent' onChange={()=>onChangeSubSelect('12Hours')}/>
                            <label className={`LABEL ${formData?.subEvent === '12Hours'  && 'LABELACTIVE'}`} htmlFor='subFilmHalfDay'>Half day</label>
                        </div>
                    )}
                    {data?.film?.perHour?.available && (
                        <div className='propertyPageEachSelection col-lg-3  col-md-6  mt-2'>
                            <input type='checkbox' className={`CHECKBOX ${formData?.subEvent === 'perHour' && 'EVENTACTIVE'}`} id='subFilmPerHour' checked={formData?.subEvent === 'perHour'} name='subEvent' onChange={()=>onChangeSubSelect('perHour')}/>
                            <label className={`LABEL ${formData?.subEvent === 'perHour'  && 'LABELACTIVE'}`} htmlFor='subFilmPerHour'>Per hour</label>
                        </div>
                    )}
                </div>
                <div className='propertyPageDateSection'>
                    <DatePicker
                    selected={formData?.startDate}
                    value={formData?.startDate}
                    minDate={new Date()}
                    dateFormat = 'dd-MM-yyyy'
                    onChange={(date) => onDateChange(date)}
                    // excludeDates={getExcludedDates()}
                    filterDate={filterDates}
                    placeholderText="Start Date"
                    calendarClassName="SpotLetContent"
                    required
                    style={{marginTop :'0px',textAlign : "center"}}
                    // disabled={!oldFormData?.event}
                    />
                    {!(formData?.event === 'stay') && (
                            <style jsx>{`
                            .react-datepicker-wrapper input::placeholder {
                                color: gray;
                                opacity: 1;
                                font-weight: 400;
                            }
                            .react-datepicker-wrapper{
                                display: flex;
                                flex-direction: row;
                                justify-content: center;
                                align-items: center;
                                width: 33%;
                                border-right: 1px solid #EAEAEA;
                            }
                            .react-datepicker__input-container{
                                display: flex;
                                flex-direction: row;
                                justify-content: center;
                                align-items: center;
                                border-right: 1px solid #EAEAEA;
                            }
                            .react-datepicker-wrapper input{
                                outline: none;
                                border: none;
                                width: 100%;
                            }
                        `}</style>
                        )}
                <select
                    className='propertyPageDateSectionstart SpotLetContent'
                    style={{textAlign : "center"}}
                    value={formData?.startTime}
                    onChange={(e)=>handleTiming(e.target.value)}
                    required
                    disabled={!formData?.startDate}
                >
                    <option value={''} hidden selected>Start Time</option>
                    {timings.map((type) => {
                        return(
                            <option value={type.value}>{type.label}</option>
                        )
                    })}
                </select>
                {formData?.subEvent === 'perHour' && (
                    <select
                    className='propertyPageDateSectionstart SpotLetContent'
                    style={{textAlign : 'center'}}
                    value={formData?.noOfHours}
                    onChange={(e)=>setFormData({...formData,noOfHours : e.target.value})}
                    required
                    disabled={!formData?.startTime}
                >
                    <option value={''} hidden selected>No of Hours</option>
                    {hours.map((type) => {
                        return(
                            <option value={type.value}>{type.label}</option>
                        )
                    })}
                </select>
                )}
                </div>
                <input
                        className="eventRequestBookingTypeSelect SpotLetContent"
                        placeholder="No. of Guests" 
                        type="number"
                        name='guests'
                        value={formData?.guests}
                        onChange={(e)=>setFormData({...formData,guests : e.target.value})}
                        required
                        disabled={!formData?.event}
                        max={formData?.maxAttendees}
                        min = {formData?.minAttendees || 1}
                    />
            </div>
        )
    }
    const tvShootSection = () => {
        return(
            <div className="w-100">
                <div className='propertyPageEventSelectionContainer row'>
                    {data?.tv?.['24Hours']?.available && (
                        <div className='propertyPageEachSelection col-lg-3  col-md-6  mt-2'>
                            <input type='checkbox' className={`CHECKBOX ${formData?.subEvent === '24Hours' && 'EVENTACTIVE'}`} id='subFilmFullDay' checked={formData?.subEvent === '24Hours'} name='subEvent' onChange={()=>onChangeSubSelect('24Hours')}/>
                            <label className={`LABEL ${formData?.subEvent === '24Hours'  && 'LABELACTIVE'}`} htmlFor='subFilmFullDay'>Full day</label>
                        </div>
                    )}
                    {data?.tv?.['12Hours']?.available && (
                        <div className='propertyPageEachSelection col-lg-3  col-md-6  mt-2'>
                            <input type='checkbox' className={`CHECKBOX ${formData?.subEvent === '12Hours' && 'EVENTACTIVE'}`} id='subFilmHalfDay' checked={formData?.subEvent === '12Hours'} name='subEvent' onChange={()=>onChangeSubSelect('12Hours')}/>
                            <label className={`LABEL ${formData?.subEvent === '12Hours'  && 'LABELACTIVE'}`} htmlFor='subFilmHalfDay'>Half day</label>
                        </div>
                    )}
                    {data?.tv?.perHour?.available && (
                        <div className='propertyPageEachSelection col-lg-3  col-md-6  mt-2'>
                            <input type='checkbox' className={`CHECKBOX ${formData?.subEvent === 'perHour' && 'EVENTACTIVE'}`} id='subFilmPerHour' checked={formData?.subEvent === 'perHour'} name='subEvent' onChange={()=>onChangeSubSelect('perHour')}/>
                            <label className={`LABEL ${formData?.subEvent === 'perHour'  && 'LABELACTIVE'}`} htmlFor='subFilmPerHour'>Per hour</label>
                        </div>
                    )}
                </div>
                <div className='propertyPageDateSection'>
                    <DatePicker
                    selected={formData?.startDate}
                    value={formData?.startDate}
                    minDate={new Date()}
                    dateFormat = 'dd-MM-yyyy'
                    onChange={(date) => onDateChange(date)}
                    // excludeDates={getExcludedDates()}
                    filterDate={filterDates}
                    placeholderText="Start Date"
                    calendarClassName="SpotLetContent"
                    required
                    style={{marginTop :'0px'}}
                    // disabled={!oldFormData?.event}
                    />
                    {!(formData?.event === 'stay') && (
                            <style jsx>{`
                            .react-datepicker-wrapper input::placeholder {
                                color: gray;
                                opacity: 1;
                                font-weight: 400;
                            }
                            .react-datepicker-wrapper{
                                display: flex;
                                flex-direction: row;
                                justify-content: center;
                                align-items: center;
                                width: 33%;
                                border-right: 1px solid #EAEAEA;
                            }
                            .react-datepicker__input-container{
                                display: flex;
                                flex-direction: row;
                                justify-content: center;
                                align-items: center;
                                border-right: 1px solid #EAEAEA;
                            }
                            .react-datepicker-wrapper input{
                                outline: none;
                                border: none;
                                width: 100%;
                            }
                        `}</style>
                        )}
                <select
                    className='propertyPageDateSectionstart SpotLetContent'
                    style={{textAlign : 'center'}}
                    value={formData?.startTime}
                    onChange={(e)=>handleTiming(e.target.value)}
                    required
                    disabled={!formData?.startDate}
                >
                    <option value={''} hidden selected>Start Time</option>
                    {timings.map((type) => {
                        return(
                            <option value={type.value}>{type.label}</option>
                        )
                    })}
                </select>
                {formData?.subEvent === 'perHour' && (
                    <select
                    className='propertyPageDateSectionstart SpotLetContent'
                    style={{textAlign : 'center'}}
                    value={formData?.noOfHours}
                    onChange={(e)=>setFormData({...formData,noOfHours : e.target.value})}
                    required
                    disabled={!formData?.startTime}
                >
                    <option value={''} hidden selected>No of Hours</option>
                    {hours.map((type) => {
                        return(
                            <option value={type.value}>{type.label}</option>
                        )
                    })}
                </select>
                )}
                </div>
                <input
                        className="eventRequestBookingTypeSelect SpotLetContent"
                        placeholder="No. of Guests" 
                        type="number"
                        name='guests'
                        value={formData?.guests}
                        onChange={(e)=>setFormData({...formData,guests : e.target.value})}
                        required
                        disabled={!formData?.event}
                        max={formData?.maxAttendees}
                        min = {formData?.minAttendees || 1}
                    />
            </div>
        )
    }
    const eventSection = () => {
        return(
            <div className="w-100">
                <div className='propertyPageEventSelectionContainer row'>
                    {data?.event?.venue?.available && (
                        <div className='propertyPageEachSelection col-lg-3  col-md-6  mt-2'>
                            <input type='checkbox' className={`CHECKBOX ${formData?.subEvent === 'venue' && 'EVENTACTIVE'}`} id='subFilfgbbdfghfgmFullDay' checked={formData?.subEvent === 'venue'} name='subEvent' onChange={()=>onChangeSubSelect('venue')}/>
                            <label className={`LABEL ${formData?.subEvent === 'venue'  && 'LABELACTIVE'}`} htmlFor='subFilfgbbdfghfgmFullDay'>Venue</label>
                        </div>
                    )}
                    {data?.event?.perPlate?.available && (
                        <div className='propertyPageEachSelection col-lg-3  col-md-6  mt-2'>
                            <input type='checkbox' className={`CHECKBOX ${formData?.subEvent === 'perPlate' && 'EVENTACTIVE'}`} id='suerfgsdfgbFilmHalfDay' checked={formData?.subEvent === 'perPlate'} name='subEvent' onChange={()=>onChangeSubSelect('perPlate')}/>
                            <label className={`LABEL ${formData?.subEvent === 'perPlate'  && 'LABELACTIVE'}`} htmlFor='suerfgsdfgbFilmHalfDay'>Per plate</label>
                        </div>
                    )}
                    {data?.event?.perHour?.available && (
                        <div className='propertyPageEachSelection col-lg-3  col-md-6  mt-2'>
                            <input type='checkbox' className={`CHECKBOX ${formData?.subEvent === 'perHour' && 'EVENTACTIVE'}`} id='subFilhsrtgbhrsmPerHour' checked={formData?.subEvent === 'perHour'} name='subEvent' onChange={()=>onChangeSubSelect('perHour')}/>
                            <label className={`LABEL ${formData?.subEvent === 'perHour'  && 'LABELACTIVE'}`} htmlFor='subFilhsrtgbhrsmPerHour'>Per hour</label>
                        </div>
                    )}
                </div>
                <div className='propertyPageDateSection'>
                    <DatePicker
                    selected={formData?.startDate}
                    value={formData?.startDate}
                    minDate={new Date()}
                    dateFormat = 'dd-MM-yyyy'
                    onChange={(date) => onDateChange(date)}
                    // excludeDates={getExcludedDates()}
                    filterDate={filterDates}
                    placeholderText="Start Date"
                    calendarClassName="SpotLetContent"
                    required
                    style={{marginTop :'0px'}}
                    // disabled={!oldFormData?.event}
                    />
                    {!(formData?.event === 'stay') && (
                            <style jsx>{`
                            .react-datepicker-wrapper input::placeholder {
                                color: gray;
                                opacity: 1;
                                font-weight: 400;
                            }
                            .react-datepicker-wrapper{
                                display: flex;
                                flex-direction: row;
                                justify-content: center;
                                align-items: center;
                                width: 33%;
                                border-right: 1px solid #EAEAEA;
                            }
                            .react-datepicker__input-container{
                                display: flex;
                                flex-direction: row;
                                justify-content: center;
                                align-items: center;
                                border-right: 1px solid #EAEAEA;
                            }
                            .react-datepicker-wrapper input{
                                outline: none;
                                border: none;
                                width: 100%;
                            }
                        `}</style>
                        )}
                <select
                    className='propertyPageDateSectionstart SpotLetContent'
                    style={{textAlign : 'center'}}
                    value={formData?.startTime}
                    // onChange={(e)=>setFormData({...formData,startTime : e.target.value,noOfHours : ''})}
                    onChange={(e)=>handleTiming(e.target.value)}
                    required
                    disabled={!formData?.startDate}
                >
                    <option value={''} hidden selected>Start Time</option>
                    {timings.map((type) => {
                        return(
                            <option value={type.value}>{type.label}</option>
                        )
                    })}
                </select>
                {formData?.subEvent === 'perHour' && (
                    <select
                    className='propertyPageDateSectionstart SpotLetContent'
                    style={{textAlign : 'center'}}
                    value={formData?.noOfHours}
                    // onChange={(e)=>handleTiming(e.target.value)}
                    onChange={(e)=>setFormData({...formData,noOfHours : e.target.value})}
                    required
                    disabled={!formData?.startTime}
                >
                    <option value={''} hidden selected>No of Hours</option>
                    {hours.map((type) => {
                        return(
                            <option value={type.value}>{type.label}</option>
                        )
                    })}
                </select>
                )}
                </div>
                <input
                        className="eventRequestBookingTypeSelect SpotLetContent"
                        placeholder="No. of Guests" 
                        type="number"
                        name='guests'
                        value={formData?.guests}
                        onChange={(e)=>setFormData({...formData,guests : e.target.value})}
                        required
                        disabled={!formData?.event}
                        max={formData?.maxAttendees}
                        min = {formData?.minAttendees || 1}
                    />
            </div>
        )
    }
    
    const staySection = () => {
        return(
            <>
               <div className='propertyPageStayDateSection'>
                    <DatePicker
                        // showIcon
                        calendarClassName='propertyPageCalender SpotLetContent'
                        selected={formData?.checkIn}
                        value={formData?.checkIn}
                        minDate={new Date()}
                        dateFormat = 'dd-MM-yyyy'
                        onChange={(date) => handleCheckIn(date)}
                        excludeDates={getStayCheckInExcludedDates()}
                        filterDate={isStayWeekday}
                        placeholderText="Check In"
                        required
                    />
                    <DatePicker
                        // showIcon
                        // calendarIconClassName='propertyPageDatePickerCalender'
                        calendarClassName="SpotLetContent"
                        selected={formData?.checkOut}
                        value={formData?.checkOut}
                        minDate={formData?.checkIn ? new Date(formData?.checkIn) : new Date()}
                        dateFormat = 'dd-MM-yyyy'
                        onChange={(date) => setFormData({...formData,checkOut : date})}
                        excludeDates={getStayCheckOutExcludedDates()}
                        disabled = {formData?.checkIn ? false : true}
                        // filterDate={isStayWeekday}
                        placeholderText="Check Out"
                        required
                        maxDate={getMaxDate( formData?.checkIn ? new Date(formData?.checkIn) : new Date(), data?.stay?.timings )}
                    />
                    {formData?.event === 'stay' && (
                        <style jsx>{`
                        .react-datepicker-wrapper input::placeholder {
                            color: gray;
                            opacity: 1;
                            font-weight: 400;
                        }
                        .react-datepicker-wrapper{
                            height: 51px;
                            border-radius: 10px;
                            border: 1px solid #EAEAEA;
                            padding: 10px;
                            width : 233px;
                            margin-top : 20px;
                        }
                        .react-datepicker-wrapper input{
                            outline: none;
                            border: none;
                            width: 100%;
                        }
                        @media (max-width : 1188px) {
                            .react-datepicker-wrapper{
                                width: 100%;
                            }
                          }
                        
                    `}</style>
                    )}
                </div>
                <div className='propertyPageStayDateSection'>
                    <div className='propertyPagesStaynumberSection'>
                        <div className='d-flex flex-column justify-content-center align-center'>
                            <span className='stayPeopleHeading SpotLetContent '>Adults</span>
                            <span className='stayPeopleText SpotLetContent '>Ages 13 or above</span>
                        </div>
                        <div className='propertyPageStayNumberSection'>
                            <img src={minus} onClick={()=>updateAdults('-',formData?.adults)} className='cursor'/>
                            <span className='stayPeopleText SpotLetContent '>{formData?.adults}</span>
                            {/* <CustomTooltip open= {stayTooltips?.adults}  title="Reached Max Limit" > */}
                                <img src={plus} onClick={()=>updateAdults('+',formData?.adults)} className='cursor'/>
                            {/* </CustomTooltip> */}
                        </div>
                    </div>
                    <div className='propertyPagesStaynumberSection'>
                        <div className='d-flex flex-column justify-content-center align-center'>
                            <span className='stayPeopleHeading SpotLetContent'>Children</span>
                            <span className='stayPeopleText SpotLetContent '>Ages 2-12</span>
                        </div>
                        <div className='propertyPageStayNumberSection'>
                            <img src={minus} onClick={()=>updateChildren('-',formData?.children)}  className='cursor'/>
                            <span className='stayPeopleText SpotLetContent '>{formData?.children}</span>
                            {/* <CustomTooltip open= {stayTooltips?.children}  title="Reached Max Limit" > */}
                                <img  src={plus} onClick={()=>updateChildren('+',formData?.children)} className='cursor'/>
                            {/* </CustomTooltip> */}
                        </div>
                    </div>
                </div>
                <div className='propertyPagesStayInfantSection'>
                    <div className='d-flex flex-column justify-content-center align-center'>
                        <span className='stayPeopleHeading SpotLetContent '>Infants</span>
                        <span className='stayPeopleText SpotLetContent '>Ages below 2</span>
                    </div>
                    <div className='propertyPageStayNumberSection'>
                        <img src={minus} onClick={()=>updateInfants('-',formData?.infants)} className='cursor'/>
                        <span className='stayPeopleText SpotLetContent '>{formData?.infants}</span>
                        <img src={plus} onClick={()=>updateInfants('+',formData?.infants)} className='cursor'/>
                    </div>
                </div>
            </>
        )
    }
    //new end

    // stay pricing calculation start

    //Custom Tooltip styles
    // const CustomTooltip = styled(({ className, ...props }) => (
    //     <Tooltip {...props} arrow classes={{ popper: className }} />
    // ))(({ theme }) => ({
    //     [`& .${tooltipClasses.arrow}`]: {
    //     color: theme.palette.common.black,
    //     },
    //     [`& .${tooltipClasses.tooltip}`]: {
    //     backgroundColor: theme.palette.common.black,
    //     padding: '10px',
    //     fontSize : "13px",
    //     },
    // }));

    const countWeekdaysWeekends = (checkIn, checkOut) => {
        // Convert dates to Date objects
        const checkInDate = new Date(checkIn);
        const checkOutDate = new Date(checkOut);
    
        // Exclude the checkOut date
        checkOutDate.setDate(checkOutDate.getDate() - 1);
    
        let weekdays = 0;
        let weekends = 0;
    
        // Iterate through each day
        for (let date = new Date(checkInDate); date <= checkOutDate; date.setDate(date.getDate() + 1)) {
            const day = date.getDay();
            if (day === 5 || day === 6 || day === 0) { // Fri, Sat, Sun are weekends
                weekends++;
            } else { // Mon, Tue, Wed, Thu are weekdays
                weekdays++;
            }
        }
    
        return { weekdays, weekends };
    }

    const getStayPricing = (pricingData, formData) => {
        
        if (formData?.checkOut) {
            // do calculation 
            const checkIn = formData?.checkIn;
            const checkOut = formData?.checkOut;
            const { weekdays, weekends } = countWeekdaysWeekends(checkIn, checkOut);
            const totalBookingDays = weekdays + weekends;
            const extraGuestFee = pricingData?.extraGuestFee ? parseInt(pricingData?.extraGuestFee) : 0;
            // const extraChildFee = pricingData?.extraChildFee ? parseInt(pricingData?.extraChildFee) : 0;
            const weekdayPrice = pricingData?.weekdayPrice;
            const weekendPrice = pricingData?.weekendPrice;
            const totalGuests = formData?.adults + formData?.children;
            // const totalChildren = formData?.children;
            const extraGuests = totalGuests > pricingData?.baseGuests ? totalGuests - pricingData?.baseGuests : 0;
            const extraGuestsPrice = extraGuestFee && extraGuests ? parseInt(extraGuestFee * extraGuests * totalBookingDays ) : 0;
            // const totalChildrenPrice = extraChildFee ? parseInt(extraChildFee * totalChildren * totalBookingDays ) : 0; 

            const totalPrice = parseInt(weekdays * weekdayPrice) + parseInt(weekends * weekendPrice) + extraGuestsPrice ;
            const averageBookingPrice = Math.ceil(totalPrice/totalBookingDays);

            return averageBookingPrice
        } else {
            return pricingData?.weekdayPrice
        }
    }
    // stay pricing calculation end

    useEffect(() => {
        setStayAverageBookingPrice(getStayPricing(data?.stay, formData));
    }, [formData?.adults, formData?.children, formData?.checkIn, formData?.checkOut])


    return(
        <form style={subPropertyName && {minWidth : 'auto',minHeight : 'auto'}} className="eventRequestBookingTile" onSubmit={onSubmit} id='bookingForm'>
            <div className="w-100 d-flex flex-row justify-content-end">
                {(formData?.event === 'event' && formData?.subEvent === 'perPlate') && (
                    <div className="w-100 d-flex flex-column justify-content-end">
                        <div className="eventRequestBookingTilePriceBox" style={{backgroundColor : "#32ba55"}}>
                        <div className="d-flex flex-column">
                                <span className="HR SpotLetConten mb-1">Starts from</span>
                                <hr/>
                            </div>
                        <span className="HR SpotLetConten mb-1">Veg</span>
                            <span className="INR SpotLetSubHead"> {data?.currency}.{' '}<span className="price SpotLetMainHead">{data?.event?.perPlate?.vegPrice}/{' '}</span><span className="HR SpotLetContent">plate</span></span>
                            {formData?.minBooking && <span className="TIME SpotLetContent">{formData?.minBooking} hrs. Minimum</span>}
                        </div>
                        <div className="eventRequestBookingTileEventBox w-100" style={{alignItems : 'end'}}>
                            <span className="EVENT" style={{color : '#32ba55'}}>For {getEventName(formData?.event)}</span>
                        </div>
                    </div>
                )}
                {(formData?.event === 'event' && formData?.subEvent === 'perPlate' &&  data?.event?.nonVeg) && (
                    <div className="w-100 d-flex flex-column justify-content-end">
                        <div className="eventRequestBookingTilePriceBox w-100">
                        <div className="d-flex flex-column">
                                <span className="HR SpotLetConten mb-1">Starts from</span>
                                <hr/>
                            </div>
                            <span className="HR SpotLetConten mb-1">Non-Veg</span>
                            <span className="INR SpotLetSubHead"> {data?.currency}.{' '}<span className="price SpotLetMainHead">{data?.event?.perPlate?.nonVegPrice}/</span><span className="HR SpotLetContent">    plate</span></span>
                            {formData?.minBooking && <span className="TIME SpotLetContent">{formData?.minBooking} hrs. Minimum</span>}
                        </div>
                        <div className="eventRequestBookingTileEventBox w-100">
                            <span className="EVENT">For {getEventName(formData?.event)}</span>
                        </div>
                    </div>

                )}
                {formData?.subEvent !== 'perPlate' && (
                    <div className="w-100 d-flex flex-column justify-content-end">
                        <div className="eventRequestBookingTilePriceBox">
                            <div className="d-flex flex-column">
                                <span className="HR SpotLetConten mb-1"> { ( (formData?.event === 'stay') && (bookingServices?.stay) && (permissions?.stay) ) ? "" : "Starts from" }</span>
                                { !((formData?.event === 'stay') && (bookingServices?.stay) && (permissions?.stay)) && <hr/> }
                            </div>
                            <span className="INR SpotLetSubHead"> {data?.currency}.{' '}<span className="price SpotLetMainHead">{formData?.event === "stay" ? stayAverageBookingPrice : formData?.price}/</span><span className="HR SpotLetContent"> {getPriceText()}</span></span>
                            {(formData?.minBooking  && formData?.subEvent !== 'venue' ) && <span className="TIME SpotLetContent">{formData?.minBooking} hrs. Minimum</span>}
                            {(formData?.minBooking  && formData?.subEvent === 'venue' )&& <span className="TIME SpotLetContent">for {formData?.minBooking} hours</span>}
                        </div>
                        <div className="eventRequestBookingTileEventBox">
                            <span className="EVENT">For {getEventName(formData?.event)}</span>
                        </div>
                    </div>
                )}
                {/* { (formData?.event === 'stay') &&  (formData?.weekEndPrice) && (!(formData?.price === formData?.weekEndPrice)) && (
                    <div className="w-100 d-flex flex-column justify-content-end">
                        <div className="eventRequestBookingTilePriceBox">
                            <div className="d-flex flex-column">
                                <span className="HR SpotLetConten mb-1">WeekEnd</span>
                                <hr/>
                            </div>
                            <span className="INR SpotLetSubHead"> {data?.currency}.{' '}<span className="price SpotLetMainHead">{formData?.weekEndPrice}/</span><span className="HR SpotLetContent"> {getPriceText()}</span></span>
                            {(formData?.minBooking  && formData?.subEvent !== 'venue' ) && <span className="TIME SpotLetContent">{formData?.minBooking} hrs. Minimum</span>}
                            {(formData?.minBooking  && formData?.subEvent === 'venue' )&& <span className="TIME SpotLetContent">for {formData?.minBooking} hours</span>}
                        </div>
                        <div className="eventRequestBookingTileEventBox">
                            <span className="EVENT">For {getEventName(formData?.event)}</span>
                        </div>
                    </div>
                )} */}
            </div>
            <span className='propertyPageEventHeading SpotLetSubHead'>Select Type of Event</span>
            <div className='propertyPageEventSelectionContainer row'>
                {data?.film?.available && (
                    <div className='propertyPageEachSelection col-lg-3 col-md-6 mt-2'>
                        <input disabled = {!(data?.film?.available)}  type='checkbox' className={`CHECKBOX ${formData?.event === 'film' && 'EVENTACTIVE'}`} id='film' checked={formData?.event === 'film'} name='event' onChange={(e) => onChangeEventSelect('film')}/>
                        <label className={`LABEL ${formData?.event === 'film' && 'LABELACTIVE'}`} htmlFor='film'>Film Shooting</label>
                    </div>
                )}
                {data?.tv?.available && (
                    <div className='propertyPageEachSelection col-lg-3  col-md-6  mt-2'>
                        <input disabled = {!(data?.tv?.available)} type='checkbox' className={`CHECKBOX ${formData?.event === 'tv' && 'EVENTACTIVE'}`} id='tv' checked={formData?.event === 'tv'} name='event' onChange={(e) => onChangeEventSelect('tv')}/>
                        <label className={`LABEL ${formData?.event === 'tv' && 'LABELACTIVE'}`} htmlFor='tv'>Tv Shooting</label>
                    </div>
                )}
                {data?.event?.available && (
                    <div className='propertyPageEachSelection col-lg-3  col-md-6  mt-2'>
                        <input disabled = {!(data?.event?.available)} type='checkbox' className={`CHECKBOX ${formData?.event === 'event' && 'EVENTACTIVE'}`} id='event' checked={formData?.event === 'event'} name='event' onChange={(e) => onChangeEventSelect('event')}/>
                        <label className={`LABEL ${formData?.event === 'event' && 'LABELACTIVE'}`} htmlFor='event'>Events</label>
                    </div>
                )}
                {data?.stay?.available && (
                    <div className='propertyPageEachSelection col-lg-3  col-md-6  mt-2  '>
                        <input disabled = {!(data?.stay?.available)} type='checkbox' className={`CHECKBOX ${formData?.event === 'stay' && 'EVENTACTIVE'}`} id='stay' checked={formData?.event === 'stay'} name='event' onChange={(e) => onChangeEventSelect('stay')}/>
                        <label className={`LABEL ${formData?.event === 'stay' && 'LABELACTIVE'}`} htmlFor='stay'>Staycation</label>
                    </div>
                )}
            </div>  
            {formData?.event === 'film' && filmShootSection()}
            { formData?.event === 'tv' && tvShootSection()}
            { formData?.event === 'event' && eventSection()}
            { formData?.event === 'stay' && staySection()}
            <div className="w-100 d-flex flex-row justify-content-start align-items-start">
                {formData?.minAttendees && <span style={{ color:"#32ba55"}} className="SpotLetContent me-2" > Min Guests : {formData?.minAttendees} </span>}
                {formData?.maxAttendees && <span style={{ color:"#32ba55"}} className="SpotLetContent" > Max Guests : {formData?.maxAttendees} </span>}
            </div>
            <button
                // disabled = {getPermission}
                className ={`eventRequestBookingTypeSubmit SpotLetContent`}
                type='submit'
                >
                Request Booking
            </button>
            {showSignUp && 
                <AuthenitcateModal
                    show={showSignUp}
                    handleSuccess={()=> {
                        setShowSignUp(false)
                        handleLead()
                    }}
                    handleClose={()=>setShowSignUp(false)}
                    activeTab=''
                    activePage="property"
                />
            }
            {showWhatsAppModal && (
                <WhatsAppVerificationModal
                    show={showWhatsAppModal}
                    onHide={() => setShowWhatsAppModal(false)}
                    onSuccess={() => { 
                        setShowWhatsAppModal(false)
                        handleLead()
                    }}
                    // phoneNumber = {number}
                    userDetails = {userProfile?.personalInfo}
                    userId = {userProfile._id}
                    header={"Please share your mobile number"}
                />
            )}
            {
                showConfirmationModal && (
                <ConfirmationModel
                    show={showConfirmationModal}
                    handleClose={() => setShowConfirmationModal(false)}
                    handleOk={() => setShowConfirmationModal(false)}
                    title="Booking"
                    body={errorMsg}
                />
                )
            }
            {loader && <PageLoader/>}
        </form>
    )
}
export default EventRequestBookingTile;