import { useState } from "react";
import { saveEnquiryData } from "../../../apis/api";
import { ClaimPropertyModal } from "../../../components/Modal/ClaimPropertyModal"
import { ConfirmationModel } from "../../../components/Modal/ConfirmationModel";
import Map from "../components/Map";

const Location = ({data, locationId}) => {

    const [showClaimPopup, setShowClaimPopup] = useState(false);
    const [showConfirmationModal, setShowConfirmationModal] = useState(false)
    const [confirmationBody, setConfirmationBody] = useState("")


    return (
        <>
            <div className="locationMainContainer" >
                <span className="locationMainHeading SpotLetMainHead" id='propertyPage7'>Location</span>
                <div className="locationContentContainer row">
                    <div className="locationTextContainer col-lg-4">
                        <div>
                            <div className="d-flex justify-content-between flex-wrap">
                                <li className="locationText SpotLetContent">Country/Region</li>
                                <span className="locationText SpotLetContent">{data?.country}</span>
                            </div>
                            <div className="d-flex justify-content-between">
                                <li className="locationText SpotLetContent">State</li>
                                <span className="locationText SpotLetContent">{data?.state}</span>
                            </div>
                            <div className="d-flex justify-content-between">
                                <li className="locationText SpotLetContent">Zip/Post/Pin Code</li>
                                <span className="locationText SpotLetContent">{data?.pincode}</span>
                            </div>
                            <div className="d-flex justify-content-between">
                                <li className="locationText SpotLetContent">City</li>
                                <span className="locationText SpotLetContent">{data?.city}</span>
                            </div>
                            <div className="d-flex justify-content-between">
                                <li className="locationText SpotLetContent">Street/Area</li>
                                <span className="locationText SpotLetContent">{data?.area}</span>
                            </div>
                        </div>
                        <div className="d-flex flex-column justify-content-between align-items-center">
                            <span className="locationText SpotLetSubHead">Claim this Property</span>
                            <button className="btn btn-primary" style={{width:"100px"}}  type="button" onClick = {() => {
                                setShowClaimPopup(true)
                            } } > Claim </button>
                        </div>
                    </div>
                    <div className="locationMapContainer col-lg-8">
                        <Map data={data} />
                    </div>
                </div>
            </div>
            {
                showClaimPopup &&
                <ClaimPropertyModal
                    show={showClaimPopup}
                    handleClose={() => setShowClaimPopup(false)}
                    handleOk={async(data) => {
                        try {
                            const res = await saveEnquiryData("Claim Property", data)
                            setConfirmationBody(res?.data?.message);
                            setShowConfirmationModal(true);
                            setShowClaimPopup(false);
                        } catch (error) {
                            console.log(error);
                            setConfirmationBody(error?.response?.data?.message);
                            setShowConfirmationModal(true);
                        }
                    }}
                    locationId = {locationId}
                />
            }
            {
                showConfirmationModal && (
                <ConfirmationModel
                    show={showConfirmationModal}
                    handleClose={() => setShowConfirmationModal(false)}
                    handleOk={() => setShowConfirmationModal(false)}
                    title="Claim Property"
                    body={confirmationBody}
                />
                )
            }
        </>
    )
}
export default Location;