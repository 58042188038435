import Slider from "react-slick";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Rating from "@mui/material/Rating";

// Images
import blueTik from "../../../assets/NewImages/Home/hexagon-check.svg";
import next from '../../../assets/NewImages/Home/next.webp';
import prev from '../../../assets/NewImages/Home/prev.webp';

import "./style.css";

function SampleNextArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{ ...style, display: "block", height: '50px', width: '100px' }}
    ><img loading="lazy" src={next} className="arrowImg" onClick={onClick} alt='Previous'/></div>
  );
}

function SamplePrevArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{ ...style, display: "block", height: '50px', width: '100px', zIndex: '1', marginLeft: '-10px' }}
    ><img loading="lazy" src={prev} className="arrowImg" onClick={onClick} alt="Next"/></div>
  );
}



const ClientTestimonials = ({data}) => {

  // const [clientTestimonials, setClientTestimonials] = useState([])

  var settings = {
    dots: false,
    infinite: true,
    speed: 300,
    swipeToSlide: false,
    fade: false,
    swipe: false,
    slidesToShow: data?.length >= 2 ? 2 : data?.length, // 2,
    slidesToScroll: 1,
    initialSlide: 0,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    responsive: [
      {
        breakpoint: 1025,
        settings: {
          slidesToShow: data?.length >= 2 ? 2 : data?.length, // 2,
          slidesToScroll: 1,
          infinite: true,
        }
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: data?.length >= 2 ? 2 : data?.length, //2,
          slidesToScroll: 1,
          infinite: true,
          initialSlide: 0
        }
      },
      {
        breakpoint: 510,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
        }
      }
    ]
  };


  // const getTestimonialsData = async () => {
  //   try {
  //     const response = await getTestimonials();
  //     setClientTestimonials(response?.data.testimonialsData)
  //   } catch (e) {
  //     console.log(e)
  //   }
  // }

  // useEffect(() => {
  //   getTestimonialsData()
  // }, [])

  return (
    <div>
      <Slider {...settings}>
        {data &&
          data?.map((file, i) => {
            return (
              <div className="item  testimonials-item" key={i}>
                <Box className="testimonials-Card"   >
                  <Box>
                    <Rating name="size-small" defaultValue={file.rating} readOnly />
                    <Typography className="testimonialsCardSubText SpotLetContent" dangerouslySetInnerHTML={{
                      __html: file.content
                    }}></Typography>
                  </Box>
                  <Box className="userInfoBox">
                    <div className="userProfilePic">
                      <p style={{ marginTop: "13px" }} className="SpotLetMainHead" >{file.name[0]}</p>
                    </div>
                    <Box sx={{ position: "relative" }} ml={2} >
                      <img loading="lazy" className="blueTik" src={blueTik} />
                      <Typography className="User_name SpotLetMainHead">{file.name}</Typography>
                      <Typography className="userRole SpotLetSubHead">{file.location}</Typography>
                    </Box>
                  </Box>
                </Box>
              </div>
            )
          })}
      </Slider>
    </div>
  )
}

// class App extends React.Component {
//   render() {
//     return (
//       <div>
//         <Slider {...settings}>
//           {testimonials.map((file, i) => {
//             return (
//               <div className="item  testimonials-item" key={i}>
//                 <Box className="testimonials-Card"   >
//                   <Box>
//                     <Rating name="size-small" defaultValue={4} />
//                     <Typography className="testimonialsCardSubText">{file.content}</Typography>
//                   </Box>
//                   <Box className="userInfoBox">
//                     <div className="userProfilePic">
//                       <p style={{ marginTop: "13px" }} >{file.name[0]}</p>
//                     </div>
//                     <Box sx={{ position: "relative" }} ml={2} >
//                       <img loading="lazy" className="blueTik" src={blueTik} />
//                       <Typography className="User_name">{file.name}</Typography>
//                       <Typography className="userRole">{file.area}</Typography>
//                     </Box>
//                   </Box>
//                 </Box>
//               </div>
//             )
//           })}
//         </Slider>
//       </div>
//     );
//   }
// }


export default ClientTestimonials;
