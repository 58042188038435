import { getBaseUrl, getSearchBaseUrl, getNotificationUrl, getAuthServiceUrl, getRegion } from "../utils/baseUrl";
import axios from "./interceptors";

const baseURL = getBaseUrl();
const searchURL = getSearchBaseUrl();
const notificationURL = getNotificationUrl();
// const defRegion = getRegion();

// using in Home --> Properties 1
export const getLocationsByType = (type) => {
  return axios.get(`${baseURL}/locationsbytype/${type}`);
};

// latestlocations for Home --> index.jsx 2
export const getLatestLocations = (type) => {
  return axios.get(`${baseURL}/latestlocations/${type?.locationType}`);
};

// using in SearchPlaces.jsx 3
export const getProperties = () => {
  return axios.get(`${baseURL}/getlocations`);
};

// using in BookingDetails.jsx 4
export const getBookingDetails = (id) => {
  const auth = localStorage.getItem("auth");
  return axios.get(`${baseURL}/booking/${id}`, {
    headers: {
      Authorization: `Bearer ${auth}`,
    },
  });
};

// using in PropertyPage --> Properties --> SimilarProperties 5
export const getSimilarProperties = (data) => {
  const auth = localStorage.getItem("auth");
  return axios.post(`${baseURL}/getsimilarproperties`, data, {
    headers: {
      Authorization: `Bearer ${auth}`,
    },
  });
};

// using in SignUp.jsx 6
export const getOtp = (details) => {
  return axios.post(`${baseURL}/otp`, { ...details });
};

// using in Login.jsx 7
export const signIn = (details) => {
  return axios.post(`${baseURL}/signin`, details);
};

export const getSignInOtp = (details) => {
  return axios.post(`${baseURL}/signinOtp`, { ...details });
};

// using multiple times in SignUp.jsx 8
export const signUp = (details) => {
  return axios.post(`${baseURL}/signup`, details);
};

// using in Calender.jsx and auth.js 9
export const getUserDetails = (userId) => {
  const auth = localStorage.getItem("auth");
  return axios.get(`${baseURL}/user/${userId}`, {
    headers: {
      Authorization: `Bearer ${auth}`,
    },
  });
};

//update fav
// Using in FeaturedProperties.jsx and PropertyInfo.jsx and Pages --> PropertPage --> Component --> TopTabs 10
export const updateFavourites = (data) => {
  const auth = localStorage.getItem("auth");
  return axios.post(`${baseURL}/favourites/`, data, {
    headers: {
      Authorization: `Bearer ${auth}`,
    },
  });
};

//getFavourites
// Using multiple times in src -->  Pages --> Favorites 11
export const getFavouritesList = (userId, sort, value) => {
  const auth = localStorage.getItem("auth");
  return axios.post(`${baseURL}/getfavourites`, { userId, value, sort }, {
    headers: {
      Authorization: `Bearer ${auth}`,
    },
  });
};

//create fav folder
// Using in src -->  Pages --> Favorites  12
export const createFavouriteFolder = (userId, name) => {
  const auth = localStorage.getItem("auth");
  return axios.post(`${baseURL}/createfavourites/`, { userId, name }, {
    headers: {
      Authorization: `Bearer ${auth}`,
    },
  });
};

//delete fav folder
// Using in src -->  Pages --> Favorites 13
export const deleteFavouriteFolder = (userId, name) => {
  const auth = localStorage.getItem("auth");
  return axios.post(`${baseURL}/deletefavourites/`, { userId, name }, {
    headers: {
      Authorization: `Bearer ${auth}`,
    },
  });
};

// Using in src -->  components --> becomeHost  --> Amenities.jsx 14
export const getAmenities = async () => {
  const auth = localStorage.getItem("auth");
  const response = await axios.get(`${baseURL}/amenities_type_lookup`, {
    headers: {
      Authorization: `Bearer ${auth}`,
    },
  });
  return response.data;
};

// Using in src -->  components --> becomeHost  --> DetailDesc.jsx 15
export const getLocationTypes = async () => {
  const response = await axios.get(`${baseURL}/location_type_lookup`);
  return response.data;
};

// Using multiple times in src -->  components --> becomeHost  --> Amenities.jsx 16
export const getFeatureTypes = async () => {
  const auth = localStorage.getItem("auth");
  const response = await axios.get(`${baseURL}/feature_type_lookup`, {
    headers: {
      Authorization: `Bearer ${auth}`,
    },
  });
  return response.data;
};

// Using multiple times in src -->  components --> becomeHost  --> DoDont.jsx 17
export const getDoLookup = async () => {
  const auth = localStorage.getItem("auth");
  const response = await axios.get(`${baseURL}/dos_type_lookup`, {
    headers: {
      Authorization: `Bearer ${auth}`,
    },
  });
  return response.data;
};

// Using multiple times in src -->  components --> becomeHost  --> DoDont.jsx 18
export const getDontLookup = async () => {
  const auth = localStorage.getItem("auth");
  const response = await axios.get(`${baseURL}/dontdos_type_lookup`, {
    headers: {
      Authorization: `Bearer ${auth}`,
    },
  });
  return response.data;
};

// Using multiple times in src -->  components --> becomeHost  --> DoDont.jsx 19
export const getRulesLookup = async () => {
  const auth = localStorage.getItem("auth");
  const response = await axios.get(`${baseURL}/rules_type_lookup`, {
    headers: {
      Authorization: `Bearer ${auth}`,
    },
  });
  return response.data;
};

// Using in src -->  components --> modal  --> Locationtype.jsx and NewLookup.jsx 20
export const createLookup = async (payload) => {
  const auth = localStorage.getItem("auth");
  const response = await axios.post(`${baseURL}/create_lookup`, payload, {
    headers: {
      Authorization: `Bearer ${auth}`,
    },
  });
  return response.data;
};

// Using in src -->  pages -->  EditProfile.jsx 21
export const updateUserDetails = (userId, details) => {
  const auth = localStorage.getItem("auth");
  return axios.put(
    `${baseURL}/user/update/${userId}`,
    { ...details },
    {
      headers: {
        Authorization: `Bearer ${auth}`,
      },
    }
  );
};

// 22
export const createLocation = async (details, selectedPlan, planPaymentId) => {
  const auth = localStorage.getItem("auth");
  const response = await axios.post(
    `${baseURL}/templocation`,
    { data: details, plan: selectedPlan, planPaymentId: planPaymentId },
    {
      headers: {
        Authorization: `Bearer ${auth}`,
      },
    }
  );
  return response.data;
};

//upload gst docs 23
export const uploadGstDocs = async (formData) => {
  const auth = localStorage.getItem("auth");
  const response = await axios.post(
    `${baseURL}/uploadgst`,
    formData, {
    headers: {
      "Content-Type": "multipart/form-data",
      Authorization: `Bearer ${auth}`,
    },
  });
  return response.data;
};

// 24
export const getTempLocation = async (id) => {
  const auth = localStorage.getItem("auth");
  const response = await axios.get(`${baseURL}/templocation/${id}`, {
    headers: {
      Authorization: `Bearer ${auth}`,
    },
  });
  return response.data;
};

// 25
export const getLocationById = async (id) => {
  const auth = localStorage.getItem("auth");
  const response = await axios.get(`${baseURL}/locations/${id}`, {
    headers: {
      Authorization: `Bearer ${auth}`,
    },
  });
  return response.data;
};

// 26
export const getLocation = async (id) => {
  const auth = localStorage.getItem("auth");
  const response = await axios.get(`${baseURL}/getlocation/${id}`, {
    headers: {
      Authorization: `Bearer ${auth}`,
    },
  });
  return response.data;
};

// 27
export const updateLocation = async (details) => {
  const auth = localStorage.getItem("auth");
  const response = await axios.post(`${baseURL}/updatelocation`, details, {
    headers: {
      Authorization: `Bearer ${auth}`,
    },
  });
  return response.data;
};

//28
export const updateSubscriptionPlan = async (details) => {
  const auth = localStorage.getItem("auth");
  const response = await axios.post(`${baseURL}/updatesubscriptionplan`, details, {
    headers: {
      Authorization: `Bearer ${auth}`,
    },
  });
  return response.data;
};


// 29
export const updateTempLocation = async (details) => {
  const auth = localStorage.getItem("auth");
  const response = await axios.post(`${baseURL}/templocation`, details, {
    headers: {
      Authorization: `Bearer ${auth}`,
    },
  });
  return response.data;
};

//upload location pics 30
export const uploadLocationPics = async (formData) => {
  const auth = localStorage.getItem("auth");
  const response = await axios.post(baseURL + "/uploadlocpic", formData, {
    headers: {
      "Content-Type": "multipart/form-data",
      Authorization: `Bearer ${auth}`,
    },
  });
  return response.data;
};

//delete files from storage 31
export const deleteFiles = (formData) => {
  const auth = localStorage.getItem("auth");
  const response = axios.delete(baseURL + `/deletefile`, {
    headers: {
      Authorization: `Bearer ${auth}`,
    },
    data: formData,
  });
  return response.data;
};

//create location 32
export const createNewLocation = async (form) => {
  const auth = localStorage.getItem("auth");
  const response = await axios.post(`${baseURL}/createlocation`, form, {
    headers: {
      Authorization: `Bearer ${auth}`,
    },
  });
  return response.data;
};

// craete location 33
export const deActiveUser = (userId) => {
  const auth = localStorage.getItem("auth");
  return axios.post(
    `${baseURL}/delreq/${userId}`,
    {},
    {
      headers: {
        Authorization: `Bearer ${auth}`,
      },
    }
  );
};


// 34
export const updateBillingDetails = (userId, details) => {
  const auth = localStorage.getItem("auth");
  return axios.put(
    `${baseURL}/user/updateBilling/${userId}`,
    { ...details },
    {
      headers: {
        Authorization: `Bearer ${auth}`,
      },
    }
  );
};


// 35
export const getBookings = (userId) => {
  const auth = localStorage.getItem("auth");
  return axios.get(`${baseURL}/bookings/user/${userId}`, {
    headers: {
      Authorization: `Bearer ${auth}`,
    },
  });
};

export const getListingBookings = (propertyId) => {
  const auth = localStorage.getItem("auth");
  return axios.get(`${baseURL}/bookings/listing/${propertyId}`, {
    headers: {
      Authorization: `Bearer ${auth}`,
    },
  });
};

// 36
export const getBookingRequests = (userId, locationId) => {
  const auth = localStorage.getItem("auth");
  return axios.get(`${baseURL}/bookings/requests/${userId}?locationId=${locationId}`, {
    headers: {
      Authorization: `Bearer ${auth}`,
    },
  });
};

// 37
export const getDrafts = (userId) => {
  const auth = localStorage.getItem("auth");
  return axios.get(`${baseURL}/user/incomplist/${userId}`, {
    headers: {
      Authorization: `Bearer ${auth}`,
    },
  });
};

// 38
export const getUserLocations = (userId) => {
  const auth = localStorage.getItem("auth");
  return axios.get(`${baseURL}/user/locations/${userId}`, {
    headers: {
      Authorization: `Bearer ${auth}`,
    },
  });
};

// 39
export const getLocations = () => {
  return axios.get(`${baseURL}/location_type_lookup`);
};
//for getting new location types // not using
export const getSearchLocationTypes = () => {
  return axios.get(`${baseURL}/searchLocationTypes`) 
}

// 40
// export const getCities = () => {
//   const region = localStorage.getItem('region');
//   return axios.get(`${baseURL}/getcities/?region=${region}`);
// };
export const getCities = (event) => {
  const region = localStorage.getItem('region');
  return axios.get(`${baseURL}/getcities/?region=${region}&event=${event}`);
};
export const getAllCities = (data) => {
  return axios.post(`https://countriesnow.space/api/v0.1/countries/state/cities`,data);
};

// 41 unnecessary
export const getLocationsOnSearch = (data) => {
  const auth = localStorage.getItem("auth");
  return axios.get(
    `${baseURL}/getLocations`,
    { params: data },
    {
      headers: {
        Authorization: `Bearer ${auth}`,
      },
    }
  );
};

// 41
export const getSearchLocations = (data, page, noOfLocations) => {
  return axios.post(
    `${searchURL}/getSearchLocations`,
    { data, page, noOfLocations }
  );
};

// 42
export const forgotPassword = (data) => {
  return axios.post(`${baseURL}/forgot-password`, data);
};

// 43
export const resetPasswords = (userId, newPassword) => {
  return axios.put(`${baseURL}/user/reset-password/${userId}`, {
    newPassword,
  });
};

//upload pics 44
export const uploadPics = (formData) => {
  const auth = localStorage.getItem("auth");
  return axios.post(`${baseURL}/user/upload-pic`, formData, {
    headers: {
      "Content-Type": "multipart/form-data",
      Authorization: `Bearer ${auth}`,
    },
  });
};

//subscription 45
export const subscription = (data) => {
  const auth = localStorage.getItem("auth");
  return axios.post(`${baseURL}/payment/subscription`, { data }, {
    headers: {
      Authorization: `Bearer ${auth}`,
    },
  });
};

export const saveSubscription = (data) => {
  const auth = localStorage.getItem("auth");
  return axios.post(`${baseURL}/payment/subscription/save`, data, {
    headers: {
      Authorization: `Bearer ${auth}`,
    },
  });
};



// 46
export const bookingRequest = (form) => {
  const auth = localStorage.getItem("auth");
  return axios.post(`${baseURL}/bookingreq`, form, {
    headers: {
      Authorization: `Bearer ${auth}`,
    },
  });
};

// 47
export const updateBookingStatus = (form) => {
  const auth = localStorage.getItem("auth");
  return axios.put(`${baseURL}/updatepaymentstatus`, form, {
    headers: {
      Authorization: `Bearer ${auth}`,
    },
  });
};

// 48
export const createPaymentOrder = (form) => {
  const auth = localStorage.getItem("auth");
  return axios.post(`${baseURL}/payment/orders`, form, {
    headers: {
      Authorization: `Bearer ${auth}`,
    },
  });
};

// 49
export const updateBookingPaymentStatus = (form) => {
  const auth = localStorage.getItem("auth");
  return axios.put(`${baseURL}/updateBookingPaymentStatus`, form, {
    headers: {
      Authorization: `Bearer ${auth}`,
    },
  });
};

//jobs 50
export const getJobs = (page, offSet, searchTerm) => {
  return axios.get(`${baseURL}/jobs/?page=${page}&items_per_page=${offSet}&searchTerm=${searchTerm}`);
};

// 51
export const applyJob = (formData, selectedJob, userId) => {
  return axios.post(`${baseURL}/jobs/apply`, { data: formData, jobId: selectedJob, userId: userId });
};

// 52
export const uploadResume = (formData) => {
  return axios.put(`${baseURL}/jobs`, formData, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
};

//coupons 53
export const getUserLocationIds = (id) => {
  const auth = localStorage.getItem('auth');
  return axios.get(`${baseURL}/getuserlocationids/${id}`,
    {
      headers: {
        Authorization: `Bearer ${auth}`
      }
    })
}

// 54
export const createCoupon = (form) => {
  const auth = localStorage.getItem("auth");
  return axios.post(
    `${baseURL}/coupon`, form,
    {
      headers: {
        Authorization: `Bearer ${auth}`,
      },
    }
  );
};

// 55
export const getCoupons = (status, plan,userId, currentSubscription) => {
  const auth = localStorage.getItem("auth"); 
  const region = localStorage.getItem('regionId');
  var url = `${baseURL}/coupons` 
  return axios.post(url, {status, plan,userId, region, currentSubscription},  {
    headers: {
      Authorization: `Bearer ${auth}`,
    },
  });
};

// 56
export const getCouponDetails = (couponId) => {
  const auth = localStorage.getItem("auth");
  var url = `${baseURL}/coupon/${couponId}`;
  return axios.get(url, {
    headers: {
      Authorization: `Bearer ${auth}`,
    },
  });
};

// 57
export const updateCoupon = (data) => {
  const auth = localStorage.getItem("auth");
  return axios.put(`${baseURL}/coupon`, data,
    {
      headers: {
        Authorization: `Bearer ${auth}`,
      },
    });
};

//Reviews 58
export const createUserReview = (data, locationId) => {
  const auth = localStorage.getItem("auth");
  return axios.post(`${baseURL}/locationreview`, { data, locationId },
    {
      headers: {
        Authorization: `Bearer ${auth}`,
      },
    });
};

// 59
export const createHostReview = (data, userId) => {
  const auth = localStorage.getItem("auth");
  return axios.post(`${baseURL}/userreview`, { data, userId },
    {
      headers: {
        Authorization: `Bearer ${auth}`,
      },
    });
};

// 60
export const updateUserReview = (data, locationId) => {
  const auth = localStorage.getItem("auth");
  return axios.put(`${baseURL}/locationreview`, { data, locationId },
    {
      headers: {
        Authorization: `Bearer ${auth}`,
      },
    });
};

// 61
export const updateHostReview = (data, userId) => {
  const auth = localStorage.getItem("auth");
  return axios.put(`${baseURL}/userreview`, { data, userId },
    {
      headers: {
        Authorization: `Bearer ${auth}`,
      },
    });
};

// ------------Reviews Section------------- //

// Received from Users 62
export const getReviewsFromUsers = (data) => {
  const auth = localStorage.getItem("auth");
  return axios.get(`${baseURL}/reviews1/${data.userId}/?pageNo=${data.pageNo}&noOfItems=${data.noOfItems}`, {
    headers: {
      Authorization: `Bearer ${auth}`,
    },
  });
}

//given to users 63
export const getReviewsGivenToUsers = (data) => {
  const auth = localStorage.getItem("auth");
  return axios.get(`${baseURL}/reviews2/${data.userId}/?pageNo=${data.pageNo}&noOfItems=${data.noOfItems}&searchTerm=${data.searchTerm}&sort=${data.sort}`, {
    headers: {
      Authorization: `Bearer ${auth}`,
    },
  });
}

//given to other Hosts 64
export const getReviewsGivenToOtherHosts = (data) => {
  const auth = localStorage.getItem("auth");
  return axios.get(`${baseURL}/reviews3/${data.userId}/?pageNo=${data.pageNo}&noOfItems=${data.noOfItems}&searchTerm=${data.searchTerm}&sort=${data.sort}`, {
    headers: {
      Authorization: `Bearer ${auth}`,
    },
  });
}

//given by other hosts 65
export const getReviewsFromOtherHosts = (data) => {
  const auth = localStorage.getItem("auth");
  return axios.get(`${baseURL}/reviews4/${data.userId}/?pageNo=${data.pageNo}&noOfItems=${data.noOfItems}&searchTerm=${data.searchTerm}&sort=${data.sort}`, {
    headers: {
      Authorization: `Bearer ${auth}`,
    },
  });
}

// all property reviews 66
export const getAllPropertyReviews = (data) => {
  const auth = localStorage.getItem("auth");
  return axios.get(`${baseURL}/locationreview/${data.locationId}/?pageNo=${data.pageNo}&noOfItems=${data.noOfItems}&searchTerm=${data.searchTerm}&sort=${data.sort}`, {
    headers: {
      Authorization: `Bearer ${auth}`,
    },
  });
}

// 67
export const addReply = (data) => {
  const auth = localStorage.getItem("auth");
  return axios.put(
    `${baseURL}/addreviewreply`,
    data,
    {
      headers: {
        Authorization: `Bearer ${auth}`,
      },
    }
  );
};


// for getting notifications count from services 68
export const noOfUserNotifications = (id) => {
  const auth = localStorage.getItem("auth");
  return axios.get(
    `${baseURL}/notifications/count/${id}`,
    {
      headers: {
        Authorization: `Bearer ${auth}`,
      },
    }
  );
}

export const getUserNotifications = (id) => {
  const auth = localStorage.getItem("auth");
  return axios.get(
    `${baseURL}/getUserNotifications/${id}`,
    {
      headers: {
        Authorization: `Bearer ${auth}`,
      },
    }
  );
}

// 69
export const markNotificationAsRead = (data) => {
  const auth = localStorage.getItem("auth");

  return axios.put(
    `${baseURL}/marknotification`, data,
    {
      headers: {
        Authorization: `Bearer ${auth}`,
      },
    }
  );
}

export const deleteNotification = (data) => {
  const auth = localStorage.getItem("auth");

  return axios.put(
    `${baseURL}/deleteNotification`, data,
    {
      headers: {
        Authorization: `Bearer ${auth}`,
      },
    }
  );
}

// 70
export const getNotificationSorted = (UserId, limit, search, sort, date) => {
  const auth = localStorage.getItem("auth");
  return axios.get(
    `${baseURL}/notifications/${UserId}/?limit=${limit}&searchTerm=${search}&sort=${sort}&date=${date}`,
    {
      headers: {
        Authorization: `Bearer ${auth}`,
      },
    }
  );
}
export const getNotifications = (data) => {
  const auth = localStorage.getItem("auth");
  return axios.get(
    `${baseURL}/getnotifications/${data?.id}/?type=${data?.type}&search=${data?.search}&sort=${data?.sort}`,
    {
      headers: {
        Authorization: `Bearer ${auth}`,
      },
    }
  );
}

// 71
export const getTestimonials = () => {
  const auth = localStorage.getItem("auth");
  return axios.get(
    `${baseURL}/testimonials`
  );
}

//upload location Vids 72
export const uploadLocationVideos = async (formData) => {
  const auth = localStorage.getItem("auth");
  const response = await axios.post(baseURL + "/uploadlocvid", formData, {
    headers: {
      Authorization: `Bearer ${auth}`,
    },
  });
  return response.data;
};

//delete files from storage 73
export const deleteVid = (formData) => {
  const auth = localStorage.getItem("auth");
  const response = axios.delete(baseURL + `/deletevid`, {
    headers: {
      Authorization: `Bearer ${auth}`,
    },
    data: formData,
  });
  return response.data;
};

// 74
export const getSettings = () => {
  const auth = localStorage.getItem("auth");
  return axios.get(
    `${baseURL}/settings`,
    {
      headers: {
        Authorization: `Bearer ${auth}`,
      },
    }
  );
}

// For Getting Coupon Usage Details 75
export const getCouponUsageDetails = (couponId) => {
  const auth = localStorage.getItem("auth");
  return axios.get(
    `${baseURL}/coupons/${couponId}`,
    {
      headers: {
        Authorization: `Bearer ${auth}`,
      },
    }
  );
}

// Apply coupon
export const applyCouponCode = async (couponId, plan) => {
  const auth = localStorage.getItem("auth");
  return axios.post(baseURL + "/coupon/apply", {couponId, plan}, {
    headers: {
      Authorization: `Bearer ${auth}`,
    },
  });
}

// Bill Desk Api 76
export const createBDPaymentOrder = (form) => {
  const auth = localStorage.getItem("auth");
  return axios.post(`${baseURL}/payment/bdorder`, form, {
    headers: {
      Authorization: `Bearer ${auth}`,
    },
  });
};

export const deActivateRequest = (userId) => {
  const auth = localStorage.getItem("auth");
  return axios.post(
    `${baseURL}/deactivatereq/${userId}`,
    {},
    {
      headers: {
        Authorization: `Bearer ${auth}`,
      },
    }
  );
};

export const deactivateAccount = (userId,data) => {
  const auth = localStorage.getItem("auth");
  return axios.post(
    `${baseURL}/deactivate/${userId}`,
    data,
    {
      headers: {
        Authorization: `Bearer ${auth}`,
      },
    }
  );
};
export const reActivateAccount = (id) => {
  const auth = localStorage.getItem("auth");
  return axios.put(
    `${baseURL}/activate/${id}`, {},
    {
      headers: {
        Authorization: `Bearer ${auth}`,
      },
    }
  );
}
export const sendActivationLink = (data) => {
  const auth = localStorage.getItem("auth");
  return axios.put(
    `${baseURL}/activate_link`, data,
    {
      headers: {
        Authorization: `Bearer ${auth}`,
      },
    }
  );
}

export const enquirePlan = (data) => {
  const auth = localStorage.getItem("auth");
  return axios.post(
    `${baseURL}/enquirePlan`,
    data,
    {
      headers: {
        Authorization: `Bearer ${auth}`,
      },
    }
  );
};

//get meta details
export const getPageDetails = (title) => {
  const auth = localStorage.getItem("auth");
  return axios.post(`${baseURL}/cms`, {title}, {
    headers: {
      Authorization: `Bearer ${auth}`,
    },
  });
}

// to update password
export const updatePassword = (userId, values) => {
 const auth = localStorage.getItem("auth");
  return axios.put(
    `${baseURL}/user/updatepassword/${userId}`,
     {...values},
     {
    headers: {
      Authorization: `Bearer ${auth}`,
    },
  }
     );
};

// to save find my spot data home page
export const saveFindMySpotData = (data) => {
  const auth = localStorage.getItem("auth");
  return axios.post(
    `${baseURL}/findmyspot`,
     data,
     {
    headers: {
      Authorization: `Bearer ${auth}`,
    },
  }
     );
}

// to save event planning req data home page
export const saveEventPlanningRequest = (data) => {
  const auth = localStorage.getItem("auth");
  return axios.post(
    `${baseURL}/eventplanning`,
     data,
     {
    headers: {
      Authorization: `Bearer ${auth}`,
    },
  }
     );
}

//******Incomplete listing start******
//create incomplete listing
export const createIncompleteListing = (data) => {
  const auth = localStorage.getItem("auth");
  return axios.post(
    `${baseURL}/incompleteListing`,
     data,
     {
    headers: {
      Authorization: `Bearer ${auth}`,
    },
  }
     );
}
//update incomplete listing
export const updateIncompleteListing = (data) => {
  const auth = localStorage.getItem("auth");
   return axios.put(
     `${baseURL}/incompleteListing`,
     data,
      {
     headers: {
       Authorization: `Bearer ${auth}`,
     },
   }
      );
 };
//get single incomplete listing
 export const getIncompleteListing = (id) => {
  const auth = localStorage.getItem("auth");
  return axios.get(`${baseURL}/incompleteListing/${id}`, {
    headers: {
      Authorization: `Bearer ${auth}`,
    },
  });
}
//get incomplete listings
export const getUserIncompleteListings = (id) => {
  const auth = localStorage.getItem("auth");
  return axios.get(`${baseURL}/incompleteListings/${id}`, {
    headers: {
      Authorization: `Bearer ${auth}`,
    },
  });
}
//******Incomplete listing end******

//******listing start******
//create listing
export const createListing = (data) => {
  const auth = localStorage.getItem("auth");
  return axios.post(
    `${baseURL}/listing`,
     data,
     {
    headers: {
      Authorization: `Bearer ${auth}`,
    },
  }
     );
}
//get listings
export const getUserListings = (id) => {
  const auth = localStorage.getItem("auth");
  return axios.get(`${baseURL}/userlisting/${id}`, {
    headers: {
      Authorization: `Bearer ${auth}`,
    },
  });
}
//get single listing
export const getListing = (id) => {
  const auth = localStorage.getItem("auth");
  return axios.get(`${baseURL}/listing/${id}`, {
    headers: {
      Authorization: `Bearer ${auth}`,
    },
  });
}
//update listing
export const updateListing = (data,submit) => {
  const auth = localStorage.getItem("auth");
   return axios.put(
     `${baseURL}/listing/?submit=${submit}`,
     data,
      {
     headers: {
       Authorization: `Bearer ${auth}`,
     },
   }
      );
 };
//******listing end******

// for new listings search page 
export const searchListings = (data, page, noOfLocations) => {
  const region = localStorage.getItem('region');
  return axios.post(
    `${baseURL}/getSearchListings/?region=${region}`,
    { data, page, noOfLocations }
  );
};

export const getSearchListings = (data, page, noOfLocations) => {

  // if (data.searchInput.length > 0 ) {
  //   const region = localStorage.getItem('region');
  //   return axios.get(
  //     `https://devsearch.spotlet.in/locations/search?text=${data.searchInput}`
  //   );
  // } else {

  //   return searchListings(data, page, noOfLocations);
  // }
  return searchListings(data, page, noOfLocations);

  
};

//getNewFavourites
// Using multiple times in src -->  Pages --> Favorites 11
export const getNewFavouritesList = (userId, sort, value) => {
  const auth = localStorage.getItem("auth");
  return axios.post(`${baseURL}/getNewfavourites`, { userId, value, sort }, {
    headers: {
      Authorization: `Bearer ${auth}`,
    },
  });
};

// new listing compare page data
export const getListingById = async (id) => {
  const auth = localStorage.getItem("auth");
  const response = await axios.get(`${baseURL}/listings/${id}`, {
    headers: {
      Authorization: `Bearer ${auth}`,
    },
  });
  return response.data;
};

//get type of properties list
export const getTypeOfProperties = () => {
  return axios.get(`${baseURL}/getTypeOfProperties`);
};

//get amenities list
export const getListingAmenities = async () => {
  const auth = localStorage.getItem("auth");
  const response = await axios.get(`${baseURL}/getListingAmenities`, {
    headers: {
      Authorization: `Bearer ${auth}`,
    },
  });
  return response.data;
};

// get compare properties details 
export const getComparePropertiesDetails = async(data) => {
  const auth = localStorage.getItem("auth");
  const response = await axios.post(`${baseURL}/compareproperties`, data, {
    headers: {
      Authorization: `Bearer ${auth}`,
    },
  });
  return response.data;
};

// using in PropertyPage --> Properties --> SimilarProperties 5
export const getSimilarListings = (data) => {
  const auth = localStorage.getItem("auth");
  return axios.post(`${baseURL}/getsimilarlistings`, data, {
    headers: {
      Authorization: `Bearer ${auth}`,
    },
  });
};

// send booking request mail
export const sendBookingRequest = (data) => {
  const auth = localStorage.getItem("auth");
  return axios.post(`${baseURL}/sendbookingrequest`, data, {
    headers: {
      Authorization: `Bearer ${auth}`,
    },
  });
};

// send get in touch mail
export const sendGetInTouch = (data) => {
  const auth = localStorage.getItem("auth");
  return axios.post(`${baseURL}/sendgetntouch`, data, {
    headers: {
      Authorization: `Bearer ${auth}`,
    },
  });
};

// get Google ratings
export const getGoogleRatings = (id) => {
  const auth = localStorage.getItem("auth");
  return axios.get(`${baseURL}/googleRatings/${id}`, {
    headers: {
      Authorization: `Bearer ${auth}`,
    },
  });
};

//add Recent Events
export const addRecentEvents = (id,data) => {
  const auth = localStorage.getItem("auth");
  return axios.put(`${baseURL}/recentEvent/${id}`,
    data,
        {
      headers: {
        Authorization: `Bearer ${auth}`,
      },
    }
  )
}

// get Recent Events
export const getRecentEvents = (id) => {
  const auth = localStorage.getItem("auth");
  return axios.get(`${baseURL}/recentEvent/${id}`, {
    headers: {
      Authorization: `Bearer ${auth}`,
    },
  });
};

//delete recentEvent
export const deleteRecentEvent = (id,eventId) => {
  const auth = localStorage.getItem("auth");
  return axios.delete(baseURL + `/recentEvent/${id}/${eventId}`, {
    headers: {
      Authorization: `Bearer ${auth}`,
    },
  });
};

// get listing leads
export const getListingLeads = (id, type, userId, filterData, count, skip, searchTerm) => {
  const auth = localStorage.getItem("auth");
  ///?type=${type}&userId=${userId}&start=${filterData?.start}&end=${end}
  return axios.post(`${baseURL}/leads/${id}`,
  {
    id,
    type,
    userId,
    start : filterData.start,
    end : filterData.end,
    limit :count,
    skip,
    searchTerm,
  }, 
  {
    headers: {
      Authorization: `Bearer ${auth}`,
    },
  });
};

//send click lead
export const sendClickLead = (id,userId) => {
  const auth = localStorage.getItem("auth");
  return axios.put(`${baseURL}/leads/${id}/${userId}`, {}, {
      headers: {
        Authorization: `Bearer ${auth}`,
      },
    });
};

//to get dashboard details 
export const getDashBoardDetails = (id) => {
  const auth = localStorage.getItem("auth");
  return axios.get(`${baseURL}/dashBoardDetails/${id}`, {
    headers: {
      Authorization: `Bearer ${auth}`,
    },
  });
}
// send priority support
export const addPrioritySupport = (data) => {
  const auth = localStorage.getItem("auth");
  return axios.post(`${baseURL}/listingsupport`, data, {
    headers: {
      Authorization: `Bearer ${auth}`,
    },
  });
};
//to get listing support data
export const getListingSupports = (id) => {
  const auth = localStorage.getItem("auth");
  return axios.get(`${baseURL}/listingsupport/${id}`, {
    headers: {
      Authorization: `Bearer ${auth}`,
    },
  });
}

// to get plan Amounts 
export const getPlans = () => {
  const auth = localStorage.getItem("auth");
  return axios.get(`${baseURL}/plans`, {
    headers: {
      Authorization: `Bearer ${auth}`,
    },
  });
}


// To get Home Page Data
export const getHomePageData = () => {
  const auth = localStorage.getItem("auth");
  const region = localStorage.getItem('region');
  const response = axios.get(`${baseURL}/home/?region=${region}`, {
    headers: {
      Authorization: `Bearer ${auth}`,
    },
  });
  return response;
}

// To get Home Page Data
export const getVideoLink = (id) => {
  const auth = localStorage.getItem("auth");
  const response = axios.get(`${baseURL}/video/${id}`, {
    headers: {
      Authorization: `Bearer ${auth}`,
    },
  });
  return response;
}

// to get loction types based on event
export const getLocationTypesByEvent = (event) => {
  const auth = localStorage.getItem("auth");
  const response = axios.get(`${baseURL}/locationTypes/${event}`, {
    headers: {
      Authorization: `Bearer ${auth}`,
    },
  });
  return response;
}
// to get all tags
export const getTags = (id, eventPlanning) => {
  const auth = localStorage.getItem("auth");
  const response = axios.get(`${baseURL}/tag/?status=Active&propertyId=${id}&eventPlanning=${eventPlanning}`, {
    headers: {
      Authorization: `Bearer ${auth}`,
    },
  });
  return response;
}
// to get property tags
export const getPropertyTags = (id) => {
  const auth = localStorage.getItem("auth");
  const response = axios.get(`${baseURL}/listingTags/${id}`, {
    headers: {
      Authorization: `Bearer ${auth}`,
    },
  });
  return response;
}
//add tags to property
export const addPropertyTag = (id,data) => {
  const auth = localStorage.getItem("auth");
  return axios.put(`${baseURL}/listingTags/${id}`, data, {
      headers: {
        Authorization: `Bearer ${auth}`,
      },
    });
};
//remove tags to property
export const removePropertyTag = (id,data) => {
  const auth = localStorage.getItem("auth");
  return axios.delete(baseURL + `/listingTags/${id}`, {
    headers: {
      Authorization: `Bearer ${auth}`,
    },
    data: {data},
  });
};
//create new tag
export const createTag = (data) => {
  const auth = localStorage.getItem("auth");
  return axios.post(`${baseURL}/tag`, data, {
      headers: {
        Authorization: `Bearer ${auth}`,
      },
    });
};

// get regions from db
export const getRegions = () => {
  const auth = localStorage.getItem("auth");
  return axios.get(`${baseURL}/regions`, {
    headers: {
      Authorization: `Bearer ${auth}`,
    },
  });
}

// send host get in touch mail
export const sendHostGetInTouch = (data) => {
  const auth = localStorage.getItem("auth");
  return axios.post(`${baseURL}/hostgetintouch`, data, {
    headers: {
      Authorization: `Bearer ${auth}`,
    },
  });
};

//send newsletter approval mail
export const sendNewsLetterMail = (email) => {
  const auth = localStorage.getItem("auth");
  return axios.post(`${baseURL}/newsLetter`, {email}, {
    headers: {
      Authorization: `Bearer ${auth}`,
    },
  });
};

//subscribe/unsubscribe newsletter approval mail
export const updateNewsLetter = (id, status) => {
  const auth = localStorage.getItem("auth");
  return axios.put(`${baseURL}/newsLetter`, {id, status}, {
    headers: {
      Authorization: `Bearer ${auth}`,
    },
  });
};
// to get subscription details
export const getSubscription = (id) => {
  const auth = localStorage.getItem("auth");
  const response = axios.post(`${baseURL}/subscription`, {"listingId" : id}, {
    headers: {
      Authorization: `Bearer ${auth}`,
    },
  });
  return response;
}

// to save claim property data 
export const saveEnquiryData = (type, data) => {
  const auth = localStorage.getItem("auth");
  return axios.post(
    `${baseURL}/saveEnquiry/${type}`,
     data,
     {
    headers: {
      Authorization: `Bearer ${auth}`,
    },
  }
     );
}

// to get enquiry data 
export const getEnquiryData = (data) => {
  const auth = localStorage.getItem("auth");
  return axios.put(
    `${baseURL}/getEnquiry`, {...data},
    {
      headers: {
        Authorization: `Bearer ${auth}`,
      },
    } 
  );
}
// to get all tags
export const getAllTags = (data) => {
  const auth = localStorage.getItem("auth");
  const response = axios.post(`${baseURL}/allTags`, {...data} ,
  {
    headers: {
      Authorization: `Bearer ${auth}`,
    },
  });
  return response;
}

// to get GetServiceDetails
export const GetServiceDetails = (id,locationId) => {
  const auth = localStorage.getItem("auth");
  return axios.get(
    `${baseURL}/allServices/${id}?locationId=${locationId}`,
     {
      headers: {
        Authorization: `Bearer ${auth}`,
    },
  }
     );
}

  // to get user requested leads
  export const getUserLeads = (data) => {
    const auth = localStorage.getItem("auth");
    const response = axios.post( `${baseURL}/getUserLeads`, {...data} ,
    {
      headers: {
        Authorization: `Bearer ${auth}`,
      },
    });
    return response;
  }

  export const changeBookingStatus = (data) => {
    const auth = localStorage.getItem("auth");
    const response = axios.put( `${baseURL}/updateBookingStatus`, data ,
    {
      headers: {
        Authorization: `Bearer ${auth}`,
      },
    });
    return response;
  }
  export const addPaymentStatusAndDetails = (data) => {
    const auth = localStorage.getItem("auth");
    const response = axios.put( `${baseURL}/updatePaymentDetails`, data ,
    {
      headers: {
        Authorization: `Bearer ${auth}`,
      },
    });
    return response;
  }
  export const userBookingCancellation = (data) => {
    const auth = localStorage.getItem("auth");
    const response = axios.put( `${baseURL}/cancelBooking`, data ,
    {
      headers: {
        Authorization: `Bearer ${auth}`,
      },
    });
    return response;
  }

  // using in BookingDetails.jsx 4
export const getInvoiveDetails = (id) => {
  const auth = localStorage.getItem("auth");
  return axios.get(`${baseURL}/invoice/${id}`, {
    headers: {
      Authorization: `Bearer ${auth}`,
    },
  });
};

// to get user requested leads
export const deleteFilesFromBucket = (fileUrls) => {
  const auth = localStorage.getItem("auth");
  return axios.delete(baseURL + `/deletefiles`, {
      headers: {
        Authorization: `Bearer ${auth}`,
      },
      data: {fileUrls},
  });
}