import { useState, useEffect, useRef } from 'react';
import { Helmet } from "react-helmet";
import Rating from '@mui/material/Rating';
import { useParams } from 'react-router-dom';
import { getListingById, sendClickLead, saveEnquiryData } from "../../apis/api"
import './newPropertyPage.css'
import { useAuthStore } from '../../store/auth';
import Constants from "../../utils/Helmet";
import { Button } from 'react-bootstrap';
import { FaPhone } from "react-icons/fa6";

///Components
import { Header } from "../../components/NewHeader/Header"
import { Footer } from "../../components/Footer"
import EventRequestBookingTile from './components/EventRequestBookingTile';
import TypeOfPorperty from './components/TypeOfProperty';
import Amenities from './components/Amenities';
import RoomsSpaces from './components/RoomsSpaces';
import Timings from './components/Timings';
import Gallery from './components/Gallery';
import Policies from './components/Policies';
// import PropertyManagerDetails from './components/PropertyManagerDetails';
import Location from './components/Location';
import SubProperties from './components/SubProperties';
import GetInTouch from './components/GetInTouch';
import SimilarProperties from './components/SimilarProperties';
// import Testimonials from './components/Testimonials';
import RecentEvents from './components/RecentEvents'
import TopTabs from "./components/TopTabs"

//Modal
import { WhatsAppVerificationModal } from '../../components/Modal/WhatsAppVerificationModal';
import { ConfirmationModel } from '../../components/Modal/ConfirmationModel';
import AuthenitcateModal from '../../utils/NewAuthentication/AuthenitcateModal';
import HostContactFormModal from './Modals/HostContantFormModal';
import { ScheduleVisitModal } from '../../components/Modal/ScheduleVisitModal';

//Images
import locationIcon from "../../assets/NewImages/PropertyPage/Group 54.svg";
import film from "./assets/images/film.svg";
import tv from "./assets/images/tv.svg";
import event from "./assets/images/event.svg";
import stay from "./assets/images/stay.svg";
import remove from "./assets/images/remove.svg";
import correct from "./assets/images/correct.svg";
import googleIcon from "./assets/images/google.svg";
import album from "./assets/images/album.svg";

const SLIDER_ITEMS = ["Property Type","Amenities", 0, "Timings","Gallery","Policies", 0, "Location",0,0,0,'Enquiry'] // , "Contact Info", "Location"

const NewPropertyPage = () => {
    const params = useParams()
    const [init,setInit] = useState(false)
    const { userProfile, setUserProfile, bookingServices } = useAuthStore();
    const locationId = params.id
    const [subscription,setSubscription] = useState('Basic')
    const [showName,setShowName] = useState(false)
    const [locationData, setLocationData] = useState({})
    const [information, setInformation] = useState('')
    const [activeTab,setActiveTab] = useState(0)
    const [clickLead,setClickLead] = useState(false)
    const [sliderItems, setSlidertems] = useState(SLIDER_ITEMS);
    const [approveEnquiries, setApproveEnquiries] = useState(false);
    const [showWhatsAppModal, setShowWhatsAppModal] = useState(false)
    const [showConfirmationModel, setShowConfirmationModel] = useState(false);
    const [confirmationTitle, setConfirmationTitle] = useState("");
    const [confirmationBody, setConfirmationBody] = useState("");
    const [showContactForm, setShowContactForm] = useState("");
    const [scheduleVisit, setScheduleVisit] = useState(false)
    const [showScheduleVisitModal, setShowScheduleVisitModal] = useState(false);


    const setTab = (index) => {
        setActiveTab(index)
        const element = document.getElementById(`propertyPage${index}`)
        if (element) element.scrollIntoView({behavior: "smooth",block: "center"});
    }

    
    window.addEventListener('scroll', function() {

        var propertyType = document.getElementById('propertyPage0') || null
        if(propertyType) propertyType = propertyType.getBoundingClientRect();

        var amenites = document.getElementById('propertyPage1') || null
        if(amenites) amenites = amenites.getBoundingClientRect();

        var rooms = document.getElementById('propertyPage2') || null
        if(rooms) rooms = rooms.getBoundingClientRect();

        var timings = document.getElementById('propertyPage3') || null
        if(timings) timings = timings.getBoundingClientRect();

        var gallery = document.getElementById('propertyPage4') || null
        if(gallery) gallery = gallery.getBoundingClientRect();

        var policies = document.getElementById('propertyPage5') || null
        if(policies) policies = policies.getBoundingClientRect();

        var manager = document.getElementById('propertyPage6') || null
        if(manager) manager = manager.getBoundingClientRect();

        var location = document.getElementById('propertyPage7') || null
        if(location) location = location.getBoundingClientRect();

        var subProperties = document.getElementById('propertyPage8') || null
        if(subProperties) subProperties = subProperties.getBoundingClientRect();

        var reviews = document.getElementById('propertyPage9') || null
        if(reviews) reviews = reviews.getBoundingClientRect();

        var recent = document.getElementById('propertyPage10') || null
        if(recent) recent = recent.getBoundingClientRect();

        var enquiry = document.getElementById('propertyPage11') || null
        if(enquiry) enquiry = enquiry.getBoundingClientRect();

        const scrollArrey = [propertyType,amenites,rooms,timings,gallery,policies,manager,location,subProperties,reviews,recent,enquiry]
        for(let [index,tab] of scrollArrey.entries()){
            // checking whether fully visible
            if(tab?.top >= 0 && tab?.bottom <= window.innerHeight) {
                if(activeTab !== index){
                    setActiveTab(index) 
                    return null
                }
            }
        }
    });

    const getLocationData = async () => {
        try {
          const response = await getListingById(locationId);
          setLocationData(response);
          setSubscription(response?.subscription_id);
          setApproveEnquiries(response?.approveEnquiries);
          if (response?.features?.length > 0) if (!(SLIDER_ITEMS.includes("Rooms/Spaces"))) SLIDER_ITEMS.splice(2, 1,"Rooms/Spaces") 
        //   if (response?.property_manager_details) if(!(SLIDER_ITEMS.includes("Contact Info"))) SLIDER_ITEMS.splice(6, 1,"Contact Info")
          if (response?.sub_properties && response?.sub_properties?.length > 0)  if(!(SLIDER_ITEMS.includes("Sub Properties"))) SLIDER_ITEMS.splice(8, 1,"Sub Properties") // push("Sub Properties") // setSlidertems([...SLIDER_ITEMS, "Reviews"]);
        //   if (response?.googleReviews?.length > 0)  if(!(SLIDER_ITEMS.includes("Reviews"))) SLIDER_ITEMS.splice(9, 1,"Reviews") // .push("Reviews") // setSlidertems([...SLIDER_ITEMS, "Reviews"]);
          if (response?.recentEvents?.length > 0)  if(!(SLIDER_ITEMS.includes("Recent Events"))) SLIDER_ITEMS.splice(10, 1,"Recent Events") // .push("Recent Events") // setSlidertems([...SLIDER_ITEMS, "Recent Events"]);
        //   if (response?.subscription_id !== "Basic")  if(!(SLIDER_ITEMS.includes("Enquiry"))) SLIDER_ITEMS.splice(11, 1,"Enquiry") // .push("Recent Events") // setSlidertems([...SLIDER_ITEMS, "Recent Events"]);
          setSlidertems([...SLIDER_ITEMS]);
        } catch (err) {
          console.log(err)
        }
      }
    const handleClickHere = async (value) => {
        if(!userProfile){
            setShowSignUp(true)
            return null
        }
        if ( userProfile?.personalInfo?.mobileVerified || (value === "true" ) ) {
            // if (!approveEnquiries) {
            //     return null;
            // }
            // if(checkStandard() || checkPremium()){
            //     setShowName(true)
            // }
            setShowContactForm(true)
            if(!clickLead){
                try{
                    setClickLead(true)
                    await sendClickLead(locationData?.location_id,userProfile?._id)
                }catch(error){
                    console.log(error)
                }
            }
        }  else {
            setShowWhatsAppModal(true)
        }
    }
    const checkStandard = () => {
        if(subscription === 'Standard') return true
        return false
    }
    const checkPremium = () => {
        if(subscription === 'Premium') return true
        return false
    }

    
    useEffect(() => {
        if(locationId && init) {
            getLocationData();
        } 
      }, [init]);
    useEffect(() => {
        window.scrollTo(0, 0);
        setInit(true)
      }, []);

    const getPricingData = (data) => {
        const reqData = {
            film : data?.film?.available,
            tv : data?.tv?.available,
            event : data?.event?.available,
            stay : data?.stay?.available,
        }
        const extraData = {
            film : data?.film?.available ? {securityDeposit : data?.film?.securityDeposit, cleaningFee : data?.film?.cleaningFee} : {},
            tv : data?.tv?.available ? {securityDeposit : data?.tv?.securityDeposit, cleaningFee : data?.tv?.cleaningFee} : {},
            event : data?.event?.available ? {securityDeposit : data?.event?.securityDeposit, cleaningFee : data?.event?.cleaningFee} : {},
            stay : data?.stay?.available ? {securityDeposit : data?.stay?.securityDeposit, cleaningFee : data?.stay?.cleaningFee} : {},
        }
        return {reqData, extraData}
    }

    const onWhatsAppVerificationSuccess = (value) => {
        if (value === "true") {
            setShowWhatsAppModal(false)
            setConfirmationTitle("Property");
            setConfirmationBody("WhatsApp Number Verified Successfully");
            setShowConfirmationModel(true);
            setUserProfile()
            handleClickHere("true")
        } else {
            setConfirmationTitle("Error");
            setConfirmationBody("Error while verifying WhatsApp Number");
            setShowConfirmationModel(true);
        }
    }

    //Lead start
    const [showSignUp,setShowSignUp] = useState(false)
    const handleLead = async () => {
        try{
            const user = await setUserProfile();
            if(user){
                if(user?.personalInfo?.mobile){
                    setClickLead(true)
                    setShowContactForm(true)
                    await sendClickLead(locationData?.location_id,user?._id)
                } else {
                    setShowWhatsAppModal(true);
                }
            }
        }catch(error){
            console.log(error)
        }
    }
    //Lead end

    const onClickScheduleVisit = () => {
        if (!userProfile) {
            setScheduleVisit(true);
            setShowSignUp(true);
        } else {
            if ( userProfile?.personalInfo?.mobileVerified) {
                setShowScheduleVisitModal(true);
            } else {
                setShowWhatsAppModal(true)
            }
        }
    }

    const handleVisitSignupSuccess = async() => {
        try{
            const user = await setUserProfile()
            if(user){
                setShowSignUp(false)
                if (user?.personalInfo?.mobile) {
                    setShowScheduleVisitModal(true)
                } else {
                    setShowWhatsAppModal(true);
                }
            }
        }catch(error){
            console.log(error)
        }
    }

    const  formatNumber = (number) => {
        const suffixes = ["", "k", "M", "B", "T"];
        if (number < 1000) {
            return number.toString();
          }
        const suffixNum = Math.floor(("" + number).length / 3);
        // let shortValue = parseFloat((suffixNum != 0 ? (number / Math.pow(1000, suffixNum)) : number).toPrecision(2));
        const shortValue = number / Math.pow(1000, suffixNum);
        // if (shortValue % 1 !== 0) {
        //     shortValue = shortValue.toFixed(1);
        // }
        return shortValue + suffixes[suffixNum];
    }

    const getBadgeText = (services, plan) => {
        if (services?.stay && bookingServices?.stay) {
          return "Hassle Free Booking";
        } else if (plan === "Standard") {
          return  "Recommended"
        } else if (plan === "Premium") {
          return "Highly Recommended"
        } else {
          return "Verified"
        }
      }

    return(
        <div className='propertyPage-bg-container'>
            <Header />
            {/* <Helmet>
                <title>{locationId}</title>
                <meta name="description" content={locationData?.basic_info?.description} />
                <meta name="keywords" content={`${locationData?.basic_info?.property_name},${locationData?.property_desc?.property_type},${locationData?.address?.city}`}/>
            </Helmet> */}
            {/* banner start */}
            <Constants/>
            <div className='container-fluid'>
                <div className='row propertyPage1stContainer'>
                    <div  className='col-lg-6 propertyPageProfileImageContainer'>
                        <img src={locationData?.images?.[locationData?.location_id]?.[0]?.image} alt='profile iamge' className='propertyPageProfileImage'/>
                        <div className="galleyShowAllContainer propertyPageMainImageContainer cursor" 
                        onClick={() => setTab(4)}
                        >
                            <img src={album} alt='album' />
                            <span className="SpotLetContent">{`Show All(${locationData?.images?.[locationData?.location_id]?.length})`}</span>
                        </div>
                    </div>
                    <div className='col-lg-6 propertyPagePricingContainer'>
                        <div className='d-flex flex-column justify-content-center align-center'>
                            {/* <span className='propertyPageName'>{locationData?.location_type}</span> */}
                            {/* {showName && 
                            <span className='propertyPagePropName SpotLetMainHead'>{locationData?.basic_info?.property_name}</span>
                            } */}
                            <div className='d-flex flex-row justify-content-center align-items-center flex-wrap' >
                                <span className='propertyPageId SpotLetMainHead'>{locationData?.location_id}
                                </span> 
                                { ( (locationData?.subscription_id !== "Basic") || (locationData?.bookingServices?.stay && bookingServices?.stay) ) && (
                                    <div className="property-page-badge SpotLetContent" >
                                        {getBadgeText(locationData?.bookingServices, locationData?.subscription_id)}
                                    </div>
                                )
                                }
                                {/* { !showName && (locationData?.subscription_id !== "Basic") ?   
                                <span className={`propertyPageSeePropertyName SpotLetContent cursor ${!approveEnquiries && "buttonDisabled"}`} onClick={handleClickHere}>{' '}See Property Name</span>
                                <button type='button' className='SpotLetContent' > See Property Name </button>
                                : ""} */}
                             </div>
                             {/* </span> */}
                            <div className='d-flex justify-content-center align-center'>
                                <img src={locationIcon} alt='marker image' />
                                <span className='mx-2 mt-1 SpotLetContent'>{locationData?.address?.area}, {locationData?.address?.city}, {locationData?.address?.state}</span>
                            </div>
                            <div className='d-flex justify-content-center align-center'>
                                <span className='SpotLetContent mt-1'>
                                    Property views{' '}-{" "}
                                    {locationData?.propertyViews ? formatNumber(locationData?.propertyViews) : 0}
                                </span>
                            {/* <img src = {googleIcon} className='googleLogo' alt = "googleIcon" title='google ratings & reviews'/>
                            <Rating
                                name="half-rating-read"
                                precision={0.5}
                                value={locationData?.googleRatings?.rating ? locationData?.googleRatings?.rating  : 0} 
                                readOnly
                                style={{ marginRight: '6px' }}
                            />
                            <span className='SpotLetContent mt-1'>{locationData?.googleRatings?.rating ? locationData?.googleRatings?.rating  : 0} ({locationData?.googleRatings?.total_ratings ? locationData?.googleRatings?.total_ratings : 0} Reviews)</span> */}
                            </div>
                            <div className='propertyRequestBookingContainer'>
                                <EventRequestBookingTile locationId = {locationData?.location_id} data={locationData?.pricing} owner_id ={locationData?.user_id} bookingServices={locationData?.bookingServices}
                                setInformation={(data) => {
                                    setConfirmationTitle('Booking status')
                                    setConfirmationBody(data)
                                    setShowConfirmationModel(true)
                                }} approveEnquiries = {approveEnquiries} />
                                {information && (
                                    <div className='informationBox'>
                                        <span className='eventTextHeading'>{information}</span>
                                        <img src={remove} alt="remove"  className='cursor' onClick={() => setInformation('')}/>
                                    </div>
                                )}
                            </div>
                            <div className='mt-2 propertyPageTopTabsContainer d-flex flex-column justify-content-center align-items-center'>
                                <div className='propertyPageLeadsButtonsContainer'>
                                    {/* <Button variant='primary' onClick={handleClickHere} className='mb-2'></Button> */}
                                    <button class="custom-ui-button propertyPageContactHostBtn"  onClick={handleClickHere}> <span> Contact Host </span>  <span class="arrow"><FaPhone style={{fontSize : '12px'}}/></span></button>
                                    {/* { locationData?.pricing?.film?.available &&  <Button variant='secondary' onClick={onClickScheduleVisit} className='mb-2 mx-2'>Schedule Visit</Button>} */}
                                    { locationData?.pricing?.film?.available &&  <button class="custom-ui-button propertyPageContactHostBtn"  onClick={onClickScheduleVisit}> <span> Schedule Visit </span>  <span class="arrow">&gt;</span></button>}
                                </div>
                                <TopTabs
                                    favoriteDisplay="true"
                                    // absolute={true}
                                    // homePage={true}
                                    // top="10px"
                                    location_id={locationData?.location_id}
                                />
                            </div>
                        </div>
                    </div>

                </div>
            </div>
            {/* banner end */}
            {/* slider start */}
            <div className='mainPropertyPageContainer'>
                <div className='sliderPropertyPageContainer'>
                    {sliderItems.map((item,i) => {
                        if(item){
                            return(
                            <div className={activeTab === i ? 'sliderItem sliderItemActive cursor' : 'sliderItem cursor'} key={i} onClick={()=>setTab(i)}>
                                <span className='SpotLetContent' >{item}</span>
                            </div>
                            )
                        }
                    })}
                </div>
            </div>
            {/* slider end */}
            {/* event start */}
            <div className='eventContainer'>
                <div className={locationData?.pricing?.film?.available ? "eventCardContainer" : "eventCardContainer opacity-50"} >
                    <div className='eventCardImage'>
                        <img src={film} alt='film' className='svgColorRed' />
                    </div>
                    <div className='eventTextContainer' >
                        <span className='eventTextHeading SpotLetSubHead'>Film Shootings</span>
                        <span  className='eventText SpotLetSubHead'><img src={locationData?.pricing?.film?.available ? correct : remove} alt='remove'/>{locationData?.pricing?.film?.available? 'Available' : 'Not Available'}</span>
                    </div>
                </div>
                <div className={locationData?.pricing?.tv?.available ? "eventCardContainer" : "eventCardContainer opacity-50"} >
                    <div className='eventCardImage'>
                        <img src={tv} alt='tv' />
                    </div>
                    <div className='eventTextContainer' >
                        <span className='eventTextHeading SpotLetSubHead '>TV Shootings</span>
                        <span  className='eventText'><img src={locationData?.pricing?.tv?.available ? correct : remove} alt='remove'/>{ locationData?.pricing?.tv?.available ? 'Available' : 'Not Available'}</span>
                    </div>
                </div>
                <div className={locationData?.pricing?.event?.available ?"eventCardContainer" : "eventCardContainer opacity-50"}>
                    <div className='eventCardImage'>
                        <img src={event} alt='event' />
                    </div>
                    <div className='eventTextContainer'>
                        <span className="eventTextHeading SpotLetSubHead " >Events</span>
                        <span  className='eventText SpotLetSubHead '><img src={locationData?.pricing?.event?.available ? correct : remove} alt='remove'/>{locationData?.pricing?.event?.available ? 'Available' : 'Not Available'}</span>
                    </div>
                </div>
                <div  className={locationData?.pricing?.stay?.available ?"eventCardContainer" : "eventCardContainer opacity-50"}>
                    <div className='eventCardImage'>
                        <img src={stay} alt='event' />
                    </div>
                    <div className='eventTextContainer'>
                        <span className='eventTextHeading SpotLetSubHead '>Homestays</span>
                        <span  className='eventText SpotLetSubHead '><img src={locationData?.pricing?.stay?.available ? correct : remove} alt='remove'/>{locationData?.pricing?.stay?.available ? 'Available' : 'Not Available'}</span>
                    </div>
                </div>
            </div>
            {/* event end */}
            {/* type of property start */}
            <TypeOfPorperty image={locationData?.images?.[locationData?.location_id]?.[1]?.image} type={locationData?.location_type} data={locationData?.basic_info} locationId = {locationData?.location_id} />
            {/* type of property end */}
            {/* amenities start */}
            <Amenities image={locationData?.images?.[locationData?.location_id]?.[2]?.image} data={locationData?.amenities} defaultAmenities = {locationData?.defaultAmenities} />
            {/* amenities end */}
            {/* roomsSpaces start */}
            {locationData?.features?.length > 0 && <RoomsSpaces images = {locationData?.images} data={locationData?.features}/> }
            {/* roomsSpaces end */}
            {/* Timings start */}
            <Timings timings={locationData?.pricing}/>
            {/* Timings end */}
            {/*Gallery start */}
            <Gallery locationId={locationData?.location_id} data={locationData?.images} videosData = {locationData?.videos} subscription = {locationData.subscription_id}/>
             {/* gallery end  */}
             {/* Policies start */}
            <Policies pricingData = {getPricingData(locationData?.pricing)} data={locationData?.policies}/>
             {/* Policies end  */}
            {/* propertyManagerDetails start */}
            {/* {(checkPremium()) && <PropertyManagerDetails image={locationData?.images?.[locationData?.location_id]?.[3]?.image} data={locationData?.property_manager_details}/>} */}
             {/* propertyManagerDetails end  */}
            {/* location start */}
            <Location data={locationData?.address} locationId = {locationData?.location_id}/>
             {/* location end  */}
            {/* SubProperties start */}
            { (locationData?.sub_properties && (checkStandard() || checkPremium()) ) && <SubProperties data={locationData?.sub_properties} owner_id ={locationData?.user_id} locationId = {locationData?.location_id} approveEnquiries = {approveEnquiries} />}
             {/* SubProperties end  */}
             {/* Testimonials Start */}
             {/* { (locationData?.googleReviews && (checkStandard() || checkPremium())) && <Testimonials reviews = {locationData?.googleReviews} length = {locationData?.googleReviews?.length}/>} */}
             {/* Testimonials End */}
             {/* Recent Events Start */}
             {(checkPremium() &&  locationData?.recentEvents?.length ) &&  <RecentEvents data = {locationData?.recentEvents} />}
             {/* Recent Events End */}
            {/* get in touch start */}
            {<GetInTouch locationId = {locationData?.location_id} image={locationData?.images?.[locationData?.location_id]?.[4]?.image} approveEnquiries = {approveEnquiries} />}
             {/* get in touch end  */}
             {/* SimilarProperties Start */}
             <SimilarProperties id = {locationData?.location_id} type = {locationData?.location_type} city = {locationData?.address?.city}  />
             {/* SimilarProperties End */}
            <Footer />
            {showWhatsAppModal && (
                <WhatsAppVerificationModal
                    show={showWhatsAppModal}
                    onHide={() => setShowWhatsAppModal(false)}
                    onSuccess={(value) => { 
                        setShowWhatsAppModal(false)
                        // onWhatsAppVerificationSuccess(value)
                        if (scheduleVisit) {
                            handleVisitSignupSuccess()
                        } else {
                            handleLead()
                        }
                    }}
                    // phoneNumber = {number}
                    userDetails = {userProfile?.personalInfo}
                    userId = {userProfile._id}
                    header={"Please share your mobile number"}
                />
            )}
            {showConfirmationModel && (
                <ConfirmationModel
                show={showConfirmationModel}
                handleClose={() => setShowConfirmationModel(false)}
                handleOk={() => setShowConfirmationModel(false)}
                title={confirmationTitle}
                body={confirmationBody}
                />
            )}
            {showSignUp && 
                <AuthenitcateModal
                    show={showSignUp}
                    handleSuccess={()=> {
                        if (scheduleVisit) {
                            handleVisitSignupSuccess()
                        } else {
                            setShowSignUp(false)
                            handleLead()
                        }
                    }}
                    handleClose={()=> {
                        setShowSignUp(false)
                        setScheduleVisit(false)
                    }}
                    activeTab=''
                    activePage="property"
                />
            }
            {showContactForm && 
                <HostContactFormModal
                    show={showContactForm}
                    handleSuccess={()=> {
                        setShowSignUp(false)
                        handleLead()
                    }}
                    onHide={()=>setShowContactForm(false)}
                    data = {(checkStandard() || checkPremium()) ?  {
                        locationName : locationData?.basic_info?.property_name,
                        name : locationData?.property_manager_details?.contact_person_name,
                        designation : locationData?.property_manager_details?.contact_person_designation,
                        number : locationData?.property_manager_details?.contact_person_number,
                        altNumber : locationData?.property_manager_details?.alternate_person_number,
                    } : {
                        number : "+919885563639",
                    }}
                />
            }
            {
                showScheduleVisitModal &&
                <ScheduleVisitModal
                    show={showScheduleVisitModal}
                    handleClose={() => setShowScheduleVisitModal(false)}
                    handleOk={async(data) => {
                        try {
                            const res = await saveEnquiryData("Schedule Property Visit", data)
                            setConfirmationTitle("Schedule Property visit");
                            setConfirmationBody(res?.data?.message);
                            setShowConfirmationModel(true);
                            setShowScheduleVisitModal(false);
                        } catch (error) {
                            console.log(error);
                            setConfirmationTitle("Schedule Property visit");
                            setConfirmationBody(error?.response?.data?.message);
                            setShowConfirmationModel(true);
                        }
                    }}
                    locationId = {locationData?.location_id}
                    userId = {userProfile?._id}
                />
            }
        </div>
    )
}
export default NewPropertyPage