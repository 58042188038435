import { useState } from "react";

import propertyType from "../../assets/images/propertyType.svg";
import area from "../../assets/images/area.svg";
import parking from "../../assets/images/parking.svg";
import valetParking from "../../assets/images/valetParking.svg";
import streetParking from "../../assets/images/streetParking.svg";
import ccTv from "../../assets/images/ccTv.svg";

import { DescriptionModal } from "../../Modals/DescriptionModal";

const SubTypeOfProperty = ({image,type,data}) => {

    // ?.slice(0,250)}{data?.description?.length > 250 && <span className='typeOfPropertyDescriptionReadMore cursor' onClick={activeModal}>{' '}Read more..</span>}
    return(
        <div className='typeOfPropertyContainer'  style={{padding:"15px 0px 15px 0px"}} >
            {/* <div className='vectorImage'>
                <img src={image} alt='typeOfPropertyImage'/>
            </div> */}
            <div className='typeOfPropertyContentContainer' style={{width: "95%"}} >
            <span className='amenitiesHeading' style={{alignSelf:"center"}} >Property Info</span>
                <div>
                    <img src={propertyType} alt = 'propertyType' />
                    <span className='propertyTypeHeading SpotLetSubHead'>{type}</span>
                </div>
                <div className='typeOfPropertyDescription'>
                    <span className="text-area-preserved-whitespace p-0" style={{fontWeight : "normal"}} >{data?.description}</span>
                    <div className='checkWrap'>
                        <div className='checkContainer'>
                            <div className='checkContainerVectorImage'>
                                <img src={area} alt='area'/>
                            </div>
                            <span className="SpotLetContent" >{data?.property_size} {data?.area} </span>
                        </div>
                        <div className='checkContainer'>
                            <div className='checkContainerVectorImage'>
                                <img src={streetParking} alt='streetParking'/>
                            </div>
                            <span className="SpotLetContent">Street Parking Facility {data?.street_parking ? "Available" : 'Not Available'}</span>
                        </div>
                    </div>
                    <div className='checkWrap'>
                        <div className='checkContainer'>
                            <div className='checkContainerVectorImage'>
                                <img src={valetParking} alt='valetParking'/>
                            </div>
                            <span className="SpotLetContent">Valet Parking {data?.valet_parking ? "Available" : 'Not Available'}</span>
                        </div>
                        <div className='checkContainer'>
                            <div className='checkContainerVectorImage'>
                                <img src={ccTv} alt='ccTv'/>
                            </div>
                            <span className="SpotLetContent">Security Camera {data?.security_camera ? "Available" : 'Not Available'}</span>
                        </div>
                    </div>
                    <div className='checkWrap'>
                        <div className='checkContainer'>
                            <div className='checkContainerVectorImage'>
                                <img src={parking} alt='area'/>
                            </div>
                            <span className="SpotLetContent">In-House Parking Facility {(data?.house_parking && data?.no_of_cars > 0) ? `Available (${data?.no_of_cars})` : 'Not Available'}</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default SubTypeOfProperty;