import { Modal } from "react-bootstrap";
import logo from "../../assets/NewImages/Header/Favicon.ico"
import DOMPurify from 'dompurify'
import { RiDeleteBinLine } from "react-icons/ri";
const { Header, Title, Body } = Modal;
export function NotificationModal({
  handleClose,
  show,
  title,
  body,
  deleteSingleNotification
}) {

  return (
    <Modal
        show={show}
        onHide={handleClose}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
    >
      <Header closeButton>
        <Title style={{fontSize : '16px'}}>{title}</Title>
      </Header>
      <Body className="verify-email-modal-body" style={{fontSize : '13px'}}>
      <div className="notification_open_cotainer">
            <div className="d-flex flex-row">
                <img src={logo} alt="avatar" className="avatar"/>
                <div className="w-100 d-flex flex-row justify-content-between">
                    <div className="d-flex flex-column">
                        <span className="notification_eachNotification_unread_main SpotLetSubHead">{body?.header}</span>
                        <span className="SpotLetContent">{body?.date}</span>
                    </div>
                    <div className="cursor" onClick={(e)=>deleteSingleNotification(e,body?.id)}><RiDeleteBinLine/></div>
                </div>
            </div>
            <div>
                <span className="SpotLetContent" dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(body?.text) }}></span>
            </div>
        </div>
      </Body>
    </Modal>
  );
}
