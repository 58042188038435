import { useState } from "react";

import { RoomSpacesModal } from "../Modals/Rooms&SpacesModal";

const RoomsSpaces  = ({images, data}) => {

    const [isHovered, setIsHovered] = useState(false);
    const [hoveredIndex, setHoveredIndex] = useState(null);
    const [reqData, setReqData] = useState([1, 2, 3])

    const [showModal,setShowModal] = useState(false);
    const [modalTitle,setModalTitle] = useState('');
    const [modalContent,setModalContent] = useState([]);

    const onMouseHover = (index) => {
        setIsHovered(true);
        setHoveredIndex(index);
    }

    const onMouseLeft = () => {
        setIsHovered(false);
        setHoveredIndex(null);
    }

    const activeModal = () => {
        setModalTitle('Rooms / Spaces')
        setModalContent({images ,data})
        setShowModal(true)
    }
 
    const eachSpaceElement = (data, index) => {
        return (
            <div className="spacesEachSpace" onMouseEnter={() => onMouseHover(index)} onMouseLeave={onMouseLeft}>
                <img className= {(isHovered === true && hoveredIndex === index) ? "spacesSubImage" : "spacesImage"} src={images?.[data?.name]?.[0]?.image} alt = "room-space-img" />
                <div className= {(isHovered === true && hoveredIndex === index) ? "spacesHideContent" : "spacesPositionContainer"}>
                    <p className="SpotLetSubHead"> {data?.name} </p>
                </div>
                <div className={(isHovered === true && hoveredIndex === index) ? "spacesShowContent" : "spacesHideContent"}>
                    <h4 className="spacesSpaceName SpotLetSubHead"> {data?.name} </h4>
                    <div className="spaceDimensionsContainer" >  
                        <div className="spacesDimensionCard" >
                            <p className="SpotLetContent"> Length </p>
                            <p className="SpotLetContent"> {data?.length ? `${data?.length} ft` : "N/A" } </p>
                        </div>
                        <div className="spacesDimensionCard" >
                            <p className="SpotLetContent"> Width </p>
                            <p className="SpotLetContent"> {data?.width ? `${data?.width} ft` : "N/A" }  </p>
                        </div>
                        <div className="spacesDimensionCard" >
                            <p className="SpotLetContent"> Height </p>
                            <p className="SpotLetContent"> {data?.height ? `${data?.height} ft` : "N/A" }  </p>
                        </div>
                    </div> 
                    <div className="spacesSizeDimensionCard SpotLetContent"> 
                        <p className="SpotLetContent m-0"> Size - {data?.size ? `${data?.size}  ${data?.area_type}` : "N/A" } </p>
                    </div>
                    <p className="spacesDescription SpotLetContent"> 
                        {data?.description } 
                    </p>
                </div>    
        </div>
        )
    }

    return(
        <div className="spacesMainContainer">
            <h1 className="spacesHead SpotLetMainHead"  id = "propertyPage2"> Rooms / Spaces </h1>
            <div className="spacesContainer" >
                { (data?.length === 0  || !data ) &&
                    <h4 className="text-center w-100 SpotLetSubHead" > Not Available </h4>
                } 
                {
                  data?.length > 0 &&
                    data?.slice(0,3).map((each, i) => eachSpaceElement(each,i) )
                }          
            </div>
            {data?.length > 3 && <button className="AmenitiesShowAll mb-3" onClick={activeModal}> Show All </button> }
            {showModal && (
                <RoomSpacesModal
                    show={showModal}
                    onHide={() => setShowModal(false)}
                    title={modalTitle}
                    body={modalContent}
                />
            )}
        </div>
    )
}
export default RoomsSpaces