import { useEffect, useMemo, useState } from "react";
import UserEnquiriesResponsiveTable from "./UserEnquiriesResponsiveTable";
import { getUserLeads } from "../apis/api";
import dayjs from "dayjs";
import { PageLoader } from "./PageLoader";
import Pagination from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';

export const UserEnquiriesTable = ({userId}) => {

  const COLUMNS = ["Enquiry Type", 'Enquiry Id', 'Location Id', 'Status', 'Date', 'Action']
  const [rows,setRows] = useState([])
  const [data, setData] = useState([]);
  const [loader, setLoader] = useState(false)
  const [init, setInit] = useState(false);

  const [searchTerm, setSearchTerm] = useState('');
  const [pageCount, setPageCount] = useState(0);
  const [page, setPage] = useState(1);
  const PAGE_LIMIT = 20;

  const handleTableData = (data) => {
    Promise.all(data?.map((item)=>{
      return {
        enquiryType : item?.type,
        enquiryId : item?.enquiryId,
        id : item?._id,
        locationId : item?.data?.locationId || item?.data?.property_id,
        status : item?.status,
        date : dayjs(item?.createdAt).format("DD/MM/YYYY"),
        action : 'details',
      }
    })).then((values)=> setRows(values))
  }

const getData = async() => {
    setLoader(true)
        try {
          const res = await getUserLeads({userId, type : null, searchTerm, page, limit : PAGE_LIMIT});
          setPageCount(Math.ceil(res?.data?.count / PAGE_LIMIT));
          setPage(1);
          setData(res?.data?.leads);
          handleTableData(res?.data?.leads);
        } catch (error) {
          console.log(error);
        }
    setLoader(false)
    setInit(true)
}

  useEffect(() => {
        getData();
}, [searchTerm]);

  // for pagination 
  const handleChange = async (event, value) => {
    setLoader(true)
    try {
        const res = await getUserLeads({ userId, type : null, searchTerm, page: value, limit : PAGE_LIMIT});
        setPage(value);
        setPageCount(Math.ceil(res?.data?.count / PAGE_LIMIT)) ;
        setData(res?.data?.leads);
        handleTableData(res?.data?.leads);
    } catch (error) {
        console.log(error);
        alert(error?.response?.data?.message);
    }
    setLoader(false)
};

const handleKeyDown = (event) => {
  if (event.key === 'Enter') {
    event.preventDefault(); 
  }
};

  return (
  <>
    {/* <div class="filter-btns">
      <input placeholder="Search" type="text" class="form-control" id="cname" />
      <button type="button" class="filter-btn btn" data-bs-toggle="modal" data-bs-target="#booking-filter">
        <img src="assets/images/filter.svg" class="me-2" alt="" />
        Filter</button>
    </div> */}
    {init && (
      <>
               <div>
                   <input onKeyDown={handleKeyDown} placeholder="Search" type="text" class="locationtype-search mb-2" id="cname" onChange={(e) => setSearchTerm(e?.target?.value) } />
               </div>
        {data?.length === 0 ? (
          <div className="d-flex flex-column justify-content-center align-items-center">
            <span className="SpotLetSubHead">No Enquiries Found</span>
          </div>
        ) : (
            <>
               <div class="table-responsive">
                    <UserEnquiriesResponsiveTable rows={rows}  columns={COLUMNS} />
                </div>
                <div className="mt-2" style={{ display: 'flex', flexDirection: 'row-reverse' }}>
                    <Stack spacing={2}>
                        <Pagination page={page} count={pageCount} variant="outlined" shape="rounded" color="primary" onChange={handleChange} />
                    </Stack>
                </div>
            </>
        )}
      </>
    )}
    {loader && <PageLoader />}
  </>
  )
};
