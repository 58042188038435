import { useState,useEffect } from "react"
import { uploadLocationPics,addRecentEvents,getRecentEvents,deleteRecentEvent, getPlans } from "../../../apis/api"
import { ConfirmationModel } from "../../../components/Modal/ConfirmationModel";
import SubscriptionModal from "../../NewSubscription/Modals/SubscriptionModal";
import { PageLoader } from "../../../components/PageLoader";


function urltoFile(url, filename, mimeType) {
    return fetch(url)
      .then(function (res) {
        return res.arrayBuffer();
      })
      .then(function (buf) {
        return new File([buf], Date.now() + filename, { type: mimeType });
      });
  }

const RecentEvents = ({data}) => {

    const [events,setEvents] = useState([])
    const [subscription,setSubscription] = useState('')
    const [formData,setFormData] = useState({})
    const [formActive,setFormActive] = useState(false)
    const [show,setShow] = useState(false)
    const [modalBody,setModalBody] = useState('')
    const getData = async () => {
        try{
            const resp = await getRecentEvents(data)
            setEvents(resp?.data?.recentEvents)
            setSubscription(resp?.data?.subscription_id)
        }catch(error){
            console.log(error)
        }
    }
    useEffect(()=>{
        getData()
    },[])
    const onChange = (e) => {
        const {name,value} = e.target
        setFormData({
            ...formData,
            [name] : value
        })
    }
    const onUploadImage = async (e) => {
        const {name,files} = e.target
        if (files && files[0]) {
            const image = files[0]
            const file = await urltoFile(
                URL.createObjectURL(image),
                image.name,
                "text/plain"
            );
            const mainImages = new FormData();
            mainImages.append("pic", file);
            mainImages.append("fileName", image.name);
            mainImages.append("locationId", data);
            mainImages.append("type",'events');
            try{
                const response = await uploadLocationPics(mainImages);
                setFormData({
                    ...formData,
                    [name] : response.url,
                })
            }catch(error){
                console.log(error)
            }
          }
    }
    const handleSubmit = async (e) => {
        e.preventDefault()
        try{
            const resp = await addRecentEvents(data,formData)
            setModalBody(resp?.data?.message)
            setShow(true)
            getData()
            setFormData({})
            setFormActive(false)
            document.getElementById('dashboardRecentEventForm').reset()
        }catch(error){
            console.log(error)
        }
    }
    const handleDelete = async (id) => {
        try{
            const resp = await deleteRecentEvent(data,id)
            setModalBody(resp?.data?.message)
            setShow(true)
            getData()
        }catch(error){
            console.log(error)
        }
    }
    //subscription update start
    const region = localStorage.getItem('region')
    const [loader, setLoader] = useState(false)
    const [showSubscriptionModel, setShowSubscriptionModel] = useState(false);
    const [standardPlan, setStandardPlan] = useState({})
    const [premiumPlan, setPremiumPlan] = useState({})
    const [activePlan,setActivePlan] = useState({})
    const onClickUpgrade = async () => {
        setLoader(true)
        try {
        const res = await getPlans();
        let reqCurrency 
        if (region === "India") {
            reqCurrency = "INR"
        } else {
            reqCurrency = "USD"
        }
        if(res?.data) {
            let standard,premium
            for (let each of  res?.data) {
                if (each?.plan === "Standard") {
                    for (let eachCountryPlan of each?.amount) {
                        if (eachCountryPlan?.currency === reqCurrency) {
                            standard = {
                                plan : each?.plan,
                                originalAmount : eachCountryPlan?.originalAmount,
                                amount : eachCountryPlan?.discountAmount,
                                planId : eachCountryPlan?.plan_id,
                                currency : eachCountryPlan?.currency,
                            }
                            setStandardPlan(standard)
                        }
                    }
                }
                if (each?.plan === "Premium") {
                    for (let eachCountryPlan of each?.amount) {
                        if (eachCountryPlan?.currency === reqCurrency) {
                            premium = {
                                plan : each?.plan,
                                originalAmount : eachCountryPlan?.originalAmount,
                                amount : eachCountryPlan?.discountAmount,
                                planId : eachCountryPlan?.plan_id,
                                currency : eachCountryPlan?.currency,
                            }
                            setPremiumPlan(premium)
                        }
                    }
                }
            }
            switch (subscription) {
                case 'Basic':
                    setActivePlan(standard)
                    break;
                case 'Standard':
                    setActivePlan(premium)
                    break;
                default:
                    break;
            }
            setShowSubscriptionModel(true)
        }
        } catch (error) {
        console.log(error)
        }
        setLoader(false)
    }
    const handleChangePlan = () => {
        if(activePlan?.plan === 'Standard'){
            setActivePlan(premiumPlan)
        }else{
            setActivePlan(standardPlan)
        }
    }
    const completeTask = () => {
        setShowSubscriptionModel(false)
        getData()
    }
    //subscription update end

    const eventForm = () => {
        return(
            <form onSubmit={handleSubmit} id='dashboardRecentEventForm'>
                <div className="d-flex flex-column mb-2">
                    <label className="form-label">Event Name{' '}<span className="required">*</span></label>
                    <input
                        className="textFiled"
                        placeholder="Event Name"
                        value={formData?.name}
                        name="name"
                        required
                        type="text"
                        onChange={onChange}
                    />
                </div>
                <div className="d-flex flex-column mb-2">
                    <label className="form-label">Event Date{' '}<span className="required">*</span></label>
                    <input
                        className="textFiled"
                        placeholder="Event Date"
                        value={formData?.date}
                        name="date"
                        required
                        max={new Date().toISOString().split("T")[0]}
                        type="date"
                        onChange={onChange}
                    />
                </div>
                <div className="d-flex flex-column mb-2">
                    <label className="form-label">Event Type{' '}<span className="required">*</span></label>
                    <select
                        className="textFiled p-0"
                        value={formData?.type}
                        name="type"
                        required
                        onChange={onChange}
                    >
                        <option  hidden selected value = "" >Select Event Type</option>
                        <option value='Film Shooting'>Film Shooting</option>
                        <option value='Tv Shooting'>Tv Shooting</option>
                        <option value='Event'>Event</option>
                        <option value='Home Stay'>Homestay</option>
                    </select>
                </div>
                <div className="d-flex flex-column mb-2">
                    <label className="form-label">Upload Image{' '}<span className="required">*</span></label>
                    <input
                        // value={formData?.image}
                        name="image"
                        required
                        type="file"
                        accept="image/png, image/jpeg"
                        onChange={onUploadImage}
                    />
                </div>
                <div>
                    {formData?.image && (<img alt='refesh' src={formData?.image} />)}
                </div>
                <div>
                    <button
                        className="btn btn-secondary mt-2 me-2"
                        type="button"
                        onClick={()=>setFormActive(false)}
                    >Cancel</button>
                    <button
                        className="btn btn-primary mt-2"
                        type="submit"
                    >Submit</button>
                </div>
            </form>
        )
    }
    const eventsTable = () => {
        return(
            <div className="mt-2">
                <table>
                    <thead>
                        <tr>
                            <th>Event Name</th>
                            <th>Event Date</th>
                            <th>Event Type</th>
                            <th>Event Image</th>
                            <th>Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        {events?.map((item)=>{
                            return(
                                <tr style={{ textAlign: "center" }}>
                                    <td>{item.name}</td>
                                    <td>{item.date}</td>
                                    <td>{item.type}</td>
                                    <td><img src={item.image} alt='refresh' style={{height : '40px',width : '40px'}}/></td>
                                    <td>
                                    {/* <button
                                        className="btn btn-primary"
                                        onClick={()=>setFormActive(true)}
                                    >Edit
                                    </button> */}
                                    <button
                                        className="btn btn-primary"
                                        onClick={()=>handleDelete(item.id)}
                                        >Delete
                                        </button>
                                    </td>
                                </tr>
                            )
                        })}
                    </tbody>
                </table>
                <div className="mt-2">
                    <button
                        className="btn btn-primary"
                        onClick={()=>setFormActive(true)}
                    >Add New</button>
                </div>
            </div>
        )
    }
    const requestAddEvent = () => {
        return(
            <div className="d-flex flex-column">
                <span>You Don't have added any recent events yet, Add your recent events happened in your location and showcase at your property page..</span>
                <div>
                    <button
                        className="btn btn-primary"
                        onClick={()=>setFormActive(true)}
                    >Add</button>
                </div>
            </div>
        )
    }
    return(
        <div className="dashboardRecentEventsSection">
            <span className="dashboardRecentEventsSectionHeading">Recent Events</span>
            {subscription !== 'Premium' ? (
                <div className="d-flex flex-column">
                    <span>You are not subscribed to Premium  plan. Please upgrade.</span>
                    <div>
                        <button type="button" className="btn btn-primary" onClick={onClickUpgrade} >Upgrade</button>
                    </div>
                </div>
            ) : (
                <>
                    {(events.length === 0 && !formActive) && requestAddEvent()}
                    {formActive && eventForm()}
                    { (events.length > 0 && !formActive) &&  eventsTable()}
                </>
            )}
            {show && (
                <ConfirmationModel
                show={show}
                handleClose={()=>setShow(false)}
                handleOk={()=>setShow(false)}
                title='Recent Events'
                body={modalBody}
                 />
            )}
            {showSubscriptionModel && (
                <SubscriptionModal
                    show={showSubscriptionModel}
                    onHide={()=>setShowSubscriptionModel(false)}
                    plan ={activePlan}
                    handleChangePlan={handleChangePlan}
                    completeTask={completeTask}
                    title = {'Upgrade Plan'}
                    listingId = {data}
                />
            )}
            {loader && <PageLoader />}
        </div>
    )
}
export default RecentEvents;