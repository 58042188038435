import { Table, Thead, Tbody, Tr, Th, Td } from 'react-super-responsive-table';
import 'react-super-responsive-table/dist/SuperResponsiveTableStyle.css';
import { Button } from "react-bootstrap";
import { useEffect, useState } from 'react';
import { useNavigate } from "react-router-dom";
// import { BookingDetailsModal } from '../../../../components/Modal/BookingDetailsModal';
import { CenteredModal } from '../../../../components/Modal/CenteredModal';
import { getEnquiryData } from '../../../../apis/api';
import moment from "moment";

const LeadsResponsiveTable = ({rows,columns, upgrade}) => {
    const navigate = useNavigate();
    const [showModal, setShowModal] = useState(false);
    const [showCenteredModal, setShowCenteredModal] = useState(false);
    const [data, setData] = useState({})
    const [details, setDetails] = useState({})
    const [tableId, setTableId] = useState("")
    const [showMessage, setShowMessage] = useState(false)

    const callBookingDetails= (data) => {
        return(
            <Button variant="danger" 
                // onClick={() => navigate(`/booking-details/${data}`)}
                onClick={() => {
                    // setShowModal(true); 
                    // setBookingId(data);
                    getBookingRequestsData(data)
                }}
                className="btn btn-theme btn-detail">
                Details
            </Button>
        )
    }
    const onClickUpgrade = () => {
        upgrade()
    }

    useEffect(() => {
        if (rows) setTableId(rows?.[0]?.tableId)
    }, [rows] )

    const showDetailsButton = (data) => {
        return(
            <Button variant="danger" 
                onClick={() => {
                    getScheduleInfo(data)
                }}
                className="btn btn-theme btn-detail">
                Details
            </Button>
        )
    }

    const showMessageButton = (data) => {
        return(
            <Button variant="danger"
                onClick={() => {
                    getScheduleInfo(data, "message");
                }}
                className="btn btn-theme btn-detail">
                View Message
            </Button>
        )
    }

    const getScheduleInfo = async(data, msg) => {
        try{
            if (data) {
                const res = await getEnquiryData({id : data, read : msg ? false : true }); 
                setDetails(res?.data?.enquiryDetails);
                setShowCenteredModal(true);
                if (msg) {
                    setShowMessage(true);
                } else {
                    setShowMessage(false);
                }
            }
        } catch(e) {
            console.log(e?.response?.data?.message)
        }  
    } 

    const getBookingRequestsData = async(bookingId) => {
        try{
            if (bookingId) {
                const res = await getEnquiryData({ id :bookingId, read : true }); 
                setData(res?.data?.enquiryDetails);
                setShowModal(true); 
            }
        } catch(e) {
            console.log(e?.response?.data?.message)
        }  
    }

    const bookingDetails = (each) => {
        let reqServices 
        if (each?.data?.services){
            const filteredServices = each?.data?.services?.filter((each ) => each !== "")
            reqServices = filteredServices.length > 0 ? each?.data?.services.join(", ") : "N/A";
        }
        else {
            reqServices = "N/A"
        }
        return(
            <div className="d-flex flex-column">
                <div className='card mb-xl-10' >
                    <div className='card-header cursor-pointer'>
                        <div className='mb-3'>
                        {/* begin::Text */}
                            <div className='mt-3'>
                                <span  className='fw-bold spotletContent'>Location Id :</span> <span>{each?.data?.locationId}</span>
                            </div>
                            <div className='mt-3'>
                                <span  className='fw-bold spotletContent'>Enquiry Id :</span> <span>{each?.enquiryId}</span>
                            </div>
                            <div className='mt-3'>
                                <span  className='fw-bold spotletContent'>Booking Id :</span> <span>{each?._id}</span>
                            </div>
                            <div className='mt-3'>
                                <span  className='fw-bold spotletContent'>Type of Event :</span> <span>{each?.data?.event}</span>
                            </div>
                            {/* { 
                            each?.data?.event !== "stay"  && (
                            <div className=' mt-3'>
                                <span  className='fw-bold'>Sub Event :</span> <span>{each?.data?.subEvent}</span>
                            </div>
                            )} */}
                            { 
                            each?.data?.event === "stay"  && 
                            <div className='mt-3'>
                                <span  className='fw-bold spotletContent'>Adults Number:</span> <span>{each?.data?.adults}</span>
                            </div>
                            }
                            { 
                            each?.data?.event === "stay"  && 
                            <div className='mt-3'>
                                <span  className='fw-bold spotletContent'>Children Number :</span> <span>{each?.data?.children}</span>
                            </div>
                            }
                            { 
                            each?.data?.event === "stay"  && 
                            <div className='mt-3'>
                                <span  className='fw-bold spotletContent'>Infants Number :</span> <span>{each?.data?.infants}</span>
                            </div>
                            }
                            { 
                            each?.data?.event === "stay"  && 
                            <div className='mt-3'>
                                <span  className='fw-bold spotletContent'>Check In :</span> <span>{moment(each?.data?.checkIn).format('DD/MM/YYYY')} </span>
                            </div>
                            }
                            { 
                            each?.data?.event === "stay"  && 
                            <div className='mt-3'>
                                <span  className='fw-bold spotletContent'>Check Out :</span> <span>{moment(each?.data?.checkOut).format('DD/MM/YYYY')} </span>
                            </div>
                            }
                            { 
                            each?.data?.event !== "stay"  && 
                            <div className='mt-3'>
                                <span  className='fw-bold spotletContent'>No. of Guests  :</span> <span>{each?.data?.guests}</span>
                            </div>
                            }
                            <div className=' mt-3'>
                                <span  className='fw-bold spotletContent'>Requested on :</span> <span>{moment(each.createdAt).format('DD/MM/YYYY hh:mm a')}</span>
                            </div>
                            { each?.data?.event !== "stay"  && 
                                <div className=' mt-3'>
                                    <span  className='fw-bold spotletContent'>Booking Requested for : </span> 
                                    <ol  className='fw-bold'>
                                        {/* {
                                            each?.data?.requestDates?.map((eachDate) => {
                                                return ( */}
                                                <li className="mt-2 spotletContent"> 
                                                    Date :  {moment(each?.data?.startDate).format('DD/MM/YYYY')} <br/>
                                                    Start Time :  {each?.data?.startTime} <br/>
                                                    No. of Hours :  {each?.data?.noOfHours} <br/>
                                                </li>
                                                {/* )
                                            } )
                                        } */}
                                    </ol>
                            </div>
                        }
                        {/* end::Text */}
                        </div>
                    </div>
                </div>
            </div>

        )
    }

    const actionOnes = (type,data,bookingId, reqId) => {
        switch (type) {
            case 'Message':
                return showMessageButton(reqId)
            default:
                break;
        }
    }
    const actions = (type,data,bookingId, reqId) => {
        switch (type) {
            // case 'dashboard':
            //     return callDashBoard(data)
            // case 'editListing':
            //     return callEditListing(data)
            // case 'continueListing':
            //     return callContinueListing()
            case 'details':
                return callBookingDetails(bookingId)
            case 'Details':
                return showDetailsButton(reqId)
            default:
                break;
        }
    }
    const bookingsTable = () => {
        return (
            <Table>
          <Thead>
            <Tr>
                {columns?.map((column,i)=>{
                    return(
                        <Th key={i}>{column}</Th>
                    )
                })}
            </Tr>
          </Thead>
          <Tbody>
            {rows?.map((row,i1)=>{
                return(
                    <Tr key={i1}>
                        {Object.keys(row)?.map((key)=>{
                            if(key !== 'action'){
                                return(
                                    <Td key={key}>{row?.[key]}</Td>
                                )
                            }else{
                                return(
                                    <Td>{actions(row?.[key],row?.locationId,row?.bookingId)}</Td>
                                )
                            }
                        })}
                    </Tr>
                )
            })}
          </Tbody>
        </Table>
        )
    }

    const contactHostDetails = (each) => {
        return(
            <div className='mb-2 mt-2'>
                <div className=''>
						<span  className='fw-bold spotletContent'>Property Id :</span> <span>{each?.data?.locationId}</span>
                </div>
                <div className='mt-3'>
						<span  className='fw-bold spotletContent'>Enquiry Id :</span> <span>{each?.enquiryId}</span>
                </div>
                <div className='mt-3'>
						<span  className='fw-bold spotletContent'>Enquiry Date & Time :</span> <span>{moment(each.createdAt).format('YYYY-MM-DD hh:mm a')}</span>
                </div>
                <div className='mt-3'>
						<span  className='fw-bold spotletContent'>User Name :</span> <span>{each?.data?.name}</span>
                </div>
                <div className='mt-3'>
						<span  className='fw-bold spotletContent'>User Contact No :</span> <span>{each?.data?.mobileNumber}</span>
                </div>
                <div className='mt-3'>
						<span  className='fw-bold spotletContent'>User Email :</span> <span>{each?.data?.email}</span>
                </div>
            </div>
        )
    } 

    const getInTouchDetails = (each) => {
        return(
            <div className='mb-2 mt-2'>
                {/* <div className=''>
						<span  className='fw-bold spotletContent'>Property Id :</span> <span>{each?.data?.locationId}</span>
                </div>
                <div className='mt-3'>
						<span  className='fw-bold spotletContent'>Enquiry Id :</span> <span>{each?.enquiryId || "NA"}</span>
                </div> */}
                <div className='mt-3'>
						<span  className='fw-bold spotletContent'>Enquiry Date & Time :</span> <span>{moment(each.createdAt).format('YYYY-MM-DD hh:mm a')}</span>
                </div>
                <div className='mt-3'>
						<span  className='fw-bold spotletContent'>User Name :</span> <span>{each?.data?.name}</span>
                </div>
                <div className='mt-3'>
						<span  className='fw-bold spotletContent'>Organisation Name :</span> <span>{each?.data?.organization}</span>
                </div>
                <div className='mt-3'>
						<span  className='fw-bold spotletContent'>User Contact No :</span> <span>{each?.data?.mobileNumber}</span>
                </div>
                <div className='mt-3'>
						<span  className='fw-bold spotletContent'>User Email :</span> <span>{each?.data?.email}</span>
                </div>
                <div className='mt-3 d-flex flex-row'>
						<span  className='fw-bold spotletContent me-2'>Message :</span>  
                        <span className='spotletContent'>{each?.data?.message}</span>
                </div>
            </div>
        )
    } 

    const getScheduleVisitDetails = (each) => {
        return(
            <div className='mb-2 mt-2'>
                <div className=''>
						<span  className='fw-bold spotletContent'>Property Id :</span> <span>{each?.data?.locationId}</span>
                </div>
                <div className='mt-3'>
						<span  className='fw-bold spotletContent'>Enquiry Id :</span> <span>{each?.enquiryId || "NA"}</span>
                </div>
                <div className='mt-3'>
						<span  className='fw-bold spotletContent'>Enquiry Date & Time :</span> <span>{moment(each.createdAt).format('YYYY-MM-DD hh:mm a')}</span>
                </div>
                <div className='mt-3'>
						<span  className='fw-bold spotletContent'>Visit Schedule Date :</span> <span>{each?.data?.date}</span>
                </div>
                <div className='mt-3'>
						<span  className='fw-bold spotletContent'>User Name :</span> <span>{each?.data?.name}</span>
                </div>
                <div className='mt-3'>
						<span  className='fw-bold spotletContent'>User Contact No :</span> <span>{each?.data?.mobileNumber}</span>
                </div>
                <div className='mt-3'>
						<span  className='fw-bold spotletContent'>User Email :</span> <span>{each?.data?.email}</span>
                </div>
                <div className='mt-3 d-flex flex-row'>
						<span  className='fw-bold spotletContent me-2'>Message :</span>  
                        <span className='spotletContent'>{each?.data?.additionalInfo}</span>
                </div>
            </div>
        )
    } 

    const getModelBody = (key) => {
        switch (key) {
            case "Contact Host Table":
                return contactHostDetails(details);
            case "Schedule Visits Table":
                if (showMessage) {
                    return details?.data?.additionalInfo
                } else {
                    return getScheduleVisitDetails(details)
                }
                return details?.data?.additionalInfo
            case "Get In Touch Table":
                // {showMessage ? details?.data?.message : getInTouchDetails(details) }
                if (showMessage) {
                    return details?.data?.message
                } else {
                    return getInTouchDetails(details)
                }
            default:
                return "NA";
        }
    }

    // for blur data start
    const contactHostObj = {
        tableId : "Contact Host Table",
        reqId : "",
        view : false,
        date : "12/34/5678",
        name : "SpotLet",
        mobileNumber : "+91 9876543210",
        email : "support@spotlet.info",
        action : 'Details'
    }
    const getInTouchObj = {
        tableId : "Get In Touch Table",
        reqId : "",
        view : false,
        date : "12/34/5678",
        name : "SpotLet",
        organisationName : "SpotLet",
        mobileNumber : "+91 9876543210",
        email : "support@spotlet.info",
        actionOne : 'Message',
        action : 'Details'
    }
    const bookingReqObj = {
        tableId : "Booking Requests Table",
        bookingId : "",
        view : false,
        date : "12/34/5678",
        bookingReqDate  : "12/34/5678",
        guestName : "SpotLet",
        guestNumber : "+91 9876543210",
        guestEmail : "support@spotlet.info",
        Event : "SpotLet",
        action : 'Details'
    }
    const scheduleVisitObj = {
        tableId : "Schedule Visits Table",
        reqId : "",
        view : false,
        date : "12/34/5678",
        reqDate : "12/34/5678",
        name : "SpotLet",
        mobileNumber : "+91 9876543210",
        email : "support@spotlet.info",
        actionOne : 'Message',
        action : 'Details'
    }
    // for blur data end

    const getRowData = (row, i1) => {
        const colSpanCount = Object.keys(row).filter(key => key !== 'action' && key !== 'actionOne' && key !== 'bookingId' && key !== 'tableId' && key !== 'reqId' && key !== 'view').length + 2; // +2 for the action columns
        return(
            <Tr key={i1}  style={{ position: 'relative' }}>
                {Object.keys(row)?.map((key)=>{
                    if(key !== 'action' && key !== "actionOne"){
                        return(
                            (key !== "bookingId" && key !== "tableId" && key !== "reqId" && key !== "view" )&& <Td className={`${row?.view ? "" : "blur-table"} text-center`} key={key}>{row?.[key]}</Td>
                        )
                    }
                    else if (key === "actionOne"){
                        return(
                            <Td className = "">
                                <div className={`${row?.view ? "" : "blur-table"} text-center w-100 d-flex flex-row justify-content-center`}>
                                {actionOnes(row?.[key],row?.locationId,row?.bookingId, row?.reqId)}
                                </div>
                            </Td>
                        )
                    }
                    else if (key === "action"){
                        return(
                            <Td className={`${row?.view ? "" : "blur-table"} d-flex flex-row justify-content-center`}>{actions(row?.[key], row?.locationId, row?.bookingId, row?.reqId)}</Td>
                        )
                    }
                })}
                {!(row?.view) && (tableId !== "Contact Host Table") && (
                    <Td colSpan={colSpanCount} className="text-center" style={{ position: 'absolute', left: '50%', transform: 'translateX(-50%)', top: '5%', zIndex: 1, border:"none" }}>
                        <button className='btn btn-primary' type='button' onClick={onClickUpgrade}>Upgrade</button>
                    </Td>
                )}
            </Tr>
        )
    }

    const getReqRow = (type) => {
        switch (type) {
            case "Contact Host Table":
                return contactHostObj
            case "Get In Touch Table":
                return getInTouchObj
            case "Booking Requests Table":
                return bookingReqObj
            case "Schedule Visits Table":
                return scheduleVisitObj
            default:
                return contactHostObj
        }
    }

    return (
        <>
            <Table>
                <Thead>
                    <Tr>
                        {columns?.map((column,i)=>{
                            return(
                                <Th key={i}>{column}</Th>
                            )
                        })}
                    </Tr>
                </Thead>
                <Tbody>
                    {rows?.map((row,i1)=>{
                        let reqRow = row
                        if (!(row?.view)) {
                            reqRow = getReqRow(row?.tableId)
                        } 
                        return(
                            getRowData(reqRow, i1)
                        )
                    })}
                </Tbody>
            </Table>
            {
                showModal && (
                <CenteredModal
                    show={showModal}
                    handleClose={() => setShowModal(false)}
                    handleOk={() => setShowModal(false)}
                    title="Booking Details"
                    // bookingId = {bookingId}
                    // body={confirmationBody}
                    data = {bookingDetails(data)}
                />
                )
            }
            {
                showCenteredModal && (
                <CenteredModal
                    show={showCenteredModal}
                    handleClose={() => setShowCenteredModal(false)}
                    handleOk={() => setShowCenteredModal(false)}
                    title={ showMessage ? "Message" : (tableId === "Booking Requests Table") ? "Booking Requests Table" : "User Details" }
                    data = {getModelBody(tableId)}
                />
                )
            }
        </>
      )
}
export default LeadsResponsiveTable;