import React, {useState} from 'react'
import PropTypes from 'prop-types';
import { Unstable_Popup as BasePopup } from '@mui/base/Unstable_Popup';
import { Box, styled } from '@mui/system';
import { LeadsPopupData } from './LeadsPopupData';

const LeadsFilter = ({filterFormData, filterData}) => {

  // mui popup starts
  const  PopupWithTrigger = (props) => {

    const { id, buttonLabel, ...other } = props;
    const [anchor, setAnchor] = useState(null);
    const [isPopupOpen, setIsPopupOpen] = React.useState(false);
  
    const handleClick = (event) => {
      setAnchor(event.currentTarget);
      setIsPopupOpen(!isPopupOpen); 
    };
  
    const handleClosePopup = () => {
      setIsPopupOpen(false); 
      setAnchor(null);
    };
    
    return (
      <div>
        <button className='btn btn-primary' aria-describedby={id} type="button" onClick={handleClick}>
          Filter
        </button>
        <Popup id={id} open={isPopupOpen} anchor={anchor} {...other}>
          <PopupBody>
                <LeadsPopupData handleClose = {handleClosePopup} filterFormData = {filterFormData} reqFilterData = {filterData} />
          </PopupBody>
        </Popup>
      </div>
    );
  }
  
  PopupWithTrigger.propTypes = {
    buttonLabel: PropTypes.string.isRequired,
    id: PropTypes.string,
  };
  
  const Popup = styled(BasePopup)`
    z-index: 1;
  `;
  
  const grey = {
    50: '#F3F6F9',
    100: '#E5EAF2',
    200: '#DAE2ED',
    300: '#C7D0DD',
    400: '#B0B8C4',
    500: '#9DA8B7',
    600: '#6B7A90',
    700: '#434D5B',
    800: '#303740',
    900: '#1C2025',
  };
  
  const PopupBody = styled('div')(
    ({ theme }) => `
    width: 260px;
    height: 310px;
    display: flex;
    align-items: center;
    padding: 16px;
    margin: 8px;
    margin-right: 40px;
    border-radius: 8px;
    border: 1px solid ${theme.palette.mode === 'dark' ? grey[700] : grey[200]};
    background-color: ${theme.palette.mode === 'dark' ? grey[900] : '#fff'};
    box-shadow: ${
      theme.palette.mode === 'dark'
        ? `0px 4px 8px rgb(0 0 0 / 0.7)`
        : `0px 4px 8px rgb(0 0 0 / 0.1)`
    };
    font-family: 'IBM Plex Sans', sans-serif;
    font-size: 0.875rem;
  `,
  );
  // mui popup ends

  return (
    <Box
        sx={{
        display: 'flex',
        gap: '10px',
        overflowY: 'auto',
        position: 'relative',
        padding: '40px',
        }}
    >
        {/* <PopupWithTrigger id="popup-with-portal" buttonLabel="With a portal" /> */}
        {/* <PopupWithTrigger
            id="popup-without-portal"
            buttonLabel="No portal, default strategy"
            disablePortal
        /> */}
        <PopupWithTrigger
            id="popup-without-portal-fixed"
            buttonLabel="No portal, 'fixed' strategy"
            disablePortal
            strategy="fixed"
        />
    </Box>
  )
}

export {LeadsFilter}