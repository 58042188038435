import { Button, Modal } from "react-bootstrap";

const { Header, Footer, Title, Body } = Modal;
export function VideoModal({
    handleClose,
    handleOk,
    show,
    title,
    body,
}) {

    return (
        <Modal show={show} onHide={handleClose}>
            <Header closeButton>
                <Title>{title}</Title>
            </Header>
            <Body >
                {body}
            </Body>
            <Footer>
                <Button variant="secondary" onClick={handleClose}>
                    Cancel
                </Button>
                <Button variant="primary" onClick={handleOk}>
                    OK
                </Button>
            </Footer>
        </Modal>
    );
}