
import React, { useState, useEffect} from "react";
import "./style.css";
import LandingBanner from "./LandingBanner";
import CreativeSpaces from "./CreativeSpaces";
import SimpleSteps from "./SimpleSteps";
import ProfessionalServices from "./ProfessionalServices";
import WhyChooseUs from "./WhyChooseUs";
// import HomeVideo from "./HomeVideo";
import HostContainer from "./HostContainer";
import SpotletProvides from "./SpotletPorvides";
// import JoinUs from "./JoinUs";
import RecentProjects from "./RecentProjects";
import ClientTestimonials from "./ClientTestimonials";
import Constants from "../../utils/Helmet";
import { Header } from "../../components/NewHeader/Header";
import { Footer } from "../../components/Footer";
import { Properties } from "./Properties";
import { FeaturedProperties } from "./FeaturedProperties";
import { PageLoader } from "../../components/PageLoader";
import { TopRatedProperties } from "./TopRatedProperties";
import {getHomePageData, getLocationTypesByEvent, getCities } from "../../apis/api";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import { styled } from '@mui/material/styles';
import {createSearchParams,useNavigate,} from "react-router-dom";;
const eventOptions = [
  { value: "film-shoot", label: "Film Shootings" },
  { value: "tv-shoot", label: "Tv Shootings" },
  { value: "event", label: "Events" },
  { value: "staycation", label: "Staycations" },
];

//Custom Tooltip 
const CustomTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} arrow classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.palette.common.black,
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.black,
    padding: '10px',
    fontSize : "13px",
  },
}));

export const Home = () => {
  const navigate = useNavigate();
  const [carouselData, setCarouselData] = useState({});
  const [open, setOpen] = useState(false); 
  const [openSearch, setOpenSearch] = useState(false); 
  const [locations, setLocations] = useState([]);
  const [selectedEvent, setSelectedEvent] = useState('');
  const [selectedLocation, setSelectedLocation] = useState('');
  const [citiesData, setCitiesData] = useState([]);
  const [selectedCity, setSelectedCity] = useState('');
  const [error, setError] = useState(false);

  const getHomePageAllData = async() => {
    try{
      const res = await getHomePageData();
      setCarouselData(res?.data)
    } catch(error) {
      console.log(error)
    }
  }
  const getReqEvent = (event) => {
    switch (event) {
      case "Film Shootings":
        return "film-shoot";
      case "Tv Shootings":
        return "tv-shoot";
      case "Events":
        return "event";
      case "Staycations":
        return "staycation"; 
      default:
        return "";
    }
  }
  const getCitiesData = async(event) => {
    try{
      const res = await getCities(getReqEvent(event));
      setCitiesData(res?.data?.cities);
    } catch(error) {
      console.log(error);
    }
  }
  useEffect(() => {
    getHomePageAllData();
  }, [] )

  useEffect(() => {
    if (selectedEvent) getCitiesData(selectedEvent);
  }, [selectedEvent] )
  
  const handleSearch = () => {
    if (!selectedEvent) {
      setError(true);
      return null
    }
    setError(false)
    let reqEvent = undefined
    switch (selectedEvent) {
      case "Film Shootings":
        reqEvent = "film-shoot";
        break;
      case "Tv Shootings":
        reqEvent = "tv-shoot";
        break;
      case "Events":
        reqEvent = "event";
        break;
      case "Staycations":
        reqEvent = "staycation";
        break;  
      default:
        reqEvent = "";
        break;
    }
    let pathName = `/search`
    if(reqEvent) pathName  = pathName + `/` + reqEvent
    if(selectedCity) pathName  = pathName + `/` + selectedCity.replaceAll(' ','-').toLowerCase()
    // if(selectedLocation) pathName  = pathName + `?` + 'type=' + selectedLocation.replaceAll(' ','-')
    navigate(pathName)
    // navigate({
    //   pathname: "/location",
    //   search: createSearchParams({
    //     event: reqEvent,
    //     // activity: encodeURIComponent(selectedActivity),
    //     city: encodeURIComponent(selectedCity),
    //     location: selectedLocation,
    //   }).toString(),
    // });
  };
  const getRequiredLocationTypes = async(id) => {
    try{
      const response = await getLocationTypesByEvent(getReqEvent(id))
      setLocations(response?.data);
      setSelectedEvent(id);
    }catch (error) {  
      console.log(error);
    }
  }

  const handleOpen = () => {
    if (!selectedEvent) {
        setError(true); 
    } else {
        setError(false); 
    }
};

  return (
    <>
      <Header />
      <Constants type={"Home"} />
      <Box className="landingpageContainer">
        < LandingBanner/>
        <div className="d-flex align-items-center justify-content-center">
          <Box className="banner-search">
            <ul className = {`${error && "banner-search-event-error"}`} >
              <li className="select-input">
                <p className="SpotLetContent" >Seeking locations for</p>
                <Autocomplete
                  name="event"
                  label='event'
                  disableClearable={true}
                  value={selectedEvent}
                  onChange={(event, newValue) => {
                    setError(false);
                    getRequiredLocationTypes(newValue);
                    // setSelectedEvent(newValue);
                  }}
                  id="controllable-states-demo1"
                  options={eventOptions.map((option) => option.label)}
                  renderInput={(params) => <TextField {...params} placeholder="Select Event" />}
                  isOptionEqualToValue={(option, value) =>
                    value === undefined || value === "" || option.id === value.id
                  }
                  sx={{
                    "& .MuiOutlinedInput-root": {
                      borderRadius: "10px",
                      height: "50px",
                      borderColor: `${error ?  "#ea4335" : "transparent" }`, // Set your desired border color here
                    },
                    // '& .MuiAutocomplete-input:hover, & .MuiAutocomplete-input:focus': {
                    //   borderColor: 'transparent' // Disable border color on hover and focus
                    // },
                    "& .MuiAutocomplete-hasPopupIcon .MuiOutlinedInput-root:hover, & .MuiAutocomplete-hasPopupIcon .MuiOutlinedInput-root:focus": {
                      borderColor: "transparent" // Disable border color on hover and focus
                    }
                  }}
                />
              { error && <span className="SpotLetContent required" > Choose your event before search </span> }
              </li>
              {/* <CustomTooltip title={selectedEvent ? "" : "Choose location before selecting location type"} open= {open} >
                <li className="select-input">
                  <p className="SpotLetContent" >Property type</p>
                  <Autocomplete
                    name="location"
                    disableClearable={true}
                    value={selectedLocation}
                    disabled = {selectedEvent ? false : true }
                    onChange={(event, newValue) => {
                      setSelectedLocation(newValue);
                    }}
                    onTouchStart={() => setOpen(true)} 
                    onTouchEnd={() => setOpen(false)}
                    onMouseEnter={() => setOpen(true)}
                    onMouseLeave={() => setOpen(false)}
                    id="controllable-states-demo2"
                    options={ locations?.length > 0 ? locations?.map((option) => option.value) : [] }
                    renderInput={(params) => <TextField {...params} placeholder="Choose property type..." />}
                    isOptionEqualToValue={(option, value) =>
                      value === undefined || value === "" || option.id === value.id
                    }
                    sx={{
                      "& .MuiOutlinedInput-root": {
                        borderRadius: "10px",
                        height: "50px"
                      }
                    }}
                  />
                </li>
              </CustomTooltip>  */}
              {/* <CustomTooltip title={selectedEvent ? "" : "Choose location before selecting city"} open= {open} >                */}
              <li className={`select-input ${error && "home-search-event-error"}`}>
                <p className="SpotLetContent" >City</p>
                <Autocomplete
                  type = "text"
                  name="city"
                  disableClearable={true}
                  // disabled = {selectedEvent ? false : true }
                  value={selectedCity}
                  onChange={(event, newValue) => {
                    setSelectedCity(newValue);
                  }}
                  onOpen={handleOpen} // Handle dropdown opening
                  onTouchStart={() => setOpen(true)} 
                  onTouchEnd={() => setOpen(false)}
                  onMouseEnter={() => setOpen(true)}
                  onMouseLeave={() => setOpen(false)}
                  id="controllable-states-demo3"
                  // options={ carouselData?.cities ? carouselData?.cities?.map((option) => option.value) : []}
                  options={ citiesData ? citiesData?.map((option) => option.value) : []}
                  noOptionsText={error ? "Choose event" : "No cities for selected event"} // Custom no options message
                  renderInput={(params) => <TextField {...params} placeholder="Choose city..." />}
                  isOptionEqualToValue={(option, value) =>
                    value === undefined || value === "" || option.id === value.id
                  }
                  sx={{
                    "& .MuiOutlinedInput-root": {
                      borderRadius: "10px",
                      height: "50px"
                    }
                  }}
                />
              </li>
              {/* </CustomTooltip> */}
              {/* <CustomTooltip title={selectedEvent ? "" : "Choose location before search"} open= {openSearch} > */}
              <li
              className={`select-button ${error && "home-search-event-error"}`}
              disabled = {selectedEvent ? false : true }
              onTouchStart={() => setOpenSearch(true)} 
              onTouchEnd={() => setOpenSearch(false)}
              onMouseEnter={() => setOpenSearch(true)}
              onMouseLeave={() => setOpenSearch(false)}
              >
                <Button
                  className="btn-search"
                  variant="contained"
                  size="large"
                  onClick={()=> {
                    // if(selectedEvent){
                      handleSearch()
                    // } 
                  }}
                >
                  Search
                </Button>
              </li>
              {/* </CustomTooltip> */}
            </ul>
          </Box>
        </div>
        <Box className="landingpage-content">
          < CreativeSpaces />
          < SimpleSteps/>
          {carouselData?.featured && 
            <Box className="featured-properties">
              <Typography className="text-stroke SpotLetMainHead" variant="h2">
                IN INDIA
              </Typography>
              <Typography className="mar-t60 SpotLetMainHead " variant="h2">
                FEATURED PROPERTIES
              </Typography>
              <Box className="properties-container">
                <FeaturedProperties locationType='featured' data = {carouselData?.featured} />
              </Box>
            </Box>}
          <ProfessionalServices/>
          {carouselData?.toprated && 
            <Box className="top-rated-properties">
              <Typography className="text-stroke SpotLetMainHead" variant="h2">
                Exclusive
              </Typography>
              <Typography className="mar-t60 SpotLetMainHead" variant="h2">
                Exclusive Properties
              </Typography>
              <Box className="properties-container">
                <TopRatedProperties locationType='topRated' data = {carouselData?.toprated}  />
              </Box>
            </Box>}
            <WhyChooseUs/>
          {carouselData?.recent && 
              <Box className="recently-added-properties">
                <Typography className="text-stroke SpotLetMainHead" variant="h2">
                  Recently
                </Typography>
                <Typography className="mar-t60 SpotLetMainHead" variant="h2">
                  Recently Added Properties
                </Typography>
                <Box className="properties-container">
                  <Properties locationType='recentlyAdded' data = {carouselData?.recent}/>
                </Box>
            </Box>}
         {carouselData?.testimonials && 
          <Box className="client-testimonials">
            <Typography className="text-stroke SpotLetMainHead" variant="h2">
              Testimonials
            </Typography>
            <Typography className="mar-t60 SpotLetMainHead" variant="h2">
              Client Testimonials
            </Typography>
            <Box className="testimonials-Container">
              <ClientTestimonials data = {carouselData?.testimonials} />
            </Box>
          </Box>}
          <RecentProjects />
          {/* <HomeVideo/> */}
          <SpotletProvides/>
          {/* <JoinUs/> */}
          <HostContainer/>
        </Box>
      </Box>
      <Footer />
    </>
  );
};
