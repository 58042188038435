import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';

export const  VerifyModal = ({show,title,body,onHide,onSuccess}) => {

  return (
  <Modal show={show} onHide={onHide}>
    <Modal.Header closeButton>
        <Modal.Title>{title}</Modal.Title>
    </Modal.Header>
    <Modal.Body>{body}</Modal.Body>
    <Modal.Footer>
        <Button variant="secondary" onClick={onHide}>
        Cancel
        </Button>
        <Button variant="primary" onClick={onSuccess}>
        Continue
        </Button>
    </Modal.Footer>
    </Modal>
  );
}