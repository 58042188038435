
import { Modal } from "react-bootstrap";
import { useState } from "react";

const { Header, Title, Body } = Modal;

export const ReportIncorrectInfoModal = ({
    handleClose,
    handleOk,
    locationId,
    show,
    userId,
}) => {

    const [formData, setFormData] = useState({
        locationId ,
        userId,
        reportDetails : [],
        additionalInfo : ""
    })
    const [errorMsg, setErrorMsg] = useState("")

    const onChange = (e) => {
        const { name, value } = e.target
        setFormData({ ...formData, [name]: value })
    }


    const submitForm = async(e) => {
        e.preventDefault()
        if ((!formData?.reportDetails?.length > 0) && (!formData?.additionalInfo)) {
            setErrorMsg("Please select any option or give some additional info")
        } else {
            setErrorMsg("")
            handleOk(formData)
        }
    }

    const onCheckBox = (e, data) => {
        if (e?.target?.checked) {
            if (!formData?.reportDetails?.includes(data)) {
                const reqArray = [...formData?.reportDetails]
                reqArray.push(data)
                setFormData({
                    ...formData,
                    reportDetails :  reqArray
                })
            }
        } else {
            if (formData?.reportDetails?.includes(data)) {
                let reqArray = [...formData?.reportDetails]
                reqArray = reqArray?.filter((each) => each !== data )
                setFormData({
                    ...formData,
                    reportDetails :  reqArray
                })
            }
        }
    }

    return (
        <>
            <Modal
                show={show}
                onHide={handleClose}
                backdrop="static"
                keyboard={false}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Header closeButton>
                    <Title className="SpotLetMainHead">Report Incorrect Info</Title>
                </Header>
                <Body className="verify-email-modal-body">
                    <form onSubmit={submitForm} style={{ overflowY: "auto" }} >
                        <div
                            style={{
                                margin: "5px 5px 5px 0px",
                            }}
                        >
                            <div className="">
                                <div className="d-flex">
                                    <div className="w-50 d-flex">
                                        <input 
                                            checked = {formData?.reportDetails?.includes("Property pricing is incorrect")} 
                                            value = {"pricing-incorrect"} 
                                            name="pricing-incorrect" 
                                            id = "pricing-incorrect" 
                                            onChange={(e) => onCheckBox(e, "Property pricing is incorrect")} 
                                            type="checkbox" 
                                            className="form-check-input"
                                        />
                                        <label htmlFor="pricing-incorrect" className="SpotLetContent" > Property pricing is incorrect </label>
                                    </div>
                                    <div className="w-50 d-flex">
                                        <input 
                                            checked = {formData?.reportDetails?.includes("Property details are incorrect")} 
                                            value = {"Property details are incorrect"} 
                                            name="details-incorrect" 
                                            id = "details-incorrect" 
                                            onChange={(e) => onCheckBox(e, "Property details are incorrect")}
                                            type="checkbox" 
                                            className="form-check-input"
                                        />
                                        <label htmlFor="details-incorrect" className="SpotLetContent" >Property details are incorrect</label>
                                    </div>
                                </div>
                                <div className="mt-3 d-flex">
                                    <div className="w-50 d-flex">
                                        <input 
                                            checked = {formData?.reportDetails?.includes("Property address is incorrect")} 
                                            value = {"address-incorrect"} 
                                            name="address-incorrect" 
                                            id = "address-incorrect" 
                                            onChange={(e) => onCheckBox(e, "Property address is incorrect")} 
                                            type="checkbox" 
                                            className="form-check-input"
                                        />
                                        <label htmlFor="address-incorrect" className="SpotLetContent" > Property address is incorrect </label>
                                    </div>
                                    <div className="w-50 d-flex">
                                        <input 
                                            checked = {formData?.reportDetails?.includes("Property has plagiarised images")} 
                                            value = {"Property has plagiarised images"} 
                                            name="images-plagiarised" 
                                            id = "images-plagiarised" 
                                            onChange={(e) => onCheckBox(e, "Property has plagiarised images")}
                                            type="checkbox" 
                                            className="form-check-input"
                                        />
                                        <label htmlFor="images-plagiarised" className="SpotLetContent" >Property has plagiarised images</label>
                                    </div>
                                </div>
                                <div className="d-flex flex-column mt-3">
                                    <label
                                        htmlFor="claim_additional_info"
                                        className="form-label "
                                    >
                                        Additional Info
                                    </label>
                                    <textarea
                                        className="basicInfoDescription text-area-preserved-whitespace"
                                        placeholder="If there is any additional feedback in terms of incorrect or missing information/pictures that needs to be added or removed from this profile, Please mention here."
                                        id="claim_additional_info"
                                        rows='5'
                                        name='additionalInfo'
                                        onChange={onChange}
                                        value={formData?.additionalInfo}
                                    ></textarea>
                                </div>
                            </div>
                            {errorMsg && <span className="required mt-3" > {errorMsg}  </span>}
                            <div>
                                <button
                                    type="submit"
                                    className="SpotLetContent"
                                    style={{
                                        background: "#EA4335",
                                        border: "none",
                                        borderRadius: "5px",
                                        color: "#ffffff",
                                        width: "80px",
                                        height: "40px",
                                        marginTop: "5px",
                                    }}
                                >
                                    Submit
                                </button>
                            </div>
                        </div>
                    </form> 
                </Body>
            </Modal>
        </>
    );

}