import { useState,useEffect} from "react";
import { useAuthStore } from "../../store/auth";
import PhoneInput, {isValidPhoneNumber} from 'react-phone-number-input'
import 'react-phone-number-input/style.css';
import info from "../../assets/NewImages/BecomeAHost/i.svg";
import "./ListYourSpace"

const PropertyManagerDetails =  ({data,handleBack,handleNext, handleSubmit, isSubPropertyActive}) => {

    const { userProfile } = useAuthStore();
    const [numberError, setNumberError] = useState("");
    const [alternateNumberError, setAlternateNumberError] = useState("");


    const [details, setDetails] = useState({
        contact_person_name :"",
        contact_person_designation : "",
        contact_person_number : "",
        contact_person_email : "",
        alternate_person_name :"",
        alternate_person_number : "",
        alternate_person_email : "",
    })

    const onClickSubmit = (e) => {
        e.preventDefault()
        setNumberError((prev) => '');
        setAlternateNumberError((prev) => '');
        
        let phoneNumberError = false
        let altNumberError = false

        //check phone number validation
        if (details?.contact_person_number === undefined) {
            phoneNumberError = true
             setNumberError("Enter phone number")
        } 
        if(!phoneNumberError){
            if (details?.contact_person_number.slice(0,4) === "+911" || !isValidPhoneNumber(details?.contact_person_number)) {
                phoneNumberError = true
                setNumberError((prev) => "Enter a Valid Phone Number")
            } 
        }
        //

        //check alt phone number validation
        let reqAltNumber = ""
        if (details?.alternate_person_number !== undefined) reqAltNumber = details?.alternate_person_number
        if(reqAltNumber.length > 0){
            if (reqAltNumber.slice(0,4) === "+911" || !isValidPhoneNumber(details?.alternate_person_number)) {
                altNumberError = true
                setAlternateNumberError((prev) => "Enter a Valid Phone Number")
            } 
        }
        //

        //if no error handle below function
        if(!phoneNumberError && !altNumberError) {
            if (!isSubPropertyActive) {
                handleSubmit({property_manager_details : {...details, alternate_person_number : reqAltNumber}})
            }
            else{
                handleNext({property_manager_details : {...details, alternate_person_number : reqAltNumber}})
            }
        }
    }

    useEffect(() => {
        if(!data && userProfile){
            setDetails({
                ...details,
                contact_person_name : userProfile?.personalInfo?.fullName,
                contact_person_email : userProfile?.personalInfo?.email,
                contact_person_number : userProfile?.personalInfo?.mobile,
            })
        }
    },[userProfile] )

    useEffect(() => {
        if(data){
            setDetails(data)    
        }
        const element = document.getElementById(`listManager`)
        if (element) element.scrollIntoView({behavior: "smooth",block: "start"});
    },[data])

    return(
        <>
        <div className="locationtype-page container-fluid " >
        <h1 className="locationtype-head" id="listManager"> Property Manager Details {' '}
                <a
                    href="#"
                    data-bs-toggle="tooltip"
                    title="Provide contact info for effective communication with the property owner"
                >
                    <img src={info} />
                </a>
            </h1>
        </div>
        <form className="mt-1 tabCard row" onSubmit={onClickSubmit}>
            <div className="d-flex flex-column mt-1 mb-2 col-lg-6">
                <label htmlFor="manager_name" className="form-label">
                    Contact Person Name <span className="required">*</span> : 
                </label>
                <input
                    type="text"
                    required
                    placeholder="Enter name"
                    id="manager_name"
                    name="contact_person_name"
                    className="textFiled "
                    value={details?.contact_person_name}
                    onChange={(e) => 
                        setDetails({
                            ...details,
                            contact_person_name : e.target.value
                        })
                    }
                />
            </div>
            <div className="col-lg-6" ></div>
            <div className="d-flex flex-column col-lg-6 mt-1 mb-2">
                <label htmlFor="manager_designation" className="form-label">
                Contact Person Designation <span className="required">*</span> : 
                </label>
                {/* <input
                    type="text"
                    required
                    placeholder="Enter designation"
                    id="manager_designation"
                    name="contact_person_designation"
                    className="textFiled "
                    value={details?.contact_person_designation}
                    onChange={(e) => 
                        setDetails({
                            ...details,
                            contact_person_designation : e.target.value
                        })
                    }
                /> */}
                <select 
                    value = {details?.contact_person_designation}
                    required
                    name = "contact_person_designation"
                    className="textFiledSelect"
                    onChange={(e) => 
                        setDetails({
                            ...details,
                            contact_person_designation : e.target.value
                        })
                    }
                >
                    <option value="" selected hidden > Select Designation </option>
                    <option value = "Property Owner"> Property Owner </option>
                    <option value = "Agent"> Agent </option>
                    <option value = "Manager"> Manager</option>
                    <option value = "Housing Association"> Housing Association </option>
                    <option value = "Tenant"> Tenant  </option>
                </select>
            </div>
            <div className="col-lg-6" ></div>
            <div className="d-flex flex-column col-lg-6 mt-1 mb-2">
                <label htmlFor="manager_number" className="form-label">
                    Phone Number <span className="required">*</span> : 
                </label>
                <PhoneInput
                    required
                    international
                    countryCallingCodeEditable={false}
                    flags
                    placeholder="Enter phone number"
                    style={{height:"35px", border : "gray solid 1px", marginBottom : "2px", paddingLeft:"16.6px"}}
                    value={details?.contact_person_number}
                    defaultCountry="IN"
                    onChange={(e) => {
                        setDetails({
                            ...details,
                            contact_person_number : e
                        })
                    }  
                }
                />
            {
                numberError && <p style={{color:"Red", fontSize:"14.5px"}} className="m-0" > {numberError} </p> 
            }
            </div>
            <div className="col-lg-6" ></div>
            <div className="d-flex flex-column col-lg-6 mt-1 mb-2">
                <label htmlFor="manager_email" className="form-label">
                    Email Address <span className="required">*</span> : 
                </label>
                <input
                    type="email"
                    pattern="^[^\s@]+@[^\s@]+\.[^\s@]{2,}$"
                    title="info@example.org"
                    required
                    placeholder="Enter email"
                    id="manager_email"
                    name="contact_person_email"
                    className="textFiled "
                    value={details?.contact_person_email}
                    onChange={(e) => 
                        setDetails({
                            ...details,
                            contact_person_email : e.target.value
                        })
                    }
                />
            </div>
            <div className="col-lg-6" ></div>
            <div className="d-flex flex-column col-lg-6 mt-1 mb-2">
                <label htmlFor="alternate_name" className="form-label">
                Alternate Person's Name : 
                </label>
                <input
                    type="text"
                    placeholder="Enter name"
                    id="alternate_name"
                    name="alternate_person_name"
                    className="textFiled "
                    value={details?.alternate_person_name}
                    onChange={(e) => 
                        setDetails({
                            ...details,
                            alternate_person_name : e.target.value
                        })
                    }
                />
            </div>
            <div className="col-lg-6" ></div>
            <div className="d-flex flex-column col-lg-6 mt-1 mb-2">
                <label htmlFor="alternate_number" className="form-label">
                    Alternate Person's Mobile Number : 
                </label>
                {/* <input
                    type="tel"
                    pattern="[6789][0-9]{9}"
                    title="Phone number should start with 6-9 and remaing 9 digit with 0-9"
                    placeholder="Enter number"
                    id="alternate_number"
                    name="alternate_person_number"
                    className="textFiled "
                    value={details?.alternate_person_number}
                    onChange={(e) => 
                        setDetails({
                            ...details,
                            alternate_person_number : e.target.value
                        })
                    }
                /> */}
                <PhoneInput
                    // required
                    international
                    countryCallingCodeEditable={false}
                    flags
                    placeholder="Enter phone number"
                    style={{height:"35px", border : "gray solid 1px", marginBottom : "2px", paddingLeft:"16.6px"}}
                    value={details?.alternate_person_number}
                    defaultCountry="IN"
                    onChange={(e) => {
                        setDetails({
                            ...details,
                            alternate_person_number : e
                        })
                    }  
                }
                />
            {
                alternateNumberError && <p style={{color:"Red", fontSize:"14.5px"}} className="m-0" > {alternateNumberError} </p> 
            }
            </div>
            <div className="col-lg-6" ></div>
            <div className="d-flex flex-column col-lg-6 mt-1 mb-2">
                <label htmlFor="manager_name" className="form-label">
                    Alternate Person's Email: 
                </label>
                <input
                    type="email"
                    pattern="^[^\s@]+@[^\s@]+\.[^\s@]{2,}$"
                    placeholder="Enter email"
                    id="manager_name"
                    name="alternate_person_email"
                    className="textFiled "
                    value={details?.alternate_person_email}
                    onChange={(e) => 
                        setDetails({
                            ...details,
                            alternate_person_email : e.target.value
                        })
                    }
                />
            </div>
        {/* Save & Continue start */}
        <div className="row controlContainer">
            <div className="col-lg-6">
                <div className="d-flex flex-row justify-content-between align-items-center">
                <div>
                    <button
                    type="button"
                    class="btn btn-outline-danger"
                    onClick={handleBack}
                    >
                        Back
                    </button>
                </div>
                <div>
                    <button
                    type="submit"
                    className="btn btn-primary"
                    >
                    {isSubPropertyActive === false ?  "Submit" : "Save & Continue"}
                    </button>
                </div>
                </div>
            </div>
        </div>
        {/* Save & Continue End   */}
        </form>
        </>
    )
}

export default  PropertyManagerDetails

