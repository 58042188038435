import Modal from 'react-bootstrap/Modal';
import { MdOutlinePhone } from "react-icons/md";

const HostContactFormModal = ({data,show,onHide}) => {
    return(
        <Modal
    show={show}
    onHide={onHide}
    keyboard={false}
    size="md"
    aria-labelledby="contained-modal-title-vcenter"
    centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter" className='SpotLetSubHead' >
          Host details
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className='d-flex flex-column SpotLetContent'>
            {data?.locationName &&<strong className='mb-2 SpotLetSubHead'>{data?.locationName}</strong>}
            <span className='mb-2'>Don't forget to mention you found them on SpotLet!</span>
            {data?.name &&<span className='mb-2'><strong>Host name:</strong>{' '}{data?.name}</span>}
            {data?.designation &&<span className='mb-2'><strong>Designation :</strong>{' '}{data?.designation}</span>}
            {data?.number &&<span className='mb-2'><strong>Mobile number :</strong>{' '}<a href={`tel:${data?.number}`} style={{textDecoration : 'none',color : '#000000'}}><MdOutlinePhone /> {data?.number}</a></span>}
            {data?.altNumber &&<span className='mb-2'><strong>Alternate number :</strong>{' '}<a href={`tel:${data?.altNumber}`} style={{textDecoration : 'none',color : '#000000'}}><MdOutlinePhone />{data?.altNumber}</a></span>}
        </div>
      </Modal.Body>
    </Modal>
    )
}
export default HostContactFormModal;