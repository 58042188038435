import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { getDashBoardDetails, getPlans } from "../../../apis/api";
import moment from "moment";
import { PageLoader } from "../../../components/PageLoader";
import { Chart } from "react-google-charts";
import SubscriptionModal from "../../NewSubscription/Modals/SubscriptionModal";


const DONUT_DATA = [
    ["Leads", "Leads per Day"],
    // ["Work", 11],
    // ["Eat", 2],
    ["Name View", 0],
    ["Booking Request", 0],
    ["Get in Touch", 0], // CSS-style declaration
  ];
  
   const donutOptions = {
    title: "Your Property Leads",
    pieHole: 0.4,
    is3D: false,
  };

const SubDashboard = ({id}) => {
    const navigate = useNavigate();
    const [loader, setLoader] = useState(false)
    const [data, setData] = useState();
    const [getInTouchLeads,setGetInTouchLeads] = useState([])
    const [clickLeads,setClickLeads] = useState([])
    const [bookingLeads,setBookingLeads] = useState(0)
    const [donutData, setDonutData] = useState([...DONUT_DATA]);
    const getData = async() => {
        setLoader(true)
        try{
            const resp = await getDashBoardDetails(id)
            const temp1 = resp?.data?.leads?.filter((item) => item.type === 'Get In Touch')
            // const tem2 = resp?.data?.leads?.filter((item) => item.type === 'Booking Request')
            const temp3 = resp?.data?.leads?.filter((item) => item.type === 'Location Id Click')
            setData(resp?.data)
            setGetInTouchLeads(temp1)
            setBookingLeads(resp?.data?.bookings)
            setClickLeads(temp3)
            const temp =  [
                ["Leads", "Leads per Day"],
                ["Name View", temp3?.length],
                ["Booking Request", resp?.data?.bookings],
                ["Get in Touch", temp1?.length], // CSS-style declaration
              ];
              setDonutData([...temp])
        }catch(error){
            console.log(error)
        }
        setLoader(false)
    }
    useEffect(()=> {
        getData()
    },[])
    const donutChart = () => {
        return(
            <Chart
                chartType="PieChart"
                width="100%"
                height="260px"
                className = 'dashboardDonutChart'
                data={donutData}
                options={donutOptions}
            />
        )
    }
     //subscription update start
     const region = localStorage.getItem('region')
     const [showSubscriptionModel, setShowSubscriptionModel] = useState(false);
     const [standardPlan, setStandardPlan] = useState({})
     const [premiumPlan, setPremiumPlan] = useState({})
     const [activePlan,setActivePlan] = useState({})
     const onClickUpgrade = async () => {
         setLoader(true)
         try {
         const res = await getPlans();
         let reqCurrency 
         if (region === "India") {
             reqCurrency = "INR"
         } else {
             reqCurrency = "USD"
         }
         if(res?.data) {
             let standard,premium
             for (let each of  res?.data) {
                 if (each?.plan === "Standard") {
                     for (let eachCountryPlan of each?.amount) {
                         if (eachCountryPlan?.currency === reqCurrency) {
                             standard = {
                                 plan : each?.plan,
                                 originalAmount : eachCountryPlan?.originalAmount,
                                 amount : eachCountryPlan?.discountAmount,
                                 planId : eachCountryPlan?.plan_id,
                                 currency : eachCountryPlan?.currency,
                             }
                             setStandardPlan(standard)
                         }
                     }
                 }
                 if (each?.plan === "Premium") {
                     for (let eachCountryPlan of each?.amount) {
                         if (eachCountryPlan?.currency === reqCurrency) {
                             premium = {
                                 plan : each?.plan,
                                 originalAmount : eachCountryPlan?.originalAmount,
                                 amount : eachCountryPlan?.discountAmount,
                                 planId : eachCountryPlan?.plan_id,
                                 currency : eachCountryPlan?.currency,
                             }
                             setPremiumPlan(premium)
                         }
                     }
                 }
             }
             switch (data?.subscription_id) {
                 case 'Basic':
                     setActivePlan(standard)
                     break;
                 case 'Standard':
                     setActivePlan(premium)
                     break;
                 default:
                     break;
             }
             setShowSubscriptionModel(true)
         }
         } catch (error) {
         console.log(error)
         }
         setLoader(false)
     }
     const handleChangePlan = () => {
         if(activePlan?.plan === 'Standard'){
             setActivePlan(premiumPlan)
         }else{
             setActivePlan(standardPlan)
         }
     }
     const completeTask = () => {
         setShowSubscriptionModel(false)
         getData()
     }
     //subscription update end

    return(
        <>
            <div className="row w-100">
                        <div className="col-lg-6 col-xl-5">
                            <div className="subscriptionCardContainer" >
                                <div className="d-flex flex-row justify-content-between align-center">
                                        <span className="dashboardSubscriptionPlanHeading">{data?.subscription_id}{' '}
                                        Subscription
                                        </span>
                                        <div>
                                            {data?.subscription_id !== 'Premium' && (
                                                <button className="subscriptionUpgrade"  onClick = {onClickUpgrade} >Upgrade</button>
                                            )}
                                        </div>
                                </div>
                                <div className="d-flex flex-column justify-content-between align-center">
                                    <span className="ListedName">{data?.basic_info?.property_name}({data?.location_id})</span>
                                    <span className="ListedDate">Status : {data?.status}</span>
                                    <span className="ListedDate">Listed on : {moment(data?.createdAt).format('DD-MM-YYYY')}</span>
                                    {/* {data?.subscription_id !== 'Basic' && (<span className="ListedDate">Subscription : {data?.subscription?.activationDate ? moment(data?.subscription?.activationDate).format('DD-MM-YYYY') to moment(data?.subscription?.expiryDate).format('DD-MM-YYYY') : 'Not Activated'}</span>)} */}
                                    {data?.subscription_id !=="Basic" && (<span className="ListedDate" > Subscription : {data?.subscription?.activationDate ? ` ${moment(data?.subscription?.activationDate).format('DD-MM-YYYY')} to ${moment(data?.subscription?.expiryDate).format('DD-MM-YYYY')} `: "Not Activated"}  </span>)}
                                    <div>
                                        {/* <button className="btn btn-secondary" onClick={()=> navigate(`/invoice/${data?.location_id}`)}>Get invoice</button> */}
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* <div  className="col-lg-6"></div> */}
                        <div className="col-lg-6 col-xl-5 donutContainer">
                            {/* <div className="contentShowContainer">
                                <span>Content Score</span>
                                <span>45%</span>
                                <div className="w-100">
                                    <ProgressBar animated now={45} />
                                </div>
                            </div> */}
                            {donutChart()}
                        </div>
                        {/* <div  className="col-lg-3 col-xl-3"></div> */}
                        <div className="col-lg-6 col-xl-5">
                            <div className="leadShowContainer">
                                <span>Total Leads</span>
                                <span>{clickLeads.length + bookingLeads + getInTouchLeads.length}</span>
                            </div>
                        </div>
                        {/* <div  className="col-lg-6"></div> */}
                        <div className="col-lg-6 col-xl-5">
                            <div className="todayShowContainer">
                                {/* <span>Today Leads</span> */}
                                <span><span className="todayLeadText">Property Name View :</span> {clickLeads.length} </span>
                                <span><span className="todayLeadText">Booking Requests :</span> {bookingLeads}</span>
                                <span><span className="todayLeadText">Get In Touch :</span> {getInTouchLeads.length}</span>
                            </div>
                        </div>
            </div>
            {showSubscriptionModel && (
                <SubscriptionModal
                    show={showSubscriptionModel}
                    onHide={()=>setShowSubscriptionModel(false)}
                    plan ={activePlan}
                    handleChangePlan={handleChangePlan}
                    completeTask={completeTask}
                    title = {'Upgrade Plan'}
                    listingId = {data?.location_id}
                />
            )}
        {loader && <PageLoader />}
        </>
    )
}
export default SubDashboard;