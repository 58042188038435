import {  Modal } from "react-bootstrap";
import { useEffect} from "react";
import Constants from "../../utils/Constants";


const { Header, Footer, Title, Body } = Modal;
export function TermsAndConditionsView({
    handleClose,
    show,
    sendAgree
}) {

    let acceptBtn;
    useEffect(() => {
        acceptBtn = document.querySelector('.agree-btn');
    })

    const handleScroll = (e) => {
        if(e.target.scrollHeight - e.target.scrollTop < 1000){
            acceptBtn.classList.add('enable-btn');
            acceptBtn.addEventListener('click',sendAgree)
        }
    };

    const scrollDown = () => {
        const scroll = document.getElementById('terms_scroll');
        scroll.scrollTo({ left: 0, top: scroll.scrollHeight, behavior: "smooth" });
    }

    return (
        <Modal show={show} onHide={handleClose}>
            <Header closeButton>
                <Title>Terms & Conditions</Title>
            </Header>
            <Body>
                <div>
                    <div className="d-flex justify-content-end">
                        <i class="bi bi-arrow-down-circle" title="click to scroll to down" onClick={scrollDown}></i>
                    </div>
                    <div id='terms_scroll' onScroll={handleScroll} className="popup-select-list">
                        <div className="row justify-content-center">
                            <Constants type = {"terms"} />
                        </div>
                    </div>
                </div>
            </Body>
            <Footer className="TandC-Footer">
                <button type = 'button' className="agree-btn">I AGREE</button>
                {/* <Button variant="secondary" onClick={handleOk}>
                    I DISAGREE
                </Button> */}

            </Footer>
        </Modal>
    );
}
