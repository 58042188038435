import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import amenityArrow from '../assets/images/amenityArrow.svg'

export function AmenitiesModal({show,onHide,title,body}) {
  return (
    <Modal
    show={show}
    onHide={onHide}
    keyboard={false}
    size="lg"
    aria-labelledby="contained-modal-title-vcenter"
    centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter" className='SpotLetSubHead' >
          {title}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body style={{marginLeft:"20px"}}>
      <div className='row propertyPageAmenityModalBody'>
            {body?.map((amenity,i) => {
                return(
                    <div className='col-lg-4 col-md-6 mb-2'>
                        <img src={amenityArrow} alt='amenityArrow'/>
                        <span className='amenityListText SpotLetContent'>{amenity?.name}</span>
                        {amenity?.sub && (
                            <ul>
                                {amenity?.sub?.map((sub)=> (
                                    <li className='SpotLetContent'>{sub.value}</li>
                                ))}
                            </ul>
                        )}
                    </div>
                )
            })}
        </div>
      </Modal.Body>
    </Modal>
  );
}