import { Modal } from "react-bootstrap";

const { Header, Title, Body } = Modal;

export const CenteredModal = ({
    handleClose,
    show,
    title,
    data
}) => {

    return (
        <Modal
            show={show}
            onHide={handleClose}
            backdrop="static"
            keyboard={false}
            size="md"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Header closeButton>
                <Title className="SpotLetSubHead">{title}</Title>
            </Header>
            <Body className="verify-email-modal-body SpotLetContent text-area-preserved-whitespace" style={{maxHeight : "431px", overflowY : "auto"}}>
                {data}
            </Body>
        </Modal>
    );

}