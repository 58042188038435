import React from "react";
import { Box, Typography } from "@mui/material";
import Slider from "react-slick";

// Images
import logo from "../../../assets/NewImages/NewLogo/Spotlet_V1 .webp";
import next from '../../../assets/NewImages/Home/next.webp';
import prev from '../../../assets/NewImages/Home/prev.webp';
import projectimg01 from "../../../assets/NewImages/Home/project-img01.webp";
import projectimg02 from "../../../assets/NewImages/Home/project-img02.webp";
import projectimg03 from "../../../assets/NewImages/Home/project-img03.webp";

import "./style.css";

function SampleNextArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{ ...style, display: "block", height: '50px', width: '100px', marginRight: '-30px' }}
    ><img loading="lazy" src={next} className="arrowImg" onClick={onClick} alt='Next'/></div>
  );
}

function SamplePrevArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{ ...style, display: "block", height: '50px', width: '100px', zIndex: '1' }}
    ><img loading="lazy" src={prev} className="arrowImg" onClick={onClick} alt='Previous'/></div>
  );
}
var settings = {
  dots: false,
  infinite: true,
  speed: 300,
  swipeToSlide: false,
  fade: false,
  swipe: false,
  slidesToShow: 3,
  slidesToScroll: 1,
  initialSlide: 0,
  nextArrow: <SampleNextArrow />,
  prevArrow: <SamplePrevArrow />,
  responsive: [
    {
      breakpoint: 1025,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1,
        infinite: true,
      }
    },
    {
      breakpoint: 768,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1,
        infinite: true,
        initialSlide: 0
      }
    },
    {
      breakpoint: 510,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        infinite: true,
      }
    }
  ]
};

const projects = [
  {
    image: projectimg01,
    name: 'Siddharth Roy',
    activity: 'Telugu Feature Film'
  },
  {
    image: projectimg02,
    name: 'Ardhangi',
    activity: ' Telugu Tv Serial',
  },
  {
    image: projectimg03,
    name: 'Thali, Telugu',
    activity: 'TV Serial'
  }
]
class App extends React.Component {
  render() {
    return (
      <Box className="recent-projects">
      <Box className="project-heading">
        <img loading="lazy" src={logo} style={{ width: "163px", height: "48px" }} alt="SpotLet"/>
        <Typography variant="h4" className="SpotLetMainHead">Recent Projects</Typography>
        <Typography variant="body1" className="SpotLetSubHead">
          "We have provided few unique locations for recent events and film shoots"
        </Typography>
      </Box>
      <Box className="project-carousel">
      <div>
        <Slider {...settings}>
          {projects.map((file, i) => {
            return (
              <div className="item">
                <Box className="project-box">
                  <figure>
                    <img loading="lazy" src={file.image} alt='projects'/>
                  </figure>
                  <article>
                    <Typography variant="h5" className="SpotLetSubHead">{file.name}</Typography>
                    <Typography variant="h6" className="SpotLetSubHead">{file.activity}</Typography>
                  </article>
                </Box>
              </div>
            )
          })}
        </Slider>
      </div>
      </Box>
    </Box>
    );
  }
}
export default App;
