// src/Notification.js
import React, { useState, useRef ,useEffect} from 'react';
import { useNavigate, useLocation } from "react-router-dom";
import { noOfUserNotifications,getUserNotifications } from '../../apis/api';
import { useAuthStore } from '../../store/auth';
import Badge from '@mui/material/Badge';
import './NotificationModal.css';
import ic_notification from "../../assets/NewImages/Header/ic-notification.svg";
import logo from "../../assets/NewImages/Header/Favicon.ico"
import moment from 'moment';

const NotificationModalNew = () => {
    const { userProfile } = useAuthStore();
    const navigate = useNavigate();
    const location = useLocation();
    const timerId = useRef(null);
    const countRef = useRef(0)
    const dropdownRef = useRef(null);
    const iconRef = useRef(null);
    const [showDropdown, setShowDropdown] = useState(false);
    const [notificationsCount, setNotificationsCount] = useState(0);
    const [unread, setUnread] = useState(0);
    const [data,setData] = useState([])
    const targetPath = '/notifications';
    useEffect(() => {
      const handleClickOutside = (event) => {
        event.stopPropagation();
        if (
          dropdownRef.current && 
          !dropdownRef.current.contains(event.target) &&
          iconRef.current &&
          !iconRef.current.contains(event.target)
        ) {
          setShowDropdown(false);
        }
      };
    
        document.addEventListener('mousedown', handleClickOutside);
    
        return () => {
          document.removeEventListener('mousedown', handleClickOutside);
        };
      }, [dropdownRef]);
    const showNotification = () => {
    const options = {
        body: "You got a New Notification",
        icon: logo,
        dir: "ltr"
    }
    const notification = new Notification(`Hello ${userProfile?.personalInfo?.fullName}`, options)
    }
    const getNotificationsCount = async () => {
    try {
        const response = await noOfUserNotifications(userProfile._id)
        setNotificationsCount(response.data.count)
        if (countRef.current < response.data.count) {
            showNotification()
            countRef.current = response.data.count
        }
    }
    catch (e) {
        console.log(e);
    }
    }
    const getData = async () => {
        try{
            const resp = await getUserNotifications(userProfile?._id)
            const temp = []
            resp?.data?.notification.map((item)=> {
                const tempObj = {}
                tempObj.id = item?._id
                tempObj.header = item?.notificationHeader
                tempObj.avatar = logo
                tempObj.read = item?.read
                tempObj.date = moment(item?.createdAt).format('YYYY-MM-DD hh:mm A')
                temp.push(tempObj)
            })
            setData(temp)
            setUnread(resp?.data?.unread)
        }catch(error){
            console.log(error)
        }
    }
    const handleOpenNotificationTab = (event) => {
      event.stopPropagation();
        if(!showDropdown){
            getData()
        }
        setShowDropdown(!showDropdown)
    }
    // const handleUnread = () => {
    //     const state = {
    //         unread : true,
    //       };
    //     navigate("/notifications",{state})
    // }
    // const handleClickNotification = (id) => {
    //     const state = {
    //         id : id,
    //       };
    //     navigate("/notifications",{state})
    // }

    const handleUnread = () => {
      const state = {
          unread: true,
      };
      if (location.pathname === targetPath) {
          navigate(targetPath, { state, replace: true });
          window.location.reload();
      } else {
          navigate(targetPath, { state });
      }
  };

  const handleClickNotification = (id) => {
      const state = {
          id: id,
      };
      if (location.pathname === targetPath) {
          navigate(targetPath, { state, replace: true });
          window.location.reload();
      } else {
          navigate(targetPath, { state });
      }
  };

    useEffect(() => {
    if (userProfile) {
        timerId.current = setInterval(() => {
        if(userProfile) getNotificationsCount();
        }, 5000);

        if (!("Notification" in window)) {
            console.info("Browser does not support desktop notification");
            } else {
            console.info("Notifications are supported");
            Notification.requestPermission()
            }

        return () => {
        clearInterval(timerId.current);
        };
    }
    }, [userProfile])

    const handleSeeAll = () => {
      if (location.pathname === targetPath) {
          window.location.reload();
      } else {
          navigate(targetPath);
      }
  };

  return (
    <div className="notification-wrapper">
      <Badge ref={iconRef} badgeContent={notificationsCount} invisible={notificationsCount === 0}
      color="error" onClick={handleOpenNotificationTab} >
            <img src={ic_notification} className="notificationImages"
            alt='notification'/>
        </Badge>
        {showDropdown && (
        <div 
          className="notification-dropdown" 
          ref={dropdownRef}
          id="notification-dropdown"
          role="menu"
        >
          <h3>Notifications {unread ? <span className='SpotLetContent' onClick={handleUnread}>{' '}{' '}{unread} Unread Messages</span> : ''}</h3>
          {data.map((notif) => (
            <div key={notif.id} className={`notification-item ${!notif.read && 'notificatinUnreadBold'}`} role="menuitem" onClick={()=>handleClickNotification(notif.id)}>
              <img src={notif.avatar} alt="avatar" className="avatar" />
              <div className="notification-text">
                <p className={`SpotLetContent ${!notif.read && 'notificatinUnreadBold'}`}>{notif.header}</p>
                <small className={`${!notif.read && 'notificatinUnreadBold'}`}>{notif.date}</small>
              </div>
            </div>
          ))}
          <div className="see-all">
            <a  onClick={() => handleSeeAll()}>See all Notifications</a>
          </div>
        </div>
      )}
    </div>
  );
};

export default NotificationModalNew;
