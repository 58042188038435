import { useEffect, useState } from "react"
import { useNavigate, useLocation  } from 'react-router-dom';
import Select from 'react-select';
import { getCities,getAllTags, getSearchListings, getLocationTypesByEvent } from "../../apis/api";
import { PageLoader } from "../../components/PageLoader";
import PropertyInfo from "../../components/NewPropertyInfo";
import {SearchFilter} from '../../components/Modal/SearchFilter';
import { Header } from "../../components/NewHeader/Header";
import { Footer } from "../../components/Footer";
import SearchFeatured from "./SearchFeatured";
import Constants from "../../utils/Helmet";
import Pagination from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';
import SearchIcon from '../../assets/NewImages/SearchPage/search-normal.svg';
import NoResults from '../../assets/NewImages/SearchPage/no_results_found.jpg';
import selectEvent from '../../assets/NewImages/SearchPage/select-event.webp'

import GoogleMap from "./GoogleMap";
import { Box } from '@mui/material';
import Tooltip, {tooltipClasses} from "@mui/material/Tooltip";
import { styled } from '@mui/material/styles';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import "./SearchPage.css" 

const options = [
    { value: 'film-shoot', label: 'Film Shootings' },
    { value: 'tv-shoot', label: 'Tv Shootings' },
    { value: 'event', label: 'Events' },
    { value: 'staycation', label: 'Staycations' },
  ]
const ATTENDIES = [
    { value: "1-5", label: "1 - 5 people" },
    { value: "6-15", label: "6 - 15 people" },
    { value: "16-30", label: "16 - 30 people" },
    { value: "31-45", label: "31 - 45 people" },
    { value: "46-60", label: "46 - 60 people" },
    { value: "60-100000", label: "60+ people" },
];

const VENUE_TYPES = [
    { value: "venue", label: "Venue" },
    { value: "perPlate", label: "Per Plate" },
    { value: "perHour", label: "Per Hour" },
];

const VENUE_FOOD_TYPES = [
    { value: "nonVeg", label: "Veg/Non-Veg" },
    { value: "pureVeg", label: "Pure Veg" },
];

const POPULAR_TYPES = [
  { value: 'Farm House', label: 'Farm House' },
  { value: 'Banquet Hall', label: 'Banquet Hall' },
  { value: 'Villa', label: 'Villa' },
  { value: 'Convention Centre', label: 'Convention Centre' },
  { value: 'Resort', label: 'Resort' },
  { value: 'Conference Hall', label: 'Conference Hall' },
  { value: 'Cottage', label: 'Cottage' },
  { value: 'Farm Land', label: 'Farm Land' },
  { value: 'Guest House', label: 'Guest House' },
  { value: 'Open Air Lawn', label: 'Open Air Lawn' },
  { value: 'Home Stay', label: 'Home Stay' },
  { value: 'Party Hall', label: 'Party Hall' },
  { value: 'Tree House', label: 'Tree House' },
  { value: 'Palace', label: 'Palace' },
  { value: 'Hotel', label: 'Hotel' },
  { value: 'Manduva House', label: 'Manduva House' },
  { value: 'Sports Arena', label: 'Sports Arena' },
  { value: 'Mansion', label: 'Mansion' }
];
const OUTDOOR_AMENITIES = [
  { value: 'Outdoor Swimming Pool', label: 'Swimming Pool' },
  { value: 'Lawn', label: 'Lawn' },
  { value: 'Bonfire', label: 'Bonfire' },
  { value: `Children's Play Area`, label: `Children's Play Area` },
  { value: 'Barbeque', label: 'Barbeque' },
  { value: `Sun Deck`, label: `Sun Deck` },
  { value: 'Outdoor Sports', label: 'Outdoor Sports' },
  { value: 'Balcony / Terrace', label: 'Balcony / Terrace' },
];
const INDOOR_AMENITIES = [
  { value: 'Air Conditioning', label: 'Air Conditioning' },
  { value: 'Wi-Fi', label: 'Wi-Fi' },
  { value: 'Fire Place', label: 'Fire Place' },
  { value: 'Steam & Sauna', label: 'Steam & Sauna' },
  { value: 'Gym/ Fitness Centre', label: 'Gym/ Fitness Centre' },
  { value: 'Indoor Games', label: 'Indoor Games' },
];
const POLICIES = [
    { value: 'Music', label: 'Music' },
    { value: 'Alcohol', label: 'Alcohol' },
    { value: 'Smoking', label: 'Smoking' },
    { value: 'Outside Food', label: 'Outside Food' },
    { value: 'Caretaker', label: 'Caretaker' },
    { value: 'Non-vegetarian food', label: 'Non-vegetarian food' }
];

  //Custom Tooltip styles
const CustomTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} arrow classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.palette.common.black,
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.black,
    padding: '10px',
    fontSize : "13px",
  },
}));


const SearchPage = () => {
    const navigate = useNavigate()
    const location = useLocation();
    const { state } = location;
    const url = new URL(window.location.href);
    const pathname = url.pathname;
    const segments = pathname?.split('/');
    // let paramsCity =''
    // if(segments?.[2]){
    //   paramsCity = {
    //     label : segments?.[3]?.replaceAll('-', ' ').replace(/\b\w/g, char => char.toUpperCase()),
    //     value : segments?.[3]?.replaceAll('-', ' ').replace(/\b\w/g, char => char.toUpperCase()),
    //   }
    // }
    let locationtype = ''
    // switch (segments?.[2]) {
    //   case "film-shoot":
    //     locationtype =  {value: 'film-shoot', label: 'Film Shootings'}
    //     break;
    //   case "tv-shoot":
    //     locationtype =  {value: 'tv-shoot', label: 'Tv Shootings'}
    //     break;
    //   case "event":
    //     locationtype =  {value: 'event', label: 'Events'}
    //     break;
    //   case "staycation":
    //     locationtype =  { value: 'staycation', label: 'Staycations'}
    //     break;
    //   default:
    //     locationtype = ""
    //     break;
    // }
    // let paramsActivity = ''
    // if(state?.formData?.activity){
    //   paramsActivity = {
    //     label : state?.formData?.activity,
    //     value : state?.formData?.activity,
    //   }
    // }
    // let paramsLocation = ''
    // if(state?.formData?.location){
    //   paramsLocation = {
    //     label : state?.formData?.location,
    //     value : state?.formData?.location,
    //   }
    // }
    const [page, setPage] = useState(1);
    const [open, setOpen] = useState(false); 
    const [openActivity, setOpenActivity] = useState(false); 
    const [openCity, setOpenCity] = useState(false); 
    const [openFilter, setOpenFilter] = useState(false); 
    const [openActivityFilter, setOpenActivityFilter] = useState(false); 
    const [openCityFilter, setOpenCityFilter] = useState(false); 
    const [cities, setCities] = useState([]);
    const [activites, setActivities] = useState([]);
    const [locations, setLocations] = useState([]);
    const [locationsDataFetched, setLocationsDataFetched] = useState(false);
    const [filterPopup, setFilterPopup] = useState(false);
    const [noOfLocations, setNoOfLocations] = useState(12);
    const [locationsCount, setLocationsCount] = useState(-1);
    const [loader, setLoader] = useState(false);
    const [listings, setListings] = useState([]);
    const [featuredListings, setFeaturedListings] = useState([]);
    const [totalCount, setTotalCount] = useState(0);
    const [inputValues, setInputValues] = useState({
      event : "",
      location : "",
      city : "",
      activity : "",
    });
    const [mapSwitch, setMapSwitch] = useState(false)
    const [formData, setFormData] = useState({
      searchInput: '',
      event: "",
      activity: "",
      location: "",
      city: "",
      area: '',
      amenities: [],
      policies: [],
      attendies: '',
      // event: segments?.[2] || '',
      event: '',
      // city: segments?.[3] ? segments?.[3]?.replaceAll('-', ' ').replace(/\b\w/g, char => char.toUpperCase()) : '',
      city: '',
      eventType : "",
      plateType : "",
      ...state?.formData,
    });
    const customStyles = {
        option: (provided, state) => ({
          ...provided,
          backgroundColor: state.isSelected || state.isFocused ? '#ea4335' : 'white', // Customize the background color here
          color: state.isSelected || state.isFocused ? 'white' : 'black', // Customize the text color here
        }),
      };
    const getRequiredData = async(id) => {
      try{
        const response = await getLocationTypesByEvent(id)
        setLocations(response?.data);
        const tempObj = {}
        switch (id) {
          case "film-shoot":
            tempObj.film = true
            break;
          case "tv-shoot":
            tempObj.tv = true
            break;
          case "event":
            tempObj.event = true
            break;
          case "staycation":
            tempObj.stay = true
            break;
          default:
            locationtype = ""
            break;
        }
        const activityRes = await getAllTags(tempObj);
        const tempActivityArray = []
        activityRes?.data?.allTags?.map((item) => {
          tempActivityArray.push({ value: item?.name, label: item?.name },)
        })
        const activityData = [...tempActivityArray].sort((a, b) =>
            a?.label?.toLowerCase() > b?.label?.toLowerCase() ? 1 : -1,
          );
        setActivities(activityData);
        const citiesRes = await getCities(id);
        // const citiesData = [...citiesRes?.data?.cities].sort((a, b) =>
        //     a?.label?.toLowerCase() > b?.label?.toLowerCase() ? 1 : -1,
        //   );
        setCities(citiesRes?.data?.cities);
      }catch (error) {
        console.log(error);
      }
    }
    const searchPlaces = async (data, page) => {
      setLoader(true)
      try {
        if(data?.event?.length > 0){
          const response = await getSearchListings(data, page,noOfLocations);
          setListings(response.data.locations);
          setFeaturedListings(response.data.featuredLocations);
          setLocationsCount(response.data.count)
          setTotalCount(Math.floor(response?.data?.count/12) <= 0 ? 1 : Math.floor(response?.data?.count/12) );
          setLoader(false);
        }else{
          setListings([])
          setFeaturedListings([])
        }
        setLocationsDataFetched(true);
      } catch (error) {
        console.log(error)
      }
      setLoader(false)
    }
    const openFilterPopup = (e) => {
      // setShowPopup(true)
      setFilterPopup(true);
    }
    const clearAll = () => {
      setFormData({
        searchInput: '',
        event: "",
        activity: "",
        location: "",
        city: "",
        area: '',
        amenities: [],
        policies: [],
        attendies: '',
        eventType: "",
        plateType : "",
      });

      setInputValues({
        event : '',
        location : '',
        city : '',
        activity : "",
      });
      navigate('/search')
      searchPlaces({
        searchInput: '',
        event: "",
        activity: "",
        location: "",
        city: "",
        area: '',
        amenities: [],
        policies: [],
        attendies: '',
        eventType: "",
        plateType : "",
      }, 1);
    }
    const clearAllFilter = () => {
      setFilterPopup(false)
      setFormData({...formData,area : '',location : '',amenities : [],policies : [],attendies: '',eventType : "", plateType : ""})
      searchPlaces({...formData,area : '',location : '',amenities : [],policies : [],attendies: '',eventType : "", plateType : ""},1)
      setInputValues({...inputValues,location : ''})
    }
    const handleAmenities = (isTrue, value) => {
      if (isTrue) {
        formData.amenities.push(value)
        setFormData({ ...formData })
      } else {
        const newArr = formData?.amenities?.filter(function (item) {
          return item !== value
        })
        setFormData({ ...formData, amenities: newArr })
      }
    }
    const handlePolicies = (isTrue, value) => {
      if (isTrue) {
        formData.policies.push(value)
        setFormData({ ...formData })
      } else {
        const newArr = formData?.policies?.filter(function (item) {
          return item !== value
        })
        setFormData({ ...formData, policies: newArr })
      }
    }
    const handleUrlChange = (data) => {
      let URL = `/search`

      if(data.event){
        URL = `/search/${data.event}`
      }
      if(data.city){
        URL = `/search/${data.event}/${data.city.replaceAll(' ','-').toLowerCase()}`
      }
      navigate(URL,{ state: { formData: data } })
    }
    // useEffect(() => {
    //   window.scrollTo(0, 0);
    //   if(locationtype) {
    //     getRequiredData(locationtype?.value)
    //   }
    //   searchPlaces(formData,page)
    // },[])

    useEffect(() => {
      // const segments = pathname?.split('/');
      // let paramsActivity = ''
      // if(segments?.[4]) {
      //     paramsActivity = {
      //         label : segments?.[4]?.replaceAll('-', ' ').replace(/\b\w/g, char => char.toUpperCase()),
      //         value : segments?.[4]?.replaceAll('-', ' ').replace(/\b\w/g, char => char.toUpperCase()),
      //     }
      // }
      let locationtype = ''
      switch (segments?.[2]) {
        case "film-shoot":
          locationtype =  {value: 'film-shoot', label: 'Film Shootings'}
          break;
        case "tv-shoot":
          locationtype =  {value: 'tv-shoot', label: 'Tv Shootings'}
          break;
        case "event":
          locationtype =  {value: 'event', label: 'Events'}
          break;
        case "staycation":
          locationtype =  { value: 'staycation', label: 'Staycations'}
          break;
        default:
          locationtype = ""
          break;
      }
      let paramsCity =''
      if(segments?.[3]){
          paramsCity = {
              label : segments?.[3]?.replaceAll('-', ' ').replace(/\b\w/g, char => char.toUpperCase()),
              value : segments?.[3]?.replaceAll('-', ' ').replace(/\b\w/g, char => char.toUpperCase()),
          }
      }
      // let paramsLocation = ''
      // if(state?.formData?.location){
      //   paramsLocation = {
      //     label : state?.formData?.location,
      //     value : state?.formData?.location,
      //   }
      // }
      setInputValues({
        ...inputValues,
        event : locationtype,
        city : paramsCity,
        ...(formData?.event !== locationtype?.value && {location : "" } ),
        ...(formData?.event !== locationtype?.value && {activity : "" } ),
        // location : paramsLocation,
        // activity : paramsActivity,
      })
      window.scrollTo(0, 0);
      if(locationtype) {
        getRequiredData(locationtype?.value)
      }
      setFormData({
        ...formData, 
        event: segments?.[2] || '',
        city: segments?.[3] ? segments?.[3]?.replaceAll('-', ' ').replace(/\b\w/g, char => char.toUpperCase()) : '',
        ...(formData?.event !== locationtype?.value && {location : "" } ),
        ...(formData?.event !== locationtype?.value && {activity : "" } ),
      })
      searchPlaces({
        ...formData, 
        city : paramsCity?.value || ""  , 
        event : locationtype?.value || "" ,
        ...(formData?.event !== locationtype?.value && {location : "" } ),
        ...(formData?.event !== locationtype?.value && {activity : "" } ),
      },1)
    }, [pathname] );
    

    const searchContainer = () => {
        return (
            <div className="searchPageSearchCard" >
                <div className="row d-flex flex-row align-items-center"> 
                  <div className="col-md-12 col-lg-10 searchPagePaddingCard d-flex justify-content-center align-items-center" >
                    <input 
                        value = {formData?.searchInput}
                        type = "input" 
                        className="col-12 searchPageSearch"
                        placeholder="Area/Property/Activity"
                        onChange = {(e) =>  setFormData({ ...formData, searchInput: e.target.value },)}
                        onKeyDown={(e)=> {
                          if(e.key === 'Enter'){
                            searchPlaces(formData, 1)
                          }
                        }}
                    />
                    <img style={{ width: "22px",marginLeft:"-25px" }} src={SearchIcon} alt = "searchIcon" />
                  </div>
                  <div className="col-md-12 col-lg-2  searchPagePaddingCard" >
                    <button 
                      type="button" 
                      className="btn btn-primary searchPageButtons w-100"
                      onClick={() => {
                        searchPlaces(formData, 1);
                      }}
                    >
                        Search
                    </button>
                  </div>
                  {/* <div className="col-xs-12 col-sm-6 col-lg-2 searchPagePaddingCard">
                    <select 
                    className="searchCardSort w-100"  // col-lg-6
                    > 
                      <option selected hidden value = "" > 
                          <svg xmlns="http://www.w3.org/2000/svg" width="17" height="10" viewBox="0 0 17 10" fill="none">
                              <path id="storBy" d="M7.18347 0.417977C6.94964 0.416944 6.72496 0.508908 6.55917 0.673798C6.39325 0.838836 6.29995 1.06292 6.2998 1.29673C6.29995 1.53056 6.39325 1.75479 6.55917 1.91967C6.72494 2.08456 6.94962 2.17652 7.18347 2.17549H15.4826C15.7164 2.17652 15.9411 2.08456 16.1069 1.91967C16.2728 1.75478 16.3661 1.53054 16.3662 1.29673C16.3661 1.06291 16.2728 0.838828 16.1069 0.673798C15.9411 0.508908 15.7164 0.416942 15.4826 0.417977H7.18347Z" fill="#555555" />
                              <path id="storBy" d="M7.18347 4.14265C6.94964 4.14162 6.72496 4.23373 6.55917 4.39862C6.39325 4.56351 6.29995 4.78759 6.2998 5.0214C6.29995 5.25523 6.39325 5.47946 6.55917 5.64434C6.72494 5.80923 6.94962 5.90119 7.18347 5.90016H12.993C13.2259 5.90001 13.4494 5.80731 13.6142 5.64256C13.7789 5.47782 13.8716 5.25434 13.8717 5.02137C13.8716 4.78842 13.7789 4.56494 13.6142 4.40017C13.4494 4.23541 13.2259 4.14287 12.993 4.14258L7.18347 4.14265Z" fill="#555555" />
                              <path id="storBy" d="M7.18344 7.86726C6.94873 7.86622 6.72331 7.95893 6.55741 8.12485C6.39151 8.29077 6.29863 8.51617 6.29982 8.75088C6.29996 8.98471 6.39326 9.20894 6.55918 9.37382C6.72496 9.53871 6.94963 9.63067 7.18348 9.62964H10.5031C10.7361 9.62949 10.9596 9.53679 11.1243 9.37204C11.2891 9.2073 11.3816 8.98394 11.3819 8.75085C11.3829 8.51702 11.2908 8.29234 11.1259 8.12655C10.9611 7.96077 10.737 7.86748 10.5032 7.86719L7.18344 7.86726Z" fill="#555555" />
                              <path id="storBy" d="M3.85723 0.369155C3.63521 0.369598 3.42249 0.458317 3.26589 0.615823C3.10927 0.773333 3.02173 0.98664 3.02247 1.20879V8.79518C3.02306 9.01646 3.11119 9.22844 3.26751 9.3849C3.42399 9.54137 3.63611 9.6295 3.85723 9.62994C4.07939 9.63068 4.29269 9.54314 4.4502 9.38667C4.60771 9.23005 4.69642 9.01718 4.69701 8.79518V1.20879C4.69775 0.985736 4.60962 0.771855 4.45196 0.614194C4.29431 0.456534 4.08027 0.36827 3.85723 0.369155Z" fill="#555555" />
                              <path id="storBy" d="M0.777785 5.21716C0.600791 5.37009 0.491554 5.58709 0.474133 5.82048C0.456714 6.05372 0.53259 6.28443 0.68493 6.46204L3.14541 9.32288C3.2985 9.49987 3.51535 9.60896 3.74874 9.62638C3.98212 9.6438 4.21269 9.56792 4.3903 9.41558C4.5673 9.2625 4.67653 9.04565 4.69395 8.81226C4.71137 8.57888 4.6355 8.34831 4.48315 8.1707L2.02267 5.30986C1.86959 5.13287 1.65274 5.02363 1.41935 5.00636C1.18597 4.98894 0.955399 5.06482 0.777785 5.21716Z" fill="#555555" />
                          </svg>
                          Sort by 
                      </option>
                      <option> Price Low to High </option>
                      <option> Price High to Low </option>
                  </select>
                  </div> */}
                </div>
                <div className="row d-flex flex-row align-items-end">
                    <div className="d-none d-lg-block col-lg-3 searchPagePaddingCard">
                        <label className="mx-1"> Seeking locations for </label>
                        <Select
                            className="w-100 searchPageReqInput"
                            id='searchEventSelect'
                            styles={customStyles}
                            options = {options}
                            value={inputValues?.event}
                            placeholder = "Select Event"
                            onChange = {(e) => {
                                setFormData({ ...formData, event: e?.value,eventType : '',plateType : '', location : "" })
                                setInputValues({...inputValues, event:e,location : '',activity : '',city : ''})
                                getRequiredData(e?.value)
                                searchPlaces({...formData,event : e?.value,location :'',activity : '',city : '',eventType : '',plateType : ''}, 1)
                                handleUrlChange({...formData,event : e?.value,location :'',activity : '',city : '',eventType : '',plateType : ''}, 1)
                                setPage(1);
                            }}
                        />
                    </div>
                    {/* <CustomTooltip open= {open}  title={formData?.event ? "" : "Choose location before selecting location type"} >
                      <div 
                        className="d-none d-lg-block col-lg-3 searchPagePaddingCard" 
                        style={{minWidth:"186px"}}
                        onTouchStart={() => setOpen(true)} 
                        onTouchEnd={() => setOpen(false)}
                        onMouseEnter={() => setOpen(true)}
                        onMouseLeave={() => setOpen(false)}
                      >
                          <label className="mx-1"> Property type</label>
                          <Select
                              className="w-100 searchPageReqInput"
                              id='searchlocationType'
                              styles={customStyles}
                              options = {locations}
                              isDisabled = {formData?.event ? false : true }
                              isClearable={true}
                              value={inputValues?.location}
                              placeholder = "Choose property type..."
                              onChange = {(e) =>  {
                                setFormData({ ...formData, location: e?.value });
                                setInputValues({...inputValues, location:e})
                                searchPlaces({...formData,location : e?.value}, 1)
                              }}
                          />
                      </div>
                    </CustomTooltip>
                    <CustomTooltip open= {openActivity}  title={formData?.event ? "" : "Choose location before selecting activities"} >
                      <div 
                        className="d-none d-lg-block col-lg-3 searchPagePaddingCard" 
                        style={{minWidth:"186px"}}
                        onTouchStart={() => setOpenActivity(true)} 
                        onTouchEnd={() => setOpenActivity(false)}
                        onMouseEnter={() => setOpenActivity(true)}
                        onMouseLeave={() => setOpenActivity(false)}
                      >
                        <label className="mx-1"> Activity</label>
                        <Select
                            className="w-100 searchPageReqInput"
                            id='searchActivity'
                            styles={customStyles}
                            options = {activites}
                            isDisabled = {formData?.event ? false : true }
                            isClearable={true}
                            value={inputValues?.activity}
                            placeholder = "Choose activity..."
                            onChange = {(e) =>  {
                              setFormData({ ...formData, activity: e?.value},)
                              setInputValues({...inputValues, activity:e})
                              searchPlaces({...formData,activity : e?.value}, 1)
                            }}
                        />
                    </div>
                    </CustomTooltip> */}
                    <CustomTooltip open= {openCity}  title={formData?.event ? "" : "Choose location before selecting city"} >
                    <div
                      className="d-none d-lg-block col-lg-3 searchPagePaddingCard"
                      onTouchStart={() => setOpenCity(true)} 
                      onTouchEnd={() => setOpenCity(false)}
                      onMouseEnter={() => setOpenCity(true)}
                      onMouseLeave={() => setOpenCity(false)}
                    >
                        <label className="mx-1"> City </label>
                        <Select
                            className="w-100 searchPageReqInput"
                            id='searchCity'
                            styles={customStyles}
                            options = {cities}
                            value={inputValues?.city}
                            isDisabled = {formData?.event ? false : true }
                            isClearable={true}
                            placeholder = "Choose city..."
                            onChange = {(e) =>  {
                              setFormData({ ...formData, city: e?.value},)
                              setInputValues({...inputValues, city:e})
                              searchPlaces({...formData,city : e?.value}, 1)
                              handleUrlChange({...formData,city : e?.value}, 1)

                            }}
                        />
                  </div>
                  </CustomTooltip>
                    <div className="col-md-12 col-lg-3 searchPagePaddingCard" >
                      <button 
                          type="button" 
                          className=" searchCardFilter w-100" // col-lg-6
                          onClick={openFilterPopup}
                      > 
                          <svg className="mx-2" xmlns="http://www.w3.org/2000/svg" width="12" height="16" viewBox="0 0 12 16" fill="none">
                              <path d="M11.2483 0.618164L7.20589 8.17569V15.3817L4.21806 13.8V8.17575L-8.96454e-05 0.618227L11.2483 0.618164Z" fill="#555555" />
                          </svg>
                          Filters
                      </button>
                    </div  >
                    <div className="col-md-12 col-lg-3  searchPagePaddingCard">
                      <button 
                        type="button" 
                        className="btn btn-secondary searchPageButtons w-100"
                        onClick={clearAll}
                      >
                          Clear All
                      </button>
                    </div>
                  </div>
                {/* <div className="row">      
                  <div className="col-md-4  searchPagePaddingCard">
                    <Box className="showMapBtn w-100">
                      <Typography mr={2}>Show Map</Typography>

                      <Box
                        onClick={() => setMapSwitch(!mapSwitch)}
                        sx={{
                          justifyContent: mapSwitch ? "end" : "start",
                          backgroundColor: mapSwitch ? " #65c466" : "#d8d8d8"
                        }}
                        className="mpaToggleSwitch">
                        <Box className="mpaToggleSwitchWhiteBtn"></Box>
                      </Box>
                    </Box>
                  </div>
                </div> */}
            </div>
        )
    }

     // row className="col-md-6 col-lg-4 col-xl-3"
    const listingsContainer = () => {
      return(
        <div className="searchListingsContainer " >
          {
            listings?.map((eachListing) =>  {
              return (
                <div key = {eachListing._id} > 
                  <PropertyInfo key={eachListing._id} property={eachListing} eventType = {formData?.event} eventSub={formData?.eventType} plateType={formData?.plateType}/>
                </div>
              )
            } )
          }
        </div>
      )
    }
    // On pagination change
    const onPageChange = (event, page) => {
      window.scrollTo(0, 0);
      searchPlaces(formData, page <= 0 ? 1 : page);
      setPage(page <= 0 ? 1 : page);
    }

    const  PaginationRounded = () => {
      return (
        <Stack className="mt-2 mb-3" spacing={2}>
          <Pagination onChange={(event, page) => onPageChange(event, page)} count={totalCount} variant="outlined" shape="rounded" page = {page} />
        </Stack>
      );
    }

    return(
        <>
            < Header/>
            <Constants/>
            <div className="searchPageBgContainer" >
                {searchContainer()}
                {(featuredListings?.length > 0 &&  listings?.length > 0) && (
                  <>
                    <div className="searchPageListingsMapContainer">
                      <div className="searchListingsAndPaginationContainer">
                        <div className="searchListingsContainer">
                          <SearchFeatured data={featuredListings}/>
                        </div>
                      </div>
                    </div>
                    <hr style={{width : '80%'}}/>
                  </>
                  )}
                { listings.length > 0 &&
                  <div className="searchPageListingsMapContainer"> 
                    <div className={`searchListingsAndPaginationContainer ${mapSwitch ? "searchListingsAndPaginationContainerHalf" : "searchListingsAndPaginationContainer"}`} >
                      {listingsContainer()}
                      <div style={{alignSelf : "center"}}>
                        {totalCount > 0 && PaginationRounded()}
                      </div>
                    </div>
                      {listings?.length > 0 && mapSwitch &&   (
                          <Box className="property_map_box_new">
                            <GoogleMap properties = {listings} />
                          </Box>
                        )}
                  </div>
                }
                {(locationsDataFetched &&  listings?.length === 0) && (
                  <>
                  {formData?.event?.length === 0 ? (
                    <div className="searchPageListingsMapContainer d-flex flex-column justify-content-center align-items-center"> 
                      <h1 className="SpotLetMainHead text-center" > Event Selection Needed </h1>
                      <span className="SpotLetContent">It looks like you haven't selected a specific event yet.</span>
                      <span className="SpotLetContent">Selecting an event will help us provide the best possible results tailored to your needs. Please choose an event to proceed.</span>
                      {/* <span className="SpotLetContent">Before you start searching, please make sure to choose your event.</span>
                      <span className="SpotLetContent">It’s like picking your movie before you grab the popcorn. Enjoy your search!</span> */}
                      <img alt="no results found" src={selectEvent} style={{width : '100%',height : 'auto',maxHeight : '400px', maxWidth : '400px'}}/>
                    </div>
                  ) : (
                    <div className="searchPageListingsMapContainer d-flex flex-column justify-content-center align-items-center"> 
                      <h1 className="SpotLetMainHead text-center" > No Locations Found </h1>
                      <span className="SpotLetContent">It seems we couldn't find any locations matching your search criteria.</span>
                      <span className="SpotLetContent">Don't worry, you can try adjusting your search terms and explore our locations.</span>
                      <img alt="no results found" src={NoResults} style={{width : '100%',height : 'auto',maxHeight : '400px', maxWidth : '400px'}}/>
                    </div>
                  )}
                  </>
                )}
            </div>
            < Footer/>
            {/* {filterPopup && <SearchFilter 
                show={filterPopup}
                onHide={() => setFilterPopup(false)}
                apply = {() => {
                    setFilterPopup(false);
                    searchPlaces(formData, 1);
                }}
                clearAll = {() => {
                  setFilterPopup(alse)
                  setFormData({...formData,area : '',amenities : []})
                  searchPlaces({...formData,area : '',amenities : []},1)
                }}
                formData = {formData}
                setFormData = {setFormData}
                inputValues={inputValues}
                setInputValues = {setInputValues}
                cities = {cities}
              />} */}
              {filterPopup && (
                <Modal
                show={filterPopup}
                size="md"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                onHide={() => setFilterPopup(false)}
              >
                <Modal.Header closeButton>
                  <Modal.Title id="contained-modal-title-vcenter">
                    Filters
                  </Modal.Title>
                </Modal.Header>
                <Modal.Body style={{paddingTop : '0px'}}>
                  <div className='container' style={{height :'500px', overflowY : 'auto'}}>
                    <div className='row'>
                      <div className='col-lg-12'>
                        <div className="row">
                          <div className="d-lg-none col-lg-12 searchPagePaddingCard">
                              <label className="mx-1"> Seeking locations for </label>
                              <Select
                                  className="w-100 searchPageReqInput"
                                  id='searchEventSelect'
                                  styles={customStyles}
                                  options = {options}
                                  value={inputValues?.event}
                                  placeholder = "Select here"
                                  
                                  onChange = {(e) => {
                                      setFormData({ ...formData, event: e?.value,eventType : '',plateType : '' })
                                      setInputValues({...inputValues, event:e,location : '',activity : ''})
                                      getRequiredData(e?.value)
                                      searchPlaces({...formData,event : e?.value,location :'',activity : '',eventType : '',plateType : ''}, 1);
                                      setPage(1);
                                  }}
                              />
                          </div>
                          <CustomTooltip open= {openFilter}  title={formData?.event ? "" : "Choose location before selecting location type"} >
                            <div 
                              className="col-lg-12 searchPagePaddingCard" 
                              style={{minWidth:"186px"}}
                              onTouchStart={() => setOpenFilter(true)} 
                              onTouchEnd={() => setOpenFilter(false)}
                              onMouseEnter={() => setOpenFilter(true)}
                              onMouseLeave={() => setOpenFilter(false)}
                            >
                                <label className="mx-1"> Property type</label>
                                <Select
                                    className="w-100 searchPageReqInput"
                                    id='searchlocationType'
                                    styles={customStyles}
                                    options = {locations}
                                    isDisabled = {formData?.event ? false : true }
                                    isClearable={true}
                                    value={inputValues?.location}
                                    placeholder = "Choose property type..."
                                    onChange = {(e) =>  {
                                      setFormData({ ...formData, location: e?.value });
                                      setInputValues({...inputValues, location:e})
                                      searchPlaces({...formData,location : e?.value}, 1);
                                      setPage(1);
                                    }}
                                />
                            </div>
                          </CustomTooltip>
                          <CustomTooltip open= {openActivityFilter}  title={formData?.event ? "" : "Choose location before selecting activities"} >
                            <div 
                              className="col-lg-12 searchPagePaddingCard" 
                              style={{minWidth:"186px"}}
                              onTouchStart={() => setOpenActivityFilter(true)} 
                              onTouchEnd={() => setOpenActivityFilter(false)}
                              onMouseEnter={() => setOpenActivityFilter(true)}
                              onMouseLeave={() => setOpenActivityFilter(false)}
                            >
                              <label className="mx-1"> Activity</label>
                              <Select
                                  className="w-100 searchPageReqInput"
                                  id='searchActivity'
                                  styles={customStyles}
                                  options = {activites}
                                  isDisabled = {formData?.event ? false : true }
                                  isClearable={true}
                                  value={inputValues?.activity}
                                  placeholder = "Choose activity..."
                                  onChange = {(e) =>  {
                                    setFormData({ ...formData, activity: e?.value},)
                                    setInputValues({...inputValues, activity:e})
                                    searchPlaces({...formData,activity : e?.value}, 1);
                                    setPage(1);
                                  }}
                              />
                          </div>
                          </CustomTooltip>
                          <CustomTooltip open= {openCityFilter}  title={formData?.event ? "" : "Choose location before selecting city"} > 
                          <div
                            className="d-lg-none col-lg-12 searchPagePaddingCard"
                            onTouchStart={() => setOpenCityFilter(true)} 
                            onTouchEnd={() => setOpenCityFilter(false)}
                            onMouseEnter={() => setOpenCityFilter(true)}
                            onMouseLeave={() => setOpenCityFilter(false)}
                          >
                              <label className="mx-1"> City </label>
                              <Select
                                  className="w-100 searchPageReqInput"
                                  id='searchCity'
                                  styles={customStyles}
                                  options = {cities}
                                  value={inputValues?.city}
                                  isDisabled = {formData?.event ? false : true }
                                  isClearable={true}
                                  placeholder = "Choose city..."
                                  onChange = {(e) =>  {
                                    setFormData({ ...formData, city: e?.value},)
                                    setInputValues({...inputValues, city:e})
                                    searchPlaces({...formData,city : e?.value}, 1);
                                    setPage(1);
                                  }}
                              />
                        </div>
                          </CustomTooltip>
                          <div
                            className="col-lg-12 searchPagePaddingCard"
                          >
                            <label className="mx-1"> Area </label>
                            <input 
                              value = {formData?.area}
                              type = "input" 
                              className="col-12 searchPageSearch"
                              placeholder="Area"
                              onChange = {(e) =>  setFormData({ ...formData, area: e.target.value },)}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-12">
                        <p className="SpotLetSubHead font-weight-bold">Attendees</p>
                        <div className="container">
                          <div className="row">
                            {ATTENDIES?.map((item) => {
                              return(
                                <div className="form-check col-lg-6 mb-3">
                                  <input className="form-check-input" 
                                  id={`filter${item.value}`}
                                  checked={formData?.attendies === item.value}
                                   type="radio" 
                                   onChange={(e) => {
                                    setFormData({...formData,attendies : item.value})
                                  }}
                                    />
                                  <label className="form-check-label SpotLetContent" htmlFor={`filter${item.value}`}>{item?.label}</label>
                                </div>
                              )
                            })}
                          </div>  
                        </div>
                      </div>
                      { formData?.event === "event" && (
                        <div className="col-lg-12">
                          <p className="SpotLetSubHead font-weight-bold">Venue Type</p>
                            <div className="container">
                              <div className="row">
                                    {VENUE_TYPES?.map((item) => {
                                      return(
                                        <div className="form-check col-lg-6 mb-3">
                                          <input className="form-check-input" 
                                            id={`filter${item.value}`}
                                            checked={formData?.eventType === item.value}
                                            type="radio" 
                                            onChange={(e) => {
                                              setFormData({...formData,eventType : item.value, plateType : ""})
                                              searchPlaces({...formData,eventType : item.value, plateType : ""}, 1);
                                              setPage(1);
                                            }}
                                            />
                                          <label className="form-check-label SpotLetContent" htmlFor={`filter${item.value}`}>{item?.label}</label>
                                        </div>
                                      )
                                    })}
                              </div>
                          </div>
                        </div>
                        )}
                      { (formData?.event === "event" && formData?.eventType === "perPlate") &&  (
                        <div className="col-lg-12">
                          <p className="SpotLetSubHead font-weight-bold">Food Type</p>
                            <div className="container">
                              <div className="row">
                                    {VENUE_FOOD_TYPES?.map((item) => {
                                      return(
                                        <div className="form-check col-lg-6 mb-3">
                                          <input className="form-check-input" 
                                            id={`filter${item.value}`}
                                            checked={formData?.plateType === item.value}
                                            type="radio" 
                                            onChange={(e) => {
                                              setFormData({...formData,plateType : item.value})
                                              searchPlaces({...formData,plateType : item.value}, 1);
                                              setPage(1);
                                            }}
                                            />
                                          <label className="form-check-label SpotLetContent" htmlFor={`filter${item.value}`}>{item?.label}</label>
                                        </div>
                                      )
                                    })}
                              </div>
                          </div>
                        </div>
                        )}
                      <div className="col-lg-12">
                        <p className="SpotLetSubHead font-weight-bold">Popular Types</p>
                        <div className="container">
                          <div className="row">
                            {POPULAR_TYPES?.map((item) => {
                              return(
                                <div className="form-check col-lg-6 mb-3">
                                  <input className="form-check-input" 
                                  id={`filter${item.value}`}
                                  checked={formData?.location === item.value}
                                   type="checkbox" 
                                   onChange={(e) => {
                                    setInputValues({...inputValues, location:item})
                                    setFormData({...formData,location : item.value})
                                  }}
                                    />
                                  <label className="form-check-label SpotLetContent" htmlFor={`filter${item.value}`}>{item?.label}</label>
                                </div>
                              )
                            })}
                          </div>  
                        </div>
                      </div>
                      <div className="col-lg-12">
                        <p className="SpotLetSubHead font-weight-bold">Outdoor Amenities</p>
                        <div className="container">
                          <div className="row">
                            {OUTDOOR_AMENITIES?.map((item) => {
                              return(
                                <div className="form-check col-lg-6 mb-3">
                                  <input className="form-check-input" id={`filter${item.value}`} checked={formData?.amenities?.includes(item.value)} type="checkbox" onChange={(e) => handleAmenities(e.target.checked, item.value)}/>
                                  <label className="form-check-label SpotLetContent" htmlFor={`filter${item.value}`}>{item?.label}</label>
                                </div>
                              )
                            })}
                          </div>  
                        </div>
                      </div>
                      <div className="col-lg-12">
                        <p className="SpotLetSubHead font-weight-bold">Indoor Amenities</p>
                        <div className="container">
                          <div className="row">
                            {INDOOR_AMENITIES?.map((item) => {
                              return(
                                <div className="form-check col-lg-6 mb-3">
                                  <input className="form-check-input" id={`filter${item.value}`} checked={formData?.amenities?.includes(item.value)} type="checkbox" onChange={(e) => handleAmenities(e.target.checked, item.value)}/>
                                  <label className="form-check-label SpotLetContent" htmlFor={`filter${item.value}`}>{item?.label}</label>
                                </div>
                              )
                            })}
                          </div>  
                        </div>
                      </div>
                      <div className="col-lg-12">
                        <p className="SpotLetSubHead font-weight-bold">Location Allows</p>
                        <div className="container">
                          <div className="row">
                            {POLICIES?.map((item) => {
                              return(
                                <div className="form-check col-lg-6 mb-3">
                                  <input className="form-check-input" id={`filter${item.value}`} checked={formData?.policies?.includes(item.value)} type="checkbox" onChange={(e) => handlePolicies(e.target.checked, item.value)}/>
                                  <label className="form-check-label SpotLetContent" htmlFor={`filter${item.value}`}>{item?.label}</label>
                                </div>
                              )
                            })}
                          </div>  
                        </div>
                      </div>
                    </div>
                  </div>
                </Modal.Body>
                <Modal.Footer>
                  <Button variant="secondary" onClick={clearAllFilter}>Clear All</Button>
                  <Button onClick={()=> {
                    setFilterPopup(false);
                    searchPlaces(formData, 1);
                    setPage(1);
                  }}
                  >Apply</Button>
                </Modal.Footer>
              </Modal>
              )}
              {loader && <PageLoader/>}
        </>

    )
}

export default SearchPage
