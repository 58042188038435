import { useEffect, useState } from "react";
import { SubpropertyBookingRequestModal } from '../Modals/SubpropertyBookingRequestModal';
import { SubPropertyViewModal } from '../Modals/SubProperty/SubPropertyViewModal';
import EventRequestBookingTile from "./EventRequestBookingTile";
import Pagination from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';


// Images
import area from "../assets/images/area.svg";
import parking from "../assets/images/parking.svg";
import valetParking from "../assets/images/valetParking.svg";
import streetParking from "../assets/images/streetParking.svg";
import ccTv from "../assets/images/ccTv.svg";
import telegram from "../assets/images/telegram.svg";


const SubProperties = ({data, locationId, owner_id, approveEnquiries}) => {
    const [showBookingModal, setShowBookingModal] = useState(false);
    const [showSubPropertyModal, setShowSubPropertyModal ] = useState(false);
    const [subPropertyData, setSubPropertyData] = useState({});
    const [pricing, setPricing] = useState({});
    const [subPropertyName, setSubPropertyName] = useState("");
    const [pageCount, setPageCount] = useState(0)
    const [currentPage, setCurrentPage] = useState(1);


    const onClickRequest = (pricingData, name) => {
        setShowBookingModal(true);
        setSubPropertyName(name)
        setPricing(pricingData);
    }

    const onClickViewMoreDetails = (data) => {
        setShowSubPropertyModal(true);
        setSubPropertyData(data);
    }

    const subPropertyCard = (each) => {
        const propertyName = each?.basic_info?.property_name ; 
        const pricing = each?.pricing
        const image = each?.images?.[propertyName][0]?.image ;
        return(
            <div className="subPropertyContainer row" >
                <div className="subPropertyImageContainer  col-lg-4" >
                    <img src = {image} alt = "sub property image"  className="subPropertyImage"  />
                </div>
                <div className="subPropertyMiddleSection col-lg-4" >
                    <div className="subPropertyDetailsContainer">
                        <h3 className="subPropertyName SpotLetSubHead" > {each?.location_type} : {propertyName} </h3>
                    </div>
                    {each?.basic_info?.description &&
                    <div className="mb-2" >
                         <span className="subPropertyDescription SpotLetContent " > {each?.basic_info?.description?.slice(0,200)}{each?.basic_info?.description?.length > 250 ? "...." : ""} </span>
                    </div>
                    }
                    <div className='subPropertyDetailsContainer'>
                        <div className='subPropertyVectorImage'>
                            <img src={area} alt='area'/>
                        </div>
                        <span className="subPropertyDescription SpotLetContent ">{each?.basic_info?.property_size} {each?.basic_info?.area} </span>
                    </div>
                    <div className='subPropertyDetailsContainer'>
                        <div className='subPropertyVectorImage'>
                            <img src={streetParking} alt='area'/>
                        </div>
                        <span  className="subPropertyDescription SpotLetContent ">Street Parking Facility {each?.basic_info?.street_parking ? "Available" : 'Not Available'} </span>
                    </div>
                    <div className='subPropertyDetailsContainer'>
                        <div className='subPropertyVectorImage'>
                            <img src={valetParking} alt='area'/>
                        </div>
                        <span  className="subPropertyDescription SpotLetContent ">Valet Parking {each?.basic_info?.valet_parking ? "Available" : 'Not Available'}</span>
                    </div>
                    <div className='subPropertyDetailsContainer'>   
                        <div className='subPropertyVectorImage'>
                            <img src={ccTv} alt='area'/>
                        </div>
                        <span className="subPropertyDescription SpotLetContent ">Security Camera {each?.basic_info?.security_camera ? "Available" : 'Not Available'}</span>
                    </div>
                    <div className='subPropertyDetailsContainer'>
                        <div className='subPropertyVectorImage'>
                            <img src={parking} alt='area'/>
                        </div>
                        <span className="subPropertyDescription SpotLetContent ">In-House Parking Facility {each?.basic_info?.house_parking ? `Available (${each?.basic_info?.no_of_cars})` : 'Not Available'}</span>
                    </div>
                </div>
                <div className="subPropertyEndSection col-lg-4" >
                    {/* <div className="subPropertyPricingCard" >
                        <span>   </span>
                    </div> */}
                    {/* <div className="subPropertyRequestBookingCard cursor" onClick={ () =>  onClickRequest(pricing, each?.basic_info?.property_name) }  >
                        <span className="subPropertyParaEl SpotLetContent"  > Request for Pricing <span> <img className="mx-1 mb-1" alt = "telegram" src = {telegram} /> </span> </span>
                    </div> */}
                    <a style={{color:"#EA4335", textDecoration:"underline"}}  className="cursor SpotLetContent" onClick={ () =>  onClickViewMoreDetails(each) } > View More Details </a>
                </div>
            </div>
        )
    }

    const onPageChange = (event, value) => {
        setPageCount((value - currentPage) * 1);
    }

    return (
        <>
        <div className="subPropertiesMainContainer" >
            <span className="galleryHeading text-center SpotLetMainHead" id="propertyPage8">Sub Properties</span>
            {
                data?.slice(pageCount, pageCount +1)?.map((each) => subPropertyCard(each) )
            }
            { (data?.length) > 1 &&
                <Stack spacing={2} className="subPropertiesPagination">
                <Pagination count={data?.length} shape="rounded"  onChange = { onPageChange } />
            </Stack>
            }

        </div>  
        
        {showBookingModal && (
            <SubpropertyBookingRequestModal
                show={showBookingModal}
                handleClose={() => setShowBookingModal(false)}
                title="Booking Request"
                body={
                    <EventRequestBookingTile subPropertyName = {subPropertyName} owner_id ={owner_id} locationId = {locationId} data={pricing}  approveEnquiries = {approveEnquiries}   setInformation={(value) => { 
                        setShowBookingModal(false)
                        alert(value)
                       
                    } } /> // setInformation={setInformation}
                 }
            />
        )
        }

        {showSubPropertyModal && 
            <SubPropertyViewModal
            show={showSubPropertyModal}
            title = {subPropertyData?.basic_info?.property_name}
            onHide={() => setShowSubPropertyModal(false)}
            data = {subPropertyData}
          />
        }
        </>
    )
}

export default SubProperties