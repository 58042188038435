

import album from "../assets/images/album.svg";
import LightGallery from 'lightgallery/react';

// import styles
import 'lightgallery/css/lightgallery.css';
// import 'lightgallery/css/lg-zoom.css';
import 'lightgallery/css/lg-thumbnail.css';

// import plugins if you need
import lgThumbnail from 'lightgallery/plugins/thumbnail';
import PropertyVideos from "./PropertyVideos";
//import lgZoom from 'lightgallery/plugins/zoom';

const Gallery = ({locationId,data, videosData, subscription}) => {
    return(
        <div className="galleryMainContainer" >
            <span className="galleryHeading SpotLetMainHead" id='propertyPage4'>Gallery</span>
            <div className="gallerySection">
                {/* <span className="gallerySectionHeading SpotLetSubHead ">{locationId}</span> */}
                <LightGallery
                    speed={500}
                    download={false}    
                    plugins={[lgThumbnail, ]}  //lgZoom
                    elementClassNames="galleryEachCardSection"
                >
                    {data?.[locationId]?.map((item,i)=> {
                        return(
                            <a href={item?.image}>
                                <div key={i}  className="galleryImageContainer" style={{position : 'relative',display : i > 3 ? 'none' : 'flex'}}>
                                    
                                        <img src={item?.image} alt={item?.title || item?.name} className="galleryImage"/>
                                        
                                    {i===3 && (
                                        <div className="galleyShowAllContainer cursor" >
                                             {/* onClick={() => handleShowGallery(data?.[locationId])} */}
                                            <img src={album} alt='album' />
                                            <span className="SpotLetContent">{`Show All(${data?.[locationId]?.length})`}</span>
                                        </div>
                                    )}
                                    <span className="galleryImageTitle SpotLetContent">{item?.title}</span>
                                </div>
                            </a>
                        )
                    })} 
                </LightGallery>
                {data && Object.keys(data)?.map((images) => {
                    if(images === locationId){
                        return null
                    }else{
                        return(
                            <>
                                <span className="gallerySectionHeading SpotLetSubHead">{images}</span>
                                <LightGallery
                                    speed={500}
                                    download={false}    
                                    plugins={[lgThumbnail, ]}  //lgZoom
                                    elementClassNames="galleryEachCardSection"
                                >
                                    {data?.[images]?.slice(0,4).map((item,i)=> {
                                        return(
                                            <a href={item?.image} >
                                                <div key={i}  className="galleryImageContainer">
                                                    <img src={item?.image} alt={item?.name} className="galleryImage"/>
                                                    <span className="galleryImageTitle SpotLetContent">{item?.title}</span>
                                                </div>
                                            </a>
                                        )
                                    })} 
                                </LightGallery>
                                </>
                        )
                    }

                })}
            {/*Videos start */}
            {/* { (subscription !== 'Basic' && videosData?.length > 0) && <PropertyVideos locationId={locationId} data={videosData} /> } */}
             {/* Videos end  */}
            </div>
        </div>
    )
};
export default Gallery;