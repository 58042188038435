import { Header } from "../components/NewHeader/Header";
import { Sidebar } from "../components/Sidebar";
import { UserEnquiriesTable } from "../components/UserEnquiriesTable";
import { useAuthStore } from "../store/auth";


export const UserEnquiriesList = () => {

 const {userProfile} = useAuthStore();

  return (
    <>
      <Header />
      <div className="container-fluid p-0">
        <div className="row g-0">
          <div className="d-flex edit-profile my-list">
            <div className="col-lg-2 d-none d-lg-block w-200">
              <Sidebar />
            </div>
            <div className="col-lg-10  staticPageHtml">
              <form className="h-100 needs-validation" noValidate>
                <div className="step-form h-100">
                  <div className="e-profile">
                    <h3 className="etitle mb-2"> My Enquiries </h3>
                    <div className="creative-space m-0">
                      <div class="tab-content" id="pills-tabContent">
                        <div class="tab-pane fade active show" id="pills-booking" role="tabpanel" aria-labelledby="pills-booking-tab">
                          <UserEnquiriesTable userId = {userProfile?._id} />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
