import { Button, Modal } from "react-bootstrap";


const { Header, Footer, Title, Body } = Modal;
export function ImageView({
  handleClose,
  show,
  imageData,
}) {

  return (
    <Modal show={show} onHide={handleClose}>
      <Header closeButton>
        <Title>{imageData?.name}</Title>
      </Header>
      <Body className="verify-email-modal-body">
        <img src={imageData?.image}
          style={{ width: "100%", height: "100%" }}
        />
      </Body>
      <Footer>
        <Button variant="secondary" onClick={handleClose}>
          OK
        </Button>

      </Footer>
    </Modal>
  );
}
