import { useState,useEffect } from "react";
import { useLocation, useNavigate } from 'react-router-dom';
import { Header } from "../../components/NewHeader/Header";
import { Sidebar, Menu, MenuItem, SubMenu } from 'react-pro-sidebar';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { SlCalender } from "react-icons/sl";
import { LuLayoutDashboard } from "react-icons/lu";
import { MdOutlineEmojiEvents  } from "react-icons/md";
import { MdOutlineManageHistory } from "react-icons/md";
import { RiCoupon2Line } from "react-icons/ri";
import { TbReportSearch } from "react-icons/tb";
import { MdOutlineGeneratingTokens } from "react-icons/md";
import { BsBuildingGear } from "react-icons/bs";
import { IoIosReturnRight } from "react-icons/io";
import { MdOutlineSupportAgent } from "react-icons/md";
import { IoPricetagsOutline   } from "react-icons/io5";
import "./index.css"
//Components
import SubDashboard from "./Components/dashboard";
import Property from "./Components/property";
import CommingSoon from "./Components/commingSoon";
import ReviewRatings from "./Components/Review&Rating";
import RecentEvents from "./Components/recentEvents";
import Leads from "./Components/leads";
import Support from "./Components/support";
import Calendar from "./Components/calendar";
// import Tags from "./Components/tags";

const Dashboard = () => {
    let location = useLocation();
    const [toggled,setToggled] = useState(false)
    const [activeTab,setActiveTab] = useState(0)
    const [subActive,isSubActive] = useState(false)
    const [activeSub,setActiveSub] = useState(0)
    const [subLeadActive,isSubLeadActive] = useState(false)
    const [activeLeadSub,setActiveLeadSub] = useState(0)
    const [activeLeadSection, setActiveLeadSection] = useState("Contact Host")
    const handleSubActive = (value) => {
        isSubActive(value)
    }
   

    const handleItemClick = (value) => {
        setActiveTab(value)
        if(toggled) setToggled(false)
    }
    const handleSubClick = (value) => {
        setActiveTab(1)
        setActiveSub(value)
        if(toggled) setToggled(false)
    }

    const handleSubLeadClick = (value, name) => {
        setActiveTab(2)
        setActiveLeadSub(value)
        setActiveLeadSection(name)
        if(toggled) setToggled(false)
    }

    return(
        <>
            <Header />
            <div className="dashboardFullContainer">
                <div className="sidebarOpenArrow h-100" onClick={()=> setToggled(!toggled)}>
                    <ArrowForwardIosIcon />
                </div>
                <div className="dashboardRightContainer h-100">
                    <div className="dashboardSidebar h-100">
                        <Sidebar
                            toggled ={toggled}
                            onBackdropClick = {()=>{setToggled(!toggled)}}
                            breakPoint = 'md'
                            backgroundColor ='#ffffff'
                            rootStyles={{
                                height : '100%',
                                overflowY : 'auto',
                                fontWeight : '400'
                            }}
                            width="300px"
                        >
                            <Menu closeOnClick={true}>
                                <MenuItem rootStyles={{background : activeTab === 0 ? '#ecf2f3' : ''}} icon={<LuLayoutDashboard />} onClick={()=> handleItemClick(0)}> Dashboard </MenuItem>
                                <SubMenu
                                    open = { activeTab === 1 }
                                    label="Property"
                                    icon={<BsBuildingGear/>}
                                    onClick={()=> {
                                        if (activeTab !== 1) {
                                            setActiveTab(1)
                                        }
                                        else{
                                            setActiveTab(0)
                                        }
                                        // setActiveSub(0)
                                    }}
                                    rootStyles={{background : activeTab === 1 ? '#ecf2f3' : ''}}
                                    >
                                    <MenuItem rootStyles={{fontWeight : '300',background : activeSub === 0 ? '#ecf2f3' : ''}} icon={<IoIosReturnRight />} onClick={()=> handleSubClick(0)}> Type of Property</MenuItem>
                                    <MenuItem rootStyles={{fontWeight : '300',background : activeSub === 1 ? '#ecf2f3' : ''}} icon={<IoIosReturnRight />} onClick={()=> handleSubClick(1)}> Basic Information </MenuItem>
                                    <MenuItem rootStyles={{fontWeight : '300',background : activeSub === 2 ? '#ecf2f3' : ''}} icon={<IoIosReturnRight />} onClick={()=> handleSubClick(2)}> Location </MenuItem>
                                    <MenuItem rootStyles={{fontWeight : '300',background : activeSub === 3 ? '#ecf2f3' : ''}} icon={<IoIosReturnRight />} onClick={()=> handleSubClick(3)}> All Amenities </MenuItem>
                                    <MenuItem rootStyles={{fontWeight : '300',background : activeSub === 4 ? '#ecf2f3' : ''}} icon={<IoIosReturnRight />} onClick={()=> handleSubClick(4)}> Rooms/Spaces </MenuItem>
                                    <MenuItem rootStyles={{fontWeight : '300',background : activeSub === 5 ? '#ecf2f3' : ''}} icon={<IoIosReturnRight />} onClick={()=> handleSubClick(5)}> Pricing & Timings </MenuItem>
                                    <MenuItem rootStyles={{fontWeight : '300',background : activeSub === 6 ? '#ecf2f3' : ''}} icon={<IoIosReturnRight />} onClick={()=> handleSubClick(6)}> Gallery </MenuItem>
                                    <MenuItem rootStyles={{fontWeight : '300',background : activeSub === 7 ? '#ecf2f3' : ''}} icon={<IoIosReturnRight />} onClick={()=> handleSubClick(7)}> Policies </MenuItem>
                                    <MenuItem rootStyles={{fontWeight : '300',background : activeSub === 8 ? '#ecf2f3' : ''}} icon={<IoIosReturnRight />} onClick={()=> handleSubClick(8)}> Activities </MenuItem>
                                    <MenuItem rootStyles={{fontWeight : '300',background : activeSub === 9 ? '#ecf2f3' : ''}} icon={<IoIosReturnRight />} onClick={()=> handleSubClick(9)}> Property Manager Details </MenuItem>
                                    {subActive && <MenuItem rootStyles={{fontWeight : '300',background : activeSub === 8 ? '#ecf2f3' : ''}} icon={<IoIosReturnRight />} onClick={()=> handleSubClick(10)}> Sub Property </MenuItem>}
                                </SubMenu>
                                <SubMenu
                                    open = { activeTab === 2 }
                                    label="Lead management"
                                    icon={<BsBuildingGear/>}
                                    onClick={()=> {
                                        if (activeTab !== 2) {
                                            setActiveTab(2)
                                        }
                                        else{
                                            setActiveTab(0)
                                        }
                                        // setActiveSub(0)
                                    }}
                                    rootStyles={{background : activeTab === 1 ? '#ecf2f3' : ''}}
                                    >
                                        <MenuItem rootStyles={{fontWeight : '300',background : activeLeadSub === 0 ? '#ecf2f3' : ''}} icon={<IoIosReturnRight />} onClick={()=> handleSubLeadClick(0, "Contact Host")}> Contact User</MenuItem>
                                        <MenuItem rootStyles={{fontWeight : '300',background : activeLeadSub === 1 ? '#ecf2f3' : ''}} icon={<IoIosReturnRight />} onClick={()=> handleSubLeadClick(1, "Get In Touch")}> Get In Touch </MenuItem>
                                        <MenuItem rootStyles={{fontWeight : '300',background : activeLeadSub === 2 ? '#ecf2f3' : ''}} icon={<IoIosReturnRight />} onClick={()=> handleSubLeadClick(2, "Booking Request")}> Booking Request </MenuItem>
                                        <MenuItem rootStyles={{fontWeight : '300',background : activeLeadSub === 3 ? '#ecf2f3' : ''}} icon={<IoIosReturnRight />} onClick={()=> handleSubLeadClick(3, "Schedule Property Visit")}> Schedule Property Visit </MenuItem>
                                </SubMenu>
                                {/* <MenuItem rootStyles={{background : activeTab === 2 ? '#ecf2f3' : ''}} icon={<MdOutlineManageHistory />} onClick={()=> handleItemClick(2)}> Lead Management</MenuItem> */}
                                <MenuItem rootStyles={{background : activeTab === 3 ? '#ecf2f3' : ''}} icon={<MdOutlineEmojiEvents  />} onClick={()=> handleItemClick(3)}> Recent Events </MenuItem>
                                {/* <MenuItem rootStyles={{background : activeTab === 4 ? '#ecf2f3' : ''}} icon={<IoPricetagsOutline   />} onClick={()=> handleItemClick(4)}> Tags </MenuItem> */}
                                <MenuItem rootStyles={{background : activeTab === 4 ? '#ecf2f3' : ''}} icon={<RiCoupon2Line />} onClick={()=> handleItemClick(4)}> Coupons </MenuItem>
                                <MenuItem rootStyles={{background : activeTab === 5 ? '#ecf2f3' : ''}} icon={<SlCalender />} onClick={()=> handleItemClick(5)}> Calendar </MenuItem>
                                <MenuItem rootStyles={{background : activeTab === 6 ? '#ecf2f3' : ''}} icon={<TbReportSearch />} onClick={()=> handleItemClick(6)}> Analytics & Reports </MenuItem>
                                <MenuItem rootStyles={{background : activeTab === 7 ? '#ecf2f3' : ''}} icon={<MdOutlineGeneratingTokens />} onClick={()=> handleItemClick(7)}> Rating & Reviews </MenuItem>
                                <MenuItem rootStyles={{background : activeTab === 8 ? '#ecf2f3' : ''}} icon={<MdOutlineSupportAgent />} onClick={()=> handleItemClick(8)}> Priority Support</MenuItem>
                            </Menu>
                        </Sidebar>
                    </div>
                    <div className="dashboardPropertyRightSection h-100">
                        { (activeTab === 0 && location?.state?.locationId) && <SubDashboard id={location?.state?.locationId}/>}
                        { (activeTab === 1  && location?.state?.locationId) && <Property id={location?.state?.locationId}  subActive ={handleSubActive} activeTab={activeSub} setActiveTab={(value)=>setActiveSub(value)} completeUpdate={()=>setActiveTab(1)}/>}
                        {activeTab === 2 && <Leads data={location?.state?.locationId} activeSection = {activeLeadSection}/>}
                        {activeTab === 3 && <RecentEvents data={location?.state?.locationId}/>}
                        {/* {activeTab === 4 && <Tags data={location?.state?.locationId}/>} */}
                        {activeTab === 4 && <CommingSoon data='Coupons'/>}
                        {activeTab === 5 && <Calendar data={location?.state?.locationId}/>}
                        {activeTab === 6 && <CommingSoon data='Analytics & Reports'/>}
                        {activeTab === 7 && <ReviewRatings data={location?.state?.locationId}/>}
                        {activeTab === 8 && <Support data={location?.state?.locationId}/>}
                    </div>
                </div> 
            </div>
        </>
    )
}
export default Dashboard;