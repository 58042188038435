import { useState } from "react";

import propertyType from "../assets/images/propertyType.svg";
import area from "../assets/images/area.svg";
import parking from "../assets/images/parking.svg";
import valetParking from "../assets/images/valetParking.svg";
import streetParking from "../assets/images/streetParking.svg";
import ccTv from "../assets/images/ccTv.svg";
import propertyTypeImage from "../assets/images/typeOfProperty1.png";
import { useAuthStore } from "../../../store/auth";

import { DescriptionModal } from "../Modals/DescriptionModal";
import AuthenitcateModal from "../../../utils/NewAuthentication/AuthenitcateModal";
import { ReportIncorrectInfoModal } from "../../../components/Modal/ReportIncorrectInfoModal";
import { ConfirmationModel } from "../../../components/Modal/ConfirmationModel";
import { saveEnquiryData } from "../../../apis/api";

const TypeOfPorperty = ({image,type,data, locationId}) => {
    const [showModal,setShowModal] = useState(false);
    const [modalTitle,setModalTitle] = useState('');
    const [modalContent,setModalContent] = useState('');
    const {userProfile, setUserProfile} = useAuthStore()
    const activeModal = () => {
        setModalTitle('Description')
        setModalContent(data?.description)
        setShowModal(true)
    }
    const [showSignUp, setShowSignUp] =useState(false);
    const [showIncorrectInfoModal, setShowIncorrectInfoModal] =useState(false);
    const [showConfirmationModal, setShowConfirmationModal] = useState(false);
    const [confirmationBody, setConfirmationBody] = useState("")
     
    const onClickReportIncorrectInfo = () => {
        if (!userProfile) {
            setShowSignUp(true);
        } else {
            setShowIncorrectInfoModal(true);
        }
    }
    const handleSuccess = async() => {
        try{
            const user = await setUserProfile()
            if(user){
                setShowSignUp(false)
                setShowIncorrectInfoModal(true)
            }
        }catch(error){
            console.log(error)
        }
    }

    return(
        <div className='typeOfPropertyContainer' >
            <div className='vectorImage'>
                <img src={propertyTypeImage} alt='typeOfPropertyImage'/>
            </div>
            <div className='typeOfPropertyContentContainer'>
                <div>
                    <img src={propertyType} alt = 'propertyType' />
                    <span className='propertyTypeHeading SpotLetSubHead' id='propertyPage0'>{type}</span>
                </div>
                <div className='typeOfPropertyDescription SpotLetContent'>
                    <span className="text-area-preserved-whitespace p-0" style={{fontWeight : "normal"}} >{data?.description?.slice(0,250)}{data?.description?.length > 250 && <span className='typeOfPropertyDescriptionReadMore cursor' onClick={activeModal} style={{fontWeight: "500"}}>{' '}Read more..</span>}</span>
                    <div className='checkWrap'>
                        <div className='checkContainer'>
                            <div className='checkContainerVectorImage'>
                                <img src={area} alt='area'/>
                            </div>
                            <span className="SpotLetContent">{data?.property_size} {data?.area} </span>
                        </div>
                        <div className='checkContainer'>
                            <div className='checkContainerVectorImage'>
                                <img src={streetParking} alt='streetParking'/>
                            </div>
                            <span className="SpotLetContent">Street Parking Facility {data?.street_parking ? "Available" : 'Not Available'}</span>
                        </div>
                    </div>
                    <div className='checkWrap'>
                        <div className='checkContainer'>
                            <div className='checkContainerVectorImage'>
                                <img src={valetParking} alt='valetParking'/>
                            </div>
                            <span className="SpotLetContent">Valet Parking {data?.valet_parking ? "Available" : 'Not Available'}</span>
                        </div>
                        <div className='checkContainer'>
                            <div className='checkContainerVectorImage'>
                                <img src={ccTv} alt='ccTv'/>
                            </div>
                            <span className="SpotLetContent">Security Camera {data?.security_camera ? "Available" : 'Not Available'}</span>
                        </div>
                    </div>
                    <div className='checkWrap'>
                        <div className='checkContainer'>
                            <div className='checkContainerVectorImage'>
                                <img src={parking} alt='area'/>
                            </div>
                            <span className="SpotLetContent">In-House Parking Facility {(data?.house_parking && data?.no_of_cars > 0) ? `Available (${data?.no_of_cars})` : 'Not Available'}</span>
                        </div>
                    </div>
                        <div className='w-100 d-flex flex-row justify-content-end'>
                            <span className="SpotLetContent text-danger cursor" onClick={onClickReportIncorrectInfo}> <i class="fa fa-flag"></i>  Report Incorrect Info </span>
                        </div>
                </div>
            </div>
            {showModal && (
                <DescriptionModal
                    show={showModal}
                    onHide={() => setShowModal(false)}
                    title={modalTitle}
                    body={modalContent}
                />
            )}
            {showSignUp && 
            <AuthenitcateModal
                show={showSignUp}
                handleSuccess={()=> {
                    handleSuccess()
                }}
                handleClose={()=>setShowSignUp(false)}
                activeTab=''
            />
            }
            {
                showIncorrectInfoModal &&
                <ReportIncorrectInfoModal
                    show={showIncorrectInfoModal}
                    handleClose={() => setShowIncorrectInfoModal(false)}
                    handleOk={async(data) => {
                        try {
                            const res = await saveEnquiryData("Report Incorrect Info", data)
                            setConfirmationBody(res?.data?.message);
                            setShowConfirmationModal(true);
                            setShowIncorrectInfoModal(false);
                        } catch (error) {
                            console.log(error);
                            setConfirmationBody(error?.response?.data?.message);
                            setShowConfirmationModal(true);
                        }
                    }}
                    locationId = {locationId}
                    userId = {userProfile?._id}
                />
            }
            {
                showConfirmationModal && (
                <ConfirmationModel
                    show={showConfirmationModal}
                    handleClose={() => setShowConfirmationModal(false)}
                    handleOk={() => setShowConfirmationModal(false)}
                    title="Report Incorrect Info"
                    body={confirmationBody}
                />
                )
            }
        </div>
    )
}
export default TypeOfPorperty;