import { Button, Modal } from "react-bootstrap";
import { useState } from "react";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import { createLookup } from "../../apis/api";

const { Header, Footer, Title, Body } = Modal;

export function NewLookup({
  handleClose,
  handleOk,
  show,
  lookupType,
  data,
  type,
}) {
  const [newLocationType, setNewLocationType] = useState("");

  const handleSubmit = async () => {
    try {
      const payload = {
        type: lookupType,
        value: newLocationType,
      };
      const resp = await createLookup(payload);
      if (resp?.createdAt) {
        handleOk(newLocationType);
      }
    } catch (error) {
      alert(error?.response?.data?.error);
    }
  };

  let titleText;

  switch (lookupType) {
    case 'amenities_type':
      titleText = "Amenity";
      break;
    case 'feature_type':
      titleText = "Feature";
      break;
    case 'dos_type':
      titleText = "Do";
      break;
    case 'dontdos_type':
      titleText = "Dont";
      break;
    case 'rules_type':
      titleText = "Rule";
      break;
    default:
      titleText = "";
      break;
  }

  return (
    <Modal show={show} onHide={handleClose}>
      <Header closeButton>
        <Title>{type}</Title>
      </Header>
      <Body className="verify-email-modal-body">
        <p>Enter New {type}</p>
        {/* <input type="text"
          value={newLocationType}
          onChange={(e) => {
            setNewLocationType(e.target.value);
          }}
        /> */}
        <Autocomplete
          name="location_type"
          value={""}
          disableClearable={true}
          onChange={(event, newValue) => {
            if (newValue) {
              handleOk(newValue);
            }
          }}
          open={newLocationType.length > 0}
          inputValue={newLocationType}
          onInputChange={(event, newInputValue) => {
            setNewLocationType(newInputValue);
          }}
          id="free-solo-demo"
          freeSolo
          options={data.map((option) => option.value)}
          renderInput={(params) => <TextField {...params} />}
        />
      </Body>
      <Footer className="mt-5">
        <Button variant="secondary" onClick={handleClose}>
          Cancel
        </Button>
        <Button variant="primary" onClick={handleSubmit}>
          Submit
        </Button>
      </Footer>
    </Modal>
  );
}