import React, { useState } from "react";

import { useEffect, useRef } from "react";
import { useAuthStore } from "../../store/auth";
import { useLocationStore } from "../../store/location";
import { uploadGstDocs, deleteFiles } from "../../apis/api";
import { Formik } from "formik";
import * as yup from "yup";
import { Button, Col, Form, Row } from "react-bootstrap";
import { ConfirmationModel } from '../../components/Modal/ConfirmationModel';

// Images
import info from "../../assets/NewImages/BecomeAHost/i.svg";
import userWhiteIcon from "../../assets/NewImages/BecomeAHost/user-white.svg";
import bankRedIcon from "../../assets/NewImages/BecomeAHost/bank-red.svg";
import bankWhiteIcon from "../../assets/NewImages/BecomeAHost/bank-white.svg";


const schema = yup.object().shape({
  contact_name: yup.string().required('Contact Person Name is required').matches(/^[aA-zZ\s]+$/, "Only alphabets are allowed for Contact Name"),
  designation: yup.string().required('Contact Person Designation is required').matches(/^[aA-zZ\s]+$/, "Only alphabets are allowed for Designation"),
  mobile_num: yup.string().required('Mobile number is required')
    .min(10, 'Mobile number must be 10 digits')
    .max(10, 'Mobile number must be 10 digits'),
  email: yup.string().email('Invalid email').required('Email Address is required'),
  pan_no: yup.string()                          //.required('PAN number is required')
    .min(10, 'PAN number must be 10 characters')
    .max(10, 'PAN number must be 10 characters'),
  aadhar_no: yup.string(),
  //.required('Aadhar number is required'),
  // .min(12, 'Aadhar number must be 12 digits')
  // .max(12, 'Aadhar number must be 12 digits'),
  account_holder_name: yup.string(),             //.required("Account Holder's Name is required"),
  bank_name: yup.string()                       //.required("Bank Name is required")
    .matches(/^[aA-zZ\s]+$/, "Only alphabets are allowed for Bank Name"),
  ifsc_code: yup.string()                       //.required("IFSC Code is required")
    .min(11, 'IFSC Code must be 11 characters')
    .max(11, 'IFSC Code must be 11 characters'),
  account_number: yup.string(),                //.required("Account Number is required"),
  alt_name: yup.string().matches(/^[aA-zZ\s]+$/, "Only alphabets are allowed for Contact Name"),
  alt_mobile: yup.string()
    .min(10, 'Mobile number must be 10 digits')
    .max(10, 'Mobile number must be 10 digits'),
});

const PropertyMangerDetails = ({ callbackFn, setTab }) => {
  const formikRef = useRef();
  const { userProfile } = useAuthStore();
  const { location: locationData } = useLocationStore();
  const [nxtView, setNxtView] = useState(false);
  const [validation, setValidation] = useState(false);
  const [errorMessage, setErrorMessage] = useState();
  const [formData, setFormData] = useState({
    contact_name: "",
    designation: "",
    mobile_num: "",
    email: "",
    alt_name: "",
    alt_mobile: "",
    pan_no: "",
    aadhar_no: "",
    img: "",
  });
  const [formData1, setFormData1] = useState({
    account_holder_name: "",
    bank_name: "",
    ifsc_code: "",
    account_number: "",
    gst_number: ""
  });
  const [filesData, setFilesData] = useState([]);

  const [initialValues, setInitialValues] = useState({
    contact_name: "",
    designation: "",
    mobile_num: "",
    email: "",
    alt_name: "",
    alt_mobile: "",
    pan_no: "",
    aadhar_no: "",
    img: "",

    account_holder_name: "",
    bank_name: "",
    ifsc_code: "",
    account_number: "",
    gst_number: ""
  });

  const [showConfirmationModel, setShowConfirmationModel] = useState(false);
  const [confirmationTitle, setConfirmationTitle] = useState("");
  const [confirmationBody, setConfirmationBody] = useState("");

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
    validateFormData(e.target);
  };

  const handleChange1 = (e) => {
    const { name, value } = e.target;
    setFormData1({
      ...formData1,
      [name]: value,
    });
  };

  const handleGstChange = async (e) => {
    try {
      for (let i = 0; i < e.target.files.length; i++) {
        const formData = new FormData();
        formData.append("pic", e.target.files[i]);
        const response = await uploadGstDocs(formData);
        setFilesData((prev) => [
          ...prev,
          { file: response.url, fileRef: response.fileRef },
        ]);
        setFormData1({
          ...formData1,
          gst_doc: response.url,
        });
      }
    } catch (error) {
      console.log(error);
    }
  };
  const deleteImage = async (fileData, index) => {
    try {
      await deleteFiles({ file: fileData?.file, fileRef: fileData?.fileRef });
      const newFilesData = filesData.filter((file, i) => i !== index);
      setFilesData(newFilesData);
    } catch (error) {
      setErrorMessage(error.response.data);
    }
  };

  const handleBack = () => {
    setTab("timing");
  }

  const onSubmit = async (formValues) => {
    if (formValues.gst_number !== "" && filesData.length === 0) {
      setConfirmationTitle("Property Manager Details");
      setConfirmationBody("GST Doc is required");
      setShowConfirmationModel(true);
      return;
    } else if (formValues.gst_number.length === 0 && filesData.length > 0) {
      setConfirmationTitle("Property Manager Details");
      setConfirmationBody("GST Number required");
      setShowConfirmationModel(true);
      return;
    }

    const contactForm = {
      contact_name: formValues?.contact_name,
      designation: formValues?.designation,
      mobile_num: formValues?.mobile_num,
      email: formValues?.email,
      alt_name: formValues?.alt_name,
      alt_mobile: formValues?.alt_mobile,
      pan_no: formValues?.pan_no,
      aadhar_no: formValues?.aadhar_no,
      img: formValues?.img,
    };

    const bankForm = {
      account_holder_name: formValues?.account_holder_name,
      bank_name: formValues?.bank_name,
      ifsc_code: formValues?.ifsc_code,
      account_number: formValues?.account_number,
    };

    if (localStorage.getItem("locationId") && validation) {
      callbackFn({
        contact_det: contactForm,
        bankDetails: bankForm,
        gst: {
          doc_no: formValues.gst_number,
          docs: filesData,
        },
      });
      setTab("photos");
    }

    setValidation(true)
    setNxtView(true)
  };


  const handleSubmit = () => {

    if (!formData.email.length || !formData.mobile_num.length ||
      !formData1.account_holder_name.length ||
      !formData1.account_number.length ||
      !formData1.bank_name.length ||
      !formData1.ifsc_code.length ||
      (formData1.gst_number !== "" && filesData.length === 0) ||
      (formData1.gst_number.length === 0 && filesData.length > 0)) {
      alert("Please fill all required fields")
      return setErrorMessage("Please fill all required fields");
    }


    if (formData.pan_no.length !== 10) {
      alert("Invalid Pan Number")
      return setErrorMessage("Invalid Pan Number");
    }

    if (
      !isNumeric(formData.aadhar_no) ||
      formData.aadhar_no?.length !== 12
    ) {
      alert("Invalid Aadhar Number");
      return setErrorMessage("Invalid Aadhar Number");
    }

    if (
      !isNumeric(formData.mobile_num) ||
      formData.mobile_num.length !== 10
    ) {
      alert("Invalid Mobile Number");
      return setErrorMessage("Invalid Mobile Number");
    }


    if (
      !/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(formData.email)
    ) {
      alert("Invalid Email");
      return setErrorMessage("Invalid Email");
    }


    if (!isNumeric(formData1.account_number)) {
      alert("Account number invalid");
      return setErrorMessage("Account number invalid");
    }

    if (formData1.ifsc_code.length !== 11) {
      alert("IFSC code invalid");
      return setErrorMessage("IFSC code invalid");
    }



    setErrorMessage("");
    if (localStorage.getItem("locationId")) {
      callbackFn({
        contact_det: formData,
        bankDetails: formData1,
        gst: {
          doc_no: formData1.gst_number,
          docs: filesData,
        },
      });
      setTab("review_app");
    }
  };

  const isNumeric = (value) => {
    return /^-?\d+$/.test(value);
  }

  useEffect(() => {
    if (locationData && locationData?.contact_det && locationData?.bankDetails) {
      const { contact_det, bankDetails, gst } = locationData;
      const tdata = Object.keys(formData).reduce((a, c) => {
        a[c] = contact_det[c] || "";
        return a;
      }, {});
      const tdata1 = Object.keys(formData1).reduce((a, c) => {
        a[c] = bankDetails[c] || "";
        return a;
      }, {});
      tdata1.gst_number = gst?.doc_no;
      setFilesData(gst?.docs);
      // setFormData({
      //   ...formData,
      //   ...tdata,
      // });
      // setFormData1({
      //   ...formData1,
      //   ...tdata1,
      // });
      setFormData(tdata);
      setFormData1(tdata1);

      // setInitialValues({
      //   contact_name: contact_det?.contact_name | "",
      //   designation: contact_det?.designation | "",
      //   mobile_num: contact_det?.mobile_num | "",
      //   email: contact_det?.email | "",
      //   alt_name: contact_det?.alt_name | "",
      //   alt_mobile: contact_det?.alt_mobile | "",
      //   pan_no: contact_det?.pan_no | "",
      //   aadhar_no: contact_det?.aadhar_no | "",
      //   img: "",

      //   account_holder_name: bankDetails?.account_holder_name | "",
      //   bank_name: bankDetails?.bank_name | "",
      //   ifsc_code: bankDetails?.ifsc_code | "",
      //   account_number: bankDetails?.account_number | "",
      //   gst_number: gst?.doc_no | ""
      // })

      if (formikRef.current) {
        formikRef.current.setFieldValue(
          "contact_name",
          contact_det?.contact_name
        );
        formikRef.current.setFieldValue(
          "designation",
          contact_det?.designation
        );
        formikRef.current.setFieldValue(
          "mobile_num",
          contact_det?.mobile_num
        );
        formikRef.current.setFieldValue(
          "email",
          contact_det?.email
        );
        formikRef.current.setFieldValue(
          "alt_name",
          contact_det?.alt_name
        );
        formikRef.current.setFieldValue(
          "alt_mobile",
          contact_det?.alt_mobile
        );
        formikRef.current.setFieldValue(
          "pan_no",
          contact_det?.pan_no
        );
        formikRef.current.setFieldValue(
          "aadhar_no",
          contact_det?.aadhar_no
        );
        formikRef.current.setFieldValue(
          "account_holder_name",
          bankDetails?.account_holder_name
        );
        formikRef.current.setFieldValue(
          "bank_name",
          bankDetails?.bank_name
        );
        formikRef.current.setFieldValue(
          "ifsc_code",
          bankDetails?.ifsc_code
        );
        formikRef.current.setFieldValue(
          "account_number",
          bankDetails?.account_number
        );
        formikRef.current.setFieldValue(
          "gst_number",
          gst?.doc_no
        );

      }
    }
  }, [locationData]);

  const fetchFromProfileData = (e) => {

    if (userProfile && e.target.checked) {
      setFormData({
        ...formData,
        contact_name: userProfile?.personalInfo?.firstName,
        designation: userProfile?.personalInfo?.profession ?? "",
        mobile_num: userProfile?.personalInfo?.mobile ?? "",
        email: userProfile?.personalInfo?.email ?? "",
      })

      if (formikRef.current) {
        formikRef.current.setFieldValue(
          "contact_name",
          userProfile?.personalInfo?.firstName
        );
        formikRef.current.setFieldValue(
          "designation",
          userProfile?.personalInfo?.profession ?? ""
        );
        formikRef.current.setFieldValue(
          "mobile_num",
          userProfile?.personalInfo?.mobile ?? ""
        );
        formikRef.current.setFieldValue(
          "email",
          userProfile?.personalInfo?.email ?? ""
        );
      }
    }
  }

  const fetchFromBillingData = (e) => {

    if (userProfile && e.target.checked) {
      setFormData1({
        ...formData1,
        account_holder_name:
          userProfile?.billing?.bankAccountHolderName || "",
        account_number: userProfile?.billing?.bankAccountNumber || "",
        ifsc_code: userProfile?.billing?.bankIfscCode || "",
        companyName: userProfile?.billing?.companyName || "",
        gst_number: userProfile?.billing?.gst || "",
      })

      setFormData({
        ...formData,
        pan_no: userProfile?.billing?.pan || "",
        aadhar_no: userProfile?.billing?.aadhar || "",
      });

      if (formikRef.current) {
        formikRef.current.setFieldValue(
          "account_holder_name",
          userProfile?.billing?.bankAccountHolderName || ""
        );
        formikRef.current.setFieldValue(
          "account_number",
          userProfile?.billing?.bankAccountNumber || ""
        );
        formikRef.current.setFieldValue(
          "ifsc_code",
          userProfile?.billing?.bankIfscCode || ""
        );
        formikRef.current.setFieldValue(
          "gst_number",
          userProfile?.billing?.gst || ""
        );
        formikRef.current.setFieldValue(
          "pan_no",
          userProfile?.billing?.pan || ""
        );
        formikRef.current.setFieldValue(
          "aadhar_no",
          userProfile?.billing?.aadhar || ""
        );
      }
    }
  }

  return (
    <>
      {/*PROPERTY MANAGER DETAILS START */}
      <div
        className="tab-pane h-100 fade property-form show active"
        id="v-pills-property"
        role="tabpanel"
        aria-labelledby="v-pills-property-tab"
      >
        <div className="row g-0 h-100">
          <div className="col-xl-8">
            <div className="h-100">
              <div className="step-form h-100">
                <div className="row">
                  <h3 className="ftitle">Property Manager Details{" "}
                  <a
                      href="#"
                      data-bs-toggle="tooltip"
                      title="Offer Property Manager contact information for inquiries"
                    >
                      <img src={info} />
                    </a>
                  </h3>

                  <div id="msform">
                    {/* progressbar  */}
                    <div className="d-flex justify-content-center">
                      <ul id="progressbar">
                        <li className={!nxtView ? "active" : ""}>
                          <img src={userWhiteIcon} />
                        </li>
                        <li className={nxtView ? "active" : ""}>
                          <img className="redbank" src={bankRedIcon} />
                          <img className="whitebank" src={bankWhiteIcon} />
                        </li>
                      </ul>
                    </div>
                    {/* <fieldset style={{ display: !nxtView ? "block" : "none" }}>
                      <div className="row">
                        <div className="col-lg-12">
                          <div className="form-check mb-lg-4 mb-sm-3">
                            <input
                              className="form-check-input mr-5"
                              type="checkbox"
                              value=""
                              id="flexCheckDefault"
                              onChange={fetchFromProfileData}
                            />
                            <label
                              className="form-check-label"
                              htmlFor="flexCheckDefault"
                            >
                              Fetch the details from Profile section
                            </label>
                          </div>
                          <span style={{color: "#ea4335"}}>
                            {errorMessage}
                          </span>
                        </div>
                      </div>   */}
                    <Formik
                      validationSchema={schema}
                      onSubmit={onSubmit}
                      initialValues={initialValues}
                      innerRef={formikRef}
                    >
                      {({
                        handleSubmit,
                        handleChange,
                        handleBlur,
                        values,
                        touched,
                        isValid,
                        errors,
                      }) => (
                        <Form noValidate onSubmit={handleSubmit} autoComplete="off">
                          <>
                            <fieldset style={{ display: !nxtView ? "block" : "none" }}>
                              <div className="row">
                                <div className="col-lg-12">
                                  <div className="form-check mb-lg-4 mb-sm-3">
                                    <input
                                      className="form-check-input mr-5"
                                      type="checkbox"
                                      value=""
                                      id="flexCheckDefault"
                                      onChange={fetchFromProfileData}
                                    />
                                    <label
                                      className="form-check-label"
                                      htmlFor="flexCheckDefault"
                                    >
                                      Fetch the details from Profile section
                                    </label>
                                  </div>
                                  <span style={{ color: "#ea4335" }}>
                                    {errorMessage}
                                  </span>
                                </div>
                              </div>
                              <Row>
                                <Form.Group as={Col} md="6">
                                  <Form.Label>
                                    Contact Person Name<span className="required">*</span>
                                    <a
                                      href="#"
                                      data-bs-toggle="tooltip"
                                      title="Primary contact overseeing property management name"
                                    >
                                      <img src={info} />
                                    </a>
                                  </Form.Label>
                                  <Form.Control
                                    type="text"
                                    name="contact_name"
                                    value={values.contact_name}
                                    onChange={handleChange}
                                    isInvalid={!!touched.contact_name && !!errors.contact_name}
                                  />
                                  <Form.Control.Feedback
                                    type="invalid"
                                    className="text-capitalize"
                                  >
                                    {errors.contact_name}
                                  </Form.Control.Feedback>
                                </Form.Group>
                                <Form.Group as={Col} md="6">
                                  <Form.Label>
                                    Contact Person Designation<span className="required">*</span>
                                    <a
                                      href="#"
                                      data-bs-toggle="tooltip"
                                      title="Official role of the main contact person"
                                    >
                                      <img src={info} />
                                    </a>
                                  </Form.Label>
                                  <Form.Control
                                    type="text"
                                    name="designation"
                                    value={values.designation}
                                    onChange={handleChange}
                                    isInvalid={!!touched.designation && !!errors.designation}
                                  />
                                  <Form.Control.Feedback
                                    type="invalid"
                                    className="text-capitalize"
                                  >
                                    {errors.designation}
                                  </Form.Control.Feedback>
                                </Form.Group>
                              </Row>

                              <Row>
                                <Form.Group as={Col} md="6">
                                  <Form.Label>
                                    Mobile Number<span className="required">*</span>
                                    <a
                                      href="#"
                                      data-bs-toggle="tooltip"
                                      title="Primary phone contact for property matters"
                                    >
                                      <img src={info} />
                                    </a>
                                  </Form.Label>
                                  <Form.Control
                                    type="number"
                                    name="mobile_num"
                                    value={values.mobile_num}
                                    onChange={handleChange}
                                    isInvalid={!!touched.mobile_num && !!errors.mobile_num}
                                  />
                                  <Form.Control.Feedback
                                    type="invalid"
                                    className="text-capitalize"
                                  >
                                    {errors.mobile_num}
                                  </Form.Control.Feedback>
                                </Form.Group>
                                <Form.Group as={Col} md="6">
                                  <Form.Label>
                                    Email Address<span className="required">*</span>
                                    <a
                                      href="#"
                                      data-bs-toggle="tooltip"
                                      title="Main email for property management communication"
                                    >
                                      <img src={info} />
                                    </a>
                                  </Form.Label>
                                  <Form.Control
                                    type="text"
                                    name="email"
                                    value={values.email}
                                    onChange={handleChange}
                                    isInvalid={!!touched.email && !!errors.email}
                                  />
                                  <Form.Control.Feedback
                                    type="invalid"
                                    className="text-capitalize"
                                  >
                                    {errors.email}
                                  </Form.Control.Feedback>
                                </Form.Group>
                              </Row>

                              <Row>
                                <Form.Group as={Col} md="6">
                                  <Form.Label>
                                    Alternate person’s name
                                    <a
                                      href="#"
                                      data-bs-toggle="tooltip"
                                      title="Backup contact for property-related queries"
                                    >
                                      <img src={info} />
                                    </a>
                                  </Form.Label>
                                  <Form.Control
                                    type="text"
                                    name="alt_name"
                                    value={values.alt_name}
                                    onChange={handleChange}
                                    isInvalid={!!touched.alt_name && !!errors.alt_name}

                                  />
                                  <Form.Control.Feedback
                                    type="invalid"
                                    className="text-capitalize"
                                  >
                                    {errors.alt_name}
                                  </Form.Control.Feedback>
                                </Form.Group>
                                <Form.Group as={Col} md="6">
                                  <Form.Label>
                                    Alternate person’s Mobile Number
                                    <a
                                      href="#"
                                      data-bs-toggle="tooltip"
                                      title="Secondary phone contact for flexibility"
                                    >
                                      <img src={info} />
                                    </a>
                                  </Form.Label>
                                  <Form.Control
                                    type="number"
                                    name="alt_mobile"
                                    value={values.alt_mobile}
                                    onChange={handleChange}
                                    isInvalid={!!touched.alt_mobile && !!errors.alt_mobile}
                                  />
                                  <Form.Control.Feedback
                                    type="invalid"
                                    className="text-capitalize"
                                  >
                                    {errors.alt_mobile}
                                  </Form.Control.Feedback>
                                </Form.Group>
                              </Row>

                              <div className="d-flex w-100 justify-content-between" style={{ padding: "10px 0px 0px 0px" }}>
                                <button
                                  type="button"
                                  name="previous"
                                  className="previous action-button btn-gray"
                                  onClick={() => setTab("timing")}
                                >
                                  Back
                                </button>
                                {/* <input
                                  type="button"
                                  name="next"
                                  className="next action-button btn-theme float-end"
                                  value="Next"
                                  onClick={() => setNxtView(true)}
                                /> */}
                                <Button className="next action-button btn-theme float-end" type="submit">Next</Button>
                              </div>
                            </fieldset>

                            <fieldset style={{ display: nxtView ? "block" : "none" }}>
                              <div className="scroolbox">
                                <div className="row">
                                  <div className="col-lg-12">
                                    <div className="form-check mb-lg-4 mb-sm-3">
                                      <input
                                        className="form-check-input mr-5"
                                        type="checkbox"
                                        value=""
                                        id="flexCheckDefault1"
                                        onChange={fetchFromBillingData}
                                      />
                                      <label
                                        className="form-check-label"
                                        htmlFor="flexCheckDefault1"
                                      >
                                        Fetch the details from Profile section
                                      </label>
                                    </div>
                                    <span style={{ color: "#ea4335" }}>
                                      {errorMessage}
                                    </span>
                                  </div>
                                  <Row>
                                    <Form.Group as={Col} md="6">
                                      <Form.Label>
                                        Pan Number (Bank Account Owner)
                                        {/* <span className="required">*</span> */}
                                        <a
                                          href="#"
                                          data-bs-toggle="tooltip"
                                          title="Property manager's unique identification for tax purposes"
                                        >
                                          <img src={info} />
                                        </a>
                                      </Form.Label>
                                      <Form.Control
                                        type="text"
                                        name="pan_no"
                                        value={values.pan_no}
                                        onChange={handleChange}
                                        isInvalid={!!touched.pan_no && !!errors.pan_no}
                                      />
                                      <Form.Control.Feedback
                                        type="invalid"
                                        className="text-capitalize"
                                      >
                                        {errors.pan_no}
                                      </Form.Control.Feedback>
                                    </Form.Group>
                                    <Form.Group as={Col} md="6">
                                      <Form.Label>
                                        Aadhar Card Number
                                        {/* <span className="required">*</span> */}
                                        <a
                                          href="#"
                                          data-bs-toggle="tooltip"
                                          title="Personal identification number for property manager"
                                        >
                                          <img src={info} />
                                        </a>
                                      </Form.Label>
                                      <Form.Control
                                        type="number"
                                        name="aadhar_no"
                                        value={values.aadhar_no}
                                        onChange={handleChange}
                                        isInvalid={!!touched.aadhar_no && !!errors.aadhar_no}
                                      />
                                      <Form.Control.Feedback
                                        type="invalid"
                                        className="text-capitalize"
                                      >
                                        {errors.aadhar_no}
                                      </Form.Control.Feedback>
                                    </Form.Group>
                                  </Row>
                                  {/* <div className="col-lg-6">
                                          <div className="mb-4">
                                            <label htmlFor="hourlyprice" className="form-label ">
                                              {" "}
                                              Pan Number (Bank Account Owner)
                                              <span> *</span>{" "}
                                              <a href="#">
                                                <img src={info} />
                                              </a>
                                            </label>
                                            <input
                                              type="text"
                                              className="form-control"
                                              id="hourlyprice"
                                              name="pan_no"
                                              onChange={handleChange}
                                              value={formData?.pan_no}
                                            />
                                          </div>
                                        </div>
                                        <div className="col-lg-6">
                                          <div className="mb-4">
                                            <label htmlFor="hourlyprice" className="form-label ">
                                              {" "}
                                              Aadhar Card Number<span> *</span>{" "}
                                              <a href="#">
                                                <img src={info} />
                                              </a>{" "}
                                            </label>
                                            <input
                                              type="number"
                                              className="form-control"
                                              id="hourlyprice"
                                              name="aadhar_no"
                                              onChange={handleChange}
                                              value={formData?.aadhar_no}
                                            />
                                          </div>
                                        </div> */}

                                  {/* <div className="col-lg-6">
                                          <div className="mb-4">
                                            <label htmlFor="hourlyprice" className="form-label ">
                                              {" "}
                                              Account Holder's Name<span> *</span>{" "}
                                              <a href="#">
                                                <img src={info} />
                                              </a>{" "}
                                            </label>
                                            <input
                                              type="text"
                                              className="form-control"
                                              id="hourlyprice"
                                              name="account_holder_name"
                                              onChange={handleChange1}
                                              value={formData1?.account_holder_name}
                                            />
                                          </div>
                                        </div>
                                        <div className="col-lg-6">
                                          <div className="mb-4">
                                            <label htmlFor="hourlyprice" className="form-label ">
                                              {" "}
                                              Bank Name<span> *</span>{" "}
                                              <a href="#">
                                                <img src={info} />
                                              </a>{" "}
                                            </label>
                                            <input
                                              type="text"
                                              className="form-control"
                                              id="hourlyprice"
                                              name="bank_name"
                                              onChange={handleChange1}
                                              value={formData1?.bank_name}
                                            />
                                          </div>
                                        </div> */}

                                  <Row>
                                    <Form.Group as={Col} md="6">
                                      <Form.Label>
                                        Account Holder's Name
                                        {/* <span className="required">*</span> */}
                                        <a
                                          href="#"
                                          data-bs-toggle="tooltip"
                                          title="Name registered with the property manager's bank account"
                                        >
                                          <img src={info} />
                                        </a>
                                      </Form.Label>
                                      <Form.Control
                                        type="text"
                                        name="account_holder_name"
                                        value={values.account_holder_name}
                                        onChange={handleChange}
                                        isInvalid={!!touched.account_holder_name && !!errors.account_holder_name}
                                      />
                                      <Form.Control.Feedback
                                        type="invalid"
                                        className="text-capitalize"
                                      >
                                        {errors.account_holder_name}
                                      </Form.Control.Feedback>
                                    </Form.Group>
                                    <Form.Group as={Col} md="6">
                                      <Form.Label>
                                        Bank Name
                                        {/* <span className="required">*</span> */}
                                        <a
                                          href="#"
                                          data-bs-toggle="tooltip"
                                          title="Financial Bank where property transactions are managed"
                                        >
                                          <img src={info} />
                                        </a>
                                      </Form.Label>
                                      <Form.Control
                                        type="text"
                                        name="bank_name"
                                        value={values.bank_name}
                                        onChange={handleChange}
                                        isInvalid={!!touched.bank_name && !!errors.bank_name}
                                      />
                                      <Form.Control.Feedback
                                        type="invalid"
                                        className="text-capitalize"
                                      >
                                        {errors.bank_name}
                                      </Form.Control.Feedback>
                                    </Form.Group>
                                  </Row>

                                  {/* <div className="col-lg-6">
                                          <div className="mb-4">
                                            <label htmlFor="hourlyprice" className="form-label ">
                                              {" "}
                                              IFSC Code<span> *</span>{" "}
                                              <a href="#">
                                                <img src={info} />
                                              </a>{" "}
                                            </label>
                                            <input
                                              type="text"
                                              className="form-control"
                                              id="hourlyprice"
                                              name="ifsc_code"
                                              onChange={handleChange1}
                                              value={formData1?.ifsc_code}
                                            />
                                          </div>
                                        </div>
                                        <div className="col-lg-6">
                                          <div className="mb-4">
                                            <label htmlFor="hourlyprice" className="form-label ">
                                              {" "}
                                              Account Number<span> *</span>{" "}
                                              <a href="#">
                                                <img src={info} />
                                              </a>{" "}
                                            </label>
                                            <input
                                              type="number"
                                              className="form-control"
                                              id="hourlyprice"
                                              name="account_number"
                                              onChange={handleChange1}
                                              value={formData1?.account_number}
                                            />
                                          </div>
                                        </div> */}

                                  <Row>
                                    <Form.Group as={Col} md="6">
                                      <Form.Label>
                                        IFSC Code
                                        {/* <span className="required">*</span> */}
                                        <a
                                          href="#"
                                          data-bs-toggle="tooltip"
                                          title="Code specifying the property manager's bank branch"
                                        >
                                          <img src={info} />
                                        </a>
                                      </Form.Label>
                                      <Form.Control
                                        type="text"
                                        name="ifsc_code"
                                        value={values.ifsc_code}
                                        onChange={handleChange}
                                        isInvalid={!!touched.ifsc_code && !!errors.ifsc_code}
                                      />
                                      <Form.Control.Feedback
                                        type="invalid"
                                        className="text-capitalize"
                                      >
                                        {errors.ifsc_code}
                                      </Form.Control.Feedback>
                                    </Form.Group>
                                    <Form.Group as={Col} md="6">
                                      <Form.Label>
                                        Account Number
                                        {/* <span className="required">*</span> */}
                                        <a
                                          href="#"
                                          data-bs-toggle="tooltip"
                                          title="Unique number assigned to property manager's bank account"
                                        >
                                          <img src={info} />
                                        </a>
                                      </Form.Label>
                                      <Form.Control
                                        type="number"
                                        name="account_number"
                                        value={values.account_number}
                                        onChange={handleChange}
                                        isInvalid={!!touched.account_number && !!errors.account_number}
                                      />
                                      <Form.Control.Feedback
                                        type="invalid"
                                        className="text-capitalize"
                                      >
                                        {errors.account_number}
                                      </Form.Control.Feedback>
                                    </Form.Group>
                                  </Row>
                                  {/* <div className="col-lg-12">
                                          <div className="mb-4">
                                            <label htmlFor="hourlyprice" className="form-label ">
                                              {" "}
                                              GST Number (Bank Account Owner)
                                              {" "}
                                              <a href="#">
                                                <img src={info} />
                                              </a>{" "}
                                            </label>
                                            <input
                                              type="text"
                                              className="form-control"
                                              id="hourlyprice"
                                              name="gst_number"
                                              onChange={handleChange1}
                                              value={formData1?.gst_number}
                                            />
                                          </div>
                                        </div> */}
                                  <Row>
                                    <Form.Group as={Col} md="6">
                                      <Form.Label>
                                        GST Number (Bank Account Owner)
                                        <a
                                          href="#"
                                          data-bs-toggle="tooltip"
                                          title="Goods and Services Tax identification for property transactions"
                                        >
                                          <img src={info} />
                                        </a>
                                      </Form.Label>
                                      <Form.Control
                                        type="text"
                                        name="gst_number"
                                        value={values.gst_number}
                                        onChange={handleChange}
                                        isInvalid={!!touched.gst_number && !!errors.gst_number}
                                      />
                                      <Form.Control.Feedback
                                        type="invalid"
                                        className="text-capitalize"
                                      >
                                        {errors.gst_number}
                                      </Form.Control.Feedback>
                                    </Form.Group>
                                  </Row>
                                  <div className="col-lg-12">
                                    <div className="mb-4">
                                      <div className="upload__box docupload">
                                        <div className="upload__btn-box">
                                          <label className="upload__btn">
                                            <h3>Upload GST Docs</h3>
                                            <input
                                              type="file"
                                              multiple=""
                                              data-max_length="20"
                                              className="upload__inputfile"
                                              onChange={handleGstChange}
                                              accept=".xlsx,.xls,,.doc, .docx,.ppt, .pptx,.txt,.pdf"
                                            />
                                          </label>
                                        </div>
                                        <div className="upload__img-wrap">
                                          {filesData?.map((fileData, index) => {
                                            return (
                                              <div
                                                className="pict"
                                                style={{ width: "440px", height: "330px" }}
                                                key={index}
                                              >
                                                <button
                                                  title="Remove"
                                                  aria-label="Remove"
                                                  type="button"
                                                  onClick={() => deleteImage(fileData, index)}
                                                >
                                                  <i className="material-icons">cancel</i>
                                                </button>
                                                <embed src={fileData?.file} width="440px" height="330px" />{" "}
                                              </div>
                                            );
                                          })}
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="d-flex w-100 justify-content-between">
                                <button
                                  type="button"
                                  name="previous"
                                  className="previous action-button btn-gray"
                                  onClick={() => { setNxtView(false), setValidation(false) }}
                                >
                                  Previous
                                </button>
                                <Button type="submit">Save & Continue</Button>
                              </div>
                            </fieldset>
                          </>
                        </Form>
                      )}
                    </Formik>
                    {/* <div className="col-lg-6">
                          <div className="mb-4">
                            <label htmlFor="hourlyprice" className="form-label ">
                              {" "}
                              Contact Person Name<span> *</span>{" "}
                              <a href="#">
                                <img src={info} />
                              </a>
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              id="hourlyprice"
                              name="contact_name"
                              onChange={handleChange}
                              value={formData?.contact_name}
                            />
                          </div>
                        </div>
                        <div className="col-lg-6">
                          <div className="mb-4">
                            <label htmlFor="hourlyprice" className="form-label ">
                              {" "}
                              Contact Person Designation<span> *</span>{" "}
                              <a href="#">
                                <img src={info} />
                              </a>{" "}
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              id="hourlyprice"
                              name="designation"
                              onChange={handleChange}
                              value={formData?.designation}
                            />
                          </div>
                        </div>

                        <div className="col-lg-6">
                          <div className="mb-4">
                            <label htmlFor="hourlyprice" className="form-label ">
                              {" "}
                              Mobile Number<span> *</span>{" "}
                              <a href="#">
                                <img src={info} />
                              </a>{" "}
                            </label>
                            <input
                              type="number"
                              className="form-control"
                              id="hourlyprice"
                              name="mobile_num"
                              onChange={handleChange}
                              value={formData?.mobile_num}
                            />
                          </div>
                        </div>
                        <div className="col-lg-6">
                          <div className="mb-4">
                            <label htmlFor="hourlyprice" className="form-label ">
                              {" "}
                              Email Address<span> *</span>{" "}
                              <a href="#">
                                <img src={info} />
                              </a>{" "}
                            </label>
                            <input
                              type="email"
                              className="form-control"
                              id="hourlyprice"
                              name="email"
                              onChange={handleChange}
                              value={formData?.email}
                            />
                          </div>
                        </div>

                        <div className="col-lg-6">
                          <div className="mb-4">
                            <label htmlFor="hourlyprice" className="form-label ">
                              {" "}
                              Alternate person’s name{" "}
                              <a href="#">
                                <img src={info} />
                              </a>{" "}
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              id="hourlyprice"
                              name="alt_name"
                              onChange={handleChange}
                              value={formData?.alt_name}
                            />
                          </div>
                        </div>
                        <div className="col-lg-6">
                          <div className="mb-4">
                            <label htmlFor="hourlyprice" className="form-label ">
                              {" "}
                              Alternate person’s Mobile Number
                              {" "}
                              <a href="#">
                                <img src={info} />
                              </a>{" "}
                            </label>
                            <input
                              type="number"
                              className="form-control"
                              id="hourlyprice"
                              name="alt_mobile"
                              onChange={handleChange}
                              value={formData?.alt_mobile}
                            />
                          </div>
                        </div> */}
                    {/* <div className="d-flex w-100 justify-content-between" style={{padding: "10px 0px 0px 0px"}}>
                        <button
                          type="button"
                          name="previous"
                          className="previous action-button btn-gray"
                          onClick={() => setTab("timing")}
                        >
                          Back
                        </button>
                        <input
                          type="button"
                          name="next"
                          className="next action-button btn-theme float-end"
                          value="Next"
                          onClick={() => setNxtView(true)}
                        />
                      </div> */}



                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-xl-4">
            <div className="detail-desc property"></div>
          </div>
        </div>
      </div>
      {showConfirmationModel && (
        <ConfirmationModel
          show={showConfirmationModel}
          handleClose={() => setShowConfirmationModel(false)}
          handleOk={() => setShowConfirmationModel(false)}
          title={confirmationTitle}
          body={confirmationBody}
        />
      )}
      {/*PROPERTY MANAGER DETAILS END */}
    </>
  );
};

export default PropertyMangerDetails;
