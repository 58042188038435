import {Helmet} from "react-helmet";
//  ********* Terms & Conditions Constant ********

const subContent1 = [
  {
    heading: `Terms & Conditions`,
    para1: `SpotLet facilitates the connection between hosts who own properties and
			renters who are interested in filming or hosting events in their residential or
			commercial spaces. Through SpotLet's online platform, renters have the
			convenience of searching for specific locations suitable for filming and events,
			engaging in communication with hosts, and securely completing payments in
			a streamlined manner.`,
    para2: `SpotLet has two primary objectives. Firstly, it aims to provide renters with an
			easily accessible and cost-effective platform for finding and booking unique
			filming locations and event spaces. Secondly, it offers property owners a new
			avenue to showcase their properties and generate additional income.`,
  },
];

const subContent2 = [
  {
    heading: `Terms of Service`,
    para1: `Please carefully review these Terms of Service as they contain crucial
			information regarding your legal rights, remedies, and obligations. They
			include various limitations and exclusions, a clause governing the
			jurisdiction and venue for dispute resolution, and obligations to comply
			with applicable laws and regulations.`,
    para2: `Specifically, it is important for property listers to understand the legal
			requirements applicable in their respective states.`,
  },
];

const heading1 = [
  {
    heading: "KEY TERMS:",
    para1: `SpotLet operates an online platform that facilitates connections between
			property listers, who offer properties for rent, and renters, who seek to rent
			such properties for various purposes, including but not limited to motion
			pictures, television, other creative productions, commercial ventures,
			corporate events, and individual events (collectively referred to as the
			"Services"). These Services are accessible through the website
			www.spotlet.in and any other websites where SpotLet makes the Services
			available (collectively referred to as the "Site").`,
    para2: `When you use the Site or Services, these Terms of Service constitute a
			legally binding agreement between you and SpotLet (referred to as
			"SpotLet," "we," "us," or "our") governing your use of the Site or Services.
			Additionally, with regard to any payments or payouts conducted through the
			Site, these Terms of Service also apply to your relationship with
			Stripe/Razorpay, a third-party payment processor ("Stripe/Razorpay").`,
    para3: `"Booking Request Period" refers to the specific duration, determined at
			SpotLet's sole discretion, starting from when a Renter submits a booking
			request, during which a Lister can decide to accept or reject the request. The
			exact length of the Booking Request Period may vary depending on the
			location and will be stated on the Site or Services.`,
    para4: `"Collective Content" encompasses both User Content and SpotLet Content.`,
    para5: `"Content" includes text, graphics, images, music, software, audio, video,
			information, or any other materials.`,
    para6: `"SpotLet Content" comprises all the Content made available by SpotLet
			through the Site, Services, official social media channels, and related
			promotional campaigns. This includes any Content licensed from third parties,
			excluding User Content.`,
    para7: `"Lister" refers to a User who creates a Listing through the Site and Services.`,
    para8: `"Listing" represents a Property listed by a Lister for rental availability through
			the Site and Services.`,
    para9: `"User" pertains to an individual who completes SpotLet's account registration
			process, including Listers and Renters, as discribed in detail under "Account
			Registration" below.`,
    para10: `"User Content" encompasses all the Content that a User posts, uploads,
			publishes, submits, transmits, or includes in their Listing, User profile, or
			SpotLet promotional campaign, which is made available through the Site or
			Services.`,
    para11: `"Property" denotes residential, commercial, and other types of properties.`,
    para12: `"Renter" signifies a User who requests a booking of a Listing from a Lister
			through the Site or Services.`,
    para13: `"Tax" or "Taxes" encompasses any applicable taxes, such as value-added
			taxes (VAT), goods and services taxes, transient occupancy taxes, tourist or
			visitor taxes, property or lodging taxes, fees (e.g., convention center fees) that
		   property providers may be legally obligated to collect and remit to
			governmental agencies, and other similar indirect taxes imposed at the
			municipal, state, federal, or national level, as well as income taxes for
			individuals or corporations.`,
  },
];
const heading2 = [
  {
    heading: "TERMS OF SERVICE:",
    para1: `By utilizing the Site or Services, you hereby agree to comply with and be
			legally bound by the terms and conditions set forth in these Terms of
			Service ("Terms"), regardless of whether or not you become a registered
			user of the Services. These Terms govern your access to and use of the
			Site, Services, and all Collective Content (as defined below), establishing a
			binding legal agreement between you and SpotLet. We also urge you to
			carefully review our Privacy Policy located at http://SpotLet.in/privacy.`,
    para2: `Furthermore, specific areas of the Site (and your access to or usage of
			certain aspects of the Services or Collective Content) may have separate
			terms and conditions, standards, guidelines, or policies posted, or may
			require you to accept additional terms and conditions. In the event of a
			conflict between these Terms and the terms and conditions posted for a
			particular area of the Site, Services, or Collective Content, the latter terms
			and conditions shall take precedence regarding your use of or access to
			that specific area of the Site, Services, or Collective Content.`,
    para3: `If you do not agree to these Terms, you have no right to obtain information
			from the Site or Services or continue using them. Failure to adhere to these
			Terms in your use of the Site or Services may subject you to civil and
			criminal penalties.`,
  },
];
const upperCaseText = [
  {
    text1: `THE SITE AND SERVICES PROVIDE AN ONLINE PLATFORM THROUGH WHICH
			LISTERS CAN CREATE LISTINGS FOR PROPERTIES, AND RENTERS CAN
			LEARN ABOUT AND DIRECTLY BOOK PROPERTIES FROM THE LISTERS.
			YOU UNDERSTAND AND AGREE THAT SPOTLET IS NOT A PARTY TO ANY
			AGREEMENTS MADE BETWEEN LISTERS AND RENTERS, NOR DOES
			SPOTLET ACT AS A REAL ESTATE BROKER, AGENT, OR INSURER. SPOTLET
			HAS NO CONTROL OVER THE CONDUCT OF LISTERS, RENTERS, AND
			OTHER USERS OF THE SITE AND SERVICES, OR OVER ANY PROPERTIES,
			AND DISCLAIMS ALL LIABILITY IN THIS REGARD TO THE FULLEST EXTENT
			PERMITTED BY LAW.`,
    text2: `IF YOU CHOOSE TO CREATE A LISTING ON SPOTLET, YOU ACKNOWLEDGE
			AND AGREE THAT YOUR RELATIONSHIP WITH SPOTLET IS LIMITED TO
			BEING A USER AND AN INDEPENDENT, THIRD-PARTY CONTRACTOR. YOU
			DO NOT SERVE AS AN EMPLOYEE, AGENT, JOINT VENTURER, OR PARTNER
			OF SPOTLET FOR ANY REASON. YOU ACT SOLELY ON YOUR OWN BEHALF
			AND FOR YOUR OWN BENEFIT, AND NOT ON BEHALF OF OR FOR THE
			BENEFIT OF SPOTLET. SPOTLET DOES NOT CONTROL, AND HAS NO RIGHT
			TO CONTROL, YOUR LISTING, YOUR OFFLINE ACTIVITIES ASSOCIATED
			WITH YOUR LISTING, OR ANY OTHER MATTERS RELATED TO ANY LISTING
			THAT YOU PROVIDE. AS A USER, YOU AGREE NOT TO ENGAGE IN ANY
			ACTIVITY THAT CREATES A FALSE IMPRESSION THAT YOU ARE ENDORSED
			BY, PARTNERED WITH, OR ACTING ON BEHALF OF OR FOR THE BENEFIT OF
			SPOTLET, INCLUDING IMPROPERLY USING ANY SPOTLET INTELLECTUAL
			PROPERTY.
			`,
    text3: `BY ACCESSING OR USING THE SITE OR SERVICES, OR BY DOWNLOADING
			OR POSTING ANY CONTENT FROM OR ON THE SITE OR THROUGH THE
			SERVICES, YOU ACKNOWLEDGE AND AGREE THAT YOU HAVE READ,
			UNDERSTAND, AND ACCEPT THESE TERMS. THIS APPLIES WHETHER OR
			NOT YOU HAVE REGISTERED WITH THE SITE. IF YOU DO NOT AGREE TO
			THESE TERMS, YOU HAVE NO RIGHT TO ACCESS OR USE THE SITE,
			SERVICES, OR COLLECTIVE CONTENT.
			`,
    text4: `If you accept or agree to these Terms on behalf of a company or other legal entity,
			you represent and warrant that you have the authority to bind that company or legal
			entity to these Terms. In such cases, the terms "you" and "your" will refer to and
			apply to that company or legal entity.
			`,
  },
];

const heading3 = [
  {
    heading: `MODIFICATION:`,
    para1: `SpotLet reserves the sole right to modify the Site or Services, as well as
			these Terms, including the Service Fees, at any time and without prior
			notice. If any modifications are made, we will either post the modified
			version on the Site or provide you with notice of the changes. The "Last
			Updated" date at the top of these Terms will also reflect the modifications.
			By continuing to access or use the Site or Services after we have posted a
			modification on the Site or provided notice of a modification, you indicate
			your agreement to be bound by the modified Terms. If you find the modified
			Terms unacceptable, your only recourse is to discontinue using the Site
			and Services.`,
  },
];

const heading4 = [
  {
    heading: `ELIGIBILITY:`,
    para1: `The Site and Services are exclusively intended for individuals aged 18
			years or older. Access to or use of the Site or Services by individuals under
			the age of 18 is expressly prohibited. By accessing or using the Site or
			Services, you represent and warrant that you are 18 years of age or older.`,
  },
];
const heading5 = [
  {
    heading: `HOW THE SITE AND SERVICES OPERATE:`,
    para1: `The Site and Services function as a platform for facilitating the listing and
			booking of properties. Listers include properties in Listings on the Site and
			Services. As an unregistered visitor, you can view Listings; however, to
			book a property or create a Listing, you must first register and create a
			SpotLet Account.
			`,
    para2: `SpotLet provides an online platform and technology that enables Renters
			and Listers to connect and arrange property bookings directly with each
			other. SpotLet does not own or operate properties, nor does it provide
			properties. SpotLet's role is limited to facilitating the availability of the Site
			and Services.
			`,
    para3: `It's important to note that SpotLet does not control the content of Listings,
			nor does it guarantee the condition, legality, or suitability of any properties.
			SpotLet is not responsible for any Listings or properties, and any bookings
			are made at the User's own risk.
			`,
  },
];

const heading6 = [
  {
    heading: `REGISTERING AN ACCOUNT:`,
    para1: `To access certain features, book a property, or create a Listing, you must
			register and create a SpotLet Account. You can register directly on the Site
			or through third-party social networking sites (SNS) by linking your SpotLet
			Account with your SNS Account. By linking your accounts, you grant
			SpotLet access to your SNS Content for use on the Site and Services. You
			are responsible for maintaining the confidentiality of your password and
			notifying SpotLet of any unauthorized use of your SpotLet Account.
			`,
  },
];

const heading7 = [
  {
    heading: `PROPERTY LISTINGS:`,
    para1: `As a User, you can create Listings for properties. When creating a Listing,
			you provide information about the property's details, availability, pricing,
			and rules. All Properties must have valid physical addresses to be featured
			in Listings on the Site and Services. The placement and ranking of Listings
			in search results may depend on various factors.`,
    para2: `Other Users can book your property based on the information provided in
			your Listing. Once a Renter requests a booking, you cannot ask for a
			higher price than the booking request. You are solely responsible for the
			Listings and User Content you post, ensuring compliance with agreements,
			laws, and regulations. SpotLet reserves the right to remove or disable
			access to any Listing that violates these Terms or is deemed objectionable.`,
    para3: `SpotLet does not act as an insurer or contracting agent for Listers. Any
			agreement between a Lister and Renter is solely between them, and
			SpotLet is not a party to it. Listers are responsible for their acts and
			omissions, as well as those of individuals present at the property at their
			request, excluding the Renter and their invited guests.`,
    para4: `SpotLet may offer photographers to take photographs of properties. If you
			choose to use these photographs, SpotLet retains ownership of the
			copyrights but provides them to you with a watermark. You are responsible
			for accurately representing your Listing with these Images. SpotLet may
			use the Images for advertising and other purposes without compensation.`,
    para5: `Please note that the rephrased text is a summary and may not capture all
			the nuances of the original document. It's always recommended to refer to
			the original source for complete and accurate information.`,
  },
];

const heading8 = [
  {
    heading: `NO ENDORSEMENT:`,
    para1: `SpotLet does not provide endorsements for any User, Listing, or Property.
			The Images displayed are solely intended to represent a photographic
			depiction of the Property at the time the photo was taken. The Images
			should not be interpreted as an endorsement by SpotLet of any User,
			Listing, or Property.`,
    para2: `Users are obligated under these Terms to provide accurate information.
			While SpotLet may request Users to provide government identification,
			date of birth, or other information directly or through third parties for
			transparency, fraud prevention, or detection purposes, SpotLet does not
			make any representations, confirmations, or endorsements regarding the
			identity or background of any User.`,
    para3: `By creating a SpotLet Account, you acknowledge that SpotLet may, at its
			discretion, request a consumer report on you from a Consumer Reporting
			Agency. If such a request is made, SpotLet will handle the consumer report
			in compliance with applicable laws, including the Fair Credit Reporting Act.`,
    para4: `References on the Site or Services to a User being "verified" or
			"connected" (or similar language) indicate only that the User has completed
			a relevant verification or identification process. These discriptions do not
			constitute an endorsement, certification, or guarantee by SpotLet regarding
			the User's identity, trustworthiness, safety, or suitability. Instead, these
			discriptions are provided as useful information for you to consider when
			making your own decisions about the identity and suitability of others you
			interact with through the Site and Services. It is recommended that you
			exercise due diligence and care when choosing to stay with a Lister or
			accepting a booking request from a Renter, or when engaging with any
			other User. SpotLet is not liable for any damages or harm resulting from
			your interactions with other Users.`,
    para5: `By using the Site or Services, you agree that any legal remedy or liability
			you seek regarding the actions or omissions of other Users or third parties
			will be limited to a claim against the specific Users or third parties
			responsible for the harm. You agree not to hold SpotLet liable or seek legal
			remedies from SpotLet for such actions or omissions. Therefore, it is
			encouraged that you communicate directly with other Users on the Site and
			Services regarding any bookings or Listings you make. This limitation does
			not apply to a Lister's claim against SpotLet concerning the remittance of
			payments received from a Renter on behalf of the Lister, which is subject to
			the limitations discribed in the "Limitation of Liability" section below.`,
  },
];

const heading9 = [
  {
    heading: `BOOKINGS AND FINANCIAL TERMS:`,
    subhead1: `Key Definitions`,
    subhead2: `Appointment of Stripe/Razorpay as Limited Payment Collection Agent for Lister`,
    subhead3: `Bookings and Financial Terms for Listers`,
    subhead4: ` Bookings and Financial Terms for Renters`,
    para1: `"Lister Fees" refer to the fees charged by SpotLet to Listers for using the
			Services. These fees are calculated as a percentage of the applicable
			Property Fees. The Lister Fees will be clearly presented to the Lister when
			deciding whether to confirm or reject a booking request from a prospective
			Renter.`,
    para2: `"Payment Method" refers to a payment made through the RazorPay or Stripe
			payment gateway.`,
    para3: `"Property Fees" denote the amounts payable by a Renter for renting a
			Property. This includes the Lister Fees, Property Management Fees (if
			applicable), and the Processing Fee. The Lister is solely responsible for
			setting the Property Fees for their Listing.`,
    para4: `"Property Manager" refers to an individual or company that assists the Lister
			with listing and renting out the Property.`,
    para5: `"Property Management Fees" represent the fees charged by a Property
			Manager to the Lister for assisting with listing and renting out the Property.
			These fees are calculated as a percentage of the applicable Property Fees.
			The Property Management Fees will be clearly displayed to the Renter when
			considering whether to send a booking request to a Lister.`,
    para6: `"Service Fees" collectively refer to the Property Management Fees and Lister
			Fees.`,
    para7: `"Processing Fee" is the fee paid by the Renter, which is calculated based on
			the total of the Location Fee plus any applicable Additional Fees. The
			Processing Fee decreases as the total cost of the booking increases.`,
    para8: `"Additional Fees" are extra charges associated with using a Listing. These
			fees are not personally beneficial to the host and are paid by the host to
			external contractors (e.g., Cleaning fee or Site rep fee).`,
    para9: `"Total Fees" collectively represent the Property Fees, GST, Insurance, and
			any applicable Taxes.`,
    para10: `Each Lister hereby appoints Stripe/RazorPay as their limited payment
			collection agent for accepting Property Fees from Renters. The Lister agrees
			that payment made by a Renter through Stripe/RazorPay will be considered
			as if the payment was made directly to the Lister. The Lister is obligated to
			make the Property available to the Renter as agreed upon as if the Lister had
			received the Property Fees.`,
    para11: `As a Lister, if a booking request for your Property is made through the Site or
			Services, you must respond by either pre-approving, confirming, or rejecting
			the request within the designated Booking Request Period. Failure to do so
			will result in the automatic expiration of the booking request. When a booking
			is requested, we will provide you with the first and last name of the Renter
			who made the request, as well as a link to their SpotLet Account profile page.
			If you are unable to confirm or decide to reject the booking request within the
			Booking Request Period, any amounts collected by Stripe for the requested
			booking will be refunded to the respective Renter. Once you confirm a
			booking requested by a Renter, SpotLet will send you a confirmation via
			email, text message, or through the Site, depending on your chosen
			communication preferences.`,
    para12: `RazorPay/Stripe will collect the Total Fees from Renters at the time of the
			booking request or upon the Lister's confirmation. In most cases, payment of
			the Property Fees (after deducting applicable fees and taxes) to the Lister and
			the Property Management Fees to the Property Manager will be initiated by
			RazorPay/Stripe within 24 hours of the Renter's scheduled check-in time at
			the relevant Property, unless a refund is due to the Renter.`,
    para13: `Listers, not SpotLet, are solely responsible for honoring confirmed bookings
			and ensuring the availability of Properties reserved through the Site and
			Services. If you, as a Renter, choose to proceed with a booking transaction
			with a Lister for a Property, you acknowledge and agree that you will be
			entering into an agreement with the Lister. You further agree to accept any
			terms, conditions, rules, and restrictions imposed by the Lister regarding the
			Property. You acknowledge that SpotLet is not a party to such agreements,
			and all liabilities arising from or related to these agreements are disclaimed by
			SpotLet (including its subsidiaries).`,
    para14: `The Total Fees payable will be presented to you as a Renter before you
			submit a booking request to a Lister. As mentioned earlier, the Lister is
			required to respond to the booking request within the designated Booking
			Request Period; otherwise, the requested booking will be automatically
			canceled. Upon receiving your booking request, RazorPay/Stripe will initiate a
			payment to confirm the booking for the requested dates. If a requested
			booking is canceled (i.e., not confirmed by the relevant Lister), any amounts
			collected by RazorPay/Stripe will be refunded to you as the Renter, based on
			your selections made through the Site, and any pre-authorization of your
			Payment Method, if applicable, will be released.`,
    para15: `As a Renter, you agree to pay the Total Fees, including the Processing Fee,
			for any booking requested and, in most cases, confirmed through your
			SpotLet Account. RazorPay/Stripe will collect the Total Fees in accordance
			with these Terms. Once your confirmed booking transaction is completed, you
			will receive a confirmation email summarizing the details of your booking.`,
  },
];

const heading10 = [
  {
    heading: `CANCELLATIONS AND REFUNDS`,
    subhead1: `Cancellations by Renters`,
    subhead2: `Cancellations by Listers`,
    subhead3: `Cancellations by SpotLet`,
    subhead4: `Modification of Bookings with Cancellation Protection`,
    subhead5: `Fraud and Fraud Prevention`,
    para1: `As a Renter, you have the option to cancel any reserved Booking before it is confirmed by the Lister without incurring any further obligations.`,
    para2: `Once a booking is confirmed by a Lister, the following cancellation and refund policies apply to Renters:`,
    para3: `Cancellation within 24 hours of booking: If a Renter cancels a booking within
			twenty-four (24) hours of confirmation, SpotLet's Grace Period cancellation
			policy applies, entitling the Renter to a full refund (unless there are less than 7
			days remaining until the shoot/event start, in which case the booking is
			non-refundable).`,
    para4: `Cancellation 7 days or fewer before the booking start date: If a Renter cancels
			a booking forty-eight (48) hours or fewer prior to the start of the booking
			period, the booking is non-refundable, and the Renter will be charged the
			Total Fees specified in the booking request.`,
    para5: `Cancellation more than 48 hours prior to the booking start date: If a Renter
			cancels a booking more than forty-eight (48) hours before the start of the
			booking period, the Renter will be charged fifty percent (50%) of the Total
			Fees indicated in the booking request.`,
    para6: `In the event that a Renter cancels a booking requiring a refund to the Renter
			or a payout to the Lister, as outlined in Section 9(f)(i), SpotLet will retain the
			Property Fees until the date on which the canceled booking was scheduled to
			begin. At that point, SpotLet will commence the processing of any applicable
			payments to the Renter and/or Lister in accordance with its standard payment
			policy. For all Renter-cancelled bookings, the Lister will be entitled to receive
			the Property Fees paid to SpotLet as outlined in Section 9(f)(i), minus the
			Service Fees payable to SpotLet. Failure of a Renter to comply with the
			cancellation provisions of Section 9(f) will result in the forfeiture of the
			Renter's right to any refund, and the Renter will be liable for the full Total
			Fees.`,
    para7: `The cancellation of a confirmed booking by a Lister can have significant
			logistical implications and impact the high standard of service provided by
			SpotLet's marketplace. Therefore, in the event of a Lister's cancellation of a
			confirmed booking, SpotLet reserves the right to impose penalties on Lister's
			account. These penalties may include the publication of an automated review
			indicating a canceled booking, as well as the suspension or termination of
			Lister's account. A-Lister must cancel a confirmed booking with at least 7
			calendar days' notice. Failure to comply with this requirement will result in
			SpotLet charging the Lister the applicable Service Fee and Processing Fee.
			Additionally, if a Lister cancels more than one confirmed booking, SpotLet
			may, at its sole discretion, withhold future payouts to the Lister to compensate
			for any losses incurred due to the Lister's initial cancellation. These losses
			may include but are not limited to, the additional costs incurred in securing a
			new booking for a displaced Renter`,
    para8: `In certain circumstances, SpotLet may determine, at its sole discretion, that it
			is necessary or desirable to cancel a confirmed booking or issue a refund to a
			Renter for all or part of the amounts charged. This may occur due to
			extenuating circumstances, emergencies, or other reasons. Additionally,
			SpotLet reserves the right to cancel any booking that it deems fictitious, at its
			sole discretion. By agreeing to these terms, you acknowledge that SpotLet
			and the relevant Renter or Lister will not be liable for any such cancellation or
			refund made by SpotLet.`,
    para9: `If a Renter requests a modification to their booking, for which Cancellation
			Protection was purchased, the following rules apply to the Cancellation
			Protection fee:`,
    para10: `If the price of the booking (Booking total) increases as a result of the
			modification, the Cancellation Protection fee will increase accordingly.`,
    para11: `If the price of the booking (Booking total) decreases as a result of the
			modification, the Cancellation Protection fee will remain unchanged.`,
    para12: `Despite SpotLet's efforts to detect and prevent fraudulent behavior, it is
			ultimately the Lister's responsibility to verify the identity of Renters who book
			their property. If a Lister confirms a booking and receives Property Fees from
			a fraudulent Renter, SpotLet reserves the right to collect the applicable
			Property Fees from the Lister after the payout has been issued. If the Lister
			refuses to return the applicable Property Fees to SpotLet, SpotLet may
			pursue legal means to recover them, and the Lister will also be required to
			reimburse SpotLet for all costs incurred in collecting the applicable Property
			Fees, including court costs and attorneys' fees. Additionally, SpotLet may, at
			its sole discretion, withhold future payouts to a Lister to compensate for any
			losses incurred as a result of the Lister's initial cancellation.`,
    para13: `Furthermore, SpotLet retains the right to withhold a Lister's payout related to
			potentially fraudulent Renter bookings for a period of 140 days after the
			payment is made on the platform to prevent the possibility of chargebacks
			related to Renters.`,
  },
];

const heading11 = [
  {
    heading: `RENTING A PROPERTY ON SPOTLET:`,
    para1: `As a Renter, it is your responsibility to obtain all necessary permits and
			insurance policies required by relevant authorities (including but not limited
			to filming permits and parking permits) for the filming process at the
			Property during the Booking Period. You must also acquire release forms
			and other relevant documents. SpotLet cannot be held accountable if you
			fail to obtain the required permits and documents for the filming process at
			the Property during the Booking Period.`,
  },
];

const heading12 = [
  {
    heading: `DAMAGE TO PROPERTIES:`,
    para1: `As a Renter, you are liable for leaving the Property in the same condition as
			it was when you arrived. You acknowledge and agree that you are
			responsible for your own actions and the actions of individuals you invite or
			grant access to the Property. If a Lister claims damage with evidence
			("Damage Claim"), such as photographs, you agree to reimburse the cost
			of replacing the damaged items with equivalent ones.`,
    para2: `Listers may include security deposits ("Security Deposits") in their Listings.
			Each Listing will specify if a Security Deposit is required for the respective
			Property. SpotLet will make reasonable efforts to address Listers' requests
			and claims regarding Security Deposits. However, SpotLet is not
			responsible for administering or accepting any Damage Claims made by
			Listers regarding Security Deposits and disclaims any liability in this regard.`,
    para3: `If a Lister makes a Damage Claim for a confirmed booking, you will be
			notified of the claim and given forty-eight (48) hours to respond. Stripe will
			collect the costs from you or deduct them from the Security Deposit.
			SpotLet/Stripe reserves the right to collect payment from you and take
			necessary actions, at SpotLet's sole discretion, if you have been
			determined to have caused damage to a Property, including payment
			requests based on a Damage Claim and payments made by SpotLet to
			Listers.`,
    para4: `Both Renters and Listers agree to cooperate with SpotLet and provide
			necessary information and assistance in good faith regarding Damage
			Claims or other complaints related to Properties or any personal or other
			property at a Property. If requested by SpotLet, Renters agree to participate
			in mediation or a similar resolution process with a Lister, facilitated by
			SpotLet or a third party selected by SpotLet, at no cost.`,
    para5: `If you are a Renter, you understand and agree that SpotLet may make a
			claim under your homeowner's, renter's, or other insurance policy for any
			damage or loss caused by you to a Property or any personal or other
			property at a Property. You agree to cooperate with SpotLet, provide
			requested information, and take necessary actions to assist in making an
			insurance claim, as reasonably requested by SpotLet.`,
    para6: `Regarding Security Deposits, if required by a Lister, they may be applied to
			any fees owed by a Renter for overstaying at a Listing without the Lister's
			consent. SpotLet holds a security deposit equal to 50% of the total location
			fee 48 hours prior to the start date of the booking, if applicable. This hold is
			authorized on the credit card used for the booking. If SpotLet fails to
			authorize the security deposit, the Renter's booking may be subject to the
			terms of the SpotLet cancellation policy.`,
    para7: ``,
  },
];

const heading13 = [
  {
    heading: `INSURANCE:`,
    para1: `SpotLet requires Listers and Renters to obtain insurance or review their
			existing policies to ensure coverage for any loss arising from the Booking
			and use of a Property by the Renter, guests, vendors, clients, customers, or
			licensees. This includes coverage for property damage, casualty, personal
			injury, fire, and general liability ("Liability Insurance"). SpotLet disclaims
			liability for failure by Listers or Renters to have Liability Insurance. If a
			Lister has not obtained Liability Insurance, SpotLet may require the Renter
			to purchase it before the booking start date. SpotLet is not liable for the
			Parties' failure to obtain Liability Insurance.`,
  },
];

const heading14 = [
  {
    heading: `OVERSTAYING WITHOUT THE LISTER'S CONSENT::`,
    para1: `Renters acknowledge that a confirmed booking grants them a license to
			enter and use the Listing for the specified duration and in accordance with
			the agreement with the Lister. Renters are only allowed to be present at the
			Property during the designated hours specified in the Listing or as mutually
			agreed upon with the Lister. Renters agree to vacate the Property no later
			than the checkout time specified in the Listing or as mutually agreed upon
			with the Lister. If a Renter exceeds the designated hours or checkout time
			without the Lister's consent, their license to stay in the Listing is revoked,
			and the Lister has the right to request their departure.`,
    para2: `In such cases, the Lister can charge the Renter an additional fee of 1.5
			times the average hourly Property Fee initially paid by the Renter for the
			inconveniences suffered by the Lister, along with applicable Service Fees,
			Taxes, and any legal expenses incurred by the Lister to enforce the
			Renter's departure. The Additional Sums will be charged in half-hour
			increments. If the Renter's production/event has more people on location
			than originally booked, SpotLet reserves the right to charge the Renter for
			the extra people, damages, and overages based on evidence provided by
			the Host. SpotLet may charge the Renter for any such overages based on
			the Location Agreement.`,
  },
];

const heading15 = [
  {
    heading: `USER CONDUCT:`,
    para1: `You acknowledge and accept that you are solely responsible for complying
			with all applicable laws, regulations, and tax obligations when using the
			Site, Services, and Collective Content. When using the Site, Services, and
			Collective Content, you agree not to:`,
    para2: `Violate any local, state, provincial, national, or other laws or regulations,
			including zoning restrictions and tax regulations, or disregard court orders.`,
    para3: `Use manual or automated software, devices, scripts, robots, backdoors, or
			any other means to access, scrape, crawl, or spider web pages or other
			services on the Site, Services, or Collective Content.`,
    para4: `Access or use the Site or Services to access or expose SpotLet Content that
			is not publicly displayed by SpotLet before a booking is confirmed, or in a
			manner inconsistent with the SpotLet Privacy Policy or Terms of Service, or
			that violates the privacy rights or any other rights of SpotLet's users or third
			parties.`,
    para5: `Use the Site, Services, or Collective Content for any commercial or
			unauthorized purposes that may falsely imply endorsement, partnership, or
			affiliation with SpotLet.`,
    para6: `Harm or diminish the SpotLet brand in any way, including unauthorized use of
			Collective Content, registering or using domain names, trade names,
			trademarks, or other identifiers similar to SpotLet's, or engaging in actions that
			dilute or confuse SpotLet's domains, trademarks, slogans, campaigns, or
			Collective Content.`,
    para7: `Copy, store, or access any information on the Site, Services, or Collective
			Content for purposes not explicitly allowed by these Terms.`,
    para8: `Infringe on SpotLet's or any other person's intellectual property, privacy,
			publicity, or contractual rights.`,
    para9: `Interfere with or damage the Site or Services, including using viruses, cancel
			bots, harmful code, denial-of-service attacks, or any other malicious methods
			or technology.`,
    para10: `Transmit or submit any information about any other person without their
			permission, including photographs, personal contact information, or financial
			details.`,
    para11: `Use the Site, Services, or Collective Content for the distribution of unsolicited
			commercial emails or unrelated advertisements.`,
    para12: `Stalk, harass, collect personally identifiable information about other users, or
			use such information for any purposes other than transacting as a SpotLet
			Renter or Lister.`,
    para13: `List a property that you do not own or have permission to rent, acting as a
			rental or listing agent without proper authorization.`,
    para14: `List a property that cannot be rented or subleased as per agreements with
			third parties, such as property rental agreements.`,
    para15: `Register for multiple SpotLet accounts or create an account on behalf of
			someone else.`,
    para16: `Request or book a stay at a property without actually using it for the intended
			purpose, in an attempt to avoid paying Service Fees or for any other reasons.`,
    para17: `Contact other users for purposes unrelated to bookings, properties, listings, or
			their use of the Site and Services.`,
    para18: `Recruit or solicit Listers or other users to join competitive services or websites
			without prior written approval from SpotLet.`,
    para19: `Recruit or solicit users to join third-party services or websites without
			SpotLet's prior written approval.`,
    para20: `Impersonate any person or entity or misrepresent yourself or your affiliation
			with any person or entity.`,
    para21: `Use automated scripts to interact with the Site, Services, or Collective
			Content.`,
    para22: `Use the Site, Services, or Collective Content to book a property outside of
			SpotLet to avoid paying Service Fees or for any other reasons.`,
    para23: `Submit a Listing with false or misleading information, including price
			information that you do not intend to honor.`,
    para24: `Violate these Terms.`,
    para25: `Engage in disruptive, abusive, or harassing behavior on the Site or Services.`,
    para26: `Post, upload, publish, submit, or transmit any content that infringes on
			third-party intellectual property rights, violates any law, is fraudulent, false,
			misleading, defamatory, obscene, offensive, promotes discrimination,
			violence, illegal activities, or harm.`,
    para27: `Systematically retrieve data from the Site or Services, create compilations,
			databases, or directories, or use bots, crawlers, or spiders without permission.`,
    para28: `Use or display SpotLet's name, trademarks, logos, or proprietary
			information without express written consent.`,
    para29: `Access non-public areas of the Site or Services, SpotLet's computer
			systems, or technical delivery systems without authorization.`,
    para30: `Attempt to probe, scan, or test the vulnerability of SpotLet's systems or
			breach security measures.`,
    para31: `Avoid, bypass, remove, deactivate, impair, or otherwise circumvent
			technological measures to protect the Site, Services, or Collective Content.`,
    para32: `Forge any header information or use the Site, Services, or Collective
			Content to send altered, deceptive, or false information.`,
    para33: `Advocate, encourage, or assist others in any of the above actions.`,
    para34: `Make or accept payment for Property Fees or Property Management Fees
			outside of SpotLet, acknowledging that it is a breach of these Terms and
			releasing SpotLet from liability for such payments.`,
    para35: `SpotLet reserves the right to investigate and take legal action against any violations
			mentioned above. Additionally, SpotLet may take various actions, such as
			deactivating or canceling Listings or SpotLet Accounts, for violations of this section
			or the Terms.`,
    para36: `SpotLet may access, preserve, and disclose user information if required by law or in
			good faith to respond to claims, enforce agreements, prevent fraud, assess risks,
			conduct investigations, provide customer support, and protect SpotLet's rights,
			users, or the public. While SpotLet has no obligation to monitor user activities or
			review User Content, it reserves the right to do so for operational, improvement,
			legal compliance, or objectionable content purposes. SpotLet may remove or disable
			access to any objectionable Collective Content at its sole discretion.`,
  },
];

const heading16 = [
  {
    heading: `REPORTING MISCONDUCT:`,
    para1: ` If you encounter or host someone who engages in inappropriate behavior,
			theft, or other disturbing conduct, you should report them immediately to
			the appropriate authorities and notify SpotLet with the police station and
			report number. However, SpotLet's response will be limited to legal
			requirements and will not incur any additional liability.`,
  },
];

const heading17 = [
  {
    heading: `PRIVACY:`,
    para1: `You agree that SpotLet's collection and use of your personal information
			are governed by SpotLet's Privacy Policy, which may be updated from time
			to time.`,
  },
];

const heading18 = [
  {
    heading: `OWNERSHIP OF INTELLECTUAL PROPERTY AND RIGHTS NOTICES:`,
    para1: `The Site, Services, and Collective Content are protected by copyright,
			trademark, and other laws of India and foreign countries. You acknowledge
			and agree that SpotLet and its licensors are the exclusive owners of the
			Site, Services, and Collective Content, including all associated intellectual
			property rights. You may not remove, alter, or obscure any copyright,
			trademark, service mark, or other proprietary rights notices incorporated in
			or accompanying the Site, Services, or Collective Content. SpotLet's
			trademarks, service marks, logos, trade names, and other proprietary
			designations used on or in connection with the Site, Services, and SpotLet
			Content are trademarks or registered trademarks of SpotLet in India and
			abroad. Trademarks, service marks, logos, trade names, and other
			proprietary designations of third parties used on or in connection with the
			Site, Services, and SpotLet Content are used for identification purposes
			only and may be the property of their respective owners. As a Lister,
			Renter, or User, you acknowledge and agree to be bound by additional
			Terms, Guidelines, and Policies applicable to your use of the Site, Services,
			and Collective Content.`,
  },
];

const heading19 = [
  {
    heading: `LICENSE FOR SPOTLET CONTENT AND USER CONTENT:`,
    para1: `Subject to your compliance with these Terms, SpotLet grants you a limited,
			non-exclusive, non-transferable license to (i) access and view SpotLet
			Content solely for personal and non-commercial purposes, and (ii) access
			and view User Content to which you have permission, solely for personal
			and non-commercial purposes. You are not permitted to use, copy, adapt,
			modify, create derivative works based on, distribute, license, sell, transfer,
			publicly display, publicly perform, transmit, broadcast, or exploit the Site,
			Services, or Collective Content unless expressly permitted in these Terms.
			No licenses or rights are granted to you implicitly or otherwise under any
			intellectual property rights owned or controlled by SpotLet or its licensors,
			except for the licenses and rights expressly granted in these Terms.`,
  },
];

const heading20 = [
  {
    heading: `USER CONTENT:`,
    para1: `At our discretion, we may allow you to post, upload, publish, submit, or
			transmit User Content. By making any User Content available on or
			through the Site, Services, or SpotLet promotional campaigns, you grant
			SpotLet a worldwide, irrevocable, perpetual, non-exclusive, transferable,
			royalty-free license, with the right to sublicense, to use, view, copy, adapt,
			modify, distribute, license, sell, transfer, publicly display, publicly perform,
			transmit, stream, broadcast, access, view, and exploit such User Content to
			promote or market the Site and Services. SpotLet does not claim
			ownership rights in any User Content, and nothing in these Terms restricts
			your rights to use and exploit your User Content.`,
    para2: `You acknowledge and agree that you are solely responsible for all User
			Content you make available through the Site, Services, or SpotLet
			promotional campaigns. You represent and warrant that (i) you own or have
			all necessary rights, licenses, consents, and releases to grant SpotLet the
			rights in your User Content, as outlined in these Terms, and (ii) your User
			Content and its use by SpotLet will not infringe, misappropriate, or violate
			any third party's intellectual property rights, privacy rights, or other
			proprietary rights, or any applicable law or regulation.`,
  },
];

const heading21 = [
  {
    heading: `LINKS:`,
    para1: `The Site and Services may include links to third-party websites or
			resources. You acknowledge and agree that SpotLet is not responsible or
			liable for (i) the availability or accuracy of such websites or resources, or (ii)
			the content, products, or services on or available from such websites or
			resources. The inclusion of links to such websites or resources does not
			imply endorsement by SpotLet. You assume all responsibility and risk for
			your use of any third.`,
  },
];

const heading22 = [
  {
    heading: `TERM AND TERMINATION, SUSPENSION, AND OTHER ACTIONS:`,
    subhead1: `Duration`,
    subhead2: `Voluntary Termination`,
    subhead3: `Termination for Breach, Suspension, and Other Actions`,
    subhead4: `Consequences`,
    subhead5: `Survival`,
    para1: `This Agreement will remain in effect for a period of 30 days, and upon its
			expiration, it will automatically renew for subsequent 30-day terms until either
			you or SpotLet terminate the Agreement as outlined below.`,
    para2: `You have the option to terminate this Agreement at any time by sending us an
			email. If you cancel your SpotLet Account as a Lister, any confirmed bookings
			will be canceled automatically, and your Renters will receive a full refund. If
			you cancel your SpotLet Account as a Renter, any confirmed booking will be
			automatically canceled, and any refund will be subject to the terms of the
			applicable cancellation policy`,
    para3: `SpotLet reserves the right to terminate this Agreement for convenience by
			providing you with a 30-day notice via email to your registered email address.`,
    para4: `SpotLet may immediately terminate this Agreement without notice if any of the
			following conditions are met: (i) you have materially breached these Terms or
			our Policies, including the warranties outlined in these Terms or the "User
			Conduct" provisions, (ii) you have provided inaccurate, fraudulent, outdated,
			or incomplete information during the SpotLet Account registration, listing
			process, or thereafter, (iii) you have violated applicable laws, regulations, or
			third-party rights, or (iv) SpotLet believes in good faith that such action is
			reasonably necessary to protect the safety or property of other Users,
			SpotLet, or third parties, for fraud prevention, risk assessment, security, or
			investigation purposes.`,
    para5: `Additionally, SpotLet may take the following actions: deactivate or delay
			Listings, reviews, or other User Content; cancel any pending or confirmed
			bookings; limit your access to or use of your SpotLet Account, the Site, or
			Services; temporarily or permanently revoke any special status associated
			with your SpotLet Account; or temporarily or permanently suspend your
			SpotLet Account, if (i) you have breached these Terms or our Policies,
			including both material and non-material breaches and receiving poor ratings
			from Listers or Renters, or (ii) SpotLet believes in good faith that such action
			is reasonably necessary to protect the safety or property of Users, SpotLet, or
			third parties, for fraud prevention, risk assessment, security, or investigation
			purposes.`,
    para6: `In the case of non-material breaches, you will receive notice from SpotLet
			regarding any measures taken and an opportunity to resolve the issue to
			SpotLet's reasonable satisfaction.`,
    para7: `If any of the aforementioned measures are taken, SpotLet may: (i) inform your
			Renters or Listers that a pending or confirmed booking has been canceled, (ii)
			provide full refunds to your Renters for all confirmed bookings, regardless of
			preexisting cancellation policies, (iii) exceptionally assist your Renters in
			finding potential alternative properties, and (iv) you will not be entitled to any
			compensation for canceled confirmed bookings.`,
    para8: `Upon termination of this Agreement, we are not obligated to delete or return
			any of your User Content, including reviews or feedback. Following
			termination, you are not entitled to restore your SpotLet Account or access
			your User Content. If your access to the Site and Services has been limited,
			your SpotLet Account has been suspended, or this Agreement has been
			terminated by us, you may not register a new SpotLet Account or attempt to
			access and use the Site and Services through other SpotLet Accounts.`,
    para9: `The clauses of these Terms that reasonably should survive the termination of
			this Agreement will remain in effect.`,
  },
];

const heading23 = [
  {
    heading: `DISCLAIMERS:`,
    para1: `By choosing to utilize the Site, Services, or Collective Content, you accept
			full responsibility for any associated risks. SpotLet acknowledges that it is
			not obliged to conduct background checks or registered sex offender
			checks on any user, including renters and listers. However, SpotLet
			reserves the right to perform such checks at its sole discretion. If these
			checks are carried out, SpotLet does not provide any express or implied
			warranties that these checks will uncover any previous misconduct by a
			user or ensure that a user will not engage in misconduct in the future.`,
    para2: `The Site, Services, and Collective Content are provided "as is" without any
			warranty, either express or implied. SpotLet explicitly disclaims any
			warranties of merchantability, fitness for a particular purpose, quiet
			enjoyment, non-infringement, and any warranties arising from the course of
			dealing or usage of trade. SpotLet does not guarantee that the Site,
			Services, or Collective Content, including listings or any properties, will
			meet your requirements or be available without interruptions, secure, or
			free from errors. SpotLet does not warrant the quality of any listings,
			properties, listers, renters, the accrual of SpotLet travel credits, the
			services, or collective content, nor the accuracy, timeliness, truthfulness,
			completeness, or reliability of any collective content obtained through the
			Site or Services.`,
    para3: `Any advice or information obtained from SpotLet or through the Site,
			Services, or Collective Content will not create any warranty not expressly
			stated in these terms. You are solely responsible for all your
			communications and interactions with other users of the Site or Services,
			including listers or renters, as well as any other individuals you
			communicate or interact with as a result of using the Site or Services.
			SpotLet does not attempt to verify the statements of users or review any
			properties. SpotLet makes no representations or warranties regarding the
			conduct of users or their compatibility with other users. It is your
			responsibility to exercise caution in all your communications and
			interactions, especially if you decide to meet offline or in person, regardless
			of whether SpotLet organizes such meetings. SpotLet explicitly disclaims
			all liability for any actions or omissions of renters or other third parties.`,
  },
];

const heading24 = [
  {
    heading: `LIMITATION OF LIABILITY:`,
    para1: `SpotLet users are responsible for their own actions and decisions when
			using the SpotLet Platform. SpotLet is not liable for any losses or damages
			that may occur as a result of using the SpotLet Platform, including but not
			limited to personal injury, property damage, or financial loss.`,
    para2: ` By using the SpotLet Platform, users agree to release and indemnify
			SpotLet and its affiliates from any and all claims, damages, losses,
			expenses, costs, and liabilities (including attorneys' fees) arising out of or in
			any way connected with their use of the SpotLet Platform.`,
    para3: `SpotLet does not guarantee the accuracy or completeness of the
			information provided on the SpotLet Platform. SpotLet is not liable for any
			errors or omissions in the information provided on the SpotLet Platform.`,
    para4: `Users are responsible for taking all necessary precautions to protect
			themselves from viruses, malware, and other harmful content that may be
			found on the SpotLet Platform.`,
    para5: `SpotLet is not liable for any damages that may occur as a result of using
			the SpotLet Platform, including but not limited to computer viruses,
			hardware failures, or software malfunctions.`,
    para6: ` If you have any questions about these terms and conditions, please contact
			SpotLet at _________________`,
  },
];

const heading25 = [
  {
    heading: `INDEMNIFICATION:`,
    para1: ` You hereby agree to indemnify, defend, and hold harmless SpotLet, its
			affiliates, subsidiaries, officers, directors, employees, and agents from any
			claims, liabilities, damages, losses, and expenses, including reasonable
			legal and accounting fees, arising out of or in connection with (a) your
			access to or use of the Site, Services, or Collective Content, or any
			violation of these Terms; (b) any User Content provided by you; (c) your
			interaction with any other User, your booking of a Property, or your creation
			of a Listing; (d) the use, condition, or rental of a Property by you, including
			any injuries, losses, or damages of any kind arising from a rental, booking,
			or use of a Property, whether compensatory, direct, incidental,
			consequential, or otherwise.`,
  },
];

const heading26 = [
  {
    heading: `ENTIRE AGREEMENT:`,
    para1: `These Terms, along with any additional SpotLet policies, guidelines,
			standards, or terms applicable to specific products, features, services, or
			offerings, constitute the complete and exclusive understanding and
			agreement between you and SpotLet regarding the Site, Services,
			Collective Content (excluding Payment Services), and any bookings or
			Listings of Properties made through the Site and Services (excluding
			Payment Services). These Terms supersede and replace any prior oral or
			written understandings or agreements between you and SpotLet regarding
			bookings or listings of Properties, the Site, Services, and Collective Content
			(excluding Payment Services).`,
  },
];

const heading27 = [
  {
    heading: `ASSIGNMENT:`,
    para1: `You may not assign or transfer these Terms, whether by operation of law or
			otherwise, without the prior written consent of SpotLet. Any attempted
			assignment or transfer without such consent will be null and void. SpotLet
			may assign or transfer these Terms at its sole discretion, without any
			restrictions. Subject to the foregoing, these Terms will be binding upon and
			inure to the benefit of the parties and their respective successors and
			permitted assigns.`,
  },
];

const heading28 = [
  {
    heading: `NOTICES:`,
    para1: `All notices or other communications permitted or required under these
			Terms, including those related to modifications of these Terms, shall be in
			writing and provided by SpotLet: (i) via email to the address you provide, or
			(ii) by posting on the Site. For notices sent by email, the date of receipt will
			be deemed the date on which the notice is transmitted.`,
  },
];

const heading29 = [
  {
    heading: `CONTROLLING LAW AND JURISDICTION:`,
    para1: `These Terms and your use of the Services will be interpreted in accordance
			with the laws of India, without regard to their conflict-of-law provisions. You
			and SpotLet agree to submit to the personal jurisdiction of the state court
			located in Telangana state, India, for any actions in which the parties retain
			the right to seek injunctive or other equitable relief to prevent the actual or
			threatened infringement, misappropriation, or violation of a party's
			copyrights, trademarks, trade secrets, patents, or other intellectual property
			rights, as stated in the Dispute Resolution provision below.`,
  },
];

const heading30 = [
  {
    heading: `DISPUTE RESOLUTION:`,
    para1: `If you reside in India, you and SpotLet agree that any dispute, claim, or
			controversy arising out of or relating to these Terms or their breach,
			termination, enforcement, interpretation, or validity, or the use of the
			Services or the Site or Collective Content (collectively referred to as
			"Disputes"), will be resolved through binding arbitration, except that each
			party retains the right to seek injunctive or other equitable relief in a court of
			competent jurisdiction to prevent the actual or threatened infringement,
			misappropriation, or violation of a party's copyrights, trademarks, trade
			secrets, patents, or other intellectual property rights. Additionally, unless
			mutually agreed otherwise in writing, the arbitrator may not consolidate
			more than one person's claims or preside over any form of className or
			representative proceeding. If this paragraph is deemed unenforceable, the
			entire "Dispute Resolution" section will be considered void, except for the
			preceding sentence, which will survive any termination of these Terms.`,
    para2: `In the event of a dispute, disagreement, or difference arising from, out of, or
			in relation to the terms specified in the Terms and Conditions(referred to as
			"Dispute"), SpotLet and You(collectively referred to as "Parties") shall
			attempt to resolve such Dispute amicably through mediation at the earliest
			opportunity.`,
    para3: `If mediation fails, the Parties shall resort to arbitration for effective and
			binding dispute resolution.The arbitrators shall be chosen jointly within two
			months.`,
    para4: `The arbitration proceedings shall be conducted in English.`,
    para5: `The arbitration shall be held in the court of Telangana, India unless
			otherwise specified in the award.`,
    para6: `Unless otherwise specified in the award, both parties shall bear the costs of
			the arbitration, including the fees of the arbitrators, equally.`,
    para7: `The awards and proceedings shall be carried out in accordance with the
			provisions of the applicable law.`,
    para8: `The award issued jointly by the arbitrators shall be considered final.`,
  },
];

const heading31 = [
  {
    heading: `HEALTH AND SAFETY MEASURES:`,
    para1: ` You acknowledge your responsibility to take all necessary health, safety,
			and hygiene measures to protect yourself, Listers, and Renters from
			potential infectious diseases or any other unhealthy, unsafe, or unsanitary
			conditions. You also acknowledge that you have read and will comply with
			SpotLet's COVID-19 Health & Safety Measures ("Safety Measures").
			SpotLet reserves the right to update these Safety Measures periodically at
			its discretion, so please review them frequently, including the current
			version at the time of your Booking.`,
  },
];

const heading32 = [
  {
    heading: `GENERAL:`,
    para1: `The failure of SpotLet to enforce any right or provision in these Terms will
			not constitute a waiver of future enforcement. Any waiver of a right or
			provision must be in writing and signed by an authorized representative of
			SpotLet. The exercise of any remedies by either party under these Terms
			will not prejudice their other remedies under these Terms or otherwise. If
			any provision of these Terms is found invalid or unenforceable by an
			arbitrator or court of competent jurisdiction, that provision will be enforced
			to the maximum extent permitted, and the other provisions will remain in full
			force and effect.`,
  },
];

const heading33 = [
  {
    heading: `THIRD-PARTY BENEFICIARY:`,
    para1: `These Terms do not grant rights or remedies to any person or entity other
			than the parties involved.`,
  },
];

const heading34 = [
  {
    heading: `CONTACTING SPOTLET:`,
    para1: `If you have any questions about these Terms, please contact SpotLet.`,
  },
];

const heading35 = [
  {
    heading: `FEEDBACK:`,
    para1: `SpotLet welcomes and encourages your feedback, comments, and
			suggestions for improving the Site and Services ("Feedback"). You may
			submit Feedback via email, through the "Contact" section of the Site, or
			through other communication channels. You acknowledge and agree that
			all Feedback you provide becomes the sole and exclusive property of
			SpotLet. You hereby assign and agree to assign all rights, title, and interest
			in the Feedback, including worldwide patent, copyright, trade secret, moral,
			and other proprietary or intellectual property rights, to SpotLet. You also
			waive any moral rights you may have in such Feedback. If requested by
			SpotLet, you will assist in acquiring, perfecting, and maintaining SpotLet's
			intellectual property rights and other legal protections for the Feedback, at
			SpotLet's expense`,
  },
];



//  ********* PrivacyPolicies Constant ********

const spotLetPrivacyPolicy = [
  {
    heading: `SpotLet operates as an online platform that bridges the gap between property listers who possess rental properties and renters searching for such spaces to accommodate various purposes, including but not limited to motion pictures, television, and diverse creative productions (collectively referred to as the "Services"). These Services are conveniently accessible through SpotLet.in and other associated websites through which SpotLet extends its offerings (collectively termed the "Site").`,
    info: '',
    numberOfParagraphs: 0,
  },
  {
    info: "",
    heading: `The primary objective of this Privacy Policy is to apprise you of our approach to handling Personal Information that we gather from you and about you. Should any aspect of this Privacy Policy be in disagreement with your preferences, we regretfully will be unable to provide you access to our Site or Services. In such instances, we kindly urge you to deactivate your account and abstain from utilizing any component of our Site.`,
    numberOfParagraphs: 0,
  },
];
const definitions = [
  {
    info: "",
    heading: `For the purpose of this Privacy Policy, terms such as "Listing," "Property," "Content," etc., shall carry the meanings defined in our Terms of Service.`,
    numberOfParagraphs: 0,
  },
  {
    info: "",
    heading: `"Affiliates" refers to companies that share common ownership or control. Such entities encompass both financial and non-financial companies.`,
    numberOfParagraphs: 0,
  },
  {
    info: "",
    heading: `"Aggregated Information" pertains to data concerning all users or specific groups/categories of users that are combined in a manner that prevents the identification or reference of individual users.`,
    numberOfParagraphs: 0,
  },
  {
    info: "",
    heading: `"Data Controller" signifies SpotLet, the company responsible for the utilization and processing of Personal Information.`,
    numberOfParagraphs: 0,
  },
  {
    info: "",
    heading: `"Third Parties" denotes companies or individuals who lack common ownership or control (i.e., non-affiliates) or individuals unrelated to you and SpotLet. This category encompasses both financial and non-financial companies.`,
    numberOfParagraphs: 0,
  },
  {
    info: "",
    heading: `"Personal Information" encompasses information, including sensitive details, pertaining to a living individual. This information either identifies the individual directly or can do so when combined with other information likely to come into the possession of the Data Controller.`,
    numberOfParagraphs: 0,
  },
];
const whatWeCollect = [
  {
    info: "",
    heading: `When you access or use our Site and Services, we collect, store, and process information, including Personal Information, that you provide. Examples of such instances include when you:`,
    numberOfParagraphs: 0,
  },
  {
    info: "",
    heading: `Fill in forms on the Site while registering, updating your user account, or submitting ID and verification information;`,
    numberOfParagraphs: 0,
  },
  {
    info: "",
    heading: `Engage with the Site by searching for or listing Properties, making or accepting bookings, making payments, posting reviews, or communicating with other users;`,
    numberOfParagraphs: 0,
  },
  {
    info: "",
    heading: `Link your SpotLet Account with a Third-Party Account. In such cases, we gather Personal Information you've shared with the Third-Party Account, as permitted by your Third-Party settings and your authorization;`,
    numberOfParagraphs: 0,
  },
  {
    info: "",
    heading: `Communicate with SpotLet; and Share information with another Member.`,
    numberOfParagraphs: 0,
  },
  {
    info: "",
    heading: `Information We Collect From Your Use of the Site`,
    numberOfParagraphs: 0,
  },
  {
    info: "",
    heading: `When you access or utilize our Site and Services, we also gather, store, and process information, which might include Personal Information. This collection encompasses but is not limited to:`,
    numberOfParagraphs: 0,
  },
  {
    heading: "Mobile Data",
    info: `If you use certain Site features, we may collect varying types of location information, such as general details (e.g., IP address, zip code) and more precise data (e.g., GPS-based information from mobile devices). Should you wish to prevent your device from providing location data, you can disable GPS or similar functions, subject to your device's capabilities. Refer to your device manufacturer's guidelines for specifics.`,
    numberOfParagraphs: 0,
  },
  {
    heading: "Log Data",
    info: `Our servers automatically record Log Data whenever you access the Site, regardless of registration or login status. Log Data includes information like your IP address, access time, hardware and software details, exit pages, clicks, device event data, pages viewed, and time spent on specific pages.`,
    numberOfParagraphs: 0,
  },
  {
    heading: "Cookies and Other Tracking Technologies",
    info: `SpotLet employs cookies and comparable technologies (e.g., device identifiers) on the Site. We may also permit business partners to use their tracking technologies on the Site. Consequently, using the Site enables you to provide information to us and our business partners.`,
    numberOfParagraphs: 0,
  },
  {
    info: "",
    heading: `While browser settings allow you to disable cookies, we don't modify our practices based on a "Do Not Track" signal in HTTP headers. We track your actions when you click on SpotLet service ads on Third-Party platforms like search engines and social networks. This tracking helps us enhance your experience.`,
    numberOfParagraphs: 0,
  },
  {
    info: "",
    heading: `Furthermore, we may continue to track your behavior on our Site for purposes like customer support, analytics, research, product development, fraud prevention, and more. This tracking also aids in marketing and advertising our services on the Site and Third-Party platforms. Third Parties that use cookies for targeted ads may provide opt-out options via industry group websites. Please note that even if you opt-out, you might still receive untargeted ads.`,
    numberOfParagraphs: 0,
  },
  {
    heading: "Third-Party Social Plugins",
    info: `Our Site may feature social plugins provided by Third Parties (e.g., Facebook's Like Button). If you interact with these plugins, certain information may be sent to the Third Party. When logged into your Third-Party Account, interactions may be linked. Refer to the Third Party's privacy policy to understand their data practices.`,
    numberOfParagraphs: 0,
  },
];
const yourConsent = [
  {
    info:
      "",
      heading: `By using our services, you explicitly provide your consent to receive communications from SpotLet and its representatives. These communications may include emails, telephone calls, text messages (via an automatic telephone dialing system or prerecorded voice), push notifications, or equivalent methods. Such communications may be directed to any of the email addresses and/or telephone numbers provided by you or on your behalf to SpotLet.`,
    numberOfParagraphs: 0,
  },
  {
    info: "",
    heading: `You acknowledge that these authorized communications could be initiated for various purposes, including transactional, customer service, advertising, marketing, promotional, debt collection, account administration, and other relevant reasons. Please note that your consent to receive prerecorded or autodialed promotional,advertising, or marketing (collectively referred to as "Marketing") calls or text messages is not a mandatory requirement for purchasing any property, goods, or services. `,
    numberOfParagraphs: 0,
  },
  {
    info: "",
    heading: `Should you wish to opt out of receiving marketing calls, emails, or text messages from us, you agree to initiate the opt-out process by following any unsubscribe instructions provided in the communications. Alternatively, you can reach out to our Customer Success team at contact@SpotLet.info for assistance. If you receive a marketing call and wish to opt out, you can convey a do-not-call request to our representative before ending the call.`,
    numberOfParagraphs: 0,
  },
  {
    info:
      "",
      heading: `It's important to note that SpotLet and our representatives won't be held responsible for honoring opt-out requests made through other channels. If you want to register for a SpotLet account without agreeing to receive autodialed or prerecorded marketing calls or text messages, you can seek assistance from our Customer Success representative at contact@SpotLet.info.`,
    numberOfParagraphs: 0,
  },
  {
    info:
      "",
      heading: `Please be aware that standard text messaging and data charges imposed by your wireless service provider may apply to the text messages we send. By providing any telephone number to us in connection with your account or use of our services, you affirm that you have the authority to consent to the receipt of calls and text messages at that number, as well as the associated carrier charges.`,
    numberOfParagraphs: 0,
  },
  {
    info:
      "",
      heading: `Kindly note that even if you opt out of marketing communications, SpotLet may continue to send you communications concerning your account, transactions, and interactions with other users of the platform, including services provided or received. Opting out of receiving text messages or other communications may impact your use of our services`,
    numberOfParagraphs: 0,
  },
];
const whatWeDo = [
  {
    info:
      "",
      heading: `A. Purpose of Information Usage, Storage, and Processing
    We utilize, store, and process your information for the following primary purposes:
    `,
    numberOfParagraphs: 0,
  },
  {
    info: "",
    heading: `To enable your access and use of the Site.`,
    numberOfParagraphs: 0,
  },
  {
    info: "",
    heading: `To facilitate communication between you and other Members, including sending messages or sharing information during the booking process.`,
    numberOfParagraphs: 0,
  },
  {
    info:
      "",
      heading: `To operate, safeguard, enhance, and optimize the Site, SpotLet's business, and user experiences. This encompasses activities like analytics, research, personalization, customer service, and performance improvement.`,
    numberOfParagraphs: 0,
  },
  {
    info:
      "",
      heading: `To establish and maintain a trustworthy and secure environment on the Site and Services. This includes identifying and preventing fraud and potentially harmful activities, conducting investigations and risk assessments, enforcing our Terms and policies, verifying the accuracy of listings, verifying the authenticity of your provided identifications, and conducting checks against databases and public sources (such as government databases) for fraud detection, risk evaluation, and harm prevention purposes. We may undertake these actions with or without prior notification to you.`,
    numberOfParagraphs: 0,
  },
  {
    info:
      "",
      heading: `To send you service-related, support, and administrative messages, updates, technical notifications, security alerts, and information you have requested.`,
    numberOfParagraphs: 0,
  },
  {
    info: "",
    heading: `To deliver marketing, advertising, promotional messages, and other content of potential interest to you. This may include information about SpotLet, our services, or general promotions related to partner campaigns and services. You can opt-out or unsubscribe from receiving such communications by adjusting your settings within your SpotLet Account or by messaging us through the Contact section on the Site.`,
    numberOfParagraphs: 1,
  },
  {
    info: "",
    heading: `To fulfill our legal responsibilities, resolve disputes, and enforce our agreements with Third Parties.`,
    numberOfParagraphs: 0,
  },
  {
    info:
      "",
      heading: `SpotLet's Use and Processing of User Communications`,
    numberOfParagraphs: 0,
  },
  {
    info:
      "",
      heading: `We may, whether directly or through Third Parties engaged to assist us, review, scan, or analyze the communications exchanged between you and other users via the Site. This review serves purposes like fraud prevention, risk assessment, regulatory compliance, investigation, product development, research, and customer support.`,
    numberOfParagraphs: 0,
  },
  {
    info:
      "",
      heading: `For instance, to prevent misleading project descriptions by Renters or to prevent fraudulent Listers from seeking payments outside the Site, the Site may scan and analyze messages as part of our fraud prevention efforts. Additionally, we may analyze messages for research and product development, aiming to enhance the efficiency and effectiveness of search, booking, and user interactions, and to improve and expand our range of offerings.`,
    numberOfParagraphs: 0,
  },
  {
    info: "",
    heading: `We assure you that we will not review, scan, or analyze your communications for sending Third-Party marketing messages. Moreover, we will not sell the results of these communication reviews or analyses to Third Parties. We may also employ automated methods for these reviews or analyses, where feasible. However, on occasion, manual review may be necessary. By using the Site, you consent to SpotLet's right, at its sole discretion, to review, scan, analyze, and store your communications, either directly or through engaged Third Parties. These reviews may be conducted manually or through automated processes.`,
    numberOfParagraphs: 1,
  },
];
const disclosing = [
  {
    info:
      "",
      heading: `We may transfer, store, use, and process your information, including Personal Information, to or within countries outside the India. Please be aware that privacy laws differ among jurisdictions. The privacy laws governing the places where your information is transferred, stored, used, or processed may vary from the laws of your residential location.`,
    numberOfParagraphs: 0,
  },
  {
    info: "",
    heading: `Disclosure of Your Personal Information
    Your Personal Information may be disclosed as follows:
    `,
    numberOfParagraphs: 0,
  },
  {
    info: "",
    heading: `Certain portions of your public profile page that contain Personal Information could be displayed in other sections of the Site for marketing reasons or if you post content in public forums or similar features on the Site.`,
    numberOfParagraphs: 0,
  },
  {
    info:
      "",
      heading: `Your public listing page will invariably include essential details like the city, area of the Property, your listing description, availability calendar, profile photo, and additional information shared with other Members. Some sections of your public listing page may be visible to other Members and/or Third-Party sites for marketing purposes.`,
    numberOfParagraphs: 0,
  },
  {
    info:
      "",
      heading: `The Site offers the possibility for your public profile and listing pages to appear in search engine results. This may involve indexing these pages by search engines and displaying them as search outcomes.`,
    numberOfParagraphs: 0,
  },
  {
    info:
      "",
      heading: `When you submit a booking request for a Property, certain Personal Information such as your full name, the full name of confirmed Renters in your Booking, and other agreed-upon Personal Information may become visible to the Listers of the Property you booked or intend to book. Parts of your public profile, reviews of you by other Members, and additional shared information may also be disclosed. If you opt to be contacted by the Listers via phone before your booking request is accepted, and the Listers decide to do so, SpotLet will contact your phone number before connecting you with the Listers. We will not share your phone number unless there is a confirmed booking; in such cases, your phone number may become visible to the Renter, who may contact you directly.`,
    numberOfParagraphs: 0,
  },
  {
    info: "",
    heading: `When your booking request for a Property is accepted by the Listers or when a Renter books your Property, certain Personal Information may be shared with the Listers and confirmed Renters. Nevertheless, your billing and payout information will not be shared with other users.`,
    numberOfParagraphs: 1,
  },
  {
    info: "",
    heading: `Upon a Lister's acceptance of your booking request for a Property, the Lister may use Third-Party Services through the SpotLet Site for managing the Property during shoot dates. The Lister may share information about the Renter with the Third-Party Service provider, facilitating coordination, including check-in/out details, rental agreements, rules, Renter details, and anonymized email addresses.`,
    numberOfParagraphs: 0,
  },
  {
    info:
      "",
      heading: `After booking a Property or having your Property booked, you may be requested to review the Lister, Renter, or Property. If you choose to provide a review, it may be made public on the Site. Likewise, a review of you by another Member may be publicly available.`,
    numberOfParagraphs: 0,
  },
  {
    info:
      "",
      heading: `You can link your SpotLet Account to a Third-Party social networking site. These contacts are referred to as "Friends". Linking your accounts may lead to:`,
    numberOfParagraphs: 0,
  },
  {
    info:
      "",
      heading: `Some information from the linked accounts being displayed on your SpotLet profile.`,
    numberOfParagraphs: 0,
  },
  {
    info: "",
    heading: `Display of your activities on the Site to your Friends on the Site and/or the Third-Party site.`,
    numberOfParagraphs: 1,
  },
  {
    info: "",
    heading: `Inclusion of a link to your public profile on the Third-Party social networking site in your SpotLet profile.`,
    numberOfParagraphs: 1,
  },
  {
    info: "",
    heading: `Storage, processing, and transmission of information for fraud prevention and risk assessment.`,
    numberOfParagraphs: 0,
  },
  {
    info:
      "",
      heading: `Display of information you provide to SpotLet via this linkage, based on your settings and authorizations on the Site and the Third-Party site.`,
    numberOfParagraphs: 0,
  },
  {
    info:
      "",
      heading: `Parts of the Site, including your Listings, may be distributed for display on sites operated by SpotLet's business partners and affiliates, using technologies like HTML widgets. In such cases, information from your public profile page may also be visible.`,
    numberOfParagraphs: 0,
  },
  {
    info:
      "",
      heading: `We may engage Third Parties, even if located outside the India, to provide services, including technology and identification verification services, fraud prevention, risk assessment, customer service, targeted ads, and payment facilitation. For example, if we hire Third Parties to conduct checks against public government databases, we may provide your Personal Information for such checks, with appropriate contractual obligations to protect your data.`,
    numberOfParagraphs: 0,
  },
  {
    info: "",
    heading: `By using the Site, you acknowledge and agree that SpotLet may access, preserve, and disclose your account information and posted content when required by law or in good faith belief that it is reasonably necessary for responding to claims, complying with legal processes, enforcing agreements, fraud prevention, customer support, product development, and safeguarding the rights, property, or personal safety of SpotLet, its users, or the public.`,
    numberOfParagraphs: 1,
  },
  {
    info: "",
    heading: `We may also publish, disclose, and use Aggregated Information and non-personal information for industry analysis, demographic profiling, marketing, advertising, and other business purposes.`,
    numberOfParagraphs: 1,
  },
];
const businessTransfers = [
  {
    info:
      "",
      heading: `If SpotLet engages in or is part of any merger, acquisition, reorganization, asset sale, bankruptcy, or insolvency event, it is possible that we may sell, transfer, or share some or all of our assets, including your Personal Information. Should such a circumstance arise, we will inform you prior to the transfer of your Personal Information, and this transfer will be subject to a distinct privacy policy.`,
    numberOfParagraphs: 0,
  },
];
const accessingChanging = [
  {
    info:
      "",
      heading: `You have the option to review, modify, rectify, or remove the Personal Information in your SpotLet account. If you wish to rectify your information or deactivate your SpotLet account, you can accomplish this by logging into your SpotLet account or by sending us an email. It's important to be aware that any reviews, forum posts, or similar content authored by you might persistently remain publicly accessible on the Site, linked to your first name, even if you choose to cancel your SpotLet account.`,
    numberOfParagraphs: 0,
  },
];
const securingYou = [
  {
    info:
      "",
      heading: `We are consistently enhancing and revising our administrative, technical, and physical security protocols to safeguard your Personal Information from unauthorized access, destruction, or alteration. Nevertheless, it's important to note that no method of transmitting data over the Internet or storing electronic information can ensure absolute security. Therefore, we cannot assure the complete security of the information you transmit to us or the Personal Information we store.`,
    numberOfParagraphs: 0,
  },
];
const yourPrivacy = [
  {
    heading:
      "Privacy While Accessing Third-Party Websites",
    info: `The Site may include links to external websites that are not owned or managed by SpotLet. SpotLet lacks authority over Third-Party websites. These external websites might install their own cookies, web beacons, or other files on your device, and they could gather and request Personal Information from you. These websites will adhere to their own guidelines regarding the collection, utilization, and sharing of Personal Information.We encourage you to read the terms of use and privacy policies of the other websites that you visit.`,
    numberOfParagraphs: 0,
  },
  // {
  //   heading: "Privacy While Accessing Third-Party Resources",
  //   info: `Some portions of the Site implement Google Maps/Earth mapping services, including Google Maps API(s). Your use of Google Maps/Earth is subject to Google’s terms of use (located at www.google.com/intl/en_us/help/terms_maps.html) and Google’s privacy policy (located at www.google.com/privacy.html), and may be amended by Google from time to time.`,
  //   numberOfParagraphs: 0,
  // },
];
const changesTo = [
  {
    info:
      "",
      heading: `We may change how we collect and then use Personal Information at any time and without prior notice, at our sole discretion. We may change this Privacy Policy at any time. If we make material changes to the Privacy Policy, we will notify you either by posting the changed Privacy Policy on the Site or by sending an email to you. We will also update the “Last Updated” date at the top of this Privacy Policy. If we let you know of changes through an email communication, then the date on which we send the email will be deemed to be the date of your receipt of that email.`,
    numberOfParagraphs: 0,
  },
  {
    info: "",
    heading: `It is important that you review the changed Privacy Policy. If you do not wish to agree to the changed Privacy Policy, then we will not be able to continue providing the Site and Services to you, and your only option will be to stop accessing the Site and Services and deactivate your SpotLet Account.`,
    numberOfParagraphs: 0,
  },
];
const feedback = [
  {
    info:
      "",
      heading: `We welcome and encourage you to provide feedback, comments and suggestions for improvements to the Site and Services ("Feedback"). You may submit Feedback by emailing us, through the "Contact" section of the Site, or by other means of communication. You acknowledge and agree that all Feedback you give us will be the sole and exclusive property of SpotLet and you hereby irrevocably assign to SpotLet and agree to irrevocably assign to SpotLet all of your right, title, and interest in and to all Feedback, including without limitation all worldwide patent, copyright, trade secret, moral and other proprietary or intellectual property rights therein, and waive any moral rights you may have in such Feedback. At SpotLet's request and expense, you will execute documents and take such further acts as SpotLet may reasonably request to assist SpotLet to acquire, perfect, and maintain its intellectual property rights and other legal protections for the Feedback.`,
    numberOfParagraphs: 0,
  },
];



export default function Constants({ type }) {

  const termsAndConditions = () => {
    console.log("yes");
    return (
      <>
        {subContent1.map((item, index) => (
          <div key={index} className="text-only-item">
            <div
              className="text-only-heading"
              style={{ fontSize: "20px", fontWeight: "600", marginTop: "2%" }}
            >
              {item.heading}
            </div>
            <ol>
              {item.para1 && (
                <li
                  className="text-only-info"
                  style={{ listStyleType: "disc" }}
                >
                  {item.para1}
                </li>
              )}
              {item.para2 && (
                <li
                  className="text-only-info"
                  style={{ listStyleType: "disc" }}
                >
                  {item.para2}
                </li>
              )}
            </ol>
          </div>
        ))}
        {subContent2.map((item, index) => (
          <div key={index} className="text-only-item">
            <div
              className="text-only-heading"
              style={{ fontSize: "20px", fontWeight: "600", marginTop: "2%" }}
            >
              {item.heading}
            </div>
            <ol>
              {item.para1 && (
                <li
                  className="text-only-info"
                  style={{ listStyleType: "disc" }}
                >
                  {item.para1}
                </li>
              )}
              {item.para2 && (
                <li
                  className="text-only-info"
                  style={{ listStyleType: "disc" }}
                >
                  {item.para2}
                </li>
              )}
            </ol>
          </div>
        ))}
        <div style={{ fontWeight: "bold" }}>
          Last Updated :-{" "}
          <span style={{ backgroundColor: "yellow" }}>25-May-2023</span>
        </div>
        {heading1.map((item, index) => (
          <div key={index} className="text-only-item">
            <div
              className="text-only-heading"
              style={{ fontSize: "20px", fontWeight: "600", marginTop: "2%" }}
            >{`${index + 1}.  ${item.heading}`}</div>
            <ol>
              {item.para1 && <li className="text-only-info">{item.para1}</li>}
              {item.para2 && <li className="text-only-info">{item.para2}</li>}
              {item.para3 && (
                <li
                  style={{ listStyleType: "disc" }}
                  className="text-only-info"
                >
                  {item.para3}
                </li>
              )}
              {item.para4 && (
                <li
                  style={{ listStyleType: "disc" }}
                  className="text-only-info"
                >
                  {item.para4}
                </li>
              )}
              {item.para5 && (
                <li
                  style={{ listStyleType: "disc" }}
                  className="text-only-info"
                >
                  {item.para5}
                </li>
              )}
              {item.para6 && (
                <li
                  style={{ listStyleType: "disc" }}
                  className="text-only-info"
                >
                  {item.para6}
                </li>
              )}
              {item.para7 && (
                <li
                  style={{ listStyleType: "disc" }}
                  className="text-only-info"
                >
                  {item.para7}
                </li>
              )}
              {item.para8 && (
                <li
                  style={{ listStyleType: "disc" }}
                  className="text-only-info"
                >
                  {item.para8}
                </li>
              )}
              {item.para9 && (
                <li
                  style={{ listStyleType: "disc" }}
                  className="text-only-info"
                >
                  {item.para9}
                </li>
              )}
              {item.para10 && (
                <li
                  style={{ listStyleType: "disc" }}
                  className="text-only-info"
                >
                  {item.para10}
                </li>
              )}
              {item.para11 && (
                <li
                  style={{ listStyleType: "disc" }}
                  className="text-only-info"
                >
                  {item.para11}
                </li>
              )}
              {item.para12 && (
                <li
                  style={{ listStyleType: "disc" }}
                  className="text-only-info"
                >
                  {item.para12}
                </li>
              )}
              {item.para13 && (
                <li
                  style={{ listStyleType: "disc" }}
                  className="text-only-info"
                >
                  {item.para13}
                </li>
              )}
            </ol>
          </div>
        ))}
        {heading2.map((item, index) => (
          <div key={index} className="text-only-item">
            <div
              className="text-only-heading"
              style={{ fontSize: "20px", fontWeight: "600", marginTop: "2%" }}
            >{`${index + 2}.  ${item.heading}`}</div>
            <ol>
              {item.para1 && <li className="text-only-info">{item.para1}</li>}
              {item.para2 && <li className="text-only-info">{item.para2}</li>}
              {item.para3 && <li className="text-only-info">{item.para3}</li>}
            </ol>
          </div>
        ))}
        {upperCaseText.map((item, index) => (
          <div key={index} className="text-only-item">
            <div
              className="text-only-info"
              style={{ fontSize: "17px", fontWeight: "600", marginTop: "1%" }}
            >
              {item.text1}
            </div>
            <div
              className="text-only-info"
              style={{ fontSize: "17px", fontWeight: "600", marginTop: "1%" }}
            >
              {item.text2}
            </div>
            <div
              className="text-only-info"
              style={{ fontSize: "17px", fontWeight: "600", marginTop: "1%" }}
            >
              {item.text3}
            </div>
            <div className="text-only-info">{item.text4}</div>
          </div>
        ))}
        {heading3.map((item, index) => (
          <div key={index} className="text-only-item">
            <div
              className="text-only-heading"
              style={{ fontSize: "20px", fontWeight: "600", marginTop: "2%" }}
            >{`${index + 3}.  ${item.heading}`}</div>
            <ol>
              {item.para1 && <li className="text-only-info">{item.para1}</li>}
            </ol>
          </div>
        ))}
        {heading4.map((item, index) => (
          <div key={index} className="text-only-item">
            <div
              className="text-only-heading"
              style={{ fontSize: "20px", fontWeight: "600", marginTop: "2%" }}
            >{`${index + 4}.  ${item.heading}`}</div>
            <ol>
              {item.para1 && <li className="text-only-info">{item.para1}</li>}
            </ol>
          </div>
        ))}
        {heading5.map((item, index) => (
          <div key={index} className="text-only-item">
            <div
              className="text-only-heading"
              style={{ fontSize: "20px", fontWeight: "600", marginTop: "2%" }}
            >{`${index + 5}.  ${item.heading}`}</div>
            <ol>
              {item.para1 && <li className="text-only-info">{item.para1}</li>}
              {item.para2 && <li className="text-only-info">{item.para2}</li>}
              {item.para3 && <li className="text-only-info">{item.para3}</li>}
            </ol>
          </div>
        ))}
        {heading6.map((item, index) => (
          <div key={index} className="text-only-item">
            <div
              className="text-only-heading"
              style={{ fontSize: "20px", fontWeight: "600", marginTop: "2%" }}
            >{`${index + 6}.  ${item.heading}`}</div>
            <ol>
              {item.para1 && <li className="text-only-info">{item.para1}</li>}
            </ol>
          </div>
        ))}
        {heading7.map((item, index) => (
          <div key={index} className="text-only-item">
            <div
              className="text-only-heading"
              style={{ fontSize: "20px", fontWeight: "600", marginTop: "2%" }}
            >{`${index + 7}.  ${item.heading}`}</div>
            <ol>
              {item.para1 && <li className="text-only-info">{item.para1}</li>}
              {item.para2 && <li className="text-only-info">{item.para2}</li>}
              {item.para3 && <li className="text-only-info">{item.para3}</li>}
              {item.para4 && <li className="text-only-info">{item.para4}</li>}
              {item.para5 && <li className="text-only-info">{item.para5}</li>}
            </ol>
          </div>
        ))}

        {heading8.map((item, index) => (
          <div key={index} className="text-only-item">
            <div
              className="text-only-heading"
              style={{ fontSize: "20px", fontWeight: "600", marginTop: "2%" }}
            >{`${index + 8}.  ${item.heading}`}</div>
            <ol>
              {item.para1 && <li className="text-only-info">{item.para1}</li>}
              {item.para2 && <li className="text-only-info">{item.para2}</li>}
              {item.para3 && <li className="text-only-info">{item.para3}</li>}
              {item.para4 && <li className="text-only-info">{item.para4}</li>}
              {item.para5 && <li className="text-only-info">{item.para5}</li>}
            </ol>
          </div>
        ))}
        {heading9.map((item, index) => (
          <div key={index} className="text-only-item">
            <div
              className="text-only-heading"
              style={{ fontSize: "20px", fontWeight: "600", marginTop: "2%" }}
            >{`${index + 9}.  ${item.heading}`}</div>
            <ol type="A">
              {item.subhead1 && (
                <li className="text-only-heading">{item.subhead1}</li>
              )}
              {item.para1 && (
                <li
                  className="text-only-info"
                  style={{ listStyleType: "disc" }}
                >
                  {item.para1}
                </li>
              )}
              {item.para2 && (
                <li
                  className="text-only-info"
                  style={{ listStyleType: "disc" }}
                >
                  {item.para2}
                </li>
              )}
              {item.para3 && (
                <li
                  className="text-only-info"
                  style={{ listStyleType: "disc" }}
                >
                  {item.para3}
                </li>
              )}
              {item.para4 && (
                <li
                  className="text-only-info"
                  style={{ listStyleType: "disc" }}
                >
                  {item.para4}
                </li>
              )}
              {item.para5 && (
                <li
                  className="text-only-info"
                  style={{ listStyleType: "disc" }}
                >
                  {item.para5}
                </li>
              )}
              {item.para6 && (
                <li
                  className="text-only-info"
                  style={{ listStyleType: "disc" }}
                >
                  {item.para6}
                </li>
              )}
              {item.para7 && (
                <li
                  className="text-only-info"
                  style={{ listStyleType: "disc" }}
                >
                  {item.para7}
                </li>
              )}
              {item.para8 && (
                <li
                  className="text-only-info"
                  style={{ listStyleType: "disc" }}
                >
                  {item.para8}
                </li>
              )}
              {item.para9 && (
                <li
                  className="text-only-info"
                  style={{ listStyleType: "disc" }}
                >
                  {item.para9}
                </li>
              )}
            </ol>
            <ol type="A" start={2}>
              {item.subhead2 && (
                <li className="text-only-heading">{item.subhead2}</li>
              )}
              {item.para10 && (
                <li
                  className="text-only-info"
                  style={{ listStyleType: "disc" }}
                >
                  {item.para10}
                </li>
              )}
            </ol>
            <ol type="A" start={3}>
              {item.subhead2 && (
                <li className="text-only-heading">{item.subhead3}</li>
              )}
              {item.para11 && (
                <li
                  className="text-only-info"
                  style={{ listStyleType: "disc" }}
                >
                  {item.para11}
                </li>
              )}
              {item.para12 && (
                <li
                  className="text-only-info"
                  style={{ listStyleType: "disc" }}
                >
                  {item.para12}
                </li>
              )}
            </ol>
            <ol type="A" start={4}>
              {item.subhead2 && (
                <li className="text-only-heading">{item.subhead4}</li>
              )}
              {item.para13 && (
                <li
                  className="text-only-info"
                  style={{ listStyleType: "disc" }}
                >
                  {item.para13}
                </li>
              )}
              {item.para14 && (
                <li
                  className="text-only-info"
                  style={{ listStyleType: "disc" }}
                >
                  {item.para14}
                </li>
              )}
              {item.para15 && (
                <li
                  className="text-only-info"
                  style={{ listStyleType: "disc" }}
                >
                  {item.para15}
                </li>
              )}
            </ol>
          </div>
        ))}
        {heading10.map((item, index) => (
          <div key={index} className="text-only-item">
            <div
              className="text-only-heading"
              style={{ fontSize: "20px", fontWeight: "600", marginTop: "2%" }}
            >{`${index + 10}.  ${item.heading}`}</div>
            <ol type="i">
              {item.subhead1 && (
                <li className="text-only-heading">{item.subhead1}</li>
              )}
              {item.para1 && (
                <li
                  className="text-only-info"
                  style={{ listStyleType: "disc" }}
                >
                  {item.para1}
                </li>
              )}
              {item.para2 && (
                <li
                  className="text-only-info"
                  style={{ listStyleType: "disc" }}
                >
                  {item.para2}
                </li>
              )}
              {item.para3 && (
                <li
                  className="text-only-info"
                  style={{ listStyleType: "disc" }}
                >
                  {item.para3}
                </li>
              )}
              {item.para4 && (
                <li
                  className="text-only-info"
                  style={{ listStyleType: "disc" }}
                >
                  {item.para4}
                </li>
              )}
              {item.para5 && (
                <li
                  className="text-only-info"
                  style={{ listStyleType: "disc" }}
                >
                  {item.para5}
                </li>
              )}
              {item.para6 && (
                <li
                  className="text-only-info"
                  style={{ listStyleType: "disc" }}
                >
                  {item.para6}
                </li>
              )}
            </ol>
            <ol type="i" start={2}>
              {item.subhead2 && (
                <li className="text-only-heading">{item.subhead2}</li>
              )}
              {item.para7 && (
                <li
                  className="text-only-info"
                  style={{ listStyleType: "disc" }}
                >
                  {item.para7}
                </li>
              )}
            </ol>
            <ol type="i" start={3}>
              {item.subhead3 && (
                <li className="text-only-heading">{item.subhead3}</li>
              )}
              {item.para8 && (
                <li
                  className="text-only-info"
                  style={{ listStyleType: "disc" }}
                >
                  {item.para8}
                </li>
              )}
            </ol>
            <ol type="i" start={4}>
              {item.subhead4 && (
                <li className="text-only-heading">{item.subhead4}</li>
              )}
              {item.para9 && (
                <li
                  className="text-only-info"
                  style={{ listStyleType: "disc" }}
                >
                  {item.para9}
                </li>
              )}
              {item.para10 && (
                <li
                  className="text-only-info"
                  style={{ listStyleType: "disc" }}
                >
                  {item.para10}
                </li>
              )}
              {item.para11 && (
                <li
                  className="text-only-info"
                  style={{ listStyleType: "disc" }}
                >
                  {item.para11}
                </li>
              )}
            </ol>
            <ol type="i" start={5}>
              {item.subhead5 && (
                <li className="text-only-heading">{item.subhead5}</li>
              )}
              {item.para12 && (
                <li
                  className="text-only-info"
                  style={{ listStyleType: "disc" }}
                >
                  {item.para12}
                </li>
              )}
              {item.para13 && (
                <li
                  className="text-only-info"
                  style={{ listStyleType: "disc" }}
                >
                  {item.para13}
                </li>
              )}
            </ol>
          </div>
        ))}

        {heading11.map((item, index) => (
          <div key={index} className="text-only-item">
            <div
              className="text-only-heading"
              style={{ fontSize: "20px", fontWeight: "600", marginTop: "2%" }}
            >{`${index + 11}.  ${item.heading}`}</div>
            <ol>
              {item.para1 && <li className="text-only-info">{item.para1}</li>}
            </ol>
          </div>
        ))}

        {heading12.map((item, index) => (
          <div key={index} className="text-only-item">
            <div
              className="text-only-heading"
              style={{ fontSize: "20px", fontWeight: "600", marginTop: "2%" }}
            >{`${index + 12}.  ${item.heading}`}</div>
            <ol>
              {item.para1 && <li className="text-only-info">{item.para1}</li>}
              {item.para2 && <li className="text-only-info">{item.para2}</li>}
              {item.para3 && <li className="text-only-info">{item.para3}</li>}
              {item.para4 && <li className="text-only-info">{item.para4}</li>}
              {item.para5 && <li className="text-only-info">{item.para5}</li>}
              {item.para6 && <li className="text-only-info">{item.para6}</li>}
              {item.para7 && <li className="text-only-info">{item.para7}</li>}
            </ol>
          </div>
        ))}

        {heading13.map((item, index) => (
          <div key={index} className="text-only-item">
            <div
              className="text-only-heading"
              style={{ fontSize: "20px", fontWeight: "600", marginTop: "2%" }}
            >{`${index + 13}.  ${item.heading}`}</div>
            <ol>
              {item.para1 && <li className="text-only-info">{item.para1}</li>}
            </ol>
          </div>
        ))}
        {heading14.map((item, index) => (
          <div key={index} className="text-only-item">
            <div
              className="text-only-heading"
              style={{ fontSize: "20px", fontWeight: "600", marginTop: "2%" }}
            >{`${index + 14}.  ${item.heading}`}</div>
            <ol>
              {item.para1 && <li className="text-only-info">{item.para1}</li>}
              {item.para2 && <li className="text-only-info">{item.para2}</li>}
            </ol>
          </div>
        ))}
        {heading15.map((item, index) => (
          <div key={index} className="text-only-item">
            <div
              className="text-only-heading"
              style={{ fontSize: "20px", fontWeight: "600", marginTop: "2%" }}
            >{`${index + 15}.  ${item.heading}`}</div>
            <ol>
              {item.para1 && <li className="text-only-info">{item.para1}</li>}
            </ol>
            <ol type="A">
              {item.para2 && <li className="text-only-info">{item.para2}</li>}
              {item.para3 && <li className="text-only-info">{item.para3}</li>}
              {item.para4 && <li className="text-only-info">{item.para4}</li>}
              {item.para5 && <li className="text-only-info">{item.para5}</li>}
              {item.para6 && <li className="text-only-info">{item.para6}</li>}
              {item.para7 && <li className="text-only-info">{item.para7}</li>}
              {item.para8 && <li className="text-only-info">{item.para8}</li>}
              {item.para9 && <li className="text-only-info">{item.para9}</li>}
              {item.para10 && <li className="text-only-info">{item.para10}</li>}
              {item.para11 && <li className="text-only-info">{item.para11}</li>}
              {item.para12 && <li className="text-only-info">{item.para12}</li>}
              {item.para13 && <li className="text-only-info">{item.para13}</li>}
              {item.para14 && <li className="text-only-info">{item.para14}</li>}
              {item.para15 && <li className="text-only-info">{item.para15}</li>}
              {item.para16 && <li className="text-only-info">{item.para16}</li>}
              {item.para17 && <li className="text-only-info">{item.para17}</li>}
              {item.para18 && <li className="text-only-info">{item.para18}</li>}
              {item.para19 && <li className="text-only-info">{item.para19}</li>}
              {item.para20 && <li className="text-only-info">{item.para20}</li>}
              {item.para21 && <li className="text-only-info">{item.para21}</li>}
              {item.para22 && <li className="text-only-info">{item.para22}</li>}
              {item.para23 && <li className="text-only-info">{item.para23}</li>}
              {item.para24 && <li className="text-only-info">{item.para24}</li>}
              {item.para25 && <li className="text-only-info">{item.para25}</li>}
              {item.para26 && <li className="text-only-info">{item.para26}</li>}
              {item.para27 && <li className="text-only-info">{item.para27}</li>}
              {item.para28 && <li className="text-only-info">{item.para28}</li>}
              {item.para29 && <li className="text-only-info">{item.para29}</li>}
              {item.para30 && <li className="text-only-info">{item.para30}</li>}
              {item.para31 && <li className="text-only-info">{item.para31}</li>}
              {item.para32 && <li className="text-only-info">{item.para32}</li>}
              {item.para33 && <li className="text-only-info">{item.para33}</li>}
              {item.para34 && <li className="text-only-info">{item.para34}</li>}
            </ol>
            <div className="text-only-info">{item.para35}</div>
            <div className="text-only-info">{item.para36}</div>
          </div>
        ))}
        {heading16.map((item, index) => (
          <div key={index} className="text-only-item">
            <div
              className="text-only-heading"
              style={{ fontSize: "20px", fontWeight: "600", marginTop: "2%" }}
            >{`${index + 16}.  ${item.heading}`}</div>
            <ol>
              {item.para1 && <li className="text-only-info">{item.para1}</li>}
            </ol>
          </div>
        ))}
        {heading17.map((item, index) => (
          <div key={index} className="text-only-item">
            <div
              className="text-only-heading"
              style={{ fontSize: "20px", fontWeight: "600", marginTop: "2%" }}
            >{`${index + 17}.  ${item.heading}`}</div>
            <ol>
              {item.para1 && <li className="text-only-info">{item.para1}</li>}
            </ol>
          </div>
        ))}
        {heading18.map((item, index) => (
          <div key={index} className="text-only-item">
            <div
              className="text-only-heading"
              style={{ fontSize: "20px", fontWeight: "600", marginTop: "2%" }}
            >{`${index + 18}.  ${item.heading}`}</div>
            <ol>
              {item.para1 && <li className="text-only-info">{item.para1}</li>}
            </ol>
          </div>
        ))}
        {heading19.map((item, index) => (
          <div key={index} className="text-only-item">
            <div
              className="text-only-heading"
              style={{ fontSize: "20px", fontWeight: "600", marginTop: "2%" }}
            >{`${index + 19}.  ${item.heading}`}</div>
            <ol>
              {item.para1 && <li className="text-only-info">{item.para1}</li>}
            </ol>
          </div>
        ))}
        {heading20.map((item, index) => (
          <div key={index} className="text-only-item">
            <div
              className="text-only-heading"
              style={{ fontSize: "20px", fontWeight: "600", marginTop: "2%" }}
            >{`${index + 20}.  ${item.heading}`}</div>
            <ol>
              {item.para1 && <li className="text-only-info">{item.para1}</li>}
              {item.para2 && <li className="text-only-info">{item.para2}</li>}
            </ol>
          </div>
        ))}
        {heading21.map((item, index) => (
          <div key={index} className="text-only-item">
            <div
              className="text-only-heading"
              style={{ fontSize: "20px", fontWeight: "600", marginTop: "2%" }}
            >{`${index + 21}.  ${item.heading}`}</div>
            <ol>
              {item.para1 && <li className="text-only-info">{item.para1}</li>}
            </ol>
          </div>
        ))}
        {heading22.map((item, index) => (
          <div key={index} className="text-only-item">
            <div
              className="text-only-heading"
              style={{ fontSize: "20px", fontWeight: "600", marginTop: "2%" }}
            >{`${index + 22}.  ${item.heading}`}</div>
            <ol type="A">
              {item.subhead1 && (
                <li className="text-only-heading">{item.subhead1}</li>
              )}
              {item.para1 && (
                <li
                  className="text-only-info"
                  style={{ listStyleType: "disc" }}
                >
                  {item.para1}
                </li>
              )}
            </ol>
            <ol type="A" start={2}>
              {item.subhead2 && (
                <li className="text-only-heading">{item.subhead2}</li>
              )}
              {item.para2 && (
                <li
                  className="text-only-info"
                  style={{ listStyleType: "disc" }}
                >
                  {item.para2}
                </li>
              )}
              {item.para31 && (
                <li
                  className="text-only-info"
                  style={{ listStyleType: "disc" }}
                >
                  {item.para4}
                </li>
              )}
            </ol>
            <ol type="A" start={3}>
              {item.subhead2 && (
                <li className="text-only-heading">{item.subhead3}</li>
              )}
              {item.para4 && (
                <li
                  className="text-only-info"
                  style={{ listStyleType: "disc" }}
                >
                  {item.para4}
                </li>
              )}
              {item.para5 && (
                <li
                  className="text-only-info"
                  style={{ listStyleType: "disc" }}
                >
                  {item.para5}
                </li>
              )}
              {item.para6 && (
                <li
                  className="text-only-info"
                  style={{ listStyleType: "disc" }}
                >
                  {item.para6}
                </li>
              )}
            </ol>
            <ol type="A" start={4}>
              {item.subhead2 && (
                <li className="text-only-heading">{item.subhead4}</li>
              )}
              {item.para7 && (
                <li
                  className="text-only-info"
                  style={{ listStyleType: "disc" }}
                >
                  {item.para7}
                </li>
              )}
              {item.para8 && (
                <li
                  className="text-only-info"
                  style={{ listStyleType: "disc" }}
                >
                  {item.para8}
                </li>
              )}
            </ol>
            <ol type="A" start={5}>
              {item.subhead5 && (
                <li className="text-only-heading">{item.subhead5}</li>
              )}
              {item.para1 && (
                <li
                  className="text-only-info"
                  style={{ listStyleType: "disc" }}
                >
                  {item.para1}
                </li>
              )}
            </ol>
          </div>
        ))}
        {heading23.map((item, index) => (
          <div key={index} className="text-only-item">
            <div
              className="text-only-heading"
              style={{ fontSize: "20px", fontWeight: "600", marginTop: "2%" }}
            >{`${index + 23}.  ${item.heading}`}</div>
            <ol>
              {item.para1 && <li className="text-only-info">{item.para1}</li>}
              {item.para2 && <li className="text-only-info">{item.para2}</li>}
              {item.para3 && <li className="text-only-info">{item.para3}</li>}
            </ol>
          </div>
        ))}
        {heading24.map((item, index) => (
          <div key={index} className="text-only-item">
            <div
              className="text-only-heading"
              style={{ fontSize: "20px", fontWeight: "600", marginTop: "2%" }}
            >{`${index + 24}.  ${item.heading}`}</div>
            <ol>
              {item.para1 && <li className="text-only-info">{item.para1}</li>}
              {item.para2 && <li className="text-only-info">{item.para2}</li>}
              {item.para3 && <li className="text-only-info">{item.para3}</li>}
              {item.para4 && <li className="text-only-info">{item.para4}</li>}
              {item.para5 && <li className="text-only-info">{item.para5}</li>}
              {item.para6 && <li className="text-only-info">{item.para6}</li>}
            </ol>
          </div>
        ))}
        {heading25.map((item, index) => (
          <div key={index} className="text-only-item">
            <div
              className="text-only-heading"
              style={{ fontSize: "20px", fontWeight: "600", marginTop: "2%" }}
            >{`${index + 25}.  ${item.heading}`}</div>
            <ol>
              {item.para1 && <li className="text-only-info">{item.para1}</li>}
            </ol>
          </div>
        ))}
        {heading26.map((item, index) => (
          <div key={index} className="text-only-item">
            <div
              className="text-only-heading"
              style={{ fontSize: "20px", fontWeight: "600", marginTop: "2%" }}
            >{`${index + 26}.  ${item.heading}`}</div>
            <ol>
              {item.para1 && <li className="text-only-info">{item.para1}</li>}
            </ol>
          </div>
        ))}
        {heading27.map((item, index) => (
          <div key={index} className="text-only-item">
            <div
              className="text-only-heading"
              style={{ fontSize: "20px", fontWeight: "600", marginTop: "2%" }}
            >{`${index + 27}.  ${item.heading}`}</div>
            <ol>
              {item.para1 && <li className="text-only-info">{item.para1}</li>}
            </ol>
          </div>
        ))}
        {heading28.map((item, index) => (
          <div key={index} className="text-only-item">
            <div
              className="text-only-heading"
              style={{ fontSize: "20px", fontWeight: "600", marginTop: "2%" }}
            >{`${index + 28}.  ${item.heading}`}</div>
            <ol>
              {item.para1 && <li className="text-only-info">{item.para1}</li>}
            </ol>
          </div>
        ))}
        {heading29.map((item, index) => (
          <div key={index} className="text-only-item">
            <div
              className="text-only-heading"
              style={{ fontSize: "20px", fontWeight: "600", marginTop: "2%" }}
            >{`${index + 29}.  ${item.heading}`}</div>
            <ol>
              {item.para1 && <li className="text-only-info">{item.para1}</li>}
            </ol>
          </div>
        ))}
        {heading30.map((item, index) => (
          <div key={index} className="text-only-item">
            <div
              className="text-only-heading"
              style={{ fontSize: "20px", fontWeight: "600", marginTop: "2%" }}
            >{`${index + 30}.  ${item.heading}`}</div>
            <ol>
              {item.para1 && <li className="text-only-info">{item.para1}</li>}
              {item.para2 && <li className="text-only-info">{item.para2}</li>}
              {item.para3 && <li className="text-only-info">{item.para3}</li>}
              {item.para4 && <li className="text-only-info">{item.para4}</li>}
              {item.para5 && <li className="text-only-info">{item.para5}</li>}
              {item.para6 && <li className="text-only-info">{item.para6}</li>}
              {item.para7 && <li className="text-only-info">{item.para7}</li>}
              {item.para8 && <li className="text-only-info">{item.para8}</li>}
            </ol>
          </div>
        ))}
        {heading31.map((item, index) => (
          <div key={index} className="text-only-item">
            <div
              className="text-only-heading"
              style={{ fontSize: "20px", fontWeight: "600", marginTop: "2%" }}
            >{`${index + 31}.  ${item.heading}`}</div>
            <ol>
              {item.para1 && <li className="text-only-info">{item.para1}</li>}
            </ol>
          </div>
        ))}
        {heading32.map((item, index) => (
          <div key={index} className="text-only-item">
            <div
              className="text-only-heading"
              style={{ fontSize: "20px", fontWeight: "600", marginTop: "2%" }}
            >{`${index + 32}.  ${item.heading}`}</div>
            <ol>
              {item.para1 && <li className="text-only-info">{item.para1}</li>}
            </ol>
          </div>
        ))}
        {heading33.map((item, index) => (
          <div key={index} className="text-only-item">
            <div
              className="text-only-heading"
              style={{ fontSize: "20px", fontWeight: "600", marginTop: "2%" }}
            >{`${index + 33}.  ${item.heading}`}</div>
            <ol>
              {item.para1 && <li className="text-only-info">{item.para1}</li>}
            </ol>
          </div>
        ))}
        {heading34.map((item, index) => (
          <div key={index} className="text-only-item">
            <div
              className="text-only-heading"
              style={{ fontSize: "20px", fontWeight: "600", marginTop: "2%" }}
            >{`${index + 34}.  ${item.heading}`}</div>
            <ol>
              {item.para1 && <li className="text-only-info">{item.para1}</li>}
            </ol>
          </div>
        ))}
        {heading35.map((item, index) => (
          <div key={index} className="text-only-item">
            <div
              className="text-only-heading"
              style={{ fontSize: "20px", fontWeight: "600", marginTop: "2%" }}
            >{`${index + 35}.  ${item.heading}`}</div>
            <ol>
              {item.para1 && <li className="text-only-info">{item.para1}</li>}
            </ol>
          </div>
        ))}
      </>
    );
  };

  const privacyPolicy = () => {
    return (
      <>
        <div className="text-only-info SpotLetContent">
          SpotLet.in recognises the importance of maintaining your privacy. We
          value your privacy and appreciate your trust in us. This Policy
          describes how we treat user information we collect on{" "}
          <a href="http://www.spotlet.co" style={{ color: "#ea4335" }}>
            http://www.spotlet.co
          </a>{" "}
          and other offline sources. This Privacy Policy applies to current and
          former visitors to our website and to our online customers. By
          visiting and/or using our website, you agree to this Privacy Policy
        </div>
        <br />
        <br />
        <div className="text-only-info SpotLetContent">
          SpotLet.in is a property of SpotLet solutions Private Limited, an
          Indian Company registered under the Companies Act, 2013 having its
          registered office at 4th Floor, Above Manjeera Mart, KPHB 9th Phase,
          Hyderabad – 500085, Telangana. India.
        </div>
        <br />
        <br />
        <div style={{ fontSize: "24px" }}>
          <strong>SpotLet Privacy Policy</strong>
        </div>

        {spotLetPrivacyPolicy.map((item, index) => (
          <div key={index} className="text-only-item SpotLetContent">
            <div
              className="text-only-heading"
              style={{ fontSize: "14px", marginTop: "4%" }}
            >{`${index + 1}.  ${item.heading}`}</div>
            <div className="text-only-info SpotLetContent">
              {item.info} {item.numberOfParagraphs === 1 && item.info}
            </div>
            {item.numberOfParagraphs > 1 && (
              <div className="text-only-info SpotLetContent">{item.info}</div>
            )}
          </div>
        ))}

        <br />
        <br />
        <div style={{ fontSize: "18px" }}>
          <strong>Definitions</strong>
        </div>
        {definitions.map((item, index) => (
          <div key={index} className="text-only-item">
            <div
              className="text-only-heading"
              style={{ fontSize: "14px", marginTop: "4%" }}
            >{`${index + 1}.  ${item.heading}`}</div>
            <div className="text-only-info">
              {item.info} {item.numberOfParagraphs === 1 && item.info}
            </div>
            {item.numberOfParagraphs > 1 && (
              <div className="text-only-info">{item.info}</div>
            )}
          </div>
        ))}

        <br />
        <br />
        <div style={{ fontSize: "18px" }}>
          <strong>What We Collect</strong>
        </div>
        {whatWeCollect.map((item, index) => (
          <div key={index} className="text-only-item">
            <div
              className="text-only-heading"
              style={{ fontSize: "14px", marginTop: "4%" }}
            >{`${index + 1}.  ${item.heading}`}</div>
            <div className="text-only-info">
              {item.info} {item.numberOfParagraphs === 1 && item.info}
            </div>
            {item.numberOfParagraphs > 1 && (
              <div className="text-only-info">{item.info}</div>
            )}
          </div>
        ))}

        <br />
        <br />
        <div style={{ fontSize: "18px" }}>
          <strong>Your Consent to Receive Calls, Text Messages, Emails, and Other Communications</strong>
        </div>
        {yourConsent.map((item, index) => (
          <div key={index} className="text-only-item">
            <div
              className="text-only-heading"
              style={{ fontSize: "14px", marginTop: "4%" }}
            >{`${index + 1}.  ${item.heading}`}</div>
            <div className="text-only-info">
              {item.info} {item.numberOfParagraphs === 1 && item.info}
            </div>
            {item.numberOfParagraphs > 1 && (
              <div className="text-only-info">{item.info}</div>
            )}
          </div>
        ))}


        <br />
        <br />
        <div style={{ fontSize: "18px" }}>
          <strong>What We Do With The Information We Gather</strong>
        </div>
        {whatWeDo.map((item, index) => (
          <div key={index} className="text-only-item">
            <div
              className="text-only-heading"
              style={{ fontSize: "14px", marginTop: "4%" }}
            >{`${index + 1}.  ${item.heading}`}</div>
            <div className="text-only-info">
              {item.info} {item.numberOfParagraphs === 1 && item.info}
            </div>
            {item.numberOfParagraphs > 1 && (
              <div className="text-only-info">{item.info}</div>
            )}
          </div>
        ))}

        <br />
        <br />
        <div style={{ fontSize: "18px" }}>
          <strong>Disclosing Your Personal Information</strong>
        </div>
        {disclosing.map((item, index) => (
          <div key={index} className="text-only-item">
            <div
              className="text-only-heading"
              style={{ fontSize: "14px", marginTop: "4%" }}
            >{`${index + 1}.  ${item.heading}`}</div>
            <div className="text-only-info">
              {item.info} {item.numberOfParagraphs === 1 && item.info}
            </div>
            {item.numberOfParagraphs > 1 && (
              <div className="text-only-info">{item.info}</div>
            )}
          </div>
        ))}

        <br />
        <br />
        <div style={{ fontSize: "18px" }}>
          <strong>Business Transfers by SpotLet</strong>
        </div>
        {businessTransfers.map((item, index) => (
          <div key={index} className="text-only-item">
            <div
              className="text-only-heading"
              style={{ fontSize: "14px", marginTop: "4%" }}
            >{`${index + 1}.  ${item.heading}`}</div>
            <div className="text-only-info">
              {item.info} {item.numberOfParagraphs === 1 && item.info}
            </div>
            {item.numberOfParagraphs > 1 && (
              <div className="text-only-info">{item.info}</div>
            )}
          </div>
        ))}

        <br />
        <br />
        <div style={{ fontSize: "18px" }}>
          <strong>Accessing, Changing, or Deleting your Information and/or SpotLet Account</strong>
        </div>
        {accessingChanging.map((item, index) => (
          <div key={index} className="text-only-item">
            <div
              className="text-only-heading"
              style={{ fontSize: "14px", marginTop: "4%" }}
            >{`${index + 1}.  ${item.heading}`}</div>
            <div className="text-only-info">
              {item.info} {item.numberOfParagraphs === 1 && item.info}
            </div>
            {item.numberOfParagraphs > 1 && (
              <div className="text-only-info">{item.info}</div>
            )}
          </div>
        ))}

        <br />
        <br />
        <div style={{ fontSize: "18px" }}>
          <strong>Securing You Personal Information</strong>
        </div>
        {securingYou.map((item, index) => (
          <div key={index} className="text-only-item">
            <div
              className="text-only-heading"
              style={{ fontSize: "14px", marginTop: "4%" }}
            >{`${index + 1}.  ${item.heading}`}</div>
            <div className="text-only-info">
              {item.info} {item.numberOfParagraphs === 1 && item.info}
            </div>
            {item.numberOfParagraphs > 1 && (
              <div className="text-only-info">{item.info}</div>
            )}
          </div>
        ))}

        <br />
        <br />
        <div style={{ fontSize: "18px" }}>
          <strong>Your Privacy While Accessing Third-Party Websites and Resources</strong>
        </div>
        {yourPrivacy.map((item, index) => (
          <div key={index} className="text-only-item">
            <div
              className="text-only-heading"
              style={{ fontSize: "14px", marginTop: "4%" }}
            >{`${index + 1}.  ${item.heading}`}</div>
            <div className="text-only-info">
              {item.info} {item.numberOfParagraphs === 1 && item.info}
            </div>
            {item.numberOfParagraphs > 1 && (
              <div className="text-only-info">{item.info}</div>
            )}
          </div>
        ))}
        <div className="text-only-item">
            <div
              className="text-only-heading"
              style={{ fontSize: "14px", marginTop: "4%" }}
            >Privacy While Accessing Third-Party Resources</div>
            <div className="text-only-info">
            Some portions of the Site implement Google Maps/Earth mapping services, including Google Maps API(s). Your use of Google Maps/Earth is subject to Google’s terms of use 
            (located at 
            <a href = "https://www.google.com/intl/en_us/help/terms_maps.html" target="_blank" style={{ color: "#ea4335" }}> Google terms  </a>
            ) and Google’s privacy policy (located at 
            <a href = "https://www.google.com/privacy.html" style={{ color: "#ea4335" }} target="_blank"> Google privacy policy </a>
            ), 
            and may be amended by Google from time to time. 
            </div>
          </div>

        <br />
        <br />
        <div style={{ fontSize: "18px" }}>
          <strong>Changes to this Privacy Policy</strong>
        </div>
        {changesTo.map((item, index) => (
          <div key={index} className="text-only-item">
            <div
              className="text-only-heading"
              style={{ fontSize: "14px", marginTop: "4%" }}
            >{`${index + 1}.  ${item.heading}`}</div>
            <div className="text-only-info">
              {item.info} {item.numberOfParagraphs === 1 && item.info}
            </div>
            {item.numberOfParagraphs > 1 && (
              <div className="text-only-info">{item.info}</div>
            )}
          </div>
        ))}

        <br />
        <br />
        <div style={{ fontSize: "18px" }}>
          <strong>Feedback</strong>
        </div>
        {feedback.map((item, index) => (
          <div key={index} className="text-only-item">
            <div
              className="text-only-heading"
              style={{ fontSize: "14px", marginTop: "4%" }}
            >{`${index + 1}.  ${item.heading}`}</div>
            <div className="text-only-info">
              {item.info} {item.numberOfParagraphs === 1 && item.info}
            </div>
            {item.numberOfParagraphs > 1 && (
              <div className="text-only-info">{item.info}</div>
            )}
          </div>
        ))}
      </>
    );
  };

  return (
    <>
      {type === "terms" && termsAndConditions()}
      {type === "privacy" && privacyPolicy()}
    </>
  );
}
