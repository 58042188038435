import { Header } from "../components/NewHeader/Header";
import { Sidebar } from "../components/Sidebar";
import { useNavigate } from "react-router-dom";
// import { BookingsTable } from "../components/BookingsTable";
import { BookingsRequestsTable } from "../components/BookingsRequestsTable";

// Images
import draftList from "../assets/NewImages/Listings/draft-list.svg";
import wDraftList from "../assets/NewImages/Listings/w-draft-list.svg";
import listings from "../assets/NewImages/BookingList/listing.svg";
import wListings from "../assets/NewImages/BookingList/w-listing.svg";

export const RequestedBookingList = () => {
  const navigate = useNavigate()

  return (
    <>
      <Header />
      <div className="container-fluid p-0">
        <div className="row g-0">
          <div className="d-flex edit-profile my-list">
            <div className="col-lg-2 d-none d-lg-block w-200">
              <Sidebar />
            </div>
            <div className="col-lg-10  staticPageHtml">
              <form className="h-100 needs-validation" noValidate>
                <div className="step-form h-100">
                  <div className="e-profile">
                    <h3 className="etitle">Booking Requests</h3>
                    <div className="creative-space m-0">
                    <div class="row">
                        <div class="col-12">
                          <ul class="nav nav-pills" id="pills-tab" role="tablist">
                            <li class="nav-item" role="presentation">
                              <button class="nav-link first" id="pills-listing-tab" onClick={() => navigate('/listed-properties')} data-bs-toggle="pill" data-bs-target="#pills-listing" type="button" role="tab" aria-controls="pills-listing" aria-selected="false">
                                <img class="reg" src={listings} />
                                <img class="click" src={wListings} />
                                Listed Properties
                              </button>
                            </li>
                            <li class="nav-item" role="presentation">
                              <button class="nav-link last" id="pills-draft-list-tab" onClick={() => navigate('/incomplete-listings')} data-bs-toggle="pill" data-bs-target="#pills-draft-list" type="button" role="tab" aria-controls="pills-draft-list" aria-selected="false">
                                <img class="reg" src={draftList} />
                                <img class="click" src={wDraftList} />
                                Incomplete Listings
                              </button>
                            </li>
                            <li class="nav-item" role="presentation">
                              <button class="nav-link last active" id="pills-draft-list-tab" onClick={() => navigate('/booking-requests')} data-bs-toggle="pill" data-bs-target="#pills-draft-list" type="button" role="tab" aria-controls="pills-draft-list" aria-selected="false">
                                <img class="reg" src={draftList} />
                                <img class="click" src={wDraftList} />
                                Booking Requests
                              </button>
                            </li>
                          </ul>
                        </div>
                      </div>

                      <div class="tab-content" id="pills-tabContent">
                        {/* <div class="tab-pane fade active show" id="pills-booking" role="tabpanel" aria-labelledby="pills-booking-tab">
                          <BookingsTable />
                        </div> */}
                        <div class="tab-pane fade active show" id="pills-listing" role="tabpanel" aria-labelledby="pills-listing-tab">
                          <BookingsRequestsTable />
                        </div>

                      </div>


                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
