import React, { useState, useEffect } from "react";
import { useLocationStore } from "../../store/location";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import { Box, Typography, Divider } from '@mui/material';
import { getAllCities } from "../../apis/api";
import { getGky } from "../../utils/baseUrl";

// Images
import info from "../../assets/NewImages/BecomeAHost/i.svg";

import GoogleMap from "./GoogleMap";
import GooglePlacesAutocomplete from 'react-google-places-autocomplete';
import { geocodeByPlaceId, getLatLng } from 'react-google-places-autocomplete';
import { ConfirmationModel } from '../Modal/ConfirmationModel';
const getLocationLink = 'https://maps.googleapis.com/maps/api/geocode/json?latlng'
const reqKey = getGky();

const Location = ({data,handleBack, handleNext }) => {

    // const { location: locationData } = useLocationStore();
    const [showConfirmationModel, setShowConfirmationModel] = useState(false);
    const [searchGoogle, setSearchGoogle] = useState(false)
    const [showForm, setShowForm] = useState(false)
    const domain = window.location.hostname;

    const [formData, setFormData] = useState({
        country: "",
        state: "",
        city: "",
        pincode: "",
        address: "",
        area: "",
        landmark: "",
        location_detail: "",
        googlePlaceId: "",
        longitude: "",
        latitude: "",
        mapLink: "",
        googlePlaces: false,
        liveLocation: false,
    });
    const [formErrors, setFormErrors] = useState({});
    const [formErrorMessages, setFormErrorMessages] = useState({
        address: "Location Address is required",
    });
    const [showmap, setShowmap] = useState(false);
    const [useLiveLocation, setUseLiveLocation] = useState(false);
    const [currentLocation, setCurrentLocation] = useState(null);
    const [searchValue, setSearchValue] = useState(null);
    const [cityData, setCitysData] = useState([]);

    const handleChange = (e) => {
        const { name, value } = e.target;
        if (!searchGoogle || !useLiveLocation) {  //adding changes in to address filed.
            const { location_detail, landmark, area, city, state, country, pincode } = formData;
            let updatedAddress = "";
            let updatedValue = value;
            if (name === "location_detail") {
                updatedAddress = [value, landmark, area, city, state, country, pincode].filter(Boolean).join(", ");
            } else if (name === "landmark") {
                updatedAddress = [location_detail, value, area, city, state, country, pincode].filter(Boolean).join(", ");
            } else if (name === "area") {
                updatedAddress = [location_detail, landmark, value, city, state, country, pincode].filter(Boolean).join(", ");
            } else if (name === "city") {
                updatedAddress = [location_detail, landmark, area, value, state, country, pincode].filter(Boolean).join(", ");
            } else if (name === "state") {
                updatedAddress = [location_detail, landmark, area, city, value, country, pincode].filter(Boolean).join(", ");
            } else if (name === "country") {
                updatedAddress = [location_detail, landmark, area, city, state, value, pincode].filter(Boolean).join(", ");
            } else if (name === "pincode") {
                updatedAddress = [location_detail, landmark, area, city, state, country, value].filter(Boolean).join(", ");
                updatedValue = value.replace(/\D/g, ""); // Remove non-digit characters from the pincode
            }  else if (name === "address") {
                updatedAddress = value
            }
            setFormData({
                ...formData,
                [name]: updatedValue,
                address: updatedAddress,
            });
        } else {
            setFormData({
                ...formData,
                [name]: value,
            });
        }
    };
    const initLocationTypes = async (country,state) => {
        try {
            const data = await getAllCities({country,state});
            const removeDiacritics = (str) => str.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
            let objectsArray = []
            data?.data?.data?.map((item) => {
                const exists = objectsArray.some(each => each.label === removeDiacritics(item));
                if(!exists){
                    objectsArray.push(
                        {
                            label: removeDiacritics(item),
                            value: removeDiacritics(item)
                        }
                    )
                }
            });
            setCitysData(objectsArray);
        } catch (e) {
            console.log(e);
        }
    };
    useEffect(() => {
        // initLocationTypes();
        const element = document.getElementById(`listLocation`)
        if (element) element.scrollIntoView({behavior: "smooth",block: "start"});
    }, []);

    const showLocation = () => {
        setShowmap(false);
        setTimeout(() => {
            setShowmap(true);
        }, 500);
    };

    // const handleBack = () => {
    //     setTab("property_desc");
    // };

    const handleSubmit = () => {
        const payload = formData;
        if (
            formData?.location_detail?.trim().length == 0 ||
            formData?.country?.trim().length == 0 ||
            formData?.state?.trim().length == 0 ||
            formData?.city?.trim().length == 0 ||
            formData?.pincode?.trim().length == 0 ||
            formData?.area?.trim().length == 0
        ) {
            setFormErrors({
                ...formErrors,
                address: formErrorMessages.address,
            });
            setShowConfirmationModel(true);
            return;
        }
        if (localStorage.getItem("locationId")) {
            callbackFn({ property_address: payload });
            setTab("amenities");
        }
    };

    // useEffect(() => {
    //     if (locationData && locationData?.property_address) {
    //         const { property_address } = locationData;
    //         const tdata = Object.keys(formData).reduce((a, c) => {
    //             a[c] = property_address[c] || "";
    //             return a;
    //         }, {});
    //         setFormData({
    //             ...formData,
    //             ...tdata,
    //         });
    //         showLocation();
    //         setSearchGoogle(tdata.googlePlaces)
    //         setUseLiveLocation(tdata.liveLocation)
    //     }
    // }, [locationData]);

    const handleLocationSelect = async (value) => {

        const [place] = await geocodeByPlaceId(value.place_id);

        setFormErrors({
            ...formErrors,
            address: ""
        });
        const { long_name: postalCode = '' } =
            place.address_components.find(c => c.types.includes('postal_code')) || {};

        const { long_name: country = '' } =
            place.address_components.find(c => c.types.includes('country')) || {};

        const { long_name: state = '' } =
            place.address_components.find(c => c.types.includes('administrative_area_level_1')) || {};

        const { long_name: city = '' } =
            place.address_components.find(c => c.types.includes('locality')) || {};

        const { long_name: sublocality3 = '' } =
            place.address_components.find(c => c.types.includes('sublocality_level_3')) || {};

        const { long_name: sublocality2 = '' } =
            place.address_components.find(c => c.types.includes('sublocality_level_2')) || {};

        const { long_name: sublocality1 = '' } =
            place.address_components.find(c => c.types.includes('sublocality_level_1')) || {};

        const { long_name: landmark = '' } =
            place.address_components.find(c => c.types.includes('neighborhood')) || {};

        let area = '';
        if (sublocality3 && sublocality3 !== '') {
            area = sublocality3;
        }
        if (sublocality2 && sublocality2 !== '') {
            area = area !== '' ? area + ', ' + sublocality2 : sublocality2;
        }
        if (sublocality1 && sublocality1 !== '') {
            area = area !== '' ? area + ', ' + sublocality1 : sublocality1;
        }
        if (!area) {
            area = city;
        }

        const gPlaceId = place?.place_id;

        let lat = '';
        let lng = '';

        // await geocodeByPlaceId(gPlaceId)
        //   //.then(results => console.log("geocodeByPlaceId ",results))
        //   .then(results => getLatLng(results[0]))
        //   .then(latLng => {
        //     //console.log('geocodeByPlaceId Success latLng : ', latLng);
        //     lat = latLng.lat
        //     lng = latLng.lng
        //   })
        //   .catch(error => console.error(error));


        setFormData({
            ...formData,
            longitude: lng,
            latitude: lat,
            pincode: postalCode,
            country: country,
            state: state,
            // city: city,
            city: '',
            area: area,
            landmark: landmark,
            address: value.description,
            location_detail: value.description,
            googlePlaceId: gPlaceId,
            mapLink: `https://www.google.com/maps/place/?q=place_id:${value.place_id}`,
            googlePlaces: true,
            liveLocation: false,
        });
        setUseLiveLocation(false);
        setShowForm(true)
        initLocationTypes(country,state)
        showLocation();
    }

    const changeLocationSearch = (event) => {
        const checked = event.target.checked;
        setSearchGoogle(checked);
        if (checked) {
            setUseLiveLocation(false)
        }
        if(!checked){
            setShowForm(false)
        }
        setFormData({
            country: "",
            state: "",
            city: "",
            pincode: "",
            area: "",
            landmark: "",
            address: "",
            location_detail: "",
            googlePlaceId: "",
            longitude: "",
            latitude: "",
            mapLink: "",
            googlePlaces: false,
            liveLocation: false,
        });
    };

    const handleLiveLocationChange = async (event) => {
        const { checked } = event.target;
        setUseLiveLocation(checked);
        setSearchGoogle(false);
        if (event.target.checked) {
            if (navigator.geolocation) {
                navigator.geolocation.getCurrentPosition(
                    async (position) => {
                        const { latitude, longitude } = position.coords;
                        try {
                            const response = await fetch(
                                `${getLocationLink}=${latitude},${longitude}&key=${reqKey}`
                            );
                            const data = await response.json();
                            if (data.results.length > 0) {
                                const { address_components, formatted_address, place_id } = data.results[0];

                                const postalCodeComponent = address_components.find(c => c.types.includes('postal_code'));
                                const postalCode = postalCodeComponent ? postalCodeComponent.long_name : '';

                                const countryComponent = address_components.find(c => c.types.includes('country'));
                                const country = countryComponent ? countryComponent.long_name : '';

                                const stateComponent = address_components.find(c => c.types.includes('administrative_area_level_1'));
                                const state = stateComponent ? stateComponent.long_name : '';

                                const cityComponent = address_components.find(c => c.types.includes('locality'));
                                const city = cityComponent ? cityComponent.long_name : '';

                                const sublocality3Component = address_components.find(c => c.types.includes('sublocality_level_3'));
                                const sublocality3 = sublocality3Component ? sublocality3Component.long_name : '';

                                const sublocality2Component = address_components.find(c => c.types.includes('sublocality_level_2'));
                                const sublocality2 = sublocality2Component ? sublocality2Component.long_name : '';

                                const sublocality1Component = address_components.find(c => c.types.includes('sublocality_level_1'));
                                const sublocality1 = sublocality1Component ? sublocality1Component.long_name : '';

                                const politicalComponent = address_components.find(c => c.types.includes('political'));
                                const political = politicalComponent ? politicalComponent.long_name : '';

                                let area = '';
                                if (sublocality3 && sublocality3 !== '') {
                                    area = sublocality3;
                                }
                                if (sublocality2 && sublocality2 !== '') {
                                    area = area !== '' ? area + ', ' + sublocality2 : sublocality2;
                                }
                                if (sublocality1 && sublocality1 !== '') {
                                    area = area !== '' ? area + ', ' + sublocality1 : sublocality1;
                                }
                                if (!area) {
                                    area = city;
                                }
                                setFormData({
                                    ...formData,
                                    longitude: longitude,
                                    latitude: latitude,
                                    pincode: postalCode,
                                    country: country,
                                    state: state,
                                    city: '',
                                    // city: city,
                                    area: area,
                                    landmark: "",
                                    address: formatted_address,
                                    location_detail: formatted_address,
                                    googlePlaceId: place_id,
                                    mapLink: `https://www.google.com/maps/search/?api=1&query=${latitude},${longitude}`,
                                    googlePlaces: false,
                                    liveLocation: true,
                                });
                                initLocationTypes(country,state)
                                showLocation();
                            }
                        } catch (error) {
                            console.error("Error fetching current location:", error);
                        }
                    },
                    (error) => {
                        console.error("Error getting current location:", error);
                    }
                );
            }
        } else {
            setFormData({
                ...formData,
                country: "",
                state: "",
                city: "",
                pincode: "",
                area: "",
                landmark: "",
                address: "",
                location_detail: "",
                googlePlaceId: "",
                mapLink: "",
                googlePlaces: false,
                liveLocation: false,
            });
            setShowForm(false)
        }
    };

    const setFormDataFromLocation = async (latitude, longitude, googlePlaces, liveLocation) => {
        try {
            setUseLiveLocation(true);
            setSearchGoogle(false);
            const response = await fetch(
                `${getLocationLink}=${latitude},${longitude}&key=${reqKey}`
            );
            const data = await response.json();
            if (data.results.length > 0) {
                const { address_components, formatted_address, place_id } = data.results[0];

                const postalCodeComponent = address_components.find(c => c.types.includes('postal_code'));
                const postalCode = postalCodeComponent ? postalCodeComponent.long_name : '';

                const countryComponent = address_components.find(c => c.types.includes('country'));
                const country = countryComponent ? countryComponent.long_name : '';

                const stateComponent = address_components.find(c => c.types.includes('administrative_area_level_1'));
                const state = stateComponent ? stateComponent.long_name : '';

                const cityComponent = address_components.find(c => c.types.includes('locality'));
                const city = cityComponent ? cityComponent.long_name : '';

                const sublocality3Component = address_components.find(c => c.types.includes('sublocality_level_3'));
                const sublocality3 = sublocality3Component ? sublocality3Component.long_name : '';

                const sublocality2Component = address_components.find(c => c.types.includes('sublocality_level_2'));
                const sublocality2 = sublocality2Component ? sublocality2Component.long_name : '';

                const sublocality1Component = address_components.find(c => c.types.includes('sublocality_level_1'));
                const sublocality1 = sublocality1Component ? sublocality1Component.long_name : '';

                const politicalComponent = address_components.find(c => c.types.includes('political'));
                const political = politicalComponent ? politicalComponent.long_name : '';

                let area = '';
                if (sublocality3 && sublocality3 !== '') {
                    area = sublocality3;
                }
                if (sublocality2 && sublocality2 !== '') {
                    area = area !== '' ? area + ', ' + sublocality2 : sublocality2;
                }
                if (sublocality1 && sublocality1 !== '') {
                    area = area !== '' ? area + ', ' + sublocality1 : sublocality1;
                }
                if (!area) {
                    area = city;
                }
                setFormData({
                    ...formData,
                    longitude: longitude,
                    latitude: latitude,
                    pincode: postalCode,
                    country: country,
                    state: state,
                    city: '',
                    // city: city,
                    area: area,
                    landmark: "",
                    address: formatted_address,
                    location_detail: formatted_address,
                    googlePlaceId: place_id,
                    mapLink: `https://www.google.com/maps/search/?api=1&query=${latitude},${longitude}`,
                    googlePlaces: googlePlaces,
                    liveLocation: liveLocation,
                });
                initLocationTypes(country,state)
                // showLocation();
            }
        } catch (error) {
            console.error("Error fetching current location:", error);
        }
    }

    const updateSelectLocation = (selectedGooglePlaceId, latitude, longitude) => {
        setFormDataFromLocation(latitude, longitude, true, false);
    };

    useEffect(() => {
        if(data){
            setFormData(data)
            setShowForm(true)
            initLocationTypes(data?.country,data?.state);
            if(data?.googlePlaceId) setShowmap(true)
            if(data?.liveLocation){
                setSearchGoogle(false)
                setUseLiveLocation(true)
            }
            else{
                setSearchGoogle(true)
                setSearchValue(data?.address)
            }
        }
    },[])



    return (
        <form onSubmit={() =>handleNext({address : formData,region : formData?.country})}>
            <div
            className="tab-pane h-100 fade show active locationtype-page"
            id="v-pills-location"
            role="tabpanel"
            aria-labelledby="v-pills-location-tab"
            >
                <h3 className="locationtype-head" id='listLocation'>Location{" "}
                    <a
                        href="#"
                        data-bs-toggle="tooltip"
                        title="Current Location, Google Maps integration for precise location details."
                    >
                        <img src={info} />
                    </a>
                </h3>

                <div className="locationCard tabCard row g-0">
                    {/* Google Map Started  */}
                    <div className="col-xl-6">
                            <p> At your property, would you like to use your current location or pick a place using Google Maps? </p>
                            <div className=" location-checkbox-container">
                                <div className="location-select">
                                    <div className="form-check ">
                                        <label className="form-check-label" htmlFor="liveLocation">
                                            Current Location <img src={info}  title='Select the current location if you are at the property'/>
                                        </label>
                                        <span>&nbsp;</span>
                                        <input
                                            className="form-check-input"
                                            type="checkbox"
                                            id="liveLocation"
                                            checked={useLiveLocation}
                                            onChange={handleLiveLocationChange}
                                        />
                                    </div>
                                </div>
                                <div className="location-select">
                                    <div className="form-check">
                                        <label className="form-check-label" htmlFor="location">
                                            Google Maps <img src={info}  title='Enter the property name or the nearby landmark and drag the pin'/>
                                        </label>
                                        <span>&nbsp;</span>
                                        <input
                                            className="form-check-input"
                                            type="checkbox"
                                            id="location"
                                            checked={searchGoogle}
                                            onChange={(event) => { changeLocationSearch(event); }}
                                        />
                                    </div>
                                </div>
                                <div className="location-select">
                                    <div>
                                        <div className="form-group " style={{ width: "240px" }} >
                                            <div className="ui-widget">
                                                {(searchGoogle || formData?.googlePlaces) && 
                                                    <GooglePlacesAutocomplete apiKey={reqKey}
                                                        selectProps={{
                                                            searchValue,
                                                            onChange: (text) => { handleLocationSelect(text.value) },
                                                            placeholder:"Enter location/Pin/zip"
                                                        }}
                                                    />
                                                 }
                                                {/* { searchGoogle && 
                                                    <input
                                                        type="text"
                                                        className="textFiled"
                                                        id="location_detail"
                                                        name="location_detail"
                                                        value={formData?.location_detail}
                                                        onChange={handleChange}
                                                        autoComplete="off"
                                                        disabled={useLiveLocation}
                                                        placeholder="Enter details here"
                                                    />
                                                } */}
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                            <div className="col-xl-4">
                                <div className="detail-desc" >
                                <div style={{ width: "100%", height: "300px" }}>
                                    {showmap && (showForm || useLiveLocation || searchGoogle ) && (
                                    <div>
                                        <GoogleMap
                                        address={currentLocation ? currentLocation.address : formData?.address}
                                        place_id={formData?.googlePlaceId}
                                        updateSelectLocation={updateSelectLocation}
                                        />
                                    </div>
                                    )}
                                </div>
                                </div>
                            </div>
                        </div>
                    {/* Google Map End */}
                    {/* Map Details Form Started */}
                    {(useLiveLocation || showForm || searchGoogle) && (
                        <div className="col-xl-6">
                            <div className="h-100">
                                <div className="step-form h-100">
                                        <div className="row">
                                            {/* <div className="col-lg-6">
                                                <div className="mb-4">
                                                    <label htmlFor="tags2" className="form-label ">
                                                        Location Address<span>*</span>
                                                    </label>
                                                    <div className="form-group">
                                                        <div className="ui-widget">
                                                            {searchGoogle ? (
                                                                <GooglePlacesAutocomplete apiKey={reqKey}
                                                                    selectProps={{
                                                                        searchValue,
                                                                        onChange: (text) => { handleLocationSelect(text.value) }
                                                                    }}
                                                                />
                                                            ) :
                                                                <input
                                                                    type="text"
                                                                    className="form-control"
                                                                    id="location_detail"
                                                                    name="location_detail"
                                                                    value={formData?.location_detail}
                                                                    onChange={handleChange}
                                                                    autoComplete="off"
                                                                    disabled={useLiveLocation}
                                                                />
                                                            }
                                                        </div>
                                                    </div>
                                                </div>
                                            </div> */}
                                            <div className="col-lg-6">
                                                <div className="mb-4 d-flex flex-column">
                                                    <label htmlFor="exampleInputEmail1" className="form-label ">
                                                    Country/Region<span>*</span>
                                                    </label>
                                                    <input
                                                        type="text"
                                                        required
                                                        // disabled
                                                        className="textFiled"
                                                        id="countyname"
                                                        name="country"
                                                        value={formData?.country}
                                                        onChange={domain === "spotlet.co" ? "" :handleChange}
                                                        autoComplete="off"
                                                    // disabled={searchGoogle || useLiveLocation}
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-lg-6">
                                                <div className="mb-4 d-flex flex-column">
                                                    <label htmlFor="exampleInputEmail1" className="form-label ">
                                                        State<span>*</span>
                                                    </label>
                                                    <input
                                                        type="text"
                                                        required
                                                        // disabled
                                                        className="textFiled"
                                                        id="statename"
                                                        name="state"
                                                        value={formData?.state}
                                                        onChange={domain === "spotlet.co" ? "" : handleChange}
                                                        autoComplete="off"
                                                    // disabled={searchGoogle || useLiveLocation}
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-lg-6">
                                                <div className="mb-4 d-flex flex-column">
                                                    <label htmlFor="exampleInputEmail1" className="form-label ">
                                                    Zip/Post/Pin Code<span>*</span>
                                                    </label>
                                                    <input
                                                        type="number"
                                                        required
                                                        className="textFiled"
                                                        min={1}
                                                        id="pincode"
                                                        name="pincode"
                                                        value={formData?.pincode}
                                                        onChange={handleChange}
                                                        autoComplete="off"
                                                    // disabled={searchGoogle || useLiveLocation}
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-lg-6">
                                                <div className="mb-4 d-flex flex-column">
                                                    <label htmlFor="exampleInputEmail1" className="form-label ">
                                                        City<span>*</span>
                                                    </label>
                                                    {/* <input
                                                        type="text"
                                                        required
                                                        className="textFiled"
                                                        id="cityname"
                                                        name="city"
                                                        value={formData?.city}
                                                        onChange={handleChange}
                                                        autoComplete="off"
                                                    // disabled={searchGoogle || useLiveLocation}
                                                    /> */}
                                                    <Autocomplete
                                                        name="city"
                                                        className="auto_textFiled"
                                                        value={{value : formData?.city,label : formData?.city}}
                                                        disableClearable={true}
                                                        onChange={(event, newValue) => {
                                                            const { location_detail, landmark, area, city, state, country, pincode } = formData;
                                                            const updatedAddress = [ landmark, area, newValue, state, country, pincode].filter(Boolean).join(", ");
                                                            setFormData({ ...formData, city: newValue, address : updatedAddress });
                                                        }}
                                                        inputValue={formData.city}
                                                        onInputChange={(event, newInputValue) => {
                                                            const { location_detail, landmark, area, city, state, country, pincode } = formData;
                                                            const updatedAddress = [ landmark, area, newInputValue, state, country, pincode].filter(Boolean).join(", ");
                                                            setFormData({ ...formData, city: newInputValue, address : updatedAddress });
                                                        }}
                                                        id="free-solo-demo"
                                                        freeSolo
                                                        options={cityData?.map((option) => option.value)}
                                                        renderInput={(params) => (
                                                            <TextField 
                                                                {...params}
                                                                required
                                                            />
                                                        )}
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-lg-6">
                                                <div className="mb-4 d-flex flex-column">
                                                    <label htmlFor="exampleInputEmail1" className="form-label ">
                                                    Street/Area<span>*</span>
                                                    </label>
                                                    <input
                                                        type="text"
                                                        required
                                                        className="textFiled"
                                                        id="areaname"
                                                        name="area"
                                                        value={formData?.area}
                                                        onChange={handleChange}
                                                        autoComplete="off"
                                                    // disabled={searchGoogle || useLiveLocation}
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-lg-6">
                                                <div className="mb-4 d-flex flex-column">
                                                    <label htmlFor="exampleInputEmail1" className="form-label ">
                                                        Landmark
                                                    </label>
                                                    <input
                                                        type="text"
                                                        className="textFiled"
                                                        id="landmarkname"
                                                        name="landmark"
                                                        value={formData?.landmark}
                                                        onChange={handleChange}
                                                        autoComplete="off"
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-lg-12">
                                                <div className="mb-4">
                                                    <label
                                                        htmlFor="exampleFormControlTextarea1"
                                                        className="form-label "
                                                    >
                                                        Address
                                                    </label>
                                                    <textarea
                                                        className="form-control"
                                                        id="exampleFormControlTextarea1"
                                                        rows="5"
                                                        name="address"
                                                        value={formData?.address}
                                                        onChange={handleChange}
                                                        autoComplete="off"
                                                    // disabled={searchGoogle || useLiveLocation}
                                                    ></textarea>
                                                </div>
                                            </div>
                                            {/* {formData?.mapLink && (
                                                <div className="col-lg-12">
                                                    <div className="mb-4">
                                                        <label
                                                            htmlFor="exampleFormControlTextarea1"
                                                            className="form-label "
                                                        >
                                                            Map link
                                                        </label>
                                                        <textarea
                                                            style={{ height: '50px' }}
                                                            className="form-control"
                                                            id="exampleFormControlTextarea1"
                                                            rows="1"
                                                            name="mapLink"
                                                            value={formData?.mapLink}
                                                            onChange={handleChange}
                                                            autoComplete="off"
                                                        // disabled={searchGoogle || useLiveLocation}
                                                        ></textarea>
                                                    </div>
                                                </div>
                                            )} */}
                                        </div>
                                    <div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
                    {/* Map Details Form End  */}
                </div>
            </div>
            {/* Back & Continue  */}
            <div className="row controlContainer">
                <div className="col-12">
                    <div className="d-flex flex-row justify-content-between align-items-center">
                        <div>
                            <button
                                type="button"
                                class="btn btn-outline-danger"
                                onClick={handleBack}
                            >
                                Back
                            </button>
                        </div>
                        <div>
                            <button
                                type="submit"
                                className="btn btn-primary"
                                disabled = {!(searchGoogle || useLiveLocation || showForm)}
                            >
                                Save & Continue
                            </button> 
                        </div>
                    </div>
                </div>
            </div>
            {/* Back & Continue End  */}
            {/* Confirmation Modal */}
            {showConfirmationModel && (
                <ConfirmationModel
                    show={showConfirmationModel}
                    handleClose={() => setShowConfirmationModel(false)}
                    handleOk={() => setShowConfirmationModel(false)}
                    title={"Location"}
                    body={"Please enter all fields !"}
                />
            )}
        </form>
    )
}
export default Location