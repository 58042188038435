import { Modal } from "react-bootstrap";
import { useState } from "react";

import { enquirePlan } from "../../apis/api";
import { ConfirmationModel } from "./ConfirmationModel";

const { Header, Title, Body } = Modal;

export const EnquiryModal = ({
    handleClose,
    show,
}) => {

    const [formData, setFormData] = useState();
    const [showConfirmationModel, setShowConfirmationModel] = useState(false);
    const [confirmationTitle, setConfirmationTitle] = useState("");
    const [confirmationBody, setConfirmationBody] = useState("");

    const hanldeSubmitForm = async (data) => {
        try {
          const response = await enquirePlan(data)
          setConfirmationTitle('Subscription')
          setConfirmationBody(response?.data?.message)
          setShowConfirmationModel(true)
        } catch (error) {
            setConfirmationTitle('Subscription')
            setConfirmationBody(error?.response?.data?.message)
            setShowConfirmationModel(true)
            console.log(error)
        }
      }

    const onChange = (e) => {
        const { name, value } = e.target
        setFormData({ ...formData, [name]: value })
    }

    const submitForm = (e) => {
        e.preventDefault()
        hanldeSubmitForm(formData)
    }

    return (
        <>
            <Modal
                show={show}
                onHide={handleClose}
                backdrop="static"
                keyboard={false}
                size="md"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Header closeButton>
                    <Title className="SpotLetMainHead"> Subscription Enquiry </Title>
                </Header>
                <Body className="verify-email-modal-body">
                <form onSubmit={submitForm}>
                    <div
                        style={{
                        // border: "1px solid",
                        // borderRadius: "3px",
                        margin: "5px 0px 5px 0px",
                        }}
                    >
                        <div className="d-flex flex-column m-2">
                        <label className="form-label" htmlFor="name">
                            Name<span className="required">*</span>
                        </label>
                        <input
                            required
                            className="form-control"
                            name="name"
                            type="text"
                            id="name"
                            value={formData?.name}
                            onChange={onChange}
                        />
                        </div>
                        <div className="d-flex flex-column m-2">
                        <label className="form-label" htmlFor="email">
                        Email Address<span className="required">*</span>
                        </label>
                        <input
                            required
                            className="form-control"
                            name="email"
                            type="email"
                            id="email"
                            pattern="^[^\s@]+@[^\s@]+\.[^\s@]{2,}$"
                            title="It should be of proper email format"
                            value={formData?.email}
                            onChange={onChange}
                        />
                        </div>
                        <div className="d-flex flex-column m-2">
                        <label className="form-label" htmlFor="phoneNumber">
                        Mobile Number<span className="required">*</span>
                        </label>
                        <input
                            required
                            className="form-control"
                            name="phoneNumber"
                            type="tel"
                            id="phoneNumber"
                            pattern="[6789][0-9]{9}"
                            title="Phone number with 6-9 and remaing 9 digit with 0-9"
                            value={formData?.phoneNumber}
                            onChange={onChange}
                        />
                        </div>
                        <div className="d-flex flex-column m-2">
                        <label className="form-label" htmlFor="plan">
                            {" "}
                            Subscription Plan<span className="required">*</span>
                        </label>
                        <select
                        style={{appearance: 'auto' }}    
                            value={formData?.plan}
                            id="plan"
                            className="form-control"
                            name="plan"
                            onChange={onChange}
                            required
                        >
                            <option value="" hidden selected >Select Plan</option>
                            <option value="standard">Standard</option>
                            <option value="premium">Premium</option>
                        </select>
                        </div>
                        <div className="d-flex flex-column m-2">
                        <label className="form-label" htmlFor="message">
                            Message
                        </label>
                        <textarea
                            // 
                            style={{
                            borderRadius : '5px'
                            }}
                            name="message"
                            rows={4}
                            columns={55}
                            id="message"
                            value={formData?.message}
                            onChange={onChange}
                        />
                        </div>
                        <div className="m-2">
                        <button 
                            className="btn btn-dark" 
                            onClick={handleClose} 
                            style={{
                            border: "none",
                            borderRadius: "5px",
                            color: "#ffffff",
                            width: "80px",
                            height: "40px",
                            marginRight: "10px",
                            }}
                        > 
                        Back 
                        </button>
                        <button
                            type="submit"
                            style={{
                            background: "#EA4335",
                            border: "none",
                            borderRadius: "5px",
                            color: "#ffffff",
                            width: "80px",
                            height: "40px",
                            }}
                        >
                            Submit
                        </button>
                        </div>
                    </div>
                    </form>
                </Body>
            </Modal>
            {showConfirmationModel && (
                  <ConfirmationModel
                  show={showConfirmationModel}
                  handleClose={() => {
                    setShowConfirmationModel(false)
                    handleClose()
                  }
                  } 
                  handleOk={() =>{
                    setShowConfirmationModel(false)
                    handleClose()
                  }
                  } 
                  title={confirmationTitle}
                  body={confirmationBody}
                  />
          )}
        </>
    );

}