import { Modal } from "react-bootstrap";
import { useEffect, useState } from "react";
import Select from 'react-select';
import { useNavigate } from "react-router-dom";
import { subscription, getCoupons, createPaymentOrder, saveSubscription } from "../../apis/api";
import { useAuthStore } from "../../store/auth";
import { useLocationStore } from "../../store/location";
import {RAZOR_KEY} from "../../utils/baseUrl";
import { PageLoader } from "../../components/PageLoader";
import { ConfirmationModel } from "./ConfirmationModel";
const { Header, Title, Body } = Modal;

// Images
// import info from "../../assets/NewImages/BecomeAHost/i.svg";
import logo from "../../assets/NewImages/Header/Favicon.ico"

export function SubscriptionModel({
    handleClose,
    show,
    completeTask,
    reqAllData
  }) {
    const { userProfile } = useAuthStore();
    const { setPlan, setPaymnetId } = useLocationStore();
    const navigate = useNavigate();
    const amount = reqAllData?.plan === 'Standard' ? reqAllData?.standard : reqAllData?.premium
    // const [formData,setFormData] = useState({plan : reqAllData?.plan})
    const [payment,setPayment] = useState('razerPay')
    const [value,setValue] = useState("pay") // useState(reqAllData?.isValue || "")
    const [allCouponsData, setAllCouponsData] = useState([])
    const [coupons,setCoupons] = useState([])
    const [offerId, selectedOfferId] = useState(null)
    const [couponId, setCouponId] = useState(null)
    const [razorDetails, setRazorDetails] = useState(null)
    const [isSubscription, setIsSubscription] = useState(true)
    const [selectedCouponFrom, setSelectedCouponFrom] = useState(null)
    const [spotletDetails, setSpotletDetails] = useState(null)
    const [finalAmount, setFinalAmount] = useState(amount)
    const [showConfirmationModel, setShowConfirmationModel] = useState(false);
    const [confirmationTitle, setConfirmationTitle] = useState("");
    const [confirmationBody, setConfirmationBody] = useState("");
    const [loader, setLoader] = useState(false)
    const [selectedCoupon, setSelectedCoupon] = useState(null)

    // const onChange = (e) => {
    //     const {name,value} = e.target
    //     setFormData({...formData,[name] : value})
    // }

    const handleSubscriptionCheckbox = (e) => {
      const {name, checked} = e.target
      if (name === "SpotLet") {
        if (isSubscription) {
        setIsSubscription(false)
        getRequiredCoupons(false)
        }
      } else {
        if (!isSubscription) {
        setIsSubscription(true)
        getRequiredCoupons(true)
        }
      }
      setSelectedCoupon(null)
      setRazorDetails(null)
      setSpotletDetails(null)
      setFinalAmount(amount)
    }

    useEffect(() => {
      if (reqAllData?.isValue) {
        getRequiredCoupons(true)
      }
    }, [])

    // const submitForm = (e) => {
    //     e.preventDefault()
    //     hanldeSubmitForm(formData)
    // }
    const onChangeCheckBox = (value) => {
      setValue(value)
      if (value === "pay")  getRequiredCoupons(true)
    }
    const onChangePayment= (value) => {
      setPayment(value)
    }

    const customStyles = {
      option: (provided, state) => ({
        ...provided,
        backgroundColor: state.isSelected ? '#ea4335' : 'white', // Customize the background color here
        color: state.isSelected ? 'white' : 'black', // Customize the text color here
      }),
    };

    const getReactSelectFormat  = (couponsData) => {
      const formattedData = couponsData?.map((each) =>  { 
        return (
          {label : each?.couponId, value : each?.couponId}
        )
      } )
      return formattedData
    }

    const getRequiredCoupons = async(subscription) => {
      setLoader(true)
      try{
        let reqSubscription  = true
        if (subscription === false) {
          reqSubscription = false
        }
        const res = await getCoupons("Active", reqAllData?.plan, userProfile?._id, reqSubscription);
        setAllCouponsData(res?.data?.coupons)
        const reqFormat = getReactSelectFormat(res?.data?.coupons)
        setCoupons(reqFormat);
      } catch(error) {
        console.log(error)
      }
      setLoader(false)
    }

    const validateSubscriptionAmount = (coupon) => {
      let finalAmount = amount
      if (coupon?.discountType === "Percentage") {
         finalAmount = Math.ceil(amount - amount * (coupon?.couponPercentage / 100))
      } else if (coupon?.discountType === "Amount") {
         finalAmount = amount - coupon?.couponValue;
      }
      setSpotletDetails({...coupon})
      setFinalAmount(finalAmount)
    }

    const getRazorPayDetails = (id) => {
      let reqDetails
      allCouponsData?.map((each) => {
        if (each?.couponId === id) {
          setSelectedCouponFrom(each?.couponFrom)
          if (each?.couponFrom === "Razorpay") {
            reqDetails = each?.razerOfferDetails
            selectedOfferId(each?.razerOfferId)
            setRazorDetails(reqDetails)
          } else if (each?.couponFrom === "SpotLet") {
            validateSubscriptionAmount(each);
          }
        }
      } )

    }

    const handleSetCoupon = (e) => {
      setSelectedCoupon(e)
      setCouponId(e?.label)
      getRazorPayDetails(e?.label)
    }

    //payment  functions start 

    function loadScript(src) {
      return new Promise((resolve) => {
        const script = document.createElement('script');
        script.src = src;
        script.onload = () => {
          resolve(true);
        };
        script.onerror = () => {
          resolve(false);
        };
        document.body.appendChild(script);
      });
    }
  
    const responseHandler = async (response,subscriptionId,planId,planName, offerId,couponId) => {
      try{
        let newObj = {
          user_id: userProfile?._id,
          subscriptionId,
          planId,
          planName,
          payment_id : response?.razorpay_payment_id,
          status : 'Success',
          offerId : offerId,
          couponId : couponId,
          locationid : reqAllData?.id,
        }
        await saveSubscription(newObj)
        setPaymnetId(response.razorpay_payment_id);
        setPlan(planName)
        completeTask()
      }catch(error){
        console.log(error)
      }
    }
    
    const checkoutSubscription = async (selectedPlan, subscriptionId,planId, offerId, couponId) => {
      const res = await loadScript(
        'https://checkout.razorpay.com/v1/checkout.js'
      );
      console.log(res)
  
      if (!res) {
        alert('Razorpay SDK failed to load. Are you online?');
        return;
      }
      var subOptions = {
        "key": RAZOR_KEY(),
        "subscription_id": subscriptionId,
        "name": "SpotLet Subscription",
        "image": logo,
        "theme": {
          "color": "#ea4335"
        },
        "description": "Auth txn for " + subscriptionId,
        "handler": function (response) {
          responseHandler(response,subscriptionId,planId,selectedPlan, offerId, couponId)
        }
      };
  
      const paymentObject = new window.Razorpay(subOptions);
      paymentObject.open();
    }

    const getSubscription = async (planId, planName, offerId, couponId) => {
      setLoader(true)
      try {
        const res = await subscription({ id: userProfile?._id, planId, planName, offerId })
        if (res.status === 200) {
          //window.open(res.data.short_url, '_blank').focus();
          // navigate(res.data.short_url)
          if (res?.data?.id) {
            checkoutSubscription(planName, res?.data?.id,planId, offerId, couponId);
          }
        }
      } catch (err) {
        console.log(err)
      }
      setLoader(false)
    }

    // razorpay payment
    async function displayRazorpay(finalAmount, couponId, planName, planId ) {
      try {
        const res = await loadScript(
          'https://checkout.razorpay.com/v1/checkout.js'
        );

        if (!res) {
          alert('Razorpay SDK failed to load. Are you online?');
          return;
        }

        const result = await createPaymentOrder({
          amount: finalAmount + '00',
          currency: reqAllData?.currencyType,
          // bookingId: booking?.bookingId
        });

        if (!result) {
          alert('Server error. Are you online?');
          return;
        }

        const { amount, id: order_id, currency } = result.data;

        const options = {
          key: RAZOR_KEY(), //'<YOUR RAZORPAY KEY>', // Enter the Key ID generated from the Dashboard
          amount: amount,
          currency: currency,
          "image":  logo,
          "theme": {
            "color": "#ea4335"
          },
          name: 'SpotLet Subscription',
          description: 'Listing Subscription Amount',
          order_id: order_id,
          handler: async function (response) {
            const data = {
              orderCreationId: order_id,
              razorpayPaymentId: response.razorpay_payment_id,
              razorpayOrderId: response.razorpay_order_id,
              razorpaySignature: response.razorpay_signature,
            };
            // updateBooking(booking, "Confirmed", 'Card');

            let newObj = {
              user_id: userProfile?._id,
              subscriptionId : null,
              planId : planId,
              planName,
              payment_id : response?.razorpay_payment_id,
              status : 'Success',
              offerId : null,
              couponId : couponId,
            }
            if (completeTask) newObj = {...newObj,  locationid : reqAllData?.id,}
            await saveSubscription(newObj)
            // if (completeTask) {
              setPaymnetId(response.razorpay_payment_id);
              setPlan(planName)
              completeTask()
            // } else {
                // navigate('/list-your-space')
            // }
            handleClose()

          },
          prefill: {
            name: userProfile?.personalInfo?.firstName,
            email: userProfile?.personalInfo?.email,
            contact: userProfile?.personalInfo?.mobile,
          },
          notes: {
            address: '',
          },
          theme: {
            color: '#61dafb',
          },
        };

        const paymentObject = new window.Razorpay(options);
        paymentObject.open();
      } catch (e) {
        console.log(e);
      }
    }

    const handlePayment =  (offerId, couponId, paymentOption, amount, isSubscription, finalAmount) => {
      // setShowSubscriptionModel(false)
      // if(paymentOption === 'billDesk'){
      //   checkoutSubscriptionBillDesk(amount, modelPlan, 'suscriptionId')
      // }
      if (isSubscription) {
        if(paymentOption === 'razerPay'){
          if(reqAllData?.plan === 'Standard'){
            getSubscription("plan_NpRRfbejULsSgP","Standard", offerId, couponId, isSubscription, reqAllData?.currencyType)
          }
          if(reqAllData?.plan === 'Premium'){
            getSubscription("plan_NpRSOVwhcG10iQ",'Premium', offerId, couponId, isSubscription, reqAllData?.currencyType)
          }
        }
      }  else {
        if(paymentOption === 'razerPay'){
          if(reqAllData?.plan === 'Standard'){
            displayRazorpay(finalAmount, couponId, "Standard", "plan_NpRRfbejULsSgP" )
          }
          if(reqAllData?.plan === 'Premium'){
            displayRazorpay(finalAmount, couponId, "Premium", "plan_NpRSOVwhcG10iQ" )
          }
        }
      }
    }

    // const hanldeSubmitForm = async (data) => {
    //   try {
    //     const response = await enquirePlan(data)
    //     setConfirmationTitle('Subscription')
    //     setConfirmationBody(response?.data?.message)
    //     setShowConfirmationModel(true)
    //   } catch (error) {
    //     console.log(error)
    //   }
    // }

    //payment  functions end 
    
    return (
      <>
        <Modal
          show={show}
          onHide={handleClose}
          backdrop="static"
          keyboard={false}
          size="md"
          aria-labelledby="contained-modal-title-vcenter"
          centered   
        >
          <Header closeButton>
            <Title>Subscription</Title>
          </Header>
          <Body className="verify-email-modal-body" style={{minHeight:"220px"}}>
              {/* <div className="d-flex">
                  <div style={{marginRight:'5px'}}>
                  <input
                      // disabled
                      checked = {value === 'pay'}
                      name='checkBox'
                      className="form-check-input"
                      type="radio"
                      id="payNow"
                      onChange={() => onChangeCheckBox('pay')}
                  />
                  <label htmlFor="payNow">Pay now</label>
                  <img title="payment gateway" src={info} />
                  </div>
                  <div>
                  <input
                      checked = {value === 'enquiry'}
                      name='checkBox'
                      className="form-check-input"
                      type="radio"
                      id="enquire"
                      onChange={() => onChangeCheckBox('enquiry')}
                  />
                  <label htmlFor="enquire">Enquire now</label>
                  <img title="Enquire about plan, this will send following information to suppert team and they will contact you soon" src={info} />
                  </div>
              </div> */}
              {
                !value &&
                <div className="d-flex flex-row justify-content-center align-items-center" style={{height:"150px"}}>
                    <div style={{marginRight:'5px'}}>
                      <button className="btn btn-primary" onClick={ () => onChangeCheckBox('pay')} > Pay Now </button>
                    </div>
                    <div>
                      <button className="btn btn-secondary" onClick={ () => onChangeCheckBox('enquiry')}> Enquire Now </button>
                    </div>
                </div>
              }
              {/* {value === 'enquiry' && (
                <form onSubmit={submitForm}>
                  <div
                    style={{
                      // border: "1px solid",
                      // borderRadius: "3px",
                      margin: "5px 0px 5px 0px",
                    }}
                  >
                    <div className="d-flex flex-column m-2">
                      <label className="form-label" htmlFor="name">
                        Name<span className="required">*</span>
                      </label>
                      <input
                        required
                        className="form-control"
                        name="name"
                        type="text"
                        id="name"
                        value={formData.name}
                        onChange={onChange}
                      />
                    </div>
                    <div className="d-flex flex-column m-2">
                      <label className="form-label" htmlFor="email">
                      Email Address<span className="required">*</span>
                      </label>
                      <input
                        required
                        className="form-control"
                        name="email"
                        type="email"
                        id="email"
                        pattern="^[^\s@]+@[^\s@]+\.[^\s@]{2,}$"
                        title="It should be of proper email format"
                        value={formData.email}
                        onChange={onChange}
                      />
                    </div>
                    <div className="d-flex flex-column m-2">
                      <label className="form-label" htmlFor="phoneNumber">
                      Mobile Number<span className="required">*</span>
                      </label>
                      <input
                        required
                        className="form-control"
                        name="phoneNumber"
                        type="tel"
                        id="phoneNumber"
                        pattern="[6789][0-9]{9}"
                        title="Phone number with 6-9 and remaing 9 digit with 0-9"
                        value={formData.phoneNumber}
                        onChange={onChange}
                      />
                    </div>
                    <div className="d-flex flex-column m-2">
                      <label className="form-label" htmlFor="plan">
                        {" "}
                        Subscription Plan<span className="required">*</span>
                      </label>
                      <select
                      style={{appearance: 'auto' }}    
                        value={formData.plan}
                        id="plan"
                        className="form-control"
                        name="plan"
                        onChange={onChange}
                        required
                      >
                        <option value="" hidden selected >Select Plan</option>
                        <option value="standard">Standard</option>
                        <option value="premium">Premium</option>
                      </select>
                    </div>
                    <div className="d-flex flex-column m-2">
                      <label className="form-label" htmlFor="message">
                        Message
                      </label>
                      <textarea
                        // 
                        style={{
                          borderRadius : '5px'
                        }}
                        name="message"
                        rows={4}
                        columns={55}
                        id="message"
                        value={formData.message}
                        onChange={onChange}
                      />
                    </div>
                    <div className="m-2">
                      <button 
                        className="btn btn-dark" 
                        onClick={() => setValue("")} 
                        style={{
                          border: "none",
                          borderRadius: "5px",
                          color: "#ffffff",
                          width: "80px",
                          height: "40px",
                          marginRight: "10px",
                        }}
                      > 
                      Back 
                      </button>
                      <button
                        type="submit"
                        style={{
                          background: "#EA4335",
                          border: "none",
                          borderRadius: "5px",
                          color: "#ffffff",
                          width: "80px",
                          height: "40px",
                        }}
                      >
                        Submit
                      </button>
                    </div>
                  </div>
                </form>
              )} */}
              {value === 'pay' && (
                <div className="m-3">
                  <div className="d-flex flex-row justify-content-between">
                    <p className="m-1"><strong>Plan : {" "} </strong></p>
                    <p className="m-1">{reqAllData?.plan}</p>
                  </div>
                  <div className="d-flex flex-row justify-content-between">
                    <p className="m-1"><strong>Amount : {" "} </strong></p>
                    <p className="m-1">{amount}/-</p>
                  </div>
                  <div className="d-flex flex-row justify-content-between">
                    <p className="m-1"><strong>Subscription Mode : {" "} </strong></p>
                  <div>
                    <input
                      className="subscription-checkbox"
                      type="checkbox"
                      name = "SpotLet"
                      onChange = {handleSubscriptionCheckbox }
                      checked = {!isSubscription}
                      />
                    <label htmlFor="razerPay">Single Time</label>
                  </div>
                  <div>
                    <input
                      className="subscription-checkbox"
                      name = "Razorpay"
                      type="checkbox"
                      onChange = {handleSubscriptionCheckbox }
                      checked = {isSubscription}
                      />
                    <label htmlFor="razerPay">Subscription</label>
                  </div>
                  </div>
                  {isSubscription && <label> (You will be charged annually) </label>}
                  {!isSubscription && <label> (This is a Single time payment) </label>}
                  {/* <div className="d-flex" srtyle={{margin : '10px 0px 10px 0px'}}>
                  <p style={{marginRight : '5px'}}>Payment Options :</p>
                  <div style={{marginRight:'5px'}}>
                  <input
                      checked = {payment === 'razerPay'}
                      name='checkBox2'
                      className="form-check-input"
                      type="checkbox"
                      id="razerPay"
                      onChange={() => onChangePayment('razerPay')}
                  />
                  <label htmlFor="razerPay">Razorpay</label> */}
                  {/* <img title="Enquire about plan, this will send following information to suppert team and they will contact you soon" src={info} /> */}
                  {/* </div> */}
                  {/* <div>
                  <input
                      disabled
                      checked = {payment === 'billDesk'}
                      name='checkBox2'
                      className="form-check-input"
                      type="checkbox"
                      id="billDesk"
                      onChange={() => onChangePayment('billDesk')}
                  />
                  <label htmlFor="billDesk">BillDesk</label>
                  <img title="payment gateway" src={info} />
                  </div> */}
              {/* </div> */}
              <div className="mb-3">
                  <p className="m-1"><strong> Coupons :</strong>  </p>
                  <Select
                      className="w-90 searchPageReqInput"
                      styles={customStyles}
                      options = {coupons}
                      id = "CouponSelect"
                      value={selectedCoupon}
                      placeholder = "Select Coupon"
                      onChange = {(e) => {
                          handleSetCoupon(e)
                        }}
                  />
              </div>
              {
                selectedCouponFrom === "Razorpay" && razorDetails && (
                  <div className="mb-2">
                    <p> <strong> Payment Details :</strong>  </p>
                    <div className="d-flex flex-row justify-content-between">
                      <p className="m-1"><strong>Discount : {" "} </strong></p>
                      <p className="m-1">{razorDetails?.display_text}</p>
                    </div>
                    <div className="d-flex flex-row justify-content-between">
                      <p className="m-1"><strong> Payment Method : {" "} </strong></p>
                      <p className="m-1">{razorDetails?.payment_method}</p>
                    </div>
                    {/* <p className="m-1"> Details - {razorDetails?.display_text} </p> */}
                    {/* <p className="m-1"> Payment Method - {razorDetails?.payment_method} </p> */}
                  </div>
                )
              }
              {
                selectedCouponFrom === "SpotLet" && spotletDetails && (
                  <div className="mb-2">
                    <p> <strong> Payment Details : </strong>  </p>
                    <div className="d-flex flex-row justify-content-between">
                    <p className="m-1"><strong>Price : {" "} </strong></p>
                    <p className="m-1">{amount}/-</p>
                  </div>
                    <div className="d-flex flex-row justify-content-between">
                        <p className="m-1"><strong> Discount Type : {" "} </strong></p>
                        <p className="m-1">{spotletDetails?.discountType}</p>
                    </div>
                    {/* <p className="m-1"> Discount Type - {spotletDetails?.discountType} </p> */}
                    {spotletDetails?.couponValue && (
                      <div className="d-flex flex-row justify-content-between">
                        <p className="m-1"><strong> Coupon Amount : {" "} </strong></p>
                        <p className="m-1">{spotletDetails?.couponValue}/-</p>
                      </div>
                    // <p className="m-1"> Coupon Amount  - {spotletDetails?.couponValue} </p>
                    )}
                    {spotletDetails?.couponPercentage && (
                      <>
                      <div className="d-flex flex-row justify-content-between">
                        <p className="m-1"><strong> Coupon Percentage : {" "} </strong></p>
                        <p className="m-1">{spotletDetails?.couponPercentage}%</p>
                     </div>
                      <div className="d-flex flex-row justify-content-between">
                        <p className="m-1"><strong> Coupon Amount : {" "} </strong></p>
                        <p className="m-1">{ Math.floor( amount * (spotletDetails?.couponPercentage/100) ) }/-</p>
                     </div>
                      </>
                    // <p className="m-1"> Coupon Percentage  - {spotletDetails?.couponPercentage}% </p>
                    )}
                    <div className="d-flex flex-row justify-content-between">
                        <p className="m-1"><strong> Total : {" "} </strong></p>
                        <p className="m-1">{finalAmount}/-</p>
                     </div>
                    {/* <p className="m-1"> Final Value  - {finalAmount} </p> */}
                  </div>
                )
              }
                <div>
                    <button 
                      className="btn btn-dark" 
                      onClick={() => {
                        // setValue("")
                        setRazorDetails(null)
                        setCouponId(null)
                        selectedOfferId(null)
                        handleClose()
                      }} 
                      style={{
                        border: "none",
                        borderRadius: "5px",
                        color: "#ffffff",
                        width: "80px",
                        height: "40px",
                        marginRight: "10px",
                      }}
                    > 
                    Back 
                    </button>
                    <button
                      type="button"
                      onClick={() => handlePayment(offerId, couponId, payment, amount, isSubscription, finalAmount)}
                      style={{
                        background: "#EA4335",
                        border: "none",
                        borderRadius: "5px",
                        color: "#ffffff",
                        width: "80px",
                        height: "40px",
                        margin: "5px 0px 5px 0px",
                      }}
                    >
                      Pay Now
                    </button>
                  </div>
                </div>
              )}
          </Body>
        </Modal>
        {showConfirmationModel && (
                  <ConfirmationModel
                  show={showConfirmationModel}
                  handleClose={() => {
                    setShowConfirmationModel(false)
                    handleClose()
                  }
                  } 
                  handleOk={() =>{
                    setShowConfirmationModel(false)
                    handleClose()
                  }
                  } 
                  title={confirmationTitle}
                  body={confirmationBody}
                  />
          )}
          {loader && <PageLoader />}
      </>
    );

  }