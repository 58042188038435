import { create } from "zustand";
import jwtDecode from "jwt-decode";
import { getUserDetails, GetServiceDetails } from "../apis/api";

export const useAuthStore = create((set) => ({
  isAuthenticated: false,
  setAuthenticated: () =>
    set(() => ({ isAuthenticated: !!localStorage.getItem("auth") })),
  userProfile: null,
  setUserProfile: async () => {
    const jwtToken = localStorage.getItem("auth");
    if (!!jwtToken) {
      const decodeInfo = jwtDecode(jwtToken);
      if(Math.floor(Date.now() / 1000) < decodeInfo?.exp){
        try {
          const response = await getUserDetails(decodeInfo._id);
          set(() => ({ userProfile: response.data }));
          return response.data
        } catch (e) {
          console.log(e)
        }
      }else{
        set(() => ({ userProfile: null }));
      }
    } else{
      set(() => ({ userProfile: null }));
    }
  },
  setUserProfileNull: () => {
    set(() => ({ userProfile: null }));
  },
  bookingServices : null,
  setBookingServices : async () => {
    try{
      const response = await GetServiceDetails('BookingServices');
      set(() => ({bookingServices : response?.data?.serviceData?.settings}) )
    } catch(e) {
      console.log(e);
    }
  }
}));
