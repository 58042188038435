import { Button, Modal } from "react-bootstrap";
import moment from 'moment';

const { Header, Footer, Title, Body } = Modal;
export function BookingPopup({
  handleClose,
  handleOk,
  show,
  bookings
}) {
  return (
    <Modal show={show} onHide={handleClose}>
      <Header closeButton>
        <Title>Booking Summary</Title>
      </Header>
      <Body className="verify-email-modal-body" style={{ overflowY: 'auto', height: '380px' }}>
        <div className="container">
          <div className="row mb-2">
            <div className="col-6">
              <h6><b>Booking ID</b></h6>
              <p>{bookings?.bookingId}</p>
            </div>
            <div className="col-6">
              <h6><b>Location ID</b></h6>
              <p>{bookings?.property_id}</p>
            </div>
          </div>
          <div className="row mb-2">
            <div className="col-6">
              <h6><b>Booking Date</b></h6>
              <p>{moment(bookings?.createdAt).format('YYYY-MM-DD HH:mm:ss')}</p>
            </div>
            <div className="col-6">
              <h6><b>Event and activity type</b></h6>
              <p>{bookings?.event}</p>
            </div>
          </div>
          <div className="row mb-2">
            <div className="col-6">
              <h6><b>Attendees</b></h6>
              <p>Adults : {bookings?.adults}, Children : {bookings?.children}, Infants : {bookings?.infants}</p>
            </div>
            <div className="col-6">
              <h6><b>Event Dates</b></h6>
              <p>{moment(bookings?.checkIn).format('DD-MM-YYYY')} to {moment(bookings?.checkOut).format('DD-MM-YYYY')}</p>
            </div>
          </div>
          <div className="row mb-2">
            {/* <div className="col-6">
              <h6><b>Total Hours</b></h6>
              <p>{bookings?.total_hours} Hrs</p>
            </div> */}
            <div className="col-6">
              <h6><b>Booking Amount</b></h6>
              <p>INR. {bookings?.totalPayment}</p>
            </div>
          </div>
        </div>
        <p>On clicking Approve, this booking request will be approved and it cannot be reverted</p>
      </Body>
      <Footer>
        <Button variant="secondary" onClick={handleClose}>
          Cancel
        </Button>
        <Button variant="primary" onClick={handleOk}>
          Approve
        </Button>
      </Footer>
    </Modal>
  );
}
