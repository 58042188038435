import { useEffect, useState } from "react"
import PropertyInfo from "../../../components/NewPropertyInfo";
import {getSimilarListings} from "../../../apis/api"

const SimilarProperties = ({id, type, city}) => {

    const [data, setData] = useState({
        "responseHead" : "",
        "responseSorry" : "",
        "similarPropertiesList" : [],
    })

    const getReqListings = async() => {
        try{
            const response = await getSimilarListings({type, city, id});
            setData({...response?.data});
        } catch (error) {
            console.log(error)
        }
    }

    useEffect(() => {
        getReqListings();
    }, [id, city, type] )

    return(
        <div className="similarPropertiesMainContainer">
            <h1 className="similarPropertiesHead SpotLetMainHead" > {data?.responseHead} </h1>
            <p className="similarPropertiesError SpotLetContent" > {data?.responseSorry} </p>
            <div className="similarPropertiesContainer" >
                {
                    data?.similarPropertiesList && 
                    data?.similarPropertiesList.map((each, index) => {
                        return <PropertyInfo property = {each} key = {index} />
                    } )
                }
            </div>
        </div>
    )
}

export default SimilarProperties