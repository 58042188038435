import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import { useState } from "react";
import {useNavigate} from "react-router-dom";;
import { useAuthStore } from "../../store/auth"
import AuthenitcateModal from "../../utils/NewAuthentication/AuthenitcateModal";
import landingpageimg02 from "../../assets/NewImages/Home/landingpage-img02.webp";
const HostContainer = () => {
  const navigate = useNavigate();
  const { userProfile } = useAuthStore();
  const [navTo, setNavTo] = useState('')
  const [authenticate, setAuthenticate] = useState(false)
  const handleNav = (value) => {
      if(userProfile){
          navigate('/plans')
      }else{
          setNavTo(value)
          setAuthenticate(true)
      }
  }
  return(
      <Box className="host-your-spot">
          <Box className="host-container">
            <article>
              <Typography variant="h2" gutterBottom className="SpotLetMainHead">
                Host your Spot with Us
              </Typography>
              <Typography variant="body1" className="SpotLetContent">
                If you’d like to become a host and list your unique property,
                we’d be happy to showcase it on our website. We invite you to
                join our growing family
              </Typography>
              <Button className="readmore SpotLetContent" variant="contained" size="large" onClick={()=>handleNav('/plans')}>
                List Your Space
              </Button>
            </article>
            <figure>
              <img loading="lazy" src={landingpageimg02} alt='HOST YOUR SPOT WITH US'/>
            </figure>
          </Box>
          {authenticate && 
          <AuthenitcateModal
              show={authenticate}
              handleSuccess={()=> {
                  setAuthenticate(false)
                  navigate(navTo)
              }}
              handleClose={()=>setAuthenticate(false)}
              activeTab=''
          />
      }
        </Box>
  )
}
export default HostContainer;