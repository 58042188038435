import { Helmet } from "react-helmet";
import { useState, useEffect } from 'react';
import { getPageDetails } from "../apis/api";

export default function Constants() {
  const [data, setData] = useState({
    metaTitle: "SpotLet",
    metaKeywords: '',
    metaDescription: '',
  });


  const removeDomainAndTrailingSlashes = (url) => {
    // Create a URL object to parse the input URL
    const urlObject = new URL(url);

    // Remove trailing slashes from pathname, search, and hash
    let pathname = urlObject.pathname.replace(/\/+$/, '');
    let search = urlObject.search.replace(/\/+$/, '');
    let hash = urlObject.hash.replace(/\/+$/, '');

    // Ensure there is only one leading slash in pathname
    pathname = '/' + pathname.replace(/^\/+/, '');

    // Combine cleaned components and handle single trailing slash for search and hash
    return pathname + (search ? (search + '/') : '') + (hash ? (hash + '/') : '');
  }

  const getData = async () => {
    try {
      const reqUrl = removeDomainAndTrailingSlashes(window.location.href);
      const res = await getPageDetails(reqUrl);
      setData(res?.data)
    } catch (error) {
      console.log(error)
    }
  }
  useEffect(() => {
    getData()
  }, [window.location.href]);

  return (
    <Helmet>
      <title>{data?.metaTitle}</title>
      <meta name="description" content={data?.metaDescription} />
      <meta name="keywords" content={data?.metaKeywords} />
    </Helmet>
  );
}
