import { Header } from "../components/NewHeader/Header";
import { Sidebar } from "../components/Sidebar";
import { useEffect, useMemo, useState } from "react";
import Table from "../components/Table";
import { getCouponUsageDetails } from "../apis/api";
import { useAuthStore } from "../store/auth";
import { Button } from "react-bootstrap";
import { useNavigate, useParams } from 'react-router-dom';
import dayjs from "dayjs";


export const CouponUsageList = () => {
    const { userProfile } = useAuthStore();
    const navigate = useNavigate();
    // const couponId = "DIWALI1500";
    const [couponDetails, setCouponDetails] = useState([])
    const [couponUsage, setCouponUsage] = useState([])

    const { couponId } = useParams()

    const getCouponUsage = async () => {
        // make an api call
        const response = await getCouponUsageDetails(couponId)
        setCouponUsage(response?.data?.usageDetails)
        setCouponDetails(response?.data?.couponDetails)
    }

    useEffect(() => {
        getCouponUsage()
    }, [])

    const columns = useMemo(
        () => [
            {
                Header: "Booking ID",
                accessor: "bookingId",
            },
            {
                Header: "Location ID",
                accessor: "property_id",
            },
            {
                Header: "User Name",
                accessor: "userName",
            },
            {
                Header: "Booking Amount",
                accessor: "totalPayment",
            },
            {
                Header: "Coupon Amount",
                accessor: "couponDiscount",
            },
            {
                Header: "Booking Date",
                accessor: "bookingRequestedDate",
                Cell: ({ row: { couponUsage } }) => {
                    return dayjs(couponUsage?.bookingRequestedDate).format(
                        "DD/MM/YYYY"
                    );
                },
            },
        ],
        []
    );

    return (
        <>
            <Header />
            <div className="container-fluid p-0">
                <div className="row g-0">
                    <div className="d-flex edit-profile my-list">
                        <div className="col-lg-2 w-200">
                            <Sidebar />
                        </div>
                        <div className="col-lg-10">
                            <form className="h-100 needs-validation" noValidate>
                                <div className="step-form h-100">
                                    <div className="e-profile">
                                        <div className="d-flex justify-content-between">
                                            <div className="mb-3" >
                                                <Button variant="primary" onClick={() => navigate('/coupons')}>
                                                    Back
                                                </Button>
                                            </div>
                                        </div>
                                        <div className="row mb-4 " >
                                            <div className="col-10 d-flex flex-row justify-content-between flex-wrap"   >
                                                <div style={{ marginTop: "26px" }} >
                                                    <h5> Coupon Name :
                                                        <small className="text-muted" >  {couponDetails?.couponName} </small>
                                                    </h5>
                                                    <h5>  Coupon Id :
                                                        <small className="text-muted" >  {couponId} </small>
                                                    </h5>
                                                    <h5> Coupon Type :
                                                        <small className="text-muted" >   {couponDetails?.couponType} </small>
                                                    </h5>
                                                </div>
                                                <div style={{ marginTop: "26px" }} >
                                                    {couponDetails?.couponType === "single" && <h5> Location Id :
                                                        <small className="text-muted" >   {couponDetails?.propertyId} </small>
                                                    </h5>}
                                                    <h5> Coupon Amount / Percentage :
                                                        <small className="text-muted" >   {couponDetails?.couponValue}{couponDetails?.discountType === "Percentage" ? "%" : "/-"} </small>
                                                    </h5>
                                                    <h5> Minimum Booking Amount:
                                                        <small className="text-muted" >   {couponDetails?.minimunBooking}/- </small>
                                                    </h5>
                                                    {couponDetails?.discountType === "Percentage" && <h5> Maximun Discount Amount :
                                                        <small className="text-muted" >   {couponDetails?.maximumDiscount}/- </small>
                                                    </h5>}
                                                </div>
                                                <div style={{ marginTop: "26px" }} >
                                                    <h5> Status :
                                                        <small className="text-muted" >   {couponDetails?.status} </small>
                                                    </h5>
                                                    <h5> Activation Date :
                                                        <small className="text-muted" >
                                                            {dayjs(couponDetails?.activationDate).format(
                                                                "DD/MM/YYYY"
                                                            )}
                                                        </small>
                                                    </h5>
                                                    <h5> Expiry Date :
                                                        <small className="text-muted" >
                                                            {dayjs(couponDetails?.expiryDate).format(
                                                                "DD/MM/YYYY"
                                                            )}
                                                        </small>
                                                    </h5>
                                                </div>
                                            </div>
                                        </div>
                                        <div>
                                            <h2 className="etitle mb-2">Coupon Usage Details :</h2>
                                        </div>
                                        <div className="creative-space m-0">
                                            <div class="tab-content" id="pills-tabContent">
                                                <div class="tab-pane fade active show" id="pills-booking" role="tabpanel" aria-labelledby="pills-booking-tab">
                                                    <div class="table-responsive">
                                                        <Table columns={columns} data={couponUsage} />

                                                        {/* <Table columns={columns} data={coupons} /> */}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </>

    )
}

export default CouponUsageList