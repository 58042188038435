import { useState, useEffect } from 'react';
import { Box, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useLocationStore } from "../../store/location";
import { subscription, createBDPaymentOrder, enquirePlan } from '../../apis/api'
import { useAuthStore } from "../../store/auth";
import "./style.css"

// Images
import basic_Plan from "../../assets/NewImages/SubscriptionPlan/basic_plan_icon.webp"
import standard_Plan from "../../assets/NewImages/SubscriptionPlan/standard_plan_icon.webp"
import premium_Plan from "../../assets/NewImages/SubscriptionPlan/premium_plan_icon.webp"
import iIcon from "../../assets/NewImages/SubscriptionPlan/i_icon.svg"

//Component
import { Header } from "../Header";
import { PageLoader } from "../PageLoader";
import { SubscriptionModel } from '../Modal/SubscriptionModel';
import { ConfirmationModel } from '../Modal/ConfirmationModel';

export const SubscriptionPlan = () => {
  const { userProfile, setUserProfile } = useAuthStore();
  const [basicPlan, setBasicPlan] = useState(false)
  const [standardPlan, setStandardPlan] = useState(true)
  const [premiumPlan, setPremiumPlan] = useState(false)
  const [loader, setLoader] = useState(false)
  const [showSubscriptionModel, setShowSubscriptionModel] = useState(false);
  const [modelPlan, setModelPlan] = useState('');
  const [showConfirmationModel, setShowConfirmationModel] = useState(false);
  const [confirmationTitle, setConfirmationTitle] = useState("");
  const [confirmationBody, setConfirmationBody] = useState("");

  const navigate = useNavigate()
  const { setPlan, setPaymnetId } = useLocationStore();

  const activatePlan = (selectedPlan) => {
    setPlan(selectedPlan)
    navigate('/list-your-space')
  }

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [])

  const getSubscription = async (planId, planName) => {
    setLoader(true)
    try {
      const res = await subscription({ id: userProfile?._id, planId, planName })
      if (res.status === 200) {
        //window.open(res.data.short_url, '_blank').focus();
        // navigate(res.data.short_url)
        if (res?.data?.id) {
          checkoutSubscription(planName, res?.data?.id);
        }
      }
    } catch (err) {
      console.log(err)
    }
  }

  function loadScript(src) {
    return new Promise((resolve) => {
      const script = document.createElement('script');
      script.src = src;
      script.onload = () => {
        resolve(true);
      };
      script.onerror = () => {
        resolve(false);
      };
      document.body.appendChild(script);
    });
  }

  const checkoutSubscription = async (selectedPlan, subscriptionId) => {
    setLoader(false)
    const res = await loadScript(
      'https://checkout.razorpay.com/v1/checkout.js'
    );

    if (!res) {
      alert('Razorpay SDK failed to load. Are you online?');
      return;
    }

    var subOptions = {
      "key": "rzp_test_gotWBtdd6rUaTo",
      "subscription_id": subscriptionId,
      "name": "Spotlet Subscription",
      "description": "Auth txn for " + subscriptionId,
      "handler": function (response) {
        setPaymnetId(response.razorpay_payment_id);
        setPlan(selectedPlan)
        navigate('/list-your-space')
      }
    };

    const paymentObject = new window.Razorpay(subOptions);
    paymentObject.open();
  }

  // Bill Desk

  function loadLinkTag(src) {
    return new Promise((resolve) => {
      const link = document.createElement('link');
      link.src = src;
      link.rel = 'stylesheet'
      link.onload = () => {
        resolve(true);
      };
      link.onerror = () => {
        resolve(false);
      };
      document.body.appendChild(link);
    });
  }

  function loadScriptModule(src, module) {
    return new Promise((resolve) => {
      const script = document.createElement('script');
      script.src = src;
      if (module) {
        script.type = 'module';
      } else {
        script.noModule = '';
      }
      script.onload = () => {
        resolve(true);
      };
      script.onerror = () => {
        resolve(false);
      };
      document.body.appendChild(script);
    });
  }


  const checkoutSubscriptionBillDesk = async (amount, selectedPlan, subscriptionId) => {

    const response = await loadScriptModule("https://uat1.billdesk.com/merchant-uat/sdk/dist/billdesksdk/billdesksdk.esm.js", 'module');
    const response1 = await loadScriptModule("https://uat1.billdesk.com/merchant-uat/sdk/dist/billdesksdk.js");
    //const response2 = await loadLinkTag("https://uat1.billdesk.com/merchant-uat/sdk/dist/billdesksdk/billdesksdk.css");

    if (!response) {
      alert('BillDesk SDK failed to load. Are you online?');
      return;
    }

    const result = await createBDPaymentOrder({
      amount: amount + '.00',
      bookingId: "12345"
    });

    const { mercid, token, ru, bdorderid } = result.data;
    console.log("token = ", token);


    var flow_config = {
      merchantId: mercid,
      bdOrderId: bdorderid,
      authToken: token,
      childWindow: true,
      retryCount: 2
    };
    var responseHandler = function (txn) {
      console.log("responseHandler = txn ", txn);

      if (txn.status === 200) {

        console.log("callback received status:: ", txn.status);
        console.log("callback received response:: ", txn.txnResponse)//response handler to be implemented by the merchant

        setPaymnetId(txn.txnResponse.orderid);
        setPlan(selectedPlan)
        navigate('/list-your-space')
      }
    };
    var config = {
      flowConfig: flow_config,
      flowType: "payments",
      responseHandler: responseHandler,
    };
    window.loadBillDeskSdk(config);
    // window.onload = function() {
    //     setTimeout(function() {
    //         window.loadBillDeskSdk(config);
    //     }, 1500);
    // };

  }

  const hanldeSubmitForm = async (data) => {
    try {
      setShowSubscriptionModel(false)
      const response = await enquirePlan(data)
      setConfirmationTitle('Subscription')
      setConfirmationBody(response?.data?.message)
      setShowConfirmationModel(true)
    } catch (error) {
      console.log(error)
    }
  }

  const handlePayment =  (amount,paymentOption) => {
    setShowSubscriptionModel(false)
    if(paymentOption === 'billDesk'){
      checkoutSubscriptionBillDesk(amount, modelPlan, 'suscriptionId')
    }
    if(paymentOption === 'razerPay'){
      if(modelPlan === 'standard'){
        getSubscription("plan_McgHlhqYY4mARQ","standard")
      }
      if(modelPlan === 'premium'){
        getSubscription("plan_McgIyGmBSbSgd6",'premium')}
      }
    }


  return (
    <>
      <Header />
      <Box className="subscriptionContainer">
        <Box className="subscriptionHeaderBox">
          <Box className="backButtonBox backButtonPosition">
            <Box onClick={() => navigate('/')} className="backButton">
              <svg xmlns="http://www.w3.org/2000/svg" width="8" height="15" viewBox="0 0 8 15" fill="none">
                <path d="M6.5375 14.4479C6.37917 14.4479 6.22084 14.3896 6.09584 14.2646L0.6625 8.83125C-0.220833 7.94792 -0.220833 6.49792 0.6625 5.61458L6.09584 0.18125C6.3375 -0.0604167 6.7375 -0.0604167 6.97917 0.18125C7.22084 0.422917 7.22084 0.822917 6.97917 1.06458L1.54583 6.49792C1.14583 6.89792 1.14583 7.54792 1.54583 7.94792L6.97917 13.3812C7.22084 13.6229 7.22084 14.0229 6.97917 14.2646C6.85417 14.3812 6.69584 14.4479 6.5375 14.4479Z" fill="#1A1E25" />
              </svg>
              <Typography ml={1}>Go back</Typography>
            </Box>
          </Box>
          <Box className="subscriptionHeader">
            <Typography>Our Listing Plans</Typography>
          </Box>
        </Box>

        <Box className="subscriptionPlanBox">

          <Box className="subCLabel">
            <Box className="planeItem tinBackground leftBorderRadius imgC">
              <Typography mr={1.5}>Property Name</Typography>
              <img src={iIcon} />
              {/* <span className="message"> message for subscription plan</span> */}
            </Box>
            <Box className="planeItem WhiteBackground imgC">
              <Typography mr={1.5}>Image Upload Limit</Typography>
              <img src={iIcon} />
              {/* <span className="message"> message for subscription plan</span> */}
            </Box>
            <Box className="planeItem tinBackground leftBorderRadius imgC">
              <Typography mr={1.5}>Video Upload</Typography>
              <img src={iIcon} />
              {/* <span className="message"> message for subscription plan</span> */}

            </Box>
            <Box className="planeItem WhiteBackground imgC">
              <Typography mr={1.5}>Search Priority</Typography>
              <img src={iIcon} />
              {/* <span className="message"> message for subscription plan</span> */}
            </Box>
            <Box className="planeItem tinBackground leftBorderRadius imgC">
              <Typography mr={1.5}>SpotLet Support for Content<br />
                Writing for Professional approach</Typography>
              <img src={iIcon} />
              {/* <span className="message"> message for subscription plan</span> */}
            </Box>
            <Box className="planeItem WhiteBackground imgC">
              <Typography mr={1.5}>Theme Options</Typography>
              <img src={iIcon} />
              {/* <span className="message"> message for subscription plan</span> */}
            </Box>
            <Box className="planeItem tinBackground leftBorderRadius imgC ">
              <Typography mr={1.5}>Modifications allowed in Listing</Typography>
              <img src={iIcon} />
              {/* <span className="message"> message for subscription plan</span> */}
            </Box>
            <Box className="planeItem WhiteBackground imgC">
              <Typography mr={1.5}>Social Media Promotion</Typography>
              <img src={iIcon} />
              {/* <span className="message"> message for subscription plan</span> */}
            </Box>
            <Box className="planeItem tinBackground leftBorderRadius imgC">
              <Typography mr={1.5}>Discount In (%)</Typography>
              <img src={iIcon} />
              {/* <span className="message"> message for subscription plan</span> */}
            </Box>
          </Box>


          <Box
            onMouseEnter={() => { setBasicPlan(true), setStandardPlan(false) }}
            onMouseLeave={() => { setBasicPlan(false), setStandardPlan(true) }}
            className="basicPlan">
            <Box className="basicPlanHeader">
              <Box className="planHeaderLogo">
                <img style={{ width: "100%", height: "100%" }} src={basic_Plan} />
              </Box>
              <Box className="planeHeaderText">
                <Typography sx={basicPlan ? { color: "#EA4335" } : { color: "#000000" }}>Basic Plan</Typography>
              </Box>
              <Box mb={5} className="planPrice">
                <Typography sx={basicPlan ? { color: "#EA4335" } : { color: "#000000" }}>Free</Typography>
              </Box>
              <Box className="StartButtonBox">
                <Box sx={basicPlan ? { backgroundColor: "#EA4335" } : { backgroundColor: "#000000" }} className="getStartButton" onClick={() => activatePlan('Basic')}>
                  <Typography >Get Started</Typography>
                </Box>
              </Box>
            </Box>

            <Box className="planItems planItemsText">
              <Box className={basicPlan ? "planeItem MobileTin" : "planeItem tinBackground"}>
                <Typography variant='h7' className='responsiveLabelText' >Property Name -</Typography>
                <Typography mr={1.5}>Partial ( Only ID)</Typography>
              </Box>
              <Box className="planeItem WhiteBackground">
                <Typography variant='h7' className='responsiveLabelText' >Image Upload Limit -</Typography>
                <Typography mr={1.5}>Up to 30 images</Typography>
              </Box>
              <Box className={basicPlan ? "planeItem MobileTin" : "planeItem tinBackground"}>
                <Typography variant='h7' className='responsiveLabelText' >Video Upload -</Typography>
                <Typography mr={1.5}>Not available</Typography>
              </Box>
              <Box className="planeItem WhiteBackground">
                <Typography variant='h7' className='responsiveLabelText' >Search Priority -</Typography>
                <Typography mr={1.5}>Low</Typography>
              </Box>
              <Box className={basicPlan ? "planeItem MobileTin" : "planeItem tinBackground"}>
                <Typography variant='h7' className='responsiveLabelText'>Professional approach -</Typography>
                <Typography mr={1.5}>Not available</Typography>
              </Box>
              <Box className="planeItem WhiteBackground">
                <Typography variant='h7' className='responsiveLabelText' >Theme Options -</Typography>
                <Typography mr={1.5}>Not available</Typography>
              </Box>
              <Box className={basicPlan ? "planeItem MobileTin" : "planeItem tinBackground"}>
                <Typography variant='h7' className='responsiveLabelText' >Modifications Listing -</Typography>
                <Typography mr={1.5}>Not available</Typography>
              </Box>
              <Box className="planeItem WhiteBackground">
                <Typography variant='h7' className='responsiveLabelText' >Social Media Promotion -</Typography>
                <Typography mr={1.5}>Not available</Typography>
              </Box>
              <Box className={basicPlan ? "planeItem MobileTin" : "planeItem tinBackground"}>
                <Typography variant='h7' className='responsiveLabelText' >Discount In (%) -</Typography>
                <Typography mr={1.5}>Free</Typography>
              </Box>
            </Box>

          </Box>


          <Box
            onMouseEnter={() => setStandardPlan(true)}
            // onMouseLeave={() => setStandardPlan(false)}
            className={standardPlan ? "standardPlan" : "basicPlan"}>
            <Box className="basicPlanHeader">
              <Box className="planHeaderLogo">
                <img style={{ width: "100%", height: "100%" }} src={standard_Plan} />
              </Box>
              <Box className="planeHeaderText">
                <Typography sx={standardPlan ? { color: "#EA4335" } : { color: "#000000" }} >Standard Plan</Typography>
              </Box>
              <Box className="planPrice">
                <Typography sx={{ color: "#000000", opacity: '0.5' }} className='priceText planPriceText'>₹24,999</Typography>
                &nbsp;
                <Typography sx={standardPlan ? { color: "#EA4335" } : { color: "#000000" }} >₹4,999</Typography>
              </Box>
              <Box className="planeHeaderText PerAnnumText">
                <Typography sx={standardPlan ? { color: "#EA4335" } : { color: "#000000" }}>Per annum</Typography>
              </Box>
              <Box mb={1.5} className="planeHeaderText lastPlanText">
                <Typography>Recommended plan</Typography>
              </Box>
              <Box className="StartButtonBox" onClick={() => { setModelPlan('Standard'), setShowSubscriptionModel(true) }}>
                {/* onClick={() => activatePlan('standard')} */}
                <Box sx={standardPlan ? { backgroundColor: "#EA4335" } : { backgroundColor: "#000000" }} className="getStartButton">
                  <Typography>Get Started</Typography>
                </Box>
              </Box>
            </Box>
            <Box className="planItems planItemsText">
              <Box className={standardPlan ? "planeItem MobileTin" : "planeItem tinBackground"}>
                <Typography variant='h7' className='responsiveLabel2ndText' >Property Name -</Typography>
                <Typography mr={1.5}>Yes</Typography>
              </Box>
              <Box className="planeItem WhiteBackground">
                <Typography variant='h7' className='responsiveLabel2ndText' >Image Upload Limit -</Typography>
                <Typography mr={1.5}>Up to 100 images</Typography>
              </Box>
              <Box className={standardPlan ? "planeItem MobileTin" : "planeItem tinBackground"}>
                <Typography variant='h7' className='responsiveLabel2ndText' >Video Upload -</Typography>
                <Typography mr={1.5}>Up to 10 Mb</Typography>
              </Box>
              <Box className="planeItem WhiteBackground">
                <Typography variant='h7' className='responsiveLabel2ndText' >Search Priority -</Typography>
                <Typography mr={1.5}>Medium</Typography>
              </Box>
              <Box className={standardPlan ? "planeItem MobileTin" : "planeItem tinBackground"}>
                <Typography variant='h7' className='responsiveLabel2ndText'>Professional approach -</Typography>
                <Typography mr={1.5}>Yes</Typography>
              </Box>
              <Box className="planeItem WhiteBackground">
                <Typography variant='h7' className='responsiveLabel2ndText' >Theme Options -</Typography>
                <Typography mr={1.5}>Not available</Typography>
              </Box>
              <Box className={standardPlan ? "planeItem MobileTin" : "planeItem tinBackground"}>
                <Typography variant='h7' className='responsiveLabel2ndText' >Modifications Listing -</Typography>
                <Typography mr={1.5}>Yes</Typography>
              </Box>
              <Box className="planeItem WhiteBackground">
                <Typography variant='h7' className='responsiveLabel2ndText' >Social Media Promotion -</Typography>
                <Typography mr={1.5}>Yes</Typography>
              </Box>
              <Box className={standardPlan ? "planeItem MobileTin" : "planeItem tinBackground"}>
                <Typography variant='h7' className='responsiveLabel2ndText' >Discount In (%) -</Typography>
                <Typography mr={1.5}>80%</Typography>
              </Box>
            </Box>

          </Box>
          <Box
            onMouseEnter={() => { setPremiumPlan(true), setStandardPlan(false) }}
            onMouseLeave={() => { setPremiumPlan(false), setStandardPlan(true) }}
            className="premiumPlan">
            <Box className="basicPlanHeader">
              <Box className="planHeaderLogo">
                <img style={{ width: "100%", height: "100%" }} src={premium_Plan} />
              </Box>
              <Box className="planeHeaderText">
                <Typography sx={premiumPlan ? { color: "#EA4335" } : { color: "#000000" }}>Premium Plan</Typography>
              </Box>
              <Box className="planPrice">
                <Typography sx={{ color: "#000000", opacity: '0.5' }} className='priceText planPriceText'>₹37,499</Typography>
                &nbsp;
                <Typography sx={premiumPlan ? { color: "#EA4335" } : { color: "#000000" }} >₹7,499</Typography>
              </Box>
              <Box className="planeHeaderText PerAnnumText">
                <Typography sx={{ color: "#EA4335" }}>Per annum</Typography>
              </Box>
              <Box mb={1.5} className="planeHeaderText lastPlanText">
                <Typography>Our most popular plan</Typography>
              </Box>
              <Box className="StartButtonBox">
                {/* onClick={() => activatePlan('premium')} */}
                <Box onClick={() => { setModelPlan('Premium'), setShowSubscriptionModel(true) }} sx={premiumPlan ? { backgroundColor: "#EA4335" } : { backgroundColor: "#000000" }} className="getStartButton" >
                  <Typography>Get Started</Typography>
                </Box>
              </Box>
            </Box>

            <Box className="planItems planItemsText">
              <Box className={premiumPlan ? "planeItem MobileTin" : "planeItem tinBackground rightBorderRadius"}>
                <Typography variant='h7' className='responsiveLabel2ndText' >Property Name -</Typography>
                <Typography mr={1.5}>Yes</Typography>
              </Box>
              <Box className="planeItem WhiteBackground">
                <Typography variant='h7' className='responsiveLabel2ndText' >Image Upload Limit -</Typography>
                <Typography mr={1.5}>Up to 200 images</Typography>
              </Box>
              <Box className={premiumPlan ? "planeItem MobileTin" : "planeItem tinBackground rightBorderRadius"}>
                <Typography variant='h7' className='responsiveLabel2ndText' >Video Upload -</Typography>
                <Typography mr={1.5}>15 Mb</Typography>
              </Box>
              <Box className="planeItem WhiteBackground">
                <Typography variant='h7' className='responsiveLabel2ndText' >Search Priority -</Typography>
                <Typography mr={1.5}>Higher Priority</Typography>
              </Box>
              <Box className={premiumPlan ? "planeItem MobileTin" : "planeItem tinBackground rightBorderRadius"}>
                <Typography variant='h7' className='responsiveLabel2ndText'>Professional approach -</Typography>
                <Typography mr={1.5}>Yes</Typography>
              </Box>
              <Box className="planeItem WhiteBackground">
                <Typography variant='h7' className='responsiveLabel2ndText' >Theme Options -</Typography>
                <Typography mr={1.5}>Yes</Typography>
              </Box>
              <Box className={premiumPlan ? "planeItem MobileTin" : "planeItem tinBackground rightBorderRadius"}>
                <Typography variant='h7' className='responsiveLabel2ndText' >Modifications Listing -</Typography>
                <Typography mr={1.5}>Yes</Typography>
              </Box>
              <Box className="planeItem WhiteBackground">
                <Typography variant='h7' className='responsiveLabel2ndText' >Social Media Promotion -</Typography>
                <Typography mr={1.5}>Yes</Typography>
              </Box>
              <Box className={premiumPlan ? "planeItem MobileTin" : "planeItem tinBackground rightBorderRadius"}>
                <Typography variant='h7' className='responsiveLabel2ndText' >Discount In (%) -</Typography>
                <Typography mr={1.5}>80%</Typography>
              </Box>
            </Box>

          </Box>

        </Box>
      </Box>
      {loader && <PageLoader />}
      {showSubscriptionModel && (
        <SubscriptionModel
          show={showSubscriptionModel}
          handleClose={() => setShowSubscriptionModel(false)}
          handleOk={hanldeSubmitForm}
          plan={modelPlan}
          payNow={handlePayment}
        />
      )}
      {showConfirmationModel && (
        <ConfirmationModel
          show={showConfirmationModel}
          handleClose={() => setShowConfirmationModel(false)}
          handleOk={() => setShowConfirmationModel(false)}
          title={confirmationTitle}
          body={confirmationBody}
        />
      )}
    </>
  )
}

