import React, { useState, useEffect } from "react";
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import { getLocationTypes, createLocation } from "../../apis/api";
import { useAuthStore } from "../../store/auth";
import { useLocationStore } from "../../store/location";
import { LocationType } from "../Modal/LocationType";

// Images
import info from "../../assets/NewImages/BecomeAHost/i.svg";

const DetailDesc = ({ callbackFn, setTab }) => {
  const { plan: selectedPlan, location: locationData, setTempLocation, paymentId: planPaymentId } = useLocationStore();
  const [locationTypesData, setLocationTypesData] = useState([]);
  const [showNewLocationTypeModel, setShowNewLocationTypeModel] = useState(false);
  const { userProfile } = useAuthStore();
  const [formErrors, setFormErrors] = useState({});
  const [searchInput, setSearchInput] = useState('')

  const [formErrorMessages, setFormErrorMessages] = useState({
    property_name: "Name of the Property is required",
    property_info: "Description of the location is required",
    property_size: "Enter valid Property size",
    location_type: "Type of Location is required",
    property_size_area: 'Select Area type'
  });

  const [formData, setFormData] = useState({
    user_id: "",
    property_name: "",
    property_info: "",
    location_type: "",
    property_size: "",
    property_size_area: "",
    house_parking: false,
    street_parking: false,
    security_camera: false,
  });

  useEffect(() => {
    setFormData({ ...formData, user_id: userProfile?._id });
  }, [userProfile]);

  useEffect(() => {
    const initLocationTypes = async () => {
      try {
        const data = await getLocationTypes();
        setLocationTypesData(data);
      } catch (e) {
        console.log(e);
      }
    };
    initLocationTypes();
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    const regex = /^[1-9][0-9]*$/
    setFormData({
      ...formData,
      [name]: value,
    });
    if (value?.length === 0 || (name === "property_size" && !(regex.test(value)))) {
      setFormErrors({
        ...formErrors,
        [name]: formErrorMessages?.[name]
      });
    }
    else {
      setFormErrors({
        ...formErrors,
        [name]: ""
      });
    }
  };

  const refreshLocationTypes = async (newLocationType) => {
    setShowNewLocationTypeModel(false);
    try {
      const data = await getLocationTypes();
      setLocationTypesData(data);
      setFormData({
        ...formData,
        location_type: newLocationType,
      });
    } catch (e) {
      console.log(e);
    }
  }

  const addLocation = async (payload) => {
    try {
      const response = await createLocation(payload, selectedPlan, planPaymentId);
      callbackFn(payload);
      localStorage.setItem("locationId", response);
      setTempLocation();
    } catch (error) {
      console.log(error);
    }
  };

  const validateForm = () => {
    let isValid = true;

    if (formData.property_size_area?.length == 0) {
      setFormErrors({ ...formErrors, property_size_area: formErrorMessages.property_size_area });
      isValid = false;
    }
    if ((formData.property_size[0]) == 0 || (formData.property_size?.length == 0)) {
      setFormErrors({ ...formErrors, property_size: formErrorMessages.property_size });
      isValid = false;
    }
    if (formData.property_name?.length == 0) {
      setFormErrors({ ...formErrors, property_name: formErrorMessages.property_name });
      isValid = false;
    }
    if (formData.location_type === null || formData.location_type?.length === 0) {
      setFormErrors({ ...formErrors, location_type: formErrorMessages.location_type });
      isValid = false;
    }
    return isValid;
  }

  const handleSubmit = () => {
    const payload = Object.keys(formData).reduce((a, c) => {
      if (["house_parking", "street_parking", "security_camera"].includes(c)) {
        a[c] = formData[c] ? "yes" : "no";
      } else {
        a[c] = formData[c];
      }
      return a;
    }, {});



    if (!validateForm()) {
      return;
    }
    payload.user_id = userProfile?._id;

    if (localStorage.getItem("locationId")) {
      callbackFn({ property_desc: payload });
    } else {
      addLocation({ property_desc: payload });
    }
    setTab("property_address");
  };

  useEffect(() => {
    if (locationData && locationData?.property_desc) {
      const { property_desc } = locationData;
      const {
        property_name = "",
        property_info = "",
        location_type = "",
        property_size = "",
        property_size_area = "",
      } = property_desc;

      const house_parking =
        property_desc.house_parking === "yes" ? true : false;
      const street_parking =
        property_desc.street_parking === "yes" ? true : false;
      const security_camera =
        property_desc.security_camera === "yes" ? true : false;
      setFormData({
        ...formData,
        property_name,
        property_info,
        location_type,
        property_size,
        property_size_area,
        house_parking,
        street_parking,
        security_camera,
      });
    }
  }, [locationData]);
  return (
    <>
      {/*DETAILS & DESCRIPTION START */}
      <div
        className="tab-pane host-forms h-100 fade show active"
        id="v-pills-details"
        role="tabpanel"
        aria-labelledby="v-pills-details-tab"
      >
        <div className="row g-0 h-100">
          <div className="col-xl-8">
            <form className="h-100 needs-validation" noValidate>
              <div className="step-form h-100">
                <div>
                  <h3 className="ftitle">
                    Details & Description{" "}
                    <a
                      href="#"
                      data-bs-toggle="tooltip"
                      title="Type your property name and size for an attractive listing"
                    >
                      <img src={info} />
                    </a>
                  </h3>

                  <div className="row">
                    <div className="col-lg-6">
                      <div className="mb-4">
                        <label htmlFor="nameoflocation" className="form-label ">
                          Name of the Property/Location/Site<span>*</span>
                        </label>
                        <input
                          type="text"
                          // className={`form-control ${formErrors?.property_name?.length > 0 && 'is-invalid'}`}
                          className={formErrors?.property_name?.length > 0 ? `form-control is-invalid` : "form-control"}

                          id="nameoflocation"
                          name="property_name"
                          onChange={handleChange}
                          value={formData?.property_name}
                          autoComplete="off"
                        />
                        <span className="required">{formErrors?.property_name}</span>
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div className="mb-4">
                        <div className="d-flex justify-content-between">
                          <label htmlFor="tags" className="form-label ">
                            Type of Location<span>*</span>
                          </label>
                          <a className="typeoflocation" onClick={() => {
                            setShowNewLocationTypeModel(true);
                          }}>
                            <i className="bi bi-plus"></i> Add new type of Location
                          </a>
                        </div>
                        <div className="form-group">
                          <div className="ui-widget">
                            {/* <input
                              className="form-control"
                              id="tags"
                              name="location_type"
                              onChange={handleChange}
                            /> */}
                            {/* <select
                              className={formErrors?.location_type?.length > 0 ? `form-control is-invalid` : "form-control"}
                              id="exampleFormControlSelect1"
                              name="location_type"
                              onChange={handleChange}
                              value={formData?.location_type}
                            >
                              <option value=''>Select your location types...</option>
                              {locationTypesData.map((item) => (
                                <option value={item.value}>{item.name}</option>
                              ))}
                            </select> */}
                            <Autocomplete
                              // unstable_classNamePrefix={`form-control ${formErrors?.location_type?.length > 0 && 'is-invalid'}`}

                              unstable_classNamePrefix={formErrors?.location_type?.length > 0 ? `form-control-is-invalid` : "form-control"}
                              name="location_type"
                              disableClearable={true}
                              value={formData?.location_type}
                              onChange={(event, newValue) => {
                                setFormData({ ...formData, location_type: newValue });
                              }}
                              inputValue={searchInput}
                              onInputChange={(event, newInputValue) => {
                                setSearchInput(newInputValue);
                              }}
                              id="controllable-states-demo"
                              options={locationTypesData.map((option) => option.value)}
                              renderInput={(params) => <TextField {...params} />}
                              sx={{
                                "& .MuiOutlinedInput-root": {
                                  borderRadius: "10px",
                                  height: "50px"
                                }
                              }}
                            />
                            <span className="required">{formErrors?.location_type}</span>

                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="col-lg-3">
                      <div className="mb-4">
                        <label htmlFor="nameoflocation" className="form-label ">
                          Property Size<span>*</span>
                        </label>
                        <input
                          type="number"
                          // className={`form-control ${formErrors?.property_size?.length > 0 && 'is-invalid'}`}

                          className={formErrors?.property_size?.length > 0 ? `form-control is-invalid` : "form-control"}
                          id="nameoflocation"
                          name="property_size"
                          onChange={handleChange}
                          value={formData?.property_size}
                          autoComplete="off"
                        />
                        <span className="required">{formErrors?.property_size}</span>
                      </div>
                    </div>
                    <div className="col-lg-3">
                      <div className="mb-4">
                        <label htmlFor="nameoflocation" className="form-label ">
                          Area type<span>*</span>
                        </label>
                        {/* <select
                              className={formErrors?.property_size_area?.length > 0 ? `form-control is-invalid` : "form-control"}
                              id="property_size_area"
                              name="property_size_area"
                              onChange={handleChange}
                              value={formData?.property_size_area}
                            >
                              <option value='' disabled selected hidden></option>
                              <option value='Sq.Ft'>Sq.Ft</option>
                              <option value='Sq.Mts'>Sq.Mts</option>
                              <option value='Sq.Yrds'>Sq.Yrds</option>
                            </select> */}
                        <Autocomplete
                          // unstable_classNamePrefix={`form-control ${formErrors?.property_size_area?.length > 0 && 'is-invalid'}`}

                          unstable_classNamePrefix={formErrors?.property_size_area?.length > 0 ? `form-control-is-invalid` : "form-control"}
                          name="property_size_area"
                          disableClearable={true}
                          value={formData?.property_size_area}
                          onChange={(event, newValue) => {
                            setFormData({ ...formData, property_size_area: newValue });
                          }}
                          id="controllable-states-demo"
                          options={['Sq.Ft', 'Sq.Mts', 'Sq.Yds']}
                          renderInput={(params) => <TextField {...params} sx={{
                            "& .MuiOutlinedInput-root": {
                              borderRadius: "10px",
                              height: "50px"
                            }
                          }} />}
                        />
                        <span className="required">{formErrors?.property_size_area}</span>
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div className="mb-4">
                        <label
                          htmlFor="exampleInputEmail1"
                          className="form-label "
                        >
                          In-House Parking Facility Available<span>*</span>
                        </label>
                        <div className="radiobox">
                          <div className="form-check me-3">
                            <input
                              className="form-check-input"
                              type="radio"
                              // name="parking"
                              id="parking"
                              name="house_parking"
                              onChange={() =>
                                setFormData({
                                  ...formData,
                                  house_parking: true,
                                })
                              }
                              checked={formData.house_parking}
                            />
                            <label
                              className="form-check-label"
                              htmlFor="parking"
                            >
                              Yes
                            </label>
                          </div>
                          <div className="form-check">
                            <input
                              className="form-check-input"
                              type="radio"
                              name="house_parking"
                              id="parking2"
                              onChange={() =>
                                setFormData({
                                  ...formData,
                                  house_parking: false,
                                })
                              }
                              checked={!formData.house_parking}
                            />
                            <label
                              className="form-check-label"
                              htmlFor="parking2"
                            >
                              No
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div className="mb-4">
                        <label
                          htmlFor="exampleInputEmail1"
                          className="form-label "
                        >
                          Street Parking Facility Available<span>*</span>
                        </label>
                        <div className="radiobox">
                          <div className="form-check me-3">
                            <input
                              className="form-check-input"
                              type="radio"
                              name="street"
                              id="street"
                              onChange={() =>
                                setFormData({
                                  ...formData,
                                  street_parking: true,
                                })
                              }
                              checked={formData.street_parking}
                            />
                            <label
                              className="form-check-label"
                              htmlFor="street"
                            >
                              Yes
                            </label>
                          </div>
                          <div className="form-check">
                            <input
                              className="form-check-input"
                              type="radio"
                              name="street"
                              id="street2"
                              onChange={() =>
                                setFormData({
                                  ...formData,
                                  street_parking: false,
                                })
                              }
                              checked={!formData.street_parking}
                            />
                            <label
                              className="form-check-label"
                              htmlFor="street2"
                            >
                              No
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div className="mb-4">
                        <label
                          htmlFor="exampleInputEmail1"
                          className="form-label "
                        >
                          Security Camera Available<span>*</span>
                        </label>
                        <div className="radiobox">
                          <div className="form-check me-3">
                            <input
                              className="form-check-input"
                              type="radio"
                              name="security"
                              id="security"
                              onChange={() =>
                                setFormData({
                                  ...formData,
                                  security_camera: true,
                                })
                              }
                              checked={formData.security_camera}
                            />
                            <label
                              className="form-check-label"
                              htmlFor="security"
                            >
                              Yes
                            </label>
                          </div>
                          <div className="form-check">
                            <input
                              className="form-check-input"
                              type="radio"
                              name="security"
                              id="security2"
                              onChange={() =>
                                setFormData({
                                  ...formData,
                                  security_camera: false,
                                })
                              }
                              checked={!formData.security_camera}
                            />
                            <label
                              className="form-check-label"
                              htmlFor="security2"
                            >
                              No
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="col-lg-12">
                      <div className="mb-4">
                        <label
                          htmlFor="exampleFormControlTextarea1"
                          className="form-label "
                        >
                          Description of the location
                          {/* <span>*</span> */}
                        </label>
                        <textarea
                          //className={formErrors?.property_info?.length > 0 ? `form-control is-invalid` : "form-control"}
                          className="form-control"
                          id="exampleFormControlTextarea1"
                          rows="5"
                          onChange={(e) => {
                            setFormData({
                              ...formData,
                              property_info: e.target.value,
                            });
                            // if (e.target.value?.split(" ")?.length < 25) {
                            //   setFormErrors({...formErrors, property_info:  "Property description must be minimum 25 words"});
                            // } else {
                            //   setFormErrors({...formErrors, property_info: ""});
                            // }
                          }}
                          value={formData.property_info}
                          autoComplete="off"
                        ></textarea>
                        {/* <span className="minword">Minimum 25 words</span> */}
                      </div>
                    </div>
                  </div>
                </div>
                <div>
                  <div className="button-bar">
                    {/* <a className="btn btn-gray">Back</a> */}
                    <span>{""}</span>
                    <button
                      className="btn btn-theme"
                      type="button"
                      onClick={handleSubmit}
                    >
                      Save & Continue
                    </button>
                  </div>
                </div>
              </div>
            </form>
          </div>
          <div className="col-xl-4">
            <div className="detail-desc"></div>
          </div>
        </div>
      </div>

      {showNewLocationTypeModel && (
        <LocationType
          show={showNewLocationTypeModel}
          handleClose={() => setShowNewLocationTypeModel(false)}
          handleOk={refreshLocationTypes}
          data={locationTypesData}
          type='Location Type'
        />
      )}
      {/*DETAILS & DESCRIPTION END */}
    </>
  );
};

export default DetailDesc;
