import Accordion from 'react-bootstrap/Accordion';
import correct from "../assets/images/correct.svg"
import remove from "../assets/images/remove.svg"
import { getEnquiryData } from '../../../apis/api';
import { useEffect } from 'react';

const defaultFilmTvPolicies = [
    {
        "subHead": "Purpose of Booking",
        "question": "The property may only be booked for legitimate film or TV shooting purposes.",
        "value": "purpose_of_booking"
    },
    {
        "subHead": "Booking Duration",
        "question": "Minimum booking duration required for film/TV shootings.",
        "value": "booking_duration"
    },
    {
        "subHead": "Security Deposit",
        "question": "A refundable security deposit is mandatory to cover any damages during the shoot.",
        "value": "security_deposit"
    },
    {
        "subHead": "Equipment Handling",
        "question": "Production teams are responsible for handling their equipment safely and ensuring no damage to the property.",
        "value": "equipment_handling"
    },
    {
        "subHead": "Property Modifications",
        "question": "Any modifications to the property for shooting purposes must be pre-approved by the owner.",
        "value": "property_modifications"
    },
    {
        "subHead": "Noise Restrictions",
        "question": "Shooting schedules must adhere to noise restrictions to minimize disruption to neighbors.",
        "value": "noise_restrictions"
    },
    {
        "subHead": "Crew Accommodation",
        "question": "Accommodation for the crew may be available on-site or nearby, subject to availability and prior arrangement.",
        "value": "crew_accommodation"
    },
    {
        "subHead": "Cleaning Fee",
        "question": "A cleaning fee may be applicable post-shoot to restore the property to its original condition.",
        "value": "cleaning_fee"
    },
    {
        "subHead": "Liability",
        "question": "The property owner holds no liability for accidents, injuries, or damages that occur during the shoot.",
        "value": "liability"
    },
];

const defaultEventPolicies = [
    {
        "subHead": "Event Type",
        "question": "Only specified event types (e.g., corporate, weddings) are permitted.",
        "value": "event_type"
    },
    {
        "subHead": "Booking Duration",
        "question": "Events must adhere to the specified minimum and maximum booking durations.",
        "value": "booking_duration"
    },
    {
        "subHead": "Guest Capacity",
        "question": "Strictly adhere to the maximum guest capacity outlined for each event.",
        "value": "guest_capacity"
    },
    {
        "subHead": "Security Deposit",
        "question": "A mandatory security deposit is required to cover damages or violations.",
        "value": "security_deposit"
    },
    {
        "subHead": "Noise Restrictions",
        "question": "Respect noise restrictions to minimize disturbance to neighbors and maintain peace.",
        "value": "noise_restrictions"
    },
    {
        "subHead": "Alcohol Policy",
        "question": "Alcohol consumption is permitted based on local regulations and with prior approval.",
        "value": "alcohol_policy"
    },
    {
        "subHead": "Cleanup Responsibility",
        "question": "Event organizers are solely responsible for cleaning up the venue post-event, including waste disposal.",
        "value": "cleanup_responsibility"
    },
    {
        "subHead": "Property Modifications",
        "question": "Any temporary modifications to the property for the event must receive prior approval from the host.",
        "value": "property_modifications"
    },
    {
        "subHead": "Liability Waiver",
        "question": "The property owner holds no liability for accidents, injuries, or damages that occur during the event. Event organizers assume full responsibility.",
        "value": "liability_waiver"
    },
];

const defaultHomeStayPolicies = [
    {
        "subHead": "Check-in/Check-out",
        "question": "Guests must adhere to the specified check-in and check-out times.",
        "value": "check_in_out"
    },
    {
        "subHead": "Minimum Stay",
        "question": "Guests must comply with the minimum length of stay requirement for homestay bookings.",
        "value": "minimum_stay"
    },
    {
        "subHead": "Guest Conduct",
        "question": "Guests are required to respect house rules, property, and neighbors at all times.",
        "value": "guest_conduct"
    },
    {
        "subHead": "Security Deposit",
        "question": "A security deposit is mandatory to cover any damages caused during the stay.",
        "value": "security_deposit"
    },
    {
        "subHead": "Pets",
        "question": "Guests must seek prior approval from the host regarding pet accommodation. Host's decision on pet accommodation is final.",
        "value": "pets"
    },
    {
        "subHead": "Smoking",
        "question": "Smoking is strictly prohibited indoors and allowed only in designated outdoor areas.",
        "value": "smoking"
    },
    {
        "subHead": "Cancellation Policy",
        "question": "Guests must comply with the homestay cancellation policy, including any associated fees.",
        "value": "cancellation_policy"
    },
    {
        "subHead": "Amenities Usage",
        "question": "Guests are expected to use available amenities responsibly and follow any usage guidelines provided.",
        "value": "amenities_usage"
    },
    {
        "subHead": "Liability Waiver",
        "question": "The property owner bears no liability for accidents, injuries, or losses incurred by guests during their stay. Guests accept full responsibility for their actions and safety.",
        "value": "liability_waiver"
    }
];

const Policies = ({data, pricingData}) => {

    const getActive = () => {
        if (pricingData?.reqData?.film || pricingData?.reqData?.tv) {
        return "0";
        } else if (pricingData?.reqData?.event) {
        return "1";
        } else if (pricingData?.reqData?.stay) {
        return "2";
        }
        return "0"; // Default return if none of the conditions are met
        };
    const defaultLiElement = (each) => {
        return (
            <li className="SpotLetContent form-label col-lg-12 col-xl-12"> <span className="policy SpotLetContent" style={{fontWeight:600}}> {each?.subHead} : </span> <span className="policy SpotLetContent" > {each?.question} </span></li>
        )
    }

    const getFilmTvPolicies = (data) => {
        return(
            <>
                <li className='SpotLetContent form-label col-lg-12 col-xl-12'>
                    {data?.cancellation}
                </li>
                <li className='SpotLetContent form-label col-lg-12 col-xl-12'>
                    Id Proofs 
                    <ul>
                        {
                            data?.idProof?.map((each) => {
                                return <li className='SpotLetContent form-label col-lg-12 col-xl-12'> {each?.label} </li>
                            } )
                        }
                    </ul>
                </li>
                {
                    data?.music?.allowed && (
                        <li className='SpotLetContent form-label col-lg-12 col-xl-12'>
                            Music allowed - {data?.music?.fullDay ? "24 Hours" : `${data?.music?.timings?.start} - ${data?.music?.timings?.end}` }
                        </li>
                    )
                }
                {
                    data?.outSideFood && (
                        <li className='SpotLetContent form-label col-lg-12 col-xl-12'>
                            Outside food allowed
                        </li>
                    )
                }
                {
                    data?.smoking && (
                        <li className='SpotLetContent form-label col-lg-12 col-xl-12'>
                            Smoking allowed
                        </li>
                    )
                }
                {
                    data?.nonVeg && (
                        <li className='SpotLetContent form-label col-lg-12 col-xl-12'>
                            Non-Veg allowed
                        </li>
                    )
                }
                {
                    data?.caretaker?.available && (
                        <li className='SpotLetContent form-label col-lg-12 col-xl-12'>
                            Caretaker available - {data?.caretaker?.fullDay ? "24 Hours" : `${data?.caretaker?.timings?.start} - ${data?.caretaker?.timings?.end}` }
                        </li>
                    )
                }
                {
                    data?.customPolicy && (
                        <li className='SpotLetContent form-label col-lg-12 col-xl-12'>
                            Host Policies
                            <ul>
                                {
                                    data?.customPolicies?.map((each) => {
                                        return <li className='SpotLetContent form-label col-lg-12 col-xl-12'> {each} </li>
                                    } )
                                }
                            </ul>
                        </li>
                    )
                }
            </>
        )
    }
    
    const getEventPolicies = (data) => {
        return(
            <>
                <li className='SpotLetContent form-label col-lg-12 col-xl-12'>
                    {data?.cancellation}
                </li>
                <li className='SpotLetContent form-label col-lg-12 col-xl-12'>
                    Id Proofs 
                    <ul>
                        {
                            data?.idProof?.map((each) => {
                                return <li className='SpotLetContent form-label col-lg-12 col-xl-12'> {each?.label} </li>
                            } )
                        }
                    </ul>
                </li>
                {
                    data?.music?.allowed && (
                        <li className='SpotLetContent form-label col-lg-12 col-xl-12'>
                            Music allowed - {data?.music?.fullDay ? "24 Hours" : `${data?.music?.timings?.start} - ${data?.music?.timings?.end}` }
                        </li>
                    )
                }
                {
                    data?.cook && (
                        <li className='SpotLetContent form-label col-lg-12 col-xl-12'>
                           Cook Available
                        </li>
                    )
                }
                 {
                    data?.outSideFood && (
                        <li className='SpotLetContent form-label col-lg-12 col-xl-12'>
                            Outside food allowed
                        </li>
                    )
                }
                {
                    data?.smoking && (
                        <li className='SpotLetContent form-label col-lg-12 col-xl-12'>
                            Smoking allowed
                        </li>
                    )
                }
                {
                    data?.nonVeg && (
                        <li className='SpotLetContent form-label col-lg-12 col-xl-12'>
                            Non-Veg allowed
                        </li>
                    )
                }
                {
                    data?.cook &&  (
                        <li className='SpotLetContent form-label col-lg-12 col-xl-12'>
                    Cuisines Known by cook
                    <ul>
                        {
                            data?.cuisines?.map((each) => {
                                return <li className='SpotLetContent form-label col-lg-12 col-xl-12'> {each?.label} </li>
                            } )
                        }
                    </ul>
                </li>
                    )
                }
                {
                    data?.inHouseAlcohol && (
                        <li className='SpotLetContent form-label col-lg-12 col-xl-12'>
                            In-House alcohol available
                        </li>
                    )
                }
                {
                    data?.outsideAlcohol && (
                        <li className='SpotLetContent form-label col-lg-12 col-xl-12'>
                           Outside alcohol allowed
                        </li>
                    )
                }
                {
                    data?.inHouseDj && (
                        <li className='SpotLetContent form-label col-lg-12 col-xl-12'>
                            In-House Dj available
                        </li>
                    )
                }
                {
                    data?.outsideDj && (
                        <li className='SpotLetContent form-label col-lg-12 col-xl-12'>
                           Outside Dj allowed
                        </li>
                    )
                }
                {
                    data?.caretaker?.available && (
                        <li className='SpotLetContent form-label col-lg-12 col-xl-12'>
                            Caretaker available - {data?.caretaker?.fullDay ? "24 Hours" : `${data?.caretaker?.timings?.start} - ${data?.caretaker?.timings?.end}` }
                        </li>
                    )
                }
                {
                    data?.customPolicy && (
                        <li className='SpotLetContent form-label col-lg-12 col-xl-12'>
                            Host Policies
                            <ul>
                                {
                                    data?.customPolicies?.map((each) => {
                                        return <li className='SpotLetContent form-label col-lg-12 col-xl-12'> {each} </li>
                                    } )
                                }
                            </ul>
                        </li>
                    )
                }
            </>
        )
    }
    
    const getStayPolicies = (data) => {
        return(
            <>
                <li className='SpotLetContent form-label col-lg-12 col-xl-12'>
                    {data?.cancellation}
                </li>
                <li className='SpotLetContent form-label col-lg-12 col-xl-12'>
                    Id Proofs 
                    <ul>
                        {
                            data?.idProof?.map((each) => {
                                return <li className='SpotLetContent form-label col-lg-12 col-xl-12'> {each?.label} </li>
                            } )
                        }
                    </ul>
                </li>
                {
                    data?.inhousePets
                    && (
                        <li className='SpotLetContent form-label col-lg-12 col-xl-12'>
                           Pets are present at the property
                        </li>
                    )
                }
                {
                    data?.outsidePets
                    && (
                        <li className='SpotLetContent form-label col-lg-12 col-xl-12'>
                           Outside pets are allowed
                        </li>
                    )
                }
                {
                    data?.coupleFriendly
                    && (
                        <li className='SpotLetContent form-label col-lg-12 col-xl-12'>
                           Couple Friendly
                        </li>
                    )
                }
                {
                    data?.cook && (
                        <li className='SpotLetContent form-label col-lg-12 col-xl-12'>
                           Cook Available
                        </li>
                    )
                }
                {
                    data?.cook &&  (
                        <li className='SpotLetContent form-label col-lg-12 col-xl-12'>
                    Cuisines Known by cook
                    <ul>
                        {
                            data?.cuisines?.map((each) => {
                                return <li className='SpotLetContent form-label col-lg-12 col-xl-12'> {each?.label} </li>
                            } )
                        }
                    </ul>
                </li>
                    )
                }
                {
                    data?.nonVeg && (
                        <li className='SpotLetContent form-label col-lg-12 col-xl-12'>
                            Non-Veg allowed
                        </li>
                    )
                }
                {
                    data?.outSideFood && (
                        <li className='SpotLetContent form-label col-lg-12 col-xl-12'>
                            Outside food allowed
                        </li>
                    )
                }
                {
                    data?.smoking && (
                        <li className='SpotLetContent form-label col-lg-12 col-xl-12'>
                            Smoking allowed
                        </li>
                    )
                }
                {
                    data?.outsideAlcohol && (
                        <li className='SpotLetContent form-label col-lg-12 col-xl-12'>
                           Outside alcohol allowed
                        </li>
                    )
                }
                {
                    data?.outsideDj && (
                        <li className='SpotLetContent form-label col-lg-12 col-xl-12'>
                           Outside Dj allowed
                        </li>
                    )
                }
                {
                    data?.music?.allowed && (
                        <li className='SpotLetContent form-label col-lg-12 col-xl-12'>
                            Music allowed - {data?.music?.fullDay ? "24 Hours" : `${data?.music?.timings?.start} - ${data?.music?.timings?.end}` }
                        </li>
                    )
                }
                {
                    data?.caretaker?.available && (
                        <li className='SpotLetContent form-label col-lg-12 col-xl-12'>
                            Caretaker available - {data?.caretaker?.fullDay ? "24 Hours" : `${data?.caretaker?.timings?.start} - ${data?.caretaker?.timings?.end}` }
                        </li>
                    )
                }
                {
                    data?.customPolicy && (
                        <li className='SpotLetContent form-label col-lg-12 col-xl-12'>
                            Host Policies
                            <ul>
                                {
                                    data?.customPolicies?.map((each) => {
                                        return <li className='SpotLetContent form-label col-lg-12 col-xl-12'> {each} </li>
                                    } )
                                }
                            </ul>
                        </li>
                    )
                }
            </>
        )
    }

    return (
        <div className="policiesMainContainer" >
            <h1 className="policiesHead SpotLetMainHead" id = "propertyPage5"> Policies </h1>
            <div className="container" >
                <div className="row" >

                 {/* <div className="col-lg-6 mb-4" >
                    <h1 className="policiesSubHead SpotLetSubHead"> Cancellation Policy : </h1>
                    <ul> 
                        <li> <span className="policy SpotLetContent">{data?.free_cancellation_period !== 'Non Refundable' ? `Free cancellation upto ${data?.free_cancellation_period}` : data?.free_cancellation_period} </span> </li>
                    </ul>
                 </div> */}

                 {/* <div className="col-lg-6 mb-4" >
                    <h1 className="policiesSubHead SpotLetSubHead"> Acceptable Identity Proofs : </h1>
                    <ul> 
                        {
                            data?.accepted_identity_proof?.map((each) => <li> <span className="policy SpotLetContent"> {each?.label} </span> </li> )
                        }
                    </ul>
                 </div> */}

                 {/* <div className="col-lg-6 mb-4" >
                    <h1 className="policiesSubHead SpotLetSubHead"> Property Rules : </h1>
                    <div className="logoPolicyContainer">
                        <img className="policyLogo" src = {data?.unmarried_couples_allowed === true ? correct : remove} />
                        <p className="policy SpotLetContent" > Unmarried couples </p>
                    </div>
                    <div className="logoPolicyContainer">
                        <img className="policyLogo" src = {data?.alcohol_allowed === true ? correct : remove} />
                        <p className="policy SpotLetContent" > Alcohol allowed within the premises </p>
                    </div>
                 </div> */}

                 {/* <div className="col-lg-6 mb-4" >
                    <h1 className="policiesSubHead SpotLetSubHead"> Pet Policy : </h1>
                    <div className="logoPolicyContainer">
                        <img className="policyLogo" src = {data?.pets_allowed === true ? correct : remove} />
                        <p className="policy SpotLetContent" > Pets allowed {data?.pets_allowed === true ? `(${data?.no_of_pets_allowed})` : null} </p>
                    </div>
                    <div className="logoPolicyContainer">
                        <img className="policyLogo" src = {data?.pets_living_on_property === true ? correct : remove} />
                        <p className="policy SpotLetContent" > Any pet(s) living on the property </p>
                    </div>
                 </div> */}

                 {/* <div className="col-lg-6 mb-4" >
                    <h1 className="policiesSubHead SpotLetSubHead"> Property Restrictions : </h1>
                    <div className="logoPolicyContainer">
                        <img className="policyLogo" src = {data?.smoking_allowed_within_property === true ? correct : remove} />
                        <p className="policy SpotLetContent" > Smoking allowed within the premises  </p>
                    </div>
                    <div className="logoPolicyContainer">
                        <img className="policyLogo" src = {data?.restrictions_on_entry_exit_timings === true ? correct : remove} />
                        <p className="policy SpotLetContent" > Restrictions on the entry & exit timing for the guests </p>
                    </div>
                    <div className="logoPolicyContainer">
                        <img className="policyLogo" src = {data?.wheel_chair_accessibility === true ? correct : remove} />
                        <p className="policy SpotLetContent" > Property accessible for guests who use a wheelchair  </p>
                    </div>
                    <div className="logoPolicyContainer">
                        <img className="policyLogo" src = {data?.visitors_allowed === true ? correct : remove} />
                        <p className="policy SpotLetContent" > Guests invite any outside visitors in the room during their stay </p>
                    </div>
                    <div className="logoPolicyContainer">
                        <img className="policyLogo" src = {data?.private_parties_events_allowed === true ? correct : remove} />
                        <p className="policy SpotLetContent" > private parties or events allowed at the property  </p>
                    </div>
                 </div> */}

                 {/* <div className="col-lg-6 mb-4" >
                    <h1 className="policiesSubHead SpotLetSubHead"> Key Exchange : </h1>
                    <div className="logoPolicyContainer">
                        <img className="policyLogo" src = {data?.self_check_in_via_smart_door_availability === true ? correct : remove} />
                        <p className="policy SpotLetContent" > Self check- in via smart Door available </p>
                    </div>
                    <div className="logoPolicyContainer">
                        <img className="policyLogo" src = {data?.will_host_receives_guests === true ? correct : remove} />
                        <p className="policy SpotLetContent" > Host Greets & Helps Guests Check-in </p>
                    </div>
                    <div className="logoPolicyContainer">
                        <img className="policyLogo" src = {data?.will_caretaker_receives_guests === true ? correct : remove} />
                        <p className="policy SpotLetContent" > Caretaker Greets & Help Guests Check-in </p>
                    </div>
                 </div> */}

                 {/* <div className="col-lg-6 mb-4" >
                    <h1 className="policiesSubHead SpotLetSubHead"> Food & Kitchen Rules : </h1>
                    <div className="logoPolicyContainer">
                        <img className="policyLogo" src = {data?.cook_available === true ? correct : remove} />
                        <p className="policy SpotLetContent" > Cook Available </p>
                    </div>
                    {
                        data?.cook_available &&
                        <>
                        <p className="policy SpotLetContent mx-4" > Cuisines known by cook </p>
                        <ul> 
                            {
                                data?.cuisine_known_by_cook?.map((each) =>  <li className="mx-3"> <span className="policy SpotLetContent" >  {each?.label}  </span></li> )
                            }
                        </ul>
                        </>
                    }
                 </div> */}

                 {/* <div className="col-lg-6 mb-4" >
                    <h1 className="policiesSubHead SpotLetSubHead"> Caretaker Information : </h1>
                    <div className="logoPolicyContainer">
                        <img className="policyLogo" src = {data?.caretaker?.available === true ? correct : remove} />
                        <p className="policy SpotLetContent" > The Caretaker available at the property </p>
                    </div>
                    {
                       data?.caretaker?.available &&
                        <>
                        <ul> 
                            { data?.caretaker?.full_day &&
                                <li className="mx-3"> <span className="policy SpotLetContent" >  Full Day  </span></li> 
                            }
                            { !data?.caretaker?.full_day &&
                                <li className="mx-3"> <span className="policy SpotLetContent" >  {data?.caretaker?.timings?.start} -  {data?.caretaker?.timings?.end} </span></li> 
                            }
                        </ul>
                        </>
                    }
                    <div className="logoPolicyContainer">
                        <img className="policyLogo" src = {data?.caretaker?.available && data?.caretaker_help_in_cleaning === true ? correct : remove} />
                        <p className="policy SpotLetContent" > The Caretaker help in cleaning the property </p>
                    </div>
                 </div> */}


                 {/* <div className="col-lg-12 mb-3" >
                    <h1 className="policiesSubHead SpotLetSubHead"> Other Policies : </h1>
                    <ul className="policyList">
                        <li> <span className="policy SpotLetContent" > Guests below 18 years old are not allowed to book the property </span> </li>
                        <li> <span className="policy SpotLetContent" > Maximum guests specified exceeding may lead to charges or booking cancellation  </span></li>
                        <li> <span className="policy SpotLetContent" > Guests must follow local laws and regulations during their stay for safety </span></li>
                        <li> <span className="policy SpotLetContent" > Early or late check-in/out requests must be made in advance, subject to availability </span> </li>
                        <li> <span className="policy SpotLetContent" > Guests are expected to treat the property and neighbours with respect </span> </li>
                        <li> <span className="policy SpotLetContent" > Guests who have booked a stay and want to host events or film shootings should talk to the property owner first about pricing differences </span></li>
                        <li> <span className="policy SpotLetContent" > A refundable security deposit is required for events/Film Shootings to cover potential property damages </span> </li>
                        <li> <span className="policy SpotLetContent" > Host bears no responsibility for loss, theft, or injury during the guest's stay/events/Film shootings </span> </li>
                        <li> <span className="policy SpotLetContent" >  Host is not liable for accidents or medical emergencies occurring on the property </span> </li>
                    </ul>
                 </div> */}
                 
                    <Accordion defaultActiveKey={getActive()}  className="mb-3" >
                       { (data?.film?.status) && (
                            <Accordion.Item  eventKey="0" className='mb-2'>
                                <Accordion.Header> Film/TV Shooting Policies : </Accordion.Header>
                                    <Accordion.Body>
                                        <div className="col-lg-12 mb-3" >
                                            {/* <h1 className="policiesSubHead SpotLetSubHead"> Film/TV Shooting Policies : </h1> */}
                                            <ul className="policyList">
                                                {/* {
                                                    defaultFilmTvPolicies.map((each) => {
                                                        return defaultLiElement(each)
                                                    }) 
                                                } */}
                                                {
                                                    data?.film?.staticPolices?.map((each) => {
                                                        return <li className="SpotLetContent form-label col-lg-12 col-xl-12"> {each} </li>
                                                    }) 
                                                }
                                                {
                                                    getFilmTvPolicies(data?.film)
                                                }
                                            </ul>
                                            {
                                                ( pricingData?.reqData?.film && (pricingData?.extraData?.film?.cleaningFee || pricingData?.extraData?.film?.securityDeposit) ) && (
                                                    <div className='d-flex flex-row'>
                                                        { pricingData?.extraData?.film?.cleaningFee && <span className='mx-2  SpotLetContent '>Film Cleaning Fee : <span style={{fontWeight:600}}>  {pricingData?.extraData?.film?.cleaningFee} </span> </span> }
                                                        { pricingData?.extraData?.film?.securityDeposit && <span className='mx-2  SpotLetContent '>Film Security Deposit : <span style={{fontWeight:600}}>  {pricingData?.extraData?.film?.securityDeposit} </span> </span> }
                                                    </div>
                                                )
                                            }
                                            {
                                                ( pricingData?.reqData?.tv && (pricingData?.extraData?.tv?.cleaningFee || pricingData?.extraData?.tv?.securityDeposit) ) && (
                                                    <div className='d-flex flex-row'>
                                                        { pricingData?.extraData?.tv?.cleaningFee && <span className='mx-2  SpotLetContent ' > Tv Cleaning Fee : <span style={{fontWeight:600}}> {pricingData?.extraData?.tv?.cleaningFee}  </span></span> }
                                                        { pricingData?.extraData?.tv?.securityDeposit && <span className='mx-2  SpotLetContent ' > Tv Security Deposit :  <span style={{fontWeight:600}}> {pricingData?.extraData?.tv?.securityDeposit} </span> </span> }
                                                    </div>
                                                )
                                            }
                                        </div>
                                    </Accordion.Body>
                            </Accordion.Item>
                        )}
                       { data?.event?.status  &&  (
                            <Accordion.Item eventKey="1"  className='mb-2' >
                                <Accordion.Header> Events Policies : </Accordion.Header>
                                    <Accordion.Body>
                                        <div className="col-lg-12 mb-3" >
                                            {/* <h1 className="policiesSubHead SpotLetSubHead"> Events Policies : </h1> */}
                                            <ul className="policyList">
                                                {/* {
                                                    defaultEventPolicies.map((each) => {
                                                        return defaultLiElement(each)
                                                    }) 
                                                } */}
                                                {
                                                    data?.event?.staticPolices?.map((each) => {
                                                        return <li className="SpotLetContent form-label col-lg-12 col-xl-12"> {each} </li>
                                                    }) 
                                                }
                                                {
                                                    getEventPolicies(data?.event)
                                                }
                                            </ul>
                                            {
                                                (pricingData?.extraData?.event?.cleaningFee || pricingData?.extraData?.event?.securityDeposit) && (
                                                    <div className='d-flex flex-row'>
                                                        { pricingData?.extraData?.event?.cleaningFee && <span className='mx-2  SpotLetContent ' > Cleaning Fee : <span style={{fontWeight:600}}> {pricingData?.extraData?.event?.cleaningFee} </span></span> }
                                                        { pricingData?.extraData?.event?.securityDeposit && <span className='mx-2  SpotLetContent '> Security Deposit : <span style={{fontWeight:600}}>  {pricingData?.extraData?.event?.securityDeposit} </span> </span> }
                                                    </div>
                                                )
                                            }
                                        </div>
                                    </Accordion.Body>
                            </Accordion.Item>
                        )}
                       { data?.stay?.status  && (
                            <Accordion.Item eventKey="2"  className='mb-2'>
                                <Accordion.Header> Homestay Policies : </Accordion.Header>
                                    <Accordion.Body>
                                        <div className="col-lg-12 mb-3" >
                                            {/* <h1 className="policiesSubHead SpotLetSubHead"> Homestay Policies : </h1> */}
                                            <ul className="policyList">
                                                {/* {
                                                    defaultHomeStayPolicies.map((each) => {
                                                        return defaultLiElement(each)
                                                    }) 
                                                } */}
                                                {
                                                    data?.stay?.staticPolices?.map((each) => {
                                                        return <li className="SpotLetContent form-label col-lg-12 col-xl-12"> {each} </li>
                                                    }) 
                                                }
                                                {
                                                    getStayPolicies(data?.stay)
                                                }
                                            </ul>
                                            {
                                                (pricingData?.extraData?.stay?.cleaningFee || pricingData?.extraData?.stay?.securityDeposit) && (
                                                    <div className='d-flex flex-row'>
                                                        { pricingData?.extraData?.stay?.cleaningFee && <span className='mx-2  SpotLetContent '> Cleaning Fee : <span style={{fontWeight:600}}>  {pricingData?.extraData?.stay?.cleaningFee}  </span> </span> }
                                                        { pricingData?.extraData?.stay?.securityDeposit && <span className='mx-2  SpotLetContent '> Security Deposit : <span style={{fontWeight:600}}>  {pricingData?.extraData?.stay?.securityDeposit} </span> </span> }
                                                    </div>
                                                )
                                            }
                                        </div>
                                    </Accordion.Body>
                            </Accordion.Item>
                        )}
                    </Accordion>

                 {/* <div className="col-lg-6 mb-3" >
                    <h1 className="policiesSubHead SpotLetSubHead"> Terms and Conditions : </h1>
                    <div className="logoPolicyContainer">
                        <img className="policyLogo" src = {data?.privacy_policy === true ? correct : remove} />
                        <p className="policy" > I have read and agree to the privacy policy </p>
                    </div>
                    <div className="logoPolicyContainer">
                        <img className="policyLogo" src = {data?.term_cond === true ? correct : remove} />
                        <p className="policy" > I agree to the Term and Conditions </p>
                    </div>
                    <div className="logoPolicyContainer">
                        <img className="policyLogo" src = {data?.grant_info === true ? correct : remove} />
                        <p className="policy" > I Consent to SpotLet using my information </p>
                    </div>
                 </div> */}

                </div>
            </div>
        </div>
    )
}

export default Policies