import { useEffect, useState, useRef } from "react";
import { updateBillingDetails } from "../apis/api";
import { Header } from "../components/NewHeader/Header";
import { Sidebar } from "../components/Sidebar";
import { useAuthStore } from "../store/auth";

import { Formik } from "formik";
import * as yup from "yup";
import { Button, Col, Form, Row } from "react-bootstrap";

import { ConfirmationModel } from '../components/Modal/ConfirmationModel';

const schema = yup.object().shape({
  companyName: yup.string().required("Company Name is requred"),
  pan: yup.string().required('PAN Number is required')
    .min(10, 'PAN number must be 10 characters')
    .max(10, 'PAN number must be 10 characters'),
  aadhar: yup.string().required('Aadhar number is required')
    .min(12, 'Aadhar number must be 12 digits')
    .max(12, 'Aadhar number must be 12 digits'),
  bankAccountHolderName: yup.string().required("Account Holder's Name is required"),
  bankName: yup.string().required("Bank Name is required")
    .matches(/^[aA-zZ\s]+$/, "Only alphabets are allowed for Bank Name"),
  bankIfscCode: yup.string().required("IFSC Code is required")
    .min(11, 'IFSC Code must be 11 characters')
    .max(11, 'IFSC Code must be 11 characters'),
  bankAccountNumber: yup.string().required("Account Number is required")
});

export const Billing = () => {
  const formikRef = useRef();

  const { userProfile, setUserProfile } = useAuthStore();

  const [showConfirmationModel, setShowConfirmationModel] = useState(false);
  const [confirmationTitle, setConfirmationTitle] = useState("");
  const [confirmationBody, setConfirmationBody] = useState("");

  const [initialValues, setInitialValues] = useState({
    pan: userProfile?.billing?.pan || "",
    aadhar: userProfile?.billing?.aadhar || "",

    companyName: userProfile?.billing?.companyName || "",
    bankAccountHolderName:
      userProfile?.billing?.bankAccountHolderName || "",
    bankName: userProfile?.billing?.bankName || "",
    bankIfscCode: userProfile?.billing?.bankIfscCode || "",
    bankAccountNumber: userProfile?.billing?.bankAccountNumber || "",
    gst: userProfile?.billing?.gst || "",
  });

  const [billingDetails, setBillingDetails] = useState({
    aadhar: "",
    bankName: "",
    bankAccountHolderName: "",
    bankAccountNumber: "",
    bankIfscCode: "",
    companyName: "",
    pan: "",
    gst: "",
  });

  useEffect(() => {
    if (userProfile?.billing) {
      setBillingDetails({
        aadhar: userProfile?.billing?.aadhar || "",
        bankName: userProfile?.billing?.bankName || "",
        bankAccountHolderName:
          userProfile?.billing?.bankAccountHolderName || "",
        bankAccountNumber: userProfile?.billing?.bankAccountNumber || "",
        bankIfscCode: userProfile?.billing?.bankIfscCode || "",
        companyName: userProfile?.billing?.companyName || "",
        pan: userProfile?.billing?.pan || "",
        gst: userProfile?.billing?.gst || "",
      });
    }
  }, [userProfile]);

  const handleChange = (event) => {
    const { name, value } = event.target;
    setBillingDetails({ ...billingDetails, [name]: value });
  };

  const onSave = async () => {
    try {
      await updateBillingDetails(userProfile._id, billingDetails);
      setUserProfile();
    } catch (error) { }
  };

  const onFormSubmit = async (formValues) => {

    try {
      await updateBillingDetails(userProfile._id, formValues);
      setUserProfile();

      setConfirmationTitle("Billing");
      setConfirmationBody("Billing Updated Successfully");
      setShowConfirmationModel(true);

    } catch (error) {
      setConfirmationTitle("Billing");
      setConfirmationBody("Error while updating Billing");
      setShowConfirmationModel(true);
    }
  };

  return (
    <>
      <Header />
      <div className="container-fluid p-0 h-100">
        <div className="row h-100 g-0">
          <div className="d-flex edit-profile">
            <div className="col-lg-2">
              <Sidebar />
            </div>
            <div className="col-lg-10">
              <div className="row g-0 h-100">
                <div className="col-xl-12">
                  <div className="h-100">
                    <div className="step-form h-100">
                      <div className="e-profile">
                        <h3 className="etitle">Billing</h3>
                        <div classN="row">
                          <div className="col-md-7">
                            <ul className="nav nav-tabs theme-tabs tabs2" id="myTab" role="tablist">
                              <li className="nav-item" role="presentation">
                                <button className="nav-link first active w-100" id="billinginfo-tab" data-bs-toggle="tab" data-bs-target="#billinginfo" type="button" role="tab" aria-controls="billinginfo" aria-selected="true">
                                  <svg width="16" height="19" viewBox="0 0 16 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M0.244141 0.38269V18.6116H1.12112L3.36119 16.3168L5.59951 18.6116L7.83958 16.3168L10.0796 18.6116L12.3177 16.3168L14.5579 18.6116H15.4349V0.38269H0.244141ZM2.20272 2.55999H10.6387V3.74079H2.20272V2.55999ZM2.20272 8.09754H8.64145V9.27835L2.20272 9.27848V8.09754ZM13.4766 12.0452H2.20272V10.8644H13.4762L13.4766 12.0452ZM13.4766 9.27845H10.6389V8.09765H13.4766V9.27845ZM13.4766 6.50967H2.20272V5.32887H13.4762L13.4766 6.50967Z" fill="#EA4335" />
                                  </svg>
                                  <span className="ms-2">Billing Info</span></button>
                              </li>
                              <li className="nav-item" role="presentation">
                                <button className="nav-link last w-100" id="history-tab" data-bs-toggle="tab" data-bs-target="#history" type="button" role="tab" aria-controls="history" aria-selected="false">
                                  <svg width="17" height="21" viewBox="0 0 17 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M1.05482 20.2855C1.65484 20.673 2.41393 20.7178 3.0555 20.4037C3.55014 20.1304 4.10936 19.9953 4.67435 20.0127C5.2462 19.9943 5.81224 20.1327 6.31132 20.4128C6.99674 20.7758 7.76378 20.9574 8.53931 20.9404C9.31484 20.9574 10.0819 20.7758 10.7675 20.4128C11.2653 20.1301 11.8321 19.9914 12.4044 20.0127C12.9691 19.9966 13.528 20.1316 14.0231 20.4037C14.6528 20.7047 15.3923 20.6639 15.9851 20.2957C16.578 19.9276 16.9422 19.2827 16.9516 18.5849V3.30653C16.9422 2.60879 16.578 1.96386 15.9851 1.59558C15.3923 1.22745 14.6528 1.18681 14.0231 1.48764C13.528 1.75971 12.9691 1.89474 12.4044 1.87876C11.8321 1.89992 11.2653 1.76138 10.7675 1.47848C10.0764 1.10152 9.29917 0.910299 8.51207 0.923863C7.73653 0.906658 6.9695 1.08829 6.28392 1.45124C5.78499 1.73138 5.21895 1.86976 4.64695 1.85136C4.09108 1.86522 3.54164 1.73017 3.0555 1.4604C2.42597 1.15955 1.68634 1.20021 1.09348 1.56834C0.500782 1.93663 0.13644 2.58155 0.127163 3.27928V18.5577C0.117115 19.2551 0.467742 19.9086 1.05482 20.2855ZM4.41964 6.38026H10.331C10.5746 6.38026 10.7998 6.51028 10.9216 6.72129C11.0436 6.93231 11.0436 7.19235 10.9216 7.40336C10.7998 7.61438 10.5746 7.7444 10.331 7.7444H4.41964C4.1759 7.7444 3.95073 7.61438 3.82893 7.40336C3.70713 7.19235 3.70713 6.93231 3.82893 6.72129C3.95073 6.51028 4.1759 6.38026 4.41964 6.38026ZM4.41964 10.0179H12.6045C12.8482 10.0179 13.0734 10.1479 13.1952 10.3589C13.317 10.57 13.317 10.83 13.1952 11.041C13.0734 11.252 12.8482 11.3821 12.6045 11.3821H4.41964C4.1759 11.3821 3.95073 11.252 3.82893 11.041C3.70713 10.83 3.70713 10.57 3.82893 10.3589C3.95073 10.1479 4.1759 10.0179 4.41964 10.0179ZM4.41964 13.6556H12.6045C12.8482 13.6556 13.0734 13.7856 13.1952 13.9966C13.317 14.2076 13.317 14.4677 13.1952 14.6787C13.0734 14.8897 12.8482 15.0197 12.6045 15.0197H4.41964C4.1759 15.0197 3.95073 14.8897 3.82893 14.6787C3.70713 14.4677 3.70713 14.2076 3.82893 13.9966C3.95073 13.7856 4.1759 13.6556 4.41964 13.6556Z" fill="#EA4335" />
                                  </svg>
                                  <span className="ms-2">Billing History</span></button>
                              </li>
                            </ul>
                          </div>
                        </div>
                        <div className="tab-content" id="myTabContent">
                          <div className="tab-pane fade show active" id="billinginfo" role="tabpanel" aria-labelledby="billinginfo-tab">
                            <div className="row">
                              <div className="col-xl-8">
                                <Formik
                                  validationSchema={schema}
                                  onSubmit={onFormSubmit}
                                  initialValues={initialValues}
                                  innerRef={formikRef}
                                  enableReinitialize={true}
                                >
                                  {({
                                    handleSubmit,
                                    handleChange,
                                    handleBlur,
                                    values,
                                    touched,
                                    isValid,
                                    errors,
                                  }) => (
                                    <Form noValidate onSubmit={handleSubmit} autoComplete="off">
                                      <>
                                        <Row>
                                          <div className="col-lg-5">


                                            <Form.Group as={Col}>
                                              <Form.Label>
                                                Company Name

                                              </Form.Label>
                                              <Form.Control
                                                type="text"
                                                name="companyName"
                                                value={values.companyName}
                                                onChange={handleChange}
                                                isInvalid={!!touched.companyName && !!errors.companyName}
                                              />
                                              <Form.Control.Feedback
                                                type="invalid"
                                                className="text-capitalize"
                                              >
                                                {errors.companyName}
                                              </Form.Control.Feedback>

                                            </Form.Group>
                                          </div>
                                          <div className="col-lg-5">
                                            <Form.Group as={Col}>
                                              <Form.Label>
                                                Pan Number

                                              </Form.Label>
                                              <Form.Control
                                                type="text"
                                                name="pan"
                                                value={values.pan}
                                                onChange={handleChange}
                                                isInvalid={!!touched.pan && !!errors.pan}
                                              />
                                              <Form.Control.Feedback
                                                type="invalid"
                                                className="text-capitalize"
                                              >
                                                {errors.pan}
                                              </Form.Control.Feedback>
                                            </Form.Group>
                                          </div>
                                        </Row>
                                        <Row>
                                          <div className="col-lg-5">
                                            <Form.Group as={Col}>
                                              <Form.Label>
                                                Aadhar Card Number
                                              </Form.Label>
                                              <Form.Control
                                                type="number"
                                                name="aadhar"
                                                value={values.aadhar}
                                                onChange={handleChange}
                                                isInvalid={!!touched.aadhar && !!errors.aadhar}
                                              />
                                              <Form.Control.Feedback
                                                type="invalid"
                                                className="text-capitalize"
                                              >
                                                {errors.aadhar}
                                              </Form.Control.Feedback>
                                            </Form.Group>
                                          </div>
                                          <div className="col-lg-5">
                                            <Form.Group as={Col}>
                                              <Form.Label>
                                                GST Number
                                              </Form.Label>
                                              <Form.Control
                                                type="text"
                                                name="gst"
                                                value={values.gst}
                                                onChange={handleChange}
                                                isInvalid={!!touched.gst && !!errors.gst}
                                              />
                                              <Form.Control.Feedback
                                                type="invalid"
                                                className="text-capitalize"
                                              >
                                                {errors.gst}
                                              </Form.Control.Feedback>
                                            </Form.Group>
                                          </div>
                                        </Row>
                                        <div className="col-lg-10">
                                          <hr className="ms-0" />
                                        </div>
                                        <div className="deactive">
                                          <h3 className="text-dark">Bank Details</h3>
                                        </div>
                                        <Row>
                                          <div className="col-lg-5">
                                            <Form.Group as={Col}>
                                              <Form.Label>
                                                IFSC Code
                                                <span className="required">*</span>

                                              </Form.Label>
                                              <Form.Control
                                                type="text"
                                                name="bankIfscCode"
                                                value={values.bankIfscCode}
                                                onChange={handleChange}
                                                isInvalid={!!touched.bankIfscCode && !!errors.bankIfscCode}
                                              />
                                              <Form.Control.Feedback
                                                type="invalid"
                                                className="text-capitalize"
                                              >
                                                {errors.bankIfscCode}
                                              </Form.Control.Feedback>
                                            </Form.Group>
                                          </div>
                                          <div className="col-lg-5">
                                            <Form.Group as={Col}>
                                              <Form.Label>
                                                Account Number
                                                <span className="required">*</span>

                                              </Form.Label>
                                              <Form.Control
                                                type="number"
                                                name="bankAccountNumber"
                                                value={values.bankAccountNumber}
                                                onChange={handleChange}
                                                isInvalid={!!touched.bankAccountNumber && !!errors.bankAccountNumber}
                                              />
                                              <Form.Control.Feedback
                                                type="invalid"
                                                className="text-capitalize"
                                              >
                                                {errors.bankAccountNumber}
                                              </Form.Control.Feedback>
                                            </Form.Group>
                                          </div>
                                        </Row>
                                        <Row>
                                          <div className="col-lg-5">
                                            <Form.Group as={Col}>
                                              <Form.Label>
                                                Account Holder's Name
                                                <span className="required">*</span>

                                              </Form.Label>
                                              <Form.Control
                                                type="text"
                                                name="bankAccountHolderName"
                                                value={values.bankAccountHolderName}
                                                onChange={handleChange}
                                                isInvalid={!!touched.bankAccountHolderName && !!errors.bankAccountHolderName}
                                              />
                                              <Form.Control.Feedback
                                                type="invalid"
                                                className="text-capitalize"
                                              >
                                                {errors.bankAccountHolderName}
                                              </Form.Control.Feedback>
                                            </Form.Group>
                                          </div>
                                          <div className="col-lg-5">
                                            <Form.Group as={Col}>
                                              <Form.Label>
                                                Bank Name
                                                <span className="required">*</span>

                                              </Form.Label>
                                              <Form.Control
                                                type="text"
                                                name="bankName"
                                                value={values.bankName}
                                                onChange={handleChange}
                                                isInvalid={!!touched.bankName && !!errors.bankName}
                                              />
                                              <Form.Control.Feedback
                                                type="invalid"
                                                className="text-capitalize"
                                              >
                                                {errors.bankName}
                                              </Form.Control.Feedback>
                                            </Form.Group>
                                          </div>
                                        </Row>
                                        <div className="col-lg-10">
                                          <hr className="ms-0" />
                                        </div>
                                        <div className="button-bar mb-3">
                                          <Button className="btn btn-theme btn-active" type="submit">Save</Button>
                                        </div>
                                      </>
                                    </Form>
                                  )}
                                </Formik>
                              </div>
                            </div>
                          </div>
                          <div className="tab-pane fade" id="history" role="tabpanel" aria-labelledby="history-tab">
                          </div>
                        </div>
                        {/* <div className="row">
                          <div className="col-lg-5">
                            <div className="mb-4">
                              <label
                                htmlFor="exampleInputEmail1"
                                className="form-label "
                              >
                                Company Name<span></span>
                              </label>
                              <input
                                type="text"
                                className="form-control"
                                id="cname"
                                value={billingDetails.companyName}
                                name="companyName"
                                onChange={handleChange}
                              />
                            </div>
                          </div>
                          <div className="col-lg-5">
                            <div className="mb-4">
                              <label
                                htmlFor="exampleInputEmail1"
                                className="form-label "
                              >
                                Pan Number<span></span>
                              </label>
                              <input
                                type="text"
                                className="form-control"
                                id="pnumber"
                                value={billingDetails.pan}
                                name="pan"
                                onChange={handleChange}
                              />
                            </div>
                          </div>
                          <div className="col-lg-5">
                            <div className="mb-4">
                              <label
                                htmlFor="exampleInputEmail1"
                                className="form-label "
                              >
                                Aadhar Card Number<span></span>
                              </label>
                              <input
                                type="number"
                                className="form-control"
                                id="anumber"
                                value={billingDetails.aadhar}
                                name="aadhar"
                                onChange={handleChange}
                              />
                            </div>
                          </div>
                          <div className="col-lg-5">
                            <div className="mb-4">
                              <label
                                htmlFor="exampleInputEmail1"
                                className="form-label "
                              >
                                GST Number<span></span>
                              </label>
                              <input
                                type="text"
                                className="form-control"
                                id="gst"
                                value={billingDetails.gst}
                                name="gst"
                                onChange={handleChange}
                              />
                            </div>
                          </div>

                          <div className="col-lg-5">
                            <div className="mb-4">
                              <label
                                htmlFor="exampleInputEmail1"
                                className="form-label "
                              >
                                IFSC Code<span></span>
                              </label>
                              <input
                                type="text"
                                className="form-control"
                                id="ifsc"
                                value={billingDetails.bankIfscCode}
                                name="bankIfscCode"
                                onChange={handleChange}
                              />
                            </div>
                          </div>
                          <div className="col-lg-5">
                            <div className="mb-4">
                              <label
                                htmlFor="exampleInputEmail1"
                                className="form-label "
                              >
                                Account Number<span></span>
                              </label>
                              <input
                                type="number"
                                className="form-control"
                                id="anumber"
                                value={billingDetails.bankAccountNumber}
                                name="bankAccountNumber"
                                onChange={handleChange}
                              />
                            </div>
                          </div>
                          <div className="col-lg-5">
                            <div className="mb-4">
                              <label
                                htmlFor="exampleInputEmail1"
                                className="form-label "
                              >
                                Account Holder's Name<span></span>
                              </label>
                              <input
                                type="text"
                                className="form-control"
                                id="aname"
                                value={billingDetails.bankAccountHolderName}
                                name="bankAccountHolderName"
                                onChange={handleChange}
                              />
                            </div>
                          </div>

                          <div className="button-bar billing">
                            <a className="btn btn-theme btn-active">
                              Verify All
                            </a>
                            <a
                              className="btn btn-theme btn-active"
                              onClick={onSave}
                            >
                              Save
                            </a>
                          </div>
                        </div> */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {showConfirmationModel && (
        <ConfirmationModel
          show={showConfirmationModel}
          handleClose={() => setShowConfirmationModel(false)}
          handleOk={() => setShowConfirmationModel(false)}
          title={confirmationTitle}
          body={confirmationBody}
        />
      )}
    </>
  );
};
