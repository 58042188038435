import { useState,useEffect} from "react";
import Accordion from 'react-bootstrap/Accordion';
import { useAccordionButton } from 'react-bootstrap/AccordionButton';
import Card from 'react-bootstrap/Card';
import info from "../../../assets/NewImages/BecomeAHost/i.svg";
import { ConfirmationModel } from "../../../components/Modal/ConfirmationModel";

const INITIAL_DATA =  {
    film : {
        status : true,
        staticPolices : [
            "The property may only be booked for legitimate film or TV shooting purposes.",
            "Minimum booking duration required for film/TV shootings.",
            'A refundable security deposit is mandatory to cover any damages during the shoot.',
            "Production teams are responsible for handling their equipment safely and ensuring no damage to the property.",
            "Any modifications to the property for shooting purposes must be pre-approved by the owner.",
            "Shooting schedules must adhere to noise restrictions to minimize disruption to neighbors.",
            "Accommodation for the crew may be available on-site or nearby, subject to availability and prior arrangement.",
            "A cleaning fee may be applicable post-shoot to restore the property to its original condition.",
            "The property owner holds no liability for accidents, injuries, or damages that occur during the shoot.",
        ],
        customPolicy : null,
        customPolicies : [],
    },
    event : {
        status : true,
        staticPolices : [
            "Only specified event types (e.g., corporate, weddings) are Allowed.",
            'Events must adhere to the specified minimum and maximum booking durations.',
            "Strictly adhere to the maximum guest capacity outlined for each event.",
            "A mandatory security deposit is required to cover damages or violations.",
           " Respect noise restrictions to minimize disturbance to neighbors and maintain peace.",
            "Alcohol consumption is Allowed based on local regulations and with prior approval.",
            "Event organizers are solely responsible for cleaning up the venue post-event, including waste disposal.",
            "Any temporary modifications to the property for the event must receive prior approval from the host.",
            "The property owner holds no liability for accidents, injuries, or damages that occur during the event. Event organizers assume full responsibility.",
           " Drugs and illegal substances are strictly prohibited.",
        ],
        customPolicy : null,
        customPolicies : [],
    },
    stay : {
        status : true,
        staticPolices : [
            "Guests must adhere to the specified check-in and check-out times.",
            "Guests must comply with the minimum length of stay requirement for homestay bookings.",
            "Guests are required to respect house rules, property, and neighbors at all times.",
            "A security deposit is mandatory to cover any damages caused during the stay.",
            "Guests must seek prior approval from the host regarding pet accommodation. Host's decision on pet accommodation is final.",
            "Smoking is strictly prohibited indoors and allowed only in designated outdoor areas.",
           " Guests must comply with the homestay cancellation policy, including any associated fees.",
            "Guests are expected to use available amenities responsibly and follow any usage guidelines provided.",
            "The property owner bears no liability for accidents, injuries, or losses incurred by guests during their stay. Guests accept full responsibility for their actions and safety.",
            "Drugs and illegal substances are strictly prohibited.",
        ],
        customPolicy : null,
        customPolicies : [],
    },
    term_cond : false,
    privacy_policy : false,
    grant_info : false,
}

const timeOptions = [
    { value: "00:00", label: "00:00" },
    { value: "01:00", label: "01:00" },
    { value: "02:00", label: "02:00" },
    { value: "03:00", label: "03:00" },
    { value: "04:00", label: "04:00" },
    { value: "05:00", label: "05:00" },
    { value: "06:00", label: "06:00" },
    { value: "07:00", label: "07:00" },
    { value: "08:00", label: "08:00" },
    { value: "09:00", label: "09:00" },
    { value: "10:00", label: "10:00" },
    { value: "11:00", label: "11:00" },
    { value: "12:00", label: "12:00" },
    { value: "13:00", label: "13:00" },
    { value: "14:00", label: "14:00" },
    { value: "15:00", label: "15:00" },
    { value: "16:00", label: "16:00" },
    { value: "17:00", label: "17:00" },
    { value: "18:00", label: "18:00" },
    { value: "19:00", label: "19:00" },
    { value: "20:00", label: "20:00" },
    { value: "21:00", label: "21:00" },
    { value: "22:00", label: "22:00" },
    { value: "23:00", label: "23:00" },
    { value: "23:59", label: "23:59" },
  ];
  const stayTimeOptions = [
    { value: "00:00 AM", label: "00:00 AM" },
    { value: "01:00 AM", label: "01:00 AM" },
    { value: "02:00 AM", label: "02:00 AM" },
    { value: "03:00 AM", label: "03:00 AM" },
    { value: "04:00 AM", label: "04:00 AM" },
    { value: "05:00 AM", label: "05:00 AM" },
    { value: "06:00 AM", label: "06:00 AM" },
    { value: "07:00 AM", label: "07:00 AM" },
    { value: "08:00 AM", label: "08:00 AM" },
    { value: "09:00 AM", label: "09:00 AM" },
    { value: "10:00 AM", label: "10:00 AM" },
    { value: "11:00 AM", label: "11:00 AM" },
    { value: "12:00 PM", label: "12:00 PM" },
    { value: "01:00 PM", label: "01:00 PM" },
    { value: "02:00 PM", label: "02:00 PM" },
    { value: "03:00 PM", label: "03:00 PM" },
    { value: "04:00 PM", label: "04:00 PM" },
    { value: "05:00 PM", label: "05:00 PM" },
    { value: "06:00 PM", label: "06:00 PM" },
    { value: "07:00 PM", label: "07:00 PM" },
    { value: "08:00 PM", label: "08:00 PM" },
    { value: "09:00 PM", label: "09:00 PM" },
    { value: "10:00 PM", label: "10:00 PM" },
    { value: "11:00 PM", label: "11:00 PM" },
    { value: "11:59 PM", label: "11:59 PM" },
  ];

const STAY_INITIAL_DATA = {
    available : false,
    sleepingArrangements : {
        singleBed : 0,
        twinBed : 0,
        queenBed : 0,
        kingBed : 0,
        doubleBed : 0,
        bunkBed : 0,
        mattress : 0,
        sofaCumBed : 0,
        futon : 0,
        murphyBed : 0,
        tatamiMats : 0,

    },
    commonBathrooms : 0,
    attachedBathrooms : 0,
    baseGuests : 1,
    maxGuests : 1,
    // maxChildren : 0,
    // maxOccupancy : 1,
    weekdayPrice : "",
    weekendPrice : "",
    extraGuestFee : "",
    // extraChildFee : "",
    cleaningFee : "",
    securityDeposit : "",
    checkIn : "12:00 PM",
    checkOut : "11:00 AM",
    timings : {
        monday : false,
        tueday : false,
        wednesday : false,
        thursday : false,
        friday : false,
        saturday : false,
        sunday : false,
    },
    // petsAllowed : false,
    // maxPets : 0,
    mealOption : "accomodationOnly",
}

const STAY_MEAL_OPTIONS = [
    {value : "accomodationOnly", label : "Accomodation Only"},
    {value : "freeBreakfast", label : "Free Breakfast"},
    {value : "freeBreakfastAndLunch", label : "Free Breakfast and Lunch"},
    {value : "freeBreakfastAndDinner", label : "Free Breakfast and Dinner"},
    {value : "freeBreakfastAndLunchOrDinner", label : "Free Breakfast and Lunch/Dinner"},
    {value : "freeBreakfastAndLunchAndDinner", label : "Free Breakfast, Lunch and Dinner"},
]

const Pricing = ({data,handleNext,handleBack, activitiesData, policiesData}) => {

    const [formData,setFormData] = useState({});
    const [currency,setCurrency] = useState(null);
    const [currencyError,setCurrencyError] = useState(false);
    const [showConfirmationModel, setShowConfirmationModel] = useState(false);
    const [confirmationTitle, setConfirmationTitle] = useState('Invalid Timings');
    const [confirmationBody, setConfirmationBody] = useState("");
    const [init,setInit] = useState(false);
    const [submit, setSubmit] = useState(false);

    const [filmError,setFilmError] = useState({
        hourlyPrice : false,
        attendees : false,
        minBooking : false,
        timings : false,
        message : "",
        hours : false,
        "24Price" : false,
        "12Price" : false,
        "PerHourPrice" : false,
        "PerHourMinHours" : false,
    })
    const [tvError,setTvError] = useState({
        hourlyPrice : false,
        attendees : false,
        minBooking : false,
        timings : false,
        message : "",
        hours : false,
        "24Price" : false,
        "12Price" : false,
        "PerHourPrice" : false,
        "PerHourMinHours" : false,
    })
    const [eventError,setEventError] = useState({
        hourlyPrice : false,
        weekendPrice : false,
        attendees : false,
        minBooking : false,
        timings : false,
        message : "",
        venuePrice : false,
        venueMinHrs : false,
        venueGuests : false,
        perHourPrice : false,
        perHourMinHrs : false,
        perHourGuests : false,
        perPlateVegPrice : false,
        perPlateNonVegPrice : false,
        perPlateMinGuests : "",
        perPlateMaxGuests : "",
        vegOrNonVeg : false,
    })
    const [stayError,setStayError] = useState({
        sleepingArrangements : false,
        // hourlyPrice : false,
        weekdayPrice : false,
        weekendPrice : false,
        baseGuests : false,
        maxGuests : false,
        timings : false,
        message : "",
        message1 : "",
    })
    //accordion start
    function Toggle({ eventKey,type, callback }) {
        const decoratedOnClick = useAccordionButton(
          eventKey,
          () => callback && callback(eventKey),
        );
        const handleYes = (e) => {
            if(e.target.checked){
                if (type === "stay") {
                    setFormData({
                        ...formData,
                        [type] : {
                            ...STAY_INITIAL_DATA,
                            available : true,
                        }
                    })
                } else {
                    setFormData({
                        ...formData,
                        [type] : {
                            ...formData[type],
                            available : true,
                        }
                    })
                }
            }
            if(!formData[type]?.available || formData[type]?.available === false){
                decoratedOnClick()
            }
        }
        const handleNo = (e) => {
            if(formData[type]?.available === true){
                decoratedOnClick()
            }
            if(e.target.checked){
                if (type === "stay") {
                    setFormData({
                        ...formData,
                        [type] : {
                            ...STAY_INITIAL_DATA,
                        }
                    })
                    setStayError({
                        sleepingArrangements : false,
                        weekdayPrice : false,
                        weekendPrice : false,
                        baseGuests : false,
                        maxGuests : false,
                        timings : false,
                        message : "",
                        message1 : "",
                    })
                } else {
                    setFormData({
                        ...formData,
                        [type] : {
                            ...formData[type],
                            available : false,
                        }
                    })
                }
            }
        }
        return (
          <div className="d-flex">
            <div className="d-flex">
                <input
                    type="checkbox"
                    className="form-check-input"
                    name={type}
                    id={type}
                    checked={formData[type]?.available}
                    onChange={handleYes}
                />
                <label htmlFor={type} className="me-2">Yes</label>
            </div>
            <div className="d-flex">
                <input
                    type="checkbox"
                    className="form-check-input"
                    name={type+'no'}
                    id={type+'no'}
                    checked={formData[type]?.available === false}
                    onChange={handleNo}
                />
                <label htmlFor={type+'no'}>No</label>
            </div>
        </div>
        );
      }
     //accordion end
     const onChangeInput = (event,type) => {
        const {name,value} = event.target
        if (name === "minimumBooking") {
            setFormData({
                ...formData,
                [type] : {
                    ...formData[type],
                    [name] : value,
                    timings : false,
                }
            })
        } else {
            setFormData({
                ...formData,
                [type] : {
                    ...formData[type],
                    [name] : value,
                }
            })
        }
     }
     
     // to check timings validity start
     const checkTimings = (timings) => {
        for (const day in timings) {
            if (timings.hasOwnProperty(day)) {
                if ( (timings?.[day] !== null && timings?.[day] !== undefined  ) || (timings?.[day]?.timings !== undefined && timings?.[day]?.timings !== null)) {
                    return false; // If any fullDay or timings is not null, return false immediately
                }
            }
        }
        return true; // If all fullDays and timings are null, return true
    };
     // to check timings validity end

    const hasSleepingArrangement = (sleepingArrangements) => {
        for (let key in sleepingArrangements) {
            if (sleepingArrangements[key] > 0) {
                return true;
            }
        }
        return false;
    }

    const checkStayTimings = (timings) => {
        for (let key in timings) {
            if (timings[key]) {
                return true;
            }
        }
        return false;
    }

    const handleSubmit = () => {
        setSubmit(true);
        let error = false;
        if(!currency){
            setCurrencyError(true)
            document.getElementById("PricingCurrencyId").scrollIntoView({behavior: "smooth"});
            return null 
        }else{
            setCurrencyError(false)
        }
        setFilmError((prev)=>{
            return {
                hourlyPrice : false,
                attendees : false,
                minBooking : false,
                timings : false,
                message : "",
                hours : false,
                "24Price" : false,
                "12Price" : false,
                "perHourPrice" : false,
                "perHourMinHours" : false,
            }
        })
        setTvError((prev)=>{
            return {
                hourlyPrice : false,
                attendees : false,
                minBooking : false,
                timings : false,
                message : "",
                hours : false,
                "24Price" : false,
                "12Price" : false,
                "perHourPrice" : false,
                "perHourMinHours" : false,
            }
        })
        setEventError((prev)=>{
            return {
                hourlyPrice : false,
                weekendPrice : false,
                attendees : false,
                minBooking : false,
                timings : false,
                message : "",
                venuePrice : false,
                venueMinHrs : false,
                venueGuests : false,
                perHourPrice : false,
                perHourMinHrs : false,
                perHourGuests : false,
                perPlateVegPrice : false,
                perPlateNonVegPrice : false,
                perPlateMinGuests : "",
                perPlateMaxGuests : "",
                vegOrNonVeg : false,
            }
        })
        setStayError((prev)=>{
            return {
                sleepingArrangements : false,
                weekdayPrice : false,
                // hourlyPrice : false,
                weekendPrice : false,
                baseGuests : false,
                maxGuests : false,
                timings : false,
                message : "",
                message1 : "",
            }
        })
       
        //film error check start
        if(formData?.film?.available){
            // if(!formData?.film?.hourlyPrice){
            //     error = true
            //     setFilmError((prevValue) => {
            //         return {
            //             ...prevValue,
            //             hourlyPrice : true,
            //         }
            //     })
            // }
            if(!formData?.film?.attendees){
                error = true
                setFilmError((prevValue) => {
                    return {
                        ...prevValue,
                        attendees : true,
                        message : "Please Enter No. of attendees"
                    }
                })
            }
            // if((parseInt(formData?.film?.min) >= parseInt(formData?.film?.max)) ){
            //     error = true
            //     setFilmError((prevValue) => {
            //         return {
            //             ...prevValue,
            //             attendees : true,
            //             message : "Min.attendees should be less than Max.attendees"
            //         }
            //     })
            // }
            // if(!formData?.film?.minimumBooking){
            //     error = true
            //     setFilmError((prevValue) => {
            //         return {
            //             ...prevValue,
            //             minBooking : true,
            //         }
            //     })
            // }
            // if(!formData?.film?.timings){
            //     error = true
            //     setFilmError((prevValue) => {
            //         return {
            //             ...prevValue,
            //             timings : true,
            //         }
            //     })
            // }
            if (checkTimings(formData?.film?.timings)) {
                error = true
                setFilmError((prevValue) => {
                    return {
                        ...prevValue,
                        timings : true,
                    }
                })
            }
            if (!(formData?.film?.["24Hours"]?.available || formData?.film?.["12Hours"]?.available || formData?.film?.["perHour"]?.available) ) {
                error = true
                setFilmError((prevValue) => {
                    return {
                        ...prevValue,
                        hours : true
                    }
                } )
            }
            if (formData?.film?.["24Hours"]?.available && !formData?.film?.["24Hours"]?.price ) {
                error = true
                setFilmError((prevValue) => {
                    return {
                        ...prevValue,
                        "24Price" : true
                    }
                } )
            }
            if (formData?.film?.["12Hours"]?.available && !formData?.film?.["12Hours"]?.price ) {
                error = true
                setFilmError((prevValue) => {
                    return {
                        ...prevValue,
                        "12Price" : true
                    }
                } )
            }
            if (formData?.film?.["perHour"]?.available && !formData?.film?.["perHour"]?.price ) {
                error = true
                setFilmError((prevValue) => {
                    return {
                        ...prevValue,
                        "perHourPrice" : true
                    }
                } )
            }
            if (formData?.film?.["perHour"]?.available && !formData?.film?.["perHour"]?.minimumBookingHours ) {
                error = true
                setFilmError((prevValue) => {
                    return {
                        ...prevValue,
                        "perHourMinHours" : true
                    }
                } )
            }
        }
        //film error check end
        //tv error check start
        if(formData?.tv?.available){
            // if(!formData?.tv?.hourlyPrice){
            //     error = true
            //     setTvError((prevValue) => {
            //         return {
            //             ...prevValue,
            //             hourlyPrice : true,
            //         }
            //     })
            // }
            if(!formData?.tv?.attendees){
                error = true
                setTvError((prevValue) => {
                    return {
                        ...prevValue,
                        attendees : true,
                        message : "Please Enter no. of attendees"
                    }
                })
            }
            // if((parseInt(formData?.tv?.min) >= parseInt(formData?.tv?.max)) ){
            //     error = true
            //     setTvError((prevValue) => {
            //         return {
            //             ...prevValue,
            //             attendees : true,
            //             message : "Min.attendees should be less than Max.attendees"
            //         }
            //     })
            // }
            // if(!formData?.tv?.minimumBooking){
            //     error = true
            //     setTvError((prevValue) => {
            //         return {
            //             ...prevValue,
            //             minBooking : true,
            //         }
            //     })
            // }
            if (checkTimings(formData?.tv?.timings)) {
                error = true
                setTvError((prevValue) => {
                    return {
                        ...prevValue,
                        timings : true,
                    }
                })
            }
            // if(!formData?.tv?.timings){
            //     error = true
            //     setTvError((prevValue) => {
            //         return {
            //             ...prevValue,
            //             timings : true,
            //         }
            //     })
            // }
            if (!(formData?.tv?.["24Hours"]?.available || formData?.tv?.["12Hours"]?.available || formData?.tv?.["perHour"]?.available) ) {
                error = true
                setTvError((prevValue) => {
                    return {
                        ...prevValue,
                        hours : true
                    }
                } )
            }
            if (formData?.tv?.["24Hours"]?.available && !formData?.tv?.["24Hours"]?.price ) {
                error = true
                setTvError((prevValue) => {
                    return {
                        ...prevValue,
                        "24Price" : true
                    }
                } )
            }
            if (formData?.tv?.["12Hours"]?.available && !formData?.tv?.["12Hours"]?.price ) {
                error = true
                setTvError((prevValue) => {
                    return {
                        ...prevValue,
                        "12Price" : true
                    }
                } )
            }
            if (formData?.tv?.["perHour"]?.available && !formData?.tv?.["perHour"]?.price ) {
                error = true
                setTvError((prevValue) => {
                    return {
                        ...prevValue,
                        "perHourPrice" : true
                    }
                } )
            }
            if (formData?.tv?.["perHour"]?.available && !formData?.tv?.["perHour"]?.minimumBookingHours ) {
                error = true
                setTvError((prevValue) => {
                    return {
                        ...prevValue,
                        "perHourMinHours" : true
                    }
                } )
            }
        } 
        //tv error check end
        //evnt error check start
        if(formData?.event?.available){
            // if(!formData?.event?.hourlyPrice){
            //     error = true
            //     setEventError((prevValue) => {
            //         return {
            //             ...prevValue,
            //             hourlyPrice : true,
            //         }
            //     })
            // }
            // if(!formData?.event?.weekendPrice){
            //     error = true
            //     setEventError((prevValue) => {
            //         return {
            //             ...prevValue,
            //             weekendPrice : true,
            //         }
            //     })
            // }
            // if(!formData?.event?.attendees ){
            //     error = true
            //     setEventError((prevValue) => {
            //         return {
            //             ...prevValue,
            //             attendees : true,
            //             message : "Please Enter no. of attendees"
            //         }
            //     })
            // }
            // if((parseInt(formData?.event?.min) >= parseInt(formData?.event?.max)) ){
            //     error = true
            //     setEventError((prevValue) => {
            //         return {
            //             ...prevValue,
            //             attendees : true,
            //             message : "Min.attendees should be less than Max.attendees"
            //         }
            //     })
            // }
            // if(!formData?.event?.minimumBooking){
            //     error = true
            //     setEventError((prevValue) => {
            //         return {
            //             ...prevValue,
            //             minBooking : true,
            //         }
            //     })
            // }
            // if(!formData?.event?.timings){
            //     error = true
            //     setEventError((prevValue) => {
            //         return {
            //             ...prevValue,
            //             timings : true,
            //         }
            //     })
            // }
            if (checkTimings(formData?.event?.timings)) {
                error = true
                setEventError((prevValue) => {
                    return {
                        ...prevValue,
                        timings : true,
                    }
                })
            }

            if (!(formData?.event?.["venue"]?.available || formData?.event?.["perPlate"]?.available || formData?.event?.["perHour"]?.available) ) {
                error = true
                setEventError((prevValue) => {
                    return {
                        ...prevValue,
                        hours : true
                    }
                } )
            }

            // venue sec start
            if (formData?.event?.["venue"]?.available) {

                if ( !formData?.event?.["venue"]?.price ) {
                    error = true;
                    setEventError((prevValue) => {
                        return {
                            ...prevValue,
                            venuePrice : true
                        }
                    } )
                }

                if ( !formData?.event?.["venue"]?.minimumBookingHours ) {
                    error = true;
                    setEventError((prevValue) => {
                        return {
                            ...prevValue,
                            venueMinHrs : true
                        }
                    } )
                }
    
                if ( !formData?.event?.["venue"]?.maxAttendees ) {
                    error = true;
                    setEventError((prevValue) => {
                        return {
                            ...prevValue,
                            venueGuests : true
                        }
                    } )
                }

            } 
            // venue sec end 

            // per plate start
            if (formData?.event?.["perPlate"]?.available) {

                if ( !formData?.["event"]?.["nonVeg"] && !formData?.["event"]?.["pureVeg"] ) {
                    error = true;
                    setEventError((prevValue) => {
                        return {
                            ...prevValue,
                            vegOrNonVeg : true
                        }
                    } )
                }          

                if ( formData?.["event"]?.["nonVeg"] || formData?.["event"]?.["pureVeg"] )  {

                    if ( !formData?.event?.["perPlate"]?.vegPrice ) {
                        error = true;
                        setEventError((prevValue) => {
                            return {
                                ...prevValue,
                                perPlateVegPrice : true
                            }
                        } )
                    }
        
                    if ( formData?.["event"]?.["nonVeg"] === true) {
                        if ( !formData?.event?.["perPlate"]?.nonVegPrice ) {
                            error = true;
                            setEventError((prevValue) => {
                                return {
                                    ...prevValue,
                                    perPlateNonVegPrice : true
                                }
                            } )
                        }
                    }
        
                    if ( !formData?.event?.["perPlate"]?.minAttendees ) {
                        error = true;
                        setEventError((prevValue) => {
                            return {
                                ...prevValue,
                                perPlateMinGuests : "Please enter min guests"
                            }
                        } )
                    }
                    
                    if ( !formData?.event?.["perPlate"]?.maxAttendees ) {
                        error = true;
                        setEventError((prevValue) => {
                            return {
                                ...prevValue,
                                perPlateMaxGuests : "Please enter max guests"
                            }
                        } )
                    }
                    
                    if ( formData?.event?.["perPlate"]?.maxAttendees  && formData?.event?.["perPlate"]?.minAttendees  ) {
    
                        if(parseInt(formData?.event?.["perPlate"]?.maxAttendees) < parseInt(formData?.event?.["perPlate"]?.minAttendees) ) {
                            error = true;
                            setEventError((prevValue) => {
                                return {
                                    ...prevValue,
                                    perPlateMaxGuests : "Max guests should be greater than min guests",
                                    perPlateMinGuests : "Min guests should be less than max guests"
                                }
                            } )
                        } 
    
                    } 
                }


            } 
            // per plate end 

            // per hour start
            if (formData?.event?.["perHour"]?.available) {

                if ( !formData?.event?.["perHour"]?.price ) {
                    error = true;
                    setEventError((prevValue) => {
                        return {
                            ...prevValue,
                            perHourPrice : true
                        }
                    } )
                }
    
                if ( !formData?.event?.["perHour"]?.minimumBookingHours ) {
                    error = true;
                    setEventError((prevValue) => {
                        return {
                            ...prevValue,
                            perHourMinHrs : true
                        }
                    } )
                }
    
                if ( !formData?.event?.["perHour"]?.maxAttendees ) {
                    error = true;
                    setEventError((prevValue) => {
                        return {
                            ...prevValue,
                            perHourGuests : true
                        }
                    } )
                }

            } 
            // per hour end 

        }
        //event error check end
        //stay error check start
        if(formData?.stay?.available){
            if (!hasSleepingArrangement(formData?.stay?.sleepingArrangements)) {
                error = true
                setStayError((prevValue) => {
                    return {
                        ...prevValue,
                        sleepingArrangements : true,
                    }
                })
            }
            if(!formData?.stay?.weekdayPrice){
                error = true
                setStayError((prevValue) => {
                    return {
                        ...prevValue,
                        weekdayPrice : true,
                    }
                })
            }
            if(!formData?.stay?.weekendPrice){
                error = true
                setStayError((prevValue) => {
                    return {
                        ...prevValue,
                        weekendPrice : true,
                    }
                })
            }
            if((!formData?.stay?.baseGuests)){ 
                error = true
                setStayError((prevValue) => {
                    return {
                        ...prevValue,
                        baseGuests : true,
                        message : "Enter Base Guests", 
                    }
                })
            }
            if((formData?.stay?.baseGuests) > formData?.stay?.maxGuests){ 
                error = true
                setStayError((prevValue) => {
                    return {
                        ...prevValue,
                        baseGuests : true,
                        message : "Base guests should be less than max guests"
                    }
                })
            }
            if((!formData?.stay?.maxGuests))  {
                error = true
                setStayError((prevValue) => {
                    return {
                        ...prevValue,
                        maxGuests : true,
                        message1 : "Enter Maximum guests"
                    }
                })
            }
            if((formData?.stay?.baseGuests) > formData?.stay?.maxGuests)  {
                error = true
                setStayError((prevValue) => {
                    return {
                        ...prevValue,
                        maxGuests : true,
                        message1 : "Max guests should be more than base guests"
                    }
                })
            }
            if (!checkStayTimings(formData?.stay?.timings)) {
                error = true
                setStayError((prevValue) => {
                    return {
                        ...prevValue,
                        timings : true,
                    }
                })
            }
            // if(!formData?.stay?.timings){
            //     error = true
            //     setStayError((prevValue) => {
            //         return {
            //             ...prevValue,
            //             timings : true,
            //         }
            //     })
            // }
        }
        //stay error check end
    }
     useEffect(() => {
        const callBack = () => {
            if ( !formData?.film?.available && !formData?.tv?.available && !formData?.event?.available && !formData?.stay?.available ) {
                setConfirmationBody('Please select any one of the type')
                setShowConfirmationModel(true)
                return null
            }
            let error = false
            for(const err in filmError) {
                if (filmError[err]) {
                    document.getElementById("PricingFilmId").scrollIntoView({behavior: "smooth"});
                    error = true
                    setSubmit(false)
                    return null
                } 
            } 
            for(const err in tvError) {
                if (tvError[err]) {
                    document.getElementById("PricingTvId").scrollIntoView({behavior: "smooth"});
                    error = true
                    setSubmit(false)
                    return null
                } 
            } 
            for(const err in eventError) {
                if (eventError[err]) {
                    document.getElementById("PricingEventId").scrollIntoView({behavior: "smooth"});
                    error = true
                    setSubmit(false)
                    return null
                } 
            } 
            for(const err in stayError) {
                if (stayError[err]) {
                    // document.getElementById("PricingStayId").scrollIntoView({behavior: "smooth"});
                    document.getElementById(`stay-${err}`).scrollIntoView({behavior: "smooth"});
                    error = true
                    setSubmit(false)
                    return null
                } 
            }
            if(!error && init && submit){
                // for activities control
                let filteredTags = []
                if(activitiesData?.length > 0 ){
                    activitiesData?.map((item) => {
                        if(formData?.film?.available || formData?.tv?.available){
                            if(item?.type === 'film'){
                                filteredTags.push(item)
                            }
                        }
                        if(formData?.event?.available){
                            if(item?.type === 'corporateEvent' || item?.type === 'individualEvent'){
                                filteredTags.push(item)
                            }
                        }
                        if(formData?.stay?.available){
                            if(item?.type === 'stay'){
                                filteredTags.push(item)
                            }
                        }
                    })
                }
                // for policies control
                let filteredPolicies
                if (policiesData) {
                    filteredPolicies = {
                        term_cond : policiesData?.term_cond || false,
                        privacy_policy : policiesData?.privacy_policy || false,
                        grant_info : policiesData?.grant_info || false,
                    };
                    if(formData?.film?.available || formData?.tv?.available){
                        filteredPolicies.film = policiesData?.film || INITIAL_DATA?.film
                    }
                    if(formData?.event?.available){
                        filteredPolicies.event = policiesData?.event || INITIAL_DATA?.event
                    }
                    if(formData?.stay?.available){
                        filteredPolicies.stay = policiesData?.stay || INITIAL_DATA?.stay
                    }
                }
                formData.currency = currency
                handleNext({pricing : formData,tags : filteredTags, policies : filteredPolicies})
            }
            setSubmit(false)
        }
        if(!init) setInit(true)
        if(init) callBack()
     },[filmError,tvError,eventError,stayError])
     useEffect(() => {
        if(data){
            setFormData(data)
            setCurrency(data?.currency)
        }
    },[data])
    const validateTimings = (open, close, type, day) => {
        const openValue = parseFloat(open.substring(0,2))
        const closeValue = parseFloat(close.substring(0,2))
        const minBookingHoursVenue = formData[type]?.["venue"]?.minimumBookingHours
        const minBookingHoursperHour = formData[type]?.["perHour"]?.minimumBookingHours
        let minBookingHours = "1";
        if (!minBookingHoursVenue && !minBookingHoursperHour ) {
            minBookingHours = "1"
        } else if (!minBookingHoursperHour) {
            minBookingHours = minBookingHoursVenue
        } else if (!minBookingHoursVenue) {
            minBookingHours = minBookingHoursperHour
        } 
       if (minBookingHours) {
            if ((openValue < closeValue) && ((closeValue - openValue) >= minBookingHours)) {
                setFormData({
                    ...formData,
                    [type] : {
                        ...formData[type],
                        timings : {
                            ...formData[type]?.timings,
                            [day] : {
                                ...formData[type]?.timings?.[day],
                                timings :{
                                    open : open,
                                    close : close,
                                }
                            },
                        }
                        }
                }) 
            } else if (openValue >= closeValue) {
                setConfirmationBody(`End time cannot be before start time. Please adjust your selection`)
                setShowConfirmationModel(true)
            }
            else if((closeValue - openValue) < minBookingHours) {
                setConfirmationBody(`Selected timings should be greater than minimum booking hours. Please adjust your selection`)
                setShowConfirmationModel(true)
            }
            else {
                setConfirmationBody(`Timings error`)
                setShowConfirmationModel(true)
            }
        } else{
            setConfirmationBody("Please select minimum booking hrs first")
            setShowConfirmationModel(true)
        }
    }
    const validateFilmTvTimings = (open, close, type, day) => {
        const openValue = parseFloat(open.substring(0,2))
        const closeValue = parseFloat(close.substring(0,2))
        const minBookingHours = parseInt(formData[type]?.["perHour"]?.minimumBookingHours)
       if (minBookingHours) {
            if ((openValue < closeValue) && ((closeValue - openValue) >= minBookingHours)) {
                setFormData({
                    ...formData,
                    [type] : {
                        ...formData[type],
                        timings : {
                            ...formData[type]?.timings,
                            [day] : {
                                ...formData[type]?.timings?.[day],
                                timings :{
                                    open : open,
                                    close : close,
                                }
                            },
                        }
                        }
                }) 
            } else if (openValue >= closeValue) {
                setConfirmationBody(`End time cannot be before start time. Please adjust your selection`)
                setShowConfirmationModel(true)
            }
            else if((closeValue - openValue) < minBookingHours) {
                setConfirmationBody(`Selected timings should be greater than minimum booking hours. Please adjust your selection`)
                setShowConfirmationModel(true)
            }
            else {
                setConfirmationBody(`Timings error`)
                setShowConfirmationModel(true)
            }
        } else{
            if (formData?.[type]?.["perHour"]?.available && !formData?.[type]?.["perHour"]?.minimumBookingHours) {
                setConfirmationBody("Please select minimim booking hrs first")
                setShowConfirmationModel(true)
            } else {
                setConfirmationBody("Please select timing options first")
                setShowConfirmationModel(true)
            }
        }
    }
    const validateStayTimings = (open, close, type, day) =>{
        // const openValue = open.slice(-2) === "AM" ? parseFloat(open.substring(0,2)) : parseFloat(open.substring(0,2)) + 12 ;
        // const closeValue = close.slice(-2) === "AM" ?   parseFloat(close.substring(0,2)) :  parseFloat(close.substring(0,2)) + 12 ;
        // console.log("openValue", openValue)
        // console.log("closeValue", closeValue)
        // if (openValue < closeValue) {
            setFormData({
                ...formData,
                [type] : {
                    ...formData[type],
                    timings : {
                        ...formData[type]?.timings,
                        [day] : {
                            ...formData[type]?.timings?.[day],
                            timings :{
                                open : open,
                                close : close,
                            }
                        },
                    }
                    }
            })
        // } else{
        //     alert(`Invalid timings`)
        // }
    }
    // const onClickDelete = (index) => {
    //     if (formData?.event?.buffets?.length === 1) {
    //         setFormData({
    //             ...formData,
    //             event : {
    //                 ...formData.event,
    //                 catering: false,
    //                 buffets : [],
    //             }
    //         })
    //     } 
    //     else {
    //         const newData = formData?.event?.buffets
    //         newData.splice(index, 1);
    //         setFormData({
    //             ...formData,
    //                 event : {
    //                     ...formData.event,
    //                     buffets : [...newData]
    //                 }
    //         })
    //     }
    // }

    // timings cards start
const onChange24HrsCheckBox = (e, type, day) => {
        if(e?.target?.checked) {
            setFormData({
                ...formData,
                [type] : {
                    ...formData?.[type],
                    timings : {
                        ...formData?.[type]?.timings,
                        [day] : {
                            ...formData?.[type]?.timings?.[day],
                            fullDay : true
                        }
                    }
                }
            })
        } else {
            setFormData({
                ...formData,
                [type] : {
                    ...formData?.[type],
                    timings : {
                        ...formData?.[type]?.timings,
                        [day] : {
                            ...formData?.[type]?.timings?.[day],
                            fullDay : null
                        }
                    }
                }
            })
        }
}
const onChangeSetHrsCheckBox = (e, type, day) => {
    let reqTimings = {}
    if(type === "stay"){
        reqTimings = { open: "12:00 PM", close: "11:00 AM" }
        if(e?.target?.checked) {
            setFormData({
                ...formData,
                [type] : {
                    ...formData?.[type],
                    timings : {
                        ...formData?.[type]?.timings,
                        [day] : {
                            fullDay : e.target.checked,
                            timings: reqTimings
                        }
                    }
                }
            })
        } else {
            setFormData({
                ...formData,
                [type] : {
                    ...formData?.[type],
                    timings : {
                        ...formData?.[type]?.timings,
                        [day] : null
                    }
                }
            })
        }
    } else {
        reqTimings = { open: "00:00", close: "23:59" }
        if(e?.target?.checked) {
            setFormData({
                ...formData,
                [type] : {
                    ...formData?.[type],
                    timings : {
                        ...formData?.[type]?.timings,
                        [day] : {
                            ...formData?.[type]?.timings?.[day],
                            fullDay : false,
                            timings: reqTimings
                        }
                    }
                }
            })
        } else {
            setFormData({
                ...formData,
                [type] : {
                    ...formData?.[type],
                    timings : {
                        ...formData?.[type]?.timings,
                        [day] : {
                            ...formData?.[type]?.timings?.[day],
                            fullDay : null,
                            timings: null
                        }
                    }
                }
            })
        }
    }
}
const selectAllTimings = (e,type) => {
    const {checked} = e.target;
    if (checked) {
        if (type === "stay" ) {
            // const reqTimings = { open: "12:00 PM", close: "11:00 AM" }
            setFormData({
                ...formData,
                [type] : {
                    ...formData[type],
                    // timings : {
                    //     "monday": {
                    //         "fullDay": true,
                    //         timings : reqTimings,
                    //       },
                    //     "tuesday": {
                    //         "fullDay": true,
                    //         timings : reqTimings,
                    //       },
                    //     "wednesday": {
                    //         "fullDay": true,
                    //         timings : reqTimings,
                    //       },
                    //     "thursday": {
                    //         "fullDay": true,
                    //         timings : reqTimings,
                    //       },
                    //     "friday": {
                    //         "fullDay": true,
                    //         timings : reqTimings,
                    //       },
                    //     "saturday": {
                    //         "fullDay": true,
                    //         timings : reqTimings,
                    //       },
                    //     "sunday": {
                    //         "fullDay": true,
                    //         timings : reqTimings,
                    //       },
                    // },
                    timings : {
                        monday : true,
                        tuesday : true,
                        wednesday : true,
                        thursday : true,
                        friday : true,
                        saturday : true,
                        sunday : true,
                    }
                }
            })
        } else {
            setFormData({
                ...formData,
                [type] : {
                    ...formData[type],
                    timings : {
                        "monday": {
                            "fullDay": true
                          },
                        "tuesday": {
                            "fullDay": true
                          },
                        "wednesday": {
                            "fullDay": true
                          },
                        "thursday": {
                            "fullDay": true
                          },
                        "friday": {
                            "fullDay": true
                          },
                        "saturday": {
                            "fullDay": true
                          },
                        "sunday": {
                            "fullDay": true
                          },
                    },
                }
            })
        }
    } else {
        if (type === "stay") {
            setFormData({
                ...formData,
                [type] : {
                    ...formData[type],
                    timings : {
                        monday : false,
                        tuesday : false,
                        wednesday : false,
                        thursday : false,
                        friday : false,
                        saturday : false,
                        sunday : false,
                    }
                }
            })
        } else {
            setFormData({
                ...formData,
                [type] : {
                    ...formData[type],
                    timings : {}
                }
            })
        }
    }
}
const getAllCheckedStatus = (type) => { 
    if ( 
        (formData?.[type]?.timings?.monday?.fullDay) && 
        (formData?.[type]?.timings?.tuesday?.fullDay) &&
        (formData?.[type]?.timings?.wednesday?.fullDay) &&
        (formData?.[type]?.timings?.thursday?.fullDay) &&
        (formData?.[type]?.timings?.friday?.fullDay) &&
        (formData?.[type]?.timings?.saturday?.fullDay) &&
        (formData?.[type]?.timings?.sunday?.fullDay) 
    ) {
        return true
    } else {
        return false
    }
}
const onChangeTimingsCheckbox = (event,type) => {
    const {name, checked} = event.target
    if (checked) {
        setFormData({
            ...formData,
            [type] : {
                ...formData[type],
                [name] : {
                    available : true
                },
                timings : false,
            }
        })
    } else {
        setFormData({
            ...formData,
            [type] : {
                ...formData[type],
                [name] : {},
                timings : false,
            }
        })
    }
}

const onChangeTimingsPrice = (event, type, tag) => {
    const {name,value} = event.target
    setFormData({
        ...formData,
        [type] : {
            ...formData[type],
            [tag] : {
                ...formData[type][tag],
                [name] : value
            },
            // timings : false,
        }
    })
}

const onChangeTimingsBookingHours = (event, type, tag) => {
    const {name,value} = event.target
    setFormData({
        ...formData,
        [type] : {
            ...formData[type],
            [tag] : {
                ...formData[type][tag],
                [name] : value
            },
            timings : false,
        }
    })
}

// Film Start 
const filmPricing24HrsFormat = () => {
    return(
        <div className="listing-pricing-card col-lg-4" >
            <div className="">
                <input 
                    // required = {formData?.film?.minimumBooking ? false : true} 
                    checked = {formData?.film?.["24Hours"]?.available} 
                    value = {24} 
                    name="24Hours" 
                    id = "film-24-hours" 
                    onChange={(e) => {
                        onChangeTimingsCheckbox(e,'film')
                        if (filmError?.hours) {
                            setFilmError({
                                ...filmError,
                                hours : false,
                            });
                        }
                    }} 
                    type="checkbox" 
                    className="form-check-input"
                />
                <label htmlFor="film-24-hours" className="SpotLetContent" > Full day </label>
            </div>
            {
                formData?.film?.["24Hours"]?.available && (
                <div className="">
                    <div className="mb-3 d-flex flex-column">
                        <label htmlFor="film-24hour-price" className="form-label SpotLetContent">
                            Pricing starts from<span className="required">*</span>
                        </label>
                        <input
                            // type="text"
                            required
                            // min={0}
                            placeholder="Enter price"
                            id="film-24hour-price"
                            name="price"
                            className="textFiled"
                            value={formData?.film?.["24Hours"]?.price || "" }
                            onChange={(e) => {
                            const regex= /^[1-9][0-9]*$/;
                            if (e.target.value === "" || regex.test(e.target.value)) {
                                onChangeTimingsPrice(e,'film', "24Hours");
                                    setFilmError({
                                        ...filmError,
                                        "24Price" : false,
                                    })
                            }
                            }}
                        />
                        {filmError?.["24Price"] && <span style={{color : 'red'}} className="SpotLetContent">Enter the start price</span>}
                        {/* {filmError.hourlyPrice &&<span style={{color : 'red'}}>Enter price</span>} */}
                    </div>
                </div>
                )
            }
        </div>
    )
}

const filmPricing12HrsFormat = () => {
    return(
        <div className="listing-pricing-card col-lg-4" >
            <div className="">
                <input 
                    // required = {formData?.film?.minimumBooking ? false : true} 
                    checked = {formData?.film?.["12Hours"]?.available} 
                    value = {12} 
                    name="12Hours" 
                    id = "film-12-hours" 
                    onChange={(e) => {
                        onChangeTimingsCheckbox(e,'film')
                        if (filmError?.hours) {
                            setFilmError({
                                ...filmError,
                                hours : false,
                            })
                        }
                    }} 
                    type="checkbox" 
                    className="form-check-input"
                />
                <label htmlFor="film-12-hours" className="SpotLetContent"  > Half day (12 Hrs) </label>
            </div>
            {
                formData?.film?.["12Hours"]?.available && (
                <div className="">
                    <div className="mb-3 d-flex flex-column">
                        <label htmlFor="film-12hour-price" className="form-label SpotLetContent">
                            Pricing starts from<span className="required">*</span>
                        </label>
                        <input
                            // type="text"
                            required
                            // min={0}
                            placeholder="Enter price"
                            id="film-12hour-price"
                            name="price"
                            className="textFiled"
                            value={formData?.film?.["12Hours"]?.price || "" }
                            onChange={(e) => {
                            const regex= /^[1-9][0-9]*$/;
                            if (e.target.value === "" || regex.test(e.target.value)) {
                                onChangeTimingsPrice(e,'film', "12Hours");
                                    setFilmError({
                                        ...filmError,
                                        "12Price" : false,
                                    })
                            }
                            }}
                        />
                        {filmError?.["12Price"] && <span style={{color : 'red'}} className="SpotLetContent">Enter the start price</span>}
                        {/* {filmError.hourlyPrice &&<span style={{color : 'red'}}>Enter price</span>} */}
                    </div>
                </div>
                )
            }
        </div>
    )
}

const filmPricingPerHrsFormat = () => {
    return(
        <div className="listing-pricing-card col-lg-4" >
            <div className="">
                <input 
                    // required = {formData?.film?.minimumBooking ? false : true} 
                    checked = {formData?.film?.["perHour"]?.available } 
                    value = {1} 
                    name="perHour" 
                    id = "film-per-hour" 
                    onChange={(e) => {
                        onChangeTimingsCheckbox(e,'film');
                        if (filmError?.hours) {
                            setFilmError({
                                ...filmError,
                                hours : false,
                            })
                        }
                    }}  
                    type="checkbox" 
                    className="form-check-input"
                />
                <label htmlFor="film-per-hour" className="SpotLetContent" > Per Hour </label>
            </div>
            {
                formData?.film?.["perHour"]?.available && (
                <div className="">
                    <div className="mb-3 d-flex flex-column">
                        <label htmlFor="film-hour-price" className="form-label SpotLetContent">
                            Pricing starts from<span className="required">*</span>
                        </label>
                        <input
                            // type="text"
                            required
                            // min={0}
                            placeholder="Enter price"
                            id="film-hour-price"
                            name="price"
                            className="textFiled"
                            value={formData?.film?.["perHour"]?.price || "" }
                            onChange={(e) => {
                            const regex= /^[1-9][0-9]*$/;
                            if (e.target.value === "" || regex.test(e.target.value)) {
                                onChangeTimingsPrice(e,'film', "perHour");
                                    setFilmError({
                                        ...filmError,
                                        "perHourPrice" : false,
                                    })
                            }
                            }}
                        />
                        {filmError?.["perHourPrice"] && <span className="SpotLetContent" style={{color : 'red'}}>Enter the start price</span>}
                        {/* {filmError.hourlyPrice &&<span style={{color : 'red'}}>Enter price</span>} */}
                    </div>
                    {/* <div className="col-lg-6"> */}
                        <div className="mb-3 d-flex flex-column">
                            <label htmlFor="film-minimumBooking" className="form-label SpotLetContent">
                            Minimum booking Hrs<span className="required">*</span>
                            </label>
                            <select
                                required
                                id="film-minimumBooking"
                                name="minimumBookingHours"
                                className="textFiledSelect SpotLetContent"
                                value={formData?.film?.["perHour"]?.minimumBookingHours}
                                onChange={(e) => { 
                                    onChangeTimingsBookingHours(e,'film', "perHour");
                                    setFilmError({
                                        ...filmError,
                                        perHourMinHours : false,
                                    })
                                }}
                            >
                                <option hidden selected value="">Select hours</option>
                                <option value='1'>1 Hr</option>
                                <option value='2'>2 Hrs</option>
                                <option value='3'>3 Hrs</option>
                                <option value='4'>4 Hrs</option>
                                <option value='5'>5 Hrs</option>
                                <option value='6'>6 Hrs</option>
                                <option value='7'>7 Hrs</option>
                                <option value='8'>8 Hrs</option>
                                <option value='9'>9 Hrs</option>
                                <option value='10'>10 Hrs</option>
                                <option value='11'>11 Hrs</option>
                                <option value='12'>12 Hrs</option>
                                <option value='13'>13 Hrs</option>
                                <option value='14'>14 Hrs</option>
                                <option value='15'>15 Hrs</option>
                                <option value='16'>16 Hrs</option>
                                <option value='17'>17 Hrs</option>
                                <option value='18'>18 Hrs</option>
                                <option value='19'>19 Hrs</option>
                                <option value='20'>20 Hrs</option>
                                <option value='21'>21 Hrs</option>
                                <option value='22'>22 Hrs</option>
                                <option value='23'>23 Hrs</option>
                                <option value='24'>24 Hrs</option>
                            </select>
                            {filmError?.["perHourMinHours"] && <span style={{color : 'red'}} className="SpotLetContent">Select the minimum booking hours</span>}
                            {/* {filmError.minBooking &&<span style={{color : 'red'}}>Select Min booking Hrs</span>} */}
                        </div>
                    {/* </div> */}
                </div>
                )
            }
        </div>
    )
}
// Film End 

// Tv Start 
const tvPricing24HrsFormat = () => {
    return(
        <div className="listing-pricing-card col-lg-4" >
            <div className="">
                <input 
                    // required = {formData?.tv?.minimumBooking ? false : true} 
                    checked = {formData?.tv?.["24Hours"]?.available} 
                    value = {24} 
                    name="24Hours" 
                    id = "tv-24-hours" 
                    onChange={(e) => {
                        onChangeTimingsCheckbox(e,'tv');
                        if (tvError?.hours) {
                            setTvError({
                                ...tvError,
                                hours : false,
                            });
                        }
                    }} 
                    type="checkbox" 
                    className="form-check-input"
                />
                <label htmlFor="tv-24-hours" className="SpotLetContent" > Full day </label>
            </div>
            {
                formData?.tv?.["24Hours"]?.available && (
                <div className="">
                    <div className="mb-3 d-flex flex-column">
                        <label htmlFor="tv-24hour-price" className="form-label SpotLetContent">
                            Pricing starts from<span className="required">*</span>
                        </label>
                        <input
                            // type="text"
                            required
                            // min={0}
                            placeholder="Enter price"
                            id="tv-24hour-price"
                            name="price"
                            className="textFiled"
                            value={formData?.tv?.["24Hours"]?.price || "" }
                            onChange={(e) => {
                            const regex= /^[1-9][0-9]*$/;
                            if (e.target.value === "" || regex.test(e.target.value)) {
                                onChangeTimingsPrice(e,'tv', "24Hours");
                                    setTvError({
                                        ...tvError,
                                        "24Price" : false,
                                    })
                            }
                            }}
                        />
                        {tvError?.["24Price"] && <span style={{color : 'red'}} className="SpotLetContent">Enter the start price</span>}
                        {/* {filmError.hourlyPrice &&<span style={{color : 'red'}}>Enter price</span>} */}
                    </div>
                </div>
                )
            }
        </div>
    )
}

const tvPricing12HrsFormat = () => {
    return(
        <div className="listing-pricing-card col-lg-4" >
            <div className="">
                <input 
                    // required = {formData?.tv?.minimumBooking ? false : true} 
                    checked = {formData?.tv?.["12Hours"]?.available} 
                    value = {12} 
                    name="12Hours" 
                    id = "tv-12-hours" 
                    onChange={(e) => {
                        onChangeTimingsCheckbox(e,'tv');
                        if (tvError?.hours) {
                            setTvError({
                                ...tvError,
                                hours : false,
                            })
                        }
                    }} 
                    type="checkbox" 
                    className="form-check-input"
                />
                <label htmlFor="tv-12-hours" className="SpotLetContent" > Half day (12 Hrs) </label>
            </div>
            {
                formData?.tv?.["12Hours"]?.available && (
                <div className="">
                    <div className="mb-3 d-flex flex-column">
                        <label htmlFor="tv-12hour-price" className="form-label SpotLetContent">
                            Pricing starts from<span className="required">*</span>
                        </label>
                        <input
                            // type="text"
                            required
                            // min={0}
                            placeholder="Enter price"
                            id="tv-12hour-price"
                            name="price"
                            className="textFiled"
                            value={formData?.tv?.["12Hours"]?.price || "" }
                            onChange={(e) => {
                            const regex= /^[1-9][0-9]*$/;
                            if (e.target.value === "" || regex.test(e.target.value)) {
                                onChangeTimingsPrice(e,'tv', "12Hours");
                                    setTvError({
                                        ...tvError,
                                        "12Price" : false,
                                    })
                            }
                            }}
                        />
                        {tvError?.["12Price"] && <span style={{color : 'red'}} className="SpotLetContent" >Enter the start price</span>}
                        {/* {filmError.hourlyPrice &&<span style={{color : 'red'}}>Enter price</span>} */}
                    </div>
                </div>
                )
            }
        </div>
    )
}

const tvPricingPerHrsFormat = () => {
    return(
        <div className="listing-pricing-card col-lg-4" >
            <div className="">
                <input 
                    // required = {formData?.tv?.minimumBooking ? false : true} 
                    checked = {formData?.tv?.["perHour"]?.available } 
                    value = {1} 
                    name="perHour" 
                    id = "tv-per-hour" 
                    onChange={(e) => {
                        onChangeTimingsCheckbox(e,'tv');
                        if (tvError?.hours) {
                            setTvError({
                                ...tvError,
                                hours : false,
                            })
                        }
                    }} 
                    type="checkbox" 
                    className="form-check-input"
                />
                <label htmlFor="tv-per-hour" className="SpotLetContent" > Per Hour </label>
            </div>
            {
                formData?.tv?.["perHour"]?.available && (
                <div className="">
                    <div className="mb-3 d-flex flex-column">
                        <label htmlFor="tv-hour-price" className="form-label SpotLetContent">
                            Pricing starts from<span className="required">*</span>
                        </label>
                        <input
                            // type="text"
                            required
                            // min={0}
                            placeholder="Enter price"
                            id="tv-hour-price"
                            name="price"
                            className="textFiled"
                            value={formData?.tv?.["perHour"]?.price || "" }
                            onChange={(e) => {
                            const regex= /^[1-9][0-9]*$/;
                            if (e.target.value === "" || regex.test(e.target.value)) {
                                onChangeTimingsPrice(e,'tv', "perHour");
                                    setTvError({
                                        ...tvError,
                                        "perHourPrice" : false,
                                    })
                            }
                            }}
                        />
                        {tvError?.["perHourPrice"] && <span style={{color : 'red'}} className="SpotLetContent">Enter the start price</span>}
                        {/* {filmError.hourlyPrice &&<span style={{color : 'red'}}>Enter price</span>} */}
                    </div>
                    {/* <div className="col-lg-6"> */}
                        <div className="mb-3 d-flex flex-column">
                            <label htmlFor="tv-minimumBooking" className="form-label SpotLetContent">
                            Minimum booking Hrs<span className="required">*</span>
                            </label>
                            <select
                                required
                                id="tv-minimumBooking"
                                name="minimumBookingHours"
                                className="textFiledSelect SpotLetContent"
                                value={formData?.tv?.["perHour"]?.minimumBookingHours}
                                onChange={(e) => { 
                                    onChangeTimingsBookingHours(e,'tv', "perHour");
                                    setTvError({
                                        ...tvError,
                                        perHourMinHours : false,
                                    })
                                }}
                            >
                                <option hidden selected value="" >Select hours</option>
                                <option value='1'>1 Hr</option>
                                <option value='2'>2 Hrs</option>
                                <option value='3'>3 Hrs</option>
                                <option value='4'>4 Hrs</option>
                                <option value='5'>5 Hrs</option>
                                <option value='6'>6 Hrs</option>
                                <option value='7'>7 Hrs</option>
                                <option value='8'>8 Hrs</option>
                                <option value='9'>9 Hrs</option>
                                <option value='10'>10 Hrs</option>
                                <option value='11'>11 Hrs</option>
                                <option value='12'>12 Hrs</option>
                                <option value='13'>13 Hrs</option>
                                <option value='14'>14 Hrs</option>
                                <option value='15'>15 Hrs</option>
                                <option value='16'>16 Hrs</option>
                                <option value='17'>17 Hrs</option>
                                <option value='18'>18 Hrs</option>
                                <option value='19'>19 Hrs</option>
                                <option value='20'>20 Hrs</option>
                                <option value='21'>21 Hrs</option>
                                <option value='22'>22 Hrs</option>
                                <option value='23'>23 Hrs</option>
                                <option value='24'>24 Hrs</option>
                            </select>
                            {tvError?.["perHourMinHours"] && <span style={{color : 'red'}} className="SpotLetContent" >Select the minimum booking hours</span>}
                            {/* {filmError.minBooking &&<span style={{color : 'red'}}>Select Min booking Hrs</span>} */}
                        </div>
                    {/* </div> */}
                </div>
                )
            }
        </div>
    )
}
// Tv End 

 // Event Start 
 const eventVenuePricingFormat = () => {
    return(
        <div className="listing-pricing-card col-lg-4" >
            <div className="">
                <input 
                    // required = {formData?.film?.minimumBooking ? false : true} 
                    checked = {formData?.event?.["venue"]?.available} 
                    value = {"venue"} 
                    name="venue" 
                    id = "event-venue" 
                    onChange={(e) => {
                        onChangeTimingsCheckbox(e,'event');
                        if (eventError?.hours) {
                            setEventError({
                                ...eventError,
                                hours : false,
                            });
                        }
                    }} 
                    type="checkbox" 
                    className="form-check-input"
                />
                <label htmlFor="event-venue" className="SpotLetContent" > Venue </label>
            </div>
            {
                formData?.event?.["venue"]?.available && (
                <div className="">
                    <div className="mb-3 d-flex flex-column">
                        <label htmlFor="event-venue-price" className="form-label SpotLetContent">
                            Pricing starts from<span className="required">*</span>
                        </label>
                        <input
                            // type="text"
                            required
                            // min={0}
                            placeholder="Enter price"
                            id="event-venue-price"
                            name="price"
                            className="textFiled"
                            value={formData?.event?.["venue"]?.price || "" }
                            onChange={(e) => {
                            const regex= /^[1-9][0-9]*$/;
                            if (e.target.value === "" || regex.test(e.target.value)) {
                                onChangeTimingsPrice(e,'event', "venue");
                                    setEventError({
                                        ...eventError,
                                        "venuePrice" : false,
                                    });
                            }
                            }}
                        />
                        {eventError?.["venuePrice"] && <span style={{color : 'red'}} className="SpotLetContent">Enter the start price</span>}
                        {/* {filmError.hourlyPrice &&<span style={{color : 'red'}}>Enter price</span>} */}
                    </div>
                    <div className="mb-3 d-flex flex-column">
                        <label htmlFor="event-minimumBooking" className="form-label SpotLetContent">
                        The above price is applicable for<span className="required">*</span>
                        </label>
                        <select
                            required
                            id="event-minimumBooking"
                            name="minimumBookingHours"
                            className="textFiledSelect SpotLetContent"
                            value={formData?.event?.["venue"]?.minimumBookingHours}
                            onChange={(e) => { 
                                onChangeTimingsBookingHours(e,'event', "venue");
                                setEventError({
                                    ...eventError,
                                    "venueMinHrs" : false,
                                });
                            }}
                        >
                            <option hidden selected value="">Select hours</option>
                            <option value='1'>1 Hr</option>
                            <option value='2'>2 Hrs</option>
                            <option value='3'>3 Hrs</option>
                            <option value='4'>4 Hrs</option>
                            <option value='5'>5 Hrs</option>
                            <option value='6'>6 Hrs</option>
                            <option value='7'>7 Hrs</option>
                            <option value='8'>8 Hrs</option>
                            <option value='9'>9 Hrs</option>
                            <option value='10'>10 Hrs</option>
                            <option value='11'>11 Hrs</option>
                            <option value='12'>12 Hrs</option>
                            <option value='13'>13 Hrs</option>
                            <option value='14'>14 Hrs</option>
                            <option value='15'>15 Hrs</option>
                            <option value='16'>16 Hrs</option>
                            <option value='17'>17 Hrs</option>
                            <option value='18'>18 Hrs</option>
                            <option value='19'>19 Hrs</option>
                            <option value='20'>20 Hrs</option>
                            <option value='21'>21 Hrs</option>
                            <option value='22'>22 Hrs</option>
                            <option value='23'>23 Hrs</option>
                            <option value='24'>24 Hrs</option>
                        </select>
                        {eventError?.["venueMinHrs"] && <span style={{color : 'red'}} className="SpotLetContent">Select the minimum booking hours</span>}
                    </div>
                    <div className="mb-3 d-flex flex-column">
                        <label className="form-label SpotLetContent">
                            Maximum guests allowed<span className="required">*</span>
                        </label>
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="mb-2 d-flex flex-column">
                                    <input
                                        // type="text"
                                        required
                                        // min={0}
                                        placeholder="Enter number"
                                        name = "maxAttendees"
                                        className="textFiled SpotLetContent"
                                        value={formData?.event?.venue?.maxAttendees || ""}
                                        onChange={(e) => {
                                            const regex= /^[1-9][0-9]*$/;
                                            if (e.target.value === "" || regex.test(e.target.value)){
                                                // onChangeInput(e,'event')
                                                onChangeTimingsPrice(e, "event", "venue");
                                                    setEventError({
                                                        ...eventError,
                                                        "venueGuests" : false,
                                                    });
                                            }
                                        }}
                                    />
                                </div>
                            </div>
                            {/* <div className="col-lg-6">
                                <div className="mb-2 d-flex flex-column">
                                    <input
                                        // type="text"
                                        required
                                        // min={0}
                                        placeholder="Max. attendees"
                                        name="max"
                                        className="textFiled SpotLetContent"
                                        value={formData?.event?.max || ""}
                                        onChange={(e) => {
                                            const regex= /^[1-9][0-9]*$/;
                                            if (e.target.value === "" || regex.test(e.target.value)) {
                                                onChangeInput(e,'event')
                                            }
                                        }}
                                    />
                                </div>
                            </div> */}
                        </div>
                        {eventError.venueGuests &&<span style={{color : 'red'}} className="SpotLetContent">Please enter number</span>}
                    </div>
                </div>
                )
            }
        </div>
    )
}

const eventPerPlateFormat = () => {
    return(
        <div className="listing-pricing-card col-lg-4" >
            <div className="">
                <input 
                    // required = {formData?.film?.minimumBooking ? false : true} 
                    checked = {formData?.event?.["perPlate"]?.available} 
                    value = {"perPlate"} 
                    name="perPlate" 
                    id = "event-perPlate" 
                    onChange={(e) => {
                        onChangeTimingsCheckbox(e,'event');
                        if (eventError?.hours) {
                            setEventError({
                                ...eventError,
                                hours : false,
                            });
                        }
                    }} 
                    type="checkbox" 
                    className="form-check-input"
                />
                <label htmlFor="event-perPlate" className="SpotLetContent" > Per Plate </label>
            </div>
            {
                formData?.event?.["perPlate"]?.available && (
                <div className="">
                    <div className="d-flex justify-content-between">
                        <div className="">
                            <input 
                                // required = {formData?.film?.minimumBooking ? false : true} 
                                checked = {formData?.event?.["pureVeg"] === true} 
                                value = {"pureVeg"} 
                                name="pureVeg" 
                                id = "event-pureVeg" 
                                onChange={(e) => {
                                    setFormData({
                                        ...formData,
                                        "event" : {
                                            ...formData?.["event"],
                                            "pureVeg" : true,
                                            "nonVeg" : false,
                                        }
                                    });
                                    setEventError({
                                        ...eventError,
                                        "vegOrNonVeg" : false,
                                    });
                                }} 
                                type="checkbox" 
                                className="form-check-input"
                            />
                            <label htmlFor="event-pureVeg" className="SpotLetContent" > Pure Veg </label>
                        </div>
                        <div className="">
                            <input 
                                // required = {formData?.film?.minimumBooking ? false : true} 
                                checked = {formData?.event?.["nonVeg"] === true} 
                                value = {"nonVeg"} 
                                name="nonVeg" 
                                id = "event-nonVeg" 
                                onChange={(e) => {
                                    setFormData({
                                        ...formData,
                                        "event" : {
                                            ...formData?.["event"],
                                            "nonVeg" : true,
                                            "pureVeg" : false,
                                        }
                                    });
                                    setEventError({
                                        ...eventError,
                                        "vegOrNonVeg" : false,
                                    });
                                }} 
                                type="checkbox" 
                                className="form-check-input"
                            />
                            <label htmlFor="event-nonVeg" className="SpotLetContent" >Veg and Non-Veg </label>
                        </div>
                    </div>
                    {eventError?.["vegOrNonVeg"] && <span style={{color : 'red'}} className="SpotLetContent"> Select one option</span>}
                    {
                        ( formData?.["event"]?.["nonVeg"] || formData?.["event"]?.["pureVeg"]) && (
                            <div className="mb-3 d-flex flex-column">
                                <label htmlFor="event-veg-perPlate-price" className="form-label SpotLetContent">
                                Veg price starts from<span className="required">*</span>
                                </label>
                                <input
                                    // type="text"
                                    required
                                    // min={0}
                                    placeholder="Enter price"
                                    id="event-veg-perPlate-price"
                                    name="vegPrice"
                                    className="textFiled"
                                    value={formData?.event?.["perPlate"]?.vegPrice || "" }
                                    onChange={(e) => {
                                    const regex= /^[1-9][0-9]*$/;
                                    if (e.target.value === "" || regex.test(e.target.value)) {
                                        onChangeTimingsPrice(e,'event', "perPlate");
                                            setEventError({
                                                ...eventError,
                                                "perPlateVegPrice" : false,
                                            });
                                    }
                                    }}
                                />
                                {eventError?.["perPlateVegPrice"] && <span style={{color : 'red'}} className="SpotLetContent">Enter the veg start price</span>}
                            </div>
                        )
                    }
                    {
                       (formData?.["event"]?.["nonVeg"] === true ) && (
                            <div className="mb-3 d-flex flex-column">
                                <label htmlFor="event-non-veg-perPlate-price" className="form-label SpotLetContent">
                                Non veg price starts from<span className="required">*</span>
                                </label>
                                <input
                                    // type="text"
                                    required
                                    // min={0}
                                    placeholder="Enter price"
                                    id="event-non-veg-perPlate-price"
                                    name="nonVegPrice"
                                    className="textFiled"
                                    value={formData?.event?.["perPlate"]?.nonVegPrice || "" }
                                    onChange={(e) => {
                                    const regex= /^[1-9][0-9]*$/;
                                    if (e.target.value === "" || regex.test(e.target.value)) {
                                        onChangeTimingsPrice(e,'event', "perPlate");
                                        setEventError({
                                            ...eventError,
                                            "perPlateNonVegPrice" : false,
                                        });
                                    }
                                    }}
                                />
                                {eventError?.["perPlateNonVegPrice"] && <span style={{color : 'red'}} className="SpotLetContent">Enter the non veg start price</span>}
                            </div>
                        )
                    }
                    {
                        (formData?.["event"]?.["nonVeg"] ||  formData?.["event"]?.["pureVeg"]) && (
                            <div className="mb-3 d-flex flex-column">
                                <label className="form-label SpotLetContent">
                                Minimum guests allowed<span className="required">*</span>
                                </label>
                                <div className="row">   
                                    <div className="col-lg-12">
                                        <div className="mb-2 d-flex flex-column">
                                            <input
                                                // type="text"
                                                required
                                                // min={0}
                                                placeholder="Enter number"
                                                name = "minAttendees"
                                                className="textFiled SpotLetContent"
                                                value={formData?.event?.perPlate?.minAttendees || ""}
                                                onChange={(e) => {
                                                    const regex= /^[1-9][0-9]*$/;
                                                    if (e.target.value === "" || regex.test(e.target.value)){
                                                        onChangeTimingsPrice(e, "event", "perPlate");
                                                            setEventError({
                                                                ...eventError,
                                                                "perPlateMinGuests" : "",
                                                            });
                                                    }
                                                }}
                                            />
                                        </div>
                                    </div>
                                </div>
                                {eventError.perPlateMinGuests &&<span style={{color : 'red'}} className="SpotLetContent">{eventError.perPlateMinGuests}</span>}
                            </div>
                        )
                    }
                    {
                        (formData?.["event"]?.["nonVeg"] ||  formData?.["event"]?.["pureVeg"]) && (
                            <div className="mb-3 d-flex flex-column">
                                <label className="form-label SpotLetContent">
                                    Maximum guests allowed<span className="required">*</span>
                                </label>
                                <div className="row">   
                                    <div className="col-lg-12">
                                        <div className="mb-2 d-flex flex-column">
                                            <input
                                                // type="text"
                                                required
                                                // min={0}
                                                placeholder="Enter number"
                                                name = "maxAttendees"
                                                className="textFiled SpotLetContent"
                                                value={formData?.event?.perPlate?.maxAttendees || ""}
                                                onChange={(e) => {
                                                    const regex= /^[1-9][0-9]*$/;
                                                    if (e.target.value === "" || regex.test(e.target.value)){
                                                        // onChangeInput(e,'event')
                                                        onChangeTimingsPrice(e, "event", "perPlate");
                                                            setEventError({
                                                                ...eventError,
                                                                "perPlateMaxGuests" : "",
                                                            });
                                                    }
                                                }}
                                            />
                                        </div>
                                    </div>
                                </div>
                                {eventError.perPlateMaxGuests &&<span style={{color : 'red'}} className="SpotLetContent">{eventError.perPlateMaxGuests}</span>}
                            </div>
                        )
                    }
                </div>
                )
            }
        </div>
    )
}

const eventPerHrsFormat = () => {
    return(
        <div className="listing-pricing-card col-lg-4" >
            <div className="">
                <input 
                    // required = {formData?.film?.minimumBooking ? false : true} 
                    checked = {formData?.event?.["perHour"]?.available} 
                    value = {"perHour"} 
                    name="perHour" 
                    id = "event-perHour" 
                    onChange={(e) => {
                        onChangeTimingsCheckbox(e,'event');
                        if (eventError?.hours) {
                            setEventError({
                                ...eventError,
                                hours : false,
                            });
                        }
                    }} 
                    type="checkbox" 
                    className="form-check-input"
                />
                <label htmlFor="event-perHour" className="SpotLetContent" > Per Hour </label>
            </div>
            {
                formData?.event?.["perHour"]?.available && (
                <div className="">
                    <div className="mb-3 d-flex flex-column">
                        <label htmlFor="event-perHour-price" className="form-label SpotLetContent">
                            Pricing starts from<span className="required">*</span>
                        </label>
                        <input
                            // type="text"
                            required
                            // min={0}
                            placeholder="Enter price"
                            id="event-perHour-price"
                            name="price"
                            className="textFiled"
                            value={formData?.event?.["perHour"]?.price || "" }
                            onChange={(e) => {
                            const regex= /^[1-9][0-9]*$/;
                            if (e.target.value === "" || regex.test(e.target.value)) {
                                onChangeTimingsPrice(e,'event', "perHour");
                                    setEventError({
                                        ...eventError,
                                        "perHourPrice" : false,
                                    });
                            }
                            }}
                        />
                        {eventError?.["perHourPrice"] && <span style={{color : 'red'}} className="SpotLetContent">Enter the start price</span>}
                    </div>
                    <div className="mb-3 d-flex flex-column">
                        <label htmlFor="event-minimumBooking" className="form-label SpotLetContent">
                        Minimum booking hours<span className="required">*</span>
                        </label>
                        <select
                            required
                            id="event-minimumBooking"
                            name="minimumBookingHours"
                            className="textFiledSelect SpotLetContent"
                            value={formData?.event?.["perHour"]?.minimumBookingHours}
                            onChange={(e) => { 
                                onChangeTimingsBookingHours(e,'event', "perHour");
                                setEventError({
                                    ...eventError,
                                    "perHourMinHrs" : false,
                                });
                            }}
                        >
                            <option hidden selected value="">Select hours</option>
                            <option value='1'>1 Hr</option>
                            <option value='2'>2 Hrs</option>
                            <option value='3'>3 Hrs</option>
                            <option value='4'>4 Hrs</option>
                            <option value='5'>5 Hrs</option>
                            <option value='6'>6 Hrs</option>
                            <option value='7'>7 Hrs</option>
                            <option value='8'>8 Hrs</option>
                            <option value='9'>9 Hrs</option>
                            <option value='10'>10 Hrs</option>
                            <option value='11'>11 Hrs</option>
                            <option value='12'>12 Hrs</option>
                            <option value='13'>13 Hrs</option>
                            <option value='14'>14 Hrs</option>
                            <option value='15'>15 Hrs</option>
                            <option value='16'>16 Hrs</option>
                            <option value='17'>17 Hrs</option>
                            <option value='18'>18 Hrs</option>
                            <option value='19'>19 Hrs</option>
                            <option value='20'>20 Hrs</option>
                            <option value='21'>21 Hrs</option>
                            <option value='22'>22 Hrs</option>
                            <option value='23'>23 Hrs</option>
                            <option value='24'>24 Hrs</option>
                        </select>
                        {eventError?.["perHourMinHrs"] && <span style={{color : 'red'}} className="SpotLetContent">Select the minimum booking hours</span>}
                    </div>
                    <div className="mb-3 d-flex flex-column">
                        <label className="form-label SpotLetContent">
                            Maximum guests allowed<span className="required">*</span>
                        </label>
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="mb-2 d-flex flex-column">
                                    <input
                                        // type="text"
                                        required
                                        // min={0}
                                        placeholder="Enter number"
                                        name = "maxAttendees"
                                        className="textFiled SpotLetContent"
                                        value={formData?.event?.perHour?.maxAttendees || ""}
                                        onChange={(e) => {
                                            const regex= /^[1-9][0-9]*$/;
                                            if (e.target.value === "" || regex.test(e.target.value)){
                                                // onChangeInput(e,'event')
                                                onChangeTimingsPrice(e, "event", "perHour");
                                                    setEventError({
                                                        ...eventError,
                                                        "perHourGuests" : false,
                                                    });
                                            }
                                        }}
                                    />
                                </div>
                            </div>
                        </div>
                        {eventError.perHourGuests &&<span style={{color : 'red'}} className="SpotLetContent">Please enter guests number</span>}
                    </div>
                </div>
                )
            }
        </div>
    )
}
// Event End 

// timings cards end

// Accordians Start
const filmAccordian = () => {
    return(
        <Accordion defaultActiveKey={data?.film?.available ? "0" : ''} id = "PricingFilmId" >
                    <Card className="pricingCard">
                        <Card.Header style={{backgroundColor : "#3d3d3d", color : "#ffffff"}} className="d-flex justify-content-between">
                            <h6 className="SpotLetSubHead">Are you interested in providing your property for a Film Shoot?<span className="required">*</span></h6>
                            <Toggle eventKey="0" type='film'/>
                        </Card.Header>
                        <Accordion.Collapse  eventKey="0">
                            <Card.Body style = {{border : "2px solid #3d3d3d"}}>
                                <div className="row">
                                    <h1 className = "SpotLetSubHead"> This property is available for booking </h1>
                                    <span className="SpotLetContent"> (You can select all 3 options, or choose at least one) </span>
                                    {filmError.hours && <span style={{color : 'red'}} className="SpotLetContent" >Select any one of the below options</span>}
                                    {filmPricing24HrsFormat()}
                                    {filmPricing12HrsFormat()}
                                    {filmPricingPerHrsFormat()}
                                    <div className="col-lg-6">
                                        {/* <div className="mb-3 d-flex flex-column">
                                            <label htmlFor="film-hourly-price" className="form-label">
                                                Pricing starts from<span className="required">*</span>
                                            </label>
                                            <input
                                                // type="text"
                                                required
                                                // min={0}
                                                placeholder="Enter price"
                                                id="film-hourly-price"
                                                name="hourlyPrice"
                                                className="textFiled"
                                                value={formData?.film?.hourlyPrice || "" }
                                                onChange={(e) => {
                                                const regex= /^[1-9][0-9]*$/;
                                                if (e.target.value === "" || regex.test(e.target.value)) {
                                                    onChangeInput(e,'film')
                                                }
                                                }}
                                            />
                                            {filmError.hourlyPrice &&<span style={{color : 'red'}}>Enter price</span>}
                                        </div> */}
                                    </div>
                                    <div className="col-lg-6"></div>
                                    <div className="col-lg-6">
                                        <div className="mb-3 d-flex flex-column">
                                            <label className="form-label SpotLetContent">
                                                Maximum attendees allowed<span className="required">*</span>
                                            </label>
                                            <div className="row">
                                                <div className="col-lg-12">
                                                    <div className="mb-2 d-flex flex-column">
                                                        <input
                                                            // type="text"
                                                            required
                                                            // min={0}
                                                            placeholder="No. of attendees"
                                                            name = "attendees"
                                                            className="textFiled"
                                                            value={formData?.film?.attendees || ""}
                                                            onChange={(e) => {
                                                                const regex= /^[1-9][0-9]*$/;
                                                                if (e.target.value === "" || regex.test(e.target.value)){
                                                                    onChangeInput(e,'film');
                                                                        setFilmError({
                                                                            ...filmError,
                                                                            attendees : false,
                                                                        })
                                                                }
                                                            }}
                                                        />
                                                    </div>
                                                </div>
                                                {/* <div className="col-lg-6">
                                                    <div className="mb-2 d-flex flex-column">
                                                        <input
                                                            // type="text"
                                                            required
                                                            // min={0}
                                                            placeholder="Max. attendees"
                                                            name="max"
                                                            className="textFiled"
                                                            value={formData?.film?.max || "" }
                                                            onChange={(e) => {
                                                                const regex= /^[1-9][0-9]*$/;
                                                                if (e.target.value === "" || regex.test(e.target.value)){
                                                                    onChangeInput(e,'film')
                                                                }
                                                            }}
                                                        />
                                                    </div>
                                                </div> */}
                                            </div>
                                            {filmError.attendees &&<span style={{color : 'red'}} className="SpotLetContent">{filmError?.message}</span>}
                                        </div>
                                    </div>
                                    <div className="col-lg-6"></div>
                                    <div className="col-lg-6">
                                        {/* <div className="mb-3 d-flex flex-column">
                                            <label htmlFor="film-minimumBooking" className="form-label">
                                           Minimum booking Hrs<span className="required">*</span>
                                            </label>
                                            <select
                                                required
                                                id="film-minimumBooking"
                                                name="minimumBooking"
                                                className="textFiledSelect"
                                                value={formData?.film?.minimumBooking}
                                                onChange={(e) => {onChangeInput(e,'film')}}
                                            >
                                                <option hidden selected value="">Select hours</option>
                                                <option value='1'>1 Hr</option>
                                                <option value='2'>2 Hrs</option>
                                                <option value='4'>4 Hrs</option>
                                                <option value='6'>6 Hrs</option>
                                                <option value='8'>8 Hrs</option>
                                                <option value='12'>12 Hrs</option>
                                                <option value='16'>16 Hrs</option>
                                                <option value='20'>20 Hrs</option>
                                                <option value='24'>24 Hrs</option>
                                            </select>
                                            {filmError.minBooking &&<span style={{color : 'red'}}>Select Min booking Hrs</span>}
                                        </div> */}
                                    </div>
                                    <div className="col-lg-12">
                                        <div className="mb-2 d-flex flex-column">
                                            <div className="d-flex">
                                                <label className="form-label SpotLetContent">
                                                    Timings available for booking<span className="required">*</span> 
                                                </label>
                                                <div className="mx-3">
                                                    <label htmlFor="film-all-booking-hours mx-3" className="SpotLetContent" > Select all </label>
                                                    <input 
                                                        checked = {getAllCheckedStatus("film")} 
                                                        name="all" 
                                                        id = "film-all-booking-hours" 
                                                        onChange={(e) => {
                                                            selectAllTimings(e,'film');
                                                            setFilmError({
                                                                ...filmError,
                                                                timings : false,
                                                            }) 
                                                        }} 
                                                        type="checkbox" 
                                                        className="form-check-input mx-2"
                                                    />
                                                </div>
                                            </div>
                                            <div className="row">
                                                {/*Monday start*/}
                                                <div className="mb-2 col-lg-12 row">
                                                    <div className="col-lg-2 col-2" style={{paddingRight : '0px'}}>
                                                        <div className="d-flex">
                                                            <label htmlFor="film-timings-monday" className="form-label SpotLetContent">Mon</label>
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-2 col-5" style={{paddingRight : '0px'}}>
                                                        <div className="d-flex">
                                                            <input
                                                                type="checkbox"
                                                                className="form-check-input"
                                                                id='film-timings-monday-fullDay'
                                                                checked={formData?.film?.timings?.monday?.fullDay === true}
                                                                onChange={(e) => { 
                                                                    onChange24HrsCheckBox(e, "film", "monday");
                                                                    setFilmError({
                                                                        ...filmError,
                                                                        timings : false,
                                                                    }) 
                                                                }}
                                                            />
                                                            <label htmlFor="film-timings-monday-fullDay" className="form-label SpotLetContent">Full day</label>
                                                        </div>
                                                    </div>
                                                    {
                                                        !(formData?.film?.["24Hours"]?.available || formData?.film?.["12Hours"]?.available ) && (
                                                        <div className="col-lg-2 col-5" style={{paddingRight : '0px',paddingLeft : '0px'}}>
                                                            <div className="d-flex">
                                                                <input
                                                                    type="checkbox"
                                                                    className="form-check-input"
                                                                    id='film-timings-monday-setHours'
                                                                    checked={formData?.film?.timings?.monday?.fullDay === false}
                                                                    onChange={(e) => {
                                                                        onChangeSetHrsCheckBox(e, "film", "monday");
                                                                        setFilmError({
                                                                            ...filmError,
                                                                            timings : false,
                                                                        }) 
                                                                    }}
                                                                />
                                                                <label htmlFor="film-timings-monday-setHours" className="form-label SpotLetContent">Set hrs</label>
                                                            </div>
                                                        </div>
                                                        )
                                                    }
                                                    {!(formData?.film?.["24Hours"]?.available || formData?.film?.["12Hours"]?.available ) && formData?.film?.timings?.monday?.fullDay === false && (
                                                        <>
                                                            <div className="col-lg-3 col-6 d-flex flex-column">
                                                                <select
                                                                    className="textFiledSelect SpotLetContent"
                                                                    value={formData?.film?.timings?.monday?.timings?.open}

                                                                    onChange={(e) => {
                                                                        validateFilmTvTimings(  e.target.value ,formData?.film?.timings?.monday?.timings?.close, 'film','monday')
                                                                    }}
                                                                >
                                                                    {timeOptions.map((item) => (
                                                                    <option value={item.value}>{item.label}</option>
                                                                    ))}
                                                                </select>
                                                            </div>
                                                            <div className="col-lg-3 col-6 d-flex flex-column">
                                                                <select
                                                                    className="textFiledSelect SpotLetContent"
                                                                    value={formData?.film?.timings?.monday?.timings?.close}
                                                                    onChange={(e) => {
                                                                        validateFilmTvTimings(formData?.film?.timings?.monday?.timings?.open, e.target.value, 'film','monday')
                                                                    }}
                                                                >
                                                                    {timeOptions.map((item) => (
                                                                    <option value={item.value}>{item.label}</option>
                                                                    ))}
                                                                </select>
                                                            </div>
                                                        </>
                                                    )}
                                                 </div>
                                                 {/*Monday end*/}
                                                  {/*Tuesday start*/}
                                                  <div className="mb-2 col-lg-12 row">
                                                    <div className="col-lg-2 col-2" style={{paddingRight : '0px'}}>
                                                        <div className="d-flex">
                                                            <label htmlFor="film-timings-tuesday" className="form-label SpotLetContent">Tue</label>
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-2 col-5" style={{paddingRight : '0px'}}>
                                                        <div className="d-flex">
                                                            <input
                                                                type="checkbox"
                                                                className="form-check-input"
                                                                id='film-timings-tuesday-fullDay'
                                                                checked={formData?.film?.timings?.tuesday?.fullDay === true}
                                                                onChange={(e) => { 
                                                                    onChange24HrsCheckBox(e, "film", "tuesday");
                                                                    setFilmError({
                                                                        ...filmError,
                                                                        timings : false,
                                                                    }) 
                                                                 }}
                                                            />
                                                            <label htmlFor="film-timings-tuesday-fullDay" className="form-label SpotLetContent">Full day</label>
                                                        </div>
                                                    </div>
                                                    {
                                                         !(formData?.film?.["24Hours"]?.available || formData?.film?.["12Hours"]?.available ) && (
                                                            <div className="col-lg-2 col-5" style={{paddingRight : '0px',paddingLeft : '0px'}}>
                                                                <div className="d-flex">
                                                                    <input
                                                                        type="checkbox"
                                                                        className="form-check-input"
                                                                        id='film-timings-tuesday-setHours'
                                                                        checked={formData?.film?.timings?.tuesday?.fullDay === false}
                                                                        onChange={(e) => {
                                                                            onChangeSetHrsCheckBox(e, "film", "tuesday");
                                                                            setFilmError({
                                                                                ...filmError,
                                                                                timings : false,
                                                                            }) 
                                                                        }}
                                                                    />
                                                                    <label htmlFor="film-timings-tuesday-setHours" className="form-label SpotLetContent">Set hrs</label>
                                                                </div>
                                                            </div>
                                                         )
                                                    }
                                                    {!(formData?.film?.["24Hours"]?.available || formData?.film?.["12Hours"]?.available ) && formData?.film?.timings?.tuesday?.fullDay === false && (
                                                        <>
                                                            <div className="col-lg-3 col-6 d-flex flex-column">
                                                                <select
                                                                    className="textFiledSelect SpotLetContent"
                                                                    value={formData?.film?.timings?.tuesday?.timings?.open}
                                                                    onChange={(e) => {
                                                                        validateFilmTvTimings(  e.target.value ,formData?.film?.timings?.tuesday?.timings?.close, 'film','tuesday')
                                                                    }}
                                                                >
                                                                    {timeOptions.map((item) => (
                                                                    <option value={item.value}>{item.label}</option>
                                                                    ))}
                                                                </select>
                                                            </div>
                                                            <div className="col-lg-3 col-6 d-flex flex-column">
                                                                <select
                                                                    className="textFiledSelect SpotLetContent"
                                                                    value={formData?.film?.timings?.tuesday?.timings?.close}
                                                                    onChange={(e) => {
                                                                        validateFilmTvTimings(formData?.film?.timings?.tuesday?.timings?.open, e.target.value, 'film','tuesday')
                                                                    }}
                                                                >
                                                                    {timeOptions.map((item) => (
                                                                    <option value={item.value}>{item.label}</option>
                                                                    ))}
                                                                </select>
                                                            </div>
                                                        </>
                                                    )}
                                                 </div>
                                                 {/*Tuesday end*/}
                                                  {/*Wednesday start*/}
                                                  <div className="mb-2 col-lg-12 row">
                                                    <div className="col-lg-2 col-2" style={{paddingRight : '0px'}}>
                                                        <div className="d-flex">
                                                            <label htmlFor="film-timings-wednesday" className="form-label SpotLetContent">Wed</label>
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-2 col-5" style={{paddingRight : '0px'}}>
                                                        <div className="d-flex">
                                                            <input
                                                                type="checkbox"
                                                                className="form-check-input"
                                                                id='film-timings-wednesday-fullDay'
                                                                checked={formData?.film?.timings?.wednesday?.fullDay === true}
                                                                onChange={(e) => { 
                                                                    onChange24HrsCheckBox(e, "film", "wednesday");
                                                                    setFilmError({
                                                                        ...filmError,
                                                                        timings : false,
                                                                    }) 
                                                                 }}
                                                            />
                                                            <label htmlFor="film-timings-wednesday-fullDay" className="form-label SpotLetContent">Full day</label>
                                                        </div>
                                                    </div>
                                                    {
                                                        !(formData?.film?.["24Hours"]?.available || formData?.film?.["12Hours"]?.available ) && (
                                                        <div className="col-lg-2 col-5" style={{paddingRight : '0px',paddingLeft : '0px'}}>
                                                            <div className="d-flex">
                                                                <input
                                                                    type="checkbox"
                                                                    className="form-check-input"
                                                                    id='film-timings-wednesday-setHours'
                                                                    checked={formData?.film?.timings?.wednesday?.fullDay === false}
                                                                    onChange={(e) => {
                                                                        onChangeSetHrsCheckBox(e, "film", "wednesday");
                                                                        setFilmError({
                                                                            ...filmError,
                                                                            timings : false,
                                                                        }) 
                                                                    }}
                                                                />
                                                                <label htmlFor="film-timings-wednesday-setHours" className="form-label SpotLetContent">Set hrs</label>
                                                            </div>
                                                        </div>
                                                        )
                                                    }
                                                    { !(formData?.film?.["24Hours"]?.available || formData?.film?.["12Hours"]?.available ) && formData?.film?.timings?.wednesday?.fullDay === false && (
                                                        <>
                                                            <div className="col-lg-3 col-6 d-flex flex-column">
                                                                <select
                                                                    className="textFiledSelect SpotLetContent"
                                                                    onChange={(e) => {
                                                                        validateFilmTvTimings(  e.target.value ,formData?.film?.timings?.wednesday?.timings?.close, 'film','wednesday')
                                                                    }}
                                                                >
                                                                    {timeOptions.map((item) => (
                                                                    <option value={item.value}>{item.label}</option>
                                                                    ))}
                                                                </select>
                                                            </div>
                                                            <div className="col-lg-3 col-6 d-flex flex-column">
                                                                <select
                                                                    className="textFiledSelect SpotLetContent"
                                                                    value={formData?.film?.timings?.wednesday?.timings?.close}
                                                                    onChange={(e) => {
                                                                        validateFilmTvTimings(formData?.film?.timings?.wednesday?.timings?.open, e.target.value, 'film','wednesday')
                                                                    }}
                                                                >
                                                                    {timeOptions.map((item) => (
                                                                    <option value={item.value}>{item.label}</option>
                                                                    ))}
                                                                </select>
                                                            </div>
                                                        </>
                                                    )}
                                                 </div>
                                                 {/*Wednesday end*/}
                                                  {/*Thursday start*/}
                                                  <div className="mb-2 col-lg-12 row">
                                                    <div className="col-lg-2 col-2" style={{paddingRight : '0px'}}>
                                                        <div className="d-flex">
                                                            <label htmlFor="film-timings-thursday" className="form-label SpotLetContent">Thu</label>
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-2 col-5" style={{paddingRight : '0px'}}>
                                                        <div className="d-flex">
                                                            <input
                                                                type="checkbox"
                                                                className="form-check-input"
                                                                id='film-timings-thursday-fullDay'
                                                                checked={formData?.film?.timings?.thursday?.fullDay === true}
                                                                onChange={(e) => { 
                                                                    onChange24HrsCheckBox(e, "film", "thursday");
                                                                    setFilmError({
                                                                        ...filmError,
                                                                        timings : false,
                                                                    }) 
                                                                 }}
                                                            />
                                                            <label htmlFor="film-timings-thursday-fullDay" className="form-label SpotLetContent">Full day</label>
                                                        </div>
                                                    </div>
                                                    {
                                                        !(formData?.film?.["24Hours"]?.available || formData?.film?.["12Hours"]?.available ) && (
                                                        <div className="col-lg-2 col-5" style={{paddingRight : '0px',paddingLeft : '0px'}}>
                                                            <div className="d-flex">
                                                                <input
                                                                    type="checkbox"
                                                                    className="form-check-input"
                                                                    id='film-timings-thursday-setHours'
                                                                    checked={formData?.film?.timings?.thursday?.fullDay === false}
                                                                    onChange={(e) => {
                                                                        onChangeSetHrsCheckBox(e, "film", "thursday");
                                                                        setFilmError({
                                                                            ...filmError,
                                                                            timings : false,
                                                                        }) 
                                                                    } }
                                                                />
                                                                <label htmlFor="film-timings-thursday-setHours" className="form-label SpotLetContent">Set hrs</label>
                                                            </div>
                                                        </div>
                                                        )
                                                    }
                                                    {!(formData?.film?.["24Hours"]?.available || formData?.film?.["12Hours"]?.available ) && formData?.film?.timings?.thursday?.fullDay === false && (
                                                        <>
                                                            <div className="col-lg-3 col-6 d-flex flex-column">
                                                                <select
                                                                    className="textFiledSelect SpotLetContent"
                                                                    value={formData?.film?.timings?.thursday?.timings?.open}
                                                                    onChange={(e) => {
                                                                        validateFilmTvTimings(  e.target.value ,formData?.film?.timings?.thursday?.timings?.close, 'film','thursday')
                                                                    }}
                                                                >
                                                                    {timeOptions.map((item) => (
                                                                    <option value={item.value}>{item.label}</option>
                                                                    ))}
                                                                </select>
                                                            </div>
                                                            <div className="col-lg-3 col-6 d-flex flex-column">
                                                                <select
                                                                    className="textFiledSelect SpotLetContent"
                                                                    value={formData?.film?.timings?.thursday?.timings?.close}
                                                                    onChange={(e) => {
                                                                        validateFilmTvTimings(formData?.film?.timings?.thursday?.timings?.open, e.target.value, 'film','thursday')
                                                                    }}
                                                                >
                                                                    {timeOptions.map((item) => (
                                                                    <option value={item.value}>{item.label}</option>
                                                                    ))}
                                                                </select>
                                                            </div>
                                                        </>
                                                    )}
                                                 </div>
                                                 {/*Thursday end*/}
                                                  {/*Friday start*/}
                                                  <div className="mb-2 col-lg-12 row">
                                                    <div className="col-lg-2 col-2" style={{paddingRight : '0px'}}>
                                                        <div className="d-flex">
                                                            <label htmlFor="film-timings-friday" className="form-label SpotLetContent ">Fri</label>
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-2 col-5" style={{paddingRight : '0px'}}>
                                                        <div className="d-flex">
                                                            <input
                                                                type="checkbox"
                                                                className="form-check-input"
                                                                id='film-timings-friday-fullDay'
                                                                checked={formData?.film?.timings?.friday?.fullDay === true}
                                                                onChange={(e) => { 
                                                                    onChange24HrsCheckBox(e, "film", "friday");
                                                                    setFilmError({
                                                                        ...filmError,
                                                                        timings : false,
                                                                    }) 
                                                                 }}
                                                            />
                                                            <label htmlFor="film-timings-friday-fullDay" className="form-label SpotLetContent ">Full day</label>
                                                        </div>
                                                    </div>
                                                    {
                                                        !(formData?.film?.["24Hours"]?.available || formData?.film?.["12Hours"]?.available ) && (
                                                        <div className="col-lg-2 col-5" style={{paddingRight : '0px',paddingLeft : '0px'}}>
                                                            <div className="d-flex">
                                                                <input
                                                                    type="checkbox"
                                                                    className="form-check-input"
                                                                    id='film-timings-friday-setHours'
                                                                    checked={formData?.film?.timings?.friday?.fullDay === false}
                                                                    onChange={(e) => {
                                                                        onChangeSetHrsCheckBox(e, "film", "friday");
                                                                        setFilmError({
                                                                            ...filmError,
                                                                            timings : false,
                                                                        }) 
                                                                    } }
                                                                />
                                                                <label htmlFor="film-timings-friday-setHours" className="form-label SpotLetContent">Set hrs</label>
                                                            </div>
                                                        </div>
                                                        )
                                                    }
                                                    {!(formData?.film?.["24Hours"]?.available || formData?.film?.["12Hours"]?.available ) && formData?.film?.timings?.friday?.fullDay === false && (
                                                        <>
                                                            <div className="col-lg-3 col-6 d-flex flex-column">
                                                                <select
                                                                    className="textFiledSelect SpotLetContent"
                                                                    value={formData?.film?.timings?.friday?.timings?.open}
                                                                    onChange={(e) => {
                                                                        validateFilmTvTimings(  e.target.value ,formData?.film?.timings?.friday?.timings?.close, 'film','friday')
                                                                    }}
                                                                >
                                                                    {timeOptions.map((item) => (
                                                                    <option value={item.value}>{item.label}</option>
                                                                    ))}
                                                                </select>
                                                            </div>
                                                            <div className="col-lg-3 col-6 d-flex flex-column">
                                                                <select
                                                                    className="textFiledSelect SpotLetContent"
                                                                    value={formData?.film?.timings?.friday?.timings?.close}
                                                                    onChange={(e) => {
                                                                        validateFilmTvTimings(formData?.film?.timings?.friday?.timings?.open, e.target.value, 'film','friday')
                                                                    }}
                                                                >
                                                                    {timeOptions.map((item) => (
                                                                    <option value={item.value}>{item.label}</option>
                                                                    ))}
                                                                </select>
                                                            </div>
                                                        </>
                                                    )}
                                                 </div>
                                                 {/*Friday end*/}
                                                  {/*Saturday start*/}
                                                  <div className="mb-2 col-lg-12 row">
                                                    <div className="col-lg-2 col-2" style={{paddingRight : '0px'}}>
                                                        <div className="d-flex">
                                                            <label htmlFor="film-timings-saturday" className="form-label SpotLetContent">Sat</label>
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-2 col-5" style={{paddingRight : '0px'}}>
                                                        <div className="d-flex">
                                                            <input
                                                                type="checkbox"
                                                                className="form-check-input"
                                                                id='film-timings-saturday-fullDay'
                                                                checked={formData?.film?.timings?.saturday?.fullDay === true}
                                                                onChange={(e) => { 
                                                                    onChange24HrsCheckBox(e, "film", "saturday");
                                                                    setFilmError({
                                                                        ...filmError,
                                                                        timings : false,
                                                                    }) 
                                                                 }}
                                                            />
                                                            <label htmlFor="film-timings-saturday-fullDay" className="form-label SpotLetContent">Full day</label>
                                                        </div>
                                                    </div>
                                                    {
                                                         !(formData?.film?.["24Hours"]?.available || formData?.film?.["12Hours"]?.available ) && (
                                                            <div className="col-lg-2 col-5" style={{paddingRight : '0px',paddingLeft : '0px'}}>
                                                                <div className="d-flex">
                                                                    <input
                                                                        type="checkbox"
                                                                        className="form-check-input"
                                                                        id='film-timings-saturday-setHours'
                                                                        checked={formData?.film?.timings?.saturday?.fullDay === false}
                                                                        onChange={(e) => {
                                                                            onChangeSetHrsCheckBox(e, "film", "saturday");
                                                                            setFilmError({
                                                                                ...filmError,
                                                                                timings : false,
                                                                            }) 
                                                                        } }
                                                                    />
                                                                    <label htmlFor="film-timings-saturday-setHours" className="form-label SpotLetContent">Set hrs</label>
                                                                </div>
                                                            </div>
                                                         )
                                                    }
                                                    {!(formData?.film?.["24Hours"]?.available || formData?.film?.["12Hours"]?.available ) && formData?.film?.timings?.saturday?.fullDay === false && (
                                                        <>
                                                            <div className="col-lg-3 col-6 d-flex flex-column">
                                                                <select
                                                                    className="textFiledSelect SpotLetContent"
                                                                    value={formData?.film?.timings?.saturday?.timings?.open}
                                                                    onChange={(e) => {
                                                                        validateFilmTvTimings(  e.target.value ,formData?.film?.timings?.saturday?.timings?.close, 'film','saturday')
                                                                    }}
                                                                >
                                                                    {timeOptions.map((item) => (
                                                                    <option value={item.value}>{item.label}</option>
                                                                    ))}
                                                                </select>
                                                            </div>
                                                            <div className="col-lg-3 col-6 d-flex flex-column">
                                                                <select
                                                                    className="textFiledSelect SpotLetContent"
                                                                    value={formData?.film?.timings?.saturday?.timings?.close}
                                                                    onChange={(e) => {
                                                                        validateFilmTvTimings(formData?.film?.timings?.saturday?.timings?.open, e.target.value, 'film','saturday')
                                                                    }}
                                                                >
                                                                    {timeOptions.map((item) => (
                                                                    <option value={item.value}>{item.label}</option>
                                                                    ))}
                                                                </select>
                                                            </div>
                                                        </>
                                                    )}
                                                 </div>
                                                 {/*Saturday end*/}
                                                  {/*Sunday start*/}
                                                  <div className="mb-2 col-lg-12 row">
                                                    <div className="col-lg-2 col-2" style={{paddingRight : '0px'}}>
                                                        <div className="d-flex">
                                                            <label htmlFor="film-timings-sunday" className="form-label SpotLetContent">Sun</label>
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-2 col-5" style={{paddingRight : '0px'}}>
                                                        <div className="d-flex">
                                                            <input
                                                                type="checkbox"
                                                                className="form-check-input"
                                                                id='film-timings-sunday-fullDay'
                                                                checked={formData?.film?.timings?.sunday?.fullDay === true}
                                                                onChange={(e) => { 
                                                                    onChange24HrsCheckBox(e, "film", "sunday");
                                                                    setFilmError({
                                                                        ...filmError,
                                                                        timings : false,
                                                                    }) 
                                                                 }}
                                                            />
                                                            <label htmlFor="film-timings-sunday-fullDay" className="form-label SpotLetContent">Full day</label>
                                                        </div>
                                                    </div>
                                                    {
                                                         !(formData?.film?.["24Hours"]?.available || formData?.film?.["12Hours"]?.available ) && (
                                                            <div className="col-lg-2 col-5" style={{paddingRight : '0px',paddingLeft : '0px'}}>
                                                                <div className="d-flex">
                                                                    <input
                                                                        type="checkbox"
                                                                        className="form-check-input"
                                                                        id='film-timings-sunday-setHours'
                                                                        checked={formData?.film?.timings?.sunday?.fullDay === false}
                                                                        onChange={(e) => {
                                                                            onChangeSetHrsCheckBox(e, "film", "sunday");
                                                                            setFilmError({
                                                                                ...filmError,
                                                                                timings : false,
                                                                            }) 
                                                                        } }
                                                                    />
                                                                    <label htmlFor="film-timings-sunday-setHours" className="form-label SpotLetContent">Set hrs</label>
                                                                </div>
                                                            </div>
                                                         )
                                                    }
                                                    {!(formData?.film?.["24Hours"]?.available || formData?.film?.["12Hours"]?.available ) && formData?.film?.timings?.sunday?.fullDay === false && (
                                                        <>
                                                            <div className="col-lg-3 col-6 d-flex flex-column">
                                                                <select
                                                                    className="textFiledSelect SpotLetContent"
                                                                    value={formData?.film?.timings?.sunday?.timings?.open}
                                                                    onChange={(e) => {
                                                                        validateFilmTvTimings(  e.target.value ,formData?.film?.timings?.sunday?.timings?.close, 'film','sunday')
                                                                    }}
                                                                >
                                                                    {timeOptions.map((item) => (
                                                                    <option value={item.value}>{item.label}</option>
                                                                    ))}
                                                                </select>
                                                            </div>
                                                            <div className="col-lg-3 col-6 d-flex flex-column">
                                                                <select
                                                                    className="textFiledSelect SpotLetContent"
                                                                    value={formData?.film?.timings?.sunday?.timings?.close}
                                                                    onChange={(e) => {
                                                                        validateFilmTvTimings(formData?.film?.timings?.sunday?.timings?.open, e.target.value, 'film','sunday')
                                                                    }}
                                                                >
                                                                    {timeOptions.map((item) => (
                                                                    <option value={item.value}>{item.label}</option>
                                                                    ))}
                                                                </select>
                                                            </div>
                                                        </>
                                                    )}
                                                 </div>
                                                 {/*Sunday end*/}
                                            </div>
                                            {filmError.timings &&<span style={{color : 'red'}} className="SpotLetContent">Select Timings</span>}
                                        </div>
                                    </div>
                                    <div className="col-lg-6">
                                        <div className="mb-2 d-flex flex-column">
                                            <label htmlFor="film_cleaning" className="form-label SpotLetContent">
                                                Cleaning fee(Optional)
                                            </label>
                                            <input
                                                // type="text"
                                                // // min={0}
                                                placeholder="Enter cleaning fee"
                                                id="film_cleaning"
                                                name="cleaningFee"
                                                className="textFiled"
                                                value={formData?.film?.cleaningFee || ""}
                                                onChange={(e) => {
                                                    const regex= /^[1-9][0-9]*$/;
                                                    if (e.target.value === "" || regex.test(e.target.value)){
                                                        onChangeInput(e,'film')
                                                    }
                                                }}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-lg-6">
                                        <div className="mb-2 d-flex flex-column" >
                                            <label htmlFor="film_security_deposit" className="form-label SpotLetContent">
                                                Security deposit(Optional)
                                            </label>
                                            <input
                                                // type="text"
                                                // // min={0}
                                                placeholder="Enter security deposit"
                                                id="film_security_deposit"
                                                name="securityDeposit"
                                                className="textFiled"
                                                value={formData?.film?.securityDeposit || "" }
                                                onChange={(e) => {
                                                    const regex= /^[1-9][0-9]*$/;  
                                                    if (e.target.value === "" || regex.test(e.target.value)){
                                                        onChangeInput(e,'film')
                                                    }
                                                }}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </Card.Body>
                        </Accordion.Collapse>
                    </Card>
        </Accordion>
    )
}

const tvAccordian = () => {
    return(
        <Accordion defaultActiveKey={data?.tv?.available ? "1" : ''} id = "PricingTvId" >
                    <Card className="pricingCard">
                        <Card.Header style={{backgroundColor : "#3d3d3d", color : "#ffffff"}} className="d-flex justify-content-between">
                            <h6 className="SpotLetSubHead">Are you interested in providing your property for a TV series?<span className="required">*</span></h6>
                            <Toggle eventKey="1" type='tv'/>
                        </Card.Header>
                        <Accordion.Collapse eventKey="1">
                            <Card.Body style = {{border : "2px solid #3d3d3d"}}>
                            <div className="row">
                            <h1 className = "SpotLetSubHead"> This property is available for booking </h1>
                            <span className="SpotLetContent"> (You can select all 3 options, or choose at least one) </span>
                                    {tvError.hours && <span style={{color : 'red'}} className="SpotLetContent">Select any one of the below options</span>}
                                    {tvPricing24HrsFormat()}
                                    {tvPricing12HrsFormat()}
                                    {tvPricingPerHrsFormat()}
                                    <div className="col-lg-6">
                                        {/* <div className="mb-3 d-flex flex-column">
                                            <label htmlFor="tv-hourly-price" className="form-label SpotLetContent">
                                                Hourly price<span className="required">*</span>
                                            </label>
                                            <input
                                                // type="text"
                                                required
                                                // min={0}
                                                placeholder="Enter hourly price"
                                                id="tv-hourly-price"
                                                name="hourlyPrice"
                                                className="textFiled"
                                                value={formData?.tv?.hourlyPrice || ""}
                                                onChange={(e) => {
                                                    const regex= /^[1-9][0-9]*$/;
                                                    if (e.target.value === "" || regex.test(e.target.value)){
                                                        onChangeInput(e,'tv')
                                                    }
                                                }}
                                            />
                                            {tvError.hourlyPrice &&<span style={{color : 'red'}}>Enter hourly price </span>}
                                        </div> */}
                                    </div>
                                    <div className="col-lg-6"></div>
                                    <div className="col-lg-6">
                                        <div className="mb-3 d-flex flex-column">
                                            <label className="form-label SpotLetContent">
                                            Maximum attendees allowed<span className="required">*</span>
                                            </label>
                                            <div className="row">
                                                <div className="col-lg-12">
                                                    <div className="mb-2 d-flex flex-column">
                                                        <input
                                                            // type="text"
                                                            required
                                                            // min={0}
                                                            placeholder="No. of attendees"
                                                            name = "attendees"
                                                            className="textFiled SpotLetContent "
                                                            value={formData?.tv?.attendees || ""}
                                                            onChange={(e) => {
                                                                const regex= /^[1-9][0-9]*$/;
                                                                if (e.target.value === "" || regex.test(e.target.value)){
                                                                    onChangeInput(e,'tv');
                                                                        setTvError({
                                                                            ...tvError,
                                                                            attendees : false,
                                                                        });
                                                                }
                                                            }}
                                                        />
                                                    </div>
                                                </div>
                                                {/* <div className="col-lg-6">
                                                    <div className="mb-2 d-flex flex-column">
                                                        <input
                                                            // type="text"
                                                            required
                                                            // min={0}
                                                            placeholder="Max. attendees"
                                                            name="max"
                                                            className="textFiled"
                                                            value={formData?.tv?.max || ""}
                                                            onChange={(e) => {
                                                                const regex= /^[1-9][0-9]*$/;
                                                                if (e.target.value === "" || regex.test(e.target.value)){
                                                                    onChangeInput(e,'tv')
                                                                }
                                                            }}
                                                        />
                                                    </div>
                                                </div> */}
                                            </div>
                                            {tvError.attendees &&<span style={{color : 'red'}} className="SpotLetContent">{tvError?.message}</span>}
                                        </div>
                                    </div>
                                    <div className="col-lg-6"></div>
                                    {/* <div className="col-lg-6">
                                        <div className="mb-3 d-flex flex-column">
                                            <label htmlFor="tv-minimumBooking" className="form-label">
                                           Minimum booking Hrs<span className="required">*</span>
                                            </label>
                                            <select
                                                required
                                                id="tv-minimumBooking"
                                                name="minimumBooking"
                                                className="textFiledSelect"
                                                value={formData?.tv?.minimumBooking}
                                                onChange={(e) => {onChangeInput(e,'tv')}}
                                            >
                                                <option hidden selected value="">Select hours</option>
                                                <option value='1'>1 Hr</option>
                                                <option value='2'>2 Hrs</option>
                                                <option value='4'>4 Hrs</option>
                                                <option value='6'>6 Hrs</option>
                                                <option value='8'>8 Hrs</option>
                                                <option value='12'>12 Hrs</option>
                                                <option value='16'>16 Hrs</option>
                                                <option value='20'>20 Hrs</option>
                                                <option value='24'>24 Hrs</option>
                                            </select>
                                            {tvError.minBooking &&<span style={{color : 'red'}}>Select Min booking Hrs</span>}
                                        </div>
                                    </div> */}
                                    <div className="col-lg-12">
                                        <div className="mb-2 d-flex flex-column">
                                            <div className ="d-flex" >
                                                <label className="form-label SpotLetContent">
                                                    Timings available for booking<span className="required">*</span> 
                                                </label>
                                                <div className="mx-3">
                                                    <label htmlFor="tv-all-booking-hours mx-3"> Select all </label>
                                                    <input 
                                                        checked = {getAllCheckedStatus("tv")} 
                                                        name="all" 
                                                        id = "tv-all-booking-hours" 
                                                        onChange={(e) => {
                                                            selectAllTimings(e,'tv');
                                                            setTvError({
                                                                ...tvError,
                                                                timings : false,
                                                            })  
                                                        }} 
                                                        type="checkbox" 
                                                        className="form-check-input mx-2"
                                                    />
                                                </div>
                                                { formData?.film?.timings && 
                                                    <div className="mx-3" >
                                                        <label className="form-label SpotLetContent" > {"(Fetch from film)"} </label>
                                                        <input
                                                            type="checkbox"
                                                            className="form-check-input mx-2"
                                                            onChange={(e) => {
                                                                if (e?.target?.checked) {
                                                                    setFormData({
                                                                        ...formData,
                                                                        tv : {
                                                                            ...formData?.film,
                                                                            available : true,
                                                                            // minimumBooking : e.target.checked ? formData?.film?.minimumBooking : "",
                                                                            // timings : e.target.checked ? formData?.film?.timings : {}
                                                                        }
                                                                    });
                                                                    setTvError({
                                                                        ...tvError,
                                                                        timings : false,
                                                                    })  
                                                                } else {
                                                                    setFormData({
                                                                        ...formData,
                                                                        tv : {
                                                                            available : true,
                                                                        }
                                                                    })
                                                                }
                                                            } }
                                                        />
                                                    </div>
                                                }
                                            </div>
                                            <div className="row">
                                                {/*Monday start*/}
                                                <div className="mb-2 col-lg-12 row">
                                                    <div className="col-lg-2 col-2" style={{paddingRight : '0px'}}>
                                                        <div className="d-flex">
                                                            <label htmlFor="tv-timings-monday" className="form-label SpotLetContent">Mon</label>
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-2 col-5" style={{paddingRight : '0px'}}>
                                                        <div className="d-flex">
                                                            <input
                                                                type="checkbox"
                                                                className="form-check-input SpotLetContent"
                                                                id='tv-timings-monday-fullDay'
                                                                checked={formData?.tv?.timings?.monday?.fullDay === true}
                                                                onChange={(e) => { 
                                                                    onChange24HrsCheckBox(e, "tv", "monday");
                                                                    setTvError({
                                                                        ...tvError,
                                                                        timings : false,
                                                                    })  
                                                                }}
                                                            />
                                                            <label htmlFor="tv-timings-monday-fullDay" className="form-label SpotLetContent">Full day</label>
                                                        </div>
                                                    </div>
                                                    {
                                                         !(formData?.tv?.["24Hours"]?.available || formData?.tv?.["12Hours"]?.available ) && (
                                                            <div className="col-lg-2 col-5" style={{paddingRight : '0px',paddingLeft : '0px'}}>
                                                                <div className="d-flex">
                                                                    <input
                                                                        type="checkbox"
                                                                        className="form-check-input SpotLetContent"
                                                                        id='tv-timings-monday-setHours'
                                                                        checked={formData?.tv?.timings?.monday?.fullDay === false}
                                                                        onChange={(e) => {
                                                                            onChangeSetHrsCheckBox(e, "tv", "monday");
                                                                            setTvError({
                                                                                ...tvError,
                                                                                timings : false,
                                                                            }) 
                                                                        } }
                                                                    />
                                                                    <label htmlFor="tv-timings-monday-setHours" className="form-label SpotLetContent">Set hrs</label>
                                                                </div>
                                                            </div>
                                                         )
                                                    }
                                                    {!(formData?.tv?.["24Hours"]?.available || formData?.tv?.["12Hours"]?.available ) && formData?.tv?.timings?.monday?.fullDay === false && (
                                                        <>
                                                            <div className="col-lg-3 col-6 d-flex flex-column">
                                                                <select
                                                                    className="textFiledSelect SpotLetContent"
                                                                    value={formData?.tv?.timings?.monday?.timings?.open}
                                                                    onChange={(e) => {
                                                                        validateFilmTvTimings(e.target.value ,formData?.tv?.timings?.monday?.timings?.close, 'tv','monday')
                                                                    }}
                                                                >
                                                                    {timeOptions.map((item) => (
                                                                    <option value={item.value}>{item.label}</option>
                                                                    ))}
                                                                </select>
                                                            </div>
                                                            <div className="col-lg-3 col-6 d-flex flex-column">
                                                                <select
                                                                    className="textFiledSelect SpotLetContent"
                                                                    value={formData?.tv?.timings?.monday?.timings?.close}
                                                                    onChange={(e) => {
                                                                        validateFilmTvTimings(formData?.tv?.timings?.monday?.timings?.open, e.target.value, 'tv','monday')
                                                                    }}
                                                                >
                                                                    {timeOptions.map((item) => (
                                                                    <option value={item.value}>{item.label}</option>
                                                                    ))}
                                                                </select>
                                                            </div>
                                                        </>
                                                    )}
                                                 </div>
                                                 {/*Monday end*/}
                                                  {/*Tuesday start*/}
                                                  <div className="mb-2 col-lg-12 row">
                                                    <div className="col-lg-2 col-2" style={{paddingRight : '0px'}}>
                                                        <div className="d-flex">
                                                            <label htmlFor="tv-timings-tuesday" className="form-label SpotLetContent">Tue</label>
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-2 col-5" style={{paddingRight : '0px'}}>
                                                        <div className="d-flex">
                                                            <input
                                                                type="checkbox"
                                                                className="form-check-input"
                                                                id='tv-timings-tuesday-fullDay'
                                                                checked={formData?.tv?.timings?.tuesday?.fullDay === true}
                                                                onChange={(e) => { 
                                                                    onChange24HrsCheckBox(e, "tv", "tuesday");
                                                                    setTvError({
                                                                        ...tvError,
                                                                        timings : false,
                                                                    }) 
                                                                }}
                                                            />
                                                            <label htmlFor="tv-timings-tuesday-fullDay" className="form-label SpotLetContent">Full day</label>
                                                        </div>
                                                    </div>
                                                    {
                                                         !(formData?.tv?.["24Hours"]?.available || formData?.tv?.["12Hours"]?.available ) && (
                                                            <div className="col-lg-2 col-5" style={{paddingRight : '0px',paddingLeft : '0px'}}>
                                                                <div className="d-flex">
                                                                    <input
                                                                        type="checkbox"
                                                                        className="form-check-input SpotLetContent"
                                                                        id='tv-timings-tuesday-setHours'
                                                                        checked={formData?.tv?.timings?.tuesday?.fullDay === false}
                                                                        onChange={(e) => {
                                                                            onChangeSetHrsCheckBox(e, "tv", "tuesday");
                                                                            setTvError({
                                                                                ...tvError,
                                                                                timings : false,
                                                                            }) 
                                                                        } }
                                                                    />
                                                                    <label htmlFor="tv-timings-tuesday-setHours" className="form-label SpotLetContent">Set hrs</label>
                                                                </div>
                                                            </div>
                                                         )
                                                    }
                                                    {!(formData?.tv?.["24Hours"]?.available || formData?.tv?.["12Hours"]?.available ) && formData?.tv?.timings?.tuesday?.fullDay === false && (
                                                        <>
                                                            <div className="col-lg-3 col-6 d-flex flex-column">
                                                                <select
                                                                    className="textFiledSelect"
                                                                    value={formData?.tv?.timings?.tuesday?.timings?.open}
                                                                    onChange={(e) => {
                                                                        validateFilmTvTimings(  e.target.value ,formData?.tv?.timings?.tuesday?.timings?.close, 'tv','tuesday')
                                                                    }}
                                                                >
                                                                    {timeOptions.map((item) => (
                                                                    <option value={item.value}>{item.label}</option>
                                                                    ))}
                                                                </select>
                                                            </div>
                                                            <div className="col-lg-3 col-6 d-flex flex-column">
                                                                <select
                                                                    className="textFiledSelect"
                                                                    value={formData?.tv?.timings?.tuesday?.timings?.close}
                                                                    onChange={(e) => {
                                                                        validateFilmTvTimings(formData?.tv?.timings?.tuesday?.timings?.open, e.target.value, 'tv','tuesday')
                                                                    }}
                                                                >
                                                                    {timeOptions.map((item) => (
                                                                    <option value={item.value}>{item.label}</option>
                                                                    ))}
                                                                </select>
                                                            </div>
                                                        </>
                                                    )}
                                                 </div>
                                                 {/*Tuesday end*/}
                                                  {/*Wednesday start*/}
                                                  <div className="mb-2 col-lg-12 row">
                                                    <div className="col-lg-2 col-2" style={{paddingRight : '0px'}}>
                                                        <div className="d-flex">
                                                            <label htmlFor="tv-timings-wednesday" className="form-label">Wed</label>
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-2 col-5" style={{paddingRight : '0px'}}>
                                                        <div className="d-flex">
                                                            <input
                                                                type="checkbox"
                                                                className="form-check-input SpotLetContent"
                                                                id='tv-timings-wednesday-fullDay'
                                                                checked={formData?.tv?.timings?.wednesday?.fullDay === true}
                                                                onChange={(e) => { 
                                                                    onChange24HrsCheckBox(e, "tv", "wednesday");
                                                                    setTvError({
                                                                        ...tvError,
                                                                        timings : false,
                                                                    })  
                                                                }}
                                                            />
                                                            <label htmlFor="tv-timings-wednesday-fullDay" className="form-label SpotLetContent">Full day</label>
                                                        </div>
                                                    </div>
                                                    {
                                                         !(formData?.tv?.["24Hours"]?.available || formData?.tv?.["12Hours"]?.available ) && (
                                                            <div className="col-lg-2 col-5" style={{paddingRight : '0px',paddingLeft : '0px'}}>
                                                                <div className="d-flex">
                                                                    <input
                                                                        type="checkbox"
                                                                        className="form-check-input SpotLetContent"
                                                                        id='tv-timings-wednesday-setHours'
                                                                        checked={formData?.tv?.timings?.wednesday?.fullDay === false}
                                                                        onChange={(e) => {
                                                                            onChangeSetHrsCheckBox(e, "tv", "wednesday");
                                                                            setTvError({
                                                                                ...tvError,
                                                                                timings : false,
                                                                            }) 
                                                                        } }
                                                                    />
                                                                    <label htmlFor="tv-timings-wednesday-setHours" className="form-label SpotLetContent">Set hrs</label>
                                                                </div>
                                                            </div>
                                                         )
                                                    }
                                                    { !(formData?.tv?.["24Hours"]?.available || formData?.tv?.["12Hours"]?.available ) && formData?.tv?.timings?.wednesday?.fullDay === false && (
                                                        <>
                                                            <div className="col-lg-3 col-6 d-flex flex-column">
                                                                <select
                                                                    className="textFiledSelect SpotLetContent"
                                                                    value={formData?.tv?.timings?.wednesday?.timings?.open}
                                                                    onChange={(e) => {
                                                                        validateFilmTvTimings(  e.target.value ,formData?.tv?.timings?.wednesday?.timings?.close, 'tv','wednesday')
                                                                    }}
                                                                >
                                                                    {timeOptions.map((item) => (
                                                                    <option value={item.value}>{item.label}</option>
                                                                    ))}
                                                                </select>
                                                            </div>
                                                            <div className="col-lg-3 col-6 d-flex flex-column">
                                                                <select
                                                                    className="textFiledSelect SpotLetContent"
                                                                    value={formData?.tv?.timings?.wednesday?.timings?.close}
                                                                    onChange={(e) => {
                                                                        validateFilmTvTimings(formData?.tv?.timings?.wednesday?.timings?.open, e.target.value, 'tv','wednesday')
                                                                    }}
                                                                >
                                                                    {timeOptions.map((item) => (
                                                                    <option value={item.value}>{item.label}</option>
                                                                    ))}
                                                                </select>
                                                            </div>
                                                        </>
                                                    )}
                                                 </div>
                                                 {/*Wednesday end*/}
                                                  {/*Thursday start*/}
                                                  <div className="mb-2 col-lg-12 row">
                                                    <div className="col-lg-2 col-2" style={{paddingRight : '0px'}}>
                                                        <div className="d-flex">
                                                            <label htmlFor="tv-timings-thursday" className="form-label SpotLetContent">Thu</label>
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-2 col-5" style={{paddingRight : '0px'}}>
                                                        <div className="d-flex">
                                                            <input
                                                                type="checkbox"
                                                                className="form-check-input SpotLetContent"
                                                                id='tv-timings-thursday-fullDay'
                                                                checked={formData?.tv?.timings?.thursday?.fullDay === true}
                                                                onChange={(e) => { 
                                                                    onChange24HrsCheckBox(e, "tv", "thursday");
                                                                    setTvError({
                                                                        ...tvError,
                                                                        timings : false,
                                                                    });
                                                                }}
                                                            />
                                                            <label htmlFor="tv-timings-thursday-fullDay" className="form-label SpotLetContent">Full day</label>
                                                        </div>
                                                    </div>
                                                    {
                                                         !(formData?.tv?.["24Hours"]?.available || formData?.tv?.["12Hours"]?.available ) && (
                                                            <div className="col-lg-2 col-5" style={{paddingRight : '0px',paddingLeft : '0px'}}>
                                                                <div className="d-flex">
                                                                    <input
                                                                        type="checkbox"
                                                                        className="form-check-input SpotLetContent"
                                                                        id='tv-timings-thursday-setHours'
                                                                        checked={formData?.tv?.timings?.thursday?.fullDay === false}
                                                                        onChange={(e) => {
                                                                            onChangeSetHrsCheckBox(e, "tv", "thursday");
                                                                            setTvError({
                                                                                ...tvError,
                                                                                timings : false,
                                                                            });
                                                                        } }
                                                                    />
                                                                    <label htmlFor="tv-timings-thursday-setHours" className="form-label SpotLetContent">Set hrs</label>
                                                                </div>
                                                            </div>
                                                         )
                                                    }
                                                    { !(formData?.tv?.["24Hours"]?.available || formData?.tv?.["12Hours"]?.available ) && formData?.tv?.timings?.thursday?.fullDay === false && (
                                                        <>
                                                            <div className="col-lg-3 col-6 d-flex flex-column">
                                                                <select
                                                                    className="textFiledSelect SpotLetContent"
                                                                    value={formData?.tv?.timings?.thursday?.timings?.open}
                                                                    onChange={(e) => {
                                                                        validateFilmTvTimings(  e.target.value ,formData?.tv?.timings?.thursday?.timings?.close, 'tv','thursday')
                                                                    }}
                                                                >
                                                                    {timeOptions.map((item) => (
                                                                    <option value={item.value}>{item.label}</option>
                                                                    ))}
                                                                </select>
                                                            </div>
                                                            <div className="col-lg-3 col-6 d-flex flex-column">
                                                                <select
                                                                    className="textFiledSelect SpotLetContent"
                                                                    value={formData?.tv?.timings?.thursday?.timings?.close}
                                                                    onChange={(e) => {
                                                                        validateFilmTvTimings(formData?.tv?.timings?.thursday?.timings?.open, e.target.value, 'tv','thursday')
                                                                    }}
                                                                >
                                                                    {timeOptions.map((item) => (
                                                                    <option value={item.value}>{item.label}</option>
                                                                    ))}
                                                                </select>
                                                            </div>
                                                        </>
                                                    )}
                                                 </div>
                                                 {/*Thursday end*/}
                                                  {/*Friday start*/}
                                                  <div className="mb-2 col-lg-12 row">
                                                    <div className="col-lg-2 col-2" style={{paddingRight : '0px'}}>
                                                        <div className="d-flex">
                                                            <label htmlFor="tv-timings-friday" className="form-label SpotLetContent">Fri</label>
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-2 col-5" style={{paddingRight : '0px'}}>
                                                        <div className="d-flex">
                                                            <input
                                                                type="checkbox"
                                                                className="form-check-input SpotLetContent"
                                                                id='tv-timings-friday-fullDay'
                                                                checked={formData?.tv?.timings?.friday?.fullDay === true}
                                                                onChange={(e) => { 
                                                                    onChange24HrsCheckBox(e, "tv", "friday");
                                                                    setTvError({
                                                                        ...tvError,
                                                                        timings : false,
                                                                    }); 
                                                                }}
                                                            />
                                                            <label htmlFor="tv-timings-friday-fullDay" className="form-label SpotLetContent">Full day</label>
                                                        </div>
                                                    </div>
                                                    {
                                                         !(formData?.tv?.["24Hours"]?.available || formData?.tv?.["12Hours"]?.available ) && (
                                                            <div className="col-lg-2 col-5" style={{paddingRight : '0px',paddingLeft : '0px'}}>
                                                                <div className="d-flex">
                                                                    <input
                                                                        type="checkbox"
                                                                        className="form-check-input SpotLetContent"
                                                                        id='tv-timings-friday-setHours'
                                                                        checked={formData?.tv?.timings?.friday?.fullDay === false}
                                                                        onChange={(e) => {
                                                                            onChangeSetHrsCheckBox(e, "tv", "friday");
                                                                            setTvError({
                                                                                ...tvError,
                                                                                timings : false,
                                                                            }); 
                                                                        } }
                                                                    />
                                                                    <label htmlFor="tv-timings-friday-setHours" className="form-label SpotLetContent">Set hrs</label>
                                                                </div>
                                                            </div>
                                                         )
                                                    }
                                                    {!(formData?.tv?.["24Hours"]?.available || formData?.tv?.["12Hours"]?.available ) && formData?.tv?.timings?.friday?.fullDay === false && (
                                                        <>
                                                            <div className="col-lg-3 col-6 d-flex flex-column">
                                                                <select
                                                                    className="textFiledSelect SpotLetContent"
                                                                    value={formData?.tv?.timings?.friday?.timings?.open}
                                                                    onChange={(e) => {
                                                                        validateFilmTvTimings(  e.target.value ,formData?.tv?.timings?.friday?.timings?.close, 'tv','friday')
                                                                    }}
                                                                >
                                                                    {timeOptions.map((item) => (
                                                                    <option value={item.value}>{item.label}</option>
                                                                    ))}
                                                                </select>
                                                            </div>
                                                            <div className="col-lg-3 col-6 d-flex flex-column">
                                                                <select
                                                                    className="textFiledSelect SpotLetContent"
                                                                    value={formData?.tv?.timings?.friday?.timings?.close}
                                                                    onChange={(e) => {
                                                                        validateFilmTvTimings(formData?.tv?.timings?.friday?.timings?.open, e.target.value, 'tv','friday')
                                                                    }}
                                                                >
                                                                    {timeOptions.map((item) => (
                                                                    <option value={item.value}>{item.label}</option>
                                                                    ))}
                                                                </select>
                                                            </div>
                                                        </>
                                                    )}
                                                 </div>
                                                 {/*Friday end*/}
                                                  {/*Saturday start*/}
                                                  <div className="mb-2 col-lg-12 row">
                                                    <div className="col-lg-2 col-2" style={{paddingRight : '0px'}}>
                                                        <div className="d-flex">
                                                            <label htmlFor="tv-timings-saturday" className="form-label SpotLetContent">Sat</label>
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-2 col-5" style={{paddingRight : '0px'}}>
                                                        <div className="d-flex">
                                                            <input
                                                                type="checkbox"
                                                                className="form-check-input SpotLetContent"
                                                                id='tv-timings-saturday-fullDay'
                                                                checked={formData?.tv?.timings?.saturday?.fullDay === true}
                                                                onChange={(e) => { 
                                                                    onChange24HrsCheckBox(e, "tv", "saturday");
                                                                    setTvError({
                                                                        ...tvError,
                                                                        timings : false,
                                                                    }); 
                                                                }}
                                                            />
                                                            <label htmlFor="tv-timings-saturday-fullDay" className="form-label SpotLetContent">Full day</label>
                                                        </div>
                                                    </div>
                                                    {
                                                         !(formData?.tv?.["24Hours"]?.available || formData?.tv?.["12Hours"]?.available ) && (
                                                            <div className="col-lg-2 col-5" style={{paddingRight : '0px',paddingLeft : '0px'}}>
                                                                <div className="d-flex">
                                                                    <input
                                                                        type="checkbox"
                                                                        className="form-check-input SpotLetContent"
                                                                        id='tv-timings-saturday-setHours'
                                                                        checked={formData?.tv?.timings?.saturday?.fullDay === false}
                                                                        onChange={(e) => {
                                                                            onChangeSetHrsCheckBox(e, "tv", "saturday");
                                                                            setTvError({
                                                                                ...tvError,
                                                                                timings : false,
                                                                            }); 
                                                                        } }
                                                                    />
                                                                    <label htmlFor="tv-timings-saturday-setHours" className="form-label SpotLetContent">Set hrs</label>
                                                                </div>
                                                            </div>
                                                         )
                                                    }
                                                    {!(formData?.tv?.["24Hours"]?.available || formData?.tv?.["12Hours"]?.available ) && formData?.tv?.timings?.saturday?.fullDay === false && (
                                                        <>
                                                            <div className="col-lg-3 col-6 d-flex flex-column">
                                                                <select
                                                                    className="textFiledSelect SpotLetContent"
                                                                    value={formData?.tv?.timings?.saturday?.timings?.open}
                                                                    onChange={(e) => {
                                                                        validateFilmTvTimings(  e.target.value ,formData?.tv?.timings?.saturday?.timings?.close, 'tv','saturday')
                                                                    }}
                                                                >
                                                                    {timeOptions.map((item) => (
                                                                    <option value={item.value}>{item.label}</option>
                                                                    ))}
                                                                </select>
                                                            </div>
                                                            <div className="col-lg-3 col-6 d-flex flex-column">
                                                                <select
                                                                    className="textFiledSelect SpotLetContent"
                                                                    value={formData?.tv?.timings?.saturday?.timings?.close}
                                                                    onChange={(e) => {
                                                                        validateFilmTvTimings(formData?.tv?.timings?.saturday?.timings?.open, e.target.value, 'tv','saturday')
                                                                    }}
                                                                >
                                                                    {timeOptions.map((item) => (
                                                                    <option value={item.value}>{item.label}</option>
                                                                    ))}
                                                                </select>
                                                            </div>
                                                        </>
                                                    )}
                                                 </div>
                                                 {/*Saturday end*/}
                                                  {/*Sunday start*/}
                                                  <div className="mb-2 col-lg-12 row">
                                                    <div className="col-lg-2 col-2" style={{paddingRight : '0px'}}>
                                                        <div className="d-flex">
                                                            <label htmlFor="tv-timings-sunday" className="form-label SpotLetContent">Sun</label>
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-2 col-5" style={{paddingRight : '0px'}}>
                                                        <div className="d-flex">
                                                            <input
                                                                type="checkbox"
                                                                className="form-check-input SpotLetContent"
                                                                id='tv-timings-sunday-fullDay'
                                                                checked={formData?.tv?.timings?.sunday?.fullDay === true}
                                                                onChange={(e) => { 
                                                                    onChange24HrsCheckBox(e, "tv", "sunday");
                                                                    setTvError({
                                                                        ...tvError,
                                                                        timings : false,
                                                                    }); 
                                                                }}
                                                            />
                                                            <label htmlFor="tv-timings-sunday-fullDay" className="form-label SpotLetContent">Full day</label>
                                                        </div>
                                                    </div>
                                                    {
                                                         !(formData?.tv?.["24Hours"]?.available || formData?.tv?.["12Hours"]?.available ) && (
                                                            <div className="col-lg-2 col-5" style={{paddingRight : '0px',paddingLeft : '0px'}}>
                                                                <div className="d-flex">
                                                                    <input
                                                                        type="checkbox"
                                                                        className="form-check-input SpotLetContent"
                                                                        id='tv-timings-sunday-setHours'
                                                                        checked={formData?.tv?.timings?.sunday?.fullDay === false}
                                                                        onChange={(e) => {
                                                                            onChangeSetHrsCheckBox(e, "tv", "sunday");
                                                                            setTvError({
                                                                                ...tvError,
                                                                                timings : false,
                                                                            }); 
                                                                        } }
                                                                    />
                                                                    <label htmlFor="tv-timings-sunday-setHours" className="form-label SpotLetContent">Set hrs</label>
                                                                </div>
                                                            </div>
                                                         )
                                                    }
                                                    {!(formData?.tv?.["24Hours"]?.available || formData?.tv?.["12Hours"]?.available ) && formData?.tv?.timings?.sunday?.fullDay === false && (
                                                        <>
                                                            <div className="col-lg-3 col-6 d-flex flex-column">
                                                                <select
                                                                    className="textFiledSelect SpotLetContent"
                                                                    value={formData?.tv?.timings?.sunday?.timings?.open}
                                                                    onChange={(e) => {
                                                                        validateFilmTvTimings(  e.target.value ,formData?.tv?.timings?.sunday?.timings?.close, 'tv','sunday')
                                                                    }}
                                                                >
                                                                    {timeOptions.map((item) => (
                                                                    <option value={item.value}>{item.label}</option>
                                                                    ))}
                                                                </select>
                                                            </div>
                                                            <div className="col-lg-3 col-6 d-flex flex-column">
                                                                <select
                                                                    className="textFiledSelect SpotLetContent"
                                                                    value={formData?.tv?.timings?.sunday?.timings?.close}
                                                                    onChange={(e) => {
                                                                        validateFilmTvTimings(formData?.tv?.timings?.sunday?.timings?.open, e.target.value, 'tv','sunday')
                                                                    }}
                                                                >
                                                                    {timeOptions.map((item) => (
                                                                    <option value={item.value}>{item.label}</option>
                                                                    ))}
                                                                </select>
                                                            </div>
                                                        </>
                                                    )}
                                                 </div>
                                                 {/*Sunday end*/}
                                            </div>
                                            {tvError.timings &&<span style={{color : 'red'}} className="SpotLetContent">Select Timings</span>}
                                        </div>
                                    </div>
                                    <div className="col-lg-6">
                                        <div className="mb-2 d-flex flex-column">
                                            <label htmlFor="tv-cleaning" className="form-label SpotLetContent">
                                                Cleaning fee(Optional)
                                            </label>
                                            <input
                                                // type="text"
                                                // min={0}
                                                placeholder="Enter cleaning fee"
                                                id="tv-cleaning"
                                                name="cleaningFee"
                                                className="textFiled SpotLetContent"
                                                value={formData?.tv?.cleaningFee || ""}
                                                onChange={(e) => {
                                                    const regex= /^[1-9][0-9]*$/;
                                                    if (e.target.value === "" || regex.test(e.target.value)){
                                                        onChangeInput(e,'tv')
                                                    }
                                                }}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-lg-6"  >
                                        <div className="mb-2 d-flex flex-column">
                                            <label htmlFor="tv-security-deposit" className="form-label SpotLetContent">
                                                Security deposit(Optional)
                                            </label>
                                            <input
                                                // type="text"
                                                // min={0}
                                                placeholder="Enter security deposit"
                                                id="tv-security-deposit"
                                                name="securityDeposit"
                                                className="textFiled SpotLetContent"
                                                value={formData?.tv?.securityDeposit || ""}
                                                onChange={(e) => {
                                                    const regex= /^[1-9][0-9]*$/;
                                                    if (e.target.value === "" || regex.test(e.target.value)){
                                                        onChangeInput(e,'tv')
                                                    }
                                                }}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </Card.Body>
                        </Accordion.Collapse>
                    </Card>
        </Accordion>
    )
}

const eventAccordian = () => {
    return(
        <Accordion defaultActiveKey={data?.event?.available ? "2" : ''} id = "PricingEventId">
                    <Card className="pricingCard">
                        <Card.Header style={{backgroundColor : "#3d3d3d", color : "#ffffff"}} className="d-flex justify-content-between">
                            <h6 className="SpotLetSubHead">Are you interested in providing your property for an Event?<span className="required">*</span> </h6>
                            <Toggle eventKey="2" type='event'/>
                        </Card.Header>
                        <Accordion.Collapse eventKey="2">
                            <Card.Body style = {{border : "2px solid #3d3d3d"}}>
                                <div className="row">
                                <h1 className = "SpotLetSubHead"> This property is available for booking </h1>
                                <span className="SpotLetContent"> (You can select all 3 options, or choose at least one) </span>
                                    {eventError.hours && <span style={{color : 'red'}} className="SpotLetContent" >Select any one of the below options</span>}
                                    {eventVenuePricingFormat()}
                                    {eventPerPlateFormat()}
                                    {eventPerHrsFormat()}
                                    <div className="col-lg-6">
                                        {/* <div className="mb-3 d-flex flex-column">
                                            <label htmlFor="event-hourly-price" className="form-label SpotLetContent">
                                                Weekday hourly price(Mon to Thu)<span className="required">*</span>
                                            </label>
                                            <input
                                                // type="text"
                                                required
                                                // min={0}
                                                placeholder="Enter hourly price"
                                                id="event-hourly-price"
                                                name="hourlyPrice"
                                                className="textFiled SpotLetContent"
                                                value={formData?.event?.hourlyPrice || ""}
                                                onChange={(e) => {
                                                    const regex= /^[1-9][0-9]*$/;
                                                    if (e.target.value === "" || regex.test(e.target.value)){
                                                        onChangeInput(e,'event')
                                                    }
                                                }}
                                            />
                                            {eventError.hourlyPrice &&<span style={{color : 'red'}} className="SpotLetContent">Enter Weekday hourly price</span>}
                                        </div> */}
                                    </div>
                                    <div className="col-lg-6"></div>
                                    <div className="col-lg-6">
                                        {/* <div className="mb-3 d-flex flex-column">
                                            <label htmlFor="event-weekend-price" className="form-label SpotLetContent">
                                            Weekend hourly price(Fri to Sun)<span className="required">*</span>
                                            </label>
                                            <input
                                                // type="text"
                                                required
                                                // min={0}
                                                placeholder="Enter weekend price"
                                                id="event-weekend-price"
                                                name="weekendPrice"
                                                className="textFiled SpotLetContent"
                                                value={formData?.event?.weekendPrice || ""}
                                                onChange={(e) => {
                                                    const regex= /^[1-9][0-9]*$/;
                                                    if (e.target.value === "" || regex.test(e.target.value)){
                                                        onChangeInput(e,'event')
                                                    }
                                                }}
                                            />
                                            {eventError.weekendPrice &&<span style={{color : 'red'}} className="SpotLetContent">Enter Weekend hourly price</span>}
                                        </div> */}
                                    </div>
                                    <div className="col-lg-6"></div>
                                    <div className="col-lg-6">
                                        {/* <div className="mb-3 d-flex flex-column">
                                            <label className="form-label SpotLetContent">
                                                No of attendees allowed<span className="required">*</span>
                                            </label>
                                            <div className="row">
                                                <div className="col-lg-12">
                                                    <div className="mb-2 d-flex flex-column">
                                                        <input
                                                            // type="text"
                                                            required
                                                            // min={0}
                                                            placeholder="No. of attendees"
                                                            name = "attendees"
                                                            className="textFiled SpotLetContent"
                                                            value={formData?.event?.attendees || ""}
                                                            onChange={(e) => {
                                                                const regex= /^[1-9][0-9]*$/;
                                                                if (e.target.value === "" || regex.test(e.target.value)){
                                                                    onChangeInput(e,'event')
                                                                }
                                                            }}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-lg-6">
                                                    <div className="mb-2 d-flex flex-column">
                                                        <input
                                                            // type="text"
                                                            required
                                                            // min={0}
                                                            placeholder="Max. attendees"
                                                            name="max"
                                                            className="textFiled SpotLetContent"
                                                            value={formData?.event?.max || ""}
                                                            onChange={(e) => {
                                                                const regex= /^[1-9][0-9]*$/;
                                                                if (e.target.value === "" || regex.test(e.target.value)) {
                                                                    onChangeInput(e,'event')
                                                                }
                                                            }}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            {eventError.attendees &&<span style={{color : 'red'}} className="SpotLetContent">{eventError?.message}</span>}
                                        </div> */}
                                    </div>
                                    <div className="col-lg-6"></div>
                                    <div className="col-lg-6">
                                        {/* <div className="mb-3 d-flex flex-column">
                                            <label htmlFor="event-minimumBooking" className="form-label SpotLetContent">
                                           Minimum booking Hrs<span className="required">*</span>
                                            </label>
                                            <select
                                                required
                                                id="event-minimumBooking"
                                                name="minimumBooking"
                                                className="textFiledSelect SpotLetContent"
                                                value={formData?.event?.minimumBooking}
                                                onChange={(e) => {onChangeInput(e,'event')}}
                                            >
                                                <option hidden selected value="">Select hours</option>
                                                <option value='1'>1 Hr</option>
                                                <option value='2'>2 Hrs</option>
                                                <option value='4'>4 Hrs</option>
                                                <option value='6'>6 Hrs</option>
                                                <option value='8'>8 Hrs</option>
                                                <option value='12'>12 Hrs</option>
                                                <option value='16'>16 Hrs</option>
                                                <option value='20'>20 Hrs</option>
                                                <option value='24'>24 Hrs</option>
                                            </select>
                                            {eventError.minBooking &&<span style={{color : 'red'}} className="SpotLetContent">Select Min booking Hrs</span>}
                                        </div> */}
                                    </div>
                                    <div className="col-lg-12">
                                        <div className="mb-2 d-flex flex-column">
                                            <div className="d-flex" >
                                                <label className="form-label SpotLetContent">
                                                    Timings available for booking<span className="required">*</span>
                                                </label>
                                                <div className="mx-3">
                                                    <label htmlFor="event-all-booking-hours mx-3" className="SpotLetContent" > Select all </label>
                                                    <input 
                                                        checked = {getAllCheckedStatus("event")} 
                                                        name="all" 
                                                        id = "event-all-booking-hours" 
                                                        onChange={(e) => {
                                                            selectAllTimings(e,'event');
                                                            setEventError({
                                                                ...eventError,
                                                                timings : false,
                                                            }) 
                                                        }} 
                                                        type="checkbox" 
                                                        className="form-check-input mx-2"
                                                    />
                                                </div>
                                                {/* { formData?.tv?.timings  && 
                                                    <div className="mx-3" >
                                                        <label className="form-label SpotLetContent" > {"(Fetch from tv)"} </label>
                                                        <input
                                                            type="checkbox"
                                                            className="form-check-input SpotLetContent mx-2"
                                                            onChange={(e) => {
                                                                setFormData({
                                                                    ...formData,
                                                                    event : {
                                                                        ...formData?.event,
                                                                        minimumBooking : e.target.checked ? formData?.tv?.minimumBooking : "",
                                                                        timings : e.target.checked ? formData?.tv?.timings : {}
                                                                    }
                                                                })
                                                            } }
                                                        />
                                                    </div>
                                                } */}
                                            </div>
                                            <div className="row">
                                                {/*Monday start*/}
                                                <div className="mb-2 col-lg-12 row">
                                                    <div className="col-lg-2 col-2" style={{paddingRight : '0px'}}>
                                                        <div className="d-flex">
                                                            <label htmlFor="event-timings-monday" className="form-label SpotLetContent">Mon</label>
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-2 col-5" style={{paddingRight : '0px'}}>
                                                        <div className="d-flex">
                                                            <input
                                                                type="checkbox"
                                                                className="form-check-input SpotLetContent"
                                                                id='event-timings-monday-fullDay'
                                                                checked={formData?.event?.timings?.monday?.fullDay === true}
                                                                onChange={(e) => { 
                                                                    onChange24HrsCheckBox(e, "event", "monday");
                                                                    setEventError({
                                                                        ...eventError,
                                                                        timings : false,
                                                                    }) 
                                                                 }}
                                                            />
                                                            <label htmlFor="event-timings-monday-fullDay" className="form-label SpotLetContent">Full day</label>
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-2 col-5" style={{paddingRight : '0px',paddingLeft : '0px'}}>
                                                        <div className="d-flex">
                                                            <input
                                                                type="checkbox"
                                                                className="form-check-input SpotLetContent"
                                                                id='event-timings-monday-setHours'
                                                                checked={formData?.event?.timings?.monday?.fullDay === false}
                                                                onChange={(e) => {
                                                                    onChangeSetHrsCheckBox(e, "event", "monday");
                                                                    setEventError({
                                                                        ...eventError,
                                                                        timings : false,
                                                                    }); 
                                                                } }
                                                            />
                                                            <label htmlFor="event-timings-monday-setHours" className="form-label SpotLetContent">Set hrs</label>
                                                        </div>
                                                    </div>
                                                    {formData?.event?.timings?.monday?.fullDay === false && (
                                                        <>
                                                            <div className="col-lg-3 col-6 d-flex flex-column">
                                                                <select
                                                                    className="textFiledSelect SpotLetContent"
                                                                    value={formData?.event?.timings?.monday?.timings?.open}
                                                                    onChange={(e) => {
                                                                        validateTimings(  e.target.value ,formData?.event?.timings?.monday?.timings?.close, 'event', 'monday')
                                                                    }}
                                                                >
                                                                    {timeOptions.map((item) => (
                                                                    <option value={item.value}>{item.label}</option>
                                                                    ))}
                                                                </select>
                                                            </div>
                                                            <div className="col-lg-3 col-6 d-flex flex-column">
                                                                <select
                                                                    className="textFiledSelect SpotLetContent"
                                                                    value={formData?.event?.timings?.monday?.timings?.close}
                                                                    onChange={(e) => {
                                                                        validateTimings(formData?.event?.timings?.monday?.timings?.open, e.target.value, 'event','monday')
                                                                    }}
                                                                >
                                                                    {timeOptions.map((item) => (
                                                                    <option value={item.value}>{item.label}</option>
                                                                    ))}
                                                                </select>
                                                            </div>
                                                        </>
                                                    )}
                                                 </div>
                                                 {/*Monday end*/}
                                                  {/*Tuesday start*/}
                                                  <div className="mb-2 col-lg-12 row">
                                                    <div className="col-lg-2 col-2" style={{paddingRight : '0px'}}>
                                                        <div className="d-flex">
                                                            <label htmlFor="event-timings-tuesday" className="form-label SpotLetContent">Tue</label>
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-2 col-5" style={{paddingRight : '0px'}}>
                                                        <div className="d-flex">
                                                            <input
                                                                type="checkbox"
                                                                className="form-check-input SpotLetContent"
                                                                id='event-timings-tuesday-fullDay'
                                                                checked={formData?.event?.timings?.tuesday?.fullDay === true}
                                                                onChange={(e) => { 
                                                                    onChange24HrsCheckBox(e, "event", "tuesday");
                                                                    setEventError({
                                                                        ...eventError,
                                                                        timings : false,
                                                                    }); 
                                                                 }}
                                                            />
                                                            <label htmlFor="event-timings-tuesday-fullDay" className="form-label SpotLetContent">Full day</label>
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-2 col-5" style={{paddingRight : '0px',paddingLeft : '0px'}}>
                                                        <div className="d-flex">
                                                            <input
                                                                type="checkbox"
                                                                className="form-check-input SpotLetContent"
                                                                id='event-timings-tuesday-setHours'
                                                                checked={formData?.event?.timings?.tuesday?.fullDay === false}
                                                                onChange={(e) => {
                                                                    onChangeSetHrsCheckBox(e, "event", "tuesday");
                                                                    setEventError({
                                                                        ...eventError,
                                                                        timings : false,
                                                                    });
                                                                } }
                                                            />
                                                            <label htmlFor="event-timings-tuesday-setHours" className="form-label SpotLetContent">Set hrs</label>
                                                        </div>
                                                    </div>
                                                    {formData?.event?.timings?.tuesday?.fullDay === false && (
                                                        <>
                                                            <div className="col-lg-3 col-6 d-flex flex-column">
                                                                <select
                                                                    className="textFiledSelect SpotLetContent"
                                                                    value={formData?.event?.timings?.tuesday?.timings?.open}
                                                                    onChange={(e) => {
                                                                        validateTimings(  e.target.value ,formData?.event?.timings?.tuesday?.timings?.close, 'event','tuesday')
                                                                    }}
                                                                >
                                                                    {timeOptions.map((item) => (
                                                                    <option value={item.value}>{item.label}</option>
                                                                    ))}
                                                                </select>
                                                            </div>
                                                            <div className="col-lg-3 col-6 d-flex flex-column">
                                                                <select
                                                                    className="textFiledSelect SpotLetContent"
                                                                    value={formData?.event?.timings?.tuesday?.timings?.close}
                                                                    onChange={(e) => {
                                                                        validateTimings(formData?.event?.timings?.tuesday?.timings?.open, e.target.value, 'event','tuesday')
                                                                    }}
                                                                >
                                                                    {timeOptions.map((item) => (
                                                                    <option value={item.value}>{item.label}</option>
                                                                    ))}
                                                                </select>
                                                            </div>
                                                        </>
                                                    )}
                                                 </div>
                                                 {/*Tuesday end*/}
                                                  {/*Wednesday start*/}
                                                  <div className="mb-2 col-lg-12 row">
                                                    <div className="col-lg-2 col-2" style={{paddingRight : '0px'}}>
                                                        <div className="d-flex">
                                                            <label htmlFor="event-timings-wednesday" className="form-label SpotLetContent">Wed</label>
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-2 col-5" style={{paddingRight : '0px'}}>
                                                        <div className="d-flex">
                                                            <input
                                                                type="checkbox"
                                                                className="form-check-input SpotLetContent"
                                                                id='event-timings-wednesday-fullDay'
                                                                checked={formData?.event?.timings?.wednesday?.fullDay === true}
                                                                onChange={(e) => { 
                                                                    onChange24HrsCheckBox(e, "event", "wednesday");
                                                                    setEventError({
                                                                        ...eventError,
                                                                        timings : false,
                                                                    }); 
                                                                 }}
                                                            />
                                                            <label htmlFor="event-timings-wednesday-fullDay" className="form-label SpotLetContent">Full day</label>
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-2 col-5" style={{paddingRight : '0px',paddingLeft : '0px'}}>
                                                        <div className="d-flex">
                                                            <input
                                                                type="checkbox"
                                                                className="form-check-input SpotLetContent"
                                                                id='event-timings-wednesday-setHours'
                                                                checked={formData?.event?.timings?.wednesday?.fullDay === false}
                                                                onChange={(e) => {
                                                                    onChangeSetHrsCheckBox(e, "event", "wednesday");
                                                                    setEventError({
                                                                        ...eventError,
                                                                        timings : false,
                                                                    });
                                                                } }
                                                            />
                                                            <label htmlFor="event-timings-wednesday-setHours" className="form-label SpotLetContent">Set hrs</label>
                                                        </div>
                                                    </div>
                                                    {formData?.event?.timings?.wednesday?.fullDay === false && (
                                                        <>
                                                            <div className="col-lg-3 col-6 d-flex flex-column">
                                                                <select
                                                                    className="textFiledSelect SpotLetContent"
                                                                    value={formData?.event?.timings?.wednesday?.timings?.open}
                                                                    onChange={(e) => {
                                                                        validateTimings(  e.target.value ,formData?.event?.timings?.wednesday?.timings?.close, 'event','wednesday')
                                                                    }}
                                                                >
                                                                    {timeOptions.map((item) => (
                                                                    <option value={item.value}>{item.label}</option>
                                                                    ))}
                                                                </select>
                                                            </div>
                                                            <div className="col-lg-3 col-6 d-flex flex-column">
                                                                <select
                                                                    className="textFiledSelect SpotLetContent"
                                                                    value={formData?.event?.timings?.wednesday?.timings?.close}
                                                                    onChange={(e) => {
                                                                        validateTimings(formData?.event?.timings?.wednesday?.timings?.open, e.target.value, 'event','wednesday')
                                                                    }}
                                                                >
                                                                    {timeOptions.map((item) => (
                                                                    <option value={item.value}>{item.label}</option>
                                                                    ))}
                                                                </select>
                                                            </div>
                                                        </>
                                                    )}
                                                 </div>
                                                 {/*Wednesday end*/}
                                                  {/*Thursday start*/}
                                                  <div className="mb-2 col-lg-12 row">
                                                    <div className="col-lg-2 col-2" style={{paddingRight : '0px'}}>
                                                        <div className="d-flex">
                                                            <label htmlFor="event-timings-thursday" className="form-label SpotLetContent">Thu</label>
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-2 col-5" style={{paddingRight : '0px'}}>
                                                        <div className="d-flex">
                                                            <input
                                                                type="checkbox"
                                                                className="form-check-input SpotLetContent"
                                                                id='event-timings-thursday-fullDay'
                                                                checked={formData?.event?.timings?.thursday?.fullDay === true}
                                                                onChange={(e) => { 
                                                                    onChange24HrsCheckBox(e, "event", "thursday");
                                                                    setEventError({
                                                                        ...eventError,
                                                                        timings : false,
                                                                    });  
                                                                }}
                                                            />
                                                            <label htmlFor="event-timings-thursday-fullDay" className="form-label SpotLetContent">Full day</label>
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-2 col-5" style={{paddingRight : '0px',paddingLeft : '0px'}}>
                                                        <div className="d-flex">
                                                            <input
                                                                type="checkbox"
                                                                className="form-check-input SpotLetContent"
                                                                id='event-timings-thursday-setHours'
                                                                checked={formData?.event?.timings?.thursday?.fullDay === false}
                                                                onChange={(e) => {
                                                                    onChangeSetHrsCheckBox(e, "event", "thursday");
                                                                    setEventError({
                                                                        ...eventError,
                                                                        timings : false,
                                                                    });
                                                                } }
                                                            />
                                                            <label htmlFor="event-timings-thursday-setHours" className="form-label SpotLetContent">Set hrs</label>
                                                        </div>
                                                    </div>
                                                    {formData?.event?.timings?.thursday?.fullDay === false && (
                                                        <>
                                                            <div className="col-lg-3 col-6 d-flex flex-column">
                                                                <select
                                                                    className="textFiledSelect SpotLetContent"
                                                                    value={formData?.event?.timings?.thursday?.timings?.open}
                                                                    onChange={(e) => {
                                                                        validateTimings(  e.target.value ,formData?.event?.timings?.thursday?.timings?.close, 'event','thursday')
                                                                    }}
                                                                >
                                                                    {timeOptions.map((item) => (
                                                                    <option value={item.value}>{item.label}</option>
                                                                    ))}
                                                                </select>
                                                            </div>
                                                            <div className="col-lg-3 col-6 d-flex flex-column">
                                                                <select
                                                                    className="textFiledSelect SpotLetContent"
                                                                    value={formData?.event?.timings?.thursday?.timings?.close}
                                                                    onChange={(e) => {
                                                                        validateTimings(formData?.event?.timings?.thursday?.timings?.open, e.target.value, 'event','thursday')
                                                                    }}
                                                                >
                                                                    {timeOptions.map((item) => (
                                                                    <option value={item.value}>{item.label}</option>
                                                                    ))}
                                                                </select>
                                                            </div>
                                                        </>
                                                    )}
                                                 </div>
                                                 {/*Thursday end*/}
                                                  {/*Friday start*/}
                                                  <div className="mb-2 col-lg-12 row">
                                                    <div className="col-lg-2 col-2" style={{paddingRight : '0px'}}>
                                                        <div className="d-flex">
                                                            <label htmlFor="event-timings-friday" className="form-label SpotLetContent">Fri</label>
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-2 col-5" style={{paddingRight : '0px'}}>
                                                        <div className="d-flex">
                                                            <input
                                                                type="checkbox"
                                                                className="form-check-input SpotLetContent"
                                                                id='event-timings-friday-fullDay'
                                                                checked={formData?.event?.timings?.friday?.fullDay === true}
                                                                onChange={(e) => { 
                                                                    onChange24HrsCheckBox(e, "event", "friday");
                                                                    setEventError({
                                                                        ...eventError,
                                                                        timings : false,
                                                                    }); 
                                                                 }}
                                                            />
                                                            <label htmlFor="event-timings-friday-fullDay" className="form-label SpotLetContent">Full day</label>
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-2 col-5" style={{paddingRight : '0px',paddingLeft : '0px'}}>
                                                        <div className="d-flex">
                                                            <input
                                                                type="checkbox"
                                                                className="form-check-input SpotLetContent"
                                                                id='event-timings-friday-setHours'
                                                                checked={formData?.event?.timings?.friday?.fullDay === false}
                                                                onChange={(e) => {
                                                                    onChangeSetHrsCheckBox(e, "event", "friday");
                                                                    setEventError({
                                                                        ...eventError,
                                                                        timings : false,
                                                                    });
                                                                } }
                                                            />
                                                            <label htmlFor="event-timings-friday-setHours" className="form-label SpotLetContent">Set hrs</label>
                                                        </div>
                                                    </div>
                                                    {formData?.event?.timings?.friday?.fullDay === false && (
                                                        <>
                                                            <div className="col-lg-3 col-6 d-flex flex-column">
                                                                <select
                                                                    className="textFiledSelect SpotLetContent"
                                                                    value={formData?.event?.timings?.friday?.timings?.open}
                                                                    onChange={(e) => {
                                                                        validateTimings(  e.target.value ,formData?.event?.timings?.friday?.timings?.close, 'event','friday')
                                                                    }}
                                                                >
                                                                    {timeOptions.map((item) => (
                                                                    <option value={item.value}>{item.label}</option>
                                                                    ))}
                                                                </select>
                                                            </div>
                                                            <div className="col-lg-3 col-6 d-flex flex-column">
                                                                <select
                                                                    className="textFiledSelect SpotLetContent"
                                                                    value={formData?.event?.timings?.friday?.timings?.close}
                                                                    onChange={(e) => {
                                                                        validateTimings(formData?.event?.timings?.friday?.timings?.open, e.target.value, 'event','friday')
                                                                    }}
                                                                >
                                                                    {timeOptions.map((item) => (
                                                                    <option value={item.value}>{item.label}</option>
                                                                    ))}
                                                                </select>
                                                            </div>
                                                        </>
                                                    )}
                                                 </div>
                                                 {/*Friday end*/}
                                                  {/*Saturday start*/}
                                                  <div className="mb-2 col-lg-12 row">
                                                    <div className="col-lg-2 col-2" style={{paddingRight : '0px'}}>
                                                        <div className="d-flex">
                                                            <label htmlFor="event-timings-saturday" className="form-label SpotLetContent">Sat</label>
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-2 col-5" style={{paddingRight : '0px'}}>
                                                        <div className="d-flex">
                                                            <input
                                                                type="checkbox"
                                                                className="form-check-input SpotLetContent"
                                                                id='event-timings-saturday-fullDay'
                                                                checked={formData?.event?.timings?.saturday?.fullDay === true}
                                                                onChange={(e) => { 
                                                                    onChange24HrsCheckBox(e, "event", "saturday");
                                                                    setEventError({
                                                                        ...eventError,
                                                                        timings : false,
                                                                    }); 
                                                                 }}
                                                            />
                                                            <label htmlFor="event-timings-saturday-fullDay" className="form-label SpotLetContent">Full day</label>
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-2 col-5" style={{paddingRight : '0px',paddingLeft : '0px'}}>
                                                        <div className="d-flex">
                                                            <input
                                                                type="checkbox"
                                                                className="form-check-input SpotLetContent"
                                                                id='event-timings-saturday-setHours'
                                                                checked={formData?.event?.timings?.saturday?.fullDay === false}
                                                                onChange={(e) => {
                                                                    onChangeSetHrsCheckBox(e, "event", "saturday");
                                                                    setEventError({
                                                                        ...eventError,
                                                                        timings : false,
                                                                    });
                                                                } }
                                                            />
                                                            <label htmlFor="event-timings-saturday-setHours" className="form-label SpotLetContent">Set hrs</label>
                                                        </div>
                                                    </div>
                                                    {formData?.event?.timings?.saturday?.fullDay === false && (
                                                        <>
                                                            <div className="col-lg-3 col-6 d-flex flex-column">
                                                                <select
                                                                    className="textFiledSelect SpotLetContent"
                                                                    value={formData?.event?.timings?.saturday?.timings?.open}
                                                                    onChange={(e) => {
                                                                        validateTimings(  e.target.value ,formData?.event?.timings?.saturday?.timings?.close, 'event','saturday')
                                                                    }}
                                                                >
                                                                    {timeOptions.map((item) => (
                                                                    <option value={item.value}>{item.label}</option>
                                                                    ))}
                                                                </select>
                                                            </div>
                                                            <div className="col-lg-3 col-6 d-flex flex-column">
                                                                <select
                                                                    className="textFiledSelect SpotLetContent"
                                                                    value={formData?.event?.timings?.saturday?.timings?.close}
                                                                    onChange={(e) => {
                                                                        validateTimings(formData?.event?.timings?.saturday?.timings?.open, e.target.value, 'event','saturday')
                                                                    }}
                                                                >
                                                                    {timeOptions.map((item) => (
                                                                    <option value={item.value}>{item.label}</option>
                                                                    ))}
                                                                </select>
                                                            </div>
                                                        </>
                                                    )}
                                                 </div>
                                                 {/*Saturday end*/}
                                                  {/*Sunday start*/}
                                                  <div className="mb-2 col-lg-12 row">
                                                    <div className="col-lg-2 col-2" style={{paddingRight : '0px'}}>
                                                        <div className="d-flex">
                                                            <label htmlFor="event-timings-sunday" className="form-label SpotLetContent">Sun</label>
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-2 col-5" style={{paddingRight : '0px'}}>
                                                        <div className="d-flex">
                                                            <input
                                                                type="checkbox"
                                                                className="form-check-input SpotLetContent"
                                                                id='event-timings-sunday-fullDay'
                                                                checked={formData?.event?.timings?.sunday?.fullDay === true}
                                                                onChange={(e) => { 
                                                                    onChange24HrsCheckBox(e, "event", "sunday");
                                                                    setEventError({
                                                                        ...eventError,
                                                                        timings : false,
                                                                    }); 
                                                                 }}
                                                            />
                                                            <label htmlFor="event-timings-sunday-fullDay" className="form-label SpotLetContent">Full day</label>
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-2 col-5" style={{paddingRight : '0px',paddingLeft : '0px'}}>
                                                        <div className="d-flex">
                                                            <input
                                                                type="checkbox"
                                                                className="form-check-input SpotLetContent"
                                                                id='event-timings-sunday-setHours'
                                                                checked={formData?.event?.timings?.sunday?.fullDay === false}
                                                                onChange={(e) => {
                                                                    onChangeSetHrsCheckBox(e, "event", "sunday");
                                                                    setEventError({
                                                                        ...eventError,
                                                                        timings : false,
                                                                    });
                                                                } }
                                                            />
                                                            <label htmlFor="event-timings-sunday-setHours" className="form-label SpotLetContent">Set hrs</label>
                                                        </div>
                                                    </div>
                                                    {formData?.event?.timings?.sunday?.fullDay === false && (
                                                        <>
                                                            <div className="col-lg-3 col-6 d-flex flex-column">
                                                                <select
                                                                    className="textFiledSelect SpotLetContent"
                                                                    value={formData?.event?.timings?.sunday?.timings?.open}
                                                                    onChange={(e) => {
                                                                        validateTimings(  e.target.value ,formData?.event?.timings?.sunday?.timings?.close, 'event','sunday')
                                                                    }}
                                                                >
                                                                    {timeOptions.map((item) => (
                                                                    <option value={item.value}>{item.label}</option>
                                                                    ))}
                                                                </select>
                                                            </div>
                                                            <div className="col-lg-3 col-6 d-flex flex-column">
                                                                <select
                                                                    className="textFiledSelect SpotLetContent"
                                                                    value={formData?.event?.timings?.sunday?.timings?.close}
                                                                    onChange={(e) => {
                                                                        validateTimings(formData?.event?.timings?.sunday?.timings?.open, e.target.value, 'event','sunday')
                                                                    }}
                                                                >
                                                                    {timeOptions.map((item) => (
                                                                    <option value={item.value}>{item.label}</option>
                                                                    ))}
                                                                </select>
                                                            </div>
                                                        </>
                                                    )}
                                                 </div>
                                                 {/*Sunday end*/}
                                            </div>
                                            {eventError.timings &&<span style={{color : 'red'}} className="SpotLetContent">Select Timings</span>}
                                        </div>
                                    </div>
                                    <div className="col-lg-6">
                                        <div className="mb-2 d-flex flex-column">
                                            <label htmlFor="event-cleaning" className="form-label SpotLetContent">
                                                Cleaning fee(Optional)
                                            </label>
                                            <input
                                                // type="text"
                                                // min={0}
                                                placeholder="Enter cleaning fee"
                                                id="event-cleaning"
                                                name="cleaningFee"
                                                className="textFiled"
                                                value={formData?.event?.cleaningFee || ""}
                                                onChange={(e) => {
                                                    const regex= /^[1-9][0-9]*$/;
                                                    if (e.target.value === "" || regex.test(e.target.value)){
                                                        onChangeInput(e,'event')
                                                    }
                                                }}
                                            />
                                        </div>
                                    </div>
                                    {/* <div className="col-lg-6"></div> */}
                                    <div className="col-lg-6">
                                        <div className="mb-2 d-flex flex-column">
                                            <label htmlFor="event-security-deposit" className="form-label SpotLetContent">
                                                Security deposit(Optional)
                                            </label>
                                            <input
                                                // type="text"
                                                // min={0}
                                                placeholder="Enter security deposit"
                                                id="event-security-deposit"
                                                name="securityDeposit"
                                                className="textFiled"
                                                value={formData?.event?.securityDeposit || "" }
                                                onChange={(e) => {
                                                    const regex= /^[1-9][0-9]*$/;
                                                    if (e.target.value === "" || regex.test(e.target.value)){
                                                        onChangeInput(e,'event')
                                                    }
                                                }}
                                            />
                                        </div>
                                    </div>
                                    {/* <div className="col-lg-6"></div> */}
                                    <div className="col-lg-6 mt-2">
                                        {/* <div className="mb-2 d-flex justify-content-between">
                                            <label className="form-label SpotLetContent">
                                            Catering service available
                                            </label>
                                            <div className="d-flex">
                                                <div className="d-flex">
                                                    <input
                                                        type="checkbox"
                                                        className="form-check-input SpotLetContent"
                                                        name='catering'
                                                        id='event-catering-yes'
                                                        checked={formData?.event?.catering}
                                                        onChange={() => {
                                                            setFormData({
                                                                ...formData,
                                                                event : {
                                                                    ...formData.event,
                                                                    catering : true,
                                                                    buffets :[ {
                                                                        name : '',
                                                                        veg : '',
                                                                        nonVeg : '',
                                                                    }]
                                                                }
                                                            })
                                                        }}
                                                    />
                                                    <label htmlFor="event-catering-yes" className="me-2">Yes</label>
                                                </div>
                                                <div className="d-flex">
                                                    <input
                                                        type="checkbox"
                                                        className="form-check-input SpotLetContent"
                                                        name='catering'
                                                        id='event-catering-no'
                                                        checked={!formData?.event?.catering}
                                                        onChange={() => {
                                                            setFormData({
                                                                ...formData,
                                                                event : {
                                                                    ...formData.event,
                                                                    catering : false,
                                                                    buffets : [],
                                                                }
                                                            })
                                                        }}
                                                    />
                                                    <label htmlFor='event-catering-no'>No</label>
                                                </div>
                                            </div>
                                        </div> */}
                                    </div>
                                    <div className="col-lg-7"></div>
                                    {/* {formData?.event?.catering &&(
                                        <>
                                            <div className="col-lg-6">
                                                <div className="row">
                                                    <label className="col-4 form-label SpotLetContent" ></label>
                                                    <label className="col-4 form-label SpotLetContent text-center"  >Veg <br/> (Per plate) </label>
                                                    <label className="col-4 form-label SpotLetContent  text-center" >Non veg <br/> (Per plate)  </label>
                                                </div>
                                            </div>
                                            <div className="col-lg-6"></div>
                                        </>
                                    )} */}
                                    {/* {formData?.event?.catering && formData?.event?.buffets.map((buffet,i) => {
                                        return(
                                            <>
                                                <form className="col-lg-7">
                                                    <div className="row">
                                                        <div className="col-4 mb-2">
                                                        <input
                                                            placeholder='Buffet name'
                                                            className="textFiled w-100 pricingTimingsBuffetText"
                                                            style={{paddingLeft : '0px',paddingRight : '0px'}}
                                                            value={buffet?.name || ""}
                                                            onChange={(e) => {
                                                                const temp = {...formData?.event?.buffets[i],name : e.target.value}
                                                                formData?.event?.buffets.splice(i,1,temp)
                                                                setFormData({
                                                                    ...formData,
                                                                })
                                                            }}
                                                            />
                                                        </div>
                                                        <div className="col-3 mb-2">
                                                            <input
                                                                placeholder='00'
                                                                className="textFiled w-100 pricingTimingsBuffetText"
                                                                value={buffet?.veg}
                                                                onChange={(e) => {
                                                                    const regex= /^[1-9][0-9]*$/;
                                                                if (e.target.value === "" || regex.test(e.target.value)){
                                                                    const temp = {...formData?.event?.buffets[i],veg : e.target.value}
                                                                    formData?.event?.buffets.splice(i,1,temp)
                                                                    setFormData({
                                                                        ...formData,
                                                                        // event : {
                                                                        //     ...formData?.event,
                                                                        //     buffets : [...formData?.event?.buffets],
                                                                        // }
                                                                    })
                                                                }
                                                            }}
                                                            />
                                                        </div>
                                                        <div className="col-3 mb-2">
                                                            <input
                                                                placeholder='00'
                                                                className="textFiled w-100 pricingTimingsBuffetText"
                                                                value={buffet?.nonVeg}
                                                                onChange={(e) => {
                                                                    const regex= /^[1-9][0-9]*$/;
                                                                    if (e.target.value === "" || regex.test(e.target.value)){
                                                                        const temp = {...formData?.event?.buffets[i],nonVeg : e.target.value}
                                                                        formData?.event?.buffets.splice(i,1,temp)
                                                                        setFormData({
                                                                            ...formData,
                                                                            // event : {
                                                                            //     ...formData?.event,
                                                                            //     buffets : [...formData?.event?.buffets],
                                                                            // }
                                                                        })
                                                                    }
                                                                }}
                                                            />
                                                        </div>
                                                        <div className="col-2 mb-2">
                                                            <button type="button" style={{width:"45px"}} className="btn btn-outline-secondary  feature-buttons" onClick={() => onClickDelete(i)}  > <i class="bi bi-trash"></i> </button>
                                                        </div>
                                                    </div>
                                                </form>
                                                <div className="col-lg-6"></div>
                                            </>
                                        )
                                    })} */}
                                    {/* {formData?.event?.catering &&(
                                        <div>
                                            <button type="button"
                                            className="btn btn-primary"
                                            onClick={()=>{
                                                setFormData({
                                                    ...formData,
                                                    event : {
                                                        ...formData.event,
                                                        buffets : [...formData?.event?.buffets,{name:'',veg:'',nonVeg:''}]
                                                    }
                                                })
                                            }}>Add more</button>
                                        </div>
                                    )} */}
                                </div>
                            </Card.Body>
                        </Accordion.Collapse>
                    </Card>
        </Accordion>
    )
}

// New Staycation Start
const onChangeStaySleepingArrangements = (name, type) => {
    if (type === "dec") {
        if(formData?.stay?.sleepingArrangements?.[name] > 0){
            setFormData({
                ...formData,
                stay : {
                    ...formData?.stay,
                    sleepingArrangements : {
                        ...formData?.stay?.sleepingArrangements,
                        [name] : parseInt(formData?.stay?.sleepingArrangements?.[name]) - 1
                    }
                }
            });
        }
    } else {
        setFormData({
            ...formData,
            stay : {
                ...formData?.stay,
                sleepingArrangements : {
                    ...formData?.stay?.sleepingArrangements,
                    [name] : formData?.stay?.sleepingArrangements?.[name] ? parseInt(formData?.stay?.sleepingArrangements?.[name]) + 1 : 1  
                }
            }
        });
        setStayError({
            ...stayError,
            sleepingArrangements : false,
        });
    }
}

const staySleepingArrangements = () => {
    return(
        <div className="col-lg-12" id = "stay-sleepingArrangements">
            <h1 className="SpotLetSubHead"> Sleeping Arrangements <span className="required">*</span> </h1>
            <span className="SpotLetContent mb-1"> (Select atleast one) </span> <br/>
            {stayError?.sleepingArrangements && <span style={{color : 'red'}} className="SpotLetContent" >Please give any one of the below details</span> }
            <div className="col-lg-6 mb-2">
                <div className="row d-flex justify-content-between mb-2">
                    <div className="col-lg-6">
                        <label className="SpotLetContent">
                        Single Bed 
                        </label>
                    </div>
                    <div className="col-lg-6 d-flex pricing-button-container">
                        <button
                            type="button"
                            onClick={() => {
                                onChangeStaySleepingArrangements("singleBed", "dec");
                            }}
                        >-</button>
                        <div> {formData?.stay?.sleepingArrangements?.singleBed || 0} </div>
                        <button
                            type="button"
                            onClick={() => {
                                onChangeStaySleepingArrangements("singleBed", "inc");
                            }}
                        >+</button>
                    </div>
                </div>
                <div className="row d-flex justify-content-between mb-2">
                    <div className="col-lg-6">
                        <label className="SpotLetContent">
                        Twin Bed 
                        </label>
                    </div>
                    <div className="col-lg-6 d-flex pricing-button-container">
                        <button
                            type="button"
                            onClick={() => {
                                onChangeStaySleepingArrangements("twinBed", "dec");
                            }}
                        >-</button>
                        <div> {formData?.stay?.sleepingArrangements?.twinBed || 0} </div>
                        <button
                            type="button"
                            onClick={() => {
                                onChangeStaySleepingArrangements("twinBed", "inc");
                            }}
                        >+</button>
                    </div>
                </div>
                <div className="row d-flex justify-content-between mb-2">
                    <div className="col-lg-6">
                        <label className="SpotLetContent">
                        Queen Bed 
                        </label>
                    </div>
                    <div className="col-lg-6 d-flex pricing-button-container">
                        <button
                            type="button"
                            onClick={() => {
                                onChangeStaySleepingArrangements("queenBed", "dec");
                            }}
                        >-</button>
                        <div> {formData?.stay?.sleepingArrangements?.queenBed || 0} </div>
                        <button
                            type="button"
                            onClick={() => {
                                onChangeStaySleepingArrangements("queenBed", "inc");
                            }}
                        >+</button>
                    </div>
                </div>
                <div className="row d-flex justify-content-between mb-2">
                    <div className="col-lg-6">
                        <label className="SpotLetContent">
                        King Bed 
                        </label>
                    </div>
                    <div className="col-lg-6 d-flex pricing-button-container">
                        <button
                            type="button"
                            onClick={() => {
                                onChangeStaySleepingArrangements("kingBed", "dec");
                            }}
                        >-</button>
                        <div> {formData?.stay?.sleepingArrangements?.kingBed || 0} </div>
                        <button
                            type="button"
                            onClick={() => {
                                onChangeStaySleepingArrangements("kingBed", "inc");
                            }}
                        >+</button>
                    </div>
                </div>
                <div className="row d-flex justify-content-between mb-2">
                    <div className="col-lg-6">
                        <label className="SpotLetContent">
                        Double Bed 
                        </label>
                    </div>
                    <div className="col-lg-6 d-flex pricing-button-container">
                        <button
                            type="button"
                            onClick={() => {
                                onChangeStaySleepingArrangements("doubleBed", "dec");
                            }}
                        >-</button>
                        <div> {formData?.stay?.sleepingArrangements?.doubleBed || 0} </div>
                        <button
                            type="button"
                            onClick={() => {
                                onChangeStaySleepingArrangements("doubleBed", "inc");
                            }}
                        >+</button>
                    </div>
                </div>
                <div className="row d-flex justify-content-between mb-2">
                    <div className="col-lg-6">
                        <label className="SpotLetContent">
                        Bunk Bed 
                        </label>
                    </div>
                    <div className="col-lg-6 d-flex pricing-button-container">
                        <button
                            type="button"
                            onClick={() => {
                                onChangeStaySleepingArrangements("bunkBed", "dec");
                            }}
                        >-</button>
                        <div> {formData?.stay?.sleepingArrangements?.bunkBed || 0} </div>
                        <button
                            type="button"
                            onClick={() => {
                                onChangeStaySleepingArrangements("bunkBed", "inc");
                            }}
                        >+</button>
                    </div>
                </div>
                <div className="row d-flex justify-content-between mb-2">
                    <div className="col-lg-6">
                        <label className="SpotLetContent">
                        Mattress
                        </label>
                    </div>
                    <div className="col-lg-6 d-flex pricing-button-container">
                        <button
                            type="button"
                            onClick={() => {
                                onChangeStaySleepingArrangements("mattress", "dec");
                            }}
                        >-</button>
                        <div> {formData?.stay?.sleepingArrangements?.mattress || 0} </div>
                        <button
                            type="button"
                            onClick={() => {
                                onChangeStaySleepingArrangements("mattress", "inc");
                            }}
                        >+</button>
                    </div>
                </div>
                <div className="row d-flex justify-content-between mb-2">
                    <div className="col-lg-6">
                        <label className="SpotLetContent">
                        Sofa Cum Bed
                        </label>
                    </div>
                    <div className="col-lg-6 d-flex pricing-button-container">
                        <button
                            type="button"
                            onClick={() => {
                                onChangeStaySleepingArrangements("sofaCumBed", "dec");
                            }}
                        >-</button>
                        <div> {formData?.stay?.sleepingArrangements?.sofaCumBed || 0} </div>
                        <button
                            type="button"
                            onClick={() => {
                                onChangeStaySleepingArrangements("sofaCumBed", "inc");
                            }}
                        >+</button>
                    </div>
                </div>
                <div className="row d-flex justify-content-between mb-2">
                    <div className="col-lg-6">
                        <label className="SpotLetContent">
                        Futon 
                        </label>
                    </div>
                    <div className="col-lg-6 d-flex pricing-button-container">
                        <button
                            type="button"
                            onClick={() => {
                                onChangeStaySleepingArrangements("futon", "dec");
                            }}
                        >-</button>
                        <div> {formData?.stay?.sleepingArrangements?.futon || 0} </div>
                        <button
                            type="button"
                            onClick={() => {
                                onChangeStaySleepingArrangements("futon", "inc");
                            }}
                        >+</button>
                    </div>
                </div>
                <div className="row d-flex justify-content-between mb-2">
                    <div className="col-lg-6">
                        <label className="SpotLetContent">
                        Murphy Bed 
                        </label>
                    </div>
                    <div className="col-lg-6 d-flex pricing-button-container">
                        <button
                            type="button"
                            onClick={() => {
                                onChangeStaySleepingArrangements("murphyBed", "dec");
                            }}
                        >-</button>
                        <div> {formData?.stay?.sleepingArrangements?.murphyBed || 0} </div>
                        <button
                            type="button"
                            onClick={() => {
                                onChangeStaySleepingArrangements("murphyBed", "inc");
                            }}
                        >+</button>
                    </div>
                </div>
                <div className="row d-flex justify-content-between mb-2">
                    <div className="col-lg-6">
                        <label className="SpotLetContent">
                        Tatami Mats
                        </label>
                    </div>
                    <div className="col-lg-6 d-flex pricing-button-container">
                        <button
                            type="button"
                            onClick={() => {
                                onChangeStaySleepingArrangements("tatamiMats", "dec");
                            }}
                        >-</button>
                        <div> {formData?.stay?.sleepingArrangements?.tatamiMats || 0} </div>
                        <button
                            type="button"
                            onClick={() => {
                                onChangeStaySleepingArrangements("tatamiMats", "inc");
                            }}
                        >+</button>
                    </div>
                </div>
            </div>
        </div>
    )
} 

const stayFacilities = () => {
    return(
        <div className="col-lg-12 mt-2">
            <h1 className="SpotLetSubHead"> Facilities Section </h1>
            <div className="col-lg-6 mb-2">
                <div className="row d-flex justify-content-between mb-2">
                    <div className="col-lg-6">
                        <label className="SpotLetContent">
                        How many Attached Bathrooms
                        </label>
                    </div>
                    <div className="col-lg-6 d-flex pricing-button-container">
                        <button
                            type="button"
                            onClick={() => {
                                if(formData?.stay?.attachedBathrooms > 0){
                                    setFormData({
                                        ...formData,
                                        stay : {
                                            ...formData?.stay,
                                            attachedBathrooms : parseInt(formData?.stay?.attachedBathrooms) - 1
                                        }
                                    })
                                }
                            }}
                        >-</button>
                        <div> {formData?.stay?.attachedBathrooms || 0} </div>
                        <button
                            type="button"
                            onClick={() => {
                                    setFormData({
                                        ...formData,
                                        stay : {
                                            ...formData?.stay,
                                            attachedBathrooms : formData?.stay?.attachedBathrooms ? parseInt(formData?.stay?.attachedBathrooms) + 1 : 1  
                                        }
                                    })

                            }}
                        >+</button>
                    </div>
                </div>
                <div className="row d-flex justify-content-between mb-2">
                    <div className="col-lg-6">
                        <label className="SpotLetContent">
                        How many Common Bathrooms
                        </label>
                    </div>
                    <div className="col-lg-6 d-flex pricing-button-container">
                        <button
                            type="button"
                            onClick={() => {
                                if(formData?.stay?.commonBathrooms > 0){
                                    setFormData({
                                        ...formData,
                                        stay : {
                                            ...formData?.stay,
                                            commonBathrooms : parseInt(formData?.stay?.commonBathrooms) - 1
                                        }
                                    })
                                }
                            }}
                        >-</button>
                        <div> {formData?.stay?.commonBathrooms || 0} </div>
                        <button
                            type="button"
                            onClick={() => {
                                    setFormData({
                                        ...formData,
                                        stay : {
                                            ...formData?.stay,
                                            commonBathrooms : formData?.stay?.commonBathrooms ? parseInt(formData?.stay?.commonBathrooms) + 1 : 1  
                                        }
                                    })

                            }}
                        >+</button>
                    </div>
                </div>
            </div>
        </div>
    )
}
const stayOccupancy = () => {
    return(
        <div className="col-lg-12 mt-2">
            <h1 className="SpotLetSubHead"> Occupancy </h1>
            <div className="col-lg-6 mb-2">
                <div className="row d-flex justify-content-between mb-2" id = "stay-baseGuests">
                    <div className="col-lg-6">
                        <label className="SpotLetContent">
                            Base guests<span className="required">*</span>
                        </label>
                        <label className="pricing-label-sub">
                            (Ideal number of guests that can be accommodated in this property)
                        </label>
                    </div>
                    <div className="col-lg-6 d-flex pricing-button-container">
                        <button
                            type="button"
                            onClick={() => {
                                if(formData?.stay?.baseGuests > 1){
                                    setFormData({
                                        ...formData,
                                        stay : {
                                            ...formData?.stay,
                                            baseGuests : parseInt(formData?.stay?.baseGuests) -1,
                                        }
                                    })
                                }
                            }}
                        >-</button>
                        <div>{formData?.stay?.baseGuests || 1}</div>
                        <button
                            type="button"
                            onClick={() => {
                                    setFormData({
                                        ...formData,
                                        stay : {
                                            ...formData?.stay,
                                            baseGuests : formData?.stay?.baseGuests ?  parseInt(formData?.stay?.baseGuests) + 1 : 1,
                                            maxGuests : (formData?.stay?.baseGuests === formData?.stay?.maxGuests) ? parseInt(formData?.stay?.maxGuests) + 1 : parseInt(formData?.stay?.maxGuests),
                                            // maxOccupancy : (formData?.stay?.baseGuests === formData?.stay?.maxGuests) ? parseInt(formData?.stay?.maxOccupancy) + 1 : parseInt(formData?.stay?.maxOccupancy), 
                                        }
                                    })

                            }}
                        >+</button>
                    </div>
                {stayError.baseGuests &&<span style={{color : 'red'}} className="SpotLetContent"> {stayError?.message} </span>}
                </div>
                <div className="row d-flex justify-content-between mb-2" id = "stay-maxGuests">
                    <div className="col-lg-6">
                        <label className="SpotLetContent">
                            Maximum guests<span className="required">*</span>
                        </label>
                        <label className="pricing-label-sub">
                            (Maximum number of guests that can be accommodated in this property)
                        </label>
                    </div>
                    <div className="col-lg-6 d-flex pricing-button-container">
                        <button
                            type="button"
                            onClick={() => {
                                if(formData?.stay?.maxGuests > 1){
                                    setFormData({
                                        ...formData,
                                        stay : {
                                            ...formData?.stay,
                                            maxGuests : parseInt(formData?.stay?.maxGuests) -1,
                                            baseGuests : formData?.stay?.baseGuests ===  formData?.stay?.maxGuests ? parseInt(formData?.stay?.baseGuests) - 1  :  formData?.stay?.baseGuests,
                                            // maxOccupancy : parseInt(formData?.stay?.maxOccupancy) - 1,
                                        }
                                    })
                                }
                            }}
                        >-</button>
                    <div>{formData?.stay?.maxGuests || 1}</div>
                        <button
                            type="button"
                            onClick={() => {
                                    setFormData({
                                        ...formData,
                                        stay : {
                                            ...formData?.stay,
                                            maxGuests : formData?.stay?.maxGuests ?  parseInt(formData?.stay?.maxGuests)+1 : 1,
                                            // maxOccupancy : parseInt(formData?.stay?.maxOccupancy) + 1, 
                                        }
                                    })

                            }}
                        >+</button>
                    </div>
                    {stayError.maxGuests &&<span style={{color : 'red'}} className="SpotLetContent"> {stayError?.message1} </span>}
                </div>
                {/* <div className="row d-flex justify-content-between mb-2">
                    <div className="col-lg-6">
                        <label className="SpotLetContent">
                            Maximum Children
                        </label>
                        <label className="pricing-label-sub">
                            (Maximum number of children that can be accommodated in this property)
                        </label>
                    </div>
                    <div className="col-lg-6 d-flex pricing-button-container">
                    <button
                        type="button"
                        onClick={() => {
                            if(formData?.stay?.maxChildren > 0){
                                setFormData({
                                    ...formData,
                                    stay : {
                                        ...formData?.stay,
                                        maxChildren : parseInt(formData?.stay?.maxChildren) -1,
                                        maxOccupancy : parseInt(formData?.stay?.maxOccupancy) - 1, 
                                    }
                                })
                            }
                        }}
                    >-</button>
                    <div>{formData?.stay?.maxChildren || 0}</div>
                    <button
                        type="button"
                        onClick={() => {
                                setFormData({
                                    ...formData,
                                    stay : {
                                        ...formData?.stay,
                                        maxChildren : formData?.stay?.maxChildren ?  parseInt(formData?.stay?.maxChildren)+1 : 1,
                                        maxOccupancy : parseInt(formData?.stay?.maxOccupancy) + 1, 
                                    }
                                })
                        }}
                    >+</button>
                </div>
                </div> */}
                {/* <div className="row d-flex justify-content-between mb-2">
                    <div className="col-lg-6">
                        <label className="SpotLetContent">
                            Maximum Occupancy
                        </label>
                        <label className="pricing-label-sub">
                            (Maximum number of guests & children that can be accommodated in this property)
                        </label>
                    </div>
                    <div className="col-lg-6 d-flex pricing-button-container">
                        <button
                            type="button"
                            disabled
                        >-</button>
                        <div>{formData?.stay?.maxOccupancy}</div>
                        <button
                            type="button"
                            disabled
                        >+</button>
                    </div>
                </div> */}
            </div>
        </div>
    )
}
const stayPricingSection = () => {
    return(
        // <div className="col-12">
        <>
            <div className="col-lg-6">
                <div className="mb-3 d-flex flex-column" id = "stay-weekdayPrice">
                    <label htmlFor="stay-weekday-price" className="form-label SpotLetContent">
                        Weekday Base Price(Mon to Thu)<span className="required">*</span>
                    </label>
                    <input
                        // type="text"
                        required
                        // min={0}
                        placeholder="Enter Weekday Price Per Night"
                        id="stay-weekday-price"
                        name="weekdayPrice"
                        className="textFiled"
                        value={formData?.stay?.weekdayPrice || ""}
                        onChange={(e) => {
                            const regex= /^[1-9][0-9]*$/;
                            if (e.target.value === "" || regex.test(e.target.value)) {
                                onChangeInput(e,'stay');
                                setStayError({
                                    ...stayError,
                                    weekdayPrice : false,
                                });
                            }
                        }}
                    />
                    {stayError?.weekdayPrice &&<span style={{color : 'red'}} className="SpotLetContent">Enter Weekday price</span>}
                </div>
            </div>
            <div className="col-lg-6"></div>
            <div className="col-lg-6">
                <div className="mb-3 d-flex flex-column" id = "stay-weekendPrice">
                    <label htmlFor="stay-weekend-price" className="form-label">
                    Weekend Base Price(Fri to Sun)<span className="required">*</span>
                    </label>
                    <input
                        // type="text"
                        required
                        // min={0}
                        placeholder="Enter Weekend Price Per Night"
                        id="stay-weekend-price"
                        name="weekendPrice"
                        className="textFiled"
                        value={formData?.stay?.weekendPrice || "" }
                        onChange={(e) => {
                            const regex= /^[1-9][0-9]*$/;
                            if (e.target.value === "" || regex.test(e.target.value)) {
                                onChangeInput(e,'stay');
                                setStayError({
                                    ...stayError,
                                    weekendPrice : false,
                                });
                            }
                        }}
                    />
                        {stayError.weekendPrice &&<span style={{color : 'red'}} className="SpotLetContent">Enter Weekend price</span>}
                </div>
            </div>
            <div className="col-lg-6"></div>
            <div className="col-lg-6">
                <div className="mb-3 d-flex flex-column">
                    <label htmlFor="stay-extra-guest-price" className="form-label">
                    Extra guest charge(Optional)
                    </label>
                    <input
                        // type="text"
                        required
                        // min={0}
                        placeholder="Enter Extra Guest Fee Per Night"
                        id="stay-extra-guest-price"
                        name="extraGuestFee"
                        className="textFiled"
                        value={formData?.stay?.extraGuestFee || "" }
                        onChange={(e) => {
                            const regex= /^[1-9][0-9]*$/;
                            if (e.target.value === "" || regex.test(e.target.value)) {
                                onChangeInput(e,'stay');
                            }
                        }}
                    />
                </div>
            </div>
            <div className="col-lg-6"></div>
            {/* <div className="col-lg-6">
                <div className="mb-3 d-flex flex-column">
                    <label htmlFor="stay-extra-child-price" className="form-label">
                    Extra charge for child(Optional)
                    </label>
                    <input
                        // type="text"
                        required
                        // min={0}
                        placeholder="Enter Extra Child Fee Per Night"
                        id="stay-extra-child-price"
                        name="extraChildFee"
                        className="textFiled"
                        value={formData?.stay?.extraChildFee || "" }
                        onChange={(e) => {
                            const regex= /^[1-9][0-9]*$/;
                            if (e.target.value === "" || regex.test(e.target.value)) {
                                onChangeInput(e,'stay');
                            }
                        }}
                    />
                </div>
            </div> */}
            {/* <div className="col-lg-6"></div> */}
            <div className="col-lg-6">
                <div className="mb-2 d-flex flex-column">
                    <label htmlFor="stay-cleaning" className="form-label">
                        Cleaning fee(Optional)
                    </label>
                    <input
                        // type="text"
                        // min={0}
                        placeholder="Enter cleaning fee"
                        id="stay-cleaning"
                        name="cleaningFee"
                        className="textFiled"
                        value={formData?.stay?.cleaningFee || ""}
                        onChange={(e) => {
                            const regex= /^[1-9][0-9]*$/;
                            if (e.target.value === "" || regex.test(e.target.value)) {
                                onChangeInput(e,'stay')
                            }
                        }}
                    />
                </div>
            </div>
            {/* <div className="col-lg-6"></div> */}
            <div className="col-lg-6">
                <div className="mb-2 d-flex flex-column">
                    <label htmlFor="stay-security-deposit" className="form-label">
                        Security deposit(Optional)
                    </label>
                    <input
                        // type="text"
                        // min={0}
                        placeholder="Enter security deposit"
                        id="stay-security-deposit"
                        name="securityDeposit"
                        className="textFiled"
                        value={formData?.stay?.securityDeposit || "" }
                        onChange={(e) => {
                            const regex= /^[1-9][0-9]*$/;
                            if (e.target.value === "" || regex.test(e.target.value)) {
                                onChangeInput(e,'stay')
                            }
                        }}
                    />
                </div>
            </div>
            {/* <div className="col-lg-6"></div> */}
        </>
        // </div>
    )
}

const getAllCheckedStatusStay = (type) => { 
    if ( 
        (formData?.[type]?.timings?.monday) && 
        (formData?.[type]?.timings?.tuesday) &&
        (formData?.[type]?.timings?.wednesday) &&
        (formData?.[type]?.timings?.thursday) &&
        (formData?.[type]?.timings?.friday) &&
        (formData?.[type]?.timings?.saturday) &&
        (formData?.[type]?.timings?.sunday) 
    ) {
        return true
    } else {
        return false
    }
}

const onChangeStayTimings = (e, type, day) => {
    const {checked} = e.target;
    if(checked) {
        setFormData({
            ...formData,
            [type] : {
                ...formData?.[type],
                timings : {
                    ...formData?.[type]?.timings,
                    [day] : true
                }

            }
        })
    } else {
        setFormData({
            ...formData,
            [type] : {
                ...formData?.[type],
                timings : {
                    ...formData?.[type]?.timings,
                    [day] : false
                }

            }
        })
    }
}

const stayTimingsSectionJsx = () => {
    return(
        <div className="col-lg-12" id="stay-timings">
            <div className="mb-2 d-flex flex-column">
                <h1 className="form-label SpotLetSubHead">
                    Timings <span className="required">*</span>
                </h1>
                <div className="row">
                    <div className="col-lg-6 mb-3 d-flex flex-column" >
                        <label className="SpotLetContent" > Check In </label>
                        <div className="d-flex flex-column">
                            <select
                                required
                                className="textFiledSelect"
                                value={formData?.stay?.checkIn}
                                onChange={(e) => {
                                    setFormData({
                                        ...formData,
                                        stay : {
                                            ...formData.stay,
                                            checkIn : e.target.value,
                                        }
                                    })
                                }}
                            >
                                {stayTimeOptions.map((item) => (
                                <option value={item.value}>{item.label}</option>
                                ))}
                            </select>
                        </div>
                    </div>
                    <div className="col-lg-6 mb-3 d-flex flex-column" >
                        <label className="SpotLetContent" > Check Out </label>
                        <div className="d-flex flex-column">
                            <select
                                required
                                className="textFiledSelect"
                                value={formData?.stay?.checkOut}
                                onChange={(e) => {
                                    setFormData({
                                        ...formData,
                                        stay : {
                                            ...formData.stay,
                                            checkOut : e.target.value,
                                        }
                                    })
                                }}
                            >
                                {stayTimeOptions.map((item) => (
                                <option value={item.value}>{item.label}</option>
                                ))}
                            </select>
                        </div>
                    </div>
                </div>   
                <div className="row">
                    <div className="mb-2 col-lg-12 row">
                        <div className="col-lg-3 col-6">
                            <div className="d-flex">
                                <label htmlFor="stay-timings-select-all" className="form-label">All days available</label>
                            </div>
                        </div>
                        <div className="col-lg-3 col-6">
                            <div className="d-flex">
                                <input 
                                    checked = {getAllCheckedStatusStay("stay")} 
                                    name="all" 
                                    id = "stay-timings-select-all" 
                                    onChange={(e) => {
                                        selectAllTimings(e,'stay');
                                        setStayError({
                                            ...stayError,
                                            timings : false,
                                        }) 
                                    }} 
                                    type="checkbox" 
                                    className="form-check-input"
                                />
                            </div> 
                        </div>
                    </div>
                    {/* Select All End */}
                    {/*Monday start*/}
                    <div className="mb-2 col-lg-12 row">
                        <div className="col-lg-3 col-6">
                            <div className="d-flex">
                                <label htmlFor="stay-timings-monday" className="form-label">Mon</label>
                            </div>
                        </div>
                        <div className="col-lg-3 col-6">
                            <div className="d-flex">
                                <input
                                    type="checkbox"
                                    className="form-check-input SpotLetContent"
                                    id='stay-timings-monday-setHours'
                                    checked={formData?.stay?.timings?.monday === true}
                                    onChange={(e) => {
                                        onChangeStayTimings(e, "stay", "monday");
                                        setStayError({
                                            ...stayError,
                                            timings : false,
                                        }) 
                                    }}
                                />
                                {/* <label htmlFor="stay-timings-monday-setHours" className="form-label">Set hrs</label> */}
                            </div>
                        </div>
                    </div>
                    {/*Monday end*/}
                    {/*Tuesday start*/}
                    <div className="mb-2 col-lg-12 row">
                        <div className="col-lg-3 col-6">
                            <div className="d-flex">
                                <label htmlFor="stay-timings-tuesday" className="form-label">Tue</label>
                            </div>
                        </div>
                        <div className="col-lg-3 col-6">
                            <div className="d-flex">
                                <input
                                    type="checkbox"
                                    className="form-check-input"
                                    id='stay-timings-tuesday-setHours'
                                    checked={formData?.stay?.timings?.tuesday === true}
                                    onChange={(e) => {
                                        onChangeStayTimings(e, "stay", "tuesday");
                                        setStayError({
                                            ...stayError,
                                            timings : false,
                                        }) 
                                    }}
                                />
                                {/* <label htmlFor="stay-timings-tuesday-setHours" className="form-label">Set hrs</label> */}
                            </div>
                        </div>
                    </div>
                    {/*Tuesday end*/}
                    {/*Wednesday start*/}
                    <div className="mb-2 col-lg-12 row">
                        <div className="col-lg-3 col-6">
                            <div className="d-flex">
                                <label htmlFor="stay-timings-wednesday" className="form-label">Wed</label>
                            </div>
                        </div>
                        <div className="col-lg-3 col-6">
                            <div className="d-flex">
                                <input
                                    type="checkbox"
                                    className="form-check-input"
                                    id='stay-timings-wednesday-setHours'
                                    checked={formData?.stay?.timings?.wednesday === true}
                                    onChange={(e) => {
                                        onChangeStayTimings(e, "stay", "wednesday");
                                        setStayError({
                                            ...stayError,
                                            timings : false,
                                        }) 
                                    }}
                                />
                                {/* <label htmlFor="stay-timings-wednesday-setHours" className="form-label">Set hrs</label> */}
                            </div>
                        </div>
                    </div>
                    {/*Wednesday end*/}
                    {/*Thursday start*/}
                    <div className="mb-2 col-lg-12 row">
                        <div className="col-lg-3 col-6">
                            <div className="d-flex">
                                <label htmlFor="stay-timings-thursday" className="form-label">Thu</label>
                            </div>
                        </div>
                        <div className="col-lg-3 col-6">
                            <div className="d-flex">
                                <input
                                    type="checkbox"
                                    className="form-check-input"
                                    id='stay-timings-thursday-setHours'
                                    checked={formData?.stay?.timings?.thursday === true}
                                    onChange={(e) => {
                                        onChangeStayTimings(e, "stay", "thursday");
                                        setStayError({
                                            ...stayError,
                                            timings : false,
                                        }) 
                                    }}
                                />
                                {/* <label htmlFor="stay-timings-thursday-setHours" className="form-label">Set hrs</label> */}
                            </div>
                        </div>
                    </div>
                    {/*Thursday end*/}
                    {/*Friday start*/}
                    <div className="mb-2 col-lg-12 row">
                        <div className="col-lg-3 col-6">
                            <div className="d-flex">
                                <label htmlFor="stay-timings-friday" className="form-label">Fri</label>
                            </div>
                        </div>
                        <div className="col-lg-3 col-6">
                            <div className="d-flex">
                                <input
                                    type="checkbox"
                                    className="form-check-input"
                                    id='stay-timings-friday-setHours'
                                    checked={formData?.stay?.timings?.friday === true}
                                    onChange={(e) => {
                                        onChangeStayTimings(e, "stay", "friday");
                                        setStayError({
                                            ...stayError,
                                            timings : false,
                                        }) 
                                    }}
                                />
                                {/* <label htmlFor="stay-timings-friday-setHours" className="form-label">Set hrs</label> */}
                            </div>
                        </div>
                    </div>
                    {/*Friday end*/}
                    {/*Saturday start*/}
                    <div className="mb-2 col-lg-12 row">
                        <div className="col-lg-3 col-6">
                            <div className="d-flex">
                                <label htmlFor="stay-timings-saturday" className="form-label">Sat</label>
                            </div>
                        </div>
                        <div className="col-lg-3 col-6">
                            <div className="d-flex">
                                <input
                                    type="checkbox"
                                    className="form-check-input"
                                    id='stay-timings-saturday-setHours'
                                    checked={formData?.stay?.timings?.saturday === true}
                                    onChange={(e) => {
                                        onChangeStayTimings(e, "stay", "saturday");
                                        setStayError({
                                            ...stayError,
                                            timings : false,
                                        })
                                    }}
                                />
                                {/* <label htmlFor="stay-timings-saturday-setHours" className="form-label">Set hrs</label> */}
                            </div>
                        </div>
                    </div>
                    {/*Saturday end*/}
                    {/*Sunday start*/}
                    <div className="mb-2 col-lg-12 row">
                        <div className="col-lg-3 col-6">
                            <div className="d-flex">
                                <label htmlFor="stay-timings-sunday" className="form-label">Sun</label>
                            </div>
                        </div>
                        <div className="col-lg-3 col-6">
                            <div className="d-flex">
                                <input
                                    type="checkbox"
                                    className="form-check-input"
                                    id='stay-timings-sunday-setHours'
                                    checked={formData?.stay?.timings?.sunday === true}
                                    onChange={(e) => {
                                        onChangeStayTimings(e, "stay", "sunday");
                                        setStayError({
                                            ...stayError,
                                            timings : false,
                                        })
                                    }}
                                />
                                {/* <label htmlFor="stay-timings-sunday-setHours" className="form-label">Set hrs</label> */}
                            </div>
                        </div>
                    </div>
                    {/*Sunday end*/}
                </div>
                {stayError.timings &&<span style={{color : 'red'}} className="SpotLetContent">Select Timings</span>}
            </div>
        </div>
    )
}

const stayMealOptions = () => {
    return(
        <div className="row">
            <div className="col-lg-12 mb-3 d-flex flex-column" >
                <h1 className="SpotLetContent pricing-label" > Meal options will be provided for all guests up to the maximum occupancy. Please ensure the correct maximum occupancy and meal options are specified accordingly. </h1>
                <div className="col-lg-6 d-flex flex-column">
                    <select
                        className="textFiledSelect"
                        value={formData?.stay?.mealOption}
                        onChange={(e) => {
                            setFormData({
                                ...formData,
                                stay : {
                                    ...formData.stay,
                                    mealOption : e.target.value,
                                }
                            })
                        }}
                    >
                        {STAY_MEAL_OPTIONS?.map((item) => (
                        <option value={item.value}>{item.label}</option>
                        ))}
                    </select>
                </div>
                <div className="col-lg-6"></div>
            </div>
        </div> 
    )
}

// New Staycation End

const stayAccordian = () => {
    return(
        <Accordion defaultActiveKey={data?.stay?.available ? "3" : ''} id = "PricingStayId" >
                    <Card className="pricingCard">
                        <Card.Header style={{backgroundColor : "#3d3d3d", color : "#ffffff"}} className="d-flex justify-content-between">
                            <h6 className="SpotLetSubHead">Are you interested in providing your property for a Staycation?<span className="required">*</span></h6>
                            <Toggle eventKey="3" type='stay'/>
                        </Card.Header>
                        <Accordion.Collapse eventKey="3">
                            <Card.Body style = {{border : "2px solid #3d3d3d"}}>
                                <div className="row">
                                    {staySleepingArrangements()}
                                    {stayFacilities()}
                                    {stayOccupancy()}
                                    {stayPricingSection()}
                                    {stayTimingsSectionJsx()}
                                    {stayMealOptions()}
                                    {/* <div className="col-lg-6">
                                        <div className="mb-3 d-flex flex-column">
                                            <label htmlFor="stay-hourly-price" className="form-label SpotLetContent">
                                                Weekday price(Mon to Thu)<span className="required">*</span>
                                            </label>
                                            <input
                                                // type="text"
                                                required
                                                // min={0}
                                                placeholder="Enter Weekday Price Per Night"
                                                id="stay-hourly-price"
                                                name="hourlyPrice"
                                                className="textFiled"
                                                value={formData?.stay?.weekdayPrice || ""}
                                                onChange={(e) => {
                                                    const regex= /^[1-9][0-9]*$/;
                                                    if (e.target.value === "" || regex.test(e.target.value)) {
                                                        onChangeInput(e,'stay');
                                                        setStayError({
                                                            ...stayError,
                                                            hourlyPrice : false,
                                                        });
                                                    }
                                                }}
                                            />
                                            {stayError.hourlyPrice &&<span style={{color : 'red'}} className="SpotLetContent">Enter Weekday price</span>}
                                        </div>
                                    </div> */}
                                    {/* <div className="col-lg-6"></div> */}
                                    {/* <div className="col-lg-6">
                                        <div className="mb-3 d-flex flex-column">
                                            <label htmlFor="stay-weekend-price" className="form-label">
                                            Weekend price(Fri to Sun)<span className="required">*</span>
                                            </label>
                                            <input
                                                // type="text"
                                                required
                                                // min={0}
                                                placeholder="Enter Weekend Price Per Night"
                                                id="stay-weekend-price"
                                                name="weekendPrice"
                                                className="textFiled"
                                                value={formData?.stay?.weekendPrice || "" }
                                                onChange={(e) => {
                                                    const regex= /^[1-9][0-9]*$/;
                                                    if (e.target.value === "" || regex.test(e.target.value)) {
                                                        onChangeInput(e,'stay');
                                                        setStayError({
                                                            ...stayError,
                                                            weekendPrice : false,
                                                        });
                                                    }
                                                }}
                                            />
                                             {stayError.weekendPrice &&<span style={{color : 'red'}} className="SpotLetContent">Enter Weekend price</span>}
                                        </div>
                                    </div> */}
                                    {/* <div className="col-lg-6"></div> */}
                                    {/* <div className="col-lg-6 mb-2">
                                        <label className="form-label">
                                            Occupancy
                                        </label>
                                        <div className="row d-flex justify-content-between">
                                            <div className="col-lg-6">
                                                <label className="pricing-label">
                                                    Base adults<span className="required">*</span>
                                                </label>
                                                <label className="pricing-label-sub">
                                                    (Ideal number of adults that can be accommodated in this property)
                                                </label>
                                            </div>
                                            <div className="col-lg-6 d-flex pricing-button-container mx-2">
                                                <button
                                                    type="button"
                                                    onClick={() => {
                                                        if(formData?.stay?.baseAdults > 0){
                                                            setFormData({
                                                                ...formData,
                                                                stay : {
                                                                    ...formData?.stay,
                                                                    baseAdults : parseInt(formData?.stay?.baseAdults) -1,
                                                                }
                                                            })
                                                        }
                                                    }}
                                                >-</button>
                                                <div>{formData?.stay?.baseAdults || 0}</div>
                                                <button
                                                    type="button"
                                                    onClick={() => {
                                                            setFormData({
                                                                ...formData,
                                                                stay : {
                                                                    ...formData?.stay,
                                                                    baseAdults : formData?.stay?.baseAdults ?  parseInt(formData?.stay?.baseAdults)+1 : 1,
                                                                    maxAdults : formData?.stay?.maxAdults ?  parseInt(formData?.stay?.maxAdults)+1 : 1,
                                                                }
                                                            })

                                                    }}
                                                >+</button>
                                                <input
                                                    required
                                                    placeholder="Enter No."
                                                    className="textFiled w-100"
                                                    value={formData?.stay?.baseAdults || ""}
                                                    onChange={(e) => {
                                                        const regex = /^[0-9]+$/;
                                                        if (regex.test(e?.target?.value)) {
                                                            setFormData({
                                                                ...formData,
                                                                stay : {
                                                                    ...formData?.stay, 
                                                                    baseAdults : parseInt(e?.target?.value)
                                                                }
                                                            });
                                                            setStayError({
                                                                ...stayError,
                                                                baseAdults : false,
                                                            });
                                                        } else if (e?.target?.value === "") {
                                                            setFormData({
                                                                ...formData,
                                                                stay : {
                                                                    ...formData?.stay, 
                                                                    baseAdults : e?.target?.value
                                                                }
                                                            })
                                                        }
                                                    } }
                                                />
                                            </div>
                                            {stayError.baseAdults &&<span style={{color : 'red'}} className="SpotLetContent"> {stayError?.message} </span>}
                                        </div>
                                        <div className="row d-flex justify-content-between">
                                            <div className="col-lg-6">
                                                <label className="pricing-label">
                                                    Maximum adults<span className="required">*</span>
                                                </label>
                                                <label className="pricing-label-sub">
                                                    (Maximum number of adults that can be accommodated in this property)
                                                </label>
                                            </div>
                                            <div className="col-lg-6 d-flex pricing-button-container mx-2">
                                                <button
                                                    type="button"
                                                    onClick={() => {
                                                        if(formData?.stay?.maxAdults > 0){
                                                            setFormData({
                                                                ...formData,
                                                                stay : {
                                                                    ...formData?.stay,
                                                                    maxAdults : parseInt(formData?.stay?.maxAdults) -1,
                                                                    baseAdults : formData?.stay?.baseAdults ===  formData?.stay?.maxAdults ? parseInt(formData?.stay?.baseAdults) - 1  :  formData?.stay?.baseAdults
                                                                }
                                                            })
                                                        }
                                                    }}
                                                >-</button>
                                                <div>{formData?.stay?.maxAdults || 0}</div>
                                                <button
                                                    type="button"
                                                    onClick={() => {
                                                            setFormData({
                                                                ...formData,
                                                                stay : {
                                                                    ...formData?.stay,
                                                                    maxAdults : formData?.stay?.maxAdults ?  parseInt(formData?.stay?.maxAdults)+1 : 1,
                                                                }
                                                            })

                                                    }}
                                                >+</button>
                                                <input
                                                    required
                                                    placeholder="Enter No."
                                                    className="textFiled w-100"
                                                    value={formData?.stay?.maxAdults || ""}
                                                    onChange={(e) => {
                                                        const regex = /^[0-9]+$/;
                                                        if (regex.test(e?.target?.value)) {
                                                            setFormData({
                                                                ...formData,
                                                                stay : {
                                                                    ...formData?.stay, 
                                                                    maxAdults : parseInt(e.target.value),
                                                                }
                                                            });
                                                            setStayError({
                                                                ...stayError,
                                                                maxAdults : false,
                                                            });
                                                        } else if (e?.target?.value === "") {
                                                            setFormData({
                                                                ...formData,
                                                                stay : {
                                                                    ...formData?.stay, 
                                                                    maxAdults : e.target.value,
                                                                }
                                                            })
                                                        }
                                                    } }
                                                />
                                            </div>
                                            {stayError.maxAdults &&<span style={{color : 'red'}} className="SpotLetContent"> {stayError?.message1} </span>}
                                        </div>
                                    </div> */}
                                    {/* <div className="col-lg-6"></div> */}
                                    {/* <div className="col-lg-6 mb-2">
                                        <div className="col-lg-6">
                                            <label className="pricing-label">
                                            Extra Bed or Cot
                                            </label>
                                            <label className="pricing-label-sub">
                                                (Help your guests understand if there are any Extra Bed or Cot available on request)
                                            </label>
                                        </div>
                                        <div className="row d-flex justify-content-between mb-2">
                                            <div className="col-lg-6">
                                                <label className="pricing-label">
                                                Mattress 
                                                </label>
                                            </div>
                                            <div className="col-lg-6 d-flex pricing-button-container">
                                                <button
                                                    type="button"
                                                    onClick={() => {
                                                        if(formData?.stay?.extraBed > 0){
                                                            setFormData({
                                                                ...formData,
                                                                stay : {
                                                                    ...formData?.stay,
                                                                    extraBed : formData?.stay?.extraBed -1
                                                                }
                                                            })
                                                        }
                                                    }}
                                                >-</button>
                                                <div>{formData?.stay?.extraBed || 0}</div>
                                                <button
                                                    type="button"
                                                    onClick={() => {
                                                            setFormData({
                                                                ...formData,
                                                                stay : {
                                                                    ...formData?.stay,
                                                                    extraBed : formData?.stay?.extraBed ?  formData?.stay?.extraBed+1 : 1,
                                                                }
                                                            })

                                                    }}
                                                >+</button>
                                            </div>
                                        </div>
                                        <div className="row d-flex justify-content-between">
                                            <div className="col-lg-6">
                                                <label className="pricing-label">
                                                Cot 
                                                </label>
                                            </div>
                                            <div className="col-lg-6 d-flex pricing-button-container">
                                                <button
                                                    type="button"
                                                    onClick={() => {
                                                        if(formData?.stay?.extraCot > 0){
                                                            setFormData({
                                                                ...formData,
                                                                stay : {
                                                                    ...formData?.stay,
                                                                    extraCot : formData?.stay?.extraCot -1
                                                                }
                                                            })
                                                        }
                                                    }}
                                                >-</button>
                                                <div>{formData?.stay?.extraCot || 0}</div>
                                                <button
                                                    type="button"
                                                    onClick={() => {
                                                            setFormData({
                                                                ...formData,
                                                                stay : {
                                                                    ...formData?.stay,
                                                                    extraCot : formData?.stay?.extraCot ?  formData?.stay?.extraCot+1 : 1,
                                                                }
                                                            })

                                                    }}
                                                >+</button>
                                            </div>
                                        </div>
                                    </div> */}
                                    {/* <div className="col-lg-6"></div> */}
                                    {/* <div className="col-lg-6">
                                        <div className="mb-3 d-flex flex-column">
                                            <label htmlFor="stay-minimumBooking" className="form-label">
                                           Minimum booking Hrs<span className="required">*</span>
                                            </label>
                                            <select
                                                required
                                                id="stay-minimumBooking"
                                                name="minimumBooking"
                                                className="textFiledSelect SpotLetContent"
                                                value={formData?.stay?.minimumBooking}
                                                onChange={(e) => {onChangeInput(e,'stay')}}
                                            >
                                                <option hidden selected value="">Select hours</option>
                                                <option value='12'>12 Hrs</option>
                                                <option value='24'>24 Hrs</option>
                                            </select>
                                        </div>
                                    </div> */}
                                    {/* <div className="col-lg-12">
                                        <div className="mb-2 d-flex flex-column"> */}
                                            {/* <div className="d-flex">
                                                <label className="form-label">
                                                    Timings available for booking<span className="required">*</span>
                                                </label>
                                                <div className="mx-3">
                                                        <label htmlFor="stay-all-booking-hours mx-3" className="SpotLetContent" > Select all </label>
                                                        <input 
                                                            checked = {getAllCheckedStatus("stay")} 
                                                            name="all" 
                                                            id = "stay-all-booking-hours" 
                                                            onChange={(e) => {
                                                                selectAllTimings(e,'stay');
                                                                setStayError({
                                                                    ...stayError,
                                                                    timings : false,
                                                                }) 
                                                            }} 
                                                            type="checkbox" 
                                                            className="form-check-input mx-2"
                                                        />
                                                    </div>
                                            </div>    */}
                                            {/* <div className="row"> */}
                                                {/*Monday start*/}
                                                {/* <div className="mb-2 col-lg-12 row">
                                                    <div className="col-lg-3 col-6">
                                                        <div className="d-flex">
                                                            <label htmlFor="stay-timings-monday" className="form-label">Mon</label>
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-3 col-6">
                                                        <div className="d-flex">
                                                            <input
                                                                type="checkbox"
                                                                className="form-check-input SpotLetContent"
                                                                id='stay-timings-monday-setHours'
                                                                checked={formData?.stay?.timings?.monday?.fullDay === true}
                                                                onChange={(e) => {
                                                                    onChangeSetHrsCheckBox(e, "stay", "monday");
                                                                    setStayError({
                                                                        ...stayError,
                                                                        timings : false,
                                                                    }) 
                                                                }}
                                                            />
                                                            <label htmlFor="stay-timings-monday-setHours" className="form-label">Set hrs</label>
                                                        </div>
                                                    </div>
                                                    {formData?.stay?.timings?.monday?.fullDay && (
                                                        <>
                                                            <div className="col-lg-3 col-6 d-flex flex-column">
                                                                <select
                                                                    // disabled
                                                                    className="textFiledSelect"
                                                                    value={formData?.stay?.timings?.monday?.timings?.open}
                                                                    // onChange={(e) => {
                                                                    //     setFormData({
                                                                    //         ...formData,
                                                                    //         stay : {
                                                                    //             ...formData.stay,
                                                                    //             timings : {
                                                                    //                 ...formData?.stay?.timings,
                                                                    //                 monday : {
                                                                    //                     ...formData?.stay?.timings?.monday,
                                                                    //                     timings :{
                                                                    //                         ...formData?.stay?.timings?.monday?.timings,
                                                                    //                         open : e.target.value
                                                                    //                     }
                                                                    //                 },
                                                                    //             }
                                                                    //             }
                                                                    //     })
                                                                    // }}
                                                                    onChange={(e) => {
                                                                        validateStayTimings(  e.target.value, formData?.stay?.timings?.monday?.timings?.close, 'stay', 'monday')
                                                                    }}
                                                                >
                                                                    {stayTimeOptions.map((item) => (
                                                                    <option value={item.value}>{item.label}</option>
                                                                    ))}
                                                                </select>
                                                            </div>
                                                            <div className="col-lg-3 col-6 d-flex flex-column">
                                                                <select
                                                                    // disabled
                                                                    className="textFiledSelect"
                                                                    value={formData?.stay?.timings?.monday?.timings?.close}
                                                                    onChange={(e) => {
                                                                        validateStayTimings(formData?.stay?.timings?.monday?.timings?.open, e.target.value, 'stay','monday')
                                                                    }}
                                                                >
                                                                    {stayTimeOptions.map((item) => (
                                                                    <option value={item.value}>{item.label}</option>
                                                                    ))}
                                                                </select>
                                                            </div>
                                                        </>
                                                    )}
                                                 </div> */}
                                                 {/*Monday end*/}
                                                  {/*Tuesday start*/}
                                                  {/* <div className="mb-2 col-lg-12 row">
                                                    <div className="col-lg-3 col-6">
                                                        <div className="d-flex">
                                                            <label htmlFor="stay-timings-tuesday" className="form-label">Tue</label>
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-3 col-6">
                                                        <div className="d-flex">
                                                            <input
                                                                type="checkbox"
                                                                className="form-check-input"
                                                                id='stay-timings-tuesday-setHours'
                                                                checked={formData?.stay?.timings?.tuesday?.fullDay === true}
                                                                onChange={(e) => {
                                                                    onChangeSetHrsCheckBox(e, "stay", "tuesday");
                                                                    setStayError({
                                                                        ...stayError,
                                                                        timings : false,
                                                                    }) 
                                                                }}
                                                            />
                                                            <label htmlFor="stay-timings-tuesday-setHours" className="form-label">Set hrs</label>
                                                        </div>
                                                    </div>
                                                    {formData?.stay?.timings?.tuesday?.fullDay && (
                                                        <>
                                                            <div className="col-lg-3 col-6 d-flex flex-column">
                                                                <select
                                                                    // disabled
                                                                    className="textFiledSelect"
                                                                    value={formData?.stay?.timings?.tuesday?.timings?.open}
                                                                    onChange={(e) => {
                                                                        validateStayTimings(  e.target.value, formData?.stay?.timings?.tuesday?.timings?.close, 'stay', 'tuesday')
                                                                    }}
                                                                >
                                                                    {stayTimeOptions.map((item) => (
                                                                    <option value={item.value}>{item.label}</option>
                                                                    ))}
                                                                </select>
                                                            </div>
                                                            <div className="col-lg-3 col-6 d-flex flex-column">
                                                                <select
                                                                    // disabled
                                                                    className="textFiledSelect"
                                                                    value={formData?.stay?.timings?.tuesday?.timings?.close}
                                                                    onChange={(e) => {
                                                                        validateStayTimings(formData?.stay?.timings?.tuesday?.timings?.open, e.target.value, 'stay','tuesday')
                                                                    }}
                                                                >
                                                                    {stayTimeOptions.map((item) => (
                                                                    <option value={item.value}>{item.label}</option>
                                                                    ))}
                                                                </select>
                                                            </div>
                                                        </>
                                                    )}
                                                 </div> */}
                                                 {/*Tuesday end*/}
                                                  {/*Wednesday start*/}
                                                  {/* <div className="mb-2 col-lg-12 row">
                                                    <div className="col-lg-3 col-6">
                                                        <div className="d-flex">
                                                            <label htmlFor="stay-timings-wednesday" className="form-label">Wed</label>
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-3 col-6">
                                                        <div className="d-flex">
                                                            <input
                                                                type="checkbox"
                                                                className="form-check-input"
                                                                id='stay-timings-wednesday-setHours'
                                                                checked={formData?.stay?.timings?.wednesday?.fullDay === true}
                                                                onChange={(e) => {
                                                                    onChangeSetHrsCheckBox(e, "stay", "wednesday");
                                                                    setStayError({
                                                                        ...stayError,
                                                                        timings : false,
                                                                    }) 
                                                                }}
                                                            />
                                                            <label htmlFor="stay-timings-wednesday-setHours" className="form-label">Set hrs</label>
                                                        </div>
                                                    </div>
                                                    {formData?.stay?.timings?.wednesday?.fullDay && (
                                                        <>
                                                            <div className="col-lg-3 col-6 d-flex flex-column">
                                                                <select
                                                                    // disabled
                                                                    className="textFiledSelect"
                                                                    value={formData?.stay?.timings?.wednesday?.timings?.open}
                                                                    onChange={(e) => {
                                                                        validateStayTimings(  e.target.value, formData?.stay?.timings?.wednesday?.timings?.close, 'stay', 'wednesday')
                                                                    }}
                                                                >
                                                                    {stayTimeOptions.map((item) => (
                                                                    <option value={item.value}>{item.label}</option>
                                                                    ))}
                                                                </select>
                                                            </div>
                                                            <div className="col-lg-3 col-6 d-flex flex-column">
                                                                <select
                                                                    // disabled
                                                                    className="textFiledSelect"
                                                                    value={formData?.stay?.timings?.wednesday?.timings?.close}
                                                                    onChange={(e) => {
                                                                        validateStayTimings(formData?.stay?.timings?.wednesday?.timings?.open, e.target.value, 'stay','wednesday')
                                                                    }}
                                                                >
                                                                    {stayTimeOptions.map((item) => (
                                                                    <option value={item.value}>{item.label}</option>
                                                                    ))}
                                                                </select>
                                                            </div>
                                                        </>
                                                    )}
                                                 </div> */}
                                                 {/*Wednesday end*/}
                                                  {/*Thursday start*/}
                                                  {/* <div className="mb-2 col-lg-12 row">
                                                    <div className="col-lg-3 col-6">
                                                        <div className="d-flex">
                                                            <label htmlFor="stay-timings-thursday" className="form-label">Thu</label>
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-3 col-6">
                                                        <div className="d-flex">
                                                            <input
                                                                type="checkbox"
                                                                className="form-check-input"
                                                                id='stay-timings-thursday-setHours'
                                                                checked={formData?.stay?.timings?.thursday?.fullDay === true}
                                                                onChange={(e) => {
                                                                    onChangeSetHrsCheckBox(e, "stay", "thursday");
                                                                    setStayError({
                                                                        ...stayError,
                                                                        timings : false,
                                                                    }) 
                                                                }}
                                                            />
                                                            <label htmlFor="stay-timings-thursday-setHours" className="form-label">Set hrs</label>
                                                        </div>
                                                    </div>
                                                    {formData?.stay?.timings?.thursday?.fullDay && (
                                                        <>
                                                            <div className="col-lg-3 col-6 d-flex flex-column">
                                                                <select
                                                                    // disabled
                                                                    className="textFiledSelect"
                                                                    value={formData?.stay?.timings?.thursday?.timings?.open}
                                                                    onChange={(e) => {
                                                                        validateStayTimings(  e.target.value, formData?.stay?.timings?.thursday?.timings?.close, 'stay', 'thursday')
                                                                    }}
                                                                >
                                                                    {stayTimeOptions.map((item) => (
                                                                    <option value={item.value}>{item.label}</option>
                                                                    ))}
                                                                </select>
                                                            </div>
                                                            <div className="col-lg-3 col-6 d-flex flex-column">
                                                                <select
                                                                    // disabled
                                                                    className="textFiledSelect"
                                                                    value={formData?.stay?.timings?.thursday?.timings?.close}
                                                                    onChange={(e) => {
                                                                        validateStayTimings(formData?.stay?.timings?.thursday?.timings?.open, e.target.value, 'stay','thursday')
                                                                    }}
                                                                >
                                                                    {stayTimeOptions.map((item) => (
                                                                    <option value={item.value}>{item.label}</option>
                                                                    ))}
                                                                </select>
                                                            </div>
                                                        </>
                                                    )}
                                                 </div> */}
                                                 {/*Thursday end*/}
                                                  {/*Friday start*/}
                                                  {/* <div className="mb-2 col-lg-12 row">
                                                    <div className="col-lg-3 col-6">
                                                        <div className="d-flex">
                                                            <label htmlFor="stay-timings-friday" className="form-label">Fri</label>
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-3 col-6">
                                                        <div className="d-flex">
                                                            <input
                                                                type="checkbox"
                                                                className="form-check-input"
                                                                id='stay-timings-friday-setHours'
                                                                checked={formData?.stay?.timings?.friday?.fullDay === true}
                                                                onChange={(e) => {
                                                                    onChangeSetHrsCheckBox(e, "stay", "friday");
                                                                    setStayError({
                                                                        ...stayError,
                                                                        timings : false,
                                                                    }) 
                                                                }}
                                                            />
                                                            <label htmlFor="stay-timings-friday-setHours" className="form-label">Set hrs</label>
                                                        </div>
                                                    </div>
                                                    {formData?.stay?.timings?.friday?.fullDay && (
                                                        <>
                                                            <div className="col-lg-3 col-6 d-flex flex-column">
                                                                <select
                                                                    // disabled
                                                                    className="textFiledSelect"
                                                                    value={formData?.stay?.timings?.friday?.timings?.open}
                                                                    onChange={(e) => {
                                                                        validateStayTimings(  e.target.value, formData?.stay?.timings?.friday?.timings?.close, 'stay', 'friday')
                                                                    }}
                                                                >
                                                                    {stayTimeOptions.map((item) => (
                                                                    <option value={item.value}>{item.label}</option>
                                                                    ))}
                                                                </select>
                                                            </div>
                                                            <div className="col-lg-3 col-6 d-flex flex-column">
                                                                <select
                                                                    // disabled
                                                                    className="textFiledSelect"
                                                                    value={formData?.stay?.timings?.friday?.timings?.close}
                                                                    onChange={(e) => {
                                                                        validateStayTimings(formData?.stay?.timings?.friday?.timings?.open, e.target.value, 'stay','friday')
                                                                    }}
                                                                >
                                                                    {stayTimeOptions.map((item) => (
                                                                    <option value={item.value}>{item.label}</option>
                                                                    ))}
                                                                </select>
                                                            </div>
                                                        </>
                                                    )}
                                                 </div> */}
                                                 {/*Friday end*/}
                                                  {/*Saturday start*/}
                                                  {/* <div className="mb-2 col-lg-12 row">
                                                    <div className="col-lg-3 col-6">
                                                        <div className="d-flex">
                                                            <label htmlFor="stay-timings-saturday" className="form-label">Sat</label>
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-3 col-6">
                                                        <div className="d-flex">
                                                            <input
                                                                type="checkbox"
                                                                className="form-check-input"
                                                                id='stay-timings-saturday-setHours'
                                                                checked={formData?.stay?.timings?.saturday?.fullDay === true}
                                                                onChange={(e) => {
                                                                    onChangeSetHrsCheckBox(e, "stay", "saturday");
                                                                    setStayError({
                                                                        ...stayError,
                                                                        timings : false,
                                                                    })
                                                                }}
                                                            />
                                                            <label htmlFor="stay-timings-saturday-setHours" className="form-label">Set hrs</label>
                                                        </div>
                                                    </div>
                                                    {formData?.stay?.timings?.saturday?.fullDay && (
                                                        <>
                                                            <div className="col-lg-3 col-6 d-flex flex-column">
                                                                <select
                                                                    // disabled
                                                                    className="textFiledSelect"
                                                                    value={formData?.stay?.timings?.saturday?.timings?.open}
                                                                    onChange={(e) => {
                                                                        validateStayTimings(  e.target.value, formData?.stay?.timings?.saturday?.timings?.close, 'stay', 'saturday')
                                                                    }}
                                                                >
                                                                    {stayTimeOptions.map((item) => (
                                                                    <option value={item.value}>{item.label}</option>
                                                                    ))}
                                                                </select>
                                                            </div>
                                                            <div className="col-lg-3 col-6 d-flex flex-column">
                                                                <select
                                                                    // disabled
                                                                    className="textFiledSelect"
                                                                    value={formData?.stay?.timings?.saturday?.timings?.close}
                                                                    onChange={(e) => {
                                                                        validateStayTimings(formData?.stay?.timings?.saturday?.timings?.open, e.target.value, 'stay','saturday')
                                                                    }}
                                                                >
                                                                    {stayTimeOptions.map((item) => (
                                                                    <option value={item.value}>{item.label}</option>
                                                                    ))}
                                                                </select>
                                                            </div>
                                                        </>
                                                    )}
                                                 </div> */}
                                                 {/*Saturday end*/}
                                                  {/*Sunday start*/}
                                                  {/* <div className="mb-2 col-lg-12 row">
                                                    <div className="col-lg-3 col-6">
                                                        <div className="d-flex">
                                                            <label htmlFor="stay-timings-sunday" className="form-label">Sun</label>
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-3 col-6">
                                                        <div className="d-flex">
                                                            <input
                                                                type="checkbox"
                                                                className="form-check-input"
                                                                id='stay-timings-sunday-setHours'
                                                                checked={formData?.stay?.timings?.sunday?.fullDay === true}
                                                                onChange={(e) => {
                                                                    onChangeSetHrsCheckBox(e, "stay", "sunday");
                                                                    setStayError({
                                                                        ...stayError,
                                                                        timings : false,
                                                                    })
                                                                }}
                                                            />
                                                            <label htmlFor="stay-timings-sunday-setHours" className="form-label">Set hrs</label>
                                                        </div>
                                                    </div>
                                                    {formData?.stay?.timings?.sunday?.fullDay && (
                                                        <>
                                                            <div className="col-lg-3 col-6 d-flex flex-column">
                                                                <select
                                                                    // disabled
                                                                    className="textFiledSelect"
                                                                    value={formData?.stay?.timings?.sunday?.timings?.open}
                                                                    onChange={(e) => {
                                                                        validateStayTimings(  e.target.value, formData?.stay?.timings?.sunday?.timings?.close, 'stay', 'sunday')
                                                                    }}
                                                                >
                                                                    {stayTimeOptions.map((item) => (
                                                                    <option value={item.value}>{item.label}</option>
                                                                    ))}
                                                                </select>
                                                            </div>
                                                            <div className="col-lg-3 col-6 d-flex flex-column">
                                                                <select
                                                                    // disabled
                                                                    className="textFiledSelect"
                                                                    value={formData?.stay?.timings?.sunday?.timings?.close}
                                                                    onChange={(e) => {
                                                                        validateStayTimings(formData?.stay?.timings?.sunday?.timings?.open, e.target.value, 'stay','sunday')
                                                                    }}
                                                                >
                                                                    {stayTimeOptions.map((item) => (
                                                                    <option value={item.value}>{item.label}</option>
                                                                    ))}
                                                                </select>
                                                            </div>
                                                        </>
                                                    )}
                                                 </div> */}
                                                 {/*Sunday end*/}
                                            {/* </div> */}
                                            {/* {stayError.timings &&<span style={{color : 'red'}} className="SpotLetContent">Select Timings</span>} */}
                                        {/* </div> */}
                                    {/* </div> */}
                                    {/* <div className="col-lg-6">
                                        <div className="mb-2 d-flex flex-column">
                                            <label htmlFor="stay-cleaning" className="form-label">
                                                Cleaning fee(Optional)
                                            </label>
                                            <input
                                                // type="text"
                                                // min={0}
                                                placeholder="Enter cleaning fee"
                                                id="stay-cleaning"
                                                name="cleaningFee"
                                                className="textFiled"
                                                value={formData?.stay?.cleaningFee || ""}
                                                onChange={(e) => {
                                                    const regex= /^[1-9][0-9]*$/;
                                                    if (e.target.value === "" || regex.test(e.target.value)) {
                                                        onChangeInput(e,'stay')
                                                    }
                                                }}
                                            />
                                        </div>
                                    </div> */}
                                    {/* <div className="col-lg-6">
                                        <div className="mb-2 d-flex flex-column">
                                            <label htmlFor="stay-security-deposit" className="form-label">
                                                Security deposit(Optional)
                                            </label>
                                            <input
                                                // type="text"
                                                // min={0}
                                                placeholder="Enter security deposit"
                                                id="stay-security-deposit"
                                                name="securityDeposit"
                                                className="textFiled"
                                                value={formData?.stay?.securityDeposit || "" }
                                                onChange={(e) => {
                                                    const regex= /^[1-9][0-9]*$/;
                                                    if (e.target.value === "" || regex.test(e.target.value)) {
                                                        onChangeInput(e,'stay')
                                                    }
                                                }}
                                            />
                                        </div>
                                    </div> */}
                                    {/* <div className="col-lg-6 mt-2">
                                        <div className="mb-2 d-flex justify-content-between">
                                            <label className="form-label">
                                            Catering Service Available
                                            </label>
                                            <div className="d-flex">
                                                <div className="d-flex">
                                                    <input
                                                        type="checkbox"
                                                        className="form-check-input"
                                                        name='catering'
                                                        id='stay-catering-yes'
                                                        checked={formData?.stay?.catering}
                                                        onChange={() => {
                                                            setFormData({
                                                                ...formData,
                                                                stay : {
                                                                    ...formData.stay,
                                                                    catering : true,
                                                                }
                                                            })
                                                        }}
                                                    />
                                                    <label htmlFor="stay-catering-yes" className="me-2">Yes</label>
                                                </div>
                                                <div className="d-flex">
                                                    <input
                                                        type="checkbox"
                                                        className="form-check-input"
                                                        name='catering'
                                                        id='stay-catering-no'
                                                        checked={!formData?.stay?.catering}
                                                        onChange={() => {
                                                            setFormData({
                                                                ...formData,
                                                                stay : {
                                                                    ...formData.stay,
                                                                    catering : false,
                                                                }
                                                            })
                                                        }}
                                                    />
                                                    <label htmlFor='stay-catering-no'>No</label>
                                                </div>
                                            </div>
                                        </div>
                                    </div> */}
                                </div>
                            </Card.Body>
                        </Accordion.Collapse>
                    </Card>
        </Accordion>
    )
}
// Accordian End

    return(
        <>
            <div className="locationtype-page container-fluid " >
                <h1 className="locationtype-head" > Pricing & Timings {' '}
                    <a
                        href="#"
                        data-bs-toggle="tooltip"
                        title="Competitive rates, specify availability, and check-in/check-out times."
                    >
                        <img src={info} />
                    </a>
                </h1>
                <div className="row tabCard">
                    <div className="col-lg-8">
                        <div className="mb-3 d-flex flex-column" id = "PricingCurrencyId">
                            <label htmlFor="currency" className="form-label">
                                Select currency<span className="required">*</span>
                            </label>
                            <select
                                required
                                id="currency"
                                name="currency"
                                className="textFiledSelect"
                                value={currency}
                                onChange={(e) => {
                                    setCurrency(e.target.value)
                                    setCurrencyError(false)
                                }}
                            >
                                <option hidden selected value="">Select currency</option>
                                <option value='INR'>INR</option>
                                <option value='USD'>USD</option>
                            </select>
                            {currencyError &&<span style={{color : 'red'}}>Select currency</span>}
                        </div>
                    </div>
                    <div className="col-lg-8">
                    {/* accordion start */}

                    {/* film start */}
                    {filmAccordian()}
                    {/* film end */}

                    {/* tv start */}
                    {tvAccordian()}
                    {/* tv end */}

                    {/* event start */}
                    {eventAccordian()}
                    {/* event end */}

                    {/* stay start */}
                    {stayAccordian()}
                    {/* stay end */}
                    {/* accordion end */}
                    </div>
                </div>
            </div>
            {/* <div className="col-lg-8 dashboardPopretySaveContainer">
                <div className="d-flex flex-row justify-content-between align-center">
                    <button
                    type="button"
                    className="btn btn-secondary"
                    onClick={handleBack}
                    >
                        Back
                    </button>
                </div>
                <div className="d-flex flex-row justify-content-between align-center">
                    <button
                    type="button"
                    className="btn btn-primary"
                    disabled={!(Object.keys(formData).length > 0)}
                    onClick={handleSubmit}
                    >
                        Save & Continue
                    </button>
                </div>
            </div> */}
            {/* Footer Start */}
            <div className="row controlContainer">
                    <div className="col-lg-8">
                    {/* <div className=""> */}
                        <div className="d-flex flex-row justify-content-between align-items-center">
                            <div>
                                <button
                                type="button"
                                className="btn btn-secondary"
                                onClick={handleBack}
                                >
                                    Back
                                </button>
                            </div>
                            <div>
                                <button
                                 type="button"
                                 className="btn btn-primary"
                                 disabled={!(Object.keys(formData).length > 0)}
                                 onClick={handleSubmit}
                                >
                                    Save & Continue
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            {/* Footer End */}
            {showConfirmationModel && (
                <ConfirmationModel
                show={showConfirmationModel}
                handleClose={() => setShowConfirmationModel(false)}
                handleOk={() => setShowConfirmationModel(false)}
                title={confirmationTitle}
                body={confirmationBody}
                />
            )}
        </>
    )
}
export default Pricing;