import { Button, Modal } from "react-bootstrap";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import { useState } from "react";

import { createLookup } from "../../apis/api";

const { Header, Footer, Title, Body } = Modal;
export function LocationType({ handleClose, handleOk, show, data }) {
  const [newLocationType, setNewLocationType] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  const handleSubmit = async () => {
    try {
      const payload = {
        type: "location_type",
        name: newLocationType.trim(),
        value: newLocationType.trim(),
      };
      const resp = await createLookup(payload);
      if (resp?.createdAt) {
        handleOk(newLocationType.trim());
      }
    } catch (error) {
      if (error?.response?.data?.error === "Apartment already exists") {
        handleOk(newLocationType.trim())
      }
      else {
        alert(error?.response?.data?.error);
      }
    }
  };

  return (
    <Modal show={show} onHide={handleClose}>
      <Header closeButton>
        <Title>Location type</Title>
      </Header>
      <Body className="verify-email-modal-body">
        <p>Enter new location type</p>
        {/* <input type="text"
          value={newLocationType}
          onChange={(e) => {
            setNewLocationType(e.target.value);
          }}
        /> */}
        <Autocomplete
          name="location_type"
          value={""}
          disableClearable={true}
          onChange={(event, newValue) => {
            console.log(newValue)
            if (newValue) {
              handleOk(newValue);
            }
          }}
          title="Input only takes alphabets"
          open={newLocationType.length > 0}
          inputValue={newLocationType}
          onInputChange={(event, newInputValue) => {
            console.log(newInputValue)
            const reqValue = newInputValue.charAt(0).toUpperCase() + newInputValue.slice(1);
            const isValid = /^[a-zA-Z\s]+$/.test(reqValue);
            if (isValid || newInputValue === "") {
              setNewLocationType(reqValue);
              setErrorMessage("");
            } else {
              setErrorMessage("Location type takes only alphabets");
            }
          }}
          id="free-solo-demo"
          freeSolo
          options={data.map((option) => option.value)}
          renderInput={(params) => <TextField {...params} />}
        />
      </Body>
      <Footer className="mt-5">
        {errorMessage && <p className="text-danger"> {errorMessage} </p>}
        <Button variant="secondary" onClick={handleClose}>
          Cancel
        </Button>
        <Button variant="primary" onClick={handleSubmit}>
          Submit
        </Button>
      </Footer>
    </Modal>
  );
}
