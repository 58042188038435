import { useState } from 'react';
import amenityArrow from '../assets/images/amenityArrow.svg'
import amenitiesImage from '../assets/images/amenities1.jpeg'
import { AmenitiesModal } from "../Modals/AmenitiesModal";

const Amenities  = ({image,data, defaultAmenities}) => {

    const [showModal,setShowModal] = useState(false);
    const [modalTitle,setModalTitle] = useState('');
    const [modalContent,setModalContent] = useState([]);

    const activeModal = () => {
        setModalTitle('All Amenities')
        setModalContent([...data])
        setShowModal(true)
    }

    return(
        <div className="amenitiesMainContainer">
            <div className='amenitiesTextConatainer'>
                <span className='amenitiesHeading SpotLetMainHead' id='propertyPage1'> All Amenities</span>
            {/* { data?.length > 0 && */}
                <>
                    <div className='row w-100'>
                        {data?.slice(0,12)?.map((amenity,i) => {
                            return(
                                <div className='col-lg-6 col-md-6 mb-2'>
                                    <img src={amenityArrow} alt='amenityArrow'/>
                                    <span className='amenityListText SpotLetContent'>{amenity?.name}</span>
                                    {/* {amenity?.sub && (
                                        <ul>
                                            {amenity?.sub?.map((sub)=> (
                                                <li>{sub.value}</li>
                                            ))}
                                        </ul>
                                    )} */}
                                </div>
                            )
                        })}
                        { defaultAmenities?.length > 0 && 
                        defaultAmenities?.map((amenity,i) => {
                            return(
                                <div className='col-lg-6 col-md-6 mb-2'>
                                    {/* <img src={amenityArrow} alt='amenityArrow'/>   */}
                                    <span className='amenityListText defaultAmenityListText SpotLetContent' style={{marginLeft:"21px"}} >{amenity?.name}</span>
                                    {/* {amenity?.sub && (
                                        <ul>
                                            {amenity?.sub?.map((sub)=> (
                                                <li>{sub.value}</li>
                                            ))}
                                        </ul>
                                    )} */}
                                </div>
                            )
                        })}
                        </div>
                    { data?.length > 0 && 
                        <button className='AmenitiesShowAll SpotLetContent' onClick={activeModal}>
                            Show All
                        </button>
                    }
                    </>
                {/* } */}
                {/* {
                    (data?.length === 0 || !data)   &&
                        <h4 className='mt-3 SpotLetSubHead ' > Not Available </h4>
                } */}
            </div>
            <div className="vectorImage">
                <img src={amenitiesImage} alt='amenities' />
            </div>
            {showModal && (
                <AmenitiesModal
                    show={showModal}
                    onHide={() => setShowModal(false)}
                    title={modalTitle}
                    body={modalContent}
                />
            )}
        </div>
    )
}
export default Amenities;