import React, {useEffect, useState} from 'react'


export const LeadsPopupData = ({handleClose, filterFormData, reqFilterData}) => {

    const [start, setStart] = useState(null)
    const [end, setEnd] = useState(null)
    const [count, setCount] = useState(null)
    const [skip, setSkip] = useState(0)

    const onHandleClose = () => {
        handleClose()
    }

    const resetData = () => {
        const el1  = document.getElementById('paymentDetailsDateStart')
        if(el1) el1.value = null
        const el2  = document.getElementById('paymentDetailsDateEnd')
        if(el2) el2.value = null
        const el3  = document.getElementById('paymentDetailsCount')
        if(el3) el3.value = null
        setStart(null);
        setEnd(null);
        // setCount(null);
        // setSkip(0);
        filterFormData({start :null,end :null}); // , null, 0
        }
    
      const filterData = (e) => {
        e.preventDefault()
        filterFormData({start,end}); //  count, skip
      }
    

    useEffect(() => {
        if (reqFilterData?.start) {
            setStart(reqFilterData?.start)
        }
        if (reqFilterData?.end) {
            setEnd(reqFilterData?.end)
        }
    },[] )
    
    return(
        // {/* begin::SubMenu */}
    //   <div className='menu menu-sub menu-sub-dropdown w-100' data-kt-menu='true'>
    <div className='leads-filter-container'>
        {/* begin::Header */}
        <div>
          <div className='fs-5 text-dark fw-bolder'>Filter Options</div>
        </div>
        {/* end::Header */}

        {/* begin::Separator */}
        <div className='separator border-gray-200'></div>
        {/* end::Separator */}

        {/* begin::Content */}
        <div className=''  id='paymentFilterForm'>
          <div className='mt-2'>
            <label className='form-label fs-6 fw-bold'>Date from</label>
            <input className='form-control form-control-solid' id='paymentDetailsDateStart'  type='date' max={ start ? new Date().toISOString().split("T")[0] : new Date().toISOString().split("T")[0]}  
            onChange={(e) => {
              setStart(e.target.value)
              setEnd(null)
              if (!e.target.value) {
                setStart(null)
                setEnd(null)
              }
              const el  = document.getElementById('paymentDetailsDateEnd')
              if(el) el.value = null
              }} 
              value={start}
              />
          </div>
          <div className='mt-2'>
            <label className='form-label fs-6 fw-bold'>Date to</label>
            <input className='form-control form-control-solid' id='paymentDetailsDateEnd'  type='date' min={new Date(start).toISOString().split("T")[0]} 
            max={end ? new Date().toISOString().split("T")[0] :  new Date().toISOString().split("T")[0] }
              onChange={(e) => {
                setEnd(e.target.value)
                if (!e.target.value) {
                    setEnd(null)
                }
              }} 
              value={end}
            />
          </div>
          {/* <div className='mb-10'>
            <label className='form-label fs-6 fw-bold'>Count</label>
            <input className='form-control form-control-solid' id='paymentDetailsCount'  type='number'  onChange={(e) => setCount(e.target.value)} value={count}/>
          </div> */}
          {/* <div className='mb-10'>
            <label className='form-label fs-6 fw-bold'>Skip</label>
            <input className='form-control form-control-solid'  type='number'  onChange={(e) => setSkip(e.target.value)} value={skip}/>
          </div> */}
          <div className='d-flex justify-content-end mt-2'>
            <button
              type='button'
              //disabled={isLoading}
              onClick= { () => {
                onHandleClose()
                resetData()
              }  } //{resetData}
              className='btn btn-light btn-active-light-primary fw-bold me-2 px-6'
              data-kt-menu-dismiss='true'
              data-kt-user-table-filter='reset'
            >
              Reset
            </button>
            <button
              //disabled={isLoading}
              type='button'
              onClick={(e) => {
                onHandleClose()
                filterData(e)
              } }
              className='btn btn-primary fw-bold px-6'
              data-kt-menu-dismiss='true'
              data-kt-user-table-filter='filter'
            >
              Apply
            </button>
          </div>
        </div>
        {/* end::Content */}
      </div>
    //   {/* end::SubMenu */}
    )
  }