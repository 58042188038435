import { useEffect, useState } from "react";
import timingsImg from "../../assets/images/timings.png"

const SubPropertyTimings = ({timings}) => {

    const [type, setType] = useState("");
    const [timingsDetails, setTimingsDetails] = useState();
    const [dropDownOptions,setDropDownOptions] = useState([]);

    const  getTypes = () => {
        if (timings) {
            const reqTypes = []
            Object.keys(timings).map((each) => {
                if(timings?.[each]?.available && !(dropDownOptions.includes(each))) {
                    if (!reqTypes?.includes(each)) {
                        reqTypes.push(each);
                       }
                    // setDropDownOptions((prev) => {
                    //        if (!reqTypes?.includes(each)) {
                    //         reqTypes.push(each);
                    //        }
                    //         // return [...prev,each]
                    // })
                    if(reqTypes.length > 0) {
                        setType(reqTypes[0])
                        setDropDownOptions([...dropDownOptions, ...reqTypes]);
                        setTimingsDetails(timings?.[reqTypes[0]]?.timings)
                    }
                }
            } )
        }
    }


    useEffect(() => {
        getTypes();
    }, [timings] )
    

    const onChangeTypeofEvent = (value) => {
        setType(value);
        Object.keys(timings).map((each) => {
            if (each === value) {
                setTimingsDetails(timings?.[each]?.timings)
            }
        } )
    } 

    return (
        <div className="timingsMainContainer" style={{padding:"15px 0px 0px 0px"}} >
            <span className="galleryHeading">Timings</span>
            <div className="timingsCard col-11 col-md-10 " >
                <div className="timingsTopSection" >
                    <h3  className="timingsTopSectionHeading" > Our Timings </h3>
                    <select 
                                value={type}
                                required
                                name = "timings_dropdown" 
                                className="mx-2 textFiled p-0 timingsSelect" 
                                style={{width : "170px"}} 
                                onChange={(e) =>
                                    onChangeTypeofEvent(e.target.value)
                                } 
                    >
                                {
                                    dropDownOptions?.map((each) => {
                                        return(
                                            <option value = {each}> Timings for {each[0].toUpperCase()+each.slice(1).toLowerCase()} </option>
                                        )
                                    } )
                                }
                            </select>
                </div>
                <hr className="w-100"/>
                {type && <h1 className="timingsBottomSectionHead text-center" > Timings for {type[0].toUpperCase()+type.slice(1).toLowerCase()}  {(type === "film" || type === "tv") && " Shootings" }  </h1>}
                <div className="timingsBottomSection" >
                    {/* <img src={timingsImg} className="timingsImage col-xs-11 col-10 col-xl-5 mb-3" alt = "timings image" />  */}
                    <div className="timingsDisplayCard col-xs-11 col-10 col-xl-6" >
                        {
                            timingsDetails && 
                            // Object.keys(timingsDetails).map((eachDay) => {
                                // return (
                                    <>
                                        <div className="timingsEachDay" >
                                            <p className="mb-0 timingsDay " > Sunday </p>
                                            <p className="mb-0 timingsDay " >{ 
                                                timingsDetails?.sunday ?
                                                    timingsDetails?.sunday?.fullDay ? 
                                                    type === "stay" ? "12:00pm - 11:00am" : 'All Day'
                                                    : `${timingsDetails?.sunday?.timings?.open} -  ${timingsDetails?.sunday?.timings?.close}` 
                                                : "Closed" }</p>
                                        </div>
                                        <hr/>
                                        <div className="timingsEachDay" >
                                            <p className="mb-0 timingsDay " > Monday </p>
                                            <p className="mb-0 timingsDay " >{ 
                                                timingsDetails?.monday ?
                                                    timingsDetails?.monday?.fullDay ? 
                                                    type === "stay" ? "12:00pm - 11:00am" : 'All Day'
                                                    : `${timingsDetails?.monday?.timings?.open} -  ${timingsDetails?.monday?.timings?.close}` 
                                                : "Closed" }</p>
                                        </div>
                                        <hr/>
                                        <div className="timingsEachDay" >
                                            <p className="mb-0 timingsDay " > Tuesday </p>
                                            <p className="mb-0 timingsDay " >{ 
                                                timingsDetails?.tuesday ?
                                                    timingsDetails?.tuesday?.fullDay ? 
                                                    type === "stay" ? "12:00pm - 11:00am" : 'All Day'
                                                    : `${timingsDetails?.tuesday?.timings?.open} -  ${timingsDetails?.tuesday?.timings?.close}` 
                                                : "Closed" }</p>
                                        </div>
                                        <hr/>
                                        <div className="timingsEachDay" >
                                            <p className="mb-0 timingsDay " > Wednesday </p>
                                            <p className="mb-0 timingsDay " >{ 
                                                timingsDetails?.wednesday ?
                                                    timingsDetails?.wednesday?.fullDay ? 
                                                    type === "stay" ? "12:00pm - 11:00am" : 'All Day'
                                                    : `${timingsDetails?.wednesday?.timings?.open} -  ${timingsDetails?.wednesday?.timings?.close}` 
                                                : "Closed" }</p>
                                        </div>
                                        <hr/>
                                        <div className="timingsEachDay" >
                                            <p className="mb-0 timingsDay " > Thursday </p>
                                            <p className="mb-0 timingsDay " >{ 
                                                timingsDetails?.thursday ?
                                                    timingsDetails?.thursday?.fullDay ? 
                                                    type === "stay" ? "12:00pm - 11:00am" : 'All Day'
                                                    : `${timingsDetails?.thursday?.timings?.open} -  ${timingsDetails?.thursday?.timings?.close}` 
                                                : "Closed" }</p>
                                        </div>
                                        <hr/>
                                        <div className="timingsEachDay" >
                                            <p className="mb-0 timingsDay " > Friday </p>
                                            <p className="mb-0 timingsDay " >{ 
                                                timingsDetails?.friday ?
                                                    timingsDetails?.friday?.fullDay ? 
                                                    type === "stay" ? "12:00pm - 11:00am" : 'All Day'
                                                    : `${timingsDetails?.friday?.timings?.open} -  ${timingsDetails?.friday?.timings?.close}` 
                                                : "Closed" }</p>
                                        </div>
                                        <hr/>
                                        <div className="timingsEachDay" >
                                            <p className="mb-0 timingsDay " > Saturday </p>
                                            <p className="mb-0 timingsDay " >{ 
                                                timingsDetails?.saturday ?
                                                    timingsDetails?.saturday?.fullDay ? 
                                                    type === "stay" ? "12:00pm - 11:00am" : 'All Day'
                                                    : `${timingsDetails?.saturday?.timings?.open} -  ${timingsDetails?.saturday?.timings?.close}` 
                                                : "Closed" }</p>
                                        </div>
                                        {/* <hr/> */}
                                    </>
                                // )
                            // } )
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}

export default SubPropertyTimings