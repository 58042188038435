import { Header } from "../components/NewHeader/Header";
import { Sidebar } from "../components/Sidebar";
import { useAuthStore } from "../store/auth";
import { useEffect, useState } from "react";
import { useNavigate } from 'react-router-dom';
import { deActiveUser, updateUserDetails, updatePassword, uploadPics, deActivateRequest, deactivateAccount } from "../apis/api";
import { Formik } from "formik";
import { Button, Col, Form, Row } from "react-bootstrap";

import { ConfirmationModel } from '../components/Modal/ConfirmationModel';
import { DeactivateRequestModel } from "../components/Modal/DeactivateRequestModel";
import { ActiveLisitngModel } from "../components/Modal/ActiveLisitngModel";
import * as yup from "yup";

// Images
import addImage from "../assets/NewImages/EditProfile/add-image.svg";
import verify from "../assets/NewImages/EditProfile/verify.svg";
import pwdHide from "../assets/NewImages/EditProfile/pwd-hide.svg";
import pwdShow from "../assets/NewImages/EditProfile/pwd-show.svg";

// Mobile Number
import PhoneInput, {isValidPhoneNumber} from 'react-phone-number-input'
import 'react-phone-number-input/style.css';

//whatsApp Verification 
import { WhatsAppVerificationModal } from "../components/Modal/WhatsAppVerificationModal";
import { EmailVerificationModal } from "../components/Modal/EmailVerificationModal";

const schema = yup.object().shape({
  currentPassword: yup
    .string()
    .required("Current password is required"),
  password: yup
    .string()
    .required("New password is required")
    .min(8, "Password must be 8 characters long")
    .matches(/[0-9]/, "Password requires a number")
    .matches(/[a-z]/, "Password requires a lowercase letter")
    .matches(/[A-Z]/, "Password requires an uppercase letter")
    .matches(/[^\w]/, "Password requires a symbol"),
  confirmPassword: yup
    .string()
    .required("Please retype your password.")
    .oneOf([yup.ref("password")], "Your passwords do not match."),
});

export const EditProfile = () => {
  const { userProfile, setUserProfile, setAuthenticated, setUserProfileNull } = useAuthStore();
  const navigate = useNavigate();

  const [details, setDetails] = useState({
    fullName: "",
    // lastName: "",
    email: "",
    mobile: "",
    profile_pic: "",
  });

  const [files, setFiles] = useState({});
  const [isImageChanged, setIsImageChanged] = useState(false);

  const [isMobileValid, setMobileValid] = useState(true);
  const [numberError, setNumberError] = useState("");
  const [emailError, setEmailError] = useState("");
  const [number, setNumber] = useState(userProfile?.personalInfo.mobile || undefined);
  const [showWhatsAppModal, setShowWhatsAppModal] = useState(false)
  const [ShowEmailVerificationModal, setShowEmailVerificationModal] = useState(false)

  //CONVERT IMG TO URL
  function urltoFile(url, filename, mimeType) {
    return fetch(url)
      .then(function (res) {
        return res.arrayBuffer();
      })
      .then(function (buf) {
        return new File([buf], Date.now() + filename, { type: mimeType });
      });
  }

  const onUploadFile = (event) => {
    if (event.target.files && event.target.files.length > 0) {
      const file = event.target.files[0];

      const reader = new FileReader();
      reader.onload = (e) => {
        const imageObj = {
          file,
          imageSrc: e.target.result,
          croppedImage: "",
          watermarkImage: "",
          preview: false,
          uploaded: false,
        };
        setFiles({ uploadedImage: imageObj });
        setIsImageChanged(true);
      };
      reader.readAsDataURL(file);
    }
  };



  useEffect(() => {
    if (JSON.stringify(files) === "{}") { return }
    handleUpload()
  }, [files])

  const handleUpload = async () => {
    try {
      const fileNames = Object.keys(files);
      for (let i = 0; i < fileNames.length; i++) {
        const flobj = files[fileNames[i]];
        if (flobj.uploaded) continue;
        const file = await urltoFile(
          flobj.imageSrc,
          fileNames[i],
          "text/plain"
        );
        const formData = new FormData();
        formData.append("pic", file);
        const response = await uploadPics(formData);
        setDetails({ ...details, profile_pic: response.data.url, fullName : details?.fullName?.trim() });
        setFiles((prevData) => {
          prevData[fileNames[i]].uploaded = true;
          return { ...prevData };
        });

      }
    } catch (error) {
      console.log(error);
    }
  }

  useEffect(() => {
    if (userProfile) {
      setDetails({
        fullName: userProfile?.personalInfo.fullName ?? "",
        // lastName: userProfile?.personalInfo.lastName ?? "",
        email: userProfile?.personalInfo.email ?? "",
        mobile: userProfile?.personalInfo.mobile ?? "",
        profession: userProfile?.personalInfo.profession ?? "",
        booking_type: userProfile?.personalInfo.booking_type ?? "",
        googleLogin :  userProfile?.personalInfo?.googleLogin ||  false,
        emailVerified: (userProfile?.personalInfo.emailVerified || userProfile?.personalInfo?.googleLogin) ?? false,
        mobileVerified : userProfile?.personalInfo?.mobileVerified ||  false,
        // emailVerified: userProfile?.personalInfo.emailVerified || false,
        profile_pic: userProfile?.personalInfo.profile_pic ?? "",
      });
    }
  }, [userProfile]);

  const [hidePassword, setHidePassword] = useState(false);
  const [hideConfirmPassword, setHideConfirmPassword] = useState(false);


  const [showConfirmationModel, setShowConfirmationModel] = useState(false);
  const [confirmationTitle, setConfirmationTitle] = useState("");
  const [confirmationBody, setConfirmationBody] = useState("");
  const [showConfirmationModel1, setShowConfirmationModel1] = useState(false);
  const [confirmationTitle1, setConfirmationTitle1] = useState("");
  const [confirmationBody1, setConfirmationBody1] = useState("");

  const [showConfirmationModel2, setShowConfirmationModel2] = useState(false);
  const [confirmationTitle2, setConfirmationTitle2] = useState("");
  const [confirmationBody2, setConfirmationBody2] = useState("");

  const [showDeactivateModel, setShowDeactivateModel] = useState(false);
  const [showActiveLisitngModel, setShowActiveLisitngModel] = useState(false);

  const [fullNameError, setFullNameError] = useState("");
  const [hideMainPassword, setHideMainPassword] = useState(false);

  const handleChange = (event) => {
    const { name, value } = event.target;
    setDetails({ ...details, [name]: value });
  };

  const handleChangeMobile = (event) => {
    if (event.target.value.length === 10) {
      setMobileValid(true)
      handleChange(event)
    } else {
      setMobileValid(false)
      handleChange(event)
    }
  }

  const updateDetails = async () => {
    try {
      if (details?.fullName === "") {
        setFullNameError("Full Name is required");
        return null
      } else if (details?.fullName.length < 3) {
        setFullNameError("Full Name must contain minimum 3 characters");
        return null
      }
      else {
        setFullNameError("");
      }
      if ( (details?.email !== "") && (!details?.emailVerified) ) {
        setEmailError("Please verify email first");
        return null
      } else {
        setEmailError("")
      }
      let reqNumber
      if (number === undefined) {
        reqNumber = ""
        setNumberError("");
        await updateUserDetails(userProfile._id, {...details, mobile : reqNumber, fullName : details?.fullName?.trim() });
        setConfirmationTitle("Edit Profile");
        setConfirmationBody("Details Updated Successfully");
        setShowConfirmationModel(true);
        setUserProfile();
      }
      else{
        reqNumber = number 
        if (number.slice(0,4) === "+911") {
          setNumberError("Enter a Valid Phone Number")
        }
        else{
          if (isValidPhoneNumber(reqNumber) && !details?.mobileVerified) {
            setNumberError("Please verify mobile first");
            return null;
          }
          else if(isValidPhoneNumber(reqNumber) || reqNumber === "" ) {
            setIsImageChanged(false);
            setNumberError("");
            try {
              await updateUserDetails(userProfile._id, {...details, mobile : reqNumber, fullName : details?.fullName?.trim()});
              setConfirmationTitle("Edit Profile");
              setConfirmationBody("Details Updated Successfully");
              setShowConfirmationModel(true);
              setUserProfile();
            } catch (e) {
              console.log(e);
            }
          }
          else{
            setNumberError("Enter a Valid Phone Number")
          }
        }
      }
    } catch (error) {
      console.log(error)
    }
  };

  const onDeActive = async () => {
    try {
      await deActiveUser(userProfile._id);
      setConfirmationTitle("Edit Profile");
      setConfirmationBody("Deactivation Request Sent Successfully");
      setShowConfirmationModel(true);
    } catch (error) {
      setConfirmationTitle("Edit Profile");
      setConfirmationBody("Deactivation Request already sent");
      setShowConfirmationModel(true);
    }
  };

  const onDeActiveNew = async () => {
    try {
      const response = await deActivateRequest(userProfile._id)
      if (response.data.listing > 0) {
        setShowActiveLisitngModel(true)
      } else {
        setShowDeactivateModel(true)
      }
    } catch (error) {
      console.log(error);
      setConfirmationTitle2("Deactivate");
      setConfirmationBody2(error?.response?.data?.message);
      setShowConfirmationModel2(true);
    }
  }

  const handleOk = async (data) => {
    try {
      setShowDeactivateModel(false)
      const res = await deactivateAccount(userProfile._id, data)
      setConfirmationTitle1("Edit Profile");
      setConfirmationBody1(res?.data?.message);
      setShowConfirmationModel1(true);
    } catch (error) {
      console.log(error)
    }
  }
  const handleEvent = () => {
    setShowConfirmationModel(false)
    localStorage.removeItem("auth");
    setAuthenticated();
    setUserProfileNull()
    navigate("/")
  }

  const onSubmit = async (values, { resetForm }) => {
    try {
      const res = await updatePassword(userProfile._id, values);
      setConfirmationTitle("Update Password");
      setConfirmationBody(res?.data?.message);
      setShowConfirmationModel(true);
      resetForm()
    } catch (error) {
      setConfirmationTitle("Error");
      setConfirmationBody(error?.response?.data?.message);
      setShowConfirmationModel(true);
    }
  };

  const onClickWhatsAppNumberVerify = () => {
    let reqNumber
    if (number === undefined) {
      reqNumber = ""
      setNumberError("Number field should not be empty");
      return null 
    }
    else{
      reqNumber = number 
      if (number.slice(0,4) === "+911") {
        setNumberError("Enter a Valid Phone Number")
      }
      else{
        if(isValidPhoneNumber(reqNumber) || reqNumber === "" ) {
          setIsImageChanged(false);
          setNumberError("");
          setShowWhatsAppModal(true);
        }
        else{
          setNumberError("Enter a Valid Phone Number")
        }
      }
    }
  }

  const emailValidityCheck = (email) => {
    const pattern = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/;
    if (!email) {
      setEmailError("Email feild should not be empty")
      return false
    }
    if (!(pattern.test(email))) {
      setEmailError("Please entered a valid email")
      return false
    }
    setEmailError("")
    return true
  }

  const onClickEmailAddressVerify = () => {
    const isEmailValid = emailValidityCheck(details?.email)
    if (!isEmailValid) return null
    setShowEmailVerificationModal(true)
  }

  const onWhatsAppVerificationSuccess = (value) => {
      if (value === "true") {
        setConfirmationTitle("Edit Profile");
        setConfirmationBody("Mobile Number Verified Successfully");
        setShowConfirmationModel(true);
        setUserProfile()
      } else {
        setConfirmationTitle("Error");
        setConfirmationBody("Error while verifying Mobile Number");
        setShowConfirmationModel(true);
      }
  }
  const onEmailVerificationSuccess = (value) => {
      if (value === "true") {
        setConfirmationTitle("Edit Profile");
        setConfirmationBody("Email Verified Successfully");
        setShowConfirmationModel(true);
        setUserProfile()
      } else {
        setConfirmationTitle("Error");
        setConfirmationBody("Error while verifying Email");
        setShowConfirmationModel(true);
      }
  }

  return (
    <>
      <Header />
      <div className="container-fluid p-0">
        <div className="row g-0">
          <div className="d-flex edit-profile">
            <div className="col-lg-2 w-200">
              <Sidebar />
            </div>
            <div className="col-lg-10">
              <div className="row g-0 h-100">
                <div className="col-xl-8">
                  <div className="h-100 needs-validation">
                    <div className="step-form h-100">
                      <div className="e-profile">
                        <h3 className="etitle">Edit Profile</h3>
                        <div className="row">
                          <div className="col-lg-12">
                            <div className="avatar-upload">
                              <div className="avatar-edit">
                                <input
                                  type="file"
                                  id="imageUpload"
                                  accept=".webp, .webp, .jpeg"
                                  onChange={onUploadFile}
                                />
                                <label htmlFor="imageUpload">
                                  <img src={addImage} />
                                </label>
                              </div>
                              <div className="avatar-preview">
                                <div
                                  id="imagePreview"
                                  className="avatar-image" style={{ alignItems: "center" }}
                                ><div className="avatar-preview">
                                    {details.profile_pic && (
                                      <img className="avatar-preview" src={details.profile_pic} alt="Profile Picture" />
                                    )}
                                  </div></div>
                              </div>
                            </div>
                          </div>

                          <div className="col-lg-5">
                            <div className="mb-4">
                              <label
                                htmlFor="exampleInputEmail1"
                                className="form-label "
                              >
                                Full Name<span className="required" >*</span>
                              </label>
                              <input
                                type="text"
                                required
                                pattern = "^(?!.*\s{2}).*$"
                                className="form-control"
                                id="fname"
                                name="fullName"
                                value={details.fullName}
                                onChange={handleChange}
                              />
                            {fullNameError && <span className="required"> {fullNameError} </span>}
                            </div>
                          </div>
                          <div className="col-lg-5"></div>
                          {/* <div className="col-lg-5">
                            <div className="mb-4">
                              <label
                                htmlFor="exampleInputEmail1"
                                className="form-label "
                              >
                                Last Name<span></span>
                              </label>
                              <input
                                type="text"
                                className="form-control"
                                id="lname"
                                name="lastName"
                                value={details.lastName}
                                onChange={handleChange}
                              />
                            </div>
                          </div> */}
                          <div className="col-lg-5">
                            <div className="mb-4">
                              <label
                                htmlFor="exampleInputEmail1"
                                className="form-label position-relative w-100"
                              >
                                Email address
                                {details?.emailVerified ?
                                  <span className="sky-txt"><img src={verify} alt="" />Verified</span>
                                  :
                                  <button className="btn verify-btn" onClick={onClickEmailAddressVerify}>Verify</button>
                                }
                              </label>
                              <input
                                type="email"
                                className="form-control"
                                id="email"
                                name="email"
                                value={details.email}
                                onChange={(e) => {
                                  handleChange(e);
                                  setEmailError("");
                                } }
                                disabled={details?.emailVerified}
                              />
                              {
                                  emailError && <p style={{color:"Red", fontSize:"14.5px"}} className="m-0" > {emailError} </p> 
                              }
                            </div>
                          </div>
                          <div className="col-lg-5">
                            <div className="mb-4">
                              <label
                                htmlFor="exampleInputEmail1"
                                className="form-label position-relative w-100"
                              >
                                Mobile Number
                                {details?.mobileVerified ?
                                  <span className="sky-txt"><img src={verify} alt="" />Verified</span>
                                  :
                                  <button className="btn verify-btn" type="button" onClick={onClickWhatsAppNumberVerify} >Verify</button>
                                }
                              </label>
                              {/* <input
                                type="number"
                                className={isMobileValid ? 'form-control' : 'form-control-invalid'}
                                id="phone"
                                value={details.mobile}
                                onChange={handleChangeMobile}
                                name="mobile"
                              />
                              {isMobileValid ? '' : <p style={{ color: '#dc3545' }}>Phone Number Must Be 10 Digits</p>} */}
                               <PhoneInput
                                    // required
                                    international
                                    countryCallingCodeEditable={false}
                                    flags
                                    placeholder="Enter phone number"
                                    style={{backgroundColor : "#ffffff", border: "1px solid #dddddd", borderRadius : "10px", height:"50px", marginBottom : "0px", paddingLeft : "15px" }}
                                    value={details?.mobile}
                                    defaultCountry="IN"
                                    onChange={(e) => {
                                      setNumber(e);
                                      setNumberError("");
                                    }}
                                    readOnly = {details?.mobileVerified ? true : false} 
                                />
                                {
                                    numberError && <p style={{color:"Red", fontSize:"14.5px"}} className="m-0" > {numberError} </p> 
                                }
                            </div>
                          </div>
                          {/* <div className="col-lg-5">
                            <div className="mb-4">
                              <label
                                htmlFor="exampleFormControlSelect1"
                                className="form-label"
                              >
                                Registered as a<span></span>
                              </label>
                              <div className="form-group">
                                <select
                                  className="form-control"
                                  style={{appearance: 'auto' }}
                                  id="exampleFormControlSelect1"
                                  value={details.booking_type}
                                  onChange={handleChange}
                                  name="booking_type"
                                >
                                  <option>Select Registered as a</option>
                                  <option value="host">Host</option>
                                  <option value="user">User</option>
                                </select>
                                <i className="bi bi-caret-down-fill arrow"></i>
                              </div>
                            </div>
                          </div>
                          <div className="col-lg-5">
                            <div className="mb-4">
                              <label
                                htmlFor="exampleInputEmail1"
                                className="form-label "
                              >
                                Profession<span></span>
                              </label>
                              <div className="form-group">
                                <select 
                                  style={{appearance: 'auto' }}
                                  className="form-control"
                                  id="profession"
                                  value={details.profession}
                                  name="profession"
                                  onChange={handleChange}
                              >
                                  <option value="" selected hidden > Select Profession </option>
                                  <option value = "Property Owner"> Property Owner </option>
                                  <option value = "Agent"> Agent </option>
                                  <option value = "Manager"> Manager</option>
                                  <option value = "Housing Association"> Housing Association </option>
                                  <option value = "Tenant"> Tenant  </option>
                                </select>
                              </div>
                            </div>
                          </div> */}
                          <div className="button-bar">
                            <a
                              className="btn btn-theme btn-active"
                              onClick={updateDetails}
                            >
                              Apply Changes
                            </a>
                          </div>
                          {/* { (!userProfile?.personalInfo?.googleLogin && !userProfile?.personalInfo?.mobileVerified) && ( */}
                            <>
                              <hr />
                              <h3 className="etitle">
                                Change Password
                              </h3>
                              <Formik
                                validationSchema={schema}
                                onSubmit={onSubmit}
                                initialValues={{
                                  currentPassword: "",
                                  password: "",
                                  confirmPassword: "",
                                }}
                              >
                                {({
                                  handleSubmit,
                                  handleChange,
                                  values,
                                  touched,
                                  isValid,
                                  errors,
                                }) => (
                                  <Form noValidate onSubmit={handleSubmit}>
                                    <Form.Group
                                      as={Col}
                                      md="4"
                                      className="position-relative"
                                    >
                                      <Form.Label>
                                        Current Password<span>*</span>
                                      </Form.Label>
                                      <div className="form-floating mb-3">
                                        <Form.Control
                                        type={hideMainPassword ? "text" : "password"}
                                          name="currentPassword"
                                          value={values.currentPassword}
                                          style={{paddingBottom: '0px',paddingTop:'0px', paddingRight:"0px"}}
                                          onChange={handleChange}
                                          isInvalid={!!errors.currentPassword}
                                        />
                                        {touched.currentPassword && !!errors.currentPassword && (
                                          <Form.Control.Feedback type="invalid">
                                            {errors.currentPassword}
                                          </Form.Control.Feedback>
                                        )}
                                        <div className="input-group-addon">
                                              <i
                                                onClick={() =>
                                                  setHideMainPassword((hideMainPassword) => !hideMainPassword)
                                                }
                                                className={
                                                  hideMainPassword
                                                    ? "fa fa-eye pointer"
                                                    : "fa fa-eye-slash pointer"
                                                }
                                                aria-hidden="true"
                                              ></i>
                                            </div>
                                      </div>
                                    </Form.Group>
                                    <Row>
                                      <Form.Group
                                        as={Col}
                                        md="4"
                                        style={{paddingRight:"0px"}}
                                      >
                                        <Form.Label>
                                          New Password<span className="required">*</span>
                                        </Form.Label>
                                        <div className="form-floating mb-3">
                                          <Form.Control
                                            type={hidePassword ? "text" : "password"}
                                            name="password"
                                            value={values.password}
                                            onChange={handleChange}
                                            isInvalid={!!errors.password}
                                            style={{paddingBottom: '0px',paddingTop:'0px'}}
                                          />
                                          {/* <div className={
                                            hidePassword
                                              ? "pwd-ico show"
                                              : "pwd-ico"
                                          }
                                            onClick={() =>
                                              setHidePassword((hidePassword) => !hidePassword)
                                            }>
                                            <img src={pwdHide} className="hide-icon" alt="" />
                                            <img src={pwdShow} className="show-icon" alt="" />
                                          </div> */}
                                          {touched.password && !!errors.password && (
                                            <Form.Control.Feedback type="invalid">
                                              {errors.password}
                                            </Form.Control.Feedback>
                                          )}
                                          <div className="input-group-addon">
                                            <i
                                              onClick={() =>
                                                setHidePassword((hidePassword) => !hidePassword)
                                              }
                                              className={
                                                hidePassword
                                                  ? "fa fa-eye pointer"
                                                  : "fa fa-eye-slash pointer"
                                              }
                                              aria-hidden="true"
                                            ></i>
                                          </div>
                                        </div>
                                      </Form.Group>
                                      <Form.Group
                                        as={Col}
                                        md="4"
                                        style={{paddingRight:"0px"}}
                                      >
                                        <Form.Label>
                                          Confirm Password<span className="required">*</span>
                                        </Form.Label>
                                        <div className="form-floating mb-3">
                                          <Form.Control
                                            type={hideConfirmPassword ? "text" : "password"}
                                            name="confirmPassword"
                                            value={values.confirmPassword}
                                            onChange={handleChange}
                                            isInvalid={!!errors.confirmPassword}
                                            style={{paddingBottom: '0px',paddingTop:'0px'}}
                                          />
                                          {/* <div className={
                                            hideConfirmPassword
                                              ? "pwd-ico show"
                                              : "pwd-ico"
                                          }
                                            onClick={() =>
                                              setHideConfirmPassword((hideConfirmPassword) => !hideConfirmPassword)
                                            }>
                                            <img src={pwdHide} className="hide-icon" alt="" />
                                            <img src={pwdShow} className="show-icon" alt="" />
                                          </div> */}
                                          {touched.confirmPassword && !!errors.confirmPassword && (
                                            <Form.Control.Feedback type="invalid">
                                              {errors.confirmPassword}
                                            </Form.Control.Feedback>
                                          )}
                                          <div className="input-group-addon">
                                            <i
                                              onClick={() =>
                                                setHideConfirmPassword((hideConfirmPassword) => !hideConfirmPassword)
                                              }
                                              className={
                                                hideConfirmPassword
                                                  ? "fa fa-eye pointer"
                                                  : "fa fa-eye-slash pointer"
                                              }
                                              aria-hidden="true"
                                            ></i>
                                          </div>
                                        </div>
                                      </Form.Group>
                                    </Row>
                                    {/* <div className="button-bar mb-3">
                                      <a className="btn btn-theme btn-active" >UPDATE PASSWORD</a>
                                    </div> */}
                                    <div className="button-bar mb-3">
                                      <Button className="btn btn-theme btn-active" type="submit">UPDATE PASSWORD</Button>
                                    </div>
                                  </Form>
                                )}
                              </Formik>
                            </>
                          {/* )} */}
                          {/* <div class="col-lg-5">
                            <div class="mb-4">
                              <label htmlFor="exampleInputEmail1" class="form-label ">Current Password<span></span></label>
                              <input placeholder="Enter Current Password" type="text" class="form-control" id="fname" />
                            </div>
                          </div>
                          <div class="col-lg-5">
                            &nbsp;
                          </div>
                          <div class="col-lg-5">
                            <div class="mb-4">
                              <label htmlFor="exampleInputEmail1" class="form-label ">New Password<span></span></label>
                              <div class="position-relative">
                                <input placeholder="Enter New Password"
                                  type={hidePassword ? "text" : "password"}
                                  class="form-control" id="fname" />
                                <div className={
                                      hidePassword
                                        ? "pwd-ico show"
                                        : "pwd-ico"
                                    }
                                    onClick={() =>
                                      setHidePassword((hidePassword) => !hidePassword)
                                    }>
                                  <img src={pwdHide} class="hide-icon" alt="" />
                                  <img src={pwdShow} class="show-icon" alt="" />
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="col-lg-5">
                            <div class="mb-4">
                              <label htmlFor="exampleInputEmail1" class="form-label ">Confirm Password<span></span></label>
                              <div class="position-relative">
                                <input placeholder="Enter Confirm Password" type="text" class="form-control" id="lname" />
                                <div class="pwd-ico show">
                                  <img src={pwdHide} class="hide-icon" alt="" />
                                  <img src={pwdShow} class="show-icon" alt="" />
                                </div>
                              </div>
                            </div>
                          </div> */}

                          <hr />
                          <div className="col-lg-10">
                            <div className="deactive">
                              <h3>Deactivate Your Account</h3>
                              <p>
                                It is possible to deactivate your account, but
                                it’s irreversible. Please make sure that you
                                would like to do that.
                              </p>
                              <a
                                className="btn btn-gray btn-line"
                                onClick={onDeActiveNew}
                              >
                                Deactivate Account
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {showConfirmationModel && (
        <ConfirmationModel
          show={showConfirmationModel}
          handleClose={() => setShowConfirmationModel(false)}
          handleOk={() => setShowConfirmationModel(false)}
          title={confirmationTitle}
          body={confirmationBody}
        />
      )}
      {showConfirmationModel1 && (
        <ConfirmationModel
          show={showConfirmationModel1}
          handleClose={handleEvent}
          handleOk={handleEvent}
          title={confirmationTitle1}
          body={confirmationBody1}
        />
      )}
      {showDeactivateModel && (
        <DeactivateRequestModel
          show={showDeactivateModel}
          handleClose={() => setShowDeactivateModel(false)}
          handleOk={(data) => handleOk(data)}
        />
      )}
      {showConfirmationModel2 && (
        <ConfirmationModel
          show={showConfirmationModel2}
          handleClose={() => setShowConfirmationModel2(false)}
          handleOk={() => setShowConfirmationModel2(false)}
          title={confirmationTitle2}
          body={confirmationBody2}
        />
      )}
      {showActiveLisitngModel && (
        <ActiveLisitngModel
          show={showActiveLisitngModel}
          handleClose={() => setShowActiveLisitngModel(false)}
          handleOk={() => { setShowActiveLisitngModel(false), setShowDeactivateModel(true) }}
        />
      )}
      {showWhatsAppModal && (
        <WhatsAppVerificationModal
          show={showWhatsAppModal}
          onHide={() => setShowWhatsAppModal(false)}
          onSuccess={(value) => { 
            setShowWhatsAppModal(false)
            onWhatsAppVerificationSuccess(value)
           }}
          phoneNumber = {number}
          userDetails = {details}
          userId = {userProfile._id}
          header = {'Verify Mobile Number'}
        />
      )}
      {ShowEmailVerificationModal && (
        <EmailVerificationModal
          show={ShowEmailVerificationModal}
          onHide={() => setShowEmailVerificationModal(false)}
          onSuccess={(value) => { 
            setShowEmailVerificationModal(false)
            onEmailVerificationSuccess(value)
           }}
          emailAddress = {details?.email}
          userDetails = {details}
          userId = {userProfile._id}
          header = {'Verify Email'}
        />
      )}
    </>
  );
};
