
import { useState } from "react";
import {
    createSearchParams,
    useNavigate,
  } from "react-router-dom";
import Slider from "react-slick";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import { GiPartyPopper } from "react-icons/gi";
import { PiFilmSlateDuotone } from "react-icons/pi";
import { TfiHome } from "react-icons/tfi";

import film_photo1 from "../../assets/NewImages/Home/Film/Ad_Film_Shoot-5e260aa7-min.webp";
import film_photo2 from "../../assets/NewImages/Home/Film/Film Shoot.webp";
import film_photo3 from "../../assets/NewImages/Home/Film/Web Series.webp";
import film_photo4 from "../../assets/NewImages/Home/Film/Music Album.webp";
import film_photo5 from "../../assets/NewImages/Home/Film/TV Serial.webp";
import film_photo6 from "../../assets/NewImages/Home/Film/Photoshoot1.webp";
import film_photo7 from "../../assets/NewImages/Home/Film/Video Shoot.webp";
import film_photo8 from "../../assets/NewImages/Home/Film/Green Screen Shoot.webp";

import corp_photo1 from "../../assets/NewImages/Home/Events/Product launch.webp";
import corp_photo2 from "../../assets/NewImages/Home/Events/Company Anniversary.webp";
import corp_photo3 from "../../assets/NewImages/Home/Events/Press Conference.webp";
import corp_photo4 from "../../assets/NewImages/Home/Events/Awards_Ceremony-0d42c878.webp";
import corp_photo5 from "../../assets/NewImages/Home/Events/Wedding.webp";
import corp_photo6 from "../../assets/NewImages/Home/Events/Baby shower.webp";
import corp_photo7 from "../../assets/NewImages/Home/Events/Bachelor Party.webp";
import corp_photo8 from "../../assets/NewImages/Home/Events/Friends Gathering.webp";

import ind_photo1 from "../../assets/NewImages/Home/homestay/01.Adventure homestay.webp";
import ind_photo2 from "../../assets/NewImages/Home/homestay/02.Artistic Homestay.webp";
import ind_photo3 from "../../assets/NewImages/Home/homestay/Eco Tourism Homestay.webp";
import ind_photo4 from "../../assets/NewImages/Home/homestay/Farmstay.webp";
import ind_photo5 from "../../assets/NewImages/Home/homestay/Heritage Homestay.webp";
import ind_photo6 from "../../assets/NewImages/Home/homestay/Pet Friendly HomeStay.webp";
import ind_photo7 from "../../assets/NewImages/Home/homestay/Traditional Homestay 2.webp";
import ind_photo8 from "../../assets/NewImages/Home/homestay/Wellness Retreat Homestay.webp";

// Images
import next from '../.././assets/NewImages/Home/next.webp';
import prev from '../.././assets/NewImages/Home/prev.webp';

const FILM = 'film-shoot'
const EVENT = 'event'
const STAY = 'staycation'

const DATA = [
  [
    {
      type : FILM,
      image : film_photo1,
      alt : "A Luxury Car Parked for Ad Shoot on Road",
      title : "Ad Film Shoot",
    },
    {
      type : FILM,
      image : film_photo2,
      alt : "Scenic Landscape with a Stunning Dome and Lush Green Trees",
      title : "Film Shoot",
    },
    {
      type : FILM,
      image : film_photo3,
      alt : "Filming Web Series in Cafe - Boy and Girl in Conversation, Cameraman Recording, Girl Holding Light, Another Girl with Script",
      title : "Web Series Shoot",
    },
    {
      type : FILM,
      image : film_photo4,
      alt : "A Musician with Guitar and videographer shooting a Music album video with Car",
      title : "Music Album Shoot",
    },
    {
      type : FILM,
      image : film_photo5,
      alt : "A girl reading a book and a boy looking at a girl sitting in the corridor and talking to each other",
      title : "Tv Serial",
    },
    {
      type : FILM,
      image : film_photo6,
      alt : "Bride and Groom in King's and Queen's Wedding Attire Posing for Photoshoot",
      title : "Photo Shoot",
    },
    {
      type : FILM,
      image : film_photo7,
      alt : "A man recording a vlog video with his camera.",
      title : "Video Shoot",
    },
    {
      type : FILM,
      image : film_photo8,
      alt : "Two girls posing with hair dryer in front of green screen for ad film shoot",
      title : "Green Screen Shoot",
    },
  ],
  [
    {
      type : EVENT,
      image : corp_photo1,
      alt : "Joyful Product Launch Celebration with Dancing and Festive Atmosphere",
      title : "Product Launch",
    },
    {
      type : EVENT,
      image : corp_photo2,
      alt : "Exquisite Company Anniversary Celebration: A Stunningly Organized Event",
      title : "Company Anniversary",
    },
    {
      type : EVENT,
      image : corp_photo3,
      alt : "Group of professionals engaged in conference meeting.",
      title : "Press Conference",
    },
    {
      type : EVENT,
      image : corp_photo4,
      alt : "Person Holding Award Trophy in Hands",
      title : "Awards Ceremony",
    },
    {
      type : EVENT,
      image : corp_photo5,
      alt : "Trade Show Attendees Engaging in Activities",
      title : "Wedding",
    },
    {
      type : EVENT,
      image : corp_photo6,
      alt : "Models Who Took Part in the Fashion Show",
      title : "Baby Shower",
    },
    {
      type : EVENT,
      image : corp_photo7,
      alt : "Group of professionals engaged in corporate meeting",
      title : "Bachelor Party",
    },
    {
      type : EVENT,
      image : corp_photo8,
      alt : "Group of Joyful People Celebrating at a Corporate Party with Wineglass Cheers",
      title : "Friends Gathering",
    },
  ],
  [
    {
      type : STAY,
      image : ind_photo1,
      alt : "Adventure Homestay",
      title : "Adventure Homestay",
    },
    {
      type : STAY,
      image : ind_photo2,
      alt : "Artistic Homestay",
      title : "Artistic Homestay"
    },
    {
      type : STAY,
      image : ind_photo3,
      alt : "Eco Tourism Homestay",
      title : "Eco Tourism Homestay"
    },
    {
      type : STAY,
      image : ind_photo4,
      alt : "Farmstay",
      title : "Farmstay",
    },
    {
      type : STAY,
      image : ind_photo5,
      alt : "Heritage Homestay",
      title : "Heritage Homestay",
    },
    {
      type : STAY,
      image : ind_photo6,
      alt : "Pet Friendly HomeStay",
      title : "Pet Friendly HomeStay",
    },
    {
      type : STAY,
      image : ind_photo7,
      alt : "Traditional Homestay",
      title : "Traditional Homestay",
    },
    {
      type : STAY,
      image : ind_photo8,
      alt : "Wellness Retreat Homestay",
      title : "Wellness Retreat Homestay",
    },
  ],
]
function SampleNextArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{ ...style, display: "block", height: '50px', width: '100px' }}
    ><img loading="lazy" src={next} className="arrowImg" onClick={onClick} alt='Next' /></div>
  );
}
function SamplePrevArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{ ...style, display: "block", height: '50px', width: '100px', zIndex: '1', marginLeft: '-10px' }}
    ><img loading="lazy" src={prev} className="arrowImg" onClick={onClick} alt='Previous'/></div>
  );
}
function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}
function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

const CreativeSpaces = () => {
    var settings = {
      dots: false,
      infinite: true,
      swipeToSlide: false,
      // autoplay: true,
      // autoplaySpeed: 3000,
      fade: false,
      swipe: false,
      slidesToScroll: 1,
      initialSlide: 0,
      pauseOnHover: true,
      nextArrow: <SampleNextArrow />,
      prevArrow: <SamplePrevArrow />,
      slidesToShow: 4, 
      responsive: [
        {
          breakpoint: 1025,
          settings: {
            slidesToShow: 4, 
            slidesToScroll: 1,
            infinite: true,
          }
        },
        {
          breakpoint: 768,
          settings: {
            slidesToShow: 2, 
            slidesToScroll: 1,
            infinite: true,
            initialSlide: 0
          }
        },
        {
          breakpoint: 510,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            infinite: true,
          }
        }
      ]
    };
    const [value, setValue] = useState(0);
    const navigate = useNavigate();
    const handleBanner = (selectedEvent, subEvent) => {
        navigate(`/locations/${selectedEvent}//${subEvent}`)
        // navigate({
        //   pathname: "",
        //   search: createSearchParams({
        //     event: selectedEvent,
        //   }).toString(),
        // });
      }
    const tabhandleChange = (event, newValue) => {
      setValue(newValue);
    };

    return(
        <Box className="film-shooting">
            <Typography className="text-stroke SpotLetMainHead" variant="h2">
              CREATIVE SPACES
            </Typography>
            <Typography className="mar-t60 SpotLetMainHead" variant="h2">
                Explore Unique Spaces / Venues
            </Typography>
            <Box className="tabs-container">
              <Box>
                <Tabs
                  className="tabs-film-shooting"
                  value={value}
                  onChange={tabhandleChange}
                  aria-label="basic tabs example"
                >
                  <Tab
                    className="tab-text SpotLetMainHead"
                    icon={<PiFilmSlateDuotone />}
                    iconPosition="start"
                    label="Film Shooting Locations"
                    {...a11yProps(0)}
                  />
                  <Tab
                    className="tab-text SpotLetMainHead"
                    icon={<GiPartyPopper />}
                    iconPosition="start"
                    label="Event Venues"
                    {...a11yProps(1)}
                  />
                  <Tab
                    className="tab-text SpotLetMainHead"
                    icon={<TfiHome  />}
                    iconPosition="start"
                    label="Staycations"
                    {...a11yProps(2)}
                  />
                </Tabs>
              </Box>
              {/* <div className="creativeSpacesDesk">
                {DATA?.map((tab,i)=>{
                  return(
                    <CustomTabPanel value={value} index={i}>
                      <ul className="tablisting">
                        {tab?.map((item,i) => {
                          return(
                          <li style={{ cursor: 'pointer' }} onClick={() => handleBanner(item?.type)}>
                            <img loading="lazy" src={item?.image} alt={item?.alt} />
                            <span className="SpotLetSubHead">{item?.title}</span>
                          </li>
                          )
                        })}
                      </ul>
                    </CustomTabPanel>
                  )
                })}
              </div> */}
                <Slider {...settings}>
                  {DATA[value]?.map((item,i)=> {
                    return(
                      <li className="homeCreativeSpaceList cursor" 
                        onClick={() => handleBanner(item?.type, item?.title?.toLowerCase().replaceAll(" ", "-"))}>
                        <img className="homeCreativeSpaceImage" loading="lazy" src={item?.image} alt={item?.alt} />
                        <span className="homeCreativeSpaceSpan SpotLetSubHead">{item?.title}</span>
                      </li>
                    )
                  })}
                </Slider>
            </Box>
          </Box>
    )
}
export default CreativeSpaces;