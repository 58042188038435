import { useState,useEffect} from "react";
import info from "../../../assets/NewImages/BecomeAHost/i.svg";
import Select from 'react-select';

const INITIAL_DATA =  {
    film : {
        status : true,
        staticPolices : [
            "The property may only be booked for legitimate film or TV shooting purposes.",
            "Minimum booking duration required for film/TV shootings.",
            'A refundable security deposit is mandatory to cover any damages during the shoot.',
            "Production teams are responsible for handling their equipment safely and ensuring no damage to the property.",
            "Any modifications to the property for shooting purposes must be pre-approved by the owner.",
            "Shooting schedules must adhere to noise restrictions to minimize disruption to neighbors.",
            "Accommodation for the crew may be available on-site or nearby, subject to availability and prior arrangement.",
            "A cleaning fee may be applicable post-shoot to restore the property to its original condition.",
            "The property owner holds no liability for accidents, injuries, or damages that occur during the shoot.",
        ]
    },
    event : {
        status : true,
        staticPolices : [
            "Only specified event types (e.g., corporate, weddings) are Allowed.",
            'Events must adhere to the specified minimum and maximum booking durations.',
            "Strictly adhere to the maximum guest capacity outlined for each event.",
            "A mandatory security deposit is required to cover damages or violations.",
           " Respect noise restrictions to minimize disturbance to neighbors and maintain peace.",
            "Alcohol consumption is Allowed based on local regulations and with prior approval.",
            "Event organizers are solely responsible for cleaning up the venue post-event, including waste disposal.",
            "Any temporary modifications to the property for the event must receive prior approval from the host.",
            "The property owner holds no liability for accidents, injuries, or damages that occur during the event. Event organizers assume full responsibility.",
           " Drugs and illegal substances are strictly prohibited.",
        ]
    },
    stay : {
        status : true,
        staticPolices : [
            "Guests must adhere to the specified check-in and check-out times.",
            "Guests must comply with the minimum length of stay requirement for homestay bookings.",
            "Guests are required to respect house rules, property, and neighbors at all times.",
            "A security deposit is mandatory to cover any damages caused during the stay.",
            "Guests must seek prior approval from the host regarding pet accommodation. Host's decision on pet accommodation is final.",
            "Smoking is strictly prohibited indoors and allowed only in designated outdoor areas.",
           " Guests must comply with the homestay cancellation policy, including any associated fees.",
            "Guests are expected to use available amenities responsibly and follow any usage guidelines provided.",
            "The property owner bears no liability for accidents, injuries, or losses incurred by guests during their stay. Guests accept full responsibility for their actions and safety.",
            "Drugs and illegal substances are strictly prohibited.",
        ]
    },
    term_cond : false,
    privacy_policy : false,
    grant_info : false,
}
const CANCELLATION = [
    "Free cancellation up to 5 days",
    "Free cancellation up to 72 hours",
    "Free cancellation up to 48 hours",
    "Free cancellation up to 24 Hours",
    "Non Refundable",
]
const ID_PROOF = [
    { value: 'passport', label: 'Passport' },
    { value: 'driving_licence', label: 'Driving License' },
    { value: 'government_id', label: 'Government Id' }
  ]
const TIME_OPTIONS = [
    { value: "00:00", label: "00:00" },
    { value: "01:00", label: "01:00" },
    { value: "02:00", label: "02:00" },
    { value: "03:00", label: "03:00" },
    { value: "04:00", label: "04:00" },
    { value: "05:00", label: "05:00" },
    { value: "06:00", label: "06:00" },
    { value: "07:00", label: "07:00" },
    { value: "08:00", label: "08:00" },
    { value: "09:00", label: "09:00" },
    { value: "10:00", label: "10:00" },
    { value: "11:00", label: "11:00" },
    { value: "12:00", label: "12:00" },
    { value: "13:00", label: "13:00" },
    { value: "14:00", label: "14:00" },
    { value: "15:00", label: "15:00" },
    { value: "16:00", label: "16:00" },
    { value: "17:00", label: "17:00" },
    { value: "18:00", label: "18:00" },
    { value: "19:00", label: "19:00" },
    { value: "20:00", label: "20:00" },
    { value: "21:00", label: "21:00" },
    { value: "22:00", label: "22:00" },
    { value: "23:00", label: "23:00" },
    { value: "23:59", label: "23:59" },
  ];
const CUSINEOPTIONS = [
    { value: 'north_indian', label: 'North Indian' },
    { value: 'south_indian', label: 'South Indian' },
    { value: 'chinese', label: 'Chinese' },
    { value: 'continental', label: 'Continental' },
    { value: 'local', label: 'Local' },
    { value: 'italian', label: 'Italian' },
]
const Policies = ({data,handleBack,handleNext, pricingData}) => {
    const [formData,setFormData] = useState({})
    const [showTermsAndConditionsModel, setTermsAndConditionsModel] = useState(false);
    const [showPrivacyAndPolicyModel, setPrivacyAndPolicyModel] = useState(false);
    const [init, setInit] = useState(false);
    const [custom, setCustom] = useState({
        "filmTv" : "",
        "event" : "",
        "stay" : "", 
    })

    const onClickSubmit = (e) => {
        e.preventDefault()
        handleNext({policies : formData})
    }
    const handlePrivacyAndPolicy = (e) => {
        if (formData.privacy_policy) {
          setFormData({ ...formData, privacy_policy: false });
        } else {
          setPrivacyAndPolicyModel(true)
        }
      }
    const handleChangeTermsAndCond = (e) => {
        if (formData.term_cond) {
          setFormData({ ...formData, term_cond: false });
        } else {
          setTermsAndConditionsModel(true)
        }
    
      }
    const handleAgreement = () => {
        setFormData({ ...formData, term_cond: true });
      }
    const handlePrivacyAndPolicyAgreement = () => {
        setFormData({ ...formData, privacy_policy: true });
      }
    const handleChangeAgree = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.checked });
      }
      const setValiditions = () => {
        if(pricingData?.film || pricingData?.tv){
            if (!formData?.film?.cancellation) {
                document.getElementById(" filmFree cancellation up to 5 days")?.setCustomValidity("Select any one of the options");
            }
            if (!formData?.film?.music) {
                document.getElementById("filmMusicYes")?.setCustomValidity("Select any one of the options");
            }
            if (!(formData?.film?.music && (formData?.film?.music?.fullDay || formData?.film?.music?.timings))) {
                document.getElementById("filmMusic24Hours")?.setCustomValidity("Select any one of the options");
            }
            if (!(formData?.film?.caretaker)) {
                document.getElementById("filmCaretakerYes")?.setCustomValidity("Select any one of the options");
            }
            if (!(formData?.film?.caretaker && (formData?.film?.caretaker?.fullDay || formData?.film?.caretaker?.timings))) {
                document.getElementById("filmCaretaker24Hours")?.setCustomValidity("Select any one of the options");
            }
            // document.getElementById("filmIOutSideFoodYes")?.setCustomValidity("Select any one of the options");
            // document.getElementById("filmISmokingYes")?.setCustomValidity("Select any one of the options");
            // document.getElementById("filmINonVegYes")?.setCustomValidity("Select any one of the options");
        }
        if(pricingData?.event){
            if (!formData?.event?.cancellation) {
                document.getElementById("eventFree cancellation up to 5 days")?.setCustomValidity("Select any one of the options");
            }
            if (!formData?.event?.music) {
                document.getElementById("eventMusicYes")?.setCustomValidity("Select any one of the options");
            }
            if (!(formData?.event?.music && (formData?.event?.music?.fullDay || formData?.event?.music?.timings))) {
                document.getElementById("eventMusic24Hours")?.setCustomValidity("Select any one of the options");
            }
            if (!(formData?.event?.caretaker)) {
                document.getElementById("eventCaretakerYes")?.setCustomValidity("Select any one of the options");
            }
            if (!(formData?.event?.caretaker && (formData?.event?.caretaker?.fullDay || formData?.event?.caretaker?.timings))) {
                document.getElementById("eventCaretaker24Hours")?.setCustomValidity("Select any one of the options");
            }
            if (!(formData?.event?.outSideFood === true || formData?.event?.outSideFood === false)) {
                document.getElementById("eventIOutSideFoodYes")?.setCustomValidity("Select any one of the options");
            }
            if (!(formData?.event?.smoking === true || formData?.event?.smoking === false)) {
                document.getElementById("eventISmokingYes")?.setCustomValidity("Select any one of the options");
            }
            if (!(formData?.event?.nonVeg === true || formData?.event?.nonVeg === false )) {
                document.getElementById("eventINonVegYes")?.setCustomValidity("Select any one of the options");
            }
            // document.getElementById("eventIinHouseAlcoholYes")?.setCustomValidity("Select any one of the options");
            // document.getElementById("eventIoutsideAlcoholYes")?.setCustomValidity("Select any one of the options");
            // document.getElementById("eventIinHouseDjYes")?.setCustomValidity("Select any one of the options");
            // document.getElementById("eventIoutsideDjYes")?.setCustomValidity("Select any one of the options");
            // document.getElementById("eventIcookYes")?.setCustomValidity("Select any one of the options");
        }
        if(pricingData?.stay){
            if (!formData?.stay?.cancellation) {
                document.getElementById("stayFree cancellation up to 5 days")?.setCustomValidity("Select any one of the options");
            }
            if (!formData?.stay?.music) {
                document.getElementById("stayMusicYes")?.setCustomValidity("Select any one of the options");
            }
            if (!(formData?.stay?.music && (formData?.stay?.music?.fullDay || formData?.stay?.music?.timings))) {
                document.getElementById("stayMusic24Hours")?.setCustomValidity("Select any one of the options");
            }
            if (!(formData?.stay?.caretaker)) {
                document.getElementById("stayCaretakerYes")?.setCustomValidity("Select any one of the options");
            }
            if (!(formData?.stay?.caretaker && (formData?.stay?.caretaker?.fullDay || formData?.stay?.caretaker?.timings))) {
                document.getElementById("stayCaretaker24Hours")?.setCustomValidity("Select any one of the options");
            }
            if (!(formData?.stay?.smoking === true || formData?.stay?.smoking === false)) {
                document.getElementById("stayISmokingYes")?.setCustomValidity("Select any one of the options");
            }
            if (!(formData?.stay?.nonVeg === true || formData?.stay?.nonVeg === false)) {
                document.getElementById("stayINonVegYes")?.setCustomValidity("Select any one of the options");
            }
            // if (!(formData?.stay?.inHouseAlcohol)) {
            //     document.getElementById("stayIinHouseAlcoholYes")?.setCustomValidity("Select any one of the options");
            // }
            // if (!(formData?.stay?.inHouseDj)) {
            //     document.getElementById("stayIinHouseDjYes")?.setCustomValidity("Select any one of the options");
            // }
            // document.getElementById("stayIOutSideFoodYes")?.setCustomValidity("Select any one of the options");
            // document.getElementById("stayIoutsideAlcoholYes")?.setCustomValidity("Select any one of the options");
            // document.getElementById("stayIoutsideDjYes")?.setCustomValidity("Select any one of the options");
            // document.getElementById("stayIcookYes")?.setCustomValidity("Select any one of the options");
            // document.getElementById("stayIoutsidePetsYes")?.setCustomValidity("Select any one of the options");
            // document.getElementById("stayIinhousePetsYes")?.setCustomValidity("Select any one of the options");
            // document.getElementById("stayIcoupleFriendlyYes")?.setCustomValidity("Select any one of the options");
        }
    }
    const setValidity = (id) => {
        setTimeout(() => {
            document.getElementById(id)?.setCustomValidity("Select any one of the options");
        },100)
    }
    const removeValidity = (id) => {
        document.getElementById(id)?.setCustomValidity("");
    }

    const updateData = () => {
        let updatedData = {}
        if(data){
            updatedData = data;
        }else{
            updatedData = {
                ...( (pricingData?.film || pricingData?.tv) && {film :  INITIAL_DATA.film}),
                ...( (pricingData?.event) && {event : INITIAL_DATA.event }),
                ...( (pricingData?.stay) && {stay : INITIAL_DATA.stay}),
                term_cond : false,
                privacy_policy : false,
                grant_info : false,
            }
        }
        setFormData(updatedData)
    }
    
    useEffect(() => {
        updateData()
        const element = document.getElementById(`listPolicies`)
        if (element) element.scrollIntoView({behavior: "smooth",block: "start"});
    },[])

    useEffect(() => {
        if (!init) setInit(true);   
        if (init) setValiditions();
    }, [init] )

    const filmTvPolicies = () => {
        return(
            <div className="policiesTabCard">
                <h6  className="SpotLetSubHead">Film/TV Shooting Policies</h6>
                <div className="container">
                    {/* stable polices start */}
                    <div className="row">
                        <ul className="col-xl-8">
                            {formData?.film?.staticPolices?.map((item,i) => {
                                return(
                                    <li key={i} className="SpotLetContent mb-1 mx-2">{item}</li>
                                )
                            })}
                        </ul>
                    </div>
                    {/* stable polices end */}
                    <div className="row mb-2">
                        <div className="col-xl-8">
                            <hr/>
                        </div>
                    </div>
                    {/* cancellation start */}
                    <div className="row mb-2">
                        <div className="col-xl-8">
                            <h6> Cancellation Policy<span className="required">*</span> : </h6>
                            {CANCELLATION?.map((item,i) => {
                                return(
                                    <div key={i} className="d-flex flex-row justify-content-between align-items-center">
                                        <label htmlFor={'film'+item} className="form-label">{item}</label>
                                        <input
                                            required = {formData?.film?.cancellation === undefined ? true : false }
                                            className="form-check-input"
                                            type="checkbox"
                                            name={item}
                                            id={'film'+item}
                                            checked={formData?.film?.cancellation === item}
                                            onClick={()=>{
                                                setFormData({
                                                    ...formData,
                                                    film : {
                                                        ...formData?.film,
                                                        cancellation : item,
                                                    }
                                                })
                                                removeValidity('filmFree cancellation up to 5 days')
                                            }}
                                        />
                                    </div>
                                )
                            })}
                        </div>
                    </div>
                    {/* cancellation end */}
                    <div className="row mb-2">
                        <div className="col-xl-8">
                            <hr/>
                        </div>
                    </div>
                    <h6 > Rules & Facilities : </h6>
                    {/* id proof start */}
                    <div className="row mb-2">
                        <div className="col-xl-8 d-flex flex-row justify-content-between align-items-center flex-wrap">
                            <span className='form-label' >Acceptable Identity Proofs<span className="required">*</span> : </span>
                            <Select
                                value={formData?.film?.idProof}
                                required
                                isMulti='multi'
                                options={ID_PROOF}
                                onChange={(e) =>
                                    setFormData({
                                    ...formData,
                                    film : {
                                        ...formData?.film,
                                        idProof: e,
                                    }
                                    })  
                                } 
                                className="policies-select"
                                classNamePrefix="Select Proof"
                                closeMenuOnSelect= {false} 
                            />
                        </div>
                    </div>
                    {/* id proof end */}
                    {/* music start */}
                    <div className="row mb-2">
                        <div className="col-xl-8 d-flex flex-row justify-content-between align-items-center flex-wrap">
                            <span className='form-label'>Music<span className="required">*</span> : </span>
                            <div className="d-flex flex-row justify-content-between flex-wrap align-items-center">
                                <div className="d-flex">
                                    <div className="form-check me-3">
                                        <input
                                            type="checkbox"
                                            className="form-check-input"
                                            id="filmMusicYes"
                                            onChange={() =>{
                                                setFormData({
                                                    ...formData,
                                                    film : {
                                                        ...formData?.film,
                                                        music : {
                                                            allowed : true,
                                                            fullDay : '',
                                                            timings : {
                                                                start : '',
                                                                end : '',
                                                            }
                                                        }
                                                    }
                                                })
                                                removeValidity('filmMusicYes')
                                                setValidity('filmMusic24Hours')
                                            }
                                            }
                                            checked={formData?.film?.music?.allowed === true}
                                        />
                                        <label
                                            className="form-check-label"
                                            htmlFor='filmMusicYes'
                                            >
                                            Allowed
                                        </label>
                                    </div>
                                    <div className="form-check">
                                        <input
                                            type="checkbox"
                                            className="form-check-input"
                                            id="filmMusicNo"
                                            onChange={() =>{
                                                setFormData({
                                                    ...formData,
                                                    film : {
                                                        ...formData?.film,
                                                        music : {
                                                            allowed : false,
                                                            fullDay : '',
                                                            timings : {
                                                                start : '',
                                                                end : '',
                                                            }
                                                        }
                                                    }
                                                })
                                                removeValidity('filmMusicYes')
                                            }
                                            }
                                            checked={formData?.film?.music?.allowed === false}
                                        />
                                        <label
                                            className="form-check-label"
                                            htmlFor='filmMusicNo'
                                            >
                                            Not allowed
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {formData?.film?.music?.allowed && (
                        <div className="row mb-2">
                            <div className="col-xl-8 d-flex flex-row justify-content-between align-items-center  flex-wrap">
                                <span className='form-label' >Music timings<span className="required">*</span> : </span>
                                <div className="d-flex flex-row justify-content-between flex-wrap align-items-center">
                                <div className="d-flex">
                                    <div className="form-check me-3">
                                        <input
                                            type="checkbox"
                                            className="form-check-input"
                                            id="filmMusic24Hours"
                                            onChange={() =>{
                                                setFormData({
                                                    ...formData,
                                                    film : {
                                                        ...formData?.film,
                                                        music : {
                                                            ...formData?.film?.music,
                                                            fullDay : true,
                                                        }
                                                    }
                                                })
                                                removeValidity('filmMusic24Hours')
                                            }
                                            }
                                            checked={formData?.film?.music?.fullDay === true}
                                        />
                                        <label
                                            className="form-check-label"
                                            htmlFor='filmMusic24Hours'
                                            >
                                            24 Hours
                                        </label>
                                    </div>
                                    <div className="form-check">
                                        <input
                                            type="checkbox"
                                            className="form-check-input"
                                            id="filmMusicCustom"
                                            onChange={() =>{
                                                setFormData({
                                                    ...formData,
                                                    film : {
                                                        ...formData?.film,
                                                        music : {
                                                            ...formData?.film?.music,
                                                            fullDay : false,
                                                        }
                                                    }
                                                })
                                                removeValidity('filmMusic24Hours')
                                            }
                                            }
                                            checked={formData?.film?.music?.fullDay === false}
                                        />
                                        <label
                                            className="form-check-label"
                                            htmlFor='filmMusicCustom'
                                            >
                                            Custom
                                        </label>
                                    </div>
                                </div>
                            </div>
                            </div>
                        </div>
                    )}
                    {formData?.film?.music?.fullDay === false && (
                        <div className="row mb-2">
                            <div className="col-xl-8 d-flex flex-row justify-content-end align-items-center">
                                <div>
                                    <select 
                                        value={formData?.film?.music?.timings?.start}
                                        required
                                        className="mx-2 textFiled p-0" 
                                        style={{width : "110px", height : "26px"}} 
                                        onChange={(e) =>
                                            setFormData({
                                                ...formData,
                                                film : {
                                                    ...formData?.film,
                                                    music : {
                                                        ...formData?.film?.music,
                                                        timings : {
                                                            start : e.target.value,
                                                            end : '',
                                                        }
                                                    }
                                                }
                                                })
                                            }
                                        >
                                        <option hidden selected value = "" > Start time </option>
                                        {TIME_OPTIONS.map((each) => {
                                            return <option value = {each.value} > {each.label} </option>
                                        })}
                                    </select>
                                    <select 
                                        value={formData?.film?.music?.timings?.end}
                                        required
                                        className="mx-2 textFiled p-0" 
                                        style={{width : "110px", height : "26px"}} 
                                        onChange={(e) =>
                                            setFormData({
                                                ...formData,
                                                film : {
                                                    ...formData?.film,
                                                    music : {
                                                        ...formData?.film?.music,
                                                        timings : {
                                                            ...formData?.film?.music?.timings,
                                                            end : e.target.value,
                                                        }
                                                    }
                                                }
                                                })
                                            }
                                        >
                                        <option hidden selected value = "" > End time </option>
                                        {TIME_OPTIONS.map((each) => {
                                            return <option value = {each.value} > {each.label} </option>
                                        })}
                                    </select>
                                </div> 
                            </div>
                        </div>
                    )}
                    {/* music end */}
                    {/* outside start */}
                    <div className="row mb-2">
                        <div className="col-xl-8 d-flex flex-row justify-content-between align-items-center flex-wrap">
                            <span className='form-label' >Outside food : </span>
                            <div className="d-flex flex-row justify-content-between flex-wrap align-items-center">
                                <div className="d-flex">
                                    <div className="form-check me-3">
                                        <input
                                            type="checkbox"
                                            className="form-check-input"
                                            id="filmIOutSideFoodYes"
                                            onChange={() =>{
                                                setFormData({
                                                    ...formData,
                                                    film : {
                                                        ...formData?.film,
                                                        outSideFood : true,
                                                    }
                                                })
                                                // removeValidity('filmIOutSideFoodYes')
                                            }
                                            }
                                            checked={formData?.film?.outSideFood === true}
                                        />
                                        <label
                                            className="form-check-label"
                                            htmlFor='filmIOutSideFoodYes'
                                            >
                                            Allowed
                                        </label>
                                    </div>
                                    <div className="form-check">
                                        <input
                                            type="checkbox"
                                            className="form-check-input"
                                            id="filmOutSideFoodNo"
                                            onChange={() =>{
                                                setFormData({
                                                    ...formData,
                                                    film : {
                                                        ...formData?.film,
                                                        outSideFood : false,
                                                    }
                                                })
                                                // removeValidity('filmIOutSideFoodYes')
                                            }
                                            }
                                            checked={formData?.film?.outSideFood === false}
                                        />
                                        <label
                                            className="form-check-label"
                                            htmlFor='filmOutSideFoodNo'
                                            >
                                            Not allowed
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* outside end */}
                    {/* smoking start */}
                    <div className="row mb-2">
                        <div className="col-xl-8 d-flex flex-row justify-content-between align-items-center flex-wrap">
                            <span className='form-label' >Smoking : </span>
                            <div className="d-flex flex-row justify-content-between flex-wrap align-items-center">
                                <div className="d-flex">
                                    <div className="form-check me-3">
                                        <input
                                            type="checkbox"
                                            className="form-check-input"
                                            id="filmISmokingYes"
                                            onChange={() =>{
                                                setFormData({
                                                    ...formData,
                                                    film : {
                                                        ...formData?.film,
                                                        smoking : true,
                                                    }
                                                })
                                                // removeValidity('filmISmokingYes')
                                            }
                                            }
                                            checked={formData?.film?.smoking === true}
                                        />
                                        <label
                                            className="form-check-label"
                                            htmlFor='filmISmokingYes'
                                            >
                                            Allowed
                                        </label>
                                    </div>
                                    <div className="form-check">
                                        <input
                                            type="checkbox"
                                            className="form-check-input"
                                            id="filmISmokingNo"
                                            onChange={() =>{
                                                setFormData({
                                                    ...formData,
                                                    film : {
                                                        ...formData?.film,
                                                        smoking : false,
                                                    }
                                                })
                                                // removeValidity('filmISmokingYes')
                                            }
                                            }
                                            checked={formData?.film?.smoking === false}
                                        />
                                        <label
                                            className="form-check-label"
                                            htmlFor='filmISmokingNo'
                                            >
                                            Not allowed
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* smokoing end */}
                    {/* nonveg start */}
                    <div className="row mb-2">
                        <div className="col-xl-8 d-flex flex-row justify-content-between align-items-center flex-wrap">
                            <span className='form-label' >Non-vegetarian food : </span>
                            <div className="d-flex flex-row justify-content-between flex-wrap align-items-center">
                                <div className="d-flex">
                                    <div className="form-check me-3">
                                        <input
                                            type="checkbox"
                                            className="form-check-input"
                                            id="filmINonVegYes"
                                            onChange={() =>{
                                                setFormData({
                                                    ...formData,
                                                    film : {
                                                        ...formData?.film,
                                                        nonVeg : true,
                                                    }
                                                })
                                                // removeValidity('filmINonVegYes')
                                            }
                                            }
                                            checked={formData?.film?.nonVeg === true}
                                        />
                                        <label
                                            className="form-check-label"
                                            htmlFor='filmINonVegYes'
                                            >
                                            Allowed
                                        </label>
                                    </div>
                                    <div className="form-check">
                                        <input
                                            type="checkbox"
                                            className="form-check-input"
                                            id="filmINonVegNo"
                                            onChange={() =>{
                                                setFormData({
                                                    ...formData,
                                                    film : {
                                                        ...formData?.film,
                                                        nonVeg : false,
                                                    }
                                                })
                                                // removeValidity('filmINonVegYes')
                                            }
                                            }
                                            checked={formData?.film?.nonVeg === false}
                                        />
                                        <label
                                            className="form-check-label"
                                            htmlFor='filmINonVegNo'
                                            >
                                            Not allowed
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* nonveg end */}
                    {/* caretaker start */}
                    <div className="row mb-2">
                        <div className="col-xl-8 d-flex flex-row justify-content-between align-items-center flex-wrap">
                            <span className='form-label' >Caretaker Available at Property<span className="required">*</span> : </span>
                            <div className="d-flex flex-row justify-content-between flex-wrap align-items-center">
                                <div className="d-flex">
                                    <div className="form-check me-3">
                                        <input
                                            type="checkbox"
                                            className="form-check-input"
                                            id="filmCaretakerYes"
                                            onChange={() =>{
                                                setFormData({
                                                    ...formData,
                                                    film : {
                                                        ...formData?.film,
                                                        caretaker : {
                                                            available : true,
                                                            fullDay : '',
                                                            timings : {
                                                                start : '',
                                                                end : '',
                                                            }
                                                        }
                                                    }
                                                })
                                                removeValidity('filmCaretakerYes')
                                                setValidity('filmCaretaker24Hours')
                                            }
                                            }
                                            checked={formData?.film?.caretaker?.available === true}
                                        />
                                        <label
                                            className="form-check-label"
                                            htmlFor='filmCaretakerYes'
                                            >
                                            Yes
                                        </label>
                                    </div>
                                    <div className="form-check">
                                        <input
                                            type="checkbox"
                                            className="form-check-input"
                                            id="filmCaretakerNo"
                                            onChange={() =>{
                                                setFormData({
                                                    ...formData,
                                                    film : {
                                                        ...formData?.film,
                                                        caretaker : {
                                                            available : false,
                                                            fullDay : '',
                                                            timings : {
                                                                start : '',
                                                                end : '',
                                                            }
                                                        }
                                                    }
                                                })
                                                removeValidity('filmCaretakerYes')
                                            }
                                            }
                                            checked={formData?.film?.caretaker?.available === false}
                                        />
                                        <label
                                            className="form-check-label"
                                            htmlFor='filmCaretakerNo'
                                            >
                                            No
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {formData?.film?.caretaker?.available && (
                        <div className="row mb-2">
                            <div className="col-xl-8 d-flex flex-row justify-content-between align-items-center flex-wrap">
                                <span className='form-label' >Caretaker Availability<span className="required">*</span> : </span>
                                <div className="d-flex flex-row justify-content-between flex-wrap align-items-center">
                                <div className="d-flex">
                                    <div className="form-check me-3">
                                        <input
                                            type="checkbox"
                                            className="form-check-input"
                                            id="filmCaretaker24Hours"
                                            onChange={() =>{
                                                setFormData({
                                                    ...formData,
                                                    film : {
                                                        ...formData?.film,
                                                        caretaker : {
                                                            ...formData?.film?.caretaker,
                                                            fullDay : true,
                                                        }
                                                    }
                                                })
                                                removeValidity('filmCaretaker24Hours')
                                            }
                                            }
                                            checked={formData?.film?.caretaker?.fullDay === true}
                                        />
                                        <label
                                            className="form-check-label"
                                            htmlFor='filmCaretaker24Hours'
                                            >
                                            24 Hours
                                        </label>
                                    </div>
                                    <div className="form-check">
                                        <input
                                            type="checkbox"
                                            className="form-check-input"
                                            id="filmCaretakerCustom"
                                            onChange={() =>{
                                                setFormData({
                                                    ...formData,
                                                    film : {
                                                        ...formData?.film,
                                                        caretaker : {
                                                            ...formData?.film?.caretaker,
                                                            fullDay : false,
                                                        }
                                                    }
                                                })
                                                removeValidity('filmCaretaker24Hours')
                                            }
                                            }
                                            checked={formData?.film?.caretaker?.fullDay === false}
                                        />
                                        <label
                                            className="form-check-label"
                                            htmlFor='filmCaretakerCustom'
                                            >
                                            Custom
                                        </label>
                                    </div>
                                </div>
                            </div>
                            </div>
                        </div>
                    )}
                    {formData?.film?.caretaker?.fullDay === false && (
                        <div className="row mb-2">
                            <div className="col-xl-8 d-flex flex-row justify-content-end align-items-center">
                                <div>
                                    <select 
                                        value={formData?.film?.caretaker?.timings?.start}
                                        required
                                        className="mx-2 textFiled p-0" 
                                        style={{width : "110px", height : "26px"}} 
                                        onChange={(e) =>
                                            setFormData({
                                                ...formData,
                                                film : {
                                                    ...formData?.film,
                                                    caretaker : {
                                                        ...formData?.film?.caretaker,
                                                        timings : {
                                                            start : e.target.value,
                                                            end : '',
                                                        }
                                                    }
                                                }
                                                })
                                            }
                                        >
                                        <option hidden selected value = "" > Start time </option>
                                        {TIME_OPTIONS.map((each) => {
                                            return <option value = {each.value} > {each.label} </option>
                                        })}
                                    </select>
                                    <select 
                                        value={formData?.film?.caretaker?.timings?.end}
                                        required
                                        className="mx-2 textFiled p-0" 
                                        style={{width : "110px", height : "26px"}} 
                                        onChange={(e) =>
                                            setFormData({
                                                ...formData,
                                                film : {
                                                    ...formData?.film,
                                                    caretaker : {
                                                        ...formData?.film?.caretaker,
                                                        timings : {
                                                            ...formData?.film?.caretaker?.timings,
                                                            end : e.target.value,
                                                        }
                                                    }
                                                }
                                                })
                                            }
                                        >
                                        <option hidden selected value = "" > End time </option>
                                        {TIME_OPTIONS.map((each) => {
                                            return <option value = {each.value} > {each.label} </option>
                                        })}
                                    </select>
                                </div> 
                            </div>
                        </div>
                    )}
                    {/* caretaker end */}
                    {/* film custom policies start  */}
                    <div className="row mb-2">
                        <div className="col-xl-8 d-flex flex-row justify-content-between align-items-center flex-wrap">
                            <span className='form-label' > Custom film policies : </span> <br/>
                        </div>
                    </div>
                    <div className="row mb-2">
                        <div className="col-xl-8 d-flex flex-row justify-content-between align-items-center flex-wrap">
                            <span className='form-label' >Do you want to add custom film policies : </span>
                            <div className="d-flex flex-row justify-content-between flex-wrap align-items-center">
                                <div className="d-flex">
                                    <div className="form-check me-3">
                                        <input
                                            type="checkbox"
                                            className="form-check-input"
                                            id="customFilmPoliciesYes"
                                            onChange={() =>{
                                                setFormData({
                                                    ...formData,
                                                    film : {
                                                        ...formData?.film,
                                                        customPolicy : true,
                                                    }
                                                })
                                            }
                                            }
                                            checked={formData?.film?.customPolicy === true}
                                        />
                                        <label
                                            className="form-check-label"
                                            htmlFor='customFilmPoliciesYes'
                                            >
                                            Yes
                                        </label>
                                    </div>
                                    <div className="form-check">
                                        <input
                                            type="checkbox"
                                            className="form-check-input"
                                            id="customFilmPoliciesNo"
                                            onChange={() =>{
                                                setFormData({
                                                    ...formData,
                                                    film : {
                                                        ...formData?.film,
                                                        customPolicy : false,
                                                        customPolicies : [],
                                                    }
                                                })
                                            }
                                            }
                                            checked={formData?.film?.customPolicy === false}
                                        />
                                        <label
                                            className="form-check-label"
                                            htmlFor='customFilmPoliciesNo'
                                            >
                                            No
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {
                        formData?.film?.customPolicies?.length > 0 && (
                            <div className="row mb-2">
                                {
                                    formData?.film?.customPolicies?.map((each, index) => {
                                        return(
                                            <span className="SpotLetContent form-label" > {each}  <span onClick={() => deleteCustomPolicy(index, "film") }> <i class="bi bi-trash me-2"></i> </span> </span>
                                        )
                                    } )
                                }
                            </div>
                        )
                    }
                    {
                        formData?.film?.customPolicy && (
                            <>
                                <div className="col-xl-8">
                                    <input 
                                        className="textFiled w-50"
                                        type="text"
                                        value = {custom?.film}
                                        placeholder = "Enter custom policy"
                                        onChange={(e) => {
                                            setCustom( {...custom, "film" : e?.target?.value } )
                                        }}
                                    />
                                </div>
                                <button 
                                    className="btn btn-primary mt-2" 
                                    type = "button"
                                    onClick={() => {
                                        const rule = custom?.film?.trim();
                                        let reqArray = [...formData?.film?.customPolicies, rule];
                                        // reqArray = reqArray.push(rule);
                                        if (rule) {
                                            setFormData({
                                                ...formData,
                                                film : {
                                                    ...formData?.film,
                                                    customPolicies : reqArray
                                                }
                                            })
                                            setCustom({
                                                ...custom,
                                                "film" : ""
                                            })
                                        }
                                    } }> 
                                    Add Policy 
                                </button>
                            </>
                        )
                    }
                    {/* film custom policies end  */}
                </div>
            </div>
        )
    }

    const eventPolicies = () => {
        return(
            <div className="policiesTabCard">
                <h6  className="SpotLetSubHead">Event Policies</h6>
                <div className="container">
                    {/* stable polices start */}
                    <div className="row">
                        <ul className="col-xl-8">
                            {formData?.event?.staticPolices?.map((item,i) => {
                                return(
                                    <li key={i} className="SpotLetContent mb-1 mx-2">{item}</li>
                                )
                            })}
                        </ul>
                    </div>
                    {/* stable polices end */}
                    <div className="row mb-2">
                        <div className="col-xl-8">
                            <hr/>
                        </div>
                    </div>
                    {/* cancellation start */}
                    <div className="row mb-2">
                        <div className="col-xl-8">
                            <h6 > Cancellation Policy<span className="required">*</span> : </h6>
                            {CANCELLATION?.map((item,i) => {
                                return(
                                    <div key={i} className="d-flex flex-row justify-content-between align-items-center">
                                        <label htmlFor={"event"+item} className="form-label">{item}</label>
                                        <input
                                            required = {formData?.event?.cancellation === undefined ? true : false }
                                            className="form-check-input"
                                            type="checkbox"
                                            name={item}
                                            id={"event"+item}
                                            checked={formData?.event?.cancellation === item}
                                            onClick={()=>{
                                                setFormData({
                                                    ...formData,
                                                    event : {
                                                        ...formData?.event,
                                                        cancellation : item,
                                                    }
                                                })
                                                removeValidity('eventFree cancellation up to 5 days')
                                            }}
                                        />
                                    </div>
                                )
                            })}
                        </div>
                    </div>
                    {/* cancellation end */}
                    <div className="row mb-2">
                        <div className="col-xl-8">
                            <hr/>
                        </div>
                    </div>
                    <h6 > Rules & Facilities : </h6>
                    {/* id proof start */}
                    <div className="row mb-2">
                        <div className="col-xl-8 d-flex flex-row justify-content-between align-items-center flex-wrap">
                            <span className='form-label' >Acceptable Identity Proofs<span className="required">*</span> : </span>
                            <Select
                                value={formData?.event?.idProof}
                                required
                                isMulti='multi'
                                options={ID_PROOF}
                                onChange={(e) =>
                                    setFormData({
                                    ...formData,
                                    event : {
                                        ...formData?.event,
                                        idProof: e,
                                    }
                                    })  
                                } 
                                className="policies-select"
                                classNamePrefix="Select Proof"
                                closeMenuOnSelect= {false} 
                            />
                        </div>
                    </div>
                    {/* id proof end */}
                    {/* music start */}
                    <div className="row mb-2">
                        <div className="col-xl-8 d-flex flex-row justify-content-between align-items-center flex-wrap">
                            <span className='form-label' >Music<span className="required">*</span> : </span>
                            <div className="d-flex flex-row justify-content-between flex-wrap align-items-center">
                                <div className="d-flex">
                                    <div className="form-check me-3">
                                        <input
                                            type="checkbox"
                                            className="form-check-input"
                                            id="eventMusicYes"
                                            onChange={() =>{
                                                setFormData({
                                                    ...formData,
                                                    event : {
                                                        ...formData?.event,
                                                        music : {
                                                            allowed : true,
                                                            fullDay : '',
                                                            timings : {
                                                                start : '',
                                                                end : '',
                                                            }
                                                        }
                                                    }
                                                })
                                                removeValidity('eventMusicYes')
                                                setValidity('eventMusic24Hours')
                                            }
                                            }
                                            checked={formData?.event?.music?.allowed === true}
                                        />
                                        <label
                                            className="form-check-label"
                                            htmlFor='eventMusicYes'
                                            >
                                            Allowed
                                        </label>
                                    </div>
                                    <div className="form-check">
                                        <input
                                            type="checkbox"
                                            className="form-check-input"
                                            id="eventMusicNo"
                                            onChange={() =>{
                                                setFormData({
                                                    ...formData,
                                                    event : {
                                                        ...formData?.event,
                                                        music : {
                                                            allowed : false,
                                                            fullDay : '',
                                                            timings : {
                                                                start : '',
                                                                end : '',
                                                            }
                                                        }
                                                    }
                                                })
                                                removeValidity('eventMusicYes')
                                            }
                                            }
                                            checked={formData?.event?.music?.allowed === false}
                                        />
                                        <label
                                            className="form-check-label"
                                            htmlFor='eventMusicNo'
                                            >
                                            Not allowed
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {formData?.event?.music?.allowed && (
                        <div className="row mb-2">
                            <div className="col-xl-8 d-flex flex-row justify-content-between align-items-center  flex-wrap">
                                <span className='form-label' >Music timings<span className="required">*</span> : </span>
                                <div className="d-flex flex-row justify-content-between flex-wrap align-items-center">
                                <div className="d-flex">
                                    <div className="form-check me-3">
                                        <input
                                            type="checkbox"
                                            className="form-check-input"
                                            id="eventMusic24Hours"
                                            onChange={() =>{
                                                setFormData({
                                                    ...formData,
                                                    event : {
                                                        ...formData?.event,
                                                        music : {
                                                            ...formData?.event?.music,
                                                            fullDay : true,
                                                        }
                                                    }
                                                })
                                                removeValidity('eventMusic24Hours')
                                            }
                                            }
                                            checked={formData?.event?.music?.fullDay === true}
                                        />
                                        <label
                                            className="form-check-label"
                                            htmlFor='eventMusic24Hours'
                                            >
                                            24 Hours
                                        </label>
                                    </div>
                                    <div className="form-check">
                                        <input
                                            type="checkbox"
                                            className="form-check-input"
                                            id="eventMusicCustom"
                                            onChange={() =>{
                                                setFormData({
                                                    ...formData,
                                                    event : {
                                                        ...formData?.event,
                                                        music : {
                                                            ...formData?.event?.music,
                                                            fullDay : false,
                                                        }
                                                    }
                                                })
                                                removeValidity('eventMusic24Hours')
                                            }
                                            }
                                            checked={formData?.event?.music?.fullDay === false}
                                        />
                                        <label
                                            className="form-check-label"
                                            htmlFor='eventMusicCustom'
                                            >
                                            Custom
                                        </label>
                                    </div>
                                </div>
                            </div>
                            </div>
                        </div>
                    )}
                    {formData?.event?.music?.fullDay === false && (
                        <div className="row mb-2">
                            <div className="col-xl-8 d-flex flex-row justify-content-end align-items-center">
                                <div>
                                    <select 
                                        value={formData?.event?.music?.timings?.start}
                                        required
                                        className="mx-2 textFiled p-0" 
                                        style={{width : "110px", height : "26px"}} 
                                        onChange={(e) =>
                                            setFormData({
                                                ...formData,
                                                event : {
                                                    ...formData?.event,
                                                    music : {
                                                        ...formData?.event?.music,
                                                        timings : {
                                                            start : e.target.value,
                                                            end : '',
                                                        }
                                                    }
                                                }
                                                })
                                            }
                                        >
                                        <option hidden selected value = "" > Start time </option>
                                        {TIME_OPTIONS.map((each) => {
                                            return <option value = {each.value} > {each.label} </option>
                                        })}
                                    </select>
                                    <select 
                                        value={formData?.event?.music?.timings?.end}
                                        required
                                        className="mx-2 textFiled p-0" 
                                        style={{width : "110px", height : "26px"}} 
                                        onChange={(e) =>
                                            setFormData({
                                                ...formData,
                                                event : {
                                                    ...formData?.event,
                                                    music : {
                                                        ...formData?.event?.music,
                                                        timings : {
                                                            ...formData?.event?.music?.timings,
                                                            end : e.target.value,
                                                        }
                                                    }
                                                }
                                                })
                                            }
                                        >
                                        <option hidden selected value = "" > End time </option>
                                        {TIME_OPTIONS.map((each) => {
                                            return <option value = {each.value} > {each.label} </option>
                                        })}
                                    </select>
                                </div> 
                            </div>
                        </div>
                    )}
                    {/* music end */}
                    {/* outside start */}
                    <div className="row mb-2">
                        <div className="col-xl-8 d-flex flex-row justify-content-between align-items-center flex-wrap">
                            <span className='form-label' >Outside food<span className="required">*</span> : </span>
                            <div className="d-flex flex-row justify-content-between flex-wrap align-items-center">
                                <div className="d-flex">
                                    <div className="form-check me-3">
                                        <input
                                            type="checkbox"
                                            className="form-check-input"
                                            id="eventIOutSideFoodYes"
                                            onChange={() =>{
                                                setFormData({
                                                    ...formData,
                                                    event : {
                                                        ...formData?.event,
                                                        outSideFood : true,
                                                    }
                                                })
                                                removeValidity('eventIOutSideFoodYes')
                                            }
                                            }
                                            checked={formData?.event?.outSideFood === true}
                                        />
                                        <label
                                            className="form-check-label"
                                            htmlFor='eventIOutSideFoodYes'
                                            >
                                            Allowed
                                        </label>
                                    </div>
                                    <div className="form-check">
                                        <input
                                            type="checkbox"
                                            className="form-check-input"
                                            id="eventOutSideFoodNo"
                                            onChange={() =>{
                                                setFormData({
                                                    ...formData,
                                                    event : {
                                                        ...formData?.event,
                                                        outSideFood : false,
                                                    }
                                                })
                                                removeValidity('eventIOutSideFoodYes')
                                            }
                                            }
                                            checked={formData?.event?.outSideFood === false}
                                        />
                                        <label
                                            className="form-check-label"
                                            htmlFor='eventOutSideFoodNo'
                                            >
                                            Not allowed
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* outside end */}
                    {/* smoking start */}
                    <div className="row mb-2">
                        <div className="col-xl-8 d-flex flex-row justify-content-between align-items-center flex-wrap">
                            <span className='form-label' >Smoking<span className="required">*</span> : </span>
                            <div className="d-flex flex-row justify-content-between flex-wrap align-items-center">
                                <div className="d-flex">
                                    <div className="form-check me-3">
                                        <input
                                            type="checkbox"
                                            className="form-check-input"
                                            id="eventISmokingYes"
                                            onChange={() =>{
                                                setFormData({
                                                    ...formData,
                                                    event : {
                                                        ...formData?.event,
                                                        smoking : true,
                                                    }
                                                })
                                                removeValidity('eventISmokingYes')
                                            }
                                            }
                                            checked={formData?.event?.smoking === true}
                                        />
                                        <label
                                            className="form-check-label"
                                            htmlFor='eventISmokingYes'
                                            >
                                            Allowed
                                        </label>
                                    </div>
                                    <div className="form-check">
                                        <input
                                            type="checkbox"
                                            className="form-check-input"
                                            id="eventISmokingNo"
                                            onChange={() =>{
                                                setFormData({
                                                    ...formData,
                                                    event : {
                                                        ...formData?.event,
                                                        smoking : false,
                                                    }
                                                })
                                                removeValidity('eventISmokingYes')
                                            }
                                            }
                                            checked={formData?.event?.smoking === false}
                                        />
                                        <label
                                            className="form-check-label"
                                            htmlFor='eventISmokingNo'
                                            >
                                            Not allowed
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* smokoing end */}
                    {/* nonveg start */}
                    <div className="row mb-2">
                        <div className="col-xl-8 d-flex flex-row justify-content-between align-items-center flex-wrap">
                            <span className='form-label' >Non-vegetarian food<span className="required">*</span> : </span>
                            <div className="d-flex flex-row justify-content-between flex-wrap align-items-center">
                                <div className="d-flex">
                                    <div className="form-check me-3">
                                        <input
                                            type="checkbox"
                                            className="form-check-input"
                                            id="eventINonVegYes"
                                            onChange={() =>{
                                                setFormData({
                                                    ...formData,
                                                    event : {
                                                        ...formData?.event,
                                                        nonVeg : true,
                                                    }
                                                })
                                                removeValidity('eventINonVegYes')
                                            }
                                            }
                                            checked={formData?.event?.nonVeg === true}
                                        />
                                        <label
                                            className="form-check-label"
                                            htmlFor='eventINonVegYes'
                                            >
                                            Allowed
                                        </label>
                                    </div>
                                    <div className="form-check">
                                        <input
                                            type="checkbox"
                                            className="form-check-input"
                                            id="eventINonVegNo"
                                            onChange={() =>{
                                                setFormData({
                                                    ...formData,
                                                    event : {
                                                        ...formData?.event,
                                                        nonVeg : false,
                                                    }
                                                })
                                                removeValidity('eventINonVegYes')
                                            }
                                            }
                                            checked={formData?.event?.nonVeg === false}
                                        />
                                        <label
                                            className="form-check-label"
                                            htmlFor='eventINonVegNo'
                                            >
                                            Not allowed
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* nonveg end */}
                    {/* caretaker start */}
                    <div className="row mb-2">
                        <div className="col-xl-8 d-flex flex-row justify-content-between align-items-center flex-wrap">
                            <span className='form-label' >Caretaker<span className="required">*</span> : </span>
                            <div className="d-flex flex-row justify-content-between flex-wrap align-items-center">
                                <div className="d-flex">
                                    <div className="form-check me-3">
                                        <input
                                            type="checkbox"
                                            className="form-check-input"
                                            id="eventCaretakerYes"
                                            onChange={() =>{
                                                setFormData({
                                                    ...formData,
                                                    event : {
                                                        ...formData?.event,
                                                        caretaker : {
                                                            available : true,
                                                            fullDay : '',
                                                            timings : {
                                                                start : '',
                                                                end : '',
                                                            }
                                                        }
                                                    }
                                                })
                                                removeValidity('eventCaretakerYes')
                                                setValidity('eventCaretaker24Hours')
                                            }
                                            }
                                            checked={formData?.event?.caretaker?.available === true}
                                        />
                                        <label
                                            className="form-check-label"
                                            htmlFor='eventCaretakerYes'
                                            >
                                            Available
                                        </label>
                                    </div>
                                    <div className="form-check">
                                        <input
                                            type="checkbox"
                                            className="form-check-input"
                                            id="eventCaretakerNo"
                                            onChange={() =>{
                                                setFormData({
                                                    ...formData,
                                                    event : {
                                                        ...formData?.event,
                                                        caretaker : {
                                                            available : false,
                                                            fullDay : '',
                                                            timings : {
                                                                start : '',
                                                                end : '',
                                                            }
                                                        }
                                                    }
                                                })
                                                removeValidity('eventCaretakerYes')
                                            }
                                            }
                                            checked={formData?.event?.caretaker?.available === false}
                                        />
                                        <label
                                            className="form-check-label"
                                            htmlFor='eventCaretakerNo'
                                            >
                                            Not available
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {formData?.event?.caretaker?.available && (
                        <div className="row mb-2">
                            <div className="col-xl-8 d-flex flex-row justify-content-between align-items-center flex-wrap">
                                <span className='form-label' >Caretaker Availability<span className="required">*</span> : </span>
                                <div className="d-flex flex-row justify-content-between flex-wrap align-items-center">
                                <div className="d-flex">
                                    <div className="form-check me-3">
                                        <input
                                            type="checkbox"
                                            className="form-check-input"
                                            id="eventCaretaker24Hours"
                                            onChange={() =>{
                                                setFormData({
                                                    ...formData,
                                                    event : {
                                                        ...formData?.event,
                                                        caretaker : {
                                                            ...formData?.event?.caretaker,
                                                            fullDay : true,
                                                        }
                                                    }
                                                })
                                                removeValidity('eventCaretaker24Hours')
                                            }
                                            }
                                            checked={formData?.event?.caretaker?.fullDay === true}
                                        />
                                        <label
                                            className="form-check-label"
                                            htmlFor='eventCaretaker24Hours'
                                            >
                                            24 Hours
                                        </label>
                                    </div>
                                    <div className="form-check">
                                        <input
                                            type="checkbox"
                                            className="form-check-input"
                                            id="eventCaretakerCustom"
                                            onChange={() =>{
                                                setFormData({
                                                    ...formData,
                                                    event : {
                                                        ...formData?.event,
                                                        caretaker : {
                                                            ...formData?.event?.caretaker,
                                                            fullDay : false,
                                                        }
                                                    }
                                                })
                                                removeValidity('eventCaretaker24Hours')
                                            }
                                            }
                                            checked={formData?.event?.caretaker?.fullDay === false}
                                        />
                                        <label
                                            className="form-check-label"
                                            htmlFor='eventCaretakerCustom'
                                            >
                                            Custom
                                        </label>
                                    </div>
                                </div>
                            </div>
                            </div>
                        </div>
                    )}
                    {formData?.event?.caretaker?.fullDay === false && (
                        <div className="row mb-2">
                            <div className="col-xl-8 d-flex flex-row justify-content-end align-items-center">
                                <div>
                                    <select 
                                        value={formData?.event?.caretaker?.timings?.start}
                                        required
                                        className="mx-2 textFiled p-0" 
                                        style={{width : "110px", height : "26px"}} 
                                        onChange={(e) =>
                                            setFormData({
                                                ...formData,
                                                event : {
                                                    ...formData?.event,
                                                    caretaker : {
                                                        ...formData?.event?.caretaker,
                                                        timings : {
                                                            start : e.target.value,
                                                            end : '',
                                                        }
                                                    }
                                                }
                                                })
                                            }
                                        >
                                        <option hidden selected value = "" > Start time </option>
                                        {TIME_OPTIONS.map((each) => {
                                            return <option value = {each.value} > {each.label} </option>
                                        })}
                                    </select>
                                    <select 
                                        value={formData?.event?.caretaker?.timings?.end}
                                        required
                                        className="mx-2 textFiled p-0" 
                                        style={{width : "110px", height : "26px"}} 
                                        onChange={(e) =>
                                            setFormData({
                                                ...formData,
                                                event : {
                                                    ...formData?.event,
                                                    caretaker : {
                                                        ...formData?.event?.caretaker,
                                                        timings : {
                                                            ...formData?.event?.caretaker?.timings,
                                                            end : e.target.value,
                                                        }
                                                    }
                                                }
                                                })
                                            }
                                        >
                                        <option hidden selected value = "" > End time </option>
                                        {TIME_OPTIONS.map((each) => {
                                            return <option value = {each.value} > {each.label} </option>
                                        })}
                                    </select>
                                </div> 
                            </div>
                        </div>
                    )}
                    {/* caretaker end */}
                    {/* in house alcohol start */}
                    <div className="row mb-2">
                        <div className="col-xl-8 d-flex flex-row justify-content-between align-items-center flex-wrap">
                            <span className='form-label' >In-house Alcohol : </span>
                            <div className="d-flex flex-row justify-content-between flex-wrap align-items-center">
                                <div className="d-flex">
                                    <div className="form-check me-3">
                                        <input
                                            type="checkbox"
                                            className="form-check-input"
                                            id="eventIinHouseAlcoholYes"
                                            onChange={() =>{
                                                setFormData({
                                                    ...formData,
                                                    event : {
                                                        ...formData?.event,
                                                        inHouseAlcohol : true,
                                                    }
                                                })
                                                // removeValidity('eventIinHouseAlcoholYes')
                                            }
                                            }
                                            checked={formData?.event?.inHouseAlcohol === true}
                                        />
                                        <label
                                            className="form-check-label"
                                            htmlFor='filmIinHouseAlcoholYes'
                                            >
                                            Available
                                        </label>
                                    </div>
                                    <div className="form-check">
                                        <input
                                            type="checkbox"
                                            className="form-check-input"
                                            id="eventinHouseAlcoholNo"
                                            onChange={() =>{
                                                setFormData({
                                                    ...formData,
                                                    event : {
                                                        ...formData?.event,
                                                        inHouseAlcohol : false,
                                                    }
                                                })
                                                // removeValidity('eventIinHouseAlcoholYes')
                                            }
                                            }
                                            checked={formData?.event?.inHouseAlcohol === false}
                                        />
                                        <label
                                            className="form-check-label"
                                            htmlFor='eventinHouseAlcoholNo'
                                            >
                                            Not available
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* in house alcohol end */}
                    {/* Outside Alcohol start */}
                    <div className="row mb-2">
                        <div className="col-xl-8 d-flex flex-row justify-content-between align-items-center flex-wrap">
                            <span className='form-label' >Outside Alcohol : </span>
                            <div className="d-flex flex-row justify-content-between flex-wrap align-items-center">
                                <div className="d-flex">
                                    <div className="form-check me-3">
                                        <input
                                            type="checkbox"
                                            className="form-check-input"
                                            id="eventIoutsideAlcoholYes"
                                            onChange={() =>{
                                                setFormData({
                                                    ...formData,
                                                    event : {
                                                        ...formData?.event,
                                                        outsideAlcohol : true,
                                                    }
                                                })
                                                // removeValidity('eventIoutsideAlcoholYes')
                                            }
                                            }
                                            checked={formData?.event?.outsideAlcohol === true}
                                        />
                                        <label
                                            className="form-check-label"
                                            htmlFor='filmIoutsideAlcoholYes'
                                            >
                                            Allowed
                                        </label>
                                    </div>
                                    <div className="form-check">
                                        <input
                                            type="checkbox"
                                            className="form-check-input"
                                            id="eventoutsideAlcoholNo"
                                            onChange={() =>{
                                                setFormData({
                                                    ...formData,
                                                    event : {
                                                        ...formData?.event,
                                                        outsideAlcohol : false,
                                                    }
                                                })
                                                // removeValidity('eventIoutsideAlcoholYes')
                                            }
                                            }
                                            checked={formData?.event?.outsideAlcohol === false}
                                        />
                                        <label
                                            className="form-check-label"
                                            htmlFor='eventoutsideAlcoholNo'
                                            >
                                            Not Allowed
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/*Outside Alcohol end */}
                    {/* In-house DJ start */}
                    <div className="row mb-2">
                        <div className="col-xl-8 d-flex flex-row justify-content-between align-items-center flex-wrap">
                            <span className='form-label' >In-house DJ : </span>
                            <div className="d-flex flex-row justify-content-between flex-wrap align-items-center">
                                <div className="d-flex">
                                    <div className="form-check me-3">
                                        <input
                                            type="checkbox"
                                            className="form-check-input"
                                            id="eventIinHouseDjYes"
                                            onChange={() =>{
                                                setFormData({
                                                    ...formData,
                                                    event : {
                                                        ...formData?.event,
                                                        inHouseDj : true,
                                                    }
                                                })
                                                // removeValidity('eventIinHouseDjYes')
                                            }
                                            }
                                            checked={formData?.event?.inHouseDj === true}
                                        />
                                        <label
                                            className="form-check-label"
                                            htmlFor='filmIinHouseDjYes'
                                            >
                                            Available
                                        </label>
                                    </div>
                                    <div className="form-check">
                                        <input
                                            type="checkbox"
                                            className="form-check-input"
                                            id="eventinHouseDjNo"
                                            onChange={() =>{
                                                setFormData({
                                                    ...formData,
                                                    event : {
                                                        ...formData?.event,
                                                        inHouseDj : false,
                                                    }
                                                })
                                                // removeValidity('eventIinHouseDjYes')
                                            }
                                            }
                                            checked={formData?.event?.inHouseDj === false}
                                        />
                                        <label
                                            className="form-check-label"
                                            htmlFor='eventinHouseDjNo'
                                            >
                                            Not available
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/*In-house DJ end */}
                    {/* Outside DJ start */}
                    <div className="row mb-2">
                        <div className="col-xl-8 d-flex flex-row justify-content-between align-items-center flex-wrap">
                            <span className='form-label' >Outside DJ : </span>
                            <div className="d-flex flex-row justify-content-between flex-wrap align-items-center">
                                <div className="d-flex">
                                    <div className="form-check me-3">
                                        <input
                                            type="checkbox"
                                            className="form-check-input"
                                            id="eventIoutsideDjYes"
                                            onChange={() =>{
                                                setFormData({
                                                    ...formData,
                                                    event : {
                                                        ...formData?.event,
                                                        outsideDj : true,
                                                    }
                                                })
                                                // removeValidity('eventIoutsideDjYes')
                                            }
                                            }
                                            checked={formData?.event?.outsideDj === true}
                                        />
                                        <label
                                            className="form-check-label"
                                            htmlFor='filmIoutsideDjYes'
                                            >
                                            Allowed
                                        </label>
                                    </div>
                                    <div className="form-check">
                                        <input
                                            type="checkbox"
                                            className="form-check-input"
                                            id="eventoutsideDjNo"
                                            onChange={() =>{
                                                setFormData({
                                                    ...formData,
                                                    event : {
                                                        ...formData?.event,
                                                        outsideDj : false,
                                                    }
                                                })
                                                // removeValidity('eventIoutsideDjYes')
                                            }
                                            }
                                            checked={formData?.event?.outsideDj === false}
                                        />
                                        <label
                                            className="form-check-label"
                                            htmlFor='eventoutsideDjNo'
                                            >
                                            Not allowed
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/*Outside DJ end */}
                    {/* Cook Available start */}
                    <div className="row mb-2">
                        <div className="col-xl-8 d-flex flex-row justify-content-between align-items-center flex-wrap">
                            <span className='form-label' >Cook : </span>
                            <div className="d-flex flex-row justify-content-between flex-wrap align-items-center">
                                <div className="d-flex">
                                    <div className="form-check me-3">
                                        <input
                                            type="checkbox"
                                            className="form-check-input"
                                            id="eventIcookYes"
                                            onChange={() =>{
                                                setFormData({
                                                    ...formData,
                                                    event : {
                                                        ...formData?.event,
                                                        cook : true,
                                                    }
                                                })
                                                // removeValidity('eventIcookYes')
                                            }
                                            }
                                            checked={formData?.event?.cook === true}
                                        />
                                        <label
                                            className="form-check-label"
                                            htmlFor='filmIcookYes'
                                            >
                                            Available
                                        </label>
                                    </div>
                                    <div className="form-check">
                                        <input
                                            type="checkbox"
                                            className="form-check-input"
                                            id="eventcookNo"
                                            onChange={() =>{
                                                setFormData({
                                                    ...formData,
                                                    event : {
                                                        ...formData?.event,
                                                        cook : false,
                                                    }
                                                })
                                                // removeValidity('eventIcookYes')
                                            }
                                            }
                                            checked={formData?.event?.cook === false}
                                        />
                                        <label
                                            className="form-check-label"
                                            htmlFor='eventcookNo'
                                            >
                                            Not available
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {formData?.event?.cook === true && (
                        <div className="row mb-2">
                            <div className="col-xl-8 d-flex flex-row justify-content-between align-items-center flex-wrap">
                                <label htmlFor="cuisines_by_cook" className="form-label">
                                Cuisines Prepared by the Cook <span className="required">*</span>
                                </label>
                                <Select
                                    value={formData?.event?.cuisines}
                                    required
                                    isMulti='multi'
                                    options={CUSINEOPTIONS}
                                    onChange={(e) =>
                                        setFormData({
                                        ...formData,
                                        event : {
                                            ...formData?.event,
                                            cuisines: e,
                                        }
                                        })  
                                    } 
                                    className="policies-select"
                                    classNamePrefix="select Cuisine"
                                    closeMenuOnSelect= {false} 
                                />
                            </div>
                        </div>
                    )}
                    {/*Cook Available end */}
                    {/* event custom policies start  */}
                    <div className="row mb-2">
                        <div className="col-xl-8 d-flex flex-row justify-content-between align-items-center flex-wrap">
                            <span className='form-label' > Custom event policies : </span> <br/>
                        </div>
                    </div>
                    <div className="row mb-2">
                        <div className="col-xl-8 d-flex flex-row justify-content-between align-items-center flex-wrap">
                            <span className='form-label' >Do you want to add custom event policies : </span>
                            <div className="d-flex flex-row justify-content-between flex-wrap align-items-center">
                                <div className="d-flex">
                                    <div className="form-check me-3">
                                        <input
                                            type="checkbox"
                                            className="form-check-input"
                                            id="customEventPoliciesYes"
                                            onChange={() =>{
                                                setFormData({
                                                    ...formData,
                                                    event : {
                                                        ...formData?.event,
                                                        customPolicy : true,
                                                    }
                                                })
                                            }
                                            }
                                            checked={formData?.event?.customPolicy === true}
                                        />
                                        <label
                                            className="form-check-label"
                                            htmlFor='customEventPoliciesYes'
                                            >
                                            Yes
                                        </label>
                                    </div>
                                    <div className="form-check">
                                        <input
                                            type="checkbox"
                                            className="form-check-input"
                                            id="customEventPoliciesNo"
                                            onChange={() =>{
                                                setFormData({
                                                    ...formData,
                                                    event : {
                                                        ...formData?.event,
                                                        customPolicy : false,
                                                        customPolicies : [],
                                                    }
                                                })
                                            }
                                            }
                                            checked={formData?.event?.customPolicy === false}
                                        />
                                        <label
                                            className="form-check-label"
                                            htmlFor='customEventPoliciesNo'
                                            >
                                            No
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {
                        formData?.event?.customPolicies?.length > 0 && (
                            <div className="row mb-2">
                                {
                                    formData?.event?.customPolicies?.map((each, index) => {
                                        return(
                                            <span className="SpotLetContent form-label" > {each}  <span onClick={() => deleteCustomPolicy(index, "event") }> <i class="bi bi-trash"></i> </span> </span>
                                        )
                                    } )
                                }
                            </div>
                        )
                    }
                    {
                        formData?.event?.customPolicy && (
                            <>
                                <div className="col-xl-8">
                                    <input 
                                        className="textFiled w-50"
                                        type="text"
                                        value = {custom?.event}
                                        placeholder = "Enter custom policy"
                                        onChange={(e) => {
                                            setCustom( {...custom, "event" : e?.target?.value } )
                                        }}
                                    />
                                </div>
                                <button 
                                    className="btn btn-primary mt-2" 
                                    type = "button"
                                    onClick={() => {
                                        const rule = custom?.event?.trim();
                                        let reqArray = [...formData?.event?.customPolicies, rule];
                                        // reqArray = reqArray.push(rule);
                                        if (rule) {
                                            setFormData({
                                                ...formData,
                                                event : {
                                                    ...formData?.event,
                                                    customPolicies : reqArray
                                                }
                                            })
                                            setCustom({
                                                ...custom,
                                                "event" : ""
                                            })
                                        }
                                    } }> 
                                    Add Policy 
                                </button>
                            </>
                        )
                    }
                    {/* eventcation custom policies end  */}
                </div>
            </div>
        )
    }

    const deleteCustomPolicy = (index, type ) => {
        let reqArray = [...formData?.[type]?.customPolicies]
        reqArray.splice(index, 1);
        setFormData({
            ...formData,
            [type] : {
                ...formData?.[type],
                customPolicies : reqArray
            }
        })
    }

    const staycationPolicies = () => {
        return(
            <div className="policiesTabCard">
                <h6  className="SpotLetSubHead">Staycation Policies</h6>
                <div className="container">
                    {/* stable polices start */}
                    <div className="row">
                        <ul className="col-xl-8">
                            {formData?.stay?.staticPolices?.map((item,i) => {
                                return(
                                    <li key={i} className="SpotLetContent mb-1 mx-2">{item}</li>
                                )
                            })}
                        </ul>
                    </div>
                    {/* stable polices end */}
                    <div className="row mb-2">
                        <div className="col-xl-8">
                            <hr/>
                        </div>
                    </div>
                    {/* cancellation start */}
                    <div className="row mb-2">
                        <div className="col-xl-8">
                            <h6 > Cancellation Policy<span className="required">*</span> : </h6>
                            {CANCELLATION?.map((item,i) => {
                                return(
                                    <div key={i} className="d-flex flex-row justify-content-between align-items-center">
                                        <label htmlFor={"stay"+item} className="form-label">{item}</label>
                                        <input
                                            required = {formData?.stay?.cancellation === undefined ? true : false }
                                            className="form-check-input"
                                            type="checkbox"
                                            name={item}
                                            id={'stay'+item}
                                            checked={formData?.stay?.cancellation === item}
                                            onClick={()=>{
                                                setFormData({
                                                    ...formData,
                                                    stay : {
                                                        ...formData?.stay,
                                                        cancellation : item,
                                                    }
                                                })
                                                removeValidity('stayFree cancellation up to 5 days')
                                            }}
                                        />
                                    </div>
                                )
                            })}
                        </div>
                    </div>
                    {/* cancellation end */}
                    <div className="row mb-2">
                        <div className="col-xl-8">
                            <hr/>
                        </div>
                    </div>
                    <h6 > Rules & Facilities : </h6>
                    {/* id proof start */}
                    <div className="row mb-2">
                        <div className="col-xl-8 d-flex flex-row justify-content-between align-items-center flex-wrap">
                            <span className='form-label' >Acceptable Identity Proofs<span className="required">*</span> : </span>
                            <Select
                                value={formData?.stay?.idProof}
                                required
                                isMulti='multi'
                                options={ID_PROOF}
                                onChange={(e) =>
                                    setFormData({
                                    ...formData,
                                    stay : {
                                        ...formData?.stay,
                                        idProof: e,
                                    }
                                    })  
                                } 
                                className="policies-select"
                                classNamePrefix="Select Proof"
                                closeMenuOnSelect= {false} 
                            />
                        </div>
                    </div>
                    {/* id proof end */}
                    {/* music start */}
                    <div className="row mb-2">
                        <div className="col-xl-8 d-flex flex-row justify-content-between align-items-center flex-wrap">
                            <span className='form-label' >Music<span className="required">*</span> : </span>
                            <div className="d-flex flex-row justify-content-between flex-wrap align-items-center">
                                <div className="d-flex">
                                    <div className="form-check me-3">
                                        <input
                                            type="checkbox"
                                            className="form-check-input"
                                            id="stayMusicYes"
                                            onChange={() =>{
                                                setFormData({
                                                    ...formData,
                                                    stay : {
                                                        ...formData?.stay,
                                                        music : {
                                                            allowed : true,
                                                            fullDay : '',
                                                            timings : {
                                                                start : '',
                                                                end : '',
                                                            }
                                                        }
                                                    }
                                                })
                                                removeValidity('stayMusicYes')
                                                setValidity('stayMusic24Hours')
                                            }
                                            }
                                            checked={formData?.stay?.music?.allowed === true}
                                        />
                                        <label
                                            className="form-check-label"
                                            htmlFor='stayMusicYes'
                                            >
                                            Allowed
                                        </label>
                                    </div>
                                    <div className="form-check">
                                        <input
                                            type="checkbox"
                                            className="form-check-input"
                                            id="stayMusicNo"
                                            onChange={() =>{
                                                setFormData({
                                                    ...formData,
                                                    stay : {
                                                        ...formData?.stay,
                                                        music : {
                                                            allowed : false,
                                                            fullDay : '',
                                                            timings : {
                                                                start : '',
                                                                end : '',
                                                            }
                                                        }
                                                    }
                                                })
                                                removeValidity('stayMusicYes')
                                            }
                                            }
                                            checked={formData?.stay?.music?.allowed === false}
                                        />
                                        <label
                                            className="form-check-label"
                                            htmlFor='stayMusicNo'
                                            >
                                            Not allowed
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {formData?.stay?.music?.allowed && (
                        <div className="row mb-2">
                            <div className="col-xl-8 d-flex flex-row justify-content-between align-items-center  flex-wrap">
                                <span className='form-label' >Music timings<span className="required">*</span> : </span>
                                <div className="d-flex flex-row justify-content-between flex-wrap align-items-center">
                                <div className="d-flex">
                                    <div className="form-check me-3">
                                        <input
                                            type="checkbox"
                                            className="form-check-input"
                                            id="stayMusic24Hours"
                                            onChange={() =>{
                                                setFormData({
                                                    ...formData,
                                                    stay : {
                                                        ...formData?.stay,
                                                        music : {
                                                            ...formData?.stay?.music,
                                                            fullDay : true,
                                                        }
                                                    }
                                                })
                                                removeValidity('stayMusic24Hours')
                                            }
                                            }
                                            checked={formData?.stay?.music?.fullDay === true}
                                        />
                                        <label
                                            className="form-check-label"
                                            htmlFor='stayMusic24Hours'
                                            >
                                            24 Hours
                                        </label>
                                    </div>
                                    <div className="form-check">
                                        <input
                                            type="checkbox"
                                            className="form-check-input"
                                            id="stayMusicCustom"
                                            onChange={() =>{
                                                setFormData({
                                                    ...formData,
                                                    stay : {
                                                        ...formData?.stay,
                                                        music : {
                                                            ...formData?.stay?.music,
                                                            fullDay : false,
                                                        }
                                                    }
                                                })
                                                removeValidity('stayMusic24Hours')
                                            }
                                            }
                                            checked={formData?.stay?.music?.fullDay === false}
                                        />
                                        <label
                                            className="form-check-label"
                                            htmlFor='stayMusicCustom'
                                            >
                                            Custom
                                        </label>
                                    </div>
                                </div>
                            </div>
                            </div>
                        </div>
                    )}
                    {formData?.stay?.music?.fullDay === false && (
                        <div className="row mb-2">
                            <div className="col-xl-8 d-flex flex-row justify-content-end align-items-center">
                                <div>
                                    <select 
                                        value={formData?.stay?.music?.timings?.start}
                                        required
                                        className="mx-2 textFiled p-0" 
                                        style={{width : "110px", height : "26px"}} 
                                        onChange={(e) =>
                                            setFormData({
                                                ...formData,
                                                stay : {
                                                    ...formData?.stay,
                                                    music : {
                                                        ...formData?.stay?.music,
                                                        timings : {
                                                            start : e.target.value,
                                                            end : '',
                                                        }
                                                    }
                                                }
                                                })
                                            }
                                        >
                                        <option hidden selected value = "" > Start time </option>
                                        {TIME_OPTIONS.map((each) => {
                                            return <option value = {each.value} > {each.label} </option>
                                        })}
                                    </select>
                                    <select 
                                        value={formData?.stay?.music?.timings?.end}
                                        required
                                        className="mx-2 textFiled p-0" 
                                        style={{width : "110px", height : "26px"}} 
                                        onChange={(e) =>
                                            setFormData({
                                                ...formData,
                                                stay : {
                                                    ...formData?.stay,
                                                    music : {
                                                        ...formData?.stay?.music,
                                                        timings : {
                                                            ...formData?.stay?.music?.timings,
                                                            end : e.target.value,
                                                        }
                                                    }
                                                }
                                                })
                                            }
                                        >
                                        <option hidden selected value = "" > End time </option>
                                        {TIME_OPTIONS.map((each) => {
                                            return <option value = {each.value} > {each.label} </option>
                                        })}
                                    </select>
                                </div> 
                            </div>
                        </div>
                    )}
                    {/* music end */}
                    {/* outside start */}
                    <div className="row mb-2">
                        <div className="col-xl-8 d-flex flex-row justify-content-between align-items-center flex-wrap">
                            <span className='form-label' >Outside food : </span>
                            <div className="d-flex flex-row justify-content-between flex-wrap align-items-center">
                                <div className="d-flex">
                                    <div className="form-check me-3">
                                        <input
                                            type="checkbox"
                                            className="form-check-input"
                                            id="stayIOutSideFoodYes"
                                            onChange={() =>{
                                                setFormData({
                                                    ...formData,
                                                    stay : {
                                                        ...formData?.stay,
                                                        outSideFood : true,
                                                    }
                                                })
                                                // removeValidity('stayIOutSideFoodYes')
                                            }
                                            }
                                            checked={formData?.stay?.outSideFood === true}
                                        />
                                        <label
                                            className="form-check-label"
                                            htmlFor='filmIOutSideFoodYes'
                                            >
                                            Allowed
                                        </label>
                                    </div>
                                    <div className="form-check">
                                        <input
                                            type="checkbox"
                                            className="form-check-input"
                                            id="stayOutSideFoodNo"
                                            onChange={() =>{
                                                setFormData({
                                                    ...formData,
                                                    stay : {
                                                        ...formData?.stay,
                                                        outSideFood : false,
                                                    }
                                                })
                                                // removeValidity('stayIOutSideFoodYes')
                                            }
                                            }
                                            checked={formData?.stay?.outSideFood === false}
                                        />
                                        <label
                                            className="form-check-label"
                                            htmlFor='stayOutSideFoodNo'
                                            >
                                            Not allowed
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* outside end */}
                    {/* smoking start */}
                    <div className="row mb-2">
                        <div className="col-xl-8 d-flex flex-row justify-content-between align-items-center flex-wrap">
                            <span className='form-label' >Smoking<span className="required">*</span> : </span>
                            <div className="d-flex flex-row justify-content-between flex-wrap align-items-center">
                                <div className="d-flex">
                                    <div className="form-check me-3">
                                        <input
                                            type="checkbox"
                                            className="form-check-input"
                                            id="stayISmokingYes"
                                            onChange={() =>{
                                                setFormData({
                                                    ...formData,
                                                    stay : {
                                                        ...formData?.stay,
                                                        smoking : true,
                                                    }
                                                })
                                                removeValidity('stayISmokingYes')
                                            }
                                            }
                                            checked={formData?.stay?.smoking === true}
                                        />
                                        <label
                                            className="form-check-label"
                                            htmlFor='stayISmokingYes'
                                            >
                                            Allowed
                                        </label>
                                    </div>
                                    <div className="form-check">
                                        <input
                                            type="checkbox"
                                            className="form-check-input"
                                            id="stayISmokingNo"
                                            onChange={() =>{
                                                setFormData({
                                                    ...formData,
                                                    stay : {
                                                        ...formData?.stay,
                                                        smoking : false,
                                                    }
                                                })
                                                removeValidity('stayISmokingYes')
                                            }
                                            }
                                            checked={formData?.stay?.smoking === false}
                                        />
                                        <label
                                            className="form-check-label"
                                            htmlFor='stayISmokingNo'
                                            >
                                            Not allowed
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* smokoing end */}
                    {/* nonveg start */}
                    <div className="row mb-2">
                        <div className="col-xl-8 d-flex flex-row justify-content-between align-items-center flex-wrap">
                            <span className='form-label' >Non-vegetarian food<span className="required">*</span> : </span>
                            <div className="d-flex flex-row justify-content-between flex-wrap align-items-center">
                                <div className="d-flex">
                                    <div className="form-check me-3">
                                        <input
                                            type="checkbox"
                                            className="form-check-input"
                                            id="stayINonVegYes"
                                            onChange={() =>{
                                                setFormData({
                                                    ...formData,
                                                    stay : {
                                                        ...formData?.stay,
                                                        nonVeg : true,
                                                    }
                                                })
                                                removeValidity('stayINonVegYes')
                                            }
                                            }
                                            checked={formData?.stay?.nonVeg === true}
                                        />
                                        <label
                                            className="form-check-label"
                                            htmlFor='stayINonVegYes'
                                            >
                                            Allowed
                                        </label>
                                    </div>
                                    <div className="form-check">
                                        <input
                                            type="checkbox"
                                            className="form-check-input"
                                            id="stayINonVegNo"
                                            onChange={() =>{
                                                setFormData({
                                                    ...formData,
                                                    stay : {
                                                        ...formData?.stay,
                                                        nonVeg : false,
                                                    }
                                                })
                                                removeValidity('stayINonVegYes')
                                            }
                                            }
                                            checked={formData?.stay?.nonVeg === false}
                                        />
                                        <label
                                            className="form-check-label"
                                            htmlFor='stayINonVegNo'
                                            >
                                            Not allowed
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* nonveg end */}
                    {/* caretaker start */}
                    <div className="row mb-2">
                        <div className="col-xl-8 d-flex flex-row justify-content-between align-items-center flex-wrap">
                            <span className='form-label' >Caretaker<span className="required">*</span> : </span>
                            <div className="d-flex flex-row justify-content-between flex-wrap align-items-center">
                                <div className="d-flex">
                                    <div className="form-check me-3">
                                        <input
                                            type="checkbox"
                                            className="form-check-input"
                                            id="stayCaretakerYes"
                                            onChange={() =>{
                                                setFormData({
                                                    ...formData,
                                                    stay : {
                                                        ...formData?.stay,
                                                        caretaker : {
                                                            available : true,
                                                            fullDay : '',
                                                            timings : {
                                                                start : '',
                                                                end : '',
                                                            }
                                                        }
                                                    }
                                                })
                                                removeValidity('stayCaretakerYes')
                                                setValidity('stayCaretaker24Hours')
                                            }
                                            }
                                            checked={formData?.stay?.caretaker?.available === true}
                                        />
                                        <label
                                            className="form-check-label"
                                            htmlFor='stayCaretakerYes'
                                            >
                                            Available
                                        </label>
                                    </div>
                                    <div className="form-check">
                                        <input
                                            type="checkbox"
                                            className="form-check-input"
                                            id="stayCaretakerNo"
                                            onChange={() =>{
                                                setFormData({
                                                    ...formData,
                                                    stay : {
                                                        ...formData?.stay,
                                                        caretaker : {
                                                            available : false,
                                                            fullDay : '',
                                                            timings : {
                                                                start : '',
                                                                end : '',
                                                            }
                                                        }
                                                    }
                                                })
                                                removeValidity('stayCaretakerYes')
                                            }
                                            }
                                            checked={formData?.stay?.caretaker?.available === false}
                                        />
                                        <label
                                            className="form-check-label"
                                            htmlFor='stayCaretakerNo'
                                            >
                                            Not available
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {formData?.stay?.caretaker?.available && (
                        <div className="row mb-2">
                            <div className="col-xl-8 d-flex flex-row justify-content-between align-items-center flex-wrap">
                                <span className='form-label' >Caretaker Availability<span className="required">*</span> : </span>
                                <div className="d-flex flex-row justify-content-between flex-wrap align-items-center">
                                <div className="d-flex">
                                    <div className="form-check me-3">
                                        <input
                                            type="checkbox"
                                            className="form-check-input"
                                            id="stayCaretaker24Hours"
                                            onChange={() =>{
                                                setFormData({
                                                    ...formData,
                                                    stay : {
                                                        ...formData?.stay,
                                                        caretaker : {
                                                            ...formData?.stay?.caretaker,
                                                            fullDay : true,
                                                        }
                                                    }
                                                })
                                                removeValidity('stayCaretaker24Hours')
                                            }
                                            }
                                            checked={formData?.stay?.caretaker?.fullDay === true}
                                        />
                                        <label
                                            className="form-check-label"
                                            htmlFor='stayCaretaker24Hours'
                                            >
                                            24 Hours
                                        </label>
                                    </div>
                                    <div className="form-check">
                                        <input
                                            type="checkbox"
                                            className="form-check-input"
                                            id="stayCaretakerCustom"
                                            onChange={() =>{
                                                setFormData({
                                                    ...formData,
                                                    stay : {
                                                        ...formData?.stay,
                                                        caretaker : {
                                                            ...formData?.stay?.caretaker,
                                                            fullDay : false,
                                                        }
                                                    }
                                                })
                                                removeValidity('stayCaretaker24Hours')
                                            }
                                            }
                                            checked={formData?.stay?.caretaker?.fullDay === false}
                                        />
                                        <label
                                            className="form-check-label"
                                            htmlFor='stayCaretakerCustom'
                                            >
                                            Custom
                                        </label>
                                    </div>
                                </div>
                            </div>
                            </div>
                        </div>
                    )}
                    {formData?.stay?.caretaker?.fullDay === false && (
                        <div className="row mb-2">
                            <div className="col-xl-8 d-flex flex-row justify-content-end align-items-center">
                                <div>
                                    <select 
                                        value={formData?.stay?.caretaker?.timings?.start}
                                        required
                                        className="mx-2 textFiled p-0" 
                                        style={{width : "110px", height : "26px"}} 
                                        onChange={(e) =>
                                            setFormData({
                                                ...formData,
                                                stay : {
                                                    ...formData?.stay,
                                                    caretaker : {
                                                        ...formData?.stay?.caretaker,
                                                        timings : {
                                                            start : e.target.value,
                                                            end : '',
                                                        }
                                                    }
                                                }
                                                })
                                            }
                                        >
                                        <option hidden selected value = "" > Start time </option>
                                        {TIME_OPTIONS.map((each) => {
                                            return <option value = {each.value} > {each.label} </option>
                                        })}
                                    </select>
                                    <select 
                                        value={formData?.stay?.caretaker?.timings?.end}
                                        required
                                        className="mx-2 textFiled p-0" 
                                        style={{width : "110px", height : "26px"}} 
                                        onChange={(e) =>
                                            setFormData({
                                                ...formData,
                                                stay : {
                                                    ...formData?.stay,
                                                    caretaker : {
                                                        ...formData?.stay?.caretaker,
                                                        timings : {
                                                            ...formData?.stay?.caretaker?.timings,
                                                            end : e.target.value,
                                                        }
                                                    }
                                                }
                                                })
                                            }
                                        >
                                        <option hidden selected value = "" > End time </option>
                                        {TIME_OPTIONS.map((each) => {
                                            return <option value = {each.value} > {each.label} </option>
                                        })}
                                    </select>
                                </div> 
                            </div>
                        </div>
                    )}
                    {/* caretaker end */}
                    {/* Outside Alcohol start */}
                    <div className="row mb-2">
                        <div className="col-xl-8 d-flex flex-row justify-content-between align-items-center flex-wrap">
                            <span className='form-label' >Outside Alcohol : </span>
                            <div className="d-flex flex-row justify-content-between flex-wrap align-items-center">
                                <div className="d-flex">
                                    <div className="form-check me-3">
                                        <input
                                            type="checkbox"
                                            className="form-check-input"
                                            id="stayIoutsideAlcoholYes"
                                            onChange={() =>{
                                                setFormData({
                                                    ...formData,
                                                    stay : {
                                                        ...formData?.stay,
                                                        outsideAlcohol : true,
                                                    }
                                                })
                                                // removeValidity('stayIoutsideAlcoholYes')
                                            }
                                            }
                                            checked={formData?.stay?.outsideAlcohol === true}
                                        />
                                        <label
                                            className="form-check-label"
                                            htmlFor='filmIoutsideAlcoholYes'
                                            >
                                            Allowed
                                        </label>
                                    </div>
                                    <div className="form-check">
                                        <input
                                            type="checkbox"
                                            className="form-check-input"
                                            id="stayoutsideAlcoholNo"
                                            onChange={() =>{
                                                setFormData({
                                                    ...formData,
                                                    stay : {
                                                        ...formData?.stay,
                                                        outsideAlcohol : false,
                                                    }
                                                })
                                                // removeValidity('stayIoutsideAlcoholYes')
                                            }
                                            }
                                            checked={formData?.stay?.outsideAlcohol === false}
                                        />
                                        <label
                                            className="form-check-label"
                                            htmlFor='stayoutsideAlcoholNo'
                                            >
                                            Not allowed
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/*Outside Alcohol end */}
                    {/* Outside DJ start */}
                    <div className="row mb-2">
                        <div className="col-xl-8 d-flex flex-row justify-content-between align-items-center flex-wrap">
                            <span className='form-label' >Outside DJ : </span>
                            <div className="d-flex flex-row justify-content-between flex-wrap align-items-center">
                                <div className="d-flex">
                                    <div className="form-check me-3">
                                        <input
                                            type="checkbox"
                                            className="form-check-input"
                                            id="stayIoutsideDjYes"
                                            onChange={() => {
                                                setFormData({
                                                    ...formData,
                                                    stay : {
                                                        ...formData?.stay,
                                                        outsideDj : true,
                                                    }
                                                })
                                                // removeValidity('stayIoutsideDjYes')
                                            }
                                            }
                                            checked={formData?.stay?.outsideDj === true}
                                        />
                                        <label
                                            className="form-check-label"
                                            htmlFor='filmIoutsideDjYes'
                                            >
                                            Allowed
                                        </label>
                                    </div>
                                    <div className="form-check">
                                        <input
                                            type="checkbox"
                                            className="form-check-input"
                                            id="stayoutsideDjNo"
                                            onChange={() =>{
                                                setFormData({
                                                    ...formData,
                                                    stay : {
                                                        ...formData?.stay,
                                                        outsideDj : false,
                                                    }
                                                })
                                                // removeValidity('stayIoutsideDjYes')
                                            }
                                            }
                                            checked={formData?.stay?.outsideDj === false}
                                        />
                                        <label
                                            className="form-check-label"
                                            htmlFor='stayoutsideDjNo'
                                            >
                                            Not allowed
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/*Outside DJ end */}
                    {/* Cook Available start */}
                    <div className="row mb-2">
                        <div className="col-xl-8 d-flex flex-row justify-content-between align-items-center flex-wrap">
                            <span className='form-label' >Cook : </span>
                            <div className="d-flex flex-row justify-content-between flex-wrap align-items-center">
                                <div className="d-flex">
                                    <div className="form-check me-3">
                                        <input
                                            type="checkbox"
                                            className="form-check-input"
                                            id="stayIcookYes"
                                            onChange={() =>{
                                                setFormData({
                                                    ...formData,
                                                    stay : {
                                                        ...formData?.stay,
                                                        cook : true,
                                                    }
                                                })
                                                // removeValidity('stayIcookYes')
                                            }
                                            }
                                            checked={formData?.stay?.cook === true}
                                        />
                                        <label
                                            className="form-check-label"
                                            htmlFor='filmIcookYes'
                                            >
                                            Available
                                        </label>
                                    </div>
                                    <div className="form-check">
                                        <input
                                            type="checkbox"
                                            className="form-check-input"
                                            id="staycookNo"
                                            onChange={() =>{
                                                setFormData({
                                                    ...formData,
                                                    stay : {
                                                        ...formData?.stay,
                                                        cook : false,
                                                    }
                                                })
                                                // removeValidity('stayIcookYes')
                                            }
                                            }
                                            checked={formData?.stay?.cook === false}
                                        />
                                        <label
                                            className="form-check-label"
                                            htmlFor='staycookNo'
                                            >
                                            Not available
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {formData?.stay?.cook === true && (
                        <div className="row mb-2">
                            <div className="col-xl-8 d-flex flex-row justify-content-between align-items-center flex-wrap">
                                <label htmlFor="cuisines_by_cook" className="form-label">
                                Cuisines Prepared by the Cook <span className="required">*</span>
                                </label>
                                <Select
                                    value={formData?.stay?.cuisines}
                                    required
                                    isMulti='multi'
                                    options={CUSINEOPTIONS}
                                    onChange={(e) =>
                                        setFormData({
                                        ...formData,
                                        stay : {
                                            ...formData?.stay,
                                            cuisines: e,
                                        }
                                        })  
                                    } 
                                    className="policies-select"
                                    classNamePrefix="select Cuisine"
                                    closeMenuOnSelect= {false} 
                                />
                            </div>
                        </div>
                    )}
                    {/*Cook Available end */}
                    {/* Outside pets start */}
                    <div className="row mb-2">
                        <div className="col-xl-8 d-flex flex-row justify-content-between align-items-center flex-wrap">
                            <span className='form-label' >Pets are allowed : </span>
                            <div className="d-flex flex-row justify-content-between flex-wrap align-items-center">
                                <div className="d-flex">
                                    <div className="form-check me-3">
                                        <input
                                            type="checkbox"
                                            className="form-check-input"
                                            id="stayIoutsidePetsYes"
                                            onChange={() =>{
                                                setFormData({
                                                    ...formData,
                                                    stay : {
                                                        ...formData?.stay,
                                                        outsidePets : true,
                                                    }
                                                })
                                                // removeValidity('stayIoutsidePetsYes')
                                            }
                                            }
                                            checked={formData?.stay?.outsidePets === true}
                                        />
                                        <label
                                            className="form-check-label"
                                            htmlFor='filmIoutsidePetsYes'
                                            >
                                            Allowed
                                        </label>
                                    </div>
                                    <div className="form-check">
                                        <input
                                            type="checkbox"
                                            className="form-check-input"
                                            id="stayoutsidePetsNo"
                                            onChange={() =>{
                                                setFormData({
                                                    ...formData,
                                                    stay : {
                                                        ...formData?.stay,
                                                        outsidePets : false,
                                                    }
                                                })
                                                // removeValidity('stayIoutsidePetsYes')
                                            }
                                            }
                                            checked={formData?.stay?.outsidePets === false}
                                        />
                                        <label
                                            className="form-check-label"
                                            htmlFor='stayoutsidePetsNo'
                                            >
                                            Not allowed
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/*Outside pets end */}
                    {/* Any Pets living in the Property start */}
                    <div className="row mb-2">
                        <div className="col-xl-8 d-flex flex-row justify-content-between align-items-center flex-wrap">
                            <span className='form-label' >Any Pets living in the Property : </span>
                            <div className="d-flex flex-row justify-content-between flex-wrap align-items-center">
                                <div className="d-flex">
                                    <div className="form-check me-3">
                                        <input
                                            type="checkbox"
                                            className="form-check-input"
                                            id="stayIinhousePetsYes"
                                            onChange={() =>{
                                                setFormData({
                                                    ...formData,
                                                    stay : {
                                                        ...formData?.stay,
                                                        inhousePets : true,
                                                    }
                                                })
                                                // removeValidity('stayIinhousePetsYes')
                                            }
                                            }
                                            checked={formData?.stay?.inhousePets === true}
                                        />
                                        <label
                                            className="form-check-label"
                                            htmlFor='filmIinhousePetsYes'
                                            >
                                            Yes
                                        </label>
                                    </div>
                                    <div className="form-check">
                                        <input
                                            type="checkbox"
                                            className="form-check-input"
                                            id="stayinhousePetsNo"
                                            onChange={() =>{
                                                setFormData({
                                                    ...formData,
                                                    stay : {
                                                        ...formData?.stay,
                                                        inhousePets : false,
                                                    }
                                                })
                                                // removeValidity('stayIinhousePetsYes')
                                            }
                                            }
                                            checked={formData?.stay?.inhousePets === false}
                                        />
                                        <label
                                            className="form-check-label"
                                            htmlFor='stayinhousePetsNo'
                                            >
                                            No
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/*Any Pets living in the Property end */}
                    {/* Couple friendly start */}
                    <div className="row mb-2">
                        <div className="col-xl-8 d-flex flex-row justify-content-between align-items-center flex-wrap">
                            <span className='form-label' >Couple friendly : </span>
                            <div className="d-flex flex-row justify-content-between flex-wrap align-items-center">
                                <div className="d-flex">
                                    <div className="form-check me-3">
                                        <input
                                            type="checkbox"
                                            className="form-check-input"
                                            id="stayIcoupleFriendlyYes"
                                            onChange={() =>{
                                                setFormData({
                                                    ...formData,
                                                    stay : {
                                                        ...formData?.stay,
                                                        coupleFriendly : true,
                                                    }
                                                })
                                                // removeValidity('stayIcoupleFriendlyYes')
                                            }
                                            }
                                            checked={formData?.stay?.coupleFriendly === true}
                                        />
                                        <label
                                            className="form-check-label"
                                            htmlFor='filmIcoupleFriendlyYes'
                                            >
                                            Yes
                                        </label>
                                    </div>
                                    <div className="form-check">
                                        <input
                                            type="checkbox"
                                            className="form-check-input"
                                            id="staycoupleFriendlyNo"
                                            onChange={() =>{
                                                setFormData({
                                                    ...formData,
                                                    stay : {
                                                        ...formData?.stay,
                                                        coupleFriendly : false,
                                                    }
                                                })
                                                // removeValidity('stayIcoupleFriendlyYes')
                                            }
                                            }
                                            checked={formData?.stay?.coupleFriendly === false}
                                        />
                                        <label
                                            className="form-check-label"
                                            htmlFor='staycoupleFriendlyNo'
                                            >
                                            No
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/*Couple friendly end */}
                    {/* Staycation custom policies start  */}
                    <div className="row mb-2">
                        <div className="col-xl-8 d-flex flex-row justify-content-between align-items-center flex-wrap">
                            <span className='form-label' > Custom staycation policies : </span> <br/>
                        </div>
                    </div>
                    <div className="row mb-2">
                        <div className="col-xl-8 d-flex flex-row justify-content-between align-items-center flex-wrap">
                            <span className='form-label' >Do you want to add custom staycation policies : </span>
                            <div className="d-flex flex-row justify-content-between flex-wrap align-items-center">
                                <div className="d-flex">
                                    <div className="form-check me-3">
                                        <input
                                            type="checkbox"
                                            className="form-check-input"
                                            id="customStaycationPoliciesYes"
                                            onChange={() =>{
                                                setFormData({
                                                    ...formData,
                                                    stay : {
                                                        ...formData?.stay,
                                                        customPolicy : true,
                                                    }
                                                })
                                                // removeValidity('stayIcoupleFriendlyYes')
                                            }
                                            }
                                            checked={formData?.stay?.customPolicy === true}
                                        />
                                        <label
                                            className="form-check-label"
                                            htmlFor='customStaycationPoliciesYes'
                                            >
                                            Yes
                                        </label>
                                    </div>
                                    <div className="form-check">
                                        <input
                                            type="checkbox"
                                            className="form-check-input"
                                            id="customStaycationPoliciesNo"
                                            onChange={() =>{
                                                setFormData({
                                                    ...formData,
                                                    stay : {
                                                        ...formData?.stay,
                                                        customPolicy : false,
                                                        customPolicies : [],
                                                    }
                                                })
                                                // removeValidity('stayIcoupleFriendlyYes')
                                            }
                                            }
                                            checked={formData?.stay?.customPolicy === false}
                                        />
                                        <label
                                            className="form-check-label"
                                            htmlFor='customStaycationPoliciesNo'
                                            >
                                            No
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {
                        formData?.stay?.customPolicies?.length > 0 && (
                            <div className="row mb-2">
                                {
                                    formData?.stay?.customPolicies?.map((each, index) => {
                                        return(
                                            <span className="SpotLetContent form-label" > {each}  <span onClick={() => deleteCustomPolicy(index, "stay") }> <i class="bi bi-trash"></i> </span> </span>
                                        )
                                    } )
                                }
                            </div>
                        )
                    }
                    {
                        formData?.stay?.customPolicy && (
                            <>
                                <div className="col-xl-8">
                                    <input 
                                        className="textFiled w-50"
                                        type="text"
                                        value = {custom?.stay}
                                        placeholder = "Enter custom policy"
                                        onChange={(e) => {
                                            setCustom( {...custom, "stay" : e?.target?.value } )
                                        }}
                                    />
                                </div>
                                <button 
                                    className="btn btn-primary mt-2" 
                                    type = "button"
                                    onClick={() => {
                                        const rule = custom?.stay?.trim();
                                        let reqArray = [...formData?.stay?.customPolicies, rule];
                                        // reqArray = reqArray.push(rule);
                                        if (rule) {
                                            setFormData({
                                                ...formData,
                                                stay : {
                                                    ...formData?.stay,
                                                    customPolicies : reqArray
                                                }
                                            })
                                            setCustom({
                                                ...custom,
                                                "stay" : ""
                                            })
                                        }
                                    } }> 
                                    Add Policy 
                                </button>
                            </>
                        )
                    }
                    {/* Staycation custom policies end  */}
                </div>
            </div>
        )
    }

    return(
        <>
            <form className="locationtype-page container-fluid" onSubmit={onClickSubmit} >
                <h1 className="locationtype-head" id='listPolicies'> Policies {' '} 
                    <a
                        href="#"
                        data-bs-toggle="tooltip"
                        title=" Clearly state cancellation, refund policies, and essential guest rules."
                    >
                        <img src={info} />
                    </a>
                </h1>
                 {/* film start */}
                 {(formData?.film?.status) && (
                    filmTvPolicies()
                )}
                {/* film end */}
                {/* event start */}
                {formData?.event?.status && (
                    eventPolicies()
                )}
                {/* event end */}
                {/* stay start */}
                {formData?.stay?.status && (
                    staycationPolicies()
                )}
                {/* stay end */}
                {/* Terms  start */}
                <div className="mt-2" >
                    <h6> Terms and Conditions <span className="required">*</span> : </h6>
                    <div className="form-check">
                        <input
                          className="form-check-input"
                          required
                          disabled
                          type="checkbox"
                          value=""
                          checked={formData.privacy_policy}
                          id="agree1"
                          name="privacy_policy"
                        />
                        <label className="form-check-label" htmlFor="agree1">
                          I have read and agree to the privacy policy<span className="required">*</span>
                        </label>
                      </div>
                      <div className="form-check">
                        <input
                          className="form-check-input"
                          required
                          disabled
                          type="checkbox"
                          checked={formData.term_cond}
                          value=""
                          id="agree2"
                          name="term_cond"
                        />
                        <label className="form-check-label" htmlFor="agree2">
                          I agree to the Term and Conditions<span className="required">*</span>
                          {/* I agree to the <Link style={{ color: "#ea4235" }} to={'/termsofservice'} target="_blank">Term and Conditions</Link> */}
                        </label>
                      </div>
                      <div className="form-check">
                        <input
                          className="form-check-input"
                          required
                          disabled
                          type="checkbox"
                          value=""
                          checked={formData?.grant_info}
                          id="agree3"
                          name="grant_info"
                        />
                        <label className="form-check-label" htmlFor="agree3">
                          I Consent to SpotLet using my information<span className="required">*</span>
                        </label>
                      </div>
                </div>
                {/* Terms  end */}
                {/* Footer Start */}
                <div className="row controlContainer">
                    <div className="col-xl-8">
                        <div className="d-flex flex-row justify-content-between align-items-center">
                            <div>
                                <button
                                type="button"
                                class="btn btn-outline-danger"
                                onClick={handleBack}
                                >
                                    Back
                                </button>
                            </div>
                            <div>
                                <button
                                type="submit"
                                className="btn btn-primary"
                                >
                                    Save & Continue
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            {/* Footer End */}
            </form>
            {showPrivacyAndPolicyModel && (
                <PrivacyAndPolicyView
                show={showPrivacyAndPolicyModel}
                handleClose={() => setPrivacyAndPolicyModel(false)}
                sendAgree={() => { setPrivacyAndPolicyModel(false), handlePrivacyAndPolicyAgreement() }}
                />
            )}
        
            {showTermsAndConditionsModel && (
                <TermsAndConditionsView
                show={showTermsAndConditionsModel}
                handleClose={() => setTermsAndConditionsModel(false)}
                sendAgree={() => { setTermsAndConditionsModel(false), handleAgreement() }}
                />
            )}
        </>
    )
}
export default Policies;