import { useState,useEffect } from "react";
import { useLocation, useNavigate } from 'react-router-dom';
import { Header } from "../NewHeader/Header";
import { createIncompleteListing,updateIncompleteListing,getIncompleteListing, createListing, getListing, updateListing } from "../../apis/api";
import { useLocationStore } from "../../store/location";
import { useAuthStore } from "../../store/auth";
import "./ListYourSpace.css"

//stepper
import { styled } from '@mui/material/styles';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Check from '@mui/icons-material/Check';
import StepConnector, { stepConnectorClasses } from '@mui/material/StepConnector';

//tabs
import TypeOfProperty from "./TypeOfProperty";
import BasicInfo from "./BasicInfo"
import Location from "./Location"
import Amenities from "./Amenities"
import Features from "./Features"
import PricingAndTimings from "./PricingAndTimings";
import Gallery from "./Gallery";
// import Policies from "./Policies";
import Policies from "./NewPolicies";
import Activities from "./Activities";
import PropertyManagerDetails from "./PropertyManagerDetails";
import SubProperties from "./SubProperties/SubProperties"

const STEPS = [
    {
        label: 'Type of Property',
      },
    {
      label: 'Basic Information',
    },
    {
      label: 'Location',
    },
    {
      label: 'All Amenities',
    },
    {
      label: 'Rooms/Spaces',
    },
    {
      label: `Pricing & Timings`,
    },
    {
      label: 'Gallery',
    },
    {
      label: 'Policies',
    },
    {
      label: 'Activities',
    },
    {
      label: 'Property Manager Details',
    },
];

const ICONS = {1:1,2:2,3:3,4:4,5:5,6:6,7:7,8:8,9:9,10:10};

const PROPRERTY_TYPES = ["Cafe and Mart", "Retail Spaces", "Convention Centers", "College", "School", "University", "Castles", "Forts", "Heritage Building", "Museum", "Hotels", "Resort", "Factory", "Manufacturing Facility", "Condominiums", "Gated Communities", "Sports Complex", "Art studios", "Fashion photography", "Film Studio", "Photo Studio"]

export const ListYourSpace = () => {

  let location = useLocation();
  let navigate = useNavigate();
  const {plan} = useLocationStore();
  const { userProfile,setUserProfile } = useAuthStore();
  const [selectedLocationType, setSelectedLocationType] = useState("");
  const [payload, setPayload] = useState({
    user_id : userProfile?._id,
    subscription_id : plan,
    sub_properties : false,
  })
  const [locationId,setLocationId] = useState(null);
  const [type, setType] = useState(location?.state?.type);
  const [steps, setSteps] = useState(STEPS);
  const [icons, setIcons] = useState(ICONS);
  const [isSubPropertyActive, setIsSubPropertyActive] = useState(false);

    //sidebar start
    const [activeStep, setActiveStep] = useState(0);
    const [tabStep, setTabStep] = useState(0);
    const [showSideBar, setShowSideBar] = useState(false)
    const QontoConnector = styled(StepConnector)(({theme}) => ({
        [`&.${stepConnectorClasses.active}`]: {
            [`& .${stepConnectorClasses.line}`]: {
                borderWidth : '2px',
              borderColor: '#2e7d32',
              color:'#2e7d32',
            },
          },
          [`&.${stepConnectorClasses.completed}`]: {
            [`& .${stepConnectorClasses.line}`]: {
                borderWidth : '2px',
              borderColor: '#2e7d32',
              color:'#2e7d32',
            },
          },
          [`& .${stepConnectorClasses.line}`]: {
            borderColor: theme.palette.mode === 'dark' ? theme.palette.grey[800] : '#eaeaf0',
            borderTopWidth: 3,
            borderRadius: 1,
          },
    }))
    const ColorlibStepIconRoot = styled('div')(({ theme, ownerState }) => ({
        backgroundColor: theme.palette.mode === 'dark' ? theme.palette.grey[700] : '#ccc',
        zIndex: 1,
        color: '#fff',
        width: 25,
        height: 25,
        display: 'flex',
        borderRadius: '50%',
        justifyContent: 'center',
        alignItems: 'center',
        ...(ownerState.active && {
          backgroundColor:'#2e7d32',
          boxShadow: '0 4px 10px 0 rgba(0,0,0,.25)',
        }),
        ...(ownerState.completed && {
            backgroundColor:'#2e7d32',
        }),
      }));
      function ColorlibStepIcon(props) {
        const { active, completed, className } = props;
      
      
        return (
          <ColorlibStepIconRoot ownerState={{ completed, active }} className={className}>
           {completed ? <Check /> :  icons[String(props.icon)]}
          </ColorlibStepIconRoot>
        );
      }
    const handleNext = (data) => {
      setPayload({
        ...payload,
        ...data,
        step : tabStep === activeStep ?  activeStep + 1 : payload?.step,
      });
      setTabStep((prevActiveStep) => prevActiveStep + 1);
      if(tabStep === activeStep){
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
      }
    };
    const handleBack = () => {
        setTabStep((prevActiveStep) => prevActiveStep - 1);
        // if(tabStep === activeStep){
        //   setActiveStep((prevActiveStep) => prevActiveStep - 1);
        // }
    };
    const handleOnclickStep = (step) => {
      setShowSideBar(false)
      if(step <= activeStep){
        setTabStep(step)
      }
    }
    //sidebar end

    const handleSelectLocationType = (value) => {
      setSelectedLocationType(value)
      setPayload({
        ...payload,
        location_type : value,
        step : activeStep === 0 ? 1 : activeStep,
      })
      setTabStep((prevActiveStep) => prevActiveStep + 1);
      if(tabStep === activeStep){
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
      }
      handleSubProperty(value);
    }
    const handleBasicInfo = async(data) => {
      try{
        if(!locationId){
          const res = await createIncompleteListing({...data,...payload,locationType : selectedLocationType,step : activeStep})
          setUserProfile()
          setPayload({
            ...payload,
            ...data,
            location_id : res?.data,
            step : activeStep === 1 ? 2 : activeStep,
          });
        }else{
          setPayload({
            ...payload,
            ...data,
            step : tabStep === activeStep ?  activeStep : payload?.step,
          });
        }
        setTabStep((prevActiveStep) => prevActiveStep + 1);
        if(tabStep === activeStep){
          setActiveStep((prevActiveStep) => prevActiveStep + 1);
        }
      }catch(error){
        console.log(error)
      }
    }
    const handleSubPopertySave = (data) => {
      setPayload({
        ...payload,
        sub_properties : data ? [...data] : false,
      })
    }
    const handleSubmit = async (payload) => {
      try{
        switch(type) {
          case 'incomplete':
            await createListing(payload)
            break;
          case 'listing':
            await updateListing(payload,true) //true is a query param to send submit status
            alert('Your Updated Listing has been submitted, Our team will review and update you the status')
            break;
          default:
            alert('Error while updating data')
        }
        navigate('/listed-properties')

      }catch(error){
        console.log(error)
      }
    }
  
    const handleSubProperty = (value) => {
      if (PROPRERTY_TYPES.includes(value) && (payload?.subscription_id !== "Basic")) {
        setIsSubPropertyActive(true);
        setSteps([
          ...STEPS,
         {label: 'Sub Property'}
        ] );
        setIcons({...icons,11:11});
      }
      else{
        setIsSubPropertyActive(false);
        setSteps(STEPS);
        setIcons(ICONS);
        if(activeStep === 10){
          setPayload({
            ...payload,
            location_type : value,
            step : 9,
          })
          setActiveStep(9)
        }
      }
    }

    useEffect(()=> {
      const initUpdateIncompleteListing = async () => {
        try{
          switch(type) {
            case 'incomplete':
              await updateIncompleteListing(payload);
              break;
            case 'listing':
              console.log('Data updated')
              await updateListing(payload,false) //false is a query param to send submit status
              break;
            default:
              alert('Error while updating data, Please try again')
          }
        }catch(error){
          console.log(error)
        }
      }
      setLocationId(payload?.location_id)
      if(locationId){
        initUpdateIncompleteListing();
      }
    },[payload])

    useEffect(() => {
      const getLocationDetails = async () => {
        try{
          let res
          switch(type) {
            case 'incomplete':
              res = await getIncompleteListing(location?.state?.locationId)
              break;
            case 'listing':
              res = await getListing(location?.state?.locationId)
              break;
            default:
              alert('Error while fetching data, Please try again')
              navigate('/listed-properties')
          }
          setPayload(res?.data)
          setActiveStep(parseInt(res?.data?.step))
          setTabStep(type === 'incomplete' ? parseInt(res?.data?.step) > 10 ? 10 : parseInt(res?.data?.step) : 0) 
          handleSubProperty(res?.data?.location_type);
        }catch(error){
          console.log(error)
        }
      }
      if(location?.state?.locationId){
        getLocationDetails()
      }else{
        setType('incomplete')
      }
    },[payload?.subscription_id]);

    // When page is reloded we should redirect it 
    window.onload = () => {
      const navEntries = performance.getEntriesByType("navigation");
      if (navEntries.length && navEntries[0].type === "reload") {
          window.location.href = "/listed-properties"; 
      }
  };

  const getPricingData = (data) => {
    const reqData = {
      film : data?.film?.available,
      tv : data?.tv?.available,
      event : data?.event?.available,
      stay : data?.stay?.available,
    }
    return reqData
  }

    return(
        <>
            <Header inListing = {true} />
            <div className="mainContainer">
                <div className="sideBar" id="sidebar-wrapper" style={{ marginLeft: showSideBar ? '0px' : null, width: showSideBar ? '100%' : '' }}>
                <button className="close-side-menu" onClick={() => setShowSideBar(false)}>
                    <i class="bi bi-x"></i>
                </button>
                <Stepper activeStep={activeStep} orientation="vertical" connector={<QontoConnector />}>
                    {steps.map((step, index) => (
                        <Step key={step.label} disabled = {!PROPRERTY_TYPES.includes(selectedLocationType)}>
                            <StepLabel StepIconComponent={ColorlibStepIcon} onClick={()=>handleOnclickStep(index)}  style={{cursor: 'pointer'}}>
                            <span style={{color : tabStep === index ? '#2e7d32' : ''}}>{step.label}</span>
                            </StepLabel>
                        </Step>
                        ))}
                </Stepper>
                </div>
                <div className="tabsContainer" style={{ display: showSideBar ? 'none' : null, }}>
                    <div className="tabSection">
                        <button className="side-menu" onClick={() => setShowSideBar(true)} id="sidebarToggle">
                            <i className="bi bi-list"></i>
                        </button>
                        {tabStep === 0 && (
                            <TypeOfProperty data={payload?.location_type} handleBack={handleBack} handleNext={handleSelectLocationType} />
                        )}
                        {tabStep === 1 && (
                            <BasicInfo data={payload?.basic_info} handleBack={handleBack} handleNext={handleBasicInfo}/>
                        )}
                        {tabStep === 2 && (
                            <Location data={payload?.address} handleBack={handleBack} handleNext={handleNext} />
                        )}
                        {tabStep === 3 && (
                            <Amenities data={payload?.amenities}  handleBack={handleBack} handleNext={handleNext}/>
                        )}
                        {tabStep === 4 && (
                            <Features data={payload} handleBack={handleBack} handleNext={handleNext}/>
                        )}
                        {tabStep === 5 && (
                            <PricingAndTimings policiesData = {payload?.policies} activitiesData = {payload?.tags} data={payload?.pricing} handleBack={handleBack} handleNext={handleNext}/>
                        )}
                        {tabStep ===6 && (
                            <Gallery data={payload} handleBack={handleBack} handleNext={handleNext}/>
                        )}
                        {tabStep === 7 && (
                            <Policies pricingData = {getPricingData(payload?.pricing)} data={payload?.policies} handleBack={handleBack} handleNext={handleNext}/>
                        )}
                        {tabStep === 8 && (
                            <Activities pricingData = {getPricingData(payload?.pricing)} data={payload?.tags} handleBack={handleBack} handleNext={handleNext} propertyId = {payload?.location_id}/>
                        )}
                        {tabStep === 9 && (
                            <PropertyManagerDetails data={payload?.property_manager_details} handleBack={handleBack} handleNext={handleNext} isSubPropertyActive = {isSubPropertyActive} handleSubmit={(data) =>  handleSubmit({...payload, ...data})}/>
                        )}
                        {(tabStep === 10 && isSubPropertyActive)  && (
                            <SubProperties savedSubPropertyData = {payload?.sub_properties} propertyId = {payload?.location_id} handleBack={handleBack} handleSubPopertySave = {handleSubPopertySave} handleSubmit={() =>  handleSubmit(payload)}/>
                        )}
                    </div>
                </div>
                {/* <div className="rightSection">
                    <h1>Right section</h1>
                </div> */}
            </div>
        </>
    )
};