import React, { useEffect } from "react";
import { Box, Typography } from "@mui/material";

//Components
import { Header } from "../../components/NewHeader/Header"
import { Footer } from "../../components/Footer"
import Constants from "../../utils/Helmet";

// Images
import bannerPhoto from "../../assets/NewImages/HelpCenter/policy-banner.webp";

import "./style.css";

export default function HelpCenter() {

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [])

  return (
    <>
      <Header />
      <Constants type={"Contact us"} />
      <Box className="PolicyContainer">
        <Box className="policy-bg">
          <img src={bannerPhoto} className="image-overlay" />
          <Box className="banner-content">
            <Typography variant="h1" gutterBottom>
              Contact Us
            </Typography>
          </Box>
        </Box>
        <Box className="policy-content">
          <Typography variant="h2" gutterBottom>
            Contact Us :
          </Typography>
          <Typography variant="body1" gutterBottom>
            <span className="span600">Mobile no : </span> 997 997 2245
          </Typography>
          <Typography variant="body1" gutterBottom>
            <span className="span600">Email : </span>
            <a
              target="_blank"
              href={`mailto:support@spotlet.in`}
              title="Share viaEmail"
            >support@spotlet.in</a>
          </Typography>
          <Typography variant="h2" gutterBottom>
            Address :
          </Typography>
          <Typography variant="body1" >
            4th Floor, P.No 1211, Above Manjeera Mart
          </Typography>
          <Typography variant="body1">
            Opposite Prajay Magapolis 2nd Gate
          </Typography>
          <Typography variant="body1">
            Venkata Ramana Colony
          </Typography>
          <Typography variant="body1">
            Hafeezpet, Hyderabad, 500085
          </Typography>
          <Typography variant="body1">
            Telangana, India.
          </Typography>
        </Box>
      </Box>
      <Footer />
    </>
  );
}
