import { VerifyEmail } from "../utils/Authentication/VerifyEmail";
import { InformationModal } from "../components/Modal/InformationModal";
import { useAuthStore } from "../store/auth";
import { useState, useEffect, useRef } from "react";
import { getOtp, signUp } from "../apis/api";
import { useNavigate, Link } from "react-router-dom";
import { GoogleLogin } from "react-google-login";
import { gapi } from "gapi-script";
import { Formik } from "formik";
import * as yup from "yup";
import { Button, Col, Form, Row, Stack, Modal } from "react-bootstrap";
import PhoneInput, {isValidPhoneNumber} from 'react-phone-number-input'
import 'react-phone-number-input/style.css';

//For Bcrypt
import bcrypt from "bcryptjs-react"

// Images
import logoName from "../assets/NewImages/Login/logoname.svg";
import whatsup from "../assets/NewImages/Login/whatsup.svg";

const schema = yup.object().shape({
  firstName: yup.string().required(),
  //lastName: yup.string().required(),
  email: yup.string().email()
  .matches(/^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i, ' Enter a Valid Email')
  .required('Email is required'),
  mobile: yup.number()
    //.required()
    .min(999999999, 'Phone number must be 10 digits')
    .max(9999999999, 'Phone number must be 10 digits'),
  password: yup
    .string()
    .required()
    .min(8, 'Password must be 8 characters long')
    .matches(/[0-9]/, 'Password requires a number')
    .matches(/[a-z]/, 'Password requires a lowercase letter')
    .matches(/[A-Z]/, 'Password requires an uppercase letter')
    .matches(/[^\w]/, 'Password requires a symbol'),
  terms: yup.bool().required().oneOf([true], "Terms must be accepted"),
});

export const SignUp = () => {
  const navigate = useNavigate();
  const { setAuthenticated, setUserProfile } = useAuthStore();
  const [details, setDetails] = useState();
  const formikRef = useRef();

  const [otp, setOtp] = useState();
  const [showVerifyOtpModal, setShowVerifyOtpModal] = useState(false);

  // For Showing Errors on Signup
  const [showErrorModal, setShowErrorModal] = useState(false);
  const [errorMsg, setErrorMsg] = useState("Some Error Occured");

  //const [termsAccepted, setTermsAccepted] = useState(false);
  const [userEnteredOtp, setUserEnteredOtp] = useState("");

  const [hidePassword, setHidePassword] = useState(false);

  // const { firstName, lastName, email, password, mobile } = details;
  const [googleClientId, setGoogleClientId] = useState(
    "837678588128-701v5ikaevb6ahi5bv0ps700q7lqit9d.apps.googleusercontent.com"
  );

  const [numberError, setNumberError] = useState("");
  const [number, setNumber] = useState("")

  useEffect(() => {
    const initClient = () => {
      gapi.client.init({
        clientId:
          "837678588128-701v5ikaevb6ahi5bv0ps700q7lqit9d.apps.googleusercontent.com",
        scope: "",
      });
    };
    setTimeout(() => {
      // @ts-ignore
      gapi.load("client:auth2", initClient);
    }, 1000);
  });

  const onSuccess = (res) => {

    signUpUser(
      res?.profileObj?.givenName,
      res?.profileObj?.familyName,
      res?.profileObj?.email,
      true,
      true
    );
  };

  const onFailure = (err) => {
    console.log("failed:", err);
  };

  useEffect(() => {
    // Define the 'otpless' function
    window.otpless = (otplessUser) => {
      // Retrieve the user's details after successful login
      const waName = otplessUser.waName;
      const waNumber = otplessUser.waNumber;


      let firstName = otplessUser?.waName?.fullName?.split(" ")[0];
      let lastName = otplessUser?.waName?.fullName?.split(" ")[1];

      const req = {
        firstName: firstName,
        lastName: lastName,
        mobile: otplessUser.waNumber,
        whatsappLogin: true,
      };
      userSignUp(req);

    };
  }, []);

  const userSignUp = async (userObj) => {
    try {
      const res = await signUp(userObj);
      const jwt = res.data.token;
      localStorage.setItem("auth", jwt);
      setAuthenticated();
      navigate("/");
    } catch (error) {
      if (error?.response?.data?.error) {
        // alert(error?.response?.data?.error);
        setErrorMsg(error?.response?.data?.error)
        setShowErrorModal(true);
      } else {
        // alert("Error while user signup");
        setErrorMsg("Error while user Register")
        setShowErrorModal(true);
      }
    }
  };

  const sendOtp = async (formValues) => {
    try {
      const response = await getOtp(formValues);
      setOtp(response.data.otp);
      setShowVerifyOtpModal(true);
    } catch (error) {
      // alert(error?.response?.data?.error);
      console.log(error);
      setErrorMsg(error?.response?.data?.error)
      setShowErrorModal(true);

    }
  };

  const onSubmit = async (formValues) => {
    let reqNumber = ''
    if (number === undefined) {
      reqNumber = ""
      setNumberError("");
    }
    else {
      reqNumber = number
      if (number.slice(0,4) === "+911") {
        setNumberError("Enter a Valid Phone Number")
      }
      else {
        if (isValidPhoneNumber(reqNumber) || reqNumber === '' ) {
          setNumberError("");
          setDetails({...formValues, mobile : reqNumber});
          await sendOtp({...formValues, mobile : reqNumber});
      }
      else{
        setNumberError("Enter a Valid Phone Number")
      }
    }
    }
  };

  const handleResend = async () => {
    await sendOtp(formikRef.current.values);
  };

  const completeSignUp = async () => {
    try {
      const response = await signUp(details);
      setShowVerifyOtpModal(false);
      localStorage.setItem("auth", response.data.token);
      setAuthenticated();
      setUserProfile();
      navigate("/");
    } catch (error) {
      console.error(error);
      // alert(error?.response?.data?.error);
      setErrorMsg(error?.response?.data?.error)
      setShowErrorModal(true);
    }
  };

  const onVerify = async () => {
    const isOk = await bcrypt.compare(userEnteredOtp, otp);
    console.log(isOk);
    if (!isOk) {
      setErrorMsg("Invalid OTP please try again!!!")
      setShowErrorModal(true);
      // return alert("Invalid OTP please try again!!!");
    }
    else {
      details.emailVerified = true;
      await completeSignUp();
    }
  };

  const signUpUser = async (firstName, lastName, email, googleLogin, emailVerified) => {
    let userObj = {
      firstName: firstName,
      lastName: lastName,
      mobile: "",
      email: email,
      password: "",
      booking_type: "",
      profession: "",
      company: "",
      googleLogin: googleLogin,
      emailVerified: emailVerified
    };
    try {
      const res = await signUp(userObj);
      const jwt = res.data.token;
      localStorage.setItem("auth", jwt);
      setAuthenticated();
      navigate("/");
    } catch (error) {
      // alert(error?.response?.data?.error);
      setErrorMsg(error?.response?.data?.error);
      setShowErrorModal(true);
    }
  };

  function isNumeric(value) {
    return /^-?\d+$/.test(value);
  }


  return (
    <div className="container-fluid p-0">
      <div className="row g-0">
        <div className="col-md-8 col-lg-6 col-11 mx-auto">
          <div className="login d-flex align-items-center py-5">
            <div className="container">
              <div className="row">
                <div className="col-md-10 col-lg-9 mx-auto">
                  <h3 className="login-heading mb-4">
                    Register to <img src={logoName} />
                  </h3>
                  <Formik
                    validationSchema={schema}
                    onSubmit={onSubmit}
                    initialValues={{
                      firstName: "",
                      lastName: "",
                      mobile: "",
                      email: "",
                      password: "",
                      booking_type: "individual",
                      profession: "",
                      company: "",
                      googleLogin: false,
                      terms: false,
                    }}
                    innerRef={formikRef}
                  >
                    {({
                      handleSubmit,
                      handleChange,
                      handleBlur,
                      values,
                      touched,
                      isValid,
                      errors,
                    }) => (
                      <Form noValidate onSubmit={handleSubmit}>
                        <>
                          <Row>
                            <Form.Group as={Col} md="6">
                              <Form.Label>
                                First Name<span className="required">*</span>
                              </Form.Label>
                              <Form.Control
                                type="text"
                                name="firstName"
                                value={values.firstName}
                                onChange={handleChange}

                                isInvalid={!!touched.firstName && !!errors.firstName}
                              />
                              <Form.Control.Feedback
                                type="invalid"
                                className="text-capitalize"
                              >
                                {errors.firstName}
                              </Form.Control.Feedback>
                            </Form.Group>
                            <Form.Group
                              as={Col}
                              md="6"
                              controlId="validationFormik02"
                            >
                              <Form.Label>
                                Last Name
                                {/* <span className="required">*</span> */}
                              </Form.Label>
                              <Form.Control
                                type="text"
                                name="lastName"
                                value={values.lastName}
                                onChange={handleChange}
                                isInvalid={!!errors.lastName}
                              />
                              <Form.Control.Feedback
                                type="invalid"
                                className="text-capitalize"
                              >
                                {errors.lastName}
                              </Form.Control.Feedback>
                            </Form.Group>
                          </Row>
                        </>
                        <Form.Group as={Col} md="12" className="mt-3">
                          <Form.Label>
                            Email<span className="required">*</span>
                          </Form.Label>
                          <Form.Control
                            type="text"
                            name="email"
                            value={values.email}
                            onChange={handleChange}
                            isInvalid={!!touched.email && !!errors.email}
                          />
                          <Form.Control.Feedback
                            type="invalid"
                            className="text-capitalize"
                          >
                            {errors.email}
                          </Form.Control.Feedback>
                        </Form.Group>
                        {/* <Form.Group as={Col} md="12" className="mt-3">
                          <Form.Label>Phone Number</Form.Label>
                          <Form.Control
                            type="number"
                            name="mobile"
                            value={values.mobile}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            isInvalid={!!errors.mobile}
                          />
                          <Form.Control.Feedback
                            type="invalid"
                            className="text-capitalize"
                          >
                            {errors.mobile}
                          </Form.Control.Feedback>
                        </Form.Group> */}
                        <div className="d-flex flex-column mt-3">
                            <label htmlFor="manager_number" className="form-label">
                                Phone Number 
                            </label>
                            <PhoneInput
                                // required
                                international
                                countryCallingCodeEditable={false}
                                flags
                                placeholder="Enter phone number"
                                style={{backgroundColor : "#ffffff", border: "1px solid #dddddd", borderRadius : "10px", height:"50px", marginBottom : "0px", paddingLeft : "15px" }}
                                value={number} 
                                defaultCountry="IN"
                                onChange={(e) => {
                                  setNumber(e)
                                }}
                            />
                            {
                                numberError && <p style={{color:"Red", fontSize:"14.5px"}} className="m-0" > {numberError} </p> 
                            }
                        </div>
                        <Form.Group as={Col} md="12" className="mt-3">
                          <Form.Label>
                            Password<span className="required">*</span>
                          </Form.Label>
                          <div className="form-floating mb-3">
                            <Form.Control
                              type={hidePassword ? "text" : "password"}
                              name="password"
                              value={values.password}
                              onChange={handleChange}
                              isInvalid={!!touched.password && !!errors.password}
                            />
                            <Form.Control.Feedback
                              type="invalid"
                              className="text-capitalize"
                            >
                              {errors.password}
                            </Form.Control.Feedback>
                            <div className="input-group-addon">
                              <i
                                onClick={() =>
                                  setHidePassword((hidePassword) => !hidePassword)
                                }
                                className={
                                  hidePassword
                                    ? "fa fa-eye pointer"
                                    : "fa fa-eye-slash pointer"
                                }
                                aria-hidden="true"
                              ></i>
                            </div>
                          </div>

                        </Form.Group>
                        <Form.Group className="mb-3 mt-3">
                          <Form.Check
                            required
                            name="terms"
                            label="I agree to the Terms and Conditions"
                            onChange={handleChange}
                            isInvalid={!!touched.terms && !!errors.terms}
                            feedback={errors.terms}
                            feedbackType="invalid"
                            id="validationFormik0"
                          />
                        </Form.Group>
                        <Col>
                          By clicking on Register you are agreeing to our &nbsp;
                          <Link style={{ color: "#ea4235" }} to={'/terms-and-conditions'} target="_blank">terms of services</Link>
                          &nbsp; and  &nbsp;<Link style={{ color: "#ea4235" }} to={'/privacypolicy'} target="_blank">privacy policy</Link>
                        </Col>
                        <div className="d-grid gap-2">
                          <Button type="submit">REGISTER</Button>
                        </div>
                      </Form>

                    )}
                  </Formik>

                  <Stack direction="horizontal" gap={3} className="mt-3">
                    <GoogleLogin
                      clientId={googleClientId}
                      buttonText="Register with Google"
                      onSuccess={onSuccess}
                      onFailure={onFailure}
                      cookiePolicy={"single_host_origin"}
                      isSignedIn={false}
                      className="btn btn-border fw-bolder text-dark"
                    />
                    {/* <div id="otpless" className="btn btn-border"></div> */}
                    {/* text-uppercase fw-bolder */}
                    <button className="btn btn-border disabled" >
                      <img src={whatsup} /> Register with WhatsApp
                    </button>
                  </Stack>

                  <div className="alreadyac">
                    <p>
                      Already have an account?{" "}
                      <a className="pointer" onClick={() => navigate("/login")}>
                        Log In
                      </a>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="d-none d-md-flex col-md-4 col-lg-6 bg-image"></div>
      </div>
      {showVerifyOtpModal && (
        <VerifyEmail
          show={showVerifyOtpModal}
          handleClose={() => setShowVerifyOtpModal(false)}
          setOTP={setUserEnteredOtp}
          onVerify={onVerify}
          otp={userEnteredOtp}
          resendEmail={handleResend}
        />
      )}
      {showErrorModal && <InformationModal
        show={showErrorModal}
        handleClose={() => setShowErrorModal(false)}
        title='Register'
        message={errorMsg}
      />}
    </div>
  );
};
