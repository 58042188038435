import { Link,useNavigate } from "react-router-dom";
import { useState } from "react";
import { useAuthStore } from "../store/auth";
import AuthenitcateModal from "../utils/NewAuthentication/AuthenitcateModal";
import { sendNewsLetterMail } from "../apis/api";
import { ConfirmationModel } from "./Modal/ConfirmationModel";

// Images
import spotLetName from "../assets/NewImages/Footer/spotlet-name.svg";

export const Footer = () => {
  const navigate = useNavigate();
  const { userProfile } = useAuthStore();
  //Handle nav start
  const [authenticate, setAuthenticate] = useState(false)
  const [navTo, setNavTo] = useState('')
  const [newsLetterMail, setNewsLetterMail] = useState("");
  const [showConfirmationModal, seShowConfirmationModal] = useState(false);
  const [modalData, setModalData] = useState("")

  const handleNav = (value) => {
    if(userProfile){
        navigate('/plans')
    }else{
        setNavTo(value)
        setAuthenticate(true)
    }
}
//Handle nav end
const sendNewsLetter = async(e) => {
  e.preventDefault()
  try{
    const res = await sendNewsLetterMail(newsLetterMail);
    seShowConfirmationModal(true)
    setModalData(res?.data?.message)
    setNewsLetterMail("")
  } catch(error) {
    console.log(error)
    seShowConfirmationModal(true)
    setModalData(error?.response?.data?.message)
  }
}

  return (
    <footer>
      <div className="footer">
        <div className="container">
          <div className="row">
            <div className="col-lg-3">
              <div className="cmpny-intro">
                <img className="logo-name" src={spotLetName} alt='spotLet'/>
                <p className="SpotLetContent">
                  SpotLet is a platform where hosts, guests, clients and
                  customers come together to find their dream spaces! It’s a
                  collaboration of ideas, places and people to create beautiful
                  moments and memories.
                </p>
              </div>
            </div>
            <div className="col-lg-9">
              <div className="row">
                <div className="col-lg-3 col-sm-6">
                  <div className="quick-link">
                    <h3 className="SpotLetSubHead" >Company</h3>
                    <ul>
                      <li>
                        <Link className="bi bi-chevron-right SpotLetContent" to={'/about-us'} target="_blank"> About Us</Link>
                      </li>
                      <li>
                        <Link className="bi bi-chevron-right SpotLetContent" to={'/careers'} target="_blank"> Careers</Link>
                      </li>
                      <li>
                        {/* <Link className="bi bi-chevron-right SpotLetContent" to={'/blog'} target="_blank"> Blogs</Link> */}
                        <a href={process.env.REACT_APP_BLOGS_LINK} target="_blank"><i className="bi bi-chevron-right SpotLetContent"></i> Blogs</a>
                      </li>
                      {/* <li>
                        <a href="#">
                          <i className="bi bi-chevron-right SpotLetContent"></i> SpotLet Go
                        </a>
                      </li> */}
                    </ul>
                  </div>
                </div>
                <div className="col-lg-3 col-sm-6">
                  <div className="quick-link">
                    <h3 className="SpotLetSubHead" >Host</h3>
                    <ul>
                      <li>
                        <a href="#">
                          <span className="bi bi-chevron-right SpotLetContent cursor" onClick={()=>handleNav('/plans')}> List Your Space</span>
                        </a>
                      </li>
                      <li>
                        <a href="#">
                          <Link className="bi bi-chevron-right SpotLetContent" to={'/host'} target="_blank"> Community</Link>
                        </a>
                      </li>
                      {/* <li>
                        <a href="#">
                          <i className="bi bi-chevron-right SpotLetContent"></i> Resource
                          Center
                        </a>
                      </li> */}
                      {/* <li>
                        <a href="#">
                          <i className="bi bi-chevron-right SpotLetContent"></i> Guidelines
                        </a>
                      </li> */}
                      {/* <li>
                        <a href="#">
                          <i className="bi bi-chevron-right SpotLetContent"></i> Photography
                        </a>
                      </li> */}
                    </ul>
                  </div>
                </div>
                {/* <div className="col-lg-3 col-sm-6">
                  <div className="quick-link">
                    <h3> Explore</h3>
                    <ul>
                      <li>
                        <a href="#">
                          <i className="bi bi-chevron-right SpotLetContent"></i> Activities
                        </a>
                      </li>
                      <li>
                        <a href="#">
                          <i className="bi bi-chevron-right SpotLetContent"></i> Location
                        </a>
                      </li>
                      <li>
                        <a href="#">
                          <i className="bi bi-chevron-right SpotLetContent"></i> Knowledge Base
                        </a>
                      </li>
                      <li>
                        <a href="#">
                          <i className="bi bi-chevron-right SpotLetContent"></i> Affiliate
                        </a>
                      </li>
                    </ul>
                  </div>
                </div> */}
                <div className="col-lg-3 col-sm-6">
                  <div className="quick-link">
                    <h3 className="SpotLetSubHead" > Support</h3>
                    <ul>
                      <li>
                        <a href="#">
                          <Link className="bi bi-chevron-right SpotLetContent" to={'/help-center'} target="_blank"> Contact Us</Link>
                        </a>
                      </li>
                      <li>
                        <a href="#">
                          <Link className="bi bi-chevron-right SpotLetContent" to={'/cancellation-and-refund'} target="_blank"> Cancellation</Link>
                        </a>
                      </li>
                      <li>
                        <Link className="bi bi-chevron-right SpotLetContent" to={'/privacy-policy'} target="_blank"> Privacy Policy</Link>
                      </li>
                      <li>
                        <Link className="bi bi-chevron-right SpotLetContent" to={'/terms-and-conditions'} target="_blank"> Terms and
                          Conditions</Link>
                      </li>
                      {/* <li>
                        <a href="#">
                          <i className="bi bi-chevron-right SpotLetContent"></i> Trust and
                          Safety
                        </a>
                      </li>
                      <li>
                        <a href="#">
                          <i className="bi bi-chevron-right SpotLetContent"></i> Disclaimer
                        </a>
                      </li> */}
                    </ul>
                  </div>
                </div>
                <div className="col-lg-3 col-sm-6">
                  <div className="quick-link">
                    <h3 className="SpotLetSubHead" > NewsLetter</h3>
                    <span style={{marginBottom : "10px"}}> Subscribe to our Newsletter, we won't spam. </span>
                    <ul>
                      <li>
                        <a>
                          {/* <Link className="bi bi-chevron-right SpotLetContent" to={'/help-center'} target="_blank"> Contact Us</Link> */}
                          <form onSubmit={sendNewsLetter} className="footer-news-letter-section">
                            <input 
                                  type="email" 
                                  required
                                  className="form-input outline-none footer-newsletter-input" 
                                  pattern="^[^\s@]+@[^\s@]+\.[^\s@]{2,}$"
                                  title="It should be of proper email format" 
                                  onChange={(e) => setNewsLetterMail(e.target.value)}
                                  value={newsLetterMail}
                                  placeholder="support@spotlet.in"
                              />
                            <button className="btn  btn-primary footer-newsletter-button" type="submit"> Subscribe </button>
                          </form>
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="footer-bottom">
        <div className="container">
          <div className="row d-flex justify-content-lg-between align-items-center">
            <div className="col-lg-8">
              <a className="SpotLetContent">@2023 SpotLet Solutions Private Limited. All rights Reserved.</a>
            </div>
            <div className="col-lg-4">
              <div className="footer-icon text-lg-end">
                <a href="https://www.facebook.com/SpotLetIN" target="_blank" aria-label="facebook">
                  <i className="bi bi-facebook"></i>
                </a>
                <a href="https://twitter.com/SpotLetIN" target="_blank" aria-label="twitter">
                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-twitter-x" viewBox="0 0 16 16" style={{color:'#ffffff', margin:'-7px -5px 0px 5px'}}>
                    <path d="M12.6.75h2.454l-5.36 6.142L16 15.25h-4.937l-3.867-5.07-4.425 5.07H.316l5.733-6.57L0 .75h5.063l3.495 4.633L12.601.75Zm-.86 13.028h1.36L4.323 2.145H2.865l8.875 11.633Z"/>
                  </svg>
                </a>
                <a href="https://www.linkedin.com/company/spotletin/" target="_blank" aria-label="linkedin">
                  <i className="bi bi-linkedin"></i>
                </a>
                <a href="https://www.instagram.com/spotletin/" target="_blank" aria-label="instagram">
                  <i className="bi bi-instagram"></i>
                </a>
                <a href="https://in.pinterest.com/SpotLetIN/" target="_blank" aria-label="pintrest">
                  <i className="bi bi-pinterest"></i>
                </a>
                <a href="https://www.youtube.com/@spotlet" target="_blank" aria-label="youtube">
                  <i className="bi bi-youtube"></i>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      {authenticate && 
          <AuthenitcateModal
              show={authenticate}
              handleSuccess={()=> {
                  setAuthenticate(false)
                  navigate(navTo)
              }}
              handleClose={()=>setAuthenticate(false)}
              activeTab=''
          />
      }
      {
        showConfirmationModal && (
          <ConfirmationModel
            show={showConfirmationModal}
            handleClose={() => seShowConfirmationModal(false)}
            handleOk={() => seShowConfirmationModal(false)}
            title="Newsletter"
            body={modalData}
        />
        )
      }
    </footer>
  );
};
