import React, { Component } from "react";
import { Map, Marker, GoogleApiWrapper } from "google-maps-react";
import {
  getLatLng,
  geocodeByPlaceId,
} from "react-places-autocomplete";
import { getGky } from "../../../utils/baseUrl";

/*global google*/
const reqKey = getGky();
export class MapContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      // for google map places autocomplete
      showingInfoWindow: false,
      activeMarker: {},
      selectedPlace: {},
      mapCenter: {
        lat: '',
        lng: '',
      },
    };  
  }

  componentDidMount() {
    // Disable double click zoom
    this.mapRef = this.refs.map;
    this.mapRef.map.addListener('dblclick', () => {
      this.mapRef.map.set('disableDoubleClickZoom', true);
    });
  }

  componentDidUpdate(prevProps) {

    // Typical usage (don't forget to compare props):
    if (this.props?.data?.googlePlaceId !== prevProps?.data?.googlePlaceId) {
      geocodeByPlaceId(this.props?.data?.googlePlaceId)
        .then((results) => getLatLng(results[0]))
        .then(async (latLng) => {
          this.setState({ mapCenter: latLng });
        })
        .catch((error) => console.error(error));
    }
  }

  render() {
    return (
      <Map
        zoom={10}
        ref = "map"
        // zoomControl={true}
        // mapTypeControl={false}
        // scaleControl={true}
        // streetViewControl={true}
        // rotateControl={true}
        // options={{ gestureHandling: 'none'}}
        scrollwheel={false}
        fullscreenControl={false}
        disableDefaultUI={true}
        zoomControl={false}
        google={this.props.google}
        style={
          window.innerWidth <= 992
            ? {
              width: "100%",
              height: "280px",
              marginTop: "0px",
              borderRadius: "0px 0px 10px 10px",
              paddingTop: "0px",
              marginBottom : '280px'
            }
            : {
              width: "100%",
              height: "414px",
              marginTop: "0px",
              borderRadius: "0px 10px 10px 0px",
            }
        }
        containerStyle={window.innerWidth <= 992 ? { width: "80%", height: "0px", position: "absolute"} :  { width: "50%", height: "0px", position: "absolute" }}
        initialCenter={{
          lat: this.state.mapCenter?.lat,
          lng: this.state.mapCenter?.lng,
        }}
        center={{
          lat: this.state.mapCenter?.lat,
          lng: this.state.mapCenter?.lng,
        }}
      >
        <Marker
          icon={{
            path: google.maps.SymbolPath.CIRCLE,
            fillColor: "#EA4335",
            fillOpacity: 0.3,
            scale: 60,
            strokeColor: "white",
            strokeWeight: 0.5,
          }}
          position={{
            lat: this.state.mapCenter?.lat,
            lng: this.state.mapCenter?.lng,
          }}
        />
      </Map>
    );
  }
}

export default GoogleApiWrapper({
  apiKey: reqKey,
})(MapContainer);