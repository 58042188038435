import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import icon01 from "../../assets/NewImages/Home/icon-desired.webp";
import icon02 from "../../assets/NewImages/Home/icon-find.webp";
import icon03 from "../../assets/NewImages/Home/icon-book.webp";

const SimpleSteps = () => {
    return(
        <Box className="simple-3-step">
            <Typography className="text-stroke SpotLetMainHead" variant="h2">
              3 STEP PROCESS
            </Typography>
            <Typography className="mar-t60 SpotLetMainHead" variant="h2">
              A SIMPLE 3-STEP BOOKING PROCESS
            </Typography>
            <Box className="step-container">
              <Box className="step-box">
                <figure>
                  <img loading="lazy" src={icon01} alt='Search your Desired Property'/>
                </figure>
                <Typography variant="h5" className="SpotLetSubHead"  gutterBottom>
                    Search your Desired Property
                </Typography>
                <Typography variant="body1" className="SpotLetContent" >
                    Explore listings, filter by preferences, and find your perfect property match on SpotLet's platform.
                </Typography>
              </Box>
              <Box className="step-box">
                <figure>
                  <img loading="lazy" src={icon02} alt='Contact Property Owners'/>
                </figure>
                <Typography variant="h5" className="SpotLetSubHead" gutterBottom>
                    Contact Property Owners
                </Typography>
                <Typography variant="body1" className="SpotLetContent" >
                    Contact the host to confirm availability and understand property, amenities, and policies that match your requirements.
                </Typography>
              </Box>
              <Box className="step-box">
                <figure>
                  <img loading="lazy" src={icon03} alt='Finalize Booking'/>
                </figure>
                <Typography variant="h5" className="SpotLetSubHead" gutterBottom>
                      Finalize Booking
                </Typography>
                <Typography variant="body1" className="SpotLetContent" >
                    Confirm details with host to match requirements and finalize booking for a seamless experience.
                </Typography>
              </Box>
            </Box>
          </Box>
    )
}
export default SimpleSteps;