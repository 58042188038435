import { useRouteError, useNavigate } from "react-router-dom";
import { Button } from "react-bootstrap";

export default function NotFound() {
  const error = useRouteError();
  const navigate = useNavigate();

  return (
    <div
      style={{
        minHeight: "100vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <div style={{ textAlign: "center" }}>
        <h1>Oops!</h1>
        <p>Sorry, an unexpected error has occurred.</p>
        <p>
          <i>{error.statusText || error.message}</i>
        </p>
        <Button variant="dark" onClick={() => navigate(-1)}>
          Go Back
        </Button>
      </div>
    </div>
  );
}
