import React from "react";
import { Map, Marker, GoogleApiWrapper } from "google-maps-react";
import {
    getLatLng,
    geocodeByPlaceId,
  } from "react-places-autocomplete";
  import { getGky } from "../../utils/baseUrl";


/*global google*/
const reqKey = getGky();
class MapContainer extends React.Component {

    constructor(props) {
        const region = localStorage.getItem('region')
        super(props);
        this.state = {
          googlePlaceIds: [],
          defaultMap : region === 'United States' ? {lat : '44.182205',lng : '-84.506836',} :   {lat : '20.5937',lng : '78.9629',}
        };
      }

    componentDidMount() {
        this.mapRef = this.refs.map;
        this.mapRef.map.addListener('dblclick', () => {
          this.mapRef.map.set('disableDoubleClickZoom', true);
        });
        const callback = async() => {
            let latLangs = []
            await Promise.all(this.props.properties.map(async(data) => {
                if(data.address.googlePlaceId){
                    await geocodeByPlaceId(data.address.googlePlaceId)
                    .then((results) => getLatLng(results[0]))
                    .then((latLng) => {
                        latLangs.push(latLng)
                    })
                    .catch((error) => console.error(error));
                }
            }))
            this.setState({ googlePlaceIds: latLangs });
        }
        callback()
    }
    render() {

        const mapStyles = {
        width: "100%",
        height: "400px",
        };
        const containerStyle = {
            position : 'relative',
        }

        return (
        <>
            <Map
             ref = "map"
             scrollwheel={false}
             fullscreenControl={false}
             disableDefaultUI={true}
             zoomControl={false}
            google={this.props.google}
            zoom={4}
            style={mapStyles}
            containerStyle={containerStyle}
            initialCenter={{
                lat: this.state.defaultMap.lat,
                lng: this.state.defaultMap.lng,
              }}
            >
                {this.state.googlePlaceIds.map((id) => {
                    // return <Marker position={{ lat: id?.lat, lng: id?.lng }} />
                    return (
                        <Marker
                            icon={{
                            path: google.maps.SymbolPath.CIRCLE,
                            fillColor: "green",
                            fillOpacity: 0.2,
                            scale: 20,
                            strokeColor: "white",
                            strokeWeight: 0.5,
                            }}
                            position={{
                            lat: id?.lat,
                            lng: id?.lng,
                            }}
                        />
                    )
                })}
            </Map>
        </>
        );
     }
    }

export default GoogleApiWrapper({
  apiKey: reqKey, // Replace with your actual API key
})(MapContainer);
