import { Button, Modal } from "react-bootstrap";
const { Header, Footer, Title } = Modal;


export const InformationModal = ({ show, handleClose,title, message }) => {
    return (
        <Modal show={show} onHide={handleClose}>
            <Header closeButton>
                <Title> {title} </Title>
            </Header>
            <Footer className="d-flex flex-row justify-content-between align-center bold" >
                {message}
                <Button variant="secondary" onClick={handleClose}>
                    Okay
                </Button>
            </Footer>
        </Modal>
    )
}

