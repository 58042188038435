import {Modal} from "react-bootstrap";
const { Body,Header } = Modal;
import Authentication from "./Authentication";

const AuthenitcateModal = ({show,handleSuccess,handleClose,activeTab, activePage}) => {
    return(
        <Modal
            show={show}
            onHide={handleClose}
            backdrop="static"
            keyboard={false}
            size="md"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Header style={{borderBottom:"none"}} closeButton></Header>
            <Body className="verify-email-modal-body d-flex flex-column justify-content-center align-items-center">
                <Authentication activeTab={activeTab} onSuccess={handleSuccess} onFailure={handleClose} activePage={activePage} />
            </Body>
        </Modal>
    )
}
export default AuthenitcateModal;