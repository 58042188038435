import { Modal } from "react-bootstrap";
import { useState } from "react";

const { Header, Title, Body } = Modal;

export const FindMySpotModal = ({
    handleClose,
    handleOk,
    show,
}) => {

    const [formData, setFormData] = useState({
        fullName: "",
        email: "",
        number: "",
        eventType: "",
        locationType: "",
        eventDate: "",
        duration: "",
        requirements: "",
        budget: "",
        requests: ""
    })

    const onChange = (e) => {
        const { name, value } = e.target
        setFormData({ ...formData, [name]: value })
    }

    const submitForm = (e) => {
        e.preventDefault()
        handleOk(formData)
    }

    return (
        <Modal
            show={show}
            onHide={handleClose}
            backdrop="static"
            keyboard={false}
            size="md"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Header closeButton>
                <Title className="SpotLetMainHead">Find My Spot</Title>
            </Header>
            <Body className="verify-email-modal-body">
                <form onSubmit={submitForm} style={{ overflowY: "auto", height: "60vh" }} >
                    <div
                        style={{
                            // border: "1px solid",
                            // borderRadius: "3px",
                            margin: "5px 0px 5px 0px",
                        }}
                    >
                        <h5 className="SpotLetSubHead"> Contact Info : </h5>
                        <div className="d-flex flex-column m-2">
                            <label className="form-label SpotLetContent " htmlFor="name">
                                Full Name<span className="required">*</span>
                            </label>
                            <input
                                required
                                className="form-control"
                                name="fullName"
                                type="text"
                                id="name"
                                pattern="^[a-zA-Z\s]+$"
                                title="Field should only consist of alphabets"
                                value={formData.fullName}
                                onChange={onChange}
                            />
                        </div>
                        <div className="d-flex flex-column m-2">
                            <label className="form-label SpotLetContent " htmlFor="email">
                                Email Address<span className="required">*</span>
                            </label>
                            <input
                                required
                                className="form-control"
                                name="email"
                                type="email"
                                id="email"
                                pattern="^[^\s@]+@[^\s@]+\.[^\s@]{2,}$"
                                title="It should be of proper email format"
                                value={formData.email}
                                onChange={onChange}
                            />
                        </div>
                        <div className="d-flex flex-column m-2">
                            <label className="form-label SpotLetContent " htmlFor="number">
                                Mobile Number<span className="required">*</span>
                            </label>
                            <input
                                required
                                className="form-control"
                                name="number"
                                type="tel"
                                id="number"
                                pattern="[6789][0-9]{9}"
                                title="Phone number should start with 6-9 and remaing 9 digit with 0-9"
                                value={formData.number}
                                onChange={onChange}
                            />
                        </div>
                        <h5 className="mt-4 SpotLetSubHead "> Event Details : </h5>
                        <div className="d-flex flex-column m-2">
                            <label className="form-label SpotLetContent " htmlFor="eventType">
                                {" "}
                                Type of Event<span className="required">*</span>
                            </label>
                            <select
                                style={{ appearance: 'auto' }}
                                value={formData.eventType}
                                id="eventType"
                                className="form-control"
                                name="eventType"
                                onChange={onChange}
                                required
                            >
                                <option hidden selected value="">Select Event</option>
                                <option value="film/tv">Film/Tv Shooting</option>
                                <option value="corporate">Corporate Event</option>
                                <option value="personal">Personal Event</option>
                                <option value="stay">Home Stay</option>
                            </select>
                        </div>
                        <div className="d-flex flex-column m-2">
                            <label className="form-label SpotLetContent " htmlFor="locationType">
                                {" "}
                                Preferred Location Type
                            </label>
                            <select

                                style={{ appearance: 'auto' }}
                                value={formData.locationType}
                                id="locationType"
                                className="form-control"
                                name="locationType"
                                onChange={onChange}
                            >
                                <option hidden selected value="">Select Location Type</option>
                                <option value="indoor">Indoor</option>
                                <option value="outdoor">Outdoor</option>
                                <option value="studio">Studio</option>

                            </select>
                        </div>
                        {/* date picker  */}
                        <div className="d-flex flex-column m-2">
                            <label className="form-label SpotLetContent " htmlFor="eventDate">
                                Date
                            </label>
                            <input

                                className="form-control"
                                name="eventDate"
                                type="date"
                                id="eventDate"
                                min={new Date().toISOString().split("T")[0]}
                                value={formData.eventDate}
                                onChange={onChange}
                            />
                        </div>
                        <div className="d-flex flex-column m-2">
                            <label className="form-label SpotLetContent  mt-2" htmlFor="duration">
                                Estimated Duration :
                            </label>
                            <input
                                className="form-control"
                                name="duration"
                                type="text"
                                id="duration"
                                value={formData.duration}
                                onChange={onChange}
                            />
                        </div>
                        <h5 className="mt-4 SpotLetSubHead "> Additional Info : </h5>
                        <div className="d-flex flex-column m-2">
                            <label className="form-label SpotLetContent " htmlFor="requirements">
                                Specific Location Features/Requirements
                            </label>
                            <textarea
                                style={{
                                    borderRadius: '5px'
                                }}
                                name="requirements"
                                rows={4}
                                columns={55}
                                id="requirements"
                                value={formData.requirements}
                                onChange={onChange}
                            />
                        </div>
                        {/* budget */}
                        <div className="d-flex flex-column m-2">
                            <label className="form-label SpotLetContent  mt-2" htmlFor="budget">
                                Budget Range :
                            </label>
                            <input
                                className="form-control"
                                name="budget"
                                type="number"
                                id="budget"
                                min="1"
                                value={formData.budget}
                                onChange={onChange}
                            />
                        </div>
                        <div className="d-flex flex-column m-2">
                            <label className="form-label SpotLetContent " htmlFor="requests">
                                Any other Comments/Requests
                            </label>
                            <textarea
                                //
                                style={{
                                    borderRadius: '5px'
                                }}
                                name="requests"
                                rows={4}
                                columns={55}
                                id="requests"
                                value={formData.requests}
                                onChange={onChange}
                            />
                        </div>
                        <div>
                            <button
                                type="submit"
                                className="SpotLetContent"
                                style={{
                                    background: "#EA4335",
                                    border: "none",
                                    borderRadius: "5px",
                                    color: "#ffffff",
                                    width: "80px",
                                    height: "40px",
                                    margin: "5px",
                                }}
                            >
                                Submit
                            </button>
                        </div>
                    </div>
                </form>
            </Body>
        </Modal>
    );

}