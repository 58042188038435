import { useState } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import {saveFindMySpotData, saveEventPlanningRequest } from "../../apis/api";
import { FindMySpotModal } from "../../components/Modal/FindMySpotModal"
import { ConfirmationModel } from "../../components/Modal/ConfirmationModel";
import { EventPlanningSupportModal } from "../../components/Modal/EventPlanningSupportModal";
import professionalservicesimg01 from "../../assets/NewImages/Home/professional-services-img01.webp";
import professionalservicesimg02 from "../../assets/NewImages/Home/professional-services-img02.webp";

const ProfessionalServices = () => {
  const [showFindMySpotModal, setShowFindMySpotModal] = useState(false);
  const [showConfirmationModel, setShowConfirmationModel] = useState(false);
  const [confirmationTitle, setConfirmationTitle] = useState("");
  const [confirmationBody, setConfirmationBody] = useState("");
  const [eventPlanningModal, setEventPlanningModal] = useState(false);
    return(
        <Box className="professional-services">
            <Typography className="text-stroke SpotLetMainHead" variant="h2">
              Professionals
            </Typography>
            <Typography className="mar-t60 SpotLetMainHead" variant="h2">
              SpotLet Professional Services
            </Typography>
            <Typography variant="body1" className="SpotLetContent">
              Let us take the lead in discovering the hidden locations,
              skillfully negotiating rates, and ensuring your desired dates are
              locked in.
            </Typography>
            <Box className="services-container">
              <Box className="img-txt-panel">
                <img loading="lazy"
                  src={professionalservicesimg01}
                  className="image-overlay"
                  alt='SpotLet Professional Services'
                />
                <Typography variant="h5" className="SpotLetMainHead">
                  SpotLet Professional Services
                </Typography>
                <Typography variant="body1" className="SpotLetSubHead" >
                  Let our expert team assist you in discovering your perfect
                  location
                </Typography>
                <Button className="readmore SpotLetContent" variant="contained" size="large" onClick={() => setShowFindMySpotModal(true)} >
                  Find My Spot
                </Button>
              </Box>
              <Box className="img-txt-panel">
                <img loading="lazy"
                  src={professionalservicesimg02}
                  className="image-overlay"
                  alt='Need Assistance with Your Event Planning?'
                />
                <Typography variant="h5" className="SpotLetMainHead">
                  Need Assistance with Your Event Planning?
                </Typography>
                <Typography variant="body1" className="SpotLetSubHead">
                  Our experienced team is here to provide you with comprehensive
                  event planning support.
                </Typography>
                <Button className="readmore SpotLetContent" variant="contained" size="large"
                  onClick={() => setEventPlanningModal(true)}>
                  Get Started
                </Button>
              </Box>
            </Box>
                {showFindMySpotModal &&
                <FindMySpotModal
                    show={showFindMySpotModal}
                    handleClose={() => setShowFindMySpotModal(false)}
                    handleOk={async (data) => {
                    try {
                        const res = await saveFindMySpotData(data)
                        setConfirmationTitle("Info");
                        setConfirmationBody(res?.data?.message);
                        setShowConfirmationModel(true);
                        setShowFindMySpotModal(false);
                    } catch (error) {
                        console.log(error);
                        setConfirmationTitle("Error");
                        setConfirmationBody(error?.response?.data?.message);
                        setShowConfirmationModel(true);
                    }
                    }}
                />
                }
        
                {eventPlanningModal &&
                <EventPlanningSupportModal
                    show={eventPlanningModal}
                    handleClose={() => setEventPlanningModal(false)}
                    handleOk={async (data) => {
                    try {
                        const res = await saveEventPlanningRequest(data)
                        setConfirmationTitle("Info");
                        setConfirmationBody(res?.data?.message);
                        setShowConfirmationModel(true);
                        setEventPlanningModal(false);
                    } catch (error) {
                        console.log(error);
                        setConfirmationTitle("Error");
                        setConfirmationBody(error?.response?.data?.message);
                        setShowConfirmationModel(true);
                    }
                    }}
                />
                }
        
                {showConfirmationModel &&
                <ConfirmationModel
                    show={showConfirmationModel}
                    handleClose={() => setShowConfirmationModel(false)}
                    handleOk={() => setShowConfirmationModel(false)}
                    title={confirmationTitle}
                    body={confirmationBody}
                />
                }
          </Box>
    )
}
export default ProfessionalServices;