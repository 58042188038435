import React, { useState, useRef } from 'react';
import { Modal, Button } from 'react-bootstrap';
import { PageLoader } from '../PageLoader';
import bcrypt from "bcryptjs-react"
import { updateUserDetails } from '../../apis/api';
import { getSignInOtp } from '../../apis/api';
import OTPInput from "otp-input-react";
import { FaRegEdit } from "react-icons/fa";



export const  EmailVerificationModal = ({show, emailAddress, onHide, onSuccess, userDetails, userId,header,text}) => {
    const [timer, setTimer] = useState(60);
    const [email, setEmail] = useState(emailAddress)
    const [emailError, setEmailError] = useState("");
    const [sentOtp, setSentOtp] = useState()
    const [otp, setOtp] = useState()
    const [loader, setLoader] = useState(false);
    const [showEmailInput, setShowEmailInput] = useState(true)
    const [otpErrorMsg, setOtpErrorMsg] = useState("")
    const intervalEmailRef = useRef(null);


    const onEmailVerificationSuccess = async() => {
       setLoader(true)
        try {
          await updateUserDetails(userId, {...userDetails, email : email, emailVerified : true, fullName : userDetails?.fullName?.trim()});
          onSuccess("true")
        } catch(error) {
          console.log(error)
          onSuccess("false")
        }
        setLoader(false)
      }


    const emailInputSection = () => {
        return(
            <form onSubmit={ handleSendOtpFunc }>
                  <span className='SpotLetContent mb-3'>{text}</span>
                  <div className="mt-3 w-100">
                    <label htmlFor="manager_number" className="form-label">
                        Email {' '}<span className="required">*</span>
                    </label>
                    <input
                        // disabled
                        required
                        type="email"
                        className="form-control mb-2"
                        id="email"
                        name="email"
                        value={email}
                        placeholder='Enter Email Address'
                        title="Please enter a valid email addressPlease enter an email address in the format username@domain.com"
                        pattern="^[^\s@]+@[^\s@]+\.[^\s@]{2,}$"
                        onChange={(e) => {
                            setEmailError("");
                            setEmail(e.target.value);
                        }} 
                    />
                    {
                        emailError && <p style={{color:"Red", fontSize:"14.5px"}} className="m-0 mb-2" > {emailError} </p> 
                    }
                  </div>
                    <Button type="submit" style={{width : '100%'}} > Submit </Button>
                </form>
        )
    }

    const onClickResend = (e) => {
        setOtp()
        setOtpErrorMsg()
        handleSendOtpFunc(e);
        setTimer(60)
        startTimer()
    }

    // const startTimer = () => {
    //     const counter =
    //         timer > 0 &&
    //         setInterval(
    //         () =>
    //             setTimer((prevState) => {
    //             return prevState - 1;
    //             }),
    //         1000
    //         );
    //     return () => clearInterval(counter);
    // }  

    const startTimer = () => {
        if (intervalEmailRef.current) {
            clearInterval(intervalEmailRef.current);
        }
        intervalEmailRef.current = setInterval(() => {
            setTimer((prevState) => {
                if (prevState <= 1) {
                    clearInterval(intervalEmailRef.current);
                    // setResendStatusMobile(false);
                    intervalEmailRef.current = null;
                    return 0;
                }
                return prevState - 1;
            });
        }, 1000);
    }
      
    const handleSendOtpFunc = async (e) =>{
        e.preventDefault()
        setLoader(true)
        try{
            const response = await getSignInOtp({email : email, otpFor : "verify"});
            setSentOtp(response?.data?.otp);
            setTimer(60)
            startTimer()
            setShowEmailInput(false)
        }catch(error){
          console.log(error)
          setEmailError(error?.response?.data?.error)
        }
        setLoader(false)
    }

    const onVerify = async () => {
        const isOk = await bcrypt.compare(otp, sentOtp);
        if (!isOk) {
            setOtpErrorMsg("Invalid OTP")
        }
        else {
            setOtpErrorMsg("")
            onEmailVerificationSuccess()
        }
    };

    const emailOtpSection = () => {
        return (
            <div className="verify-email-modal-body">
                <div className="d-flex flex-row justify-content-center align-items-center mb-2">
                    <p className="otp-header text-gray-500 text-center fw-semibold fs-6 mb-2 SpotLetContent">Enter the 4 digit code sent to</p>
                    <div className=" d-flex flex-row justify-content-center align-items-center mb-2 mx-1">
                        <span 
                        className="text-gray-500 text-center fw-semibold fs-6 me-2 SpotLetContent"
                        style={{fontWeight : 'bold'}}
                        >{email}
                        </span>
                        <FaRegEdit 
                        className="auth_text_color cursor"
                        style={{fontSize : '18px'}}
                        onClick={()=>{
                            setShowEmailInput(true)
                            setOtpErrorMsg("")
                            setOtp()
                            // setForgotEmailSuccess(false)
                        }}/>
                    </div>
                </div>
                <OTPInput
                    value={otp}
                    onChange= {(e) => {
                        setOtp(e);
                        setOtpErrorMsg("")
                    } }//{ (e) =>  onChangeOtp(e)}
                    autoFocus
                    OTPLength={4}
                    otpType="number"
                    disabled={false}
                    secure
                    className={`otp-input ${otpErrorMsg !== "" ? "mb-2" : "mb-3"}`}
                />
                {/* {timer > 0 && <p className="timer">{timer} sec.</p>} */}
                {otpErrorMsg && (
                    <div className='d-flex flex-row justify-content-center mb-2'>
                        <span className='required'> {otpErrorMsg} </span>
                    </div>
                )}
                <div className="resend-email mt-1 mb-3">
                    <span className="text-gray-500 text-center fw-semibold fs-6 SpotLetContent" > Didn't get the OTP? </span>
                    <span className={`${timer <= 0 ? "auth_text_color cursor" : "text-muted"} fw-semibold SpotLetContent mx-1`}
                        variant="link"
                        // className="resend-button"
                        disabled={timer > 0}
                        onClick={onClickResend}
                        >
                        Resend 
                    </span>
                    {timer > 0 && <span className="text-gray-500 text-center fw-semibold fs-6 SpotLetContent"> OTP in <span className="text-gray-500 text-center fw-semibold fs-6 SpotLetContent"  style={{fontWeight : 'bold'}}> {timer} sec</span> . </span>}
                </div>
                
                <div className='d-flex flex-row justify-content-center' >
                    <button className='btn btn-primary' type='button' onClick={onVerify} > Verify OTP </button>
                </div>
            </div>
        )
    }
 
  return (
  <Modal backdrop="static" show={show} onHide={onHide} centered>
    <Modal.Header closeButton>
    <Modal.Title>{header}</Modal.Title>
    </Modal.Header>
    <Modal.Body>
        { showEmailInput ? emailInputSection() : emailOtpSection()}
    </Modal.Body>
    {/* <Modal.Footer className='d-flex flex-row justify-content-start'>
        <Button variant="secondary" onClick={onHide}>
        Cancel
        </Button>
        <Button variant="primary" onClick={onSuccess}>
        Verify OTP
        </Button>
    </Modal.Footer> */}
      {loader && <PageLoader/>}
    </Modal>
  );
}