import { Table, Thead, Tbody, Tr, Th, Td } from 'react-super-responsive-table';
import 'react-super-responsive-table/dist/SuperResponsiveTableStyle.css';
import { button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

const ResponsiveTable = ({rows,columns,handleDashboard,editTempLocation,continueListing,payNow,approve,reject,cancelByHost,cancelByUser}) => {
    const navigate = useNavigate();

    const callDashBoard= (data) => {
        return(
            <button type='button' className="btn m-2 btn-outline-danger" onClick={()=>handleDashboard(data)}>
                Dashboard
              </button>
        )
    }
    const callEditListing= (data) => {
        return(
            <button type='button' className="btn m-2 btn-outline-danger" onClick={() => editTempLocation(data)}>
                  Continue Listing
                </button>
        )
    }
    const callContinueListing= () => {
        return(
            <button type='button' className="btn m-2 btn-outline-danger" onClick={continueListing}>
                  Continue Listing
                </button>
        )
    }
    const callBookingDetails= (data) => {
        return(
            <button type='button' onClick={() => navigate(`/booking-details/${data}`)}
                className="btn m-2 btn-outline-danger">
                Details
              </button>
        )
    }
    const callPayNow = (data,status) => {
        if(status === 'Approved'){
            return(
                <button type='button' onClick={() => payNow(data)}
                    className="btn m-2 btn-outline-danger">
                    Pay Now
                  </button>
            )
        }
    }
    const callApprove= (data,status) => {
        if(status === 'Booking Requested'){
            return(
                <button type='button' onClick={() => approve(data)}
                    className="btn m-2 btn-outline-danger">
                    Approve
                  </button>
            )
        }
    }
    const callReject= (data,status) => {
        if(status === 'Booking Requested'){
            return(
                <button type='button' onClick={() => reject(data)}
                    className="btn m-2 btn-outline-danger">
                    Reject
                  </button>
            )
        }
    }
    const hostCancel = (data,status) => {
        if(status === 'Approved'){
            return(
                <button type='button' onClick={() => cancelByHost(data)}
                    className="btn m-2 btn-outline-danger">
                    Cancel
                  </button>
            )
        }
    }
    const userCancel = (data,status) => {
        if(status === 'Confirmed'){
            return(
                <button type='button' onClick={() => cancelByUser(data)}
                    className="btn m-2 btn-outline-danger">
                    Cancel
                  </button>
            )
        }
    }
    const actions = (type,data,bookingId,status) => {
        switch (type) {
            case 'dashboard':
                return callDashBoard(data)
            case 'editListing':
                return callEditListing(data)
            case 'continueListing':
                return callContinueListing()
            case 'details':
                return callBookingDetails(bookingId)
            case 'Pay Now':
                return callPayNow(bookingId,status)
            case 'Approve':
                return callApprove(bookingId,status)
            case 'Reject':
                return callReject(bookingId,status)
            case 'Host Cancel':
                return hostCancel(bookingId,status)
            case 'User Cancel':
                return userCancel(bookingId,status)
            default:
                break;
        }
    }
    return (
        <Table>
          <Thead>
            <Tr>
                {columns?.map((column,i)=>{
                    return(
                        <Th key={i}>{column}</Th>
                    )
                })}
            </Tr>
          </Thead>
          <Tbody>
            {rows?.map((row,i1)=>{
                return(
                    <Tr key={i1}>
                        {Object.keys(row)?.map((key)=>{
                            if(key !== 'action'){
                                return(
                                    <Td key={key} className = "text-center">{row?.[key]}</Td>
                                )
                            }else{
                                if(Array.isArray(row?.[key])){
                                    return(
                                        <Td className = {`d-flex flex-row justify-content-center align-items-center flex-wrap`} style = {{height : "61px", borderBottom : "none"}}>
                                            {row?.[key]?.map((item) => {
                                                return actions(item,row?.locationId,row?.bookingId,row?.status)
                                            })}
                                        </Td>
                                    )
                                }else{
                                    return(
                                        <Td className = "d-flex flex-row justify-content-center align-items-center" style = {{height : "61px", borderBottom : "none"}}>{actions(row?.[key],row?.locationId,row?.bookingId)}</Td>
                                    )
                                }
                            }
                        })}
                    </Tr>
                )
            })}
          </Tbody>
        </Table>
      )
}
export default ResponsiveTable;