import { Modal,Button,Stack,Col ,Form,Row} from "react-bootstrap";
import { useState,useRef,useEffect } from "react";
import { Link } from "react-router-dom";
import { signIn, signUp,getOtp,getSignInOtp } from "../../../apis/api";
import { useAuthStore } from "../../../store/auth";
// import { GoogleLogin } from "react-google-login";
import logoName from "../../../assets/NewImages/Login/logoname.svg";
// import whatsup from "../../../assets/NewImages/Login/whatsup.svg";
import { Formik, ErrorMessage } from "formik";
import * as yup from "yup";
import PhoneInput, {isValidPhoneNumber} from 'react-phone-number-input'
import "./index.css"
import OTPInput from "otp-input-react";
//For Bcrypt
import bcrypt from "bcryptjs-react"
import { ConfirmationModel } from "../../../components/Modal/ConfirmationModel";
import {VerifyWhatsApp} from "../../../components/Modal/VerifyWhatsApp"
import { PageLoader } from "../../../components/PageLoader";
import Authentication from "../../NewAuthentication/Authentication";

const schema = yup.object().shape({
  firstName: yup.string().trim().min(3, "First Name must contain atleast 3 characters").required("First Name is required"),
  lastName: yup.string().trim().min(1, "Last Name must contain atleast 1 character"),
  email: yup.string().email('Invalid email')
  .matches(/^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i, 'Enter a valid email address'),
  // .required('Email Address is required'),
  mobile: yup.number()
  //.required()
  .min(999999999, 'Phone number must be 10 digits')
  .max(9999999999, 'Phone number must be 10 digits'),
  password: yup
  .string()
  .required("Password is required")
  .min(8, 'Password must be 8 characters and have uppercase, lowercase, number, and special character') // Password must be 8 characters long
  .matches(/[0-9]/, 'Password must be 8 characters long and contain uppercase, lowercase, number, and special character') // Password requires a number
  .matches(/[a-z]/, 'Password must be 8 characters long and contain uppercase, lowercase, number, and special character') // Password requires a lowercase letter
  .matches(/[A-Z]/, 'Password must be 8 characters long and contain uppercase, lowercase, number, and special character') // Password requires an uppercase letter
  .matches(/[^\w]/, 'Password must be 8 characters long and contain uppercase, lowercase, number, and special character'), // Password requires a symbol
  terms: yup.bool().required().oneOf([true], "Terms must be accepted"),
});

const { Body,Header } = Modal;
export function SignupModel({handleClose,handleOk,show,handleForgotPassword,handleUserDeactivated,handleRecieveOtp,type}) {

  const { setAuthenticated, setUserProfile } = useAuthStore();
  const [isSignIn,setIsSignIn] = useState(type || 'signUp')
  const [googleClientId, setGoogleClientId] = useState(
      "837678588128-701v5ikaevb6ahi5bv0ps700q7lqit9d.apps.googleusercontent.com"
    );
  const [numberError, setNumberError] = useState("");
  const [signUpEmailError, setSignUpEmailError] = useState("");
  const [number, setNumber] = useState("")
  const [showConfirmationModel, setShowConfirmationModel] = useState(false);
  const [confirmationTitle, setConfirmationTitle] = useState("");
  const [confirmationBody, setConfirmationBody] = useState("");
  const [showWhatsAppModal, setShowWhatsAppModal] = useState(false);
  const [loader, setLoader] = useState(false);
    
  useEffect(() => {
    const initClient = () => {
      gapi.client.init({
        clientId:
          "837678588128-701v5ikaevb6ahi5bv0ps700q7lqit9d.apps.googleusercontent.com",
        scope: "",
      });
    };
    setTimeout(() => {
      // @ts-ignore
      gapi.load("client:auth2", initClient);
    }, 1000);
  });
  const onFailure = (err) => {
    console.log("failed:", err);
  };

  //singup
  const formikRef = useRef();
  const [hidePasswordSignup, setHidePasswordSignup] = useState(false)
  useEffect(() => {
    // Define the 'otpless' function
    window.otpless = (otplessUser) => {
      // Retrieve the user's details after successful login
      const waName = otplessUser.waName;
      const waNumber = otplessUser.waNumber;


      let firstName = otplessUser?.waName?.fullName?.split(" ")[0];
      let lastName = otplessUser?.waName?.fullName?.split(" ")[1];

      const req = {
        firstName: firstName,
        lastName: lastName,
        mobile: otplessUser.waNumber,
        whatsappLogin: true,
      };
      userSignUp(req);

    };
  }, []);
  const userSignUp = async (userObj) => {
    try {
      const res = await signUp(userObj);
      const jwt = res.data.token;
      localStorage.setItem("auth", jwt);
      setAuthenticated();
      handleOk()
    } catch (error) {
      if (error?.response?.data?.error) {
        setConfirmationTitle('Error')
        setConfirmationBody(error?.response?.data?.error)
        setShowConfirmationModel(true)
      } else {
        setConfirmationTitle('Error')
        setConfirmationBody("Error while user register")
        setShowConfirmationModel(true)
      }

    }
  };
  const onSuccessGoogleSignup = (res) => {
    signUpUser(
      res?.profileObj?.givenName,
      res?.profileObj?.familyName,
      res?.profileObj?.email,
      true,
      true
    );
  };
  const signUpUser = async (firstName, lastName, email, googleLogin, emailVerified) => {
    let userObj = {
      firstName: firstName,
      lastName: lastName,
      mobile: "",
      email: email,
      password: "",
      booking_type: "",
      profession: "",
      company: "",
      googleLogin: googleLogin,
      emailVerified: emailVerified
    };
    try {
      const res = await signUp(userObj);
      const jwt = res.data.token;
      localStorage.setItem("auth", jwt);
      setAuthenticated();
      setUserProfile();
      handleOk();
    } catch (error) {
      setConfirmationTitle('Error')
      setConfirmationBody(error?.response?.data?.error)
      setShowConfirmationModel(true)
    }
  };
  const checkMobileValidation = () => {
    if (number === undefined || number === ''){
      setNumberError("Phone Number is Required");
      return false
    }else {
      if (number?.slice(0,4) === "+911") {
        setNumberError("Enter Valid Phone Number")
        return false
      }else {
        if (isValidPhoneNumber(number) || number === '' ) {
          setNumberError("");
          return true
      }else{
        setNumberError("Enter Valid Phone Number")
        return false
      }
    }
    }
  }
  const onSignup = async (formValues) => {
    setLoader(true)
    try{
      if(checkMobileValidation()){
        setDetails({...formValues, mobile : number});
        const resp = await getOtp({...formValues, mobile : number})
        handleRecieveOtp({...formValues, mobile : number},resp?.data?.otp);
      }
    }catch(error){
      if(error?.response?.data?.error === 'Email already exist'){
        setSignUpEmailError(error?.response?.data?.error)
      }else{
        setSignUpEmailError('')
      }
      if(error?.response?.data?.error === 'Phone Number already exist'){
        setNumberError(error?.response?.data?.error)
      }else{
        setNumberError('')
      }
    }
    setLoader(false)
  };
  //signin
  const [hidePassword, setHidePassword] = useState(false);
  const [showError,setShowError] = useState(false);
  const [errorInfo,setErrorInfo] = useState('')
  const [emailError,setEmailError] = useState(false);
  const [signInWith,setSignInWith] = useState('mobile');
  const [passwordError,setPasswordError] = useState(false);
  const [details, setDetails] = useState({
      email : '',
      mobile: "",
      password: "",
  });
  const { email,mobile, password } = details;
  const checkSignInMobileValidation = (number) => {
    if (number === undefined || number === ''){
      setEmailError("Phone Number is Required");
      return false
    }else {
      if (number?.slice(0,4) === "+911") {
        setEmailError("Enter Valid Phone Number")
        return false
      }else {
        if (isValidPhoneNumber(number) || number === '' ) {
          setEmailError("");
          return true
      }else{
        setEmailError("Enter Valid Phone Number")
        return false
      }
    }
    }
  }
  const onSuccess = (res) => {
  const req = {
      email: res.profileObj.email,
      googleLogin: true,
  };
  setDetails({ ...details, email: res.profileObj.email })
  userLogin(req);
  };
  const handleChange = (event) => {
    const { name, value } = event.target;
    setDetails({ ...details, [name]: value });
  };
  const onSignin = async (event) => {
    event.preventDefault();
    if(signInWith === 'mobile' && !checkSignInMobileValidation(mobile)){
      return null
    }
    userLogin(details);
  };
  const userLogin = async (details) => {
    try {
      setLoader(true)
      const response = await signIn(details);
      localStorage.setItem("auth", response.data.token);
      setAuthenticated();
      setUserProfile();
      handleOk()
      setLoader(false)
    } catch (error) {
      console.log(error);
      if (error?.response?.status === 403) {
        handleUserDeactivated(details)
      } else {
        setErrorInfo(error?.response?.data?.error)
        setShowError(true)
      }
    }
    setLoader(false)
  };
  //whtatspp signup
  const [mobileNumber,setMobileNumber] = useState('')
  const [whatsappNumberError, setWhatsappNumberError] = useState("");
  const [showOtpInput,setShowOtpInput] = useState(false)
  const [otp, setOtp] = useState();
  const [userEnteredOtp, setUserEnteredOtp] = useState("");
  const [timer, setTimer] = useState(60);
  const [timerInitiated, setTimerInitiated] = useState(false);
  const startTimer = () => {
    const counter =
      timer > 0 &&
      setInterval(
        () =>
          setTimer((prevState) => {
            return prevState - 1;
          }),
        1000
      );
    return () => clearInterval(counter);
  }
  const handleSendOtpFunc = async (data) =>{
    try{
      const response = await getOtp(data);
      setOtp(response.data.otp);
      startTimer()
      setShowOtpInput(true)
      setShowWhatsAppModal(true)
      setLoader(false)
    }catch(error){
      console.log(error)
      setWhatsappNumberError(error?.response?.data?.error)
      setLoader(false);
    }
  }
  const handleWhatsappSignUp = (e) =>{
    e.preventDefault()
    try{
      setLoader(true)
      let reqNumber = ''
      if (mobileNumber === undefined  || mobileNumber === ''){
        reqNumber = ""
        setWhatsappNumberError("Please Enter Mobile Number");
        setLoader(false)
      }else {
        reqNumber = mobileNumber 
        if (mobileNumber.slice(0,4) === "+911") {
          setWhatsappNumberError("Enter Valid Phone Number")
          setLoader(false)
        }else {
          if (isValidPhoneNumber(reqNumber) || reqNumber === '' ) {
            setWhatsappNumberError("");
            handleSendOtpFunc({mobile : reqNumber})
            // handleClose()
            if (timer < 60) {
              setTimer(60);
            }
          }else{
            setWhatsappNumberError("Enter Valid Phone Number")
            setLoader(false)
          }
        }
      }
    }catch(error){
      console.log(error)
      setWhatsappNumberError('')
      setLoader(false)
    }
  }
  const onVerify = async () => {
    const isOk = await bcrypt.compare(userEnteredOtp, otp);
    if (!isOk) {
      setConfirmationTitle('Error')
      setConfirmationBody("Invalid OTP please try again!!!")
      setShowConfirmationModel(true)
    }
    else {
      const response = await signUp({mobile : mobileNumber,whatsappLogin : true});
      handleOk()
      localStorage.setItem("auth", response.data.token);
      setAuthenticated();
      setUserProfile();
    }
};

//whatsapp signin
const [signinMobileNumber,setSignInMobileNumber] = useState('')
const [signInWhatsappNumberError, setSignInWhatsappNumberError] = useState("");
const [signInShowOtpInput,setSignInShowOtpInput] = useState(false)
const [signInOtp, setSignInOtp] = useState();
const [signInUserEnteredOtp, setSignInUserEnteredOtp] = useState("");
const handleSendOtpSignInFunc = async (data) =>{
  try{
    const response = await getSignInOtp(data);
    setSignInOtp(response.data.otp);
    startTimer()
    setSignInShowOtpInput(true)
    setLoader(false)
  }catch(error){
    console.log(error)
    setSignInWhatsappNumberError(error?.response?.data?.error)
    setLoader(false)
  }
}
const handleWhatsappSignIn = (e) =>{
  e.preventDefault()
  try{
    setLoader(true)
    let reqNumber = ''
    if (signinMobileNumber === undefined || signinMobileNumber === ''){
      reqNumber = ""
      setSignInWhatsappNumberError("Please Enter Mobile Number");
      setLoader(false)
    }else {
      reqNumber = signinMobileNumber 
      if (signinMobileNumber.slice(0,4) === "+911") {
        setSignInWhatsappNumberError("Enter Valid Phone Number")
        setLoader(false)
      }else {
        if (isValidPhoneNumber(reqNumber) || reqNumber === '' ) {
          setSignInWhatsappNumberError("");
          handleSendOtpSignInFunc({mobile : reqNumber})
        }else{
          setSignInWhatsappNumberError("Enter Valid Phone Number")
          setLoader(false)
        }
      }
    }
  }catch(error){
    console.log(error)
    setWhatsappNumberError('')
    setLoader(false)
  }
}
const onVerifySigin = async () => {
  const isOk = await bcrypt.compare(signInUserEnteredOtp, signInOtp);
  if (!isOk) {
    setConfirmationTitle('Error')
    setConfirmationBody("Invalid OTP please try again!!!")
    setShowConfirmationModel(true)
  }
  else {
    const response = await signIn({mobile : signinMobileNumber,whatsappLogin : true});
    localStorage.setItem("auth", response.data.token);
    setAuthenticated();
    setUserProfile();
    handleOk()
  }
};
  //components
  const signInModel = () => {
    return(
        <>
            <div className="alreadyac">
                <h3 style={{marginLeft:'16px'}}>Welcome back</h3>
                <p>
                Don’t have an account?{" "}
                <a
                    className="pointer"
                    onClick={() => setIsSignIn('signUp')}
                    style={{color:'#ea4335'}}
                >
                    Register
                </a>
                </p>
            </div>
            <form onSubmit={onSignin} className="container">
                <div className="row">
                  <div className="d-flex flex-column mb-3">
                      <label htmlFor="manager_number" className="form-label mb-1 mt-2">
                          <span onClick={()=>{
                            setSignInWith('mobile')
                            setDetails({...details,email : ''})
                          }} 
                          className={`pointer ${signInWith === 'mobile' ? 'required' : ''}`}>Phone Number</span>/
                          <span onClick={()=>{
                            setSignInWith('email')
                            setDetails({...details,mobile : ''})
                          }} 
                          className={`pointer ${signInWith === 'email' ? 'required' : ''}`}>Email</span><span className="required">*</span>
                      </label>
                      {signInWith === 'mobile' && (
                        <PhoneInput
                            required
                            international
                            className="signUpModalInputEl"
                            countryCallingCodeEditable={false}
                            flags
                            placeholder="Enter phone number"
                            style={{backgroundColor : "#ffffff", border: "1px solid #dddddd", borderRadius : "10px", height:"50px", marginBottom : "0px", paddingLeft : "15px" }}
                            value={mobile} 
                            defaultCountry="IN"
                            onChange={(e) => {
                              setDetails({
                                ...details,
                                mobile : e,
                              })
                            }}
                        />
                      )}
                      {signInWith === 'email' && (
                            <input
                                required
                                type="email"
                                className="form-control"
                                id="uemail"
                                name="email"
                                onChange={handleChange}
                                value={email}
                                // placeholder="Enter Your Email"
                            />
                      )}
                      {
                          emailError && <p style={{color:"Red", fontSize:"14.5px"}} className="m-0" > {emailError} </p> 
                      }
                  </div>
                  <div className="col-lg-12">
                      <div className="">
                        <label htmlFor="floatingInput">
                            Password<span className="required">*</span>
                        </label>
                        <div className="form-floating mb-3">
                            <input
                            required
                            type={!hidePassword ? "password" : "text"}
                            className="form-control"
                            style={{paddingBottom: '0px',paddingTop:'0px'}}
                            id="upwd"
                            name="password"
                            onChange={handleChange}
                            password={password}
                            // placeholder="Enter Your Password"
                            />
                            <div className="input-group-addon">
                            <i
                                onClick={() =>
                                setHidePassword((hidePassword) => !hidePassword)
                                }
                                className={
                                hidePassword
                                    ? "fa fa-eye pointer"
                                    : "fa fa-eye-slash pointer"
                                }
                                aria-hidden="true"
                            ></i>
                            </div>
                        {passwordError && <span style={{color : '#ea4335'}}>{passwordError}</span>}
                        </div>
                      </div>
                      {showError && <p style={{color : '#ea4335'}}>{errorInfo}</p>}
                      <div className="fgt-pwd text-end">
                      <Button
                          variant="link"
                          onClick={handleForgotPassword}
                      >
                          Forgot Password?
                      </Button>
                      </div>
                  </div>
                </div>
                  <div className="d-grid">
                  <button
                      className="btn btn-lg btn-primary btn-login text-uppercase fw-bold mb-2"
                      type="submit"
                  >
                      Log In
                  </button>
                  </div>
                    {/* <div className="other-login">
                        <GoogleLogin
                            clientId={googleClientId}
                            buttonText="Log In with Google"
                            onSuccess={onSuccess}
                            onFailure={onFailure}
                            cookiePolicy={"single_host_origin"}
                            isSignedIn={false}
                            className="btn btn-border fw-bolder text-dark googleBtn"
                        />
                        <div id="otpless"  className="btn btn-border"></div>
                        <button type="button" className="btn btn-border text-uppercase fw-bold mt-3" onClick={()=> setIsSignIn('whtasappSignIn')}>
                            <img src={whatsup} /> Log In with WhatsApp
                        </button>
                    </div> */}
                </form>
        </>
    )
  }


  const otpCallBack = (data) => {
    setUserEnteredOtp(data)
  }

  const signUpModel = () => {
    return(
        <>
            <h3 className="login-heading d-flex flex-row align-center">
                Register to <img src={logoName} />
            </h3>
            <div className="alreadyac signupModalTerms">
                <p>
                  Already have an account?{" "}
                  <a className="pointer" style={{color:'#ea4335'}} onClick={() => setIsSignIn('signIn')}>
                    Log In
                  </a>
                </p>
              </div>
            {/* <Stack direction="horizontal" gap={3} className="mb-2 col-12 signUpModalOtherSignUpsContainer">
                <GoogleLogin
                  clientId={googleClientId}
                  buttonText="Register with Google"
                  onSuccess={onSuccessGoogleSignup}
                  onFailure={onFailure}
                  cookiePolicy={"single_host_origin"}
                  isSignedIn={false}
                  className="btn btn-border fw-normal text-dark mx-2"
                />
                <div id="otpless" className="btn btn-border"></div>
                text-uppercase fw-bolder
                <button type="button" className="btn btn-border mx-1 signUpModalWhatsAppLogin"  onClick={()=> setIsSignIn('whtasappSignUp')}>
                  <img src={whatsup} /> Register with WhatsApp
                </button>
              </Stack> */}
              <Formik
                validationSchema={schema}
                onSubmit={onSignup}
                initialValues={{
                  firstName: "",
                  lastName: "",
                  mobile: "",
                  email: "",
                  password: "",
                  booking_type: "individual",
                  profession: "",
                  company: "",
                  googleLogin: false,
                  terms: false,
                }}
                innerRef={formikRef}
              >
                {({
                  handleSubmit,
                  handleChange,
                  handleBlur,
                  values,
                  touched,
                  isValid,
                  errors,
                }) => (
                  <div>
                  <Form noValidate onSubmit={handleSubmit} style={{paddingLeft:"5%",paddingRight : '5%'}} >
                    <>
                      <Row>
                        <Form.Group as={Col} md="6">
                          <Form.Label>
                            First Name<span className="required">*</span>
                          </Form.Label>
                          <Form.Control
                            type="text"
                            name="firstName"
                            value={values.firstName}
                            onChange={handleChange}
                            className="signUpModalInputEl"
                            isInvalid={!!touched.firstName && !!errors.firstName}
                          />
                          <Form.Control.Feedback
                            type="invalid"
                            className="" // text-capitalize
                          >
                            {errors.firstName}
                          </Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group
                          as={Col}
                          md="6"
                          controlId="validationFormik02"
                        >
                          <Form.Label>
                            Last Name
                            {/* <span className="required">*</span> */}
                          </Form.Label>
                          <Form.Control
                            type="text"
                            name="lastName"
                            value={values.lastName}
                            className="signUpModalInputEl"
                            onChange={handleChange}
                            isInvalid={!!errors.lastName}
                          />
                          <Form.Control.Feedback
                            type="invalid"
                            className= "" //text-capitalize"
                          >
                            {errors.lastName}
                          </Form.Control.Feedback>
                        </Form.Group>
                      </Row>
                    </>
                    <div className="d-flex flex-column">
                      <label htmlFor="manager_number" className="form-label mb-1 mt-2">
                          Phone Number<span className="required">*</span>
                      </label>
                      <PhoneInput
                          required
                          international
                          className="signUpModalInputEl"
                          countryCallingCodeEditable={false}
                          flags
                          placeholder="Enter phone number"
                          style={{backgroundColor : "#ffffff", border: "1px solid #dddddd", borderRadius : "10px", height:"50px", marginBottom : "0px", paddingLeft : "15px" }}
                          value={number} 
                          defaultCountry="IN"
                          onChange={(e) => {
                            setNumber(e)
                          }}
                      />
                      {
                          numberError && <p style={{color:"Red", fontSize:"14.5px"}} className="m-0" > {numberError} </p> 
                      }
                  </div>
                    <Form.Group as={Col} md="12">
                      <Form.Label className="mb-1 mt-2">
                        Email
                      </Form.Label>
                      <Form.Control
                        type="text"
                        name="email"
                        value={values.email}
                        className="signUpModalInputEl"
                        onChange={handleChange}
                        isInvalid={!!touched.email && !!errors.email}
                      />
                      <Form.Control.Feedback
                        type="invalid"
                        className="" //"text-capitalize"
                      >
                        {errors.email}
                      </Form.Control.Feedback>
                      {
                          signUpEmailError && <p style={{color:"Red", fontSize:"14.5px"}} className="m-0" > {signUpEmailError} </p> 
                      }
                    </Form.Group>
                    {/* <Form.Group as={Col} md="12">
                      <Form.Label>Phone Number</Form.Label>
                      <Form.Control
                        type="number"
                        name="mobile"
                        value={values.mobile}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        isInvalid={!!errors.mobile}
                      />
                      <Form.Control.Feedback
                        type="invalid"
                        className="text-capitalize"
                      >
                        {errors.mobile}
                      </Form.Control.Feedback>
                    </Form.Group> */}
                    <Form.Group as={Col} md="12">
                      <Form.Label className="mb-1 mt-2">
                        Password<span className="required">*</span>
                      </Form.Label>
                      <div className="form-floating mb-3">
                        <Form.Control
                          type={hidePasswordSignup ? "text" : "password"}
                          style={{paddingBottom: '0px',paddingTop:'0px'}}
                          className="signUpModalInputEl"
                          name="password"
                          value={values.password}
                          onChange={handleChange}
                          isInvalid={!!touched.password && !!errors.password}
                        />
                        <Form.Control.Feedback
                          type="invalid"
                          className=""
                        >
                          {errors.password}
                        </Form.Control.Feedback>
                        <div className="input-group-addon">
                          <i
                            onClick={() =>
                              setHidePasswordSignup((hidePasswordSignup) => !hidePasswordSignup)
                            }
                            className={
                              hidePasswordSignup
                                ? "fa fa-eye pointer"
                                : "fa fa-eye-slash pointer"
                            }
                            aria-hidden="true"
                          ></i>
                        </div>
                      </div>

                    </Form.Group>
                    <Form.Group className="mb-2 mt-2 col-md-11">
                      <div className="signupModalTerms d-flex flex-row align-items-center">
                        <Form.Check
                          required
                          name="terms"
                          label="I agree to the Terms and Conditions"
                          onChange={handleChange}
                          isInvalid={!!touched.terms && !!errors.terms}
                          // feedback={errors.terms}
                          feedbackType="invalid"
                          id="validationFormik0"
                        />
                      </div>
                      <ErrorMessage name="terms"  render={msg => <div style={{color:"#dc3545", fontSize:"0.875rem", marginTop:"0.25rem"}}> {msg} </div> }/>
                    </Form.Group>
                    <Col className="signupModalTerms mb-2" >
                      By clicking on Register you are agreeing to our &nbsp;
                      <Link style={{ color: "#ea4235" }} to={'/terms-and-conditions'} target="_blank">Terms of Services</Link>
                      &nbsp; and  &nbsp;<Link style={{ color: "#ea4235" }} to={'/privacypolicy'} target="_blank">Privacy Policy</Link>
                    </Col>
                    <div className="d-grid gap-2">
                      <Button type="submit" onClick={checkMobileValidation}>REGISTER</Button>
                    </div>
                  </Form>
                  </div>
                )}
              </Formik>
        </>
    )
  }

  const whatsAppSignUpModel = () => {
    return(
      <>
            <h3 className="login-heading">
                Register to <img src={logoName} />
            </h3>
            <div className="alreadyac">
                <p className="m-0">
                  Want to{" "}
                  <a className="pointer" style={{color:'#ea4335'}} onClick={() => setIsSignIn('signUp')}>
                    Register</a> with Mail
                </p>
              </div>
              {/* {showOtpInput ? (
                <div>
                  <p className="otp-header">Enter the 4 digit code sent to your WhatsApp</p>
                    <OTPInput
                      value={userEnteredOtp}
                      onChange={setUserEnteredOtp}
                      autoFocus
                      OTPLength={4}
                      otpType="number"
                      disabled={false}
                      secure
                      className="otp-input"
                    />
                    {timer > 0 && <p className="timer">{timer} sec.</p>}
                    <div className="resend-email">
                      <p>Didn't get a OTP?</p>
                      <Button
                        variant="link"
                        className="resend-button"
                        disabled={timer > 0}
                        onClick={handleWhatsappSignUp}
                      >
                        Resend Code
                      </Button>
                      <Button variant="primary" onClick={onVerify}>
                        Verify OTP
                      </Button>
                    </div>
                </div>
              ) : ( */}
                <form onSubmit={handleWhatsappSignUp}>
                  <div className="mt-3 w-100">
                    <label htmlFor="manager_number" className="form-label">
                        WhatsApp Number{' '}<span className="required">*</span>
                    </label>
                    <PhoneInput
                        // required
                        international
                        countryCallingCodeEditable={false}
                        flags
                        placeholder="Enter phone number"
                        style={{backgroundColor : "#ffffff", border: "1px solid #dddddd", borderRadius : "10px", height:"50px", margin : "0px 0px 10px 0px", paddingLeft : "15px" }}
                        value={mobileNumber} 
                        defaultCountry="IN"
                        onChange={(e) => {
                          setMobileNumber(e)
                        }}
                    />
                    {
                        whatsappNumberError && <p style={{color:"Red", fontSize:"14.5px"}} className="m-0" > {whatsappNumberError} </p> 
                    }
                    <Form.Group className="mb-2 mt-2 col-md-11">
                      <Form.Check
                        required
                        name="terms"
                        className="signupModalTerms d-flex flex-row align-items-center "
                        label="I agree to the Terms and Conditions"
                        onChange={handleChange}
                        feedbackType="invalid"
                        id='whatsappSignUpCheckTerms'
                      />
                    </Form.Group>
                      <Col htmlFor='whatsappSignUpCheckTerms' className="signupModalTerms mb-2" >
                        By clicking on Register you are agreeing to our &nbsp;
                        <Link style={{ color: "#ea4235" }} to={'/terms-and-conditions'} target="_blank">Terms of Services</Link>
                        &nbsp; and  &nbsp;<Link style={{ color: "#ea4235" }} to={'/privacypolicy'} target="_blank">Privacy Policy</Link>
                      </Col>
                  </div>
                    <Button type="submit" style={{width : '100%'}}>REGISTER</Button>
                </form>
              {/* )} */}
      </>
    )
  }
  const whatsAppSignInModel = () => {
    return(
      <>
        <h3 className="login-heading">
                Log In to <img src={logoName} />
            </h3>
            <div className="alreadyac">
            <h3 >Welcome back</h3>
                <p className="m-0">
                  Want to{" "}
                  <a className="pointer text-center" style={{color:'#ea4335'}} onClick={() => setIsSignIn('signIn')}>
                    Log In</a> with Mail
                </p>
              </div>
              {signInShowOtpInput ? (
                <div className="w-100">
                  <p className="otp-header">Enter the 4 digit code sent to your WhatsApp</p>
                    <OTPInput
                      value={signInUserEnteredOtp}
                      onChange={setSignInUserEnteredOtp}
                      autoFocus
                      OTPLength={4}
                      otpType="number"
                      disabled={false}
                      secure
                      className="otp-input"
                    />
                    {timer > 0 && <p className="timer">{timer} sec.</p>}
                    <div className="resend-email">
                      <p>Didn't get a OTP?</p>
                      <Button
                        variant="link"
                        className="resend-button"
                        disabled={timer > 0}
                        onClick={handleWhatsappSignIn}
                      >
                        Resend Code
                      </Button>
                      <Button variant="primary" onClick={onVerifySigin}>
                        Verify OTP
                      </Button>
                    </div>
                </div>
              ) : (
                <form onSubmit={handleWhatsappSignIn} className="w-100">
                  <div className="mt-3 w-100 mb-3">
                    <label htmlFor="manager_number" className="form-label">
                        WhatsApp Number{' '}<span className="required">*</span>
                    </label>
                    <PhoneInput
                        // required
                        international
                        countryCallingCodeEditable={false}
                        flags
                        placeholder="Enter phone number"
                        style={{backgroundColor : "#ffffff", border: "1px solid #dddddd", borderRadius : "10px", height:"50px", margin : "0px 0px 5px 0px", paddingLeft : "15px" }}
                        value={signinMobileNumber} 
                        defaultCountry="IN"
                        onChange={(e) => {
                          setSignInMobileNumber(e)
                        }}
                    />
                    {
                        signInWhatsappNumberError && <p style={{color:"Red", fontSize:"14.5px"}} className="m-0" > {signInWhatsappNumberError} </p> 
                    }
                  </div>
                    <Button type="submit" style={{width : '100%'}}>LOG IN</Button>
                </form>
              )}
      </>
    )
  }


  return (
    <Modal
      show={show}
      onHide={handleClose}
      backdrop="static"
      keyboard={false}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Header style={{borderBottom:"none"}} closeButton></Header>
      <Body className="verify-email-modal-body d-flex flex-column justify-content-center align-items-center">
        <Authentication activeTab='register' onSuccess={handleOk}/>
        {/* {isSignIn === 'signIn' && signInModel()}
        {isSignIn === 'signUp' && signUpModel()}
        {isSignIn === 'whtasappSignUp' && whatsAppSignUpModel()}
        {isSignIn === 'whtasappSignIn' && whatsAppSignInModel()} */}
      </Body>
      {showConfirmationModel && (
          <ConfirmationModel
          show={showConfirmationModel}
          handleClose={() => {
              setShowConfirmationModel(false)
          }}
          handleOk={() => setShowConfirmationModel(false)}
          title={confirmationTitle}
          body={confirmationBody}
          />
      )}
      { showWhatsAppModal &&
        <VerifyWhatsApp
        show = {showWhatsAppModal}
        handleClose={() => {
          setShowWhatsAppModal(false)
        }}
        userEnteredOtp = {userEnteredOtp}
        timer = {timer}
        handleOk={() => setShowWhatsAppModal(false)}
        title="Verify your WhatsApp Number"
        otpCallBack = { (data) => otpCallBack(data) } 
        number = {mobileNumber}
        reqOtp = {otp}
        handleSendOtpFunc = {(data) => handleSendOtpFunc(data) }
        handleWhatsappSignUp = { () => handleWhatsappSignUp() }
        confirmationTitle = { (text) => setConfirmationTitle(text) }
        confirmationBody = { (text) => setConfirmationBody(text) }
        confirmationModel = { (text) => setShowConfirmationModel(text) }
        handleOkay = {() => handleOk()}
        />
      }
    {loader && <PageLoader/>}
    </Modal>
  );

  }