import { Modal, Button, Form, Col, Alert } from "react-bootstrap";
// import { Formik } from "formik";
import * as yup from "yup";
import { useState } from "react";
import { forgotPassword } from "../../apis/api";
import PhoneInput, {isValidPhoneNumber} from 'react-phone-number-input'

const { Body, Title, Header } = Modal;
const schema = yup.object().shape({
  email: yup.string().email('Enter a valid email address')
  .matches(/^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i, 'Enter a valid email address')
  .required('Enter a valid email address'),
});

export const ForgotPasswordModal = ({ handleClose, show }) => {
  const [isEmailSent, setIsEmailSent] = useState(false);
  const [response, setResponse] = useState('');
  const [signInWith,setSignInWith] = useState('mobile');
  const [details, setDetails] = useState({
    email : '',
    mobile: "",
});
const { email,mobile } = details;
const [emailError,setEmailError] = useState(false);
const handleChange = (event) => {
  const { name, value } = event.target;
  setDetails({ ...details, [name]: value });
};
const checkSignInMobileValidation = (number) => {
  if (number === undefined || number === ''){
    setEmailError("Phone Number is Required");
    return false
  }else {
    if (number?.slice(0,4) === "+911") {
      setEmailError("Enter Valid Phone Number")
      return false
    }else {
      if (isValidPhoneNumber(number) || number === '' ) {
        setEmailError("");
        return true
    }else{
      setEmailError("Enter Valid Phone Number")
      return false
    }
  }
  }
}

  const onSubmit = async () => {
    if(signInWith === 'mobile' && !checkSignInMobileValidation(mobile)){
      return null
    }
    try {
      await forgotPassword(details);
      setResponse('')
      setIsEmailSent(true);
    } catch (error) {
      console.log(error.response.data.error)
      // setResponse(error.response.data.error)
      setEmailError(error.response.data.error)
      setIsEmailSent(false);
    }
  };

  return (
    <Modal show={show} onHide={handleClose}>
      <Header closeButton className="text-center">
        <Title className="text-center">Forgot Password</Title>
      </Header>
      <Body>
        {isEmailSent ? (
          <Alert variant="success">
            {details?.mobile && `Please check your WhatsApp. Message sent successfully!`}
            {details?.email && `Please check your email. Email sent successfully!`}
          </Alert>
        ) : (
          // <Formik
          //   validationSchema={schema}
          //   onSubmit={onSubmit}
          //   initialValues={{
          //     email: "",
          //   }}
          // >
          //   {({
          //     handleSubmit,
          //     handleChange,
          //     values,
          //     touched,
          //     isValid,
          //     errors,
          //   }) => (
          //     <Form noValidate onSubmit={handleSubmit}>
          //       <Form.Group
          //         as={Col}
          //         md="12"
          //         controlId="validationFormik101"
          //         className="position-relative"
          //       >
          //         <Form.Label>Email<span className="required" >*</span></Form.Label>
          //         <Form.Control
          //           type="text"
          //           name="email"
          //           value={values.email}
          //           onChange={(e)=> {
          //             if(response) setResponse('')
          //             handleChange(e)
          //           }}
          //           isInvalid={touched.email && !!errors.email}
          //         />
          //         {touched.email && !!errors.email && (
          //           <Form.Control.Feedback type="invalid">
          //             {errors.email}
          //           </Form.Control.Feedback>
          //         )}
          //         {response && <p style={{color : 'red'}}>{response}</p>}
          //       </Form.Group>
          //       <div className="d-grid gap-2 mt-4">
          //         <Button type="submit">Reset</Button>
          //       </div>
          //     </Form>
          //   )}
          // </Formik>
          <div className="d-flex flex-column mb-3">
            <label htmlFor="manager_number" className="form-label mb-1 mt-2">
                <span onClick={()=>{
                  setSignInWith('mobile')
                  setDetails({...details,email : ''})
                }} 
                className={`pointer ${signInWith === 'mobile' ? 'required' : ''}`}>Phone Number</span>/
                <span onClick={()=>{
                  setSignInWith('email')
                  setDetails({...details,mobile : ''})
                }} 
                className={`pointer ${signInWith === 'email' ? 'required' : ''}`}>Email</span><span className="required">*</span>
            </label>
            {signInWith === 'mobile' && (
              <PhoneInput
                  required
                  international
                  className="signUpModalInputEl"
                  countryCallingCodeEditable={false}
                  flags
                  placeholder="Enter phone number"
                  style={{backgroundColor : "#ffffff", border: "1px solid #dddddd", borderRadius : "10px", height:"50px", marginBottom : "0px", paddingLeft : "15px" }}
                  value={mobile} 
                  defaultCountry="IN"
                  onChange={(e) => {
                    setDetails({
                      ...details,
                      mobile : e,
                    })
                  }}
              />
            )}
            {signInWith === 'email' && (
                  <input
                      required
                      type="email"
                      className="form-control"
                      id="uemail"
                      name="email"
                      onChange={handleChange}
                      value={email}
                      // placeholder="Enter Your Email"
                  />
            )}
            {
                emailError && <p style={{color:"Red", fontSize:"14.5px"}} className="m-0" > {emailError} </p> 
            }
            <Button type="button" className = "mt-2" onClick={onSubmit}>Reset</Button>
        </div>
        )}
      </Body>
    </Modal>
  );
};
