import React from "react";
import { useEffect } from 'react'
import { Box, Typography } from "@mui/material";

//Components
import { Header } from "../../components/NewHeader/Header"
import { Footer } from "../../components/Footer"

import "./style.css";

// Images
import bannerPhoto from "../../assets/NewImages/CancellationRefund/terms-banner.webp";

export default function Cancellation() {

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [])

  return (
    <>
      <Header />
      <Box className="TermsContainer">
        <Box className="terms-bg">
          <img src={bannerPhoto} className="image-overlay" />
          <Box className="banner-content">
            <Typography variant="h1" gutterBottom>
              Cancellation and Refund
            </Typography>
            <Typography variant="subtitle1">
            Explore Spotlet's Policies for Clarity and Confidence in Your Subscription Experience.
            </Typography>
          </Box>
        </Box>
        <Box className="terms-content">
          <Typography variant="h3" gutterBottom>
            Cancellations and Refunds Policy:
          </Typography>
          <Typography variant="subtitle1" gutterBottom>
            Version: 1.2
          </Typography>
          <Typography variant="subtitle1" gutterBottom>
            Date of Last Revision: 01-Mar-2024
          </Typography>

          <Typography variant="h3" gutterBottom>
            1.  Introduction:
          </Typography>
          <Typography variant="body1" gutterBottom>
            {" "}
            <span className="span700">1.1 </span>{" "} This Cancellation and Refund Policy ("Policy") governs the cancellation 
            and refund process for subscriptions and services provided by SpotLet. By using SpotLet's platform and services,
             you agree to comply with this Policy.
          </Typography>

          <Typography variant="h3" gutterBottom>
            2.  Definitions:
          </Typography>
          <Typography variant="body1" gutterBottom>
            {" "}
            <span className="span700">2.1 </span>{" "} "SpotLet" refers to the online platform and services provided by 
            SpotLet for property listing, booking, and related activities.
          </Typography>
          <Typography variant="body1" gutterBottom>
            {" "}
            <span className="span700">2.2</span>{" "} "Subscription" refers to the paid plans offered by 
            SpotLet for property owners to list their properties on the platform.
          </Typography>
          <Typography variant="body1" gutterBottom>
            {" "}
            <span className="span700">2.3 </span>{" "}  "User" refers to any individual or entity 
            using SpotLet's platform and services.
          </Typography>
          <Typography variant="body1" gutterBottom>
            {" "}
            <span className="span700">2.4</span>{" "} "Host" refers to property owners who list their properties on SpotLet's platform.
          </Typography>

          <Typography variant="h3" gutterBottom>
          3. Cancellation of Subscription:
          </Typography>
          <Typography variant="body1" gutterBottom>
            {" "}
            <span className="span700">3.1 </span>{" "} Hosts may cancel their subscription to 
            SpotLet's platform by submitting a cancellation request through their account settings or by contacting SpotLet's customer support.
          </Typography>
          <Typography variant="body1" gutterBottom>
            {" "}
            <span className="span700">3.2</span>{" "} Hosts must submit their cancellation request within 
            30 days from the date their subscription was activated/paid to be eligible for a refund, as per SpotLet's refund policy.
          </Typography>
          <Typography variant="body1" gutterBottom>
            {" "}
            <span className="span700">3.3 </span>{" "}  SpotLet reserves the right to cancel a subscription if the host violates SpotLet's terms of service or engages in fraudulent activities.
          </Typography>

          <Typography variant="h3" gutterBottom>
          4. Refund Process:
          </Typography>
          <Typography variant="body1" gutterBottom>
            {" "}
            <span className="span700">4.1 </span>{" "}  Refund requests for cancelled subscriptions will be processed within 7 working days from the date the cancellation request is accepted by SpotLet.
          </Typography>
          <Typography variant="body1" gutterBottom>
            {" "}
            <span className="span700">4.2</span>{" "}  SpotLet will issue refunds using the same payment method used for the original transaction unless otherwise agreed upon.
          </Typography>
          <Typography variant="body1" gutterBottom>
            {" "}
            <span className="span700">4.3 </span>{" "}   Hosts will receive email confirmation once their cancellation request is accepted, and the refund process is initiated.
          </Typography>


          <Typography variant="h3" gutterBottom>
          5. Eligibility for Refund:
          </Typography>
          <Typography variant="body1" gutterBottom>
            {" "}
            <span className="span700">5.1 </span>{" "}   Hosts are eligible for a refund if they cancel their subscription within 30 days from the date of activation/payment.
          </Typography>
          <Typography variant="body1" gutterBottom>
            {" "}
            <span className="span700">5.2</span>{" "} Refunds will only be issued for the unused portion of the subscription period. Any services already provided by SpotLet during the subscription period will not be eligible for a refund.
          </Typography>
          <Typography variant="body1" gutterBottom>
            {" "}
            <span className="span700">5.3 </span>{" "}   Hosts who have received incentives, discounts, or promotional offers as part of their subscription may have their refund amount adjusted accordingly.
          </Typography>


          <Typography variant="h3" gutterBottom>
          6. Non-Refundable Charges:
          </Typography>
          <Typography variant="body1" gutterBottom>
            {" "}
            <span className="span700">6.1 </span>{" "}   Certain charges incurred by SpotLet are non-refundable and will be deducted from the refund amount, including but not limited to:
            <ul style={{listStyleType:"lower-alpha"}} >
              <li>
              Transaction fees or charges imposed by payment processors.
              </li>
              <li>
              Taxes or duties paid by SpotLet on behalf of the host.
              </li>
              <li>
              Administrative or processing fees associated with the refund process.
              </li>
            </ul>
          </Typography>

          <Typography variant="h3" gutterBottom>
          7. Dispute Resolution:
          </Typography>
          <Typography variant="body1" gutterBottom>
            {" "}
            <span className="span700">7.1 </span>{" "}   In the event of any dispute regarding a cancellation or refund request, SpotLet reserves the right to 
            investigate the matter and make a final decision based on its findings.
          </Typography>
          <Typography variant="body1" gutterBottom>
            {" "}
            <span className="span700">7.2</span>{" "} Hosts may contact SpotLet's customer support to escalate disputes or seek further clarification regarding their cancellation or refund request.
          </Typography>


           <Typography variant="h3" gutterBottom>
           8. International Payments and Legal Terms:
          </Typography>
          <Typography variant="body1" gutterBottom>
            {" "}
            <span className="span700">8.1 </span>{" "}   SpotLet accepts international payments and will adhere to the legal terms and regulations of the respective countries in which its services are utilized.
             Users are responsible for complying with their local laws regarding subscriptions, cancellations, and refunds.
          </Typography>


          <Typography variant="h3" gutterBottom>
          9. Amendments to the Policy:
          </Typography>
          <Typography variant="body1" gutterBottom>
            {" "}
            <span className="span700">9.1 </span>{" "}  SpotLet reserves the right to amend or update this Policy at any time without 
            prior notice. Any changes to the Policy will be effective immediately upon posting on SpotLet's website or platform.
          </Typography>
          <Typography variant="body1" gutterBottom>
            {" "}
            <span className="span700">9.2 </span>{" "}  Hosts are encouraged to review this Policy periodically to stay informed about any changes or updates.
          </Typography>


          <Typography variant="h3" gutterBottom>
          10. Governing Law:
          </Typography>
          <Typography variant="body1" gutterBottom>
            {" "}
            <span className="span700">10.1 </span>{" "}  This Policy shall be governed by and construed in accordance with the laws of Hyderabad, Telangana State, india, without regard to its conflict of law provisions.
          </Typography>


          <Typography variant="h3" gutterBottom>
          11. Severability:
          </Typography>
          <Typography variant="body1" gutterBottom>
            {" "}
            <span className="span700">11.1 </span>{" "}  If any provision of this Policy is found to be invalid, unlawful, or unenforceable, 
            the remaining provisions shall remain in full force and effect to the maximum extent permitted by law.
          </Typography>
          <Typography variant="body1" gutterBottom>
            {" "}
            <span className="span700">11.2 </span>{" "}  SpotLet's failure to enforce any provision of this Policy shall not be deemed a waiver of such provision or any other provision herein.
          </Typography>


          <Typography variant="h3" gutterBottom>
          12. Contact Information:
          </Typography>
          <Typography variant="body1" gutterBottom>
            {" "}
            <span className="span700">12.1 </span>{" "}   For any questions or concerns regarding this Policy or SpotLet's services, please contact us at support@spotlet.co.
          </Typography>


          <Typography variant="h3" gutterBottom>
          13. Acknowledgment and Acceptance:
          </Typography>
          <Typography variant="body1" gutterBottom>
            {" "}
            <span className="span700">13.1 </span>{" "}   By using SpotLet's platform and services, you acknowledge that you have read, understood, and agree to be bound by this Cancellation and Refund Policy. 
            If you do not agree to this Policy, you may not access or use SpotLet's platform and services.
          </Typography>


        </Box>
      </Box>
      <Footer />
    </>
  );
}


// return (
//   <>
//     <Header />
//     <Box className="TermsContainer">
//       <Box className="terms-bg">
//         <img src={bannerPhoto} className="image-overlay" />
//         <Box className="banner-content">
//           <Typography variant="h1" gutterBottom>
//             Cancellation and Refund
//           </Typography>
//           <Typography variant="subtitle1">
//           Explore Spotlet's Policies for Clarity and Confidence in Your Subscription Experience.
//           </Typography>
//         </Box>
//       </Box>
//       <Box className="terms-content">
//         <Typography variant="h3" gutterBottom>
//           Cancellations and Refunds Policy:
//         </Typography>
//         <Typography variant="subtitle1" gutterBottom>
//           Version: 1.2
//         </Typography>
//         <Typography variant="subtitle1" gutterBottom>
//           Date of Last Revision: 01-Mar-2024
//         </Typography>
//         <Typography variant="subtitle1" gutterBottom>
//           1. Subscription Services:
//         </Typography>
//         <Typography variant="body1" gutterBottom>
//         Spotlet solely provides subscription services to hosts for listing their properties on the platform. As such, there are no cancellation or refund provisions applicable to subscription services.
//         </Typography>
//         <Typography variant="subtitle1" gutterBottom>
//         2. Subscription Duration:
//         </Typography>
//         <Typography variant="body1" gutterBottom>
//         Hosts subscribing to Spotlet's services agree to a specified subscription duration as per their chosen plan, whether monthly or annually.          </Typography>
//         <Typography variant="subtitle1" gutterBottom>
//         3. No Cancellation: 
//         </Typography>
//         <Typography variant="body1" gutterBottom>
//         Once a subscription plan is activated, the host is committed to the agreed-upon duration and is not eligible for cancellation or termination of the subscription before the end of the subscription period.          </Typography>
//         <Typography variant="subtitle1" gutterBottom>
//         4. No Refunds:
//         </Typography>
//         <Typography variant="body1" gutterBottom>
//         Spotlet does not offer refunds for any subscription fees paid by hosts, regardless of the circumstances, including but not limited to early termination, non-usage of services, or dissatisfaction with the platform.          </Typography>
//         <Typography variant="subtitle1" gutterBottom>
//         5. Automatic Renewal:
//         </Typography>
//         <Typography variant="body1" gutterBottom>
//         Subscription plans may automatically renew at the end of the subscription period unless canceled by the host prior to the renewal date. Hosts are responsible for managing their subscription settings to prevent automatic renewals.          </Typography>
//         <Typography variant="subtitle1" gutterBottom>
//         6. Change of Subscription Plan:
//         </Typography>
//         <Typography variant="body1" gutterBottom>
//         Hosts have the option to upgrade or downgrade their subscription plan at any time. Any changes to the subscription plan will be effective from the next billing cycle.          </Typography>
//         <Typography variant="subtitle1" gutterBottom>
//         7. Contact Information: 
//         </Typography>
//         <Typography variant="body1" gutterBottom>
//         For inquiries regarding subscription services, billing, or other related matters, hosts can contact Spotlet's customer support team at support@spotlet.co. 
//         </Typography>
//         <Typography variant="subtitle1" gutterBottom>
//         8. Amendment of Policy:
//         </Typography>
//         <Typography variant="body1" gutterBottom>
//         Spotlet reserves the right to amend or update this Cancellation and Refund Policy at any time without prior notice. Hosts are advised to review the policy periodically for any changes.          </Typography>
//         <Typography variant="body1" gutterBottom>
//         By subscribing to Spotlet's services, hosts acknowledge and agree to abide by the terms outlined in this Cancellation and Refund Policy.  </Typography>        {/* <Typography variant="subtitle1" gutterBottom>
//           i). Cancellations by Renters
//         </Typography>
//         <Typography variant="body1" gutterBottom>
//           <ul>
//             <li>
//               As a Renter, you have the option to cancel any reserved Booking
//               before it is confirmed by the Lister without incurring any
//               further obligations.
//             </li>
//             <li>
//               Once a booking is confirmed by a Lister, the following
//               cancellation and refund policies apply to Renters:
//             </li>
//             <li>
//               Cancellation within 24 hours of booking: If a Renter cancels a
//               booking within twenty-four (24) hours of confirmation, SpotLet's
//               Grace Period cancellation policy applies, entitling the Renter
//               to a full refund (unless there are less than 7 days remaining
//               until the shoot/event start, in which case the booking is
//               non-refundable).
//             </li>
//             <li>
//               Cancellation 7 days or fewer before the booking start date: If a
//               Renter cancels a booking forty-eight (48) hours or fewer prior
//               to the start of the booking period, the booking is
//               non-refundable, and the Renter will be charged the Total Fees
//               specified in the booking request.
//             </li>
//             <li>
//               Cancellation more than 48 hours prior to the booking start date:
//               If a Renter cancels a booking more than forty-eight (48) hours
//               before the start of the booking period, the Renter will be
//               charged fifty percent (50%) of the Total Fees indicated in the
//               booking request.
//             </li>
//             <li>
//               In the event that a Renter cancels a booking requiring a refund
//               to the Renter or a payout to the Lister, as outlined in Section
//               9(f)(i), SpotLet will retain the Property Fees until the date on
//               which the canceled booking was scheduled to begin. At that
//               point, SpotLet will commence the processing of any applicable
//               payments to the Renter and/or Lister in accordance with its
//               standard payment policy. For all Renter-cancelled bookings, the
//               Lister will be entitled to receive the Property Fees paid to
//               SpotLet as outlined in Section 9(f)(i), minus the Service Fees
//               payable to SpotLet. Failure of a Renter to comply with the
//               cancellation provisions of Section 9(f) will result in the
//               forfeiture of the Renter's right to any refund, and the Renter
//               will be liable for the full Total Fees.
//             </li>
//           </ul>
//         </Typography>
//         <Typography variant="subtitle1" gutterBottom>
//           ii) Cancellations by Listers
//         </Typography>
//         <Typography variant="body1" gutterBottom>
//           <ul>
//             <li>
//               The cancellation of a confirmed booking by a Lister can have
//               significant logistical implications and impact the high standard
//               of service provided by SpotLet's marketplace. Therefore, in the
//               event of a Lister's cancellation of a confirmed booking, SpotLet
//               reserves the right to impose penalties on Lister's account.
//               These penalties may include the publication of an automated
//               review indicating a canceled booking, as well as the suspension
//               or termination of Lister's account. A-Lister must cancel a
//               confirmed booking with at least 7 calendar days' notice. Failure
//               to comply with this requirement will result in SpotLet charging
//               the Lister the applicable Service Fee and Processing Fee.
//               Additionally, if a Lister cancels more than one confirmed
//               booking, SpotLet may, at its sole discretion, withhold future
//               payouts to the Lister to compensate for any losses incurred due
//               to the Lister's initial cancellation. These losses may include
//               but are not limited to, the additional costs incurred in
//               securing a new booking for a displaced Renter.
//             </li>
//           </ul>
//         </Typography>
//         <Typography variant="subtitle1" gutterBottom>
//           iii) Cancellations by SpotLet
//         </Typography>
//         <Typography variant="body1" gutterBottom>
//           <ul>
//             <li>
//               In certain circumstances, SpotLet may determine, at its sole
//               discretion, that it is necessary or desirable to cancel a
//               confirmed booking or issue a refund to a Renter for all or part
//               of the amounts charged. This may occur due to extenuating
//               circumstances, emergencies, or other reasons. Additionally,
//               SpotLet reserves the right to cancel any booking that it deems
//               fictitious, at its sole discretion. By agreeing to these terms,
//               you acknowledge that SpotLet and the relevant Renter or Lister
//               will not be liable for any such cancellation or refund made by
//               SpotLet.
//             </li>
//           </ul>
//         </Typography>
//         <Typography variant="subtitle1" gutterBottom>
//           iv) Modification of Bookings with Cancellation Protection
//         </Typography>
//         <Typography variant="body1" gutterBottom>
//           If a Renter requests a modification to their booking, for which
//           Cancellation Protection was purchased, the following rules apply to
//           the Cancellation Protection fee:
//         </Typography>
//         <Typography variant="body1" gutterBottom>
//           <ul>
//             <li>
//               If the price of the booking (Booking total) increases as a
//               result of the modification, the Cancellation Protection fee will
//               increase accordingly.
//             </li>
//             <li>
//               If the price of the booking (Booking total) decreases as a
//               result of the modification, the Cancellation Protection fee will
//               remain unchanged.
//             </li>
//           </ul>
//         </Typography>
//         <Typography variant="subtitle1" gutterBottom>
//           Fraud and Fraud Prevention
//         </Typography>
//         <Typography variant="body1" gutterBottom>
//           <ul>
//             <li>
//               Despite SpotLet's efforts to detect and prevent fraudulent
//               behavior, it is ultimately the Lister's responsibility to verify
//               the identity of Renters who book their property. If a Lister
//               confirms a booking and receives Property Fees from a fraudulent
//               Renter, SpotLet reserves the right to collect the applicable
//               Property Fees from the Lister after the payout has been issued.
//               If the Lister refuses to return the applicable Property Fees to
//               SpotLet, SpotLet may pursue legal means to recover them, and the
//               Lister will also be required to reimburse SpotLet for all costs
//               incurred in collecting the applicable Property Fees, including
//               court costs and attorneys' fees. Additionally, SpotLet may, at
//               its sole discretion, withhold future payouts to a Lister to
//               compensate for any losses incurred as a result of the Lister's
//               initial cancellation.
//             </li>
//             <li>
//               Furthermore, SpotLet retains the right to withhold a Lister's
//               payout related to potentially fraudulent Renter bookings for a
//               period of 140 days after the payment is made on the platform to
//               prevent the possibility of chargebacks related to Renters.
//             </li>
//           </ul>
//         </Typography>
//         <Typography variant="h3" gutterBottom>
//           1.1 Renting a property on Spotlet:
//         </Typography>
//         <Typography variant="body1" gutterBottom>
//           {" "}
//           <span className="span700">i)</span> As a Renter, it is your
//           responsibility to obtain all necessary permits and insurance
//           policies required by relevant authorities (including but not limited
//           to filming permits and parking permits) for the filming process at
//           the Property during the Booking Period. You must also acquire
//           release forms and other relevant documents. SpotLet cannot be held
//           accountable if you fail to obtain the required permits and documents
//           for the filming process at the Property during the Booking Period.
//         </Typography>
//         <Typography variant="h3" gutterBottom>
//           2. Damage To Properties:
//         </Typography>
//         <Typography variant="body1" gutterBottom>
//           {" "}
//           <span className="span700">2.1</span> As a Renter, you are liable
//           for leaving the Property in the same condition as it was when you
//           arrived. You acknowledge and agree that you are responsible for your
//           own actions and the actions of individuals you invite or grant
//           access to the Property. If a Lister claims damage with evidence
//           ("Damage Claim"), such as photographs, you agree to reimburse the
//           cost of replacing the damaged items with equivalent ones.
//         </Typography>
//         <Typography variant="body1" gutterBottom>
//           {" "}
//           <span className="span700">2.2</span> Listers may include security
//           deposits ("Security Deposits") in their Listings. Each Listing will
//           specify if a Security Deposit is required for the respective
//           Property. SpotLet will make reasonable efforts to address Listers'
//           requests and claims regarding Security Deposits. However, SpotLet is
//           not responsible for administering or accepting any Damage Claims
//           made by Listers regarding Security Deposits and disclaims any
//           liability in this regard.
//         </Typography>
//         <Typography variant="body1" gutterBottom>
//           {" "}
//           <span className="span700">2.3</span> If a Lister makes a Damage
//           Claim for a confirmed booking, you will be notified of the claim and
//           given forty-eight (48) hours to respond. Stripe will collect the
//           costs from you or deduct them from the Security Deposit.
//           SpotLet/Stripe reserves the right to collect payment from you and
//           take necessary actions, at SpotLet's sole discretion, if you have
//           been determined to have caused damage to a Property, including
//           payment requests based on a Damage Claim and payments made by
//           SpotLet to Listers.
//         </Typography>
//         <Typography variant="body1" gutterBottom>
//           {" "}
//           <span className="span700">2.4</span> Both Renters and Listers agree
//           to cooperate with SpotLet and provide necessary information and
//           assistance in good faith regarding Damage Claims or other complaints
//           related to Properties or any personal or other property at a
//           Property. If requested by SpotLet, Renters agree to participate in
//           mediation or a similar resolution process with a Lister, facilitated
//           by SpotLet or a third party selected by SpotLet, at no cost.
//         </Typography>
//         <Typography variant="body1" gutterBottom>
//           {" "}
//           <span className="span700">2.5</span> If you are a Renter, you
//           understand and agree that SpotLet may make a claim under your
//           homeowner's, renter's, or other insurance policy for any damage or
//           loss caused by you to a Property or any personal or other property
//           at a Property. You agree to cooperate with SpotLet, provide
//           requested information, and take necessary actions to assist in
//           making an insurance claim, as reasonably requested by SpotLet.
//         </Typography>
//         <Typography variant="body1" gutterBottom>
//           {" "}
//           <span className="span700">2.6</span> Regarding Security Deposits,
//           if required by a Lister, they may be applied to any fees owed by a
//           Renter for overstaying at a Listing without the Lister's consent.
//           SpotLet holds a security deposit equal to 50% of the total location
//           fee 48 hours prior to the start date of the booking, if applicable.
//           This hold is authorized on the credit card used for the booking. If
//           SpotLet fails to authorize the security deposit, the Renter's
//           booking may be subject to the terms of the SpotLet cancellation
//           policy.
//         </Typography>
//         <Typography variant="h3" gutterBottom>
//           3. Insurance:
//         </Typography>
//         <Typography variant="body1" gutterBottom>
//           {" "}
//           <span className="span700">3.1</span>{" "}
//           <span className="span600">
//             SpotLet requires Listers and Renters to obtain
//           </span>{" "}
//           insurance or review their existing policies to ensure coverage for
//           any loss arising from the Booking and use of a Property by the
//           Renter, guests, vendors, clients, customers, or licensees. This
//           includes coverage for property damage, casualty, personal injury,
//           fire, and general liability ("Liability Insurance").{" "}
//           <span className="span600">SpotLet</span> disclaims liability for
//           failure by Listers or Renters to have Liability Insurance. If a
//           Lister has not obtained Liability Insurance,{" "}
//           <span className="span600">SpotLet</span> may require the Renter to
//           purchase it before the booking start date.{" "}
//           <span className="span600">SpotLet</span> is not liable for the
//           Parties' failure to obtain Liability Insurance.
//         </Typography>
//         <Typography variant="h3" gutterBottom>
//           4. Overstaying Without The Lister's Consent:
//         </Typography>
//         <Typography variant="body1" gutterBottom>
//           {" "}
//           <span className="span700">4.1</span> Renters acknowledge that a
//           confirmed booking grants them a license to enter and use the Listing
//           for the specified duration and in accordance with the agreement with
//           the Lister.{" "}
//           <span className="span600">Renters are only allowed</span> to be
//           present at the Property during the designated hours specified in the
//           Listing or as mutually agreed upon with the Lister. Renters agree to
//           vacate the Property no later than the checkout time{" "}
//           <span className="span600">specified in the Listing</span> or as
//           mutually agreed upon with the Lister. If a Renter exceeds the
//           designated hours or checkout time without the Lister's consent,
//           their license to stay in the Listing is revoked, and the Lister has
//           the right to request their departure.
//         </Typography>
//         <Typography variant="body1" gutterBottom>
//           {" "}
//           <span className="span700">4.2</span> In such cases, the{" "}
//           <span className="span600">Lister can charge the Renter</span> an
//           additional fee of 1.5 times the average hourly Property Fee
//           initially paid by the Renter for the inconveniences suffered by the
//           Lister, along with applicable Service Fees, Taxes, and any legal
//           expenses incurred by the Lister to enforce the{" "}
//           <span className="span600">Renter's departure</span> . The Additional
//           Sums will be charged in half-hour increments. If the Renter's
//           production/event has more people on location than originally booked,{" "}
//           <span className="span600">SpotLet</span> reserves the right to
//           charge the Renter for the extra people, damages, and overages based
//           on evidence provided by the Host.{" "}
//           <span className="span600">SpotLet</span> may charge the Renter for
//           any such overages based on the Location Agreement.
//         </Typography>
//         <Typography variant="h3" gutterBottom>
//           5. User Conduct:
//         </Typography>
//         <Typography variant="body1" gutterBottom>
//           {" "}
//           <span className="span700">5.1</span> You acknowledge and accept
//           that you are solely responsible for complying with all applicable
//           laws, regulations, and tax obligations when using the Site,
//           Services, and Collective Content. When using the Site, Services, and
//           Collective Content, you agree not to:
//         </Typography>
//         <Typography variant="body1" gutterBottom>
//           {" "}
//           <span className="span700">A.</span> Violate any local, state,
//           provincial, national, or other laws or regulations, including zoning
//           restrictions and tax regulations, or disregard court orders.
//           <br></br> <span className="span700">B.</span> Use manual or
//           automated software, devices, scripts, robots, backdoors, or any
//           other means to access, scrape, crawl, or spider web pages or other
//           services on the Site, Services, or Collective Content.<br></br>{" "}
//           <span className="span700">C.</span> Access or use the Site or
//           Services to access or expose SpotLet Content that is not publicly
//           displayed by SpotLet before a booking is confirmed, or in a manner
//           inconsistent with the SpotLet Privacy Policy or Terms of Service, or
//           that violates the privacy rights or any other rights of SpotLet's
//           users or third parties.<br></br> <span className="span700">D.</span>{" "}
//           Use the Site, Services, or Collective Content for any commercial or
//           unauthorized purposes that may falsely imply endorsement,
//           partnership, or affiliation with SpotLet.<br></br>{" "}
//           <span className="span700">E.</span> Harm or diminish the SpotLet
//           brand in any way, including unauthorized use of Collective Content,
//           registering or using domain names, trade names, trademarks, or other
//           identifiers similar to SpotLet's, or engaging in actions that dilute
//           or confuse SpotLet's domains, trademarks, slogans, campaigns, or
//           Collective Content.
//           <br></br> <span className="span700">F.</span> Copy, store, or access
//           any information on the Site, Services, or Collective Content for
//           purposes not explicitly allowed by these Terms.<br></br>{" "}
//           <span className="span700">G.</span> Infringe on SpotLet's or any
//           other person's intellectual property, privacy, publicity, or
//           contractual rights.<br></br> <span className="span700">H.</span>{" "}
//           Interfere with or damage the Site or Services, including using
//           viruses, cancel bots, harmful code, denial-of-service attacks, or
//           any other malicious methods or technology.<br></br>{" "}
//           <span className="span700">I.</span> Transmit or submit any
//           information about any other person without their permission,
//           including photographs, personal contact information, or financial
//           details.<br></br> <span className="span700">J.</span> Use the Site,
//           Services, or Collective Content for the distribution of unsolicited
//           commercial emails or unrelated advertisements.<br></br>{" "}
//           <span className="span700">K.</span> Stalk, harass, collect
//           personally identifiable information about other users, or use such
//           information for any purposes other than transacting as a SpotLet
//           Renter or Lister.<br></br> <span className="span700">L.</span> List
//           a property that you do not own or have permission to rent, acting as
//           a rental or listing agent without proper authorization.
//           <br></br> <span className="span700">M.</span> List a property that
//           cannot be rented or subleased as per agreements with third parties,
//           such as property rental agreements.<br></br>{" "}
//           <span className="span700">N.</span> Register for multiple SpotLet
//           accounts or create an account on behalf of someone else.<br></br>{" "}
//           <span className="span700">O.</span> Request or book a stay at a
//           property without actually using it for the intended purpose, in an
//           attempt to avoid paying Service Fees or for any other reasons.
//           <br></br> <span className="span700">P.</span> Contact other users
//           for purposes unrelated to bookings, properties, listings, or their
//           use of the Site and Services.
//           <br></br> <span className="span700">Q.</span> Recruit or solicit
//           Listers or other users to join competitive services or websites
//           without prior written approval from SpotLet.
//           <br></br> <span className="span700">R.</span> Recruit or solicit
//           users to join third-party services or websites without SpotLet's
//           prior written approval.<br></br> <span className="span700">S.</span>{" "}
//           Impersonate any person or entity or misrepresent yourself or your
//           affiliation with any person or entity.<br></br>{" "}
//           <span className="span700">T.</span> Use automated scripts to
//           interact with the Site, Services, or Collective Content.<br></br>{" "}
//           <span className="span700">U.</span> Use the Site, Services, or
//           Collective Content to book a property outside of SpotLet to avoid
//           paying Service Fees or for any other reasons.<br></br>{" "}
//           <span className="span700">V.</span> Submit a Listing with false or
//           misleading information, including price information that you do not
//           intend to honor.<br></br> <span className="span700">W.</span>{" "}
//           Violate these Terms.<br></br> <span className="span700">X.</span>{" "}
//           Engage in disruptive, abusive, or harassing behavior on the Site or
//           Services.<br></br> <span className="span700">Y.</span> Post, upload,
//           publish, submit, or transmit any content that infringes on
//           third-party intellectual property rights, violates any law, is
//           fraudulent, false, misleading, defamatory, obscene, offensive,
//           promotes discrimination, violence, illegal activities, or harm.
//           <br></br> <span className="span700">Z.</span> Systematically
//           retrieve data from the Site or Services, create compilations,
//           databases, or directories, or use bots, crawlers, or spiders without
//           permission.
//         </Typography>
//         <Typography variant="body1" gutterBottom>
//           {" "}
//           <span className="span700">AA.</span> Use or display SpotLet's name,
//           trademarks, logos, or proprietary information without express
//           written consent.<br></br> <span className="span700">BB.</span>{" "}
//           Access non-public areas of the Site or Services, SpotLet's computer
//           systems, or technical delivery systems without authorization.{" "}
//           <br></br> <span className="span700">CC.</span> Attempt to probe,
//           scan, or test the vulnerability of SpotLet's systems or breach
//           security measures.<br></br> <span className="span700">DD.</span>{" "}
//           Avoid, bypass, remove, deactivate, impair, or otherwise circumvent
//           technological measures to protect the Site, Services, or Collective
//           Content.<br></br>
//           <span className="span700">EE.</span> Forge any header information or
//           use the Site, Services, or Collective Content to send altered,
//           deceptive, or false information.
//           <br></br>
//           <span className="span700">FF</span> . Advocate, encourage, or assist
//           others in any of the above actions.<br></br>
//           <span className="span700">GG.</span> Make or accept payment for
//           Property Fees or Property Management Fees outside of SpotLet,
//           acknowledging that it is a breach of these Terms and releasing
//           SpotLet from liability for such payments.
//         </Typography>
//         <Typography variant="body1" gutterBottom>
//           {" "}
//           <span className="span600">
//             SpotLet reserves the right to investigate
//           </span>{" "}
//           and take legal action against any violations mentioned above.
//           Additionally, SpotLet may take various actions, such as deactivating
//           or canceling Listings or SpotLet Accounts, for violations of this
//           section or the Terms.
//         </Typography>
//         <Typography variant="body1" gutterBottom>
//           SpotLet may access, preserve, and{" "}
//           <span className="span600">
//             disclose user information if required by law
//           </span>{" "}
//           or in good faith to respond to claims, enforce agreements, prevent
//           fraud, assess risks, conduct investigations, provide customer
//           support, and protect{" "}
//           <span className="span600">
//             SpotLet's rights, users, or the public
//           </span>{" "}
//           . While SpotLet has no obligation to monitor user activities or
//           review User Content, it reserves the right to do so for operational,
//           improvement, legal compliance, or objectionable content purposes.
//           SpotLet may remove or{" "}
//           <span className="span600">disable access to any objectionable</span>{" "}
//           Collective Content atits sole discretion.
//         </Typography>
//         <Typography variant="h3" gutterBottom>
//           6. Reporting Misconduct:
//         </Typography>
//         <Typography variant="body1" gutterBottom>
//           {" "}
//           <span className="span700">6.1</span> If you encounter or host
//           someone who engages in inappropriate behavior, theft, or other
//           disturbing conduct, you should report them immediately to the
//           appropriate authorities and notify SpotLet with the police station
//           and report number. However, SpotLet's response will be limited to
//           legal requirements and will not incur any additional liability.
//         </Typography>
//         <Typography variant="h3" gutterBottom>
//           7. Privacy:
//         </Typography>
//         <Typography variant="body1" gutterBottom>
//           {" "}
//           <span className="span700">7.1</span> You agree that SpotLet's
//           collection and use of your personal information are governed by
//           SpotLet's Privacy Policy, which may be updated from time to time.
//         </Typography>
//         <Typography variant="h3" gutterBottom>
//           8. Ownership Of Intellectual Property And Rights Notices:
//         </Typography>
//         <Typography variant="body1" gutterBottom>
//           {" "}
//           <span className="span700">8.1</span> The Site, Services, and
//           Collective Content are protected by copyright, trademark, and other{" "}
//           <span className="span600">laws of India and foreign countries</span>{" "}
//           . You acknowledge and agree that{" "}
//           <span className="span600">SpotLet</span> and its licensors are the
//           exclusive owners of the Site, Services, and Collective Content,
//           including all associated intellectual property rights. You may not
//           remove, alter, or obscure any copyright, trademark, service mark, or
//           other proprietary rights notices incorporated in or accompanying the
//           Site, Services, or Collective Content.{" "}
//           <span className="span600">SpotLet's</span> trademarks, service
//           marks, logos, trade names, and other proprietary designations used
//           on or in connection with the Site, Services, and{" "}
//           <span className="span600">SpotLet</span> Content are trademarks or
//           registered trademarks of <span className="span600">SpotLet</span> in
//           India and abroad. Trademarks, service marks, logos, trade names, and
//           other proprietary designations of third parties used on or in
//           connection with the Site, Services, and{" "}
//           <span className="span600">SpotLet</span> Content are used for
//           identification purposes only and may be the property of their
//           respective owners. As a Lister, Renter, or User, you acknowledge and
//           agree to be bound by additional Terms, Guidelines, and Policies
//           applicable to your use of the Site, Services, and Collective
//           Content.
//         </Typography>
//         <Typography variant="h3" gutterBottom>
//           9. License For Spotlet Content And User Content:
//         </Typography>
//         <Typography variant="body1" gutterBottom>
//           {" "}
//           <span className="span600">9.1</span> Subject to your compliance
//           with these Terms,{" "}
//           <span className="span600">SpotLet grants you a limited</span> ,
//           non-exclusive, non-transferable license to (i) access and view
//           SpotLet Content solely for personal and non-commercial purposes, and
//           (ii) access and view User Content to which you have permission,
//           solely for personal and non-commercial purposes. You are not
//           permitted to use, copy, adapt, modify, create derivative works based
//           on, distribute, license, sell, transfer, publicly display, publicly
//           perform, transmit, broadcast, or exploit the Site, Services, or
//           Collective Content unless expressly permitted in these Terms. No
//           licenses or rights are granted to you implicitly or otherwise under
//           any intellectual property rights owned or controlled by SpotLet or
//           its licensors, except for the licenses and rights expressly granted
//           in these Terms.
//         </Typography>
//         <Typography variant="h3" gutterBottom>
//           10. User Content:
//         </Typography>
//         <Typography variant="body1" gutterBottom>
//           {" "}
//           <span className="span700">10.1</span> At our discretion, we may
//           allow you to post, upload, publish, submit, or transmit User
//           Content. By making any User Content available on or through the
//           Site, Services, or SpotLet promotional campaigns, you grant SpotLet
//           a worldwide, irrevocable, perpetual, non-exclusive, transferable,
//           royalty-free license, with the right to sublicense, to use, view,
//           copy, adapt, modify, distribute, license, sell, transfer, publicly
//           display, publicly perform, transmit, stream, broadcast, access,
//           view, and exploit such User Content to promote or market the Site
//           and Services. SpotLet does not claim ownership rights in any User
//           Content, and nothing in these Terms restricts your rights to use and
//           exploit your User Content.
//         </Typography>
//         <Typography variant="body1" gutterBottom>
//           {" "}
//           <span className="span700">10.2</span> You acknowledge and agree that
//           you are solely responsible for all User Content you make available
//           through the Site, Services, or SpotLet promotional campaigns. You
//           represent and warrant that (i) you own or have all necessary rights,
//           licenses, consents, and releases to grant SpotLet the rights in your
//           User Content, as outlined in these Terms, and (ii) your User Content
//           and its use by SpotLet will not infringe, misappropriate, or violate
//           any third party's intellectual property rights, privacy rights, or
//           other proprietary rights, or any applicable law or regulation.
//         </Typography>
//         <Typography variant="h3" gutterBottom>
//           11. Links:
//         </Typography>
//         <Typography variant="body1" gutterBottom>
//           {" "}
//           <span className="span700">11.1</span> The Site and Services may
//           include links to third-party websites or resources. You acknowledge
//           and agree that SpotLet is not responsible or liable for (i) the
//           availability or accuracy of such websites or resources, or (ii) the
//           content, products, or services on or available from such websites or
//           resources. The inclusion of links to such websites or resources does
//           not imply endorsement by SpotLet. You assume all responsibility and
//           risk for your use of any third.
//         </Typography>
//         <Typography variant="h3" gutterBottom>
//           12. Term And Termination, Suspension, And Other Actions:
//         </Typography>
//         <Typography variant="subtitle1" gutterBottom>
//           A. Duration
//         </Typography>
//         <Typography variant="body1" gutterBottom>
//           This Agreement will remain in effect for a{" "}
//           <span className="span600">
//             period of 30 days, and upon its expiration
//           </span>{" "}
//           , it will automatically renew for subsequent 30-day terms until
//           either you or SpotLet terminate the Agreement as outlined below.
//         </Typography>
//         <Typography variant="subtitle1" gutterBottom>
//           B. Voluntary Termination
//         </Typography>
//         <Typography variant="body1" gutterBottom>
//           You have the option to{" "}
//           <span className="span600">
//             terminate this Agreement at any time by sending
//           </span>{" "}
//           terminate this Agreement at any time by sending us an email. If you
//           cancel your SpotLet Account as a Lister, any confirmed bookings will
//           be canceled automatically, and your Renters will receive a full
//           refund. If you cancel your{" "}
//           <span className="span600">SpotLet Account as a Renter</span> , any
//           confirmed booking will be automatically canceled, and any refund
//           will be subject to the terms of the applicable cancellation policy.
//           SpotLet reserves the right to terminate this Agreement for
//           convenience by providing you with a{" "}
//           <span className="span600">
//             30-day notice via email to your registered email address
//           </span>{" "}
//           .
//         </Typography>
//         <Typography variant="subtitle1" gutterBottom>
//           C. Termination for Breach, Suspension, and Other Actions
//         </Typography>
//         <Typography variant="body1" gutterBottom>
//           {" "}
//           <span className="span600">
//             SpotLet may immediately terminate this Agreement
//           </span>{" "}
//           without notice if any of the following conditions are met: (i) you
//           have materially breached these Terms or our Policies, including the
//           warranties outlined in these Terms or the "User Conduct" provisions,
//           (ii) you have provided inaccurate, fraudulent, outdated, or
//           incomplete information during the SpotLet Account registration,
//           listing process, or thereafter, (iii) you have violated applicable
//           laws, regulations, or third-party rights, or (iv) SpotLet believes
//           in good faith{" "}
//           <span className="span600">that such action is reasonably</span>{" "}
//           necessary to protect the safety or property of other Users, SpotLet,
//           or third parties, for fraud prevention, risk assessment, security,
//           or investigation purposes.
//         </Typography>
//         <Typography variant="body1" gutterBottom>
//           {" "}
//           <span className="span600">
//             Additionally, SpotLet may take the following actions:
//           </span>{" "}
//           deactivate or delay Listings, reviews, or other User Content; cancel
//           any pending or confirmed bookings; limit your access to or use of
//           your SpotLet Account, the Site, or Services; temporarily or
//           permanently revoke any special status associated with your SpotLet
//           Account; or temporarily or permanently suspend your SpotLet Account,
//           if (i) you have breached these Terms or our Policies, including both
//           material and non-material breaches and receiving poor ratings from
//           Listers or Renters, or (ii) SpotLet believes in good faith that such
//           action is reasonably necessary to protect the safety or property of
//           Users, SpotLet, or third parties, for fraud prevention, risk
//           assessment, security, or investigation purposes.
//         </Typography>
//         <Typography variant="body1" gutterBottom>
//           In the case of non-material breaches,{" "}
//           <span className="span600">
//             you will receive notice from SpotLet regarding
//           </span>{" "}
//           any measures taken and an opportunity to resolve the issue to
//           SpotLet's reasonable satisfaction.
//         </Typography>
//         <Typography variant="subtitle1" gutterBottom>
//           D. Consequences
//         </Typography>
//         <Typography variant="body1" gutterBottom>
//           If any of the aforementioned measures are taken, SpotLet may: (i)
//           inform your Renters or Listers that a pending or confirmed booking
//           has been canceled, (ii) provide full refunds to your Renters for all
//           confirmed bookings, regardless of preexisting cancellation policies,
//           (iii) exceptionally assist your Renters in finding potential
//           alternative properties, and (iv) you will not be entitled to any
//           compensation for canceled confirmed bookings.
//         </Typography>
//         <Typography variant="body1" gutterBottom>
//           {" "}
//           <span className="span600">
//             Upon termination of this Agreement,
//           </span>{" "}
//           we are not obligated to delete or return any of your User Content,
//           including reviews or feedback. Following termination, you are not
//           entitled to restore your{" "}
//           <span className="span600">
//             SpotLet Account or access your User Content
//           </span>{" "}
//           . If your access to the Site and Services has been limited, your
//           SpotLet Account has been suspended, or this Agreement has been
//           terminated by us, you may not register a new SpotLet Account or
//           attempt to access and use the Site and Services through other
//           SpotLet Accounts.
//         </Typography>
//         <Typography variant="subtitle1" gutterBottom>
//           E. Survival
//         </Typography>
//         <Typography variant="body1" gutterBottom>
//           The clauses of these Terms that reasonably should survive the
//           termination of this Agreement will remain in effect.
//         </Typography>
//         <Typography variant="h3" gutterBottom>
//           13. Disclaimers:
//         </Typography>
//         <Typography variant="body1" gutterBottom>
//           {" "}
//           <span className="span700">13.1</span> By choosing to utilize the
//           Site, Services, or Collective Content, you accept full
//           responsibility for any associated risks. SpotLet acknowledges that
//           it is not obliged to conduct background checks or registered sex
//           offender checks on any user, including renters and listers. However,
//           SpotLet reserves the right to perform such checks at its sole
//           discretion. If these checks are carried out, SpotLet does not
//           provide any express or implied warranties that these checks will
//           uncover any previous misconduct by a user or ensure that a user will
//           not engage in misconduct in the future.
//         </Typography>
//         <Typography variant="body1" gutterBottom>
//           {" "}
//           <span className="span700">13.2</span> The Site, Services, and
//           Collective Content are provided "as is" without any warranty, either
//           express or implied. SpotLet explicitly disclaims any warranties of
//           merchantability, fitness for a particular purpose, quiet enjoyment,
//           non-infringement, and any warranties arising from the course of
//           dealing or usage of trade. SpotLet does not guarantee that the Site,
//           Services, or Collective Content, including listings or any
//           properties, will meet your requirements or be available without
//           interruptions, secure, or free from errors. SpotLet does not warrant
//           the quality of any listings, properties, listers, renters, the
//           accrual of SpotLet travel credits, the services, or collective
//           content, nor the accuracy, timeliness, truthfulness, completeness,
//           or reliability of any collective content obtained through the Site
//           or Services.
//         </Typography>
//         <Typography variant="body1" gutterBottom>
//           {" "}
//           <span className="span700">13.3</span> Any advice or information
//           obtained from SpotLet or through the Site, Services, or Collective
//           Content will not create any warranty not expressly stated in these
//           terms. You are solely responsible for all your communications and
//           interactions with other users of the Site or Services, including
//           listers or renters, as well as any other individuals you communicate
//           or interact with as a result of using the Site or Services. SpotLet
//           does not attempt to verify the statements of users or review any
//           properties. SpotLet makes no representations or warranties regarding
//           the conduct of users or their compatibility with other users. It is
//           your responsibility to exercise caution in all your communications
//           and interactions, especially if you decide to meet offline or in
//           person, regardless of whether SpotLet organizes such meetings.
//           SpotLet explicitly disclaims all liability for any actions or
//           omissions of renters or other third parties.
//         </Typography>
//         <Typography variant="h3" gutterBottom>
//           14. Limitation Of Liability:
//         </Typography>
//         <Typography variant="body1" gutterBottom>
//           {" "}
//           <span className="span700">14.1</span> SpotLet users are responsible
//           for their own actions and decisions when using the SpotLet Platform.
//           SpotLet is not liable for any losses or damages that may occur as a
//           result of using the SpotLet Platform, including but not limited to
//           personal injury, property damage, or financial loss.
//         </Typography>
//         <Typography variant="body1" gutterBottom>
//           {" "}
//           <span className="span700">14.2</span> By using the SpotLet Platform,
//           users agree to release and indemnify SpotLet and its affiliates from
//           any and all claims, damages, losses, expenses, costs, and
//           liabilities (including attorneys' fees) arising out of or in any way
//           connected with their use of the SpotLet Platform.
//         </Typography>
//         <Typography variant="body1" gutterBottom>
//           {" "}
//           <span className="span700">14.3</span> SpotLet does not guarantee the
//           accuracy or completeness of the information provided on the SpotLet
//           Platform. SpotLet is not liable for any errors or omissions in the
//           information provided on the SpotLet Platform.
//         </Typography>
//         <Typography variant="body1" gutterBottom>
//           {" "}
//           <span className="span700">14.4</span> Users are responsible for
//           taking all necessary precautions to protect themselves from viruses,
//           malware, and other harmful content that may be found on the SpotLet
//           Platform.
//         </Typography>
//         <Typography variant="body1" gutterBottom>
//           {" "}
//           <span className="span700">14.5</span> SpotLet is not liable for any
//           damages that may occur as a result of using the SpotLet Platform,
//           including but not limited to computer viruses, hardware failures, or
//           software malfunctions.
//         </Typography>
//         <Typography variant="body1" gutterBottom>
//           {" "}
//           <span className="span700">14.6</span> If you have any questions
//           about these terms and conditions, please contact SpotLet at
//           info@spotlet.info
//         </Typography>
//         <Typography variant="h3" gutterBottom>
//           25. Indemnification:
//         </Typography>
//         <Typography variant="body1" gutterBottom>
//           {" "}
//           <span className="span700">15.1</span> You hereby agree to indemnify,
//           defend, and hold harmless SpotLet, its affiliates, subsidiaries,
//           officers, directors, employees, and agents from any claims,
//           liabilities, damages, losses, and expenses, including reasonable
//           legal and accounting fees, arising out of or in connection with{" "}
//           <span className="span600">(a)</span> your access to or use of the
//           Site, Services, or Collective Content, or any violation of these
//           Terms; <span className="span600">(b)</span> any User Content
//           provided by you; <span className="span600">(c)</span> your
//           interaction with any other User, your booking of a Property, or your
//           creation of a Listing; <span className="span600">(d)</span> the use,
//           condition, or rental of a Property by you, including any injuries,
//           losses, or damages of any kind arising from a rental, booking, or
//           use of a Property, whether compensatory, direct, incidental,
//           consequential, or otherwise.
//         </Typography>
//         <Typography variant="h3" gutterBottom>
//           16. Entire Agreement:
//         </Typography>
//         <Typography variant="body1" gutterBottom>
//           {" "}
//           <span className="span700">16.1</span> These Terms, along with any
//           additional{" "}
//           <span className="span600">
//             SpotLet policies, guidelines, standards, or terms applicable
//           </span>{" "}
//           to specific products, features, services, or offerings, constitute
//           the complete and exclusive understanding and agreement between you
//           and SpotLet regarding the Site, Services, Collective Content
//           (excluding Payment Services), and any{" "}
//           <span className="span600">
//             bookings or Listings of Properties made
//           </span>{" "}
//           through the Site and Services (excluding Payment Services). These
//           Terms supersede and replace any prior oral or written understandings
//           or agreements between you and{" "}
//           <span className="span600">SpotLet regarding bookings</span> or
//           listings of Properties, the Site, Services, and Collective Content
//           (excluding Payment Services).
//         </Typography>
//         <Typography variant="h3" gutterBottom>
//           17. Assignment:
//         </Typography>
//         <Typography variant="body1" gutterBottom>
//           {" "}
//           <span className="span700">17.1</span> You may not assign or transfer
//           these Terms, whether by operation of law or otherwise, without the
//           prior written consent of <span className="span600">SpotLet</span> .
//           Any attempted assignment or transfer without such consent will be
//           null and void.{" "}
//           <span className="span600">
//             SpotLet may assign or transfer these
//           </span>{" "}
//           Terms at its sole discretion, without any restrictions. Subject to
//           the foregoing, these Terms will be binding upon and inure to the
//           benefit of the parties and their respective successors and permitted
//           assigns.
//         </Typography>
//         <Typography variant="h3" gutterBottom>
//           18. Notices:
//         </Typography>
//         <Typography variant="body1" gutterBottom>
//           {" "}
//           <span className="span700">18.1</span> All notices or other
//           communications permitted or required under these Terms, including
//           those related to modifications of these Terms, shall be in writing
//           and provided by SpotLet: <span className="span600">(i)</span> via
//           email to the address you provide, or{" "}
//           <span className="span600">(ii)</span> by posting on the Site. For
//           notices sent by email, the date of receipt will be deemed the date
//           on which the notice is transmitted.
//         </Typography>
//         <Typography variant="h3" gutterBottom>
//           19. Controlling Law and Jurisdiction:
//         </Typography>
//         <Typography variant="body1" gutterBottom>
//           {" "}
//           <span className="span700">19.1</span> These Terms and your use of
//           the Services will be interpreted in{" "}
//           <span className="span600">accordance with the laws of India</span> ,
//           without regard to their conflict-of-law provisions. You and SpotLet
//           agree to submit to the personal jurisdiction of the state court
//           located in Telangana state, India, for any actions in which the
//           parties retain the right to seek injunctive or other equitable
//           relief to prevent the actual or{" "}
//           <span className="span600">
//             threatened infringement, misappropriation
//           </span>{" "}
//           , or violation of a party's copyrights, trademarks, trade{" "}
//           <span className="span600">secrets, patents,</span> or other
//           intellectual property rights, as stated in the Dispute Resolution
//           provision below.
//         </Typography>
//         <Typography variant="h3" gutterBottom>
//           20. Dispute Resolution:
//         </Typography>
//         <Typography variant="body1" gutterBottom>
//           {" "}
//           <span className="span700">20.1</span> If you reside in India, you
//           and <span className="span600">SpotLet agree that any dispute</span>{" "}
//           , claim, or controversy arising out of or relating to these Terms or
//           their breach, termination, enforcement, interpretation, or validity,
//           or the use of the Services or the Site or Collective Content{" "}
//           <span className="span600">
//             (collectively referred to as "Disputes")
//           </span>{" "}
//           , will be resolved through binding arbitration, except that each
//           party retains the right to seek injunctive or other equitable relief
//           in a court of competent jurisdiction to prevent the actual or
//           threatened infringement,{" "}
//           <span className="span600">
//             misappropriation, or violation of a party's
//           </span>{" "}
//           copyrights, trademarks, trade secrets, patents, or other
//           intellectual property rights. Additionally, unless mutually agreed
//           otherwise in writing, the arbitrator may not consolidate more than
//           one person's claims or preside over any form of class or
//           representative proceeding. If this paragraph is deemed
//           unenforceable, the entire "Dispute Resolution" section will be
//           considered void, except for the preceding sentence, which will
//           survive any termination of these Terms.
//         </Typography>
//         <Typography variant="body1" gutterBottom>
//           {" "}
//           <span className="span700">20.2</span> If mediation fails, the
//           Parties shall resort to arbitration for effective and binding
//           dispute resolution. The arbitrators shall be chosen jointly within
//           two months.
//         </Typography>
//         <Typography variant="body1" gutterBottom>
//           {" "}
//           <span className="span700">20.3</span> The arbitration proceedings
//           shall be conducted in English.
//         </Typography>
//         <Typography variant="body1" gutterBottom>
//           {" "}
//           <span className="span700">20.4</span> The arbitration shall be held
//           in the court of Telangana, India unless otherwise specified in the
//           award..
//         </Typography>
//         <Typography variant="body1" gutterBottom>
//           {" "}
//           <span className="span700">20.5</span> Unless otherwise specified in
//           the award, both parties shall bear the costs of the arbitration,
//           including the fees of the arbitrators, equally.
//         </Typography>
//         <Typography variant="body1" gutterBottom>
//           {" "}
//           <span className="span700">20.6</span> Unless otherwise specified in
//           the award, both parties shall bear the costs of the arbitration,
//           including the fees of the arbitrators, equally.
//         </Typography>
//         <Typography variant="body1" gutterBottom>
//           {" "}
//           <span className="span700">20.7</span> The awards and proceedings
//           shall be carried out in accordance with the provisions of the
//           applicable law.
//         </Typography>
//         <Typography variant="body1" gutterBottom>
//           {" "}
//           <span className="span700">20.8</span> The award issued jointly by
//           the arbitrators shall be considered final.
//         </Typography>
//         <Typography variant="h3" gutterBottom>
//           21. Dispute Resolution:
//         </Typography>
//         <Typography variant="body1" gutterBottom>
//           {" "}
//           <span className="span700">21.1</span> You acknowledge your
//           responsibility to take all necessary health, safety, and{" "}
//           <span className="span600">
//             hygiene measures to protect yourself
//           </span>{" "}
//           , Listers, and Renters from potential infectious diseases or any
//           other unhealthy, unsafe, or unsanitary conditions. You also
//           acknowledge that you have read and will comply with SpotLet's
//           COVID-19 Health & Safety Measures ("Safety Measures"). SpotLet
//           reserves the right to update these Safety Measures periodically at
//           its discretion, so please review them frequently, including the
//           current version at the time of your Booking.
//         </Typography>
//         <Typography variant="h3" gutterBottom>
//           22. General:
//         </Typography>
//         <Typography variant="body1" gutterBottom>
//           {" "}
//           <span className="span700">22.1</span> The failure of{" "}
//           <span className="span600">
//             SpotLet to enforce any right or provision
//           </span>{" "}
//           in these Terms will not constitute a waiver of future enforcement.
//           Any waiver of a right or provision must be in writing and signed by
//           an authorized representative of{" "}
//           <span className="span600">SpotLet. The exercise of any</span>{" "}
//           remedies by either party under these Terms will not prejudice their
//           other remedies under these Terms or otherwise. If any provision of
//           these Terms is found invalid or unenforceable by an arbitrator or
//           court of competent jurisdiction, that provision will be enforced to
//           the maximum extent permitted, and the other provisions will remain
//           in full force and effect.
//         </Typography>
//         <Typography variant="h3" gutterBottom>
//           23. Third-Party Benefictary:
//         </Typography>
//         <Typography variant="body1" gutterBottom>
//           {" "}
//           <span className="span700">23.1</span> These Terms do not grant
//           rights or remedies to any person or entity other than the parties
//           involved.
//         </Typography>
//         <Typography variant="h3" gutterBottom>
//           24. Contacting Spotlet:
//         </Typography>
//         <Typography variant="body1" gutterBottom>
//           {" "}
//           <span className="span700">24.1</span> If you have any questions
//           about these Terms, please contact SpotLet
//         </Typography>
//         <Typography variant="h3" gutterBottom>
//           25. Feedback:
//         </Typography>
//         <Typography variant="body1" gutterBottom>
//           {" "}
//           <span className="span700">25.1</span> SpotLet welcomes and
//           encourages your feedback, comments, and{" "}
//           <span className="span600">suggestions for improving</span> the Site
//           and Services ("Feedback"). You may submit Feedback via email,
//           through the{" "}
//           <span className="span600">"Contact" section of the Site,</span> or
//           through other communication channels. You acknowledge and agree that
//           all Feedback you provide becomes the sole and exclusive property of
//           SpotLet. You hereby assign and agree to assign all rights, title,
//           and interest in the Feedback, including worldwide{" "}
//           <span className="span600">
//             patent, copyright, trade secret, moral,
//           </span>{" "}
//           and other proprietary or intellectual property rights, to SpotLet.
//           You also waive any moral rights you may have in such Feedback. If
//           requested by <span className="span600">SpotLet</span>, you will
//           assist in acquiring, perfecting, and maintaining SpotLet's
//           intellectual property rights and other legal protections for the
//           Feedback, at SpotLet's expense.
//         </Typography> */}
//       </Box>
//     </Box>
//     <Footer />
//   </>
// );
