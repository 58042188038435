import { useMemo, useState, useEffect } from "react";
import Table from "./Table";
import ResponsiveTable from "./responsiveTable";
import { useLocationStore } from "../store/location";
import { useAuthStore } from "../store/auth";
import { useNavigate } from "react-router-dom";
import { Button } from "react-bootstrap";
import dayjs from "dayjs";
import { getUserLocations, updateSubscriptionPlan, subscription, getUserListings } from "../apis/api";
import { ConfirmationModel } from "./Modal/ConfirmationModel";
import { PageLoader } from "./PageLoader";

export const ListingTable = () => {
  const navigate = useNavigate();
  const { userProfile } = useAuthStore();
  const {  setPlan, setPaymnetId } = useLocationStore();
  const [locationId, setLocationId] = useState('')
  const [showConfirmationModel, setShowConfirmationModel] = useState(false);
  const [confirmationTitle, setConfirmationTitle] = useState("");
  const [confirmationBody, setConfirmationBody] = useState("");
  const [listedLocations, setListedLocations] = useState([]);
  const [loader, setLoader] = useState(false)
  const [init, setInit] = useState(false)
  const COLUMNS = ['Location Id','Location Name','Status','Created Date','Last Updated Date','Plan','Action']
  const [rows,setRows] = useState([])

  const handleTableData = (data) => {
    Promise.all(data?.map((item)=>{
      return {
        locationId : item?.location_id,
        locationName : item?.basic_info?.property_name,
        status : item?.status,
        createdAt : dayjs(item?.createdAt).format("DD/MM/YYYY"),
        updatedAt : dayjs(item?.updatedAt).format("DD/MM/YYYY"),
        plan : item?.subscription_id,
        action : 'dashboard'
      }
    })).then((values)=> setRows(values))
  }

  useEffect(() => {
    (async () => {
      if (userProfile) {
        setLoader(true)
        try {
          const resp = await getUserListings(userProfile._id);
          setListedLocations(resp?.data);
          handleTableData(resp?.data)
        } catch (error) {
          console.log(error);
        }
        setLoader(false)
        setInit(true)
      }
    })();
  }, [userProfile]);

  const handleOK = () => {
    setShowConfirmationModel(false)
    if (locationId) {
      navigate("/list-your-space",{ state: {locationId,type : 'listing'}});
    }
  }

  const editLocation = async (location) => {
    setLocationId(location?.location_id)
    setConfirmationTitle('Edit listing')
    setConfirmationBody('Updated changes will reflect after you submit and spotLet team approves the updated changes')
    setShowConfirmationModel(true)
  }

  const getSubscription = async (planId, planName, locationId) => {
    try {
      const res = await subscription({ id: userProfile?._id, planId, planName })
      if (res.status === 200) {
        //window.open(res.data.short_url, '_blank').focus();
        // navigate(res.data.short_url)
        if (res?.data?.id) {
          checkoutSubscription(planName, res?.data?.id, locationId);
        }

      }
    } catch (err) {
      console.log(err)
    }
  }

  function loadScript(src) {
    return new Promise((resolve) => {
      const script = document.createElement('script');
      script.src = src;
      script.onload = () => {
        resolve(true);
      };
      script.onerror = () => {
        resolve(false);
      };
      document.body.appendChild(script);
    });
  }

  const checkoutSubscription = async (selectedPlan, subscriptionId, locationId) => {
    const res = await loadScript(
      'https://checkout.razorpay.com/v1/checkout.js'
    );

    if (!res) {
      alert('Razorpay SDK failed to load. Are you online?');
      return;
    }

    var subOptions = {
      "key": "rzp_test_gotWBtdd6rUaTo",
      "subscription_id": subscriptionId,
      "name": "My Billing Label",
      "description": "Auth txn for " + subscriptionId,
      "handler": async (response) => {
        setPaymnetId(response.razorpay_payment_id);
        setPlan(selectedPlan)
        try {
          await updateSubscriptionPlan({ plan: selectedPlan, paymentId: response.razorpay_payment_id, locationId: locationId })
          navigate('/listed-properties')
        } catch (e) {
          console.log(e);
        }
      }
    };

    const paymentObject = new window.Razorpay(subOptions);
    paymentObject.open();
  }

  const handleDashboard = (data) => {
    navigate("/dashboard",{ state: {locationId : data}});
  }

  const columns = useMemo(
    () => [
      {
        Header: "Location Id",
        accessor: "location_id",
      },
      {
        Header: "Location Name",
        accessor: "basic_info.property_name",
      },
      {
        Header: "Status",
        accessor: "status",
      },
      {
        Header: "Created Date",
        accessor: "createdAt",
        Cell: ({ cell: { value } }) => {
          return dayjs(value).format("DD/MM/YYYY");
        },
      },
      {
        Header: "Last Updated Date",
        accessor: "updatedAt",
        Cell: ({ cell: { value } }) => {
          return dayjs(value).format("DD/MM/YYYY");
        },
      },
      // {
      //   Header: "Booking Request",
      //   accessor: "",
      //   Cell: ({ row: { original } }) =>
      //     original?.bookedDates ? original?.bookedDates?.length + " Requests" : "0 Requests",
      // },
      {
        Header: "Plan",
        accessor: "",
        Cell: ({ row: { original } }) =>
          original.subscription_id.charAt(0).toUpperCase() + original.subscription_id.slice(1),
      },
      {
        Header: "Action",
        accessor: "",
        Cell: ({ row: { original } }) => {
          return(
            <Button variant="outline-danger" onClick={() => handleDashboard(original)}>
                Dashboard
              </Button>
          )
          // if (original?.subscription_id === 'Premium') {
          //   return (
          //     <Button variant="danger" className="btn btn-theme btn-detail" onClick={() => editLocation(original)}>
          //       Update
          //     </Button>
          //   )
          // } else if (original?.subscription_id === 'Standard') {
          //   return (
          //     // className="btn btn-theme btn-detail"
          //     <div className="d-flex justify-content-between">
          //       <Button variant="danger" className="btn btn-theme btn-detail" onClick={() => editLocation(original)}>
          //         Update
          //       </Button>
          //       <Button variant="primary" onClick={() => getSubscription("plan_McgIyGmBSbSgd6", 'Premium', original?.location_id)}>
          //         Upgrade
          //       </Button>
          //     </div>
          //   )
          // } else {
          //   return (
          //     // className="btn btn-theme btn-detail"
          //     <div className="d-flex justify-content-between">
          //       {/* <Button variant="success" onClick={() => getSubscription("plan_McgHlhqYY4mARQ", "standard", original?.location_id)}>
          //         Standard
          //       </Button> */}
          //       <Button variant="primary" onClick={() => getSubscription("plan_McgIyGmBSbSgd6", 'Premium', original?.location_id)}>
          //         Upgrade
          //       </Button>
          //     </div>
          //   )
          // }
        },
      },
    ],
    []
  );

  return (
    <>
      {/* <Table data={listedLocations || []} columns={columns} /> */}
      <>
      {init && (
        <>
          {listedLocations?.length === 0 ? (
          <div className="d-flex flex-column justify-content-center align-items-center">
            <span className="SpotLetSubHead">No Listings Found</span>
          </div>
        ) : (
          <>
            <ResponsiveTable rows={rows}  columns={COLUMNS} handleDashboard={handleDashboard}/>
            {showConfirmationModel && (
              <ConfirmationModel
                show={showConfirmationModel}
                handleClose={() => setShowConfirmationModel(false)}
                handleOk={handleOK}
                title={confirmationTitle}
                body={confirmationBody}
              />
            )}
          </>
        )}
        </>
      )}
      </>
    {loader && <PageLoader />}
    </>
  )
};
