import { useState,useEffect} from "react";
import SubTypeOfProperty from "./SubTypeOfProperty";
import SubBasicInfo from "./SubBasicInfo";
import SubAmenities from "./SubAmenities";
import SubPropertyFeatures from "./SubPropertyFeatures";
import SubPricingAndTimings from "./SubPricingAndTimings";
import SubPropertyGallery from "./SubPropertyGallery";

import info from "../../../../assets/NewImages/BecomeAHost/i.svg";

const SubProperty = ({data,propertyId,handleBack, handleSubPopertySave, handleSubmit}) => {

    const [formData, setFormData] = useState(false)
    const [showAddNewSubButton, setShowAddNewSubButton] = useState(false)
    const [subPropertyData, setSubPropertyData] = useState(null)
    const [updateIndex,setUpdateIndex] = useState(null)
    const [isEditing, setIsEditing] = useState(false)
    const [step,setStep] = useState(0);
    const onClickSubmit = (e) => {
        e.preventDefault();
        handleSubmit()
    }
    const onClickEachSave = (data) => {
        setSubPropertyData({
            ...subPropertyData,
            ...data, 
        })
        setStep((prev) => prev+1);
    }
    const onClickBack = () => {
        setStep((prev) => prev-1);
    }
    const onClickDiscard = () => {
        setUpdateIndex(null);
        setIsEditing(false);
        setStep(0);
        setShowAddNewSubButton(true)
    }
    const onClickAddSubProperty = (data) => {
        // let editedfileIndex;
        // formData.map((file,i) => {
        //     if(file.basic_info?.property_name === subPropertyData?.basic_info?.property_name){
        //         editedfileIndex = i
        //         return null
        //     }
        // })
        if(updateIndex || updateIndex === 0){
            let temp = {
                ...subPropertyData,
                // [name] : data,
                ...data 
            }
            formData.splice(updateIndex,1,temp)
            setFormData([...formData])
            handleSubPopertySave(formData)
            setUpdateIndex(null)
        }else{
            setFormData([{
                ...subPropertyData,
                // [name] : data,
                ...data 
            }])
            handleSubPopertySave([
                ...formData,
                {
                    ...subPropertyData,
                    // [name] : data,
                    ...data 
                }
            ])
        }
        setSubPropertyData(null)
        setShowAddNewSubButton(true);
        setIsEditing(false)
        setStep(0);
    }
    const eachSubProperty = () => {
        return (
            <>
                {
                    step === 1 &&
                    <div className="tabCard  col-12 col-md11 col-lg-12 col-xl-9 mx-5" >
                        <SubTypeOfProperty 
                        data = {subPropertyData?.location_type}  
                        getSaved = {onClickEachSave} 
                        getBack = {onClickDiscard}
                        />
                    </div>
                }
                {
                    subPropertyData?.location_type &&  step === 2 &&
                    <div className="tabCard  col-12 col-md11 col-lg-12 col-xl-9 mx-5" >
                        <SubBasicInfo 
                        data = {subPropertyData} 
                        getSaved = {onClickEachSave}  
                        getBack = {onClickBack}
                        />
                    </div>
                }
                {
                    subPropertyData?.basic_info &&  step === 3 &&
                    <div className="tabCard  col-12 col-md11 col-lg-12 col-xl-9 mx-5" >
                        <SubAmenities 
                        data = {subPropertyData?.amenities} 
                        getSaved = {onClickEachSave}  
                        getBack = {onClickBack}
                        />
                    </div>
                }

                {
                    subPropertyData?.amenities &&  step === 4 &&
                    <div className="tabCard  col-12 col-md11 col-lg-12 col-xl-9 mx-5" >
                        <SubPropertyFeatures 
                        data = {subPropertyData}  
                        getSaved = {onClickEachSave}  
                        getBack = {onClickBack}
                        />
                    </div>
                }
                {
                    subPropertyData?.features && step === 5 &&
                    <div className="tabCard  col-12 col-md11 col-lg-12 col-xl-9 mx-5" >
                        <SubPricingAndTimings 
                            data = {subPropertyData?.pricing}  
                            getSaved = {onClickEachSave}  
                            getBack = {onClickBack}
                        />
                    </div>
                }
                {
                    subPropertyData?.pricing && step === 6 &&
                    <div className="tabCard  col-12 col-md11 col-lg-12 col-xl-9 mx-5" >
                        <SubPropertyGallery 
                        data = {subPropertyData} 
                        getSaved = {onClickAddSubProperty}  
                        propertyId = {propertyId}
                        getBack = {onClickBack}
                        />
                    </div>
                }
        </> 
        )
    }
    const editSubProperty = (data) => {
        setSubPropertyData(data)
        setShowAddNewSubButton(false)
    }
    const deleteSub = (i) => {
            formData.splice(i,1)
            setFormData([...formData])
            if(formData?.length === 0){
                setShowAddNewSubButton(true)
            }
            setSubPropertyData(null);
    }
    useEffect(() => {
        if(data){
            setShowAddNewSubButton(true)
            setFormData(data)
        }
    },[data])
    return(
        <>
        <div className="locationtype-page container-fluid " >
        <h1 className="locationtype-head" > Sub Properties{' '}
                <a
                    href="#"
                    data-bs-toggle="tooltip"
                    title="Summarize sub-properties or unique aspects of your space"
                >
                    <img src={info} />
                </a>
            </h1>
        </div>
        {/* Sub Properties Section Start  */}
        <div  className="col-12"   >
            <div className="tabCard mb-0 mx-5 d-flex justify-content-between flex-wrap align-items-center col-12 col-md-10 col-lg-9">
                <label
                    htmlFor= "subproperty_question"
                    className="form-label"
                    >
                    Do You have Sub Property ?<span className="required">*</span>
                </label>
                <div className="d-flex">
                    <div className="form-check me-3">
                        <input
                        required = {formData === "" ? true : false }
                        disabled
                        type="checkbox"
                        className="form-check-input"
                        id="subproperty_yes"
                        name="subproperty"
                        onChange={() =>{
                            setFormData([]);
                            setSubPropertyData({});
                            setStep((prev) => prev+1);
                            }
                        }
                        checked={formData !== false && formData !== "" }
                        />
                        <label
                        className="form-check-label"
                        htmlFor="subproperty_yes"
                        >
                        Yes
                        </label>
                    </div>
                    <div className="form-check">
                        <input
                        required = {formData === "" ? true : false }
                        // value = {each?.value}
                        type="checkbox"
                        className="form-check-input"
                        name="subproperty"
                        id="subproperty_no"
                        onChange={() => {
                            setFormData(false);
                            setSubPropertyData(null);
                            handleSubPopertySave(false)
                            setShowAddNewSubButton(false)
                            setStep(0);
                        } }
                        checked={formData === false}
                        />
                        <label
                        className="form-check-label"
                        htmlFor="subproperty_no"
                        >
                        No
                        </label>
                    </div>
                </div>
            </div>

            {
                formData.length > 0 && 
                formData?.map((eachSub,i) => {
                    return(
                        <div className="tabCard mb-0 mx-5 d-flex justify-content-between flex-wrap align-items-center col-12 col-md-10 col-lg-9 " >
                            <p className="m-0" > {eachSub?.basic_info?.property_name} </p>
                            <div>
                            { isEditing === false && updateIndex !== i &&
                                <button
                                className="btn btn-outline-danger mx-2"
                                style={{height :"35px"}}
                                onClick = {() =>{ 
                                    editSubProperty(eachSub)
                                    setUpdateIndex(i)
                                    setIsEditing(true)
                                    setStep((prev) => prev+1);
                                } }
                            > Edit </button>  }
                            { isEditing === true && updateIndex === i && 
                                <button
                                    className="btn btn-outline-danger mx-2"
                                    style={{height :"35px"}}
                                    onClick = {onClickDiscard}
                                > Discard Edit </button>
                            }
                            <button
                                className="btn btn-outline-danger"
                                style={{height :"35px"}}
                                onClick = {() => deleteSub(i) }
                            > Delete </button>
                            </div>
                        </div>
                    )
                })
            }

            {
                subPropertyData &&  eachSubProperty()
            }

            {
                showAddNewSubButton && 
                <div>
                    <button
                    type="button"
                    className="btn btn-primary mx-5 mt-2 mb-3"
                    onClick={() =>{
                        setShowAddNewSubButton(false);
                        setSubPropertyData({})
                        setStep((prevStep) => prevStep + 1);
                    }}
                    >
                        Add New
                    </button>
                </div>
            }

            {/* Footer Start  */}
            {/* <div className="col-lg-8 dashboardPopretySaveContainer mt-2">
            <div className="d-flex flex-row justify-content-between align-center">
                <button
                type="button"
                className="btn btn-secondary"
                onClick={handleBack}
                >
                    Back
                </button>
            </div>
            <div className="d-flex flex-row justify-content-between align-center">
                <button
                type="button"
                className="btn btn-primary"
                onClick={onClickSubmit}
                disabled = {( (formData && formData.length < 1) || (isEditing)  ? true : false) }
                >
                   Submit
                </button>
            </div>
        </div> */}
            {/* Footer End  */}
             {/* Footer Start */}
             <div className="row controlContainer">
                    <div className="col-lg-8">
                    {/* <div className=""> */}
                        <div className="d-flex flex-row justify-content-between align-items-center">
                            <div>
                                <button
                               type="button"
                               className="btn btn-secondary"
                               onClick={handleBack}
                                >
                                    Back
                                </button>
                            </div>
                            <div>
                                <button
                                 type="button"
                                 className="btn btn-primary"
                                 onClick={onClickSubmit}
                                 disabled = {( (formData && formData.length < 1) || (isEditing)  ? true : false) }
                                >
                                    Save & Continue
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            {/* Footer End */}
        </div>
        {/* Sub Properties Sec End  */}
        </>
    )
}
export default SubProperty;