import { useState,useEffect } from "react";
import { updateListing, getListing } from "../../../apis/api";
import { ConfirmationModel } from "../../../components/Modal/ConfirmationModel";

//Components
import TypeOfProperty from "../PropertyComponents/typeOfProperty";
import Amenities from "../PropertyComponents/Amenities";
import BasicInfo from "../PropertyComponents/BasicInfo";
import Gallery from "../PropertyComponents/Gallery";
import Location from "../PropertyComponents/Location";
import Manager from "../PropertyComponents/Manager";
// import Policies from "../PropertyComponents/Policies";
import Policies from "../PropertyComponents/NewPolicies";
import Activities from "../PropertyComponents/Activities"
import Spaces from "../PropertyComponents/Spaces";
import SubProperty from "../PropertyComponents/SubProperties/SubProperty";
import Pricing from "../PropertyComponents/Pricing";

const PROPERTY_TYPES = ["Cafe and Mart", "Retail Spaces", "Convention Centers", "College", "School", "University", "Castles", "Forts", "Heritage Building", "Museum", "Hotels", "Resort", "Factory", "Manufacturing Facility", "Condominiums", "Gated Communities", "Sports Complex", "Art studios", "Fashion photography", "Film Studio", "Photo Studio"]

const Property = ({id,activeTab,setActiveTab,completeUpdate,subActive}) => {
    const [showConfirmationModel, setShowConfirmationModel] = useState(false);
    const [confirmationBody, setConfirmationBody] = useState("");
    const [data,setData] = useState();

    const getData = async () => {
        try{
            const resp = await getListing(id)
            if(resp?.data?.sub_properties || PROPERTY_TYPES?.includes(resp?.data?.location_type) ){
                subActive(true)
            }
            setData(resp?.data)
        }catch(error){
            console.log(error)
        }
    }

    useEffect(()=> {
        setActiveTab(0)
        getData()
    },[])

    const handleNext = async (updatedData) => {
        try{
            setData({...data,...updatedData})
            await updateListing({...data,...updatedData},false)
            setActiveTab(activeTab + 1)
        }catch(error){
            console.log(error)
        }
    }
    const handleSubmit = async (data) => {
        try{
            await updateListing(data,true)
            setActiveTab(0)
            setShowConfirmationModel(true)
            setConfirmationBody("Your Updated Listing has been submitted, Our team will review and update you the status")
        }catch(error){
            console.log(error)
        }
    }
    const handleBack = () => {
        setActiveTab(activeTab-1)
    }
    const handleSubPopertySave = (subData) => {
        setData({
          ...data,
          sub_properties : subData ? [...subData] : false,
        })
      }

      const getPricingData = (data) => {
        const reqData = {
          film : data?.film?.available,
          tv : data?.tv?.available,
          event : data?.event?.available,
          stay : data?.stay?.available,
        }
        return reqData
      }
      
    return(
        <div className="dashboardPropertyContainer">
            {activeTab === 0 && <TypeOfProperty data={data?.location_type} handleNext={(value)=>setActiveTab(value)}/>}
            {activeTab === 1 && <BasicInfo data={data?.basic_info} handleNext={handleNext} handleBack={handleBack}/>}
            {activeTab === 2 && <Location data={data?.address} handleNext={handleNext} handleBack={handleBack}/>}
            {activeTab === 3 && <Amenities data={data?.amenities} handleNext={handleNext} handleBack={handleBack}/>}
            {activeTab === 4 && <Spaces data={data} handleNext={handleNext} handleBack={handleBack}/>}
            {activeTab === 5 && <Pricing policiesData = {data?.policies}  activitiesData = {data?.tags} data={data?.pricing} handleNext={handleNext} handleBack={handleBack}/>}
            {activeTab === 6 &&  data && <Gallery data={data} handleNext={handleNext} handleBack={handleBack}/>}
            {activeTab === 7 && <Policies pricingData = {getPricingData(data?.pricing)} data={data?.policies} handleNext={handleNext} handleBack={handleBack}/>}
            {activeTab === 8 && <Activities pricingData = {getPricingData(data?.pricing)} data={data?.tags} handleNext={handleNext} handleBack={handleBack} propertyId={data?.location_id}/>}
            {activeTab === 9 && <Manager data={data?.property_manager_details} handleNext={handleNext} handleBack={handleBack} handleSubmit={(payload) =>  handleSubmit({ ...data,...payload})} isSubPropertyActive={(data?.sub_properties && data?.subscription_id !== "Basic") ? true : false}/>}
            {(activeTab === 10 && ( (data?.sub_properties || PROPERTY_TYPES?.includes(data?.location_type) ) && data?.subscription_id !== "Basic") ) && <SubProperty data={data?.sub_properties} propertyId = {data?.location_id} handleBack={handleBack} handleSubPopertySave = {handleSubPopertySave} handleSubmit={() =>  handleSubmit(data)}/>}
            {showConfirmationModel && 
                <ConfirmationModel
                show={showConfirmationModel}
                handleClose={() => {
                    setShowConfirmationModel(false)
                    completeUpdate()
                }}
                handleOk={() => {
                    setShowConfirmationModel(false)
                    completeUpdate()
                }}
                title="Listing"
                body={confirmationBody}
                />
            }
        </div>
    )
}
export default Property;