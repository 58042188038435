import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';

export function RoomSpacesModal({show,onHide,title,body}) {
  return (
    <Modal
    show={show}
    onHide={onHide}
    keyboard={false}
    size="lg"
    aria-labelledby="contained-modal-title-vcenter"
    centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter SpotLetMainHead">
          {title}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className='spacesModalContainer'>
            {body?.data?.map((item,i) => {
                return(
                    <div key={i} className='spacesModalSingleContainer'>
                        <img src={body?.images?.[item?.name][0]?.image} alt={i} className='spacesModalImage'/>
                        <div className='spacesModalItemContainer'>
                            <span className='spaceModelItemName SpotLetSubHead'>{item?.name}</span>
                            <span className='spaceModelItemText SpotLetContent '>Length : {item?.length || 'NA'} ft</span>
                            <span className='spaceModelItemText SpotLetContent '>Width : {item?.width || 'NA'} ft</span>
                            <span className='spaceModelItemText SpotLetContent '>Height : {item?.height || 'NA'} ft</span>
                            {item?.description && <span className='spaceModelItemText SpotLetContent '>{item?.description}</span>}
                        </div>
                    </div>
                )
            })}
        </div>
      </Modal.Body>
    </Modal>
  );
}