import amenityArrow from '../../assets/images/amenityArrow.svg'

const SubPropertyAmenities = ({image,data, defaultAmenities}) => {

    return(
        <div className="amenitiesMainContainer" style={{padding:"15px 0px 15px 0px"}} >
            <div className='amenitiesTextConatainer' style={{width: "95%"}}  >
                <span className='amenitiesHeading SpotLetSubHead' style={{alignSelf:"center"}} > All Amenities</span>
                <>
                    {/* { data?.length > 0 && */}
                        <div className='row w-100'>
                            {data?.map((amenity,i) => {
                                return(
                                    <div className='col-lg-4 col-md-4 mb-2'>
                                        <img src={amenityArrow} alt='amenityArrow'/>
                                        <span className='amenityListText SpotLetContent'>{amenity?.name}</span>
                                        {amenity?.sub && (
                                            <ul>
                                                {amenity?.sub?.map((sub)=> (
                                                    <li>{sub.value}</li>
                                                ))}
                                            </ul>
                                        )}
                                    </div>
                                )
                            })}
                            { defaultAmenities?.length > 0 && 
                                defaultAmenities?.map((amenity,i) => {
                                    return(
                                        <div className='col-lg-4 col-md-4 mb-2'>
                                            {/* <img src={amenityArrow} alt='amenityArrow'/>   */}
                                            <span className='amenityListText defaultAmenityListText SpotLetContent' style={{marginLeft:"21px"}} >{amenity?.name}</span>
                                            {/* {amenity?.sub && (
                                                <ul>
                                                    {amenity?.sub?.map((sub)=> (
                                                        <li>{sub.value}</li>
                                                    ))}
                                                </ul>
                                            )} */}
                                        </div>
                                    )
                            })}
                    </div>
                    {/* } */}
                </>
            </div>
        </div>
    )
}
export default SubPropertyAmenities;