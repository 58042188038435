import { Header } from "../components/NewHeader/Header";
import { Sidebar } from "../components/Sidebar";
import { useState } from "react";
import { BookingsTable } from "../components/BookingsTable";
// import { BookingsRequestsTable } from "../components/BookingsRequestsTable";

// Images
import bookings from "../assets/NewImages/BookingList/booking.svg";
import wBooking from "../assets/NewImages/BookingList/w-booking.svg";
import listings from "../assets/NewImages/BookingList/listing.svg";
import wListings from "../assets/NewImages/BookingList/w-listing.svg";

export const BookingsList = () => {
  const [key, setKey] = useState("bookings");

  return (
    <>
      <Header />
      <div className="container-fluid p-0">
        <div className="row g-0">
          <div className="d-flex edit-profile my-list">
            <div className="col-lg-2 d-none d-lg-block w-200">
              <Sidebar />
            </div>
            <div className="col-lg-10  staticPageHtml">
              <form className="h-100 needs-validation" noValidate>
                <div className="step-form h-100">
                  <div className="e-profile">
                    <h3 className="etitle">My Bookings</h3>
                    <div className="creative-space m-0">
                      {/* <div class="row">
                        <div class="col-md-7">
                          <ul class="nav nav-pills" id="pills-tab" role="tablist">
                            <li class="nav-item" role="presentation">
                              <button class="nav-link first active" id="pills-booking-tab" data-bs-toggle="pill" data-bs-target="#pills-booking" type="button" role="tab" aria-controls="pills-booking" aria-selected="true"
                                onClick={() => setKey("bookings")}>
                                <img class="reg" src={bookings} />
                                <img class="click" src={wBooking} />
                                My Bookings
                              </button>
                            </li>
                            <li class="nav-item" role="presentation">
                              <button class="nav-link" id="pills-listing-tab" onClick={() => setKey("listings")} data-bs-toggle="pill" data-bs-target="#pills-listing" type="button" role="tab" aria-controls="pills-listing" aria-selected="false">
                                <img class="reg" src={listings} />
                                <img class="click" src={wListings} />
                                Bookings Requests
                              </button>
                            </li>
                          </ul>
                        </div>
                      </div> */}

                      <div class="tab-content" id="pills-tabContent">
                        <div class="tab-pane fade active show" id="pills-booking" role="tabpanel" aria-labelledby="pills-booking-tab">
                          <BookingsTable />
                        </div>
                        {/* <div class="tab-pane fade" id="pills-listing" role="tabpanel" aria-labelledby="pills-listing-tab">
                          <BookingsRequestsTable />
                        </div> */}

                      </div>


                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
