import { Modal } from "react-bootstrap";
import { useState } from "react";

const { Header, Title, Body } = Modal;

export const ClaimPropertyModal = ({
    handleClose,
    handleOk,
    locationId,
    show,
}) => {

    const [formData, setFormData] = useState({
        locationId ,
        firstName: "",
        lastName : "",
        email: "",
        mobileNumber: "",
        relationship: "",
        additionalInfo: "",
    })

    const onChange = (e) => {
        const { name, value } = e.target
        setFormData({ ...formData, [name]: value })
    }

    const submitForm = async(e) => {
        e.preventDefault()
        handleOk(formData)
    }

    return (
        <>
            <Modal
                show={show}
                onHide={handleClose}
                backdrop="static"
                keyboard={false}
                size="md"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Header closeButton>
                    <Title className="SpotLetMainHead">Claim Property</Title>
                </Header>
                <Body className="verify-email-modal-body">
                    <form onSubmit={submitForm} style={{ overflowY: "auto", height: "60vh" }} >
                        <div
                            style={{
                                margin: "5px 5px 5px 0px",
                            }}
                        >
                            <h1 className="SpotLetSubHead"> Contact Info : </h1>
                            <div className="">
                                <div className="mb-4 d-flex flex-column">
                                    <label htmlFor="first_name" className="form-label SpotLetContent">
                                        First Name<span className="required">*</span>
                                    </label>
                                    <input
                                        type="text"
                                        required
                                        placeholder="First Name"
                                        id="first_name"
                                        name="firstName"
                                        className="textFiled SpotLetContent"
                                        value={formData?.firstName}
                                        onChange={onChange}
                                    />
                                </div>
                                <div className="mb-4 d-flex flex-column">
                                    <label htmlFor="last_name" className="form-label SpotLetContent">
                                        Last Name<span className="required">*</span>
                                    </label>
                                    <input
                                        type="text"
                                        required
                                        placeholder="Last Name"
                                        id="last_name"
                                        name="lastName"
                                        className="textFiled SpotLetContent"
                                        value={formData?.lastName}
                                        onChange={onChange}
                                    />
                                </div>
                                <div className="mb-4 d-flex flex-column">
                                    <label className="form-label SpotLetContent " htmlFor="email">
                                        Email Address<span className="required">*</span>
                                    </label>
                                    <input
                                        required
                                        className="textFiled SpotLetContent"
                                        name="email"
                                        type="email"
                                        id="email"
                                        pattern="^[^\s@]+@[^\s@]+\.[^\s@]{2,}$"
                                        title="It should be of proper email format"
                                        placeholder="Email"
                                        value={formData.email}
                                        onChange={onChange}
                                    />
                                </div>
                                <div className="mb-4 d-flex flex-column">
                                    <label className="form-label SpotLetContent " htmlFor="number">
                                        Mobile Number<span className="required">*</span>
                                    </label>
                                    <input
                                        required
                                        className="textFiled SpotLetContent"
                                        name="mobileNumber"
                                        type="tel"
                                        id="number"
                                        placeholder="Mobile Number"
                                        pattern="[6789][0-9]{9}"
                                        title="Phone number should start with 6-9 and remaing 9 digit with 0-9"
                                        value={formData.mobileNumber}
                                        onChange={onChange}
                                    />
                                </div>
                                <div className="mb-4 d-flex flex-column">
                                    <label htmlFor="relationship" className="form-label">
                                        Relationship with property <span className="required">*</span> : 
                                    </label>
                                    <select 
                                        id = "relationship"
                                        value = {formData?.relationship}
                                        required
                                        name = "relationship"
                                        className="textFiledSelect"
                                        onChange={onChange}
                                    >
                                        <option value="" selected hidden > Select Designation </option>
                                        <option value = "Property Owner"> Property Owner </option>
                                        <option value = "Agent"> Agent </option>
                                        <option value = "Manager"> Manager</option>
                                        <option value = "Housing Association"> Housing Association </option>
                                        <option value = "Tenant"> Tenant  </option>
                                    </select>
                                </div>
                                <div className="d-flex flex-column mb-4">
                                    <label
                                        htmlFor="claim_additional_info"
                                        className="form-label "
                                    >
                                        Additional Info<span className="required">*</span>
                                    </label>
                                    <textarea
                                        required
                                        className="basicInfoDescription text-area-preserved-whitespace"
                                        placeholder="Additional Info"
                                        id="claim_additional_info"
                                        rows='5'
                                        name='additionalInfo'
                                        onChange={onChange}
                                        value={formData?.additionalInfo}
                                    ></textarea>
                                </div>
                            </div>
                            
                            <div>
                                <button
                                    type="submit"
                                    className="SpotLetContent"
                                    style={{
                                        background: "#EA4335",
                                        border: "none",
                                        borderRadius: "5px",
                                        color: "#ffffff",
                                        width: "80px",
                                        height: "40px",
                                        margin: "5px",
                                    }}
                                >
                                    Submit
                                </button>
                            </div>
                        </div>
                    </form> 
                </Body>
            </Modal>
        </>
    );

}