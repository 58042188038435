import { useState,useEffect} from "react";
import Accordion from 'react-bootstrap/Accordion';
import info from "../../assets/NewImages/BecomeAHost/i.svg";
import fileUploadIcon from "../../assets/NewImages/BecomeAHost/file-upload.svg";
import { PageLoader } from "../PageLoader";
import SortableList, { SortableItem } from 'react-easy-sort'
import arrayMove from 'array-move'
import { ImageView } from '../Modal/ImageView';
import imageCompression from "browser-image-compression";
import { ConfirmationModel } from '../Modal/ConfirmationModel';
import { uploadLocationPics, deleteFiles, deleteFilesFromBucket } from "../../apis/api";
import ReactPlayer from 'react-player'
import "./ListYourSpace"

// for resize & watermark
import Resizer from "react-image-file-resizer";
import  watermark  from "watermarkjs";
import logo from "../../assets/NewImages/watermark/Spotlet_V1_Watermark4.webp"

function urltoFile(url, filename, mimeType) {
    return fetch(url)
      .then(function (res) {
        return res.arrayBuffer();
      })
      .then(function (buf) {
        return new File([buf], Date.now() + filename, { type: mimeType });
      });
  }

const FILE_TYPES = ["image/png", "image/jpeg", "image/jpg"] 

const Gallery = ({data,handleBack,handleNext}) => {
    const locationId = data?.location_id
    const [noOfFiles, setNoOfFiles] = useState(0);
    const [noOfSubFiles, setNoOfSubFiles] = useState(4);
    const [loaded, setLoaded] = useState(false);
    const [formData,setFormData] = useState({})
    const [originalImages,setOriginalImages] = useState({})
    const [imageData, setImageData] = useState();
    //model
    const [showConfirmationModel, setShowConfirmationModel] = useState(false);
    const [confirmationTitle, setConfirmationTitle] = useState("");
    const [confirmationBody, setConfirmationBody] = useState("");
    const [showImageModel, setShowImageModel] = useState(false);

    const [showAlertConfirmationModel, setShowAlertConfirmationModel] = useState(false);
    const [alertConfirmationTitle, setAlertConfirmationTitle] = useState('Gallery');
    const [alertConfirmationBody, setAlertConfirmationBody] = useState("");
    //delete
    const [deleteFileName, setDeleteFileName] = useState("");
    const [deleteFileIndex, setDeleteFileIndex] = useState("");
    const [subName,setSubName] = useState('')
    const [deleteImg, setDeleteImg] = useState(false)

    // Videos Link
    const [videoData, setVideoData] = useState();
    const [videoLink, setVideoLink] = useState("");
    const [videoLinkFormActive,setVideoLinkFormActive] = useState(true);
    const [videoLinkErrorMsg, setVideoLinkErrorMsg] = useState("");

    // for deleting the files
    const [fileUrls, setFileUrls] = useState([]);

    //adding images start
    const uplaodFileToS3Bucket = async (item,subName,type) => {
        // const imageObj = {
        //     item,
        //     imageSrc: URL.createObjectURL(item),
        //     name: item.name,
        // };
        const file = await urltoFile(
            item.imageSrc,
            item.name,
            "text/plain"
        );
        const mainImages = new FormData();
        mainImages.append("pic", file);
        mainImages.append("fileName", item.name);
        mainImages.append("locationId", locationId);
        mainImages.append("type",type);
        try {
            const response = await uploadLocationPics(mainImages);
            const uploadedImage = {
                name : item.name,
                image : response.url,
                title: "",
            }
            if(type === 'Images'){
                setFormData((prevFiles) => {
                    return {
                        ...prevFiles,
                        [subName] : [
                            ...prevFiles[subName],
                            uploadedImage
                        ]
                    }
                });
            }
            if(type === 'Original_images')
            setOriginalImages((prevFiles) => {
                return {
                    ...prevFiles,
                    [subName] : [
                        ...prevFiles[subName],
                        uploadedImage
                    ]
                }
            });
            setLoaded(false)
          } catch (e) {
            console.log(e)
          }
    }
    //Watermark start
    const addWatermark = (file, subName) => {
        const img = new Image();
        img.src = URL.createObjectURL(file);
        img.onload = () => {
            const getx = () => {
                return img.width - 650
            }
            const gety = () => {
                return img.height - 300
            }
            watermark([file, logo])
                .image(watermark.image.atPos(getx, gety))
                .then(async (watermarkedImage) => {
                const imageObj = {
                file,
                imageSource : URL.createObjectURL(file),
                imageSrc : watermarkedImage.src,
                name: file.name,
                };

                uplaodFileToS3Bucket(imageObj,subName,'Images');
                });
            };
        }
    // Watermark end

    // Resizer start 
        const imageResizer = (compressedObj, subName) => {
            return(
                Resizer.imageFileResizer(
                    compressedObj, 
                    1920,
                    1080, 
                    "JPEG",
                    100, 
                    0, 
                    (resizedImage) => {
                        addWatermark(resizedImage, subName); 
                    }, 
                    "file", 
                    1920, 
                    1080 
                )
            )
        }
    // resizer end 

    const onUploadFile = (event,subName,FeatureImage) => {
        if(FeatureImage && event.target.files.length + formData[subName]?.length > noOfSubFiles){
            setAlertConfirmationBody(`You can Upload only ${noOfSubFiles} Images`)
            setShowAlertConfirmationModel(true)
            return null
        }
        if(event.target.files.length + formData[subName]?.length > noOfFiles){
            setAlertConfirmationBody(`You can Upload only ${noOfFiles} Images`)
            setShowAlertConfirmationModel(true)
            return null
        }

        //removing dublicate uploads start
        const selectedFiles = [...event.target.files]
        const filterFiles = [];
        const uploadedFiles = [];
        formData[subName].map((file,i) => uploadedFiles.push(file.name.split('.')[0]))
        selectedFiles.map((file) => {
            if(!uploadedFiles.includes(file.name.split('.')[0]) && FILE_TYPES.includes(file.type)){
                filterFiles.push(file)
            } else if (uploadedFiles.includes(file.name.split('.')[0])) {
                event.target.value = null;
                setAlertConfirmationBody(`The image you have uploaded already exists in the gallery. Please select a different image to upload`)
                setShowAlertConfirmationModel(true)
                return null;
            } else{
                event.target.value = null;
                setAlertConfirmationBody(`Only JPEG, JPG and PNG files allowed for upload.`)
                setShowAlertConfirmationModel(true)
                return null;
            }
        })
        //removing dublicate uploads end
        if (!filterFiles) setLoaded(false); 
        if (filterFiles && filterFiles.length > 0) {
            setLoaded(true)
            const options = {
                maxSizeMB: 2,
                maxWidthOrHeight: 800,
                useWebWorker: true,
                fileType: 'image/webp',
              };
              //saving compressed & watermark images start
              filterFiles.forEach((file) => {
                if (options.maxSizeMB < file.size / 1000000) {
                    imageCompression(file, options).then(compressedObj => {
                                imageResizer(compressedObj,subName,'Images')
                    })
                }else{
                    imageResizer(file,subName,'Images')
                }
            })
            //saving compressed & watermark images end

            // saving original images start
            filterFiles.forEach((file) => {
                const imageObj = {
                    file,
                    imageSrc: URL.createObjectURL(file),
                    name: file.name,
                };
                uplaodFileToS3Bucket(imageObj,subName,'Original_images');
            })
            //saving original images end
        }
    }
    //adding images end

    //delete image section start
    const deleteImage = async (fileName, index,subName) => {
        setConfirmationTitle("Confirmation");
        setConfirmationBody("Do you want to delete the image ?");
        setShowConfirmationModel(true);
        setDeleteImg(true)
        setDeleteFileName(fileName);
        setDeleteFileIndex(index);
        setSubName(subName)
    }
    const handleConfirmOk = async () => {
        setShowConfirmationModel(false)
        if (deleteImg) {
          confirmDeleteImage(deleteFileIndex);
        }
      }
    // for saving removed url's
    const updateDeleteUrls = (fileObj) => {
        const reqUrlArray = [];
        Object.keys(fileObj)?.map((key) => {
            if (key !== "name" && key !== "title") {
                reqUrlArray.push(fileObj[key]);
            }
        });
        setFileUrls([...fileUrls, ...reqUrlArray]);
    }
    // for deleting files from the removed url's 
    const deleteFilesFromDB = async() => {
        try {
            await deleteFilesFromBucket(fileUrls);
            handleNext({images : formData,original_images : originalImages, videos : videoData ? [videoData] : [] });
        } catch (error) {
            setAlertConfirmationBody(error?.response?.data?.message || "Error while deleting images");
            setShowAlertConfirmationModel(true);
            return null
        }
    }
    const confirmDeleteImage = async (index) => {
        const fileToDelete = formData[subName][index];
        try {
        if (fileToDelete) {
            // await deleteFiles({
            // image: fileToDelete.image
            // });
            let tempData = [...formData[subName]];

            // for saving deleted url's
            const deletedFile = tempData.splice(index, 1);
            updateDeleteUrls(deletedFile[0]);
            
            setFormData((prevFiles) => {
                return {
                    ...prevFiles,
                    [subName] : tempData
                }
            });

            let tempOrgData = [...originalImages[subName]]
            tempOrgData.splice(index, 1)
            setOriginalImages((prevFiles) => {
                return{
                    ...prevFiles,
                    [subName]: tempOrgData
                }
            })
        }
        } catch (error) {
        alert(error.response.data);
        }
    };
    //delete image section end

    const onSubmit = () => {
        let error = false
        let reqFeature = ""
        for(const sub in formData){
            if(formData[sub]?.length < 1 && sub !== locationId){
                reqFeature = sub;
                error = true
                break
            }
        }
        if(formData[locationId]?.length < 5 && error){
            setAlertConfirmationBody('Please upload minimum of 5 images in property images and 2 in rooms/spaces images')
            setShowAlertConfirmationModel(true)
            return null
        }
        if(formData[locationId]?.length < 5){
            setAlertConfirmationBody('Please upload minimum 5 images in property images')
            setShowAlertConfirmationModel(true)
            return null
        }
        if (error) {
            setAlertConfirmationBody(`Please upload minimum 1 images in "${reqFeature}" images`);
            setShowAlertConfirmationModel(true);
            return null
        }

        // for deleting the images
        if (fileUrls?.length > 0) {
            deleteFilesFromDB();
        } else {
            handleNext({images : formData,original_images : originalImages, videos : videoData ? [videoData] : [] });
        }
    }

    useEffect(() => {
        if(data.images){
            setFormData({...data.images})
        }
        if(data.original_images){
            setOriginalImages({...data.original_images})
        }
        if (data?.videos?.length > 0) {
            setVideoLink(data?.videos[0]?.[locationId]);
            setVideoData({...data?.videos[0]});
            setVideoLinkFormActive(false);
        }
        const tempSubImages ={}
        const tempOrigianlSubImages ={}
        data?.features?.map((each) => {
            if(!data.images?.[each.name]){
                tempSubImages[each.name] = []
            }
            if(!data.original_images?.[each.name]){
                tempOrigianlSubImages[each.name] = []
            }
        })
        if(!data?.images?.[locationId]){
            tempSubImages[locationId] = []
        }
        if(!data?.original_images?.[locationId]){
            tempOrigianlSubImages[locationId] = []
        }
        setFormData((prevFiles) => {
            return {
                ...prevFiles,
                ...tempSubImages,
            }
        })
        setOriginalImages((prevFiles) => {
            return {
                ...prevFiles,
                ...tempOrigianlSubImages,
            }
        })
        switch (data?.subscription_id) {
            case 'Premium':
              setNoOfFiles(200);
              break;
            case 'Standard':
              setNoOfFiles(100);
              break;
            case 'Basic':
              setNoOfFiles(30);
              break;
            default:
              setNoOfFiles(30)
          }
          const element = document.getElementById(`listGallary`)
        if (element) element.scrollIntoView({behavior: "smooth",block: "start"});
    },[])
    
    //drag and drop start
    const onSortEnd = (oldIndex, newIndex) => {
        setFormData((prevData) => {
            const sortedArrey = arrayMove(prevData[locationId], oldIndex, newIndex)
            return{
                ...formData,
                [locationId] : sortedArrey
            }
        })
      }

    const onSortEndSub = (oldIndex, newIndex,name) => {
        setFormData((prevData) => {
            const sortedArrey = arrayMove(prevData[name], oldIndex, newIndex)
            return{
                ...formData,
                [name] : sortedArrey
            }
        })
      }
    //drag and drop end

    const showImage = (image) => {
        setImageData(image);
        setShowImageModel(true);
      }
      
    const onClickSaveName = (i, subName, name) => {
        const imageData = formData[subName][i]
        imageData.title= name
        formData[subName].splice(i,1,imageData)
        setFormData({
            ...formData,
        })
    }



    // for video link start
    const handleVideoLinkSubmit = async (e) => {
        e.preventDefault()
        const isValidUrl = checkUrlValidity(videoLink);
        if (isValidUrl) {
            setVideoLinkFormActive(false);
            setVideoLinkErrorMsg("");
            setVideoData({
                // ...videoData,
                [locationId] : videoLink,
            })
        } else {
            setVideoLinkErrorMsg("Invalid Url")
        }
    }

    const onChangeVideoLinkInput = (e) => {
        setVideoLink(e.target.value);
    }

    const checkUrlValidity = (url) => {
        const domainRegex = /(?:https?:\/\/)?(?:www\.)?([^\/]+)/;
        const match = url.match(domainRegex);
        if (match) {
            const domain = match[1];
            if ( (domain === "youtube.com" || domain === "vimeo.com" || domain === "youtu.be") && (url.length > 26) ) {
                // URL is valid and from youtube or vimeo
                return true;
            } else {
                // URL is not from youtube or vimeo
                return false;
            }
        } else {
            // Not a valid URL format
            return false;
        }
    };

    const onClickEdit = () => {
        setVideoData({
            [locationId] :  videoLink
        })
        setVideoLinkFormActive(true);
    }

    const onClickDelete = async() => {
            setVideoLink("")
            setVideoData()
            setVideoLinkFormActive(true);
    }

    const inputFormView = () => {
        return (
            <form onSubmit={handleVideoLinkSubmit} id='videoLinkForm'>
                <div className="d-flex flex-column mb-2">
                    <label className="form-label">Video Url{' '}(Please provide a YouTube or Vimeo video link)</label>
                    <input
                        className="textFiled col-md-6 col-lg-5"
                        placeholder="Video Link"
                        value={videoLink}
                        name="video"
                        required
                        type="text"
                        onChange={onChangeVideoLinkInput}
                    />
                    {videoLinkErrorMsg && <span className="required" > {videoLinkErrorMsg} </span> }
                </div>
                <button type="submit" className="btn btn-primary"> Submit </button>
        </form>
        )
    }

    const isValidURL = (url) => {
        try {
            new URL(url);
          return true;
        } catch (error) {
          return false;
        }
      };
      
      const sanitizeURL = (url) => {
        // Basic sanitization example (replace potentially harmful characters)
        return url.replace(/['";\\]/g, '');
      };

    const videoLinkDataView  = () => {
        let sanitizedUrl = null;
        if (videoLink || videoData?.video) {
            const urlToValidate = videoLink || videoData?.video;
            if (isValidURL(urlToValidate)) {
              sanitizedUrl = sanitizeURL(urlToValidate);
            }
          }

        return (
            <div className="" >
                <span className="videosLinkText">Video Url</span>
                <h4 className="videosLink"> {videoLink} </h4>
                <div className='videosLinkVideoBox col-lg-6' >
                        <ReactPlayer
                            url={sanitizedUrl}
                            width='100%'
                            height='100%'
                            controls
                        />
                </div>
                <div className="videosLinkButtonsContainer" >
                    <button className="btn btn-outline-secondary mx-2 "  onClick={onClickEdit} > Edit </button>
                    <button className="btn btn-outline-danger" onClick={onClickDelete} > Delete </button>
                </div>
            </div>
        )
    }
    // for video link end

    return(
        <>
        <div className="locationtype-page container-fluid " >
            <h1 className="locationtype-head" id="listGallary"> Gallery {' '}
                <a
                    href="#"
                    data-bs-toggle="tooltip"
                    title="Showcase with high-quality photos; feature main areas, rooms."
                >
                    <img src={info} />
                </a>
            </h1>
        </div>
        <div className="tabCard">
            <div className="row">
                <div className="col-lg-8">
                    <Accordion defaultActiveKey="0" className="mb-3" >
                        <Accordion.Item eventKey="0">
                            <Accordion.Header> {data?.basic_info?.property_name} Images </Accordion.Header>
                            <Accordion.Body>
                                {formData[locationId]?.length < 1 && (
                                    <div className="upload__btn-box">
                                        <label className="upload__btn">
                                        <img src={fileUploadIcon} />
                                        <h3>Upload Your jpg or png files</h3>
                                        <p>Min. 5 images</p>
                                        <p>Max. {noOfFiles} images</p>
                                        <input
                                            type="file"
                                            multiple
                                            data-max_length="20"
                                            className="upload__inputfile"
                                            id="upload__inputfile"
                                            accept='image/png, image/jpeg, image/jpg'
                                            onChange={(e) =>onUploadFile(e,locationId)}
                                        />
                                        </label>
                                    </div>
                                )}
                                    <SortableList onSortEnd={onSortEnd} className="row">
                                        {formData[locationId]?.map((file,i) => {
                                            return(
                                                <SortableItem key={i} >
                                                    <div className= {i === 0 ? "col-lg-3 col-sm-6 mb-2 gallery-profile-img-sec " : "col-lg-3 col-sm-6 mb-2 gallery-img-sec "} >
                                                        <p> { i === 0 ? "Profile image" : "  " } </p>
                                                        <div  onClick={() => showImage({ name: file.name, image: file.image })}>
                                                            <div className="img-bg listSpaceImageLoading" style={{
                                                            backgroundImage: `url(${file.image})`,
                                                            backgroundRepeat: 'no-repeat'
                                                            }}>
                                                            <div className="upload__img-close"
                                                                onClick={(e) => {
                                                                deleteImage(file.name, i,locationId);
                                                                e.stopPropagation();
                                                                }}
                                                            />
                                                            </div>
                                                        </div>
                                                        <div className="gallery-img-name-sec"  >
                                                                <input
                                                                type="text"
                                                                placeholder="Add name"
                                                                className="gallery-input"
                                                                value={formData[locationId][i]?.title}
                                                                onChange = {(e) => onClickSaveName(i, locationId, e.target.value)} 
                                                                />
                                                        </div>
                                                    </div>
                                                </SortableItem>
                                            )
                                        })}
                                        {formData[locationId]?.length > 0 && formData[locationId]?.length < noOfFiles && (
                                        <div className="col-lg-3 col-sm-6 mb-2">
                                            <label className="upload__btn_mini" style={{marginTop:"23px"}} >
                                            <img src={fileUploadIcon} />
                                            <p>Add more</p>
                                            <input
                                                type="file"
                                                multiple
                                                data-max_length="20"
                                                className="upload__inputfile"
                                                id="upload__inputfile"
                                                accept='image/png, image/jpeg, image/jpg'
                                                onChange={(e) =>onUploadFile(e,locationId)}
                                            />
                                            </label>
                                        </div>
                                        )}
                                    </SortableList>
                            </Accordion.Body>
                        </Accordion.Item>
                    </Accordion>
                    {
                        data?.features.length > 0 && 
                        data?.features.map((each) => {
                            return(
                                <Accordion defaultActiveKey="0" className="mb-3">
                                    <Accordion.Item eventKey="0">
                                        <Accordion.Header > {each?.name} Images </Accordion.Header>
                                        <Accordion.Body>
                                            {formData[each.name]?.length < 1 && (
                                                <div className="upload__btn-box">
                                                    <label className="upload__btn">
                                                    <img src={fileUploadIcon} />
                                                    <h3>Upload Your jpg or png files</h3>
                                                    <p>Min. 1 images</p>
                                                    <p>Max. {noOfSubFiles} images</p>
                                                    <input
                                                        type="file"
                                                        multiple
                                                        data-max_length="20"
                                                        className="upload__inputfile"
                                                        id="upload__inputfile"
                                                        accept='image/png, image/jpeg, image/jpg'
                                                        onChange={(e) =>onUploadFile(e,each.name,'FeatureImage')}
                                                    />
                                                    </label>
                                                </div>
                                            )}
                                            <SortableList onSortEnd={(oldIndex, newIndex) => onSortEndSub(oldIndex, newIndex,each.name)} className="row">
                                                    {formData[each.name]?.map((file,i) => {
                                                        return(
                                                            <SortableItem key={i} >
                                                                <div  className= {i === 0 ? "col-lg-3 col-sm-6 mb-2 gallery-profile-img-sec " : "col-lg-3 col-sm-6 mb-2 gallery-img-sec "} >
                                                                    <p> { i === 0 ? "Profile image" : "  " } </p>
                                                                    <div onClick={() => showImage({ name: file.name, image: file.image })}>
                                                                        <div className="img-bg  listSpaceImageLoading" style={{
                                                                        backgroundImage: `url(${file.image})`,
                                                                        backgroundRepeat: 'no-repeat'
                                                                        }}>
                                                                        <div className="upload__img-close"
                                                                            onClick={(e) => {
                                                                            deleteImage(file.name, i,each.name);
                                                                            e.stopPropagation();
                                                                            }}
                                                                        />
                                                                        </div>
                                                                    </div>
                                                                    <div className="gallery-img-name-sec"   >
                                                                        <input
                                                                        type="text"
                                                                        placeholder="Add name"
                                                                        className="gallery-input"
                                                                        value={formData[each?.name][i]?.title}
                                                                        onChange={(e) => onClickSaveName(i, each?.name, e.target.value)}
                                                                        />
                                                                    </div>
                                                                </div>
                                                            </SortableItem>
                                                        )
                                                    })}
                                                    {formData[each.name]?.length > 0 && formData[each.name]?.length < noOfSubFiles && (
                                                    <div className="col-lg-3 col-sm-6 mb-2">
                                                        <label className="upload__btn_mini" style={{marginTop:"23px"}} >
                                                        <img src={fileUploadIcon} />
                                                        <p>Add more</p>
                                                        <input
                                                            type="file"
                                                            multiple
                                                            data-max_length="20"
                                                            className="upload__inputfile"
                                                            id="upload__inputfile"
                                                            accept='image/png, image/jpeg, image/jpg'
                                                            onChange={(e) =>onUploadFile(e,each.name,'FeatureImage')}
                                                        />
                                                        </label>
                                                    </div>
                                                    )}
                                            </SortableList>
                                        </Accordion.Body>
                                    </Accordion.Item>
                            </Accordion>
                            )
                        } )
                    }
                    <Accordion defaultActiveKey="0" className="mb-3">
                        <Accordion.Item eventKey="0">
                            <Accordion.Header > Video Link </Accordion.Header>
                            <Accordion.Body>
                                    {
                                        !videoLinkFormActive && videoLinkDataView() // videoLinkFormActive &&
                                    }
                                    {
                                        videoLinkFormActive && inputFormView()
                                    }
                            </Accordion.Body>
                        </Accordion.Item>
                    </Accordion>
                </div>
            </div>
        </div>
        {/* Footer Start  */}
        <div className="row controlContainer">
            <div className="col-lg-8">
                <div className="d-flex flex-row justify-content-between align-items-center">
                <div>
                    <button
                    type="button"
                    class="btn btn-outline-danger"
                    onClick={handleBack}
                    >
                        Back
                    </button>
                </div>
                <div>
                    <button
                    type="button"
                    className="btn btn-primary"
                    onClick={onSubmit}
                    >
                    Save & Continue
                    </button>
                </div>
                </div>
            </div>
        </div>
        {/* Footer End */}
        {showConfirmationModel && (
        <ConfirmationModel
          show={showConfirmationModel}
          handleClose={() => { setShowConfirmationModel(false), setDeleteImg(false) }}
          handleOk={() => handleConfirmOk()}
          title={confirmationTitle}
          body={confirmationBody}
        />
      )}
      {showImageModel && (
        <ImageView
          show={showImageModel}
          handleClose={() => setShowImageModel(false)}
          handleOk={() => setShowImageModel(false)}
          imageData={imageData}
        />
      )}
      {showAlertConfirmationModel && (
        <ConfirmationModel
          show={showAlertConfirmationModel}
          handleClose={() => setShowAlertConfirmationModel(false)}
          handleOk={() => setShowAlertConfirmationModel(false)}
          title={alertConfirmationTitle}
          body={alertConfirmationBody}
        />
      )}
        {loaded && <PageLoader />}
    </>
    )
}

export default  Gallery