import { useState,useEffect } from "react";
import Box from "@mui/material/Box";
import Typewriter from "typewriter-effect";
import slide01 from "../../assets/NewImages/Home/film-tv-shoot2.webp";
import slide02 from "../../assets/NewImages/Home/Banner/Corporate Event Slider Image.webp";
import slide03 from "../../assets/NewImages/Home/Banner/Pool Party Banner.webp";
import slide04 from "../../assets/NewImages/Home/Banner/Homestay Banner.webp";

const LandingBanner = () => {
    const images = [slide02, slide01, slide03, slide04];
    const messages = [
        "Corporate Event Venue…",
        "Film Shooting Location…",
        "Personal Event Venue…",
        "Staycation…",
    ];
    const messages2 = [
        "Find Your Next",
        "Discover Your Next",
        "Explore Your Next",
        "Book Your Next",
    ];
    const [index, setIndex] = useState(0);
    const image = images[index];
    const message = messages[index];
    useEffect(() => {
        setTimeout(() => {
        setIndex((prev) => (prev < 3 ? prev + 1 : 0));
        }, 5000);
    }, [index]);
    return(
        <Box className="landingpage-banner">
            <div className="text-on-image-container animated fadeOut">
            <img loading="lazy" src={image} alt="background" className="home-bg-image darken" />
            <div className="message">
                {messages2[index]}
                <div className="colored">
                <Typewriter
                    options={{
                    strings: message,
                    autoStart: true,
                    delay: 100,
                    }}
                />
                </div>
            </div>
            </div>
        </Box>
    )
}
export default LandingBanner;