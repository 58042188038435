import { Navigate, useLocation } from "react-router-dom";
import { useAuthStore } from "../store/auth";
import jwt_decode from "jwt-decode";

export const ProtectedRoute = ({ children }) => {

  const { setAuthenticated, setUserProfile } = useAuthStore()
  const token = localStorage.getItem("auth");
  const location = useLocation();
  if (!token) {
    // user is not authenticated
    setUserProfile();
    return <Navigate to="/auth" replace state={{from : location}}/>;
  }

  let decoded = jwt_decode(token);
  if (Math.floor(Date.now() / 1000) > decoded?.exp) {
    console.log("Token expired");
    localStorage.removeItem("auth");
    setAuthenticated();
    return <Navigate to="/auth" replace state={{from : location}}/>;
  }

  return children;
};