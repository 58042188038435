import React, { useEffect, useState, useRef } from 'react'
import { Box, Typography, TextField } from '@mui/material';
import { createSearchParams, useNavigate } from "react-router-dom";
import moment from 'moment';
import { Pagination } from "@mui/material";

//Components
import { Header } from "../../components/NewHeader/Header"
import { Footer } from "../../components/Footer";
import PropertyInfo from "../../components/NewPropertyInfo";
import { PageLoader } from "../../components/PageLoader";
import { useAuthStore } from "../../store/auth";
import { InformationModal } from '../../components/Modal/InformationModal';

//api
import { getFavouritesList, getNewFavouritesList, createFavouriteFolder, deleteFavouriteFolder } from "../../apis/api"

import "./style.css"

// Images
import SearchIcon from "../../assets/NewImages/Favorites/search-normal.svg";
import upDownArrow from "../../assets/NewImages/Favorites/UpdownArrow.svg"
import plusIcon from "../../assets/NewImages/Favorites/plassIcon.svg"
import whiteCross from "../../assets/NewImages/Favorites/whiteCross.svg"
import FavoritesCardCover from "../../assets/NewImages/Favorites/favoritesCardCover.svg"
import OptionIcon from "../../assets/NewImages/Favorites/FavoritesCardOptionIcon.svg"


const sortByArray = [{ label: 'Location Type', value: 'locationType' }, { label: 'City', value: 'city' }] //{label : 'Review/Rating',value : 'ratings'}

export default function Favorites() {

  const navigate = useNavigate();
  const { userProfile, setUserProfile } = useAuthStore();
  const [favouritesList, setFavouriesList] = useState([]);
  const [favouritesFolderList, setFavouritesFolderList] = useState([])
  const [loader, setLoader] = useState(false);
  const [count, setCount] = useState(0);
  const [showCount, setShowCount] = useState([0, 28])
  const [compareList, setCompareList] = useState([]);
  const [favFolderName, setFavFolderName] = useState('');
  const [searchInput, setSearchInput] = useState('')
  const [sort, setSort] = useState('')
  const [noFav, setNoFav] = useState(false);
  const [sortByPopUp, setSortByPopUp] = useState(false);

  const [openCardIndex, setOpenCardIndex] = useState(-1);
  const [showErrorModal, setShowErrorModal] = useState(false);
  const [modalBody, setModalBody] = useState("");

  const toggleCardOption = (index) => {
    if (index === openCardIndex) {
      setOpenCardIndex(-1); // Close the currently open card
    } else {
      setOpenCardIndex(index); // Open the clicked card
    }
  };
  const isCardOpen = (index) => index === openCardIndex;

  const getFavourites = async () => {
    try {
      setLoader(true)
      // const resp = await getFavouritesList(userProfile?._id, sort);
      const resp = await getNewFavouritesList(userProfile?._id, sort);
      setCount(Math.ceil(resp.data.length / 28))
      setFavouriesList(resp.data);
      if (resp.data.length < 1) {
        setNoFav(true)
      }
      setLoader(false)
    } catch (error) {
      console.log(error)
      setLoader(false)
    }
  }

  useEffect(() => {
    window.scrollTo(0, 0);
    if (!userProfile) {
      setUserProfile()
    } else {
      getFavourites();
    }
  }, [userProfile]);

  const relaod = () => {
    getFavourites();
  }


  const [createListPupup, setCreateListPupUp] = useState(false)
  const [showPopup, setShowPopup] = useState(false);

  const handleDeleteFolder = async (index, name) => {
    toggleCardOption(index);
    try {
      const resp = await deleteFavouriteFolder(userProfile._id, name);
    } catch (error) {
      console.log(error)
    }
  }

  const createListPupUp = () => {
    return (
      <Box sx={{ display: createListPupup ? "block" : "none" }} className="createListPopUp">
        <Box className="listBoxHeader">
          <img
            style={{ width: "100px", height: "100px" }}
            className='whiteCrossImage pointer'
            src={whiteCross}
            onClick={() => {
              setCreateListPupUp(false)
              setShowPopup(false)
            }}
          />
          <Typography className='listHeaderText'>Create a New list</Typography>
          <Typography className='listSubText'>Create a list around a project, a topic or for inspiration.</Typography>
        </Box>
        <Box mt={3} className="listNameBox">
          <Typography mb={2}>List Name</Typography>
          <TextField className='listNameInput' placeholder='Favorites  List 1' onChange={(e) => setFavFolderName(e.target.value)} value={favFolderName} />

        </Box>
        <Box mt={3} className="listDescriptionBox">
          <Box sx={{ display: "flex", flexDirection: "row" }}>
            <Typography className=''>Description</Typography>
            <Typography mb={2}>(Optional)</Typography>
          </Box>
          <TextField className='descriptionInput' />
        </Box>
        <Box className="listButtonBox">
          <Box onClick={() => {
            setCreateListPupUp(false)
            setShowPopup(false)
            // setFavFolderName('')
          }}
            className="grayCancelBtn pointer">
            <Typography>Cancel</Typography>
          </Box>
          {/* <Box onClick={() => {
            setCreateListPupUp(false)
            setShowPopup(false)
            // setFavFolderName('')
            // createFavFolder()
          }} ml={3} className="ListCreateBtn pointer">
            <Typography>Create List</Typography>
          </Box> */}
        </Box>
      </Box>
    )
  }

  const favoriteCard = (name, file, index) => {
    return (
      <Box key={index} className="favoritesCard pointer">
        <Box className="optionIcon" onClick={() => toggleCardOption(index)}>
          <img src={OptionIcon} />
        </Box>
        <Box className="favoritesCardCover">
          <img src={FavoritesCardCover} alt={`Favorite Card ${index}`} />
        </Box>
        <Box className="favoritesCardTest">
          <Typography className='FCardTitle'>{name}</Typography>
          <Typography className='FCardDate'>Created on {moment(file.date).format('MMM Do YYYY')}</Typography>
        </Box>
        <Box sx={{ display: isCardOpen(index) ? "flex" : "none" }} className="favoritesOptionPupUP">
          <Box onClick={() => {
            toggleCardOption(index)
            setCreateListPupUp(true)
            setShowPopup(true)
            setFavFolderName(name)
          }
          } className="FCardOptionItem FCardOptionItemB">
            <Typography>Edit listing</Typography>
          </Box>
          <Box onClick={() => handleDeleteFolder(index, name)} className="FCardOptionItem">
            <Typography>Delete</Typography>
          </Box>
        </Box>
      </Box>
    );
  }

  const handleChangePage = async (event, value) => {
    setShowCount([(value * 28) - 28, value * 28])
    window.scrollTo(0, 0);
  }

  const handleCompareChange = (locationId) => {
    if (compareList.includes(locationId)) {
      setCompareList((prev) => prev.filter((element) => element !== locationId));
      return null
    }
    if (compareList.length === 3) {
      setModalBody('You can choose only 3 properties')
      setShowErrorModal(true)
      // return alert('You can choose only 3 properties')
      return null
    }
    setCompareList((prev) => [...prev, locationId])
  }

  const handleCompareProperties = () => {
    if (compareList.length < 2) {
      // alert('Please select atleast 2 properties')
      setModalBody('Please select atleast 2 properties')
      setShowErrorModal(true)
    } else {
      if (compareList.length === 2) {
        navigate({
          pathname: `/compare`,
          search: createSearchParams({
            locationId: `${compareList[0]}-${compareList[1]}`,
          }).toString(),
        });
      } else {
        navigate({
          pathname: `/compare`,
          search: createSearchParams({
            locationId: `${compareList[0]}-${compareList[1]}-${compareList[2]}`,
          }).toString(),
        });
      }
    }
  }

  const createFavFolder = async () => {
    try {
      const resp = await createFavouriteFolder(userProfile?._id, favFolderName)
      setFavFolderName('')
      setUserProfile();
    } catch (error) {
      console.log(error);
    }
  }

  const handleSearch = async (value) => {
    try {
      setSearchInput(value)
      // const resp = await getFavouritesList(userProfile?._id, sort, value);
      const resp = await getNewFavouritesList(userProfile?._id, sort, value);
      setCount(Math.ceil(resp.data.length / 28))
      setFavouriesList(resp.data);
    } catch (error) {
      console.log(error);
    }
  }

  const handleSort = async (label) => {
    try {
      setSort(label)
      var value = '';
      switch (label) {
        case 'Location Type':
          value = 'locationType'
          break;
        case 'City':
          value = 'city'
          break;
        case 'Review/Rating':
          value = 'ratings'
          break;
        default:
        // code block
      }
      // const resp = await getFavouritesList(userProfile?._id, value, searchInput);
      const resp = await getNewFavouritesList(userProfile?._id, value, searchInput);
      setCount(Math.ceil(resp.data.length / 28))
      setFavouriesList(resp.data);
    } catch (error) {
      console.log(error);
    }
  }

  return (
    <>
      <Header active={true} />
      <Box className="favoritesMainContainer">
        {showPopup && <div className="popupBackdrop"></div>}
        {createListPupUp()}
        <Box className="favoritesContainer">
          <Box className="favoritesHeader">
            <Box className="favoritesHeaderBox1">
              <Box className="naveFirstBox">
                <Box className="favoritesHeaderTextBox  headerItems">
                  <h3 className="favoritesHeaderText">
                    Your Personal Shortlist
                  </h3>
                </Box>
              </Box>
              {/* <div> */}
              <Box className="favoritesHeaderSearchBar headerItems">
                <Box className="favorite_search_Bar">
                  <img src={SearchIcon} />
                  <TextField
                    className="favorite_search_input"
                    placeholder="Search favorites by id..."
                    value={searchInput}
                    onChange={(e) => handleSearch(e.target.value)}
                  />
                </Box>
              </Box>
              {/* </div> */}

              <Box className="NavSecondBox">
                <Box className="favoritesHeaderSortBy headerItems">
                  <Box className="storyButton pointer" onClick={() => setSortByPopUp(!sortByPopUp)}>
                    <Typography mr={2}>Sort by {sort ? `: ${sort}` : ''}</Typography>
                    <img src={upDownArrow} />
                  </Box>
                  <Box className="favSortByPopUpBox">
                    <Box sx={{ display: sortByPopUp ? "flex" : "none" }} className="sortEventList">
                      {
                        sortByArray?.map((data, i) => (
                          <Box key={i} onClick={e => {
                            handleSort(e?.target?.innerText)
                            setSortByPopUp(false)
                          }} className="sorteventItem">
                            <Typography>{data.label}</Typography>
                          </Box>
                        ))
                      }
                    </Box>
                  </Box>
                </Box>

                <Box className="favoritesHeaderSortBy headerItems" title='Create a folder/list and move favourite properties in to it'>
                  {/* <Box
                    // onClick={() => {
                    //   setCreateListPupUp(true);
                    //   setShowPopup(true);
                    // }}
                    className="CreateListBtn storyButton pointer"
                  >
                    <Typography mr={1}>Create List</Typography>
                    <img src={plusIcon} />
                  </Box> */}
                </Box>
                <Box
                  onClick={handleCompareProperties}
                  className="favoritesHeaderCompare headerItems"
                  title='Select two or three properties and compare them'
                >
                  <Box className="compareButton pointer">
                    <Typography>Compare properties</Typography>
                  </Box>
                </Box>
              </Box>
            </Box>

            <Box className="favoritesHeaderBox2">
              <Typography className="favoritesSubHeader">
                Curate your dream locations with our 'Favorites' feature. Easily compare and decide on the perfect venue for your film shoot, corporate event, or special occasion. Your ideal spot is just a click away!
              </Typography>
            </Box>
          </Box>

          {/* <Box className="favoriteContentBox">
              {Object.keys(favouritesFolderList).map((name,index) => (
                favoriteCard(name,favouritesFolderList[name],index)
              ))}
            </Box> */}
          {/* <hr /> */}
          {/* <div className='fav-tiles-container' > */}
          {favouritesList.length > 0 && (
            <Box className="favoriteContentBox">
              {favouritesList
                .slice(showCount[0], showCount[1])
                ?.map((property, i) => (
                  <div className="favouritePropertyContainer" key={i}>
                    <PropertyInfo
                      key={property._id}
                      property={property}
                      relaod={relaod}
                      lg={3}
                      sm={12}
                    />
                    <div className='compare-input-container'>
                      <input
                        className="form-check-input mr-5"
                        type="checkbox"
                        checked={compareList.includes(property?.location_id)}
                        id={`compare${i}`}
                        onClick={() =>
                          handleCompareChange(property?.location_id)
                        }
                      />
                      <label
                        className="form-check-label"
                        htmlFor={`compare${i}`}
                      >
                        Compare properties
                      </label>
                    </div>
                  </div>
                ))}
            </Box>
          )}
          {/* </div> */}

          {noFav && (
            <div className='d-flex justify-content-center align-items-center mt-5'>
              <h3>No Favourites Found</h3>
            </div>
          )}

          {favouritesList.length > 0 && (
            <Box className="opernings-bot m-3">
              <Pagination
                count={count}
                variant="outlined"
                onChange={handleChangePage}
              />
            </Box>
          )}
        </Box>
      </Box>
      {loader && <PageLoader />}
      <Footer />
      {showErrorModal && <InformationModal
        show={showErrorModal}
        handleClose={() => setShowErrorModal(false)}
        title='Favorites'
        message={modalBody}
      />}
    </>
  );
}


