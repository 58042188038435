import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import safespace from "../../assets/NewImages/Home/icon-safespace.svg";
import chancetosave from "../../assets/NewImages/Home/icon-chancetosave.svg";
import widerange from "../../assets/NewImages/Home/icon-widerange.svg";
import memorable from "../../assets/NewImages/Home/icon-memorable.svg";

const SpotletProvides = () => {
    return (
        <Box className="spotlet-provides">
            <Typography className="text-stroke SpotLetMainHead" variant="h2">
              Spotlet
            </Typography>
            <Typography className="mar-t60 SpotLetMainHead" variant="h2">
              SpotLet Provides
            </Typography>
            <Box className="provides-container">
              <Box className="icon-text-box">
                <figure>
                  <img loading="lazy" src={chancetosave} alt='A Chance to Save'/>
                </figure>
                <Typography variant="h5" className="SpotLetSubHead">A Chance to Save</Typography>
                <Typography variant="body1" className="SpotLetContent" >
                Users save time and money with SpotLet through streamlined property searches, transparent pricing, and direct communication with hosts, avoiding middlemen fees.
                </Typography>
              </Box>
              <Box className="icon-text-box">
                <figure>
                  <img loading="lazy" src={widerange} alt='A Wide Range of Options'/>
                </figure>
                <Typography variant="h5" className="SpotLetSubHead">A Wide Range of Options</Typography>
                <Typography variant="body1" className="SpotLetContent">
                  We invite you to explore our diverse locations for all kinds
                  of shooting, events and celebrations. You’ll always be spoilt
                  for choice!
                </Typography>
              </Box>
              <Box className="icon-text-box">
                <figure>
                  <img loading="lazy" src={safespace} alt='A Safe Space'/>
                </figure>
                <Typography variant="h5" className="SpotLetSubHead">Transparency and Ease</Typography>
                <Typography variant="body1" className="SpotLetContent">
                    SpotLet facilitates direct communication between property owners and customers, 
                    making the booking process straightforward and eliminating the need for intermediaries.
                </Typography>
              </Box>
              <Box className="icon-text-box">
                <figure>
                  <img loading="lazy" src={memorable} alt='A Memorable Experience'/>
                </figure>
                <Typography variant="h5" className="SpotLetSubHead">A Memorable Experience</Typography>
                <Typography variant="body1" className="SpotLetContent">
                  We promise our customers a smooth, satisfactory and memorable
                  experience during the booking, and our hosts ensure you love
                  your location every time!
                </Typography>
              </Box>
            </Box>
          </Box>
    )
}
export default SpotletProvides;