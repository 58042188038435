import Rating from '@mui/material/Rating';
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import blueTik from "../../../assets/NewImages/Home/hexagon-check.svg";


const HostTestimonials = ({reviews, length}) => {

  const TESTIMONIALS = [
    {
      rating: 4.0,
      content:
        "SpotLet's platform is incredibly user-friendly. I was able to list my property with ease, thanks to the intuitive interface. Within minutes, my property was live and ready to attract guests. SpotLet makes hosting a breeze!",
      name: "Varun",
      giveBy: "Host",
    },
    {
      rating: 3.8,
      content:
        "Since listing my property on SpotLet, my revenue has skyrocketed. The platform's wide reach and targeted marketing efforts have resulted in a steady stream of bookings. SpotLet has helped me turn my property into a lucrative source of income.",
      name: "Neha Sharma",
      giveBy: "Host",
    },
    {
      rating: 4.2,
      content:
        "SpotLet has been instrumental in generating more leads for my property. The platform's extensive network and strategic promotion have led to increased visibility and inquiries. Thanks to SpotLet, my property is always in demand!",
      name: "Rajesh Yadav",
      giveBy: "Host",
    },
    {
      rating: 3.5,
      content:
        "SpotLet offers unparalleled exposure for my property. The platform's comprehensive listing options and robust marketing campaigns ensure that my property stands out to potential guests. SpotLet has helped me reach a broader audience and attract guests from around the world.",
      name: "Priya Gupta",
      giveBy: "Host",
    },
    {
      rating: 4.5,
      content:
        "I love having a dedicated webpage for my property on SpotLet. It allows me to showcase all the unique features and amenities of my property in one place. The professional layout and detailed descriptions make it easy for guests to envision their stay. SpotLet's dedicated webpages are a game-changer for hosts!",
      name: "Srinivasa Rao",
      giveBy: "Host",
    },
    {
      rating: 4.8,
      content:
        "SpotLet's transparent pricing model is a breath of fresh air. As a host, I appreciate knowing exactly what fees are involved and how much I'll earn from each booking. SpotLet's upfront approach to pricing builds trust with both hosts and guests, making the booking process seamless.",
      name: "Sneha Reddy",
      giveBy: "Host",
    },
    {
      rating: 4.1,
      content:
        "SpotLet has helped me establish trust with my guests from the very beginning. The platform's verification process and transparent communication ensure that guests feel confident booking my property. Thanks to SpotLet, I've built long-lasting relationships with guests who return year after year.",
      name: "Aarti ",
      giveBy: "Host",
    },
  ];

    const eachCard = (data, index) => {
      return (
            // <div className="item  testimonials-item" key={i}>
                <div className="hostTestimonialCard" key = {index}   >
                  <div>
                    <Rating precision={0.5}  value={data?.rating} readOnly />
                    <p className="testimonialsCardSubText SpotLetContent">{data?.content}</p>
                  </div>
                  <div className="hostInfoBox">
                    <div className="hostProfilePic">
                      <p style={{ marginTop: "13px" }} className="SpotLetMainHead" >{data?.name[0]}</p>
                    </div>
                    <div className='hostDetailsContainer'>
                      <div className='hostNameContainer'>
                          <h1 className="hostName SpotLetMainHead">{data?.name}</h1>
                          <img loading="lazy" className="hostBlueTick" src={blueTik} />
                      </div>
                      <h6 className="hostRole SpotLetSubHead">{data?.giveBy}</h6>
                    </div>
                  </div>
                </div>
              // </div>
      )
    }

    // const getTestimonialsData = async () => {
    //   try {
    //     const response = await getTestimonials();
    //     setClientTestimonials(response?.TESTIMONIALS.testimonialsData)
    //   } catch (e) {
    //     console.log(e)
    //   }
    // }

    // useEffect(() => {
    //   getTestimonialsData()
    // }, [])



      const responsive = {
        desktop: {
          "breakpoint":  { max: 3000, min: 1200 },
          items: 2, // length > 3 ? 3 : length ,
          slidesToSlide: 1 // optional, default to 1.
        },
        tablet: {
          "breakpoint":  { max: 1200, min: 768 },
          items: 1, //length > 2 ? 2 : length,
          slidesToSlide: 1 // optional, default to 1.
        },
        mobile: {
          "breakpoint":  { max: 768, min: 0 },
          items: 1,
          slidesToSlide: 1 // optional, default to 1.
        }
      };

    return(
        <div className="hostTestimonialsContainer" >
            <h1 className="hostTestimonialsHead SpotLetMainHead" id="propertyPage9"> Host Testimonials </h1>
            <div className="hostTestimonialsSlider" >
                <Carousel 
                swipeable={true}
                draggable={true}
                showDots={false}
                responsive={responsive}
                ssr={true} // means to render carousel on server-side.
                infinite={true}
                autoPlay= {false} 
                containerClass="carousel-container"
                itemClass="carousel-item-padding-40-px"
                >
                    {
                        TESTIMONIALS?.map((each,index) => eachCard(each, index))
                    }
                </Carousel>
            </div>
        </div>
    )
}

export default HostTestimonials