import "react-big-calendar/lib/css/react-big-calendar.css";
import { useEffect, useState } from "react";
import { Calendar as Cal, dayjsLocalizer } from "react-big-calendar";
import { Header } from "../components/NewHeader/Header";
import { Sidebar } from "../components/Sidebar";
import { useNavigate } from "react-router-dom";
import { useAuthStore } from "../store/auth";
import { getUserDetails , getBookings} from "../apis/api";
import dayjs from "dayjs";

const items = [
  { text: 'Unavailable', color: '#CCCCCC' },
  { text: 'Available', color: '#A7BEAE' },
  { text: 'Booking Requested', color: '#1E2761' },
  { text: 'Approved', color: '#F98866' },
  { text: 'Rejected', color: '#7A2048' },
  { text: 'Confirmed', color: '#2C5F2D' }
]
const listItemStyle = {
  listStyle: 'none',
  color: 'black',
  display: 'flex',
  alignItems: 'center'
};

const dotStyle = {
width: '10px',
height: '10px',
borderRadius: '50%',
backgroundColor: '#ccc', // Default color for dots
marginRight: '10px'
};

const listStyle = {
margin: '10px',
padding: '0',
};

const localizer = dayjsLocalizer(dayjs);

const eventStyleGetter = (event, start, end, isSelected) => {
  var backgroundColor = event.hexColor;
  var style = {
      backgroundColor: backgroundColor,
      borderRadius: '0px',
      opacity: 0.8,
      color: '#ffffff',
      fontWeight : 'bold',
      // height :'150px',
      border: '0px',
      display: 'block',

  };
  return {
      style: style
  };
}

function calculateStartAndEndTime(startDate, time, noOfHours) {
  // Parse the start date
  const startDateObj = new Date(startDate);
  // Function to parse time string
  function parseTime(timeStr) {
      const [hours, minutes] = timeStr.split(':').map(Number);
      return { hours, minutes };
  }

  // Parse the provided time
  const parsedTime = parseTime(time);

  // Convert noOfHours to a number if it's a string
  const hoursToAdd = Number(noOfHours);

  let endDateObj;
  if (!isNaN(hoursToAdd)) {
      // Set the provided time
      startDateObj.setHours(parsedTime.hours);
      startDateObj.setMinutes(parsedTime.minutes);
      startDateObj.setSeconds(0);
      startDateObj.setMilliseconds(0);
      // Add the number of hours
      endDateObj = new Date(startDateObj);
      endDateObj.setHours(endDateObj.getHours() + hoursToAdd);
  } else {
      // Set the end time to the end of the day
      endDateObj = new Date(startDateObj);
      endDateObj.setHours(23);
      endDateObj.setMinutes(59);
      endDateObj.setSeconds(59);
      endDateObj.setMilliseconds(999);
  }

  // Format the results
  const formattedStartDate = startDateObj.toISOString();
  const formattedEndDate = endDateObj.toISOString();

  return {
      start: formattedStartDate,
      end: formattedEndDate
  };
}

export const Calendar = () => {
  const navigate = useNavigate();
  const { userProfile } = useAuthStore();

  const [events, setEvents] = useState([]);

  const getUserBookings = async () => {
    if(userProfile){
      try{
        const resp = await getBookings(userProfile._id);
        const bookings = resp?.data?.map((item) => {
          let hexColor = ''
          const green = '#2C5F2D'
          const red = '#7A2048'
          const yellow = '#F98866'
          const blue = '#1E2761'
          let start = ''
          let end = ''
          switch (item.status) {
              case 'Booking Requested':
                  hexColor = blue
                  break;
              case 'Approved':
                  hexColor = yellow
                  break;
              case 'Rejected':
                  hexColor = red
                  break;
              case 'Confirmed':
                  hexColor = green
                  break;
              default:
                  hexColor = blue
                  break;
          }
          switch (item?.event) {
              case 'stay':
                  start = new Date(item?.checkIn)
                  end =  new Date(item?.checkOut)
                  break;
              case 'film':
                  const filmObj = calculateStartAndEndTime(item?.startDate,item?.startTime,item?.noOfHours)
                  start = filmObj?.start
                  end =  filmObj?.end
                  break;
              case 'tv':
                  const tvObj = calculateStartAndEndTime(item?.startDate,item?.startTime,item?.noOfHours)
                  start = tvObj?.start
                  end =  tvObj?.end
                  break;
              case 'event':
                  const eventObj = calculateStartAndEndTime(item?.startDate,item?.startTime,item?.noOfHours)
                  start = eventObj?.start
                  end =  eventObj?.end
                  break;
              default:
                  break;
          }
          return {
          id: item?.bookingId,
          title: item?.status,
          propertyId : item?.property_id,
          allDay: true,
          start,
          end,
          hexColor,
          }
      })
        setEvents(bookings);
      }catch(error){
        console.log(error)
      }
    }
    // if (userProfile) {
    //   try {
    //     const userResp = await getUserDetails(userProfile._id);
    //     if (userResp?.data?.portfolio) {
    //       let bookingData = userResp?.data?.portfolio?.map((booking, index) => {
    //         let endTime =
    //           (Number(booking?.bookedTimeDates?.at(0)?.bookedTime?.substr(0, 2)) +
    //             Number(booking.bookedTimeDates?.at(0)?.bookedHours)) %
    //           24;
    //         let ampm = booking.bookedTimeDates?.at(0)?.bookedTime?.substr(6, 7);
    //         if (endTime > 12) {
    //           endTime = endTime % 12;
    //           if (endTime < 10) {
    //             endTime = "0" + endTime;
    //           }
    //           ampm = ampm === "pm" ? "am" : "pm";
    //         }
    //         let bookedDate = new Date(
    //           booking?.bookedTimeDates?.at(0)?.bookedDate
    //         );

    //         let bookingDate = `${bookedDate.getFullYear()}-${bookedDate.getMonth() + 1
    //           }-${bookedDate.getDate()}`;

    //         return {
    //           id: index,
    //           title: booking.activity,
    //           propertyId: booking?.property_id,
    //           start: bookingDate,
    //           allDay: true,
    //           end: bookingDate,
    //         };
    //       });

    //       setEvents(bookingData);
    //     }
    //   } catch (e) {
    //     console.log(e);
    //   }
    // }

  };

  useEffect(() => {
    getUserBookings();
  }, [userProfile]);

  const handleEventClick = (clickInfo) => {
    navigate("/booking-details/" + clickInfo?.id);
    // if (
    //   window.confirm(
    //     `Are you sure you want to navigate the bookings '${clickInfo?.title}'`
    //   )
    // ) {
    //   navigate("/booking-details/" + clickInfo?.id);
    // }
  };
  return (
    <>
      <Header />
      <div className="container-fluid">
        <div className="row edit-profile">
          <div className="d-none d-lg-block col-lg-2">
            <Sidebar />
          </div>
          <div className="col-lg-10 pt-3 pe-3">
            <ul className="row" style={listStyle}>
                {items.map((item, index) => (
                <li className="col-lg-2 col-md-4" key={index} style={listItemStyle}>
                    <span className="SpotLetContent" style={{ ...dotStyle, backgroundColor: item.color }}></span>
                    {item.text}
                </li>
                ))}
            </ul>
            <Cal
              localizer={localizer}
              events={events}
              startAccessor="start"
              endAccessor="end"
              style={{ height: "100vh"  }}
              onSelectEvent={handleEventClick}
              defaultDate={new Date()}
              defaultView="month"
              views={["month"]}
              // views={["day", "week", "month"]}
              // onSelectSlot={handleSelect}
              dayMaxEvents={1}
              dayMaxEventRows={1}
              eventPropGetter={eventStyleGetter}
              popup
                />
          </div>
        </div>
      </div>
    </>
  );
};
