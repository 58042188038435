import { useEffect, useState } from 'react'
import { Box, Typography } from '@mui/material'
import { useAuthStore } from "../../store/auth";
import { markNotificationAsRead, getNotificationSorted } from "../../../src/apis/api"
import moment from 'moment';
import "./Notifications.css"
import { RingLoader } from 'react-spinners';

//Images
import SearchIcon from "../../assets/NewImages/Notifications/search-normal.svg"
import upDownArrow from "../../assets/NewImages/Notifications/UpdownArrow.svg"
import defaultProfileIcon from "../../assets/NewImages/Notifications/Favicon.ico"
import { IoCloseSharp } from "react-icons/io5";



const Notifications = (props) => {
    const { userProfile } = useAuthStore();
    const { clickNotification, notificationsCount, count } = props
    const [notificationSPopUp, setNotificationSPupUp] = useState(true)
    const [notificationBPopUp, setNotificationBPupUp] = useState(false)
    const [sortValue, setSortValue] = useState("Sort by");

    // Loader
    const [loader, setLoader] = useState(true);

    // for all Notifications
    const [smallData, setSmallData] = useState({});
    const [bigData, setBigData] = useState({});
    const [displayObject, setDisplayObject] = useState({});

    // search
    const [search, setSearch] = useState("");

    // Header Functions
    const onClickClose = () => {
        clickNotification()
    }

    const onClickRead = () => {
        notificationsCount()
    }

    const onClickEachNotification = async (index, id, readStatus) => {
        if (!readStatus) {
            try {
                await markNotificationAsRead(userProfile?._id, id);
                onClickRead()
            }
            catch (e) {
                console.log(e);
            }
        }
        onClickViewAll("-1", id)
    }

    const onClickViewAll = async (sort, id) => {
        setLoader(true)
        setNotificationSPupUp(false)
        setNotificationBPupUp(true)
        try {
            const resp2 = await getNotificationSorted(userProfile?._id, "", search, sort, new Date())
            Object.keys(resp2?.data).map((key) => {
                resp2?.data[key]?.map((eachObj) => {
                    if (eachObj._id === id) {
                        setDisplayObject(eachObj);
                    }
                })
            })
            setBigData(resp2?.data);
            setLoader(false)
        }
        catch (e) {
            console.log(e);
        }
    }

    const onClickMarkAllRead = async () => {
        try {
            await markNotificationAsRead(userProfile?._id, "");
            const resp1 = await getNotificationSorted(userProfile?._id, "", search, "-1", new Date());
            setBigData(resp1?.data);
            onClickRead();
        }
        catch (e) {
            console.log(e);
        }
    }

    const eachSmallNotification = (each, index) => {
        const dateAndTimeClass = !each?.read ? "time-date" : "";
        return (
            <Box onClick={() => {
                onClickEachNotification(index, each?._id, each?.read)
            }}
                key={index} className="NotiUserRow pointer">
                {/* <Box className="photoBox"> */}
                {/* {!each?.read && <Box className="NotiRedDot"><img src={RedDot} /></Box>} */}
                {/* <Box className="userPhoto"> */}
                <img className='NotiUserPhoto' src={defaultProfileIcon} />
                {/* </Box> */}
                {/* </Box> */}
                <Box className="notiUserInfo SNotiUserInfo">
                    {/* <Typography className={dateAndTimeClass} ml={0.5}>{moment(each?.createdAt).format("DD/MM/YYYY")}</Typography> */}
                    <Typography className='notiUserName'>
                        {/* {name} */}
                        <span className={dateAndTimeClass} > {each?.notificationHeader}</span>
                    </Typography>
                    <Box className='time-and-date' >

                        <Box className="userNotiDate sNotiUserTime">
                            <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12" fill="none">
                                <path d="M8.13787 7.05963L6.46474 5.80479V3.24865C6.46474 2.99163 6.25699 2.78388 5.99998 2.78388C5.74296 2.78388 5.53521 2.99163 5.53521 3.24865V6.0372C5.53521 6.18359 5.604 6.32163 5.72112 6.40901L7.58013 7.80328C7.66378 7.86602 7.7614 7.89623 7.85852 7.89623C8.00027 7.89623 8.1397 7.83255 8.2308 7.70986C8.38514 7.50488 8.3433 7.21348 8.13787 7.05963Z" fill="#777272" />
                                <path d="M6 0C2.6914 0 0 2.6914 0 6C0 9.3086 2.6914 12 6 12C9.3086 12 12 9.3086 12 6C12 2.6914 9.3086 0 6 0ZM6 11.0705C3.20449 11.0705 0.929508 8.79551 0.929508 6C0.929508 3.20449 3.20449 0.929508 6 0.929508C8.79598 0.929508 11.0705 3.20449 11.0705 6C11.0705 8.79551 8.79551 11.0705 6 11.0705Z" fill="#777272" />
                            </svg>
                            <Typography className={dateAndTimeClass} ml={0.5}>{moment(each?.createdAt).format("hh:mm a")}</Typography>
                        </Box>
                    </Box>
                </Box>
            </Box>
        )
    }

    const eachBigNotification = (each, index, notificationId) => {
        const dateAndTimeClass = !each?.read ? "time-date" : "";
        return (
            <Box onClick={
                () => onClickEachNotification(index, notificationId, each?.read)
            } key={index} className="NotiUserRow pointer">
                {/* <Box className="photoBox"> */}
                {/* {!each?.read && <Box className="NotiRedDot"><img src={RedDot} /></Box>} */}
                {/* <Box className="userPhoto"> */}
                <img className='NotiUserPhoto' src={defaultProfileIcon} />
                {/* </Box> */}
                {/* </Box> */}
                <Box className="notiUserInfo">
                    <Typography className='notiUserName'>
                        {/* {name} */}
                        <span className={dateAndTimeClass} > {each?.notificationHeader}</span>
                    </Typography>
                    {/* <Typography className={dateAndTimeClass} ml={0.5}>{moment(each?.createdAt).format("DD/MM/YYYY")}</Typography> */}

                    <Box className="userNotiDate">
                        <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12" fill="none">
                            <path d="M8.13787 7.05963L6.46474 5.80479V3.24865C6.46474 2.99163 6.25699 2.78388 5.99998 2.78388C5.74296 2.78388 5.53521 2.99163 5.53521 3.24865V6.0372C5.53521 6.18359 5.604 6.32163 5.72112 6.40901L7.58013 7.80328C7.66378 7.86602 7.7614 7.89623 7.85852 7.89623C8.00027 7.89623 8.1397 7.83255 8.2308 7.70986C8.38514 7.50488 8.3433 7.21348 8.13787 7.05963Z" fill="#777272" />
                            <path d="M6 0C2.6914 0 0 2.6914 0 6C0 9.3086 2.6914 12 6 12C9.3086 12 12 9.3086 12 6C12 2.6914 9.3086 0 6 0ZM6 11.0705C3.20449 11.0705 0.929508 8.79551 0.929508 6C0.929508 3.20449 3.20449 0.929508 6 0.929508C8.79598 0.929508 11.0705 3.20449 11.0705 6C11.0705 8.79551 8.79551 11.0705 6 11.0705Z" fill="#777272" />
                        </svg>
                        <Typography className={dateAndTimeClass} ml={0.5}>{moment(each?.createdAt).format("hh:mm a")}</Typography>
                    </Box>
                </Box>
            </Box>
        )
    }

    const noNotifications = () => {
        return (
            <Box className="notificationSPopUp" sx={{ display: notificationSPopUp ? "flex" : "none" }} >
                <Box className="notificationSHeader">
                    <Typography>Notifications</Typography>
                    <IoCloseSharp className='close-icon' onClick={onClickClose} />

                </Box>

                {!loader && <h3 className='text-center mt-5 no-notifications ' > No Notifications Present </h3>}

                <div className='loader-container' >
                    {loader && <RingLoader color='#ea4335' />}
                </div>

            </Box>

        )
    }

    const showNotification = () => {
        let toShow = true
        Object.keys(smallData).map((key) => {
            if (smallData[key].length > 0) {
                toShow = false;
                return null
            }
        })
        return (
            <>
                {smallData &&
                    smallNotification()
                }
                {toShow &&
                    noNotifications()}
            </>
        )
    }


    const onChangeSearch = async (e) => {
        setSearch(e.target.value)
        try {
            const resp2 = await getNotificationSorted(userProfile?._id, "", e.target.value, "-1", new Date());
            setBigData(resp2?.data)
        } catch (e) {
            console.log(e);
        }
    }

    const onClickFilterDateAsc = async () => {
        onClickViewAll("1")
        setSortValue("Date Asc")
    }

    const onClickFilterDateDesc = async () => {
        onClickViewAll("-1")
        setSortValue("Date Desc")
    }

    const getSortedNotificationsData = async () => {
        try {
            const date = new Date()
            const response = await getNotificationSorted(userProfile?._id, "10", search, "-1", new Date());
            setSmallData(response?.data)

            if (response?.data.today.length > 0) {
                setDisplayObject(response?.data["today"][0])
            }
            else if (response?.data.yesterday.length > 0) {
                setDisplayObject(response?.data["yesterday"][0])
            }
            else if (response?.data.thisWeek.length > 0) {
                setDisplayObject(response?.data["thisWeek"][0])
            }
            else if (response?.data.lastWeek.length > 0) {
                setDisplayObject(response?.data["lastWeek"][0])
            }
            else if (response?.data.thisMonth.length > 0) {
                setDisplayObject(response?.data["thisMonth"][0])
            }
            else if (response?.data.lastMonth.length > 0) {
                setDisplayObject(response?.data["lastMonth"][0])
            }
            else if (response?.data.thisYear.length > 0) {
                setDisplayObject(response?.data["thisYear"][0])
            }
            else if (response?.data.lastYear.length > 0) {
                setDisplayObject(response?.data["lastYear"][0])
            }
            else if (response?.data.fewYearsAgo.length > 0) {
                setDisplayObject(response?.data["fewYearsAgo"][0])
            }

            setLoader(false)
        } catch (e) {
            console.log(e);
        }
    }

    useEffect(() => {
        getSortedNotificationsData();
    }, [])

    const NumberOfNotifications = count > 0 ? <span> ({count}) </span> : null

    const smallNotification = () => {
        return (
            <Box className="notificationSPopUp" sx={{ display: notificationSPopUp ? "flex" : "none" }} >
                <Box className="notificationSHeader">
                    <Typography>Notifications <span> {NumberOfNotifications}  </span> </Typography>
                    <IoCloseSharp className='close-icon' onClick={onClickClose} />
                    <IoCloseSharp className='close-icon-small' onClick={onClickClose} />
                </Box>

                <Box className="notificationSContainer">
                    {
                        !smallData?.today && <div className='loader-container mt-5' >
                            {loader && <RingLoader color='#ea4335' />}
                        </div>
                    }
                    {!loader && smallData && <div>

                        {smallData?.today.length > 0 &&
                            <div>
                                <Box className="top_Date">
                                    <Typography>TODAY</Typography>
                                </Box>
                                {
                                    smallData?.today?.map((each, index) => (
                                        eachSmallNotification(each, index)
                                    ))
                                }
                            </div>
                        }

                        {smallData?.yesterday.length > 0 &&
                            <div>
                                <Box className="top_Date">
                                    <Typography>YESTERDAY</Typography>
                                </Box>
                                {
                                    smallData?.yesterday?.map((each, index) => (
                                        eachSmallNotification(each, index)
                                    ))
                                }
                            </div>
                        }

                        {smallData?.thisWeek.length > 0 &&
                            <div>
                                <Box className="top_Date">
                                    <Typography>THIS WEEK</Typography>
                                </Box>
                                {
                                    smallData?.thisWeek?.map((each, index) => (
                                        eachSmallNotification(each, index)
                                    ))
                                }
                            </div>
                        }

                        {smallData?.lastWeek.length > 0 &&
                            <div>
                                <Box className="top_Date">
                                    <Typography>LAST WEEK</Typography>
                                </Box>
                                {
                                    smallData?.lastWeek?.map((each, index) => (
                                        eachSmallNotification(each, index)
                                    ))
                                }
                            </div>
                        }

                        {smallData?.thisMonth.length > 0 &&
                            <div>
                                <Box className="top_Date">
                                    <Typography>THIS MONTH</Typography>
                                </Box>
                                {
                                    smallData?.thisMonth?.map((each, index) => (
                                        eachSmallNotification(each, index)
                                    ))
                                }
                            </div>
                        }

                        {smallData?.lastMonth.length > 0 &&
                            <div>
                                <Box className="top_Date">
                                    <Typography>LAST MONTH</Typography>
                                </Box>
                                {
                                    smallData?.lastMonth?.map((each, index) => (
                                        eachSmallNotification(each, index)
                                    ))
                                }
                            </div>
                        }

                        {smallData?.thisYear.length > 0 &&
                            <div>
                                <Box className="top_Date">
                                    <Typography>THIS YEAR</Typography>
                                </Box>
                                {
                                    smallData?.thisYear?.map((each, index) => (
                                        eachSmallNotification(each, index)
                                    ))
                                }
                            </div>
                        }

                        {smallData?.lastYear.length > 0 &&
                            <div>
                                <Box className="top_Date">
                                    <Typography>LAST YEAR</Typography>
                                </Box>
                                {
                                    smallData?.lastYear?.map((each, index) => (
                                        eachSmallNotification(each, index)
                                    ))
                                }
                            </div>
                        }

                        {smallData?.fewYearsAgo.length > 0 &&
                            <div>
                                <Box className="top_Date">
                                    <Typography>FEW YEARS AGO</Typography>
                                </Box>
                                {
                                    smallData?.fewYearsAgo?.map((each, index) => (
                                        eachSmallNotification(each, index)
                                    ))
                                }
                            </div>
                        }

                    </div>}
                </Box>
                <Box
                    onClick={() => onClickViewAll("-1")}
                    className="notificationPopUpSeeAllBtn pointer">
                    <Typography>View all</Typography>
                </Box>

            </Box >
        )
    }

    const descendingLeftBox = () => {
        return (
            <Box className="notiLeftBox">
                {bigData?.today.length > 0 &&
                    <div className='each-notifications-container' >
                        <Box className="top_Date">
                            <Typography>TODAY</Typography>
                        </Box>
                        {
                            bigData?.today?.map((each, index) => (
                                eachBigNotification(each, index, each?._id)
                            ))
                        }
                    </div>
                }

                {bigData?.yesterday.length > 0 &&
                    <div className='each-notifications-container' >
                        <Box className="top_Date">
                            <Typography>YESTERDAY</Typography>
                        </Box>
                        {
                            bigData?.yesterday?.map((each, index) => (
                                eachBigNotification(each, index, each?._id)
                            ))
                        }
                    </div>
                }

                {bigData?.thisWeek.length > 0 &&
                    <div className='each-notifications-container' >
                        <Box className="top_Date">
                            <Typography>THIS WEEK</Typography>
                        </Box>
                        {
                            bigData?.thisWeek?.map((each, index) => (
                                eachBigNotification(each, index, each?._id)
                            ))
                        }
                    </div>
                }

                {bigData?.lastWeek.length > 0 &&
                    <div className='each-notifications-container' >
                        <Box className="top_Date">
                            <Typography>LAST WEEK</Typography>
                        </Box>
                        {
                            bigData?.lastWeek?.map((each, index) => (
                                eachBigNotification(each, index, each?._id)
                            ))
                        }
                    </div>
                }

                {bigData?.thisMonth.length > 0 &&
                    <div className='each-notifications-container' >
                        <Box className="top_Date">
                            <Typography>THIS MONTH</Typography>
                        </Box>
                        {
                            bigData?.thisMonth?.map((each, index) => (
                                eachBigNotification(each, index, each?._id)
                            ))
                        }
                    </div>
                }

                {bigData?.lastMonth.length > 0 &&
                    <div className='each-notifications-container' >
                        <Box className="top_Date">
                            <Typography>LAST MONTH</Typography>
                        </Box>
                        {
                            bigData?.lastMonth?.map((each, index) => (
                                eachBigNotification(each, index, each?._id)
                            ))
                        }
                    </div>
                }

                {bigData?.thisYear.length > 0 &&
                    <div className='each-notifications-container'>
                        <Box className="top_Date">
                            <Typography>THIS YEAR</Typography>
                        </Box>
                        {
                            bigData?.thisYear?.map((each, index) => (
                                eachBigNotification(each, index, each?._id)
                            ))
                        }
                    </div>
                }

                {bigData?.lastYear.length > 0 &&
                    <div className='each-notifications-container'>
                        <Box className="top_Date">
                            <Typography>LAST YEAR</Typography>
                        </Box>
                        {
                            bigData?.lastYear?.map((each, index) => (
                                eachBigNotification(each, index, each?._id)
                            ))
                        }
                    </div>
                }

                {bigData?.fewYearsAgo.length > 0 &&
                    <div className='each-notifications-container'>
                        <Box className="top_Date">
                            <Typography>FEW YEARS AGO</Typography>
                        </Box>
                        {
                            bigData?.fewYearsAgo?.map((each, index) => (
                                eachBigNotification(each, index, each?._id)
                            ))
                        }
                    </div>
                }

            </Box>
        )
    }

    const ascendingLeftBox = () => {
        return (
            <Box className="notiLeftBox">

                {bigData?.fewYearsAgo.length > 0 &&
                    <div className='each-notifications-container' >
                        <Box className="top_Date">
                            <Typography>FEW YEARS AGO</Typography>
                        </Box>
                        {
                            bigData?.fewYearsAgo?.map((each, index) => (
                                eachBigNotification(each, index, each?._id)
                            ))
                        }
                    </div>
                }

                {bigData?.lastYear.length > 0 &&
                    <div className='each-notifications-container' >
                        <Box className="top_Date">
                            <Typography>LAST YEAR</Typography>
                        </Box>
                        {
                            bigData?.lastYear?.map((each, index) => (
                                eachBigNotification(each, index, each?._id)
                            ))
                        }
                    </div>
                }

                {bigData?.thisYear.length > 0 &&
                    <div className='each-notifications-container' >
                        <Box className="top_Date">
                            <Typography>THIS YEAR</Typography>
                        </Box>
                        {
                            bigData?.thisYear?.map((each, index) => (
                                eachBigNotification(each, index, each?._id)
                            ))
                        }
                    </div>
                }

                {bigData?.lastMonth.length > 0 &&
                    <div className='each-notifications-container' >
                        <Box className="top_Date">
                            <Typography>LAST MONTH</Typography>
                        </Box>
                        {
                            bigData?.lastMonth?.map((each, index) => (
                                eachBigNotification(each, index, each?._id)
                            ))
                        }
                    </div>
                }

                {bigData?.thisMonth.length > 0 &&
                    <div className='each-notifications-container' >
                        <Box className="top_Date">
                            <Typography>THIS MONTH</Typography>
                        </Box>
                        {
                            bigData?.thisMonth?.map((each, index) => (
                                eachBigNotification(each, index, each?._id)
                            ))
                        }
                    </div>
                }

                {bigData?.lastWeek.length > 0 &&
                    <div className='each-notifications-container' >
                        <Box className="top_Date">
                            <Typography>LAST WEEK</Typography>
                        </Box>
                        {
                            bigData?.lastWeek?.map((each, index) => (
                                eachBigNotification(each, index, each?._id)
                            ))
                        }
                    </div>
                }

                {bigData?.thisWeek.length > 0 &&
                    <div className='each-notifications-container' >
                        <Box className="top_Date">
                            <Typography>THIS WEEK</Typography>
                        </Box>
                        {
                            bigData?.thisWeek?.map((each, index) => (
                                eachBigNotification(each, index, each?._id)
                            ))
                        }
                    </div>
                }

                {bigData?.yesterday.length > 0 &&
                    <div className='each-notifications-container' >
                        <Box className="top_Date">
                            <Typography>YESTERDAY</Typography>
                        </Box>
                        {
                            bigData?.yesterday?.map((each, index) => (
                                eachBigNotification(each, index, each?._id)
                            ))
                        }
                    </div>
                }

                {bigData?.today.length > 0 &&
                    <div className='each-notifications-container' >
                        <Box className="top_Date">
                            <Typography>TODAY</Typography>
                        </Box>
                        {
                            bigData?.today?.map((each, index) => (
                                eachBigNotification(each, index, each?._id)
                            ))
                        }
                    </div>
                }

            </Box>
        )
    }

    const bigNotifications = () => {
        return (
            <>
                {/* Notification Big box */}
                <Box className="NotificationPupUp"
                    sx={{ display: notificationBPopUp ? "flex" : "none" }}
                >
                    <Box className="notificationHeader">
                        <Box className="notificationHeaderBox1">
                            <Box className="notiHeaderItem">
                                <Typography className='notiHeaderText'>Notifications <span> {NumberOfNotifications}  </span> </Typography>
                                <Typography className='notiSubText pointer' onClick={onClickMarkAllRead}  >Mark all as read</Typography>
                            </Box>
                        </Box>

                        {/* For Mobile  */}

                        <Box className="notificationHeadBox1" >
                            <Box className="head-box-2" >
                                <Typography className='notiHeaderText'>Notifications</Typography>
                                <IoCloseSharp className='close-icon1' onClick={onClickClose} />
                            </Box>
                            <Typography className='notiSubText pointer' onClick={onClickMarkAllRead}  >Mark all as read</Typography>
                        </Box>

                        <Box className="notificationHeaderBox2">
                            <Box className="notiHeaderItem">
                                <Box className="search_Field">
                                    <img style={{ width: "22px", position: "relative", left: "34px", zIndex: "9" }} src={SearchIcon} />
                                    <input value={search} onChange={onChangeSearch} className='search-input' placeholder='Search Notifications....' />
                                </Box>
                            </Box>
                        </Box>

                        <div className="sortby-container" >
                            <div className="btn-group">
                                <button type="button" className="filter-btn btn dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false">
                                    <img src={upDownArrow} className="me-2" alt="" />
                                    {sortValue}</button>
                                <ul className="dropdown-menu dropdown-menu-end">
                                    <li><a onClick={onClickFilterDateAsc} className="dropdown-item" value="Date Asc " >Date Asc</a></li>
                                    <li><a onClick={onClickFilterDateDesc} className="dropdown-item" value="Date Desc" >Date Desc</a></li>
                                </ul>
                            </div>
                            <IoCloseSharp className='close-icon' onClick={onClickClose} />
                        </div>
                    </Box>

                    {
                        !smallData && <div className='loader-container mt-5' >
                            {loader && <RingLoader color='#ea4335' />}
                        </div>
                    }
                    {bigData?.today && <Box className="notificationContainer">

                        {sortValue === "Date Asc" ? ascendingLeftBox() : descendingLeftBox()}
                        {
                            displayObject && <Box className="notiRightBox">
                                <Box className="user_info">
                                    {/* <Box className="userPhoto"> */}
                                    <img className='NotiUserPhoto' src={defaultProfileIcon} />
                                    {/* </Box> */}
                                    <Box className="user_infoText">
                                        <Typography className='User_date'>
                                            <span className='mr-2' >
                                                {`${moment(displayObject?.createdAt).format("DD/MM/YYYY")} - `}
                                            </span>
                                            <span className='ml-3'>
                                                {moment(displayObject?.createdAt).format("hh:mm a")}

                                            </span> </Typography>
                                    </Box>
                                </Box>
                                <Box className="user_summery">
                                    <Typography className="user_Summary_text">{displayObject?.notificationHeader}</Typography>
                                    <p className="user_Summary_sub_text"
                                        dangerouslySetInnerHTML={{ __html: displayObject?.notificationContent }}></p>
                                </Box>
                            </Box>
                        }
                    </Box>}
                </Box>
            </>
        )
    }

    return (
        <>
            {showNotification()}
            {bigNotifications()}
        </>
    )
}

export default Notifications