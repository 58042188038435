import { useNavigate, useParams } from "react-router";
import { Formik } from "formik";
import * as yup from "yup";
import { Alert, Button, Col, Form } from "react-bootstrap";
import { resetPasswords } from "../apis/api";
import { useState } from "react";

const schema = yup.object().shape({
  password: yup
    .string()
    .required("Please enter your password")
    .min(8, "Password must be 8 characters long")
    .matches(/[0-9]/, "Password requires a number")
    .matches(/[a-z]/, "Password requires a lowercase letter")
    .matches(/[A-Z]/, "Password requires an uppercase letter")
    .matches(/[^\w]/, "Password requires a symbol"),
  confirmPassword: yup
    .string()
    .required("Please retype your password")
    .oneOf([yup.ref("password")], "Your passwords do not match"),
});

export const ResetPassword = () => {
  const { userId } = useParams();
  const navigate = useNavigate();
  const [isPasswordUpdated, setIsPasswordUpdated] = useState(false);
  const [passwordError, setPasswordError] = useState("")
  const [hidePassword1, setHidePassword1] = useState(false)
  const [hidePassword2, setHidePassword2] = useState(false)

  const onSubmit = async (values) => {
    try {
      await resetPasswords(userId, values.password);
      setPasswordError("");
      setIsPasswordUpdated(true);
    } catch (error) {
      setIsPasswordUpdated(false);
      setPasswordError(error?.response?.data?.message)
      console.log(error);
    }
  };

  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        height: "100vh",
      }}
    >
      <div
        style={{
          border: "2px solid grey",
          borderRadius: "4px",
          padding: "20px",
          width: "500px",
        }}
      >
        {isPasswordUpdated ? (
          <Alert variant="success" className="text-center">
            <p> Password Updated Successfully</p>
            <Button onClick={() => navigate("/")}>Login</Button>
          </Alert>
        ) : (
          <>
            <div style={{ textAlign: "center" }}>
              <h5>Reset Password</h5>
            </div>
            <Formik
              validationSchema={schema}
              onSubmit={onSubmit}
              initialValues={{
                password: "",
                confirmPassword: "",
              }}
            >
              {({
                handleSubmit,
                handleChange,
                values,
                touched,
                isValid,
                errors,
              }) => (
                <Form noValidate onSubmit={handleSubmit}>
                  <Form.Group
                    as={Col}
                    md="12"
                    controlId="validationFormik101"
                    className="position-relative"
                  >
                    <Form.Label>
                      New Password<span className="required">*</span>
                    </Form.Label>
                    <div className="form-floating mb-3">
                      <Form.Control
                        type={hidePassword1 ? "text" : "password"}
                        name="password"
                        value={values.password}
                        onChange={handleChange}
                        isInvalid={touched.password && !!errors.password}
                        className="pt-1 pb-0"
                      />
                      {touched.password && !!errors.password && (
                        <Form.Control.Feedback type="invalid">
                          {errors.password}
                        </Form.Control.Feedback>
                      )}
                      <div className="input-group-addon mt-1">
                            <i
                              onClick={() =>
                                setHidePassword1((hidePassword1) => !hidePassword1) // hidePassword1
                              }
                              className={
                                hidePassword1
                                  ? "fa fa-eye pointer"
                                  : "fa fa-eye-slash pointer"
                              }
                              aria-hidden="true"
                            ></i>
                      </div>
                  </div>
                  </Form.Group>
                  <Form.Group
                    as={Col}
                    md="12"
                    controlId="validationFormik102"
                    className="position-relative mt-3"
                  >
                    <Form.Label>
                      Confirm New Password<span className="required">*</span>
                    </Form.Label>
                    <div className="form-floating">
                      <Form.Control
                        type={hidePassword2 ? "text" : "password"}
                        className="pt-1 pb-0"
                        name="confirmPassword"
                        value={values.confirmPassword}
                        onChange={handleChange}
                        isInvalid={
                          touched.confirmPassword && !!errors.confirmPassword
                        }
                      />
                      {touched.confirmPassword && !!errors.confirmPassword && (
                        <Form.Control.Feedback type="invalid">
                          {errors.confirmPassword}
                        </Form.Control.Feedback>
                      )}
                      <div className="input-group-addon mt-1">
                              <i
                                onClick={() =>
                                  setHidePassword2((hidePassword2) => !hidePassword2)
                                }
                                className={
                                  hidePassword2
                                    ? "fa fa-eye pointer"
                                    : "fa fa-eye-slash pointer"
                                }
                                aria-hidden="true"
                              ></i>
                      </div>
                    </div>
                  </Form.Group>
                  <p className="text-danger" > {passwordError} </p>
                  <div className="d-grid gap-2 mt-4">
                    <Button type="submit">Reset</Button>
                  </div>
                </Form>
              )}
            </Formik>
          </>
        )}
      </div>
    </div>
  );
};
