import React, { useEffect, useState } from "react";
import { getListingLeads,getPlans } from "../../../apis/api";
// import moment from "moment";
// import Accordion from 'react-bootstrap/Accordion';
// import { BookingsRequestsTable } from "../../../components/BookingsRequestsTable";
import dayjs from "dayjs";
import LeadsResponsiveTable from "./LeadsFilterPopup/leadsResponsiveTable";
import { PageLoader } from "../../../components/PageLoader";
import SubscriptionModal from "../../NewSubscription/Modals/SubscriptionModal";
import { useAuthStore } from "../../../store/auth"
import { LeadsFilter } from "./LeadsFilterPopup/leadsFilter";
import Pagination from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';
// const REQ_ARRAY = [ "Contact Guest", "Get In Touch", "Booking Requests", "Schedule Property Visit"]
const LEADS_COLUMNS = ['Location Id', 'Date', 'Name', 'Email', "Mobile Number"]
// const BOOKINGS_COLUMNS = ['Booking ID', 'Location ID', 'Status', 'Date', 'Event', 'Action']
const BOOKINGS_COLUMNS = ['Enquiry Received Date', "Booking Requested Date", "User Name", "Contact Number", "Contact Email", 'Event', 'View Details']
const SCHEDULE_COLUMNS =  ["Enquiry Received Date", 'Schedule Visit Date',  'User Name', "Contact Number", 'Contact Email', "User Message", "View Details"]
const GET_IN_TOUCH_COLUMNS = ["Enquiry Date", "User Name", "Organisation Name", "Contact Number", "Contact Email", "User Message", "View Details"]
const CONTACT_GUEST_COLUMNS = ["Enquiry Date", "User Name", "Contact Number", "Contact Email", "View Details"]

const COUNT = 10

const Leads = ({data, activeSection}) => {
    const { userProfile } = useAuthStore();
    // const [getInTouchLeads,setGetInTouchLeads] = useState([])
    // const [clickLeads,setClickLeads] = useState([])
    const [subscription,setSubscription] = useState('')
    // const [bookingReqCount, setBookingReqCount] = useState();
    // const [activeSection, setActiveSection] = useState("Contact Host");
    // const [leads, setLeads] = useState()
    const [rows, setRows] = useState([])
    const [columns, setColumns] = useState(LEADS_COLUMNS)
    const [clicksCount, setClicksCount] = useState()
    const [touchsCount, setTouchsCount] = useState()
    const [bookingsCount, setBookingsCount] = useState();
    const [schedulesCount, setSchedulesCount] = useState();
    const [creditsDetails, setCreditsDetails] = useState({})
    const [filterData,setFilterData] = useState({
		start : null,
		end : null,
	})
    const [skip, setSkip] = useState(0);
    const [searchTerm, setSearchTerm] = useState("");
    const handleBookingsTableData = (data) => {
        Promise.all(data?.map((item)=>{
          return {
            // bookingId : item?.bookingId,
            date : dayjs(item?.createdAt).format("DD/MM/YYYY"),
            bookingReqDate : dayjs(item?.data?.startDate).format("DD/MM/YYYY"),
            guestName : item?.data?.guestName,
            guestNumber : item?.data?.guestNumber,
            guestEmail : item?.data?.guestEmail,
            Event : item?.data?.event,
            action : 'details',
            tableId : "Booking Requests Table",
            bookingId : item?._id,
            // locationId : item?.data?.locationId,
            // status : item?.status,
            view : item?.view,
          }
        })).then((values)=> setRows(values))
    }
    const handleFilterData = async (data) => {
        setFilterData(data)
    }
    const handleLeadsTableData = (data) => {
        Promise.all(data?.map((item)=>{
        return {
            tableId : "Leads Table",
            locationId : item?.data?.locationId,
            date : dayjs(item?.createdAt).format("DD/MM/YYYY"),
            name : item?.data?.name,
            email : item?.data?.email,
            mobileNumber : item?.data?.mobileNumber,
            // action : 'details'
        }
        })).then((values)=> setRows(values))
    }
    const handleGetInTouchTableData = (data) => {
        Promise.all(data?.map((item)=>{
        return {
            date : dayjs(item?.createdAt).format("DD/MM/YYYY") || "NA",
            name : item?.data?.name || "NA",
            organisationName : item?.data?.organization || "NA",
            mobileNumber : item?.data?.mobileNumber || "NA",
            email : item?.data?.email || "NA",
            actionOne : 'Message',
            action : 'Details',
            tableId : "Get In Touch Table",
            reqId : item?._id,
            view : item?.view,
            // message : item?.data?.message || "NA",
        }
        })).then((values)=> setRows(values))
    }
    const handleConactHostTableData = (data) => {
        Promise.all(data?.map((item)=>{
        return {
            date : dayjs(item?.createdAt).format("DD/MM/YYYY") || "NA",
            name : item?.data?.name || "NA",
            mobileNumber : item?.data?.mobileNumber || "NA",
            email : item?.data?.email || "NA",
            action : 'Details',
            tableId : "Contact Host Table",
            reqId : item?._id,
            view : item?.view,
        }
        })).then((values)=> setRows(values))
    }
    const handleScheduleTableData = (data) => {
        Promise.all(data?.map((item)=>{
        return {
            createdAt : dayjs(item?.createdAt).format("DD/MM/YYYY"),
            date : dayjs(item?.data?.date).format("DD/MM/YYYY") || "NA",
            name : item?.data?.name,
            mobileNumber : item?.data?.mobileNumber || "NA",
            email : item?.data?.email || "NA",
            actionOne : 'Message',
            action : 'Details',
            tableId : "Schedule Visits Table",
            reqId : item?._id,
            view : item?.view,
        }
        })).then((values)=> setRows(values))
    }
    const getData = async() => {
        try{
            const resp = await getListingLeads(data, activeSection, userProfile?._id, filterData, COUNT, skip, searchTerm)
            setSubscription(resp?.data?.listing?.subscription_id)
            // setLeads(resp?.data?.leads)
            // count start
            setClicksCount(resp?.data?.clicksCount)
            setTouchsCount(resp?.data?.touchsCount)  
            setBookingsCount(resp?.data?.bookingsCount)
            setSchedulesCount(resp?.data?.schedulesCount)
            setCreditsDetails(resp?.data?.creditsDetails)
            // count end
            if (activeSection === "Booking Request") {
                setColumns(BOOKINGS_COLUMNS)
                handleBookingsTableData(resp?.data?.leads)
            }  
            else if (activeSection === "Schedule Property Visit" ) {
                setColumns(SCHEDULE_COLUMNS)
                handleScheduleTableData(resp?.data?.leads)
            }
            else if (activeSection === "Get In Touch" ) {
                setColumns(GET_IN_TOUCH_COLUMNS)
                handleGetInTouchTableData(resp?.data?.leads)
            }
            else if (activeSection === "Contact Host" ) {
                setColumns(CONTACT_GUEST_COLUMNS)
                handleConactHostTableData(resp?.data?.leads)
            }
            else {
                setColumns(LEADS_COLUMNS)
                handleLeadsTableData(resp?.data?.leads)
            }
            // const temp1 = resp?.data?.leads?.filter((item) => item.type === 'Get In Touch')
            // const temp3 = resp?.data?.leads?.filter((item) => item.type === 'Contact Host')
            // setGetInTouchLeads(temp1)
            // setClickLeads(temp3)
        }catch(error){
            console.log(error?.response?.data?.message)
        }
    }
    useEffect(()=>{
        getData()
    },[activeSection, filterData, skip, searchTerm])
    const onPageChange = (event, value) => {
        setSkip((value - 1) * COUNT)
    }
    const paginationSection = () => {
        const paginationCount = Math.ceil(getCountValue(activeSection) / COUNT)
        if (paginationCount > 1 ) {
            return(
                <div className="d-flex flex-row justify-content-center mt-3" >
                    <Stack spacing={2} className="subPropertiesPagination">
                        <Pagination count={paginationCount} shape="rounded"  onChange = { onPageChange } /> 
                    </Stack>
                </div>  
            )
        } 
    }

    const leadsDetailsTable = ( creditsDetails ) => {
        return (
          <table>
            <tbody>
              <tr>
                <td className="credits-table-main-head" >Property ID</td>
                <td>{creditsDetails?.propertyId}</td>
              </tr>
              <tr>
                <td className="credits-table-main-head" >Property Name & City</td>
                <td>{creditsDetails?.nameAndCity}</td>
              </tr>
              <tr>
                <td className="credits-table-main-head" >Plan</td>
                <td>{creditsDetails?.plan}</td>
              </tr>
              {creditsDetails?.plan !== "Premium" && (
                <tr>
                    <td className="credits-table-main-head" > Credits </td>
                    <td style={{padding:"0px"}}>
                        <table>
                            <thead>
                                <tr>
                                    <th className="credits-table-head">Total</th>
                                    <th className="credits-table-head">Used</th>
                                    <th className="credits-table-head">Balance</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td className="credits-table-data">{creditsDetails?.totalCredits}</td>
                                    <td className="credits-table-data">{creditsDetails?.usedCredits}</td>
                                    <td className="credits-table-data">{creditsDetails?.balanceCredits}</td>
                                </tr>
                            </tbody>
                        </table>
                    </td>
                </tr>
              ) }
            </tbody>
          </table>
        );
      };

      
    //onChange = { onPageChange }
    
    // const getInTouch = () => {
    //     return(
    //         <div className="mt-4">
    //             <Accordion className="mb-3">
    //                 <Accordion.Item eventKey="0">
    //                     <Accordion.Header ><span className="dashboardRecentEventsSectionHeading">Get in touch{' '}({getInTouchLeads.length})</span></Accordion.Header>
    //                     <Accordion.Body style={{overflow : 'auto',maxHeight : '400px'}}>
    //                         <table>
    //                             <thead>
    //                                 <tr>
    //                                     <th>Location Id</th>
    //                                     <th>Date</th>
    //                                     <th>Name</th>
    //                                     <th>Email</th>
    //                                     <th>Mobile Number</th>
    //                                 </tr>
    //                             </thead>
    //                             {getInTouchLeads?.length > 0 ? (
    //                                 <tbody>
    //                                     {getInTouchLeads?.map((item)=>{
    //                                         if(item.type === 'Get In Touch'){
    //                                             return(
    //                                                 <tr style={{ textAlign: "center" }}>
    //                                                     <td  style={{ width: "200px"}}>{item.data.locationId}</td>
    //                                                     <td  style={{ width: "200px"}}>{moment(item.createdAt).format('DD-MM-YYYY')}</td>
    //                                                     <td  style={{ width: "200px"}}>{item.data.name}</td>
    //                                                     <td  style={{ width: "200px"}}>{item.data.email}</td>
    //                                                     <td  style={{ width: "200px"}}>{item.data.mobileNumber}</td>
    //                                                 </tr>
    //                                             )
    //                                         }
    //                                     })}
    //                                 </tbody>
    //                             ) : (
    //                                 <tr style={{ textAlign: "center", height: "200px" }}>
    //                                     <td colSpan={5}>No Data</td>
    //                                 </tr>
    //                             )}
    //                         </table>
    //                     </Accordion.Body>
    //                 </Accordion.Item>
    //             </Accordion>
    //         </div>
    //     )
    // }

    // const setBookingRequestsCount = (count) => {
    //     setBookingReqCount(count);
    // }

    // const bookingReuests = () => {
    //     return(
    //         <div className="mt-4">
    //              <Accordion className="mb-3">
    //                 <Accordion.Item eventKey="0">
    //                     <Accordion.Header ><span className="dashboardRecentEventsSectionHeading">Booking Requests{" "}{bookingReqCount > 0 ? `(${bookingReqCount})` : ""}</span></Accordion.Header>
    //                     <Accordion.Body style={{overflow : 'auto',maxHeight : '400px'}}>
    //                         {/* <table>
    //                             <thead>
    //                                 <tr>
    //                                     <th>Location Id</th>
    //                                     <th>Date</th>
    //                                     <th>Event</th>
    //                                     <th>Guests</th>
    //                                     <th>Event Date</th>
    //                                 </tr>
    //                             </thead>
    //                             {bookingLeads?.length > 0 ? (
    //                                 <tbody>
    //                                     {bookingLeads?.map((item)=>{
    //                                             return(
    //                                                 <tr style={{ textAlign: "center"}}>
    //                                                     <td  style={{ width: "200px"}}>{item.data.locationId}</td>
    //                                                     <td  style={{ width: "200px"}}>{moment(item.createdAt).format('DD-MM-YYYY')}</td>
    //                                                     <td  style={{ width: "200px"}}>{item.data.event}</td>
    //                                                     <td  style={{ width: "200px"}}>{item.data.guests || item.data.adults}</td>
    //                                                     <td  style={{ width: "200px"}}>{moment(item.data.requestDates[0].date).format('DD-MM-YYYY')}</td>
    //                                                 </tr>
    //                                             )
    //                                     })}
    //                                 </tbody>
    //                             ) : (
    //                                 <tr style={{ textAlign: "center", height: "200px" }}>
    //                                     <td colSpan={5}>No Data</td>
    //                                 </tr>
    //                             )}
    //                         </table> */}
    //                         <BookingsRequestsTable locationId = {data} setBookingRequestsCount = {setBookingRequestsCount}  />
    //                     </Accordion.Body>
    //                 </Accordion.Item>
    //             </Accordion>
    //         </div>
    //     )
    // }
    // const locationClick = () => {
    //     return(
    //         <div className="mt-4">
    //             <Accordion className="mb-3">
    //                 <Accordion.Item eventKey="0">
    //                     <Accordion.Header ><span className="dashboardRecentEventsSectionHeading">Contact Hosts{' '}({clickLeads.length})</span> </Accordion.Header>
    //                     <Accordion.Body style={{overflow : 'auto',maxHeight : '400px'}}>
    //                         <table>
    //                             <thead>
    //                                 <tr>
    //                                     <th>Location Id</th>
    //                                     <th>Date</th>
    //                                     <th>Name</th>
    //                                     <th>Email</th>
    //                                     <th>Mobile Number</th>
    //                                 </tr>
    //                             </thead>
    //                             {clickLeads.length > 0 ? (
    //                                 <tbody>
    //                                 {clickLeads?.map((item)=>{
    //                                         return(
    //                                             <tr style={{ textAlign: "center"}}>
    //                                                 <td  style={{ width: "200px"}}>{item.data.locationId}</td>
    //                                                 <td  style={{ width: "200px"}}>{moment(item.createdAt).format('DD-MM-YYYY')}</td>
    //                                                 <td  style={{ width: "200px"}}>{item.data.name}</td>
    //                                                 <td  style={{ width: "200px"}}>{item.data.email}</td>
    //                                                 <td  style={{ width: "200px"}}>{item.data.mobileNumber}</td>
    //                                             </tr>
    //                                         )
    //                                 })}
    //                             </tbody>
    //                             ) : (
    //                                 <tr style={{ textAlign: "center", height: "200px" }}>
    //                                     <td colSpan={5}>No Data</td>
    //                                 </tr>
    //                             )}
    //                         </table>
    //                     </Accordion.Body>
    //                 </Accordion.Item>
    //             </Accordion>
    //         </div>
    //     )
    // }
    const getCountValue = (value) => {
        switch (value) {
            case "Contact Host":
                return clicksCount
            case "Get In Touch":
                return touchsCount
            case "Booking Request":
                return bookingsCount
            case "Schedule Property Visit":
                return schedulesCount
            default:
                return 0
        }
    }

    const setActiveSectionText = (key) => {
        switch (key) {
            case "Contact Guest":
                return "Contact Host"
            case "Get In Touch":
                return "Get In Touch"
            case "Booking Request":
                return "Booking Request"
            case "Schedule Property Visit":
                return "Schedule Property Visit"
            default:
                return "Contact Host"
        }
    }
    const leadManagementTitles = () => {
        return (
            REQ_ARRAY?.map((each) => {
                return <span className={`SpotLetMainHead cursor ${setActiveSectionText(each) === activeSection ? "leads-type-heading" : null } mx-2`} onClick={ () => {
                    setActiveSection(setActiveSectionText(each))
                    setFilterData({
                        start : null,
                        end : null,
                        // count : null,
                        // skip : 0,
                    })
                    setSkip(0)
                } } > {each} ({getCountValue(setActiveSectionText(each))}) </span>
            })
        )
    }
    const getView = () => {
        if(rows?.length > 0) {
            return (
                <>
                {( !(subscription === "Basic" && activeSection === "Contact Host") ) && (
                            <div className="locationtype-searchcontainer mb-2">
                                <input
                                    type="search"
                                    placeholder="Search Leads"
                                    onChange={searchLeadData}
                                    className="locationtype-search"
                                    value={searchTerm}
                                />
                            </div>
                        )}
                <LeadsResponsiveTable rows={rows}  columns={columns} upgrade = {onClickUpgrade} />
                </>
            ) 
        } else {
            return(
                <div className="p-3 d-flex flex-row justify-content-center" >
                    <span className="SpotLetMainHead"> No Leads Available </span>
                </div>
            )
        }
    }
    //subscription update start
    const region = localStorage.getItem('region')
    const [loader, setLoader] = useState(false)
    const [showSubscriptionModel, setShowSubscriptionModel] = useState(false);
    const [standardPlan, setStandardPlan] = useState({})
    const [premiumPlan, setPremiumPlan] = useState({})
    const [activePlan,setActivePlan] = useState({})
    const onClickUpgrade = async () => {    
        setLoader(true)
        try {
        const res = await getPlans();
        let reqCurrency 
        if (region === "India") {
            reqCurrency = "INR"
        } else {
            reqCurrency = "USD"
        }
        if(res?.data) {
            let standard,premium
            for (let each of  res?.data) {
                if (each?.plan === "Standard") {
                    for (let eachCountryPlan of each?.amount) {
                        if (eachCountryPlan?.currency === reqCurrency) {
                            standard = {
                                plan : each?.plan,
                                originalAmount : eachCountryPlan?.originalAmount,
                                amount : eachCountryPlan?.discountAmount,
                                planId : eachCountryPlan?.plan_id,
                                currency : eachCountryPlan?.currency,
                            }
                            setStandardPlan(standard)
                        }
                    }
                }
                if (each?.plan === "Premium") {
                    for (let eachCountryPlan of each?.amount) {
                        if (eachCountryPlan?.currency === reqCurrency) {
                            premium = {
                                plan : each?.plan,
                                originalAmount : eachCountryPlan?.originalAmount,
                                amount : eachCountryPlan?.discountAmount,
                                planId : eachCountryPlan?.plan_id,
                                currency : eachCountryPlan?.currency,
                            }
                            setPremiumPlan(premium)
                        }
                    }
                }
            }
            switch (subscription) {
                case 'Basic':
                    setActivePlan(standard)
                    break;
                case 'Standard':
                    setActivePlan(premium)
                    break;
                default:
                    break;
            }
            setShowSubscriptionModel(true)
        }
        } catch (error) {
        console.log(error)
        }
        setLoader(false)
    }
    const handleChangePlan = () => {
        if(activePlan?.plan === 'Standard'){
            setActivePlan(premiumPlan)
        }else{
            setActivePlan(standardPlan)
        }
    }
    const completeTask = () => {
        setShowSubscriptionModel(false)
        getData()
    }
    //subscription update end

    // useEffect(()=>{
    //     const timeOut = setTimeout(()=>{
    //         getData()
    //     },1500)
    //     return ()=>{
    //         clearTimeout(timeOut)
    //     }
    // },[searchTerm])

    const searchLeadData = (e) => {
        setSearchTerm(e?.target?.value)
    }

    return(
        <>
            <div className="dashboardRecentEventsSection">
                <div className="d-flex flex-row justify-content-between flex-wrap">
                    <div className="d-flex flex-row flex-wrap">
                        {/* {leadManagementTitles()} */}
                        <span className={`SpotLetSubHead cursor leads-type-heading mx-2`}> {activeSection === "Contact Host" ? "Contact User" : activeSection} ({getCountValue(setActiveSectionText(activeSection))}) </span>
                    </div>
                    {
                    ( !(subscription === "Basic" && activeSection === "Contact Host") ) && (
                        <div className="card-toolbar me-3">
                            <LeadsFilter filterFormData={handleFilterData} filterData = {filterData}/>
                        </div>
                    )}
                </div>
                {
                    ( !(subscription === "Basic" && activeSection === "Contact Host") ) && (
                        <div className="container-fluid mt-2">
                            <div className="row" >
                                <div className="col-12 col-md-10 col-lg-8 col-xl-5 m-0 p-0">
                                    {leadsDetailsTable(creditsDetails)}
                                </div>
                            </div>
                            {/* <h1>Leads Details</h1> */}
                            {/* <LeadsDetailsTable leadsDetails={leadsDetails} /> */}
                        </div>
                    )
                }
                {
                  <div className="table-responsive mt-3">
                    {/* <Table columns={columns} data={bookings} /> */}
                    {
                        (subscription === "Basic" &&  activeSection === "Contact Host") ? (
                            <div className="contact-host-table-container" style={{minHeight : "200px"}} >
                                {
                                    getCountValue("Contact Host") > 0 ? (
                                    <>
                                        <div className="blur-table" >
                                            {getView()}
                                        </div>
                                        <div className="d-flex w-100 flex-column align-items-center">
                                            <div className="p-3 d-flex flex-column contact-host-upgrade-sec">
                                                {/* <span className="dashboardRecentEventsSectionHeading">Contact Host</span> */}
                                                <span>Upgrade to view the enquiries.</span>
                                                <div className="d-flex w-100 flex-column align-items-center" >
                                                    <button type="button" className="btn btn-primary" onClick={onClickUpgrade} >Upgrade</button>
                                                </div>
                                            </div>
                                        </div>
                                    </>
                                    ) : (
                                        <div className="p-3 d-flex flex-row justify-content-center" >
                                            <span className="SpotLetMainHead"> No Leads Available </span>
                                        </div>
                                    )
                                 } 
                            </div>
                        ) : (
                        getView()
                        )
                    }
                  </div>
                }
                {/* {locationClick()} */}
                {/* {subscription === 'Basic' ? (
                    <div className="d-flex flex-column">
                        <span className="dashboardRecentEventsSectionHeading">Get in touch</span>
                        <span>You are not subscribed to any plan. Please upgrade.</span>
                        <div>
                            <button type="button" className="btn btn-primary" onClick={onClickUpgrade} >Upgrade</button>
                        </div>
                    </div>
                ) : getInTouch()}
                {bookingReuests()} */}
                {  !(subscription === "Basic" &&  activeSection === "Contact Host")  && paginationSection()}
            </div>
            {showSubscriptionModel && (
                <SubscriptionModal
                    show={showSubscriptionModel}
                    onHide={()=>setShowSubscriptionModel(false)}
                    plan ={activePlan}
                    handleChangePlan={handleChangePlan}
                    completeTask={completeTask}
                    title = {'Upgrade Plan'}
                    listingId = {data}
                />
            )}
             {loader && <PageLoader />}
        </>
    )
}
export default Leads;