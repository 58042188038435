import "./Notification.css"
import { useLocation } from 'react-router-dom';
import { Header } from "../../components/NewHeader/Header";
import { Footer } from "../../components/Footer";
import logo from "../../assets/NewImages/Header/Favicon.ico"
import { RiDeleteBinLine } from "react-icons/ri";
import { LiaCheckDoubleSolid } from "react-icons/lia";
import { BiSort } from "react-icons/bi";
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { useState,useEffect,useRef } from "react";
import { getNotifications, markNotificationAsRead,deleteNotification } from "../../apis/api";
import { useAuthStore } from "../../store/auth";
import DOMPurify from 'dompurify'
import { PageLoader } from "../../components/PageLoader";
import { NotificationModal } from "./NotificationModal";
// import { Sidebar } from "../../components/Sidebar";
import { ConfirmationModel } from "../../components/Modal/ConfirmationModel";
import { MdKeyboardArrowDown } from "react-icons/md";
import { MdKeyboardArrowUp } from "react-icons/md";
import moment from 'moment';

const sortOptions = [
    'Latest first',
    'Oldest first',
  ];

const Notification = () => {
    const { userProfile } = useAuthStore();
    const location = useLocation();
    const { unread, id } = location.state || {};
    const tempType = unread ? 'Unread' : 'Total'
    const [requestedId,setRequestedId] = useState(id)
    const [notifications,setNotifications] = useState([])
    const [totalNotificationCount,setTotalNotificationCount] = useState(null)
    const [readNotificationCount,setReadNotificationCount] = useState(null)
    const [unreadNotificationCount,setUnreadNotificationCount] = useState(null)
    const [type,setType] = useState(tempType)
    const [search,setSearch] = useState('')
    const [sort,setSort] = useState(-1)
    const [selected,setSelected] = useState([])
    const [openedNotification,setOpenedNotificaiton] = useState('')
    const [notificationOpen,setNotificationOpen] = useState(false)
    const [loader, setLoader] = useState(true);
    const [showNotificationModal, setShowNotificationModal] = useState(false);
    const [showConfirmationModel, setShowConfirmationModel] = useState(false);
    const [confirmationTitle, setConfirmationTitle] = useState("");
    const [confirmationBody, setConfirmationBody] = useState("");
    const [trigger, setTrigger] = useState("");
    const [deleteItem, setDeleteItem] = useState("");
    const [selectAll, setSelectAll] = useState(false);
    function findObjectById(data, idToCheck) {
        for (const key in data) {
          const found = data[key].find(item => item.id === idToCheck);
          if (found) {
            return found;
          }
        }
        return null; // Return null if not found
      }

    const getData = async () => {
        setLoader(true)
        try{
            const resp = await getNotifications({id : userProfile?._id,type,sort,search})
            setNotifications(resp?.data?.groupedNotifications)
            setTotalNotificationCount(resp?.data?.total)
            setReadNotificationCount(resp?.data?.read)
            setUnreadNotificationCount(resp?.data?.unread)
            if(requestedId){
                const result = findObjectById(resp?.data?.groupedNotifications, requestedId);
                setNotificationOpen(true)
                setOpenedNotificaiton(result)
                setRequestedId(null)
                if(!result.read){
                    setLoader(true)
                    try{
                        await markNotificationAsRead({id : userProfile?._id, notif : [result?.id]});
                        getData()
                    }catch(error){
                        console.log(error)
                    }
                    setLoader(false)
                }
            }
        }catch(error){
            console.log(error)
        }
        setLoader(false)
    }
    const onChangeType = (value) => {
        setType(value)
    }
    const handleSearch = (e) => {
        setSearch(e.target.value)
    }
    const onCheck = (event,id) => {
        event.stopPropagation()
        if(selected.includes(id)){
            const data = selected.filter(item => item !== id);
            setSelected(data)
        }else{
            setSelected([...selected,id])
        }
    }
    const handleOpenNotification = async (e,data) => {
        // e.stopPropagation();
        setOpenedNotificaiton(data)
        setNotificationOpen(true)
        if(window.innerWidth < 992){
            setShowNotificationModal(true)
        }
        if(!data?.read){
            // setLoader(true)
            try{
                await markNotificationAsRead({id : userProfile?._id, notif : [data?.id]});
                getData()
            }catch(error){
                console.log(error)
            }
            // setLoader(false)
        }
    }
    const getConfirmation = (item,text,event,id) => {
        if(event) event.stopPropagation();
        if(id) setDeleteItem(id)
        setTrigger(item)
        setConfirmationTitle("Notifications");
        setConfirmationBody(text);
        setShowConfirmationModel(true);
    }
    const handleTrigger = () => {
        switch (trigger) {
            case 'markAll':
                markAll()
                break;
            case 'markSelected':
                markSelected()
                break;
            case 'deleteSelected':
                deleteSelected()
                break;
            case 'deleteAll':
                deleteAll()
                break;
            case 'deleteSingleNotification':
                deleteSingleNotification()
                break;
        
            default:
                break;
        }
        setShowConfirmationModel(false)
        setTrigger('')
    }
    const markAll = async () => {
        setLoader(true)
        try{
            await markNotificationAsRead({id : userProfile?._id,markAll : true});
            setSelected([])
            getData()
        }catch(error){
            console.log(error)
        }
        setLoader(false)
    }
    const markSelected = async () => {
            setLoader(true)
            try{
                await markNotificationAsRead({id : userProfile?._id,notif : selected});
                setSelected([])
                getData()
            }catch(error){
                console.log(error)
            }
            setLoader(false)
    }
    const deleteSelected = async () => {
            setLoader(true)
            try{
                await deleteNotification({id : userProfile?._id,notif : selected});
                setSelected([])
                getData()
            }catch(error){
                console.log(error)
            }
            setLoader(false)
    }
    const deleteAll = async () => {
        setLoader(true)
        try{
            await markNotificationAsRead({id : userProfile?._id,markAll : true});
            setSelected([])
            getData()
        }catch(error){
            console.log(error)
        }
        setLoader(false)
    }
    const deleteSingleNotification = async () => {
            try{
                await deleteNotification({id : userProfile?._id, notif : [deleteItem]});
                getData()
                if(openedNotification) setOpenedNotificaiton('')
                if(notificationOpen) setNotificationOpen(false)
                setDeleteItem('')
            }catch(error){z
                console.log(error)
            }
    }
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = (e) => {
        setAnchorEl(null);
        if(e.target.innerText === 'Latest first' && sort === -1) return null
        if(e.target.innerText === 'Oldest first' && sort === 1) return null
        if(e.target.innerText === 'Latest first')  setSort(-1)
        if(e.target.innerText === 'Oldest first')  setSort(1)
    };
    const handleSelectAll = () => {
        setSelectAll(!selectAll)
        let tempSelected = []
        if(!selectAll){
            tempSelected = Object.values(notifications).reduce((acc, currentArray) => {
                const ids = currentArray.map(item => item.id);
                return acc.concat(ids);
              }, []);
        }
        setSelected(tempSelected)
    }
    const eachNotification = (main,data) => {
        return(
            <div className={`notification_eachNotification ${data.id === openedNotification.id && 'notification_opened'}`} onClick={(e)=>handleOpenNotification(e,data)}>
                <div className="notification_eachNotification_check">
                    <input
                        id = {data?.id}
                        type="checkbox"
                        className="notification_checkbox"
                        checked={selected.includes(data?.id)}
                        onClick={(event) => onCheck(event,data?.id)}
                    />
                </div>
                <div className={`notification_eachNotification_right `}>
                    <span className={!data?.read ? 'SpotLetContent notification_eachNotification_unread_main' : 'SpotLetContent'}>{data?.header}</span>
                    <div className="notification_eachNotification_right_sub">
                    {/* {!notificationOpen &&  (
                        <div className="d-flex justify-content-start SpotLetContent" style={{width :'33.3%'}}>
                            <span className={!data?.read && 'notification_eachNotification_unread_sub'} dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(truncateHtml(data?.text, 100)) }}></span>
                        </div>
                    )} */}
                        <div className="SpotLetContent" style={{width :'33.3%'}}>
                            {(main === 'Today' || main === 'Yesterday') ? (
                                <span className={!data?.read && 'notification_eachNotification_unread_sub'}>{moment(data?.dateTime).format('hh:mm A')}</span>
                            ) : (
                                <span className={!data?.read && 'notification_eachNotification_unread_sub'}>{moment(data?.dateTime).format('MMM D')}{' '}{moment(data?.dateTime).format('hh:mm A')}</span>
                            )}
                        </div>
                        <div className="notification_eachNotification_right_sub_delete"  style={{width :'33.3%'}}>
                            <RiDeleteBinLine onClick={(e)=>getConfirmation('deleteSingleNotification','Are you sure you want to delete notification ?',e,data?.id)} />
                        </div>
                    </div>
                </div>
            </div>
        )
    }
    const truncateHtml = (html, maxLength) => {
        const div = document.createElement('div');
        div.innerHTML = html;
        let truncated = div.textContent || div.innerText || "";
        if (truncated.length > maxLength) {
          truncated = truncated.substring(0, maxLength) + '...';
        }
        return truncated;
      };
    // useEffect(()=>{
    //     setLoader(true)
    //     getData()
    // },[])
    useEffect(()=>{
        const timeOut = setTimeout(()=>{
            getData()
        }, 1500)
        return ()=>{
            clearTimeout(timeOut)
        }
    },[search,type,sort, userProfile])

    //dropdown start
    const [showDropdown, setShowDropdown] = useState(false);
    const [selectedIndex, setSelectedIndex] = useState(-1);
    const [options] = useState(['All', 'Read', 'Unread']);
    const dropdownRef = useRef(null);

    // for dropdown close on clicking outside the focus
    useEffect(() => {
        const handleClickOutside = (event) => {
          event.stopPropagation();
          if (
            dropdownRef.current && 
            !dropdownRef.current.contains(event.target)
          ) {
            setShowDropdown(false);
          }
        };
      
          document.addEventListener('mousedown', handleClickOutside);
      
          return () => {
            document.removeEventListener('mousedown', handleClickOutside);
          };
        }, [dropdownRef]);

    useEffect(() => {
        const handleKeyDown = (event) => {
        if (!showDropdown) return;

        if (event.key === 'ArrowDown') {
            setSelectedIndex((prevIndex) =>
            prevIndex < options.length - 1 ? prevIndex + 1 : 0
            );
        } else if (event.key === 'ArrowUp') {
            setSelectedIndex((prevIndex) =>
            prevIndex > 0 ? prevIndex - 1 : options.length - 1
            );
        } else if (event.key === 'Enter' && selectedIndex >= 0) {
            alert(`Selected: ${options[selectedIndex]}`);
            setShowDropdown(false);
        }
        };

        document.addEventListener('keydown', handleKeyDown);

        return () => {
        document.removeEventListener('keydown', handleKeyDown);
        };
    }, [showDropdown, selectedIndex, options]);

    const handleIconClick = () => {
        setShowDropdown((prev) => !prev);
    };

    const handleOptionClick = (index) => {
        setShowDropdown(false);
        let tempSelected = []
        if(index == 0){
            tempSelected = Object.values(notifications).reduce((acc, currentArray) => {
                const ids = currentArray.map(item => item.id);
                return acc.concat(ids);
              }, []);
        }else if(index === 1){
            tempSelected = Object.values(notifications).reduce((acc, currentArray) => {
                currentArray.forEach(item => {
                  if (item.read) {
                    acc.push(item.id);
                  }
                });
                return acc;
              }, []);
        }else{
            tempSelected = Object.values(notifications).reduce((acc, currentArray) => {
                currentArray.forEach(item => {
                  if (!item.read) {
                    acc.push(item.id);
                  }
                });
                return acc;
              }, []);
        }
        setSelected(tempSelected)
        setSelectAll(true)
    };
    //dropdown end
    return(
        <>
        {/* <div className="notification-page-main-container"> */}
            <Header />
            <div className="container-fluid" style={{height : "calc(100vh - 70px )"}}>
                <div className='row h-100'>
                    {/* <div className={`${notificationOpen ? 'col-lg-4' : 'col-lg-12'}`}> */}
                    <div className="col-lg-4">
                        <div className="notification_main_section">
                            <div>
                                <h3 className="etitle SpotLetMainHead">Notifications</h3>
                            </div>
                            <div className="notification_section_head">
                                <div className="notificationEventTypeContainer">
                                    <div onClick={()=>onChangeType('Total')} className={type == 'Total' ? `SpotLetSubHead notificationEventTypeButtonActive` : `SpotLetSubHead notificationEventTypeButton`}>Total : {totalNotificationCount}</div>
                                    <div onClick={()=>onChangeType('Unread')} className={type == 'Unread' ? `SpotLetSubHead notificationEventTypeButtonActive` : `SpotLetSubHead notificationEventTypeButton`}>Unread : {unreadNotificationCount}</div>
                                    <div onClick={()=>onChangeType('Read')} className={type == 'Read' ? `SpotLetSubHead notificationEventTypeButtonActive` : `SpotLetSubHead notificationEventTypeButton`}>Read : {readNotificationCount}</div>
                                </div>
                            </div>
                            <div className="notification_search_section">
                                <input
                                    type="text"
                                    className="textFiled w-100 mb-2"
                                    value={search}
                                    onChange={handleSearch}
                                    placeholder="Search..."
                                />
                            </div>
                            <div className="d-flex flex-row justify-content-between align-items-center mb-2">
                                    <div className="notification_selection_Section">
                                        <div className="d-flex flex-row justify-content-center align-items-center">
                                            <input
                                                type="checkbox"
                                                className="amenities-checkbox"
                                                style={{marginRight : '0px',marginLeft : '7px'}}
                                                checked={selectAll}
                                                onClick={handleSelectAll}
                                            />
                                            <div>
                                            <div onClick={handleIconClick} style={{ cursor: 'pointer',marginRight : '5px' }} className="d-flex flex-row justify-content-center align-items-center">
                                                {/* Conditionally render the arrow icon */}
                                                {showDropdown ? <MdKeyboardArrowUp /> : <MdKeyboardArrowDown />}
                                            </div>
                                            {showDropdown && (
                                                <ul className="notificationDropdown" ref={dropdownRef}>
                                                {options.map((option, index) => (
                                                <li
                                                    key={index}
                                                    onClick={() => handleOptionClick(index)}
                                                    className={`notificationDropdown-option SpotLetContent ${index === selectedIndex ? 'selected' : ''}`}
                                                >
                                                    {option}
                                                </li>
                                                ))}
                                            </ul>
                                    
                                            )}
                                            </div>
                                        </div>
                                        {selected.length > 0 && (
                                            <>
                                                <span className="SpotLetContent me-2" onClick={()=>getConfirmation('markSelected','Are you sure you want to mark the selected notifications as read?')}><LiaCheckDoubleSolid />{' '}Mark as read</span>
                                                <span className="SpotLetContent" onClick={()=>getConfirmation('deleteSelected','Are you sure you want to delete the selected notifications?')} ><RiDeleteBinLine/>{' '} Delete</span>
                                            </>
                                        )}
                                    </div>
                                <div className="notificationEventTypeButton mx-0">
                                    <span className="SpotLetContent" onClick={handleClick}><BiSort />{' '}Sort by</span>
                                    <Menu
                                        id="long-menu"
                                        MenuListProps={{
                                        'aria-labelledby': 'long-button',
                                        }}
                                        anchorEl={anchorEl}
                                        open={open}
                                        onClose={handleClose}
                                        PaperProps={{
                                        style: {
                                            maxHeight: 48 * 4.5,
                                            width: '20ch',
                                        },
                                        }}
                                    >
                                        {sortOptions.map((option) => (
                                        <MenuItem key={option} selected={option === 'Letest first'} onClick={handleClose}>
                                            {option}
                                        </MenuItem>
                                        ))}
                                    </Menu>
                                </div>
                            </div>
                            <div className="notification_all_section">
                                {Object.keys(notifications).map((main) => {
                                    return(
                                        <div>
                                            <div className="notification_main_date_container">
                                                <span>{main}</span>
                                            </div>
                                            {notifications?.[main]?.map((each) => {
                                                return eachNotification(main,each)
                                            })}
                                        </div>
                                    )
                                })}
                            </div>
                        </div>
                    </div>
                    {notificationOpen && (
                        <div className="col-lg-8 d-flex flex-row jutify-content-start align-items-start d-lg-flex d-none" style={{marginTop : '80px'}}>
                            <div className="notification_open_cotainer">
                                <div className="d-flex flex-row">
                                    <img src={logo} alt="avatar" className="avatar"/>
                                    <div className="w-100 d-flex flex-row justify-content-between">
                                        <div className="d-flex flex-column">
                                            <span className="notification_eachNotification_unread_main SpotLetSubHead">{openedNotification?.header}</span>
                                            <span className="SpotLetContent">{moment(openedNotification?.dateTime).format('MMM D')}{' '}{moment(openedNotification?.dateTime).format('hh:mm A')}</span>
                                        </div>
                                        <div className="cursor"><RiDeleteBinLine onClick={(e)=>getConfirmation('deleteSingleNotification','Are you sure you want to delete notification ?',e,openedNotification?.id)} /></div>
                                    </div>
                                </div>
                                <div>
                                    <span className="SpotLetContent" dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(openedNotification?.text) }}></span>
                                </div>
                            </div>
                        </div>
                    )} 
                    { (totalNotificationCount && !notificationOpen) && (
                    <div className="col-lg-8 d-flex flex-row jutify-content-center align-items-start d-lg-flex d-none" style={{marginTop : '80px'}}>
                        <div className="notification_open_container w-100 d-flex justify-content-center">
                            <span className="SpotLetMainHead"> No conversations selected </span>
                        </div>
                    </div>
                    ) } 
                </div>
            </div>
            {showNotificationModal && (
                < NotificationModal 
                    show={showNotificationModal}
                    title={'Notification Details'}
                    body={openedNotification}
                    handleClose={()=> {
                        setShowNotificationModal(false)
                        setNotificationOpen(false)
                        setOpenedNotificaiton('')
                    }}
                    deleteSingleNotification={(e,id)=> {
                        getConfirmation('deleteSingleNotification','Are you sure you want to delete notification ?',e,id)
                        setShowNotificationModal(false)
                        setNotificationOpen(false)
                        setOpenedNotificaiton('')
                    }}
                />
            )}
             {showConfirmationModel && (
                <ConfirmationModel
                show={showConfirmationModel}
                handleOk={handleTrigger}
                handleClose={() => {
                    setTrigger('')
                    setDeleteItem('')
                    setShowConfirmationModel(false)
                }}
                title={confirmationTitle}
                body={confirmationBody}
                />
            )}
            {loader && <PageLoader />}
            {/* <Footer/> */}
        {/* </div> */}
        </>    
    )
}
export default Notification;