import { useEffect } from "react";

export const Payment = () => {

  useEffect(() => {
    // call api or anything
    console.log("Payment completed");
    //alert("Payment completed");

    setTimeout(() => {
      window.opener = null;
      window.open("", "_self");
      window.close();
    }, 2000);

    

 });

  return <>
    <h1>BillDesk</h1>
    <div>Payment completed, you can close the tab</div>
  </>;
};
