import Rating from '@mui/material/Rating';
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

const Testimonials = ({reviews, length}) => {

    const eachCard = (data, index) => {
        return(
            <div className="testimonialCard" id = {index} >
                    <div className="cardTopSection">
                        <img src = {data?.profile_photo_url} className="testimonialCardImg" alt = "userImg"/>
                        <Rating
                            name="readOnly"
                            value={data?.rating}
                            readOnly
                            style={{ marginRight: '6px' }}
                        />
                    </div>
                    <p className='testimonialsText SpotLetContent' > {data?.text} </p>
                    <h6 className='SpotLetSubHead'> {data?.author_name} </h6>
                    <p className='SpotLetContent'> {data?.relative_time_description} </p>
            </div>
        )
    }

      const responsive = {
        desktop: {
          "breakpoint":  { max: 3000, min: 1200 },
          items: 3, // length > 3 ? 3 : length ,
          slidesToSlide: 1 // optional, default to 1.
        },
        tablet: {
          "breakpoint":  { max: 1200, min: 768 },
          items: 2, //length > 2 ? 2 : length,
          slidesToSlide: 1 // optional, default to 1.
        },
        mobile: {
          "breakpoint":  { max: 768, min: 0 },
          items: 1,
          slidesToSlide: 1 // optional, default to 1.
        }
      };

    return(
        <div className="testimonialsContainer" >
            <h1 className="testimonialsHead SpotLetMainHead" id="propertyPage9"> Google Reviews </h1>
            <div className="testimonialsSlider" >
                <Carousel 
                swipeable={true}
                draggable={true}
                showDots={false}
                responsive={responsive}
                ssr={true} // means to render carousel on server-side.
                infinite={true}
                autoPlay= {false} 
                containerClass="carousel-container"
                itemClass="carousel-item-padding-40-px"
                >
                    {
                        reviews?.map((each,index) => eachCard(each, index))
                    }
                </Carousel>
            </div>
        </div>
    )
}

export default Testimonials