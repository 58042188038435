import { Modal } from "react-bootstrap";

const { Header, Title, Body } = Modal;

export const SeeMore = ({
    handleClose,
    show,
    title,
    data
}) => {
    console.log(data)

    return (
        <Modal
            show={show}
            onHide={handleClose}
            backdrop="static"
            keyboard={false}
            size="md"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Header closeButton>
                <Title>{title}</Title>
            </Header>
            <Body className="verify-email-modal-body">
                <ul>
                    {
                        data?.map((each) => <li> {each} </li> )
                    }
                </ul>
            </Body>
        </Modal>
    );

}