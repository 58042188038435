
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import whyChooseUs from "../../assets/NewImages/Home/landingpage-img01.webp"
import ExcitingIcon from "../../assets/NewImages/Home/Exciting Spaces icon.svg";
import TransparentIcon from "../../assets/NewImages/Home/Transparent prices icon.svg";
import SimplifiedIcon from "../../assets/NewImages/Home/Simpified Booking icons.svg";

const WhyChooseUs = () => {
    return(
        <Box className="why-choose-us">
            <Typography className="text-stroke SpotLetMainHead" variant="h2">
              Choose us
            </Typography>
            <Typography className="mar-t60 SpotLetMainHead" variant="h2">
              Why Choose Us
            </Typography>
            <Box className="why-container">
              <figure>
                <img loading="lazy" src={whyChooseUs} alt="Colorful Hot Air Balloons Soaring in the Sky at Skyfest" />
                <Typography className="box SpotLetSubHead" variant="h5">
                  SpotLet offers transparency and trust
                </Typography>
              </figure>
              <article>
                <Box className="icon-content">
                  <Box className="icon-content-Choose ">
                    <img loading="lazy" src={ExcitingIcon} alt='Exciting Spaces'/>
                  </Box>
                  <Box className="content">
                    <Typography variant="subtitle1" className="SpotLetSubHead" >
                      Exciting Spaces 
                      {/* Donot Remove -- It has been placed here because while submitting any form on home page the last input el is getting triggered -- so this will be the last input el which is hidden */}
                      <label htmlFor="staticDefaultInput"></label>
                      <input value = "" id = "staticDefaultInput" style={{backgroundColor:"transparent", width:"0px", border:"none", outline:"none", opacity:"0", zIndex:"-10", position:"fixed"}} onFocus = {(e) => e.preventDefault()} />
                      {/* Donot Remove -- It has been placed here because while submitting any form on home page the last input el is getting triggered -- so this will be the last input el which is hidden */}
                      </Typography>
                    <Typography variant="body1" className="SpotLetContent">
                      Whether you’re hosting a family get-together, planning a
                      corporate event or starting an OTT series, we have the
                      perfect spaces for you to uncover!
                    </Typography>
                  </Box>
                </Box>
                <Box className="icon-content">
                  <Box className="icon-content-Choose ">
                    <img loading="lazy" src={TransparentIcon} alt='Transparent Prices'/>
                  </Box>
                  <Box className="content">
                    <Typography variant="subtitle1" className="SpotLetSubHead" >
                      Transparent Prices
                    </Typography>
                    <Typography variant="body1" className="SpotLetContent">
                      We assure you of no hidden fees in all our properties. Pay
                      for what you need and find the most cost-effective spaces
                      for your requirements.
                    </Typography>
                  </Box>
                </Box>
                <Box className="icon-content">
                  <Box className="icon-content-Choose ">
                    <img loading="lazy" src={SimplifiedIcon} alt='Simplified Bookings'/>
                  </Box>
                  <Box className="content">
                    <Typography variant="subtitle1" className="SpotLetSubHead">
                      Simplified Bookings
                    </Typography>
                    <Typography variant="body1" className="SpotLetContent">
                    At Spotlet, trust is built on transparency. We provide detailed property information, clear  pricing, and direct communication with hosts. No hidden costs or middlemen, ensuring confidence in every rental.
                    </Typography>
                  </Box>
                </Box>
              </article>
            </Box>
          </Box>
    )
}
export default WhyChooseUs