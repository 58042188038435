

import album from "../../assets/images/album.svg";
import LightGallery from 'lightgallery/react';

// import styles
import 'lightgallery/css/lightgallery.css';
// import 'lightgallery/css/lg-zoom.css';
import 'lightgallery/css/lg-thumbnail.css';

// import plugins if you need
import lgThumbnail from 'lightgallery/plugins/thumbnail';
//import lgZoom from 'lightgallery/plugins/zoom';


const SubPropertyGallery = ({locationId,data}) => {
    return(
        <div className="galleryMainContainer" style={{padding:"15px 0px 0px 15pxpx", alignItems:"start"}} >
            <span className="galleryHeading" style={{alignSelf:"center"}} >Gallery</span>
            <div className="gallerySection">
                <span className="gallerySectionHeading SpotLetSubHead">{locationId}</span>
                {/* <LightGallery
                    speed={500}
                    download={false}    
                    plugins={[lgThumbnail, ]}  //lgZoom
                    elementClassNames="galleryEachCardSection"
                > */}
                <div className="galleryEachCardSection" >
                    {data?.[locationId]?.slice(0,2).map((item,i)=> {
                        return(
                            <a href={item?.image}  >
                                <div key={i}  className="galleryImageContainer" style={{position : 'relative',display : i > 3 ? 'none' : 'flex'}}>
                                        {/* onClick={() => handleShowGallery(data?.[locationId])} */}
                                        <img src={item?.image} alt={item?.title || item?.name} className="galleryImage"/>
                                        
                                    {i===3 && (
                                        <div className="galleyShowAllContainer cursor" >
                                            <img src={album} alt='album' />
                                            {/* <span>{`Show All(${data?.[locationId]?.length})`}</span> */}
                                        </div>
                                    )}
                                    <span className="galleryImageTitle">{item?.title}</span>
                                </div>
                            </a>
                        )
                    })} 
                    </div>
                {/* </LightGallery> */}
                {data && Object.keys(data)?.map((images) => {
                    if(images === locationId){
                        return null
                    }else{
                        return(
                            <>
                                <span className="gallerySectionHeading SpotLetSubHead">{images}</span>
                                {/* <LightGallery
                                    speed={500}
                                    download={false}    
                                    plugins={[lgThumbnail, ]}  //lgZoom
                                    elementClassNames="galleryEachCardSection"
                                > */}
                            <div className="galleryEachCardSection" >
                                    {data?.[images]?.slice(0,2).map((item,i)=> {
                                        return(
                                            <a href={item?.image} >
                                                <div key={i}  className="galleryImageContainer">
                                                    <img src={item?.image} alt={item?.name} className="galleryImage"/>
                                                    <span className="galleryImageTitle">{item?.title}</span>
                                                </div>
                                            </a>
                                        )
                                    })} 
                              </div>      
                                {/* </LightGallery> */}
                                </>
                        )
                    }

                })}
            </div>
        </div>
    )
};
export default SubPropertyGallery;