import { useEffect, useMemo, useState } from "react";
import Table from "./Table";
import ResponsiveTable from "./responsiveTable";
import { getBookingRequests, updateBookingStatus, changeBookingStatus } from "../apis/api";
import { useAuthStore } from "../store/auth";
import dayjs from "dayjs";
import { Button } from "react-bootstrap";
import { useNavigate } from 'react-router-dom';
import { BookingPopup } from '../components/Modal/BookingPopup';
import { BookingRejectPopup } from '../components/Modal/BookingRejectPopup';
import moment from "moment";
import { PageLoader } from "./PageLoader";

export const BookingsRequestsTable = ({locationId, setBookingRequestsCount}) => {
  const { userProfile } = useAuthStore();
  const navigate = useNavigate();
  const COLUMNS = ['Booking ID','Location ID','Status','Date','Event','Action']
  const [rows,setRows] = useState([])
  const [bookings, setBookings] = useState([]);
  const [showBookingPopup, setsShowBookingPopup] = useState(false)
  const [showBookingRejectPopup, setsShowBookingRejectPopup] = useState(false)
  const [showBookingCancelPopup, setsShowBookingCancelPopup] = useState(false)
  const [booking, setBooking] = useState({})
  const [loader, setLoader] = useState(false)
  const [init, setInit] = useState(false)

  const handleTableData = (data) => {
    Promise.all(data?.map((item)=>{
      let actions = ['details','Approve','Reject']
      if(item?.status === 'Approved'){
        const updatedAt = new Date(item?.updatedAt);
        const currentTime = new Date();
        const diffMs = currentTime - updatedAt;
        const diffMinutes = diffMs / (1000 * 60);
        if(diffMinutes >= 30){
          actions.push('Host Cancel')
        }
      }
      return {
        bookingId : item?.bookingId,
        locationId : item?.property_id,
        status : item?.status,
        date : dayjs(item?.createdAt).format("DD/MM/YYYY"),
        Event : item?.event,
        action : actions,
      }
    })).then((values)=> setRows(values))
  }
  useEffect(() => {
    (async () => {
      if (userProfile) {
        setLoader(true)
        try {
          const resp = await getBookingRequests(userProfile._id,locationId);
          handleTableData(resp?.data)
          setBookings(resp.data);
          if (setBookingRequestsCount) {
            setBookingRequestsCount(resp?.data?.length);
          }
        } catch (error) {
          console.log(error);
        }
        setLoader(false)
        setInit(true)
      }
    })();
  }, [userProfile,locationId]);


  const handleApprove = async () => {
    setLoader(true)
    const data = {
      bookingId: booking?._id,
      currentStatus: "Booking Requested",
      newStatus: "Approved",
      reason: ''
    };

    try {
      await changeBookingStatus(data);
      setsShowBookingPopup(false);
      const resp = await getBookingRequests(userProfile._id);
      setBookings(resp.data);
      handleTableData(resp?.data)
    } catch (e) {
      console.log(e);
    }
    setLoader(false)
  }

  const handleReject = async (rejectReason) => {
    setLoader(true)
    const data = {
      bookingId: booking?._id,
      currentStatus: "Booking Requested",
      newStatus: "Rejected",
      reason: rejectReason
    };

    try {
      await changeBookingStatus(data);
      setsShowBookingRejectPopup(false);
      const resp = await getBookingRequests(userProfile._id);
      setBookings(resp.data);
      handleTableData(resp?.data)
    } catch (e) {
      console.log(e);
    }
    setLoader(false)
  }

  const handleCancel = async (rejectReason) => {
    setLoader(true)
    const data = {
      bookingId: booking?._id,
      currentStatus: "Approved",
      newStatus: "Cancelled",
      reason: rejectReason
    };

    try {
      await changeBookingStatus(data);
      setsShowBookingCancelPopup(false);
      const resp = await getBookingRequests(userProfile._id);
      setBookings(resp.data);
      handleTableData(resp?.data)
    } catch (e) {
      console.log(e);
    }
    setLoader(false)
  }

  const columns = useMemo(
    () => [
      {
        Header: "Booking ID",
        accessor: "bookingId",
      },
      {
        Header: "Location ID",
        accessor: "property_id",
      },
      {
        Header: "Status",
        accessor: "status",
      },
      {
        Header: "Date",
        accessor: "createdAt",
        Cell: ({ row: { original } }) => {
          return dayjs(original?.createdAt).format(
            "DD-MM-YYYY"
          );
        },
      },
      {
        Header: "Event",
        accessor: "event",
      },
      // {
      //   Header: "Time Duration",
      //   accessor: "show5",
      //   Cell: ({ row: { original } }) => {
      //     return original?.bookedTimeDates[0]?.no_of_hours ? original?.bookedTimeDates[0]?.no_of_hours + " hrs" : "" + " hrs";
      //   },
      // },
      // {
      //   Header: "Total Amount",
      //   accessor: "totalPayment",
      // },
      {
        Header: "Action",
        accessor: "",
        Cell: ({ row: { original } }) => {

          // const eventData = original?.bookedTimeDates[0];

          // // Parse the date and start time
          // const eventDate = new Date(eventData.date);
          // const startTime = moment(eventData.start, 'hh:mm a');

          // // Set the start time to the date
          // eventDate.setHours(startTime.hours(), startTime.minutes(), 0, 0);

          // // Add the number of hours
          // // eventDate.setHours(eventDate.getHours() + parseInt(eventData.no_of_hours));

          return (
            <div className="d-flex justify-content-center align-items-center">
              <Button variant="danger" onClick={() => navigate(`/booking-details/${original?.bookingId}`)} className="btn btn-theme btn-detail">
                Details
              </Button>
              {/* {original?.status === "Booking Requested" && (new Date() < eventDate) && (
                <button type="button" className="btn btn-theme btn-detail" onClick={() => { setsShowBookingPopup(true), setBooking(original) }}>
                  Approve
                </button>
              )}
              {original?.status === "Booking Requested" && (new Date() < eventDate) && (
                <button type="button" className="btn btn-theme btn-detail" onClick={() => { setsShowBookingRejectPopup(true), setBooking(original) }}>
                  Reject
                </button>
              )} */}
            </div>
          );
        },
      },
    ],
    []
  );

  return (
      <>
        {init && (
          <>
            {bookings?.length === 0 ? (
              <div className="d-flex flex-column justify-content-center align-items-center">
                <span className="SpotLetSubHead">No Booking Requests Found</span>
              </div>
            ) : (
              <>
                <div class="table-responsive">
                  {/* <Table columns={columns} data={bookings} /> */}
                  <ResponsiveTable rows={rows}  columns={COLUMNS} 
                    approve={(bookingId)=>{
                      let data;
                      bookings?.map((item)=> {
                        if(item?.bookingId === bookingId){
                          data = item
                        }
                      })
                      setsShowBookingPopup(true)
                      setBooking(data)
                    }}
                    reject={(bookingId)=>{
                      let data;
                      bookings?.map((item)=> {
                        if(item?.bookingId === bookingId){
                          data = item
                        }
                      })
                      setsShowBookingRejectPopup(true)
                      setBooking(data)
                    }}
                    cancelByHost={(bookingId)=>{
                      let data;
                      bookings?.map((item)=> {
                        if(item?.bookingId === bookingId){
                          data = item
                        }
                      })
                      setsShowBookingCancelPopup(true)
                      setBooking(data)
                    }}
                  />
                </div>
                {showBookingPopup && (
                  <BookingPopup
                    show={showBookingPopup}
                    handleClose={() => setsShowBookingPopup(false)}
                    handleOk={handleApprove}
                    bookings={booking}
                  />
                )}
                {showBookingRejectPopup && (
                  <BookingRejectPopup
                    type='Reject'
                    show={showBookingRejectPopup}
                    handleClose={() => setsShowBookingRejectPopup(false)}
                    handleOk={(rejectReason) => handleReject(rejectReason)}
                    bookings={booking}
                  />
                )}
                {showBookingCancelPopup && (
                  <BookingRejectPopup
                    type='Continue'
                    show={showBookingCancelPopup}
                    handleClose={() => setsShowBookingCancelPopup(false)}
                    handleOk={(rejectReason) => handleCancel(rejectReason)}
                    bookings={booking}
                  />
                )}
              </>
            )}
          </>
        )}
        {loader && <PageLoader />}
      </>
  )
};
