import React from 'react';
import { useState, useEffect } from 'react'
import { useAuthStore } from "../store/auth";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { PageLoader } from "../components/PageLoader";
import ImageGallery from "react-image-gallery";
import AuthenitcateModal from "../utils/NewAuthentication/AuthenitcateModal";
//api
import { updateFavourites } from "../apis/api"


// Images
import fillHeart from "../assets/NewImages/Propertyinfo/fill-heart.svg";
import borderHeart from "../assets/NewImages/Propertyinfo/border-heart.svg";
import verifiedV1 from "../assets/NewImages/Propertyinfo/VerifiedV1.webp"
import film from "../assets/NewImages/Propertyinfo/film.svg";
import corp from "../assets/NewImages/Propertyinfo/eventNew.svg";
import individual from "../assets/NewImages/Propertyinfo/stayNew.svg";
import location from "../assets/NewImages/Propertyinfo/location.svg";


const PropertyInfo = React.memo(({ property, relaod, eventType,eventSub,plateType, addVerify }) => {

  const { userProfile, setUserProfile, bookingServices } = useAuthStore();
  const [favorites, setFavorites] = useState([]);
  const [loader, setLoader] = useState(false)
  const [authenticate, setAuthenticate] = useState(false)
  const [favLocation, setFavLocation] = useState('')
  const [price,setPrice] = useState('')
  const [nonVegPrice,setNonVegPrice] = useState('');
  const url = property?.locationPageUrl ? property.locationPageUrl : property?.location_id
  const string = property?.address?.area;
  const lastWord = string.split(", ").pop();
  let city = property?.address?.city;
  let area = lastWord;
  let result;
  if (city && area) {
    result = area + ", " + city;
  } else if (city) {
    result = city;
  } else if (area) {
    result = area;
  } else {
    result = "No location available";
  }
  const {pricing} = property

  const  formatNumber = (number) => {
    const suffixes = ["", "k", "M", "B", "T"];
    if (number < 1000) {
      return number.toString();
    }
    const suffixNum = Math.floor(("" + number).length / 3);
    const shortValue = number / Math.pow(1000, suffixNum);
    return shortValue + suffixes[suffixNum];
  }
  const findMinPriceObject = (priceArray) => {
    // Handle the case where the array has only one object
    if (priceArray.length === 1) {
      return setPrice(priceArray[0]);
    }
  
    // Use reduce to find the object with the minimum price
    const temp = priceArray.reduce((min, current) => {
      const minPrice = typeof min.price === 'string' ? parseFloat(min.price) : min.price;
      const currentPrice = typeof current.price === 'string' ? parseFloat(current.price) : current.price;
      
      return currentPrice < minPrice ? current : min;
    }, priceArray[0]);
    return setPrice(temp)
  };
  const calculateMinFilmPrice = () => {
    let price = []
    if (pricing?.film?.["24Hours"]?.available) {
      const priceObj = {
        type : 'film',
        tag : 'day',
        price : pricing?.film?.["24Hours"]?.price,
      }
      price.push(priceObj)
    }

    if (pricing?.film?.["12Hours"]?.available) {
      const priceObj = {
        type : 'film',
        tag : 'half day',
        price : pricing?.film?.["12Hours"]?.price,
      }
      price.push(priceObj)
    }

    if (pricing?.film?.["perHour"]?.available) {
      const priceObj = {
        type : 'film',
        tag : 'hour',
        price : pricing?.film?.["perHour"]?.price,
      }
      price.push(priceObj)
    }
    findMinPriceObject(price)
  }
  const calculateMinTvPrice = () => {
    let price = []
    if (pricing?.tv?.["24Hours"]?.available) {
      const priceObj = {
        type : 'tv',
        tag : 'day',
        price : pricing?.tv?.["24Hours"]?.price,
      }
      price.push(priceObj)
    }

    if (pricing?.tv?.["12Hours"]?.available) {
      const priceObj = {
        type : 'tv',
        tag : 'half day',
        price : pricing?.tv?.["12Hours"]?.price,
      }
      price.push(priceObj)
    }

    if (pricing?.tv?.["perHour"]?.available) {
      const priceObj = {
        type : 'tv',
        tag : 'hour',
        price : pricing?.tv?.["perHour"]?.price,
      }
      price.push(priceObj)
    }
    findMinPriceObject(price)
  }
  const calculateEventPrice = () => {
    let price = []
    if(eventSub === 'venue'){
      const priceObj = {
        type : 'event',
        tag : `${pricing?.event?.["venue"]?.minimumBookingHours} hours`,
        price : pricing?.event?.["venue"]?.price,
      }
      price.push(priceObj)
      findMinPriceObject(price)
      return null
    }
    if(eventSub === 'perHour'){
      const priceObj = {
        type : 'event',
        tag : `hour`,
        price : pricing?.event?.["perHour"]?.price,
      }
      price.push(priceObj)
      findMinPriceObject(price)
      return null
    }
    if(eventSub === 'perPlate'){
      const priceObj = {
        type : 'event',
        tag : `veg`,
        price : pricing?.event?.["perPlate"]?.vegPrice,
      }
      price.push(priceObj)
      findMinPriceObject(price)
      if(plateType !== 'pureVeg' && (!pricing?.event?.pureVeg) ){
        setNonVegPrice({
          type : 'event',
          tag : `non veg`,
          price : pricing?.event?.["perPlate"]?.nonVegPrice,
        })
      }
      return null
    }
    if (pricing?.event?.["venue"]?.available) {
      const priceObj = {
        type : 'event',
        tag : `${pricing?.event?.["venue"]?.minimumBookingHours} hours`,
        price : pricing?.event?.["venue"]?.price,
      }
      price.push(priceObj)
    }
    if (pricing?.event?.["perHour"]?.available) {
      const priceObj = {
        type : 'event',
        tag : 'hour',
        price : pricing?.event?.["perHour"]?.price,
      }
      price.push(priceObj)
    }
    if (!(pricing?.event?.["venue"]?.available || pricing?.event?.["perHour"]?.available) && pricing?.event?.["perPlate"]?.available) {
      const priceObj = {
        type : 'event',
        tag : 'veg',
        price : pricing?.event?.["perPlate"]?.vegPrice,
      }
      price.push(priceObj)
      if(pricing?.event?.perPlate?.nonVeg && (!(pricing?.event?.pureVeg)) ){
        setNonVegPrice({
          type : 'event',
          tag : `non veg`,
          price : pricing?.event?.["perPlate"]?.nonVegPrice,
        })
      }
    }
    findMinPriceObject(price)
  }
  const calculateStacationPrice = () => {
    let price = []
    if(pricing?.stay?.available){
      const priceObj = {
        type : 'staycation',
        tag : 'night',
        price : pricing?.stay?.weekdayPrice,
      }
      price.push(priceObj)
    }
    findMinPriceObject(price)
  }
  const getDefaultData = () => {
    let price = []
    if (pricing?.film?.["24Hours"]?.available) {
      const priceObj = {
        type : 'film',
        tag : 'day',
        price : pricing?.film?.["24Hours"]?.price,
      }
      price.push(priceObj)
    }
    if (pricing?.film?.["12Hours"]?.available) {
      const priceObj = {
        type : 'film',
        tag : 'half day',
        price : pricing?.film?.["12Hours"]?.price,
      }
      price.push(priceObj)
    }
    if (pricing?.film?.["perHour"]?.available) {
      const priceObj = {
        type : 'film',
        tag : 'hour',
        price : pricing?.film?.["perHour"]?.price,
      }
      price.push(priceObj)
    }
    if (pricing?.tv?.["24Hours"]?.available) {
      const priceObj = {
        type : 'tv',
        tag : 'day',
        price : pricing?.tv?.["24Hours"]?.price,
      }
      price.push(priceObj)
    }
    if (pricing?.tv?.["12Hours"]?.available) {
      const priceObj = {
        type : 'tv',
        tag : 'half day',
        price : pricing?.tv?.["12Hours"]?.price,
      }
      price.push(priceObj)
    }
    if (pricing?.tv?.["perHour"]?.available) {
      const priceObj = {
        type : 'tv',
        tag : 'hour',
        price : pricing?.tv?.["perHour"]?.price,
      }
      price.push(priceObj)
    }
    if (pricing?.event?.["venue"]?.available) {
      const priceObj = {
        type : 'event',
        tag : '8 hours',
        price : pricing?.event?.["venue"]?.price,
      }
      price.push(priceObj)
    }
    if (pricing?.event?.["perHour"]?.available) {
      const priceObj = {
        type : 'event',
        tag : 'hour',
        price : pricing?.event?.["perHour"]?.price,
      }
      price.push(priceObj)
    }
    if (!(pricing?.event?.["venue"]?.available || pricing?.event?.["perHour"]?.available) && pricing?.event?.["perPlate"]?.available) {
      const priceObj = {
        type : 'event',
        tag : 'plate',
        price : pricing?.event?.["perPlate"]?.vegPrice,
      }
      price.push(priceObj)
    }
    if(pricing?.stay?.available){
      const priceObj = {
        type : 'staycation',
        tag : 'night',
        price : pricing?.stay?.weekdayPrice,
      }
      price.push(priceObj)
    }
    findMinPriceObject(price)
  }
  const getRequiredData = () => {
    switch (eventType) {
      case 'film-shoot':
        calculateMinFilmPrice()
        break;
      case 'tv-shoot':
        calculateMinTvPrice()
        break;
      case 'event':
        calculateEventPrice()
        break;
      case 'staycation':
        calculateStacationPrice()
        break;
      default:
        getDefaultData()
        break;
    }
  }
  const updateFav = async (favorites) => {
    const data = {
      favourites: favorites,
      user_id: userProfile?._id,
    };
    if (userProfile?._id) {
      try {
        await updateFavourites(data);
        setUserProfile();
        relaod();
      } catch (error) {
        console.log(error);
      }
    }
  };

  // for setting local storage with the property tile pricing details start 
  
  // Function to set an item in localStorage with an expiration time
  function setLocalStorageWithExpiry() {
    const now = new Date();
    const item = {
      price,
      nonVegPrice,
      expiry: now.getTime() + 5 * 60 * 1000,
    };
    localStorage.setItem(`${property?.location_id}`, JSON.stringify(item));
  }
  // for setting local storage with the property tile pricing details end

  const navigatePropertyPage = () => {
    setLocalStorageWithExpiry();
    window.open(`${window.location.origin}/property/${url}`, '_blank')
  }
  const handleFavorites = (location_id) => {
    setLoader(true)
    if (userProfile) {
      if (favorites?.includes(location_id)) {
        const data = favorites.filter((element) => element !== location_id);
        setFavorites((prev) => prev.filter((element) => element !== location_id));
        updateFav(data);
      } else {
        setFavorites((prev) => [...prev, location_id]);
        updateFav([...favorites, location_id]);
      }
      setFavLocation('')
      setLoader(false)
    } else {
      setLoader(false)
      setFavLocation(location_id)
      setAuthenticate(true)
    }
  };
  const handleOk = () => {
    if(favLocation){
      setAuthenticate(false)
      handleFavorites(favLocation)
    }else{
        window.open(`${window.location.origin}/property/${url}`, '_blank')
    }
    
  }

  useEffect(()=> {
    setNonVegPrice('')
    getRequiredData()
  },[eventType,eventSub,plateType])
  useEffect(() => {
    if (userProfile) {
      setFavorites(userProfile?.favourites);
    }
  }, [userProfile]);


  const getBadgeText = (services, plan) => {
    if (services?.stay && bookingServices?.stay) {
      return "Hassle Free Booking";
    } else if (plan === "Standard") {
      return  "Recommended"
    } else if (plan === "Premium") {
      return "Highly Recommended"
    }
  }

  return (
    <div className="properties-list-container" key={property?._id} >
      <div className="properties-list" style={{ cursor: "pointer" }}>
        <div className="img-section">
          {/* {addVerify && (
            <div className="verify" title="Verified">
              <img className="verify-img" src={verifiedV1} alt='Verified' />
              <div className="verify-and-exclusive" >
                <img className="exclusive-img" src={ExclusiveV3} alt="Exclusive" />
              </div>
            </div>
          )} */}
          {
            ( (property?.subscription_id !== "Basic")  || (property?.bookingServices?.stay && bookingServices?.stay )) && (
              <div className="property-info-tile-badge SpotLetContent" >
                {getBadgeText(property?.bookingServices, property?.subscription_id)}
              </div>
            ) 
          }
          <a
            className="heart fill"
            onClick={() => handleFavorites(property?.location_id)}
          >
            <img
              src={
                favorites?.includes(property?.location_id)
                  ? fillHeart
                  : borderHeart
              }
              alt="Favorite"
            />
          </a>
          <ImageGallery 
            // items={property?.images?.map((file) => ({
            //   original: file.image,
            // }))}
            items = {property?.images?.map((file) => {
              // if (!file?.image_320) console.log(property?.location_id);
              return (
                {original : file?.image_320 ? file?.image_320 : file?.image}
              )
            } )}
            disableKeyDown= {true}
            showPlayButton={false}
            showIndex={false}
            showThumbnails={false}
            showFullscreenButton={false}
            showBullets={true}
            stopPropagation={true}
            onClick={navigatePropertyPage}
            bulletClass='bulletClass'
          // infinite = {false}
          />
        </div>
        <div
          className="properties-data search-result-property d-flex justify-content-between align-items-start"
          style={{ paddingBottom: '0px', paddingTop: '20px', marginTop: '-10px' }}
          onClick={navigatePropertyPage}
        >
          <i className="category cat3 flex-row" >
            {pricing?.event?.available && (
              <img
                src={corp}
                style={{ padding: "0px 5px 0px 5px", width:"34px", heigh:"22px" }}
                alt="Corporate"
              />
            )}
            {(pricing?.film?.available ||
              pricing?.tv?.available) && (
                <img
                  src={film}
                  style={{ padding: "0px 5px 0px 5px" }}
                  alt="Film"
                />
              )}
            {pricing?.stay?.available && (
              <img
                src={individual}
                style={{ padding: "0px 5px 0px 5px", width:"34px", heigh:"22px" }}
                alt="Individual"
              />
            )}
          </i>
          <div className="leftpart">
            <h6>#{property?.location_id}</h6>
            <div className='d-flex flex-column'>
              <div>
                <span className="rate"
                style={{ color: '#EA4335', fontWeight: "600", fontSize: '14px' }}>
                  INR.{price?.price}
                </span>
                <span className="rate" style={{ fontSize: '14px' }}>
                  /{price?.tag}
                </span>
              </div>
              {nonVegPrice && (!(pricing?.event?.["pureVeg"])) && (
                <div className='d-flex'>
                  <span className="rate"
                  style={{ color: '#EA4335', fontWeight: "600", fontSize: '14px' }}>
                    INR.{nonVegPrice?.price}
                  </span>
                  <span className="rate" style={{ fontSize: '14px' }}>
                    /{nonVegPrice?.tag}
                  </span>
                </div>
              )}
            </div>
            {/* &#8377;  */}
          </div>
          <div className="right-part mt-3 text-end">
            <span style={{ fontSize: "12px" }}>
              Views{" "}
              {property?.propertyViews ? formatNumber(property?.propertyViews) : 0}
            </span>
            <p className="code" style={{ fontSize: "14px", fontWeight: '600', marginTop: "5px" }}>{property?.location_type}</p>
          </div>
        </div>
        <div className="location d-flex justify-content-end align-items-center" onClick={navigatePropertyPage}>
          <div style={{ marginBottom: "10px" }}><img src={location} alt="Location" /></div>
          <a href={`${window.location.origin}/property/${url}`} style={{ opacity: 0, width: 0, height: 0}}>Hidden Link</a>
          <h6 style={{ marginLeft: "5px", marginRight: "5px", fontSize: "14px" }}>{result}</h6>
        </div>
      </div>
      {loader && <PageLoader />}
      {authenticate && 
          <AuthenitcateModal
              show={authenticate}
              handleSuccess={handleOk}
              handleClose={()=>setAuthenticate(false)}
              activeTab=''
          />
      }
    </div>
  );
});

export default PropertyInfo;