import { useEffect, useState } from "react";
import timingsImg from "../assets/images/timings.png"
import film from "../assets/images/film.jpeg"
import tv from "../assets/images/tv.jpeg"
import event from "../assets/images/event.jpeg"
import stay from "../assets/images/stay.jpeg"

const Timings = ({timings}) => {
    const [type, setType] = useState("");
    const [timingsDetails, setTimingsDetails] = useState();
    const [dropDownOptions,setDropDownOptions] = useState([]);
    const [image,setImage] = useState(film);

    const getImage = (value) => {
        switch (value) {
            case 'film':
                setImage(film)
                break;
            case 'tv':
                setImage(tv)
                break;
            case 'event':
                setImage(event)
                break;
            case 'stay':
                setImage(stay)
                break;
            default:
                break;
        }
    }

    const  getTypes = () => {
        if (timings) {
            const reqTypes = []
            Object.keys(timings).map((each) => {
                if(timings?.[each]?.available && !(dropDownOptions.includes(each))) {
                    if (!reqTypes?.includes(each)) {
                        reqTypes.push(each);
                       }
                    // setDropDownOptions((prev) => {
                    //        if (!reqTypes?.includes(each)) {
                    //         reqTypes.push(each);
                    //        }
                    //         // return [...prev,each]
                    // })
                    if(reqTypes.length > 0) {
                        setType(reqTypes[0])
                        getImage(reqTypes[0])
                        setDropDownOptions([...dropDownOptions, ...reqTypes]);
                        setTimingsDetails(timings?.[reqTypes[0]]?.timings)
                    }
                }
            } )
        }
    }

    useEffect(() => {
        getTypes();
    }, [timings] )

    

    const onChangeTypeofEvent = (value) => {
        setType(value);
        getImage(value)
        Object.keys(timings).map((each) => {
            if (each === value) {
                setTimingsDetails(timings?.[each]?.timings)
            }
        } )
    } 

    return (
        <div className="timingsMainContainer" >
            <span className="galleryHeading SpotLetMainHead" id='propertyPage3'>Timings</span>
            <div className="timingsCard col-11 col-md-10 " >
                <div className="timingsTopSection" >
                    <h3 className="timingsTopSectionHeading SpotLetMainHead" > Our Timings </h3>
                    <div className="timingsHeadEventTypeContainer">
                        {dropDownOptions.includes('film') && <div onClick={()=>onChangeTypeofEvent('film')} className={type == 'film' ? `timingsHeadEventTypeButtonActive` : `timingsHeadEventTypeButton`}>Film</div>}
                        {dropDownOptions.includes('tv') && <div onClick={()=>onChangeTypeofEvent('tv')} className={type == 'tv' ? `timingsHeadEventTypeButtonActive` : `timingsHeadEventTypeButton`}>Tv</div>}
                        {dropDownOptions.includes('event') && <div onClick={()=>onChangeTypeofEvent('event')} className={type == 'event' ? `timingsHeadEventTypeButtonActive` : `timingsHeadEventTypeButton`}>Event</div>}
                        {dropDownOptions.includes('stay') && <div onClick={()=>onChangeTypeofEvent('stay')} className={type == 'stay' ? `timingsHeadEventTypeButtonActive` : `timingsHeadEventTypeButton`}>Staycation</div>}
                    </div>
                    <select 
                        value={type}
                        required
                        name = "timings_dropdown" 
                        className="mx-2 textFiled p-0 timingsSelect  SpotLetSubHead" 
                        style={{width : "170px"}} 
                        onChange={(e) =>
                            onChangeTypeofEvent(e.target.value)
                        } 
                    >
                        {
                            dropDownOptions?.map((each) => {
                                return(
                                    <option value = {each}> Timings for {each[0].toUpperCase()+each.slice(1).toLowerCase()} </option>
                                )
                            } )
                        }
                    </select>
                </div>
                <hr className="w-100"/>
                {type && <h1 className="timingsBottomSectionHead SpotLetMainHead" > Timings for {type[0].toUpperCase()+type.slice(1).toLowerCase()} {(type === "film" || type === "tv") && " Shootings" } </h1>}
                <div className="timingsBottomSection" >
                    <img src={image} className="timingsImage col-xs-11 col-10 col-xl-5 mb-3" alt = "timings image" /> 
                    <div className="timingsDisplayCard col-xs-11 col-10 col-xl-6" >
                        {
                            timingsDetails && 
                            // Object.keys(timingsDetails).map((eachDay) => {
                                // return (
                                    <>
                                        <div className="timingsEachDay" >
                                            <p className="mb-0 timingsDay propertypage-timing-text" > Sunday </p>
                                            <p className="mb-0 timingsDay propertypage-timing-text" >{ 
                                                timingsDetails?.sunday ?
                                                    (timingsDetails?.sunday?.fullDay || (timingsDetails?.sunday === true )) ? 
                                                    type === "stay" ? "12:00pm - 11:00am" : 'All Day'
                                                    : `${timingsDetails?.sunday?.timings?.open} -  ${timingsDetails?.sunday?.timings?.close}` 
                                                : "Closed" }</p>
                                        </div>
                                        <hr/>
                                        <div className="timingsEachDay" >
                                            <p className="mb-0 timingsDay propertypage-timing-text" > Monday </p>
                                            <p className="mb-0 timingsDay propertypage-timing-text" >{ 
                                                timingsDetails?.monday ?
                                                    (timingsDetails?.monday?.fullDay || (timingsDetails?.monday === true )) ?
                                                    type === "stay" ? "12:00pm - 11:00am" : 'All Day'
                                                    : `${timingsDetails?.monday?.timings?.open} -  ${timingsDetails?.monday?.timings?.close}` 
                                                : "Closed" }</p>
                                        </div>
                                        <hr/>
                                        <div className="timingsEachDay" >
                                            <p className="mb-0 timingsDay propertypage-timing-text" > Tuesday </p>
                                            <p className="mb-0 timingsDay propertypage-timing-text" >{ 
                                                timingsDetails?.tuesday ?
                                                    (timingsDetails?.tuesday?.fullDay || (timingsDetails?.tuesday === true )) ?
                                                    type === "stay" ? "12:00pm - 11:00am" : 'All Day'
                                                    : `${timingsDetails?.tuesday?.timings?.open} -  ${timingsDetails?.tuesday?.timings?.close}` 
                                                : "Closed" }</p>
                                        </div>
                                        <hr/>
                                        <div className="timingsEachDay" >
                                            <p className="mb-0 timingsDay propertypage-timing-text" > Wednesday </p>
                                            <p className="mb-0 timingsDay propertypage-timing-text" >{ 
                                                timingsDetails?.wednesday ?
                                                    (timingsDetails?.wednesday?.fullDay || (timingsDetails?.wednesday === true )) ?
                                                    type === "stay" ? "12:00pm - 11:00am" : 'All Day'
                                                    : `${timingsDetails?.wednesday?.timings?.open} -  ${timingsDetails?.wednesday?.timings?.close}` 
                                                : "Closed" }</p>
                                        </div>
                                        <hr/>
                                        <div className="timingsEachDay" >
                                            <p className="mb-0 timingsDay propertypage-timing-text" > Thursday </p>
                                            <p className="mb-0 timingsDay propertypage-timing-text" >{ 
                                                timingsDetails?.thursday ?
                                                    (timingsDetails?.thursday?.fullDay || (timingsDetails?.thursday === true )) ? 
                                                    type === "stay" ? "12:00pm - 11:00am" : 'All Day'
                                                    : `${timingsDetails?.thursday?.timings?.open} -  ${timingsDetails?.thursday?.timings?.close}` 
                                                : "Closed" }</p>
                                        </div>
                                        <hr/>
                                        <div className="timingsEachDay" >
                                            <p className="mb-0 timingsDay propertypage-timing-text" > Friday </p>
                                            <p className="mb-0 timingsDay propertypage-timing-text" >{ 
                                                timingsDetails?.friday ?
                                                (timingsDetails?.friday?.fullDay || (timingsDetails?.friday === true )) ?
                                                    type === "stay" ? "12:00pm - 11:00am" : 'All Day'
                                                    : `${timingsDetails?.friday?.timings?.open} -  ${timingsDetails?.friday?.timings?.close}` 
                                                : "Closed" }</p>
                                        </div>
                                        <hr/>
                                        <div className="timingsEachDay" >
                                            <p className="mb-0 timingsDay propertypage-timing-text" > Saturday </p>
                                            <p className="mb-0 timingsDay propertypage-timing-text" >{ 
                                                timingsDetails?.saturday ?
                                                    (timingsDetails?.saturday?.fullDay || (timingsDetails?.saturday === true )) ?
                                                    type === "stay" ? "12:00pm - 11:00am" : 'All Day'
                                                    : `${timingsDetails?.saturday?.timings?.open} -  ${timingsDetails?.saturday?.timings?.close}` 
                                                : "Closed" }</p>
                                        </div>
                                        {/* <hr/> */}
                                    </>
                                // )
                            // } )
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Timings