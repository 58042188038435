import { Modal } from "react-bootstrap";
import React, { useState, useEffect } from "react";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
// import { getTags } from "../../apis/api";

const { Header, Title, Body } = Modal;

export const EventPlanningSupportModal = ({
    handleClose,
    handleOk,
    show,
}) => {

    const services = ["Venue Selection", "Catering", "Decoration", "Entertainment", "Transportation"]

    const [formData, setFormData] = useState({
        services: [],
    })
    const [otherServices, setOtherServices] = useState("")
    const [data, setData] = useState([])

    const onChange = (e) => {
        const { name, value } = e.target
        setFormData({ ...formData, [name]: value })
    }

    const submitForm = (e) => {
        e.preventDefault()
        let data = formData.services
        data.push(otherServices)
        setFormData({ ...formData, services: data })
        handleOk({ ...formData, services: data })
    }

    const onChangeCheckBox = (e) => {
        let data
        if (formData?.services.includes(e.target.value)) {
            data = formData?.services.filter(function (item) {
                return item !== e.target.value
            })
        } else {
            data = formData?.services
            data.push(e.target.value)
        }
        setFormData({ ...formData, services: data })
    }

    const onChangeOtherServices = (e) => {
        setOtherServices(e.target.value)
    }

    // const getTagsData = async() => {
    //     try{
    //         const resp = await getTags(null,"Yes");
    //         setData(resp?.data?.tags)
    //     }catch(error) {
    //         console.log(error)
    //     }
    // }

    // useEffect(() => {
    //     getTagsData()
    // },[] )

    return (
        <Modal
            show={show}
            onHide={handleClose}
            backdrop="static"
            keyboard={false}
            size="md"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Header closeButton>
                <Title className="SpotLetMainHead">Event Planning</Title>
            </Header>
            <Body className="verify-email-modal-body">
                <form onSubmit={submitForm} style={{ overflowY: "auto", height: "60vh" }} >
                    <div
                        style={{
                            margin: "5px 0px 5px 0px",
                        }}
                    >
                        <h5 className="SpotLetSubHead" > Contact Info : </h5>
                        <div className="d-flex flex-column m-2">
                            <label className="form-label SpotLetContent" htmlFor="name">
                                Full Name<span className="required">*</span>
                            </label>
                            <input
                                required
                                className="form-control"
                                name="fullName"
                                type="text"
                                pattern="^[a-zA-Z\s]+$"
                                title="Field should only consist of alphabets"
                                id="name"
                                value={formData.fullName}
                                onChange={onChange}
                            />
                        </div>
                        <div className="d-flex flex-column m-2">
                            <label className="form-label SpotLetContent" htmlFor="email">
                                Email Address<span className="required">*</span>
                            </label>
                            <input
                                required
                                className="form-control"
                                name="email"
                                type="email"
                                pattern="^[^\s@]+@[^\s@]+\.[^\s@]{2,}$"
                                title="It should be of proper email format"
                                id="email"
                                value={formData.email}
                                onChange={onChange}
                            />
                        </div>
                        <div className="d-flex flex-column m-2">
                            <label className="form-label SpotLetContent" htmlFor="number">
                                Mobile Number<span className="required">*</span>
                            </label>
                            <input
                                required
                                className="form-control"
                                name="number"
                                type="tel"
                                id="number"
                                pattern="[6789][0-9]{9}"
                                title="Phone number should start with 6-9 and remaing 9 digit with 0-9"
                                value={formData.number}
                                onChange={onChange}
                            />
                        </div>
                        <h5 className="mt-4 SpotLetSubHead "> Event Details : </h5>
                        <div className="d-flex flex-column m-2 eventPlanningStyle">
                            <label className="form-label SpotLetContent" htmlFor="eventType">
                            Name of the Event
                            </label>
                            <input
                                // required
                                className="form-control"
                                name="eventType"
                                type="text"
                                id="eventType"
                                value={formData?.eventType}
                                onChange={onChange}
                            />
                            {/* <select
                                style={{ appearance: 'auto' }}
                                value={formData.eventType}
                                id="eventType"
                                className="form-control"
                                name="eventType"
                                onChange={onChange}
                            >
                                <option hidden selected value="">Select Event</option>
                                <option value="corporate">Corporate Event</option>
                                <option value="wedding">Wedding</option>
                                <option value="photoshoot">Photoshoot</option>
                                <option value="birthday">Birthday Party</option>
                            </select> */}
                            {/* <Autocomplete
                            className="mb-2"
                            value={formData.eventType}
                            name="eventType"
                            // disableClearable={true}
                            onChange={(event, newValue) => {
                                if (newValue) {
                                    setFormData({ ...formData, eventType: newValue })
                                }
                            }}
                            onInputChange={(event, newInputValue) => {
                                setFormData({ ...formData, eventType: newInputValue })
                            }}
                            freeSolo
                            options={data.map((option) => option.name)}
                            renderInput={(params) => <TextField {...params} />}
                            /> */}
                        </div>
                        {/* date picker  */}
                        <div className="d-flex flex-column m-2">
                            <label className="form-label SpotLetContent" htmlFor="eventDate">
                                Preferred Event Date
                            </label>
                            <input
                                className="form-control"
                                name="eventDate"
                                type="date"
                                id="eventDate"
                                min={new Date().toISOString().split("T")[0]}
                                value={formData.eventDate}
                                onChange={onChange}
                            />
                        </div>
                        <div className="d-flex flex-column m-2">
                            <label className="form-label SpotLetContent mt-2" htmlFor="guests">
                                Number of Guests Expected :
                            </label>
                            <input
                                className="form-control"
                                name="guests"
                                type="number"
                                id="guests"
                                value={formData.guests}
                                onChange={onChange}
                                min="1"
                            />
                        </div>
                        <div className="d-flex flex-column m-2">
                            <label className="form-label SpotLetContent" htmlFor="eventLocation">
                                Area/City
                            </label>
                            <input
                                className="form-control"
                                name="eventLocation"
                                type="text"
                                id="eventLocation"
                                value={formData.eventLocation}
                                onChange={onChange}
                            />
                        </div>
                        <h5 className="mt-4 SpotLetSubHead "> Services Required : </h5>
                        <div className="d-flex flex-column m-2">
                            {
                                services.map((each) => {
                                    return (<div style={{ marginRight: '5px' }}>
                                        <input
                                            value={each}
                                            checked={formData?.services?.includes(each)}
                                            name={each}
                                            className="form-check-input"
                                            type="checkbox"
                                            id={each}
                                            onChange={onChangeCheckBox}
                                        />
                                        <label htmlFor={each}>{each}</label>
                                    </div>)
                                })
                            }
                            <div className="d-flex flex-column m-2">
                                <label className="form-label SpotLetContent" htmlFor="others">
                                    Other Services (Please Specify)
                                </label>
                                <textarea
                                    style={{
                                        borderRadius: '5px'
                                    }}
                                    name="others"
                                    rows={4}
                                    columns={55}
                                    id="others"
                                    value={otherServices}
                                    onChange={onChangeOtherServices}
                                />
                            </div>
                        </div>

                        <h5 className="mt-4 SpotLetSubHead "> Additional Info : </h5>
                        <div className="d-flex flex-column m-2">
                            <label className="form-label SpotLetContent" htmlFor="requirements">
                                Specific Themes/Preferences/Requirements
                            </label>
                            <textarea
                                style={{
                                    borderRadius: '5px'
                                }}
                                name="requirements"
                                rows={4}
                                columns={55}
                                id="requirements"
                                value={formData.requirements}
                                onChange={onChange}
                            />
                        </div>
                        <div>
                            <button
                                type="submit"
                                className="SpotLetContent"
                                style={{
                                    background: "#EA4335",
                                    border: "none",
                                    borderRadius: "5px",
                                    color: "#ffffff",
                                    width: "80px",
                                    height: "40px",
                                    margin: "5px",
                                }}
                            >
                                Submit
                            </button>
                        </div>
                    </div>
                </form>
            </Body>
        </Modal>
    );

}