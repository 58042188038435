import React from 'react';
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import PropertyInfo from "../../components/NewPropertyInfo";

const responsive = {
    largeDesktop: {
    breakpoint: { max: 3000, min: 1520 },
    items: 4,
    slidesToSlide: 1 // optional, default to 1.
  },
  desktop: {
    breakpoint: { max: 1520, min: 1230 },
    items: 3,
    slidesToSlide: 1 // optional, default to 1.
  },
  tablet: {
    breakpoint: { max: 1230, min: 825 },
    items: 2,
    slidesToSlide: 1 // optional, default to 1.
  },
  mobile: {
    breakpoint: { max: 825, min: 0 },
    items: 1,
    slidesToSlide: 1 // optional, default to 1.
  }
};

const SearchFeatured = React.memo(({data}) => {
      return(
          <Carousel
          swipeable={true}
          draggable={true}
          showDots={false}
          responsive={responsive}
          ssr={true} // means to render carousel on server-side.
          infinite={true}
          autoPlay={true}
          autoPlaySpeed={3000}
          keyBoardControl={false}
          customTransition="transform 300ms ease-in-out"
          transitionDuration={500}
          containerClass="carousel-container search-featured-carousel"
          removeArrowOnDeviceType={[]}
          // deviceType={this.props.deviceType}
          dotListClass="custom-dot-list-style"
          itemClass="carousel-item-padding-40-px"
          >
          {data?.map((property) => (
            <PropertyInfo key={property._id} property={property} />
            ))}
          </Carousel>
    
      )
    });

export default SearchFeatured