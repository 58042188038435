import { Button, Modal } from "react-bootstrap";
const { Header, Body, Title } = Modal;


export const PropertyPageInformationModal = ({ show, handleClose,title, content,showFooter,showOk,showCancel }) => {
    return (
        <Modal show={show} onHide={handleClose}>
            <Header closeButton>
                <Title style={{fontSize : '16px'}} className="SpotLetSubHead"> {title} </Title>
            </Header>
            <Body>
                {content}
            </Body>
            {showFooter && (
                <Footer>
                    {showCancel && (
                        <Button variant="secondary" onClick={handleClose} className="SpotLetContent" style={{fontSize : '13px'}}>
                            Cancel
                        </Button>
                    )}
                    {showOk && (
                        <Button variant="primary" onClick={handleClose} className="SpotLetContent" style={{fontSize : '13px'}}>
                            Okay
                        </Button>
                    )}
                </Footer>
            )}
        </Modal>
    )
}