import React, { useState, useEffect, useRef } from 'react'
import { useParams, useNavigate,useLocation } from 'react-router-dom';
import { Box, Typography, Select, MenuItem } from '@mui/material'

import { getListing, bookingRequest, applyCouponCode, getSettings } from "../../apis/api"
import { useAuthStore } from "../../store/auth";
import { ConfirmationModel } from '../../components/Modal/ConfirmationModel';
// import DatePicker from "react-datepicker";
import Moment from "moment";
import "react-datepicker/dist/react-datepicker.css";
// import Rating from '@mui/material/Rating';
import { Header } from "../../components/NewHeader/Header"
import { PageLoader } from "../../components/PageLoader";
import { Formik } from "formik";
import * as yup from "yup";
import { Button, Col, Form, Row } from "react-bootstrap";

// Mobile Number
import PhoneInput, {isValidPhoneNumber} from 'react-phone-number-input'
import 'react-phone-number-input/style.css';

// import Flatpickr from 'react-flatpickr';
import "flatpickr/dist/themes/material_orange.css";

import "./booking.css"

// Images
import locationIcon from "../../assets/NewImages/Booking/location.svg"

//datepicker
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import Accordion from 'react-bootstrap/Accordion';

// stay images
// import minus from '../assets/images/minus.svg'
import minus from "../../pages/NewPropertyPage/assets/images/minus.svg"
import plus from '../../pages/NewPropertyPage/assets/images/plus.svg'
import amenityArrow from '../../pages/NewPropertyPage/assets/images/amenityArrow.svg'
import { Link } from "react-router-dom";

const schema = yup.object().shape({
  // event: yup.string().required('Event type is required'),
  // attendies: yup.string().required('No of attendies is required'),
  // activity: yup.string().required('About your project is required').max(450, 'Maximum 450 charecters allowed'),
  // project_name: yup.string().required('Project name is required'),
  contact_name: yup.string().required('Contact name is required'),
  // mobile_no: yup.string().required('Mobile number is required')
  //   .min(10, 'Mobile number must be 10 digits')
  //   .max(10, 'Mobile number must be 10 digits'),
  // booking_as_a: yup.string().required('required'),
  email: yup.string().email('Invalid email')
  .matches(/^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i, 'Enter a valid email address')
  .required('Email Address is required'),
  // alt_mobile_no: yup.string()
  //   .min(10, 'Mobile number must be 10 digits')
  //   .max(10, 'Mobile number must be 10 digits'),
});


const Booking = () => {
  const formikRef = useRef();
  const params = useParams()
  const navigate = useNavigate();
  let location = useLocation();
  const locationId = params.id
  const { userProfile,setUserProfile } = useAuthStore();
  const dataFetchedRef = useRef(false);
  const [locationData, setLocationData] = useState({});
  // static content
  const IText = "This helps us cover transaction fess and provide support for your booking."
  const area = locationData?.address?.area.split("").reverse().join("")
  let slicedArea = ''
  if (area) {
    for (let i = 0; i < area.length; i++) {
      if (area.charAt(i) !== ' ') {
        slicedArea = area.charAt(i) + slicedArea
      } else {
        break
      }
    }
  }

  const [initialValues, setInitialValues] = useState({
    // activity: '',
    // project_name: '',
    contact_name: userProfile?.personalInfo?.fullName,
    email: userProfile?.personalInfo?.email,
    alt_contact_name: '',
  });
  const [formData,setFormData] = useState({
    ...location?.state?.stayRequest, 
    mobile_no: userProfile?.personalInfo?.mobile,
    alt_mobile_no: '',
    weekdays : 0,
    weekends : 0,
    weekdayBookingPrice : 0.00,
    weekendBookingPrice : 0.00,
    extraGuestFee : 0.00,
    bookingCharges : 0.00,
    convenienceFee : 0.00,
    cleaningFee : 0.00,
    gstFee : 0.00,
    totalPayment : 0.00,
    cancellation_policy : true,
    terms_conditions : true,
    share_info : true 
  });
  const [showConfirmationModel, setShowConfirmationModel] = useState(false);
  const [confirmationTitle, setConfirmationTitle] = useState("");
  const [confirmationBody, setConfirmationBody] = useState("");
  const [loader, setLoader] = useState(false)
  const [attendiesError, setAttendiesError] = useState(false);
  const [convenienceFee, setConvenienceFee] = useState({
    percentage : 0,
    maximum : 0,
  });

  // for mobile numbers 
  const [numberError, setNumberError] = useState("");
  const [alternateNumberError, setAlternateNumberError] = useState("");
  const [checkInError, setCheckInError] = useState("");
  const [checkOutError, setCheckOutError] = useState("");
  const [checkInBookedDates,setCheckInBookedDates] = useState(location?.state?.checkInBookedDates)
  const [checkOutBookedDates,setCheckOutBookedDates] = useState(location?.state?.checkOutBookedDates)

  const checkValidation = () => {
    // if (!formData.booking_as_a) {
    //   setBookingAsError(true)
    // }
    // else {
    //   setBookingAsError(false);
    // }
 
    if (formData?.mobile_no === "" || formData?.mobile_no === undefined ) {
      setNumberError("Enter a Valid Phone Number")
      setBookingAsError(true)
    }
    else if ( formData?.mobile_no?.slice(0,4) === "+911" || !isValidPhoneNumber(formData?.mobile_no) ) {
      setNumberError("Enter a Valid Phone Number")
      setBookingAsError(true)
    }
    else {
      setNumberError("");
    }

    if (formData?.alt_mobile_no !== "" && formData?.alt_mobile_no !== undefined ){
        if(formData?.alt_mobile_no?.slice(0,4) === "+911" || !isValidPhoneNumber(formData?.alt_mobile_no)){
          setAlternateNumberError("Enter a Valid Phone Number")
          setBookingAsError(true) 
        }
        else {
          setAlternateNumberError("");
        }
    }

  }

  function calculateBookingPrice(checkInDate, checkOutDate, weekdayPriceStr, weekendPriceStr) {
    // Convert prices from strings to numbers
    const weekdayPrice = parseInt(weekdayPriceStr, 10);
    const weekendPrice = parseInt(weekendPriceStr, 10);
    // Calculate number of weekdays and weekends
    const totalDays = Math.ceil((checkOutDate - checkInDate) / (1000 * 60 * 60 * 24));
    const weekdays = getWeekdayCount(checkInDate, checkOutDate);
    const weekends = totalDays - weekdays;
    const weekdayBookingPrice = parseFloat(weekdayPrice * weekdays).toFixed(2)
    const weekendBookingPrice = parseFloat(weekendPrice * weekends).toFixed(2)
    const bookingCharges = parseFloat(parseFloat(weekdayBookingPrice) + parseFloat(weekendBookingPrice  )).toFixed(2)
    return {
        weekdays,
        weekends,
        weekdayBookingPrice,
        weekendBookingPrice,
        bookingCharges,
    };
  }
  function getWeekdayCount(startDate, endDate) {
  let count = 0;
  let currentDate = new Date(startDate);

  while (currentDate < endDate) {
      const dayOfWeek = currentDate.getDay();
      if (dayOfWeek >= 1 && dayOfWeek <= 4) {
          count++;
      }
      currentDate.setDate(currentDate.getDate() + 1);
  }

  return count;
  }
  const calculateConvenienceFee = (value, extraFee) => {
    let reqValue = 0;
    reqValue = (parseInt(value) + parseInt(extraFee)) * (parseInt(convenienceFee?.percentage) / 100);
    if (reqValue > parseInt(convenienceFee?.maximum)) {
      reqValue = parseInt(convenienceFee?.maximum)
    }
    reqValue = parseFloat(reqValue).toFixed(2);
    return reqValue;
  }
  const calculateCharges = (data) => {
    let result = {
      bookingCharges : 0,
    };
    if (data?.checkOut) {
      result = calculateBookingPrice(data?.checkIn, data?.checkOut, data?.weekdayPrice, data?.weekEndPrice, data?.extraGuestFee, data?.baseGuests, data?.maxGuests);
    }
    const totalDays = Math.ceil((data?.checkOut - data?.checkIn) / (1000 * 60 * 60 * 24));
    let extraGuests = data?.adults + data?.children - data?.baseGuests
    let extraGuestTotalFee = 0.00;
    if (data?.extraGuestFee && (extraGuests > 0) ) {
      extraGuestTotalFee = parseFloat(extraGuests * data?.extraGuestFee * totalDays).toFixed(2);
    }
    extraGuestTotalFee = extraGuestTotalFee ? extraGuestTotalFee : "0.00";

    let convenienceFee = calculateConvenienceFee(result?.bookingCharges, extraGuestTotalFee);
    // let convenienceFee = parseFloat(result?.bookingCharges /10).toFixed(2);
    let cleaningFee = data?.cleaningFee ? parseFloat(data?.cleaningFee).toFixed(2) : "0.00"
    let tempPrice = parseFloat(result?.bookingCharges)+parseFloat(cleaningFee)+ parseFloat(extraGuestTotalFee) + parseFloat(convenienceFee);
    let gstFee = parseFloat((tempPrice)*(18/100)).toFixed(2)
    let totalPayment = parseFloat(parseFloat(result?.bookingCharges) + parseFloat(convenienceFee) +  parseFloat(gstFee) + parseFloat(cleaningFee) + parseFloat(extraGuestTotalFee)).toFixed(2) ;
    setFormData({
      ...data,
      ...result,
      owner_id: data?.user_id,
      convenienceFee,
      gstFee,
      cleaningFee,
      totalPayment,
      securityDeposite : data?.securityDeposite,
      cancellation : data?.cancellation,
      extraGuests, 
      extraGuestTotalFee
    })
  }
  const getLocationData = async () => {
    try {
      const response = await getListing(locationId)
      setLocationData(response?.data);
      setFormData({
        ...formData,
        extraGuestFee : response?.data?.pricing?.stay?.extraGuestFee,
        baseGuests : response?.data?.pricing?.stay?.baseGuests,
        maxGuests : response?.data?.pricing?.stay?.maxGuests,
      })
      calculateCharges({
        ...formData,
        cleaningFee : response?.data?.pricing?.stay?.cleaningFee, 
        securityDeposite : response?.data?.pricing?.stay?.securityDeposite, 
        user_id : response?.data?.user_id, 
        cancellation : response?.data?.policies?.stay?.cancellation,
        extraGuestFee : response?.data?.pricing?.stay?.extraGuestFee,
        baseGuests : response?.data?.pricing?.stay?.baseGuests,
        maxGuests : response?.data?.pricing?.stay?.maxGuests,
      })
    }
    catch (err) {
      console.log(err)
    }
  }
  const submitForm = async (data) => {
    setLoader(true)
    try {
      const response = await bookingRequest(data)
      setLoader(false)
      setUserProfile()
      setConfirmationTitle('Booking Request')
      setConfirmationBody(`Thank you for Booking Request, host will reach out to you ${new Date().getHours() >= 20 ?  'before tomorrow 11am' : 'within 2 hours'} `)
      setShowConfirmationModel(true)
    } catch (err) {
      alert(err);
      console.log(err);
    }
    setLoader(false)
  }
  const handlePopUp = () => {
    setShowConfirmationModel(false)
    navigate('/my-bookings',  { replace: true })
  }

  const getConvinienceFee = async() => {
    try{
      const response = await getSettings();
      if (response?.data?.active) {
        setConvenienceFee({
          percentage : response?.data?.percentage,
          maximum : response?.data?.eligible,
        })
      }
    } catch(error) {
      console.log(error?.response?.data?.message);
    }
  }
  useEffect(() => {
    window.scrollTo(0, 0);
    if (dataFetchedRef.current) return;
    if (locationId) {
      dataFetchedRef.current = true;
      getLocationData();
      getConvinienceFee();
    }
  }, [convenienceFee]);
  const onSubmit = (formValues) => {
    if(!formData?.checkIn){
      setCheckInError('Check In is required');
      return null
    }
    if(!formData?.checkOut){
      setCheckOutError('Check Out is required');
      return null
    }
    if (numberError || alternateNumberError || checkInError || checkOutError) {
      return null
    }
    setFormData({
      ...formData,
      ...formValues
    })
    submitForm({ ...formData, ...formValues,property_id : locationId,user_id: userProfile?._id,});
  };
  const handleMobileNumberChange = (e, name) => {
    setFormData({
      ...formData,
      [name] : e
    })
  }

  // stay section dates start
  const updateAdults = (type,value) => {
    if(type === '-'){
        if(value <= 1) return null
        setFormData((prev)=>{
            return{
                ...prev,
                adults : prev.adults - 1,
            }
        })
    }
    if(type === '+'){
        if(parseInt(locationData?.pricing?.stay?.maxGuests) ===  value) return null
        if(value + formData?.children === parseInt(locationData?.pricing?.stay?.maxGuests)) return null
        setFormData((prev)=>{
            return{
                ...prev,
                adults : prev.adults + 1,
            }
        })
    }
  }
  const updateChildren= (type,value) => {
      if(type === '-'){
          if(value <= 0) return null
          setFormData((prev)=>{
              return{
                  ...prev,
                  children : prev.children - 1,
              }
          })
      }
      if(type === '+'){
          if(formData?.adults + value >= parseInt(locationData?.pricing?.stay?.maxGuests)) return null
          setFormData((prev)=>{
              return{
                  ...prev,
                  children : prev.children + 1,
              }
          })
      }
  }

const updateInfants= (type,value) => {
  if(type === '-'){
      if(value <= 0) return null
      setFormData((prev)=>{
          return{
              ...prev,
              infants : prev.infants - 1,
          }
      })
  }
  if(type === '+'){
      // if(formData?.adults + value >= 6) return null
      setFormData((prev)=>{
          return{
              ...prev,
              infants : prev.infants + 1,
          }
      })
  }
}

  const isStayWeekday = (date) => {
    let dates = []
    let finalDates = [];
    Object.keys(locationData?.pricing?.stay?.timings).map((day,i)=>{
            if (locationData?.pricing?.stay?.timings?.[day]) {
                dates.push(day.substring(0, 3))
            } 
    })
    for (let i = 0; i < 7; i++) {
        switch (dates[i]) {
          case "mon":
            finalDates.push(1);
            break;
          case "tue":
            finalDates.push(2);
            break;
          case "wed":
            finalDates.push(3);
            break;
          case "thu":
            finalDates.push(4);
            break;
          case "fri":
            finalDates.push(5);
            break;
          case "sat":
            finalDates.push(6);
            break;
          case "sun":
            finalDates.push(0);
            break;
          default:
            break;
        }
      }
      const day = date.getDay();
      return (
        finalDates.includes(day)
      );
  };
  const getStayCheckInExcludedDates = () => {
    // var array = [new Date(formData?.checkIn) ,new Date(formData?.checkOut)]
    var array = []
    checkInBookedDates?.map((item) => array.push(new Date(item)))
    if(formData?.checkIn) array.push(new Date(formData?.checkIn))
    if(formData?.checkOut) array.push(new Date(formData?.checkOut))
    return array
}
const getStayCheckOutExcludedDates = () => {
    // var array = [new Date(formData?.checkIn) ,new Date(formData?.checkOut)]
    var array = []
    checkOutBookedDates?.map((item) => array.push(new Date(item)))
    if(formData?.checkIn) array.push(new Date(formData?.checkIn))
    if(formData?.checkOut) array.push(new Date(formData?.checkOut))
    return array
}
  const handleCheckIn = (date) => {
    setCheckInError('')
    setFormData({
        ...formData,
        checkIn : date,
        checkOut : '',
        weekdays : 0,
        weekends : 0,
        weekdayBookingPrice : 0.00,
        weekendBookingPrice : 0.00,
        extraGuestFee : 0.00,
        bookingCharges : 0.00,
        convenienceFee : 0.00,
        cleaningFee : 0.00,
        gstFee : 0.00,
        totalPayment : 0.00,
    })
  }

  function extractDay(dateString) {
    const datePart = dateString.split(',')[1].trim();
    const day = datePart.split(' ')[1];
    return day;
  }

  // for calculating the checkoutdate date start
  const getMaxDate = (checkIn, timings) => {
    let maxDate 
    if (!checkIn) return maxDate;
  
    const daysOfWeek = ['sunday', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday'];
    const checkInDate = new Date(checkIn);
    let checkInDayIndex = checkInDate.getDay();
    let trigger = true
    let i = 1
    const extractedDays = checkInBookedDates.map(extractDay);
    while(trigger && i<=6){
      const checkInDate2 = new Date(checkIn)
      checkInDate2.setDate(checkInDate.getDate() + i);
      const newDayOfMonth = checkInDate2.getDate().toString();
      checkInDayIndex = checkInDayIndex + 1
      if(checkInDayIndex > 6) checkInDayIndex = checkInDayIndex - 7
      const dayName = daysOfWeek[checkInDayIndex];
      if (timings?.[dayName] === null || timings?.[dayName] === undefined || extractedDays.includes(newDayOfMonth)) {
          const maxDate = new Date(checkInDate);
          maxDate.setDate(checkInDate.getDate() + i);
          trigger = false
          return maxDate
      }
      i++
  }
    return maxDate; // No max date if no null days are found
  }; 
   // for calculating the checkout date end 

  const handleCheckOut = (date) => {
    setCheckOutError('')
    setFormData({...formData,checkOut : date})
    calculateCharges({...formData,checkOut :date})
    // getLocationData("", date)
  }

  const staySection = () => {
      return(
          <div className='mt-1 mb-2'>
            <div className='w-100 d-flex p-2' style = {{gap : "10px"}}>
                <div className='d-flex flex-column'>
                  <DatePicker
                      // showIcon
                      calendarClassName='propertyPageCalender SpotLetContent'
                      selected={formData?.checkIn}
                      value={formData?.checkIn}
                      // minDate={formData?.checkOut ? new Date(formData?.checkOut) : new Date()}
                      minDate={new Date()}
                      dateFormat = 'dd-MM-yyyy'
                      onChange={(date) => handleCheckIn(date)}
                      excludeDates={getStayCheckInExcludedDates()}
                      // maxDate = {formData?.checkOut ? new Date(formData?.checkOut) : undefined}
                      filterDate={isStayWeekday}
                      placeholderText="Check In"
                      required
                  />
                  {checkInError && <span style={{color:"Red", fontSize:"14.5px"}}>{checkInError}</span>}
                </div>
                <div className='d-flex flex-column'>
                  <DatePicker
                      // showIcon
                      // calendarIconClassName='propertyPageDatePickerCalender'
                      calendarClassName="SpotLetContent"
                      selected={formData?.checkOut}
                      value={formData?.checkOut}
                      minDate={formData?.checkIn ? new Date(formData?.checkIn) : new Date()}
                      dateFormat = 'dd-MM-yyyy'
                      onChange={(date) => handleCheckOut(date)}
                      excludeDates={getStayCheckOutExcludedDates()}
                      disabled = {formData?.checkIn ? false : true}
                      // filterDate={isStayWeekday}
                      placeholderText="Check Out"
                      required
                      maxDate={getMaxDate( formData?.checkIn ? new Date(formData?.checkIn) : new Date(), locationData?.pricing?.stay?.timings )}
                  />
                  {checkOutError && <span style={{color:"Red", fontSize:"14.5px"}}>{checkOutError}</span>}
                </div>
                  {formData?.event === 'stay' && (
                      <style jsx>{`
                      .react-datepicker-wrapper input::placeholder {
                          color: gray;
                          opacity: 1;
                          font-weight: 400;
                      }
                      .react-datepicker-wrapper{
                          height: 51px;
                          border-radius: 10px;
                          border: 1px solid #EAEAEA;
                          padding: 10px;
                          width : 233px;
                          margin-top : 20px;
                      }
                      .react-datepicker-wrapper input{
                          outline: none;
                          border: none;
                          width: 100%;
                      }
                      @media (max-width : 1188px) {
                          .react-datepicker-wrapper{
                              width: 100%;
                          }
                        }
                      
                  `}</style>
                  )}
              </div>
              <div className='w-100 d-flex p-2' style = {{gap : "10px"}}>
                  <div className='propertyPagesStaynumberSection'>
                      <div className='d-flex flex-column justify-content-center align-center'>
                          <span className='stayPeopleHeading SpotLetContent '>Adults</span>
                          <span className='stayPeopleText SpotLetContent '>Ages 13 or above</span>
                      </div>
                      <div className='propertyPageStayNumberSection'>
                          <img src={minus} onClick={()=>updateAdults('-',formData?.adults)} className='cursor'/>
                          <span className='stayPeopleText SpotLetContent '>{formData?.adults}</span>
                          <img src={plus} onClick={()=>updateAdults('+',formData?.adults)}  className='cursor'/>
                      </div>
                  </div>
                  <div className='propertyPagesStaynumberSection'>
                      <div className='d-flex flex-column justify-content-center align-center'>
                          <span className='stayPeopleHeading SpotLetContent'>Children</span>
                          <span className='stayPeopleText SpotLetContent '>Ages 2-12</span>
                      </div>
                      <div className='propertyPageStayNumberSection'>
                          <img src={minus} onClick={()=>updateChildren('-',formData?.children)}  className='cursor'/>
                          <span className='stayPeopleText SpotLetContent '>{formData?.children}</span>
                          <img src={plus} onClick={()=>updateChildren('+',formData?.children)} className='cursor'/>
                      </div>
                  </div>
              </div>
              <div className='p-2'>
                <div className='propertyPagesStayInfantSection p-2'>
                    <div className='d-flex flex-column justify-content-center align-center'>
                        <span className='stayPeopleHeading SpotLetContent'>Infants</span>
                        <span className='stayPeopleText SpotLetContent '>Ages below 2</span>
                    </div>
                    <div className='propertyPageStayNumberSection'>
                        <img src={minus} onClick={()=>updateInfants('-',formData?.infants)}  className='cursor'/>
                        <span className='stayPeopleText SpotLetContent'>{formData?.infants}</span>
                        <img src={plus} onClick={()=>updateInfants('+',formData?.infants)} className='cursor'/>
                    </div>
                </div>
              </div>
          </div>
      )
  }

  // policies start
  const getStayPolicies = (data) => {
    return(
        <>
            <li className='SpotLetContent form-label col-lg-12 col-xl-12'>
                {data?.cancellation}
            </li>
            <li className='SpotLetContent form-label col-lg-12 col-xl-12'>
                Id Proofs 
                <ul>
                    {
                        data?.idProof?.map((each) => {
                            return <li className='SpotLetContent form-label col-lg-12 col-xl-12'> {each?.label} </li>
                        } )
                    }
                </ul>
            </li>
            {
                data?.inhousePets
                && (
                    <li className='SpotLetContent form-label col-lg-12 col-xl-12'>
                       Pets are present at the property
                    </li>
                )
            }
            {
                data?.outsidePets
                && (
                    <li className='SpotLetContent form-label col-lg-12 col-xl-12'>
                       Outside pets are allowed
                    </li>
                )
            }
            {
                data?.coupleFriendly
                && (
                    <li className='SpotLetContent form-label col-lg-12 col-xl-12'>
                       Couple Friendly
                    </li>
                )
            }
            {
                data?.cook && (
                    <li className='SpotLetContent form-label col-lg-12 col-xl-12'>
                       Cook Available
                    </li>
                )
            }
            {
                data?.cook &&  (
                    <li className='SpotLetContent form-label col-lg-12 col-xl-12'>
                Cuisines Known by cook
                <ul>
                    {
                        data?.cuisines?.map((each) => {
                            return <li className='SpotLetContent form-label col-lg-12 col-xl-12'> {each?.label} </li>
                        } )
                    }
                </ul>
            </li>
                )
            }
            {
                data?.nonVeg && (
                    <li className='SpotLetContent form-label col-lg-12 col-xl-12'>
                        Non-Veg allowed
                    </li>
                )
            }
            {
                data?.outSideFood && (
                    <li className='SpotLetContent form-label col-lg-12 col-xl-12'>
                        Outside food allowed
                    </li>
                )
            }
            {
                data?.smoking && (
                    <li className='SpotLetContent form-label col-lg-12 col-xl-12'>
                        Smoking allowed
                    </li>
                )
            }
            {
                data?.outsideAlcohol && (
                    <li className='SpotLetContent form-label col-lg-12 col-xl-12'>
                       Outside alcohol allowed
                    </li>
                )
            }
            {
                data?.outsideDj && (
                    <li className='SpotLetContent form-label col-lg-12 col-xl-12'>
                       Outside Dj allowed
                    </li>
                )
            }
            {
                data?.music?.allowed && (
                    <li className='SpotLetContent form-label col-lg-12 col-xl-12'>
                        Music allowed - {data?.music?.fullDay ? "24 Hours" : `${data?.music?.timings?.start} - ${data?.music?.timings?.end}` }
                    </li>
                )
            }
            {
                data?.caretaker?.available && (
                    <li className='SpotLetContent form-label col-lg-12 col-xl-12'>
                        Caretaker available - {data?.caretaker?.fullDay ? "24 Hours" : `${data?.caretaker?.timings?.start} - ${data?.caretaker?.timings?.end}` }
                    </li>
                )
            }
            {
                data?.customPolicy && (
                    <li className='SpotLetContent form-label col-lg-12 col-xl-12'>
                        Host Policies
                        <ul>
                            {
                                data?.customPolicies?.map((each) => {
                                    return <li className='SpotLetContent form-label col-lg-12 col-xl-12'> {each} </li>
                                } )
                            }
                        </ul>
                    </li>
                )
            }
        </>
    )
}

  // policies end 

  // Amenities Start
  const propertyAmenities = (data) => {

    return(
        <div className="amenitiesMainContainer" style={{padding:"15px 0px 15px 0px"}} >
            <div className='amenitiesTextConatainer' style={{width: "95%"}}  >
                {/* <span className='amenitiesHeading SpotLetSubHead' style={{alignSelf:"center"}} > All Amenities</span> */}
                <>
                    {/* { data?.length > 0 && */}
                        <div className='row w-100'>
                            {data?.map((amenity,i) => {
                                return(
                                    <div className='col-lg-4 col-md-4 mb-2'>
                                        <img src={amenityArrow} alt='amenityArrow'/>
                                        <span className='amenityListText SpotLetContent'>{amenity?.name}</span>
                                        {amenity?.sub && (
                                            <ul>
                                                {amenity?.sub?.map((sub)=> (
                                                    <li>{sub.value}</li>
                                                ))}
                                            </ul>
                                        )}
                                    </div>
                                )
                            })}
                    </div>
                    {/* } */}
                </>
            </div>
        </div>
    )
} 
  // Amenities End 
  // stay section dates end

  // for pice calculation 
  const countWeekdaysWeekends = (checkIn, checkOut) => {
        // Convert dates to Date objects
        const checkInDate = new Date(checkIn);
        const checkOutDate = new Date(checkOut);
    
        // Exclude the checkOut date
        checkOutDate.setDate(checkOutDate.getDate() - 1);
    
        let weekdays = 0;
        let weekends = 0;
    
        // Iterate through each day
        for (let date = new Date(checkInDate); date <= checkOutDate; date.setDate(date.getDate() + 1)) {
            const day = date.getDay();
            if (day === 5 || day === 6 || day === 0) { // Fri, Sat, Sun are weekends
                weekends++;
            } else { // Mon, Tue, Wed, Thu are weekdays
                weekdays++;
            }
        }
    
        return { weekdays, weekends };
    }

    const getStayPricing = (pricingData, formData) => {
        
        if (formData?.checkOut) {
            // do calculation 
            const checkIn = formData?.checkIn;
            const checkOut = formData?.checkOut;
            const { weekdays, weekends } = countWeekdaysWeekends(checkIn, checkOut);
            const totalBookingDays = weekdays + weekends;
            const extraGuestFee = pricingData?.extraGuestFee ? parseInt(pricingData?.extraGuestFee) : 0;
            // const extraChildFee = pricingData?.extraChildFee ? parseInt(pricingData?.extraChildFee) : 0;
            const weekdayPrice = pricingData?.weekdayPrice;
            const weekendPrice = pricingData?.weekendPrice;
            const totalGuests = formData?.adults + formData?.children;
            // const totalChildren = formData?.children;
            const extraGuests = totalGuests > pricingData?.baseGuests ? totalGuests - pricingData?.baseGuests : 0;
            const extraGuestsPrice = extraGuestFee && extraGuests ? parseInt(extraGuestFee * extraGuests * totalBookingDays ) : 0;
            // const totalChildrenPrice = extraChildFee ? parseInt(extraChildFee * totalChildren * totalBookingDays ) : 0; 

            const totalPrice = parseInt(weekdays * weekdayPrice) + parseInt(weekends * weekendPrice) + extraGuestsPrice ;
            const averageBookingPrice = Math.ceil(totalPrice/totalBookingDays);

            return averageBookingPrice
        } else {
            return pricingData?.weekdayPrice
        }
    }
    // stay pricing calculation end

    useEffect(() => {
        calculateCharges(formData);
        // setStayAverageBookingPrice(getStayPricing(data?.stay, formData));
    }, [formData?.adults, formData?.children, formData?.checkIn, formData?.checkOut, convenienceFee, locationData]);

  return (
    <>
      <Header />
      <Box className="bookingContainer">
        <Box className="bookingContentBox">
          <Box className="bookingContent">
            <Box className="BookingHeader">
              <Typography>Request to Book</Typography>
              <Box className="BookingHeaderUnderLine"></Box>
            </Box>
            <Box className="bookingMainBox">
              <Formik
                validationSchema={schema}
                onSubmit={onSubmit}
                initialValues={initialValues}
                innerRef={formikRef}
              >
                {({
                  handleSubmit,
                  handleChange,
                  handleBlur,
                  values,
                  touched,
                  isValid,
                  errors,
                }) => (
                  <Form noValidate onSubmit={handleSubmit} autoComplete="off" className='formikContainer'>
                    <Box className="headerLabel">
                      <Box className="numberLogo">
                        <Typography className='numberText'>1</Typography>
                      </Box>
                      <Typography className='numberLabel'>Staycation Details</Typography>
                    </Box>
                    {formData?.event === 'stay' ? (
                      <Row>
                      <Form.Group as={Col} md='12'>
                        <Form.Label>
                          Type of Event
                        </Form.Label>
                        <Select
                          name="event"
                          style={{ height: '50px', borderRadius: '10px', marginBottom: "3px", borderColor: '#dddddd', width: '100%' }}
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          value={formData?.event}
                          inputProps={{ 'aria-label': 'Without label' }}
                          displayEmpty
                          disabled
                        >

                          <MenuItem value='film'>Film Shooting</MenuItem>
                          <MenuItem value='tv'>Tv Shooting</MenuItem>
                          <MenuItem value='event'>Event</MenuItem>
                          <MenuItem value='stay'>Staycation</MenuItem>
                        </Select>
                      </Form.Group>
                      <Form.Group as={Col} md='6'>
                        <Form.Label>
                          Check In
                        </Form.Label>
                        <Form.Control
                          type='text'
                          name='checkOut'
                          value={ formData.checkIn ? Moment(formData.checkIn).format('DD-MM-YYYY') : ""}
                          disabled
                          required
                        />
                      </Form.Group>
                      <Form.Group as={Col} md='6'>
                        <Form.Label>
                        Check Out
                        </Form.Label>
                        <Form.Control
                          type='text'
                          name='checkOut'
                          value={ formData?.checkOut ? Moment(formData?.checkOut).format('DD-MM-YYYY') : "" }
                          disabled
                          required
                        />
                      </Form.Group>
                      <Form.Group as={Col} md='6' className='mt-1'>
                        <Form.Label>
                          Adults
                        </Form.Label>
                        <Form.Control
                          type='number'
                          name='adults'
                          value={formData?.adults}
                          disabled
                          required
                        />
                      </Form.Group>
                      <Form.Group as={Col} md='6' className='mt-1' >
                        <Form.Label>
                        Children
                        </Form.Label>
                        <Form.Control
                          type='number'
                          name='children'
                          value={formData?.children}
                          disabled
                          required
                        />
                      </Form.Group>
                      <Form.Group as={Col} md='6' className='mt-1'>
                        <Form.Label>
                        Infants
                        </Form.Label>
                        <Form.Control
                          type='number'
                          name='infants'
                          value={formData?.infants}
                          disabled
                          required
                        />
                      </Form.Group>
                    </Row>
                    ) : (
                      <Row>
                        <Form.Group as={Col} md='6'>
                          <Form.Label>
                            Type of Event
                          </Form.Label>
                          <Select
                            name="event"
                            style={{ height: '50px', borderRadius: '10px', marginBottom: "3px", borderColor: '#dddddd', width: '100%' }}
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={formData?.event}
                            inputProps={{ 'aria-label': 'Without label' }}
                            displayEmpty
                            disabled
                          >

                            <MenuItem value='film'>Film Shooting</MenuItem>
                            <MenuItem value='tv'>Tv Shooting</MenuItem>
                            <MenuItem value='event'>Event</MenuItem>
                            <MenuItem value='stay'>Staycation</MenuItem>
                          </Select>
                        </Form.Group>
                        <Form.Group as={Col} md='6'>
                          <Form.Label>
                            Guests
                          </Form.Label>
                          <Form.Control
                            type='number'
                            name='guests'
                            value={formData?.guests}
                            disabled
                          />
                        </Form.Group>
                        <Form.Group as={Col} md='4'>
                          <Form.Label>
                          Date
                          </Form.Label>
                          <Form.Control
                            type='text'
                            name='date'
                            style={{ borderColor: attendiesError ? '#dc3545' : '' }}
                            value={Moment(formData.startDate).format('DD-MM-YYYY')}
                            disabled
                          />
                        </Form.Group>
                        <Form.Group as={Col} md='4'>
                          <Form.Label>
                            Start
                          </Form.Label>
                          <Form.Control
                            type='text'
                            name='start'
                            style={{ borderColor: attendiesError ? '#dc3545' : '' }}
                            value={formData.startTime}
                            disabled
                          />
                        </Form.Group>
                        {formData?.noOfHours && (
                          <Form.Group as={Col} md='4'>
                            <Form.Label>
                            No of hours
                            </Form.Label>
                            <Form.Control
                              type='text'
                              name='end'
                              style={{ borderColor: attendiesError ? '#dc3545' : '' }}
                              value={formData?.noOfHours}
                              disabled
                            />
                          </Form.Group>
                        )}
                      </Row>
                    )}
                    <Box className="headerLabel">
                      <Box className="numberLogo">
                        <Typography className='numberText'>2</Typography>
                      </Box>
                      <Typography className='numberLabel'>Share your information</Typography>
                    </Box>
                    <Row>
                      <Form.Group as={Col} md='12'>
                        <Form.Label>
                          Contact person name<span className='required'>*</span>
                        </Form.Label>
                        <Form.Control
                          type='text'
                          name='contact_name'
                          value={values.contact_name}
                          onChange={handleChange}
                          isInvalid={!!touched.contact_name && !!errors.contact_name}
                        />
                        <Form.Control.Feedback
                          type='invalid'
                          className='text-capitalize'
                        >
                          {errors.contact_name}
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Row>
                    <Row>
                      <Form.Group as={Col} md='6' className = "mt-2" >
                        <Form.Label>
                          Contact number<span className='required'>*</span>
                        </Form.Label>
                        {/* <Form.Control
                          type='number'
                          name='mobile_no'
                          value={values.mobile_no}
                          onChange={handleChange}
                          isInvalid={!!touched.mobile_no && !!errors.mobile_no}
                        />
                        <Form.Control.Feedback
                          type='invalid'
                          className='text-capitalize'
                        >
                          {errors.mobile_no}
                        </Form.Control.Feedback> */}
                        <PhoneInput
                                  // required
                                  international
                                  countryCallingCodeEditable={false}
                                  flags
                                  placeholder="Enter phone number"
                                  style={{backgroundColor : "#ffffff", border: "1px solid #dddddd", borderRadius : "10px", height:"50px", marginBottom : "0px", paddingLeft : "15px" }}
                                  value={formData?.mobile_no}
                                  defaultCountry="IN"
                                  // onChange={(e) => {
                                  //   setNumber(e)
                                  // }}
                                onChange={(e) => handleMobileNumberChange(e,"mobile_no")}
                              />
                              {
                                  numberError && <p style={{color:"Red", fontSize:"14.5px"}} className="m-0" > {numberError} </p> 
                              }
                      </Form.Group>
                      <Form.Group as={Col} md='6' className = "mt-2" >
                        <Form.Label>
                          Email address<span className='required'>*</span>
                        </Form.Label>
                        <Form.Control
                          type='text'
                          name='email'
                          value={values.email}
                          onChange={handleChange}
                          isInvalid={!!touched.email && !!errors.email}
                        />
                        <Form.Control.Feedback
                          type='invalid'
                          className='text-capitalize'
                        >
                          {errors.email}
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Row>
                    <Row>
                      <Form.Group as={Col} md='6' className = "mt-2" >
                        <Form.Label>
                          Alternate contact person
                        </Form.Label>
                        <Form.Control
                          type='text'
                          name='alt_contact_name'
                          value={values.alt_contact_name}
                          onChange={handleChange}
                        />
                      </Form.Group>
                      <Form.Group as={Col} md='6' className = "mt-2" >
                        <Form.Label>
                          Alternate contact number
                        </Form.Label>

                        <PhoneInput
                                // required
                                international
                                countryCallingCodeEditable={false}
                                flags
                                placeholder="Enter phone number"
                                style={{backgroundColor : "#ffffff", border: "1px solid #dddddd", borderRadius : "10px", height:"50px", marginBottom : "0px", paddingLeft : "15px" }}
                                // value={details?.alternate_person_number}
                                defaultCountry="IN"
                                // onChange={(e) => {
                                //     setDetails({
                                //         ...details,
                                //         alternate_person_number : e
                                //     })
                                // }  
                                // }
                                onChange={(e) => handleMobileNumberChange(e,"alt_mobile_no")}
                            />
                        {
                            alternateNumberError && <p style={{color:"Red", fontSize:"14.5px"}} className="m-0" > {alternateNumberError} </p> 
                        }
                      </Form.Group>
                    </Row>
                      { formData?.event === 'stay' && locationData && (
                        <Accordion className='mt-2 mb-2'>
                            <Accordion.Item eventKey="0"  className='mb-2'>
                                <Accordion.Header> Staycation Policies  </Accordion.Header>
                                    <Accordion.Body>
                                        <div className="col-lg-12 mb-3" >
                                            <ul className="policyList">
                                                {
                                                    locationData?.policies?.stay?.staticPolices?.map((each) => {
                                                        return <li className="SpotLetContent form-label col-lg-12 col-xl-12"> {each} </li>
                                                    }) 
                                                }
                                                {
                                                    getStayPolicies(locationData?.policies?.stay)
                                                }
                                            </ul>
                                            {/* {
                                                (pricingData?.extraData?.stay?.cleaningFee || pricingData?.extraData?.stay?.securityDeposit) && (
                                                    <div className='d-flex flex-row'>
                                                        { pricingData?.extraData?.stay?.cleaningFee && <span className='mx-2  SpotLetContent '> Cleaning Fee : <span style={{fontWeight:600}}>  {pricingData?.extraData?.stay?.cleaningFee}  </span> </span> }
                                                        { pricingData?.extraData?.stay?.securityDeposit && <span className='mx-2  SpotLetContent '> Security Deposit : <span style={{fontWeight:600}}>  {pricingData?.extraData?.stay?.securityDeposit} </span> </span> }
                                                    </div>
                                                )
                                            } */}
                                        </div>
                                    </Accordion.Body>
                            </Accordion.Item>
                        </Accordion>
                      ) }
                      { formData?.event === 'stay' && locationData && (
                        <Accordion className='mt-2 mb-2'>
                            <Accordion.Item eventKey="0"  className='mb-2'>
                                <Accordion.Header> Amenities  </Accordion.Header>
                                    <Accordion.Body>
                                        {propertyAmenities( locationData?.amenities )}
                                    </Accordion.Body>
                            </Accordion.Item>
                        </Accordion>
                      ) }
                    <span className="text-gray-500 text-center fw-semibold fs-6 mb-4 SpotLetContent">
                      By clicking Request Booking you accept SpotLet{' '}
                      <Link 
                        className="auth_text_color fw-semibold SpotLetContent"
                        to={'/privacy-policy'} 
                        target="_blank"
                      >
                        Privacy Policy
                      </Link>
                      {' '}&{' '}
                      <Link 
                        href="" 
                        className="auth_text_color fw-semibold SpotLetContent"
                        to={'/terms-and-conditions'} 
                        target="_blank"
                      >
                        Terms & Conditions
                      </Link>
                    </span>
                    <div className='mt-3 BookingBtnBox'>
                      <button type='button' sx={{ backgroundColor: '#f2f2f2', marginRight: '10px' }} className='booking-cancel-button' onClick={()=> window.history.back()} style={{ color: '#555' }}  > Cancel  </button>
                      <Button type="submit" className="BookingRequestBtn" onClick={checkValidation}>Request Booking</Button>
                    </div>
                  </Form>
                )}
              </Formik>
              <Box className="BookingDetailsBox">
                <Box className="BookingDetailsCard">
                  <Box className="BookingDetailsOneBox">

                    <Box className="BookingDetailsTopCard">
                      <Box className="BookingDetailsTopCardThamnal">
                        <img src={locationData?.images?.[locationId]?.[0]?.image} />
                      </Box>
                      <Box className="BookingDetailsTopCardTextBox">
                        <Typography className='BookingDetailsTopCardHeader'>{locationData?.location_id}</Typography>
                        <Box className="BookingDetailsTopCardLocation">
                          <img src={locationIcon} />
                          <Box className="location-container" >
                            <Typography className='topCardLocationText'>
                              {`${slicedArea},`}
                            </Typography>
                            <Typography className='topCardLocationText' >
                              {locationData?.address?.city},
                            </Typography>
                            <Typography className='topCardLocationText' >
                              {locationData?.address?.state}
                            </Typography>
                          </Box>
                        </Box>
                        <Box className="topCardStarBox">
                          <Typography className='poppins' sx={{ color: "#888888" }}>{locationData?.propertyViews} Views</Typography>
                        </Box>
                      </Box>
                    </Box>
                    {/* <Box className="redPriceBanarBox">
                      <Box className="redPriceBanar">
                        <Typography className='inr_Text'>INR.</Typography>
                        <Typography ml={1.5} className='redBanerPrice'>{formData?.price}/</Typography>
                        <Typography mt={1} mr={1.5} className='hr'>Night</Typography>
                        <Typography className="banerLastText">{minHours} hr. minimum</Typography>
                      </Box>
                    </Box> */}
                    {/* <Box className="addDays">
                      <Box className="addDaysBtn" sx={{ cursor: "pointer" }} onClick={handleAddDay}>
                        <Typography>Add a day</Typography>
                      </Box>
                      <Typography className='totalHoursText'>Total hours: {formData?.total_hours}</Typography>
                    </Box> */}
                    <div>
                      {staySection()}
                    </div>

                    {/* {!formData?.couponId && (
                      <>
                        <Box className="couponcodeBox">
                          <Typography>Coupon Codes: </Typography>
                        </Box>

                        <Box className="couponEnterField">
                          <TextField sx={{ width: "100%" }} placeholder='Enter your Coupon' value={couponCode} onChange={onCouponChange} />
                          <Box className="redArrowBtn" style={{ cursor: 'pointer' }} onClick={applyCoupon}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="10" viewBox="0 0 24 10" fill="none">
                              <path d="M23.1062 4.46337L18.8112 0.223797C18.3271 -0.254504 17.4988 0.0847786 17.4988 0.760427V4.02784H0.972156C0.434554 4.02784 0 4.46337 0 5C0 5.53663 0.434554 5.97216 0.972156 5.97216H17.4988V9.23957C17.4988 9.91522 18.3271 10.2545 18.8112 9.7762L23.1062 5.53663C23.4066 5.24012 23.4066 4.75988 23.1062 4.46337Z" fill="white" />
                            </svg>
                          </Box>
                        </Box></>
                    )} */}
                    {/* {formData?.couponId && (
                      <Box className='couponText'>
                        <Typography>Congratulations coupon applied successfully, You got {formData?.couponDiscount} discount on booking charges.</Typography>
                        <Typography style={{ color: 'red', cursor: 'pointer' }} onClick={removeCoupon}>Remove</Typography>
                      </Box>)} */}
                    {/* {couponError && <p style={{ color: 'red' }}>{couponError}</p>} */}
                  </Box>
                  <Box className="BookingDetailsTowBox">
                    <Box className="bookingCharge">
                      <Box className="bookingPriceTextBox">
                        <Typography className='BookingChargesText'>Weekday : {formData?.weekdays} * {formData?.weekdayPrice}
                          <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12" fill="none">
                            <title>Transparent details on charges associated with booking your selected venue</title>
                            <path d="M5.99975 0C2.68648 0 0 2.68648 0 5.99975C0 9.31302 2.68648 12 5.99975 12C9.31302 12 12 9.31302 12 5.99975C12 2.68648 9.31302 0 5.99975 0ZM7.24876 9.29879C6.93994 9.4207 6.6941 9.51314 6.50971 9.57714C6.32584 9.64114 6.112 9.67314 5.8687 9.67314C5.49486 9.67314 5.20381 9.58171 4.99657 9.39937C4.78933 9.21702 4.68622 8.98591 4.68622 8.70502C4.68622 8.59581 4.69384 8.48406 4.70908 8.37029C4.72483 8.25651 4.74971 8.12851 4.78375 7.98476L5.17029 6.61943C5.20432 6.48838 5.23378 6.36394 5.25714 6.24813C5.28051 6.1313 5.29168 6.02413 5.29168 5.9266C5.29168 5.75289 5.25562 5.63098 5.184 5.56241C5.11137 5.49384 4.97473 5.46032 4.77105 5.46032C4.67149 5.46032 4.56889 5.47505 4.46375 5.50603C4.35962 5.53803 4.26921 5.56698 4.19505 5.59543L4.29714 5.17486C4.5501 5.07175 4.79238 4.98337 5.02349 4.91022C5.2546 4.83606 5.47302 4.79949 5.67873 4.79949C6.05003 4.79949 6.33651 4.8899 6.53816 5.0687C6.73879 5.248 6.83987 5.48114 6.83987 5.76762C6.83987 5.82705 6.83276 5.93168 6.81905 6.08102C6.80533 6.23086 6.77943 6.36749 6.74184 6.49295L6.35733 7.85422C6.32584 7.96343 6.2979 8.08838 6.27251 8.22806C6.24762 8.36775 6.23543 8.47441 6.23543 8.54603C6.23543 8.72686 6.27556 8.85029 6.35683 8.91581C6.43708 8.98133 6.57778 9.01435 6.77689 9.01435C6.87086 9.01435 6.976 8.99759 7.09486 8.96508C7.2127 8.93257 7.29803 8.90362 7.35187 8.87873L7.24876 9.29879ZM7.1807 3.77346C7.0014 3.94006 6.78552 4.02337 6.53308 4.02337C6.28114 4.02337 6.06375 3.94006 5.88292 3.77346C5.70311 3.60686 5.61219 3.40419 5.61219 3.16749C5.61219 2.9313 5.70362 2.72813 5.88292 2.56C6.06375 2.39137 6.28114 2.30756 6.53308 2.30756C6.78552 2.30756 7.00191 2.39137 7.1807 2.56C7.36 2.72813 7.4499 2.9313 7.4499 3.16749C7.4499 3.4047 7.36 3.60686 7.1807 3.77346Z" fill="#B3B3B3" />
                          </svg>

                        </Typography>
                        <Typography className='InrText'>INR {formData?.weekdayBookingPrice}</Typography>
                      </Box>
                      <Box className="bookingPriceTextBox">
                        <Typography className='BookingChargesText'>Weekend : {formData?.weekends} * {formData?.weekEndPrice}
                          <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12" fill="none">
                            <title>Transparent details on charges associated with booking your selected venue</title>
                            <path d="M5.99975 0C2.68648 0 0 2.68648 0 5.99975C0 9.31302 2.68648 12 5.99975 12C9.31302 12 12 9.31302 12 5.99975C12 2.68648 9.31302 0 5.99975 0ZM7.24876 9.29879C6.93994 9.4207 6.6941 9.51314 6.50971 9.57714C6.32584 9.64114 6.112 9.67314 5.8687 9.67314C5.49486 9.67314 5.20381 9.58171 4.99657 9.39937C4.78933 9.21702 4.68622 8.98591 4.68622 8.70502C4.68622 8.59581 4.69384 8.48406 4.70908 8.37029C4.72483 8.25651 4.74971 8.12851 4.78375 7.98476L5.17029 6.61943C5.20432 6.48838 5.23378 6.36394 5.25714 6.24813C5.28051 6.1313 5.29168 6.02413 5.29168 5.9266C5.29168 5.75289 5.25562 5.63098 5.184 5.56241C5.11137 5.49384 4.97473 5.46032 4.77105 5.46032C4.67149 5.46032 4.56889 5.47505 4.46375 5.50603C4.35962 5.53803 4.26921 5.56698 4.19505 5.59543L4.29714 5.17486C4.5501 5.07175 4.79238 4.98337 5.02349 4.91022C5.2546 4.83606 5.47302 4.79949 5.67873 4.79949C6.05003 4.79949 6.33651 4.8899 6.53816 5.0687C6.73879 5.248 6.83987 5.48114 6.83987 5.76762C6.83987 5.82705 6.83276 5.93168 6.81905 6.08102C6.80533 6.23086 6.77943 6.36749 6.74184 6.49295L6.35733 7.85422C6.32584 7.96343 6.2979 8.08838 6.27251 8.22806C6.24762 8.36775 6.23543 8.47441 6.23543 8.54603C6.23543 8.72686 6.27556 8.85029 6.35683 8.91581C6.43708 8.98133 6.57778 9.01435 6.77689 9.01435C6.87086 9.01435 6.976 8.99759 7.09486 8.96508C7.2127 8.93257 7.29803 8.90362 7.35187 8.87873L7.24876 9.29879ZM7.1807 3.77346C7.0014 3.94006 6.78552 4.02337 6.53308 4.02337C6.28114 4.02337 6.06375 3.94006 5.88292 3.77346C5.70311 3.60686 5.61219 3.40419 5.61219 3.16749C5.61219 2.9313 5.70362 2.72813 5.88292 2.56C6.06375 2.39137 6.28114 2.30756 6.53308 2.30756C6.78552 2.30756 7.00191 2.39137 7.1807 2.56C7.36 2.72813 7.4499 2.9313 7.4499 3.16749C7.4499 3.4047 7.36 3.60686 7.1807 3.77346Z" fill="#B3B3B3" />
                          </svg>

                        </Typography>
                        <Typography className='InrText'>INR {formData?.weekendBookingPrice}</Typography>
                      </Box>
                      { (formData?.extraGuests > 0 && formData?.extraGuestFee) ? (
                        <Box className="bookingPriceTextBox">
                          <Typography className='BookingChargesText'>Extra Guest Fee : {formData?.extraGuests} * {formData?.weekends + formData?.weekdays} * {formData?.extraGuestFee}
                            <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12" fill="none">
                              <title>Transparent details on charges associated with booking your selected venue</title>
                              <path d="M5.99975 0C2.68648 0 0 2.68648 0 5.99975C0 9.31302 2.68648 12 5.99975 12C9.31302 12 12 9.31302 12 5.99975C12 2.68648 9.31302 0 5.99975 0ZM7.24876 9.29879C6.93994 9.4207 6.6941 9.51314 6.50971 9.57714C6.32584 9.64114 6.112 9.67314 5.8687 9.67314C5.49486 9.67314 5.20381 9.58171 4.99657 9.39937C4.78933 9.21702 4.68622 8.98591 4.68622 8.70502C4.68622 8.59581 4.69384 8.48406 4.70908 8.37029C4.72483 8.25651 4.74971 8.12851 4.78375 7.98476L5.17029 6.61943C5.20432 6.48838 5.23378 6.36394 5.25714 6.24813C5.28051 6.1313 5.29168 6.02413 5.29168 5.9266C5.29168 5.75289 5.25562 5.63098 5.184 5.56241C5.11137 5.49384 4.97473 5.46032 4.77105 5.46032C4.67149 5.46032 4.56889 5.47505 4.46375 5.50603C4.35962 5.53803 4.26921 5.56698 4.19505 5.59543L4.29714 5.17486C4.5501 5.07175 4.79238 4.98337 5.02349 4.91022C5.2546 4.83606 5.47302 4.79949 5.67873 4.79949C6.05003 4.79949 6.33651 4.8899 6.53816 5.0687C6.73879 5.248 6.83987 5.48114 6.83987 5.76762C6.83987 5.82705 6.83276 5.93168 6.81905 6.08102C6.80533 6.23086 6.77943 6.36749 6.74184 6.49295L6.35733 7.85422C6.32584 7.96343 6.2979 8.08838 6.27251 8.22806C6.24762 8.36775 6.23543 8.47441 6.23543 8.54603C6.23543 8.72686 6.27556 8.85029 6.35683 8.91581C6.43708 8.98133 6.57778 9.01435 6.77689 9.01435C6.87086 9.01435 6.976 8.99759 7.09486 8.96508C7.2127 8.93257 7.29803 8.90362 7.35187 8.87873L7.24876 9.29879ZM7.1807 3.77346C7.0014 3.94006 6.78552 4.02337 6.53308 4.02337C6.28114 4.02337 6.06375 3.94006 5.88292 3.77346C5.70311 3.60686 5.61219 3.40419 5.61219 3.16749C5.61219 2.9313 5.70362 2.72813 5.88292 2.56C6.06375 2.39137 6.28114 2.30756 6.53308 2.30756C6.78552 2.30756 7.00191 2.39137 7.1807 2.56C7.36 2.72813 7.4499 2.9313 7.4499 3.16749C7.4499 3.4047 7.36 3.60686 7.1807 3.77346Z" fill="#B3B3B3" />
                            </svg>
                          </Typography>
                          <Typography className='InrText'>INR {formData?.extraGuestTotalFee}</Typography>
                        </Box>
                      ) : (<></>)
                      }
                      {/* <Box className="bookingPriceTextBox">
                        <Typography className='BookingChargesText'>Booking Charges :
                          <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12" fill="none">
                            <title>Transparent details on charges associated with booking your selected venue</title>
                            <path d="M5.99975 0C2.68648 0 0 2.68648 0 5.99975C0 9.31302 2.68648 12 5.99975 12C9.31302 12 12 9.31302 12 5.99975C12 2.68648 9.31302 0 5.99975 0ZM7.24876 9.29879C6.93994 9.4207 6.6941 9.51314 6.50971 9.57714C6.32584 9.64114 6.112 9.67314 5.8687 9.67314C5.49486 9.67314 5.20381 9.58171 4.99657 9.39937C4.78933 9.21702 4.68622 8.98591 4.68622 8.70502C4.68622 8.59581 4.69384 8.48406 4.70908 8.37029C4.72483 8.25651 4.74971 8.12851 4.78375 7.98476L5.17029 6.61943C5.20432 6.48838 5.23378 6.36394 5.25714 6.24813C5.28051 6.1313 5.29168 6.02413 5.29168 5.9266C5.29168 5.75289 5.25562 5.63098 5.184 5.56241C5.11137 5.49384 4.97473 5.46032 4.77105 5.46032C4.67149 5.46032 4.56889 5.47505 4.46375 5.50603C4.35962 5.53803 4.26921 5.56698 4.19505 5.59543L4.29714 5.17486C4.5501 5.07175 4.79238 4.98337 5.02349 4.91022C5.2546 4.83606 5.47302 4.79949 5.67873 4.79949C6.05003 4.79949 6.33651 4.8899 6.53816 5.0687C6.73879 5.248 6.83987 5.48114 6.83987 5.76762C6.83987 5.82705 6.83276 5.93168 6.81905 6.08102C6.80533 6.23086 6.77943 6.36749 6.74184 6.49295L6.35733 7.85422C6.32584 7.96343 6.2979 8.08838 6.27251 8.22806C6.24762 8.36775 6.23543 8.47441 6.23543 8.54603C6.23543 8.72686 6.27556 8.85029 6.35683 8.91581C6.43708 8.98133 6.57778 9.01435 6.77689 9.01435C6.87086 9.01435 6.976 8.99759 7.09486 8.96508C7.2127 8.93257 7.29803 8.90362 7.35187 8.87873L7.24876 9.29879ZM7.1807 3.77346C7.0014 3.94006 6.78552 4.02337 6.53308 4.02337C6.28114 4.02337 6.06375 3.94006 5.88292 3.77346C5.70311 3.60686 5.61219 3.40419 5.61219 3.16749C5.61219 2.9313 5.70362 2.72813 5.88292 2.56C6.06375 2.39137 6.28114 2.30756 6.53308 2.30756C6.78552 2.30756 7.00191 2.39137 7.1807 2.56C7.36 2.72813 7.4499 2.9313 7.4499 3.16749C7.4499 3.4047 7.36 3.60686 7.1807 3.77346Z" fill="#B3B3B3" />
                          </svg>

                        </Typography>
                        <Typography className='InrText'>INR {formData?.bookingCharges}</Typography>
                      </Box> */}

                      <Box mb={4} className="bookingPriceTextBox" style={{marginBottom : '24px'}}>
                        <Typography className='BookingChargesText'>Convenience fee :
                          <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12" fill="none">
                            <title>To cover SpotLet's essential operational expenses</title>
                            <path d="M5.99975 0C2.68648 0 0 2.68648 0 5.99975C0 9.31302 2.68648 12 5.99975 12C9.31302 12 12 9.31302 12 5.99975C12 2.68648 9.31302 0 5.99975 0ZM7.24876 9.29879C6.93994 9.4207 6.6941 9.51314 6.50971 9.57714C6.32584 9.64114 6.112 9.67314 5.8687 9.67314C5.49486 9.67314 5.20381 9.58171 4.99657 9.39937C4.78933 9.21702 4.68622 8.98591 4.68622 8.70502C4.68622 8.59581 4.69384 8.48406 4.70908 8.37029C4.72483 8.25651 4.74971 8.12851 4.78375 7.98476L5.17029 6.61943C5.20432 6.48838 5.23378 6.36394 5.25714 6.24813C5.28051 6.1313 5.29168 6.02413 5.29168 5.9266C5.29168 5.75289 5.25562 5.63098 5.184 5.56241C5.11137 5.49384 4.97473 5.46032 4.77105 5.46032C4.67149 5.46032 4.56889 5.47505 4.46375 5.50603C4.35962 5.53803 4.26921 5.56698 4.19505 5.59543L4.29714 5.17486C4.5501 5.07175 4.79238 4.98337 5.02349 4.91022C5.2546 4.83606 5.47302 4.79949 5.67873 4.79949C6.05003 4.79949 6.33651 4.8899 6.53816 5.0687C6.73879 5.248 6.83987 5.48114 6.83987 5.76762C6.83987 5.82705 6.83276 5.93168 6.81905 6.08102C6.80533 6.23086 6.77943 6.36749 6.74184 6.49295L6.35733 7.85422C6.32584 7.96343 6.2979 8.08838 6.27251 8.22806C6.24762 8.36775 6.23543 8.47441 6.23543 8.54603C6.23543 8.72686 6.27556 8.85029 6.35683 8.91581C6.43708 8.98133 6.57778 9.01435 6.77689 9.01435C6.87086 9.01435 6.976 8.99759 7.09486 8.96508C7.2127 8.93257 7.29803 8.90362 7.35187 8.87873L7.24876 9.29879ZM7.1807 3.77346C7.0014 3.94006 6.78552 4.02337 6.53308 4.02337C6.28114 4.02337 6.06375 3.94006 5.88292 3.77346C5.70311 3.60686 5.61219 3.40419 5.61219 3.16749C5.61219 2.9313 5.70362 2.72813 5.88292 2.56C6.06375 2.39137 6.28114 2.30756 6.53308 2.30756C6.78552 2.30756 7.00191 2.39137 7.1807 2.56C7.36 2.72813 7.4499 2.9313 7.4499 3.16749C7.4499 3.4047 7.36 3.60686 7.1807 3.77346Z" fill="#B3B3B3" />
                          </svg>
                        </Typography>
                        <Typography className='OnlyBookingPrice'>(Only on booking price)</Typography>
                        <Typography className='InrText'>INR {formData?.convenienceFee}</Typography>
                      </Box>

                      {/* <Box className="bookingPriceTextBox">
                        <Typography className='BookingChargesText'>Discount  :
                          <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12" fill="none">
                            <title>Insights into available discounts, making your venue booking cost-effective</title>
                            <path d="M5.99975 0C2.68648 0 0 2.68648 0 5.99975C0 9.31302 2.68648 12 5.99975 12C9.31302 12 12 9.31302 12 5.99975C12 2.68648 9.31302 0 5.99975 0ZM7.24876 9.29879C6.93994 9.4207 6.6941 9.51314 6.50971 9.57714C6.32584 9.64114 6.112 9.67314 5.8687 9.67314C5.49486 9.67314 5.20381 9.58171 4.99657 9.39937C4.78933 9.21702 4.68622 8.98591 4.68622 8.70502C4.68622 8.59581 4.69384 8.48406 4.70908 8.37029C4.72483 8.25651 4.74971 8.12851 4.78375 7.98476L5.17029 6.61943C5.20432 6.48838 5.23378 6.36394 5.25714 6.24813C5.28051 6.1313 5.29168 6.02413 5.29168 5.9266C5.29168 5.75289 5.25562 5.63098 5.184 5.56241C5.11137 5.49384 4.97473 5.46032 4.77105 5.46032C4.67149 5.46032 4.56889 5.47505 4.46375 5.50603C4.35962 5.53803 4.26921 5.56698 4.19505 5.59543L4.29714 5.17486C4.5501 5.07175 4.79238 4.98337 5.02349 4.91022C5.2546 4.83606 5.47302 4.79949 5.67873 4.79949C6.05003 4.79949 6.33651 4.8899 6.53816 5.0687C6.73879 5.248 6.83987 5.48114 6.83987 5.76762C6.83987 5.82705 6.83276 5.93168 6.81905 6.08102C6.80533 6.23086 6.77943 6.36749 6.74184 6.49295L6.35733 7.85422C6.32584 7.96343 6.2979 8.08838 6.27251 8.22806C6.24762 8.36775 6.23543 8.47441 6.23543 8.54603C6.23543 8.72686 6.27556 8.85029 6.35683 8.91581C6.43708 8.98133 6.57778 9.01435 6.77689 9.01435C6.87086 9.01435 6.976 8.99759 7.09486 8.96508C7.2127 8.93257 7.29803 8.90362 7.35187 8.87873L7.24876 9.29879ZM7.1807 3.77346C7.0014 3.94006 6.78552 4.02337 6.53308 4.02337C6.28114 4.02337 6.06375 3.94006 5.88292 3.77346C5.70311 3.60686 5.61219 3.40419 5.61219 3.16749C5.61219 2.9313 5.70362 2.72813 5.88292 2.56C6.06375 2.39137 6.28114 2.30756 6.53308 2.30756C6.78552 2.30756 7.00191 2.39137 7.1807 2.56C7.36 2.72813 7.4499 2.9313 7.4499 3.16749C7.4499 3.4047 7.36 3.60686 7.1807 3.77346Z" fill="#B3B3B3" />
                          </svg>

                        </Typography>
                        <Typography className='InrText'>-INR {formData?.discountPrice}</Typography>
                      </Box> */}
                      <Box className="bookingPriceTextBox">
                        <Typography className='BookingChargesText'>Cleaning fee :
                          <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12" fill="none">
                            <title> Details on any cleaning fees associated with your chosen venue</title>
                            <path d="M5.99975 0C2.68648 0 0 2.68648 0 5.99975C0 9.31302 2.68648 12 5.99975 12C9.31302 12 12 9.31302 12 5.99975C12 2.68648 9.31302 0 5.99975 0ZM7.24876 9.29879C6.93994 9.4207 6.6941 9.51314 6.50971 9.57714C6.32584 9.64114 6.112 9.67314 5.8687 9.67314C5.49486 9.67314 5.20381 9.58171 4.99657 9.39937C4.78933 9.21702 4.68622 8.98591 4.68622 8.70502C4.68622 8.59581 4.69384 8.48406 4.70908 8.37029C4.72483 8.25651 4.74971 8.12851 4.78375 7.98476L5.17029 6.61943C5.20432 6.48838 5.23378 6.36394 5.25714 6.24813C5.28051 6.1313 5.29168 6.02413 5.29168 5.9266C5.29168 5.75289 5.25562 5.63098 5.184 5.56241C5.11137 5.49384 4.97473 5.46032 4.77105 5.46032C4.67149 5.46032 4.56889 5.47505 4.46375 5.50603C4.35962 5.53803 4.26921 5.56698 4.19505 5.59543L4.29714 5.17486C4.5501 5.07175 4.79238 4.98337 5.02349 4.91022C5.2546 4.83606 5.47302 4.79949 5.67873 4.79949C6.05003 4.79949 6.33651 4.8899 6.53816 5.0687C6.73879 5.248 6.83987 5.48114 6.83987 5.76762C6.83987 5.82705 6.83276 5.93168 6.81905 6.08102C6.80533 6.23086 6.77943 6.36749 6.74184 6.49295L6.35733 7.85422C6.32584 7.96343 6.2979 8.08838 6.27251 8.22806C6.24762 8.36775 6.23543 8.47441 6.23543 8.54603C6.23543 8.72686 6.27556 8.85029 6.35683 8.91581C6.43708 8.98133 6.57778 9.01435 6.77689 9.01435C6.87086 9.01435 6.976 8.99759 7.09486 8.96508C7.2127 8.93257 7.29803 8.90362 7.35187 8.87873L7.24876 9.29879ZM7.1807 3.77346C7.0014 3.94006 6.78552 4.02337 6.53308 4.02337C6.28114 4.02337 6.06375 3.94006 5.88292 3.77346C5.70311 3.60686 5.61219 3.40419 5.61219 3.16749C5.61219 2.9313 5.70362 2.72813 5.88292 2.56C6.06375 2.39137 6.28114 2.30756 6.53308 2.30756C6.78552 2.30756 7.00191 2.39137 7.1807 2.56C7.36 2.72813 7.4499 2.9313 7.4499 3.16749C7.4499 3.4047 7.36 3.60686 7.1807 3.77346Z" fill="#B3B3B3" />
                          </svg>

                        </Typography>
                        <Typography className='InrText'>INR {formData?.cleaningFee}</Typography>
                      </Box>
                      {/* <Box className="bookingPriceTextBox">
                        <Typography className='BookingChargesText'>Coupon value :
                          <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12" fill="none">
                            <title>Learn about the value and application of available venue coupons</title>
                            <path d="M5.99975 0C2.68648 0 0 2.68648 0 5.99975C0 9.31302 2.68648 12 5.99975 12C9.31302 12 12 9.31302 12 5.99975C12 2.68648 9.31302 0 5.99975 0ZM7.24876 9.29879C6.93994 9.4207 6.6941 9.51314 6.50971 9.57714C6.32584 9.64114 6.112 9.67314 5.8687 9.67314C5.49486 9.67314 5.20381 9.58171 4.99657 9.39937C4.78933 9.21702 4.68622 8.98591 4.68622 8.70502C4.68622 8.59581 4.69384 8.48406 4.70908 8.37029C4.72483 8.25651 4.74971 8.12851 4.78375 7.98476L5.17029 6.61943C5.20432 6.48838 5.23378 6.36394 5.25714 6.24813C5.28051 6.1313 5.29168 6.02413 5.29168 5.9266C5.29168 5.75289 5.25562 5.63098 5.184 5.56241C5.11137 5.49384 4.97473 5.46032 4.77105 5.46032C4.67149 5.46032 4.56889 5.47505 4.46375 5.50603C4.35962 5.53803 4.26921 5.56698 4.19505 5.59543L4.29714 5.17486C4.5501 5.07175 4.79238 4.98337 5.02349 4.91022C5.2546 4.83606 5.47302 4.79949 5.67873 4.79949C6.05003 4.79949 6.33651 4.8899 6.53816 5.0687C6.73879 5.248 6.83987 5.48114 6.83987 5.76762C6.83987 5.82705 6.83276 5.93168 6.81905 6.08102C6.80533 6.23086 6.77943 6.36749 6.74184 6.49295L6.35733 7.85422C6.32584 7.96343 6.2979 8.08838 6.27251 8.22806C6.24762 8.36775 6.23543 8.47441 6.23543 8.54603C6.23543 8.72686 6.27556 8.85029 6.35683 8.91581C6.43708 8.98133 6.57778 9.01435 6.77689 9.01435C6.87086 9.01435 6.976 8.99759 7.09486 8.96508C7.2127 8.93257 7.29803 8.90362 7.35187 8.87873L7.24876 9.29879ZM7.1807 3.77346C7.0014 3.94006 6.78552 4.02337 6.53308 4.02337C6.28114 4.02337 6.06375 3.94006 5.88292 3.77346C5.70311 3.60686 5.61219 3.40419 5.61219 3.16749C5.61219 2.9313 5.70362 2.72813 5.88292 2.56C6.06375 2.39137 6.28114 2.30756 6.53308 2.30756C6.78552 2.30756 7.00191 2.39137 7.1807 2.56C7.36 2.72813 7.4499 2.9313 7.4499 3.16749C7.4499 3.4047 7.36 3.60686 7.1807 3.77346Z" fill="#B3B3B3" />
                          </svg>

                        </Typography>
                        <Typography className='InrText'>-INR {formData?.couponDiscount}.00</Typography>
                      </Box> */}
                    </Box>


                    <Box className="bookingText">
                      <Box className="bookingTextHeader">
                        <Typography sx={{ alignItems: "center" }} pr={2}>Taxes
                          <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12" fill="none" style={{ marginLeft: "5" }}>
                            <title>Transparent information on taxes applied to your venue booking</title>
                            <path d="M5.99975 0C2.68648 0 0 2.68648 0 5.99975C0 9.31302 2.68648 12 5.99975 12C9.31302 12 12 9.31302 12 5.99975C12 2.68648 9.31302 0 5.99975 0ZM7.24876 9.29879C6.93994 9.4207 6.6941 9.51314 6.50971 9.57714C6.32584 9.64114 6.112 9.67314 5.8687 9.67314C5.49486 9.67314 5.20381 9.58171 4.99657 9.39937C4.78933 9.21702 4.68622 8.98591 4.68622 8.70502C4.68622 8.59581 4.69384 8.48406 4.70908 8.37029C4.72483 8.25651 4.74971 8.12851 4.78375 7.98476L5.17029 6.61943C5.20432 6.48838 5.23378 6.36394 5.25714 6.24813C5.28051 6.1313 5.29168 6.02413 5.29168 5.9266C5.29168 5.75289 5.25562 5.63098 5.184 5.56241C5.11137 5.49384 4.97473 5.46032 4.77105 5.46032C4.67149 5.46032 4.56889 5.47505 4.46375 5.50603C4.35962 5.53803 4.26921 5.56698 4.19505 5.59543L4.29714 5.17486C4.5501 5.07175 4.79238 4.98337 5.02349 4.91022C5.2546 4.83606 5.47302 4.79949 5.67873 4.79949C6.05003 4.79949 6.33651 4.8899 6.53816 5.0687C6.73879 5.248 6.83987 5.48114 6.83987 5.76762C6.83987 5.82705 6.83276 5.93168 6.81905 6.08102C6.80533 6.23086 6.77943 6.36749 6.74184 6.49295L6.35733 7.85422C6.32584 7.96343 6.2979 8.08838 6.27251 8.22806C6.24762 8.36775 6.23543 8.47441 6.23543 8.54603C6.23543 8.72686 6.27556 8.85029 6.35683 8.91581C6.43708 8.98133 6.57778 9.01435 6.77689 9.01435C6.87086 9.01435 6.976 8.99759 7.09486 8.96508C7.2127 8.93257 7.29803 8.90362 7.35187 8.87873L7.24876 9.29879ZM7.1807 3.77346C7.0014 3.94006 6.78552 4.02337 6.53308 4.02337C6.28114 4.02337 6.06375 3.94006 5.88292 3.77346C5.70311 3.60686 5.61219 3.40419 5.61219 3.16749C5.61219 2.9313 5.70362 2.72813 5.88292 2.56C6.06375 2.39137 6.28114 2.30756 6.53308 2.30756C6.78552 2.30756 7.00191 2.39137 7.1807 2.56C7.36 2.72813 7.4499 2.9313 7.4499 3.16749C7.4499 3.4047 7.36 3.60686 7.1807 3.77346Z" fill="#B3B3B3" />
                          </svg>
                        </Typography>
                      </Box>
                      <Box className="bookingPriceTextBox marginTB">
                        <Box className="gstUperBox d-flex flex-column">
                          <Typography className='textSpamP'>Add GST @ 18%</Typography>
                          <span className='textSpam' >(taxes applicable on booking fee, convenience fee and cleaning fee)</span>
                        </Box>
                        <Typography className='InrText'>INR {formData?.gstFee}</Typography>
                      </Box>

                    </Box>
                    {/* <Box className="securityDeposit">
                      <Box className="bookingPriceTextBox">
                        <Box className="securityDepositSubBox">
                          <input type='checkBox' checked={addSecurity} onClick={handleSecurityDepo} />
                          <Typography ml={0.8} className='BookingChargesText sSize'>Security Deposit (<span className='refSpan'>Refundable</span>) :
                            <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12" fill="none">
                              <title>Understanding the deposit required to secure and book your venue</title>
                              <path d="M5.99975 0C2.68648 0 0 2.68648 0 5.99975C0 9.31302 2.68648 12 5.99975 12C9.31302 12 12 9.31302 12 5.99975C12 2.68648 9.31302 0 5.99975 0ZM7.24876 9.29879C6.93994 9.4207 6.6941 9.51314 6.50971 9.57714C6.32584 9.64114 6.112 9.67314 5.8687 9.67314C5.49486 9.67314 5.20381 9.58171 4.99657 9.39937C4.78933 9.21702 4.68622 8.98591 4.68622 8.70502C4.68622 8.59581 4.69384 8.48406 4.70908 8.37029C4.72483 8.25651 4.74971 8.12851 4.78375 7.98476L5.17029 6.61943C5.20432 6.48838 5.23378 6.36394 5.25714 6.24813C5.28051 6.1313 5.29168 6.02413 5.29168 5.9266C5.29168 5.75289 5.25562 5.63098 5.184 5.56241C5.11137 5.49384 4.97473 5.46032 4.77105 5.46032C4.67149 5.46032 4.56889 5.47505 4.46375 5.50603C4.35962 5.53803 4.26921 5.56698 4.19505 5.59543L4.29714 5.17486C4.5501 5.07175 4.79238 4.98337 5.02349 4.91022C5.2546 4.83606 5.47302 4.79949 5.67873 4.79949C6.05003 4.79949 6.33651 4.8899 6.53816 5.0687C6.73879 5.248 6.83987 5.48114 6.83987 5.76762C6.83987 5.82705 6.83276 5.93168 6.81905 6.08102C6.80533 6.23086 6.77943 6.36749 6.74184 6.49295L6.35733 7.85422C6.32584 7.96343 6.2979 8.08838 6.27251 8.22806C6.24762 8.36775 6.23543 8.47441 6.23543 8.54603C6.23543 8.72686 6.27556 8.85029 6.35683 8.91581C6.43708 8.98133 6.57778 9.01435 6.77689 9.01435C6.87086 9.01435 6.976 8.99759 7.09486 8.96508C7.2127 8.93257 7.29803 8.90362 7.35187 8.87873L7.24876 9.29879ZM7.1807 3.77346C7.0014 3.94006 6.78552 4.02337 6.53308 4.02337C6.28114 4.02337 6.06375 3.94006 5.88292 3.77346C5.70311 3.60686 5.61219 3.40419 5.61219 3.16749C5.61219 2.9313 5.70362 2.72813 5.88292 2.56C6.06375 2.39137 6.28114 2.30756 6.53308 2.30756C6.78552 2.30756 7.00191 2.39137 7.1807 2.56C7.36 2.72813 7.4499 2.9313 7.4499 3.16749C7.4499 3.4047 7.36 3.60686 7.1807 3.77346Z" fill="#B3B3B3" />
                            </svg>

                          </Typography>
                        </Box>
                        <Typography className='InrText '>INR {parseFloat(locationData?.pricing?.stay?.securityDeposite).toFixed(2) || '0.00'}</Typography>
                      </Box>
                    </Box> */}
                    <Box className="totalPayment">
                      <Box className="totalPriceTextBox">
                        <Typography className='totalPriceLabel'>Total Payment :</Typography>
                        <Typography className='totalPriceAmount'>INR {formData?.totalPayment}</Typography>
                      </Box>

                    </Box>

                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box >
      {showConfirmationModel && (
        <ConfirmationModel
          show={showConfirmationModel}
          handleClose={handlePopUp}
          handleOk={handlePopUp}
          title={confirmationTitle}
          body={confirmationBody}
        />
      )}
      {loader && <PageLoader />}
    </>
  )
}

export default Booking