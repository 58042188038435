import { Modal } from "react-bootstrap";
import { useEffect } from "react";
import Constants from "../../utils/Constants";


const { Header, Footer, Title, Body } = Modal;

export function PrivacyAndPolicyView({
    handleClose,
    show,
    sendAgree
}) {

    let acceptBtn;
    useEffect(() => {
        acceptBtn = document.querySelector('.agree-btn');
    })

    const handleScroll = (e) => {
        if (e.target.scrollHeight - e.target.scrollTop < 700) {
            acceptBtn.classList.add('enable-btn');
            acceptBtn.addEventListener('click', sendAgree)
        }
    };
    const scrollDown = () => {
        const scroll = document.getElementById('privacy_scroll');
        scroll.scrollTo({ left: 0, top: scroll.scrollHeight, behavior: "smooth" });
    }


    return (
        <Modal show={show} onHide={handleClose}>
            <Header closeButton>
                <Title>Privacy Policy</Title>
            </Header>
            <Body >
                <div>
                    <div className="d-flex justify-content-end">
                        <i class="bi bi-arrow-down-circle" title="click to scroll to down" onClick={scrollDown}></i>
                    </div>
                    <div  id='privacy_scroll' onScroll={handleScroll} class="popup-select-list">
                        <div className="row justify-content-center" >
                            <Constants type = {"privacy"} />
                        </div>
                    </div>
                </div>
            </Body>
            <Footer className="TandC-Footer">
                <button type='button' className="agree-btn">I AGREE</button>
            </Footer>
        </Modal>
    );
}