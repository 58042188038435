import React, { useState, useRef, useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import { Formik } from "formik";
import * as yup from "yup";
import { Button, Form } from "react-bootstrap";
import Moment from 'moment';
import {
  Box,
  Typography,
  Card,
  Pagination,
  Divider,
  Chip,
  Avatar,
  Modal,
} from "@mui/material";

import { PageLoader } from "../../components/PageLoader";

//Components
import { Header } from "../../components/NewHeader/Header"
import { Footer } from "../../components/Footer"
import { useAuthStore } from "../../store/auth";

//apis
import { getJobs, applyJob, uploadResume } from "../../apis/api"

// Images
import bannerPhoto from "../../assets/NewImages/Careers/careers-banner.webp";
import joinbg from "../../assets/NewImages/Careers/join-bg.webp";
import careerimg01 from "../../assets/NewImages/Careers/careers-img01.webp";
import careerimg02 from "../../assets/NewImages/Careers/careers-img02.webp";
import icon08 from "../../assets/NewImages/Careers/icon-08.svg";
import icon09 from "../../assets/NewImages/Careers/icon-09.svg";
import icon10 from "../../assets/NewImages/Careers/icon-10.svg";
import icon11 from "../../assets/NewImages/Careers/icon-11.svg";
import icon12 from "../../assets/NewImages/Careers/icon-12.svg";
import icon13 from "../../assets/NewImages/Careers/icon-13.svg";
import icon14 from "../../assets/NewImages/Careers/icon-14.svg";
import iconbriefcase from "../../assets/NewImages/Careers/icon-briefcase.svg";
import iconrupee from "../../assets/NewImages/Careers/icon-rupee.svg";
import iconlocation from "../../assets/NewImages/Careers/icon-location.svg";
import whiteCross from "../../assets/NewImages/Careers/whiteCross.svg"

// Modal 
import { ConfirmationModel } from '../../components/Modal/ConfirmationModel';
import JobApplyModal from '../../components/Modal/JobApplyModal';


import "./style.css";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 730,
  bgcolor: "background.paper",
  border: "0px solid #000",
  boxShadow: 0,
  p: 4,
};


const schema = yup.object().shape({
  position: yup.string().required('Position is required'),
  name: yup.string().required('Name is required'),
  qualification: yup.string().required('Qualification is required'),
  phoneNumber: yup.string().required('Mobile number is required')
    .min(10, 'Mobile number must be 10 digits')
    .max(10, 'Mobile number must be 10 digits'),
  altNumber: yup.string()
    .min(10, 'Mobile number must be 10 digits')
    .max(10, 'Mobile number must be 10 digits'),
  email: yup.string().email('Invalid email').required('Email Address is required'),
  experience: yup.string().required('Experience is required'),
  relExperience: yup.string().required('Relevant Experience is required'),
  currentCtc: yup.string().required('Currect Ctc is required'),
  expCtc: yup.string().required('Expected Ctc is required'),
  skils: yup.string().required('Skils is required'),
});

export default function Careers() {
  const navigate = useNavigate();
  const { userProfile } = useAuthStore();
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false)
  const [loader, setLoader] = useState(false)
  const [selectedJob, setSelectedJob] = useState('')
  const [jobs, setJobs] = useState([]);
  const [formData, setFormData] = useState({});
  const fileInputRef = useRef(null);
  const [count, setCount] = useState('')
  const [searchTerm, setSearchTerm] = useState('');
  const [description, setDescription] = useState([])
  const [index, setIndex] = useState([false, false]);

  const [seeMore, setSeeMore] = useState(false)

  // Modal
  const [showConfirmationModel, setShowConfirmationModel] = useState(false);
  const [confirmationTitle, setConfirmationTitle] = useState("");
  const [confirmationBody, setConfirmationBody] = useState("");

  const [showJobApplyModal, setShowJobApplyModal] = useState(false);
  const [jobTitle, setJobTitle] = useState("");

  const formInitialValues = {
    position: '',
    name: "",
    qualification: "",
    phoneNumber: "",
    altNumber: "",
    email: "",
    experience: "",
    relExperience: "",
    currentCtc: "",
    expCtc: "",
    skils: "",
  }
  const [initialValues, setInitialValues] = useState(formInitialValues);

  const handleChangePage = async (event, value) => {
    const res = await getJobs(value, "2", searchTerm);
    setJobs(res.data.jobs)
  }

  const handleUploadResume = async (e) => {
    try {
      if (e.target.files[0].size > 2097152) { //accepts upto 2MB.
        setShowConfirmationModel(true);
        setConfirmationBody("File is too big!");
        // alert("File is too big!")
      };
      let resume = new FormData();
      resume = { "resume": e.target.files[0] }
      const response = await uploadResume(resume);
      setFormData({ ...formData, resume: { url: response.data.url, } });
    } catch (error) {
      console.log(error);
    }
  };

  const onChange = (e) => {
    const { name, value } = e.target
    setFormData({ ...formData, [name]: value })
  }

  const submitForm = async (formValues) => {
    // if (!userProfile) {
    //   return navigate('/login')
    // }
    setLoading(true)
    try {
      setLoader(true)
      const data = { ...formData, ...formValues }
      const res = await applyJob(data, selectedJob, userProfile?._id)
      // setOpen(false)
      setLoading(false)
      setLoader(false)
      setFormData({})
      setShowConfirmationModel(true);
      setConfirmationBody(res?.data);
      // alert(res.data)
      location.reload()
    } catch (err) {
      console.log(err)
      // setOpen(false)
      setLoading(false)
      setLoader(false)
      setFormData({})
      setShowConfirmationModel(true);
      setConfirmationBody(err);
      // alert(err)
    }
  }

  const getJobDetails = async () => {
    try {
      setLoader(true)
      const res = await getJobs("1", "2", searchTerm);
      setJobs(res.data.jobs)
      setCount(Math.ceil((res.data.count) / 2))
      setLoader(false)
    } catch (err) {
      console.log(err)
      setLoader(false)
    }
  }

  useEffect(() => {
    window.scrollTo(0, 0);
    getJobDetails()
  }, [])


  const seeMoreModal = () => {
    return (
      <Modal
        open={seeMore}
        onClose={() => setSeeMore(false)}
        aria-labelledby="parent-modal-title"
        aria-describedby="parent-modal-description"
      >
        <Box className="modalbox" sx={{ ...style, width: 730 }}>
          <Typography variant="h5 SpotLetSubHead">Job Description</Typography>
          <img onClick={() => {
            setSeeMore(false)
          }} className="whiteCross" src={whiteCross} />
          <Box className="form-auto-scroll" sx={{ height: 550, overflowY: 'scroll' }}>

            <ul className='modal-ul' >
              {description.map((each) => <li className='modal-li SpotLetContent' > {each} </li>)}
            </ul>
          </Box>
          <div style={{ display: "flex", justifyContent: "end" }} className="one-column">
            <button
              className="see-more-cancel-btn SpotLetSubHead"
              onClick={() => setSeeMore(false)}
            >
              Cancel
            </button>
          </div>
        </Box>
      </Modal>
    )
  }

  // const prevOnClickSeeMore = () => {
  //   index[i] = !(index[i])
  //   setIndex([...index])
  // }

  return (
    <>
      <Header />
      <Box className="CareersContainer">
        <Box className="careers-bg">
          <img src={bannerPhoto} className="image-overlay" />
          <Box className="banner-content">
            <Typography variant="h1" gutterBottom className='SpotLetMainHead'>
              Careers
            </Typography>
            <Typography variant="subtitle1" className='SpotLetSubHead'>
            Join the SpotLet team and unlock a world of innovation, growth, and endless opportunities in the venue / locations rental marketplace.
            </Typography>
          </Box>
        </Box>
        <Box className="careers-content">
          <Box className="join-team">
            <figure>
              <img src={careerimg01} />
            </figure>
            <article>
              <Typography variant="h2" gutterBottom className='SpotLetSubHead'>
                Join Our Team and Shape the Future of Event and Production
                Spaces
              </Typography>
              <Typography variant="body1" gutterBottom className='SpotLetContent'>
                At SpotLet, we're not just a company; we're a community of
                innovators, dreamers, and creators. If you're passionate about
                transforming the way people discover, book, and experience event
                and production spaces, then you're in the right place. Join us
                on our mission to connect individuals, businesses, and
                filmmakers with extraordinary locations.
              </Typography>
              <Typography variant="h4" gutterBottom className='SpotLetSubHead'>
                Our Commitment to Diversity and Inclusion:
              </Typography>
              <Typography variant="body1" gutterBottom className='SpotLetContent'>
                At SpotLet, we embrace diversity in all its forms. We believe
                that a diverse and inclusive workplace fosters creativity,
                innovation, and empathy. We are committed to providing equal
                opportunities and cultivating an environment where every team
                member's unique background and perspective are valued. Join us
                and contribute to a culture that celebrates differences and
                empowers individuals to thrive.
              </Typography>
            </article>
          </Box>
          <Box className="why-work">
            <Box className="work-container">
              <article>
                <Typography variant="h2" gutterBottom className='SpotLetMainHead'>
                  Why Work at SpotLet?
                </Typography>
                <Box className="icon-content">
                  <Box className="icon">
                    <img src={icon08} />
                  </Box>
                  <Box className="content">
                    <Typography variant="subtitle1" className='SpotLetSubHead'>
                      Innovative Environment
                    </Typography>
                    <Typography variant="body1"  className='SpotLetContent'>
                      Be a part of a dynamic and forward-thinking team that's
                      changing the events and production industry.
                    </Typography>
                  </Box>
                </Box>
                <Box className="icon-content">
                  <Box className="icon">
                    <img src={icon09} />
                  </Box>
                  <Box className="content">
                    <Typography variant="subtitle1"  className='SpotLetSubHead'>
                      Collaborative Culture
                    </Typography>
                    <Typography variant="body1" className='SpotLetContent'>
                      We value collaboration and encourage diverse perspectives
                      to drive creativity and growth.
                    </Typography>
                  </Box>
                </Box>
                <Box className="icon-content">
                  <Box className="icon">
                    <img src={icon10} />
                  </Box>
                  <Box className="content">
                    <Typography variant="subtitle1" className='SpotLetSubHead'>Impactful Work</Typography>
                    <Typography variant="body1" className='SpotLetContent'>
                      Your contributions directly influence how people create
                      and celebrate memorable moments.
                    </Typography>
                  </Box>
                </Box>
                <Box className="icon-content">
                  <Box className="icon">
                    <img src={icon11} />
                  </Box>
                  <Box className="content">
                    <Typography variant="subtitle1" className='SpotLetSubHead'>
                      Continuous Learning
                    </Typography>
                    <Typography variant="body1" className='SpotLetContent'>
                      Grow professionally with opportunities for skill
                      development and learning new technologies.
                    </Typography>
                  </Box>
                </Box>
                <Box className="icon-content">
                  <Box className="icon">
                    <img src={icon12} />
                  </Box>
                  <Box className="content">
                    <Typography variant="subtitle1" className='SpotLetSubHead'>
                      Work-Life Balance
                    </Typography>
                    <Typography variant="body1" className='SpotLetContent'>
                      We believe in work-life harmony and offer flexible
                      schedules to support your well-being.
                    </Typography>
                  </Box>
                </Box>
              </article>
              <figure>
                <img src={careerimg02} />
              </figure>
            </Box>
          </Box>
          <Box className="why-work-with-us">
            {/* <Typography className="text-center" variant="h2" gutterBottom>
              Why work with us
            </Typography>
            <Typography
              className="text-center max-1000"
              variant="body1"
              gutterBottom
            >
              Join SpotLet and be part of a dynamic team shaping the future of creative space rentals. Enjoy a collaborative work environment, cutting-edge projects, and the opportunity to make a significant impact in the rapidly evolving space rental industry. Elevate your career with SpotLet!
            </Typography> */}

            <Box className="whywork-container">
              <Card className="our">
                <Box className="icon-content">
                  <Box className="icon">
                    <img src={icon13} />
                  </Box>
                  <Box className="content">
                    <Typography variant="subtitle1" className='SpotLetSubHead'>Our Passion</Typography>
                    <Typography variant="body1" className='SpotLetContent'>
                      We know that true innovation and exceptional work system
                      from individuals who are deeply passionate about what they
                      do. When you join our team, you'll become a part of a
                      community that thrives on enthusiasm and creative energy.
                    </Typography>
                  </Box>
                </Box>
              </Card>
              <Card className="our">
                <Box className="icon-content">
                  <Box className="icon">
                    <img src={icon14} />
                  </Box>
                  <Box className="content">
                    <Typography variant="subtitle1" className='SpotLetSubHead'>Our Perks</Typography>
                    <Typography variant="body1" className='SpotLetContent'>
                      Joining SpotLet means becoming a valued member of a dynamic
                      and supportive team. We offer a range of perks designed to
                      make your journey with us enjoyable, fulfilling, and
                      rewarding.
                    </Typography>
                  </Box>
                </Box>
              </Card>
            </Box>
          </Box>
          <Box className="join-us">
            <Box className="join-content">
              <img src={joinbg} className="image-overlay" />
              <article>
                <Typography variant="h2" gutterBottom className='SpotLetSubHead'>
                  Join Us in Making Every Event and Production Unforgettable
                </Typography>
                <Typography variant="body1" className='SpotLetContent'>
                  Our skills and passion could be the missing piece to our
                  puzzle. Come be a part of SpotLet's story and help shape the
                  future of exceptional event and production spaces.
                </Typography>
              </article>
              <aside>
                <Button className="readmore SpotLetSubHead" style={{ color: '#fff' }} variant="contained" size="large" onClick={() => {
                  // setOpen(true)
                  setSelectedJob('')
                  setFormData({})
                  setInitialValues(formInitialValues)
                  setShowJobApplyModal(true);
                }}>
                  Apply Now
                </Button>
              </aside>
            </Box>
          </Box>
          <Box className="current-opernings">
            <Box className="opernings-top">
              <Typography className="text-center SpotLetMainHead" variant="h2">
                Current Openings
              </Typography>
              <form class="careerSearchForm" onSubmit={(e) => {
                e.preventDefault()
                getJobDetails()
              }} style={{ maxWidth: '320px' }}>
                <input type="text" placeholder="Search Openings" onChange={(e) => setSearchTerm(e.target.value)} />
                <button type="submit"><i class="fa fa-search"></i></button>
              </form>
            </Box>
            <Box className="opernings-mid">
              {jobs?.map((file, i) => {
                return (
                  <Card className="job-opernings">
                    <Typography variant="h5" className='SpotLetSubHead'>{file?.jobTitle}</Typography>
                    <Typography className="company-name SpotLetContent" variant="subtitle2">
                      {file?.company}
                    </Typography>
                    <div className="mar5 d-flex" spacing={2}>
                      <Chip
                        className="job-info SpotLetContent"
                        avatar={<Avatar alt="" src={iconbriefcase} />}
                        label={`${file?.experience} years`}
                      />
                      <Chip
                        className="job-info SpotLetContent"
                        avatar={<Avatar alt="" src={iconrupee} />}
                        label={file?.salary}
                      />
                    </div>
                    <div className="mar5 d-flex" spacing={2}>
                      <Chip
                        className="job-info SpotLetContent"
                        avatar={<Avatar alt="" src={iconlocation} />}
                        label={file?.location}
                      />
                    </div>
                    <Divider />
                    <Box className="posted-apply">
                      <ul>
                        <li className='SpotLetContent'>
                          Posted: <span className="span600">{Moment(file?.updatedAt).format('DD-MM-YYYY')}</span>{" "}
                        </li>
                        <li className='SpotLetContent'>
                          Openings: <span className="span600">{file?.openings}</span>{" "}
                        </li>
                        <li className='SpotLetContent'>
                          Applicants: <span className="span600">{file?.noOfApplications}</span>{" "}
                        </li>
                      </ul>
                      <Button
                        className="btn-apply SpotLetSubHead"
                        variant="contained"
                        onClick={() => {
                          // setOpen(true)
                          setSelectedJob(file._id)
                          setFormData({ position: file.jobTitle })
                          setInitialValues({ ...initialValues, position: file.jobTitle })
                          setShowJobApplyModal(true)
                        }}
                      >
                        Apply
                      </Button>
                    </Box>
                    <Divider />
                    <Typography variant="h5" className='SpotLetSubHead'>Job Description</Typography>
                    <Typography className="job-descriptions" variant="body1">
                      <ul>
                        {file?.description.slice(0, index[i] ? file.description.length : 10)?.map((disc, i) => {
                          return (
                            <li key={i} className='SpotLetContent'>{disc}</li>
                          )
                        })}
                        {file?.description.length > 10 &&
                          <Typography
                            onClick={() => {
                              setSeeMore(true)
                              setDescription(file?.description)
                            }
                            }
                            pl={2} className='seeMoreText SpotLetContent' style={{ color: "#ea4335" }}>
                            {index[i] ? "- SEE LESS" : "+ READ MORE"}
                          </Typography>
                        }
                      </ul>
                    </Typography>
                    <Divider />
                    <Typography variant="h5" className='SpotLetSubHead'>Other Details</Typography>
                    <Typography className="job-other-details" variant="body1">
                      <ul>
                        <li>
                          {" "}
                          <span className="span600 SpotLetContent">Role:</span> {file?.role}
                        </li>
                        <li>
                          {" "}
                          <span className="span600 SpotLetContent">Industry Type:</span>{file?.IndustryType}
                        </li>
                        <li>
                          {" "}
                          <span className="span600 SpotLetContent">Department:</span>{file?.Department}
                        </li>
                        <li>
                          {" "}
                          <span className="span600 SpotLetContent">Employment Type:</span> {file?.employmentType}
                        </li>
                        <li>
                          {" "}
                          <span className="span600 SpotLetContent">Salary:</span> {file?.salary}

                        </li>
                        <li>
                          {" "}
                          <span className="span600 SpotLetContent">Role Category:</span>{file?.roleCategory}
                        </li>
                      </ul>
                    </Typography>
                  </Card>
                )
              })}
            </Box>
            {jobs.length ? (
              <Box className="opernings-bot">
                <Pagination count={count} variant="outlined" onChange={handleChangePage} />
              </Box>
            ) : ' '}
            <Modal
              open={open}
              onClose={() => setOpen(false)}
              aria-labelledby="parent-modal-title"
              aria-describedby="parent-modal-description"
            >
              <Box className="modalbox" sx={{ ...style, width: 730 }}>
                <Typography variant="h5">Apply Now</Typography>
                <img onClick={() => {
                  setOpen(false)
                  setSelectedJob('')
                  setFormData({})
                  setInitialValues(formInitialValues)
                }} className="whiteCross" src={whiteCross} />
                <Box className="form-auto-scroll" sx={{ height: 550, overflowY: 'scroll' }}>
                  <Formik
                    validationSchema={schema}
                    onSubmit={submitForm}
                    initialValues={initialValues}
                  >
                    {({
                      handleSubmit,
                      handleChange,
                      handleBlur,
                      touched,
                      isValid,
                      errors,
                    }) => (
                      <Form noValidate onSubmit={handleSubmit} autoComplete="off" className='modal-form' >
                        <Box className="form-box">
                          <div className="two-column">
                            <Typography className="applyInput" variant="body1">Position</Typography>
                            <Form.Control
                              type="text"
                              value={formData?.position}
                              name='position'
                              disabled={selectedJob}
                              onChange={(e) => handleChange(e)}
                              isInvalid={!!touched.position && !!errors.position}
                            />
                            <Form.Control.Feedback
                              type="invalid"
                              className="text-capitalize"
                            >
                              {errors.position}
                            </Form.Control.Feedback>
                          </div>
                        </Box>
                        <Box className="form-box">
                          <div className="two-column">
                            <Typography className="applyInput" variant="body1">Name<span className="required">*</span></Typography>
                            <Form.Control
                              type="text"
                              value={formData?.name}
                              name='name'
                              onChange={(e) => handleChange(e)}
                              isInvalid={!!touched.name && !!errors.name}
                            />
                            <Form.Control.Feedback
                              type="invalid"
                              className="text-capitalize"
                            >
                              {errors.name}
                            </Form.Control.Feedback>
                          </div>
                          <div className="two-column">
                            <Typography className="applyInput" variant="body1">Email Address<span className="required">*</span></Typography>
                            <Form.Control
                              type="text"
                              value={formData?.email}
                              name='email'
                              onChange={(e) => handleChange(e)}
                              isInvalid={!!touched.email && !!errors.email}
                            />
                            <Form.Control.Feedback
                              type="invalid"
                              className="text-capitalize"
                            >
                              {errors.email}
                            </Form.Control.Feedback>
                          </div>
                        </Box>
                        <Box className="form-box">
                          <div className="two-column">
                            <Typography className="applyInput" variant="body1" >Phone number<span className="required">*</span></Typography>
                            <Form.Control
                              type="number"
                              value={formData?.phoneNumber}
                              name='phoneNumber'
                              onChange={(e) => handleChange(e)}
                              isInvalid={!!touched.phoneNumber && !!errors.phoneNumber}
                            />
                            <Form.Control.Feedback
                              type="invalid"
                              className="text-capitalize"
                            >
                              {errors.phoneNumber}
                            </Form.Control.Feedback>
                          </div>
                          <div className="two-column">
                            <Typography className="applyInput" variant="body1">Alternate number</Typography>
                            <Form.Control
                              type="number"
                              value={formData?.altNumber}
                              name='altNumber'
                              onChange={(e) => handleChange(e)}
                              isInvalid={!!errors.altNumber}
                            />
                            <Form.Control.Feedback
                              type="invalid"
                              className="text-capitalize"
                            >
                              {errors.altNumber}
                            </Form.Control.Feedback>
                          </div>
                        </Box>
                        <Box className="form-box">
                          <div className="two-column">
                            <Typography className="applyInput" variant="body1">Qualification<span className="required">*</span></Typography>
                            <Form.Control
                              type="text"
                              value={formData?.posqualificationition}
                              name='qualification'
                              onChange={(e) => handleChange(e)}
                              isInvalid={!!touched.qualification && !!errors.qualification}
                            />
                            <Form.Control.Feedback
                              type="invalid"
                              className="text-capitalize"
                            >
                              {errors.qualification}
                            </Form.Control.Feedback>
                          </div>
                          <div className="two-column">
                            <Typography className="applyInput" variant="body1">IT experience<span className="required">*</span></Typography>
                            <Form.Control
                              type="number"
                              value={formData?.experience}
                              name='experience'
                              onChange={(e) => handleChange(e)}
                              isInvalid={!!touched.experience && !!errors.experience}
                            />
                            <Form.Control.Feedback
                              type="invalid"
                              className="text-capitalize"
                            >
                              {errors.experience}
                            </Form.Control.Feedback>
                          </div>
                        </Box>
                        <Box className="form-box">
                          <div className="two-column">
                            <Typography className="applyInput" variant="body1">
                              Relevant experience<span className="required">*</span>
                            </Typography>
                            <Form.Control
                              type="number"
                              value={formData?.relExperience}
                              name='relExperience'
                              onChange={(e) => handleChange(e)}
                              isInvalid={!!touched.relExperience && !!errors.relExperience}
                            />
                            <Form.Control.Feedback
                              type="invalid"
                              className="text-capitalize"
                            >
                              {errors.relExperience}
                            </Form.Control.Feedback>
                          </div>
                          <div className="two-column">
                            <Typography className="applyInput" variant="body1">Current CTC<span className="required">*</span></Typography>
                            <Form.Control
                              type="number"
                              value={formData?.currentCtc}
                              name='currentCtc'
                              onChange={(e) => handleChange(e)}
                              isInvalid={!!touched.currentCtc && !!errors.currentCtc}
                            />
                            <Form.Control.Feedback
                              type="invalid"
                              className="text-capitalize"
                            >
                              {errors.currentCtc}
                            </Form.Control.Feedback>
                          </div>
                        </Box>
                        <Box className="form-box">
                          <div className="two-column">
                            <Typography className="applyInput" variant="body1">Expected CTC<span className="required">*</span></Typography>
                            <Form.Control
                              type="number"
                              value={formData?.expCtc}
                              name='expCtc'
                              onChange={(e) => handleChange(e)}
                              isInvalid={!!touched.expCtc && !!errors.expCtc}
                            />
                            <Form.Control.Feedback
                              type="invalid"
                              className="text-capitalize"
                            >
                              {errors.expCtc}
                            </Form.Control.Feedback>
                          </div>
                          <div className="two-column">
                            <Typography className="applyInput" variant="body1">Key skills<span className="required">*</span></Typography>
                            <Form.Control
                              type="text"
                              value={formData?.skils}
                              name='skils'
                              onChange={(e) => handleChange(e)}
                              isInvalid={!!touched.skils && !!errors.skils}
                            />
                            <Form.Control.Feedback
                              type="invalid"
                              className="text-capitalize"
                            >
                              {errors.skils}
                            </Form.Control.Feedback>
                          </div>
                        </Box>
                        <Box className="form-box">
                          <div className="one-column">
                            <Typography className="applyInput" variant="body1">Profile Summary</Typography>
                            <Form.Control
                              type="textarea"
                              className="textarea"
                              value={formData?.summary}
                              name='summary'
                              onChange={(e) => handleChange(e)}
                            />
                          </div>
                        </Box>
                        <Box className="form-box">
                          <div className="one-column">
                            <Typography className="applyInput" variant="body1">{formData?.resume ? 'Uploaded' : 'Upload Resume'}</Typography>
                            <Box className="applyFileInputBox">
                              <input
                                onChange={handleUploadResume}
                                ref={fileInputRef}
                                type="file"
                                accept=".xlsx,.xls,image/*,.doc, .docx,.ppt, .pptx,.txt,.pdf"
                                className="hidden"
                                name='resume'
                              // disabled = {formData?.resume ? true : false}
                              />
                              <svg onClick={() => fileInputRef.current.click()} className='pointer' xmlns="http://www.w3.org/2000/svg" width="26" height="26" viewBox="0 0 26 26" fill="none">
                                <g clip-path="url(#clip0_2182_5478)">
                                  <path d="M20.9625 10.8766C20.2258 7.13909 16.9433 4.33325 13 4.33325C9.86917 4.33325 7.15 6.10992 5.79583 8.70992C2.535 9.05658 0 11.8191 0 15.1666C0 18.7524 2.91417 21.6666 6.5 21.6666H20.5833C23.5733 21.6666 26 19.2399 26 16.2499C26 13.3899 23.7792 11.0716 20.9625 10.8766ZM20.5833 19.4999H6.5C4.10583 19.4999 2.16667 17.5608 2.16667 15.1666C2.16667 12.9458 3.82417 11.0933 6.02333 10.8658L7.1825 10.7466L7.72417 9.71742C8.75333 7.73492 10.7683 6.49992 13 6.49992C15.8383 6.49992 18.2867 8.51492 18.8392 11.2991L19.1642 12.9241L20.8217 13.0433C22.5117 13.1516 23.8333 14.5708 23.8333 16.2499C23.8333 18.0374 22.3708 19.4999 20.5833 19.4999ZM8.66667 14.0833H11.4292V17.3333H14.5708V14.0833H17.3333L13 9.74992L8.66667 14.0833Z" fill="#212121" />
                                </g>
                                <defs>
                                  <clipPath id="clip0_2182_5478">
                                    <rect width="26" height="26" fill="white" />
                                  </clipPath>
                                </defs>
                              </svg>
                              <Typography onClick={() => fileInputRef.current.click()} className="dragText pointer">Drag & Drop</Typography>
                              <Typography onClick={() => fileInputRef.current.click()} my={1} className="bottomRedTex pointert">Supported Formats: doc, docx, rtf, PDF upto 2MB</Typography>
                            </Box>
                          </div>
                        </Box>
                        <Box className="form-box">
                          <div style={{ display: "flex", justifyContent: "end" }} className="one-column">
                            <button
                              className="btn-apply"
                              variant="contained"
                              size="large"
                              type='submit'
                              disabled={loading}
                            >
                              Apply Now
                            </button>
                          </div>
                        </Box>
                      </Form>
                    )}
                  </Formik>
                </Box>
              </Box>
            </Modal>

            {/*  See More Modal */}
            {/* <Modal
              open={seeMore}
              onClose={() => setSeeMore(false)}
              aria-labelledby="parent-modal-title"
              aria-describedby="parent-modal-description"
            >
              <Box className="modalbox" sx={{ ...style, width: 730 }}>
                <Typography variant="h5">Job Description</Typography>
                <img onClick={() => {
                  setSeeMore(false)
                }} className="whiteCross" src={whiteCross} />
                <Box className="form-auto-scroll" sx={{ height: 550, overflowY: 'scroll' }}>

                </Box>
              </Box>
            </Modal> */}
            {seeMoreModal()}
            {showConfirmationModel && (
              <ConfirmationModel
                show={showConfirmationModel}
                handleClose={() => setShowConfirmationModel(false)}
                handleOk={() => setShowConfirmationModel(false)}
                title={"Careers"}
                body={confirmationBody}
              />
            )}

              {showJobApplyModal && (
                  <JobApplyModal
                    show={showJobApplyModal}
                    handleClose={() => setShowJobApplyModal(false)}
                    handleOk={() => setShowJobApplyModal(false)}
                    title= "Apply Now"
                    job = {selectedJob}
                    pos = {formData?.position}
                  />
                )}

          </Box>
        </Box >
      </Box >
      {loader && <PageLoader />
      }
      <Footer />
    </>
  );
}

