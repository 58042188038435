import { Button, Modal } from "react-bootstrap";
import OTPInput from "otp-input-react";
import { useEffect, useState } from "react";
import { PageLoader } from "../../components/PageLoader";
//For Bcrypt
import bcrypt from "bcryptjs-react"

const { Header, Footer, Title, Body } = Modal;
export function VerifyEmail({
  handleClose,
  onVerify,
  setOTP,
  show,
  otp,
  resendEmail,
  completeSignUp,
}) {
  const [timer, setTimer] = useState(60);
  const [loader, setLoader] = useState(false);
  const [enteredOtp, setEnteredOtp] = useState("");

  useEffect(() => {
    const counter =
      timer > 0 &&
      setInterval(
        () =>
          setTimer((prevState) => {
            return prevState - 1;
          }),
        1000
      );
    return () => clearInterval(counter);
  }, [timer]);

  const onVerify1 = async () => {
    const isOk = await bcrypt.compare(enteredOtp, otp);
    if (!isOk) {
      alert("Invalid OTP please try again!!!");
      setLoader(false)
    }
    else {
      // signupDetails.emailVerified = true;
      setLoader(false)
      completeSignUp();
    }
  };

  const toSetOtp = (otp) => {
    setEnteredOtp(otp)
  }

  return (
    <Modal show={show} 
    onHide={ () => {
      handleClose()
      setLoader(false)
    }} 
    backdrop="static">
      <Header closeButton>
        <Title>Verify your Mobile Number</Title>
      </Header>
      <Body className="verify-email-modal-body">
        <p className="otp-header">Enter the 4 digit code sent to Your Mobile Number</p>
        <OTPInput
          value={enteredOtp}
          // onChange={setOTP}
          onChange = { toSetOtp }
          autoFocus
          OTPLength={4}
          otpType="number"
          disabled={false}
          secure
          className="otp-input"
        />
        {timer > 0 && <p className="timer">{timer} sec.</p>}
        <div className="resend-email">
          <p>Didn't get a message?</p>
          <Button
            variant="link"
            className="resend-button"
            disabled={timer > 0}
            onClick={() => {
              setTimer(60)
              resendEmail()
            } }
          >
            Resend Code
          </Button>
        </div>
        {loader && <PageLoader/>}
      </Body>
      <Footer>
        <Button variant="secondary" onClick={() => {
          handleClose()
          setLoader(false)
        }} 
        >
          Cancel
        </Button>
        <Button variant="primary" onClick={ () => {
          setLoader(true)
          onVerify1()
        }}>
          Verify
        </Button>
      </Footer>
    </Modal>
  );
}
