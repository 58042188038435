import { useEffect, useState } from "react"
import { useNavigate } from 'react-router-dom';
import Select from 'react-select';
import { getCities,getAllTags, getSearchListings, getLocationTypesByEvent } from "../../apis/api";
import { PageLoader } from "../../components/PageLoader";
import PropertyInfo from "../../components/NewPropertyInfo";
import { Header } from "../../components/NewHeader/Header";
import { Footer } from "../../components/Footer";
import Pagination from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';
import NoResults from '../../assets/NewImages/SearchPage/no_results_found.jpg';
import Tooltip, {tooltipClasses} from "@mui/material/Tooltip";
import { styled } from '@mui/material/styles';
import Constants from "../../utils/Helmet";
// import "../NewSearchPage/SearchPage.css" 

const options = [
    { value: 'film-shoot', label: 'Film Shootings' },
    { value: 'tv-shoot', label: 'Tv Shootings' },
    { value: 'event', label: 'Events' },
    { value: 'staycation', label: 'Staycations' },
  ]

  //Custom Tooltip styles
const CustomTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} arrow classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.palette.common.black,
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.black,
    padding: '10px',
    fontSize : "13px",
  },
}));


const Activity = () => {
    const navigate = useNavigate()
    const url = new URL(window.location.href);
    const pathname = url.pathname;
    // const segments = pathname?.split('/');
    // let paramsActivity =''
    // if(segments?.[3]) {
    //     paramsActivity    = {
    //         label : segments?.[3]?.replaceAll('-', ' ').replace(/\b\w/g, char => char.toUpperCase()),
    //         value : segments?.[3]?.replaceAll('-', ' ').replace(/\b\w/g, char => char.toUpperCase()),
    //     }
    // }
    // let locationtype = ''
    // switch (segments?.[2]) {
    //   case "film-shoot":
    //     locationtype =  {value: 'film-shoot', label: 'Film Shootings'}
    //     break;
    //   case "tv-shoot":
    //     locationtype =  {value: 'tv-shoot', label: 'Tv Shootings'}
    //     break;
    //   case "event":
    //     locationtype =  {value: 'event', label: 'Events'}
    //     break;
    //   case "staycation":
    //     locationtype =  { value: 'staycation', label: 'Staycations'}
    //     break;
    //   default:
    //     locationtype = ""
    //     break;
    // }
    // let paramsCity =''
    // if(segments?.[4]){
    //     paramsCity = {
    //         label : segments?.[4]?.replaceAll('-', ' ').replace(/\b\w/g, char => char.toUpperCase()),
    //         value : segments?.[4]?.replaceAll('-', ' ').replace(/\b\w/g, char => char.toUpperCase()),
    //     }
    // }
    const [page, setPage] = useState(1);
    const [open, setOpen] = useState(false); 
    const [openActivity, setOpenActivity] = useState(false); 
    const [openCity, setOpenCity] = useState(false); 
    const [cities, setCities] = useState([]);
    const [activites, setActivities] = useState([]);
    const [locations, setLocations] = useState([]);
    const [locationsDataFetched, setLocationsDataFetched] = useState(false);
    const [noOfLocations, setNoOfLocations] = useState(12);
    const [locationsCount, setLocationsCount] = useState(-1);
    const [loader, setLoader] = useState(false);
    const [listings, setListings] = useState([]);
    const [totalCount, setTotalCount] = useState(0);
    const [inputValues, setInputValues] = useState({
      event : "",
      location : '',
      city : "",
      activity : "",
    });
    const [formData, setFormData] = useState({
        searchInput: '',
        location: "",
        city: '',
        // city: segments?.[4] ? segments?.[4]?.replaceAll('-', ' ').replace(/\b\w/g, char => char.toUpperCase()) : '',
        event: '',
        // event: segments?.[2] || '',
        activity: '',
        // activity: segments?.[3] ? segments?.[3]?.replaceAll('-', ' ').replace(/\b\w/g, char => char.toUpperCase()) : '',
        area: '',
        amenities: [],
        policies: [],
        attendies: '',
    });
    const customStyles = {
        option: (provided, state) => ({
          ...provided,
          backgroundColor: state.isSelected || state.isFocused ? '#ea4335' : 'white', // Customize the background color here
          color: state.isSelected ||  state.isFocused ? 'white' : 'black', // Customize the text color here
        }),
      };
    const getRequiredData = async(id) => {
      try{
        const response = await getLocationTypesByEvent(id)
        setLocations(response?.data);
        const tempObj = {}
        switch (id) {
          case "film-shoot":
            tempObj.film = true
            break;
          case "tv-shoot":
            tempObj.tv = true
            break;
          case "event":
            tempObj.event = true
            break;
          case "staycation":
            tempObj.stay = true
            break;
          default:
            locationtype = ""
            break;
        }
        const activityRes = await getAllTags(tempObj);
        const tempActivityArray = []
        activityRes?.data?.allTags?.map((item) => {
          tempActivityArray.push({ value: item?.name, label: item?.name },)
        })
        const activityData = [...tempActivityArray].sort((a, b) =>
            a?.label?.toLowerCase() > b?.label?.toLowerCase() ? 1 : -1,
          );
        setActivities(activityData);
        const citiesRes = await getCities(id);
      //   const citiesData = [...citiesRes?.data?.cities].sort((a, b) =>
      //     a?.label?.toLowerCase() > b?.label?.toLowerCase() ? 1 : -1,
      // );
        setCities(citiesRes?.data?.cities);
      }catch (error) {
        console.log(error);
      }
    }
    const searchPlaces = async (data, page) => {
      setLoader(true)
      try {
        const response = await getSearchListings(data, page, noOfLocations);
        setListings(response.data.locations);
        setLocationsCount(response.data.count)
        setTotalCount(Math.floor(response?.data?.count/12) <= 0 ? 1 : Math.floor(response?.data?.count/12) );
        setLoader(false);
        setLocationsDataFetched(true);
      } catch (error) {
        console.log(error)
      }
      setLoader(false)
    }
    const handleUrlChange = (data) => {
      let URL = '/locations'

      if(data.event){
        URL = URL + `/${data.event}`
      }
      if(data.city){
        URL = URL + `/${data.city.replaceAll(' ','-').toLowerCase()}`
      }else{
        URL = URL + '/'
      }
      if(data.activity){
        URL = URL + `/${data.activity.replaceAll(' ','-').toLowerCase()}`
      }
      navigate(URL)
    }
    // useEffect(() => { 
    //   window.scrollTo(0, 0);
    //   if(locationtype) {
    //     getRequiredData(locationtype?.value)
    //   }
    //   searchPlaces(formData,page)
    // },[])

    useEffect(() => {
      const segments = pathname?.split('/');
      let paramsActivity =''
      if(segments?.[4]) {
          paramsActivity    = {
              label : segments?.[4]?.replaceAll('-', ' ').replace(/\b\w/g, char => char.toUpperCase()),
              value : segments?.[4]?.replaceAll('-', ' ').replace(/\b\w/g, char => char.toUpperCase()),
          }
      }
      let locationtype = ''
      switch (segments?.[2]) {
        case "film-shoot":
          locationtype =  {value: 'film-shoot', label: 'Film Shootings'}
          break;
        case "tv-shoot":
          locationtype =  {value: 'tv-shoot', label: 'Tv Shootings'}
          break;
        case "event":
          locationtype =  {value: 'event', label: 'Events'}
          break;
        case "staycation":
          locationtype =  { value: 'staycation', label: 'Staycations'}
          break;
        default:
          locationtype = ""
          break;
      }
      let paramsCity =''
      if(segments?.[3]){
          paramsCity = {
              label : segments?.[3]?.replaceAll('-', ' ').replace(/\b\w/g, char => char.toUpperCase()),
              value : segments?.[3]?.replaceAll('-', ' ').replace(/\b\w/g, char => char.toUpperCase()),
          }
      }
      setInputValues({
        ...inputValues,
        event : locationtype,
        activity : paramsActivity,
        city : paramsCity,
      })
      window.scrollTo(0, 0);
      if(locationtype) {
        getRequiredData(locationtype?.value)
      }
      setFormData({
        ...formData, 
        event: segments?.[2] || '',
        activity: segments?.[4] ? segments?.[4]?.replaceAll('-', ' ').replace(/\b\w/g, char => char.toUpperCase()) : '',
        city: segments?.[3] ? segments?.[3]?.replaceAll('-', ' ').replace(/\b\w/g, char => char.toUpperCase()) : '',
      })
      searchPlaces({...formData, activity : paramsActivity?.value || "" , city : paramsCity?.value || ""  , event : locationtype?.value || "" },1)
    }, [pathname] );
  

    const searchContainer = () => {
        return (
            <div className="searchPageSearchCard" >
                <div className="row">
                    <div className="col-lg-4 searchPagePaddingCard">
                        <label className="mx-1"> Seeking locations for </label>
                        <Select
                            className="w-100 searchPageReqInput"
                            id='searchEventSelect'
                            styles={customStyles}
                            options = {options}
                            value={inputValues?.event}
                            placeholder = "Select here"
                            
                            onChange = {(e) => {
                                setFormData({ ...formData, event: e?.value })
                                setInputValues({...inputValues, event:e,location : '',activity : '',city : ''})
                                getRequiredData(e?.value)
                                searchPlaces({...formData,event : e?.value,location :'',activity : '',city : ''}, 1)
                                handleUrlChange({...formData,event : e?.value,location :'',activity : '',city : ''}, 1)
                            }}
                        />
                    </div>
                    {/* <CustomTooltip open= {open}  title={formData?.event ? "" : "Choose location before selecting location type"} >
                      <div 
                        className="col-lg-3 searchPagePaddingCard" 
                        style={{minWidth:"186px"}}
                        onTouchStart={() => setOpen(true)} 
                        onTouchEnd={() => setOpen(false)}
                        onMouseEnter={() => setOpen(true)}
                        onMouseLeave={() => setOpen(false)}
                      >
                          <label className="mx-1"> Property type</label>
                          <Select
                              className="w-100 searchPageReqInput"
                              id='searchlocationType'
                              styles={customStyles}
                              options = {locations}
                              isDisabled = {formData?.event ? false : true }
                              isClearable={true}
                              value={inputValues?.location}
                              placeholder = "Choose property type..."
                              onChange = {(e) =>  {
                                setFormData({ ...formData, location: e?.value });
                                setInputValues({...inputValues, location:e})
                                searchPlaces({...formData,location : e?.value}, 1)
                              }}
                          />
                      </div>
                    </CustomTooltip> */}
                    <CustomTooltip open= {openActivity}  title={formData?.event ? "" : "Choose location before selecting activities"} >
                      <div 
                        className="col-lg-4 searchPagePaddingCard" 
                        style={{minWidth:"186px"}}
                        onTouchStart={() => setOpenActivity(true)} 
                        onTouchEnd={() => setOpenActivity(false)}
                        onMouseEnter={() => setOpenActivity(true)}
                        onMouseLeave={() => setOpenActivity(false)}
                      >
                        <label className="mx-1"> Activity</label>
                        <Select
                            className="w-100 searchPageReqInput"
                            id='searchActivity'
                            styles={customStyles}
                            options = {activites}
                            isDisabled = {formData?.event ? false : true }
                            isClearable={true}
                            value={inputValues?.activity}
                            placeholder = "Choose activity..."
                            onChange = {(e) =>  {
                              setFormData({ ...formData, activity: e?.value},)
                              setInputValues({...inputValues, activity:e})
                              searchPlaces({...formData,activity : e?.value}, 1)
                              handleUrlChange({...formData,activity : e?.value}, 1)
                            }}
                        />
                    </div>
                    </CustomTooltip>
                    <CustomTooltip open= {openCity}  title={formData?.event ? "" : "Choose location before selecting city"} >
                    <div
                      className="col-lg-4 searchPagePaddingCard"
                      onTouchStart={() => setOpenCity(true)} 
                      onTouchEnd={() => setOpenCity(false)}
                      onMouseEnter={() => setOpenCity(true)}
                      onMouseLeave={() => setOpenCity(false)}
                    >
                        <label className="mx-1"> City </label>
                        <Select
                            className="w-100 searchPageReqInput"
                            id='searchCity'
                            styles={customStyles}
                            options = {cities}
                            value={inputValues?.city}
                            isDisabled = {formData?.event ? false : true }
                            isClearable={true}
                            placeholder = "Choose city..."
                            onChange = {(e) =>  {
                              setFormData({ ...formData, city: e?.value},)
                              setInputValues({...inputValues, city:e})
                              searchPlaces({...formData,city : e?.value}, 1)
                              handleUrlChange({...formData,city : e?.value}, 1)

                            }}
                        />
                  </div>
                  </CustomTooltip>
                </div>
            </div>
        )
    }

     // row className="col-md-6 col-lg-4 col-xl-3"
    const listingsContainer = () => {
      return(
        <div className="searchListingsContainer " >
          {
            listings?.map((eachListing) =>  {
              return (
                <div key = {eachListing._id} > 
                  <PropertyInfo key={eachListing._id} property={eachListing} eventType = {formData?.event} />
                </div>
              )
            } )
          }
        </div>
      )
    }

    // On pagination change
    const onPageChange = (event, page) => {
      window.scrollTo(0, 0);
      searchPlaces(formData, page <= 0 ? 1 : page);
      setPage(page <= 0 ? 1 : page);
    }

    const  PaginationRounded = () => {
      return (
        <Stack className="mt-2 mb-3" spacing={2}>
          <Pagination onChange={(event, page) => onPageChange(event, page)} count={totalCount} variant="outlined" shape="rounded" />
        </Stack>
      );
    }

    return(
        <>
            < Header/>
            < Constants/>
            <div className="searchPageBgContainer" >
                {searchContainer()}
                { listings.length > 0 &&
                  <div className="searchPageListingsMapContainer"> 
                    <div className={`searchListingsAndPaginationContainer searchListingsAndPaginationContainer`} >
                      {listingsContainer()}
                      <div style={{alignSelf : "center"}}>
                        {totalCount > 0 && PaginationRounded()}
                      </div>
                    </div>
                  </div>
                }
                { (locationsDataFetched &&  listings?.length === 0) && 
                  <div className="searchPageListingsMapContainer d-flex flex-column justify-content-center align-items-center"> 
                    <h1 className="SpotLetMainHead text-center" > No Locations Found </h1>
                    <span className="SpotLetContent">It seems we couldn't find any locations matching your search criteria.</span>
                    <span className="SpotLetContent">Don't worry, you can try adjusting your search terms and explore our locations.</span>
                    <img alt="no results found" src={NoResults} style={{width : '100%',height : 'auto',maxHeight : '400px', maxWidth : '400px'}}/>
                  </div>
                }
            </div>
            < Footer/>
              {loader && <PageLoader/>}
        </>

    )
}

export default Activity
