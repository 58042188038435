import Slider from "react-slick";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Rating from "@mui/material/Rating";

// Images
import blueTik from "../../../assets/NewImages/Home/hexagon-check.svg";
import next from '../../../assets/NewImages/Home/next.webp';
import prev from '../../../assets/NewImages/Home/prev.webp';

import "./style.css";

const TESTIMONIALS = [
  {
    rating: "4",
    content:
      "We’re a renowned 5-star hotel in the heart of the city, often booked by families and outstation guests. However, we wanted to expand our customer base and become a preferred location for corporate events. SpotLet allowed us to reach out to our potential customers, and we’re doing great business with corporates now!",
    name: "JHON DOE",
    giveBy: "Host",
  },
  {
    rating: "4",
    content:
      "We’re a renowned 5-star hotel in the heart of the city, often booked by families and outstation guests. However, we wanted to expand our customer base and become a preferred location for corporate events. SpotLet allowed us to reach out to our potential customers, and we’re doing great business with corporates now!",
    name: "JHON DOE",
    giveBy: "Host",
  },
  {
    rating: "4",
    content:
      "We’re a renowned 5-star hotel in the heart of the city, often booked by families and outstation guests. However, we wanted to expand our customer base and become a preferred location for corporate events. SpotLet allowed us to reach out to our potential customers, and we’re doing great business with corporates now!",
    name: "JHON DOE",
    giveBy: "Host",
  },
  {
    rating: "4",
    content:
      "We’re a renowned 5-star hotel in the heart of the city, often booked by families and outstation guests. However, we wanted to expand our customer base and become a preferred location for corporate events. SpotLet allowed us to reach out to our potential customers, and we’re doing great business with corporates now!",
    name: "JHON DOE",
    giveBy: "Host",
  },
];

function SampleNextArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{ ...style, display: "block", height: '50px', width: '100px' }}
    ><img loading="lazy" src={next} className="arrowImg" onClick={onClick} alt='Previous'/></div>
  );
}

function SamplePrevArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{ ...style, display: "block", height: '50px', width: '100px', zIndex: '1', marginLeft: '-10px' }}
    ><img loading="lazy" src={prev} className="arrowImg" onClick={onClick} alt="Next"/></div>
  );
}



const HostClientTestimonials = () => {

  // const [clientTestimonials, setClientTestimonials] = useState([])

  var settings = {
    dots: false,
    infinite: true,
    speed: 300,
    swipeToSlide: false,
    fade: false,
    swipe: false,
    // slidesToShow: TESTIMONIALS?.length >= 2 ? 2 : TESTIMONIALS?.length,
    slidesToShow: 2,
    slidesToScroll: 1,
    initialSlide: 0,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    responsive: [
      {
        breakpoint: 1025,
        settings: {
          // slidesToShow: TESTIMONIALS?.length >= 2 ? 2 : TESTIMONIALS?.length,
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
        }
      },
      {
        breakpoint: 768,
        settings: {
          // slidesToShow: TESTIMONIALS?.length >= 2 ? 2 : TESTIMONIALS?.length,
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          initialSlide: 0
        }
      },
      {
        breakpoint: 510,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
        }
      }
    ]
  };


  // const getTestimonialsData = async () => {
  //   try {
  //     const response = await getTestimonials();
  //     setClientTestimonials(response?.TESTIMONIALS.testimonialsData)
  //   } catch (e) {
  //     console.log(e)
  //   }
  // }

  // useEffect(() => {
  //   getTestimonialsData()
  // }, [])

  return (
      <Slider {...settings}>
        {TESTIMONIALS?.map((file, i) => {
            return (
              <div className="item  testimonials-item" key={i}>
                <Box className="testimonials-Card"   >
                  <Box>
                    <Rating name="size-small" defaultValue={file?.rating} readOnly />
                    <Typography className="testimonialsCardSubText SpotLetContent">{file?.content}</Typography>
                  </Box>
                  <Box className="userInfoBox">
                    <div className="userProfilePic">
                      <p style={{ marginTop: "13px" }} className="SpotLetMainHead" >{file?.name[0]}</p>
                    </div>
                    <Box sx={{ position: "relative" }} ml={2} >
                      <img loading="lazy" className="blueTik" src={blueTik} />
                      <Typography className="User_name SpotLetMainHead">{file?.name}</Typography>
                      <Typography className="userRole SpotLetSubHead">{file?.giveBy}</Typography>
                    </Box>
                  </Box>
                </Box>
              </div>
            )
          })}
      </Slider>
  )
}

export default HostClientTestimonials;
