import { useRef } from "react";
import "./Authentication.css"
import { Link } from "react-router-dom";
import { GoogleLogin } from "react-google-login";
import { HiMiniDevicePhoneMobile } from "react-icons/hi2";
import { CiMail } from "react-icons/ci";
import OTPInput from "otp-input-react";
import { Alert } from "react-bootstrap";
import { useState } from "react";
import { useAuthStore } from "../../store/auth"
import { IoMdArrowBack } from "react-icons/io";
import { FaRegEdit } from "react-icons/fa";
import { ConfirmationModel } from "../../components/Modal/ConfirmationModel";
import {
    signIn,
    signUp,
    getOtp,
    forgotPassword,
    sendActivationLink,
} from "../../apis/api"
import bcrypt from "bcryptjs-react"
import { PageLoader } from "../../components/PageLoader";

const Authentication = ({activeTab,onSuccess,onFailure,activePage}) => {

    const getTab = () => {
        if (activePage === "property") {
            return "registerMobile"
        } 
        else if (activeTab) {
            return activeTab
        } 
        else {
        return "register"
        }
    }
    const { setAuthenticated, setUserProfile } = useAuthStore();
    const [loader, setLoader] = useState(false);
    // const [tab,setTab] = useState(activeTab || 'register')
    const [tab,setTab] = useState(getTab())
    const [showConfirmationModal, setShowConfirmationModal] = useState(false)
    const [errorMsg, setErrorMsg] = useState(false)
    const [googleClientId, setGoogleClientId] = useState(
        "837678588128-701v5ikaevb6ahi5bv0ps700q7lqit9d.apps.googleusercontent.com"
    );
    const changeTab =(tab)=> {
        setTab(tab)
    }

    //register with google start
    const onSuccessRegister = async (res) => {
        setLoader(true)
        let userObj = {
            fullName: res?.profileObj?.name,
            mobile: "",
            email: res?.profileObj?.email,
            password: "",
            profile_pic: res?.profileObj?.imageUrl || '',
            googleLogin: true,
            emailVerified: true,
            mobileVerified: false,
          };
          try {
            const res = await signUp(userObj);
            const jwt = res?.data?.token;
            localStorage.setItem("auth", jwt);
            setAuthenticated();
            setUserProfile();
            onSuccess();
          } catch (error) {
            console.log(error)
            setErrorMsg(error?.response?.data?.error)
            setShowConfirmationModal(true)
            // alert(error?.response?.data?.error);
          }
          setLoader(false)
    }
    const onFailureRegister = (err) => {
        console.log("failed:", err);
    };
    //register with google end

    //signIn with google start
    const onSuccessSignIn = async (res) => {
        setLoader(true)
        const details = {
            email: res?.profileObj?.email,
            googleLogin: true,
        };
        try{
            const response = await signIn(details);
            localStorage.setItem("auth", response.data.token);
            setAuthenticated();
            setUserProfile();
            onSuccess();
        }catch(error){
            console.log(error)
            setErrorMsg(error?.response?.data?.error)
            setShowConfirmationModal(true)
            // alert(error?.response?.data?.error);
        }
        setLoader(false)
    }
    const onFailureSignIn = (err) => {
        console.log("failed:", err);
    };
    //signIn with google end

    //register with mobile start
    const [registerMobileFormData,setRegisterMobileFormData] = useState({})
    const [registerMobileHidePassword, setRegisterMobileHidePassword] = useState(false);
    const [registerMobileOtp, setRegisterMobileOtp] = useState('');
    const [registerMobileError, setRegisterMobileError] = useState('');
    const onChangeRegisterMobile = (e) => {
        const {name,value} = e.target
        if (name === "mobile") {
            setRegisterMobileError("")
        }
        setRegisterMobileFormData({
            ...registerMobileFormData,
            [name] : value,
        })
    }
    const registerMobileSubmit = async (e) => {
        e.preventDefault()
        setLoader(true)
        try{
            const resp = await getOtp({...registerMobileFormData,mobile : '+91'+ registerMobileFormData?.mobile})
            setRegisterMobileOtp(resp?.data?.otp)
            setTab('otpValidationMobile')
            setTimerMobile(60)
            runTimerMobile()
            setResendStatusMobile(false)
        }catch(error){
            console.log(error?.response?.data?.error)
            setRegisterMobileError(error?.response?.data?.error)
        }
        setLoader(false)
    }
    //register with mobile end

    //register with email start
    const [registerEmailFormData,setRegisterEmailFormData] = useState({})
    const [registerEmailHidePassword, setRegisterEmailHidePassword] = useState(false);
    const [registerEmailOtp, setRegisterEmailOtp] = useState('');
    const [registerEmailError, setRegisterEmailError] = useState('');
    const onChangeRegisterEmail = (e) => {
        const {name,value} = e.target
        if (name === "email") {
            setRegisterEmailError("")
        }
        setRegisterEmailFormData({
            ...registerEmailFormData,
            [name] : value,
        })
    }
    const registerEmailSubmit = async (e) => {
        e.preventDefault()
        setLoader(true)
        try{
            const resp = await getOtp(registerEmailFormData)
            setRegisterEmailOtp(resp?.data?.otp)
            setTab('otpValidationEmail')
            setTimerEmail(60)
            runTimerEmail()
            setResendStatusEmail(false)
        }catch(error){
            console.log(error?.response?.data?.error)
            setRegisterEmailError(error?.response?.data?.error)
        }
        setLoader(false)
    }
    //register with email end

    //signIn with email start
    const [signInEmailFormData,setSignInEmailFormData] = useState({})
    const [signInEmailHidePassword, setSignInEmailHidePassword] = useState(false);
    const [signInEmailError, setSignInEmailError] = useState('');
    const [signInInputError, setSignInInputError] = useState("");
    const [signInTypeError, setSignInTypeError] = useState('');
    const onChangeSignInEmail = (e) => {
        setSignInEmailError("");
        const {name,value} = e.target
        setSignInEmailFormData({
            ...signInEmailFormData,
            [name] : value,
        })
    }
    const signInEmailSubmit = async (e) => {
        e.preventDefault()
        setLoader(true)
        setSignInEmailError("")
        setSignInInputError("")
        setSignInTypeError("")
        try{
            const response = await signIn(signInEmailFormData);
            localStorage.setItem("auth", response.data?.token);
            setAuthenticated();
            setUserProfile();
            onSuccess();
        }catch(error){
            console.log(error)
            if (!(error?.response?.data?.errorType)) {
                setSignInEmailError(error?.response?.data?.error)
            } else {
                setSignInEmailError(error?.response?.data?.error)
                setSignInTypeError(error?.response?.data?.errorType)
                setSignInInputError(error?.response?.data?.errorFor)
            }
        }
        setLoader(false)
    }
    //register with email end

    //signIn with mobile start
    const [signInMobileFormData,setSignInMobileFormData] = useState({})
    const [signInMobileHidePassword, setSignInMobileHidePassword] = useState(false);
    const [signInMobileError, setSignInMobileError] = useState('');
    const onChangeSignInMobile = (e) => {
        setSignInEmailError("");
        const {name,value} = e.target
        setSignInMobileFormData({
            ...signInMobileFormData,
            [name] : value,
        })
    }
    const signInMobileSubmit = async (e) => {
        e.preventDefault()
        setLoader(true)
        setSignInInputError("")
        setSignInTypeError("")
        try{
            const response = await signIn({...signInMobileFormData,mobile : "+91" + signInMobileFormData?.mobile});
            localStorage.setItem("auth", response.data?.token);
            setAuthenticated();
            setUserProfile();
            onSuccess();
        }catch(error){
            console.log(error)
            if (!(error?.response?.data?.errorType)) {
                setSignInEmailError(error?.response?.data?.error)
            } else {
                setSignInEmailError(error?.response?.data?.error)
                setSignInTypeError(error?.response?.data?.errorType)
                setSignInInputError(error?.response?.data?.errorFor)
            }
        }
        setLoader(false)
    }
    //signIn with mobile end

    //otpValidationEmail start
    const [timerEmail, setTimerEmail] = useState(60);
    const [enteredOtpEmail, setEnteredOtpEmail] = useState("")
    const [resendStatusEmail,setResendStatusEmail] = useState(false)
    const [resendEmailError,setResendStatusEmailError] = useState(false)
    const intervalEmailRef = useRef(null);
    const toSetOtpEmail = (otp) => {
        setEnteredOtpEmail(otp)
    }
    const runTimerEmail = () => {
        if (intervalEmailRef.current) {
            clearInterval(intervalEmailRef.current);
        }
        // const counter =
        // timerEmail > 0 &&
        //     setInterval(
        //     () =>
        //         setTimerEmail((prevState) => {
        //         return prevState - 1;
        //         }),
        //     1000
        //     );
        // return () => {
        //     clearInterval(counter)
        //     setResendStatusEmail(false)
        // };
        intervalEmailRef.current = setInterval(() => {
            setTimerEmail((prevState) => {
                if (prevState <= 1) {
                    clearInterval(intervalEmailRef.current);
                    setResendStatusEmail(false);
                    intervalEmailRef.current = null;
                    return 0;
                }
                return prevState - 1;
            });
        }, 1000);

    }
    const onResendEmail = async () => {
        try{
            const resp = await getOtp(registerEmailFormData)
            setRegisterEmailOtp(resp?.data?.otp)
            setTimerEmail(60)
            runTimerEmail()
            setEnteredOtpEmail('')
            setResendStatusEmailError('')
            setResendStatusEmail(true)
        }catch(error){
            console.log(error)
        }
    }
    const verifyOtpEmail = async () => {
        setLoader(true)
        setResendStatusEmail(false)
        const isOk = await bcrypt.compare(enteredOtpEmail, registerEmailOtp);
        if(isOk){
              try {
                const userObj = {
                    fullName: registerEmailFormData?.fullName,
                    mobile: "",
                    email: registerEmailFormData?.email,
                    password: registerEmailFormData?.password,
                    profile_pic: '',
                    googleLogin: false,
                    emailVerified: true,
                    mobileVerified: false,
                }
                const res = await signUp(userObj);
                const jwt = res?.data?.token;
                localStorage.setItem("auth", jwt);
                setAuthenticated();
                setUserProfile();
                onSuccess();
              } catch (error) {
                console.log(error)
                setErrorMsg(error?.response?.data?.error)
                setShowConfirmationModal(true)
                // alert(error?.response?.data?.error);
              }
        }else{
            setResendStatusEmailError(true)
        }
    setLoader(false)
    }
    //otpValidationEmail end

    //otpValidationMobile start
    const [timerMobile, setTimerMobile] = useState(60);
    const [enteredOtpMobile, setEnteredOtpMobile] = useState("")
    const [resendStatusMobile,setResendStatusMobile] = useState(false)
    const [resendMobileError,setResendStatusMobileError] = useState(false)
    const intervalMobileRef = useRef(null);
    const toSetOtpMobile = (otp) => {
        setEnteredOtpMobile(otp)
    }
    const runTimerMobile = () => {
        if (intervalMobileRef.current) {
            clearInterval(intervalMobileRef.current);
        }
        // const counter =
        // timerMobile > 0 &&
        //     setInterval(
        //     () =>
        //         setTimerMobile((prevState) => {
        //         return prevState - 1;
        //         }),
        //     1000
        //     );
        // return () => {
        //     clearInterval(counter)
        //     setResendStatusMobile(false)
        // };
        intervalMobileRef.current = setInterval(() => {
            setTimerMobile((prevState) => {
                if (prevState <= 1) {
                    clearInterval(intervalMobileRef.current);
                    setResendStatusMobile(false);
                    intervalMobileRef.current = null;
                    return 0;
                }
                return prevState - 1;
            });
        }, 1000);
    }
    const onResendMobile = async () => {
        try{
            const resp = await getOtp({...registerMobileFormData,mobile : '+91'+ registerMobileFormData?.mobile})
            setRegisterMobileOtp(resp?.data?.otp)
            setTimerMobile(60)
            runTimerMobile()
            setEnteredOtpMobile('')
            setResendStatusMobileError('')
            setResendStatusMobile(true)
        }catch(error){
            console.log(error)
        }
    }
    const verifyOtpMobile = async () => {
        setLoader(true)
        setResendStatusMobile(false)
        const isOk = await bcrypt.compare(enteredOtpMobile, registerMobileOtp);
        if(isOk){
              try {
                const userObj = {
                    fullName: registerMobileFormData?.fullName,
                    mobile: "+91" + registerMobileFormData?.mobile,
                    email: '',
                    password: registerMobileFormData?.password,
                    profile_pic: '',
                    googleLogin: false,
                    emailVerified: false,
                    mobileVerified: true,
                }
                const res = await signUp(userObj);
                const jwt = res?.data?.token;
                localStorage.setItem("auth", jwt);
                setAuthenticated();
                setUserProfile();
                onSuccess();
              } catch (error) {
                console.log(error)
                setErrorMsg(error?.response?.data?.error)
                setShowConfirmationModal(true)
                // alert(error?.response?.data?.error);
              }
        }else{
            setResendStatusMobileError(true)
        }
        setLoader(false)
    }
    //otpValidationMobile end

    //forgotMobile start
    const [forgotMobileNumber,setForgotMobileNumber] = useState('')
    const [forgotMobileSuccess,setForgotMobileSuccess] = useState(false)
    const [forgotMobileError,setForgotMobileError] = useState(false)
    const forgotMobileSubmit = async (e) => {
        e.preventDefault()
        setLoader(true)
        try{
            const resp = await forgotPassword({mobile : "+91" + forgotMobileNumber})
            setForgotMobileSuccess(true)
        }catch(error){
            console.log(error)
            setForgotMobileError(error?.response?.data?.error)
        }
        setLoader(false)
    }
    //forgotMobile end

    //forgotEmail start
    const [forgotEmailNumber,setForgotEmailNumber] = useState('')
    const [forgotEmailSuccess,setForgotEmailSuccess] = useState(false)
    const [forgotEmailError,setForgotEmailError] = useState(false)
    const forgotEmailSubmit = async (e) => {
        e.preventDefault()
        setLoader(true)
        try{
            const resp = await forgotPassword({email : forgotEmailNumber})
            setForgotEmailSuccess(true)
        }catch(error){
            console.log(error)
            setForgotEmailError(error?.response?.data?.error)
        }
        setLoader(false)
    }
    //forgotEmail end


    //components start
    const register = () => {
        return(
            <div className="spotletSignUpMainContainer w-100 d-flex flex-column justify-content-center align-items-center">
                <h1 className="SpotLetMainHead mb-4">Register</h1>
                <span className="text-gray-500 text-center fw-semibold fs-6 mb-4 SpotLetContent">Already have an Account?{' '}
                <span className="auth_text_color fw-semibold SpotLetContent cursor" onClick={()=>changeTab('signIn')}>Log In</span>
                </span>
                <div className="container mb-4">
                    <div className="row">
                        <div className="col-12 mb-4">
                            <GoogleLogin
                                clientId={googleClientId}
                                buttonText="Continue with Google"
                                onSuccess={onSuccessRegister}
                                onFailure={onFailureRegister}
                                cookiePolicy={"single_host_origin"}
                                isSignedIn={false}
                                className="spotletGoogleButton SpotLetContent"
                                // disabled={region === 'India'}
                            />
                        </div>
                        <div className="col-12 mb-4">
                            <button  type="button"
                            className="spotletEmailButton SpotLetContent"
                            onClick={()=>changeTab('registerMobile')}
                            >
                                <HiMiniDevicePhoneMobile  style={{width : '18px',height : '18px',marginRight : '10px'}}/>
                                <span>Continue with Mobile</span>
                            </button>
                        </div>
                        <div className="col-12">
                            <button type="button"
                            // className={`spotletEmailButton SpotLetContent ${region === 'India' && 'spotletEmailDisabled'}`}
                            className='spotletEmailButton SpotLetContent'
                            // disabled={region === 'India'}
                            onClick={()=>changeTab('registerEmail')}
                            >
                                <CiMail style={{width : '18px',height : '18px',marginRight : '10px'}}/>
                                <span>Continue with Email</span>
                            </button>
                        </div>
                    </div>
                </div>
                <span className="text-gray-500 text-center fw-semibold fs-6 mb-4 SpotLetContent">By registering, You agree to SpotLet's{' '}
                <Link href="" className="auth_text_color fw-semibold SpotLetContent"
                to={'/terms-and-conditions'} target="_blank"
                >Terms & Conditions</Link>{' '}and{' '}
                <Link className="auth_text_color fw-semibold SpotLetContent"
                to={'/privacy-policy'} target="_blank"
                >Privacy Policy</Link>
                </span>
            </div>
        )
    }
    const registerEmail = () => {
        return(
            <div className="spotletSignUpMainContainer w-100 d-flex flex-column justify-content-center align-items-center">
                <h1 className="SpotLetMainHead mb-4">Register with Email</h1>
                <span className="auth_text_color fw-semibold SpotLetContent cursor align-self-start mb-4 d-flex flex-row justify-content-center align-items-center"
                onClick={()=>{
                    setTab('register');
                    setRegisterEmailFormData({})
                }}
                ><IoMdArrowBack /> {' '}Back</span>
                <form className="w-100  align-self-start" onSubmit={registerEmailSubmit}>
                <div className="d-flex flex-column mb-4">
                        <label htmlFor="registerFullName" className="form-label">Full Name<span className="required">*</span></label>
                        <input
                            className="spotletInputField"
                            type="text"
                            placeholder="Full Name"
                            name="fullName"
                            id="registerFullName"
                            required
                            value={registerEmailFormData?.fullName}
                            onChange={onChangeRegisterEmail}
                        />
                    </div>
                    <div className={`d-flex flex-column ${registerEmailError ? "mb-0" : "mb-4"}`}>
                        <label htmlFor="registerEmail" className="form-label">Email<span className="required">*</span></label>
                        <input
                            className="spotletInputField"
                            type="email"
                            id="registerEmail"
                            name="email"
                            value={registerEmailFormData?.email}
                            onChange={onChangeRegisterEmail}
                            placeholder="Email"
                            title="Please enter a valid email addressPlease enter an email address in the format username@domain.com"
                            pattern="^[^\s@]+@[^\s@]+\.[^\s@]{2,}$"
                            required
                        />
                        <span className="text-muted SpotLetContent">Enter email as username@domain.com</span>
                    </div>
                    {registerEmailError && <span className='required SpotLetContent mb-2'> {registerEmailError}</span>}
                    <div className={`d-flex flex-column mb-4 ${registerEmailError ? "mt-2" : "mt-0"}`}>
                        <label htmlFor="registerPassword" className="form-label">Password<span className="required">*</span></label>
                        <div className="w-100">
                            <input
                                className="spotletInputField w-100"
                                type={!registerEmailHidePassword ? "password" : "text"}
                                placeholder="Password"
                                name="password"
                                id="registerPassword"
                                required
                                title="Password must contain 1 capital letter, 1 small letter, 1 special character, 1 digit, 8-16 characters."
                                pattern="^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[\W_]).{8,16}$"
                                maxLength={16}
                                value={registerEmailFormData?.password}
                                onChange={onChangeRegisterEmail}
                            />
                            <i
                                onClick={() =>
                                    setRegisterEmailHidePassword((registerEmailHidePassword) => !registerEmailHidePassword)
                                }
                                className={
                                    registerEmailHidePassword
                                    ? "fa fa-eye pointer"
                                    : "fa fa-eye-slash pointer"
                                }
                                aria-hidden="true"
                                style={{marginLeft : '-40px'}}
                            ></i>
                        </div>
                       <span className="text-muted SpotLetContent mb-2">Password must contain 1 capital letter, 1 small letter, 1 special character, 1 digit, 8-16 characters.</span>
                    </div>
                    <button type="submit" className="btn btn-primary SpotLetSubHead SpotLetSubHead SpotLetSubHead w-100">Register</button>
                </form>
            </div>
        )
    }
    const registerMobile = () => {
        return(
            <div className="spotletSignUpMainContainer w-100 d-flex flex-column justify-content-center align-items-center">
                <h1 className="SpotLetMainHead mb-4">Register with Mobile</h1>
                <span className="auth_text_color fw-semibold SpotLetContent cursor align-self-start mb-4 d-flex flex-row justify-content-center align-items-center"
                onClick={()=>{
                    setTab('register');
                    setRegisterMobileFormData({});
                }}
                ><IoMdArrowBack /> {' '}Back</span>
                <form className="w-100  align-self-start" onSubmit={registerMobileSubmit}>
                <div className="d-flex flex-column mb-4">
                        <label htmlFor="registerFullName" className="form-label">Full Name<span className="required">*</span></label>
                        <input
                            className="spotletInputField"
                            type="text"
                            placeholder="Full Name"
                            name="fullName"
                            id="registerFullName"
                            required
                            value={registerMobileFormData?.fullName}
                            onChange={onChangeRegisterMobile}
                        />
                    </div>
                    <div className={`d-flex flex-column ${registerMobileError ? "mb-0" : "mb-4"}`}>
                        <label htmlFor="registerMobile" className="form-label">Mobile<span className="required">*</span></label>
                        <div className="w-100 d-flex flex-row justify-content-start align-items-center">
                            <div className="spotLetCountryCodeContainer text-muted w-20">+91</div>
                            <input
                                className="spotletInputField w-100"
                                type="text"
                                id="registerMobile"
                                name="mobile"
                                value={registerMobileFormData?.mobile}
                                onChange={onChangeRegisterMobile}
                                placeholder="Mobile"
                                title="Enter 10-digit indian mobile number"
                                pattern="^[6-9]\d{9}$"
                                required
                            />
                        </div>
                        <span className="text-muted SpotLetContent">Enter 10-digit indian mobile number.</span>
                    </div>
                    {registerMobileError && <span className='required SpotLetContent'> {registerMobileError}</span>}
                    <div className={`d-flex flex-column mb-4 ${registerMobileError ? "mt-2" : "mt-0"}`}>
                        <label htmlFor="registerPassword" className="form-label">Password<span className="required">*</span></label>
                        <div className="w-100">
                            <input
                                className="spotletInputField w-100"
                                type={!registerMobileHidePassword ? "password" : "text"}
                                placeholder="Password"
                                name="password"
                                id="registerPassword"
                                required
                                title="Password must contain 1 capital letter, 1 small letter, 1 special character, 1 digit, 8-16 characters."
                                pattern="^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[\W_]).{8,16}$"
                                maxLength={16}
                                value={registerMobileFormData?.password}
                                onChange={onChangeRegisterMobile}
                            />
                            <i
                                onClick={() =>
                                    setRegisterMobileHidePassword((registerMobileHidePassword) => !registerMobileHidePassword)
                                }
                                className={
                                    registerMobileHidePassword
                                    ? "fa fa-eye pointer"
                                    : "fa fa-eye-slash pointer"
                                }
                                aria-hidden="true"
                                style={{marginLeft : '-40px'}}
                            ></i>
                        </div>
                       <span className="text-muted SpotLetContent mb-2">Password must contain 1 capital letter, 1 small letter, 1 special character, 1 digit, 8-16 characters.</span>
                    </div>
                    <button type="submit" className="btn btn-primary SpotLetSubHead SpotLetSubHead SpotLetSubHead w-100">Register</button>
                </form>
            </div>
        )
    }
    const logIn = () => {
        return(
            <div className="spotletSignUpMainContainer w-100 d-flex flex-column justify-content-center align-items-center">
                <h1 className="SpotLetMainHead mb-4">Log In</h1>
                <span className="text-gray-500 text-center fw-semibold fs-6 mb-4 SpotLetContent">Don't have an account?{' '}
                <span className="auth_text_color fw-semibold SpotLetContent cursor" onClick={()=>changeTab('register')}>Register</span>
                </span>
                <div className="container mb-4">
                    <div className="row">
                        <div className="col-12 mb-4">
                            <GoogleLogin
                                clientId={googleClientId}
                                buttonText="Continue with Google"
                                onSuccess={onSuccessSignIn}
                                onFailure={onFailureSignIn}
                                cookiePolicy={"single_host_origin"}
                                isSignedIn={false}
                                className="spotletGoogleButton SpotLetContent"
                                // disabled={region === 'India'}
                            />
                        </div>
                        <div className="col-12 mb-4">
                            <button  type="button"
                            className="spotletEmailButton SpotLetContent"
                            onClick={()=>changeTab('signInMobile')}
                            >
                                <HiMiniDevicePhoneMobile  style={{width : '18px',height : '18px',marginRight : '10px'}}/>
                                <span>Continue with Mobile</span>
                            </button>
                        </div>
                        <div className="col-12">
                            <button type="button"
                            // className={`spotletEmailButton SpotLetContent ${region === 'India' && 'spotletEmailDisabled'}`}
                            className='spotletEmailButton SpotLetContent'
                            // disabled={region === 'India'}
                            onClick={()=>changeTab('signInEmail')}
                            >
                                <CiMail style={{width : '18px',height : '18px',marginRight : '10px'}}/>
                                <span>Continue with Email</span>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
    const signInEmail = () => {
        return(
            <div className="spotletSignUpMainContainer w-100 d-flex flex-column justify-content-center align-items-center">
                <h1 className="SpotLetMainHead mb-4">Log In with Email</h1>
                <span className="auth_text_color fw-semibold SpotLetContent cursor align-self-start mb-4 d-flex flex-row justify-content-center align-items-center"
                onClick={()=>{
                    setTab('signIn');
                    setSignInEmailFormData({});
                    setSignInTypeError("")
                    setSignInInputError("")
                    setSignInEmailError("")
                }}
                ><IoMdArrowBack /> {' '}Back</span>
                <form className="w-100  align-self-start" onSubmit={signInEmailSubmit}>
                    <div className={`d-flex flex-column ${signInInputError === "user" ? "mb-2" : "mb-4"}`}>
                        <label htmlFor="signInEmail" className="form-label">Email<span className="required">*</span></label>
                        <input
                            className="spotletInputField"
                            type="email"
                            id="signInEmail"
                            name="email"
                            value={signInEmailFormData?.email}
                            onChange={onChangeSignInEmail}
                            placeholder="Email"
                            title="Please enter a valid email addressPlease enter an email address in the format username@domain.com"
                            pattern="^[^\s@]+@[^\s@]+\.[^\s@]{2,}$"
                            required
                        />
                    </div>
                    { ( (signInTypeError === "email") && (signInInputError === "user") ) &&  <span className="required SpotLetContent">{signInEmailError}</span> }
                    <div className={`d-flex flex-column ${( (signInTypeError === "email") && (signInInputError === "user") ) ? "mt-2" : "mt-0"}  ${ ( (signInTypeError === "email") && (signInInputError === "password") ) ? "mb-2" : "mb-4"}`}>
                        <label htmlFor="signInPassword" className="form-label">Password<span className="required">*</span></label>
                        <div className="w-100">
                            <input
                                className="spotletInputField w-100"
                                type={!signInEmailHidePassword ? "password" : "text"}
                                placeholder="Password"
                                name="password"
                                id="signInPassword"
                                required
                                title="Password must contain 1 capital letter, 1 small letter, 1 special character, 1 digit, 8-16 characters."
                                pattern="^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[\W_]).{8,16}$"
                                maxLength={16}
                                value={signInEmailFormData?.password}
                                onChange={onChangeSignInEmail}
                            />
                            <i
                                onClick={() =>
                                    setSignInEmailHidePassword((signInEmailHidePassword) => !signInEmailHidePassword)
                                }
                                className={
                                    signInEmailHidePassword
                                    ? "fa fa-eye pointer"
                                    : "fa fa-eye-slash pointer"
                                }
                                aria-hidden="true"
                                style={{marginLeft : '-40px'}}
                            ></i>
                        </div>
                    </div>
                    { ( (signInTypeError === "email") && (signInInputError === "password") ) &&  <span className="required SpotLetContent">{signInEmailError}</span> }
                    <div className="d-flex flex-column justify-content-center align-items-end mb-4">
                        <span className={`auth_text_color fw-semibold SpotLetContent cursor  ${ ( (signInTypeError === "email") && (signInInputError === "password") ) ? "mt-2" : "mt-0"}`}
                        onClick={()=>setTab('forgotPasswordEmail')}
                        >Forgot password?</span>
                    </div>
                    { (signInEmailError  && !signInInputError) && <span className="required SpotLetContent mb-4">{signInEmailError}</span>}
                    <button type="submit" className="btn btn-primary SpotLetSubHead SpotLetSubHead SpotLetSubHead w-100">Sign In</button>
                    <span style={{color : '#ffffff',fontSize : '7px'}}>Password must contain 1 capital letter, 1 small letter, 1 special character, 1 digit, 8-16 characters.</span>
                </form>
            </div>
        )
    }
    const signInMobile = () => {
        return(
            <div className="spotletSignUpMainContainer w-100 d-flex flex-column justify-content-center align-items-center">
                <h1 className="SpotLetMainHead mb-4">Log In with Mobile</h1>
                <span className="auth_text_color fw-semibold SpotLetContent cursor align-self-start mb-4 d-flex flex-row justify-content-center align-items-center"
                onClick={()=>{
                    setTab('signIn');
                    setSignInMobileFormData({});
                    setSignInTypeError("")
                    setSignInInputError("")
                    setSignInMobileError("")
                    setSignInEmailError("")
                }}
                ><IoMdArrowBack /> {' '}Back</span>
                <form className="w-100  align-self-start" onSubmit={signInMobileSubmit}>
                    <div className={`d-flex flex-column ${( (signInTypeError === "mobile") && (signInInputError === "user") )  ? "mb-2" : "mb-4"}`}>
                        <label htmlFor="signInMobile" className="form-label">Mobile<span className="required">*</span></label>
                        <div className="w-100 d-flex flex-row justify-content-start align-items-center">
                            <div className="spotLetCountryCodeContainer text-muted w-20">+91</div>
                            <input
                                className="spotletInputField w-100"
                                type="Mobile"
                                id="signInMobile"
                                name="mobile"
                                value={signInMobileFormData?.mobile}
                                onChange={onChangeSignInMobile}
                                placeholder="Mobile"
                                title="Enter 10-digit indian mobile number"
                                pattern="^[6-9]\d{9}$"
                                required
                            />
                        </div>
                    </div>
                    { ( (signInTypeError === "mobile") && (signInInputError === "user") ) &&  <span className="required SpotLetContent">{signInEmailError}</span> }
                    <div className={`d-flex flex-column ${( (signInTypeError === "mobile") && (signInInputError === "user") )  ? "mt-2" : "mt-0"}  ${( (signInTypeError === "mobile") && (signInInputError === "password") ) ? "mb-2" : "mb-4"}`}>
                        <label htmlFor="signInPassword" className="form-label">Password<span className="required">*</span></label>
                        <div className="w-100">
                            <input
                                className="spotletInputField w-100"
                                type={!signInMobileHidePassword ? "password" : "text"}
                                placeholder="Password"
                                name="password"
                                id="signInPassword"
                                required
                                title="Password must contain 1 capital letter, 1 small letter, 1 special character, 1 digit, 8-16 characters."
                                pattern="^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[\W_]).{8,16}$"
                                maxLength={16}
                                value={signInMobileFormData?.password}
                                onChange={onChangeSignInMobile}
                            />
                            <i
                                onClick={() =>
                                    setSignInMobileHidePassword((signInMobileHidePassword) => !signInMobileHidePassword)
                                }
                                className={
                                    signInMobileHidePassword
                                    ? "fa fa-eye pointer"
                                    : "fa fa-eye-slash pointer"
                                }
                                aria-hidden="true"
                                style={{marginLeft : '-40px'}}
                            ></i>
                        </div>
                    </div>
                    { ( (signInTypeError === "mobile") && (signInInputError === "password") ) &&  <span className="required SpotLetContent">{signInEmailError}</span> }
                    <div className="d-flex flex-column justify-content-center align-items-end mb-4">
                        <span className={`auth_text_color fw-semibold SpotLetContent cursor ${( (signInTypeError === "mobile") && (signInInputError === "password") )  ? "mt-2" : "mt-0"}`}
                        onClick={()=>setTab('forgotPasswordMobile')}
                        >Forgot password?</span>
                    </div>
                    { (signInMobileError && !signInInputError) && <span className="required SpotLetContent mb-4">{signInMobileError}</span>}
                    <button type="submit" className="btn btn-primary SpotLetSubHead SpotLetSubHead SpotLetSubHead w-100">Sign In</button>
                    <span style={{color : '#ffffff',fontSize : '7px'}}>Password must contain 1 capital letter, 1 small letter, 1 special character, 1 digit, 8-16 characters.</span>
                </form>
            </div>
        )
    }
    const otpValidationEmail = () => {
        return(
            <div className="spotletSignUpMainContainer w-100 d-flex flex-column justify-content-center align-items-center">
                <h1 className="SpotLetMainHead mb-4">Verify your email</h1>
                <span className="auth_text_color fw-semibold SpotLetContent cursor align-self-start mb-4 d-flex flex-row justify-content-center align-items-center"
                onClick={()=>{
                    setTab('registerEmail')
                    setTimerEmail(60)
                    setForgotEmailSuccess(false)
                    setResendStatusEmailError(false)
                    setEnteredOtpEmail("")
                    if (intervalEmailRef.current) {
                        clearInterval(intervalEmailRef.current);
                    }
                }}
                ><IoMdArrowBack /> {' '}Back</span>
                {/* <div className="d-flex flex-row justify-content-center align-items-center mb-4"> */}
                    <span className="text-gray-500 text-center fw-semibold fs-6 mb-2 SpotLetContent"
                    >Enter the 4 digit code sent to </span>
                    <div className=" d-flex flex-row justify-content-center align-items-center mb-4">
                        <span 
                        className="text-gray-500 text-center fw-semibold fs-6 me-2 SpotLetContent"
                        style={{fontWeight : 'bold'}}
                        >{registerEmailFormData?.email}
                        </span>
                        <FaRegEdit 
                        className="auth_text_color cursor"
                        style={{fontSize : '18px'}}
                        onClick={()=>{
                            setTab('registerEmail')
                            setTimerEmail(60)
                            setForgotEmailSuccess(false)
                            setResendStatusEmailError(false)
                            setEnteredOtpEmail("")
                            if (intervalEmailRef.current) {
                                clearInterval(intervalEmailRef.current);
                            }
                        }}/>
                    </div>
                {/* </div> */}
                <OTPInput
                    value={enteredOtpEmail}
                    // onChange={setOTP}
                    onChange = { (e) => {
                        toSetOtpEmail(e)
                        setResendStatusEmailError(false)
                    }}
                    autoFocus
                    OTPLength={4}
                    otpType="number"
                    disabled={false}
                    secure
                    className="otp-input mb-4"
                />
                {resendEmailError && <span className="auth_text_color fw-semibold SpotLetContent align-self-center mb-2">Invalid OTP</span>}
                <div className="d-flex flex-row justify-content-center align-items-center">
                    <span className="text-gray-500 text-center fw-semibold fs-6 mb-4 SpotLetContent"
                    >Didn’t get the OTP ?{' '}
                    <span className={`${timerEmail <= 0 ? "auth_text_color cursor" : "text-muted"} fw-semibold SpotLetContent`}
                        onClick={() => {
                            if(timerEmail <= 0) onResendEmail()
                            }}
                        >Resend</span>
                    </span>
                    {timerEmail > 0 && <span className="text-gray-500 text-center fw-semibold fs-6 mb-4 SpotLetContent mx-1"> OTP in <span className="text-gray-500 text-center fw-semibold fs-6 SpotLetContent"  style={{fontWeight : 'bold'}}> {timerEmail} sec</span> . </span>}
                </div>
                {resendStatusEmail && <span className="auth_text_color fw-semibold SpotLetContent align-self-center mb-4">Verification code resend successfully</span>}
                <button type="button" onClick={verifyOtpEmail} className="btn btn-primary SpotLetSubHead SpotLetSubHead SpotLetSubHead w-50 mb-4">Submit</button>
                <span style={{color : '#ffffff',fontSize : '7px'}}>Password must contain 1 capital letter, 1 small letter, 1 special character, 1 digit, 8-16 characters.</span>
            </div>
        )
    }
const otpValidationMobile = () => {
    return(
            <div className="spotletSignUpMainContainer w-100 d-flex flex-column justify-content-center align-items-center">
                <h1 className="SpotLetMainHead mb-4">Verify your Mobile</h1>
                <span className="auth_text_color fw-semibold SpotLetContent cursor align-self-start mb-4 d-flex flex-row justify-content-center align-items-center"
                onClick={()=>{
                    setTab('registerMobile')
                    setTimerMobile(60)
                    setForgotMobileSuccess(false)
                    setResendStatusMobileError(false)
                    setEnteredOtpMobile("")
                    if (intervalMobileRef.current) {
                        clearInterval(intervalMobileRef.current);
                    }
                }}
                ><IoMdArrowBack /> {' '}Back</span>
                <div className="d-flex flex-row justify-content-center align-items-center mb-4">
                    <span 
                    className="text-gray-500 text-center fw-semibold fs-6 SpotLetContent mx-1"
                    style={{fontWeight:"500"}}
                    >Enter 4 digit OTP sent to </span>
                    <span 
                    className="text-gray-500 text-center fw-semibold fs-6 me-2 SpotLetContent"
                    style={{fontWeight : 'bold'}}
                    >+91{registerMobileFormData?.mobile}
                    </span>
                    <FaRegEdit 
                    className="auth_text_color cursor"
                    style={{fontSize : '18px'}}
                    onClick={()=>{
                        setTab('registerMobile')
                        setTimerMobile(60)
                        setForgotMobileSuccess(false)
                        setResendStatusMobileError(false)
                        setEnteredOtpMobile("")
                        if (intervalMobileRef.current) {
                            clearInterval(intervalMobileRef.current);
                        }
                    }}/>
                </div>
                <OTPInput
                    value={enteredOtpMobile}
                    // onChange={setOTP}
                    onChange = { (e) => {
                        toSetOtpMobile(e)
                        setResendStatusMobileError(false)
                    }}
                    autoFocus
                    OTPLength={4}
                    otpType="number"
                    disabled={false}
                    secure
                    className="otp-input mb-4"
                />
                {/* {timerMobile > 0 && <span className="text-gray-500 text-center fw-semibold fs-6 SpotLetContent">OTP in {timerMobile} sec.</span>} */}
                {resendMobileError && <span className="auth_text_color fw-semibold SpotLetContent align-self-center mb-2">Invalid OTP</span>}
                <div className="d-flex flex-row justify-content-center align-items-center mb-4">
                    <span className="text-gray-500 text-center fw-semibold fs-6  SpotLetContent mx-1"
                    >Didn’t get the OTP ?{' '}
                    <span className={`${timerMobile <= 0 ? "auth_text_color cursor" : "text-muted"} fw-semibold SpotLetContent`}
                        onClick={() => {
                            if(timerMobile <= 0) onResendMobile()
                            }}
                        >Resend</span>
                    </span>
                    {timerMobile > 0 && <span className="text-gray-500 text-center fw-semibold fs-6 SpotLetContent">OTP in <span className="text-gray-500 text-center fw-semibold fs-6 SpotLetContent"  style={{fontWeight : 'bold'}}> {timerMobile} sec</span> . </span>}
                </div>
                {resendStatusMobile && <span className="auth_text_color fw-semibold SpotLetContent align-self-center mb-4">Verification code resend successfully</span>}
                <button type="button" onClick={verifyOtpMobile} className="btn btn-primary SpotLetSubHead SpotLetSubHead SpotLetSubHead w-50 mb-4">Submit</button>
                <span style={{color : '#ffffff',fontSize : '7px'}}>Password must contain 1 capital letter, 1 small letter, 1 special character, 1 digit, 8-16 characters.</span>
            </div>
        )
    }
    const forgotPasswordMobile = () => {
        return(
            <div className="spotletSignUpMainContainer w-100 d-flex flex-column justify-content-center align-items-center">
                <h1 className="SpotLetMainHead mb-4">Forgot Password ?</h1>
                <span className="auth_text_color fw-semibold SpotLetContent cursor align-self-start mb-4 d-flex flex-row justify-content-center align-items-center"
                onClick={()=>{
                    setForgotMobileSuccess(false);
                    setTab('signInMobile');
                    setForgotMobileError("");
                    setForgotMobileNumber("");
                }}
                ><IoMdArrowBack /> {' '}Back</span>
                {forgotMobileSuccess ? (
                    <Alert variant="success">We have send a password reset link to your mobile number.</Alert>
                ) : (
                    <>
                        <span className="text-gray-500 text-center fw-semibold fs-6 mb-4 SpotLetContent"
                        >Enter your mobile number to reset your password.</span>
                        <form  className="w-100  align-self-start" onSubmit={forgotMobileSubmit}>
                            <div className="d-flex flex-column mb-4">
                                <label htmlFor="forgotMobile" className="form-label">Mobile<span className="required">*</span></label>
                                <div className="w-100 d-flex flex-row justify-content-start align-items-center">
                                    <div className="spotLetCountryCodeContainer text-muted w-20">+91</div>
                                    <input
                                        className="spotletInputField w-100"
                                        type="Mobile"
                                        id="forgotMobile"
                                        name="Mobile"
                                        value={forgotMobileNumber}
                                        onChange={(e)=>{
                                            setForgotMobileError("");
                                            setForgotMobileNumber(e.target.value)
                                        }}
                                        placeholder="Mobile"
                                        title="Enter 10-digit indian mobile number"
                                        pattern="^[6-9]\d{9}$"
                                        required
                                    />
                                </div>
                            </div>
                            {forgotMobileError && <span className="required SpotLetContent mb-4">{forgotMobileError}</span>}
                            <button type="submit" className="btn btn-primary SpotLetSubHead SpotLetSubHead SpotLetSubHead w-100">Submit</button>
                            <span style={{color : '#ffffff',fontSize : '7px'}}>Password must contain 1 capital letter, 1 small letter, 1 special character, 1 digit, 8-16 characters.</span>
                        </form>
                    </>
                )}
            </div>
        )
    }
    const forgotPasswordEmail = () => {
        return(
            <div className="spotletSignUpMainContainer w-100 d-flex flex-column justify-content-center align-items-center">
                <h1 className="SpotLetMainHead mb-4">Forgot Password ?</h1>
                <span className="auth_text_color fw-semibold SpotLetContent cursor align-self-start mb-4 d-flex flex-row justify-content-center align-items-center"
                onClick={()=>{
                    setForgotEmailSuccess(false);
                    setTab('signInEmail');
                    setForgotEmailError("");
                    setForgotEmailNumber("");
                }}
                ><IoMdArrowBack /> {' '}Back</span>
                {forgotEmailSuccess ? (
                    <Alert variant="success">We have send a password reset link to your email.</Alert>
                ) : (
                    <>
                        <span className="text-gray-500 text-center fw-semibold fs-6 mb-4 SpotLetContent"
                        >Enter your email to reset your password.</span>
                        <form  className="w-100  align-self-start" onSubmit={forgotEmailSubmit}>
                            <div className="d-flex flex-column mb-4">
                                <label htmlFor="forgotEmail" className="form-label">Email<span className="required">*</span></label>
                                <input
                                    className="spotletInputField"
                                    type="email"
                                    id="forgotEmail"
                                    name="Email"
                                    value={forgotEmailNumber}
                                    onChange={(e)=>{
                                        setForgotEmailError("");
                                        setForgotEmailNumber(e.target.value)
                                    }}
                                    placeholder="Email"
                                    title="Enter email as username@domain.com."
                                    pattern="^[^\s@]+@[^\s@]+\.[^\s@]{2,}$"
                                    required
                                />
                            </div>
                            {forgotEmailError && <span className="required SpotLetContent mb-4">{forgotEmailError}</span>}
                            <button type="submit" className="btn btn-primary SpotLetSubHead SpotLetSubHead SpotLetSubHead w-100">Submit</button>
                            <span style={{color : '#ffffff',fontSize : '7px'}}>Password must contain 1 capital letter, 1 small letter, 1 special character, 1 digit, 8-16 characters.</span>
                        </form>
                    </>
                )}
            </div>
        )
    }
    //components end
    return(
        <>
            {tab === 'register' && register()}
            {tab === 'registerEmail' && registerEmail()}
            {tab === 'registerMobile' && registerMobile()}
            {tab === 'signIn' && logIn()}
            {tab === 'signInEmail' && signInEmail()}
            {tab === 'signInMobile' && signInMobile()}
            {tab === 'otpValidationMobile' && otpValidationMobile()}
            {tab === 'otpValidationEmail' && otpValidationEmail()}
            {tab === 'forgotPasswordEmail' && forgotPasswordEmail()}
            {tab === 'forgotPasswordMobile' && forgotPasswordMobile()}
            {loader && <PageLoader/>}
            {
                showConfirmationModal && (
                <ConfirmationModel
                    show={showConfirmationModal}
                    handleClose={() => setShowConfirmationModal(false)}
                    handleOk={() => setShowConfirmationModal(false)}
                    title="Authenticate"
                    body={errorMsg}
                />
                )
            }
        </>
    )
};
export default Authentication;