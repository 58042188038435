import { useEffect, useState, useRef } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useAuthStore } from "../../store/auth";
import { noOfUserNotifications } from "../../apis/api"
import Notifications from "../NotificationsComponent/Notifications.jsx";
import AuthenitcateModal from "../../utils/NewAuthentication/AuthenitcateModal.jsx";
import { getRegions } from "../../apis/api";
import NotificationModalNew from "../NewNotificationModal/NotificationModal.jsx";

import * as React from 'react';
import Box from '@mui/material/Box';
import SwipeableDrawer from '@mui/material/SwipeableDrawer';
import Badge from '@mui/material/Badge';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Avatar from '@mui/material/Avatar';
import AddHomeOutlinedIcon from '@mui/icons-material/AddHomeOutlined';
import ListAltOutlinedIcon from '@mui/icons-material/ListAltOutlined';
import CalendarMonthOutlinedIcon from '@mui/icons-material/CalendarMonthOutlined';
import ManageAccountsOutlinedIcon from '@mui/icons-material/ManageAccountsOutlined';
import ChatIcon from '@mui/icons-material/Chat';
import LogoutOutlinedIcon from '@mui/icons-material/LogoutOutlined';
import NotificationsActiveOutlinedIcon from '@mui/icons-material/NotificationsActiveOutlined';
import MessageOutlinedIcon from '@mui/icons-material/MessageOutlined';
import FavoriteBorderOutlinedIcon from '@mui/icons-material/FavoriteBorderOutlined';
import LoginOutlinedIcon from '@mui/icons-material/LoginOutlined';
import SensorOccupiedOutlinedIcon from '@mui/icons-material/SensorOccupiedOutlined';
import StickyNote2Icon from '@mui/icons-material/StickyNote2';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import Grow from '@mui/material/Grow';
import Paper from '@mui/material/Paper';
import Popper from '@mui/material/Popper';
import MenuItem from '@mui/material/MenuItem';
import MenuList from '@mui/material/MenuList';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import { Collapse } from '@mui/material';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';


import spotlet_logo from "../../assets/NewImages/NewLogo/Spotlet_V1 .webp"
import ic_notification from "../../assets/NewImages/Header/ic-notification.svg";
import ic_message from "../../assets/NewImages/Header/ic-message.svg";
import ic_like from "../../assets/NewImages/Header/ic-like.svg";
import fillHeart from "../../assets/NewImages/Header/redHeartP.svg";
import man from "../../assets/NewImages/CssStyle/avtar.svg";
import globe from "../../assets/NewImages/Header/vector-globe-icon.webp";
import india from "../../assets/NewImages/Header/India.webp"
import unitedStates from "../../assets/NewImages/Header/unitedStates.webp"
import logo from "../../assets/NewImages/Header/Favicon.ico"
import "./Header.css"

export const Header = (props) => {
    const { active, inListing } = props
    // User Data
    const { isAuthenticated, setAuthenticated, userProfile, setUserProfileNull } = useAuthStore();
    const navigate = useNavigate();
    const timerId = useRef(null);
    const [authenticate, setAuthenticate] = useState(false)
    const [navTo, setNavTo] = useState('')
    const [clicked, setClicked] = useState('')
    const [allRegions, setAllRegions] = useState()
    const handleNav = (value,type) => {
        if(userProfile){
            navigate(value)
        }else{
            setClicked(type)
            setNavTo(value)
            setAuthenticate(true)
        }
    }
    const [open, setOpen] = React.useState(false);
    const [nestedOpen, setNestedOpen] = useState(false);
    const handleClick = (event) => {
        event.stopPropagation();
        setNestedOpen(!nestedOpen);
      };
    const anchorRef = React.useRef(null);

    const handleToggle = () => {
        setOpen((prevOpen) => !prevOpen);
    };

    const handleClose = (event) => {
        if (anchorRef.current && anchorRef.current.contains(event.target)) {
        return;
        }

        setOpen(false);
    };

    const [region,setRegion] = useState()
    const [drawerOpen,setDrawerOpen] = useState(false)

    // Notifications
    const [showPopup, setShowPopup] = useState(false);
    const [notificationsCount, setNotificationsCount] = useState(0);
    const countRef = useRef(0)

    const onClickNotification = () => {
        setShowPopup(!showPopup)
      }

    const getNotificationsCount = async () => {
        try {
            const response = await noOfUserNotifications(userProfile._id)
            setNotificationsCount(response.data.count)
            if (countRef.current < response.data.count) {
                showNotification()
                countRef.current = response.data.count
            }
        }
        catch (e) {
            console.log(e);
        }
    }

    const showNotification = () => {
        const options = {
          body: "You got a New Notification",
          icon: logo,
          dir: "ltr"
        }
        const notification = new Notification(`Hello ${userProfile?.personalInfo?.fullName}`, options)
      }

    // useEffect(() => {
    // if (userProfile) {
    //     timerId.current = setInterval(() => {
    //     if(userProfile) getNotificationsCount();
    //     }, 5000);

    //     if (!("Notification" in window)) {
    //         console.info("Browser does not support desktop notification");
    //       } else {
    //         console.info("Notifications are supported");
    //         Notification.requestPermission()
    //       }

    //     return () => {
    //     clearInterval(timerId.current);
    //     };
    // }
    // }, [userProfile])

    const selectRegion = (reg) => {
        setRegion(reg)
        localStorage.setItem('region',reg.name) //set region
        localStorage.setItem("regionId", reg?.regionId) // set region id
        if(reg.name !== region.name) location.reload();
    }
    const toggleDrawer = () => {
        setDrawerOpen(!drawerOpen)
    }
    const onLogout = (event) => {
        event.preventDefault();
        event.stopPropagation();
        toggleDrawer()
        localStorage.removeItem("auth");
        setAuthenticated();
        setUserProfileNull();
        clearInterval(timerId.current);
        navigate("/");
      };

      const getRegionsData = async() => {
        try {
            const res = await getRegions()
            let reqArray = []
            res?.data?.map((each) => {
                const reqObj = {
                    name : each?.country_name ,
                    flag : each?.country_image,
                    regionId : each?.country_id,
                }
                reqArray.push(reqObj)
            } )
            setAllRegions(reqArray)
            setRegionData(reqArray)
        } catch (e) {
            console.log(e)
        }
      }

      const setRegionData = (data) => {
        const currentRegion = localStorage.getItem("region")
        let reqRegionObj
        data?.map((each) => {
            if (each?.name === currentRegion) {
                reqRegionObj = each
            }
        } )
        if (reqRegionObj) {
            setRegion(reqRegionObj)
        }else {
            const defaultRegion = data?.filter((each) => each?.name === "India" )
            setRegion(defaultRegion[0])
        }
      }

      useEffect(() => {
        getRegionsData()
      },[])

    return(
        <div className="headerMainContainier">
            <div className="headerLeftSection">
                <Link className="logo" to="/" onClick={() => window.scrollTo(0, 0)}>
                    <img src={spotlet_logo} alt="Spotlet" />
                </Link>
            </div>
            <div className="headerRightSection">
                {/* <div className="dropdown m-1">
                    <a className="dropdown-toggle region-toggle" title="Region" style={{color:'#000'}} href="#" id="navbarDropdownMenuLink" data-bs-toggle="dropdown" aria-expanded="false"
                    >
                        <img src={region.flag || globe} style={{height:'30px',width:'30px',borderRadius:'50%'}}/>
                    </a>
                    <div className="dropdown-menu" style={{ backgroundColor: "white",width : '200px' }} aria-labelledby="navbarDropdownMenuLink">
                        {REGIONS.map((reg) => (
                        <div className="dropdown-item d-flex justify-content-between align-center cursor" onClick={() => selectRegion(reg)}>
                            <div className="d-flex justify-content-between align-center">
                                <img style={{height:'30px',width:'30px',borderRadius:'50%',marginRight:'10px'}} alt = {reg.name} src = {reg.flag}/>
                                <p className="m-0">{reg.name}</p>  
                                {region.name === reg.name && <i className="bi bi-check2"></i>} 
                            </div>
                        </div>
                        ))}
                    </div>
                </div> */}
                { allRegions?.length > 0 && (
                     <>
                        <Tooltip title="Region">
                            <IconButton
                                onClick={handleToggle}
                                size="small"
                                sx={{ ml: 2 }}
                                ref={anchorRef}
                                id="composition-button"
                                aria-controls={open ? 'account-menu' : undefined}
                                aria-haspopup="true"
                                aria-expanded={open ? 'true' : undefined}
                            >
                                {/* <Avatar sx={{ width: 32, height: 32 }} src={region.flag || globe} ></Avatar> style={{width:"32px", height:"32px", objectFit : "cover"}}  */}
                                <img src={region.flag || globe} className="flagImage" alt='flag' /> 
                            </IconButton>
                        </Tooltip>
                        <Popper
                        open={open}
                        anchorEl={anchorRef.current}
                        role={undefined}
                        placement="bottom-start"
                        transition
                        disablePortal
                        >
                        {({ TransitionProps, placement }) => (
                            <Grow
                            {...TransitionProps}
                            style={{
                                transformOrigin:
                                placement === 'bottom-start' ? 'left top' : 'left bottom',
                            }}
                            >
                            <Paper>
                                <ClickAwayListener onClickAway={handleClose}>
                                <MenuList
                                    autoFocusItem={open}
                                    id="composition-menu"
                                    aria-labelledby="composition-button"
                                    // onKeyDown={handleListKeyDown}
                                >
                                    {allRegions?.map((reg)=>{
                                        return(
                                            <MenuItem onClick={() => selectRegion(reg)}><Avatar src={reg.flag}  style={{height:'30px',width:'30px',borderRadius:'50%',marginRight:'10px'}}/>{reg.name}{region.name === reg.name && <i className="bi bi-check2"></i>} </MenuItem>
                                        )
                                    })}
                                </MenuList>
                                </ClickAwayListener>
                            </Paper>
                            </Grow>
                        )}
                        </Popper>
                </>
                )}
                {/* {!inListing && <span className="headerLinks listYourSpace cursor" onClick={()=>handleNav('/plans')}>List Your Space</span>} */}
                {!isAuthenticated && <span className="headerLinks listYourSpace cursor" onClick={()=>navigate('/auth',{state:{activeTab:'register'}})}>Register</span>}
                 {!isAuthenticated && <span className="headerLinks listYourSpace cursor" onClick={()=>navigate('/auth',{state:{activeTab:'signIn'}})}>Log In</span>}
                 {isAuthenticated && (
                    <div className="headerNotifications">
                        {/* <span>
                        <Badge badgeContent={notificationsCount} invisible={notificationsCount === 0} color="error">
                            <img src={ic_notification} className="notificationImages"
                            onClick={()=> navigate('/notifications')} alt='notification'/>
                        </Badge>
                        </span> */}
                        <NotificationModalNew/>
                        {/* <span>
                            <img src={ic_message} alt='message' className="notificationImages"/>
                        </span> */}
                        <Link to="/favourites" className="headerFavourites" >
                            <img src={active ? fillHeart : ic_like} title="Favourites" className="notificationImages"/>
                        </Link>
                    </div>
                 )}
                <div className="header-sidebar" >
                {/* <button
                    className="navbar-toggler ms-auto"
                    type="button"
                    onClick={toggleDrawer}
                    >
                    <i className="bi bi-list"></i>
                </button> */}
                {/* <Avatar onClick={toggleDrawer} style={{objectFit : 'cover'}} alt="avatar" src={userProfile?.personalInfo?.profile_pic ? userProfile?.personalInfo?.profile_pic : man} className="cursor"/>  */}
                {userProfile ? (
                    <>
                        {userProfile?.personalInfo?.profile_pic ? (
                            <img  onClick={toggleDrawer} className="headerProfileImage cursor" alt="avatar" src={userProfile?.personalInfo?.profile_pic}  />
                        ) : (
                            <p className="headerProfileImage header_avatar_firstLetter cursor" onClick={toggleDrawer}>{userProfile?.personalInfo?.fullName.slice(0,1).toUpperCase()}</p>
                        )}
                    </>
                ) : (
                    <img  onClick={toggleDrawer} className="headerProfileImage cursor" alt="avatar" src={man}  />
                )}
                <SwipeableDrawer
                    anchor='right'
                    open={drawerOpen}
                    onClose={toggleDrawer}
                    onOpen={toggleDrawer}
                >
                    <Box
                        sx={{ width: 300 }}
                        role="presentation"
                        onClick={toggleDrawer}
                        onKeyDown={toggleDrawer}
                        >
                        <List>
                        {isAuthenticated && (
                        <ListItem disablePadding className="headerSidebar">
                                <ListItemButton>
                                    <ListItemIcon>
                                        {/* <LoginOutlinedIcon  className="headerSidebarItem"/> */}
                                        {userProfile ? (
                                            <>
                                                {userProfile?.personalInfo?.profile_pic ? (
                                                    <Avatar onClick={toggleDrawer} alt="avatar" src={userProfile?.personalInfo?.profile_pic} className="cursor headerSidebarItem"/>
                                                ) : (
                                                    <p className="headerProfileImage header_avatar_firstLetter cursor" onClick={toggleDrawer}>{userProfile?.personalInfo?.fullName.slice(0,1).toUpperCase()}</p>
                                                )}
                                            </>
                                        ) : (
                                            <Avatar onClick={toggleDrawer} alt="avatar" src={man} className="cursor headerSidebarItem"/>
                                        )}
                                    </ListItemIcon>
                                    <ListItemText primary={`Hi, ${userProfile?.personalInfo?.fullName}`} className="headerSidebarItem"/>
                                </ListItemButton>
                            </ListItem>
                            )}
                            {!isAuthenticated && (
                            <ListItem disablePadding className="headerSidebar" onClick={()=>navigate('/auth',{state : {activeTab : 'signIn'}})}>
                                <ListItemButton>
                                    <ListItemIcon>
                                        <LoginOutlinedIcon  className="headerSidebarItem"/>
                                    </ListItemIcon>
                                    <ListItemText primary='Log In' className="headerSidebarItem"/>
                                </ListItemButton>
                            </ListItem>
                            )}
                            {!isAuthenticated && (
                            <ListItem disablePadding className="headerSidebar" onClick={()=>navigate('/auth',{state:{activeTab:'register'}})}>
                                <ListItemButton>
                                    <ListItemIcon>
                                        <SensorOccupiedOutlinedIcon   className="headerSidebarItem"/>
                                    </ListItemIcon>
                                    <ListItemText primary='Register'   className="headerSidebarItem"/>
                                </ListItemButton>
                            </ListItem>
                            )}
                            {!inListing && (
                            <ListItem disablePadding className="headerSidebar" onClick={()=>handleNav('/plans')}>
                                <ListItemButton>
                                    <ListItemIcon>
                                        <AddHomeOutlinedIcon   className="headerSidebarItem"/>
                                    </ListItemIcon>
                                    <ListItemText primary='List Your Space'   className="headerSidebarItem"/>
                                </ListItemButton>
                            </ListItem>
                            )}
                        </List>
                        {isAuthenticated && (
                            <>
                            <Divider />
                            <List>
                                <ListItem disablePadding className="headerSidebar" onClick={()=> navigate('/edit-profile')}>
                                    <ListItemButton>
                                        <ListItemIcon>
                                            <ManageAccountsOutlinedIcon   className="headerSidebarItem"/>
                                        </ListItemIcon>
                                        <ListItemText primary='Edit Profile'   className="headerSidebarItem"/>
                                    </ListItemButton>
                                </ListItem>
                                {(userProfile?.listings > 0 || userProfile?.incompleteListing > 0) && (
                                    <>
                                        <ListItem button onClick={handleClick}>
                                            <ListItemIcon>
                                                <ListAltOutlinedIcon   className="headerSidebarItem" />
                                            </ListItemIcon>
                                            <ListItemText primary="My Listings"  className="headerSidebarItem"/>
                                            {/* {nestedOpen ? <ExpandLess /> : <ExpandMore />} */}
                                        </ListItem>
                                        <Collapse in={nestedOpen} timeout="auto" unmountOnExit>
                                            <List component="div" disablePadding>
                                            <ListItem button sx={{ pl: 4 }} onClick={()=> navigate('/listed-properties')}>
                                                <ListItemIcon>
                                                <KeyboardArrowRightIcon />
                                                </ListItemIcon>
                                                <ListItemText primary="Listed Properties"  className="headerSidebarItem"/>
                                            </ListItem>
                                            <ListItem button sx={{ pl: 4 }} onClick={()=> navigate('/incomplete-listings')}>
                                                <ListItemIcon>
                                                <KeyboardArrowRightIcon />
                                                </ListItemIcon>
                                                <ListItemText primary="Incomplete Listings"  className="headerSidebarItem"/>
                                            </ListItem>
                                            <ListItem button sx={{ pl: 4 }} onClick={()=> navigate('/booking-requests')}>
                                                <ListItemIcon>
                                                <KeyboardArrowRightIcon />
                                                </ListItemIcon>
                                                <ListItemText primary="Booking Requests"  className="headerSidebarItem"/>
                                            </ListItem>
                                            </List>
                                        </Collapse>
                                    </>
                                )}
                                {userProfile?.bookings > 0 && (
                                    <ListItem disablePadding className="headerSidebar" onClick={()=> navigate('/my-bookings')}>
                                        <ListItemButton>
                                            <ListItemIcon>
                                                <StickyNote2Icon   className="headerSidebarItem"/>
                                            </ListItemIcon>
                                            <ListItemText primary='My Bookings'   className="headerSidebarItem"/>
                                        </ListItemButton>
                                    </ListItem>
                                )}
                                <ListItem disablePadding className="headerSidebar" onClick={()=> navigate('/my-enquiries')}>
                                    <ListItemButton>
                                        <ListItemIcon>
                                            <ChatIcon   className="headerSidebarItem"/>
                                        </ListItemIcon>
                                        <ListItemText primary='My Enquiries'   className="headerSidebarItem"/>
                                    </ListItemButton>
                                </ListItem>
                                <ListItem disablePadding className="headerSidebar" onClick={()=> navigate('/notifications')}>
                                    <ListItemButton>
                                        <ListItemIcon>
                                            <NotificationsActiveOutlinedIcon   className="headerSidebarItem"/>
                                        </ListItemIcon>
                                        <ListItemText primary='Notifications'   className="headerSidebarItem"/>
                                    </ListItemButton>
                                </ListItem>
                                {/* <ListItem disablePadding className="headerSidebar" onClick={toggleDrawer}>
                                    <ListItemButton>
                                        <ListItemIcon>
                                            <MessageOutlinedIcon   className="headerSidebarItem"/>
                                        </ListItemIcon>
                                        <ListItemText primary='Messages'   className="headerSidebarItem"/>
                                    </ListItemButton>
                                </ListItem> */}
                                <ListItem disablePadding className="headerSidebar" onClick={()=> navigate('/favourites')}>
                                    <ListItemButton>
                                        <ListItemIcon>
                                            <FavoriteBorderOutlinedIcon   className="headerSidebarItem"/>
                                        </ListItemIcon>
                                        <ListItemText primary='Favourites'   className="headerSidebarItem"/>
                                    </ListItemButton>
                                </ListItem>
                                <ListItem disablePadding className="headerSidebar" onClick={()=> navigate('/calendar')}>
                                    <ListItemButton>
                                        <ListItemIcon>
                                            <CalendarMonthOutlinedIcon   className="headerSidebarItem"/>
                                        </ListItemIcon>
                                        <ListItemText primary='Calendar'   className="headerSidebarItem"/>
                                    </ListItemButton>
                                </ListItem>
                            </List>
                            <Divider />
                            <List>
                            <ListItem disablePadding className="headerSidebar" onClick={onLogout}>
                                    <ListItemButton>
                                        <ListItemIcon>
                                            <LogoutOutlinedIcon  className="headerSidebarItem" />
                                        </ListItemIcon>
                                        <ListItemText primary='Log Out'  className="headerSidebarItem" />
                                    </ListItemButton>
                                </ListItem>
                            </List>
                            </>
                        )}
                        </Box>
                </SwipeableDrawer>
                </div>
            </div>
            {showPopup && <Notifications clickNotification={onClickNotification} notificationsCount={getNotificationsCount} count={notificationsCount} />}
            {authenticate && 
                <AuthenitcateModal
                    show={authenticate}
                    handleSuccess={()=> {
                        setAuthenticate(false)
                        navigate(navTo)
                    }}
                    handleClose={()=>setAuthenticate(false)}
                    activeTab={clicked}
                />
            }
        </div>
    )
};