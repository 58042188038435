import { useEffect,useState } from "react";
import { getAllTags, createTag } from "../../apis/api";
import { ConfirmationModel } from "../Modal/ConfirmationModel";
import { AddNewTagModal } from "../Modal/AddNewTagModal";
import { PageLoader } from "../PageLoader";
import info from "../../assets/NewImages/BecomeAHost/i.svg";

const Activities = ({data, handleBack, handleNext, pricingData, propertyId}) => {

    const [addTag, setAddTag] = useState(false);
    const [newTag,setNewTag] = useState('')
    const [show,setShow] = useState(false)
    const [modalBody,setModalBody] = useState('')
    const [modalTitle,setModalTitle] = useState('')
    const [showLoader,setShowLoader] = useState(false)

    const [allTags, setAllTags] = useState()
    const [reqTypes, setReqTypes] = useState([])
    const [selectedTagsData, setSelectedTagsData] = useState([])
    const [requestedTagsData, setRequestedTagsData] = useState([])
    const [newTagType, setNewTagType] = useState()
    const [modalTagsData, setModalTagsData] = useState([])


    const handleCheckBox = (e,type) => {
        const {name,checked} = e.target 
        let tempTagsData = [...selectedTagsData]
        const currentData = {
            name,
            type,
        }
        if (checked) {
            tempTagsData.push(currentData)
        } else {
            const reqArray = tempTagsData?.filter((each) => {
                const reqEach = {
                    name : each?.name,
                    type : each?.type
                }
                if (JSON.stringify(currentData) !== JSON.stringify(reqEach) ) return each
            })
            tempTagsData = reqArray
        }
        setSelectedTagsData(tempTagsData)
    }

    const handleAddNewTag = async (reqData) => {
        // e.preventDefault()
        setShowLoader(true)
        try{
            const resp = await createTag({name : reqData?.name,status : 'Under Review', type : reqData?.type, requested_by : propertyId})
            setModalTitle('Tags')
            setModalBody(resp?.data?.message)
            setShow(true)
            setNewTag('')
            setAddTag(false)
            getData()
        }catch(error){
            console.log(error)
            setModalTitle('Tags')
            setModalBody(error?.response?.data?.message)
            setShow(true)
        }
        setShowLoader(false)
    }


    const getData = async () => {
        setShowLoader(true)
        try{
            const resp = await getAllTags({...pricingData, propertyId})
            const hostTags = resp?.data?.hostTags
            // const propTags = [...data, ...hostTags];
            // const temp = []
            // resp?.data?.map((item) => {
            //     if(!propTags?.data?.listing?.tags.includes(item?.name)){
            //         temp.push({value : item?.name,label : item?.name})
            //     }
            // })
            const reqTypesArray = []
            for (let each in pricingData) {
                if (pricingData[each]) reqTypesArray.push(each)
            }
            // resp?.data?.allTags?.map((each) => {
            //     if (!reqTypesArray?.includes(each?.type)) {
            //         reqTypesArray.push(each?.type)
            //     }
            // } )

            let orderedReqTypes = []
            if (reqTypesArray?.includes("film") || reqTypesArray?.includes("tv")  ) orderedReqTypes.push("film")
            if (reqTypesArray?.includes("event")) orderedReqTypes.push("event")
            if (reqTypesArray?.includes("event")) orderedReqTypes.push("corporateEvent")
            if (reqTypesArray?.includes("event")) orderedReqTypes.push("individualEvent")
            if (reqTypesArray?.includes("stay")) orderedReqTypes.push("stay")
            // orderedReqTypes.push("photoshoot")

            setRequestedTagsData([...hostTags])
            setReqTypes(orderedReqTypes)
            resp?.data?.tags?.sort((a,b) => {
                return a.name.localeCompare(b.name)
            } )
            setAllTags(resp?.data?.allTags);

            // setTags(temp)
            // setPropertyTags(propTags?.data?.listing?.tags)
            // setSubscription(propTags?.data?.listing?.subscription_id)
            // setPropertyReuqestedTags(propTags?.data?.requestedTags)
        }catch(error){
            console.log(error)
            setModalTitle('Tags')
            setModalBody(error?.response?.data?.message)
            setShow(true)
        }
        setShowLoader(false)
    }

    useEffect(()=>{
        getData()
    },[data]);

    useEffect(() => {
        if(data){
            setSelectedTagsData(data) 
        }
    },[])


    const getCheckedStatus = (data) => {
        const status = selectedTagsData?.some((eachObject) => {
            if  (JSON.stringify(eachObject) === JSON.stringify(data) ) {
                return true
            }
        } )
        return status
    }

    const getTagsForType = (data) => {
        const reqTags =  allTags?.filter((each) => each?.type === data )
        reqTags?.sort((a,b) => {
            return a.name.localeCompare(b.name)
        } )
        return (
            // <div className="container">
                // {
                    <>
                        {
                            reqTags?.map((each) => {
                                    return (
                                        <div className="tags-eachtag-container col-sm-6 col-lg-4">
                                            <input
                                            className="tags-checkbox"
                                            id = {`${each?.type}-${each?.name}`}
                                            name = {each?.name}
                                            type = "checkbox"
                                            onChange={(e) => handleCheckBox(e, each?.type)}
                                            checked  = {getCheckedStatus({name : each?.name, type : each?.type})}
                                            />
                                            <label className = "SpotLetContent" htmlFor={`${each?.type}-${each?.name}`}> {each?.name} </label>
                                        </div>
                                    )
                            } )
                        }
                        {/* {
                            requestedTagsData?.map((each) => {
                                if (each?.type === data) {
                                    return (
                                        <div className="tags-eachtag-container col-sm-6 col-lg-4">
                                            <input
                                            className="tags-checkbox"
                                            id = {`${each?.type}-${each?.name}`}
                                            name = {each?.name}
                                            type = "checkbox"
                                            // onChange={handleCheckBox}
                                            checked  = {true}
                                            // disabled
                                            />
                                            <label htmlFor = {`${each?.type}-${each?.name}`}> {each?.name} - "Requested"  </label>
                                        </div>
                                    )
                                }
                                    
                            } )
                        } */}
                        {/* <button  type = 'button' className="btn btn-primary mt-2" style={{width:"220px", height : "40px"}}   title="Create a new tag here" 
                            onClick={()=>{
                                setAddTag(true)
                                setNewTagType(data)
                                setModalTagsData(reqTags)
                            } 
                        }
                        >
                            Create New Tag
                        </button> */}
                    </>
            //    }
            // </div>
        )
    }

    const getTagTypeHeading = (name) => {
        switch (name) {
            case "film":
                return "FILM / TV SHOOTINGS"
            case "event":
                return "EVENTS"
            case "corporateEvent":
                return "CORPORATE EVENTS"
            case "individualEvent":
                return "PERSONAL EVENTS"
            case "stay":
                return "STAYCATIONS"
            // case "photoshoot":
            //     return "PHOTOSHOOT"
            default:
                return "No Type";
        }
    }

    // new code
    const onClickSubmit = (e) => {
        e.preventDefault()
        if (!(selectedTagsData?.length > 0)) {
            setShow(true);
            setModalTitle("All Activities");
            setModalBody("Select atleast one activity");
            return null
        }
        handleNext({tags : selectedTagsData})
    }

    const  removeObjects = (array1, array2) => {
        return array2.filter(obj2 => 
            !array1.some(obj1 => obj1.name === obj2.name && obj1.type === obj2.type)
        );
    }

    const validateCheckAll = (type) => {
        let reqTags =  []
        allTags?.filter((each) => {
            const reqEach = {
                name : each?.name,
                type : each?.type
            }
            if (each?.type === type) {
                reqTags.push(reqEach);
            }
        } );

        const value =  reqTags.every(obj1 => 
            selectedTagsData.some(obj2 => obj1.name === obj2.name && obj1.type === obj2.type)
        );
        return value
    } 

    const handleSelectAll = (e, type) => {
        const {checked} = e?.target;
        let reqTags =  []
        allTags?.filter((each) => {
            const reqEach = {
                name : each?.name,
                type : each?.type
            }
            if (each?.type === type) {
                reqTags.push(reqEach);
            }
        } );

        if (checked) {
            setSelectedTagsData([...selectedTagsData, ...reqTags]);
        } else {
            const reqArray = removeObjects(reqTags, selectedTagsData);
            setSelectedTagsData(reqArray)
        }
    }

    return (
        // <div className="dashboardRecentEventsSection">
        <div className="locationtype-page container-fluid">
            {/* <span className="dashboardRecentEventsSectionHeading">Tags{' '} */}
            <h1 className="locationtype-head SpotLetMainHead" id = "listTags">Activities{' '}
            <a
                    href="#"
                    data-bs-toggle="tooltip"
                    title=" Improve search visibility by categorizing properties for easier discovery"
                >
                    <img src={info} />
                </a>
            </h1>
            {
                <div className="container">
                    <div className="row">
                        <form  className="col-lg-12 mt-3" style={{marginBottom : "73px"}}>
                        {/* <form  className="col-xl-8 mt-2" style={{marginBottom : "73px"}}> */}
                            <div className="container">
                                {
                                    reqTypes?.length > 0 && (
                                        // <div className="tags-tagstype-container"> 
                                        <div className=""> 
                                            {
                                            reqTypes?.map((each) => {
                                                return(
                                                    <>
                                                        {/* <button type = "button" className={`tag-type-buttons cursor btn ${activeType === each ? "tagsTypeActive" : "btn-outline-secondary"} `}  onClick={() => setActiveType(each)} > {each?.charAt(0)?.toUpperCase() + each?.slice(1)} </button> */}
                                                        {each === "event" && (<h1 className="SpotLetSubHead mt-3"  style={{fontWeight : "900"}}> {getTagTypeHeading(each)} </h1> )}
                                                        { each !== "event" && (
                                                            <div className = {`tags-type-heading d-flex flex-row align-items-center ${ (each === "corporateEvent" || each === "individualEvent") && "mt-1"}`}>
                                                                <h1 className="SpotLetSubHead tags-sub-head "> {getTagTypeHeading(each)} </h1>
                                                                <div className="tags-eachtag-container col-sm-6 col-lg-4 pt-0">
                                                                    <input
                                                                    className="tags-checkbox"
                                                                    style = {{width : "18px"}}
                                                                    id = {`type-${each}`}
                                                                    name = {each}
                                                                    type = "checkbox"
                                                                    onChange={(e) => handleSelectAll(e, each)}
                                                                    checked  = {validateCheckAll(each)}
                                                                    />
                                                                    <label className="SpotLetSubHead tags-select-all-label" htmlFor={`type-${each}`}> Select all </label>
                                                                </div>
                                                            </div>
                                                        )}
                                                        { each !== "event" && (
                                                            <div className="row">
                                                                {/* <div className="container"> */}
                                                                    {getTagsForType(each)}
                                                                {/* </div> */}
                                                            </div>
                                                        )}
                                                    </>
                                                )
                                            } )
                                            }
                                        </div>
                                    )
                                }
                            </div>
                        </form>
                    </div>
                </div>
            // )
            }
            {/* Footer Start */}
            <div className="row controlContainer">
                    <div className="col-xl-10">
                    {/* <div className=""> */}
                        <div className="d-flex flex-row justify-content-between align-items-center">
                            <div>
                                <button
                                type="button"
                                class="btn btn-outline-danger"
                                onClick={handleBack}
                                >
                                    Back
                                </button>
                            </div>
                            <div>
                                <button
                                type="button"
                                className="btn btn-primary"
                                onClick = {onClickSubmit}
                                >
                                    Save & Continue
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            {/* Footer End */}
            {show && (
                <ConfirmationModel
                    show={show}
                    title={modalTitle}
                    body={modalBody}
                    handleClose={() => setShow(false)}
                    handleOk={()=> setShow(false)}
                 />
            )}
            {/* {addTag && (
                <AddNewTagModal
                    show={addTag}
                    // title={modalTitle}
                    // body={modalBody}
                    handleClose={() => setAddTag(false)}
                    handleOk={()=> setAddTag(false)}
                    handleSubmit = {(submitData, tagExists) => {
                        if (tagExists) {
                            handleEachSubmit(data, [...selectedTagsData, submitData])
                            setAddTag(false)
                            getData()
                        } else {
                            handleAddNewTag(submitData)
                        }
                    } }
                    data = {modalTagsData}
                    type = {newTagType}
                 />
            )} */}
            {showLoader && <PageLoader />}
            {/* {(paymentComponent && data) && <PaymentComponent id = {data}  triggerPaymentComponent = {triggerPaymentComponent}  completeTask={() =>getData()}/>} */}
        </div>
    )
}
export default Activities;