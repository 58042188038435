import { useEffect, useMemo, useState } from "react";
import Table from "./Table";
import ResponsiveTable from "./responsiveTable";
import { useAuthStore } from "../store/auth";
import { useNavigate } from "react-router-dom";
import { Button } from "react-bootstrap";
import {updateSubscriptionPlan, subscription, getUserIncompleteListings} from "../apis/api";
import dayjs from "dayjs";
import { PageLoader } from "./PageLoader";

export const DraftsTable = () => {
  const { userProfile } = useAuthStore();
  const navigate = useNavigate();
  const COLUMNS = ['Location Id','Location Name','Created Date','Last Updated Date','Plan','Action']
  const [rows,setRows] = useState([])
  const [drafts, setDrafts] = useState([]);
  const [loader, setLoader] = useState(false)
  const [init, setInit] = useState(false)

  const handleTableData = (data) => {
    Promise.all(data?.map((item)=>{
      return {
        locationId : item?.location_id || "NA",
        locationName : item?.basic_info?.property_name || "NA",
        createdAt : dayjs(item?.createdAt).format("DD/MM/YYYY"),
        updatedAt : dayjs(item?.updatedAt).format("DD/MM/YYYY"),
        plan : item?.subscription_id || item?.planName,
        action : item?.location_id ? 'editListing' :  'continueListing'
      }
    })).then((values)=> setRows(values))
  }

  useEffect(() => {
    (async () => {
      if (userProfile) {
        setLoader(true)
        try {
          const resp = await getUserIncompleteListings(userProfile._id);
          handleTableData(resp?.data)
          const locations = [];

          // if (userProfile?.standardSubscription) {
          //   locations.push({
          //     subscriptionPlan: "Standard",
          //     subscriptionId: userProfile?.standardSubscription?.subscriptionId
          //   });
          // }

          // if (userProfile?.premiumSubscription) {
          //   locations.push({
          //     subscriptionPlan: "Premium",
          //     subscriptionId: userProfile?.premiumSubscription?.subscriptionId
          //   });
          // }

          resp?.data.map((loc) => {
            locations.push(loc);
          });
          setDrafts(locations);
        } catch (error) {
          console.log(error);
        }
        setLoader(false)
        setInit(true)
      }
    })();
  }, [userProfile]);

  const editTempLocation = async (location) => {
    const locationId = location;

    if (locationId) {
      navigate("/list-your-space",{ state: {locationId,type : 'incomplete'} });
    }
  }

  const continueListing = async () => {
      navigate("/list-your-space",{ state: {type : 'incomplete'} });
  }

  function loadScript(src) {
    return new Promise((resolve) => {
      const script = document.createElement('script');
      script.src = src;
      script.onload = () => {
        resolve(true);
      };
      script.onerror = () => {
        resolve(false);
      };
      document.body.appendChild(script);
    });
  }

  const getSubscription = async (planId, planName, locationId) => {
    try {
      const res = await subscription({ id: userProfile?._id, planId, planName })
      if (res.status === 200) {
        //window.open(res.data.short_url, '_blank').focus();
        // navigate(res.data.short_url)
        if (res?.data?.id) {
          checkoutSubscription(planName, res?.data?.id, locationId);
        }

      }
    } catch (err) {
      console.log(err)
    }
  }

  const checkoutSubscription = async (selectedPlan, subscriptionId, locationId) => {
    const res = await loadScript(
      'https://checkout.razorpay.com/v1/checkout.js'
    );

    if (!res) {
      alert('Razorpay SDK failed to load. Are you online?');
      return;
    }

    var subOptions = {
      "key": "rzp_test_gotWBtdd6rUaTo",
      "subscription_id": subscriptionId,
      "name": "My Billing Label",
      "description": "Auth txn for " + subscriptionId,
      "handler": async (response) => {
        setPaymnetId(response.razorpay_payment_id);
        setPlan(selectedPlan)
        try {
          if (locationId) {
            await updateSubscriptionPlan({ plan: selectedPlan, paymentId: response.razorpay_payment_id, locationId: locationId });
            navigate('/listed-properties');
          }
          navigate('/listing-type');
        } catch (e) {
          console.log(e);
        }
      }
    };

    const paymentObject = new window.Razorpay(subOptions);
    paymentObject.open();
  }

  const createLocation = async (location) => {

    checkoutSubscription(location?.subscriptionPlan, location.subscriptionId)
  }

  const columns = useMemo(
    () => [
      {
        Header: "Location ID",
        accessor: "location_id",
      },
      {
        Header: "Location Name",
        accessor: "basic_info.property_name",
      },
      {
        Header: "Date",
        accessor: "updatedAt",
        Cell: ({ cell: { value } }) => {
          return dayjs(value).format("DD/MM/YYYY");
        },
      },
      {
        Header: "Plan",
        accessor: "",
        Cell: ({ row: { original } }) => {
          if(original.subscription_id){
            return original.subscription_id
          }else{
            return original.planName
          }
        },
      },
      {
        Header: "Action",
        accessor: "",
        Cell: ({ row: { original } }) => {
          if (original?.subscriptionPlan === 'Premium' && original?.location_id) {
            return (
              <div className="d-flex justify-content-start">
                <button type="button" className="btn btn-detail btn-delet" onClick={() => editTempLocation(original)}>
                  Continue Listing
                </button>
                <button type="button" className="btn  btn-detail btn-delet">
                  Delete
                </button>
              </div>
            );
          } else if (original?.subscriptionPlan === 'standard' && original?.location_id) {
            return (
              <div className="d-flex justify-content-start">
                <Button variant="primary" onClick={() => getSubscription("plan_McgIyGmBSbSgd6", 'Premium', original?.location_id)}>
                  Upgrade
                </Button>
                <Button variant="danger" className="btn btn-theme btn-detail" onClick={() => editTempLocation(original)}>
                  Continue Listing
                </Button>
              </div>
            )
          } else if (original?.location_id) {
            return (
              <div className="d-flex justify-content-start">
                {/* <Button variant="success" onClick={() => getSubscription("plan_McgHlhqYY4mARQ", "standard", original?.location_id)}>
                  Standard
                </Button> */}
                <Button variant="primary" style={{ marginRight: '10px' }} onClick={() => getSubscription("plan_McgIyGmBSbSgd6", 'Premium', original?.location_id)}>
                  Upgrade
                </Button>
                <Button variant="danger" className="btn btn-theme btn-detail" onClick={() => editTempLocation(original)}>
                  Continue Listing
                </Button>
              </div>
            )
          } else {
            return (
              <Button variant="danger" className="btn btn-theme btn-detail" onClick={() => continueListing(original)}>
                  Continue Listing
              </Button>
            )
          }
        },
      },
    ],
    []
  );

  return (
    <>
    {/* <Table columns={columns} data={drafts} /> */}
    <>
    {init && (
      <>
        {drafts?.length === 0 ? (
          <div className="d-flex flex-column justify-content-center align-items-center">
            <span className="SpotLetSubHead">No Incomplete Listings Found</span>
          </div>
        ) : (
          <ResponsiveTable rows={rows}  columns={COLUMNS} editTempLocation={editTempLocation} continueListing={continueListing}/>
        )}
      </>
    )}
    </>
    {loader && <PageLoader />}
    </>
  );
};
