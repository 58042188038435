import { create } from "zustand";

export const useLocationStore = create((set) => ({
  plan:null,
  paymentId: null,
  setPaymnetId : (paymentId) => {
    set(() => ({ paymentId: paymentId }));
  },
  setPlan : (selectedPlan) => {
    set(() => ({ plan: selectedPlan }));
  },
}));
