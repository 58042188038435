import React, { useState, useEffect } from 'react';
import { Modal, Button, Col, Form} from 'react-bootstrap';
import { PageLoader } from '../PageLoader';
import bcrypt from "bcryptjs-react"
import { updateUserDetails } from '../../apis/api';
import { getSignInOtp } from '../../apis/api';
import PhoneInput, {isValidPhoneNumber} from 'react-phone-number-input'
import OTPInput from "otp-input-react";
import { FaRegEdit } from "react-icons/fa";



export const  WhatsAppVerificationModal = ({show, phoneNumber, onHide, onSuccess, userDetails, userId, header,text}) => {
    const [timer, setTimer] = useState(60);
    const [mobileNumber,setMobileNumber] = useState(phoneNumber)
    const [mobileNumberError,setMobileNumberError] = useState("");
    const [sentOtp, setSentOtp] = useState()
    const [otp, setOtp] = useState()
    const [loader, setLoader] = useState(false);
    const [showMobileInput, setShowMobileInput] = useState(true)
    const [otpErrorMsg, setOtpErrorMsg] = useState("")


    const onWhatsAppVerificationSuccess = async() => {
       setLoader(true)
        try {
          await updateUserDetails(userId, {...userDetails, mobile : mobileNumber, mobileVerified : true, fullName : userDetails?.fullName?.trim()});
          onSuccess("true")
        } catch(error) {
          console.log(error)
          onSuccess("false")
        }
        setLoader(false)
      }


    const whatsAppNumberInputSection = () => {
        return(
            <form onSubmit={ (e) => handleWhatsappVerification(e)}>
                    <span className='SpotLetContent mb-3'>{text}</span>
                  <div className="mt-3 w-100">
                    <label htmlFor="manager_number" className="form-label">
                        Mobile Number{' '}<span className="required">*</span>
                    </label>
                    <PhoneInput
                        required
                        international
                        countryCallingCodeEditable={false}
                        flags
                        placeholder="Enter phone number"
                        style={{backgroundColor : "#ffffff", border: "1px solid #dddddd", borderRadius : "10px", height:"50px", margin : "0px 0px 10px 0px", paddingLeft : "15px" }}
                        value={mobileNumber} 
                        defaultCountry="IN"
                        onChange={ (e) => {
                            setMobileNumber(e);
                            setMobileNumberError("");
                        } }
                        // readOnly={phoneNumber ? true : false}
                    />
                    {
                        mobileNumberError && <p style={{color:"Red", fontSize:"14.5px"}} className="m-0 mb-2" > {mobileNumberError} </p> 
                    }
                  </div>
                    <Button type="submit" style={{width : '100%'}} > Submit </Button>
                </form>
        )
    }

    const onClickResend = () => {
        handleSendOtpFunc();
        setTimer(60)
        startTimer()
    }

    const startTimer = () => {
        const counter =
            timer > 0 &&
            setInterval(
            () =>
                setTimer((prevState) => {
                return prevState - 1;
                }),
            1000
            );
        return () => clearInterval(counter);
    }  
      
    const handleSendOtpFunc = async (e) =>{
        // if (phoneNumber) e.preventDefault()
        setLoader(true)
        try{
            const response = await getSignInOtp({mobile : mobileNumber, otpFor : "verify"});
            setSentOtp(response.data.otp);
            startTimer()
            setShowMobileInput(false)
        }catch(error){
          console.log(error)
          setMobileNumberError(error?.response?.data?.error)
        }
        setLoader(false)
    }

    const handleWhatsappVerification = (e) =>{
        e.preventDefault()
        try{
          let reqNumber = ''
          if (mobileNumber === undefined  || mobileNumber === ''){
            reqNumber = ""
            setMobileNumberError("Please Enter Mobile Number");
          }else {
            reqNumber = mobileNumber 
            if (mobileNumber.slice(0,4) === "+911") {
              setMobileNumberError("Enter Valid Phone Number")
            }else {
              if (isValidPhoneNumber(reqNumber) || reqNumber === '' ) {
                setMobileNumberError("");
                handleSendOtpFunc();
                if (timer < 60) {
                  setTimer(60);
                }
              }else{
                setMobileNumberError("Enter Valid Phone Number")
              }
            }
          }
        }catch(error){
          console.log(error)
          setMobileNumberError('')
        }
    }

    const onVerify = async () => {
        const isOk = await bcrypt.compare(otp, sentOtp);
        if (!isOk) {
            setOtpErrorMsg("Invalid OTP")
        }
        else {
            setOtpErrorMsg("")
            onWhatsAppVerificationSuccess()
        }
    };

    const whatsAppOtpSection = () => {
        return (
            <div className="verify-email-modal-body">
              <div className="d-flex flex-row justify-content-center align-items-center mb-2">
                <p className="otp-header text-gray-500 text-center fw-semibold fs-6 mb-2 SpotLetContent">Enter the 4 digit code sent to </p>
                <div className=" d-flex flex-row justify-content-center align-items-center mb-2 mx-1">
                  <span 
                    className="text-gray-500 text-center fw-semibold fs-6 me-2 SpotLetContent"
                    style={{fontWeight : 'bold'}}
                    >{mobileNumber}
                  </span>
                  <FaRegEdit 
                    className="auth_text_color cursor"
                    style={{fontSize : '18px', marginBottom : "2px"}}
                    onClick={()=>{
                        setShowMobileInput(true);
                        setOtpErrorMsg("");
                        setOtp();
                    }}
                  />
                </div>
              </div>
                <OTPInput
                    value={otp}
                    onChange= {(e) => {
                      setOtp(e);
                      setOtpErrorMsg("")
                    }}//{ (e) =>  onChangeOtp(e)}
                    autoFocus
                    OTPLength={4}
                    otpType="number"
                    disabled={false}
                    secure
                    className={`otp-input ${otpErrorMsg !== "" ? "mb-2" : "mb-3"}`}
                />
                {otpErrorMsg && (
                    <div className='d-flex flex-row justify-content-center'>
                        <span className='required'> {otpErrorMsg} </span>
                    </div>
                )}
                <div className="resend-email mt-2 mb-3">
                    <span className="text-gray-500 text-center fw-semibold fs-6 SpotLetContent" > Didn't get the OTP? </span>
                    <span className={`${timer <= 0 ? "auth_text_color cursor" : "text-muted"} fw-semibold SpotLetContent mx-1`}
                        variant="link"
                        // className="resend-button"
                        disabled={timer > 0}
                        onClick={onClickResend}
                        >
                        Resend 
                    </span>
                    {timer > 0 && <span className="text-gray-500 text-center fw-semibold fs-6 SpotLetContent"> OTP in <span className="text-gray-500 text-center fw-semibold fs-6 SpotLetContent"  style={{fontWeight : 'bold'}}> {timer} sec</span> . </span>}
                </div>
                <div className='d-flex flex-row justify-content-center' >
                    <button className='btn btn-primary' type='button' onClick={onVerify} > Verify OTP </button>
                </div>
            </div>
        )
    }
 
  return (
  <Modal backdrop="static" show={show} onHide={onHide} centered>
    <Modal.Header closeButton>
        <Modal.Title>{header}</Modal.Title>
    </Modal.Header>
    <Modal.Body>
        { showMobileInput ? whatsAppNumberInputSection() : whatsAppOtpSection()}
    </Modal.Body>
    {/* <Modal.Footer className='d-flex flex-row justify-content-start'>
        <Button variant="secondary" onClick={onHide}>
        Cancel
        </Button>
        <Button variant="primary" onClick={onSuccess}>
        Verify OTP
        </Button>
    </Modal.Footer> */}
      {loader && <PageLoader/>}
    </Modal>
  );
}