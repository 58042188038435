import { useNavigate, useLocation } from 'react-router-dom';
import React,{ useEffect } from 'react';
import "./index.css";
import banner from "../../assets/NewImages/Auth/auth_banner_image.jpg";
import Authentication from "../../utils/NewAuthentication/Authentication"
import { useAuthStore } from '../../store/auth';
const Auth = () => {
    const { state } = useLocation();
    const location = useLocation();
    const navigate = useNavigate();
    const {userProfile} = useAuthStore()

    useEffect(()=> {
        if(userProfile) navigate("/")
    },[])

    const handleSuccess = () => {
        if (state || state?.from?.pathname) {
            navigate(state?.from?.pathname || '/')
        }else{
            navigate('/')
        }
    }

    return(
        <div className="authMainContainer">
            <div className="authSubContainer authSub1">
                <img alt="auth banner" src={banner} className="authBannerImage"/>
            </div>
            <div className="authSubContainer d-flex flex-row justify-content-center align-items-center">
                <div className="authRightSection">
                    <Authentication onSuccess={handleSuccess} activeTab={location.state?.activeTab|| 'register'}/>
                </div>
            </div>
        </div>
    )
}
export default Auth;