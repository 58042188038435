import { useEffect, useState } from "react"
import { getTypeOfProperties } from "../../apis/api";
import "./ListYourSpace.css"
import Accordion from 'react-bootstrap/Accordion';

// Images
import info from "../../assets/NewImages/BecomeAHost/i.svg";

const TypeOfProperty = ({data, handleNext }) => {
    const [selectedLocation, setSelectedLocation] = useState("");
    const [activeCategory,setActiveCategory] = useState('Commercial Space')
    const [index,setIndex] = useState(0)
    const [categories,setCategories] = useState({})
    const [locationTypes, setLocationTypes] = useState({});
    const [inputPropertyType,setInputPropertyType] = useState('');
    const [search, setSearch] = useState("");
    const [inputPropertyError, setInputPropertyError] = useState("");
    const [showNoCategory, setShowNoCategory] = useState(false)
    const onChangeSearch = (e) => {
        const value = e.target.value
        setSearch(value)

        let tempObj = {};
        if(value.trim() === "") {
            if (inputPropertyType) {
                setLocationTypes({...categories, "Others": [inputPropertyType]});
            }
            else{
                setLocationTypes({...categories});
            }
            setShowNoCategory(false)
        } else {
            for( const mainHead in categories) {
                const tempArrey = []
                for(const subHead of categories[mainHead]){
                    if(subHead.toLowerCase().includes(value.toLowerCase())) tempArrey.push(subHead)
                }
                if(tempArrey.length > 0) tempObj[mainHead] = tempArrey
            }
            setLocationTypes(tempObj)
            if(Object.keys(tempObj).length > 0)  setActiveCategory(Object.keys(tempObj)[0])
            console.log("tempObj", Object.keys(tempObj).length === 0);
            if (Object.keys(tempObj).length === 0 ) {
                setShowNoCategory(true)
            } else {
                setShowNoCategory(false)
            }
        }

    }
    const handleAddNewPropertyType = (e) => {
        e.preventDefault()
        if (inputPropertyType.trim() === "" ) {
            setInputPropertyError("Property type should not be empty");
            return null
        }
        else if (inputPropertyType.trim().length < 3) {
            setInputPropertyError("Property type should consist of minimum 3 characters");
            return null
        }
        else {
            setInputPropertyError("");
        }

        let tempPropertyType
        let tempActiveHead
        for( const mainHead in categories) {
            for(const subHead of categories[mainHead]){
                if(subHead.toLowerCase() === inputPropertyType.toLowerCase()){
                    tempPropertyType =  subHead
                    tempActiveHead = mainHead
                    break
                }
            }
        }
        if(tempPropertyType){
            setActiveCategory(tempActiveHead)
            setSelectedLocation(tempPropertyType)
        }else{
            setLocationTypes({
                ...locationTypes,
                Others : [inputPropertyType],
            })
            setSelectedLocation(inputPropertyType)
        }
        setInputPropertyType('')
    }
    const onClickCategory = (value) => {
        setActiveCategory(value)
    }
    const onCheckBoxClick = (e) => {
        const {name,checked} = e.target
        if(checked){
            setSelectedLocation(name)
        }else{
            setSelectedLocation('')
        }
    }
    const handleSave = () => {
        handleNext(selectedLocation)
    }
    useEffect(()=>{
        const callBack = async()=> {
            try{
                const resp = await getTypeOfProperties()

                const temp = {}
                resp.data.map((type)=>{
                    temp[type.category] = type.types.sort();
                })
                setCategories({...temp})
                setLocationTypes({...temp})
                //setData
                setSelectedLocation(data)
                if(data){
                    let tempPropertyType ;
                    let tempIndex = 0 
                    for (const category in  temp) {
                        if( temp[category].includes(data)){
                            setIndex(tempIndex)
                            setActiveCategory(category)
                            tempPropertyType = data
                            break
                        }
                        tempIndex +=1
                    }
                    if(!tempPropertyType){
                        setLocationTypes({
                            ...temp,
                            Others : [data],
                        })
                        setActiveCategory('Others')
                        setInputPropertyType(data);
                    }
                }
            }catch(error){
                console.log(error)
            }
        }
        callBack()
        const element = document.getElementById(`listTypeOfproperty`)
        if (element) element.scrollIntoView({behavior: "smooth",block: "start"});
    },[data])

    return (
        <>
            <div className="locationtype-page">
                <div className="locationtype-heading-container" >
                    <h1 className="locationtype-head" id='listTypeOfproperty'> Type of Property {' '}
                        <a
                            href="#"
                            data-bs-toggle="tooltip"
                            title="Select: Residential, Commercial, Event Venue, Vacation Rental"
                        >
                            <img src={info} />
                        </a>
                    </h1>
                    <div className="locationtype-searchcontainer">
                        <input
                            type="search"
                            placeholder="Search type of property"
                            onChange={onChangeSearch}
                            className="locationtype-search"
                            value={search}
                        />
                    </div>
                </div>
            <div className="tabCard deskCard row">
                <div className="mainHeadContainer  col-lg-3 col-xl-2">
                    {Object.keys(locationTypes).map((category,i) => {
                        return(
                            <div 
                            key={i}
                            className={activeCategory=== category ? "category  is-active-categorry cursor" : 'category cursor'}
                            onClick={()=>onClickCategory(category)}
                            >
                                <p>{category}</p>
                            </div>
                        )
                    })}
                </div>
                <div className="subHeadContainer  col-lg-7 col-xl-6">
                    {locationTypes[activeCategory]?.map((locationType,i)=>{
                        return(
                            <div 
                            key={i}
                            className={selectedLocation === locationType ? 'is-active-locationType' : 'locationType'}
                            >
                                <div className="d-flex flex-row  align-items-center" >
                                    <input className="amenities-checkbox cursor" type='checkbox' name={locationType} checked={selectedLocation === locationType} id={i} onChange={onCheckBoxClick}/>
                                    <label  className="amenities-label cursor" htmlFor={i}>{locationType}</label>
                                </div>
                            </div>
                        )
                    })}
                    {(activeCategory=== "Others" && (locationTypes.Others.length === 0 || !selectedLocation)) && (
                        <>
                        <form className="d-flex flex-column " onSubmit={handleAddNewPropertyType}>
                            <div className="d-flex flex-row align-items-end mx-2" >
                                <div className="mb-0 d-flex flex-column">
                                    <label htmlFor="property_type" className="form-label">
                                        Type of property
                                    </label>
                                    <input
                                        type="text"
                                        required
                                        placeholder="Enter property type"
                                        id="property_type"
                                        name="property_type"
                                        className="textFiled me-2"
                                        value={inputPropertyType}
                                        onChange={(e) => setInputPropertyType(e.target.value)}
                                    />
                                </div>
                                <div>
                                    <button type="submit" className="btn btn-primary">Submit</button>
                                </div>
                            </div>
                            { inputPropertyError && <span className="mx-2"  style={{color : '#ea4335'}}> {inputPropertyError} </span> }
                        </form>
                        </>
                    )}
                </div>
            </div>
            <div className="tabRowMobile">
                {Object.keys(locationTypes).map((category,i) => {
                    return(
                        <Accordion defaultActiveKey={index} className="m-3">
                            <Accordion.Item eventKey={i} onClick={()=>onClickCategory(category)}>
                            <Accordion.Header>{category}</Accordion.Header>
                            <Accordion.Body>
                            <div className="subHeadContainer  col-lg-7 col-xl-6">
                                {locationTypes[category]?.map((locationType,i)=>{
                                    return(
                                        <div 
                                        key={i}
                                        className={selectedLocation === locationType ? 'is-active-locationType' : 'locationType'}
                                        >
                                            <div className="d-flex flex-row  align-items-center" >
                                                <input className="amenities-checkbox cursor" type='checkbox' name={locationType} checked={selectedLocation === locationType} id={i} onChange={onCheckBoxClick}/>
                                                <label  className="amenities-label cursor" htmlFor={i}>{locationType}</label>
                                            </div>
                                        </div>
                                    )
                                })}
                                {(category=== "Others" && (locationTypes.Others.length === 0 || !selectedLocation)) && (
                                        <form className="d-flex align-items-center mx-2" onSubmit={handleAddNewPropertyType}>
                                            <div className="mb-2 d-flex flex-column">
                                                <label htmlFor="property_type" className="form-label">
                                                    Type of property
                                                </label>
                                                <div className="d-flex align-items-center amenitiesNewTypeInputContainer" >
                                                <input
                                                    type="text"
                                                    required
                                                    placeholder="Enter property type"
                                                    id="property_type"
                                                    name="property_type"
                                                    className="textFiled me-2 amenitiesNewTypeInput"
                                                    value={inputPropertyType}
                                                    onChange={(e) => setInputPropertyType(e.target.value)}
                                                />
                                                <button type="submit" className="btn btn-primary">Submit</button>
                                                </div>
                                                { inputPropertyError && <span className="mx-1"  style={{color : '#ea4335'}}> {inputPropertyError} </span> }
                                            </div>
                                        </form>
                                    )}
                            </div>
                            </Accordion.Body>
                            </Accordion.Item>
                        </Accordion>
                        // <div 
                        // key={i}
                        // className={activeCategory=== category ? "category  is-active-categorry cursor" : 'category cursor'}
                        // onClick={()=>onClickCategory(category)}
                        // >
                        //     <p>{category}</p>
                        // </div>
                    )
                })}
            </div>
                    {
                        showNoCategory && 
                        <div className="w-100 text-center" >
                            <h3 className="SpotLetSubHead"> No Results Found  </h3>
                        </div>
                    }
                    <div className="row controlContainer">
                        <div className="col-lg-8">
                            <div className="d-flex flex-row justify-content-between align-items-center">
                                <div>
                                    <button
                                        type="button"
                                        class="btn btn-tertiary"
                                        disabled
                                    >
                                        Back
                                    </button>
                                </div>
                                <div>
                                    <button
                                        type="button"
                                        className="btn btn-primary"
                                        onClick={handleSave}
                                        disabled={selectedLocation ? false : true}
                                    >
                                        Save & Continue
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4"></div>
                    </div>
        </div>
        </>
    )
}

export default TypeOfProperty