import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useLocationStore } from "../../store/location";
import {  getPlans, enquirePlan } from '../../apis/api'
import { Button } from "react-bootstrap";

import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';

//Components
import { Header } from "../../components/NewHeader/Header";
import { PageLoader } from "../../components/PageLoader";
// import { SubscriptionModel } from '../../components/Modal/SubscriptionModel';
import SubscriptionModal from './Modals/SubscriptionModal';
import { EnquiryModal } from '../../components/Modal/EnquiryModal';
import { ConfirmationModel } from '../../components/Modal/ConfirmationModel';
import { WhatsAppVerificationModal } from '../../components/Modal/WhatsAppVerificationModal';
import { EmailVerificationModal } from '../../components/Modal/EmailVerificationModal';
import { useAuthStore } from '../../store/auth';
//Icons
import CheckOutlinedIcon from '@mui/icons-material/CheckOutlined';
import ClearRoundedIcon from '@mui/icons-material/ClearRounded';
//Images
import iIcon from "../../assets/NewImages/SubscriptionPlan/i_icon.svg";
//Style
import "./Subscription.css"
import Carousel from 'react-elastic-carousel'

const ARROW_STYLE_LEFT = {
    position: 'absolute',
    top: '50%',
    // left : '30px',
    // marginTop: '-18px',
    backgroundColor: '#EA4335',
    padding: '7.5px',
    border: 0,
    height: '50px',
    width: '50px',
    borderRadius: '50%',
    zIndex: 1,
    color: '#ffffff',
    fontSize : '25px',
    boxShadow : '1px 1px 2px rgba(0, 0, 0, .12)',
  };
const ARROW_STYLE_RIGHT = {
position: 'absolute',
top: '50%',
backgroundColor: '#EA4335',
padding: '7.5px',
border: 0,
height: '50px',
width: '50px',
borderRadius: '50%',
zIndex: 1,
color: '#ffffff',
fontSize : '25px',
boxShadow : '1px 1px 2px rgba(0, 0, 0, .12)',
};
const TOOL_TIP_PLACEMENT = 'top'
const TOOL_TIP_DELAY = { show: 100, hide: 400 }
const BASIC = [
    {
        name : 'Promote your Property',
        toolTip : "Increase your property's exposure on SpotLet to attract more bookings and maximize revenue."
    },
    {
        name : 'Upload upto 30 Images',
        toolTip : 'Add high-quality images to showcase your property and attract more guests.'
    },
    {
        name : `Display Property's Price`,
        toolTip : 'Set transparent pricing information to help guests make informed decisions.'
    },
    {
        name : 'Highlight your Amenties',
        toolTip : 'List the amenities available at your property to highlight its comforts and conveniences.'
    },
    {
        name : 'Showcase your Rooms/Spaces',
        toolTip : 'Provide details about the different rooms or spaces available for booking.'
    },
    {
        name : `Share your Property's policies`,
        toolTip : 'Clearly outline your booking policies, cancellation terms, and any other important rules or regulations.'
    },
    {
        name : 'Access Host Dashboard',
        toolTip : 'Access a comprehensive dashboard to manage bookings, view analytics, and communicate with guests.'
    },
    {
        name : 'Personlized Property Page',
        toolTip : 'Customized webpage showcasing your property details'
    },
    {
        name : 'Get Unlimited Enquiries from Get in Touch Form',
        toolTip : ''
    },
    {
        name : 'Get Unlimited Booking Enquiries from App Notifications',
        toolTip : ''
    },
]
const STANDARD = [
    {
        name : 'All Inclusive Basic',
        toolTip : null,
    },
    {
        name : 'Upload upto 100 Images',
        toolTip : null,
    },
    {
        name : 'Share your Contact details to Interested Booking Enquiries',
        toolTip : null,
    },
    {
        name : 'Standard plan gives better search results than Basic',
        toolTip :`Your property will be listed above Basic listings in the selected category, ensuring better visibility.`,
    },
    {
        name : 'Real time Leads via email',
        toolTip : `Get instant notifications when potential renters show interest in your property.`,
    },
    {
        name : 'Priority Support',
        toolTip : `Get support for quick assistance whenever you need.`,
    },
]
const PREMIUM = [
    {
        name : 'All Inclusive Basic + Standard',
        toolTip : null,
    },
    {
        name : 'Upload upto 200 Images',
        toolTip : null,
    },
    {
        name : 'Premium plan gives better search result than Basic & Standard',
        toolTip : `Enhanced visibility with premium placement above both Basic and Standard listings.`,
    },
    {
        name : 'Search Suggestions for Similar Properties',
        toolTip : `Your property will be showcased as a similar option when users search for Basic or Standard properties, increasing your chances of being noticed by potential customers.`,
    },
    {
        name : 'Real time Leads via whatsapp',
        toolTip : null,
    },
    {
        name : '1 Blog written on your Property',
        toolTip : `Showcasing your property's charm, elevating its visibility and appeal to potential renters`,
    },
    {
        name : 'Promote your Recent Events',
        toolTip : `Highlight your recent events held at your property, showcasing its versatility and appeal to potential renters.`,
    },
]


const Subscription = () => {

  const navigate = useNavigate()
  const { userProfile, setUserProfile} = useAuthStore();
  const region = localStorage.getItem('region')
  const { setPlan } = useLocationStore();
  const [showSubscriptionModel, setShowSubscriptionModel] = useState(false);
  const [showEnquiryModel, setShowEnquiryModel] = useState(false);
  const [data, setData] = useState();
  const [standardPlan, setStandardPlan] = useState({})
  const [premiumPlan, setPremiumPlan] = useState({})
  const [activePlan,setActivePlan] = useState({})
  const [loader, setLoader] = useState(false)
  const [formData, setFormData] = useState();
  const [confirmationTitle, setConfirmationTitle] = useState("");
  const [showConfirmationModel, setShowConfirmationModel] = useState(false);
  const [confirmationBody, setConfirmationBody] = useState("");
  const [showWhatsAppModal, setShowWhatsAppModal] = useState(false)
  const [ShowEmailVerificationModal, setShowEmailVerificationModal] = useState(false)
  const [selectedPlan, setSelectedPlan] = useState('')

  const getPlansDetails = async () => {
    setLoader(true)
    try {
      const res = await getPlans();
      let reqCurrency 
      if (region === "India") {
        reqCurrency = "INR"
      } else {
        reqCurrency = "USD"
      }
      setData(res?.data);
      if(res?.data) {
        for (let each of  res?.data) {
          if (each?.plan === "Standard") {
            for (let eachCountryPlan of each?.amount) {
              if (eachCountryPlan?.currency === reqCurrency) {
                setStandardPlan({
                    plan : each?.plan,
                    originalAmount : eachCountryPlan?.originalAmount,
                    amount : eachCountryPlan?.discountAmount,
                    planId : eachCountryPlan?.plan_id,
                    currency : eachCountryPlan?.currency,
                })
              }
            }
          }
          if (each?.plan === "Premium") {
            for (let eachCountryPlan of each?.amount) {
              if (eachCountryPlan?.currency === reqCurrency) {
                setPremiumPlan({
                    plan : each?.plan,
                    originalAmount : eachCountryPlan?.originalAmount,
                    amount : eachCountryPlan?.discountAmount,
                    planId : eachCountryPlan?.plan_id,
                    currency : eachCountryPlan?.currency,
                })
              }
            }
          }
        }
      }
    } catch (error) {
      console.log(error)
    }
    setLoader(false)
  }
  const handleChangePlan = () => {
    if(activePlan?.plan === 'Standard'){
        setActivePlan(premiumPlan)
    }else{
        setActivePlan(standardPlan)
    }
  }
  const activatePlan = (selectedPlan) => {
      setPlan(selectedPlan)
      navigate('/list-your-space')
    }
const completeTask = () => {
    setShowSubscriptionModel(false)
    navigate('/list-your-space')
}
    useEffect(() => {
      window.scrollTo(0, 0);
      getPlansDetails()
    }, [] )
    const eachTerm = (data) => {
        return(
            <li className='subscription-carousel-li-item'>
                <span>
                    {(data?.name === 'All Inclusive Basic' || data?.name === 'All Inclusive Basic + Standard') ? 
                    <strong>{data?.name}</strong> : 
                    <span>{data?.name}</span>}
                    {' '}
                {data?.toolTip && (
                    <OverlayTrigger
                        placement={TOOL_TIP_PLACEMENT}
                        delay={TOOL_TIP_DELAY}
                        overlay={
                            <Tooltip id="button-tooltip">
                                {data?.toolTip}
                            </Tooltip>
                        }
                    >
                        <img src={iIcon}  alt={'{data?.toolTip}'}/>
                    </OverlayTrigger>
                )}
                </span>
            </li>
        )
    }
    const CustomLeftArrow = ({ onClick }) => {
        return (
        //   <button onClick={onClick} style={ARROW_STYLE_LEFT}>
          <button onClick={onClick} className='subscription-crousel-left-button'>
            &lt;
          </button>
        );
    };
    
    const CustomRightArrow = ({ onClick }) => {
    return (
        // <button onClick={onClick} style={ARROW_STYLE_RIGHT}>
         <button onClick={onClick} className='subscription-crousel-right-button'>
        &gt;
        </button>
    );
    };

    const hanldeSubmitForm = async (data) => {
    try {
        const response = await enquirePlan(data)
        setConfirmationTitle('Subscription')
        setConfirmationBody(response?.data?.message)
        setShowConfirmationModel(true)
        setFormData({})
        document.getElementById('subscriptionEnquiryForm').reset()
        document.getElementById("subscriptionEnquiryFormMessage").value = "";

    } catch (error) {
        setConfirmationTitle('Subscription')
        setConfirmationBody(error?.response?.data?.message)
        setShowConfirmationModel(true)
        console.log(error)
    }
    }

    const onChange = (e) => {
        const { name, value } = e.target
        setFormData({ ...formData, [name]: value })
    }

    const submitForm = (e) => {
        e.preventDefault()
        hanldeSubmitForm(formData)
    }

    const handleSelectPlan = (plan) => {
        if (!(region === "India") && !(plan === 'Basic')) {
            setConfirmationTitle('Coming soon')
            setShowConfirmationModel(true)
            setConfirmationBody(`We're coming soon with SpotLet 
            Subscription Plans! Till then enjoy Basic Listing while we develop 
            more features.`)
            return null
        } 
        setSelectedPlan(plan)
        if(!userProfile?.personalInfo?.email || !userProfile?.personalInfo?.emailVerified){
            setShowEmailVerificationModal(true)
        }else if(!userProfile?.personalInfo?.mobile || !userProfile?.personalInfo?.mobileVerified){
            setShowWhatsAppModal(true)
        }else{
            if(plan === 'Basic'){
                activatePlan(plan)
            }else if(plan === 'Standard'){
                if (!(region === "India")) {
                    setConfirmationTitle('Coming soon')
                    setShowConfirmationModel(true)
                    setConfirmationBody(`We're coming soon with SpotLet 
                    Subscription Plans! Till then enjoy Basic Listing while we prepare 
                    more features.`)
                } else {
                    setActivePlan(standardPlan) 
                    setShowSubscriptionModel(true) 
                }
            }else if(plan === "Premium"){
                if (!(region === "India")) {
                    setConfirmationTitle('Comning soon')
                    setShowConfirmationModel(true)
                    setConfirmationBody(`We're coming soon with SpotLet 
                    Subscription Plans! Till then enjoy Basic Listing while we prepare 
                    more features.`)
                } else {
                    setActivePlan(premiumPlan)
                    setShowSubscriptionModel(true) 
                }
            }else{
                console.log('Error while selecting plan')
                setConfirmationTitle('Selecting plan')
                setShowConfirmationModel(true)
                setConfirmationBody(`Error while selecting plan`)
            }
        }
    }

    const onWhatsAppVerificationSuccess = (value) => {
        if(value === 'true'){
            if(selectedPlan === 'Basic'){
                setUserProfile()
                activatePlan(selectedPlan)
            }else if(selectedPlan === 'Standard'){
                if (!(region === "India")) {
                    setConfirmationTitle('Coming soon')
                    setShowConfirmationModel(true)
                    setConfirmationBody(`We're coming soon with SpotLet 
                    Subscription Plans! Till then enjoy Basic Listing while we prepare 
                    more features.`)
                } else {
                    setUserProfile()
                    setActivePlan(standardPlan) 
                    setShowSubscriptionModel(true) 
                }
            }else if(selectedPlan === "Premium"){
                if (!(region === "India")) {
                    setConfirmationTitle('Comning soon')
                    setShowConfirmationModel(true)
                    setConfirmationBody(`We're coming soon with SpotLet 
                    Subscription Plans! Till then enjoy Basic Listing while we prepare 
                    more features.`)
                } else {
                    setUserProfile()
                    setActivePlan(premiumPlan)
                    setShowSubscriptionModel(true) 
                }
            }else{
                console.log('Error while selecting plan')
                setConfirmationTitle('Selecting plan')
                setShowConfirmationModel(true)
                setConfirmationBody(`Error while selecting plan`)
            }
        }else{
            setUserProfile()
            setConfirmationTitle("Error");
            setConfirmationBody("Error while verifying Mobile Number");
            setShowConfirmationModel(true);
        }
    }
      
    
    return(
        <div>
            <Header/>
            <div className="subscriptionContainer">
                <h1 className='SpotLetMainHead'>Choose Your Plan</h1>
                <Carousel
                itemsToShow={3}
                className="plans"
                pagination={false}
                breakPoints ={ [
                    // { width: 1, itemsToShow: 1 },
                    { width: 550, itemsToShow: 1, itemsToScroll: 1 },
                    { width: 768, itemsToShow: 2, itemsToScroll: 1 },
                    { width: 1200, itemsToShow: 3, itemsToScroll: 1 }
                  ]}
                renderArrow={({ type, onClick, isEdge }) => {
                    // Hide the left arrow if at the start, and the right arrow if at the end
                    if (isEdge) {
                        if (type === 'PREV') return <div></div>; // Hide the left arrow at the start
                        if (type === 'NEXT') return <div></div>; // Hide the right arrow at the end
                    }
                    const pointer = type === 'PREV' ? <CustomLeftArrow onClick={onClick} /> : <CustomRightArrow onClick={onClick} />;
                    return <div>{pointer}</div>;
                }}
                >
                    <div className="plan">
                    <h2 className='SpotLetMainHead'>Basic</h2>
                    <p className="price">Free</p>
                    <button onClick={() => handleSelectPlan('Basic')}>Select Plan</button>
                    <hr/>
                    <ul className='subscriptionUl'>
                        {BASIC?.map((item)=> eachTerm(item))}
                    </ul>
                    
                    </div>
                    <div className="plan">
                    <h2 className='SpotLetMainHead'>Standard</h2>
                    <p className="price"><span className="strike"><span className='plansSymbol'>{' '}{region === 'India' ? '₹' : '$'}{'  '}</span>{standardPlan?.originalAmount}/-</span><span className='plansSymbol'>{' '}{region === 'India' ? '₹' : '$'}{'  '}</span>{standardPlan?.amount}/-<span className="perAnnum">Per Annum</span></p>
                    <button onClick={() => handleSelectPlan('Standard')}>Select Plan</button>
                    <hr/>
                    <ul className='subscriptionUl'>
                        {STANDARD?.map((item)=> eachTerm(item))}
                    </ul>
                    </div>
                    <div className="plan">
                    <h2 className='SpotLetMainHead'>Premium</h2>
                    <p className="price"><span className="strike"><span className='plansSymbol'>{' '}{region === 'India' ? '₹' : '$'}{'  '}</span>{premiumPlan?.originalAmount}/-</span>{' '}{' '}<span className='plansSymbol'>{' '}{region === 'India' ? '₹' : '$'}{'  '}</span>{premiumPlan?.amount}/-<span className="perAnnum">Per Annum</span></p>
                    <button onClick={() => handleSelectPlan('Premium')}>Select Plan</button>
                    <hr/>
                    <ul className='subscriptionUl'>
                    {PREMIUM?.map((item)=> eachTerm(item))}
                    </ul>
                    </div>
                    <div className="plan">
                    <h2 className='SpotLetMainHead' >Enquire Now</h2>
                    <h2 className='SpotLetSubHead' >Explore the full potential of your property?</h2>
                    <hr/>
                    <span className='SpotLetContent'>
                        List with SpotLet for enhanced visibility, more bookings, and
                         increased revenue. Choose from tailored subscription 
                        plans to meet your rental needs. Partner with SpotLet for rental market success.
                    </span>
                    <form onSubmit={submitForm} id='subscriptionEnquiryForm'>
                    <div
                        style={{
                        // border: "1px solid",
                        // borderRadius: "3px",
                        margin: "5px 0px 5px 0px",
                        }}
                    >
                        <div className="d-flex flex-column justify-content-start align-items-start m-2">
                        <label className="form-label" htmlFor="name">
                            Name<span className="required">*</span>
                        </label>
                        <input
                            required
                            className="form-control"
                            name="name"
                            type="text"
                            id="name"
                            value={formData?.name}
                            onChange={onChange}
                        />
                        </div>
                        <div className="d-flex flex-column justify-content-start align-items-start m-2">
                        <label className="form-label" htmlFor="email">
                        Email Address<span className="required">*</span>
                        </label>
                        <input
                            required
                            className="form-control"
                            name="email"
                            type="email"
                            id="email"
                            pattern="^[^\s@]+@[^\s@]+\.[^\s@]{2,}$"
                            title="It should be of proper email format"
                            value={formData?.email}
                            onChange={onChange}
                        />
                        </div>
                        <div className="d-flex flex-column justify-content-start align-items-start m-2">
                        <label className="form-label" htmlFor="phoneNumber">
                        Mobile Number<span className="required">*</span>
                        </label>
                        <input
                            required
                            className="form-control"
                            name="phoneNumber"
                            type="tel"
                            id="phoneNumber"
                            pattern="[6789][0-9]{9}"
                            title="Phone number with 6-9 and remaing 9 digit with 0-9"
                            value={formData?.phoneNumber}
                            onChange={onChange}
                        />
                        </div>
                        <div className="d-flex flex-column justify-content-start align-items-start m-2">
                        <label className="form-label" htmlFor="plan">
                            {" "}
                            Subscription Plan<span className="required">*</span>
                        </label>
                        <select
                        style={{appearance: 'auto' }}    
                            value={formData?.plan}
                            id="plan"
                            className="form-control"
                            name="plan"
                            onChange={onChange}
                            required
                        >
                            <option value="" hidden selected >Select Plan</option>
                            <option value="standard">Standard</option>
                            <option value="premium">Premium</option>
                        </select>
                        </div>
                        <div className="d-flex flex-column justify-content-start align-items-start m-2">
                        <label className="form-label" htmlFor="message">
                            Message
                        </label>
                        <textarea
                            style={{
                            borderRadius : '5px',
                            width : '100%',
                            }}
                            name="message"
                            rows={4}
                            columns={55}
                            id='subscriptionEnquiryFormMessage'
                            value={formData?.message}
                            onChange={onChange}
                        />
                        </div>
                        <div className="m-2">
                        <Button type='submit' className="readmore SpotLetSubHead" style={{ color: '#fff' }} variant="contained" size="large" 
                            >
                            Submit
                            </Button>
                        </div>
                    </div>
                    </form>
                    </div>
                </Carousel>
            </div>
            {loader && <PageLoader />}
            {/* {showSubscriptionModel && (
                <SubscriptionModel
                  show={showSubscriptionModel}
                  handleClose={() => setShowSubscriptionModel(false)}
                  // handleOk={hanldeSubmitForm}
                  reqAllData = {modalData}
                  // plan={modalData?.plan}
                  // standard = {modalData?.standard}
                  // premium = {modalData?.premium}
                  // currencyType = {modalData?.currencyType}
                  // payNow={(offerId, couponId, payment, amount, isSubscription, finalAmount) => handlePayment(offerId, couponId, payment, amount, isSubscription, finalAmount)}
                  completeTask = {() => navigate('/list-your-space')}
                />
            )} */}
            {/* {showConfirmationModel && (
                <ConfirmationModel
                show={showConfirmationModel}
                handleClose={() => setShowConfirmationModel(false)}
                handleOk={() => setShowConfirmationModel(false)}
                title={confirmationTitle}
                body={confirmationBody}
                />
            )} */}
            {showSubscriptionModel && (
                <SubscriptionModal
                    show={showSubscriptionModel}
                    onHide={()=>setShowSubscriptionModel(false)}
                    plan ={activePlan}
                    handleChangePlan={handleChangePlan}
                    completeTask={completeTask}
                    title = {'Selected Plan'}
                    listingId={false}
                />
            )}
            {
                showEnquiryModel && (
                    <EnquiryModal
                      show = {showEnquiryModel}
                      handleClose={() => setShowEnquiryModel(false)}
                    />
                )
            }
            {showConfirmationModel && (
                <ConfirmationModel
                    show={showConfirmationModel}
                    handleClose={() => setShowConfirmationModel(false)}
                    handleOk={() => setShowConfirmationModel(false)}
                    title={confirmationTitle}
                    body={confirmationBody}
                />
            )}
            {showWhatsAppModal && (
                <WhatsAppVerificationModal
                    show={showWhatsAppModal}
                    onHide={() => setShowWhatsAppModal(false)}
                    onSuccess={(value) => { 
                    setShowWhatsAppModal(false)
                    onWhatsAppVerificationSuccess(value)
                    }}
                    phoneNumber = {userProfile?.personalInfo?.mobile}
                    userDetails = {userProfile?.personalInfo}
                    userId = {userProfile._id}
                    header = {'Update Mobile Number'}
                    text = {'Please update your mobile number to proceed further'}
                />
            )}
            {ShowEmailVerificationModal && (
                <EmailVerificationModal
                    show={ShowEmailVerificationModal}
                    onHide={() => setShowEmailVerificationModal(false)}
                    onSuccess={(value) => { 
                    setShowEmailVerificationModal(false)
                    onWhatsAppVerificationSuccess(value)
                    }}
                    emailAddress = {userProfile?.personalInfo?.email}
                    userDetails = {userProfile?.personalInfo}
                    userId = {userProfile._id}
                    header = {'Update Email Address'}
                    text = {'Please update your Email Address to proceed further'}
                />
                )}
        </div>
    )
}
export default Subscription;