import React, { Component } from "react";
import { Map, Marker, GoogleApiWrapper } from "google-maps-react";
import {
  getLatLng,
  geocodeByPlaceId,
} from "react-places-autocomplete";
import { getGky } from "../../../utils/baseUrl";

/*global google*/
const reqKey = getGky();
export class MapContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      // for google map places autocomplete
      showingInfoWindow: false,
      activeMarker: {},
      selectedPlace: {},

      mapCenter: {
        lat: this.props?.lat,
        lng: this.props?.lng,
      },
    };

    // geocodeByAddress(this.props?.address)
    //   .then(results => getLatLng(results[0]))
    //   .then(latLng => {
    //     this.setState({ mapCenter: latLng });
    //   })
    //   .catch(error => console.error('Error', error));
    geocodeByPlaceId(this.props?.place_id)
      .then((results) => getLatLng(results[0]))
      .then((latLng) => {
        this.setState({ mapCenter: latLng });
      })
      .catch((error) => console.error(error));
  }

  //   handleGetPlaceID = async (latitude, longitude) => {
  //     try {
  //       const apiKey = reqKey;
  //       const response = await axios.get(
  //         `https://maps.googleapis.com/maps/api/geocode/json?latlng=${latitude},${longitude}&key=${apiKey}`
  //       );

  //       if (response.data && response.data.results && response.data.results.length > 0) {
  //         this.props.updateSelectLocation(response.data.results[0].place_id, latitude, longitude);
  //       } else {
  //         console.error('No results found');
  //       }
  //     } catch (error) {
  //       console.error('Error fetching Place ID:', error);
  //     }
  //   };

  // Handler for when a marker or map is clicked
  //   onMapClick = (mapProps, map, clickEvent) => {
  //     // Do something with the clickEvent, e.g., show an InfoWindow with more details
  //     this.setState({
  //       mapCenter: {
  //         lat: clickEvent.latLng.lat(),
  //         lng: clickEvent.latLng.lng(),
  //       }
  //     });
  //     this.handleGetPlaceID(clickEvent.latLng.lat(), clickEvent.latLng.lng());
  //   };

  render() {
    return (
      <Map
        zoom={10}
        // zoomControl={true}
        // mapTypeControl={false}
        // scaleControl={true}
        // streetViewControl={true}
        // rotateControl={true}
        // options={{ gestureHandling: 'none'}}
        scrollwheel={false}
        fullscreenControl={false}
        disableDefaultUI={true}
        zoomControl={false}
        google={this.props.google}
        style={
          window.innerWidth <= 768
            ? {
              width: "350px",
              height: "280px",
              marginTop: "0px",
              borderRadius: "20px",
              paddingTop: "0px",
            }
            : {
              width: "655px",
              height: "440px",
              marginTop: "0px",
              borderRadius: "20px",
            }
        }
        containerStyle={{ width: "10%", height: "0px", position: "absolute" }}
        initialCenter={{
          lat: this.state.mapCenter.lat,
          lng: this.state.mapCenter.lng,
        }}
        center={{
          lat: this.state.mapCenter.lat,
          lng: this.state.mapCenter.lng,
        }}
      // onClick={this.onMapClick}
      >
        <Marker
          icon={{
            path: google.maps.SymbolPath.CIRCLE,
            fillColor: "#EA4335",
            fillOpacity: 0.3,
            scale: 60,
            strokeColor: "white",
            strokeWeight: 0.5,
          }}
          position={{
            lat: this.state.mapCenter.lat,
            lng: this.state.mapCenter.lng,
          }}
        />
      </Map>
    );
  }
}

export default GoogleApiWrapper({
  apiKey: reqKey,
})(MapContainer);
