import { Modal } from "react-bootstrap";

const { Header, Title, Body } = Modal;
export function SubpropertyBookingRequestModal({
    handleClose,
    show,
    title,
    body,
}) {

    return (
        <Modal show={show} onHide={handleClose}>
            <Header closeButton>
                <Title>{title}</Title>
            </Header>
            <Body >
                {body}
            </Body>
        </Modal>
    );
}